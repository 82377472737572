import React, { PureComponent } from 'react';
import { PieChart, Pie, Cell, Tooltip, Sector } from 'recharts';

export default class PieChartComponent extends PureComponent {
  render() {
    return (
      <div>
        <PieChart width={250} height={350} onMouseEnter={this.onPieEnter}>
          <Pie
            data={this.props.data}
            cx={120}
            cy={200}
            innerRadius={this.props.innerRadius ? this.props.innerRadius : this.props.totalCount ? 30 : 60}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={this.props.paddingAngle ? this.props.paddingAngle : 10}
            dataKey={this.props.dataKey}
            label={this.props.hideLabel}
            cornerRadius={this.props.cornerRadius ? this.props.cornerRadius : 0}
          >

            {this.props.colors && this.props.colors.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry} />
            ))}
          </Pie>
          {this.props.customToolTip ?
            <Tooltip wrapperStyle={{ zIndex: 10 }} content={this.props.customToolTip} />
            : null}
        </PieChart>
        {this.props.label1 && this.props.label2 ?
          <div className='PieLabel col-md-5 text-center'>
            <label className='font-size-16 font-wt-500 m-0'>{this.props.label1}</label>
            <label className='font-size-16 font-wt-600 m-0'>{this.props.label2}</label>
          </div>
          : null
        }
        {this.props.totalCount &&
          <div
            style={{
              position: 'absolute',
              top: '49%',
              left: '52%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
              fontSize: '20px',
            }}
          >
            {this.props.totalCount}
          </div>
        }

        {this.props.totalVal ?
          <div className='pieContainer'>
            <label className='font-size-16 font-wt-500 labelCenter'>{(this.props.hideDollar ? "" : "$ ") + Intl.NumberFormat('en-US', { notation: 'compact' }).format(this.props.totalVal)}</label>
          </div>
          : null
        }
      </div>
    );
  }
}
