import React from 'react'
import ContractPieChart from '../piechart'

const tradeContract = [
  { name: "Pilot", key: "pilotcount", color: "color-ffc107e6" },
  { name: "In Progress", key: "progresscount", color: 'colorFFA41C' },
  { name: "Completed ", key: 'completecount', color: 'color3DB16F' }
]

const TradeContract = ({ tradecontract_data }) => {
  return (
    <div className='mt-3'>
      <div className='w-30  pl-0'>
        <div className='card h-75 dashboard-card shadow-sm align-items-center justify-content-center'>
          <label className='w-100 font-size-14 text-color-value font-wt-600 pt-2 text-left'>{`Total Contract - `}
            <label className='font-size-14 text-color-value font-wt-600 text-color1'>{tradecontract_data?.totalcount || 0}</label></label>
          <div className='row px-0 w-100'>
            {tradeContract.map(item => {
              return <div className='w-33 cursor'>
                <label className={`value font-wt-600  w-100 cursor ${item.color}`}>
                  {tradecontract_data?.[item.key] || 0}
                </label>
                <label className={'font-size-14 font-wt-700 text-color-value cursor'}>{item.name}</label>
              </div>
            })}
          </div>
        </div>
      </div>
      <div className='w-80 card mt-4 h-75 dashboard-card shadow-sm align-items-center justify-content-center'>
        <ContractPieChart contractDetails={{
          total: tradecontract_data ? tradecontract_data.totalcount : 0,
          complete: tradecontract_data ? tradecontract_data.completecount : 0,
          progress: tradecontract_data ? tradecontract_data.progresscount : 0,
          pilot: tradecontract_data ? tradecontract_data.pilotcount : 0
        }} />
      </div>
    </div>
  )
}

export default TradeContract
