import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import HeaderV2 from "../../../partial/headerV2";
import SideBarV2 from "../../../partial/sideBarV2";
import call from "../../../../service";
import toastDisplay from "../../../../utils/toastNotification";
import { most_used_currencies } from "../../../../utils/myFunctions";
import { getDocDetails } from "../../../../utils/myFunctions";
import { FileInput } from "../../../../utils/FileInput";
import { convertImageToPdf } from "../../../../utils/myFunctions";
import AdavancePaymentTab from "../../../InvoiceDiscounting/contract/components/adavancePaymentTab";
import FinanceInvoiceModal from "../../../InvoiceDiscounting/contract/components/financeinvoiceModal";
import SignDocument from "../../../InvoiceDiscounting/components/SignDocument";
import TooltipModal from "../../../InvoiceDiscounting/components/Tooltipmodal";
import { InputWithSelect, NewInput, NewSelect } from "../../../../utils/newInput";


const addBuyerTabs = [
  { name: "Buyer details" },
  { name: "LC details" },
  { name: "Documents" },
  { name: "Review" },
  { name: "Payment" },
]

const reviewForm = [
  { "name": "Buyer Name", val: "buyerName" },
  { "name": "Previous year annual sales", val: "previousAnnualSale", unit: "buyerCurrency" },
  { "name": "Contact Number:", val: "buyerPhone", unit: "buyerPhoneCode" },
  { "name": "Expected current year’s annual sale", val: "currentAnnualSale", unit: "buyerCurrency" },

  { "name": "Email Id", val: "buyerEmail" },
  { "name": "Inco Terms", val: "incoTerms" },
  { "name": "Website", val: "buyerWebsite" },
  { "name": "Terms of Payment", val: "termsOfPayment" },

  { "name": "Address", val: "buyerAddress" },
  { "name": "Product Details", val: "productDetails" },
  { "name": "Industry Type", val: "industryType" },
  { "name": "DUNS No", val: "buyerDUNSNo" },
  { "name": "HSN Code", val: "buyerHsnCode" },
  { "name": "Limit Required", val: "requiredLimit", unit: "requiredLimitCurrency" },
]


const reviewForm3 = [
  { "name": "LC type", val: "LCtype", unit: "LCtype" },
  { "name": "Beneficiary bank account no.", val: "Beneficiarybankaccountno", unit: "Beneficiarybankaccountno" },
  { "name": "LC number:", val: "LCnumber", unit: "LCnumber" },
  { "name": "Beneficiary bank IFSC code", val: "BeneficiarybankIFSCcode", unit: "BeneficiarybankIFSCcode" },
  { "name": "LC tenor", val: "LCtenor" },
  { "name": "LC issuing bank name", val: "LCissuingbankname" },
  { "name": "Country of origin", val: "Countryoforigin" },
  { "name": "LC issuing bank SWIFT code", val: "LCissuingbankSWIFTcode" },
  { "name": "Shipment from country", val: "Shipmentfromcountry" },
  { "name": "Shipment to country", val: "Shipmenttocountry" },
  { "name": "LC issuing bank address", val: "LCissuingbankaddress" },
  { "name": "Port of loading", val: "Portofloading" },
  { "name": "Confirming bank name", val: "Confirmingbankname" },
  { "name": "Port of discharge", val: "Portofdischarge" },
  { "name": "Confirming bank SWIFT code", val: "ConfirmingbankSWIFTcode" },
  { "name": "Commodity", val: "Commodity" },
  { "name": "Expected date of shipment", val: "Expecteddateofshipment" },
  { "name": "Beneficiary bank name", val: "Beneficiarybankname" },
  { "name": "Confirming bank address", val: "Confirmingbankaddress" },
  { "name": "Beneficiary bank SWIFT code", val: "BeneficiarybankSWIFTcode" },
  { "name": "Confirming bank name", val: "Confirmingbankname" },
  { "name": "Confirming bank SWIFT code", val: "ConfirmingbankSWIFTcode" },
  { "name": "Confirming bank address", val: "Confirmingbankaddress" },

]

const adavanceData = [
  { name: "Factoring fees", amount: "$ 50" },
  { name: "Interest rate", amount: "$ 100" },
  { name: "One time set-up fees", amount: "$ 750" },
  { name: "Finance credit days", amount: "50" },
];

const AmendApplication = ({ userTokenDetails, navToggleState }) => {


  const queryParams = new URLSearchParams(window.location.search)
  const buyer = queryParams.get("buyer")

  let onlyViewMode = localStorage.getItem('onlyViewMode')

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)

  const [tab, setTab] = useState(4)
  const [limitinvoice, setLimitinvoice] = useState(false);
  const [experience, setExperience] = useState(false);

  const [tooltip, setTooltip] = useState(false);
  const [signdoc, setSigndoc] = useState(false);
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)
  const [addMoreDoc, setAddMoreDoc] = useState([])

  const [dunsData, setDunsData] = useState([])
  const [hsnCodes, setHsnCodes] = useState([])
  const [countrys, setCountrys] = useState([])
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [financierData, setFinancierData] = useState([]);

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  const [firstindex, setFirstindex] = useState(0);
  const [lastindex, setLastindex] = useState(4);
  const [slice, setSlice] = useState(1);

  const Next = () => {
    setFirstindex(firstindex + 4);
    setLastindex(lastindex + 4);
    setSlice(slice + 1);
  };

  const Back = () => {
    setFirstindex(firstindex - 4);
    setLastindex(lastindex - 4);
    setSlice(slice - 1);
  };


  const closeSuccess = () => {
    setLimitinvoice(false);
    setExperience(true);
  }

  useEffect(() => {
    //Get Buyer list start
    call('POST', 'getBuyersDetailWithDoc', {
      buyersId: buyer,
      applicationId: localStorage.getItem("applicationId")
    }).then((result) => {
      // console.log('running getBuyersDetailWithDoc api-->', result);
      setDbData(result);
    }).catch((e) => {
      // console.log('error in getBuyersDetailWithDoc', e);
    });
    //Get buyer list end
  }, [])

  useEffect(() => {
    if (buyer && dbData.length) {
      let event = {
        target: {
          name: "selectedBuyer",
          value: buyer
        }
      }
      handleChange(event)
    }
  }, [buyer, dbData])

  const handleChange = async (event) => {
    if (event.persist) {
      event.persist()
    }

    if (event.target.name === "selectedBuyer") {
      setshowLoader(true)
      let selectedBuyer = dbData[0]
      let buyers_credit = JSON.parse(selectedBuyer.buyers_credit)

      for (let index = 0; index < buyers_credit.length; index++) {
        const element = buyers_credit[index];
        if (element.lender_name.toLowerCase().includes("stenn")) {
          buyers_credit[index]["logo"] = "assets/images/supplier-images/stenn-logo.png"
        }
        else if (element.lender_name.toLowerCase().includes("modifi")) {
          buyers_credit[index]["logo"] = "assets/images/supplier-images/modifi-logo.png"
        }
      }


      let termSheetDocument = await getDocDetails(localStorage.getItem("termSheet"))
      let invoiceDocument = await getDocDetails(selectedBuyer.buyersDocId.split(":")[0])
      let poDocument = await getDocDetails(selectedBuyer.buyersDocId.split(":")[1])

      let otherDocsObj = {}

      if (selectedBuyer.buyerOtherDocs) {
        let buyerOtherDocs = selectedBuyer.buyerOtherDocs.split(":")
        for (let index = 0; index < buyerOtherDocs.length; index++) {
          const element = buyerOtherDocs[index];
          otherDocsObj["otherDoc" + index] = await getDocDetails(element)
          addMoreDoc.push(null)
        }
      }

      setshowLoader(false)

      setData({
        ...data, [event.target.name]: event.target.value, ...selectedBuyer, buyerCurrency: selectedBuyer.buyerCurrency.split(":")[1],
        termSheetDocument, invoiceDocument, poDocument, ...otherDocsObj, buyers_credit
      })

    }
    else {
      setData({ ...data, [event.target.name]: event.target.value })
      setErrors({ ...errors, [event.target.name]: "" })
    }
  }
  const closeExpFn = () => {
    setExperience(false);
  }


  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }
      if (event.target.name.includes("otherDoc")) {
        setAddMoreDoc(addMoreDoc.concat(null))
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (!file_type.includes("pdf")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  async function handleValidation(tabIndex) {

    if (tabIndex != undefined) {
      if (tabIndex < tab) {
        return setTab(tabIndex)
      }
      else if (tabIndex == tab) {
        return null
      }
      else {
        return toastDisplay("Click on continue button to go to next form", "info")
      }
    }

    let err = {}

    if (tab === 2) {
      if (!(data.invoiceDocument && data.invoiceDocument.filebase64)) {
        err["invoiceDocument"] = "Mandatory Field"
      }
      if (!(data.poDocument && data.poDocument.filebase64)) {
        err["poDocument"] = "Mandatory Field"
      }
      if (!(data.termSheetDocument && data.termSheetDocument.signatureId)) {
        err["termSheetDocument"] = "Signing term sheet is mandatory"
      }
    }

    if (!Object.keys(err).length) {
      if (tab === 2) {
        setshowLoader(true)
        let formData = new FormData()
        formData.append("buyerId", data.id)
        if (!data.termSheetDocument.fromDb) {
          formData.append("termSheetDocument", data.termSheetDocument)
        }
        if (!data.invoiceDocument.fromDb) {
          formData.append("previousInvoiceDocument", data.invoiceDocument)
        }
        if (!data.poDocument.fromDb) {
          formData.append("previousPODocument", data.poDocument)
        }
        formData.append("buyersDocId", data.buyersDocId)
        formData.append("buyerOtherDocs", data.buyerOtherDocs)
        formData.append("userId", userId)
        formData.append("applicationId", localStorage.getItem("applicationId"))
        formData.append("termSheetDocId", localStorage.getItem("termSheet"))
        if (data.termSheetDocument.signatureId) {
          formData.append("termSheetDocSignId", data.termSheetDocument.signatureId)
        }
        if (data.invoiceDocument.signatureId) {
          formData.append("invoiceDocSignId", data.invoiceDocument.signatureId)
        }
        if (data.poDocument.signatureId) {
          formData.append("poDocSignId", data.poDocument.signatureId)
        }
        addMoreDoc.forEach((k, index) => {
          if (data["otherDoc" + index]) {
            formData.append("otherDoc" + index, data["otherDoc" + index])
            if (data["otherDoc" + index]["signatureId"]) {
              formData.append("otherDoc" + index + "SignId", data["otherDoc" + index]["signatureId"])
            }
          }
        })
        if (userTypeId != 8) {
          formData.append("exporterSignedContract", true)
        }
        call('POST', 'submitInvQuotation', formData).then((result) => {
          // console.log('running submitInvQuotation api-->', result);
          toastDisplay("Contract updated successfully", "success", () => window.location = "/invoicecontract")
          setshowLoader(false)
        }).catch((e) => {
          // console.log('error in submitInvQuotation', e);
          toastDisplay("Something went wrong", "error")
          setshowLoader(false)
        });
        return null
      }
      else {
        setTab(tab + 1)
      }
    }

    setErrors(err)
  }



  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="LcAmendment" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Amend non resource - LC discounting"}
              userTokenDetails={userTokenDetails} />
            <div>
              {/* <a className="cursor" href="/LcContract"><img className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a> */}
              <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                {addBuyerTabs.map((item, index) => {
                  return (
                    <li>
                      <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                        onClick={() => { handleValidation(index) }}
                      >
                        {item.name}</a>
                    </li>
                  )
                })}
              </ul>
              <div className="card mt-1">
                {tab === 0 ? (
                  <div className='px-5 py-4 pt-5'>
                    <div className="row">
                      <div className="col-md-6">
                        <InputWithSelect isAstrix={true} type={"text"} label={"Buyer’s Name"}
                          selectData={currencyData}
                          selectName={"Commodity"} selectValue={data.limitRequiredCurrency}
                          optionLabel={"code"} optionValue={'code'}
                          name={'limitRequired'} value={data.limitRequired} error={errors.limitRequired}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-6">
                        <InputWithSelect isAstrix={true} type={"text"} label={"Contact Number"}
                          selectData={currencyData}
                          selectName={"Commodity"} selectValue={data.limitRequiredCurrency}
                          optionLabel={"code"} optionValue={'code'}
                          name={'limitRequired'} value={data.limitRequired} error={errors.limitRequired}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-6">
                        <NewInput isAstrix={true} type={"email"} label={"Email ID"}
                          name={"Expecteddateshipment"} value={data.Purchaseordernumber} error={errors.Purchaseordernumber}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-6">
                        <NewInput isAstrix={true} type={"url"} label={"Website"}
                          name={"Expecteddateshipment"} value={data.Purchaseordernumber} error={errors.Purchaseordernumber}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-6">
                        <NewInput isAstrix={true} type={"text"} label={"Address line 1"}
                          name={"Expecteddateshipment"} value={data.Purchaseordernumber} error={errors.Purchaseordernumber}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-6">
                        <NewInput isAstrix={true} type={"text"} label={"Address line 2"}
                          name={"Expecteddateshipment"} value={data.Purchaseordernumber} error={errors.Purchaseordernumber}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-6">
                        <NewInput isAstrix={true} type={"text"} label={"City"}
                          name={"Expecteddateshipment"} value={data.Purchaseordernumber} error={errors.Purchaseordernumber}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-6">
                        <NewInput isAstrix={true} type={"text"} label={"State"}
                          name={"Expecteddateshipment"} value={data.Purchaseordernumber} error={errors.Purchaseordernumber}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-6">
                        <NewSelect isAstrix={true} label={"Country"}
                          selectData={countrys} name={"Country"}
                          value={data.buyerCountry} optionLabel={"name"} optionValue={'sortname'}
                          onChange={handleChange} error={errors.buyerCountry} />
                      </div>
                      <div className="col-md-6">
                        <NewInput isAstrix={true} type={"text"} label={"Postal code"}
                          name={"Postalcode"} value={data.Purchaseordernumber} error={errors.Purchaseordernumber}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-6">
                        <NewSelect isAstrix={true} label={"Industry Type"}
                          selectData={countrys} name={"IndustryType"}
                          value={data.buyerCountry} optionLabel={"name"} optionValue={'sortname'}
                          onChange={handleChange} error={errors.buyerCountry} />
                      </div>
                      <div className="col-md-6">
                        <NewInput isAstrix={true} type={"text"} label={"DUNS No."}
                          name={"Postalcode"} value={data.Purchaseordernumber} error={errors.Purchaseordernumber}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-6">
                        <InputWithSelect isAstrix={true} type={"text"} label={"Previous year’s annual sale"}
                          selectData={currencyData}
                          selectName={"Commodity"} selectValue={data.limitRequiredCurrency}
                          optionLabel={"code"} optionValue={'code'}
                          name={'limitRequired'} value={data.limitRequired} error={errors.limitRequired}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-6">
                        <InputWithSelect isAstrix={true} type={"text"} label={"Expected current year’s annual sale"}
                          selectData={currencyData}
                          selectName={"Commodity"} selectValue={data.limitRequiredCurrency}
                          optionLabel={"code"} optionValue={'code'}
                          name={'limitRequired'} value={data.limitRequired} error={errors.limitRequired}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-6">
                        <InputWithSelect isAstrix={true} type={"text"} label={"Inco Trems"}
                          selectData={currencyData}
                          selectName={"Commodity"} selectValue={data.limitRequiredCurrency}
                          optionLabel={"code"} optionValue={'code'}
                          name={'limitRequired'} value={data.limitRequired} error={errors.limitRequired}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-6">
                        <NewSelect isAstrix={true} label={"Terms of Payment"}
                          selectData={countrys} name={"IndustryType"}
                          value={data.buyerCountry} optionLabel={"name"} optionValue={'sortname'}
                          onChange={handleChange} error={errors.buyerCountry} />
                      </div>
                      <div className="col-md-6">
                        <NewInput isAstrix={true} type={"text"} label={"Product details"}
                          name={"Postalcode"} value={data.Purchaseordernumber} error={errors.Purchaseordernumber}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-6">
                        <NewInput isAstrix={true} type={"text"} label={"HSN Code"}
                          name={"Postalcode"} value={data.Purchaseordernumber} error={errors.Purchaseordernumber}
                          onChange={handleChange} />
                      </div>
                    </div>

                  </div>
                ) : null}
                {tab === 1 ? (
                  <div className='px-5 pt-5 pb-3'>
                    <div className="row align-items-center">
                      <div className="col-md-6 mb-3 mt-3">
                        <label className="font-size-14">LC Draft</label>
                        <FileInput name={"poDocument"} value={data.poDocument} error={errors.poDocument}
                          onChange={handleFile} isEditable={false}
                        />
                      </div>
                      <div className="col-md-6 mb-3 mt-3">
                        <label className="font-size-14"></label>
                        <NewInput isAstrix={true} type={"text"} label={"LC number"}
                          name={"Postalcode"} value={data.Purchaseordernumber} error={errors.Purchaseordernumber}
                          onChange={handleChange} />
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <NewSelect isAstrix={true} label={"LC type"}
                            selectData={countrys} name={"IndustryType"}
                            value={data.buyerCountry} optionLabel={"name"} optionValue={'sortname'}
                            onChange={handleChange} error={errors.buyerCountry} />
                        </div>
                        <div className="col-md-6">
                          <NewSelect isAstrix={true} label={"LC tenor"}
                            selectData={countrys} name={"IndustryType"}
                            value={data.buyerCountry} optionLabel={"name"} optionValue={'sortname'}
                            onChange={handleChange} error={errors.buyerCountry} />
                        </div>
                        <div className="col-md-6">
                          <NewSelect isAstrix={true} label={"Shipment from country"}
                            selectData={countrys} name={"IndustryType"}
                            value={data.buyerCountry} optionLabel={"name"} optionValue={'sortname'}
                            onChange={handleChange} error={errors.buyerCountry} />
                        </div>
                        <div className="col-md-6">
                          <NewSelect isAstrix={true} label={"Shipment to country"}
                            selectData={countrys} name={"IndustryType"}
                            value={data.buyerCountry} optionLabel={"name"} optionValue={'sortname'}
                            onChange={handleChange} error={errors.buyerCountry} />
                        </div>
                        <div className="col-md-6">
                          <NewSelect isAstrix={true} label={"Port of loading"}
                            selectData={countrys} name={"IndustryType"}
                            value={data.buyerCountry} optionLabel={"name"} optionValue={'sortname'}
                            onChange={handleChange} error={errors.buyerCountry} />
                        </div>
                        <div className="col-md-6">
                          <NewSelect isAstrix={true} label={"Port of discharge"}
                            selectData={countrys} name={"IndustryType"}
                            value={data.buyerCountry} optionLabel={"name"} optionValue={'sortname'}
                            onChange={handleChange} error={errors.buyerCountry} />
                        </div>
                        <div className="col-md-6">
                          <InputWithSelect isAstrix={true} type={"text"} label={"Commodity"}
                            selectData={currencyData}
                            selectName={"Commodity"} selectValue={data.limitRequiredCurrency}
                            optionLabel={"code"} optionValue={'code'}
                            name={'limitRequired'} value={data.limitRequired} error={errors.limitRequired}
                            onChange={handleChange} />
                        </div>
                        <div className="col-md-6 ">
                          <NewInput isAstrix={true} type={"text"} label={"44C - Expected date of shipment"}
                            name={"Postalcode"} value={data.Purchaseordernumber} error={errors.Purchaseordernumber}
                            onChange={handleChange} />
                        </div>
                        <div className="col-md-6">
                          <NewSelect isAstrix={true} label={"Beneficiary bank name"}
                            selectData={countrys} name={"IndustryType"}
                            value={data.buyerCountry} optionLabel={"name"} optionValue={'sortname'}
                            onChange={handleChange} error={errors.buyerCountry} />
                        </div>
                        <div className="col-md-6 ">
                          <NewInput isAstrix={true} type={"text"} label={"Beneficiary bank account no."}
                            name={"Postalcode"} value={data.Purchaseordernumber} error={errors.Purchaseordernumber}
                            onChange={handleChange} />
                        </div>
                        <div className="col-md-6 ">
                          <NewInput isAstrix={true} type={"text"} label={"Beneficiary bank IFSC code"}
                            name={"Postalcode"} value={data.Purchaseordernumber} error={errors.Purchaseordernumber}
                            onChange={handleChange} />
                        </div>
                        <div className="col-md-6">
                          <NewSelect isAstrix={true} label={"Beneficiary bank SWIFT code"}
                            selectData={countrys} name={"IndustryType"}
                            value={data.buyerCountry} optionLabel={"name"} optionValue={'sortname'}
                            onChange={handleChange} error={errors.buyerCountry} />
                        </div>
                        <div className="col-md-6">
                          <NewSelect isAstrix={true} label={"LC issuing bank name"}
                            selectData={countrys} name={"IndustryType"}
                            value={data.buyerCountry} optionLabel={"name"} optionValue={'sortname'}
                            onChange={handleChange} error={errors.buyerCountry} />
                        </div>
                        <div className="col-md-6">
                          <NewSelect isAstrix={true} label={"LC issuing bank address"}
                            selectData={countrys} name={"IndustryType"}
                            value={data.buyerCountry} optionLabel={"name"} optionValue={'sortname'}
                            onChange={handleChange} error={errors.buyerCountry} />
                        </div>
                        <div className="col-md-6">
                          <NewSelect isAstrix={true} label={"LC issuing bank SWIFT code"}
                            selectData={countrys} name={"IndustryType"}
                            value={data.buyerCountry} optionLabel={"name"} optionValue={'sortname'}
                            onChange={handleChange} error={errors.buyerCountry} />
                        </div>
                        <div className="col-md-6">
                          <NewSelect isAstrix={true} label={"Country of origin"}
                            selectData={countrys} name={"IndustryType"}
                            value={data.buyerCountry} optionLabel={"name"} optionValue={'sortname'}
                            onChange={handleChange} error={errors.buyerCountry} />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {tab === 2 ? (
                  <div className='px-5 py-4 pt-5'>
                    <div className="col-md-8 mb-">
                      <label className="font-size-14">LC Draft</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"poDocument"} value={data.poDocument} error={errors.poDocument}
                            onChange={handleFile} isEditable={false}
                          />
                        </div>
                        <div className="col-md-4 text-end">
                          <a className="text-secondary cursor "><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</a>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-8 mb-3">
                      <label className="font-size-14">Purchase Order</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"poDocument"} value={data.poDocument} error={errors.poDocument}
                            onChange={handleFile} isEditable={false}
                          />
                        </div>
                        <div className="col-md-4 text-end">
                          <a className="text-secondary cursor "><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8 mb-3">
                      <label className="font-size-14">Invoice</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"poDocument"} value={data.poDocument} error={errors.poDocument}
                            onChange={handleFile} isEditable={false}
                          />
                        </div>
                        <div className="col-md-4 text-end">
                          <a className="text-secondary cursor"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8 mb-3">
                      <label className="font-size-14">Letter of confirmation</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"poDocument"} value={data.poDocument} error={errors.poDocument}
                            onChange={handleFile} isEditable={false}
                          />
                        </div>
                        <div className="col-md-4 text-end">
                          <a className="text-secondary cursor"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8 mb-3">
                      <label className="font-size-14">Add more</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"poDocument"} value={data.poDocument} error={errors.poDocument}
                            onChange={handleFile} isEditable={false}
                          />
                        </div>
                        <div className="col-md-4 text-end">
                          <a className="text-secondary cursor"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8 mb-3">
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"poDocument"} value={data.poDocument} error={errors.poDocument}
                            onChange={handleFile} isEditable={false}
                          />
                        </div>
                        <div className="col-md-4 text-end">
                          <a onClick={() => setSigndoc(true)} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />Sign document</a>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {tab === 3 ? (
                  <div className='px-5 py-4 pt-5'>
                    <div className="row">
                      <div className='w-100'>
                        <label className='font-wt-500 font-size-16'><u>Buyer Details</u>
                        </label>
                        <div className='row'>
                          {reviewForm.map((item) => {
                            return (
                              // <div className='col-5 p-0'>
                              //   <label className='col-6 lh-18 text-color-label font-size-14'>{item.name}</label>
                              //   <label className='lh-18 font-size-14'>:</label>
                              //   <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                              //     {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}
                              //   </label>
                              // </div>
                              <div className="col-md-6 pe-5">
                                <p className=" d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      <div className='w-100 mt-5'>
                        <label className='font-wt-500 font-size-16'><u>LC Details</u></label>
                        <div className='row'>
                          {reviewForm3.map((item) => {
                            return (
                              // <div className='col-5 p-0'>
                              //   <label className='col-6 lh-18 text-color-label font-size-14'>{item.name}</label>
                              //   <label className='lh-18 font-size-14'>:</label>
                              //   <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                              //     {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}
                              //   </label>
                              // </div>
                              <div className="col-md-6 pe-5">
                                <p className="d-flex d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      <div className='w-100 mt-5'>
                        <label className='font-wt-500 font-size-16'><u>Documents </u>
                        </label>
                        <div className='row mt-2'>

                          <div className="col-md-4 col-xl-4">
                            <label className="font-size-14">Draft LC</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"invoiceDocument"} value={data.invoiceDocument} error={errors.invoiceDocument}
                                  isEditable={false}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 col-xl-4">
                            <label className="font-size-14 ">Invoice</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"poDocument"} value={data.poDocument} error={errors.poDocument}
                                  isEditable={false}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 col-xl-4">
                            <label className="font-size-14">Purchase Order</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"invoiceDocument"} value={data.invoiceDocument} error={errors.invoiceDocument}
                                  isEditable={false}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 col-xl-4">
                            <label className="font-size-14">Letter of confirmation</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"invoiceDocument"} value={data.invoiceDocument} error={errors.invoiceDocument}
                                  isEditable={false}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='w-100 mt-5'>
                        <label className='font-wt-500 font-size-16'><u>Financier selected</u></label>
                        <div className="d-flex">
                          <div className="my-3 col-2 px-0">
                            <label className="font11">LC Confirmation</label>
                            <p
                              className="browse-div font-size-13 cursor text-center m-0 col-10 border-finance text-dark" >
                              ICICI bank
                            </p>
                          </div>
                          <div className="my-3 col-2 px-0">
                            <label className="font11">LC Discounting</label>
                            <p
                              className="browse-div font-size-13 cursor text-center m-0 col-10 border-finance text-dark" >
                              N/A
                            </p>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                ) : null}
                {tab === 4 ? (
                  <div className='p-5'>
                    <AdavancePaymentTab adavanceData={adavanceData} />
                  </div>
                ) : null}

                {tab === 4 && data.tcAccepted ? null : (
                  <div className="pb-5 px-5">
                    <button type="button"
                      onClick={() => tab === 4 ? setLimitinvoice(true) : handleValidation(undefined)}
                      className={`new-btn w-20 py-2 px-2 text-white`}>
                      {tab === 4 ? "Submit" : "Continue"}
                    </button>
                  </div>
                )}

              </div>
            </div>
          </main>
        </div>
      </div>
      {limitinvoice && <FinanceInvoiceModal limitinvoice={limitinvoice} setLimitinvoice={setLimitinvoice} closeSuccess={closeSuccess} >
        <img src={"/assets/images/upload_done.png"} alt="" className="mb-3" />
        <p className="mx-3">Amendment of “Non resource LC Discounting” for “Rosti Suzhou Integrated” has been done successfully.</p>
      </FinanceInvoiceModal>}

      {tooltip && <TooltipModal tooltip={tooltip} setTooltip={setTooltip}
        data={{ showRemark: data.showRemark, showLenderName: data.showLenderName }} />}

      {signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
        setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />}

    </>
  );
}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(AmendApplication)