import React, { useEffect, useState } from 'react';
import call from '../../service';
import { connect } from 'react-redux';
import { setShowCreateProfileAdminModal } from '../../store/actions/action';
import { ToastContainer } from 'react-toastify';
import SideBarV2 from '../partial/sideBarV2';
import HeaderV2 from '../partial/headerV2';
import toastDisplay from '../../utils/toastNotification';
import moment from 'moment';
import { displayRazorpay } from '../../utils/PaymentGateway';
import NewTablev2 from '../../utils/newTablev2';
import swal from 'sweetalert';
import FinanceInvoiceModal from '../InvoiceDiscounting/contract/components/financeinvoiceModal';
import { NewTable } from '../../utils/newTable';
import { printDiv } from '../../utils/myFunctions';
var converter = require('number-to-words');

let plans = [
  // { title: "Data secured by block chain", "backendAlt": "dataSecuredByBlockchain",  "alt": 'dataSecuredByBlockchain', subtitle: '', by: "month", perQuantity: 10, perQuantityInr: 850, unit: 'month', dontShowIncrementModal: true },
  // { title: "QMS", "backendAlt": 'qms', "alt": 'qms', subtitle: '(query management with contract partners)', by: "month", perQuantity: 4, perQuantityInr: 340, unit: 'month', dontShowIncrementModal: true },
  { title: "Commodity Manage", backendAlt: 'commodities', "alt": 'commodity', subtitle: '(select in multiple of 10)', by: "month", perQuantity: 10, perQuantityInr: 850, unit: 'month' },
  { title: "Commodity Contracts", backendAlt: 'trades', "alt": 'commodityContracts', subtitle: '(select in multiple of 10)', by: "month", perQuantity: 20, perQuantityInr: 1700, unit: 'month' },
  { title: "Shipment Contracts", backendAlt: 'shipmentContracts', "alt": 'shipmentContracts', subtitle: '(select in multiple of 10)', by: "month", perQuantity: 20, perQuantityInr: 1700, unit: 'month' },
  { title: "Partners", backendAlt: 'partners', "alt": 'partners', subtitle: '(select in multiple of 10)', by: "month", perQuantity: 10, perQuantityInr: 850, unit: 'month' }
]

let allPlans = [
  { title: "Data secured by block chain", noQuantity: true, "backendAlt": "dataSecuredByBlockchain", "alt": 'dataSecuredByBlockchain', subtitle: '', by: "month", perQuantity: 10, perQuantityInr: 850, unit: 'month', dontShowIncrementModal: true },
  { title: "QMS", noQuantity: true, "backendAlt": 'qms', "alt": 'qms', subtitle: '(query management with contract partners)', by: "month", perQuantity: 4, perQuantityInr: 340, unit: 'month', dontShowIncrementModal: true },
  { title: "Commodity Manage", backendAlt: 'commodities', "alt": 'commodity', subtitle: '(select in multiple of 10)', by: "month", perQuantity: 10, perQuantityInr: 850, unit: 'month' },
  { title: "Commodity Contracts", backendAlt: 'trades', "alt": 'commodityContracts', subtitle: '(select in multiple of 10)', by: "month", perQuantity: 20, perQuantityInr: 1700, unit: 'month' },
  { title: "Shipment Contracts", backendAlt: 'shipmentContracts', "alt": 'shipmentContracts', subtitle: '(select in multiple of 10)', by: "month", perQuantity: 20, perQuantityInr: 1700, unit: 'month' },
  { title: "Partners", backendAlt: 'partners', "alt": 'partners', subtitle: '(select in multiple of 10)', by: "month", perQuantity: 10, perQuantityInr: 850, unit: 'month' }
]


const ManagePlan = ({ userTokenDetails, channelPartnerUser, setShowCreateProfileAdminModal, showCreateProfileAdminModal, dispatch, navToggleState }) => {

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;
  const user_avatar = userTokenDetails.userAvatar ? userTokenDetails.userAvatar : null

  const [showLoader, setShowLoader] = useState(false)
  const [currentPlan, setCurrentPlan] = useState(null)
  const [activityData, setActivityData] = useState([])
  const [upcomingPlans, setUpcomingPlans] = useState([])
  const [transactionHistory, setTransactionHistory] = useState([])
  const [showInvoice, toggleShowInvoice] = useState({ show: false, data: {} })
  const [upcomingPlanPopup, setUpcomingPlanPopup] = useState({ show: false, data: {} })

  useEffect(() => {
    getPlanManagerData()
  }, [])

  async function getPlanManagerData() {
    setShowLoader(true)
    let apiResp = await call('POST', 'getPlanManagerData', { userId })
    setShowLoader(false)
    setCurrentPlan(apiResp.currentPlan)
    setActivityData(apiResp.activites)
    setUpcomingPlans(apiResp.upcomingPlans)
    setTransactionHistory(apiResp.transactionHistory)
  }

  // console.log("uuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu", upcomingPlanPopup.data);

  return (
    <div className="">
      {upcomingPlanPopup.show ?
        <FinanceInvoiceModal modalSize={'lg'} limitinvoice={upcomingPlanPopup.show} closeSuccess={() => {
          setUpcomingPlanPopup({ show: false })
        }}>
          <div className='text-center'>
            <img src='/assets/images/Questionmark.png' />
            <p className='font-size-14 font-wt-600 mt-2'>Kindly confirm the activation of your plan.</p>
            <label className='font-size-14 font-wt-500 mt-2'>Your current plan, which includes the items listed below, will expire immediately, and the upcoming plan will be activated starting today. For more details, please find the summary of both plans below:</label>
          </div>
          <div className='row d-flex mt-4' >
            <div className='col-6' >
              <label className='font-size-14 font-wt-600'>Current Plan (going to expire now)</label>
              <NewTablev2
                disableAction={true}
                columns={[{ subColumns: "Service", subColumnStyle: { width: '70%' } },
                { subColumns: "Left Usage", subColumnStyle: { width: '30%' } }
                ]}
              >
                {currentPlan["dataSecuredByBlockchain"] ?
                  <tr>
                    <td
                    >
                      <label
                        className='font-size-12 font-wt-400 text-break'
                      >{allPlans[0]["title"]}</label>
                    </td>
                    <td><label
                      className='font-size-12 font-wt-400 text-break '
                    >{"Till plan expiry"}</label></td>
                  </tr>
                  : null}
                {currentPlan["qms"] ?
                  <tr>
                    <td
                    >
                      <label
                        className='font-size-12 font-wt-400 text-break'
                      >{allPlans[1]["title"]}</label>
                    </td>
                    <td><label
                      className='font-size-12 font-wt-400 text-break'
                    >{"Till plan expiry"}</label></td>
                  </tr>
                  : null}
                {currentPlan ? plans.map((i, j) => {
                  if (currentPlan[i.backendAlt]) {
                    return (
                      <tr>
                        <td
                        >
                          <label
                            className='font-size-12 font-wt-400 text-break'
                          >{i.title}</label>
                        </td>
                        <td><label
                          className='font-size-12 font-wt-400 text-break text-right'
                        >{currentPlan[i.backendAlt]?.["limitLeft"] || 0}</label></td>
                      </tr>
                    )
                  }
                }) : null}
              </NewTablev2>
            </div>
            <div className='col-6' >
              <label className='font-size-14 font-wt-600'>Upcoming Plan (going to activate now)</label>
              <NewTablev2
                disableAction={true}
                columns={[{ subColumns: "Service", subColumnStyle: { width: '70%' } },
                { subColumns: "Quantity", subColumnStyle: { width: '30%' } }
                ]}
              >
                {upcomingPlanPopup.data?.subscriptionDetails?.length ?
                  upcomingPlanPopup.data?.subscriptionDetails.map((i, j) => {
                    return (
                      <tr>
                        <td
                        >
                          <label
                            className='font-size-12 font-wt-400 text-break'
                          >{i.name}</label>
                        </td>
                        <td><label
                          className='font-size-12 font-wt-400 text-break '
                        >{(i.alt === "dataSecuredByBlockchain" || i.alt === "qms") ? "Till plan expiry" : i.quantity}</label></td>
                      </tr>
                    )
                  })
                  : null}
              </NewTablev2>
            </div>
          </div>
          <div className='row d-flex mt-4 justify-content-end px-3' >
            <button type="button"
              onClick={() => {
                setUpcomingPlanPopup({ show: false })
              }}
              class={` enablesigncontract bg-transparent border2px-5CB8D3 text-color1 w-20`}>
              {"Cancel"}
            </button>
            <button type="button"
              onClick={async () => {
                setShowLoader(true)
                await call('POST', 'activatePlanById', { planId: upcomingPlans[0].id, userId, userEmail })
                toastDisplay("Plan activated successfully", "success",
                  () => {
                    window.location.reload()
                  })
              }}
              class={`border-0 text-white enableQuotebtn w-20 ml-3`}>
              {"Activate Now"}
            </button>
          </div>
        </FinanceInvoiceModal> : null}
      {showInvoice.show ? (
        <FinanceInvoiceModal modalSize={'xl'} limitinvoice={showInvoice.show} closeSuccess={() => {
          toggleShowInvoice({ show: false })
        }}><>
            <div className='d-flex flex-row justify-content-end mb-3'>
              <button type="button"
                onClick={() => printDiv("printCommissionInvoice", `Tradereboot Plan Invoice - ${showInvoice.data.transactionId}`, [])}
                className={` border-0 mb-2 text-white enableQuotebtn`}>
                {"Print Invoice"}
              </button>
            </div>
            <div id={"printCommissionInvoice"} className='px-4 pt-3'>
              <p className='font-size-18 font-wt-800 text-center' >Invoice</p>
              <div className='d-flex flex-row ' >
                <div
                  style={{
                    "border": "1px solid black"
                  }}
                  className='col-6' >
                  <p className='text-color-value font-size-16 font-wt-800 text-left m-1'>Tradereboot Fintech Pvt. Ltd.</p>
                  <p className='text-color-value font-size-14 font-wt-400 text-left'>
                    410,Atlanta Estate,Near Virvani Estate<br />
                    W.E.Highway,Goregaon Mulund Link Road,<br />
                    Goregaon(E),Mumbai - 400063<br />
                    Contact : 8291474760<br />
                    Maharashtra ( Code - 27)<br />
                    GSTIN/UIN: 27AACI3158R1ZX<br />
                    State Name :  Maharashtra, Code : 27<br />
                    E-Mail : info@tradereboot.com<br />
                    CIN :- U74999MH2019PTC327078
                  </p>
                </div>
                <div
                  style={{
                    "border": "1px solid black"
                  }}
                  className='col-6' >
                  <div style={{ borderBottom: '1px solid black' }} className='row' >
                    <div style={{ borderRight: '1px solid black' }} className='col-6 py-2' >
                      <p className='font-size-14 font-wt-400 m-0'>{`Invoice No.`}</p>
                      <p className='font-size-14 font-wt-600 m-0'>{showInvoice.data.transactionId}</p>
                    </div>
                    <div className='col-6 py-2' >
                      <p className='font-size-14 font-wt-400 m-0'>{`Dated`}</p>
                      <p className='font-size-14 font-wt-600 m-0'>{moment(showInvoice.data.createdAt).format("YYYY-MM-DD")}</p>
                    </div>
                  </div>
                  <div style={{ borderBottom: '1px solid black' }} className='row' >
                    <div style={{ borderRight: '1px solid black' }} className='col-6 py-2' >
                      <p className='font-size-14 font-wt-400 m-0'>{`Plan Valid From`}</p>
                      <p className='font-size-14 font-wt-600 m-0'>{moment(showInvoice.data.startsOn).format("YYYY-MM-DD")}</p>
                    </div>
                    <div className='col-6 py-2' >
                      <p className='font-size-14 font-wt-400 m-0'>{`Plan Valid To`}</p>
                      <p className='font-size-14 font-wt-600 m-0'>{moment(showInvoice.data.expiredOn).format("YYYY-MM-DD")}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='px-3 py-1'
                style={{ border: '1px solid black' }}
              >
                <label className='font-size-14 font-wt-400 m-0'>Bill to</label><br />
                <label className='font-size-14 font-wt-600 m-0'>{userName}</label><br />
              </div>
              <div style={{ border: '1px solid black' }}>
                <NewTablev2
                  disableAction={true}
                  columns={[{ subColumns: "Sr. No.", subColumnStyle: { width: '15%' } },
                  { subColumns: "Item", subColumnStyle: { width: '55%' } },
                  { subColumns: "Quantity", subColumnStyle: { width: '15%' } },
                  { subColumns: "Amount", subColumnStyle: { width: '15%' } }
                  ]}>
                  {showInvoice.data.subscriptionDetails.map((i, j) => {
                    return (
                      <tr>
                        <td><label className='font-size-12 font-wt-500 text-break' >{j + 1}</label></td>
                        <td><label className='font-size-12 font-wt-500 text-break' >{i.name}</label></td>
                        <td><label className='font-size-12 font-wt-500 text-break'>{i.quantity}</label></td>
                        <td><label className='font-size-12 font-wt-500 text-break'>{`${i.unit === "USD" ? '$' : '₹'} ${i.amount}`}</label></td>
                      </tr>)
                  })}
                </NewTablev2>
              </div>
              <div style={{ border: '1px solid black' }} className='d-flex justify-content-between px-2 pt-2 mx-0' >
                <label className='font-size-14 font-wt-500'>Total</label>
                <label className='font-size-14 font-wt-600'>{`${showInvoice.data.unit} ${showInvoice.data.amt}`}</label>
              </div>
              <p className='font-size-14 font-wt-600 text-center w-100'>This is a Computer Generated Invoice</p>
            </div>
          </>
        </FinanceInvoiceModal>
      ) : null}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="Manageplan" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Manage Plan"}
              userTokenDetails={userTokenDetails} />
            <div
              className='card'
            >
              <div className='row d-flex' >
                <div
                  style={{ paddingTop: '2rem', paddingLeft: '2rem' }}
                  className='col-8 pl-5 pt-5' >
                  <div style={{
                    borderRadius: '10px',
                    border: '1px solid #5CB8D3'
                  }} className='d-flex row align-items-center px-0 py-2 m-0' >
                    {currentPlan ? plans.map((i, j) => {
                      return (
                        <div className='w-25 pl-3'
                          style={j != plans.length - 1 ? { borderRight: '1px solid #D5D5D5' } : {}}
                        >
                          {currentPlan[i.backendAlt] ?
                            <div >
                              <label className='font-size-22 font-wt-600 colorFE4141 m-0 p-0' >{currentPlan[i.backendAlt]["limitLeft"]}</label>
                              <label className='font-size-15 font-wt-500 m-0 p-0'>{`/${currentPlan[i.backendAlt]["limitLeft"] + currentPlan[i.backendAlt]["limitUsed"]}`}</label>
                              <label className='font-size-14 font-wt-400 text-secondary m-0 p-0 ml-1'>{`(left)`}</label>
                            </div> : <label onClick={() => {
                              window.location = '/Purchaseplan?topup=true'
                            }} className='font-size-14 font-wt-600 m-0 p-0 text-color1 cursor'>Top Up</label>}
                          <div>
                            <label
                              className='font-size-12 font-wt-400'
                            >{i.title}</label>
                          </div>
                        </div>
                      )
                    }) : <label className='font-size-14 font-wt-500' >No active current plan</label>}

                  </div>
                  <div className='my-4' >
                    <lable className="font-size-14 font-wt-400" >Activity</lable>
                    <div className='mt-2' >
                      <NewTablev2
                        columns={[{ subColumns: "Date", subColumnStyle: { width: '30%' } },
                        { subColumns: "Purpose", subColumnStyle: { width: '60%' } },
                        { subColumns: "Left", subColumnStyle: { width: '10%' } }]}>
                        {activityData.map((i, j) => {
                          return (
                            <tr>
                              <td><label className='font-size-12 font-wt-500 text-break' >{moment(i.createdAt).format("YYYY/MM/DD hh:mm a")}</label></td>
                              <td><label className='font-size-12 font-wt-500 text-break' >{allPlans.filter((m, n) => {
                                if (m.backendAlt === i.purpose) {
                                  return true
                                }
                              })?.[0]?.title || "NA"}</label></td>
                              <td><label className='font-size-12 font-wt-500 text-break' >{i.leftPoints}</label></td>
                            </tr>
                          )
                        })}
                      </NewTablev2>
                    </div>
                  </div>

                  <div className='my-4' >
                    <lable className="font-size-14 font-wt-400 " >Transaction History</lable>
                    <div className='mt-2' >
                      <NewTablev2
                        columns={[{ subColumns: "Date", subColumnStyle: { width: '15%' } },
                        { subColumns: "Products", subColumnStyle: { width: '40%' } },
                        { subColumns: "Mode of Payment", subColumnStyle: { width: '15%' } },
                        { subColumns: "Amount", subColumnStyle: { width: '10%' } },
                        { subColumns: "Status", subColumnStyle: { width: '12%' } },
                        { subColumns: "Invoice", subColumnStyle: { width: '10%' } }
                        ]}>
                        {transactionHistory.map((i, j) => {
                          let unit = i.subscriptionDetails[0]["unit"] === 'USD' ? "$" : '₹'
                          let amt = i.subscriptionDetails.reduce((accumulator, item) => accumulator + item.amount, 0);
                          return (
                            <tr>
                              <td><label className='font-size-12 font-wt-500 text-break' >{moment(i.createdAt).format('YYYY/MM/DD')}</label></td>
                              <td><label className='font-size-12 font-wt-500 text-break' >{
                                i.subscriptionDetails.map((d, k) => {
                                  return `${d.name} ${k != i.subscriptionDetails.length - 1 ? ', ' : ''}`
                                })}</label></td>
                              <td><label className='font-size-12 font-wt-500 text-break' >{i.modeOfPayment}</label></td>
                              <td><label className='font-size-12 font-wt-500 text-break' >{`${unit} ${amt}`}</label></td>
                              <td><label className={`font-size-12 font-wt-600 text-break
                              ${i.status === 2 ? " text-48DA87 " : ' text-danger '}
                              `} >{`${i.status === 2 ? "Success" : "Failed"}`}</label></td>
                              <td>
                                <img
                                  onClick={() => {
                                    if (i.status === 2) {
                                      toggleShowInvoice({ show: true, data: { ...i, amt, unit } })
                                    }
                                    else {
                                      toastDisplay("Fail to generate invoice", "error")
                                    }
                                  }}
                                  className='cursor ml-3'
                                  src={"assets/images/eye.png"} alt='' />
                              </td>
                            </tr>
                          )
                        })}
                      </NewTablev2>
                    </div>
                  </div>

                </div>
                <div className='col-4' >
                  <div className='card p-3' >
                    {currentPlan ?
                      <div className='' >
                        <div className='d-flex' >
                          <label className='text-color1 font-size-16 font-wt-500' >Current Plan</label>
                          <label className='colorFE4141 font-size-16 font-wt-600 ml-auto'>{`${moment(currentPlan.expiredOn).diff(moment(), "days")} Days Left`}</label>
                        </div>
                        <label
                          className='font-size-14 font-wt-500 my-3'
                        ><u>Plan Includes</u></label>
                        {allPlans.map((i, j) => {
                          if (currentPlan[i.backendAlt]) {
                            return (
                              <div className='d-flex' >
                                <label className='font-size-14 font-wt-400' >{i.title}</label>
                                {i.noQuantity ? null :
                                  <label className='font-size-14 font-wt-500 ml-auto'>{currentPlan[i.backendAlt]["limitLeft"] + currentPlan[i.backendAlt]["limitUsed"]}</label>}
                              </div>
                            )
                          }
                        })}
                        <div className='d-flex justify-content-between my-4' >
                          <button type="button"
                            onClick={() => {
                              window.location = '/Purchaseplan?topup=true'
                            }}
                            class={` enablesigncontract bg-transparent border2px-5CB8D3 text-color1 w-48`}>
                            {"Top Up"}
                          </button>
                          <button type="button"
                            onClick={() => {
                              window.location = '/Purchaseplan'
                            }}
                            class={`border-0 text-white enableQuotebtn w-48`}>
                            {"New Plan"}
                          </button>
                        </div>
                      </div> : <label className='font-size-14 font-wt-500' >No active current plan</label>}
                  </div>
                  <div className='card p-3' >
                    <div className='' >
                      <label
                        className='font-size-14 font-wt-500 my-3'
                      ><u>Upcoming Plans</u></label>
                      <div >
                        {upcomingPlans.map((i, j) => {
                          let unit = i.subscriptionDetails[0]["unit"] === 'USD' ? "$" : '₹'
                          let amt = i.subscriptionDetails.reduce((accumulator, item) => accumulator + item.amount, 0);
                          return (
                            <div className='card p-3 mb-3' >
                              <div className='d-flex row' >
                                <div className='col-6' >
                                  <label className='w-100 font-size-14 font-wt-600 m-0 p-0' >{moment(i.startsOn).format('YYYY-MM-DD')}</label>
                                  <label className='w-100 font-size-13 font-wt-400 m-0 p-0'>Starting from</label>
                                </div>
                                <div className='col-6'>
                                  <label className='w-100 font-size-14 font-wt-600 m-0 p-0 text-right' >{`${unit} ${amt}`}</label>
                                  <label className='w-100 font-size-13 font-wt-400 m-0 p-0 text-right'>Amount</label>
                                </div>
                                <p className=' font-size-14 font-wt-400 my-3' >
                                  {i.subscriptionDetails.map((d, k) => {
                                    return `${d.name} ${k != i.subscriptionDetails.length - 1 ? ', ' : '. '}`
                                  })}
                                </p>
                              </div>
                              <div className='d-flex row m-0 p-0' >
                                <button type="button"
                                  onClick={() => {
                                    if (j != 0) {
                                      toastDisplay("Only 1st upcoming plan can be activated", "info")
                                    }
                                    else {
                                      setUpcomingPlanPopup({ show: true, data: i })
                                      // swal({
                                      //   title: "Please Confirm",
                                      //   html: `The previous plan will be deactivated, and this new plan will be activated starting today.`,
                                      //   icon: "warning",
                                      //   buttons: ["No", "Yes"],
                                      //   dangerMode: true,
                                      // })
                                      //   .then(async (yes) => {
                                      //     if (yes) {
                                      //       setShowLoader(true)
                                      //       await call('POST', 'activatePlanById', { planId: i.id, userId })
                                      //       toastDisplay("Plan activated successfully", "success",
                                      //         () => {
                                      //           window.location.reload()
                                      //         })
                                      //     }
                                      //   });
                                    }
                                  }}
                                  class={` enablesigncontract bg-transparent border2px-5CB8D3 text-color1 w-80`}>
                                  {"Activate Plan"}
                                </button>
                                <div className={'w-20'} >
                                  <img className='cursor ml-3' src='assets/images/invoice_icon.png' />
                                </div>
                              </div>
                            </div>
                          )
                        })}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>

  )
}

const mapStateToProps = state => {
  return {
    showCreateProfileAdminModal: state.showCreateProfileAdminModal,
    navToggleState: state.navToggleState
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setShowCreateProfileAdminModal: (flag, data) => { dispatch(setShowCreateProfileAdminModal({ modal: flag, info: data })) },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagePlan)