import React, { PureComponent } from 'react'
import { ResponsiveContainer, XAxis, YAxis, Tooltip, Bar, BarChart, AreaChart, Area } from 'recharts'

class CustomAreaChart extends PureComponent {
  render() {
    console.log('render', this.props)
    return (
      <ResponsiveContainer width={"100%"} height={300}>
        <AreaChart
          width={500}
          height={300}
          data={this.props.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}

        >
          <XAxis dataKey={this.props.xDataKey} tickFormatter={this.props.XFormatter} />
          <YAxis tickFormatter={this.props.YFormatter} />
          <Tooltip formatter={this.props.formatterFunction} />
          <defs>
            <linearGradient id="fillcolor" x1="0" y1="0" x2="0" y2="1">
              <stop offset={"20%"} stopColor={this.props.fillColor ? this.props.fillColor : "#5CB8D3"} stopOpacity={0.8} />
              <stop offset={"40%"} stopColor={this.props.fillColor ? this.props.fillColor : "#5CB8D3"} stopOpacity={0.6} />
              <stop offset={"60%"} stopColor={this.props.fillColor ? this.props.fillColor : "#5CB8D3"} stopOpacity={0.4} />

            </linearGradient>
          </defs>
          <defs>
            <linearGradient id="fillcolor2" x1="0" y1="0" x2="0" y2="1">
              <stop offset={"20%"} stopColor={"#FDD775"} stopOpacity={0.8} />
              <stop offset={"40%"} stopColor={"#FDD775"} stopOpacity={0.6} />
              <stop offset={"60%"} stopColor={"#FDD775"} stopOpacity={0.4} />

            </linearGradient>
          </defs>
          <Area type="linear" dataKey={this.props.dataKey} stroke={this.props.fillColor ? this.props.fillColor : "#5CB8D3"} fill={this.props.fillColor ? "url(#fillcolor2)" : "url(#fillcolor)"} strokeWidth={"2px"} activeDot={{ r: 8 }} dot={{ stroke: this.props.fillColor ? this.props.fillColor : "#5CB8D3", strokeWidth: 5, fill: this.props.fillColor ? this.props.fillColor : "#5CB8D3" }} />
        </AreaChart>
      </ResponsiveContainer>
    )
  }
}

export default CustomAreaChart