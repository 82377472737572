import React, { useEffect, useState } from "react";
import { Action } from "../components/myCounterPartComp/action";
import { NewInput } from "./newInput";
import toastDisplay from "./toastNotification";
import { useRef } from "react";

export const NewTable = ({ hideRowBorderDefault, columns, data, options, disableAction, tableContainsNotification, tableSubColumnWith, filterData, setFilterData, filteredSearch, setFilteredSearch }) => {

  const box2 = useRef();

  const [action, setAction] = useState({ show: false, index: null })
  const [filterPopup, toggleFilterPopup] = useState({})

  const setFilterDataOnChange = (filterName, filterDataIndex, item) => {
    let temp = JSON.parse(JSON.stringify(filterData))
    let temp2 = JSON.parse(JSON.stringify(filteredSearch))

    let indexTemp1
    temp[filterName]["data"].forEach((val, index) => {
      if (val.name === item.name) {
        indexTemp1 = index
      }
    })
    temp[filterName]["data"][indexTemp1]["isChecked"] = !temp[filterName]["data"][indexTemp1]["isChecked"]
    temp2[filterName]["data"][filterDataIndex]["isChecked"] = !temp2[filterName]["data"][filterDataIndex]["isChecked"]
    temp2[filterName]["isAllChecked"] = false
    temp[filterName]["isAllChecked"] = false
    console.log('temppppp', temp, temp2, temp2[filterName]["data"][filterDataIndex]);
    let isFilterActive = false
    for (let index = 0; index < temp[filterName]["data"].length; index++) {
      const element = temp[filterName]["data"][index];
      if (element.isChecked) {
        isFilterActive = true
      }
    }
    let isFilterActive2 = false

    for (let index = 0; index < temp2[filterName]["data"].length; index++) {
      const element = temp2[filterName]["data"][index];
      if (element.isChecked) {
        isFilterActive2 = true
      }
    }
    temp[filterName]["isFilterActive"] = isFilterActive
    temp2[filterName]["isFilterActive"] = isFilterActive2
    setFilterData({ ...temp })
    setFilteredSearch({ ...temp2 })
  }

  const handleAllFilterStatus = (filterName) => {
    let temp = filterData
    let temp2 = filteredSearch
    let isFilterActive = false


    for (let index = 0; index < temp2[filterName].data.length; index++) {
      const element = temp2[filterName]["data"][index];
      element["isChecked"] = !temp2[filterName]["isAllChecked"]
      if (element["isChecked"]) {
        isFilterActive = true
      }
    }

    temp[filterName]["isFilterActive"] = isFilterActive
    temp[filterName]["isAllChecked"] = !temp2[filterName]["isAllChecked"]

    temp2[filterName]["isFilterActive"] = isFilterActive
    temp2[filterName]["isAllChecked"] = !temp2[filterName]["isAllChecked"]
    setFilterData({ ...temp })
    setFilteredSearch({ ...temp2 })
  }

  useEffect(() => {
    if (Object.keys(filterPopup || {}).length) {
      document.addEventListener('mousedown', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [filterPopup])

  const handleOutsideClick = (event) => {
    console.log("bbbbbbbbbbbbbbbbbbb", event.target, box2.current);
    let eventTargetStr = event?.target?.outerHTML
    if (box2 && box2.current && !box2.current.contains(event.target)) {
      let temp = Object.keys(filterPopup || {})
      let tempFilterPopup = filterPopup
      for (let index = 0; index < temp.length; index++) {
        const element = temp[index];
        tempFilterPopup[element] = false
      }
      toggleFilterPopup({ ...tempFilterPopup })
    }
  }
  return (
    <table
      className='tablev4'>
      <tr className='th'>
        {columns.map((item, index) => {
          return (
            <th
              key={index}
              style={{
                height: '3.2rem', width: 'auto',
                alignItems: !item.subColumns ? "center" : 'baseline'
              }}
              className={`position-relative font-wt-600 text-color-value font-size-13 pl-2 ${index == 0 ? 'pl-3' : ''}`}>
              {item.name}
              {filterPopup[item.filterDataKey] ? (
                <div
                  ref={box2}
                  className={'position-absolute bg-white sortPopup px-3'}
                  style={{
                    width: '20rem',
                    zIndex: 10,
                    top: 0,
                  }}
                >
                  <div className="py-2 border-bottom-eeeeee d-flex flex-row gap-2 justify-content-between">
                    <div>
                      {item.name}
                      <img
                        style={{ "transform": "rotate(180deg)" }}
                        onClick={() => {
                          toggleFilterPopup({ [item.filterDataKey]: false })
                        }}
                        className='mx-2 cursor' src={"assets/images/header/down_arrow.png"} />
                    </div>

                    {filteredSearch?.[item?.filterDataKey]?.data?.length ? (
                      <div>
                        <img
                          onClick={() => handleAllFilterStatus(item.filterDataKey)}
                          className='cursor' src={`assets/images/${filteredSearch[item.filterDataKey]["isAllChecked"] ? 'checked-green' : 'empty-check'}.png`} />
                      </div>
                    ) : null}
                  </div>
                  <div>
                    {item?.sort?.length ? item.sort.map((m, n) =>
                      <div
                        key={n}
                        onClick={() => { m.onActionClick(); toggleFilterPopup({ [item.filterDataKey]: false }) }}
                        className="pt-1 border-bottom-eeeeee cursor">
                        <label className={`${m.selected ? 'text-color1 font-wt-600 cursor' : 'text-color-label cursor'}`}>{m.name}</label>
                      </div>) : null}
                    {filterData?.[item?.filterDataKey]?.data?.length ?
                      <div>
                        <NewInput name={"search"} label={"Search"} onChange={(e) => {
                          setTimeout(() => {
                            if (e.target.value) {
                              console.log(filteredSearch);
                              let key = filteredSearch[item.filterDataKey]["labelName"]
                              const filtereddata = filterData?.[item?.filterDataKey]?.data.filter(item => item[key]?.toLowerCase().includes(e.target.value?.toLowerCase()))
                              setFilteredSearch({
                                ...filteredSearch,
                                [item?.filterDataKey]: {
                                  ...filteredSearch[item?.filterDataKey],
                                  data: filtereddata
                                }
                              })
                            } else {
                              setFilteredSearch(filterData)
                            }
                          }, 500);
                        }} />
                      </div>
                      : null
                    }
                    <div style={{
                      overflow: 'auto',
                      height: 'auto',
                      maxHeight: "15rem"
                    }}>

                      {filteredSearch?.[item?.filterDataKey]?.data?.length ? filteredSearch?.[item?.filterDataKey]?.data.map((i, j) => {
                        return (
                          <div className="mt-2" key={j}>
                            <img
                              onClick={() => setFilterDataOnChange(item.filterDataKey, j, i)}
                              className='cursor' src={`assets/images/${i.isChecked ? 'checked-green' : 'empty-check'}.png`} />
                            <label className="ml-2" >{i[filteredSearch[item.filterDataKey]["labelName"]]}</label>
                          </div>
                        )
                      }) : null}
                    </div>
                  </div>
                </div>
              ) : null}
              {item.filter ? (
                <img
                  onClick={() => {
                    console.log('FilteredSearch', filteredSearch, item.filterDataKey);
                    if (filteredSearch?.[item.filterDataKey]?.data?.length || item.sort?.length) {
                      toggleFilterPopup({ [item.filterDataKey]: !filterPopup[item.filterDataKey] })
                    }
                    else {
                      toastDisplay("Data not available for filter", "info")
                    }
                  }}
                  className='mx-2 cursor' src={"assets/images/header/down_arrow.png"} />
              ) : null}
              {item.subColumns ? (
                <div className="tableSubColumns"
                  style={tableSubColumnWith ? { width: `${tableSubColumnWith}` } : {}}
                >
                  {item.subColumns.map(i => (
                    <div>{i}</div>
                  ))}
                </div>
              ) : null}
            </th>)
        })}
      </tr>
      {
        data.length ? data.map((item, index) => {
          return (
            <>
              <tr
                key={index}
                style={item.length == 100 || (hideRowBorderDefault && item.length != 101) ? { borderBottom: 0 } : { borderBottom: "1px solid #EEEEEE" }}
                className={`bg-white position-relative`}
              >
                {
                  item.length ? item.map((item, j) => {
                    if (j != 99 && j != 100) {
                      return (
                        <td
                          key={j}
                          style={{ maxWidth: '10rem' }}
                          className={`font-wt-600 text-color-label font-size-13 pl-2 text-break ${j / 1 == 0 ? 'pl-3' : ''} pr-0 py-2 `}>{item}</td>
                      )
                    }
                  }) : null
                }
                {disableAction ? null : (
                  <td
                    // style={{ width: `${100 / columns.length}%` }}
                    style={{ width: '4rem', justifyContent: 'center', alignItems: 'center', display: 'flex' }}
                    className=''>
                    <i className="fa fa-ellipsis-v cursor mt-2 ml-2"
                      onClick={() => setAction({ show: true, index })}
                      aria-hidden="true"></i>
                    {action.show && action.index === index ? (
                      <Action
                        id={index}
                        onDismiss={() => setAction({ show: false, index })}
                        options={options} />
                    ) : null}
                  </td>
                )}
              </tr>
              {item.length == 100 ? (
                <tr
                  style={{ height: '2rem', borderBottom: "1px solid #EEEEEE" }}
                  className="position-relative">
                  <div style={{ zIndex: 10 }} className="position-absolute">
                    {item[99]}
                  </div>
                </tr>
              ) : null}
            </>
          )
        })
          :
          // <tr className="w-100 py-4 text-center">
          //   <label className="font-size-16 font-wt-600">No Data Found</label>
          // </tr>
          <tr
            style={{ height: '2.5rem', borderBottom: "1px solid #EEEEEE", left: "45%" }}
            className="position-relative">
            <div style={{ zIndex: 10 }} className="position-absolute">
              <label className="font-size-13 font-wt-600">No Data Found</label>
            </div>
          </tr>
      }
    </table >
  )
}