import moment from 'moment'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { connect } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import call from '../../service'
import { NewTable } from '../../utils/newTable'
import Filter from '../InvoiceDiscounting/components/Filter'
import Pagination from '../InvoiceDiscounting/contract/components/pagination'
import HeaderV2 from '../partial/headerV2'
import SideBarV2 from '../partial/sideBarV2'
import ExportersTab from '../UserOnboard/ExportersTab'
import UserDetails from '../UserOnboard/UserDetails'
import ChannelPartnerDetails from '../UserOnboard/ChannelPartnerDetails'
import toastDisplay from '../../utils/toastNotification'
import { ExportExcel, getInitials, insertObjectInArray } from '../../utils/myFunctions'
import { NewInput, NewTextArea } from '../../utils/newInput'
import { Area, AreaChart, Bar, BarChart, Funnel, Legend, Line, FunnelChart, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis, Cell } from 'recharts';
import PieChartComponent from '../Reports/components/PieChartComponent'
import SubAdminPopup from '../adminNewUI/SubAdminPopup'

// const salesPerson = [
//   "Nishi",
//   "Fiza",
//   "Manju",
//   "Dhruvi"
// ]

const lanesColor = ['#E8AF7B', '#98BCDE', '#FDB601', '#F887E0']

let applicationStageFunnelGraphColors = ["#FF774D", "#FFA64C", "#F26191", "#CC66FF", "#39BF3F"]

let inactiveUserFunnelGraphColors = ["#FF774D", "#FFA64C", "#F26191", "#CC66FF", "#39BF3F"]

const TaskManager = ({ userTokenDetails, navToggleState, renderAsChildren, hideGraphs, showForThisUser, changedFilterData, setChangedFilterData, leadStatusIndex, applicationStatusIndex }) => {

  let todayDateObj = moment()
  let lastMonthDateObj = moment().subtract("1", "months")

  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10 })
  const [filterData, setFilterData] = useState({})
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [dbData, setdbData] = useState([])
  const [transactionPopup, toggleTransactionPopup] = useState({ show: false, data: [] })
  const [showLoader, setshowLoader] = useState(false)
  const [viewDetails, setViewDetails] = useState({
    type: '',
    isVisible: false,
    data: {}
  })
  const [overalldata, setoveralldata] = useState([])
  const [salesPerson, setSalesPerson] = useState([])
  const [notePopup, toggleNotePopup] = useState({ show: false, data: "", selectedIndex: null, noteFor: "" })
  const [statsdata, setstatsdata] = useState({})
  const [graphConfiguration, setGraphConfiguration] = useState({
    applicationStageGraphMode: true,
    applicationStageFrom: lastMonthDateObj.clone().format("YYYY-MM-DD"),
    applicationStageTo: todayDateObj.clone().format("YYYY-MM-DD"),
    newUserGraphMode: true,
    newUserSummaryFrom: lastMonthDateObj.clone().format("YYYY-MM-DD"),
    newUserSummaryTo: todayDateObj.clone().format("YYYY-MM-DD")
  })
  const [graphData, setGraphData] = useState({})
  const [filteredSearch, setFilteredSearch] = useState([])
  const [subadminPopup, togglesubadminPopup] = useState({ data: [], show: false, userId: '' })

  const userPermissionsForSubAdmin = JSON.parse(userTokenDetails.UserAccessPermission || "{}")
  const userId = userTokenDetails?.user_id
  let onlyShowForUserId = userPermissionsForSubAdmin?.mainAdmin ? undefined : userId

  useEffect(() => {
    console.log('changedFilterData', leadStatusIndex);
    if (leadStatusIndex === 0) {
      let temp = filterData
      temp["Lead Status"]["data"][0]["isChecked"] = true
      temp["Lead Status"]["data"][1]["isChecked"] = false
      temp["Lead Status"]["isFilterActive"] = true
      setFilterData({ ...temp })
      let temp2 = filteredSearch
      temp2["Lead Status"]["data"][0]["isChecked"] = true
      temp2["Lead Status"]["data"][1]["isChecked"] = false
      temp2["Lead Status"]["isFilterActive"] = true
      setFilteredSearch({ ...temp2 })
    } else if (leadStatusIndex === 1) {
      let temp = filterData
      temp["Lead Status"]["data"][0]["isChecked"] = false
      temp["Lead Status"]["data"][1]["isChecked"] = true
      temp["Lead Status"]["isFilterActive"] = true
      let temp2 = filteredSearch
      temp2["Lead Status"]["data"][0]["isChecked"] = false
      temp2["Lead Status"]["data"][1]["isChecked"] = true
      temp2["Lead Status"]["isFilterActive"] = true
      setFilteredSearch({ ...temp2 })
      setFilterData({ ...temp })
    }
  }, [leadStatusIndex])
  useEffect(() => {
    if (applicationStatusIndex === 0) {
      let temp = filterData
      temp["Application Status"]["data"][0]["isChecked"] = true
      temp["Application Status"]["data"][1]["isChecked"] = false
      temp["Application Status"]["data"][2]["isChecked"] = false
      temp["Application Status"]["isFilterActive"] = true
      setFilterData({ ...temp })
      let temp2 = filteredSearch
      temp2["Application Status"]["data"][0]["isChecked"] = true
      temp2["Application Status"]["data"][1]["isChecked"] = false
      temp2["Application Status"]["data"][2]["isChecked"] = false
      temp2["Application Status"]["isFilterActive"] = true
      setFilteredSearch({ ...temp2 })
    } else if (applicationStatusIndex === 1) {
      let temp = filterData
      temp["Application Status"]["data"][0]["isChecked"] = false
      temp["Application Status"]["data"][1]["isChecked"] = true
      temp["Application Status"]["data"][2]["isChecked"] = false
      temp["Application Status"]["isFilterActive"] = true
      setFilterData({ ...temp })
      let temp2 = filteredSearch
      temp2["Application Status"]["data"][0]["isChecked"] = false
      temp2["Application Status"]["data"][1]["isChecked"] = true
      temp2["Application Status"]["data"][2]["isChecked"] = false
      temp2["Application Status"]["isFilterActive"] = true
      setFilteredSearch({ ...temp2 })
    } else if (applicationStatusIndex === 2) {
      let temp = filterData
      temp["Application Status"]["data"][0]["isChecked"] = false
      temp["Application Status"]["data"][1]["isChecked"] = false
      temp["Application Status"]["data"][2]["isChecked"] = true
      temp["Application Status"]["isFilterActive"] = true
      setFilterData({ ...temp })
      let temp2 = filteredSearch
      temp2["Application Status"]["data"][0]["isChecked"] = false
      temp2["Application Status"]["data"][1]["isChecked"] = false
      temp2["Application Status"]["data"][2]["isChecked"] = true
      temp2["Application Status"]["isFilterActive"] = true
      setFilteredSearch({ ...temp2 })
    }

  }, [applicationStatusIndex])
  useEffect(() => {
    // let isCacheExist = localStorage.getItem('taskManagerFilterData') != "{}"
    // if (!isCacheExist) {
    let objectAPI = {
      onlyShowForUserId: showForThisUser ? showForThisUser : onlyShowForUserId ? onlyShowForUserId : undefined
    }

    call('POST', 'getTasksForAdminFilters', objectAPI).then(res => {
      console.log("getTasksForAdminFilters then", res);
      setFilterData(res)
      // const filtereddata = res?.[item?.filterDataKey]?.data.filter(item => true)
      // setFilteredSearch({
      //   ...res,
      //   [item?.filterDataKey]: {
      //     ...res[item?.filterDataKey],
      //     data: filtereddata
      //   }
      // })
      setFilteredSearch(res)
    }).catch(err => { })
    call('POST', 'getTasksStatsForAdmin', {}).then(result => {
      console.log('resulttttt', result);
      setstatsdata(result)
      // let dataObj = {}
      // if (result.length) {
      //   dataObj["newUsersCount"] = result[0]?.total_users + result[1]?.total_users
      //   dataObj["impexpCount"] = result[0]?.total_users
      //   dataObj["CPCount"] = result[1]?.total_users
      //   dataObj["leadsAssignedCount"] = result[0]?.lead_assigned + result[1]?.lead_assigned
      //   dataObj["leadsNotAssignedCount"] = result[0]?.lead_not_assigned + result[1]?.lead_not_assigned
      //   dataObj["AgreementPending"] = result[0]?.agreement_pending + result[1]?.agreement_pending
      //   setstatsdata(dataObj)
      // }
    }).catch(e => {

    })
    // }
  }, [])


  useEffect(() => {
    setshowLoader(true)
    call('POST', 'getTaskManagerGraphData', graphConfiguration).then(res => {
      console.log("getTaskManagerGraphData response===============>", res);
      setshowLoader(false)
      let activeApplicationStageFunnelGraphData = []
      activeApplicationStageFunnelGraphData[0] = { "name": "Finance Limit", "value": res.activeUserApplicationSummary["Finance Limit"]["invoice"] + res.activeUserApplicationSummary["Finance Limit"]["lc"] }
      activeApplicationStageFunnelGraphData[1] = { "name": "Quote", "value": res.activeUserApplicationSummary["Quote"]["invoice"] + res.activeUserApplicationSummary["Quote"]["lc"] }
      activeApplicationStageFunnelGraphData[2] = { "name": "Termsheet/Contract", "value": res.activeUserApplicationSummary["Termsheet/Contract"]["invoice"] + res.activeUserApplicationSummary["Termsheet/Contract"]["lc"] }
      activeApplicationStageFunnelGraphData[3] = { "name": "Finance", "value": res.activeUserApplicationSummary["Finance"]["invoice"] + res.activeUserApplicationSummary["Finance"]["lc"] }
      activeApplicationStageFunnelGraphData[4] = { "name": "Agreement", "value": res.activeUserApplicationSummary["Agreement"]["invoice"] }
      activeApplicationStageFunnelGraphData[5] = { "name": "Approved", "value": res.activeUserApplicationSummary["Approved"]["invoice"] + res.activeUserApplicationSummary["Approved"]["lc"] }

      let inactiveUserDaysFunnelGraphData = []
      inactiveUserDaysFunnelGraphData[0] = { "name": "15 Days", "value": res.inactiveUserDayWiseSummary["15 Days"]["exporter"] + res.inactiveUserDayWiseSummary["15 Days"]["channelPartner"] + res.inactiveUserDayWiseSummary["15 Days"]["financers"] }
      inactiveUserDaysFunnelGraphData[1] = { "name": "30 Days", "value": res.inactiveUserDayWiseSummary["30 Days"]["exporter"] + res.inactiveUserDayWiseSummary["30 Days"]["channelPartner"] + res.inactiveUserDayWiseSummary["30 Days"]["financers"] }
      inactiveUserDaysFunnelGraphData[2] = { "name": "45 Days", "value": res.inactiveUserDayWiseSummary["45 Days"]["exporter"] + res.inactiveUserDayWiseSummary["45 Days"]["channelPartner"] + res.inactiveUserDayWiseSummary["45 Days"]["financers"] }
      inactiveUserDaysFunnelGraphData[3] = { "name": "60 Days", "value": res.inactiveUserDayWiseSummary["60 Days"]["exporter"] + res.inactiveUserDayWiseSummary["60 Days"]["channelPartner"] + res.inactiveUserDayWiseSummary["60 Days"]["financers"] }
      inactiveUserDaysFunnelGraphData[4] = { "name": "75 Days", "value": res.inactiveUserDayWiseSummary["75 Days"]["exporter"] + res.inactiveUserDayWiseSummary["75 Days"]["channelPartner"] + res.inactiveUserDayWiseSummary["75 Days"]["financers"] }

      let activeApplicationStageTableData = []
      activeApplicationStageTableData[0] = ["Invoice Discounting", res.activeUserApplicationSummary["Finance Limit"]["invoice"], res.activeUserApplicationSummary["Quote"]["invoice"], res.activeUserApplicationSummary["Termsheet/Contract"]["invoice"],
        res.activeUserApplicationSummary["Finance"]["invoice"], res.activeUserApplicationSummary["Agreement"]["invoice"], res.activeUserApplicationSummary["Approved"]["invoice"]]
      activeApplicationStageTableData[1] = ["LC Discounting", res.activeUserApplicationSummary["Finance Limit"]["lc"], res.activeUserApplicationSummary["Quote"]["lc"], res.activeUserApplicationSummary["Termsheet/Contract"]["lc"],
        res.activeUserApplicationSummary["Finance"]["lc"], 0, res.activeUserApplicationSummary["Approved"]["lc"]]

      let inactiveUserDaysTableData = []
      inactiveUserDaysTableData[0] = ["Exporter", res.inactiveUserDayWiseSummary["15 Days"]["exporter"], res.inactiveUserDayWiseSummary["30 Days"]["exporter"], res.inactiveUserDayWiseSummary["45 Days"]["exporter"],
        res.inactiveUserDayWiseSummary["60 Days"]["exporter"], res.inactiveUserDayWiseSummary["75 Days"]["exporter"]]
      inactiveUserDaysTableData[1] = ["financers", res.inactiveUserDayWiseSummary["15 Days"]["financers"], res.inactiveUserDayWiseSummary["30 Days"]["financers"], res.inactiveUserDayWiseSummary["45 Days"]["financers"],
        res.inactiveUserDayWiseSummary["60 Days"]["financers"], res.inactiveUserDayWiseSummary["75 Days"]["financers"]]
      inactiveUserDaysTableData[2] = ["Channel Partner", res.inactiveUserDayWiseSummary["15 Days"]["channelPartner"], res.inactiveUserDayWiseSummary["30 Days"]["channelPartner"], res.inactiveUserDayWiseSummary["45 Days"]["channelPartner"],
        res.inactiveUserDayWiseSummary["60 Days"]["channelPartner"], res.inactiveUserDayWiseSummary["75 Days"]["channelPartner"]]

      setGraphData({
        ...res, activeApplicationStageFunnelGraphData, inactiveUserDaysFunnelGraphData, activeApplicationStageTableData, inactiveUserDaysTableData
      })
    })
  }, [graphConfiguration])

  useEffect(() => {
    if (userPermissionsForSubAdmin.mainAdmin || userPermissionsForSubAdmin?.["Assign Task"]) {
      setshowLoader(true)
      call("POST", 'getSubAdminUser', {}).then(res => {
        setshowLoader(false)
        setSalesPerson(res.data)
      }).catch(err => setshowLoader(false))
    } else {
      setshowLoader(true)
      call("POST", 'getSubAdminUser', { onlyUserId: onlyShowForUserId }).then(res => {
        setshowLoader(false)
        setSalesPerson(res.data)
      }).catch(err => setshowLoader(false))
    }
  }, [])

  const getTasks = (isDownload) => {
    if (!isDownload) {
      setshowLoader(true)
    }
    let dateRangeFilter = [moment().subtract(3, "days").format("YYYY-MM-DD"), moment().add(1, "days").format("YYYY-MM-DD")]
    let objectAPI = {
      dateRangeFilter,
      currentPage: page,
      resultPerPage: filter.resultPerPage,
      onlyShowForUserId: showForThisUser ? showForThisUser : onlyShowForUserId ? onlyShowForUserId : undefined,
      ...filter
    }
    if (isDownload) {
      delete objectAPI["currentPage"]
      delete objectAPI["resultPerPage"]
    }
    for (let index = 0; index < Object.keys(changedFilterData || filterData || {}).length; index++) {
      let filterName = Object.keys(changedFilterData || filterData)[index]
      const element = changedFilterData?.[filterName] || filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push((element.accordianId === "newUserType" ||
                element.accordianId === "leadAssignmentStatus" ||
                element.accordianId === "leadStatus" || element.accordianId === "applicationStatus") ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
        else if (element.type === "minMaxDate") {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }
    call('POST', 'getTasksForAdmin', objectAPI)
      .then((result) => {
        if (isDownload) {
          let finaldata = []
          for (let i = 0; i <= result?.message?.length - 1; i++) {
            const item = result?.message[i]
            try {
              console.log('in for loooopppp', item);
              let buyers_credit = item.buyers_credit ? JSON.parse(item.buyers_credit) : []
              let status = ''
              let type = ''
              if (!item.hasOwnProperty("buyers_credit")) {
                if (item.LeadAssignedTo === null) {
                  status = 'New User'
                } else if (item.LeadAssignedTo != null && item.buyers_count == null && item.type_id == 19) {
                  status = 'Buyer Not added'
                } else if (item.LeadAssignedTo != null && item.buyers_count != null && item.limit_count == null && item.type_id == 19) {
                  status = 'Limit not applied'
                }
                else if (item.type_id == 20 && item.CPStatus != 3) {
                  status = 'Agreement Pending'
                }
                else if (item.type_id == 20 && item.CPStatus == 3) {
                  status = 'Agreement Sent'
                } else if (item.type_id == 20) {
                  status = 'New User'
                }
                else {
                  status = 'New User'
                }

              }
              else if (item.buyers_credit === null) {
                status = "Waiting for financer quote"
              }
              else if (item.buyers_credit != null && item.selectedFinancier == null) {
                status = 'Quote Recieved'
              } else if (item.termSheetSignedByBank && item.termSheetSignedByExporter) {
                status = (item.invRefNo === 'lc_discounting' || item.invRefNo === 'lc_confirmation' || item.invRefNo === 'sblc') ? "Quote Locked" : "Limit Approved"
              } else if (item.termSheetSignedByExporter) {
                status = (item.invRefNo === 'lc_discounting' || item.invRefNo === 'lc_confirmation' || item.invRefNo === 'sblc') ? "Contract Signed by Exporter" : 'TermSheet signed by exporter'
              }
              else if (item.selectedFinancier) {
                status = 'Quote Selected by exporter'
              } else if (item.invRefNo === 'lc_discounting' || item.invRefNo === 'lc_confirmation') {
                if (buyers_credit.every(data => data.status === 'denied')) {
                  status = 'Limit Denied'
                }
              } else if (item.invRefNo != 'lc_discounting' && item.invRefNo != 'lc_confirmation' && item.invRefNo != 'sblc') {

                if (buyers_credit.every(data => data.financierAction === 'deny')) {
                  status = 'Limit Denied'
                }
              }
              if (!item.hasOwnProperty("buyers_credit")) {
                type = 'User Onboard'
              }
              else if (item.invRefNo === 'lc_discounting') {
                type = 'LC Discounting'
              } else if (item.invRefNo === 'lc_confirmation') {
                type = 'LC Confirmation'
              } else if (item.invRefNo === 'sblc') {
                type = 'SBLC'
              } else {
                type = 'Invoice Discounting'
              }
              let downloadObj = {
                'Company Name': item.company_name,
                'Application Number': item.finance_type === 'invoice_discounting' ? item.invRefNo ? item.invRefNo : `${getInitials(item.buyerName)}/${item.buyerCountry ? item.buyerCountry : '-'}/${item.requiredLimit ? item.requiredLimit : '-'}` : item.id ? item.id : '-',
                'Contact Person': item.contact_person,
                'Contact Number': item.contact_number,
                'Status': status,
                'Lead Assigned To': item.subAdminContactPersonName,
                'Application Type': type
              }
              console.log('FINALoBJ', downloadObj);
              finaldata.push(downloadObj)
            } catch (e) {
              console.log('error in download', e);
            }

          }
          ExportExcel(finaldata, `TaskManager_${new Date().getTime()}`)
        } else {
          setdbData(formatDataForTable(result.message))
          setCount(result.totalCount)
          setoveralldata(result.message)
          setshowLoader(false)
        }

      }).catch(e => {
        console.log('111111111111111111111111111111', e);
        setshowLoader(false)
      })
  }
  useEffect(() => {
    getTasks()
  }, [page, refresh, salesPerson, filterData, changedFilterData])

  async function handleTransactionPopupLC(itemData) {
    setshowLoader(true)
    let apiResp = await call('POST', 'getTransactionHistoryForLC', {
      buyerId: itemData.buyerId, applicationId: itemData.applicationId
    })
    console.log("getTransactionHistoryForLC api resp====>", itemData, apiResp);
    setshowLoader(false)
    toggleTransactionPopup({ show: true, data: apiResp })
  }

  async function handleTransactionPopupINV(itemData) {
    setshowLoader(true)
    let apiResp = await call('POST', 'getTransactionHistoryForInvoiceLimit', {
      buyerId: itemData.buyerId,
      applicationId: itemData.applicationId,
      invRefNo: itemData.reference_no
    })
    // console.log("getTransactionHistoryForInvoiceLimit api resp====>", itemData, apiResp);
    setshowLoader(false)
    toggleTransactionPopup({ show: true, data: apiResp })
  }
  const updateLeadAssignedTo = (leadAssignedName, userId) => {
    call('POST', 'updateLeadAssignedTo', { leadAssignedName, userId }).then(result => {
      toastDisplay("Lead updated", "success")
      getTasks()
    }).catch(e => {
      toastDisplay("Failed to assign lead to " + leadAssignedName, "error")
    })
  }
  function formatDataForTable(data) {

    let obj = {
      "19": "Exporter",
      "8": "Bank",
      "20": "Channel Partner"
    }
    let tableData = []
    let row = []
    data.forEach((item, index) => {
      try {
        let buyers_credit = item.buyers_credit ? JSON.parse(item.buyers_credit) : []
        let status = ''
        if (!item.hasOwnProperty("buyers_credit")) {
          if (item.LeadAssignedTo === null) {
            status = 'New User'
          } else if (item.LeadAssignedTo != null && item.buyers_count == null && item.type_id == 19) {
            status = 'Buyer Not added'
          } else if (item.LeadAssignedTo != null && item.buyers_count != null && item.limit_count == null && item.type_id == 19) {
            status = 'Limit not applied'
          }
          else if (item.type_id == 20 && item.CPStatus != 3) {
            status = 'Agreement Pending'
          }
          else if (item.type_id == 20 && item.CPStatus == 3) {
            status = 'Agreement Sent'
          } else if (item.type_id == 20) {
            status = 'New User'
          }
          else {
            status = 'New User'
          }

        }
        else if (item.buyers_credit === null) {
          status = "Waiting for financer quote"
        }
        else if (item.buyers_credit != null && item.selectedFinancier == null) {
          status = 'Quote Recieved'
        } else if (item.termSheetSignedByBank && item.termSheetSignedByExporter) {
          status = (item.invRefNo === 'lc_discounting' || item.invRefNo === 'lc_confirmation' || item.invRefNo === 'sblc') ? "Quote Locked" : "Limit Approved"
        } else if (item.termSheetSignedByExporter) {
          status = (item.invRefNo === 'lc_discounting' || item.invRefNo === 'lc_confirmation' || item.invRefNo === 'sblc') ? "Contract Signed by Exporter" : 'TermSheet signed by exporter'
        }
        else if (item.selectedFinancier) {
          status = 'Quote Selected by exporter'
        } else if (item.invRefNo === 'lc_discounting' || item.invRefNo === 'lc_confirmation') {
          if (buyers_credit.every(data => data.status === 'denied')) {
            status = 'Limit Denied'
          }
        } else if (item.invRefNo != 'lc_discounting' && item.invRefNo != 'lc_confirmation' && item.invRefNo != 'sblc') {

          if (buyers_credit.every(data => data.financierAction === 'deny')) {
            status = 'Limit Denied'
          }
        }

        row[0] = item.company_name
        row[1] = item.finance_type === 'invoice_discounting' ? item.invRefNo ? item.invRefNo : `${getInitials(item.buyerName)}/${item.buyerCountry ? item.buyerCountry : '-'}/${item.requiredLimit ? item.requiredLimit : '-'}` : item.id ? item.id : '-'
        row[2] = `${item.name_title ? item.name_title : ''} ${item.contact_person ? item.contact_person : ''}`
        row[3] = `${item.phone_code ? "+" + item.phone_code : ''} ${item.contact_number ? item.contact_number : ''}`
        row[4] = obj[item.type_id]
        row[5] = <div class="dropdown w-100" >
          <label class="font-wt-600 font-size-13 cursor" onClick={() => {
            togglesubadminPopup({
              show: true,
              data: [],
              userId: item.userId
            })
          }}>
            {item.subAdminContactPersonName || '-'}
          </label>
        </div>
        row[6] = moment().diff(item.updated_at, 'days') + " days"
        row[7] = <label className='text-color1 font-wt-600 cursor' onClick={() => {
          if ((status === 'New User' || status === 'Agreement Pending') && item.type_id == 20) {
            setViewDetails({
              isVisible: true,
              type: "Channel Partner",
              data: {
                type_id: item.type_id,
                id: item.userId,
                email_id: item.email_id,
                company_name: item.company_name,
                ...item
              }
            })
          }
          else if ((status === 'New User' || status === 'Limit Denied' || status === 'Buyer Not added') && item.type_id == 19) {
            setViewDetails({
              isVisible: true,
              type: "Exporter",
              data: {
                type_id: item.type_id,
                id: item.userId,
                email_id: item.email_id,
                company_name: item.company_name,
                ...item
              }
            })
          }
          else if (item.invRefNo === 'lc_discounting' || item.invRefNo === 'lc_confirmation' || item.invRefNo === 'sblc') {
            handleTransactionPopupLC({
              buyerId: item.buyerId,
              applicationId: item.tblId
            })
          } else {
            handleTransactionPopupINV({
              buyerId: item.buyerId,
              applicationId: item.tblId,
              invRefNo: item.invRefNo
            })
          }
        }}>{status}</label>
        if (item.type_id == 20 && item.CPStatus != 3) {
          row[99] = <p className="font-size-12 text-color-value ml-3">
            <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" mr-2  font-size-13"><b>
              New Channel partner signed up.</b><span className='ml-2 text-color1 text-decoration-underline cursor font-wt-600 font-size-13' onClick={() => {
                setViewDetails({
                  isVisible: true,
                  type: "Channel Partner",
                  data: {
                    type_id: item.type_id,
                    id: item.userId,
                    email_id: item.email_id,
                    company_name: item.company_name,
                    ...item
                  }
                })
              }}>Send an agreement </span> </span>
          </p>
        }
        // if (!item.buyers_credit) {
        //   row[99] = <p className="font-size-12 text-color-value ml-3">
        //     <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" mr-2"><b>
        //       Application sent by supplier, waiting for quote from financier</b> </span>
        //   </p>
        // }

        // if (item.termSheet && !item.termSheetSignedByExporter) {
        //   row[99] = <p className="font-size-12 text-color-value ml-3">
        //     <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" mr-2"><b>
        //       Term sheet sent by financier</b> </span>
        //   </p>
        // }
        tableData.push(row)
        row = []
      } catch (e) {
        console.log('error in', e, item);
      }

    })
    return tableData
  }

  function handleOpeningApplication(indx, tabIndx) {
    let item = overalldata[indx]
    if (item.finance_type == 'invoice_discounting') {
      if (item.buyers_credit == null || item.selectedFinancier == null) {
        window.location = `/seeQuotes?buyer=${item.buyerId}`;
        localStorage.setItem("applicationId", item.tblId)
        localStorage.setItem("invIfAppliedNo", item.invRefNo)
        localStorage.setItem("isAdmin", true)
        localStorage.setItem("defaultTabForAdmin", tabIndx)
        localStorage.setItem("selectedLenderName", item.FinancierName)

        // setting manual user id & email for user
        localStorage.setItem("manualUserId", item.userId)
        localStorage.setItem("manualUserEmail", item.email_id)
      } else {
        window.location = `/sign-agreement`
        localStorage.setItem("item", JSON.stringify(item))
        localStorage.setItem("isAdminUser", true)
        localStorage.setItem("defaultSetTab", tabIndx)

        // setting manual user id & email for user
        localStorage.setItem("manualUserId", item.userId)
        localStorage.setItem("manualUserEmail", item.email_id)
        localStorage.setItem("headerBreadCum", "Invoice Discounting > Finance > Application Details")
      }
    } else {
      window.location = `/LcSeequotes?id=${item.tblId}`
      localStorage.setItem("isAdmin", true)
      localStorage.setItem("defaultTabForAdmin", tabIndx)

      // setting manual user id & email for user
      localStorage.setItem("manualUserId", item.userId)
      localStorage.setItem("manualUserEmail", item.email_id)
    }
  }

  const handleGraphConfigurationChange = async (event) => {
    if (event.persist) {
      event.persist()
    }
    setGraphConfiguration({ ...graphConfiguration, [event.target.name]: event.target.value })
  }

  async function downloadActiveUserApplicationStage() {
    if (!graphData.activeApplicationStageTableData?.length) {
      return toastDisplay('No data found to download', "info")
    }
    try {
      let temp = graphData.activeApplicationStageTableData
      let csvString = "Active Users (Application Stage),Finance Limit,Quote,Termsheet/Contract,Finance,Agreement,Approved\r\n"
      let rowString = `${temp[0][0]},${temp[0][1]},${temp[0][2]},${temp[0][3]},${temp[0][4]},${temp[0][5]},${temp[0][6]}\r\n`
      rowString = rowString.replace(/(\n)/gm, "")
      csvString += rowString

      rowString = `${temp[1][0]},${temp[1][1]},${temp[1][2]},${temp[1][3]},${temp[1][4]},${temp[1][5]},${temp[1][6]}\r\n`
      rowString = rowString.replace(/(\n)/gm, "")
      csvString += rowString

      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', `ActiveUserApplicationStage.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log("error in downloadActiveUserApplicationStage", error);
    }
  }

  async function downloadInactiveUserDays() {
    if (!graphData.inactiveUserDaysTableData?.length) {
      return toastDisplay('No data found to download', "info")
    }
    try {
      let temp = graphData.inactiveUserDaysTableData
      let csvString = "Inactive Users(Days),15 Days,30 Days,45 Days,60 Days,75 Days\r\n"
      let rowString = `${temp[0][0]},${temp[0][1]},${temp[0][2]},${temp[0][3]},${temp[0][4]},${temp[0][5]}\r\n`
      rowString = rowString.replace(/(\n)/gm, "")
      csvString += rowString

      rowString = `${temp[1][0]},${temp[1][1]},${temp[1][2]},${temp[1][3]},${temp[1][4]},${temp[1][5]}\r\n`
      rowString = rowString.replace(/(\n)/gm, "")
      csvString += rowString

      rowString = `${temp[2][0]},${temp[2][1]},${temp[2][2]},${temp[2][3]},${temp[2][4]},${temp[2][5]}\r\n`
      rowString = rowString.replace(/(\n)/gm, "")
      csvString += rowString

      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', `InactiveUserSummary.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log("error in downloadInactiveUserDays", error);
    }
  }

  return (

    <div className={renderAsChildren ? "" : "container-fluid"}>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <SubAdminPopup togglesubadminpopup={togglesubadminPopup} subadminpopup={subadminPopup} setShowLoader={setshowLoader} />

      <div className={`modal fade ${notePopup.show && "show"}`} style={notePopup.show ? { display: "block", "zIndex": '100001' } : {}}>
        <div className="modal-dialog modal-md mr-0 my-0">
          <div className="modal-content submitmodal pb-4"
          >

            <div className="modal-header border-0">
              <div className="w-100 d-flex align-items-center justify-content-between">
                <label
                  className="font-size-16 font-wt-600 text-color-value mx-3"
                >{`Add Note for ${notePopup.noteFor}`}</label>
                <div className="modal-header border-0">
                  <button type="button" className="btn-close" aria-label="Close" onClick={() => toggleNotePopup({ show: false, data: "", selectedIndex: null })}></button>
                </div>
              </div>
            </div>

            <div className="modal-body px-4">
              <div className="col-md-12 px-0">
                <div className="">
                  <NewTextArea
                    rows={6}
                    type={"text"} label={`Write Note`} name={"note"}
                    value={notePopup.data}
                    onChange={(e) => {
                      toggleNotePopup({ ...notePopup, data: e.target.value })
                    }}
                  />
                </div>
              </div>

              <button type="button"
                onClick={async () => {
                  setshowLoader(true)
                  let temp = overalldata[notePopup.selectedIndex]
                  // console.log("temp==========================>", overalldata, notePopup);
                  let req = { leadNote: notePopup.data }
                  if (!temp?.hasOwnProperty("buyers_credit")) {
                    req["userTblId"] = temp.userId
                  }
                  else if (temp?.finance_type === "invoice_discounting") {
                    req["invoiceLimitId"] = temp.id
                  }
                  else {
                    req["lcNo"] = temp.id
                  }
                  call('POST', 'addNoteForLead', req).then(res => {
                    setshowLoader(false)
                    toastDisplay(res, "success")
                    setRefresh(refresh + 1)
                    toggleNotePopup({ show: false, data: "", selectedIndex: null })
                  }).catch(err => {
                    setshowLoader(false)
                    toastDisplay("Something went wrong", "error")
                  })
                }}
                className={`new-btn w-20 py-2 mt-2 px-2 text-white enablesigncontract text-white `}>
                {"Submit"}
              </button>

            </div>
          </div>
        </div>
      </div>

      <div className={`modal fade ${transactionPopup.show && "show"}`} style={transactionPopup.show ? { display: "block", "zIndex": '100001' } : {}}>
        <div className="modal-dialog modal-md mr-0 my-0">
          <div className="modal-content submitmodal pb-4"
          >

            <div className="modal-header border-0">
              <div className="w-100 d-flex align-items-center justify-content-between">
                <label
                  className="font-size-16 font-wt-600 text-color-value mx-3"
                >Transaction History</label>
                <div className="modal-header border-0">
                  <button type="button" className="btn-close" aria-label="Close" onClick={() => toggleTransactionPopup({ show: false, data: [] })}></button>
                </div>
              </div>
            </div>

            <div className="modal-body px-4">
              {transactionPopup.data.length ? transactionPopup.data.map((item, index) => {
                return (
                  <div className='d-flex flex-row ml-3'>
                    <div className="progressBarContainer">
                      <div className="progressBarInnerCircle">
                      </div>
                    </div>
                    <div className='pl-4 pt-3'>
                      <p className='font-size-14 text-color1 font-wt-500 mb-0'>{item.action}</p>
                      <p className='font-size-14 text-color-label font-wt-500 mb-0'>{item.date}</p>
                      <p className='font-size-14 text-color-label font-wt-500 mb-0'>{item.time}</p>
                    </div>
                  </div>
                )
              }) :
                null}
            </div>

          </div>
        </div>
      </div>
      <div className="row">
        {!renderAsChildren ?
          <SideBarV2 state={"taskManagerUsers"} userTokenDetails={userTokenDetails} /> : null}
        <main role="main" className={`ml-sm-auto col-lg-${renderAsChildren ? '12' : '10'} ` + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
          {!renderAsChildren ?
            <HeaderV2
              title={"Task Manager"}
              userTokenDetails={userTokenDetails} /> : null}
          {viewDetails.isVisible && viewDetails.type == 'Exporter' &&
            <div className='mt-4'>
              <UserDetails data={viewDetails.data} goBack={() => {
                setViewDetails({
                  isVisible: false,
                  data: {},
                  type: ''
                })
              }} userTokenDetails={userTokenDetails} />
            </div>
          }
          {viewDetails.isVisible && viewDetails.type == 'Channel Partner' &&
            <div className='mt-4'>
              <ChannelPartnerDetails data={viewDetails.data} goBack={() => {
                setViewDetails({
                  isVisible: false,
                  data: {},
                  type: ''
                })
              }} userTokenDetails={viewDetails.data} />
            </div>
          }
          {!viewDetails.isVisible &&
            <>
              {!renderAsChildren && <>

                <div className='row mt-4'>
                  <div className='w-30 pl-0'>
                    <div className='card h-75 dashboard-card shadow-sm align-items-center justify-content-center'>
                      <label className='w-100 font-size-14 text-color-value font-wt-600 pt-2 text-left'>{`New User - `}
                        <label className='font-size-14 text-color-value font-wt-600 text-custom2'>{statsdata.newUsersCount || 0}</label></label>
                      <div className='row px-0 w-100'>
                        <div className='w-50 cursor'
                          onClick={() => {
                            let temp = filterData
                            temp["New User Type"]["data"][0]["isChecked"] = true
                            temp["New User Type"]["data"][1]["isChecked"] = false
                            temp["New User Type"]["isFilterActive"] = true
                            setFilterData({ ...temp })
                          }}>
                          <label className={`value font-wt-600  w-100 cursor`}>
                            {statsdata.impexpCount || 0}
                          </label>
                          <label className={'font-size-14 font-wt-700 text-color-value cursor'}>{"Exporter/Importers"}</label>
                        </div>

                        <div className='w-50 cursor'
                          onClick={() => {
                            let temp = filterData
                            temp["New User Type"]["data"][0]["isChecked"] = false
                            temp["New User Type"]["data"][1]["isChecked"] = true
                            temp["New User Type"]["isFilterActive"] = true
                            setFilterData({ ...temp })
                          }}>
                          <label className={`value font-wt-600 w-100 cursor`}>
                            {statsdata.CPCount || 0}
                          </label>
                          <label className={'font-size-14 font-wt-700 text-color-value cursor'}>{"Channel Partner"}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-20'>
                    <div className='card h-75 dashboard-card shadow-sm align-items-center justify-content-center'>
                      <label className='w-100 font-size-14 text-color-value font-wt-600 pt-2 text-left'>{`Leads - `}
                        <label className='font-size-14 text-color-value font-wt-600 text-custom2'>{statsdata.leadsAssignedCount || 0}</label></label>
                      <label className={`w-100  value font-wt-600 text-custom2 text-left`}>
                        {statsdata.leadsNotAssignedCount || 0}
                      </label>
                      <label
                        onClick={() => {
                          let temp = filterData
                          temp["Lead Assignment Status"]["data"][1]["isChecked"] = true
                          temp["Lead Assignment Status"]["data"][0]["isChecked"] = false
                          temp["Lead Assignment Status"]["isFilterActive"] = true
                          setFilterData({ ...temp })
                        }}
                        className={'w-100 font-size-14 font-wt-700 text-color-value text-left cursor'}>{"Leads not assigned"}</label>
                    </div>
                  </div>

                  <div
                    onClick={() => {
                      let temp = filterData
                      for (let index = 0; index < temp["Lead Status"]["data"].length; index++) {
                        const element = temp["Lead Status"]["data"][index];
                        if (element.name === "Agreement Not Sent") {
                          temp["Lead Status"]["data"][index]["isChecked"] = true
                        }
                        else {
                          temp["Lead Status"]["data"][index]["isChecked"] = false
                        }
                      }
                      temp["Lead Status"]["isFilterActive"] = true
                      setFilterData({ ...temp })
                    }}
                    className='w-20 cursor'>
                    <div className='card h-75 dashboard-card shadow-sm align-items-center justify-content-between'>
                      <label className={`w-100  value font-wt-600 text-custom2 text-left`}>
                        {statsdata.AgreementPending || 0}
                      </label>
                      <label className={'w-100 font-size-14 font-wt-700 text-color-value text-left'}>{"Agreement not sent"}</label>
                    </div>
                  </div>
                </div>
              </>}
              <div className="filter-div ml-4 mt-3">
                <Filter
                  filteredSearch={filteredSearch}
                  setFilteredSearch={setFilteredSearch}
                  filterData={changedFilterData || filterData} setFilterData={setChangedFilterData || setFilterData} showFilterBtn={true}
                  showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} showDownloadIcon={true} onDownloadClick={() => getTasks(true)} />
              </div>

              <div className="mb-3">
                <NewTable
                  //columns={Shipmentcolumns} 
                  filteredSearch={filteredSearch}
                  setFilteredSearch={setFilteredSearch}
                  tableFixed data={dbData}
                  columns={[
                    {
                      name: "Company Name", filter: true, filterDataKey: "Company Name", sort: [
                        { name: "Sort A-Z", selected: filter.sortCompanyName === "ASC", onActionClick: () => { setFilter({ ...filter, sortCompanyName: 'ASC', sortContactPerson: false, sortContactNo: false, sortLeadAssignedTo: false, sortByPeriod: false, }); setRefresh(refresh + 1) } },
                        { name: "Sort Z-A", selected: filter.sortCompanyName === "DESC", onActionClick: () => { setFilter({ ...filter, sortCompanyName: "DESC", sortContactPerson: false, sortContactNo: false, sortLeadAssignedTo: false, sortByPeriod: false, }); setRefresh(refresh + 1) } }]
                    },
                    { name: "Application No." },
                    {
                      name: "Contact Person", filter: true, filterDataKey: "Contact Person", sort: [
                        { name: "Sort A-Z", selected: filter.sortContactPerson === "ASC", onActionClick: () => { setFilter({ ...filter, sortContactPerson: 'ASC', sortCompanyName: false, sortContactNo: false, sortLeadAssignedTo: false, sortByPeriod: false, }); setRefresh(refresh + 1) } },
                        { name: "Sort Z-A", selected: filter.sortContactPerson === "DESC", onActionClick: () => { setFilter({ ...filter, sortContactPerson: "DESC", sortCompanyName: false, sortContactNo: false, sortLeadAssignedTo: false, sortByPeriod: false, }); setRefresh(refresh + 1) } }]
                    },
                    {
                      name: "Contact No", filter: true, filterDataKey: "Contact No"
                    },
                    {
                      name: "User Type", filter: true, filterDataKey: "New User Type"
                    },
                    {
                      name: "Lead Assigned", filter: true, filterDataKey: "Lead Assigned To", sort: [
                        { name: "Sort A-Z", selected: filter.sortLeadAssignedTo === "ASC", onActionClick: () => { setFilter({ ...filter, sortLeadAssignedTo: 'ASC', sortContactPerson: false, sortCompanyName: false, sortContactNo: false, sortByPeriod: false, }); setRefresh(refresh + 1) } },
                        { name: "Sort Z-A", selected: filter.sortLeadAssignedTo === "DESC", onActionClick: () => { setFilter({ ...filter, sortLeadAssignedTo: "DESC", sortContactPerson: false, sortCompanyName: false, sortContactNo: false, sortByPeriod: false, }); setRefresh(refresh + 1) } }]
                    },
                    {
                      name: "Period", filter: true, filterDataKey: "Period", sort: [
                        { name: "Sort A-Z", selected: filter.sortByPeriod === "ASC", onActionClick: () => { setFilter({ ...filter, sortByPeriod: 'ASC', sortContactPerson: false, sortCompanyName: false, sortContactNo: false, sortLeadAssignedTo: false, }); setRefresh(refresh + 1) } },
                        { name: "Sort Z-A", selected: filter.sortByPeriod === "DESC", onActionClick: () => { setFilter({ ...filter, sortByPeriod: "DESC", sortContactPerson: false, sortCompanyName: false, sortContactNo: false, sortLeadAssignedTo: false, }); setRefresh(refresh + 1) } }]
                    },
                    {
                      name: "Status"
                    },
                  ]}
                  options={[
                    {
                      name: "Add Note", icon: "", onClick: (index) => {
                        let temp = overalldata[index]
                        toggleNotePopup({ show: true, data: temp.invoiceLimitLeadNote || temp.lcLimitLeadNote || temp.leadNote, selectedIndex: index, noteFor: temp.contact_person })
                      }
                    },
                    {
                      name: "View Application", icon: "", onClick: (index) => {
                        const tabledata = dbData[index]
                        let status = tabledata[7].props.children
                        const item = overalldata[index]
                        console.log('Itemmmmm', status)

                        if ((status === 'New User' || status === 'Agreement Pending') && item.type_id == 20) {
                          setViewDetails({
                            isVisible: true,
                            type: "Channel Partner",
                            data: {
                              type_id: item.type_id,
                              id: item.userId,
                              email_id: item.email_id,
                              company_name: item.company_name,
                              ...item
                            }
                          })
                        }
                        else if ((status === 'New User' || status === 'Limit Denied' || status === 'Buyer Not added') && item.type_id == 19) {
                          setViewDetails({
                            isVisible: true,
                            type: "Exporter",
                            data: {
                              type_id: item.type_id,
                              id: item.userId,
                              email_id: item.email_id,
                              company_name: item.company_name,
                              ...item
                            }
                          })
                        }
                        else {
                          handleOpeningApplication(index, 0)
                        }
                      }
                    },
                  ]}
                  filterData={filterData}
                  setFilterData={setFilterData}
                />

              </div>
              <Pagination page={page} totalCount={count} onPageChange={(p) => setPage(p)} perPage={filter.resultPerPage || 10} />
            </>
          }
          {(hideGraphs || viewDetails.isVisible) ? null : (
            <div>
              <div className='row  mb-3'>
                <div className='w-70 align-items-center d-flex'>
                  <div className='w-auto pr-3'>
                    <label className='text-color-value font-size-14 font-wt-600'>Custom</label>
                  </div>
                  <div className='w-20 pr-3'>
                    <NewInput type={"date"} name={"applicationStageFrom"} value={graphConfiguration.applicationStageFrom}
                      onChange={handleGraphConfigurationChange} />
                  </div>
                  <div className='w-20 pr-3'>
                    <NewInput type={"date"} name={"applicationStageTo"} value={graphConfiguration.applicationStageTo}
                      onChange={handleGraphConfigurationChange} />
                  </div>
                </div>
                <div className='w-30 align-items-center d-flex justify-content-end'>
                  <div className='px-3'>
                    <img
                      onClick={() => { setGraphConfiguration({ ...graphConfiguration, applicationStageGraphMode: !graphConfiguration.applicationStageGraphMode }) }}
                      className='cursor'
                      src={`/assets/images/${graphConfiguration.applicationStageGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                  </div>
                  <div className=''>
                    <img
                      onClick={() => {
                        downloadActiveUserApplicationStage()
                        downloadInactiveUserDays()
                      }}
                      className='cursor' src='/assets/images/download_icon_with_bg.png' />
                  </div>
                </div>
              </div>
              {!graphConfiguration.applicationStageGraphMode ? (
                <>
                  <div>
                    <NewTable
                      disableAction={true}
                      data={graphData.activeApplicationStageTableData || []}
                      columns={[{ name: "Active Users(Application Stage)" }, { name: "Finance Limit" }, { name: "Quote" },
                      { name: "Termsheet/Contract" }, { name: "Finance" }, { name: "Agreement" }, { name: "Approved" }]}
                    />
                  </div>
                  <div className='mt-4'>
                    <NewTable
                      disableAction={true}
                      data={graphData.inactiveUserDaysTableData || []}
                      columns={[{ name: "Inactive Users(Days)" }, { name: "15 Days" }, { name: "30 Days" },
                      { name: "45 Days" }, { name: "60 Days" }, { name: "75 Days" }]}
                    />
                  </div>
                </>
              ) : (
                <div className='card p-3 dashboard-card border-0 borderRadius h-100 d-flex flex-row pt-5 pb-4 mx-3' >
                  <div className='col-6'>
                    {graphData?.activeApplicationStageFunnelGraphData?.length ? (
                      <>
                        <div className='text-center h-90'>
                          <img src='/assets/images/Active users application stage.png' width={"70%"} height={"90%"} />
                        </div>
                        <label className='position-absolute font-size-16 font-wt-700 appstage-fl'>{graphData?.activeApplicationStageFunnelGraphData[0]?.value}</label>
                        <label className='position-absolute font-size-16 font-wt-700 appstage-qts'>{graphData?.activeApplicationStageFunnelGraphData[1]?.value}</label>
                        <label className='position-absolute font-size-16 font-wt-700 appstage-ts'>{graphData?.activeApplicationStageFunnelGraphData[2]?.value}</label>
                        <label className='position-absolute font-size-16 font-wt-700 appstage-fin'>{graphData?.activeApplicationStageFunnelGraphData[3]?.value}</label>
                        <label className='position-absolute font-size-16 font-wt-700 appstage-agree'>{graphData?.activeApplicationStageFunnelGraphData[4]?.value}</label>
                        <label className='position-absolute font-size-16 font-wt-700 appstage-approved'>{graphData?.activeApplicationStageFunnelGraphData[5]?.value}</label>

                      </>
                    ) : null}
                    <label className='text-color-value font-size-14 font-wt-600 w-100 text-center mt-3'>Active Users (Application Stage)</label>
                  </div>

                  <div className='col-6'>
                    {graphData?.inactiveUserDaysFunnelGraphData?.length ? (
                      <>
                        <div className='text-center h-90'>
                          <img src='/assets/images/inactive days.png' width={"70%"} height={"80%"} />
                        </div>
                        <label className='position-absolute font-size-16 font-wt-700 inact-15-days'>{graphData?.inactiveUserDaysFunnelGraphData[0]?.value}</label>
                        <label className='position-absolute font-size-16 font-wt-700 inact-30-days'>{graphData?.inactiveUserDaysFunnelGraphData[1]?.value}</label>
                        <label className='position-absolute font-size-16 font-wt-700 inact-45-days'>{graphData?.inactiveUserDaysFunnelGraphData[2]?.value}</label>
                        <label className='position-absolute font-size-16 font-wt-700 inact-60-days'>{graphData?.inactiveUserDaysFunnelGraphData[3]?.value}</label>
                        <label className='position-absolute font-size-16 font-wt-700 inact-75-days'>{graphData?.inactiveUserDaysFunnelGraphData[4]?.value}</label>

                      </>
                    ) : null}
                    <label className='text-color-value font-size-14 font-wt-600 w-100 text-center mt-3'>Inactive Users (Days)</label>
                  </div>
                </div>
              )}
            </div>
          )}

          {/* <div className='row pt-5 mb-3'>
            <div className='w-80 align-items-center d-flex'>
              <div className='w-auto pr-3'>
                <label className='text-color-value font-size-14 font-wt-600'>Custom</label>
              </div>
              <div className='w-15 pr-3'>
                <NewInput type={"date"} name={"newUserSummaryFrom"} value={graphConfiguration.newUserSummaryFrom}
                  onChange={handleGraphConfigurationChange} />
              </div>
              <div className='w-15 pr-3'>
                <NewInput type={"date"} name={"newUserSummaryTo"} value={graphConfiguration.newUserSummaryTo}
                  onChange={handleGraphConfigurationChange} />
              </div>
            </div>
            <div className='w-20 align-items-center d-flex justify-content-end'>
              <div className='pr-3'>
                <img
                  onClick={() => { setGraphConfiguration({ ...graphConfiguration, newUserGraphMode: !graphConfiguration.newUserGraphMode }) }}
                  className='cursor'
                  src={`/assets/images/${graphConfiguration.newUserGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
              </div>
              <div className=''>
                <img
                  // onClick={downloadUserOnboardData}
                  className='cursor' src='/assets/images/download_icon_with_bg.png' />
              </div>
            </div>
          </div> */}

          {/* <div className='card p-3 dashboard-card border-0 borderRadius'>
            {graphConfiguration.newUserGraphMode ? (
              <div className='row'>
                <div className='col-md-6'>
                  {graphData?.newUserSummaryGraphPieData?.length ? (
                    <div className='col-md-12 text-center ml-9rem'>
                      <PieChartComponent hideDollar={true}
                        customToolTip={<CustomTooltipForNewUserSummary />}
                        data={graphData.newUserSummaryGraphPieData} dataKey="value" colors={lanesColor} cornerRadius={30} totalVal={graphData.totalNewUserSummaryGraphPieData} />
                    </div>
                  ) : null}
                </div>

                <div className='col-md-6 row py-5 my-5'>
                  <div className='row align-items-center justify-content-center text-center'>
                    <label className='font-size-16 font-wt-600'>{"New Users"}</label>
                  </div>
                  {graphData?.newUserSummaryGraphPieData?.length ? graphData.newUserSummaryGraphPieData.map((i, index) => {
                    return (
                      <div className='row align-items-center justify-content-center'>
                        <div className=''
                          style={{ width: '40px', height: '20px', backgroundColor: lanesColor[index] }}>
                        </div>
                        <div className='w-30'>
                          <label className='text-center font-size-14 font-wt-600'>{i.type}</label>
                        </div>
                      </div>
                    )
                  }) : null}
                </div>
              </div>
            ) : (
              <NewTable
                disableAction={true}
                columns={[{ name: "New Users" }, { name: "Send Agreement" }, { name: "Sign Agreement" },
                { name: "Add Buyer" }, { name: "Apply For Limit" }, { name: "Apply For Finance" }]}
                data={graphData.newUsertableDataForUserOnboarded || []}
              />
            )}
          </div> */}

        </main>

      </div>
    </div >
  )
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}
export default connect(mapStateToProps, null)(TaskManager) 