import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import call from '../../../../service';
import { NewTable } from '../../../../utils/newTable';
import HeaderV2 from '../../../partial/headerV2';
import SideBarV2 from '../../../partial/sideBarV2';
import Filter from "../../../InvoiceDiscounting/components/Filter";
import moment from 'moment';
import { getDocDetails, LCPurposeObject, LCTypesObject } from '../../../../utils/myFunctions';
import Pagination from '../../../InvoiceDiscounting/contract/components/pagination';
import ChatBoxPopUp from '../../../chatRoom/components/ChatBoxPopUp';
import NewTablev2 from '../../../../utils/newTablev2';
import ChatBoxPopUp2 from '../../../chatRoom/components/ChatBoxPopUp2';
import { Action } from '../../../myCounterPartComp/action';


export const LCFinApplication = ({ userTokenDetails, navToggleState }) => {
  const [dbData, setdbData] = useState([])
  const [tableData, setTableData] = useState([])
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10, singleApplication: true })
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [filterData, setFilterData] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [action, setAction] = useState({ show: false, index: null })
  const [applicationCounts, setApplicationCounts] = useState({})

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  useEffect(() => {
    fetchApplicationCounts()
  }, [])

  async function fetchApplicationCounts() {
    call('POST', 'getAppliedForLCFinanceApplicationCounts', { userId }).then(res => setApplicationCounts(res))
  }

  useEffect(() => {
    //Get Buyer list start
    let objectAPI = {
      "userId": userId,
      "userTypeId": userTypeId,
      currentPage: page,
      "onlyAppliedForFinance": true,
      ...filter
    }
    call('POST', 'getAppliedForLCLimit', objectAPI).then((result) => {
      console.log('running getAppliedForLCLimit api-->', result);
      setTableData(result.data)
      setdbData(result.data)
      setCount(result.totalCount)
    }).catch((e) => {
      // console.log('error in getAppliedForLCLimit', e);
    });
  }, [refresh]);


  const getChatRoomDetails = () => {
    let chatRoomName = userName + "/" + selectedChat.receiverName

    call('POST', 'getChatRoomIdByName', { chat_room_name: chatRoomName }).then((result) => {
      console.log('success in getChatRoomIdByName ', result)
      getChatDetails({
        chat_room_id: result.chat_room_id,
        loggedInUser: userId
      })
    }).catch(e => {
      console.log('error in getChatRoomIdByName', e)
    })
  }
  useEffect(() => {
    getChatRoomDetails()
  }, [selectedChat])

  function formatDataForTable(data) {
    let tableData = []
    let row = [];
    data.forEach((item, index) => {
      row[0] = item.updatedAt ? moment(item.updatedAt).format('DD/MM/YYYY') : 'NA'
      row[1] = item.company_name ? item.company_name : 'NA'
      row[2] = item.buyerName ? item.buyerName : 'NA'
      row[3] = item.lcNo ? item.lcNo : 'NA'
      row[4] = item.lcTenor ? item.lcTenor : 'NA'
      row[5] = item.lcType ? LCTypesObject[item.lcType] : 'NA'
      row[6] = <div className='row ' >
        {item.financeStatus == 1 ?
          <a>
            <button type="button"
              onClick={() => {
                window.location = `/viewLCFinApplication`;
                localStorage.setItem("applicationDetails", JSON.stringify({
                  ...item, buyerCurrency: item.buyerCurrency ? item.buyerCurrency.split(":")[1] : null,
                  lcPurpose: LCPurposeObject[item.lcPurpose],
                  lcType: LCTypesObject[item.lcType]
                }))
              }}
              className={`border-0 mb-2 text-white approvedQuotebtn`}>
              {"Approved"}
            </button>
          </a> : item.financeStatus == 2 ?
            <a>
              <button type="button"
                onClick={() => {
                  window.location = `/viewLCFinApplication`;
                  localStorage.setItem("applicationDetails", JSON.stringify({
                    ...item, buyerCurrency: item.buyerCurrency ? item.buyerCurrency.split(":")[1] : null,
                    lcPurpose: LCPurposeObject[item.lcPurpose],
                    lcType: LCTypesObject[item.lcType]
                  }))
                }}
                className={`border-0 mb-2 text-white rejectedQuotebtn`}>
                {"Rejected"}
              </button>
            </a> : item.financeStatus == 3 ?
              <a>
                <button type="button"
                  onClick={() => {
                    window.location = `/viewLCFinApplication`;
                    localStorage.setItem("applicationDetails", JSON.stringify({
                      ...item, buyerCurrency: item.buyerCurrency ? item.buyerCurrency.split(":")[1] : null,
                      lcPurpose: LCPurposeObject[item.lcPurpose],
                      lcType: LCTypesObject[item.lcType]
                    }))
                  }}
                  className={`border-0 mb-2 text-white progressQuotebtn`}>
                  {"In Progress"}
                </button>
              </a> : item.financeStatus == 4 ?
                <a>
                  <button type="button"
                    onClick={() => {
                      window.location = `/viewLCFinApplication`;
                      localStorage.setItem("applicationDetails", JSON.stringify({
                        ...item, buyerCurrency: item.buyerCurrency ? item.buyerCurrency.split(":")[1] : null,
                        lcPurpose: LCPurposeObject[item.lcPurpose],
                        lcType: LCTypesObject[item.lcType]
                      }))
                    }}
                    className={`border-0 mb-2 text-white approvedQuotebtn`}>
                    {"Disbursed"}
                  </button>
                </a>
                :
                <a>
                  <button type="button"
                    onClick={() => {
                      window.location = `/viewLCFinApplication`;
                      localStorage.setItem("applicationDetails", JSON.stringify({
                        ...item, buyerCurrency: item.buyerCurrency ? item.buyerCurrency.split(":")[1] : null,
                        lcPurpose: LCPurposeObject[item.lcPurpose],
                        lcType: LCTypesObject[item.lcType]
                      }))
                    }}
                    className={`border-0 mb-2 text-white enableQuotebtn2`}>
                    {"View Details"}
                  </button>
                </a>}
      </div >
      tableData.push(row)
      row = []
    })
    return tableData
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('lcApplicationId', selectedChat.lcApplicationId)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))

    formdata.append("userTypeId", userTypeId)
    formdata.append("sellerId", selectedChat.sellerId)

    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="LcFinApplication" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Letter of Credit - Finance Application"}
              userTokenDetails={userTokenDetails} />

            <div className='d-flex row justify-content-between px-2' >
              <div className='card p-4 border1Blue' style={{ width: '23.22%' }} >
                <label className='font-size-15 font-wt-600 color0C0C0C' >Application in process - <label className='text-color1 font-size-15 font-wt-600' >{count || 0}</label></label>
                <div className='d-flex row' >
                  <div className='w-auto' >
                    <p className='font-size-22 font-wt-600 text-color1 p-0 m-0' >{applicationCounts.new || 0}</p>
                    <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0'>New</p>
                  </div>
                  <div className='w-auto' >
                    <p className='font-size-22 font-wt-600 text-color1 p-0 m-0' >{applicationCounts.disbursed || 0}</p>
                    <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0'>Disbursed</p>
                  </div>
                </div>
              </div>
              <div
                className='card border1Blue pl-4 d-flex justify-content-center cursor' style={{ width: '15.44%' }} >
                <div>
                  <p className='font-size-22 font-wt-600 text-48DA87 p-0 m-0' >{applicationCounts.approved || 0}</p>
                  <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0 mt-3'>Approved</p>
                </div>
              </div>
              <div
                className='card border1Blue pl-4 d-flex justify-content-center cursor' style={{ width: '15.44%' }} >
                <div>
                  <p className='font-size-22 font-wt-600 colorF1C40F p-0 m-0' >{applicationCounts.inprogress || 0}</p>
                  <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0 mt-3'>In Progress</p>
                </div>
              </div>
              <div
                className='card border1Blue pl-4 d-flex justify-content-center cursor' style={{ width: '15.44%' }} >
                <div>
                  <p className='font-size-22 font-wt-600 text-danger p-0 m-0' >{applicationCounts.rejected || 0}</p>
                  <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0 mt-3'>Rejected</p>
                </div>
              </div>
              <div style={{ width: '22.44%' }} >
              </div>
            </div>

            <div className='filter-div position-relative'>
              <Filter
                filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} />
            </div>
            <div className='mt-4'>
              {/* <NewTable disableAction={false}
                columns={[{ name: "Date", filter: true }, { name: "Supplier Name", filter: true }, { name: "Buyer Name", filter: true }, { name: "LC No.", filter: true }, { name: "LC Tenor", filter: true },
                { name: "LC Type", filter: true }, { name: "", filter: false }, { name: "", filter: false }
                ]}
                data={tableData}
                options={[
                  { name: "Chat with supplier", icon: "edit.png", onClick: (id) => intiateChat(id) }]}
              /> */}
              <NewTablev2
                columns={[{ subColumns: "Date", filter: true, subColumnStyle: { width: '8%' } },
                { subColumns: "Supplier Name", filter: true, subColumnStyle: { width: '12%' } },
                { subColumns: "Buyer Name/LC No", filter: true, subColumnStyle: { width: '12%' } },
                { subColumns: "Issuing Country", filter: true, subColumnStyle: { width: '12%' } },
                { subColumns: "Limit Type", filter: true, subColumnStyle: { width: '12%' } },
                { subColumns: "LC Tenor", subColumnStyle: { width: '10%' } }, { subColumns: "Remark", subColumnStyle: { width: '18%' } },
                { subColumns: "Action", subColumnStyle: { width: '15%' } }, { subColumns: "", subColumnStyle: { width: '3%' } }
                ]}
              >
                {tableData.map((item, index) => {
                  let chatRoomIds = item.chatRoomIds?.split(",") || []
                  let chatRoomUsers = item.chatRoomUsers?.split(",") || []
                  let openChatRoomIndx = null
                  chatRoomUsers.forEach((u, i) => {
                    if (u?.split("::")[1] / 1 == userId / 1) {
                      openChatRoomIndx = i
                    }
                  })
                  return (
                    <tr>
                      <td className='font-size-13 font-wt-500'>{item.updatedAt ? moment(item.updatedAt).format('DD/MM/YYYY') : 'NA'}</td>
                      <td className='font-size-13 font-wt-500'>{item.company_name || 'NA'}</td>
                      <td style={{ whiteSpace: 'pre-line' }} className='font-size-13 font-wt-500'>{item.buyerName || item.lcNo}</td>
                      <td style={{ whiteSpace: 'pre-line' }} className='font-size-13 font-wt-500'>{item.countryOfOriginName}</td>
                      <td style={{ whiteSpace: 'pre-line' }} className='font-size-13 font-wt-500'>{LCPurposeObject[item.lcPurpose]}</td>
                      <td style={{ whiteSpace: 'pre-line' }} className='font-size-13 font-wt-500'>{item.lcTenor + " Days"}</td>
                      <td><div
                        onClick={async () => {
                          let reqObj = {
                            userTypeId,
                            senderId: userId,
                            sellerId: item.createdBy,
                            receiverId: userId,
                            textMessage: 'Hii',
                            chat_room_name: "CHAT" + new Date().getTime(),
                            includeAdmins: true,
                            lcApplicationId: item.id,
                            receiverParties: userId,
                            dontSendInitialMsg: true
                          }
                          setshowLoader(true)
                          let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
                          setshowLoader(false)
                          getChatDetails({
                            chat_room_id: apiResp.id,
                            loggedInUser: userId
                          })
                          setSelectedChat({
                            chatRoomId: apiResp.id,
                            receiverName: `${chatRoomIds[openChatRoomIndx] || apiResp.id}`,
                            lcApplicationId: item.id,
                            parties: chatRoomUsers[openChatRoomIndx] || apiResp.parties,
                            userId: userId,
                            isChatOpen: true,
                            receiverId: userId,
                            sellerId: item.createdBy
                          })
                        }}
                      >
                        {openChatRoomIndx != null ? (
                          <div className='d-flex' >
                            <p className={`font-size-12 font-wt-500 p-0 m-0 ${item.chatRoomUnreadMsgCount > 0 ? ' w-80' : ' w-100 '}`} >
                              {item.lastMsgTime ?
                                <label className='font-wt-700 w-100 p-0 m-0' >{moment(item.lastMsgTime).format("DD/MM/YYYY | HH:mm")}</label> : null}
                              <p className='ellipsis-container' >
                                <p className='mb-0 ellipsis-text' style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: item.lastMsg }}></p>
                              </p>
                            </p>
                            {item.chatRoomUnreadMsgCount > 0 ?
                              <label
                                className='lastMsgCount'
                              >{item.chatRoomUnreadMsgCount}</label> : null}
                          </div>) : "-"}
                      </div></td>
                      <td>
                        <div className='row ' >
                          {item.financeStatus == 1 ?
                            <a>
                              <button type="button"
                                onClick={() => {
                                  window.location = `/viewLCFinApplication`;
                                  localStorage.setItem("applicationDetails", JSON.stringify({
                                    ...item, buyerCurrency: item.buyerCurrency ? item.buyerCurrency.split(":")[1] : null,
                                    lcPurpose: LCPurposeObject[item.lcPurpose],
                                    lcType: LCTypesObject[item.lcType]
                                  }))
                                }}
                                className={`border-0 mb-2 text-white approvedQuotebtn`}>
                                {"Approved"}
                              </button>
                            </a> : item.financeStatus == 2 ?
                              <a>
                                <button type="button"
                                  onClick={() => {
                                    window.location = `/viewLCFinApplication`;
                                    localStorage.setItem("applicationDetails", JSON.stringify({
                                      ...item, buyerCurrency: item.buyerCurrency ? item.buyerCurrency.split(":")[1] : null,
                                      lcPurpose: LCPurposeObject[item.lcPurpose],
                                      lcType: LCTypesObject[item.lcType]
                                    }))
                                  }}
                                  className={`border-0 mb-2 text-white rejectedQuotebtn`}>
                                  {"Rejected"}
                                </button>
                              </a> : item.financeStatus == 3 ?
                                <a>
                                  <button type="button"
                                    onClick={() => {
                                      window.location = `/viewLCFinApplication`;
                                      localStorage.setItem("applicationDetails", JSON.stringify({
                                        ...item, buyerCurrency: item.buyerCurrency ? item.buyerCurrency.split(":")[1] : null,
                                        lcPurpose: LCPurposeObject[item.lcPurpose],
                                        lcType: LCTypesObject[item.lcType]
                                      }))
                                    }}
                                    className={`border-0 mb-2 text-white progressQuotebtn`}>
                                    {"In Progress"}
                                  </button>
                                </a> : item.financeStatus == 4 ?
                                  <a>
                                    <button type="button"
                                      onClick={() => {
                                        window.location = `/viewLCFinApplication`;
                                        localStorage.setItem("applicationDetails", JSON.stringify({
                                          ...item, buyerCurrency: item.buyerCurrency ? item.buyerCurrency.split(":")[1] : null,
                                          lcPurpose: LCPurposeObject[item.lcPurpose],
                                          lcType: LCTypesObject[item.lcType]
                                        }))
                                      }}
                                      className={`border-0 mb-2 text-white approvedQuotebtn`}>
                                      {"Disbursed"}
                                    </button>
                                  </a>
                                  :
                                  <a>
                                    <button type="button"
                                      onClick={() => {
                                        window.location = `/viewLCFinApplication`;
                                        localStorage.setItem("applicationDetails", JSON.stringify({
                                          ...item, buyerCurrency: item.buyerCurrency ? item.buyerCurrency.split(":")[1] : null,
                                          lcPurpose: LCPurposeObject[item.lcPurpose],
                                          lcType: LCTypesObject[item.lcType]
                                        }))
                                      }}
                                      className={`border-0 mb-2 text-white enableQuotebtn2`}>
                                      {"View Details"}
                                    </button>
                                  </a>}
                        </div >
                      </td>
                      <td
                        style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
                        className='position-relative'>
                        <i className="fa fa-ellipsis-v cursor mt-2 ml-2"
                          onClick={() => setAction({ show: true, index })}
                          aria-hidden="true"></i>
                        {action.show && action.index === index ? (
                          <Action
                            id={index}
                            onDismiss={() => setAction({ show: false, index })}
                            options={[
                              {
                                name: "Chat With Supplier", icon: "",
                                onClick: async () => {
                                  let reqObj = {
                                    userTypeId,
                                    senderId: userId,
                                    sellerId: item.createdBy,
                                    receiverId: userId,
                                    textMessage: 'Hii',
                                    chat_room_name: "CHAT" + new Date().getTime(),
                                    includeAdmins: true,
                                    lcApplicationId: item.id,
                                    receiverParties: userId,
                                    dontSendInitialMsg: true
                                  }
                                  setshowLoader(true)
                                  let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
                                  setshowLoader(false)
                                  getChatDetails({
                                    chat_room_id: apiResp.id,
                                    loggedInUser: userId
                                  })
                                  setSelectedChat({
                                    chatRoomId: apiResp.id,
                                    receiverName: `${chatRoomIds[openChatRoomIndx] || apiResp.id}`,
                                    lcApplicationId: item.id,
                                    parties: chatRoomUsers[openChatRoomIndx] || apiResp.parties,
                                    userId: userId,
                                    isChatOpen: true,
                                    receiverId: userId,
                                    sellerId: item.createdBy
                                  })
                                }
                              }
                            ]} />
                        ) : null}
                      </td>
                    </tr>
                  )
                })}
              </NewTablev2>
              <Pagination page={page} totalCount={count} onPageChange={(p) => setPage(p)} perPage={filter.resultPerPage || 0} />
            </div>
          </main>
        </div>

      </div>
      {selectedChat.isChatOpen &&
        <div className="chatboxDivFixed">
          <ChatBoxPopUp2
            chatList={chatList}
            user_avatar={selectedChat.logo}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            parties={selectedChat.parties}
            userTokenDetails={userTokenDetails}
            onChatSend={(file, parties) => sendChatMsg(file, parties)}
            message={message}
            setTextMsg={setTextMsg}
            reloadChatList={() => getChatDetails({
              chat_room_id: selectedChat.chatRoomId,
              loggedInUser: userId
            })}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false,
                logo: ''
              })
              setRefresh(refresh + 1)
            }}
          />
        </div>
      }
    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(LCFinApplication)