import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from 'react-dropzone'
import { Line, Circle } from 'rc-progress';
import DocViewer from "react-doc-viewer";
import JSZip from "jszip";
import { convertZipFileArrayToFilePdfObjects } from "./myFunctions";

function downloadTheFile(result) {
  if (result.filebase64.includes("base64,")) {
    result["filebase64"] = result.filebase64.split("base64,")[1]
  }
  if (result.name.split(".").pop() === "png" || result.name.split(".").pop() === "PNG") {
    console.log(result.name);
    let link = document.createElement("a");
    console.log(link);
    link.download = result.name.split(".")[0] + ".png";
    link.href = 'data:application/png;base64,' + encodeURI(result.filebase64);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else if (result.name.split(".").pop() === "jpg" || result.name.split(".").pop() === "JPG") {
    let link = document.createElement("a");
    link.download = result.name.split(".")[0] + ".jpeg";
    link.href = 'data:application/jpeg;base64,' + encodeURI(result.filebase64);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else if (result.name.split(".").pop() === "pdf" || result.name.split(".").pop() === "PDF") {
    let link = document.createElement("a");
    link.download = result.name.split(".")[0] + ".PDF";
    link.href = 'data:application/pdf;base64,' + encodeURI(result.filebase64);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

function getSourceType(mime) {
  return ((mime === "png" || mime === "PNG") ? "data:image/png;base64," :
    (mime === "jpg" || mime === "JPG") ? "data:image/jpeg;base64," :
      (mime === "pdf" || mime === "PDF") ? "data:application/pdf;base64," : "")
}

function viewTheFile(result) {
  if (result.filebase64.includes("base64,")) {
    result["filebase64"] = result.filebase64.split("base64,")[1]
  }
  let mime = result.name.split(".").pop()
  let sourceType = getSourceType(mime)
  return (
    <>
      {
        (mime === "pdf" || mime === "PDF") ?
          <iframe title="Document Preview" frameborder="0" height="100%" className="col-md-12 m-0 p-0"
            src={sourceType + encodeURI(result.filebase64 ? result.filebase64 : "")}></iframe>
          :
          <img src={sourceType + encodeURI(result.filebase64 ? result.filebase64 : "")} alt={result.name} />
      }
    </>
  )
}


export const FileInput = ({ showDocName, showFileNameW100, hideViewDowdBtns, multipleDragDropEnable, onMultipleDragDrop, hideVault, name, value, onChange, error, onUploadCancel, onView, isEditable, extra_class, changeEditIconToUpload, onEyeBuPress }) => {
  console.log("selectedfile", value, name);

  const [uploadProgress, toggleUplaodProgress] = useState(!isEditable ? 100 : 0)
  const [showDoc, toggleShowDoc] = useState(false)

  let isFileSelected = value && value.name ? true : false

  useEffect(() => {
    if (isFileSelected && uploadProgress < 100) {
      setTimeout(() => {
        toggleUplaodProgress(uploadProgress + 35)
      }, 1000);
    }
  }, [value, uploadProgress])

  const onDrop = (async (acceptedFiles, fileRejections, event) => {
    // console.log("acceptedFilesss", acceptedFiles);
    if (acceptedFiles.length) {
      let tempZipConvertedFiles = []
      let indexToDelete = []
      for (let index = 0; index < acceptedFiles.length; index++) {
        const element = acceptedFiles[index];
        if (element.type.includes("zip")) {
          indexToDelete.push(index)
          const zip = new JSZip();
          try {
            const zipData = await zip.loadAsync(element);
            tempZipConvertedFiles = tempZipConvertedFiles.concat(await convertZipFileArrayToFilePdfObjects(zipData))
          } catch (error) {
            console.error("Error opening ZIP file:", error);
          }
        }
      }
      acceptedFiles = acceptedFiles.concat(tempZipConvertedFiles)
      // console.log("indexToDeleteeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee", indexToDelete, acceptedFiles);
      if (indexToDelete.length) {
        acceptedFiles = acceptedFiles.filter((i, j) => {
          if (!indexToDelete.includes(j)) {
            return true
          }
        })
      }
      // console.log("ppppppppppppppppppppppppppppppppppppppppppppppppp", acceptedFiles);
      let event = {
        target: { name: name, files: acceptedFiles },
        persist: () => console.log("onDrop called")
      }
      if (multipleDragDropEnable) {
        onMultipleDragDrop(event)
      }
      else {
        onChange(event)
      }
      toggleUplaodProgress(0)
    }
  })

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <>
      {showDoc ? (
        <div className={"modal d-block show "} id="docPreview">
          <div className="modal-dialog modal-fullscreen " id="parent_class">
            <div className="modal-content bg-transparent">
              <div className="modal-header border-0 px-4 pe-5 d-flex justify-content-between" style={{ background: "rgba(0,0,0,0.3)" }}>

                <div>
                  <a className="cursor close" onClick={() => { toggleShowDoc(false) }}><img src={"assets/images/pdf-back.png"} alt="Back" height={25} width={25} /></a>
                </div>
                <div>
                  <h6 className="modal-title text-color1 text-capitalize">{value.name}</h6>
                </div>
                <div className="d-flex gap-3">
                  <a className="cursor"><img src={"assets/images/download-pdf.png"} alt="Back" height={25} width={25} /></a>
                  <a className="cursor"><img src={"assets/images/printer.png"} alt="Back" height={25} width={25} /></a>
                  <a className="cursor"><img src={"assets/images/share.png"} alt="Back" height={25} width={25} /></a>
                </div>


              </div>
              <div className="modal-body p-0">
                <div className="d-flex" style={{ height: "670px" }}>
                  <div className="col-md-2 " style={{ background: "rgba(0,0,0,0.3)" }}>

                  </div>
                  <div className=" col-md-8 mx-auto pt-3" >
                    <div className="d-flex m-0 p-0 bg-transparent" >
                      <div className={"tab-pane active show col-md-12"} style={{ height: "650px" }}>
                        {viewTheFile(value)}
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      ) : null
      }
      <div className={"custom-file-upload" + (error ? " border-danger" : "") + (extra_class ? (" " + extra_class) : "")}>
        {isFileSelected ? (
          <>
            {uploadProgress >= 100 ? (
              <div
                className="selectedFile-div align-items-center"
              >
                <div className={`d-flex align-items-center ${showFileNameW100 ? ' w-100 ' : ' w-65 '} `} >
                  <img className="mr-3 " src={"assets/images/pdf_file.png"} />
                  <label className="file-text m-0 truncate">{showDocName ? value.doc_name : value.name}</label>
                </div>
                <div>
                  {isEditable ? (
                    <label
                      onClick={onUploadCancel}
                      className="cursor m-0">
                      <img
                        style={changeEditIconToUpload ? { transform: "rotate(180deg)" } : {}}
                        src={changeEditIconToUpload ? "assets/images/download_light.png" : "assets/images/edit.png"} />
                    </label>
                  ) : null}
                  {hideViewDowdBtns ? null : (
                    <>
                      <img
                        style={
                          {
                            width: "22px",
                            height: "22px"
                          }
                        }
                        src={"assets/images/show_pass.png"}
                        className='cursor mx-2' onClick={() => {
                          if (onEyeBuPress) {
                            onEyeBuPress()
                          } else {
                            toggleShowDoc(true)
                          }
                        }}
                      />
                      <img src={"assets/images/download_light.png"} className='cursor'
                        onClick={() => downloadTheFile(value)}
                      />
                    </>
                  )}
                </div>
              </div>
            ) : (
              <>
                <Line percent={uploadProgress}
                  style={{ marginLeft: '1%', width: '85%' }}
                  strokeWidth={2} strokeColor="#1B94B7" />
                <img
                  onClick={onUploadCancel}
                  src={"assets/images/cancel-icon.png"} color={"#fff"} />
              </>
            )}
          </>
        ) : (
          <>
            {isEditable ? (
              <>
                <div
                  {...getRootProps()}
                  className={`dragDrop-div ${isDragActive ? "dragDrop-div-active" : ""} ${hideVault ? ' w-50 ' : ' '}`}
                >
                  <img src={"assets/images/drag.png"} />
                  <span className="truncate text-dark" >Drag & Drop</span>
                </div>
                <label
                  className={`browse-div cursor text-center m-0 ${hideVault ? ' w-50 ' : ' '}`}
                  for={name}
                >
                  <img src={"assets/images/folder.png"} />
                  Browse
                </label>
                {hideVault ? null : <div
                  className="vault-div"
                >
                  <img src={"assets/images/vault-white.png"} color={"#fff"} />
                  Vault
                </div>}
              </>
            ) :
              <label className="font-wt-600">No Document Found</label>}
          </>
        )
        }
      </div >
      <input
        {...getInputProps()}
        id={name}
        onChange={(e) => {
          // if (multipleDragDropEnable) {
          //   onMultipleDragDrop(e)
          // }
          // else {
          //   onChange(e);
          // }
          // console.log("fffffffffffffffffffffffff", e.target?.files);
          if (e.target?.files?.[0]) {
            let collectedFiles = []
            for (let index = 0; index < Object.keys(e.target?.files).length; index++) {
              const element = Object.keys(e.target?.files)[index];
              collectedFiles.push(e.target.files[element])
            }
            onDrop(collectedFiles)
          }
          toggleUplaodProgress(0)
        }}
        type="file" className={"d-none"}
        name={name}
      />
    </>)
}