import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import call from '../../../service';
import { GetCache, most_used_currencies, SetCache } from '../../../utils/myFunctions';
import { NewTable } from '../../../utils/newTable';
import Filter from '../../InvoiceDiscounting/components/Filter';
import Pagination from '../../InvoiceDiscounting/contract/components/pagination';
import HeaderV2 from '../../partial/headerV2';
import SideBarV2 from '../../partial/sideBarV2';
import MultipleSelect from '../../../utils/MultipleSelect';
import toastDisplay from '../../../utils/toastNotification';
import SubAdminPopup from '../SubAdminPopup';

const overallCounts = [
  { name: "Applied", alt: 'applied', class: ' text-F1C40F ' },
  { name: "Approved", alt: 'approved', class: ' text-48DA87 ' },
  // { name: "Inprogress", alt: 'inprogress', class: ' text-F1C40F ' },
  // { name: "Disbursed", alt: 'disbursed', class: ' text-48DA87 ' },
  { name: "Rejected", alt: 'rejected', class: ' colorFF7B6D ' }
]

const InvoiceFinance = ({ userTokenDetails, navToggleState }) => {

  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [tableData, setTableData] = useState([])
  const [showLoader, setshowLoader] = useState(false)
  const [filterCount, setFilterCount] = useState({})


  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState(Object.keys(GetCache("invoiceFinanceFilter")).length ? GetCache("invoiceFinanceFilter") : { resultPerPage: 10, search: serarchParam ? serarchParam : '' })
  const [filterData, setFilterData] = useState(GetCache("invoiceFinanceFilterData"))
  const [filteredSearch, setFilteredSearch] = useState(GetCache("invoiceFinanceSearchFilterData"))

  const [transactionPopup, toggleTransactionPopup] = useState({ show: false, data: [] })
  const [salesPerson, setSalesPerson] = useState([])
  const [data, setdata] = useState({})
  const [subadminPopup, togglesubadminPopup] = useState({ data: [], show: false, userId: '' })

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;
  const userPermissionsForSubAdmin = JSON.parse(userTokenDetails.UserAccessPermission || "{}")
  let onlyShowForUserId = userPermissionsForSubAdmin?.mainAdmin ? undefined : userId

  useEffect(() => {
    SetCache("invoiceFinanceSearchFilterData", filteredSearch)
  }, [filteredSearch])
  useEffect(() => {

    if (onlyShowForUserId) {
      let reqObj = {
        parentId: onlyShowForUserId
      }
      console.log('onlyshowfor userid', reqObj);
      call("POST", 'getSubAdminUser', reqObj).then(res => {
        console.log('onlyshowfor userid', res);
        setshowLoader(false)
        setSalesPerson(res.data)
      }).catch(err => setshowLoader(false))
    } else {
      call("POST", 'getSubAdminUser', {}).then(res => {
        setshowLoader(false)
        setSalesPerson(res.data)
      }).catch(err => setshowLoader(false))
    }
  }, [])
  const getAdminInvoiceFinance = () => {
    SetCache("invoiceFinanceFilterData", filterData)
    SetCache("invoiceFinanceFilter", filter)
    setshowLoader(true)
    let objectAPI = {
      "userId": userId,
      "userEmail": userEmail,
      "userTypeId": userTypeId,
      "type": 'all',
      currentPage: page,
      atFinanceStage: true,
      ...filter
    }
    objectAPI["onlyShowForUserId"] = onlyShowForUserId
    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push((element.accordianId === "status" || element.accordianId === "timeLeft") ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
        else if (element.type === "minMaxDate") {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }
    if (data.subadmins && data.subadmins?.length) {
      delete objectAPI["onlyShowForUserId"]
      objectAPI["subadminIds"] = data.subadmins
    }

    call('POST', 'getInvoiceDiscountingListForAdmin', objectAPI).then((result) => {
      console.log('running getInvoiceDiscountingListForAdmin api-->', result);
      setDbData(result.listData);
      setTableData(formatDataForTable(result.listData))
      setCount(result.countData);
      setFilterCount(result.filterCount)
      setshowLoader(false)
    }).catch((e) => {
      // console.log('error in getInvoiceDiscountingListForAdmin', e);
      setshowLoader(false)
    });
  }
  useEffect(() => {
    getAdminInvoiceFinance()
  }, [refresh, page, filterData, data.subadmins, salesPerson])

  useEffect(() => {
    let isCacheExist = localStorage.getItem('invoiceFinanceFilterData') != "{}"
    let isSearchCacheExist = localStorage.getItem('invoiceFinanceSearchFilterData') != "{}"

    call('POST', 'getInvoiceDiscountingFiltersForAdmin', { userId, atFinanceStage: true }).then(res => {
      console.log("getInvoiceDiscountingFiltersForAdmin then", res);
      if (!isCacheExist) {
        setFilterData(res)
      }
      if (!isSearchCacheExist) {
        setFilteredSearch(res)
      }
    }).catch(err => { })
  }, [])

  async function handleTransactionPopup(itemData) {
    setshowLoader(true)
    let apiResp = await call('POST', 'getTransactionHistoryForInvoiceLimit', {
      buyerId: itemData.id, applicationId: itemData.applicationId,
      invRefNo: itemData.reference_no
    })
    // console.log("getTransactionHistoryForInvoiceLimit api resp====>", itemData, apiResp);
    setshowLoader(false)
    toggleTransactionPopup({ show: true, data: apiResp })
  }

  function formatDataForTable(data) {
    let tableData = []
    let row = []
    for (let index = 0; index < data.length; index++) {
      const item = data[index];

      let allDeniedCount = 0
      let allApprovedCount = 0

      if (item.buyers_credit) {
        for (let index = 0; index < JSON.parse(item.buyers_credit).length; index++) {
          const j = JSON.parse(item.buyers_credit)[index];
          if (j.financierAction === "deny") {
            allDeniedCount += 1
          }
          else {
            allApprovedCount += 1
          }
        }
      }

      row[0] = moment(item.invoiceApplicationCreatedAt).format('DD/MM/YYYY')

      row[1] = item.reference_no || "NA"
      row[2] = item.stenn_deal_id || "NA"
      row[3] = <label className='cursor font-size-13 font-wt-600'
        onClick={() => {
          window.location = `/supplierDetails?id=${item.user_id}`
        }}
      >{item.supplierName} </label>
      row[4] = <label className='cursor font-size-13 font-wt-600'
        onClick={() => {
          window.location = `/buyerManagement?viewBuyerId=${item.id}&viewUserTypeId=19&viewUserId=${item.user_id}`
        }}
      > {item.buyerName}</label>
      row[5] = <div class="dropdown w-100" >
        <label class="font-wt-600 font-size-13 cursor" onClick={() => {
          togglesubadminPopup({
            show: true,
            data: [],
            userId: item.user_id
          })
        }}>
          {item.leadAssignToName || '-'}
        </label>

      </div>
      row[6] = item.lenderName
      row[7] = item.selectedQuote?.financeLimit ? (item.selectedQuote?.financeLimit + " " + item.selectedQuote?.financeLimitCurrency) : "NA"
      // row[7] = item.invoiceStatus / 1 == 5 ?
      //   <button type="button"
      //     onClick={() => { handleTransactionPopup(item) }}
      //     class={`rejected text-white border-0 `}>
      //     {"Rejected"}
      //   </button> :
      //   item.invoiceStatus / 1 == 3 ?
      //     <button type="button"
      //       onClick={() => { handleTransactionPopup(item) }}
      //       class={`approved text-white border-0 `}>
      //       {"Approved"}
      //     </button> : item.invoiceStatus / 1 == 6 ? <button type="button"
      //       onClick={() => { handleTransactionPopup(item) }}
      //       class={`inprogress text-white border-0 `}>
      //       {"Inprogress"}
      //     </button> : item.invoiceStatus / 1 == 4 ?
      //       <button type="button"
      //         onClick={() => { handleTransactionPopup(item) }}
      //         class={`approved text-white border-0 `}>
      //         {"Disbursed"}
      //       </button> : <button type="button"
      //         onClick={() => { handleTransactionPopup(item) }}
      //         class={`inprogress text-white border-0 `}>
      //         {"Applied"}
      //       </button>

      row[8] = item.invoiceStatus / 1 == 5 ?
        <button type="button"
          onClick={() => { handleTransactionPopup(item) }}
          class={`rejected text-white border-0 `}>
          {"Rejected"}
        </button> :
        (item.invoiceStatus / 1 == 3 || item.invoiceStatus / 1 == 4 || item.invoiceStatus / 1 == 6) ?
          <button type="button"
            onClick={() => { handleTransactionPopup(item) }}
            class={`approved text-white border-0 `}>
            {"Approved"}
          </button> : <button type="button"
            onClick={() => { handleTransactionPopup(item) }}
            class={`inprogress text-white border-0 `}>
            {"Applied"}
          </button>

      let isBuyerSignedAgreement = (item.frameworkBuyerSign || item.exhibitBuyerSign || item.noaBuyerSign)
      let isExporterSignedAgreement = (item.frameworkExporterSign || item.exhibitExporterSign || item.noaExporterSign)
      let isFinancierSignedAgreement = (item.frameworkFinancierSign || item.exhibitFinancierSign || item.noaFinancierSign)

      if (isBuyerSignedAgreement || isExporterSignedAgreement || isFinancierSignedAgreement) {
        row[99] = <p className="font-size-12 text-color-value ml-3">
          <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" mr-2"><b>
            {`Application signed by
              ${isBuyerSignedAgreement ? ' Buyer,' : ''}
              ${isExporterSignedAgreement ? ' Exporter,' : ''}
              ${isFinancierSignedAgreement ? ' Financier' : ''}`}</b> </span>
        </p>
      }
      else if (item.frameworkDoc || item.exhibitDoc || item.noaDoc) {
        row[99] = <p className="font-size-12 text-color-value ml-3">
          <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" mr-2"><b>
            Application sent by financier</b> </span>
        </p>
      }
      tableData.push(row)
      row = []
    }
    return tableData
  }

  function openApplication(indx, tabIndx) {
    let item = dbData[indx]
    window.location = `/sign-agreement`
    localStorage.setItem("item", JSON.stringify(item))
    localStorage.setItem("isAdminUser", true)
    localStorage.setItem("defaultSetTab", tabIndx)

    // setting manual user id & email for user
    localStorage.setItem("manualUserId", item.user_id)
    localStorage.setItem("manualUserEmail", item.supplierEmailId)
    localStorage.setItem("headerBreadCum", "Invoice Discounting > Finance > Application Details")
  }

  async function downloadCSVFile() {
    try {
      let csvString = "Date,Invoice No,Stenn Deal Id,Supplier,Buyer,Financer,Limit Available,Status\r\n"
      for (let i = 0; i < dbData.length; i++) {
        let element = dbData[i]
        let rowString = `${moment(element.invoiceApplicationCreatedAt).format('DD/MM/YYYY')},
          ${element.reference_no || 'NA'},
          ${element.stenn_deal_id || 'NA'},
          ${element.supplierName || 'NA'},
          ${element.buyerName || 'NA'},
          ${element.lenderName || 'NA'},
          ${element.selectedQuote?.financeLimit ? (element.selectedQuote?.financeLimit + " " + element.selectedQuote?.financeLimitCurrency) : "NA"},
          ${element.invoiceStatus / 1 == 5 ? 'Rejected' :
            (element.invoiceStatus / 1 == 3 || element.invoiceStatus / 1 == 4 || element.invoiceStatus / 1 == 6) ?
              "Approved" : "Applied"}\r\n`
        rowString = rowString.replace(/(\n)/gm, "")
        csvString += rowString
      }
      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', `InvoiceFinanceList.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log("error in downloadCSVFile", error);
    }
  }

  const handleMultiSelectchange = (e, name, val, singleSelect) => {
    if (singleSelect) {
      setdata({
        ...data,
        [name]: e?.[0]?.[val] ? e.reverse()?.[0]?.[val] : null
      })
    }
    else {
      setdata({
        ...data,
        [name]: Array.isArray(e) ? e.map((x) => x[val]) : []
      });
    }
  };
  const updateLeadAssignedTo = (leadAssignedName, userId) => {
    call('POST', 'updateLeadAssignedTo', { leadAssignedName, userId }).then(result => {
      toastDisplay("Lead updated", "success")
      getAdminInvoiceFinance()
    }).catch(e => {
      toastDisplay("Failed to assign lead to " + leadAssignedName, "error")
    })
  }
  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <SubAdminPopup togglesubadminpopup={togglesubadminPopup} subadminpopup={subadminPopup} setShowLoader={setshowLoader} />

      <div className={`modal fade ${transactionPopup.show && "show"}`} style={transactionPopup.show ? { display: "block", "zIndex": '100001' } : {}}>
        <div className="modal-dialog modal-md mr-0 my-0">
          <div className="modal-content submitmodal pb-4"
          >

            <div className="modal-header border-0">
              <div className="w-100 d-flex align-items-center justify-content-between">
                <label
                  className="font-size-16 font-wt-600 text-color-value mx-3"
                >Transaction History</label>
                <div className="modal-header border-0">
                  <button type="button" className="btn-close" aria-label="Close" onClick={() => toggleTransactionPopup({ show: false, data: [] })}></button>
                </div>
              </div>
            </div>

            <div className="modal-body px-4">
              {transactionPopup.data.length ? transactionPopup.data.map((item, index) => {
                return (
                  <div className='d-flex flex-row ml-3'>
                    <div className="progressBarContainer">
                      <div className="progressBarInnerCircle">
                      </div>
                    </div>
                    <div className='pl-4 pt-3'>
                      <p className='font-size-14 text-color1 font-wt-500 mb-0'>{item.action}</p>
                      <p className='font-size-14 text-color-label font-wt-500 mb-0'>{item.date}</p>
                      <p className='font-size-14 text-color-label font-wt-500 mb-0'>{item.time}</p>
                    </div>
                  </div>
                )
              }) :
                null}
            </div>

          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="invoiceFinance" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Invoice Discounting > Finance"}
              userTokenDetails={userTokenDetails} />

            <div className='mt-4' style={{}} >
              <div className='d-flex justify-content-end p-0 '>
                <div className="col-md-3">
                  <div className='col-md-12 p-0'>
                    <MultipleSelect
                      Id="Select User"
                      Label="Select User"
                      selectedvalue="Select Requirement"
                      optiondata={salesPerson}
                      onChange={(e) => handleMultiSelectchange(e, "subadmins", "id")}
                      value={data.subadmins ? data.subadmins : []}
                      name="subadmins"
                      labelKey={"contact_person"}
                      valKey={"id"}
                      customStyles={{
                        backgroundColor: '#DEF7FF',
                        borderRadius: '10px'
                      }}
                      isCheckableList={true}
                    />
                  </div>
                </div>
              </div>
              <div className='row mb-3'>
                {overallCounts.map((i, j) => {
                  return (
                    <div className='w-15 cursor'
                      onClick={() => {
                        let temp = filterData
                        for (let index = 0; index < temp["Status"]["data"].length; index++) {
                          const element = temp["Status"]["data"][index];
                          if (index / 1 == j / 1) {
                            element["isChecked"] = true
                          }
                          else {
                            element["isChecked"] = false
                          }
                        }
                        temp["Status"]["isFilterActive"] = true
                        setFilterData({ ...temp })
                      }}>
                      <div className='card h-100 dashboard-card shadow-sm align-items-center cursor'>
                        <label className={`cursor value font-wt-600 ${i.class}`}>{filterCount[i.alt] || "NA"}</label>
                        <label className={'cursor font-size-14 font-wt-600 text-color-label'}>{i.name}</label>
                      </div>
                    </div>
                  )
                })}
              </div>

              <div className='filter-div'>
                <Filter
                  filteredSearch={filteredSearch}
                  setFilteredSearch={setFilteredSearch}
                  showDownloadIcon={true} onDownloadClick={downloadCSVFile}
                  filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                  showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} />
              </div>

              <div
                style={{}}>
                <NewTable disableAction={false}
                  columns={[{
                    name: "Date", filter: true,
                    filterDataKey: "Date",
                    sort: [
                      { name: "Latest First", selected: filter.sortDateBy === "DESC", onActionClick: () => { setFilter({ ...filter, sortDateBy: 'DESC', sortBuyerName: false, sortExpName: false }); setRefresh(refresh + 1) } },
                      { name: "Oldest First", selected: filter.sortDateBy === "ASC", onActionClick: () => { setFilter({ ...filter, sortDateBy: "ASC", sortBuyerName: false, sortExpName: false }); setRefresh(refresh + 1) } }]
                  },
                  {
                    name: "Invoice No", filter: false
                  },
                  {
                    name: "Stenn Deal Id", filter: false
                  },
                  {
                    name: "Supplier", filter: true,
                    filterDataKey: "Exporter Name",
                    sort: [
                      { name: "Sort A-Z", selected: filter.sortExpName === "ASC", onActionClick: () => { setFilter({ ...filter, sortExpName: 'ASC', sortDateBy: false, sortBuyerName: false }); setRefresh(refresh + 1) } },
                      { name: "Sort Z-A", selected: filter.sortExpName === "DESC", onActionClick: () => { setFilter({ ...filter, sortExpName: "DESC", sortDateBy: false, sortBuyerName: false }); setRefresh(refresh + 1) } }]
                  },
                  {
                    name: "Buyer", filter: true,
                    filterDataKey: "Buyer Name",
                    sort: [
                      { name: "Sort A-Z", selected: filter.sortBuyerName === "ASC", onActionClick: () => { setFilter({ ...filter, sortBuyerName: 'ASC', sortDateBy: false, sortExpName: false }); setRefresh(refresh + 1) } },
                      { name: "Sort Z-A", selected: filter.sortBuyerName === "DESC", onActionClick: () => { setFilter({ ...filter, sortBuyerName: "DESC", sortDateBy: false, sortExpName: false }); setRefresh(refresh + 1) } }]
                  },
                  {
                    name: "Admin", filter: true,
                    filterDataKey: "Admins"
                  },
                  {
                    name: "Financer", filter: true, filterDataKey: "Financier Selected"
                  },
                  {
                    name: "Limit Available", filter: false
                  },
                  {
                    name: "Status", filter: true, filterDataKey: "Status", sort: []
                  }
                  ]}
                  data={tableData}
                  options={[
                    { name: "Application", onClick: (indx) => openApplication(indx, 0) },
                    { name: "Documents", onClick: (indx) => openApplication(indx, 1) },
                    { name: "Quote", onClick: (indx) => openApplication(indx, 2) },
                    { name: "Disbursement", onClick: (indx) => openApplication(indx, 3) },
                    { name: "Chat with Supplier", onClick: () => null },
                    { name: "Chat with Buyer", onClick: () => null }
                  ]}

                  filterData={filterData}
                  setFilterData={setFilterData}
                  filteredSearch={filteredSearch}
                  setFilteredSearch={setFilteredSearch}
                />
              </div>
              <Pagination perPage={filter.resultPerPage || 10} page={page} totalCount={count} onPageChange={(p) => setPage(p)} />
            </div>
          </main>
        </div>
      </div>
    </>)
}

const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(InvoiceFinance)