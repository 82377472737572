
import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import HeaderV2 from "../partial/headerV2";
import SideBarV2 from "../partial/sideBarV2";
import toastDisplay from "../../utils/toastNotification";
import { astrix, convertImageToPdf, decryptData, getDocDetails, getInvoiceTotalOtherCharges, getInvoiceTotalCharges, most_used_currencies, finLogoByUserId, getTotalCharges, dataURItoBlob, getBGTotalOtherCharges, getBGTotalCharges, most_used_currencies_with_percentage, handleFileCommonFunction, handleMultipleFile, mergeMultiplePdfs } from "../../utils/myFunctions";
import call from '../../service';
import config from "../../config.json"


import { useHistory } from "react-router";
import { AddMoreDocumentHandler } from "../addMoreDocumentHandler";
import { ToastContainer } from "react-toastify";
import { reviewBG, reviewBusiness, reviewOther } from "./BGGetConfirmation";
import NewTablev2 from "../../utils/newTablev2";
import { FileInput } from "../../utils/FileInput";
import { DocumentViewer } from "../../utils/documentViewer";
import { DocumentDownloader } from "../../utils/DocumentDownloader";
import SignDocument from "../InvoiceDiscounting/components/SignDocument";
import ChatBoxPopUp2 from "../chatRoom/components/ChatBoxPopUp2";
import FinanceInvoiceModal from "../InvoiceDiscounting/contract/components/financeinvoiceModal";
import { InputForTable, NewSelect, SelectForTable } from "../../utils/newInput";
import moment from "moment";

const tabLabels = [
  { name: "Application Form" },
  { name: "Documents" }
]

const BGFinancierSendTermSheet = ({ userTokenDetails, navToggleState }) => {

  let bgQuoteDetails = JSON.parse(localStorage.getItem("bgQuoteDetails"))

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;
  const user_avatar = userTokenDetails.userAvatar ? userTokenDetails.userAvatar : null
  const user_name = userTokenDetails.userName ? userTokenDetails.userName : null

  const queryParams = new URLSearchParams(window.location.search)
  const buyer = queryParams.get("buyer")
  const onlyView = queryParams.get("onlyView")
  const isAdmin = localStorage.getItem("isAdmin")
  const defaultTabForAdmin = localStorage.getItem("defaultTabForAdmin")
  let history = useHistory()


  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)

  const [showAddBuyerForm, toggleAddBuyerForm] = useState(false)
  const [tab, setTab] = useState(1)
  const [finActionTab, setFinancierActionTab] = useState(0)
  const [modal, setModal] = useState(false);
  const [tooltip, setTooltip] = useState(false);
  const [signdoc, setSigndoc] = useState(false);
  const [data, setData] = useState({
    invoiceAmountCurrency: "USD", invoiceAmount: 50000, termsOfPaymentInDays: 30,
    confirmationRateCurrency: '%', ocUnit0: '%', offerValidity: moment().add(1, "month").format("YYYY-MM-DD")
  })
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)
  const [addMoreDoc, setAddMoreDoc] = useState([null])
  const [recommendedFinanciers, setRecommendedFinanciers] = useState([])
  const [additionalReqDetails, setAdditionalReqDetails] = useState([])
  const [overlay, setOverlay] = useState(false);
  const [enableStennQuote, toggleStennQuote] = useState(false)
  const [seeRateCalculator, toggleSeeRateCalculator] = useState(false)
  const [financiers, setFinanciers] = useState([])
  const [moreDocsArray, setMoreDocsArray] = useState([])
  const [availableTermSheets, setAvailableTermSheets] = useState([])
  const [tableDocsData, setTableDocsData] = useState([])
  const [multInvPo, setMultInvPo] = useState([])

  const [viewDoc, toggleViewDoc] = useState({ show: false, doc: {} })
  const [dowdDoc, toggleDowdDoc] = useState({ show: false, doc: {} })

  const [quoteView, setQuoteView] = useState("card")

  const quoteCardRef = useRef([])
  const [otherCharges, setOtherCharges] = useState([null])

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [refresh, setRefresh] = useState(0)
  const [applicationSubmitPopup, toggleApplicationSubmitPopup] = useState({ show: false, data: {} })

  const [quoteScreen, toggleQuoteScreen] = useState({ show: false })
  const [currencyData, setCurrencyData] = useState(most_used_currencies_with_percentage)

  const [otherDocs, setOtherDocs] = useState([null])


  const scrollLeft = () => {
    let cardOffsetWidth = quoteCardRef.current[0].offsetWidth
    let containerRef = document.getElementById("scrollContainerId")
    // console.log("letCardOffsetWidthhhhhhhhhhhhh", cardOffsetWidth);
    containerRef.scrollTo({ left: -cardOffsetWidth * 4, behavior: 'smooth' })
  };

  const scrollRight = () => {
    let cardOffsetWidth = quoteCardRef.current[0].offsetWidth
    let containerRef = document.getElementById("scrollContainerId")
    containerRef.scrollTo({ left: cardOffsetWidth * 4, behavior: 'smooth' })
  };

  async function loadData() {
    setshowLoader(true)
    let tempLimitDocsDetails = {}
    let limitDocs = await call('POST', 'getInvLimitApplicationDocs', { userId, quoteId: bgQuoteDetails.limit_id, fetchBGDocs: true })
    for (let index = 0; index < limitDocs.data.length; index++) {
      const element = limitDocs.data[index];
      tempLimitDocsDetails[element.label] = await getDocDetails(element.docId)
      if (element.attributes) {
        tempLimitDocsDetails = { ...tempLimitDocsDetails, ...element.attributes }
      }
    }
    setData({
      ...data, ...bgQuoteDetails.details, ...tempLimitDocsDetails, selectedLenderName: bgQuoteDetails.selectedLenderName,
      financierQuotes: JSON.parse(bgQuoteDetails.financierQuotes)
    })
    call('POST', 'getRecommendedFinanciers', { forBG: true }).then((result) => {
      console.log("getRecommendedFinanciers", result);
      setRecommendedFinanciers(result)
    }).catch((e) => {
      console.log('error in getRecommendedFinanciers', e);
    })
    setshowLoader(false)
  }

  useEffect(() => {
    loadData()
  }, [])


  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('bgApplicationId', selectedChat.bgApplicationId)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))

    formdata.append("userTypeId", userTypeId)
    formdata.append("sellerId", selectedChat.sellerId)

    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  const handleChange = async (event) => {
    event.persist()
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }

  async function submitDocument() {

    if (!(data.termSheet?.name && data.termSheet.signatureId)) {
      return toastDisplay("Upload term sheet document & sign it to continue", "info")
    }

    setshowLoader(true)
    let formData = new FormData();

    Object.keys(data).forEach((key) => {
      if (!key.toLowerCase().includes("document") && key != "userId") {
        formData.append(key, data[key]);
      }
    })

    formData.append('sellerId', bgQuoteDetails.createdBy);
    formData.append('limit_id', bgQuoteDetails.limit_id);
    formData.append('userId', userId);
    if (data.termSheet?.signatureId) {
      formData.append('signatureId', data.termSheet.signatureId);
    }
    // formData.append("termSheetSignType", data.signTypeDigital ? "Digital" : "Wet")

    call('POST', 'sendBgTermSheet', formData).then(async (result) => {
      setshowLoader(false)
      toggleApplicationSubmitPopup({ show: true, msg: 'Termsheet sent successfully' })
    }).catch((error) => {
      setshowLoader(false)
      toastDisplay(error, "error")
    })
  }

  async function submitForm(value, type) {
    if (value != 'deny' && !(data.confirmationRateCurrency && data.confirmationRate && data.offerValidity)) {
      return toastDisplay("Fill the quote details to continue", "info")
    }
    setshowLoader(true);
    let financierAction = '';
    let financierActionType = ""
    if (value && value === 'deny') {
      financierAction = 'deny'
      financierActionType = type
    }
    else {
      financierAction = 'Approved'
    }
    let formData = new FormData();

    Object.keys(data).forEach((key) => {
      if (!key.toLowerCase().includes("document") && key != "userId") {
        formData.append(key, data[key]);
      }
    })

    formData.append("userName", userName)
    formData.append("lenderId", userId);
    formData.append("limit_id", bgQuoteDetails.limit_id);
    formData.append("financierAction", financierAction);
    formData.append("financierActionType", financierActionType);
    formData.append("sellerId", bgQuoteDetails.createdBy)

    let tempOtherCharges = []

    for (let index = 0; index < otherCharges.length; index++) {
      if (data[`ocName${index}`] && data[`ocAmount${index}`] && data[`ocUnit${index}`]) {
        tempOtherCharges.push({
          name: data[`ocName${index}`],
          amount: data[`ocAmount${index}`],
          unit: data[`ocUnit${index}`],
          other: data[`ocOther${index}`]
        })
      }
    }

    formData.append("otherCharge", JSON.stringify(tempOtherCharges));
    if (data.termSheet?.name) {
      formData.append('termSheet', data.termSheet)
    }
    for (let index = 0; index < otherDocs.length; index++) {
      if (data[`otherDocument${index}`]?.name) {
        formData.append(`otherDocument${index}`, data[`otherDocument${index}`])
        if (data[data[`otherDocument${index}`].signatureId]) {
          formData.append(`otherDocumentSignId${index}`, data[`otherDocumentSignId${index}`])
        }
      }
    }

    call('POST', 'sendBgQuote', formData).then(async (result) => {
      toggleApplicationSubmitPopup({ show: true, msg: value === "deny" ? "Application rejected successfully" : "Quote sent successfully" })
      setshowLoader(false)
    }).catch((error) => {
      setshowLoader(false)
      toastDisplay(error, "error")
    })
  }

  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <DocumentViewer show={viewDoc.show} value={viewDoc.doc} dismiss={() => toggleViewDoc({ show: false, doc: {} })} />
      <DocumentDownloader show={dowdDoc.show} value={dowdDoc.doc} dismiss={() => toggleDowdDoc({ show: false, doc: {} })} />
      {signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
        setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />}
      {selectedChat.isChatOpen &&
        <div className="chatboxDivFixed">
          <ChatBoxPopUp2
            chatList={chatList}
            user_avatar={selectedChat.logo}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            parties={selectedChat.parties}
            userTokenDetails={userTokenDetails}
            onChatSend={(file, parties) => sendChatMsg(file, parties)}
            message={message}
            setTextMsg={setTextMsg}
            reloadChatList={() => getChatDetails({
              chat_room_id: selectedChat.chatRoomId,
              loggedInUser: userId
            })}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false,
                logo: ''
              })
              setRefresh(refresh + 1)
            }}
          />
        </div>
      }
      {applicationSubmitPopup.show ? (
        <FinanceInvoiceModal limitinvoice={applicationSubmitPopup} closeSuccess={() => {
          toggleApplicationSubmitPopup({ show: false })
          window.location = 'bgFinancierQuote'
        }}>
          <div className='text-center'>
            <img src='/assets/images/successful_icon.svg' />
            {/* <div className="my-4">
              <img style={{}} src="assets/images/greetings_congratulation.svg" />
            </div> */}
            <p className="m-0 p-0 px-5 font-size-16" >{applicationSubmitPopup.msg}</p>
          </div>
        </FinanceInvoiceModal>
      ) : null}
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="bg_quote_details" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Other Financial > Bank Guarantee > Send Termsheet"}
              userTokenDetails={userTokenDetails} />
            <a className="cursor"
              onClick={() => {
                if (quoteScreen.show) {
                  toggleQuoteScreen({ show: false })
                }
                else {
                  history.go(-1)
                }
              }}
            ><img className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>

            <div>
              {quoteScreen.show ? null : <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                {tabLabels.map((item, index) => {
                  return (
                    <li>
                      <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                        onClick={() => {
                          if (index < tab) {
                            setTab(index)
                          }
                        }}
                      >
                        {item.name}</a>
                    </li>
                  )
                })}
              </ul>}

              <div className="card mt-1">
                {tab === 0 && !quoteScreen.show ? (
                  <div className="d-flex row px-5 pt-5 pb-4" >
                    <div className='w-100'>
                      <label className='font-wt-500 font-size-16'><u>Business Details</u></label>
                      <div className='row'>
                        {reviewBusiness.map((item) => {
                          return (
                            <div className="col-md-6 pe-5">
                              {item.name ?
                                <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[`${item.val}`] ? (item.unit ? `${data[`${item.unit}`]} ` : "") + (data[`${item.val}`]) : "-"} {item.after && data[item.after] && ` ${data[item.after]} `}</span> </p>
                                : null} </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className='w-100 mt-4'>
                      <label className='font-wt-500 font-size-16'><u>BG Details</u></label>
                      <div className='row'>
                        <div className="col-md-6 pe-5">
                          <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">BG type</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" >{data.domesticBG ? "Domestic" : 'Foreign'}</span> </p>
                        </div>
                        {reviewBG.map((item) => {
                          return (
                            <div className="col-md-6 pe-5">
                              {item.name ?
                                <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[`${item.val}`] ? (item.unit ? `${data[`${item.unit}`]} ` : "") + (data[`${item.val}`]) : "-"} {item.after && data[item.after] && ` ${data[item.after]} `}</span> </p>
                                : null} </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className='w-100 mt-4'>
                      <label className='font-wt-500 font-size-16'><u>Other Details</u></label>
                      <div className='row'>
                        {reviewOther.map((item) => {
                          return (
                            <div className="col-md-6 pe-5">
                              {item.name ?
                                <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[`${item.val}`] ? (item.unit ? `${data[`${item.unit}`]} ` : "") + (data[`${item.val}`]) : "-"} {item.after && data[item.after] && ` ${data[item.after]} `}</span> </p>
                                : null} </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className='w-100 mt-5'>
                      <NewTablev2 columns={[
                        {
                          subColumns: "Document Name", subColumnStyle: { width: '30%' }
                        },
                        {
                          subColumns: "Attach Document", subColumnStyle: { width: '40%' }
                        },
                        {
                          subColumns: "Sign", subColumnStyle: { width: '15%' }
                        },
                        {
                          subColumns: "Action", subColumnStyle: { width: '15%' }
                        }
                      ]}>
                        {data.bgTextDocument?.name ?
                          <tr>
                            <td>BG Text</td>
                            <td><FileInput isEditable={false} value={data.bgTextDocument} /></td>
                            <td>
                              {!data[`bgTextDocument`]?.signatureId ?
                                <img className="cursor"
                                  onClick={() => { setSigndoc(data[`bgTextDocument`]?.name); setData({ ...data, docToSign: data[`bgTextDocument`], docSignName: `bgTextDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                :
                                <img style={{ width: '20px' }} className=""
                                  src={"assets/images/doc_sign_filled.svg"} alt="" />
                              }
                            </td>
                            <td><div
                              className="d-flex"
                            >
                              {data[`bgTextDocument`]?.name ? (
                                <>
                                  <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`bgTextDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                  <img onClick={() => { toggleViewDoc({ show: true, doc: data[`bgTextDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                </>
                              ) : null}
                            </div></td>
                          </tr> : null}
                        {data.contractCopyDocument?.name ?
                          <tr>
                            <td>Contract Copy</td>
                            <td><FileInput isEditable={false} value={data.contractCopyDocument} /></td>
                            <td>
                              {!data[`contractCopyDocument`]?.signatureId ?
                                <img className="cursor"
                                  onClick={() => { setSigndoc(data[`contractCopyDocument`]?.name); setData({ ...data, docToSign: data[`contractCopyDocument`], docSignName: `contractCopyDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                :
                                <img style={{ width: '20px' }} className=""
                                  src={"assets/images/doc_sign_filled.svg"} alt="" />
                              }
                            </td>
                            <td><div
                              className="d-flex"
                            >
                              {data[`contractCopyDocument`]?.name ? (
                                <>
                                  <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`contractCopyDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                  <img onClick={() => { toggleViewDoc({ show: true, doc: data[`contractCopyDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                </>
                              ) : null}
                            </div></td>
                          </tr> : null}
                        {data.declarationDocument?.name ?
                          <tr>
                            <td>Declaration</td>
                            <td><FileInput isEditable={false} value={data.declarationDocument} /></td>
                            <td>
                              {!data[`declarationDocument`]?.signatureId ?
                                <img className="cursor"
                                  onClick={() => { setSigndoc(data[`declarationDocument`]?.name); setData({ ...data, docToSign: data[`declarationDocument`], docSignName: `declarationDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                :
                                <img style={{ width: '20px' }} className=""
                                  src={"assets/images/doc_sign_filled.svg"} alt="" />
                              }
                            </td>
                            <td><div
                              className="d-flex"
                            >
                              {data[`declarationDocument`]?.name ? (
                                <>
                                  <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`declarationDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                  <img onClick={() => { toggleViewDoc({ show: true, doc: data[`declarationDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                </>
                              ) : null}
                            </div></td>
                          </tr> : null}
                        {data.boardResolutionDocument?.name ?
                          <tr>
                            <td>Board Resolution</td>
                            <td><FileInput isEditable={false} value={data.boardResolutionDocument} /></td>
                            <td>
                              {!data[`boardResolutionDocument`]?.signatureId ?
                                <img className="cursor"
                                  onClick={() => { setSigndoc(data[`boardResolutionDocument`]?.name); setData({ ...data, docToSign: data[`boardResolutionDocument`], docSignName: `boardResolutionDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                :
                                <img style={{ width: '20px' }} className=""
                                  src={"assets/images/doc_sign_filled.svg"} alt="" />
                              }
                            </td>
                            <td><div
                              className="d-flex"
                            >
                              {data[`boardResolutionDocument`]?.name ? (
                                <>
                                  <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`boardResolutionDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                  <img onClick={() => { toggleViewDoc({ show: true, doc: data[`boardResolutionDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                </>
                              ) : null}
                            </div></td>
                          </tr> : null}
                      </NewTablev2>
                    </div>
                  </div>
                ) : null}

                {tab === 1 ?
                  <div className="d-flex row px-5 pt-5 pb-4" >
                    <div className='w-100 mt-5'>
                      <NewTablev2 columns={[
                        {
                          subColumns: "Document Name", subColumnStyle: { width: '30%' }
                        },
                        {
                          subColumns: "Attach Document", subColumnStyle: { width: '40%' }
                        },
                        {
                          subColumns: "Sign", subColumnStyle: { width: '15%' }
                        },
                        {
                          subColumns: "Action", subColumnStyle: { width: '15%' }
                        }
                      ]}>
                        <tr>
                          <td>Termsheet</td>
                          <td>
                            <FileInput hideViewDowdBtns={true} multipleDragDropEnable={true}
                              onMultipleDragDrop={async (e) => {
                                setshowLoader(true)
                                let tempFilesArr = []
                                let tempData = data
                                let tempErrors = errors
                                for (let index = 0; index < e.target.files.length; index++) {
                                  let element = e.target.files[index];
                                  let customEvent = {
                                    target: { name: "termSheet", files: [element] },
                                    persist: () => console.log("...")
                                  }
                                  let resp = await handleMultipleFile(customEvent)
                                  if (resp.success) {
                                    tempFilesArr.push(resp.msg)
                                  }
                                  else {
                                    tempErrors = { ...tempErrors, ...resp.msg }
                                  }
                                }
                                if (tempFilesArr.length) {
                                  tempData["termSheet"] = await mergeMultiplePdfs(tempFilesArr, "termSheet")
                                }
                                setData(tempData)
                                setErrors(tempErrors)
                                setshowLoader(false)
                              }}
                              value={data["termSheet"]} error={errors["termSheet"]}
                              isEditable={true}
                              onUploadCancel={() => setData({ ...data, ["termSheet"]: null })}
                            />
                            {errors["bgTextDocument"] ? <div class="text-danger mt-2 font-size-12">
                              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                              <b>{errors["bgTextDocument"]}</b></div> : ''}
                          </td>
                          <td>
                            {!data[`termSheet`]?.signatureId ?
                              <img className="cursor"
                                onClick={() => { setSigndoc(data[`termSheet`]?.name); setData({ ...data, docToSign: data[`termSheet`], docSignName: `termSheet` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                              :
                              <img style={{ width: '20px' }} className=""
                                src={"assets/images/doc_sign_filled.svg"} alt="" />
                            }
                          </td>
                          <td><div
                            className="d-flex"
                          >
                            {data[`termSheet`]?.name ? (
                              <>
                                <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`termSheet`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                <img onClick={() => { toggleViewDoc({ show: true, doc: data[`termSheet`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                              </>
                            ) : null}
                          </div></td>
                        </tr>
                        {data.bgTextDocument?.name ?
                          <tr>
                            <td>BG Text</td>
                            <td><FileInput isEditable={false} value={data.bgTextDocument} /></td>
                            <td>
                              {!data[`bgTextDocument`]?.signatureId ?
                                <img className="cursor"
                                  onClick={() => { setSigndoc(data[`bgTextDocument`]?.name); setData({ ...data, docToSign: data[`bgTextDocument`], docSignName: `bgTextDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                :
                                <img style={{ width: '20px' }} className=""
                                  src={"assets/images/doc_sign_filled.svg"} alt="" />
                              }
                            </td>
                            <td><div
                              className="d-flex"
                            >
                              {data[`bgTextDocument`]?.name ? (
                                <>
                                  <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`bgTextDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                  <img onClick={() => { toggleViewDoc({ show: true, doc: data[`bgTextDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                </>
                              ) : null}
                            </div></td>
                          </tr> : null}
                        {data.contractCopyDocument?.name ?
                          <tr>
                            <td>Contract Copy</td>
                            <td><FileInput isEditable={false} value={data.contractCopyDocument} /></td>
                            <td>
                              {!data[`contractCopyDocument`]?.signatureId ?
                                <img className="cursor"
                                  onClick={() => { setSigndoc(data[`contractCopyDocument`]?.name); setData({ ...data, docToSign: data[`contractCopyDocument`], docSignName: `contractCopyDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                :
                                <img style={{ width: '20px' }} className=""
                                  src={"assets/images/doc_sign_filled.svg"} alt="" />
                              }
                            </td>
                            <td><div
                              className="d-flex"
                            >
                              {data[`contractCopyDocument`]?.name ? (
                                <>
                                  <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`contractCopyDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                  <img onClick={() => { toggleViewDoc({ show: true, doc: data[`contractCopyDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                </>
                              ) : null}
                            </div></td>
                          </tr> : null}
                        {data.declarationDocument?.name ?
                          <tr>
                            <td>Declaration</td>
                            <td><FileInput isEditable={false} value={data.declarationDocument} /></td>
                            <td>
                              {!data[`declarationDocument`]?.signatureId ?
                                <img className="cursor"
                                  onClick={() => { setSigndoc(data[`declarationDocument`]?.name); setData({ ...data, docToSign: data[`declarationDocument`], docSignName: `declarationDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                :
                                <img style={{ width: '20px' }} className=""
                                  src={"assets/images/doc_sign_filled.svg"} alt="" />
                              }
                            </td>
                            <td><div
                              className="d-flex"
                            >
                              {data[`declarationDocument`]?.name ? (
                                <>
                                  <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`declarationDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                  <img onClick={() => { toggleViewDoc({ show: true, doc: data[`declarationDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                </>
                              ) : null}
                            </div></td>
                          </tr> : null}
                        {data.boardResolutionDocument?.name ?
                          <tr>
                            <td>Board Resolution</td>
                            <td><FileInput isEditable={false} value={data.boardResolutionDocument} /></td>
                            <td>
                              {!data[`boardResolutionDocument`]?.signatureId ?
                                <img className="cursor"
                                  onClick={() => { setSigndoc(data[`boardResolutionDocument`]?.name); setData({ ...data, docToSign: data[`boardResolutionDocument`], docSignName: `boardResolutionDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                :
                                <img style={{ width: '20px' }} className=""
                                  src={"assets/images/doc_sign_filled.svg"} alt="" />
                              }
                            </td>
                            <td><div
                              className="d-flex"
                            >
                              {data[`boardResolutionDocument`]?.name ? (
                                <>
                                  <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`boardResolutionDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                  <img onClick={() => { toggleViewDoc({ show: true, doc: data[`boardResolutionDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                </>
                              ) : null}
                            </div></td>
                          </tr> : null}
                      </NewTablev2>
                    </div>
                  </div> : null}
                <div
                  className="d-flex finActionTabContainer mx-5 mt-4 mb-5 p-0 " >
                  {tab / 1 == 1 ? <div
                    onClick={() => {
                      submitDocument()
                    }}
                    style={{ borderRight: '2px solid #5CB8D3' }}
                    className={`w-25 text-center py-2 cursor bg-5CB8D3 `} >
                    <label className={"font-size-16 font-wt-700 text-white cursor"} >{"Send Termsheet"}</label>
                  </div> : null}
                  <div
                    onClick={() => {
                      setTab(tab / 1 == 0 ? 1 : 0)
                    }}
                    style={{ borderRight: '2px solid #5CB8D3' }}
                    className={tab / 1 == 0 ? `w-25 text-center py-2 cursor bg-5CB8D3` : `w-25 text-center py-2 cursor`} >
                    <label className={tab / 1 == 0 ? "font-size-16 font-wt-700 cursor text-white" : "font-size-14 font-wt-500 cursor"} >{tab / 1 == 0 ? "Next" : "Back"}</label>
                  </div>
                  <div
                    onClick={() => {
                      setFinancierActionTab(finActionTab == 0 ? 1 : 0)
                    }}
                    style={{ borderRight: '2px solid #5CB8D3' }}
                    className={`position-relative w-25 text-center py-2 px-2 cursor`} >
                    {finActionTab == 1 ? <div
                      style={{
                        width: '14rem',
                        top: '-7rem',
                        borderRadius: "10px"
                      }}
                      className="position-absolute bg-DEF7FF " >
                      <label
                        onClick={() => submitForm("deny", "Application Rejected")}
                        className="font-size-14 font-wt-500 w-100 cursor text-left py-1 px-3 border-bottom-solid-dee2e6" >Reject this Application</label>
                      <label
                        onClick={() => submitForm("deny", "Buyer Rejected")} className="font-size-14 font-wt-500 w-100 cursor text-left py-1 px-3 border-bottom-solid-dee2e6">Reject this Buyer</label>
                      <label
                        onClick={() => submitForm("deny", "Supplier Rejected")} className="font-size-14 font-wt-500 w-100 cursor text-left py-1 px-3 border-bottom-solid-dee2e6">Reject this Supplier</label>
                    </div> : null}
                    <label className={"font-size-14 font-wt-500 text-dark cursor"} >Reject Application</label>
                    <img
                      src="assets/images/arrow.png"
                    />
                  </div>
                  <div
                    style={{ borderRight: '2px solid #5CB8D3' }} className="w-25 text-center py-2">
                    <img className="mr-2"
                      src="assets/images/chat_new_icon.svg"
                    />
                    <label
                      onClick={async () => {
                        let chatRoomIds = bgQuoteDetails.chatRoomIds?.split(",") || []
                        let chatRoomUsers = bgQuoteDetails.chatRoomUsers?.split(",") || []

                        let openChatRoomIndx = null
                        chatRoomUsers.forEach((u, i) => {
                          if (u?.split("::")[1] / 1 == userId / 1) {
                            openChatRoomIndx = i
                          }
                        })

                        let reqObj = {
                          userTypeId,
                          senderId: userId,
                          sellerId: bgQuoteDetails.createdBy,
                          receiverId: userId,
                          textMessage: 'Hii',
                          chat_room_name: "CHAT" + new Date().getTime(),
                          includeAdmins: true,
                          bgApplicationId: bgQuoteDetails.limit_id,
                          receiverParties: userId,
                          dontSendInitialMsg: true
                        }
                        setshowLoader(true)
                        let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
                        setshowLoader(false)
                        getChatDetails({
                          chat_room_id: apiResp.id,
                          loggedInUser: userId
                        })
                        setSelectedChat({
                          chatRoomId: apiResp.id,
                          receiverName: `${chatRoomIds[openChatRoomIndx] || apiResp.id}`,
                          bgApplicationId: bgQuoteDetails.limit_id,
                          parties: chatRoomUsers[openChatRoomIndx] || apiResp.parties,
                          userId: userId,
                          isChatOpen: true,
                          receiverId: userId,
                          sellerId: bgQuoteDetails.createdBy
                        })
                      }}
                      className={"font-size-14 font-wt-500 text-dark cursor"} >Chat / Remarks</label>
                  </div>
                  <div className="w-25 text-center py-2">
                    <label className={"font-size-14 font-wt-500 text-dark cursor"} >Assign to other user</label>
                    <img className="ml-2"
                      src="assets/images/redirect_new_icon.png"
                    />
                  </div>
                </div>


              </div>

            </div>

          </main>
        </div>
      </div>
    </>
  )

}

const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState,
  }
}

export default connect(
  mapStateToProps,
  null
)(BGFinancierSendTermSheet)