import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import SideBarV2 from '../partial/sideBarV2';
import HeaderV2 from '../partial/headerV2';
import { NewInput, NewSelect } from '../../utils/newInput';
import MultipleSelect from '../../utils/MultipleSelect';
import call from '../../service';
import toastDisplay from '../../utils/toastNotification';
import { FileInput } from '../../utils/FileInput';
import { GetCache, SetCache, convertImageToPdf, dataURItoBlob, downloadJSONFile, most_used_currencies, multiSelectEventHandler } from '../../utils/myFunctions';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { ExpandableTable } from '../wallet/components/ExpandableTable';
import FinanceInvoiceModal from '../InvoiceDiscounting/contract/components/financeinvoiceModal';
import ReactJson from "react-json-view";
import { aiServerBackendUrl, environment } from '../../urlConstants';
import axios from 'axios';

const addBuyerTabs = [
  // { name: "Select Buyer" },
  // { name: "DUNS No." },
  { name: "Details" },
  { name: "Result" }
]

let currentYear = moment().format("YYYY") / 1



let last5Years = [
  { label: `${currentYear}-${(currentYear + 1).toString().substring(2, 4)}` },
  { label: `${currentYear - 1}-${(currentYear).toString().substring(2, 4)}` },
  { label: `${currentYear - 2}-${(currentYear - 1).toString().substring(2, 4)}` },
  { label: `${currentYear - 3}-${(currentYear - 2).toString().substring(2, 4)}` },
  { label: `${currentYear - 4}-${(currentYear - 3).toString().substring(2, 4)}` },
]


const SupplierCreditCheck = ({ userTokenDetails, navToggleState }) => {

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [tableData, setTableData] = useState([])
  const [showLoader, setshowLoader] = useState(false)
  const [tab, setTab] = useState(0)


  const [refresh, setRefresh] = useState(0)
  const [data, setData] = useState(Object.keys(GetCache("SupplierCreditCheckData")).length ? GetCache("SupplierCreditCheckData") : {})
  const [errors, setErrors] = useState({})
  const [countrys, setCountrys] = useState([])
  const [dunsData, setDunsData] = useState([])
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [management, setManagement] = useState([null])
  const [reasonPopup, toggleReasonPopup] = useState({ show: false })
  const [result, setResult] = useState({})
  const [finData, setFinData] = useState([null])
  const [allUsers, setAllUsers] = useState([])

  console.log("dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", data, GetCache("SupplierCreditCheckData"));


  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;
  const ttvExporterCode = userTokenDetails.ttvExporterCode ? userTokenDetails.ttvExporterCode : ''
  const subUserName = userTokenDetails?.sub_user_name
  const mainUserName = userTokenDetails?.main_user_name

  useEffect(() => {
    call('POST', 'getAllExportersOnPlatform', {}).then(res => {
      let temp = [{ company_name: 'Add Manually' }]
      temp = temp.concat(res)
      setAllUsers(temp)
    })
  }, [])

  useEffect(() => {
    if (result?.reason?.length) {
      formatDataForTable(result.reason)
    }
  }, [result])

  async function handleReasonPopup(reason) {
    toggleReasonPopup({ show: true, data: reason })
  }

  async function formatDataForTable(data) {
    let temp = []
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      let row = []
      row[0] = element.title
      row[1] = Intl.NumberFormat("en", { notation: 'compact' }).format(element.score)
      row[2] = <label
        onClick={() => {
          handleReasonPopup(element)
        }}
        className='font-size-14 cursor font-wt-600 text-color1'
      ><u>View</u></label>
      temp.push(row)
    }
    setTableData(temp)
  }

  useEffect(() => {
    call('get', 'getallCountry').then((result) => {
      setCountrys(result)
    }).catch((e) => {
      console.log('error in getallCountry', e);
    })
    // call('get', 'getHsnCodes').then((result) => {
    //   setHsnCodes(result)
    // }).catch((e) => {
    //   console.log('error in getHsnCodes', e);
    // })
    // call("POST", "getLenderDetails").then((result) => {
    //   setFinancierData(result);
    // }).catch((e) => {
    //   console.log("error in getLenderDetails", e);
    // });
  }, [])

  const handleMultiSelectchange = (e, name, val, singleSelect) => {
    if (singleSelect) {
      setData({
        ...data,
        [name]: e?.[0]?.[val] ? e.reverse()?.[0]?.[val] : null
      })
    }
    else {
      setData({
        ...data,
        [name]: Array.isArray(e) ? e.map((x) => x[val]) : []
      });
    }
  };

  const handleChange = async (event) => {
    event.persist()
    if (event.target?.name === "companyNameDD" && event.target.value) {
      let temp = allUsers
      temp = temp.filter((i) => {
        if (i.company_name === event.target.value) {
          return true
        }
      })?.[0]
      let manuallyAdd = event.target.value === 'Add Manually'
      setData({
        ...data, [event.target.name]: event.target.value, companyName: manuallyAdd ? "" : event.target.value, gstNo: temp?.gst_vat_no, cinNo: temp?.cin_no, iecNo: temp?.iec_no,
        panNo: temp?.pan_no
      })
    }
    else {
      setData({ ...data, [event.target.name]: event.target.value })
    }
    setErrors({ ...errors, [event.target.name]: "" })
  }

  function getDunsList() {
    setData({
      ...data, selectedDuns: null,
      buyerAddress: "",
      buyerPostalCode: "", buyerDunsNo: ""
    })
    setshowLoader(true)
    call('POST', 'getDUNSNo', {
      companyName: data.buyerName, countryCode: data.buyerCountry,
      userId, userEmail
    }).then((result) => {
      setshowLoader(false)
      if (result.length > 0) {
        setDunsData(result);
        setTab(1)
      }
      else {
        toastDisplay("No Related Buyer Details Found", "error")
        setDunsData([])
      }
    })
  }

  async function handleValidation(tabIndex) {

    let validateFields = []
    let validater = []

    let finApiResp = {
      result: {
        "standalone": []
      }
    }
    let mngApiResp = {
      result: [
        { dateOfIncorporation: data["dateOfIncorporation"], directors: [] }
      ]
    }

    let err = {}
    if (tab === 0) {
      if (data.gstNonConsentBased) {
        validateFields = ["companyName", "gstNo", "cinNo", "iecNo", "panNo"]
      }
      else {
        validateFields = ["companyName", "gstNo", "cinNo", "iecNo", "gstUserName", "gstPassword"]
      }
      if (data.cinByPass) {
        validateFields = validateFields.filter(i => {
          if (i != 'cinNo') {
            return true
          }
        })
        validateFields.push('dateOfIncorporation')
        // financials & directors validation
        for (let index = 0; index < finData.length; index++) {
          validater = validater.concat([`financialYear${index}`, `totalEl${index}`, `freeCashFlow${index}`, `netProfitMargin${index}`])
          finApiResp["result"]["standalone"].push({
            metadata: { financialYear: data[`financialYear${index}`] },
            data: {
              currentFinancialSummary: {
                balSheet: { totalEl: data[`totalEl${index}`] },
                cashFlowStat: { freeCashFlow: data[`freeCashFlow${index}`] },
                ratAnalysis: { netProfitMargin: data[`netProfitMargin${index}`] }
              }
            }
          })
        }
        for (let index = 0; index < management.length; index++) {
          validater = validater.concat([`fullName${index}`, `tenureBeginDate${index}`])
          mngApiResp.result[0]["directors"].push({
            name: data[`fullName${index}`],
            tenureBeginDate: data[`tenureBeginDate${index}`],
            tenureEndDate: data[`tenureEndDate${index}`] || undefined
          })
        }
      }
    }

    validateFields.forEach((item) => {
      if (!data[item]) {
        err[item] = "Mandatory Field"
      }
    })

    validater.forEach((item) => {
      if (!data[item]) {
        err[item] = "Mandatory Field"
      }
    })

    if (!Object.keys(err).length) {
      if (tab / 1 == 0) {
        let formData = new FormData()
        if (environment === "local") {
          formData.append("useHardCodeData", true)
        }
        if (!data.cinByPass) {
          formData.append('cinNumber', data.cinNo)
        }
        else {
          formData.append("finApiResp", JSON.stringify(finApiResp))
          formData.append("mngApiResp", JSON.stringify(mngApiResp))
        }
        formData.append('gstUserName', data.gstUserName)
        formData.append('gstPassword', data.gstPassword)
        formData.append('gstNumber', data.gstNo)
        formData.append('iecNumber', data.iecNo)
        formData.append('companyName', data.companyName)
        if (data.gstNonConsentBased) {
          formData.append('gstNonConsentBased', data.gstNonConsentBased)
          formData.append('panNo', data.panNo)
        }
        setshowLoader(true)
        let apiResp = await call('POST', 'supplierCreditCheck', formData)
        setResult(apiResp)
        setshowLoader(false)
        setTab(1)
      }
    }
    setErrors(err)
  }

  async function doOCRofFinancials(fileObj, j) {
    let requiredInformation = {
      "totalDebt": 0,
      "freeCashFlow": 0,
      "netProfitMargin": 0
    }
    let formData = new FormData()
    formData.append("auditedFinancialReport", fileObj)
    formData.append("defaultResp", JSON.stringify(requiredInformation))
    formData.append("prompt", `Analyze below audited financial report and give me details like ${Object.keys(requiredInformation).join(", ")} in an json format, and if you dont find details still return json object with 0 value, but the json should have all the keys which i have mentioned & value should be in integer.
    
    docTxtData `)
    setshowLoader(true)
    let apiResp = await axios.post(aiServerBackendUrl + '/get_json_details_from_gpt', formData, {
      "Content-Type": "multipart/form-data"
    })
    setshowLoader(false)
    console.log("apiResppppppppppppppppppppppppppp", apiResp.data);
  }

  const handleFile = async (event, j) => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")) || (file_type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")) || (file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png, jpeg, docx & xlsx extension are allowed" })
        return
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (file_type.includes("png") || file_type.includes("jpeg")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') || file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
          try {
            setshowLoader(true)
            let formData = new FormData()
            formData.append("file", fileObj)
            if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
              formData.append("docType", "docx")
            }
            if (file_type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
              formData.append("docType", "xlsx")
            }
            let apiResp = await call('POST', "docToPdf", formData)
            let blob = dataURItoBlob(apiResp);
            fileObj = new File([blob], fileObj.name + "converted.pdf", { type: "application/pdf" });
            fileDataUrl = apiResp
            toastDisplay("File converted into pdf format", "success")
          } catch (error) {
            return toastDisplay("failed to convert file", "error")
          }
          setshowLoader(false)
        }
        fileObj["filebase64"] = fileDataUrl
        let tempData = { ...data, [event.target.name]: fileObj }
        doOCRofFinancials(fileObj, j)
        setData(tempData)
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  useEffect(() => {
    SetCache("SupplierCreditCheckData", data)
  }, [data])


  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      {reasonPopup.show ? (
        <FinanceInvoiceModal limitinvoice={reasonPopup.show} closeSuccess={() => {
          toggleReasonPopup({ show: false })
        }}>
          <div className=''>
            {/* <img src='/assets/images/Questionmark.png' /> */}
            {typeof (reasonPopup.data) === 'object' ?
              <ReactJson
                displayDataTypes={false}
                style={{ padding: '0.8rem', marginTop: '0.8rem', marginBottom: '0.8rem', fontSize: '14px', textAlign: 'left' }}
                collapseStringsAfterLength={50}
                theme={"chalk"}
                name={false}
                src={reasonPopup.data} />
              :
              <p className='font-size-14 font-wt-400 mt-2 text-center'>{reasonPopup.data}</p>}
            {/* <button className={`my-4 new-btn w-30 py-2 px-1 text-white cursor`} onClick={() => {
              window.location = `applyForLimit?buyer=${newBuyerId}`
            }}>Continue</button>
            <p className='font-size-14 font-wt-400 cursor' onClick={() => {
              window.location = '/buyerManagement'
            }}><u>Back to buyer management</u></p> */}
          </div>
        </FinanceInvoiceModal>
      ) : null}
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="suppliercreditcheck" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Supplier Credit Check"}
              userTokenDetails={userTokenDetails} />

            <div className='' style={{}} >
              {/* <i
                onClick={() => {
                  window.location = "/buyerManagement"
                }}
                class="fas fa-2x fa-arrow-left mx-1 mb-4 icon-color cursor"></i> */}
              <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                {addBuyerTabs.map((item, index) => {
                  return (
                    <li>
                      <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                        onClick={() => {
                          // handleValidation(index)
                          setTab(index)
                        }}
                      >{item.name}</a>
                    </li>
                  )
                })}
              </ul>

              <div className='bg-white pb-5'
                style={{
                  "height": "40rem",
                  "overflow": "auto"
                }}
              >
                {tab === 0 ? (
                  <div className='px-4 py-5'>
                    <div className='d-flex row mb-3' >
                      <div className='col-4'>
                        <img
                          onClick={() => setData({ ...data, gstNonConsentBased: !data.gstNonConsentBased })}
                          className='cursor mr-3' src={`assets/images/${data.gstNonConsentBased ? 'checked-green' : 'empty-check'}.png`} />
                        <label className='font-size-14 font-wt-600' >Use GST non consent API</label>
                      </div>
                      <div className=' col-4'>
                        <img
                          onClick={() => setData({ ...data, cinByPass: !data.cinByPass })}
                          className='cursor mr-3' src={`assets/images/${data.cinByPass ? 'checked-green' : 'empty-check'}.png`} />
                        <label className='font-size-14 font-wt-600' >Continue without CIN</label>
                      </div>
                      <div className=' col-4'>
                        <label
                          onClick={() => {
                            localStorage.clear("SupplierCreditCheckData")
                            window.location.reload()
                          }}
                          className='font-size-14 font-wt-600 cursor text-center' >Reset Form</label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <MultipleSelect
                          Label={"Company Name"}
                          Id={`companyNameDD`}
                          optiondata={allUsers}
                          onChange={(e) => {
                            handleChange(multiSelectEventHandler(e, `companyNameDD`, "company_name"))
                          }}
                          value={data[`companyNameDD`] ? [data[`companyNameDD`]] : []}
                          name={`companyNameDD`}
                          labelKey={"company_name"}
                          valKey={"company_name"}
                          error={errors[`companyName`]}
                        />
                      </div>
                      {data.companyNameDD === "Add Manually" ?
                        <div className="col-md-4">
                          <NewInput isAstrix={true} type={"text"} label={"Company Name"}
                            name={"companyName"} value={data.companyName} error={errors.companyName}
                            onChange={handleChange} /> </div> : null}
                      <div className="col-md-4">
                        <NewInput isAstrix={true} type={"text"} label={"GST No"}
                          name={"gstNo"} value={data.gstNo} error={errors.gstNo}
                          onChange={handleChange} />
                      </div>
                      {data.cinByPass ? <div className="col-md-4">
                        <NewInput isAstrix={true} type={"date"} label={"Date of Incorporation"}
                          name={"dateOfIncorporation"} value={data.dateOfIncorporation} error={errors.dateOfIncorporation}
                          onChange={handleChange} />
                      </div> : <div className="col-md-4">
                        <NewInput isAstrix={true} type={"text"} label={"CIN No"}
                          name={"cinNo"} value={data.cinNo} error={errors.cinNo}
                          onChange={handleChange} />
                      </div>}
                      <div className="col-md-4">
                        <NewInput isAstrix={true} type={"text"} label={"IEC No"}
                          name={"iecNo"} value={data.iecNo} error={errors.iecNo}
                          onChange={handleChange} />
                      </div>
                      {data.gstNonConsentBased ? <>
                        <div className="col-md-4">
                          <NewInput isAstrix={true} type={"text"} label={"PAN No"}
                            name={"panNo"} value={data.panNo} error={errors.panNo}
                            onChange={handleChange} />
                        </div>
                      </> : (
                        <>
                          <div className="col-md-4">
                            <NewInput isAstrix={true} type={"text"} label={"GST User Name"}
                              name={"gstUserName"} value={data.gstUserName} error={errors.gstUserName}
                              onChange={handleChange} />
                          </div>
                          <div className="col-md-4">
                            <NewInput isAstrix={true} type={"text"} label={"GST Password"}
                              name={"gstPassword"} value={data.gstPassword} error={errors.gstPassword}
                              onChange={handleChange} />
                          </div>
                        </>
                      )}
                    </div>
                    {data.cinByPass ?
                      <div className='mt-3' >
                        <label className="font-size-13 font-wt-600">Financial Details</label>
                        {finData.length ? finData.map((i, j) => {
                          return (
                            <div className='row mt-2'>
                              {/* <div className="w-25">
                                <div className="row form-group">
                                  <div className="col-md-12">
                                    <FileInput name={`auditedFinancialDocument${j}`} value={data[`auditedFinancialDocument${j}`]} error={errors[`auditedFinancialDocument${j}`]}
                                      onChange={(e) => handleFile(e, j)} isEditable={true}
                                      onUploadCancel={() => setData({ ...data, [`auditedFinancialDocument${j}`]: null })}
                                    />
                                  </div>
                                </div>
                              </div> */}
                              <div className='w-15'>
                                <NewSelect selectData={last5Years} optionLabel={"label"} optionValue={"label"} error={errors[`financialYear${j}`]}
                                  value={data[`financialYear${j}`]} name={`financialYear${j}`} onChange={handleChange}
                                />
                              </div>
                              <div className='w-15'>
                                <NewInput isAstrix={false} type={"number"} label={"Total Debt ($)"}
                                  name={`totalEl${j}`} value={data[`totalEl${j}`]} error={errors[`totalEl${j}`]}
                                  onChange={handleChange} />
                              </div>
                              <div className='w-15'>
                                <NewInput isAstrix={false} type={"number"} label={"Free Cash Flow ($)"}
                                  name={`freeCashFlow${j}`} value={data[`freeCashFlow${j}`]} error={errors[`freeCashFlow${j}`]}
                                  onChange={handleChange} />
                              </div>
                              <div className='w-15'>
                                <NewInput isAstrix={false} type={"number"} label={"Net Profit Margin (%)"}
                                  name={`netProfitMargin${j}`} value={data[`netProfitMargin${j}`]} error={errors[`netProfitMargin${j}`]}
                                  onChange={handleChange} />
                              </div>
                              <div className=' w-15 ' >
                                <img
                                  onClick={() => {
                                    let temp = finData
                                    temp.push(null)
                                    setFinData([...temp])
                                  }}
                                  className="cursor mx-4"
                                  src={"assets/images/vector_add_blue.svg"}
                                />
                                <img
                                  onClick={() => {
                                    let temp = finData
                                    if (temp.length > 1) {
                                      let tempUpdatedPo = []
                                      for (let m = 0; m < temp.length; m++) {
                                        if (m != j) {
                                          tempUpdatedPo.push(null)
                                        }
                                      }
                                      temp = tempUpdatedPo
                                      setFinData([...temp])
                                    }
                                    else {
                                      toastDisplay("Atleast 1 details are required", "info")
                                    }
                                  }}
                                  className="cursor"
                                  src={"assets/images/cross.png"}
                                />
                              </div>
                            </div>
                          )
                        }) : null}
                      </div>
                      : null}
                    {data.cinByPass ?
                      <div className='mt-3'>
                        <label className="font-size-13 font-wt-600">Management Details</label>
                        {management.map((i, j) => {
                          return (
                            <div className='row mt-2'>
                              <div className='col-2'>
                                <NewInput isAstrix={false} type={"text"} label={"Full Name"}
                                  name={`fullName` + j} value={data[`fullName` + j]} error={errors[`fullName` + j]}
                                  onChange={handleChange} />
                              </div>
                              <div className='col-2'>
                                <NewInput isAstrix={false} type={"date"} label={"Tenure Begin Date"}
                                  name={`tenureBeginDate${j}`} value={data[`tenureBeginDate${j}`]} error={errors[`tenureBeginDate${j}`]}
                                  onChange={handleChange} />
                              </div>
                              <div className='col-2'>
                                <NewInput isAstrix={false} type={"date"} label={"Tenure End Date"}
                                  name={`tenureEndDate${j}`} value={data[`tenureEndDate${j}`]} error={errors[`tenureEndDate${j}`]}
                                  onChange={handleChange} />
                              </div>
                              <div className=' col-2 ' >
                                <img
                                  onClick={() => {
                                    let temp = management
                                    temp.push(null)
                                    setManagement([...temp])
                                  }}
                                  className="cursor mx-4"
                                  src={"assets/images/vector_add_blue.svg"}
                                />
                                <img
                                  onClick={() => {
                                    let temp = management
                                    if (temp.length > 1) {
                                      let tempUpdatedPo = []
                                      for (let m = 0; m < temp.length; m++) {
                                        if (m != j) {
                                          tempUpdatedPo.push(null)
                                        }
                                      }
                                      temp = tempUpdatedPo
                                      setManagement([...temp])
                                    }
                                    else {
                                      toastDisplay("Atleast 1 details are required", "info")
                                    }
                                  }}
                                  className="cursor"
                                  src={"assets/images/cross.png"}
                                />
                              </div>
                            </div>
                          )
                        })}
                      </div> : null}
                  </div>
                ) : null}



                {tab === 1 ?
                  <div className='px-4 '>
                    <div className='d-flex justify-content-end'>
                      <button type="button"
                        onClick={() => {
                          downloadJSONFile(result, `${data.companyName}_report.json`)
                        }}
                        className={`mx-2 new-btn w-25 py-2 px-2 mt-4 text-white`}>
                        {"Download JSON Report"}
                      </button>
                    </div>
                    <div className='row py-5'>
                      <div className="col-8">
                        <div>
                          <label className='font-size-14 font-wt-500'>{`Check Points :  `}</label><label className='font-size-14 font-wt-800'>{result.check_points}</label></div>
                        <div>
                          <label className='font-size-14 font-wt-500'>{`Total Score :  `}</label><label className='font-size-14 font-wt-800'>
                            {Intl.NumberFormat("en", { notation: 'compact' }).format(result.score)}</label></div>
                        <div>
                          <label className='font-size-14 font-wt-500'>{`Per checkpoint Max Score :  `}</label><label className='font-size-14 font-wt-800'>{10}</label></div>
                      </div>
                      <div className="col-4 d-flex">
                        <div>
                          <div style={{ width: '6rem' }}>
                            <CircularProgressbar
                              styles={buildStyles({
                                pathColor: '#2ECC71',
                                textColor: '#000000'
                              })}
                              value={result.tr_score_percentage} text={`${Math.round(result.tr_score_percentage)}%`} />
                          </div>
                          <label className='font-size-14 font-wt-500'>{`TR Score percentage`}</label>
                          <label className='font-size-14 font-wt-800'>
                            {`(total score / (check points * max score per checkpoint)) * 100`}</label>
                          <div>
                            <label className='font-size-16 font-wt-800'>{`Final Limit : ${result.limit || "NA"} USD`}</label>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className='mt-4' >
                      <ExpandableTable
                        data={tableData}
                        columns={[
                          { name: "Title", filter: true, width: '60%', filter: false },
                          { name: "Score", filter: true, width: '20%', filter: false },
                          { name: "Reason", filter: true, width: '20%', filter: false },
                        ]}
                        overalldata={[]}
                        expand={[]}
                        tableExpand={[]}
                        expandKey={""}
                        disableAction={true}
                      // options={[
                      //   {
                      //     name: "View Reason", icon: "", onClick: (index) => {
                      //     }
                      //   }
                      // ]}
                      ></ExpandableTable>
                    </div>
                  </div>

                  : null}

                {tab != 1 ? (
                  <div className={tab === 1 ? "buyerContinuBtn row" : "row mx-0 px-3"}>
                    <button type="button"
                      onClick={() => {
                        handleValidation(undefined)
                      }}
                      className={`mx-4 new-btn ${tab === 1 ? '' : 'w-25'} py-2 px-2 text-white`}>
                      {"Continue"}
                    </button>
                  </div>

                ) : null}

              </div>

            </div>
          </main>

        </div >
      </div >
    </>
  )

}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(SupplierCreditCheck)