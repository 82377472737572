import React from 'react'
import call from '../../service'
import { useEffect } from 'react'
import { useState } from 'react'
import moment from 'moment'

const SubAdminPopup = ({ subadminpopup, togglesubadminpopup, setShowLoader }) => {
  const [dbdata, setdbdata] = useState([])
  const [callHistoryPopup, toggleCallHistoryPopup] = useState({ show: false, data: [], ADMIN_ID: '' })
  const [activeIndex, setActiveIndex] = useState(null);

  const getsubadminsByUser = () => {
    setShowLoader(true)
    call('POST', 'getsubadminsByUser', { userId: subadminpopup.userId }).then(result => {
      setShowLoader(false)
      setdbdata(result)
    }).catch(e => {
      setShowLoader(false)
    })
  }
  useEffect(() => {
    if (subadminpopup.userId) {
      getsubadminsByUser()
    }
  }, [subadminpopup])
  const handleAccordianClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  async function handleCallHistoryPopup(ADMIN_ID) {
    setShowLoader(true)
    let apiResp = await call('POST', 'getUserOnboardedHistoryAdminWise', {
      EXPORTER_CODE: subadminpopup.userId,
      ADMIN_ID
    })
    // console.log("getTransactionHistoryForInvoiceLimit api resp====>", itemData, apiResp);
    setShowLoader(false)
    toggleCallHistoryPopup({ show: true, data: apiResp, ADMIN_ID: ADMIN_ID })
  }
  return (
    <div className={`modal fade ${subadminpopup.show && "show"}`} style={subadminpopup.show ? { display: "block", "zIndex": '100001' } : {}}>
      <div className="modal-dialog modal-md mr-0 my-0">
        <div className="modal-content submitmodal p-0"
        >

          <div className="modal-header border-0">
            <div className="w-100 d-flex align-items-center justify-content-between">
              <label
                className="font-size-16 font-wt-600 text-color-value mx-3"
              >Sub admins</label>
              <div className="modal-header border-0">
                <button type="button" className="btn-close" aria-label="Close" onClick={() => togglesubadminpopup({ show: false, data: [], userId: '' })}></button>
              </div>
            </div>
          </div>

          <div className="modal-body p-0 ">
            <div className='px-4'>
              {dbdata.map((item, index) => {
                return (
                  <div className={`${dbdata.length - 1 == index ? '' : 'border-bottom'} p-4`}>
                    <div className='d-flex gap-4 align-items-center'>
                      <label className='subadminNameLabel mb-0'>{item.name}</label>
                      <div className={item.admin_priority === 'Primary' ? 'bg-2ECC71 br-1' : 'bg-FFB801  br-1'}>
                        <label className='subadminPriorityLabel mb-0 p-2'>{item.admin_priority}</label>
                      </div>
                    </div>
                    <span>
                      <label className='subadminlabel'>No. Of Task Created</label>
                      <label className='subadminlabel'>:</label>
                      <label className='subadminValue'>{item.number_of_tasks}</label>
                    </span>
                    <div className='d-flex align-items-center mt-1 cursor' onClick={() => {
                      if (callHistoryPopup.ADMIN_ID === item.id) {
                        toggleCallHistoryPopup({ show: false, data: [], ADMIN_ID: '' })
                      } else {
                        handleCallHistoryPopup(item.id)
                      }
                    }}>
                      <label className='subadminShowHistoryLabel mb-0 cursor'>{callHistoryPopup.ADMIN_ID === item.id ? 'Hide History' : `Show History`}</label>
                      <img src='assets/images/ep_arrow-down-bold.png' className='cursor' style={callHistoryPopup.ADMIN_ID === item.id ? { marginTop: 3 } : { transform: "rotate(-90deg)", marginTop: 3 }} />
                    </div>
                    {callHistoryPopup.ADMIN_ID === item.id ?
                      <div className='p-2'>
                        {callHistoryPopup.data.length ? callHistoryPopup.data.map((item, index) => {
                          return (
                            <div className='d-flex flex-row ml-3'>
                              <div className="progressBarContainer2">
                                <div className="progressBarInnerCircle">
                                </div>
                              </div>
                              <div className='pl-4 pt-4 mt-2'>
                                <p className='font-size-14 text-color1 font-wt-500 mb-0'>
                                  {item.CREATED_AT ? moment(item.CREATED_AT).format('Do MMM, YYYY - hh:mm A') : '-'}
                                  <span><img src='assets/images/arrow.png' className='cursor' onClick={() => handleAccordianClick(index)} /></span>
                                </p>
                                {activeIndex === index &&
                                  <div>
                                    <p className='mb-0 font-size-14'>{item.LOG_TYPE}</p>
                                    <p className='mb-0 font-size-14'>{item.REMARK}</p>
                                    <p>
                                      {item.CONTACT_PERSON && <span className='mb-0 font-size-14 font-wt-600'>{item.CONTACT_PERSON + " - "}</span>}
                                      {item.CONTACT_NUMBER && <span className='mb-0 font-size-14 font-wt-600'>{item.CONTACT_NUMBER}</span>}
                                    </p>
                                    <p>
                                      {item.EVENT_TIME &&
                                        <span className='mb-0 font-size-14 '>Next followup date:
                                          <span className='mb-0 font-size-14 '>
                                            {moment(item.EVENT_TIME).format('DD/MM/YYYY')}
                                          </span>
                                        </span>
                                      }
                                    </p>
                                  </div>
                                }
                              </div>
                            </div>
                          )
                        }) :
                          null}
                      </div>
                      : null
                    }

                  </div>
                )
              })}
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default SubAdminPopup
