import React, { useState } from "react";
import { InputWithSelect, NewInput, NewSelect } from "../../../utils/newInput";
import InputDropdown from "./inputDropdown";
import InputEdit from "./inputEdit";
import { most_used_currencies } from "../../../utils/myFunctions";
import { companyTypes, industryData } from "../../registration/newRegistration";
import { FileInput } from "../../../utils/FileInput";

const organizationTypesMaster = [
  {
    name: "Private Limited",
    sortname: "privateLtd"
  },
  {
    name: "Public Limited",
    sortname: "publicLtd"
  },
  {
    name: "Joint Limited",
    sortname: "jointLtd"
  },
]

export const BusinessDocs = [
  { name: "MOM Document", val: "MOM_Document" },
  { name: "AOA Document", val: "AOA_Document" },
  { name: "Shareholding Document", val: "Shareholding_Document" },
  { name: "Partnership Deed Document", val: "Partnership_Deed_Document" },
  { name: "Company Profile Document", val: "Company_Profile_Document" }
]

const CompanyTab = ({ handleChange, userdata, countrys, handleCompanySubmit, userTokenDetails, handleFile, setData }) => {

  const [data, setData2] = useState({})
  const [errors, setErrors] = useState({})
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const { company_name, company_email, website, country_of_incorporation, country_of_operation, dunsNo, industry_type, organization_type, minExisting, minExpected, companyPostal, CompanyCountry, companyAdd1, companyAdd2, companyState, companyCity, prevNetProfit, years_of_incorporation, currency } = userdata
  return (
    <>
      <p className="text-decoration-underline font-size-14">General details</p>
      <div className="row align-items-end">
        <div className="col-md-6 ">
          <div className="col-md-10 px-0">
            <InputEdit Label="Company name" isDisabled={true} Placeholder={"Company Name"} name={"company_name"} value={company_name} />
          </div>
        </div>
        <div className="col-md-6">
          <div className="col-md-10 px-0">
            <NewInput isAstrix={true} type={"text"} label={"Website"}
              name={"website"} value={website} error={errors.website}
              onChange={handleChange} />
          </div>
        </div>
        <div className="col-md-6 ">
          <div className="col-md-10 px-0">
            <NewInput isAstrix={true} type={"text"} label={"Email ID"}
              name={"company_email"} value={company_email} error={errors.company_email}
              onChange={handleChange} />
          </div>
        </div>
        <div className="col-md-6 ">
          <div className="col-md-10 px-0">
            <InputEdit Placeholder="DUNS No." Label="DUNS No." name={"dunsNo"} value={dunsNo} onChange={handleChange} />
          </div>
        </div>
        <div className="col-md-6">
          <div className="col-md-10 px-0">
            <NewSelect isAstrix={true} label={"Organisation type"}
              selectData={companyTypes} name={"organization_type"}
              value={organization_type} optionLabel={"name"} optionValue={'alt'}
              onChange={handleChange} error={errors.organization_type} />
          </div>
        </div>
        <div className="col-md-6">
          <div className="col-md-10 px-0">
            <NewSelect isAstrix={true} label={"Industry Type"}
              selectData={industryData} name={"industry_type"}
              value={industry_type} optionLabel={"name"} optionValue={'value'}
              onChange={handleChange} error={errors.industry_type} />
          </div>
        </div>
        <div className="col-md-6 ">
          <div className="col-md-10 px-0">
            <NewSelect isAstrix={true} label={"Country of incorporation"}
              selectData={countrys} name={"country_of_incorporation"}
              value={country_of_incorporation} optionLabel={"name"} optionValue={'sortname'}
              onChange={handleChange} error={errors.country_of_incorporation} />
          </div>
        </div>
        <div className="col-md-6 ">
          <div className="col-md-10 px-0">
            <NewSelect isAstrix={true} label={"Country of operation"}
              selectData={countrys} name={"country_of_operation"}
              value={country_of_operation} optionLabel={"name"} optionValue={'sortname'}
              onChange={handleChange} error={errors.country_of_operation} />
          </div>
        </div>
        <div className="col-md-6 ">
          <div className="col-md-10 px-0">
            <NewInput isAstrix={true} type={"text"} label={"Years of incorporation"}
              name={"years_of_incorporation"} value={years_of_incorporation} error={errors.years_of_incorporation}
              onChange={handleChange} />
          </div>
        </div>
        <div className="col-md-6 ">
          <div className="col-md-10 px-0">
            <label>Last year’s net profit</label>
            <InputWithSelect isAstrix={false} type={"text"} label={""}
              selectData={currencyData}
              selectName={"currency"} selectValue={currency}
              optionLabel={"code"} optionValue={'code'}
              name={'prevNetProfit'} value={prevNetProfit} error={errors.prevNetProfit}
              onChange={handleChange} />
          </div>
        </div>
        <div className="col-md-6 ">
          <div className="col-md-10 px-0">
            <label>Average existing turnover</label>
            <InputWithSelect isAstrix={false} type={"text"} label={""}
              selectData={currencyData}
              selectName={"currency"} selectValue={currency}
              optionLabel={"code"} optionValue={'code'}
              name={'minExisting'} value={minExisting} error={errors.minExisting}
              onChange={handleChange} />
          </div>
        </div>
        <div className="col-md-6 ">
          <div className="col-md-10 px-0">
            <label>Expected turnover</label>
            <InputWithSelect isAstrix={false} type={"text"} label={""}
              selectData={currencyData}
              selectName={"currency"} selectValue={currency}
              optionLabel={"code"} optionValue={'code'}
              name={'minExpected'} value={minExpected} error={errors.minExpected}
              onChange={handleChange} />
          </div>
        </div>
      </div>

      <p className="text-decoration-underline font-size-14 mt-4">Address</p>
      <div className="row">
        <div className="col-md-6 ">
          <div className="col-md-10 px-0">
            <InputEdit Placeholder="Address line 1" Label="Address line 1" value={companyAdd1} name={"companyAdd1"} onChange={handleChange} />
          </div>
        </div>
        <div className="col-md-6 ">
          <div className="col-md-10 px-0">
            <InputEdit Placeholder="Address line 2" Label="Address line 2" value={companyAdd2} name={"companyAdd2"} onChange={handleChange} />
          </div>
        </div>
        <div className="col-md-6 ">
          <div className="col-md-10 px-0">
            <InputEdit Placeholder="City" Label="City" value={companyCity} name={"companyCity"} onChange={handleChange} />
          </div>
        </div>
        <div className="col-md-6 ">
          <div className="col-md-10 px-0">
            <InputEdit Placeholder="State" Label="State" value={companyState} name={"companyState"} onChange={handleChange} />
          </div>
        </div>
        <div className="col-md-6 ">
          <div className="col-md-10 px-0">
            <InputEdit Placeholder="Country" Label="Country" value={CompanyCountry} name={"CompanyCountry"} onChange={handleChange} />
          </div>
        </div>
        <div className="col-md-6 ">
          <div className="col-md-10 px-0">
            <InputEdit Placeholder="Postal code" Label="Postal code" value={companyPostal} name={"companyPostal"} onChange={handleChange} />
          </div>
        </div>
      </div>
      <>
        <p className='font-size-14 mt-4'>Attach documents</p>
        <div className='row'>
          {BusinessDocs.map(docs => {
            return <div className="col-md-6">
              <label className="font-size-13">{docs.name}</label>
              <div className="row form-group">
                <div className="col-md-11">
                  <FileInput name={docs.name} value={userdata[docs.name]} error={errors[docs.name]}
                    onChange={handleFile}
                    isEditable={true}
                    userTokenDetails={userTokenDetails}
                    onUploadCancel={() => setData({ ...userdata, [docs.name]: null })}
                  />
                  {errors[docs.name] ? <div class="text-danger mt-2 font-size-12">
                    <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                    <b>{errors[docs.name]}</b></div> : ''}
                </div>
              </div>
            </div>
          })
          }
        </div>
      </>
      <div className="d-flex gap-4">
        <button className={`my-4 new-btn w-17 py-2 px-2 text-white cursor`} onClick={handleCompanySubmit}>Save</button>
      </div>
    </>
  );
}
export default CompanyTab;