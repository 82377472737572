import jsPDF from "jspdf";
import cryptoJs from "crypto-js";
import React from 'react'
import call from "../service";
import { getUserDataFromCookie } from "./cookieHelper";
import { NewInput, NewSelect } from "./newInput";
import { Lcdetailsdata, lcTenorDD } from "../components/lcV2/applyforLimit/components/lcdiscountingcard";
import html2canvas from "html2canvas";
import { mergePDF, createPDF, pdfArrayToBlob } from "pdf-actions";
import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver'
import moment from "moment";
import { PDFDocument } from "pdf-lib";
import toastDisplay from "./toastNotification";
let secretKey = "5N-LzDy;LcKYHYgp^d]2Q59,}rR=|V&=3/Me,ugIuw]92<7cX@uEmE):+%|/#mA"


export const is_valid = (data) => {
  if (data && data.length) return true
  return false
}

// export const validate_number_field = str => {
//   return str.replace(/[^0-9\.]/g, "").replace(/(?<=\..*)\./g, '')
// }

export const validate_text_field = str => {
  return str.replace(/^\s?/, '')
}

const A4_PAPER_DIMENSIONS = {
  width: 210,
  height: 297,
};

const A4_PAPER_RATIO = A4_PAPER_DIMENSIONS.width / A4_PAPER_DIMENSIONS.height;

const imageDimensionsOnA4 = (dimensions) => {
  const isLandscapeImage = dimensions.width >= dimensions.height;
  // If the image is in landscape, the full width of A4 is used.
  if (isLandscapeImage) {
    return {
      width: A4_PAPER_DIMENSIONS.width,
      height:
        A4_PAPER_DIMENSIONS.width / (dimensions.width / dimensions.height),
    };
  }
  // If the image is in portrait and the full height of A4 would skew
  // the image ratio, we scale the image dimensions.
  const imageRatio = dimensions.width / dimensions.height;
  if (imageRatio > A4_PAPER_RATIO) {
    const imageScaleFactor =
      (A4_PAPER_RATIO * dimensions.height) / dimensions.width;
    const scaledImageHeight = A4_PAPER_DIMENSIONS.height * imageScaleFactor;
    return {
      height: scaledImageHeight,
      width: scaledImageHeight * imageRatio,
    };
  }
  // The full height of A4 can be used without skewing the image ratio.
  return {
    width: A4_PAPER_DIMENSIONS.height / (dimensions.height / dimensions.width),
    height: A4_PAPER_DIMENSIONS.height,
  };
};

export async function convertImageToPdf(imgDataUrl, imgFileName) {
  return new Promise((resolve, reject) => {
    try {
      let pdfFileName = imgFileName.split(".")[0] + ".pdf"
      let image = new Image();
      image.src = imgDataUrl;
      image.onload = function () {
        const doc = new jsPDF();
        doc.deletePage(1);
        const imageDimensions = imageDimensionsOnA4({
          width: image.width,
          height: image.height,
        });
        doc.addPage();
        doc.addImage(
          image.src,
          // Images are vertically and horizontally centered on the page.
          (A4_PAPER_DIMENSIONS.width - imageDimensions.width) / 2,
          (A4_PAPER_DIMENSIONS.height - imageDimensions.height) / 2,
          imageDimensions.width,
          imageDimensions.height,
          "",
          "FAST"
        );
        // To view pdf in new tab
        // const test = doc.output('bloburl')
        // window.open(test, "_blank");
        const pdfBlob = doc.output('blob');
        resolve(
          {
            "file": new File([pdfBlob], pdfFileName, { type: pdfBlob["type"], lastModified: new Date() }),
            "dataUrl": doc.output('datauristring', { filename: pdfFileName })
          }
        );
      };
    }
    catch (error) {
      reject(false)
    }
  })
}

export const GetCache = (key) => {
  return JSON.parse(localStorage.getItem(key)) || {}
}

export const SetCache = (key, data_obj) => {
  localStorage.setItem(key, JSON.stringify({ ...GetCache(key), ...data_obj }))
}

export const ClearCache = (key) => {
  localStorage.removeItem(key)
}

export const encryptData = (strData) => {
  return cryptoJs.AES.encrypt(strData, secretKey).toString()
}

export const decryptData = (encStrData) => {
  let bytes = cryptoJs.AES.decrypt(encStrData, secretKey)
  return bytes.toString(cryptoJs.enc.Utf8)
}

export const cargoTypeDD = [
  { "name": "Break Bulk" },
  { "name": "Bulk Cargo" },
  { "name": "Oversize Load" },
  { "name": "Liquid Cargo" },
  { "name": "Gas" },
  { "name": "Wheeled Carg" }
]

export const containerTypeDD = [
  { "name": "20 Standard" },
  { "name": "40 Standard" },
  { "name": "40 High Cube" },
  { "name": "45 High Cube" },
  { "name": "20 Open Top" },
  { "name": "40 Open Top" },
  { "name": "20 Flatrack" },
  { "name": "40 Flatrack" },
  { "name": "20 Refrigerate" }
]

export const vesselTypeDD = [
  { "name": "General Cargo" },
  { "name": "Bulk Carriers" },
  { "name": "Containership" },
  { "name": "Tanker Market" },
  { "name": "Specialized" }
]

export function calcHaversineDistance(lat1, lon1, lat2, lon2) {
  var R = 6371; // km
  var dLat = toRad(lat2 - lat1);
  var dLon = toRad(lon2 - lon1);
  var lat1 = toRad(lat1);
  var lat2 = toRad(lat2);

  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d;
}

function toRad(Value) {
  return Value * Math.PI / 180;
}

export const modifi_currencies = [
  { id: 2, name: "Dollars", code: "USD", symbol: "$" },
  { id: 11, name: "Euro", code: "EUR", symbol: "€" },
  { id: 19, name: "Pounds", code: "GBP", symbol: "£" },
  { id: 22, name: "Dollars", code: "CAD", symbol: "$" },
  { "id": 25, "name": "Yuan Renminbi", "code": "CNY", "symbol": "¥" }
]

export const downloadJSONFile = (jsonData, fileName) => {
  // Step 1: Generate JSON content
  const content = JSON.stringify(jsonData, null, 2); // Pretty-print with 2 spaces

  // Step 2: Convert content to Blob
  const blob = new Blob([content], { type: 'application/json' });

  // Step 3: Create a downloadable link
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName || 'data.json'; // Default filename if not provided
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

export const most_used_currencies_with_percentage = [
  { id: null, name: "%", code: "%", symbol: "%" },
  { id: 47, name: "Rupees", code: "INR", symbol: "₹" },
  { id: 6, name: "Dollars", code: "AUD", symbol: "$" },
  { id: 4, name: "Pesos", code: "ARS", symbol: "$" },
  { id: 18, name: "Reais", code: "BRL", symbol: "R$" },
  { id: 22, name: "Dollars", code: "CAD", symbol: "$" },
  { id: 25, name: "Yuan Renminbi", code: "CNY", symbol: "¥" },
  { id: 11, name: "Euro", code: "EUR", symbol: "€" },
  { id: 48, name: "Rupiahs", code: "IDR", symbol: "Rp" },
  { id: 53, name: "Yen", code: "JPY", symbol: "¥" },
  { id: 68, name: "Pesos", code: "MXN", symbol: "$" },
  { id: 87, name: "Rubles", code: "RUB", symbol: "py6" },
  { id: 89, name: "Riyals", code: "SAR", symbol: "﷼" },
  { id: 92, name: "Dollars", code: "SGD", symbol: "$" },
  { id: 95, name: "Rand", code: "ZAR", symbol: "R" },
  { id: 53, name: "WON", code: "KRW", symbol: "₩" },
  { id: 63, name: "Switzerland Francs", code: "CHF", symbol: "CHF" },
  { id: 103, name: "Lira", code: "TRY", symbol: "TL" },
  { id: 19, name: "Pounds", code: "GBP", symbol: "£" },
  { id: 2, name: "Dollars", code: "USD", symbol: "$" }
]

export const most_used_currencies = [
  { id: 47, name: "Rupees", code: "INR", symbol: "₹" },
  { id: 6, name: "Dollars", code: "AUD", symbol: "$" },
  { id: 4, name: "Pesos", code: "ARS", symbol: "$" },
  { id: 18, name: "Reais", code: "BRL", symbol: "R$" },
  { id: 22, name: "Dollars", code: "CAD", symbol: "$" },
  { id: 25, name: "Yuan Renminbi", code: "CNY", symbol: "¥" },
  { id: 11, name: "Euro", code: "EUR", symbol: "€" },
  { id: 48, name: "Rupiahs", code: "IDR", symbol: "Rp" },
  { id: 53, name: "Yen", code: "JPY", symbol: "¥" },
  { id: 68, name: "Pesos", code: "MXN", symbol: "$" },
  { id: 87, name: "Rubles", code: "RUB", symbol: "py6" },
  { id: 89, name: "Riyals", code: "SAR", symbol: "﷼" },
  { id: 92, name: "Dollars", code: "SGD", symbol: "$" },
  { id: 95, name: "Rand", code: "ZAR", symbol: "R" },
  { id: 53, name: "WON", code: "KRW", symbol: "₩" },
  { id: 63, name: "Switzerland Francs", code: "CHF", symbol: "CHF" },
  { id: 103, name: "Lira", code: "TRY", symbol: "TL" },
  { id: 19, name: "Pounds", code: "GBP", symbol: "£" },
  { id: 2, name: "Dollars", code: "USD", symbol: "$" },

  // { id: 44, name: "Dollars", code: "HKD", symbol: "$" },
  // { id: 22, name: "Dollars", code: "CAD", symbol: "$" },
  // { id: 6, name: "Dollars", code: "AUD", symbol: "$" },
  // { id: 63, name: "Switzerland Francs", code: "CHF", symbol: "CHF" },
  // { id: 95, name: "Rand", code: "ZAR", symbol: "R" },
  // { id: 74, name: "Dollars", code: "NZD", symbol: "$" }
]

export function toolTip(msg) {
  return (
    <span className="help-icon" flow="right" tooltip={msg}>i</span>
  )
}

export const astrix = <span className="required-field text-danger">*</span>

export function roundOfNumberInDecimal(no, pointAfterDecimal) {
  no = no / 1
  if (no.toString().includes(".")) {
    return no.toFixed(pointAfterDecimal || 2)
  }
  else {
    return no
  }
}

export async function getDocDetails(id, fileHash, isEximBank) {
  return new Promise((resolve, reject) => {
    try {
      let userTokenDetails = getUserDataFromCookie()
      call('POST', 'getDoc', { id, fileHash, typeId: userTokenDetails.type_id, isEximBank }).then((result) => {
        console.log("getDoc fileResult-->", result)
        if (result) {
          result["fromDb"] = true
          resolve(result)
        }
        else {
          resolve({})
        }
      }).catch(err => {
        resolve({})
      })
    } catch (error) {
      resolve({})
    }
  })
}

export function dataURItoBlobURL(base64Data) {
  // console.log("base64Data==============", base64Data);
  if (!base64Data.includes("base64")) {
    base64Data = "data:application/pdf;base64," + base64Data
  }
  const byteCharacters = atob(base64Data.split(",")[1]);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'application/pdf' });
  return URL.createObjectURL(blob);
}

export function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  // console.log("dataURI==============", dataURI);
  if (!dataURI.includes("base64")) {
    dataURI = "data:application/pdf;base64," + dataURI
  }
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
}

export const LCTypesObject = {
  "sight_lc": "Sight LC",
  "usance_lc": "Usance LC",
  "b2bank_lc": "Back to back LC",
  "green_clause_lc": "Green Clause LC",
  "red_clause_lc": "Red Clause LC",
  "transferable_lc": "Transferable LC"
}

export const LCPurposeObject = {
  "lc_discounting": "LC discounting (International)",
  "lc_confirmation": "LC confirmation (International)",
  "lc_confirmation_discounting": "LC Confirmation & Discounting (International)",
  "lc_discounting_domestic": "LC discounting (Domestic)",
  "lc_confirmation_domestic": "LC confirmation (Domestic)",
  "lc_confirmation_discounting_domestic": "LC Confirmation & Discounting (Domestic)",
  "sblc": "SBLC"
}

export const LCPurposeObjectV2 = {
  "lc_discounting": "Discounting (International)",
  "lc_confirmation": "Confirmation (International)",
  "lc_confirmation_discounting": "Confirmation & Discounting (International)",
  "lc_discounting_domestic": "Discounting (Domestic)",
  "lc_confirmation_domestic": "Confirmation (Domestic)",
  "lc_confirmation_discounting_domestic": "Confirmation & Discounting (Domestic)",
  "sblc": "SBLC",
}

export function getBGTotalOtherCharges(chargeArr, refAmt) {
  let totalCharges = 0
  let referenceAmount = refAmt || 50000
  if (chargeArr && chargeArr.length) {
    for (let index = 0; index < chargeArr.length; index++) {
      const element = chargeArr[index];
      if (element.amount && element.unit != "%") {
        totalCharges += (element.amount / 1)
      }
      else if (element.amount) {
        totalCharges += ((element.amount / 100) * referenceAmount)
      }
    }
  }
  return totalCharges
}

export function getInvoiceTotalOtherCharges(chargeArr, refAmt) {
  let totalCharges = 0
  let referenceAmount = refAmt || 50000
  if (chargeArr && chargeArr.length) {
    for (let index = 0; index < chargeArr.length; index++) {
      const element = chargeArr[index];
      if (element.amount && element.unit != "%") {
        totalCharges += (element.amount / 1)
      }
      else if (element.amount) {
        totalCharges += ((element.amount / 100) * referenceAmount)
      }
    }
  }
  return totalCharges
}

export function getWCTotalCharges(item, refAmt, creditDays) {
  let totalCharges = 0
  let referenceAmount = refAmt || 50000

  let getSlabDivider = (creditDays || 30) / 30
  if (item.factoringRateCurrency === "%") {
    totalCharges += (((item.factoringRate / 100) * referenceAmount) / 12) * getSlabDivider
  }
  else {
    totalCharges += item.factoringRate
  }
  if (item.interestRateCurrency === "%") {
    totalCharges += (((item.interestRate / 100) * referenceAmount) / 12) * getSlabDivider
  }
  else {
    totalCharges += item.interestRate
  }
  if (item.setUpFeesCurrency === "%") {
    totalCharges += (((item.setUpFees / 100) * referenceAmount) / 12) * getSlabDivider
  }
  else {
    totalCharges += item.setUpFees
  }
  totalCharges += getBGTotalOtherCharges(item.otherCharges, refAmt)
  return totalCharges / 1
}

export function getBGTotalCharges(item, refAmt, creditDays) {
  let totalCharges = 0
  let referenceAmount = refAmt || 50000

  let getSlabDivider = (creditDays || 30) / 30
  if (item.confirmationRateCurrency === "%") {
    totalCharges += (((item.confirmationRate / 100) * referenceAmount) / 12) * getSlabDivider
  }
  else {
    totalCharges += item.confirmationRate
  }
  totalCharges += getBGTotalOtherCharges(item.otherCharges, refAmt)
  return totalCharges
}

export function getInvoiceTotalCharges(item, refAmt, creditDays) {
  let totalCharges = 0
  let referenceAmount = refAmt || 50000
  if (item.factoringFeesCurrency === "%") {
    totalCharges += ((item.factoringFees / 100) * referenceAmount)
  }
  else {
    totalCharges += item.factoringFees / 1
  }
  let getSlabDivider = (creditDays || 30) / 30
  totalCharges += (((item.interestRate / 100) * referenceAmount) / 12) * getSlabDivider
  if (item.setupFeesCurrency === "%") {
    totalCharges += ((item.setupFees / 100) * referenceAmount)
  }
  else {
    totalCharges += item.setupFees / 1
  }
  totalCharges += getInvoiceTotalOtherCharges(item.otherCharges, refAmt)
  return totalCharges
}

export function getInvoiceFinalChargesObj(contractAmt, creditDays, item) {
  let finalCharges = {
    "factoring": 0,
    "interest": 0,
    "oneTime": 0,
    "otherCharges": [],
    "total": 0
  }
  let referenceAmount = contractAmt
  // let getSlabDivider = creditDays <= 30 ? 1 : (creditDays > 30 && creditDays <= 45) ? 8.11 : (creditDays > 45 && creditDays <= 60) ? 6 : (creditDays > 60 && creditDays <= 90) ? 4 : creditDays >= 90 ? 3 : null
  let getSlabDivider = creditDays / 30
  console.log("getSlabDivider", creditDays, getSlabDivider);
  if (getSlabDivider) {
    if (item.factoringFeesCurrency === "%") {
      finalCharges["factoring"] = ((((item.factoringFees / 100) * referenceAmount))).toFixed(2) + " " + item.financeLimitCurrency + " (" + item.factoringFees + "%)"
      finalCharges["total"] += ((((item.factoringFees / 100) * referenceAmount)))
    }
    else {
      finalCharges["factoring"] = item.factoringFees + " " + item.financeLimitCurrency
      finalCharges["total"] += item.factoringFees / 1
    }
    if (item.setupFeesCurrency === "%") {
      finalCharges["oneTime"] = ((((item.setupFees / 100) * referenceAmount))).toFixed(2) + " " + item.financeLimitCurrency + " (" + item.setupFees + "%)"
      finalCharges["total"] += ((((item.setupFees / 100) * referenceAmount)))
    }
    else {
      finalCharges["oneTime"] = item.setupFees + " " + item.financeLimitCurrency
      finalCharges["total"] += item.setupFees / 1
    }
    finalCharges["interest"] = ((((item.interestRate / 100) * referenceAmount) / 12) * getSlabDivider).toFixed(2) + " " + item.financeLimitCurrency + " (" + item.interestRate + "%)"
    finalCharges["total"] += ((((item.interestRate / 100) * referenceAmount) / 12) * getSlabDivider)
    if (item.otherCharges && item.otherCharges.length) {
      for (let index = 0; index < item.otherCharges.length; index++) {
        const element = item.otherCharges[index];
        if (element.unit === "%") {
          finalCharges["otherCharges"][index] = { ["OtherCharge" + (index + 1)]: ((((element.amount / 100) * referenceAmount))).toFixed(2) + " " + item.financeLimitCurrency + " (" + element.amount + "%)" }
          finalCharges["total"] += ((((element.amount / 100) * referenceAmount)))
        }
        else {
          finalCharges["otherCharges"][index] = { ["OtherCharge" + (index + 1)]: element.amount + " " + item.financeLimitCurrency }
          finalCharges["total"] += element.amount / 1
        }
      }
    }
    finalCharges["total"] = finalCharges["total"].toFixed(2) + " " + item.financeLimitCurrency
  }
  console.log("ccccccccccccccccccccccccc", finalCharges);
  return finalCharges
}


export function getTotalOtherCharges(chargeArr) {
  let totalCharges = 0
  let referenceAmount = 50000
  for (let index = 0; index < chargeArr?.length; index++) {
    const element = chargeArr[index];
    if (element.amount && element.unit != "%") {
      totalCharges += (element.amount / 1)
    }
    else if (element.amount) {
      totalCharges += ((element.amount / 100) * referenceAmount)
    }
  }
  return totalCharges
}

export function getTotalCharges(item) {
  let totalCharges = 0
  let referenceAmount = 50000
  if (item.giveConfirmationFees) {
    totalCharges += ((item.confirmationFees / 100) * referenceAmount) / 12
  }
  if (item.giveDiscountingRate) {
    totalCharges += ((item.discountingRate / 100) * referenceAmount) / 12
  }
  totalCharges += item.setupFees / 1
  totalCharges += item.handlingCharges / 1
  totalCharges += item.courierCharges / 1
  totalCharges += getTotalOtherCharges(item.otherCharges)
  return totalCharges
}

export let LcFieldsMaster = {
  "lcNo": {
    "title": "LC Number",
    "component": NewInput,
    "type": "text",
    "name": "lcNo",
    "value": "lcNo",
    "error": "lcNo"
  },
  "lcTenor": {
    "title": "LC Tenor",
    "component": NewInput,
    "type": "number",
    "name": "lcTenor",
    "value": "lcTenor",
    "error": "lcTenor"
  },
  "lcType": {
    "title": "LC Type",
    "component": NewSelect,
    "selectData": Lcdetailsdata,
    "name": "lcType",
    "value": "lcType",
    "error": "lcType",
    "optionLabel": "name",
    "optionValue": 'value',
  },
  "shipmentFromCountry": {
    "title": "Shipment from Country",
    "component": NewSelect,
    "selectData": [],
    "name": "shipmentFromCountry",
    "value": "shipmentFromCountry",
    "error": "shipmentFromCountry",
    "optionLabel": "name",
    "optionValue": 'sortname'
  },
  "shipmentToCountry": {
    "title": "Shipment to Country",
    component: NewSelect,
    selectData: [],
    "name": "shipmentToCountry",
    "value": "shipmentToCountry",
    "error": "shipmentToCountry",
    "optionLabel": "name",
    "optionValue": 'sortname'
  },
  "portOfLoading": {
    "title": "Port of Loading",
    component: NewSelect,
    selectData: [],
    "name": "portOfLoading",
    "value": "portOfLoading",
    "error": "portOfLoading",
    "optionLabel": "port_name",
    "optionValue": 'id'
  },
  "portOfDischarge": {
    "title": "Port of Discharge",
    component: NewSelect,
    selectData: [],
    "name": "portOfDischarge",
    "value": "portOfDischarge",
    "error": "portOfDischarge",
    "optionLabel": "port_name",
    "optionValue": 'id'
  },
  "commodity": {
    "title": "Commodity",
    component: NewInput,
    type: "text",
    "name": "commodity",
    "value": "commodity",
    "error": "commodity"
  },
  "expectedDateOfShipment": {
    "title": "Expected date of shipment",
    component: NewInput,
    type: "date",
    "name": "expectedDateOfShipment",
    "value": "expectedDateOfShipment",
    "error": "expectedDateOfShipment"
  },
  "lcIssuingBankAddress": {
    "title": "LC issuing bank address",
    component: NewInput,
    "type": "text",
    "name": "lcIssuingBankAddress",
    "value": "lcIssuingBankAddress",
    "error": "lcIssuingBankAddress"
  },
  "lcIssuingBankSwiftCode": {
    "title": "LC issuing bank SWIFT code",
    component: NewInput,
    "type": "text",
    "name": "lcIssuingBankSwiftCode",
    "value": "lcIssuingBankSwiftCode",
    "error": "lcIssuingBankSwiftCode"
  },
  "countryOfOrigin": {
    "title": "Country of origin",
    component: NewSelect,
    selectData: [],
    "name": "countryOfOrigin",
    "value": "countryOfOrigin",
    "error": "countryOfOrigin",
    optionLabel: "name",
    optionValue: 'sortname'
  },
  "confirmingBankName": {
    "title": "Confirming bank name",
    component: NewInput,
    type: "text",
    "name": "confirmingBankName",
    "value": "confirmingBankName",
    "error": "confirmingBankName"
  },
  "confirmingBankAddress": {
    "title": "Confirming bank address",
    component: NewInput,
    type: "text",
    "name": "confirmingBankAddress",
    "value": "confirmingBankAddress",
    "error": "confirmingBankAddress"
  },
  "confirmingBankSwiftCode": {
    "title": "Confirming bank SWIFT code",
    component: NewInput,
    type: "text",
    "name": "confirmingBankSwiftCode",
    "value": "confirmingBankSwiftCode",
    "error": "confirmingBankSwiftCode"
  }
}

export const finLogoByUserId = {
  51: "assets/images/supplier-images/stenn-logo.png",
  972: "assets/images/Drip_logo.png",
  // "a.wakil@tradewindfinance.com": require("../../constants/assets/tradewind_logo.png"),
  748: "assets/images/supplier-images/modifi-logo.png",
  5035: "assets/images/supplier-images/modifi-logo.png"
}

export const extractNoFromTxt = (noWithTxt) => {
  if (!isNaN(noWithTxt)) {
    return noWithTxt
  }
  if (noWithTxt) {
    let validNos = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "."]
    noWithTxt = noWithTxt.split("")
    let temp = []
    let isDotScanned = false
    for (let index = 0; index < noWithTxt.length; index++) {
      const element = noWithTxt[index];
      if (validNos.includes(element)) {
        if (element != ".") {
          temp.push(element)
        }
        if (element === "." && !isDotScanned) {
          temp.push(element)
          isDotScanned = true
        }
      }
    }
    return temp.join("")
  }
  else {
    return ""
  }
}

export const printDiv = (divName, Filename, pdfArr) => {

  let printContents = document.getElementById(divName)
  window.scrollTo(0, 0);
  let w = printContents.clientWidth;
  let h = printContents.clientHeight;


  html2canvas(printContents, {
    width: w,
    height: h,
    scrollX: 0,
    scrollY: -window.scrollY,
    allowTaint: true,
    useCORS: true
    // foreignObjectRendering: true
  }).then(async (canvas) => {
    // canvas.crossOrigin = 'Anonymous';
    // window.open().document.write('<img src="' + canvas.toDataURL() + '" />');
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF({
      orientation: 'portrait',
      compress: true
    });
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, (pdfHeight > 300 ? pdfHeight - 60 : pdfHeight));
    let filedataUI = new File([pdf.output('blob')], `${Filename}.pdf`, {
      type: pdf.output('blob').type,
      lastModified: pdf.output('blob').lastModified,
    });
    try {
      let filedataPDF = await createPDF.PDFDocumentFromFile(filedataUI)
      const allpdf = [filedataPDF, ...pdfArr]
      const pdfDoc = await mergePDF(allpdf)
      const u8intarr = await pdfDoc.save()
      const blobdata = pdfArrayToBlob(u8intarr)
      let filedata = new File([blobdata], `${Filename}.pdf`, {
        type: blobdata.type,
      });
      let reader = new FileReader();
      let fileObj
      reader.readAsDataURL(filedata);
      reader.onerror = (e) => {
        console.log('Error in pdf download', e)
      }
      reader.onloadend = async (e) => {
        fileObj = filedata
        let fileDataUrl = e.target.result
        fileObj["filebase64"] = fileDataUrl
        console.log('PDF Downloaded succesfully')
        downloadTheFile(fileObj)
      }
    } catch (e) {
      console.log('error', e)
    }

  }).catch(e => { });
}

export function correctFileBase64(filebase64) {
  if (!filebase64.includes("base64,")) {
    return `data:application/pdf;base64,${filebase64}`
  }
  return filebase64
}

function downloadTheFile(result) {
  if (result.filebase64.includes("base64,")) {
    result["filebase64"] = result.filebase64.split("base64,")[1]
  }
  if (result.name.split(".").pop() === "png" || result.name.split(".").pop() === "PNG") {
    console.log(result.name);
    let link = document.createElement("a");
    console.log(link);
    link.download = result.name.split(".")[0] + ".png";
    link.href = 'data:application/png;base64,' + encodeURI(result.filebase64);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else if (result.name.split(".").pop() === "jpg" || result.name.split(".").pop() === "JPG") {
    let link = document.createElement("a");
    link.download = result.name.split(".")[0] + ".jpeg";
    link.href = 'data:application/jpeg;base64,' + encodeURI(result.filebase64);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else if (result.name.split(".").pop() === "pdf" || result.name.split(".").pop() === "PDF") {
    let link = document.createElement("a");
    link.download = result.name.split(".")[0] + ".PDF";
    link.href = 'data:application/pdf;base64,' + encodeURI(result.filebase64);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export const getPDFFromFile = async (file) => {
  console.log('Filebase64', file)
  if (file.filebase64) {
    const filedata = await fetch('data:application/pdf;base64,' + file.filebase64)
    const blob = await filedata.blob();
    const fileObj = new File([blob], file.name, { type: blob.type });
    const pdfFile = await createPDF.PDFDocumentFromFile(fileObj)
    return pdfFile
  }
}
export const isEmpty = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key))
      return false;
  }
  return true
}

export const ExportExcel = (data, filename) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheet.sheet;charset-UTF-8'
  const fileExtension = '.xlsx'
  const ws = XLSX.utils.json_to_sheet(data)
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
  const excelbuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  const finaldata = new Blob([excelbuffer], { type: fileType })
  FileSaver.saveAs(finaldata, filename + fileExtension)
}
export const industryTypesObj = {
  apparelTextile: "Apparels & Textile",
  industrialMech: "Industrial & Mechanical",
  foodBeverage: "Food & Beverage",
  electronicConsumer: "Electronics & Consumer Goods",
  eCommerce: "E-Commerce",
  gamingMedia: "Gaming & Media",
  fmcg: "FMCG",
  medicalPharma: "Medical & Pharmaceutical",
  auto: "Auto",
  frozenFood: "Frozen Foods",
  ITStaffing: "IT Staffing",
  argo: "AGRO",
  govtEntity: "Buyer/Supplier Government Entity",
  oilGas: "Oil/Gas",
  diamond: "Diamond",
  preciousStone: "Precious Stone",
  gold: "Gold",
  others: "Others",
}

export const getDateTimeString = (dateTimeObj) => {
  const dateString = dateTimeObj.toLocaleDateString()
  const dateTimeFormated = dateString.split('/').join('-')
  const TimeString = dateTimeObj.toTimeString()
  const TimeStringFormatted = TimeString.split(' ')[0]
  return dateTimeFormated + " " + TimeStringFormatted
}


export function copyToClipboard(textId) {
  let copyText = document.getElementById(textId);
  let temp = document.createElement("textarea");
  document.body.appendChild(temp);
  temp.value = copyText.innerText
  temp.select();
  document.execCommand("copy");
  document.body.removeChild(temp);
}

export function getNotificationIcon(type, subType) {
  let srcString = `/assets/images/Notification/${type}_${subType}.svg`
  console.log('notificationURL', srcString)
  return srcString
}

export function redirectNotification(type, subType, id) {
  let redirectpath = ''
  if (type === 'LC') {
    if (subType === 'Quote') {
      redirectpath = `/LcQuote?search=${id}`
    } else if (subType === 'Contract') {
      redirectpath = `/LcContract?search=${id}`
    } else if (subType === 'Apply Finance') {
      redirectpath = `/LcApplyforFinance?search=${id}`
    } else if (subType === 'Approved Finance') {
      redirectpath = `/LcApprovedFinance?search=${id}`
    } else if (subType === 'Ammendments') {
      redirectpath = `/LcAmendment?search=${id}`
    }
  } else if (type === 'Invoice Discounting') {
    if (subType === 'Quote') {
      redirectpath = `/Quotes?search=${id}`
    } else if (subType === 'Contract') {
      redirectpath = `/invoicecontract?search=${id}`
    } else if (subType === 'Apply Finance') {
      redirectpath = `/apply-finance?search=${id}`
    } else if (subType === 'Approved Finance') {
      redirectpath = `/approvedFinance?search=${id}`
    } else if (subType === 'Sign Agreement') {
      redirectpath = `/invoice-agreement?search=${id}`
    }

  } else if (type === 'Other') {
    if (subType === 'Chat room') {
      redirectpath = '/ChatRoomV2'
    } else if (subType === 'Wallet') {
      redirectpath = '/wallet'
    } else if (subType === 'Profile') {
      redirectpath = '/viewprofile'
    } else if (subType === 'Buyer Management') {
      redirectpath = `/buyerManagement?search=${id}`
    } else if (subType === 'Reports') {
      redirectpath = '/Reportv2'
    }
  }
  window.location = redirectpath
}

export function getFinancerAvailability(findata, type, userdata) {
  return new Promise(async (resolve) => {
    try {
      const result = await call('POST', 'getCreditLine', { userId: findata.id })
      console.log('result', result)
      if (isEmpty(result)) {
        return resolve({
          isAvailable: true,
          info_text: 'No Credit Line Found'
        })
      } else {
        let errors = {}
        let prevYearAnnualSaleRequired = result.prevYearAnnualSaleRequired
        let onboarding = JSON.parse(result.onboarding)
        let industry = JSON.parse(result.industry)
        let companyTypes = JSON.parse(result.companyTypes)
        let services = JSON.parse(result.services)
        let hsncodes = JSON.parse(result.HSNCodes)
        let supplierCountries = JSON.parse(result.supplierCountries)
        let buyerCountries = JSON.parse(result.buyerCountries)
        let currencies = JSON.parse(result.currencies)
        let minimumRequiredLimitInUsd = result.minimumExpectedLimitInUSD
        let userEntererdLimitInUsd = await call('POST', 'convertValueIntoUSD', { amount: userdata.limitRequired, currencyCode: userdata.limitCurrency })
        let exportRangeOfExporter = result.exportRange?.split("-") || []
        let minRangeOfExport = exportRangeOfExporter?.[0]?.trim()
        let maxRangeOfExport = exportRangeOfExporter?.[1]?.trim()
        // console.log("getFinancerAvailabilityyyyyyyyyyyyyyyyyyyyyyyy", companyTypes, findata, type, userdata)
        if (minRangeOfExport && maxRangeOfExport) {
          if (!(minRangeOfExport && maxRangeOfExport && userdata.exporterTurnOver > minRangeOfExport / 1 && userdata.exporterTurnOver < maxRangeOfExport / 1)) {
            errors.exporterTurnOver = `Export turn over is not in a range of ${result.exportRange} USD`
          }
        }


        if (minimumRequiredLimitInUsd && userEntererdLimitInUsd && userEntererdLimitInUsd < minimumRequiredLimitInUsd) {
          errors.minimumRequiredLimitInUsd = `Required limit is less than ${minimumRequiredLimitInUsd} USD`
        }
        if (prevYearAnnualSaleRequired) {
          if (!type.previousAnnualSale) {
            errors.previousAnnualSale = 'Buyer Previous year annual sale not present'
          }
        }
        if (onboarding.hasOwnProperty('dunsNo') && onboarding.dunsNo) {
          if (!userdata.buyerDUNSNo || userdata.buyerDUNSNo === 'NA') {
            errors.buyerDUNSNo = 'Buyer DUNS No. not available'
          }
        }
        if (industry && !isEmpty(industry)) {
          if (!industry[userdata.industry_type]) {
            errors.industry_type = 'Industry Type not supported by financer'
          }
        }
        if (companyTypes && !isEmpty(companyTypes)) {
          if (!companyTypes[userdata.organization_type]) {
            errors.organization_type = `Organization Type (${userdata.organization_type}) not supported by financer, only supports ${Object?.keys(companyTypes)?.filter(i => {
              if (companyTypes[i]) {
                return true
              }
            })} `
          }
        }

        if (services && !isEmpty(services)) {
          if (!Object.keys(services).some(item => item.includes(type) && services[item])) {
            errors.organization_type = 'Service Not Provided by financer'
          }
        }
        if (hsncodes && !isEmpty(hsncodes)) {
          let isHSNSupported = false
          if (userdata.buyerHsnCode) {
            Object.keys(hsncodes).forEach(item => {
              const hsncodesBuyer = userdata.buyerHsnCode.split(',')
              if (hsncodesBuyer.includes(item?.toString()) && hsncodes[item]) {
                isHSNSupported = true
              }
            })
          } else {
            isHSNSupported = true
          }
          if (!isHSNSupported) {
            errors.hsncodes = 'HSN Code Not Supported by financer'
          }
        }

        if (supplierCountries && !isEmpty(supplierCountries)) {
          let isCountryAvailable = true
          Object.keys(supplierCountries).forEach(item => {
            if (userdata.country_code?.toLowerCase() === item.split('-')[0]?.toLowerCase() && supplierCountries[item]) {
              isCountryAvailable = false
            }
          })
          if (!isCountryAvailable) {
            errors.supplierCountries = 'Supplier Country Not Supported by financer'
          }
        }
        if (buyerCountries && !isEmpty(buyerCountries)) {
          let isBuyerCountryAvailable = true
          Object.keys(buyerCountries).forEach(item => {
            if (userdata.buyerCountry?.toLowerCase() === item.split('-')[0]?.toLowerCase() && buyerCountries[item]) {
              isBuyerCountryAvailable = false
            }
          })
          if (!isBuyerCountryAvailable) {
            errors.buyerCountries = 'Buyer Country Not Supported by financer'
          }
        }
        if (currencies && !isEmpty(currencies)) {
          if (!Object.keys(currencies).some(item => item?.toLowerCase() === userdata.limitCurrency?.toLowerCase() && currencies[item])) {
            errors.currencies = 'Currency Not Supported by financer'
          }
        }

        console.log('errors', errors)
        if (isEmpty(errors)) {
          return resolve({
            isAvailable: true,
            info_text: ''
          })
        } else {
          return resolve({
            isAvailable: false,
            info_text: Object.values(errors).map(value => value)
          })
        }

      }
    } catch (e) {
      console.log('no cl', e);
      return resolve({
        isAvailable: true,
        info_text: 'No Credit Line Found'
      })
    }
  })
}

export const sendDocumentsToFinancer = async (userId, doc_id, userEmail, doc_type, typeId) => {
  if (typeId == 19) {
    try {
      const result = await call('POST', 'sendDocumentsToFinancer', { userId, doc_id, userEmail, doc_type })
    } catch (e) {
      console.log('error in sendDocumentsToFinancer', e)
    }
  }
}

export function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

export function detectPostalCode(str) {
  // Split the string into two parts
  let firstHalf = str.substring(0, str.length / 2);
  let secondHalf = str.substring(str.length / 2);

  // Use a regular expression to match a 5 to 8 digit number in the second half
  let match = secondHalf.match(/\d{5,8}/);

  // If a match is found, return the number
  if (match) {
    return match[0];
  }
  // If no match is found, return "Not Found"
  else {
    return "";
  }
}

export function checkPermission(UserAccessPermission, sub_user_id, event, route, toastDisplay, userId, callback, isSubUser, isAdmin) {
  if (!isAdmin) {
    if (userId === sub_user_id) {
      if (callback) {
        callback()
      }
      return
    }
  }
  if (isAdmin && isSubUser === "") {
    if (callback) {
      callback()
    }
    return
  }
  if ((sub_user_id !== 0 && sub_user_id !== null) || isSubUser) {
    if (UserAccessPermission.includes(route)) {
      if (callback) {
        callback()
      }

    } else {
      event.preventDefault()
      toastDisplay("Permission Denied, Ask Owner for permission.", "info")
      if (callback) {
        callback()
      }
    }
  }
}

export const isOutStandingDatePassed = (scheduledOn, status) => {
  return (moment(scheduledOn).add(1, "day") < moment() && status / 1 == 0)
}

export const insertObjectInArray = (arrayData, objToInsert) => {
  for (let index = 0; index < arrayData.length; index++) {
    arrayData[index] = { ...arrayData[index], ...objToInsert }
  }
  return arrayData
}

export const isUserInactive = (lastLoginAt) => {
  if (!lastLoginAt) {
    return true
  }
  let activeUserLogicDurationInWeeks = 4
  let lastLoginBeforeWeeks = moment().diff(moment(lastLoginAt), "week")
  if (lastLoginBeforeWeeks >= activeUserLogicDurationInWeeks) {
    return true
  }
  return false
}

export function getInitials(name) {
  var initials = "";
  var nameArray = name?.split(" ");

  for (var i = 0; i < nameArray?.length; i++) {
    var firstLetter = nameArray[i].charAt(0).toUpperCase();
    initials += firstLetter;
  }

  return initials;
}

export function isFormDataEmpty(formData) {
  for (const value of formData.values()) {
    if (value) {
      return false; // Found a non-empty value, FormData is not empty
    }
  }
  return true; // No non-empty values found, FormData is empty
}

export function returnCSVTxtForm(txt, emptyTxt) {
  if (txt) {
    return `"${txt}"`
  }
  else if (emptyTxt) {
    return `"${emptyTxt}"`
  }
  else {
    return `"NA"`
  }
}

export function removeNextLine(txt) {
  return txt?.replace(/(\r\n|\r|\n)/g, ' ');
}

export function removeDuplicates(arr) {
  return arr.filter((item,
    index) => arr.indexOf(item) === index);
}

export function hasOverlapInObject(obj) {
  const seen = new Set();
  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      const array = obj[prop].EXPORTER_CODE;
      for (const value of array) {
        if (seen.has(value)) {
          return true;
        }
        seen.add(value);
      }
    }
  }
  return false;
}

export function evaluateCPCommissionPercentBasedOnInvoiceAmount(invoiceAmount, CPChargesCommission) {
  invoiceAmount = invoiceAmount / 1
  let CPCommissionObjKeysArray = Object.keys(CPChargesCommission)
  let CPCommissionObjValuesArray = Object.values(CPChargesCommission)
  let commissionPercentage = 0
  for (let index = 0; index < CPCommissionObjKeysArray.length; index++) {
    const element = CPCommissionObjKeysArray[index];
    if (!commissionPercentage) {
      if (element.includes("<>")) {
        let rangeOfValue = element.split("<>")
        if (rangeOfValue[0] <= invoiceAmount && invoiceAmount <= rangeOfValue[1]) {
          commissionPercentage = CPCommissionObjValuesArray[index] / 1
        }
      }
      else if (element.includes("<<")) {
        let maxRangeValue = element.split("<<")
        if (invoiceAmount >= maxRangeValue[0]) {
          commissionPercentage = CPCommissionObjValuesArray[index] / 1
        }
      }
    }
  }
  return commissionPercentage
}

export const getContactObject = (EXTRA_DETAILS = []) => {
  let contactRow = {}
  const primaryCotact = EXTRA_DETAILS.find(element => element.isPrimary && element["Contact Number"])
  if (primaryCotact) {
    contactRow = primaryCotact
    return primaryCotact
  }
  const mangDirector = EXTRA_DETAILS.find(element => element.Designation?.toUpperCase() === 'MANAGING DIRECTOR' && element["Contact Number"])
  if (mangDirector && mangDirector["Contact Number"]) {
    contactRow = mangDirector
    return mangDirector
  }

  const wholetimeDirector = EXTRA_DETAILS.find(element => element.Designation?.toUpperCase() === 'WHOLETIME DIRECTOR' && element["Contact Number"])
  if (wholetimeDirector && wholetimeDirector["Contact Number"]) {
    contactRow = wholetimeDirector
    return wholetimeDirector
  }

  const directordata = EXTRA_DETAILS.find(element => element.Designation?.toUpperCase() === 'DIRECTOR' && element["Contact Number"])

  if (directordata && directordata["Contact Number"]) {
    contactRow = directordata
    return directordata
  }


  const desgPartner = EXTRA_DETAILS.find(element => element.Designation?.toUpperCase() === 'DESIGNATED PARTNER' && element["Contact Number"])
  if (desgPartner && desgPartner["Contact Number"]) {
    contactRow = desgPartner
    return desgPartner
  }

  const manager = EXTRA_DETAILS.find(element => element.Designation?.toUpperCase() === 'Manager' && element["Contact Number"])
  if (manager && manager["Contact Number"]) {
    contactRow = manager
    return manager
  }
  const financeDep = EXTRA_DETAILS.find(element => element.Department === 'Finance' && element["Contact Number"])
  if (financeDep && financeDep["Contact Number"]) {
    contactRow = financeDep
    return financeDep
  }
  const HRDep = EXTRA_DETAILS.find(element => element.Department === 'Human Resource' && element["Contact Number"])
  if (HRDep && HRDep["Contact Number"]) {
    contactRow = HRDep
    return HRDep
  }
  const ManagementDep = EXTRA_DETAILS.find(element => element.Department === 'Management' && element["Contact Number"])
  if (ManagementDep && ManagementDep["Contact Number"]) {
    contactRow = ManagementDep
    return ManagementDep
  }
  if (!isEmpty(contactRow) && contactRow["Contact Number"]) {
    const anyContactRow = EXTRA_DETAILS.find(element => element["Contact Number"])
    if (anyContactRow) {
      return anyContactRow
    }
  } else {
    return contactRow
  }
}

export const generateInvoiceNumber = (createdAt, companyName) => {

  const companyInitials = getInitials(companyName)

  return `INV-${companyInitials}-${new Date(createdAt).getTime()}`
}

export const getCompletePdfBase64 = (base64Str) => {
  if (base64Str.includes(";base64")) {
    return base64Str
  }
  else {
    return "data:application/pdf;base64," + base64Str
  }
}

export function getFiscalYearDates(year) {
  // Define the start month and day of the fiscal year
  const fiscalYearStartMonth = 4; // April
  const fiscalYearStartDay = 1;

  // Calculate the start date of the fiscal year
  const startDate = new Date(parseInt(year), fiscalYearStartMonth - 1, fiscalYearStartDay);

  // Calculate the end date of the fiscal year (start date of next fiscal year minus one day)
  const endDate = new Date(parseInt(year) + 1, fiscalYearStartMonth - 1, fiscalYearStartDay - 1);

  return { startDate: moment(startDate).format('YYYY-MM-DD'), endDate: moment(endDate).format('YYYY-MM-DD') };
}
export const getFiscalYearsDropdown = () => {
  var startYear = new Date().getFullYear();
  var prevYear = new Date().getFullYear() - 1;
  let yearsDropDown = []
  for (var i = 0; i < 30; i++) {
    yearsDropDown.push({
      label: prevYear + "-" + startYear,
      value: prevYear
    })
    startYear = startYear - 1;
    prevYear = prevYear - 1;

  }
  return yearsDropDown
}

export const gettotalCount = (data, key) => {

  let sum = 0
  data?.forEach(element => {
    sum += element[key]
  })
  return sum
}

export function addDaysSkipSundays(startDate, days) {
  var currentDate = new Date(startDate.getTime());
  var count = 0;

  while (count < days) {
    currentDate.setDate(currentDate.getDate() + 1); // Add 1 day to the current date

    if (currentDate.getDay() !== 0) {
      // Check if the current date is not a Sunday (0 represents Sunday)
      count++;
    }
  }

  return currentDate;
}

export const mergeMultiplePdfs = async (files, fileName) => {
  if (files.length / 1 == 1) {
    return files[0]
  }

  const mergedPdfDoc = await PDFDocument.create();
  files = files.filter((element) => {
    return Boolean(element); // Removes empty elements
  });

  for (const file of files) {
    const pdfDoc = await PDFDocument.load(await file.arrayBuffer());
    const copiedPages = await mergedPdfDoc.copyPages(pdfDoc, [0]);
    copiedPages.forEach((page) => mergedPdfDoc.addPage(page));
  }

  const mergedPdfBytes = await mergedPdfDoc.save();

  // Convert merged PDF bytes into a base64 string
  const mergedPdfBase64 = btoa(
    new Uint8Array(mergedPdfBytes).reduce((data, byte) => data + String.fromCharCode(byte), '')
  );

  // Create a new File object with the additional fileBase64 property
  const mergedPdfFile = new File([mergedPdfBytes], fileName + '.pdf', {
    type: 'application/pdf',
  });

  mergedPdfFile["filebase64"] = "data:application/pdf;base64," + mergedPdfBase64

  return mergedPdfFile;
};


export const getProgressPercentage = (value) => {
  let numParts = 4;

  let parts = [];

  for (let i = 0; i < numParts; i++) {
    if (value >= 25) {
      parts.push(25);
      value -= 25;
    } else {
      parts.push(value);
      value = 0;
    }
  }
  return parts
}


export const handleMultipleFile = async (event) => {
  // console.log("handleMultipleFile", event);
  return new Promise((resolve, reject) => {
    event.persist()
    if (!event.target.files.length) {
      return resolve({ success: false, msg: { [event.target.name]: 'Kindly Select File' } })
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")) || (file_type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")) || (file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")))) {
        return resolve({ success: false, msg: { [event.target.name]: "Files with pdf, png, jpeg, docx & xlsx extension are allowed" } })
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (file_type.includes("png") || file_type.includes("jpeg")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') || file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
          try {
            let formData = new FormData()
            formData.append("file", fileObj)
            if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
              formData.append("docType", "docx")
            }
            if (file_type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
              formData.append("docType", "xlsx")
            }
            let apiResp = await call('POST', "docToPdf", formData)
            let blob = dataURItoBlob(apiResp);
            fileObj = new File([blob], fileObj.name + "converted.pdf", { type: "application/pdf" });
            fileDataUrl = apiResp
            toastDisplay("File converted into pdf format", "success")
          } catch (error) {
            return toastDisplay("failed to convert file", "error")
          }
        }
        fileObj["filebase64"] = fileDataUrl
        return resolve({ success: true, msg: fileObj })
      }
    }
  })
}

export const financialYearMonths = [{ name: "April" }, { name: 'May' }, { name: 'June' }, { name: 'July' }, { name: 'August' },
{ name: 'September' }, { name: 'October' }, { name: 'November' }, { name: 'December' }, { name: 'January' }, { name: 'February' }, { name: 'March' }]


export const handleFileCommonFunction = (event, data, errors, setData, setErrors, setshowLoader) => {
  event.persist()
  if (!event.target.files.length) {
    return null
  }
  else {
    let file_type = event.target.files[0]["type"].toLowerCase()
    if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")) || (file_type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")) || (file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")))) {
      setErrors({ ...errors, [event.target.name]: "Files with pdf, png, jpeg, docx & xlsx extension are allowed" })
      return
    }
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onloadend = async (e) => {
      let fileObj = event.target.files[0]
      let fileDataUrl = e.target.result
      if (file_type.includes("png") || file_type.includes("jpeg")) {
        let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
        console.log("pdfconversionresp", response);
        fileObj = response["file"]
        fileDataUrl = response['dataUrl']
        toastDisplay("File converted into pdf format", "success")
      }
      if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') || file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
        try {
          setshowLoader(true)
          let formData = new FormData()
          formData.append("file", fileObj)
          if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
            formData.append("docType", "docx")
          }
          if (file_type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
            formData.append("docType", "xlsx")
          }
          let apiResp = await call('POST', "docToPdf", formData)
          let blob = dataURItoBlob(apiResp);
          fileObj = new File([blob], fileObj.name + "converted.pdf", { type: "application/pdf" });
          fileDataUrl = apiResp
          toastDisplay("File converted into pdf format", "success")
        } catch (error) {
          return toastDisplay("failed to convert file", "error")
        }
        setshowLoader(false)
      }
      fileObj["filebase64"] = fileDataUrl
      setData({ ...data, [event.target.name]: fileObj })
      setErrors({ ...errors, [event.target.name]: "" });
    }
  }
}

export const replaceFileBase64WithFileObj = (dataObj) => {
  try {
    let objKeys = Object.keys(dataObj)
    for (let index = 0; index < objKeys.length; index++) {
      let tempFileObj = null
      if (dataObj?.[objKeys[index]]?.filebase64) {
        tempFileObj = dataURItoBlob(dataObj[objKeys[index]].filebase64)
        tempFileObj = new File([tempFileObj], dataObj[objKeys[index]].path, { type: "application/pdf" });
        tempFileObj["filebase64"] = dataObj[objKeys[index]].filebase64
        tempFileObj["path"] = dataObj[objKeys[index]].path
        tempFileObj["signatureId"] = dataObj[objKeys[index]].signatureId
        dataObj[objKeys[index]] = tempFileObj
      }
    }
  } catch (error) {
    console.log("errorinreplaceFileBase64WithFileObj", error);
  }
  return dataObj
}

export const multiSelectEventHandler = (data, nameKey, valueKey) => {
  if (data?.length) {
    data = data.reverse()
  }
  return {
    persist: () => { },
    target: {
      name: nameKey,
      value: data?.[0]?.[valueKey]
    }
  }
}

export const exportersNavConfig = [
  {
    "id": "1",
    "icon": "assets/images/sidebarV2/dashboard.svg",
    "text": "Dashboard",
    "level": 0,
    "stateVal": "dashboard",
    "redirectURL": "/dashboard",
    "permissionVal": "dashboard",
    "notificationVal": "buyer management"
  },
  {
    "id": "2",
    "icon": "assets/images/sidebarV2/buyermanagement.svg",
    "text": "Buyer Management",
    "level": 0,
    "stateVal": "buyerManagement",
    "redirectURL": "/buyermanagement",
    "permissionVal": "dashboard",
    "notificationVal": "dashboard"
  },
  {
    "id": "3",
    "icon": "assets/images/sidebarV2/lc_menu.svg",
    "text": "Letter of Credit",
    "level": 1,
    "stateVal": "Lc",
    "subItems": [
      {
        "name": "Check For Limit",
        "stateVal": "LcDiscountingV2",
        "redirectURL": "/LcDiscountingV2",
        "permissionVal": "LcDiscountingV2"
      },
      {
        "name": "Quotes",
        "stateVal": "LcQuote",
        "redirectURL": "/LcQuote",
        "permissionVal": "LcQuote"
      },
      {
        "name": "Contract",
        "stateVal": "LcContract",
        "redirectURL": "/LcContract",
        "permissionVal": "LcContract",
        "notificationVal": ""
      },
      {
        "name": "Apply For Finance",
        "stateVal": "LcApplyforFinance",
        "redirectURL": "/LcApplyforFinance",
        "permissionVal": "LcApplyforFinance",
        "notificationVal": ""
      },
      {
        "name": "Approved Finance",
        "stateVal": "LcApprovedFinance",
        "redirectURL": "/LcApprovedFinance",
        "permissionVal": "LcApprovedFinance",
        "notificationVal": ""
      }
    ],
    "collapseTarget": "collapseLc",
    "notificationVal": "lc"
  },
  {
    "id": "4",
    "icon": "assets/images/sidebarV2/invoice_menu.svg",
    "activeIcon": "assets/images/sidebarV2/invoice_menu_active.svg",
    "text": "Export Factoring",
    "level": 1,
    "stateVal": "invoice",
    "subItems": [
      {
        "name": "Apply For Limit",
        "stateVal": "invoice_applyLimit",
        "redirectURL": "/applyLimitV2",
        "permissionVal": "applyLimit",
        "notificationVal": ""
      },
      {
        "name": "Quotes",
        "stateVal": "invoice_Quotes",
        "redirectURL": "/Quotes",
        "permissionVal": "Quotes",
        "notificationVal": ""
      },
      {
        "name": "Termsheet",
        "stateVal": "invoicecontract",
        "redirectURL": "/invoicecontract",
        "permissionVal": "Quotes",
        "notificationVal": ""
      },
      {
        "name": "Apply For Finance",
        "stateVal": "invoice_apply-finance",
        "redirectURL": "/apply-finance",
        "permissionVal": "apply-finance",
        "notificationVal": ""
      },
      {
        "name": "Sign Agreement",
        "stateVal": "invoice-agreement",
        "redirectURL": "/invoice-agreement",
        "permissionVal": "invoice-agreement",
        "notificationVal": ""
      },
      {
        "name": "Approved Finance",
        "stateVal": "invoice_approvedFinance",
        "redirectURL": "/approvedFinance",
        "permissionVal": "approvedFinance",
        "notificationVal": ""
      }
    ],
    "collapseTarget": "collapseinv",
    "notificationVal": "invoicee discounting"
  },
  {
    "id": "5",
    "icon": "assets/images/sidebarV2/other_financial.svg",
    "text": "Other Financial",
    "level": 2,
    "stateVal": "otherFin",
    "subItems": [
      {
        "name": "Working Capital",
        "stateVal": "otherFinworkingCapital",
        "collapseTarget": "collapseWC",
        "stages": [
          { "name": "Apply For Limit", redirectURL: "/wcApplyLimit", stateVal: "otherFinWC" },
          { "name": "Quote", redirectURL: "/wcQuote", stateVal: "otherFinWCQuote" }
        ]
      },
      {
        "name": "CGTMSE",
        "stateVal": "otherFinCGTMSE",
        "collapseTarget": "collapseCGTMSE",
        "stages": [
          { "name": "Apply For Limit", redirectURL: "/applylimitCGTMSE", stateVal: "otherFinCGTMSEApplyLimit" },
          { "name": "Quote", redirectURL: "/cgtmseQuote", stateVal: "otherFinCGTMSEQuote" }
        ]
      },
      {
        "name": "Bank Gurantee",
        "stateVal": "bg",
        "collapseTarget": "collapseBG",
        "stages": [
          { "name": "Get Confirmation", redirectURL: "/bgGetConfirmation", stateVal: "bgGetConfirmation" },
          { "name": "Quote", redirectURL: "/bgQuote", stateVal: "bgQuote" },
          { "name": "Confirmed BG", redirectURL: "/bgConfirmed", stateVal: "bgConfirmed" }
        ]
      },
      {
        "name": "Supply Chain Finance",
        "stateVal": "otherFinSCF",
        "collapseTarget": "collapseSCF",
        "stages": [
          { "name": "Apply For Limit", redirectURL: "/applylimitSCF", stateVal: "otherFinSCFlimit" },
          { "name": "Quote", redirectURL: "/scfQuote", stateVal: "otherFinSCFQuote" },
          { "name": "Approved Limit", redirectURL: "/approvedLimitSCF", stateVal: "otherFinSCFApprovedLimit" },
          { "name": "Finance Application", redirectURL: "/approvedLimitSCF", stateVal: "otherFinSCFApprovedLimit" }
        ]
      },
      {
        "name": "Domestic Invoice Discounting",
        "stateVal": "otherFinDID",
        "collapseTarget": "collapseDID",
        "stages": [
          { "name": "Apply For Finance", redirectURL: "/applyfinDID", stateVal: "otherFinDIDApplyFin" },
          { "name": "Approved Finance", redirectURL: "/invoiceFinance", stateVal: "otherFinDIDApprovedFin" }
        ]
      }
    ],
    "collapseTarget": "collapseOtherFin",
  },
  {
    "id": "6",
    "icon": "assets/images/sidebarV2/tradeDiscovery.svg",
    "text": "Trade Discovery",
    "level": 0,
    "stateVal": "tradeDiscovery",
    "redirectURL": "/tradeDiscovery",
    "permissionVal": "tradeDiscovery",
    "notificationVal": "tradeDiscovery"
  },
  {
    "id": "7",
    "icon": "assets/images/sidebarV2/wallet.svg",
    "text": "Wallet",
    "level": 0,
    "stateVal": "wallet",
    "redirectURL": "/wallet",
    "permissionVal": "wallet",
    "notificationVal": "wallet"
  },
  {
    "id": "8",
    "icon": "assets/images/sidebarV2/chatroom2.svg",
    "text": "Chat Room",
    "level": 0,
    "stateVal": "ChatRoomV2",
    "redirectURL": "/ChatRoomV2",
    "permissionVal": "ChatRoomV2",
    "notificationVal": "chat room"
  },
  {
    "id": "9",
    "icon": "assets/images/document_vault.svg",
    "text": "Document Vault",
    "level": 1,
    "stateVal": "docVault",
    "subItems": [
      {
        "name": "Transaction",
        "stateVal": "docVaultTransaction",
        "redirectURL": "/docVaultTransaction",
        "permissionVal": "",
        "notificationVal": ""
      }
    ],
    "collapseTarget": "collapsedocVault",
    "notificationVal": ""
  },
  {
    "id": "10",
    "icon": "assets/images/sidebarV2/reports_icon.svg",
    "text": "Reports",
    "level": 1,
    "stateVal": "Reportv2",
    "localStorageKey": "report_name",
    "subItems": [
      {
        "name": "Payment",
        "stateVal": "Reportv2",
        "redirectURL": "/Reportv2",
        "permissionVal": "Reportv2",
        "notificationVal": ""
      },
      {
        "name": "Finance",
        "stateVal": "Reportv2",
        "redirectURL": "/Reportv2",
        "permissionVal": "Reportv2",
        "notificationVal": ""
      },
      {
        "name": "Shipment",
        "stateVal": "Reportv2",
        "redirectURL": "/Reportv2",
        "permissionVal": "Reportv2",
        "notificationVal": ""
      }
    ],
    "collapseTarget": "collapseReports",
    "notificationVal": "invoicee discounting"
  }
]

export const importerExporterConfig = [
  {
    "id": "1",
    "icon": "assets/images/sidebarV2/workOrderContract.svg",
    "activeIcon": "assets/images/sidebarV2/workOrderContract.svg",
    "text": "Workorder Contracts",
    "level": 1,
    "stateVal": "workOrderContract",
    "subItems": [
      {
        "name": "Create Contract",
        "stateVal": "createWorkOrderContract",
        "redirectURL": "/createWorkOrderContract",
        "permissionVal": "createWorkOrderContract",
        "notificationVal": ""
      },
      {
        "name": "Contract List",
        "stateVal": "workOrderContracts",
        "redirectURL": "/workOrderContracts",
        "permissionVal": "workOrderContracts",
        "notificationVal": "workOrderContracts"
      }
    ],
    "collapseTarget": "workOrderContract",
    "notificationVal": "workOrderContract"
  },
  {
    "id": "2",
    "icon": "assets/images/sidebarV2/workOrderContract.svg",
    "activeIcon": "assets/images/sidebarV2/workOrderContract.svg",
    "text": "Manage Commodities",
    "level": 1,
    "stateVal": "manageCommodities",
    "subItems": [
      {
        "name": "Add Commodity",
        "stateVal": "manageCommoditiesAdd",
        "redirectURL": "/manageCommodity?add=true",
        "permissionVal": "manageCommoditiesAdd",
        "notificationVal": "manageCommoditiesAdd"
      },
      {
        "name": "Commodities List",
        "stateVal": "manageCommoditiesList",
        "redirectURL": "/commoditiesList",
        "permissionVal": "manageCommoditiesList",
        "notificationVal": "manageCommoditiesList"
      }
    ],
    "collapseTarget": "manageCommodities",
    "notificationVal": "manageCommodities"
  },
  {
    "id": "3",
    "icon": "assets/images/sidebarV2/buyermanagement.svg",
    "activeIcon": "assets/images/sidebarV2/buyermanagement.svg",
    "text": "Manage Network",
    "level": 1,
    "stateVal": "Network",
    "subItems": [
      {
        "name": "My Network",
        "stateVal": "MyNetwork",
        "redirectURL": "/MyNetwork",
        "permissionVal": "MyNetwork",
        "notificationVal": "MyNetwork"
      },
      {
        "name": "Manage Network",
        "stateVal": "ManageNetwork",
        "redirectURL": "/ManageNetwork",
        "permissionVal": "ManageNetwork",
        "notificationVal": "ManageNetwork"
      },
      {
        "name": "Network Requests",
        "stateVal": "NetworkRequests",
        "redirectURL": "/NetworkRequests",
        "permissionVal": "NetworkRequests",
        "notificationVal": "NetworkRequests"
      },
      {
        "name": "Blocked Users",
        "stateVal": "NetworkBlockedUsers",
        "redirectURL": "/NetworkBlockedUsers",
        "permissionVal": "NetworkBlockedUsers",
        "notificationVal": "NetworkBlockedUsers"
      }
    ],
    "collapseTarget": "Network",
    "notificationVal": "Network"
  },
  {
    "id": "4",
    "icon": "assets/images/sidebarV2/walletBlack.png",
    "activeIcon": "assets/images/sidebarV2/walletBlack.png",
    "text": "Plan",
    "level": 1,
    "stateVal": "plan",
    "subItems": [
      {
        "name": "Purchase Plan",
        "stateVal": "Purchaseplan",
        "redirectURL": "/Purchaseplan",
        "permissionVal": "Purchaseplan",
        "notificationVal": "Purchaseplan"
      },
      {
        "name": "Manage Plan",
        "stateVal": "Manageplan",
        "redirectURL": "/Manageplan",
        "permissionVal": "Manageplan",
        "notificationVal": "Manageplan"
      },
    ],
    "collapseTarget": "plan",
    "notificationVal": "plan"
  },
  {
    "id": "5",
    "icon": "assets/images/sidebarV2/tradeDiscovery.svg",
    "activeIcon": "assets/images/sidebarV2/tradeDiscovery.svg",
    "text": "Shipment Booking",
    "level": 1,
    "stateVal": "ShipmentBooking",
    "subItems": [
      {
        "name": "Market Place",
        "stateVal": "ShipmentBookingMarketPlace",
        "redirectURL": "/ShipmentBookingMarketPlace",
        "permissionVal": "ShipmentBookingMarketPlace",
        "notificationVal": "ShipmentBookingMarketPlace"
      },
      {
        "name": "Apply For Booking",
        "stateVal": "ShipmentBookingApply",
        "redirectURL": "/ShipmentBookingApply",
        "permissionVal": "ShipmentBookingApply",
        "notificationVal": "ShipmentBookingApply"
      },
      {
        "name": "Quotes",
        "stateVal": "ShipmentBookingQuotes",
        "redirectURL": "/ShipmentBookingQuotes",
        "permissionVal": "ShipmentBookingQuotes",
        "notificationVal": "ShipmentBookingQuotes"
      },
      {
        "name": "Contract",
        "stateVal": "ShipmentBookingContract",
        "redirectURL": "/ShipmentBookingContract",
        "permissionVal": "ShipmentBookingContract",
        "notificationVal": "ShipmentBookingContract"
      }
    ],
    "collapseTarget": "ShipmentBooking",
    "notificationVal": "ShipmentBooking"
  },
  {
    "id": "6",
    "icon": "assets/images/settings.svg",
    "activeIcon": "assets/images/settings.svg",
    "text": "Settings",
    "level": 1,
    "stateVal": "Settings",
    "subItems": [
      {
        "name": "Notifications",
        "stateVal": "SettingsNotificationList",
        "redirectURL": "/NotificationList",
        "permissionVal": "SettingsNotificationList",
        "notificationVal": "SettingsNotificationList"
      },
      {
        "name": "Notification Settings",
        "stateVal": "SettingsNotification",
        "redirectURL": "/SettingsNotification",
        "permissionVal": "SettingsNotification",
        "notificationVal": "SettingsNotification"
      }
    ],
    "collapseTarget": "Settings",
    "notificationVal": "Settings"
  },
]


export const shipperConfig = [
  {
    "id": "1",
    "icon": "assets/images/sidebarV2/tradeDiscovery.svg",
    "activeIcon": "assets/images/sidebarV2/tradeDiscovery.svg",
    "text": "Shipment Booking",
    "level": 1,
    "stateVal": "ShipmentBooking",
    "subItems": [
      {
        "name": "Quotes",
        "stateVal": "ShipmentBookingFinancierQuotes",
        "redirectURL": "/ShipmentBookingFinancierQuotes",
        "permissionVal": "ShipmentBookingFinancierQuotes",
        "notificationVal": "ShipmentBookingFinancierQuotes"
      },
      {
        "name": "Contract",
        "stateVal": "ShipmentBookingFinancierContract",
        "redirectURL": "/ShipmentBookingFinancierContract",
        "permissionVal": "ShipmentBookingFinancierContract",
        "notificationVal": "ShipmentBookingFinancierContract"
      }
    ],
    "collapseTarget": "ShipmentBooking",
    "notificationVal": "ShipmentBooking"
  },
  {
    "id": "2",
    "icon": "assets/images/settings.svg",
    "activeIcon": "assets/images/settings.svg",
    "text": "Settings",
    "level": 1,
    "stateVal": "Settings",
    "subItems": [
      {
        "name": "Notifications",
        "stateVal": "SettingsNotificationList",
        "redirectURL": "/NotificationList",
        "permissionVal": "SettingsNotificationList",
        "notificationVal": "SettingsNotificationList"
      },
      {
        "name": "Notification Settings",
        "stateVal": "SettingsNotification",
        "redirectURL": "/SettingsNotification",
        "permissionVal": "SettingsNotification",
        "notificationVal": "SettingsNotification"
      }
    ],
    "collapseTarget": "Settings",
    "notificationVal": "Settings"
  },
]

export const buyersNavConfig = [
  {
    "id": "4",
    "icon": "assets/images/sidebarV2/invoice_menu.svg",
    "activeIcon": "assets/images/sidebarV2/invoice_menu_active.svg",
    "text": "Export Factoring",
    "level": 1,
    "stateVal": "invoice",
    "subItems": [
      {
        "name": "Sign Agreement",
        "stateVal": "sign-invoice-agreement",
        "redirectURL": "/sign-invoice-agreement",
        "permissionVal": "sign-invoice-agreement",
        "notificationVal": ""
      }
    ],
    "collapseTarget": "collapseinv",
    "notificationVal": "invoicee discounting"
  }
]

export const FinanciersNavConfig = [
  {
    "id": "1",
    "icon": "assets/images/sidebarV2/dashboard.svg",
    "text": "Dashboard",
    "level": 0,
    "stateVal": "dashboard",
    "redirectURL": "/dashboard",
    "permissionVal": "dashboard",
    "notificationVal": "dashboard"
  },
  {
    "id": "2",
    "icon": "assets/images/sidebarV2/buyermanagement.svg",
    "text": "Supplier List",
    "level": 0,
    "stateVal": "supplierList",
    "redirectURL": "/supplierList",
    "permissionVal": "",
    "notificationVal": ""
  },
  {
    "id": "3",
    "icon": "assets/images/sidebarV2/lc_menu.svg",
    "text": "Letter of Credit",
    "level": 1,
    "stateVal": "Lc",
    "subItems": [
      {
        "name": "Limit Application",
        "stateVal": "LcDiscountingV2",
        "redirectURL": "/finLCQuotes",
        "permissionVal": "LcDiscountingV2"
      },
      {
        "name": "Finance Application",
        "stateVal": "LcFinApplication",
        "redirectURL": "/lcFinApplication",
        "permissionVal": "lcFinApplication"
      }
    ],
    "collapseTarget": "collapseLc",
    "notificationVal": "lc"
  },
  {
    "id": "4",
    "icon": "assets/images/sidebarV2/invoice_menu.svg",
    "activeIcon": "assets/images/sidebarV2/invoice_menu_active.svg",
    "text": "Export Factoring",
    "level": 1,
    "stateVal": "invoice",
    "subItems": [
      {
        "name": "Limit Application",
        "stateVal": "fininvoiceQuotes",
        "redirectURL": "/finInvoiceQuotes",
        "permissionVal": "finInvoiceQuotes",
        "notificationVal": ""
      },
      {
        "name": "Finance Application",
        "stateVal": "invoice_financeApplication",
        "redirectURL": "/financeApplication",
        "permissionVal": "financeApplication",
        "notificationVal": ""
      },
      {
        "name": "Disbursement",
        "stateVal": "invoice_disbursement",
        "redirectURL": "/disbursementV2",
        "permissionVal": "disbursementV2",
        "notificationVal": ""
      }
    ],
    "collapseTarget": "collapseinv",
    "notificationVal": "invoicee discounting"
  },
  {
    "id": "5",
    "icon": "assets/images/sidebarV2/other_financial.svg",
    "text": "Other Financial",
    "level": 2,
    "stateVal": "otherFin",
    "subItems": [
      {
        "name": "Working Capital",
        "stateVal": "otherFinworkingCapital",
        "collapseTarget": "collapseWC",
        "stages": [
          { "name": "Quote", redirectURL: "/wcFinancierQuote", stateVal: "otherFinworkingCapitalFinancierQuote" }
        ]
      },
      {
        "name": "CGTMSE",
        "stateVal": "otherFinCGTMSE",
        "collapseTarget": "collapseCGTMSE",
        "stages": [
          { "name": "Quote", redirectURL: "/cgtmseFinancierQuote", stateVal: "otherFinCGTMSEFinancierQuote" }
        ]
      },
      {
        "name": "Bank Gurantee",
        "stateVal": "bg",
        "collapseTarget": "collapseBG",
        "stages": [
          { "name": "Quote", redirectURL: "/bgFinancierQuote", stateVal: "bgFinancierQuote" },
          { "name": "Confirmed BG", redirectURL: "/bgConfirmedFinancier", stateVal: "bgConfirmedFinancier" }
        ]
      },
      {
        "name": "Supply Chain Finance",
        "stateVal": "otherFinSCF",
        "collapseTarget": "collapseSCF",
        "stages": [
          { "name": "Limit Application", redirectURL: "/scfFinancierQuote", stateVal: "otherFinSCFFinancierQuote" },
          { "name": "Approved Limit", redirectURL: "/approvedLimitSCF", stateVal: "otherFinSCFApprovedLimit" }
        ]
      },
      {
        "name": "Domestic Invoice Discounting",
        "stateVal": "otherFinDID",
        "collapseTarget": "collapseDID",
        "stages": [
          { "name": "Finance Application", redirectURL: "/", stateVal: "otherFinDIDApplyFin" },
          { "name": "Approved Finance", redirectURL: "/", stateVal: "otherFinDIDApprovedFin" }
        ]
      }
    ],
    "collapseTarget": "collapseOtherFin",
  },
  // {
  //   "id": "6",
  //   "icon": "assets/images/sidebarV2/tradeDiscovery.svg",
  //   "text": "Trade Discovery",
  //   "level": 0,
  //   "stateVal": "tradeDiscovery",
  //   "redirectURL": "/tradeDiscovery",
  //   "permissionVal": "tradeDiscovery",
  //   "notificationVal": "tradeDiscovery"
  // },
  {
    "id": "7",
    "icon": "assets/images/insurance.svg",
    "text": "Insuarance",
    "level": 1,
    "stateVal": "other_services",
    "permissionVal": "",
    "notificationVal": "other_services",
    "subItems": [
      {
        "name": "Trade Credit Insuarance",
        "stateVal": "other_services_credit_insurance",
        "redirectURL": "/CreditInsurance",
        "permissionVal": "",
        "notificationVal": ""
      }
    ],
    "collapseTarget": "collapseOtherIns",
  },
  {
    "id": "8",
    "icon": "assets/images/logistics.svg",
    "text": "Logistics",
    "level": 1,
    "stateVal": "logistics",
    "permissionVal": "",
    "notificationVal": "",
    "subItems": [],
    "collapseTarget": "collapseOtherlogistics",
  },
  {
    "id": "9",
    "icon": "assets/images/document_vault.svg",
    "text": "Document Vault",
    "level": 1,
    "stateVal": "document_vault",
    "permissionVal": "",
    "notificationVal": "",
    "subItems": [],
    "collapseTarget": "collapseOtherVault",

  },
  {
    "id": "10",
    "icon": "assets/images/Edocs.svg",
    "text": "E-Docs",
    "level": 1,
    "stateVal": "Edocs",
    "permissionVal": "",
    "notificationVal": "",
    "subItems": [],
    "collapseTarget": "collapseOtherEdocs",

  },
  {
    "id": "11",
    "icon": "assets/images/sidebarV2/chatroom2.svg",
    "text": "Chat Room",
    "level": 0,
    "stateVal": "ChatRoomV2",
    "redirectURL": "/ChatRoomV2",
    "permissionVal": "ChatRoomV2",
    "notificationVal": "chat room"
  },
  {
    "id": "12",
    "icon": "assets/images/sidebarV2/reports_icon.svg",
    "text": "Reports",
    "level": 1,
    "stateVal": "Reportv2",
    "localStorageKey": "report_name",
    "subItems": [
      {
        "name": "Payment",
        "stateVal": "Reportv2",
        "redirectURL": "/Reportv2",
        "permissionVal": "Reportv2",
        "notificationVal": ""
      },
      {
        "name": "Finance",
        "stateVal": "Reportv2",
        "redirectURL": "/Reportv2",
        "permissionVal": "Reportv2",
        "notificationVal": ""
      }
    ],
    "collapseTarget": "collapseReports",
    "notificationVal": "invoicee discounting"
  }
]

export const AdminConfig = [
  {
    "id": "1",
    "icon": "assets/images/sidebarV2/dashboard.svg",
    "text": "Dashboard",
    "level": 0,
    "stateVal": "dashboard",
    "redirectURL": "/dashboard",
    "permissionVal": "dashboard",
    "notificationVal": "dashboard"
  },
  {
    "id": "2",
    "icon": "assets/images/sidebarV2/workOrderContract.svg",
    "activeIcon": "assets/images/sidebarV2/workOrderContract.svg",
    "text": "Manage Commodities",
    "level": 1,
    "stateVal": "manageCommodities",
    "subItems": [
      {
        "name": "Add Commodity",
        "stateVal": "manageCommoditiesAdd",
        "redirectURL": "/manageCommodity?add=true",
        "permissionVal": "manageCommoditiesAdd",
        "notificationVal": "manageCommoditiesAdd"
      },
      {
        "name": "Commodities List",
        "stateVal": "manageCommoditiesList",
        "redirectURL": "/commoditiesList",
        "permissionVal": "manageCommoditiesList",
        "notificationVal": "manageCommoditiesList"
      }
    ],
    "collapseTarget": "manageCommodities",
    "notificationVal": "manageCommodities"
  }
]

export async function getMimeTypeOfFile(file) {
  const getMimeType = (signature) => {
    switch (signature) {
      case '89504E47':
        return 'image/png'
      case '47494638':
        return 'image/gif'
      case '25504446':
        return 'application/pdf'
      case 'FFD8FFDB':
      case 'FFD8FFE0':
      case 'FFD8FFE1':
        return 'image/jpeg'
      case '504B0304':
        return 'application/zip'
      default:
        return 'UNK'
    }
  }
  return await new Promise((resolve) => {
    let fileReader = new FileReader();
    fileReader.onloadend = function (evt) {
      if (evt.target.readyState === FileReader.DONE) {
        const uint = new Uint8Array(evt.target.result);
        let bytes = [];
        uint.forEach((byte) => {
          bytes.push(byte.toString(16))
        })
        const hex = bytes.join('').toUpperCase();
        const mimeType = getMimeType(hex);
        resolve(mimeType);
      }
    }
    fileReader.readAsArrayBuffer(file.slice(0, 4));
  });
}

export async function convertZipFileArrayToFilePdfObjects(zipFileArray) {
  try {
    const fileObjects = [];
    for (const [relativePath, zipEntry] of Object.entries(zipFileArray.files)) {
      const blob = await zipEntry.async("blob");
      // Create a File object from the extracted blob
      const extractedFile = new File([blob], zipEntry.name, { type: await getMimeTypeOfFile(blob) });
      fileObjects.push(extractedFile);
    }
    return fileObjects
  } catch (error) {
    console.log("errorinconvertZipFileArrayToFileObject", error);
    return []
  }
}

export function userPlanQuotaFetch() {
  return JSON.parse(localStorage.getItem("userPlanQuota") || "{}")
}


export const userTypeList = [
  {
    "id": 1,
    "name": "Super Admin"
  },
  {
    "id": 2,
    "name": "Admin (REMOVED)"
  },
  {
    "id": 3,
    "name": "Importer"
  },
  {
    "id": 4,
    "name": "Exporter"
  },
  {
    "id": 5,
    "name": "Ship Owner"
  },
  {
    "id": 6,
    "name": "Investigation Agency"
  },
  {
    "id": 7,
    "name": "Vessel Master"
  },
  {
    "id": 8,
    "name": "Bank"
  },
  {
    "id": 9,
    "name": "Employee"
  },
  {
    "id": 10,
    "name": "Broker"
  },
  {
    "id": 11,
    "name": "Stevedore"
  },
  {
    "id": 12,
    "name": "Custom Agent (REMOVED)"
  },
  {
    "id": 13,
    "name": "Insurance Agency"
  },
  {
    "id": 14,
    "name": "Trader"
  },
  {
    "id": 15,
    "name": "Producer (UNUSED)"
  },
  {
    "id": 16,
    "name": "Producer and Buyer (UNUSED)"
  },
  {
    "id": 17,
    "name": "Captain"
  },
  {
    "id": 18,
    "name": "Carrier Agent"
  },
  {
    "id": 19,
    "name": "Trade Finance"
  },
  {
    "id": 20,
    "name": "Channel Partner"
  }
]

export const userTypeList2 = [
  // {
  //   "id": 1,
  //   "name": "Super Admin"
  // },
  // {
  //   "id": 2,
  //   "name": "Admin (REMOVED)"
  // },
  {
    "id": 14,
    "name": "Trader"
  },
  // {
  //   "id": 4,
  //   "name": "Exporter"
  // },
  {
    "id": 5,
    "name": "Ship Owner"
  },
  // {
  //   "id": 6,
  //   "name": "Investigation Agency"
  // },
  // {
  //   "id": 7,
  //   "name": "Vessel Master"
  // },
  // {
  //   "id": 8,
  //   "name": "Bank"
  // },
  // {
  //   "id": 9,
  //   "name": "Employee"
  // },
  // {
  //   "id": 10,
  //   "name": "Broker"
  // },
  // {
  //   "id": 11,
  //   "name": "Stevedore"
  // },
  // {
  //   "id": 12,
  //   "name": "Custom Agent (REMOVED)"
  // },
  // {
  //   "id": 13,
  //   "name": "Insurance Agency"
  // },
  // {
  //   "id": 14,
  //   "name": "Trader"
  // },
  // {
  //   "id": 15,
  //   "name": "Producer (UNUSED)"
  // },
  // {
  //   "id": 16,
  //   "name": "Producer and Buyer (UNUSED)"
  // },
  // {
  //   "id": 17,
  //   "name": "Captain"
  // },
  // {
  //   "id": 18,
  //   "name": "Carrier Agent"
  // },
  // {
  //   "id": 19,
  //   "name": "Trade Finance"
  // },
  // {
  //   "id": 20,
  //   "name": "Channel Partner"
  // }
]

