import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import HeaderV2 from '../partial/headerV2'
import SideBarV2 from '../partial/sideBarV2'
import Filter from '../InvoiceDiscounting/components/Filter'
import { ExpandableTable } from '../wallet/components/ExpandableTable'
import Pagination from '../InvoiceDiscounting/contract/components/pagination'
import toastDisplay from '../../utils/toastNotification'
import moment from 'moment'
import call from '../../service'
import swal from 'sweetalert'
import NewTablev2 from '../../utils/newTablev2'
import { applyFinTblDID } from '../Dashboard/TableJson'
import { roundOfNumberInDecimal } from '../../utils/myFunctions'

const dummydata = [
  {
    buyerId: 11660,
    scfApplicationCreatedAt: new Date().toISOString(),
    buyerName: 'walmart',
    limitAvailable: 20000,
    limitAvailableCurrency: "USD",
    selectedLenderIds: '5184',
    selectedLenderNames: 'State Bank',
    applicationStatus: "",
    isAppliedForFinance: true,
    isTermSheetSigned: false,
    isQuoteRecieved: false,
    invoiceAmount: 0,
    invRefNo: 'INV-WALL-231213212123'
  },
  {
    buyerId: 1091,
    scfApplicationCreatedAt: new Date().toISOString(),
    buyerName: 'Reliance',
    limitAvailable: 6000,
    limitAvailableCurrency: "USD",
    selectedLenderIds: '51',
    selectedLenderNames: 'STENN',
    applicationStatus: "",
    isAppliedForFinance: false,
    isTermSheetSigned: true,
    isQuoteRecieved: false,
    invoiceAmount: 0,
    invRefNo: 'INV-Reliance-75343223'
  }
]

const ApplyFinanceDID = ({ userTokenDetails, navToggleState }) => {
  const [filteredSearch, setFilteredSearch] = useState({})
  const [filter, setFilter] = useState({
    resultPerPage: 10
  })
  const [refresh, setRefresh] = useState(0)
  const [filterData, setFilterData] = useState({})
  const [count, setCount] = useState(1)
  const [page, setPage] = useState(1)
  const [dbdata, setDbdata] = useState([])
  const [showLoader, setshowLoader] = useState(false)
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;
  const userPermissionsForSubAdmin = JSON.parse(userTokenDetails.UserAccessPermission || "{}")
  let onlyShowForUserId = userPermissionsForSubAdmin?.mainAdmin ? undefined : userId
  useEffect(() => {
    setshowLoader(true)
    //Get Buyer list start
    let objectAPI = {
      "userId": userId,
      "userEmail": userEmail,
      "userTypeId": userTypeId,
      "type": 'all',
      currentPage: page,
      onlyReadyForInvoiceFinance: true,
      ...filter
    }
    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push((element.accordianId === "invoiceNo" || element.accordianId === "financiersFilter" || element.accordianId === "timeLeft") ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
        else if (element.type === "minMax") {
          objectAPI[element.accordianId] = element["value"]
        }
        else if (element.type === "minMaxDate") {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }
    call('POST', 'getDomesticInvoiceDiscountingList', objectAPI).then((result) => {
      console.log('running getBuyersDetail api-->', result);
      setDbdata(result.buyerData);
      //setTableData(formatDataForTable(result.buyerData))
      setCount(result.countData);
      setshowLoader(false)
    }).catch((e) => {
      // console.log('error in getBuyersDetail', e);
      setshowLoader(false)
    });
    //Get buyer list end
  }, [refresh, page, filterData])
  function formatDataForTable(data) {
    let tableData = []
    let row = []
    for (let index = 0; index < data.length; index++) {
      const item = data[index];


      let allDeniedCount = 0
      let allApprovedCount = 0
      let buyersCredit = []

      if (item.buyers_credit) {
        try {
          buyersCredit = JSON.parse(item.buyers_credit)
        } catch (error) {
          console.log("errinnnnnnnnnnnnn", item);
        }
        for (let index = 0; index < buyersCredit.length; index++) {
          const j = buyersCredit[index];
          if (j.financierAction === "deny") {
            allDeniedCount += 1
          }
          else {
            allApprovedCount += 1
          }
        }
      }


      row[0] = <ul className='py-0 pl-3 cursor' >
        {item?.suppliersdata?.map(item => {
          return <li >
            <div className='font-size-12'>
              {item.name}
            </div>
          </li>
        })}
      </ul>
      row[1] = item.limitAvailableCurrency + " " + item.limitAvailable

      let selectedLenderName = item.selectedLenderNames ? item.selectedLenderNames.split(",") : []
      let selectedLenderId = item.selectedLenderIds ? item.selectedLenderIds.split(",") : []


      let unsendFinList = []

      // for (let index = 0; index < financiers.length; index++) {
      //   const element = financiers[index];
      //   let isAlreadySent = false
      //   for (let j = 0; j < selectedLenderId.length; j++) {
      //     if (element.id / 1 == selectedLenderId[j] / 1) {
      //       isAlreadySent = true
      //     }
      //   }
      //   if (!isAlreadySent) {
      //     unsendFinList.push(element)
      //   }
      // }
      let chatRoomIds = item.chatRoomIds?.split(",") || []
      let chatRoomUsers = item.chatRoomUsers?.split(",") || []
      let chatRoomUnreadMsgCount = item.chatRoomUnreadMsgCount?.split(",") || []
      row[2] = <div
        className=''
      >
        {selectedLenderName.length ? selectedLenderName.map((key, j) => {
          let isApprovedByFinancier = buyersCredit?.filter(i => {
            if ((i.lender_id / 1 == selectedLenderId[j] / 1) && i.financierAction === "Approved") {
              return i
            }
          })?.[0]
          let isRejectedByFinancier = buyersCredit?.filter(i => {
            if ((i.lender_id / 1 == selectedLenderId[j] / 1) && i.financierAction === "deny") {
              return i
            }
          })?.[0]
          let isOnlyRemarkProvidedByFinancier = false
          if (!isApprovedByFinancier && !isRejectedByFinancier) {
            isOnlyRemarkProvidedByFinancier = item.buyersRemark?.[selectedLenderId[j] / 1]?.["isVisible"] ? true : false
          }
          let openChatRoomIndx = null
          chatRoomUsers.forEach((u, i) => {
            if (u?.split("::")[1] / 1 == selectedLenderId[j]) {
              openChatRoomIndx = i
            }
          })

          return (
            <div
              className={`position-relative cursor ${isOnlyRemarkProvidedByFinancier ? ' textOrange ' : '   '}
              ${isApprovedByFinancier ? " text2ECC71 " : ''} ${isRejectedByFinancier ? ' text-danger ' : '   '}`} >
              {/* <label className='font-wt-600 font-size-22 position-absolute cursor' style={{ top: "-0.8rem" }} >{`.`}</label> */}
              <img className='cursor'
                onClick={async () => {
                  let reqObj = {
                    userTypeId,
                    senderId: userId,
                    sellerId: item.created_by,
                    receiverId: selectedLenderId[j],
                    textMessage: 'Hii',
                    chat_room_name: "CHAT" + new Date().getTime(),
                    includeAdmins: true,
                    invApplicationId: item.applicationId,
                    receiverParties: selectedLenderId[j],
                    dontSendInitialMsg: true
                  }
                  setshowLoader(true)
                  let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
                  setshowLoader(false)
                  // getChatDetails({
                  //   chat_room_id: apiResp.id,
                  //   loggedInUser: userId
                  // })
                  // setSelectedChat({
                  //   chatRoomId: apiResp.id,
                  //   receiverName: `${key} - ${chatRoomIds[openChatRoomIndx] || apiResp.id}`,
                  //   invApplicationId: item.applicationId,
                  //   parties: chatRoomUsers[openChatRoomIndx] || apiResp.parties,
                  //   userId: userId,
                  //   isChatOpen: true,
                  //   receiverId: selectedLenderId[j],
                  //   sellerId: item.created_by
                  // })
                }}
                src={chatRoomIds[openChatRoomIndx] ? `assets/images/comment_filled.png` : `assets/images/chat.png`} />
              <label
                onClick={() => { }} className='ml-3 font-size-14  font-wt-600 cursor' >{`${key}`}
                <span className='text-color1 mx-2' ><u>{chatRoomUnreadMsgCount[openChatRoomIndx] / 1 ? (chatRoomUnreadMsgCount[openChatRoomIndx] < 10 ? `0${chatRoomUnreadMsgCount[openChatRoomIndx]}` : chatRoomUnreadMsgCount[openChatRoomIndx]) : null}</u></span></label>
            </div>
          )
        }) : "NA"}

      </div>

      row[3] = <div>
        <button
          onClick={() => { window.location = `/LcSeequotes?id=${item.id}` }}
          type="button"
          class={` border-0 mb-2 enablesigncontract text-white w-100`} >
          {"Forward"}
        </button>
        <p className="font-size-12 font-wt-400 text-dark ml-3">
          <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" mr-2"><b>
            Limit available, send notification to supplier to apply for finance</b> </span>
        </p>
      </div>

      tableData.push(row)
      row = []
    }
    return tableData
  }
  return (
    <div className='container-fluid'>
      <div className="row">
        <SideBarV2 state="otherFinDIDApplyFin" userTokenDetails={userTokenDetails} />
        <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState?.status ? " expanded-right" : "")} id="app-main-div">
          <HeaderV2
            title={"Domestic Invoice Discounting  >   Apply for Finance"}
            userTokenDetails={userTokenDetails} />

          <div className='filter-div'>
            <Filter
              filteredSearch={filteredSearch}
              setFilteredSearch={setFilteredSearch}
              showDownloadIcon={true} onDownloadClick={() => { }}
              filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
              showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} />
          </div>
          <div className="mb-3">
            <NewTablev2 columns={applyFinTblDID} showSideBorders={false}>
              {dbdata.map((item, index) => {

                let selectedLenderName = item.selectedQuote.lender_name
                let selectedLenderId = item.selectedQuote.lender_id
                let chatRoomIds = item.chatRoomIds?.split(",") || []
                let chatRoomUsers = item.chatRoomUsers?.split(",") || []
                let chatRoomUnreadMsgCount = item.chatRoomUnreadMsgCount?.split(",") || []
                let openChatRoomIndx = null

                chatRoomUsers.forEach((u, i) => {
                  if (u?.split("::")[1] / 1 == selectedLenderId / 1) {
                    openChatRoomIndx = i
                  }
                })
                return <tr>
                  <td>
                    {item.buyerName}
                  </td>
                  <td>
                    {item.invRefNo ? item.invRefNo : '-'}
                  </td>
                  <td>
                    {item.invoiceAmount ? "$ " + item.invoiceAmount : '-'}
                  </td>
                  <td>
                    {roundOfNumberInDecimal(item.selectedQuote.financeLimit) + " " + item.selectedQuote.financeLimitCurrency}
                  </td>
                  <td>
                    <div
                      className='flex-row position-relative'
                    >
                      <img className='cursor'
                        onClick={async () => {
                          let reqObj = {
                            senderId: userId,
                            receiverId: selectedLenderId,
                            textMessage: 'Hii',
                            chat_room_name: "CHAT" + new Date().getTime(),
                            includeAdmins: true,
                            invApplicationId: item.applicationId,
                            receiverParties: selectedLenderId,
                            dontSendInitialMsg: true
                          }
                          setshowLoader(true)
                          let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
                          setshowLoader(false)
                          // getChatDetails({
                          //   chat_room_id: apiResp.id,
                          //   loggedInUser: userId
                          // })
                          // setSelectedChat({
                          //   chatRoomId: apiResp.id,
                          //   receiverName: `${selectedLenderName} - ${chatRoomIds[openChatRoomIndx] || apiResp.id}`,
                          //   invApplicationId: item.applicationId,
                          //   parties: chatRoomUsers[openChatRoomIndx] || apiResp.parties,
                          //   userId: userId,
                          //   isChatOpen: true,
                          //   receiverId: selectedLenderId
                          // })
                        }}
                        src={chatRoomIds[openChatRoomIndx] ? `assets/images/comment_filled.png` : `assets/images/chat.png`} />
                      <label className='p-1 mx-1 font-size-14 font-wt-600' >{selectedLenderName}
                        <span className='text-color1 mx-2' ><u>{chatRoomUnreadMsgCount[openChatRoomIndx] / 1 ? (chatRoomUnreadMsgCount[openChatRoomIndx] < 10 ? `0${chatRoomUnreadMsgCount[openChatRoomIndx]}` : chatRoomUnreadMsgCount[openChatRoomIndx]) : null}</u></span></label>
                    </div>
                  </td>
                  <td>
                    <button type="button"
                      onClick={() => { }}
                      class={`approved text-white border-0 `}>
                      {"Approved"}
                    </button>
                  </td>
                  <td>
                    <div className='' >
                      <button type="button"
                        disabled={item.invRefNo}
                        onClick={() => {
                          window.location = `/apply-invoice-multiple?buyer=${item.id}`
                          localStorage.setItem("selectedQuote", JSON.stringify(item.selectedQuote))
                          localStorage.setItem("termSheet", JSON.stringify(item.termSheet))
                          localStorage.setItem("applicationId", item.applicationId)
                          localStorage.setItem("limitPendingFrom", item.limitPendingFrom)
                          localStorage.setItem("documentStatus", item.documentStatus)
                        }}
                        class={` border-0 mb-2 ${item.invRefNo ? "disablesigncontract text-dark" : " enablesigncontract text-white  "}`}>
                        {item.invRefNo ? "Applied for Finance" : "Apply for Finance"}
                      </button>
                      {item.invRefNo ?
                        <p className="font-size-12 text-color-value ml-3">
                          <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-500">Waiting for financier to send agreement</span>
                        </p>
                        : null}
                    </div >
                  </td>
                </tr>
              })}
            </NewTablev2>

          </div>
          <Pagination page={page} totalCount={count} onPageChange={(p) => setPage(p)} perPage={filter.resultPerPage || 10} />
        </main>
      </div>
    </div>
  )
}
const mapStateToProps = state => {
  return {
    clientType: state.clientType,
    navToggleState: state.navToggleState,
    notifications: state.IncomingNotifications.notification_data
  }
}

export default connect(mapStateToProps)(ApplyFinanceDID)
