import React, { useEffect, useState } from 'react';
import FindBuyers from './FindBuyers';
import SideBarV2 from '../partial/sideBarV2';
import HeaderV2 from '../partial/headerV2';
import { connect } from 'react-redux';


const TradeDiscovery = ({ userTokenDetails, navToggleState }) => {

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;
  const ttvExporterCode = userTokenDetails.ttvExporterCode ? userTokenDetails.ttvExporterCode : ''
  const subUserName = userTokenDetails?.sub_user_name
  const mainUserName = userTokenDetails?.main_user_name


  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="tradeDiscovery" userTokenDetails={userTokenDetails} />
          <main role="main" className={"container-bg ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={`Trade Discovery`}
              userTokenDetails={userTokenDetails} />
            <FindBuyers userTokenDetails={userTokenDetails} />
          </main>
        </div>
      </div >
    </>
  )

}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(TradeDiscovery)
