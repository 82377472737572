import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import HeaderV2 from "../../../partial/headerV2";
import SideBarV2 from "../../../partial/sideBarV2";
import toastDisplay from "../../../../utils/toastNotification";
import { astrix, ClearCache, convertImageToPdf, GetCache, SetCache, toolTip, getDocDetails, most_used_currencies, handleFileCommonFunction } from "../../../../utils/myFunctions";
import call from '../../../../service';
import { FileInput } from "../../../../utils/FileInput";
import { ToastContainer } from "react-toastify";
import { InputForTable, InputWithSelect, InputWithSelectForTable, NewInput, NewSelect, NewTextArea, SelectForTable } from '../../../../utils/newInput';
import { PopupMessage } from '../../../popupMessage';
import { CustomProgressBar } from "../../../myCounterPartComp/checkBuyerHealth";
import moment from "moment";
import { AddMoreDocumentHandler } from "../../../addMoreDocumentHandler";
import { NewTable } from "../../../../utils/newTable";
import NewTablev2 from "../../../../utils/newTablev2";
import { yesnoqsts } from "../../extraDetailsForLimitApplication";
import { ExpandableTable } from "../../../wallet/components/ExpandableTable";
import ChatBoxPopUp2 from "../../../chatRoom/components/ChatBoxPopUp2";
import avatarUrl from "../../../../utils/makeAvatarUrl";
import { useHistory } from "react-router";
import { DocumentViewer } from "../../../../utils/documentViewer";
import { DocumentDownloader } from "../../../../utils/DocumentDownloader";
import SignDocument from "../../components/SignDocument";
import { Action } from "../../../myCounterPartComp/action";
import ViewBuyerDetails from "../../../myCounterPartComp/viewBuyerDetails";

const quotationTabs = [
  { name: "Supplier Details" },
  { name: "Buyer Details" },
  { name: "Application form" },
]

const otherDetails = [
  { "name": "No. of finance applied: ", val: "totalInvApplied", unit: "" },
  { "name": "No. of finance approved: ", val: "totalInvApproved", unit: "" },
  { "name": "Total shipment done: ", val: "totalShipmentsDone", unit: "" }
]

const currencies = [
  { id: 47, name: "Rupees", code: "INR", symbol: "₹" },
  { id: 2, name: "Dollars", code: "USD", symbol: "$" },
  { id: 11, name: "Euro", code: "EUR", symbol: "€" },
  { id: 19, name: "Pounds", code: "GBP", symbol: "£" },
  { code: "%" }
]

const infoType = [
  { "name": "$ Currency", "unit": "currency" },
  { "name": "% Percentage", "unit": "percentage" },
  { "name": "Text", "unit": "text" },
  { "name": "Date", "unit": "date" }
]

const ViewDetails = ({ userTokenDetails, navToggleState }) => {

  let quotesDetailTemp = localStorage.getItem("quotesDetail");
  // quotesDetail = quotesDetailTemp ? JSON.parse(quotesDetailTemp) : 'NA';

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  let [quotesDetail, setQuotesDetail] = useState(JSON.parse(quotesDetailTemp))
  const [tab, setTab] = useState(2)
  const [data, setData] = useState({
    onlyRemark: quotesDetail?.buyersRemark?.[userId]?.["remark"],
    ocUnit0: 'USD', financeLimitUnit0: "USD", financeLimitUnit1: "USD", financeLimitUnit2: "USD", financeLimitUnit3: "USD"
    , financeLimitUnit4: "USD", financeLimitUnit5: "USD"
  })
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)
  const [quoteMsg, quoteMsgPopup] = useState(false);
  const [userData, setUserData] = useState({})
  const [remark, setRemark] = useState("");
  const [userFinData, setUserFinData] = useState([])
  const [isSubmitting, setIsSubmitting] = useState({ modal: false, type: '' });
  const [moreCharges, setMoreCharges] = useState(false);
  const [moreOption, toggleMoreOption] = useState(false)
  const [popupMsg, setPopupMessage] = useState({ show: false })

  const [quoteForm, setQuoteForm] = useState({ modal: false, page: 0 });
  const [denyForm, setDenyForm] = useState({ modal: false, page: 0 });

  const [lcRequestMoreDetails, setLCRequestMoreDetails] = useState({ modal: false, page: 0 });
  const [moreDocs, setMoreDocs] = useState([])
  const [addMoreDetails, setAddMoreDetails] = useState(false);
  const [lcMoreDetails, setLCMoreDetails] = useState(false);
  const [ammendFields, setAmmendFields] = useState({})
  const [ammendPopup, toggleAmmendPopup] = useState({ show: false, key: null })
  const [showComment, toggleComment] = useState(null)
  const [lcMoreDocuments, setLCMoreDocuments] = useState({ modal: false, page: 0 });
  const [additionalReqDetails, setAdditionalReqDetails] = useState([])
  const [tableDocsData, setTableDocsData] = useState([])
  const [multInvPo, setMultInvPo] = useState([])

  const [gstReturns, setGstReturns] = useState([])

  const [bankDocs1, setBankDocs1] = useState([])
  const [bankDocs2, setBankDocs2] = useState([])
  const [bankDocs3, setBankDocs3] = useState([])

  const [financialDoc1, setFinancialDoc1] = useState([])
  const [financialDoc2, setFinancialDoc2] = useState([])
  const [financialDoc3, setFinancialDoc3] = useState([])
  const [financialDoc4, setFinancialDoc4] = useState([])

  const [orgDoc1, setOrgDoc1] = useState([])
  const [orgDoc2, setOrgDoc2] = useState([])
  const [orgDoc3, setOrgDoc3] = useState([])

  const [applicationType, setApplicationType] = useState(0)
  const [allApplicationsWithSupplier, setAllApplicationsWithSupplier] = useState([])
  const [allApplicationTableData, setAllApplicationTableData] = useState([])

  const [openApplication, setOpenApplication] = useState("")
  const [expandData, setExpandData] = useState([{}])

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [finActionTab, setFinancierActionTab] = useState(0)
  const [InsurerList, setInsurerList] = useState([])
  const [quoteScreen, toggleQuoteScreen] = useState({ show: false })
  const [otherCharges, setOtherCharges] = useState([null])

  const [viewDoc, toggleViewDoc] = useState({ show: false, doc: {} })
  const [dowdDoc, toggleDowdDoc] = useState({ show: false, doc: {} })
  const [signdoc, setSigndoc] = useState(false);
  const [action, setAction] = useState({ show: false, index: null })
  const [accordion, setAccordion] = useState(1);
  const [viewBuyerDetails, setViewBuyerDetails] = useState({ show: false, data: {} })

  const [SubUsers, setSubUsers] = useState([])

  let history = useHistory()

  const ProgressBars = [
    { name: "Portpolio comparision score", min: 1, max: 9 },
    { name: "Failure score", min: 1, max: 100, key: "failure_score" },
    { name: "Delinqency score", min: 1, max: 100, key: "delinquency_score" },
    { name: "Paydex score", min: 1, max: 100, key: "paydex_scrore", reverse: true }
  ]

  const generalDetails = [
    { "name": "Name: ", val: quotesDetail.supplierName, unit: quotesDetail.supplierName },
    { "name": "Organization Type: ", val: quotesDetail.organization_type, unit: quotesDetail.organization_type },
    { "name": "Country: ", val: quotesDetail.country_code, unit: quotesDetail.country_code },
    { "name": "Industry Type: ", val: quotesDetail.industry_type, unit: quotesDetail.industry_type }
  ]

  const supplierForm = [
    { "name": "Financial Year: ", val: "year", unit: "" },
    { "name": "Turn Over: ", val: "turnover", unit: "finCurrency" },
    { "name": "Net Profit: ", val: "netprofit", unit: "finCurrency" },
    { "name": "Net Worth: ", val: "networth", unit: "finCurrency" }
  ]

  let supplierDetails = [
    { name: 'Supplier Name', val: "company_name" },
    {
      name: 'Active Application', val: 'activeApplication', valStyle: {
        color: "#4CA0B9",
        fontWeight: 600,
        textDecoration: "underline",
        cursor: 'pointer'
      }, onValClick: (arg) => {
        localStorage.setItem("finInvoiceQuotesSearch", arg)
        window.open(`/finInvoiceQuotes`, "_blank");
      }
    },
    { name: 'Contact Number', val: 'contact_number', unit: 'phone_code' },
    { name: 'Approved Application', val: 'totalApproved' },
    { name: 'Email Id', val: 'email_id' },
    { name: 'Rejected Application', val: 'totalRejected' },
    { name: 'Website', val: 'website' },
    { name: 'Pending Application', val: 'totalPending' },
    { name: 'Address', val: 'user_address' },
    { name: 'Industry Type', val: 'industry_type' },
    { name: 'Date of Incorportation', val: 'dateOfIncorporation' },
    { name: 'Exporter Type', val: 'categoryOfExporters' },
    { name: 'Export Turn Over (USD)', val: 'fobUsd' }
  ]

  const reviewForm3 = [
    { "name": "Buyer Name", val: "buyerName" },
    { "name": "Contact Number", val: "buyerPhone", unit: 'buyerPhoneCode' },
    { "name": "Email Id", val: 'buyerEmail' },
    { "name": "Website", val: "buyerWebsite" },
    { "name": "Address", val: "buyerAddress" },
    { "name": "Country", val: "buyerCountry" },
    { "name": "Duns No.", val: "buyerDUNSNo" },
    {
      "name": "Previous year annual sale", val: "previousAnnualSale", unit: 'buyerCurrency', convUnit: (data) => {
        return data.split(":")[1]
      }
    },
    {
      "name": "Expected current year's annual sale", val: "currentAnnualSale", unit: 'buyerCurrency', convUnit: (data) => {
        return data.split(":")[1]
      }
    },
    { "name": "Inco Terms", val: "incoTerms" },
    { "name": "Terms of Payment", val: "termsOfPayment" },
    { "name": "Product details", val: "productDetails" },
    { "name": "HSN Code", val: "buyerHsnCode", notAmmendable: true }
  ]

  async function toggleViewBuyerDetails(buyerData) {
    setViewBuyerDetails({ show: true, data: buyerData })
  }


  async function asyncUseEffectFunction() {
    let tempData = data
    setshowLoader(true)

    // Get additional requested documents start
    let tempFiles = {}
    let additionalReqResp = await call('POST', 'isAdditionalDetailsReqByFinancer', {
      lenderIdArr: [{ lender_id: userId }],
      applicationId: quotesDetail.limit_id
    })
    console.log("additionalReqResp============================", additionalReqResp?.[0]);
    if (additionalReqResp?.[0]?.additionalDocuments?.[0]) {
      for (let index = 0; index < additionalReqResp?.[0]?.additionalDocuments.length; index++) {
        const element = additionalReqResp?.[0]?.additionalDocuments[index];
        if (element.doc_id) {
          tempFiles[element.doc_name] = await getDocDetails(element.doc_id)
        }
      }
    }
    setAmmendFields(additionalReqResp?.[0]?.commentsOnInformation || {})
    // Get additional requested documents end

    let tempLimitDocsDetails = {}
    let limitDocs = await call('POST', 'getInvLimitApplicationDocs', { userId, quoteId: quotesDetail.limit_id })
    let totalInvoiceDetails = limitDocs.maxIndx
    let maxInvPoDocs = limitDocs.maxSubIndx

    for (let index = 0; index < limitDocs.data.length; index++) {
      const element = limitDocs.data[index];
      tempLimitDocsDetails[element.label] = await getDocDetails(element.docId)
      if (element.attributes) {
        tempLimitDocsDetails = { ...tempLimitDocsDetails, ...element.attributes }
      }
    }

    setshowLoader(false)
    setMultInvPo([...new Array(totalInvoiceDetails).fill({ invoices: new Array(maxInvPoDocs).fill(null), purchase_orders: new Array(maxInvPoDocs).fill(null) })])
    tempData = {
      ...tempData, ...tempFiles,
      additionalDocuments: additionalReqResp?.[0]?.additionalDocuments, additionalDetails: additionalReqResp?.[0]?.additionalDetails,
      ...tempLimitDocsDetails
    }
    setshowLoader(true)
    call('POST', 'getGeneralDetailsOfExporter', {
      "userId": quotesDetail.created_by,
      "invLenderId": userId
    }).then(async (result) => {
      setshowLoader(false)
      let apiResp = await call('POST', 'getUserDetailsFromIEC', { iec: result.iec_no })
      setUserData({ ...result, ...apiResp, dateOfIncorporation: apiResp.dateOfIncorporation ? moment(apiResp.dateOfIncorporation).format("YYYY-MM-DD") : null })
    }).catch((e) => {
      setshowLoader(false)
    });

    call('POST', 'getuserfinancegrid', {
      "currentPage": 1,
      "manualUserId": quotesDetail.userId,
      "resultPerPage": 10,
      userTypeId
    }).then((result) => {
      setshowLoader(false)
      setUserFinData(result.finData)
    }).catch((e) => {
      setshowLoader(false)
    });

    let buyers_credit = quotesDetail.buyers_credit ? JSON.parse(quotesDetail.buyers_credit) : null;
    let fetchGeneralQuoteInformation = true
    if (buyers_credit && buyers_credit.length > 0) {
      for (let i = 0; i < buyers_credit.length; i++) {
        if (buyers_credit[i].lender_id == userId) {
          fetchGeneralQuoteInformation = false
          tempData = {
            ...tempData,
            termSofr: buyers_credit[i].termSofr,
            factoringFees: buyers_credit[i].factoringFees,
            factoringFeesUnit: buyers_credit[i].factoringFeesCurrency,
            interestRate: buyers_credit[i].interestRate,
            setupFees: buyers_credit[i].setupFees,
            setupFeesUnit: buyers_credit[i].setupFeesCurrency,
            offerValidity: buyers_credit[i].offerValidity,
            financeLimit: buyers_credit[i].financeLimit,
            financeLimitUnit: buyers_credit[i].financeLimitCurrency,
            discountingPercentage: buyers_credit[i].discountingPercentage,
            otherCharges: buyers_credit[i].otherCharges,
            assignRemark: buyers_credit[i].assignRemark,
            ...tempFiles,
            additionalDocuments: additionalReqResp?.[0]?.additionalDocuments,
            additionalDetails: additionalReqResp?.[0]?.additionalDetails
          }
        }
      }
    }

    if (fetchGeneralQuoteInformation) {
      setshowLoader(true)
      let getQuoteInfo = await call("POST", "getLenderQuoteInformation", { userId })
      setshowLoader(false)
      if (getQuoteInfo?.[0]?.rate) {
        getQuoteInfo = getQuoteInfo[0].rate
        tempData = {
          ...tempData,
          assignRemark: '',
          termSofr: getQuoteInfo.termSofr || null,
          factoringFees: getQuoteInfo.factoringFees,
          factoringFeesUnit: getQuoteInfo.factoringFeesCurrency || "USD",
          interestRate: getQuoteInfo.interestRate,
          setupFees: getQuoteInfo.setupFees,
          setupFeesUnit: getQuoteInfo.setupFeesCurrency || "USD",
          financeLimit: "",
          financeLimitUnit: getQuoteInfo.financeLimitCurrency || 'USD',
          discountingPercentage: getQuoteInfo.discountingPercentage,
          otherCharges: getQuoteInfo.otherCharges,
          offerValidity: getQuoteInfo.offerValidTillInDays ? moment().add(getQuoteInfo.offerValidTillInDays / 1, "days").format("YYYY-MM-DD") : null,
          ...tempFiles,
          additionalDocuments: additionalReqResp?.[0]?.additionalDocuments,
          additionalDetails: additionalReqResp?.[0]?.additionalDetails
        }
      }
    }

    setshowLoader(true)
    let apiResp = await call("POST", 'getUserExtraDocs', { userId: quotesDetail.created_by, buyerId: quotesDetail.buyerId })
    if (apiResp?.yesNoQsts) {
      tempData = { ...tempData, ...apiResp?.yesNoQsts }
    }
    if (apiResp?.gst) {
      let gstArr = []
      for (let index = 0; index < apiResp.gst?.doc.length; index++) {
        const docId = apiResp.gst?.doc[index];
        tempData["gstDoc:" + index] = await getDocDetails(docId)
        tempData["gstDoc:" + index + "Year"] = apiResp.gst.year[index]
        gstArr.push(null)
      }
      if (gstArr.length) {
        setGstReturns(gstArr)
      }
    }
    if (apiResp?.bank) {
      let bankDoc1Arr = []
      let bankDoc2Arr = []
      let bankDoc3Arr = []
      for (let index = 0; index < apiResp.bank?.doc1.length; index++) {
        const docId = apiResp.bank?.doc1[index];
        tempData["bankDoc1:" + index] = await getDocDetails(docId)
        bankDoc1Arr.push(null)
      }
      for (let index = 0; index < apiResp.bank?.doc2.length; index++) {
        const docId = apiResp.bank?.doc2[index];
        tempData["bankDoc2:" + index] = await getDocDetails(docId)
        bankDoc2Arr.push(null)
      }
      for (let index = 0; index < apiResp.bank?.doc3.length; index++) {
        const docId = apiResp.bank?.doc3[index];
        tempData["bankDoc3:" + index] = await getDocDetails(docId)
        tempData["bankDoc3:" + index + "Name"] = tempData["bankDoc3:" + index]?.doc_name
        bankDoc3Arr.push(null)
      }
      if (bankDoc1Arr.length) { setBankDocs1(bankDoc1Arr) }
      if (bankDoc2Arr.length) { setBankDocs2(bankDoc2Arr) }
      if (bankDoc3Arr.length) { setBankDocs3(bankDoc3Arr) }
    }
    if (apiResp?.financial) {
      let doc1Arr = []
      let doc2Arr = []
      let doc3Arr = []
      let doc4Arr = []
      for (let index = 0; index < apiResp.financial.doc1.length; index++) {
        const docId = apiResp.financial.doc1[index];
        tempData["finDoc1:" + index] = await getDocDetails(docId)
        tempData["finDoc1:" + index + "Year"] = apiResp.financial?.doc1Year?.[index]
        doc1Arr.push(null)
      }
      for (let index = 0; index < apiResp.financial.doc2.length; index++) {
        const docId = apiResp.financial.doc2[index];
        tempData["finDoc2:" + index] = await getDocDetails(docId)
        doc2Arr.push(null)
      }
      for (let index = 0; index < apiResp.financial.doc3.length; index++) {
        const docId = apiResp.financial.doc3[index];
        tempData["finDoc3:" + index] = await getDocDetails(docId)
        doc3Arr.push(null)
      }
      for (let index = 0; index < apiResp.financial.doc4.length; index++) {
        const docId = apiResp.financial.doc4[index];
        tempData["finDoc4:" + index] = await getDocDetails(docId)
        tempData["finDoc4:" + index + "Name"] = tempData["finDoc4:" + index]?.doc_name
        doc4Arr.push(null)
      }
      if (doc1Arr.length) { setFinancialDoc1(doc1Arr) }
      if (doc2Arr.length) { setFinancialDoc2(doc2Arr) }
      if (doc3Arr.length) { setFinancialDoc3(doc3Arr) }
      if (doc4Arr.length) { setFinancialDoc4(doc4Arr) }
    }
    if (apiResp?.organizational) {
      let doc1Arr = []
      let doc2Arr = []
      let doc3Arr = []
      for (let index = 0; index < apiResp.organizational.doc1.length; index++) {
        const docId = apiResp.organizational.doc1[index];
        tempData["orgDoc1:" + index] = await getDocDetails(docId)
        doc1Arr.push(null)
      }
      for (let index = 0; index < apiResp.organizational.partnerName.length; index++) {
        const partnerName = apiResp.organizational.partnerName[index];
        tempData["aadharDoc:" + index] = await getDocDetails(apiResp.organizational.aadharDoc[index])
        tempData["panDoc:" + index] = await getDocDetails(apiResp.organizational.panDoc[index])
        tempData["partnerName:" + index] = partnerName
        tempData[`aadharDoc:${index}DocNo`] = apiResp.organizational?.aadharId?.[index] || ""
        tempData[`panDoc:${index}DocNo`] = apiResp.organizational?.panId?.[index] || ""
        doc2Arr.push(null)
      }
      for (let index = 0; index < apiResp.organizational.doc3.length; index++) {
        const docId = apiResp.organizational.doc3[index];
        tempData["orgDoc3:" + index] = await getDocDetails(docId)
        tempData["orgDoc3:" + index + "Name"] = tempData["orgDoc3:" + index]?.doc_name
        doc3Arr.push(null)
      }
      if (doc1Arr.length) { setOrgDoc1(doc1Arr) }
      if (doc2Arr.length) { setOrgDoc2(doc2Arr) }
      if (doc3Arr.length) { setOrgDoc3(doc3Arr) }
    }
    setshowLoader(false)
    // Setting data at last
    setData(tempData)
    // onCheckBuyerHealthClick()    
  }

  async function getAllApplicationsWithSeller() {
    setshowLoader(true)
    let resp = await call('POST', 'getInvoiceLimit', {
      userId, userEmail, "userTypeId": 8,
      "currentPage": 1, "resultPerPage": applicationType / 1 == 0 ? 1 : 1000, mergedApplicationId: quotesDetail.merged_application_id,
      singleApplication: true
    })
    setshowLoader(false)
    setAllApplicationsWithSupplier(resp.limitData || [])
    let temp = []
    for (let index = 0; index < resp.limitData?.length; index++) {
      const element = resp.limitData[index];
      let row = []
      row[0] = element.buyerName || "-"
      row[1] = element.buyerDUNSNo || "-"
      row[2] = element.buyerCountryName || "-"
      row[3] = element.productDetails || "-"
      row[4] = `${element.currency} ${element.requiredLimit}`
      row[5] = element.termsOfPayment || "-"
      row[6] = `${element.buyerCurrency.split(":")[1]} ${element.currentAnnualSale}`
      row[7] = element.expShipmentDate || "-"
      row[8] = <div className="d-flex">
        <img
          onClick={() => {
            handleApplication(index)
          }}
          className="cursor mr-3" src="assets/images/view_docs.svg" />
        <img className="cursor"
          onClick={() => handleApplication(index, null, "openChat")} src="assets/images/chat_new_btn.svg" />
      </div>
      row[9] = <div className="d-flex">
        <img
          // onClick={() => {
          //   handleApplication(index, "approve");
          // }}
          className="cursor mr-3" src="assets/images/approve_new_btn.svg" />
        <img
          // onClick={() => {
          //   handleApplication(index, "deny");
          // }}
          className="cursor"
          src="assets/images/reject_new_btn.svg" />
      </div>
      row[99] = element.createdAt
      temp.push(row)
    }
    setAllApplicationTableData(temp)
  }

  useEffect(() => {
    asyncUseEffectFunction()
    if (quotesDetail.merged_application_id?.includes("MERGED")) {
      getAllApplicationsWithSeller()
    }
  }, [quotesDetail, applicationType])

  useEffect(() => {
    getMyUsers()
  }, []);

  async function getMyUsers() {
    setSubUsers(await call('POST', 'getSubUsers', { parent_id: userId }))
  }

  function onCheckBuyerHealthClick(duns) {
    setshowLoader(true)
    call('POST', 'getBuyerHealth', { duns }).then((result) => {
      setshowLoader(false)
    }).catch((e) => {
      setshowLoader(false)
    });
  }

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting.modal && isSubmitting.type == 'quote') {
      submitForm();
    } else if (Object.keys(errors).length === 0 && isSubmitting.modal && (isSubmitting.type == 'charges' || isSubmitting.type == 'moreCharges')) {
      addOtherCharges();
    }
    else if (isSubmitting.modal) {
      setIsSubmitting({ modal: false, type: '' })
    }
  }, [errors]);

  async function handleValidation(tabIndex) {
    if (tabIndex != undefined) {
      return setTab(tabIndex)
    }
  }

  const handleChange = async (event) => {
    event.persist()
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }

  const handleRemark = async (event) => {
    event.persist()
    setRemark({ ...remark, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }

  const onDelete = async (datakey) => {
    const objWithIdIndex = data.otherCharges.findIndex((obj, key) => key === datakey);
    data.otherCharges.splice(objWithIdIndex, 1);
    if (data.otherCharges.length == 0) {
      delete data.otherCharges;
    }
    setData({ ...data })
  }

  const onEdit = async (item, datakey) => {
    if (item.unit == '%') {
      setData({
        ...data,
        otherChargeTitle: item.name,
        otherChargePercentage: item.amount
      })
    }
    else {
      setData({
        ...data,
        otherChargeTitle: item.name,
        otherChargesAmt: item.amount,
        otherChargeCurrency: item.unit
      })
    }
    const objWithIdIndex = data.otherCharges.findIndex((obj, key) => key === datakey);
    data.otherCharges.splice(objWithIdIndex, 1);
    if (data.otherCharges.length == 0) {
      delete data.otherCharges;
    }
  }

  function addOtherCharges() {
    let otherCharges = data.otherCharges || []
    if (data.otherChargeCurrency != "" || data.otherChargePercentage != "") {
      let temp = {
        "name": data.otherChargeTitle,
        "unit": data.otherChargeCurrency || "%",
        "amount": data.otherChargeCurrency ? data.otherChargesAmt : data.otherChargePercentage
      }
      otherCharges.push(temp)
      setData({
        ...data, otherChargeCurrency: "", otherChargePercentage: "", otherChargeTitle: "",
        otherChargesAmt: "", otherCharges
      })
    }
    if (isSubmitting.type == 'moreCharges') {
      setMoreCharges(true);
    }
    else {
      setQuoteForm({ modal: true, page: 1 });
    }
  }

  function addRemark() {
    setData({ ...data, 'assignRemark': remark.assignRemark })
  }

  async function preHandleSubmit(type) {
    let err = {};
    let validateFields = [];
    if (type == 'quote') {
      if (applicationType / 1 == 0) {
        validateFields = ["factoringFees", "factoringFeesUnit", "interestRate", "setupFees", "setupFeesUnit", "offerValidity", "financeLimit", "financeLimitUnit", "discountingPercentage"];
      }
      if (applicationType / 1 == 1) {
        let isBuyerActionTaken = true
        validateFields = ["factoringFees", "factoringFeesUnit", "interestRate", "setupFees", "setupFeesUnit", "offerValidity"];
        for (let index = 0; index < allApplicationsWithSupplier.length; index++) {
          if (data[`buyerAction${index}`] === undefined) {
            isBuyerActionTaken = false
          }
          if (data[`buyerAction${index}`] === true) {
            validateFields.push([`financeLimit${index}`])
            validateFields.push([`financeLimitUnit${index}`])
            validateFields.push([`discountingPercentage${index}`])
          }
        }
        if (!isBuyerActionTaken) {
          toastDisplay('Kindly select buyer status', "info")
          validateFields.push("takeBuyerAction")
        }
      }
    }
    else if (type == 'charges' || type == 'moreCharges') {
      if (!data.otherCharges) {
        if (data.otherChargeCurrency) {
          validateFields = ["otherChargeCurrency", "otherChargeTitle", "otherChargesAmt"];
        }
        else if (data.otherChargePercentage) {
          validateFields = ["otherChargePercentage", "otherChargeTitle"];
        }
        else {
          validateFields = ["otherChargeCurrency", "otherChargePercentage", "otherChargeTitle", "otherChargesAmt"];
        }

      }
    }
    validateFields.forEach((item) => {
      if (!data[item]) {
        err[item] = "Mandatory Field"
      }
    })
    console.log("eeeeeeeeeeeeeeeeeeeeeeeeee", err);
    setErrors(err)
    setIsSubmitting({ modal: true, type: type });
  }

  async function submitForm(value, actionType) {
    setshowLoader(true);
    let loopToIterate = applicationType / 1 == 0 ? [null] : allApplicationsWithSupplier
    for (let j = 0; j < loopToIterate.length; j++) {
      let financierAction = '';
      if (applicationType / 1 == 0) {
        if (value && value === 'deny') {
          financierAction = 'deny'
        }
        else {
          financierAction = 'Approved'
        }
      }
      else {
        financierAction = data[`buyerAction${j}`] ? "Approved" : "deny"
      }
      let formData = new FormData();
      Object.keys(data).forEach((key) => {
        if (data[key] && key === "termSheetLink") {
          formData.append("termSheetLink", data[key].trim())
        }
        else {
          if (key.includes(`financeLimit${j}`) && applicationType / 1 == 1) {
            formData.append(`financeLimit`, data[`financeLimit${j}`])
          }
          else if (key.includes(`financeLimitUnit${j}`) && applicationType / 1 == 1) {
            formData.append(`financeLimitUnit`, data[`financeLimitUnit${j}`])
          }
          else if (key.includes(`discountingPercentage${j}`) && applicationType / 1 == 1) {
            formData.append(`discountingPercentage`, data[`discountingPercentage${j}`])
          }
          else if (key.includes(`assignRemark${j}`) && applicationType / 1 == 1) {
            formData.append(`assignRemarks`, data[`assignRemark${j}`])
          }
          else {
            if ((applicationType / 1 == 1 && !(key.includes("financeLimit") || key.includes("financeLimitUnit") || key.includes("discountingPercentage") || key.includes('assignRemark')))
              || (applicationType / 1 == 0)) {
              formData.append(key, data[key]);
            }
          }
        }
      })
      formData.append("userName", userName)
      formData.append("lenderId", userId);
      if (applicationType / 1 == 0) {
        formData.append("buyerId", quotesDetail.buyerId);
        formData.append("limit_id", quotesDetail.limit_id);
        formData.append("sellerId", quotesDetail.created_by)
      }
      else {
        formData.append("buyerId", loopToIterate[j].buyerId);
        formData.append("limit_id", loopToIterate[j].limit_id);
        formData.append("sellerId", loopToIterate[j].created_by)
      }
      formData.append("financierAction", financierAction);
      if (actionType) {
        formData.append("financierActionType", actionType);
      }
      if (data.assignRemark && applicationType / 1 == 0) {
        formData.append('assignRemarks', data.assignRemark);
      }
      let tempOtherCharges = []
      for (let index = 0; index < otherCharges.length; index++) {
        if (data[`ocName${index}`] && data[`ocAmount${index}`] && data[`ocUnit${index}`]) {
          tempOtherCharges.push({
            name: data[`ocName${index}`],
            amount: data[`ocAmount${index}`],
            unit: data[`ocUnit${index}`],
            other: data[`ocOther${index}`]
          })
        }
      }
      if (tempOtherCharges.length) {
        formData.append("otherCharge", JSON.stringify(tempOtherCharges));
      }
      // else if (data.otherCharges) {
      //   formData.append("otherCharge", JSON.stringify(data.otherCharges));
      // }
      if (financierAction.deny) {
        formData.append("deniedstage", 'quote');
      }
      console.log('Termsheet name', data.termSheet)
      if (data.termSheet?.name) {
        formData.append('termSheet', data.termSheet)
      }
      await call('POST', 'sendQuote', formData)
      // .then(async (result) => {
      //   if (value && value === 'deny') {
      //     setDenyForm({ modal: false, page: 0 })
      //     toggleMsgPopup(true);
      //   }
      //   else {
      //     setQuoteForm({ modal: false, page: 0 })
      //     quoteMsgPopup(true);
      //   }
      //   setshowLoader(false)
      // }).catch((error) => {
      //   setshowLoader(false)
      //   toastDisplay(error, "error")
      // })
    }
    setshowLoader(false)
    setQuoteForm({ modal: false, page: 0 })
    quoteMsgPopup(true);
  }

  async function addRemarkOnQuote() {
    setshowLoader(true)
    call('POST', 'addRemarkOnQuote', {
      remark: data.onlyRemark, userId, userName, buyerId: quotesDetail.buyerId, buyerName: quotesDetail.buyerName, exporterUserId: quotesDetail.userId,
      applicationId: quotesDetail.limit_id
    }).then(async (result) => {
      setshowLoader(false)
      setDenyForm({ modal: true, page: 2, onlyRemark: true })
    }).catch((error) => {
      setshowLoader(false)
      toastDisplay(error, "error")
      setDenyForm({ modal: false, page: 1 })
    })
  }

  const handleFile = (event, isImage) => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }
      console.log('Fileddatataaaa', event.target.files)

      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result

        if (!file_type.includes("pdf") && !isImage) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }
  // console.log("dattttttttttttttttttttttttttttttttttttttttt", allApplicationsWithSupplier);

  const handleApplication = async (index, quoteForm, openChat, denialType) => {
    // console.log("insideHandleApplicationnnnnnnnnnnnnnnn", index, allApplicationsWithSupplier);
    setQuotesDetail({ ...quotesDetail, ...allApplicationsWithSupplier[index] })
    if (!quoteForm && !openChat) {
      if (allApplicationsWithSupplier[index]?.['createdAt']) {
        setExpandData([{ ...allApplicationsWithSupplier[index] }])
        setOpenApplication(allApplicationsWithSupplier[index]['createdAt'])
      }
    }
    if (openChat) {
      let item = allApplicationsWithSupplier[index] || quotesDetail
      let chatRoomIds = item.chatRoomIds?.split(",") || []
      let chatRoomUsers = item.chatRoomUsers?.split(",") || []

      let openChatRoomIndx = null
      chatRoomUsers.forEach((u, i) => {
        if (u?.split("::")[1] / 1 == userId / 1) {
          openChatRoomIndx = i
        }
      })

      let reqObj = {
        userTypeId,
        senderId: userId,
        sellerId: item.created_by,
        receiverId: userId,
        textMessage: 'Hii',
        chat_room_name: "CHAT" + new Date().getTime(),
        includeAdmins: true,
        invApplicationId: item.limit_id,
        receiverParties: userId,
        dontSendInitialMsg: true
      }
      setshowLoader(true)
      let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
      setshowLoader(false)
      getChatDetails({
        chat_room_id: apiResp.id,
        loggedInUser: userId
      })
      setSelectedChat({
        chatRoomId: apiResp.id,
        receiverName: `${chatRoomIds[openChatRoomIndx] || apiResp.id}`,
        invApplicationId: item.limit_id,
        parties: chatRoomUsers[openChatRoomIndx] || apiResp.parties,
        userId: userId,
        isChatOpen: true,
        receiverId: userId,
        sellerId: item.created_by
      })
    }
    if (quoteForm) {
      if (quoteForm === "approve") {
        setQuoteForm({ modal: true, page: 1 })
      }
      if (quoteForm === 'deny') {
        setDenyForm({ modal: true, page: 0, denialType })
      }
    }
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('invApplicationId', selectedChat.invApplicationId)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))

    formdata.append("userTypeId", userTypeId)
    formdata.append("sellerId", selectedChat.sellerId)

    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }
  const getFinancers = async () => {
    setshowLoader(true)
    let finApiResp = await call('POST', "getFinanciersForInsurance", {})
    let finIds = []
    for (let index = 0; index < finApiResp.length; index++) {
      const element = finApiResp[index];
      element["isAvailable"] = true
      element["logo"] = element.user_avatar || null
      element["isChecked"] = true
      finIds.push(element.id)
    }
    setData({
      ...data,
      selectedInsuarer: finIds
    })
    setshowLoader(false)
    setInsurerList(finApiResp)
  }
  useEffect(() => {
    getFinancers()
  }, [])
  console.log('quotesDetail', quotesDetail)
  return (
    <>
      {(tab === 2 && lcMoreDocuments.modal && lcMoreDocuments.page === 1) ?
        <div className={`modal fade ${lcMoreDocuments.modal && "show"}`} style={{ display: "block" }}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content submitmodal pb-4">
              <div className="modal-header border-0">
                <button type="button" className="btn-close" aria-label="Close" onClick={() => setLCMoreDocuments({ modal: false, page: 0 })}></button>
              </div>
              <div className="modal-body text-center">
                <p>Request more documents</p>
                <div className="row">
                  <div className='w-100'>
                    <div className='row'>
                      {data.additionalDocuments && data.additionalDocuments.length ?
                        data.additionalDocuments.map((item, index) => <>
                          <div className='col-md-7 move-l'>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <input disabled={true} className={"form-control bg-white text-al"} placeholder={item.doc_name} />
                                <div className="folderCommentIcon">
                                  <img src={"/assets/images/folder.png"} alt="" className="mb-3" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-2 move-l align-self-center'>
                            <img
                              onClick={async () => {
                                let temp = data.additionalDocuments;
                                temp = temp.filter((i, j) => {
                                  if (j != index) {
                                    return i
                                  }
                                })
                                let tempFile = undefined
                                if (item.documentSample) {
                                  setshowLoader(true)
                                  tempFile = await getDocDetails(item.documentSample)
                                  setshowLoader(false)
                                }
                                setData({
                                  ...data, additionalDocuments: temp, documentName: item.doc_name, documentReason: item.reason,
                                  documentSample: tempFile
                                })
                              }}
                              src={"/assets/images/edit.png"} alt="" className="mb-3" />
                            <img
                              onClick={() => {
                                let temp = data.additionalDocuments;
                                temp = temp.filter((i, j) => {
                                  if (j != index) {
                                    return i
                                  }
                                })
                                setData({ ...data, additionalDocuments: temp })
                              }} src={"/assets/images/cancel-icon.png"} alt="" className="mb-3 px-3" />
                          </div></>) : null
                      }
                      <div className='col-md-7 move-l'>
                        <NewInput isAstrix={true} type={"text"} label={"Document Name"}
                          name={"documentName"} value={data.documentName} onChange={handleChange}
                        />
                      </div>
                      <div className='col-md-7 move-l'>
                        <NewTextArea rows="6" isAstrix={true} type={"text"} label={`Reason`}
                          name={"documentReason"} value={data.documentReason} onChange={handleChange}
                        />
                      </div>
                      <div className='col-md-7 move-l'>
                        <label>Sample Document</label>
                        <FileInput name={"documentSample"} value={data.documentSample} error={errors.documentSample}
                          onChange={handleFile} isEditable={true}
                          onUploadCancel={() => setData({ ...data, "documentSample": null })}
                        />
                      </div>
                      <div className="col-md-11 move-md text-blue">
                        <a onClick={async () => {
                          if (!(data.documentName && data.documentReason)) {
                            toastDisplay("Fill details to continue", "info")
                          }
                          else {
                            // Upload document & get doc id
                            let docId = undefined
                            if (data.documentSample?.name && !data.documentSample?.fromDb) {
                              let formData = new FormData()
                              formData.append("userId", userId)
                              formData.append("docName", data.documentSample.name)
                              formData.append("file", data.documentSample)
                              setshowLoader(true)
                              docId = await call("POST", 'uploadDocManually', formData)
                              setshowLoader(false)
                            }
                            else if (data.documentSample?.doc_id) {
                              docId = data.documentSample?.doc_id
                            }
                            let temp = data.additionalDocuments || []
                            temp.push({ doc_name: data.documentName, reason: data.documentReason, documentSample: docId })
                            setData({ ...data, additionalDocuments: temp, documentName: "", documentReason: "", documentSample: null })
                          }
                        }}><p>Add more</p></a>
                      </div>
                    </div>
                  </div>
                </div>
                <button type="button" className={`new-btn w-20 py-2 px-2 text-white`}
                  onClick={async () => {
                    if (data?.additionalDocuments?.length) {
                      setshowLoader(true)
                      await call("POST", 'raiseInvoiceAmendment', {
                        additionalDocuments: data.additionalDocuments, applicationId: quotesDetail.limit_id,
                        userId, userName, buyerId: quotesDetail.buyerId, buyerName: quotesDetail.buyerName,
                        sellerId: quotesDetail.created_by
                      })
                      setshowLoader(false)
                      toastDisplay('Request forwarded', "success")
                      setLCMoreDocuments({ modal: false, page: 2 })
                    }
                    else {
                      toastDisplay("Kindly add 1 request to proceed", "info")
                    }
                  }}
                >Send Request</button>
              </div>
            </div>
          </div>
        </div> : null
      }

      {lcRequestMoreDetails.modal && lcRequestMoreDetails.page === 1 &&
        <div className={`modal fade ${lcRequestMoreDetails.modal && "show"}`} style={{ display: "block" }}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content submitmodal pb-4">
              <div className="modal-header border-0">
                <button type="button" className="btn-close" aria-label="Close" onClick={() => setLCRequestMoreDetails({ modal: false, page: 0 })}></button>
              </div>
              <div className="modal-body text-center">
                <p>Request more details</p>
                <div className="row">
                  <div className='w-100'>
                    <div className='row'>
                      {data.additionalDetails && data.additionalDetails.map((item, index) => {
                        return (
                          <>
                            <div className='col-md-6 move-m'>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <input disabled={true} className={"form-control bg-white"} placeholder={item.title} />
                                  {item.type == 'percentage' ? <p className="input-date-icon">%</p> :
                                    item.type == 'currency' ? <p className="input-date-icon">$</p> :
                                      item.type == 'text' ? <p className="input-date-icon">T</p> :
                                        <img src={"/assets/images/calender.png"} alt="" className="input-date-icon" />
                                  }
                                </div>
                              </div>
                            </div>
                            <div className='col-md-2 move-m'>
                              <img src={"/assets/images/edit.png"} alt="" className="mb-3 mt-2 cursor"
                                onClick={() => {
                                  let temp = data.additionalDetails;
                                  temp = temp.filter((i, j) => {
                                    if (j != index) {
                                      return i
                                    }
                                  })
                                  setData({ ...data, additionalDetails: temp, fieldTitle: item.title, fieldType: item.type, fieldReason: item.reason })
                                }}
                              />
                              <img src={"/assets/images/cancel-icon.png"} alt="" className="mb-3 px-3 mt-2 cursor"
                                onClick={() => {
                                  let temp = data.additionalDetails;
                                  temp = temp.filter((i, j) => {
                                    if (j != index) {
                                      return i
                                    }
                                  })
                                  setData({ ...data, additionalDetails: temp })
                                }}
                              />
                            </div></>
                        )
                      })
                      }
                    </div>
                    <div class="tab-pane fade show active" id="nav-home" role="tabpanel" >
                      <div class="col-md-6 move-m">
                        <div class="col-md-6">
                          <p style={{ "fontSize": "12px" }}>Select field type</p>
                        </div>
                        <div className="row justify-evenly">
                          {infoType.map((item) => {
                            return (
                              <div
                                onClick={() => setData({ ...data, fieldType: item.unit })}
                                style={data.fieldType == item.unit ? {
                                  border: "2px solid #B9EFFF"
                                } : null}
                                className={`mb-3 col-md-6 form-control w-45 cursor `}>
                                <label>{item.name}</label>
                              </div>
                            )
                          })}
                          {errors.fieldType ? <div class="text-danger mt-2 font-size-12">
                            <b>{errors.fieldType}</b></div> : ''}
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className="col-md-6 move-m">
                        <NewInput isAstrix={true} type={"text"} label={"Title"}
                          name={"fieldTitle"} value={data.fieldTitle} error={errors.fieldTitle} onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <div className="col-md-6 move-m">
                        <NewTextArea rows="6" isAstrix={true} type={"text"} label={`Reason.`}
                          name={"fieldReason"} value={data.fieldReason} error={errors.fieldReason} onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 move-left text-blue">
                  <a onClick={() => {
                    if (!(data.fieldType && data.fieldTitle && data.fieldReason)) {
                      toastDisplay("Fill details to add", "info")
                    }
                    else {
                      let temp = data.additionalDetails || []
                      temp.push({ title: data.fieldTitle, type: data.fieldType, reason: data.fieldReason })
                      setData({ ...data, additionalDetails: temp, fieldTitle: "", fieldTitle: "", fieldReason: "" })
                      // setAddMore(true)
                    }
                  }}><p>Add more</p></a>
                </div>
                <button type="button" className={`mx-2 new-btn w-20 py-2 px-2 text-white`} onClick={async () => {
                  if (data?.additionalDetails?.length) {
                    setshowLoader(true)
                    await call("POST", "raiseInvoiceAmendment", {
                      applicationId: quotesDetail.limit_id, additionalDetails: data.additionalDetails,
                      userId, userName, buyerId: quotesDetail.buyerId, buyerName: quotesDetail.buyerName,
                      sellerId: quotesDetail.created_by
                    })
                    toastDisplay("Request forwarded", "success")
                    setLCRequestMoreDetails({ modal: false, page: 0 })
                    setshowLoader(false)
                  }
                  else {
                    toastDisplay("Add atleast 1 request to send", "info")
                  }
                }}>Save</button>
              </div>
            </div>
          </div>
        </div>}
      <AddMoreDocumentHandler hide={true}
        purpose={"ApplyForLimitInvoice"} purposeId={quotesDetail.buyerId}
        userTokenDetails={userTokenDetails} onChange={(docs) => setMoreDocs(docs)}
      />
      {selectedChat.isChatOpen &&
        <div className="chatboxDivFixed">
          <ChatBoxPopUp2
            chatList={chatList}
            user_avatar={selectedChat.logo}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            parties={selectedChat.parties}
            userTokenDetails={userTokenDetails}
            onChatSend={(file, parties) => sendChatMsg(file, parties)}
            message={message}
            setTextMsg={setTextMsg}
            reloadChatList={() => getChatDetails({
              chat_room_id: selectedChat.chatRoomId,
              loggedInUser: userId
            })}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false,
                logo: ''
              })
            }}
          />
        </div>
      }
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <DocumentViewer show={viewDoc.show} value={viewDoc.doc} dismiss={() => toggleViewDoc({ show: false, doc: {} })} />
      <DocumentDownloader show={dowdDoc.show} value={dowdDoc.doc} dismiss={() => toggleDowdDoc({ show: false, doc: {} })} />
      {signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
        setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />}
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="fininvoiceQuotes" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Export Factoring > Quote Details"}
              userTokenDetails={userTokenDetails} />
            {viewBuyerDetails.show ? (
              <ViewBuyerDetails data={viewBuyerDetails.data} userTokenDetails={userTokenDetails}
                goback={() => setViewBuyerDetails({ show: false })}
              />
            ) : null}
            {showMsgPopup ? (
              <PopupMessage
                onClose={() => {
                  toggleMsgPopup(false);
                  if (applicationType / 1 == 0) {
                    window.location = "/finInvoiceQuotes"
                  }
                }}
                title={''}
                msg={`Finance limit for Invoice discounting denied successfully !`}
              />
            ) : null}
            {quoteMsg ? (
              <PopupMessage
                onClose={() => {
                  quoteMsgPopup(false);
                  window.location = "/finInvoiceQuotes"
                }}
                title={''}
                msg={`Finance quote for Invoice discounting sent successfully !`}
              />
            ) : null}
            {popupMsg.show ? (
              <PopupMessage
                onClose={() => {
                  setPopupMessage({ show: false });
                }}
                title={''}
                msg={popupMsg.msg}
              />
            ) : null}
            {viewBuyerDetails.show ? null : (
              <>
                <a className="cursor"
                  onClick={() => {
                    if (quoteScreen.show) {
                      toggleQuoteScreen({ show: false })
                    }
                    else {
                      history.go(-1)
                    }
                  }}
                ><img className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
                <div>
                  {quoteScreen.show ? null : (
                    <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                      {quotationTabs.map((item, index) => {
                        return (
                          <li>
                            <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                              onClick={() => { handleValidation(index) }}
                            >
                              {item.name}</a>
                          </li>
                        )
                      })}
                      {quotesDetail.merged_application_id?.includes("MERGED") ?
                        <li className="ml-auto pb-1">
                          <div className="ml-2 borderRadius10 border1pxSolidD5D5D5D">
                            {[{ name: 'Single Application' }, { name: 'All Application' }].map((i, j) => {
                              return (
                                <label
                                  onClick={() => setApplicationType(j)}
                                  className={`m-0 borderRadius10 cursor p-2 font-size-14 ${applicationType / 1 == j ? ' bg-B9EFFF font-wt-800' : ' font-wt-500 '}  `}>{i.name}</label>
                              )
                            })}
                          </div>
                        </li> : null}
                    </ul>
                  )}
                  <div className="card mt-1">
                    {/* <label
                  onClick={() => toggleMoreOption(!moreOption)}
                  className="font-wt-500 cursor moreOptionLabel"
                >...</label>
                <div
                  className={`moreOptionPopup ${moreOption ? ' d-block ' : ''}`}>
                  <div
                    onClick={() => { setDenyForm({ modal: true, page: 1, onlyRemark: true }); toggleMoreOption(false) }}
                    className="border-bottom d-flex px-3 justify-content-between align-items-center pt-2">
                    <label className="text-color-label cursor">{'Add Remark'}</label>
                  </div>
                  <div
                    onClick={async () => {
                      localStorage.setItem("applicationId", quotesDetail.limit_id)
                      localStorage.setItem("sellerId", quotesDetail.userId)
                      localStorage.setItem("financeLimit", 0)
                      localStorage.setItem("lenderId", userId)
                      localStorage.setItem("buyerId", quotesDetail.buyerId)
                      localStorage.setItem("buyerData", JSON.stringify({
                        buyerName: quotesDetail["buyerName"], buyerPosition: quotesDetail["buyerPosition"],
                        nameAuthorizedSignatory: quotesDetail["nameAuthorizedSignatory"], buyerEmail: quotesDetail["buyerEmail"],
                        buyerPhoneCode: quotesDetail["buyerPhoneCode"], buyerPhone: quotesDetail["buyerPhone"],
                        buyerIdentifier: quotesDetail["identifier"], incoTerms: quotesDetail["incoTerms"],
                        buyerWebsite: quotesDetail["buyerWebsite"], buyerAddress: quotesDetail["buyerAddress"],
                        buyerCountry: quotesDetail["buyerCountry"], buyerDUNSNo: quotesDetail["buyerDUNSNo"],
                        previousAnnualSale: quotesDetail["previousAnnualSale"], currentAnnualSale: quotesDetail["currentAnnualSale"],
                        termsOfPayment: quotesDetail["termsOfPayment"], productDetails: quotesDetail["productDetails"],
                        buyersDocId: quotesDetail["buyersDocId"]
                      }))
                      localStorage.setItem("lenderName", userName)
                      localStorage.setItem("lenderRemark", data.assignRemark || data.onlyRemark)
                      setshowLoader(true)
                      let additionalReqResp = await call('POST', 'isAdditionalDetailsReqByFinancer', {
                        lenderIdArr: [{ lender_id: userId }],
                        applicationId: quotesDetail.limit_id
                      })
                      // console.log("additionalReqResp========", quotesDetail, additionalReqResp);
                      setAdditionalReqDetails(additionalReqResp)
                      setshowLoader(false)
                      if (additionalReqResp?.[0]) {
                        localStorage.setItem("additionalReqObj", JSON.stringify(additionalReqResp?.[0]))
                      }
                      else {
                        localStorage.removeItem("additionalReqObj")
                      }
                      window.location = `/docsRequested`
                    }}
                    className="border-bottom d-flex px-3 justify-content-between align-items-center pt-2">
                    <label className="text-color-label cursor">{'View Reply on Remark'}</label>
                  </div>
                </div> */}
                    {tab === 0 ? (
                      <div className='px-5 py-4 pt-5 '>
                        <div className="row">
                          <div className='w-100'>
                            <label className='font-wt-500 font-size-16'><u>Finance Details</u></label>
                            <div className='row'>
                              {userFinData.length ? userFinData.map((item, index) => (
                                <div className="col-3 p-0 mb-1">
                                  {
                                    supplierForm.map((j, i) => {
                                      return (
                                        <div className='p-0'>
                                          <label className='col-6 lh-18 text-color-label font-size-14'>{j.name}</label>
                                          <label className='lh-18 font-size-14'>:</label>
                                          <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                            {item[j["val"]]} {j["unit"] === "finCurrency" ? item["currency"].split(":")[1] : ""}
                                          </label>
                                        </div>
                                      )
                                    })}
                                </div>
                              )) : null}
                            </div>
                          </div>

                          <div className='w-100 mt-5'>
                            <label className='font-wt-500 font-size-16'><u>Other Details</u></label>
                            <div className='row'>
                              {otherDetails.map((item) => {
                                return (
                                  <div className='col-12 p-0'>
                                    <label className='col-2 lh-18 text-color-label font-size-14'>{item.name}</label>
                                    <label className='lh-18 font-size-14'>:</label>
                                    <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                      {userData[item.val] || "NA"}
                                    </label>
                                  </div>
                                )
                              })}
                            </div>
                          </div>

                          <div className='w-100 mt-5'>
                            <label className='font-wt-500 font-size-16'><u>General Details</u></label>
                            <div className='row'>
                              {generalDetails.map((item) => {
                                return (
                                  <div className='col-12 p-0'>
                                    <label className='col-2 lh-18 text-color-label font-size-14'>{item.name}</label>
                                    <label className='lh-18 font-size-14'>:</label>
                                    <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                      {item.val ? item.val : "NA"}
                                    </label>
                                  </div>
                                )
                              })}
                            </div>
                          </div>

                        </div>
                      </div>
                    ) : null}

                    {tab === 1 ?
                      <div className='ulFormsContainer'>
                        <div className="accordionWrapper p-0 m-0">
                          {allApplicationsWithSupplier?.map((key, index) => {
                            return (
                              <div className="col-md-12 row p-0 m-0">
                                <div
                                  style={errors[`accordionRow${index}`] ? { border: '2px solid red' } : { border: accordion === (index + 1) ? '2px solid rgb(92, 184, 211)' : '0' }}
                                  className={"accordionItem col-md-12" + (accordion === (index + 1) ? " open" : " closed")}>
                                  <div className={"accordionItemHeading d-flex row align-items-center "} onClick={() => { setAccordion(accordion === index + 1 ? null : (index + 1)) }}>
                                    <div className="col-5" >
                                      <label className="font-size-14 font-wt-600" >{key[`buyerName`] || "NA"}</label>
                                    </div>
                                    <div style={{ right: '2.5rem' }} className="col-7 d-flex justify-content-end" >
                                      <button type="button"
                                        onClick={() => {
                                          toggleViewBuyerDetails(key)
                                        }}
                                        className={`new-btn2 py-2 px-3 text-blue font-wt-600`}>
                                        {"View Profile"}
                                      </button>
                                    </div>
                                  </div>
                                  <div className="accordionItemContent">
                                    <div className='mx-0 px-0'>
                                      <p><u>Credit Summary</u></p>
                                      <label className="font-size-16 text-color1 font-wt-600" >Maximum credit recommendation : NA</label>
                                      <div className="row d-flex px-2 pt-2">
                                        {ProgressBars.map((item, index) => {
                                          return (
                                            <div className="bg-white w-35 card-layout mr-5 mb-4 px-4 py-2">
                                              <label className="font-wt-400 font-size-14" >{item.name}</label>
                                              <CustomProgressBar reverse={item.reverse}
                                                value={data[item.key] ? data[item.key] / 1 : 0}
                                                min={item.min} max={item.max} />
                                              <div className="row pt-2 d-flex">
                                                <div className="w-50">
                                                  <label>{item.reverse ? "High Risk" : "Low risk :"} <label className="font-wt-600">{item.min}</label></label>
                                                </div>
                                                <div className="w-50 text-right">
                                                  <label>{item.reverse ? "Low Risk" : "High risk :"} <label className="font-wt-600">{item.max}</label></label>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        })}
                                      </div>
                                    </div>
                                    <div className='m-0 p-0'>
                                      <div className="d-flex gap-3 py-1 align-items-center justify-content-between">
                                        <p><u>Trade Credit Insuarance</u></p>
                                        <div className="d-flex px-4 pb-0 pt-0">
                                          <button
                                            onClick={() => {
                                              window.location = `tciSingleBuyer?buyerid=${quotesDetail.buyerId}&exporterId=${quotesDetail.userId}`
                                              localStorage.setItem("quotesDetail", JSON.stringify(quotesDetail));
                                            }}
                                            type="button" className={`new-btn py-2 px-4 text-white cursor`}>
                                            {"Apply for TCI"}
                                          </button>
                                        </div>
                                      </div>
                                      <div className='row m-0 p-0 d-flex justify-content-between'>
                                        {InsurerList.map((item, index) => {
                                          return <div style={{ width: '32%' }} className=" mt-4 text-center m-0 p-0">
                                            <div className='col-md-12 m-0 p-0'>
                                              <div className="card p-4 cursor rounded-3 chatlist h-100">
                                                <div className="d-flex gap-3 pb-3 align-items-center">
                                                  {/* <div className='mb-3 d-flex pl-2 pt-2' >
                                  <input type="radio" id={item.alt} name={item.alt}
                                    checked={data[item.alt] ? true : false}
                                    onClick={() => {
                                      setData({ ...data, [item.alt]: !data[item.alt] })
                                    }} className='cursor'
                                  />
                                  <label for={item.alt}></label>
                                </div> */}
                                                  <img
                                                    style={{ maxWidth: '8rem', maxHeight: '2rem' }}
                                                    src={avatarUrl(item.logo)} alt="" />
                                                  <div className='d-flex gap-2 align-items-center'>
                                                    <label className='font-size-14 font-wt-500 text-color1 m-0'>View More</label>
                                                    <img src='/assets/images/ArrowBack.png' height={20} width={20} style={{ filter: "invert(44%) sepia(97%) saturate(487%) hue-rotate(149deg) brightness(91%) contrast(82%)" }} />
                                                  </div>
                                                </div>
                                                <div className="d-flex gap-3 py-3 align-items-center justify-content-between">
                                                  <div className="d-flex align-items-center gap-2 px-0 cursor">
                                                    <div>
                                                      <p className="mb-0 font-size-14 text-right">{item.avgRatings.toFixed(2)}
                                                      </p>
                                                    </div>
                                                    <div>
                                                      <span>
                                                        <img className={`${item.avgRatings < 0.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                                        <img className={`${item.avgRatings < 1.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                                        <img className={`${item.avgRatings < 2.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                                        <img className={`${item.avgRatings < 3.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                                        <img className={`${item.avgRatings < 4.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div className="d-flex align-items-center gap-2">
                                                    <p className="font-size-14 mb-1 text-right">{item.reviewsCount}</p>
                                                    <p className="font-size-14 mb-1 text-right">{"See reviews"}</p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        })}
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      : null}

                    {tab === 2 && lcMoreDetails ? (
                      <>
                        <img className="mb-3 back-btn cursor" src={"assets/images/back.png"} alt="Back" height={15} width={15} onClick={() => setLCMoreDetails(false)} />
                        <div className='px-5 py-4 pt-5 '>
                          <div className="row">
                            <div className='w-100'>
                              <label className='font-wt-500 font-size-16'><u>Buyer Details</u></label>

                              <div className='row'>
                                {reviewForm3.map((item) => {
                                  let isFieldChanged = false
                                  // let isFieldChanged = afterAmendmentFiels[item] && afterAmendmentFiels[item] != beforeAmendmentFiels[item] ? true : false
                                  return (
                                    <div className='col-md-4'>
                                      <NewInput type={"text"} isDisabled={true} label={item.name}
                                        value={quotesDetail[item.val] ? (item.unit ? `${item.convUnit ? item.convUnit(quotesDetail[item.unit]) : quotesDetail[item.unit]} ` : "") + (quotesDetail[item.val]) : "NA"}
                                      />
                                      {item.notAmmendable ? null : (
                                        <div
                                          onClick={() => toggleAmmendPopup({ show: true, key: item.val })}
                                          className="cursor ammendCommentIcon"
                                        >
                                          <img className="genericImgIcon" src={ammendFields[item.val] ? "assets/images/comment_filled.png" : "assets/images/chat.png"} alt="" />
                                        </div>
                                      )}
                                      {ammendPopup && ammendPopup.show && ammendPopup.key === item.val ? (
                                        <div className="ammendPopup">
                                          <button
                                            type="button" className="btn-close ammendCommentCrossIcon" onClick={() => toggleAmmendPopup({ show: false, key: null })}></button>
                                          <div className="text-center">
                                            <NewTextArea
                                              rows={6}
                                              type={"text"} label={`Comment`} name={ammendPopup.key}
                                              value={ammendFields[ammendPopup.key]}
                                              onChange={(e) => {
                                                setAmmendFields({ ...ammendFields, [ammendPopup.key]: e.target.value || undefined })
                                              }}
                                            />
                                            <button type="button"
                                              onClick={async () => {
                                                setshowLoader(true)
                                                await call("POST", "raiseInvoiceAmendment", {
                                                  commentObj: { [item.val]: ammendFields[ammendPopup.key] }, applicationId: quotesDetail.limit_id,
                                                  userId, userName, buyerId: quotesDetail.buyerId, buyerName: quotesDetail.buyerName,
                                                  sellerId: quotesDetail.created_by
                                                })
                                                setshowLoader(false)
                                                toggleAmmendPopup({ show: false, key: null })
                                                toastDisplay('Request forwarded', "success")
                                              }}
                                              style={{ bottom: '2rem', right: '1rem' }}
                                              className={`new-btn py-2 px-3 text-white position-absolute`}>
                                              {"Send"}
                                            </button>
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>
                                  )
                                })}
                              </div>

                              {/* <p className="mt-3 text-blue cursor"
                            onClick={() => setLCRequestMoreDetails({ modal: true, page: 1 })}
                          >Request more details  <img src={"assets/images/right-arrow.png"} alt="Back" height={15} width={15} /></p> */}
                            </div>
                            {data.additionalDetails && data.additionalDetails.length ?
                              <div className='w-100 mt-4'>
                                <label className='font-wt-500 font-size-16'><u>Additional Details Required</u></label>
                                <div className="row">
                                  {data.additionalDetails.map((item, index) =>
                                    <div className='col-md-3'>
                                      <div className="row form-group">
                                        <div className="col-md-12">
                                          {item.type === "date" ? (
                                            <img
                                              src={"/assets/images/calender.png"} alt="" className="input-date-icon2" />) : null}
                                          {/* <input disabled={true} className={"form-control bg-white"}
                                        placeholder={`${item.type === "percentage" ? "%  " : ""}${item.type === "currency" ? "$  " : ""}${item.title}`} /> */}
                                          <NewInput isDisabled={true} value={item.val ? ((item.unit || "") + " " + item.val) : ""} label={`${item.type === "percentage" ? "%  " : ""}${item.type === "currency" ? "$  " : ""}${item.title}`} />
                                          <img onClick={() => toggleComment(index)} src={"/assets/images/comment_filled.png"} alt="" className="input-date-icon" />
                                        </div>
                                        {showComment === index ? (
                                          <div className="ammendPopup">
                                            <button
                                              type="button" className="btn-close ammendCommentCrossIcon"
                                              onClick={() => toggleComment(null)}></button>
                                            <div className="text-center">
                                              <NewTextArea
                                                rows={6}
                                                type={"text"} label={`Reason`}
                                                value={item.reason}
                                              />
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              : null}
                          </div>
                        </div>
                      </>) : quoteScreen.show ?
                      <div className="d-flex row px-5 pt-5 pb-4" >
                        <div className="d-flex" >
                          <div className="col-9" >
                            <label className="font-size-18 font-wt-600" >Quote</label>
                            {/* <div className="mt-3" > */}
                            <img className=" mr-2 ml-3" src="assets/images/warning.png" />
                            <label className="font-size-13 font-wt-600">Click on the “%” symbol to change is to currency.</label>
                            {/* </div> */}
                          </div>
                          <div className="col-3 d-flex justify-content-end" >
                            <button type="button"
                              onClick={() => {
                                preHandleSubmit('quote')
                              }}
                              className={`border-0 mb-2 text-white enableQuotebtn2`}>
                              {"Send Quote"}
                            </button>
                          </div>
                        </div>

                        {applicationType / 1 === 1 ? <div className="mt-3" >
                          <NewTablev2 stickTableHeaderToTop columns={[{ subColumns: 'Buyer Name', subColumnStyle: { width: '20%' } },
                          { subColumns: 'Finance limit', subColumnStyle: { width: '18%' } },
                          { subColumns: 'Discounting Percentage', subColumnStyle: { width: '18%' } },
                          { subColumns: 'Remark', subColumnStyle: { width: '25%' } },
                          { subColumns: 'Status', subColumnStyle: { width: '16%' } },
                          { subColumns: '', subColumnStyle: { width: '7%' } }]}>
                            {allApplicationsWithSupplier.map((i, j) => {
                              return (
                                <tr>
                                  <td><label className='font-size-12 font-wt-500'>{i.buyerName || "-"}</label></td>
                                  <td>
                                    <InputWithSelectForTable
                                      isAstrix={false} type={"number"} label={""}
                                      selectData={most_used_currencies}
                                      selectName={`financeLimitUnit${j}`} selectValue={data[`financeLimitUnit${j}`]}
                                      optionValue={"code"} optionLabel={"code"}
                                      name={`financeLimit${j}`} value={data[`financeLimit${j}`]} error={errors[`financeLimit${j}`]}
                                      onChange={handleChange} />
                                  </td>
                                  <td>
                                    <InputWithSelectForTable
                                      isAstrix={false} type={"number"} label={""}
                                      selectData={[{ name: "%" }]}
                                      selectName={`discountingPercentageUnit${j}`} selectValue={data[`discountingPercentageUnit${j}`] || "%"}
                                      optionValue={"name"} optionLabel={"name"}
                                      name={`discountingPercentage${j}`} value={data[`discountingPercentage${j}`]} error={errors[`discountingPercentage${j}`]}
                                      onChange={handleChange} />
                                  </td>
                                  <td>
                                    <InputForTable name={`assignRemark${j}`} onChange={handleChange} value={data[`assignRemark${j}`]} />
                                  </td>
                                  <td>
                                    {data[`buyerAction${j}`] === undefined ?
                                      <div className="d-flex">
                                        <img
                                          onClick={() => {
                                            setData({ ...data, [`buyerAction${j}`]: true })
                                          }}
                                          className="cursor mr-3" src="assets/images/approve_new_btn.svg" />
                                        <img
                                          onClick={() => {
                                            setData({ ...data, [`buyerAction${j}`]: false })
                                          }}
                                          className="cursor"
                                          src="assets/images/reject_new_btn.svg" />
                                      </div> :
                                      < button type="button"
                                        className={` ${data[`buyerAction${j}`] ? ' approvedBtnWithBorder ' : ' rejectedBtnWithBorder'}`}>
                                        {data[`buyerAction${j}`] ? "Approved" : "Rejected"}
                                      </button>
                                    }
                                  </td>
                                  <td
                                    style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
                                    className='position-relative'>
                                    <i className="fa fa-ellipsis-v cursor mt-2 ml-2"
                                      onClick={() => setAction({ show: true, index: j })}
                                      aria-hidden="true"></i>
                                    {action.show && action.index === j ? (
                                      <Action
                                        id={j}
                                        onDismiss={() => setAction({ show: false, index: j })}
                                        options={[
                                          {
                                            name: data[`buyerAction${j}`] ? "Reject Buyer" : "Approve Buyer", onClick: () => {
                                              setData({ ...data, [`buyerAction${j}`]: !data[`buyerAction${j}`] })
                                            }
                                          }
                                        ]} />
                                    ) : null}
                                  </td>
                                </tr>
                              )
                            })}
                          </NewTablev2>
                        </div> : null}

                        <div className="my-3" >

                          <NewTablev2 stickTableHeaderToTop showSideBorders={true} columns={[{ subColumns: 'Charges', subColumnStyle: { width: '18%' } }, { subColumns: 'Rate/fees', subColumnStyle: { width: '20%' } },
                          { subColumns: 'Others', subColumnStyle: { width: '50%' } }, { subColumns: 'Action', subColumnStyle: { width: '10%' } }]} >
                            <tr>
                              <td >
                                <label className='font-size-12 font-wt-500'>Factoring Fees</label></td>
                              <td className='font-size-13 font-wt-500' >
                                <InputWithSelectForTable
                                  isAstrix={false} type={"number"} label={"Factoring Fees"}
                                  selectData={currencies}
                                  selectName={"factoringFeesUnit"} selectValue={data.factoringFeesUnit}
                                  optionValue={"code"} optionLabel={"code"}
                                  name={"factoringFees"} value={data.factoringFees} error={errors.factoringFees}
                                  onChange={handleChange} />
                              </td>
                              <td>-</td>
                              <td>-</td>
                            </tr>
                            <tr>
                              <td  ><label className='font-size-12 font-wt-500'>Interest Rate</label></td>
                              <td className='' >
                                <InputWithSelectForTable
                                  isAstrix={false} type={"number"} label={"Interest Rate"}
                                  selectData={[{ name: "%" }]}
                                  selectName={"interestRateUnit"} selectValue={data.interestRateUnit || "%"}
                                  optionValue={"name"} optionLabel={"name"}
                                  name={"interestRate"} value={data.interestRate} error={errors.interestRate}
                                  onChange={handleChange} />
                              </td>
                              <td className="font-size-12 font-wt-500 " >
                                <div className="d-flex row " >
                                  <div className="w-auto">
                                    <label className="font-size-12 font-wt-500 ">Inclusive of TERM SOFR? If yes, </label>
                                  </div>
                                  <div className="w-auto font-size-13 font-wt-600" ><SelectForTable name={"termSofr"}
                                    selectData={[{ name: '3 Months' }, { name: '6 Months' }, { name: '9 Months' }, { name: '12 Months' }]}
                                    optionLabel={"name"} optionValue={'name'} value={data.termSofr} onChange={handleChange} /> </div>
                                </div>
                              </td>
                              <td>-</td>
                            </tr>
                            <tr>
                              <td  > <label className='font-size-12 font-wt-500'>Setup fees</label> </td>
                              <td className='font-size-13 font-wt-500 d-flex w-100' >
                                <InputWithSelectForTable
                                  isAstrix={false} type={"number"} label={"Setup Fees"}
                                  selectData={currencies}
                                  selectName={"setupFeesUnit"} selectValue={data.setupFeesUnit}
                                  optionValue={"code"} optionLabel={"code"}
                                  name={"setupFees"} value={data.setupFees} error={errors.setupFees}
                                  onChange={handleChange} />
                              </td>
                              <td className="font-size-12 font-wt-500" >
                                <div className="d-flex row " >
                                  <div className="w-auto pt-1" >
                                    <label className="font-size-12 font-wt-500 ">Negotiable?</label>
                                  </div>
                                  <div className="col-7 row d-flex my-0 p-0 px-2 font-size-13 font-wt-600" >
                                    <div className='w-auto' onClick={() => {
                                      setData({ ...data, setUpFeesNegotiable: "no" })
                                    }}>
                                      <input className="form-check-input" type="radio" value={"no"} checked={data.setUpFeesNegotiable === 'no'} />
                                      <label className="form-check-label p-0 m-0" >
                                        No
                                      </label>
                                    </div>
                                    <div className='w-auto' onClick={() => {
                                      setData({ ...data, setUpFeesNegotiable: "yes" })
                                    }}>
                                      <input className="form-check-input" type="radio" value={"yes"} checked={data.setUpFeesNegotiable === 'yes'} />
                                      <label className="form-check-label p-0 m-0" >
                                        Yes
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>-</td>
                            </tr>
                            <tr>
                              <td  ><label className='font-size-12 font-wt-500'>Offer Validity</label> </td>
                              <td className='' >
                                <InputForTable minDate={moment().format("YYYY-MM-DD")} type={"date"} name={"offerValidity"} value={data.offerValidity} onChange={handleChange} error={errors.offerValidity} />
                              </td>
                              <td>-</td>
                              <td>-</td>
                            </tr>
                            {applicationType / 1 == 1 ? null :
                              <tr>
                                <td ><label className='font-size-12 font-wt-500' >Finance Limit</label> </td>
                                <td className='' >
                                  <InputWithSelectForTable
                                    isAstrix={false} type={"number"} label={"Finance Limit"}
                                    selectData={most_used_currencies}
                                    selectName={"financeLimitUnit"} selectValue={data.financeLimitUnit}
                                    optionValue={"code"} optionLabel={"code"}
                                    name={"financeLimit"} value={data.financeLimit} error={errors.financeLimit}
                                    onChange={handleChange} />
                                </td>
                                <td>-</td>
                                <td>-</td>
                              </tr>
                            }
                            {applicationType / 1 == 1 ? null : <tr>
                              <td> <label className='font-size-12 font-wt-500'>Discounting Percentage</label> </td>
                              <td className='' >
                                <InputWithSelectForTable
                                  isAstrix={false} type={"number"} label={"Discounting Percentage(%)"}
                                  selectData={[{ name: "%" }]}
                                  selectName={"discountingPercentageUnit"} selectValue={data.discountingPercentageUnit || "%"}
                                  optionValue={"name"} optionLabel={"name"}
                                  name={"discountingPercentage"} value={data.discountingPercentage} error={errors.discountingPercentage}
                                  onChange={handleChange} />
                              </td>
                              <td>-</td>
                              <td>-</td>
                            </tr>}
                            <tr>
                              <td  ><label className='font-size-12 font-wt-500'>Limit For</label></td>
                              <td>-</td>
                              <td className='font-size-13 font-wt-500' >
                                <div className="row d-flex my-0 p-0 px-2 font-size-13 font-wt-600" >
                                  <div className='w-auto' onClick={() => {
                                    setData({ ...data, limitFor: "Supplier" })
                                  }}>
                                    <input className="form-check-input" type="radio" value={"Supplier"} checked={data.limitFor === 'Supplier'} />
                                    <label className="form-check-label p-0 m-0" >
                                      Supplier
                                    </label>
                                  </div>
                                  <div className='w-auto' onClick={() => {
                                    setData({ ...data, limitFor: "Buyer" })
                                  }}>
                                    <input className="form-check-input" type="radio" value={"Buyer"} checked={data.limitFor === 'Buyer'} />
                                    <label className="form-check-label p-0 m-0" >
                                      Buyer
                                    </label>
                                  </div>
                                </div>
                              </td>
                              <td>-</td>
                            </tr>
                            <tr>
                              <td><label className='font-size-12 font-wt-500'>Facility Type</label> </td>
                              <td>-</td>
                              <td className='font-size-13 font-wt-500' >
                                <div className="row d-flex my-0 p-0 px-2 font-size-13 font-wt-600" >
                                  <div className='w-auto' onClick={() => {
                                    setData({ ...data, facilityType: "Recourse" })
                                  }}>
                                    <input className="form-check-input " type="radio" value={"Recourse"} checked={data.facilityType === 'Recourse'} />
                                    <label className="form-check-label p-0 m-0" >
                                      Recourse
                                    </label>
                                  </div>
                                  <div className='w-auto' onClick={() => {
                                    setData({ ...data, facilityType: "Non-Recourse" })
                                  }}>
                                    <input className="form-check-input" type="radio" value={"Non-Recourse"} checked={data.facilityType === 'Non-Recourse'} />
                                    <label className="form-check-label p-0 m-0" >
                                      Non-Recourse
                                    </label>
                                  </div>
                                  <div className='w-auto' onClick={() => {
                                    setData({ ...data, facilityType: "Limited Recourse" })
                                  }}>
                                    <input className="form-check-input" type="radio" value={"Limited Recourse"} checked={data.facilityType === 'Limited Recourse'} />
                                    <label className="form-check-label p-0 m-0" >
                                      Limited Recourse
                                    </label>
                                  </div>
                                </div>
                              </td>
                              <td>-</td>
                            </tr>
                            {otherCharges.map((i, j) => {
                              return (<tr>
                                <td className='' >
                                  <InputForTable name={`ocName${j}`} value={data[`ocName${j}`]} onChange={handleChange} />
                                </td>
                                <td className='font-size-13 font-wt-500' >
                                  <InputWithSelectForTable type={"number"} label={""}
                                    selectData={most_used_currencies}
                                    selectName={`ocUnit${j}`} selectValue={data[`ocUnit${j}`]}
                                    optionValue={"code"} optionLabel={"code"}
                                    name={`ocAmount${j}`} value={data[`ocAmount${j}`]}
                                    onChange={handleChange} />
                                </td>
                                <td className='' >
                                  <div>
                                    <InputForTable name={`ocOther${j}`} value={data[`ocOther${j}`]} onChange={handleChange} />
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex w-100 justify-content-between" >
                                    <div className="" >
                                      {otherCharges.length - 1 === j ?
                                        <img
                                          onClick={async () => {
                                            let temp = otherCharges
                                            temp.push(null)
                                            setOtherCharges([...temp])
                                            setData({ ...data, [`ocUnit${j + 1}`]: '%' })
                                          }}
                                          className="cursor" src="assets/images/add_black_icon.png" /> :
                                        <img
                                          onClick={() => {
                                            let temp = otherCharges
                                            temp = temp.filter((l, m) => {
                                              if (m != j) {
                                                return true
                                              }
                                            })
                                            setOtherCharges([...temp])
                                          }}
                                          className="cursor" src="assets/images/delete.png" />}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              )
                            })}
                          </NewTablev2>
                        </div>

                        <div className="my-3" >
                          <label className="font-size-13 font-wt-500" ><u>Documents</u></label>
                          <NewTablev2 columns={[{ subColumns: 'Document Name', subColumnStyle: { width: '25%' } }, { subColumns: 'Attach Document', subColumnStyle: { width: '55%' } },
                          { subColumns: 'Sign', subColumnStyle: { width: '10%' } }, { subColumns: 'Action', subColumnStyle: { width: '10%' } }]} >
                            <tr>
                              <td> <label className='font-size-12 font-wt-500'>Term Sheet</label> </td>
                              <td>
                                <FileInput isEditable={true} value={data.termSheet} name={"termSheet"}
                                  onChange={(e) => handleFileCommonFunction(e, data, errors, setData, setErrors, setshowLoader)}
                                  onUploadCancel={() => setData({ ...data, termSheet: null })} />
                              </td>
                              <td>
                                {!data[`termSheet`]?.signatureId ?
                                  <img className="cursor"
                                    onClick={() => { setSigndoc(data[`termSheet`]?.name); setData({ ...data, docToSign: data[`termSheet`], docSignName: `termSheet` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                  :
                                  <img style={{ width: '20px' }} className=""
                                    src={"assets/images/doc_sign_filled.svg"} alt="" />
                                }
                              </td>
                              <td><div
                                className="d-flex"
                              >
                                {data[`termSheet`]?.name ? (
                                  <>
                                    <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`termSheet`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                    <img onClick={() => { toggleViewDoc({ show: true, doc: data[`termSheet`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                  </>
                                ) : null}
                              </div></td>
                            </tr>
                            {/* {otherDocs.map((i, j) => {
                          return (
                            <tr>
                              <td className='' >
                                <div className="col-9 p-0" >
                                  <InputForTable name={`otherDocumentName${j}`} value={data[`otherDocumentName${j}`]} onChange={handleChange} />
                                </div>
                              </td>
                              <td>
                                <FileInput isEditable={true} value={data[`otherDocument${j}`]} name={`otherDocument${j}`}
                                  onChange={(e) => handleFileCommonFunction(e, data, errors, setData, setErrors, setshowLoader)}
                                  onUploadCancel={() => setData({ ...data, [`otherDocument${j}`]: null })} />
                              </td>
                              <td>
                                {!data[`otherDocument${j}`]?.signatureId ?
                                  <img className="cursor"
                                    onClick={() => { setSigndoc(data[`otherDocument${j}`]?.name); setData({ ...data, docToSign: data[`otherDocument${j}`], docSignName: `otherDocument${j}` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                  :
                                  <img style={{ width: '20px' }} className=""
                                    src={"assets/images/doc_sign_filled.svg"} alt="" />
                                }
                              </td>
                              <td><div
                                className="d-flex"
                              >
                                {data[`otherDocument${j}`]?.name ? (
                                  <>
                                    <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`otherDocument${j}`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                    <img onClick={() => { toggleViewDoc({ show: true, doc: data[`otherDocument${j}`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                    <div className="" >
                                      {otherDocs.length - 1 === j ?
                                        <img
                                          onClick={async () => {
                                            let temp = otherDocs
                                            temp.push(null)
                                            setOtherDocs([...temp])
                                          }}
                                          className="cursor" src="assets/images/add_black_icon.png" /> :
                                        <img
                                          onClick={() => {
                                            let temp = otherDocs
                                            temp = temp.filter((l, m) => {
                                              if (m != j) {
                                                return true
                                              }
                                            })
                                            setOtherDocs([...temp])
                                          }}
                                          className="cursor" src="assets/images/delete.png" />}
                                    </div></>
                                ) : null}
                              </div></td>
                            </tr>
                          )
                        })} */}
                          </NewTablev2>
                        </div>

                      </div> : tab === 2 ? (
                        <div className='px-5 py-4 pt-5 '>
                          <div className="row">
                            <div className='w-100'>
                              <label className='font-wt-500 font-size-16'><u>Seller Details</u></label>
                              <div className='row'>
                                {supplierDetails.map((item) => {
                                  return (
                                    <div className="col-md-6 pe-5">
                                      <p className="d-flex align-items-top mb-2">
                                        <span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span>
                                        {item.name ? <>
                                          <span className="mx-3">:</span>
                                          <span className="col-md-7 BuyerdetailsDesc" > {!item.unit ? <span
                                            onClick={() => {
                                              if (item.onValClick) {
                                                item.onValClick(userData["company_name"])
                                              }
                                            }}
                                            style={item.valStyle || {}} >{(userData[item.val] || "-")}</span> : `${userData[item.unit]} ${userData[item.val] || "-"}`}</span>
                                        </> : null}
                                      </p>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                            {applicationType / 1 == 0 ? (<div className='w-100 mt-4'>
                              <label className='font-wt-500 font-size-16'><u>Buyer Details</u></label>
                              <div className='row'>
                                <div className="col-md-6 pe-5">
                                  <p className="d-flex align-items-top mb-2">
                                    <span className="col-md-5 px-0 BuyerdetailsLabel">{"Exporter Id"}</span>
                                    <span className="mx-3">:</span>
                                    <span className="col-md-7 BuyerdetailsDesc" > {quotesDetail.exporterIdentifier}</span>
                                  </p>
                                </div>
                                <div className="col-md-6 pe-5">
                                  <p className="d-flex align-items-top mb-2">
                                    <span className="col-md-5 px-0 BuyerdetailsLabel">{"Buyer Id"}</span>
                                    <span className="mx-3">:</span>
                                    <span className="col-md-7 BuyerdetailsDesc" > {quotesDetail.identifier}</span>
                                  </p>
                                </div>
                                {quotesDetail.buyerDetails.map((item) => {
                                  return (
                                    <div className="col-md-6 pe-5">
                                      <p className="d-flex align-items-top mb-2">
                                        <span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span>
                                        <span className="mx-3">:</span>
                                        <span className="col-md-7 BuyerdetailsDesc" > {item.val ? item.val : "NA"}</span>
                                      </p>
                                    </div>
                                  )
                                })}
                                <div className="col-md-6 pe-5">
                                  <p className="d-flex d-flex align-items-top mb-2">
                                    <span className="col-md-5 px-0 BuyerdetailsLabel">Limit Requested</span>
                                    <span className="mx-3">:</span>
                                    <span className="col-md-7 BuyerdetailsDesc" > {quotesDetail.requiredLimit ? quotesDetail.currency + ' ' + quotesDetail.requiredLimit : "NA"}</span>
                                  </p>
                                </div>
                                <div className="col-md-6 pe-5">
                                  <p className="d-flex d-flex align-items-top mb-2">
                                    <span className="col-md-5 px-0 BuyerdetailsLabel">Expected date of Shipment</span>
                                    <span className="mx-3">:</span>
                                    <span className="col-md-7 BuyerdetailsDesc" > {quotesDetail.expShipmentDate || "NA"}</span>
                                  </p>
                                </div>
                                <div className="col-md-6 pe-5">
                                  <p className="d-flex d-flex align-items-top mb-2">
                                    <span className="col-md-5 px-0 BuyerdetailsLabel">Buyer Annual Turnover</span>
                                    <span className="mx-3">:</span>
                                    <span className="col-md-7 BuyerdetailsDesc" > {quotesDetail.annualTurnOver || "NA"}</span>
                                  </p>
                                </div>
                                {/* <p className="mt-3 text-blue cursor"
                              onClick={() => setLCMoreDetails(true)}
                            >Request more details  <img src={"assets/images/right-arrow.png"} alt="Back" height={15} width={15} /></p> */}
                              </div>
                            </div>) : (
                              <div className='w-100 mt-4'>
                                <div className="d-flex align-items-center my-2" >
                                  <label className='font-wt-500 font-size-16 w-40'><u>Buyer Details</u></label>
                                  <div className="d-flex w-60 justify-content-end" >
                                    <div className="mr-4" >
                                      <img
                                        className="cursor mr-3" src="assets/images/approve_new_btn.svg" />
                                      <label className="font-size-14 font-wt-600" >Approve Buyer</label>
                                    </div>
                                    <div>
                                      <img
                                        className="cursor mr-3"
                                        src="assets/images/reject_new_btn.svg" />
                                      <label className="font-size-14 font-wt-600">Reject Buyer</label>
                                    </div>
                                  </div>
                                </div>
                                <ExpandableTable
                                  tableFixed data={allApplicationTableData}
                                  columns={[
                                    { name: "Buyer Name", filter: false, width: '9%' },
                                    { name: "DUNS No.", filter: false, width: '9%' },
                                    { name: "Country", filter: false, width: '9%' },
                                    { name: "Industry Type", filter: false, width: '9%' },
                                    { name: "Limit Required", filter: false, width: '9%' },
                                    { name: "Terms of Payment", filter: false, width: '9%' },
                                    { name: "Projected T/O", filter: false, width: '9%' },
                                    { name: "Expected Shipment Date", filter: false, width: '9%' },
                                    { name: "Action", width: '9%' },
                                    { name: " ", width: '11%' }
                                  ]}
                                  expand={expandData}
                                  tableExpand={openApplication}
                                  expandKey={"createdAt"}
                                  disableAction={true}
                                >
                                  {expandData.length === 1 ?
                                    <div className="row p-0 m-0">
                                      <NewTablev2 columns={[{ subColumns: 'Document Name' }, { subColumns: 'Amount' }, { subColumns: 'Attach Document' },
                                      ]}>
                                        {multInvPo.map((i, j) => {
                                          return <>
                                            {i.invoices?.map((o, p) => {
                                              return (
                                                <tr>
                                                  <td>{`Invoice - ${data["invoiceRefrenceNo" + j + ":" + p]}`}</td>
                                                  <td>{`${data["contractAmount" + j + ":" + p]} ${data["currency" + j + ":" + p]}`} </td>
                                                  <td><div>
                                                    <FileInput hideViewDowdBtns={false} isEditable={false}
                                                      name={"invoiceDocument" + j + ":" + p} value={data["invoiceDocument" + j + ":" + p]}
                                                      onChange={handleFile}
                                                      onUploadCancel={() => setData({ ...data, ["invoiceDocument" + j + ":" + p]: null })}
                                                    />
                                                    {errors["invoiceDocument" + j + ":" + p] ? <div class="text-danger mt-2 font-size-12">
                                                      <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                                      <b>{errors["invoiceDocument" + j + ":" + p]}</b></div> : ''}
                                                  </div></td>
                                                </tr>
                                              )
                                            })}
                                            {
                                              i.purchase_orders?.map((o, p) => {
                                                return (
                                                  <tr>
                                                    <td>{`Purchase Order - ${data["poRefrenceNo" + j + ":" + p]}`}</td>
                                                    <td>{`${data["poAmount" + j + ":" + p]} ${data["currency" + j + ":" + p]}`}</td>
                                                    <td>
                                                      <div>
                                                        <FileInput hideViewDowdBtns={false}
                                                          name={"poDocument" + j + ":" + p} value={data["poDocument" + j + ":" + p]} error={errors["poDocument" + j + ":" + p]}
                                                          isEditable={false}
                                                          onUploadCancel={() => setData({ ...data, ["poDocument" + j + ":" + p]: null })}
                                                        />
                                                        {errors["poDocument" + j + ":" + p] ? <div class="text-danger mt-2 font-size-12">
                                                          <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                                          <b>{errors["poDocument" + j + ":" + p]}</b></div> : ''}
                                                      </div>
                                                    </td>
                                                  </tr>
                                                )
                                              })}
                                            {data.logisticDocument?.name ?
                                              <tr>
                                                <td>Bill of Lading/Airway Bill</td>
                                                <td>-</td>
                                                <td><FileInput name={"logisticDocument"} value={data.logisticDocument} error={errors.logisticDocument} handleValidation
                                                  isEditable={false}
                                                /></td>
                                              </tr>
                                              : null}
                                            {data.invReportDocument?.name ?
                                              <tr>
                                                <td>Investigation Report</td>
                                                <td>-</td>
                                                <td><FileInput name={"invReportDocument"} value={data.invReportDocument} error={errors.invReportDocument} handleValidation
                                                  isEditable={false}
                                                /></td>
                                              </tr>
                                              : null}
                                            {data.grnDocument?.name ?
                                              <tr>
                                                <td>Certificate of Origin</td>
                                                <td>-</td>
                                                <td><FileInput name={"grnDocument"} value={data.grnDocument} error={errors.grnDocument} handleValidation
                                                  isEditable={false}
                                                /></td>
                                              </tr>
                                              : null}
                                            {data.shippingBillDocument?.name ?
                                              <tr>
                                                <td>Shipping Bill</td>
                                                <td>-</td>
                                                <td><FileInput name={"shippingBillDocument"} value={data.shippingBillDocument} error={errors.shippingBillDocument} handleValidation
                                                  isEditable={false}
                                                /></td>
                                              </tr>
                                              : null}
                                            {data.packingListDocument?.name ?
                                              <tr>
                                                <td>Packing List</td>
                                                <td>-</td>
                                                <td><FileInput name={"packingListDocument"} value={data.packingListDocument} error={errors.packingListDocument} handleValidation
                                                  isEditable={false}
                                                /></td>
                                              </tr>
                                              : null}
                                            {data.salesContractDocument?.name ?
                                              <tr>
                                                <td>Sales Contract</td>
                                                <td>-</td>
                                                <td><FileInput name={"salesContractDocument"} value={data.salesContractDocument} error={errors.salesContractDocument} handleValidation
                                                  isEditable={false}
                                                /></td>
                                              </tr>
                                              : null}
                                          </>
                                        })}
                                      </NewTablev2>
                                      <div className="mt-4">
                                        <NewTablev2 columns={[{ subColumns: "Yes / No Questions", subColumnStyle: { width: '80%' } }, { subColumns: 'YES', subColumnStyle: { width: '10%' } },
                                        { subColumns: 'NO', subColumnStyle: { width: '10%' } }]}>
                                          {Object.keys(yesnoqsts).map((i, j) => {
                                            return (
                                              <tr>
                                                <td>{yesnoqsts[i]["label"]}</td>
                                                <td>
                                                  <img
                                                    className='cursor mr-3' src={`assets/images/${data[i] === true ? 'checked-green' : 'empty-check'}.png`} />
                                                </td>
                                                <td>
                                                  <img
                                                    className='cursor mr-3' src={`assets/images/${data[i] === false ? 'checked-green' : 'empty-check'}.png`} />
                                                </td>
                                              </tr>
                                            )
                                          })}
                                        </NewTablev2>
                                      </div>
                                    </div>
                                    : null}
                                </ExpandableTable>
                              </div>
                            )}

                            {applicationType / 1 == 0 ? (
                              <>
                                <div className='w-100 mt-5'>
                                  <label className='font-wt-500 font-size-16'><u>Invoice & PO Details</u>
                                    <span className="text-blue w-40 cursor mt-2 font-wt-600 font-size-14 ml-3" onClick={() => handleApplication(0, null, true)}>Request more documents  <img src={"assets/images/right-arrow.png"} alt="Back" height={15} width={15} />
                                    </span></label>
                                  <div className='row mt-2'>
                                    <div className="my-4">
                                      <NewTablev2 columns={[{ subColumns: 'Document Name' }, { subColumns: 'Amount' }, { subColumns: 'Attach Document' },
                                      ]}>
                                        {multInvPo.map((i, j) => {
                                          return <>
                                            {i.invoices?.map((o, p) => {
                                              return (
                                                <tr>
                                                  <td>{`Invoice - ${data["invoiceRefrenceNo" + j + ":" + p]}`}</td>
                                                  <td>{`${data["contractAmount" + j + ":" + p]} ${data["currency" + j + ":" + p]}`} </td>
                                                  <td><div>
                                                    <FileInput hideViewDowdBtns={false} isEditable={false}
                                                      name={"invoiceDocument" + j + ":" + p} value={data["invoiceDocument" + j + ":" + p]}
                                                      onChange={handleFile}
                                                      onUploadCancel={() => setData({ ...data, ["invoiceDocument" + j + ":" + p]: null })}
                                                    />
                                                    {errors["invoiceDocument" + j + ":" + p] ? <div class="text-danger mt-2 font-size-12">
                                                      <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                                      <b>{errors["invoiceDocument" + j + ":" + p]}</b></div> : ''}
                                                  </div></td>
                                                </tr>
                                              )
                                            })}
                                            {
                                              i.purchase_orders?.map((o, p) => {
                                                return (
                                                  <tr>
                                                    <td>{`Purchase Order - ${data["poRefrenceNo" + j + ":" + p]}`}</td>
                                                    <td>{`${data["poAmount" + j + ":" + p]} ${data["currency" + j + ":" + p]}`}</td>
                                                    <td>
                                                      <div>
                                                        <FileInput hideViewDowdBtns={false}
                                                          name={"poDocument" + j + ":" + p} value={data["poDocument" + j + ":" + p]} error={errors["poDocument" + j + ":" + p]}
                                                          isEditable={false}
                                                          onUploadCancel={() => setData({ ...data, ["poDocument" + j + ":" + p]: null })}
                                                        />
                                                        {errors["poDocument" + j + ":" + p] ? <div class="text-danger mt-2 font-size-12">
                                                          <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                                          <b>{errors["poDocument" + j + ":" + p]}</b></div> : ''}
                                                      </div>
                                                    </td>
                                                  </tr>
                                                )
                                              })}
                                          </>
                                        })}
                                      </NewTablev2>
                                    </div>
                                    <label className='font-wt-500 font-size-16'><u>Documents</u><span className="text-blue w-40 cursor mt-2 font-wt-600 font-size-14 ml-3" onClick={() => handleApplication(0, null, true)}>Request more documents  <img src={"assets/images/right-arrow.png"} alt="Back" height={15} width={15} />
                                    </span></label>
                                    {data.logisticDocument && data.logisticDocument.name &&
                                      <div className="col-md-4">
                                        <label className="font-size-14">Bill of Lading/Airway Bill</label>
                                        <div className="row form-group">
                                          <div className="col-md-12">
                                            <FileInput name={"logisticDocument"} value={data.logisticDocument} error={errors.logisticDocument} handleValidation
                                              isEditable={false}
                                            />
                                            <div
                                              onClick={() => toggleAmmendPopup({ show: true, key: "logisticDocument" })}
                                              className="cursor ammendCommentIconForFile"
                                            >
                                              <img className="genericImgIcon" src={ammendFields["logisticDocument"] ? "assets/images/comment_filled.png" : "assets/images/chat.png"} alt="" />
                                            </div>
                                            {ammendPopup && ammendPopup.show && ammendPopup.key === "logisticDocument" ? (
                                              <div className="ammendPopup">
                                                <button
                                                  type="button" className="btn-close ammendCommentCrossIcon" onClick={() => toggleAmmendPopup({ show: false, key: null })}></button>
                                                <div className="text-center">
                                                  <NewTextArea
                                                    rows={6}
                                                    type={"text"} label={`Comment`} name={ammendPopup.key}
                                                    value={ammendFields[ammendPopup.key]}
                                                    onChange={(e) => {
                                                      setAmmendFields({ ...ammendFields, [ammendPopup.key]: e.target.value || undefined })
                                                    }}
                                                  />
                                                  <button type="button"
                                                    onClick={async () => {
                                                      setshowLoader(true)
                                                      await call("POST", "raiseInvoiceAmendment", {
                                                        commentObj: { ["logisticDocument"]: ammendFields[ammendPopup.key] }, applicationId: quotesDetail.limit_id,
                                                        userId, userName, buyerId: quotesDetail.buyerId, buyerName: quotesDetail.buyerName,
                                                        sellerId: quotesDetail.created_by
                                                      })
                                                      setshowLoader(false)
                                                      toggleAmmendPopup({ show: false, key: null })
                                                      toastDisplay('Request forwarded', "success")
                                                    }}
                                                    style={{ bottom: '2rem', right: '1rem' }}
                                                    className={`new-btn py-2 px-3 text-white position-absolute`}>
                                                    {"Send"}
                                                  </button>
                                                </div>
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>}
                                    {data.invReportDocument && data.invReportDocument.name &&
                                      <div className="col-md-4">
                                        <label className="font-size-14">Investigation Report</label>
                                        <div className="row form-group">
                                          <div className="col-md-12">
                                            <FileInput name={"invReportDocument"} value={data.invReportDocument} error={errors.invReportDocument} handleValidation
                                              isEditable={false}
                                            />
                                            <div
                                              onClick={() => toggleAmmendPopup({ show: true, key: "invReportDocument" })}
                                              className="cursor ammendCommentIconForFile"
                                            >
                                              <img className="genericImgIcon" src={ammendFields["invReportDocument"] ? "assets/images/comment_filled.png" : "assets/images/chat.png"} alt="" />
                                            </div>
                                            {ammendPopup && ammendPopup.show && ammendPopup.key === "invReportDocument" ? (
                                              <div className="ammendPopup">
                                                <button
                                                  type="button" className="btn-close ammendCommentCrossIcon" onClick={() => toggleAmmendPopup({ show: false, key: null })}></button>
                                                <div className="text-center">
                                                  <NewTextArea
                                                    rows={6}
                                                    type={"text"} label={`Comment`} name={ammendPopup.key}
                                                    value={ammendFields[ammendPopup.key]}
                                                    onChange={(e) => {
                                                      setAmmendFields({ ...ammendFields, [ammendPopup.key]: e.target.value || undefined })
                                                    }}
                                                  />
                                                  <button type="button"
                                                    onClick={async () => {
                                                      setshowLoader(true)
                                                      await call("POST", "raiseInvoiceAmendment", {
                                                        commentObj: { ["invReportDocument"]: ammendFields[ammendPopup.key] }, applicationId: quotesDetail.limit_id,
                                                        userId, userName, buyerId: quotesDetail.buyerId, buyerName: quotesDetail.buyerName,
                                                        sellerId: quotesDetail.created_by
                                                      })
                                                      setshowLoader(false)
                                                      toggleAmmendPopup({ show: false, key: null })
                                                      toastDisplay('Request forwarded', "success")
                                                    }}
                                                    style={{ bottom: '2rem', right: '1rem' }}
                                                    className={`new-btn py-2 px-3 text-white position-absolute`}>
                                                    {"Send"}
                                                  </button>
                                                </div>
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>}
                                    {data.grnDocument && data.grnDocument.name &&
                                      <div className="col-md-4">
                                        <label className="font-size-14">Certificate of Origin</label>
                                        <div className="row form-group">
                                          <div className="col-md-12">
                                            <FileInput name={"grnDocument"} value={data.grnDocument} error={errors.grnDocument} handleValidation
                                              isEditable={false}
                                            />
                                            <div
                                              onClick={() => toggleAmmendPopup({ show: true, key: "grnDocument" })}
                                              className="cursor ammendCommentIconForFile"
                                            >
                                              <img className="genericImgIcon" src={ammendFields["grnDocument"] ? "assets/images/comment_filled.png" : "assets/images/chat.png"} alt="" />
                                            </div>
                                            {ammendPopup && ammendPopup.show && ammendPopup.key === "grnDocument" ? (
                                              <div className="ammendPopup">
                                                <button
                                                  type="button" className="btn-close ammendCommentCrossIcon" onClick={() => toggleAmmendPopup({ show: false, key: null })}></button>
                                                <div className="text-center">
                                                  <NewTextArea
                                                    rows={6}
                                                    type={"text"} label={`Comment`} name={ammendPopup.key}
                                                    value={ammendFields[ammendPopup.key]}
                                                    onChange={(e) => {
                                                      setAmmendFields({ ...ammendFields, [ammendPopup.key]: e.target.value || undefined })
                                                    }}
                                                  />
                                                  <button type="button"
                                                    onClick={async () => {
                                                      setshowLoader(true)
                                                      await call("POST", "raiseInvoiceAmendment", {
                                                        commentObj: { ["grnDocument"]: ammendFields[ammendPopup.key] }, applicationId: quotesDetail.limit_id,
                                                        userId, userName, buyerId: quotesDetail.buyerId, buyerName: quotesDetail.buyerName,
                                                        sellerId: quotesDetail.created_by
                                                      })
                                                      setshowLoader(false)
                                                      toggleAmmendPopup({ show: false, key: null })
                                                      toastDisplay('Request forwarded', "success")
                                                    }}
                                                    style={{ bottom: '2rem', right: '1rem' }}
                                                    className={`new-btn py-2 px-3 text-white position-absolute`}>
                                                    {"Send"}
                                                  </button>
                                                </div>
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>}
                                    {data.shippingBillDocument && data.shippingBillDocument.name &&
                                      <div className="col-md-4">
                                        <label className="font-size-14">Shipping Bill</label>
                                        <div className="row form-group">
                                          <div className="col-md-12">
                                            <FileInput name={"shippingBillDocument"} value={data.shippingBillDocument} error={errors.shippingBillDocument} handleValidation
                                              isEditable={false}
                                            />
                                            <div
                                              onClick={() => toggleAmmendPopup({ show: true, key: "shippingBillDocument" })}
                                              className="cursor ammendCommentIconForFile"
                                            >
                                              <img className="genericImgIcon" src={ammendFields["shippingBillDocument"] ? "assets/images/comment_filled.png" : "assets/images/chat.png"} alt="" />
                                            </div>
                                            {ammendPopup && ammendPopup.show && ammendPopup.key === "shippingBillDocument" ? (
                                              <div className="ammendPopup">
                                                <button
                                                  type="button" className="btn-close ammendCommentCrossIcon" onClick={() => toggleAmmendPopup({ show: false, key: null })}></button>
                                                <div className="text-center">
                                                  <NewTextArea
                                                    rows={6}
                                                    type={"text"} label={`Comment`} name={ammendPopup.key}
                                                    value={ammendFields[ammendPopup.key]}
                                                    onChange={(e) => {
                                                      setAmmendFields({ ...ammendFields, [ammendPopup.key]: e.target.value || undefined })
                                                    }}
                                                  />
                                                  <button type="button"
                                                    onClick={async () => {
                                                      setshowLoader(true)
                                                      await call("POST", "raiseInvoiceAmendment", {
                                                        commentObj: { ["shippingBillDocument"]: ammendFields[ammendPopup.key] }, applicationId: quotesDetail.limit_id,
                                                        userId, userName, buyerId: quotesDetail.buyerId, buyerName: quotesDetail.buyerName,
                                                        sellerId: quotesDetail.created_by
                                                      })
                                                      setshowLoader(false)
                                                      toggleAmmendPopup({ show: false, key: null })
                                                      toastDisplay('Request forwarded', "success")
                                                    }}
                                                    style={{ bottom: '2rem', right: '1rem' }}
                                                    className={`new-btn py-2 px-3 text-white position-absolute`}>
                                                    {"Send"}
                                                  </button>
                                                </div>
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>}
                                    {data.packingListDocument && data.packingListDocument.name &&
                                      <div className="col-md-4">
                                        <label className="font-size-14">Packing List</label>
                                        <div className="row form-group">
                                          <div className="col-md-12">
                                            <FileInput name={"packingListDocument"} value={data.packingListDocument} error={errors.packingListDocument} handleValidation
                                              isEditable={false}
                                            />
                                            <div
                                              onClick={() => toggleAmmendPopup({ show: true, key: "packingListDocument" })}
                                              className="cursor ammendCommentIconForFile"
                                            >
                                              <img className="genericImgIcon" src={ammendFields["packingListDocument"] ? "assets/images/comment_filled.png" : "assets/images/chat.png"} alt="" />
                                            </div>
                                            {ammendPopup && ammendPopup.show && ammendPopup.key === "packingListDocument" ? (
                                              <div className="ammendPopup">
                                                <button
                                                  type="button" className="btn-close ammendCommentCrossIcon" onClick={() => toggleAmmendPopup({ show: false, key: null })}></button>
                                                <div className="text-center">
                                                  <NewTextArea
                                                    rows={6}
                                                    type={"text"} label={`Comment`} name={ammendPopup.key}
                                                    value={ammendFields[ammendPopup.key]}
                                                    onChange={(e) => {
                                                      setAmmendFields({ ...ammendFields, [ammendPopup.key]: e.target.value || undefined })
                                                    }}
                                                  />
                                                  <button type="button"
                                                    onClick={async () => {
                                                      setshowLoader(true)
                                                      await call("POST", "raiseInvoiceAmendment", {
                                                        commentObj: { ["shippingBillDocument"]: ammendFields[ammendPopup.key] }, applicationId: quotesDetail.limit_id,
                                                        userId, userName, buyerId: quotesDetail.buyerId, buyerName: quotesDetail.buyerName,
                                                        sellerId: quotesDetail.created_by
                                                      })
                                                      setshowLoader(false)
                                                      toggleAmmendPopup({ show: false, key: null })
                                                      toastDisplay('Request forwarded', "success")
                                                    }}
                                                    style={{ bottom: '2rem', right: '1rem' }}
                                                    className={`new-btn py-2 px-3 text-white position-absolute`}>
                                                    {"Send"}
                                                  </button>
                                                </div>
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>}
                                    {data.salesContractDocument && data.salesContractDocument.name &&
                                      <div className="col-md-4">
                                        <label className="font-size-14">Sales Contract</label>
                                        <div className="row form-group">
                                          <div className="col-md-12">
                                            <FileInput name={"salesContractDocument"} value={data.salesContractDocument} error={errors.salesContractDocument} handleValidation
                                              isEditable={false}
                                            />
                                            <div
                                              onClick={() => toggleAmmendPopup({ show: true, key: "salesContractDocument" })}
                                              className="cursor ammendCommentIconForFile"
                                            >
                                              <img className="genericImgIcon" src={ammendFields["salesContractDocument"] ? "assets/images/comment_filled.png" : "assets/images/chat.png"} alt="" />
                                            </div>
                                            {ammendPopup && ammendPopup.show && ammendPopup.key === "salesContractDocument" ? (
                                              <div className="ammendPopup">
                                                <button
                                                  type="button" className="btn-close ammendCommentCrossIcon" onClick={() => toggleAmmendPopup({ show: false, key: null })}></button>
                                                <div className="text-center">
                                                  <NewTextArea
                                                    rows={6}
                                                    type={"text"} label={`Comment`} name={ammendPopup.key}
                                                    value={ammendFields[ammendPopup.key]}
                                                    onChange={(e) => {
                                                      setAmmendFields({ ...ammendFields, [ammendPopup.key]: e.target.value || undefined })
                                                    }}
                                                  />
                                                  <button type="button"
                                                    onClick={async () => {
                                                      setshowLoader(true)
                                                      await call("POST", "raiseInvoiceAmendment", {
                                                        commentObj: { ["shippingBillDocument"]: ammendFields[ammendPopup.key] }, applicationId: quotesDetail.limit_id,
                                                        userId, userName, buyerId: quotesDetail.buyerId, buyerName: quotesDetail.buyerName,
                                                        sellerId: quotesDetail.created_by
                                                      })
                                                      setshowLoader(false)
                                                      toggleAmmendPopup({ show: false, key: null })
                                                      toastDisplay('Request forwarded', "success")
                                                    }}
                                                    style={{ bottom: '2rem', right: '1rem' }}
                                                    className={`new-btn py-2 px-3 text-white position-absolute`}>
                                                    {"Send"}
                                                  </button>
                                                </div>
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>}
                                    {/* {moreDocs?.length ? moreDocs.map((item, index) => {
                              if (item?.name) {
                                return (
                                  <div className="col-md-4">
                                    <label className="font-size-14">{item.doc_name}</label>
                                    <div className="row form-group">
                                      <div className="col-md-12">
                                        <FileInput value={item} handleValidation
                                          isEditable={false}
                                        />
                                        <div
                                          onClick={() => toggleAmmendPopup({ show: true, key: "addMore" + index })}
                                          className="cursor ammendCommentIconForFile"
                                        >
                                          <img className="genericImgIcon" src={ammendFields["addMore" + index] ? "assets/images/comment_filled.png" : "assets/images/chat.png"} alt="" />
                                        </div>
                                        {ammendPopup && ammendPopup.show && ammendPopup.key === "addMore" + index ? (
                                          <div className="ammendPopup">
                                            <button
                                              type="button" className="btn-close ammendCommentCrossIcon" onClick={() => toggleAmmendPopup({ show: false, key: null })}></button>
                                            <div className="text-center">
                                              <NewTextArea
                                                rows={6}
                                                type={"text"} label={`Comment`} name={ammendPopup.key}
                                                value={ammendFields[ammendPopup.key]}
                                                onChange={(e) => {
                                                  setAmmendFields({ ...ammendFields, [ammendPopup.key]: e.target.value || undefined })
                                                }}
                                              />
                                              <button type="button"
                                                onClick={async () => {
                                                  setshowLoader(true)
                                                  await call("POST", "raiseInvoiceAmendment", {
                                                    commentObj: { ["addMore" + index]: ammendFields[ammendPopup.key] }, applicationId: quotesDetail.limit_id,
                                                    userId, userName, buyerId: quotesDetail.buyerId, buyerName: quotesDetail.buyerName,
                                                    sellerId: quotesDetail.created_by
                                                  })
                                                  setshowLoader(false)
                                                  toggleAmmendPopup({ show: false, key: null })
                                                  toastDisplay('Request forwarded', "success")
                                                }}
                                                style={{ bottom: '2rem', right: '1rem' }}
                                                className={`new-btn py-2 px-3 text-white position-absolute`}>
                                                {"Send"}
                                              </button>
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                )
                              }
                            }) : null} */}
                                    {data?.additionalDocuments?.length ? data?.additionalDocuments.map((item, index) => {
                                      if (data?.[item.doc_name]?.name) {
                                        return (
                                          <div className="col-md-4">
                                            <label className="font-size-14">{item.doc_name}</label>
                                            <div className="row form-group">
                                              <div className="col-md-12">
                                                <FileInput value={data[item.doc_name]}
                                                  isEditable={false}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      }
                                    }) : null}
                                  </div>
                                </div>

                              </>
                            ) : null}

                            {applicationType / 1 == 0 ? <div className="mt-4">
                              <NewTablev2 columns={[{ subColumns: "Yes / No Questions", subColumnStyle: { width: '80%' } }, { subColumns: 'YES', subColumnStyle: { width: '10%' } },
                              { subColumns: 'NO', subColumnStyle: { width: '10%' } }]}>
                                {Object.keys(yesnoqsts).map((i, j) => {
                                  return (
                                    <tr>
                                      <td>{yesnoqsts[i]["label"]}</td>
                                      <td>
                                        <img
                                          className='cursor mr-3' src={`assets/images/${data[i] === true ? 'checked-green' : 'empty-check'}.png`} />
                                      </td>
                                      <td>
                                        <img
                                          className='cursor mr-3' src={`assets/images/${data[i] === false ? 'checked-green' : 'empty-check'}.png`} />
                                      </td>
                                    </tr>
                                  )
                                })}
                              </NewTablev2>
                            </div> : null}

                            {gstReturns.length ? (
                              <>
                                <label className="font-size-14 font-wt-500 text-color-value col-10 mt-5 mb-4"><u>GST</u><span className="text-blue w-40 cursor mt-2 font-wt-600 font-size-14 ml-3" onClick={() => handleApplication(0, null, true)}>Request more documents  <img src={"assets/images/right-arrow.png"} alt="Back" height={15} width={15} />
                                </span></label>
                                <NewTablev2 columns={[{ subColumns: 'Document Name' }, { subColumns: 'Year' }, { subColumns: 'Attach Document' },
                                ]} >
                                  {gstReturns.map((i, j) => {
                                    if (data[`gstDoc:${j}`]?.name) {
                                      return <tr>
                                        <td>{`GST Return`}</td>
                                        <td>{`${data[`gstDoc:${j}Year`]}`}</td>
                                        <td>
                                          <FileInput value={data[`gstDoc:${j}`]}
                                            isEditable={false}
                                          />
                                        </td>
                                      </tr>
                                    }
                                  })}
                                </NewTablev2>
                              </>
                            ) : null}

                            {(bankDocs1.length || bankDocs2.length || bankDocs3.length) ? (
                              <>
                                <label className="font-size-14 font-wt-500 text-color-value col-10 mt-5 mb-4"><u>Bank</u><span className="text-blue w-40 cursor mt-2 font-wt-600 font-size-14 ml-3" onClick={() => handleApplication(0, null, true)}>Request more documents  <img src={"assets/images/right-arrow.png"} alt="Back" height={15} width={15} />
                                </span></label>
                                <NewTablev2 columns={[{ subColumns: 'Document Name' }, { subColumns: 'Year' }, { subColumns: 'Attach Document' },
                                ]} >
                                  {bankDocs1.map((i, j) => {
                                    if (data[`bankDoc1:${j}`]?.name) {
                                      return <tr>
                                        <td>{`Bank statement of all working capital & Current accounts (last 12 months)`}</td>
                                        <td>-</td>
                                        <td>
                                          <FileInput value={data[`bankDoc1:${j}`]}
                                            isEditable={false}
                                          /></td>
                                      </tr>
                                    }
                                  })}
                                  {bankDocs2.map((i, j) => {
                                    if (data[`bankDoc2:${j}`]?.name) {
                                      return <tr>
                                        <td>{`Limit details & sanctioned letter of existing banking lines`}</td>
                                        <td>-</td>
                                        <td>
                                          <FileInput value={data[`bankDoc2:${j}`]}
                                            isEditable={false}
                                          /></td>
                                      </tr>
                                    }
                                  })}
                                  {bankDocs3.map((i, j) => {
                                    if (data[`bankDoc3:${j}`]?.name) {
                                      return <tr>
                                        <td>{`${data[`bankDoc3:${j}`]?.doc_name}`}</td>
                                        <td>-</td>
                                        <td>
                                          <FileInput value={data[`bankDoc3:${j}`]}
                                            isEditable={false}
                                          /></td>
                                      </tr>
                                    }
                                  })}
                                </NewTablev2>
                              </>
                            ) : null}

                            {(financialDoc1.length || financialDoc2.length || financialDoc3.length || financialDoc4.length) ? (
                              <>
                                <label className="font-size-14 font-wt-500 text-color-value col-10 mt-5 mb-4"><u>Financial</u><span className="text-blue w-40 cursor mt-2 font-wt-600 font-size-14 ml-3" onClick={() => handleApplication(0, null, true)}>Request more documents  <img src={"assets/images/right-arrow.png"} alt="Back" height={15} width={15} />
                                </span></label>
                                <NewTablev2 columns={[{ subColumns: 'Document Name' }, { subColumns: 'Year' }, { subColumns: 'Attach Document' },
                                ]}>
                                  {financialDoc1.map((i, j) => {
                                    if (data[`finDoc1:${j}`]?.name) {
                                      return <tr>
                                        <td>{`Audited financials including ITR`}</td>
                                        <td>{data[`finDoc1:${j}Year`]}</td>
                                        <td><FileInput value={data[`finDoc1:${j}`]}
                                          isEditable={false}
                                        /></td>
                                      </tr>
                                    }
                                  })}
                                  {financialDoc2.map((i, j) => {
                                    if (data[`finDoc2:${j}`]?.name) {
                                      return <tr>
                                        <td>{`Ledger with all the buyer in the term sheet (last 12 months)`}</td>
                                        <td>-</td>
                                        <td><FileInput value={data[`finDoc2:${j}`]}
                                          isEditable={false}
                                        /></td>
                                      </tr>
                                    }
                                  })}
                                  {financialDoc3.map((i, j) => {
                                    if (data[`finDoc3:${j}`]?.name) {
                                      return <tr>
                                        <td>{`Stock statement shared with banks ageing breakup of debtor /creditors (last 2 months)`}</td>
                                        <td>-</td>
                                        <td><FileInput value={data[`finDoc3:${j}`]}
                                          isEditable={false}
                                        /></td>
                                      </tr>
                                    }
                                  })}
                                  {financialDoc4.map((i, j) => {
                                    if (data[`finDoc4:${j}`]?.name) {
                                      return <tr>
                                        <td className="font-size-14">{`${data[`finDoc4:${j}`]?.doc_name}`}</td>
                                        <td>-</td>
                                        <td><FileInput value={data[`finDoc3:${j}`]}
                                          isEditable={false}
                                        /></td>
                                      </tr>
                                    }
                                  })}
                                </NewTablev2>
                              </>
                            ) : null}

                            {(orgDoc1.length || orgDoc2.length || orgDoc3.length) ? (
                              <>
                                <label className="font-size-14 font-wt-500 text-color-value col-10 mt-5 mb-4"><u>Organizational</u><span className="text-blue w-40 cursor mt-2 font-wt-600 font-size-14 ml-3" onClick={() => handleApplication(0, null, true)}>Request more documents  <img src={"assets/images/right-arrow.png"} alt="Back" height={15} width={15} />
                                </span></label>
                                <NewTablev2 columns={[{ subColumns: 'Document Name' }, { subColumns: 'Document No' }, { subColumns: 'Attach Document' }]}>
                                  {orgDoc1.map((i, j) => {
                                    if (data[`orgDoc1:${j}`]?.name) {
                                      return <tr>
                                        <td>{`Partnership deed (in case of partnership firm)`}</td>
                                        <td>-</td>
                                        <td><FileInput value={data[`orgDoc1:${j}`]}
                                          isEditable={false}
                                        /></td>
                                      </tr>
                                    }
                                  })}
                                  {orgDoc2.map((i, j) => {
                                    return (<>
                                      {data[`aadharDoc:${j}`]?.name ?
                                        <tr>
                                          <td>{`Aadhar (${data[`partnerName:${j}`]})`}</td>
                                          <td>{data[`aadharDoc:${j}DocNo`]}</td>
                                          <td><FileInput value={data[`aadharDoc:${j}`]}
                                            isEditable={false}
                                          /></td>
                                        </tr> : null}
                                      {data[`panDoc:${j}`]?.name ?
                                        <tr>
                                          <td>{`PAN (${data[`partnerName:${j}`]})`}</td>
                                          <td>{data[`panDoc:${j}DocNo`]}</td>
                                          <td><FileInput value={data[`panDoc:${j}`]}
                                            isEditable={false}
                                          /></td>
                                        </tr>
                                        : null}
                                    </>)
                                  })}
                                  {orgDoc3.map((i, j) => {
                                    if (data[`orgDoc3:${j}`]?.name) {
                                      return <tr>
                                        <td>{`${data[`orgDoc3:${j}`]?.doc_name}`}</td>
                                        <td>-</td>
                                        <td><FileInput value={data[`orgDoc3:${j}`]}
                                          isEditable={false}
                                        /></td>
                                      </tr>
                                    }
                                  })}
                                </NewTablev2>
                              </>
                            ) : null}
                          </div>
                        </div>
                      ) : null}



                    {
                      tab === 2 ?
                        <>
                          {quoteScreen.show ? null : (
                            <div
                              className="d-flex finActionTabContainer mx-5 mt-4 mb-5 p-0" >
                              <div
                                onClick={() => {
                                  // setQuoteForm({ modal: true, page: 1 })
                                  setFinancierActionTab(0)
                                  toggleQuoteScreen({ show: true })
                                }}
                                style={{ borderRight: '2px solid #5CB8D3' }}
                                className={`w-25 text-center py-2 cursor bg-5CB8D3 `} >
                                <label className={"font-size-16 font-wt-700 text-white cursor"} >Send Quote</label>
                              </div>
                              <div
                                onClick={() => {
                                  setFinancierActionTab(finActionTab == 1 ? 0 : 1)
                                }}
                                style={{ borderRight: '2px solid #5CB8D3' }}
                                className={`position-relative w-25 text-center py-2 px-2 cursor`} >
                                {finActionTab == 1 ? <div
                                  style={{
                                    width: '14rem',
                                    top: '-7rem',
                                    borderRadius: "10px"
                                  }}
                                  className="position-absolute bg-DEF7FF " >
                                  <label
                                    onClick={() => { handleApplication(0, "deny", null, "Application Rejected") }}
                                    className="font-size-14 font-wt-500 w-100 cursor text-left py-1 px-3 border-bottom-solid-dee2e6" >Reject this Application</label>
                                  <label
                                    onClick={() => { handleApplication(0, "deny", null, "Buyer Rejected") }}
                                    className="font-size-14 font-wt-500 w-100 cursor text-left py-1 px-3 border-bottom-solid-dee2e6">Reject this Buyer</label>
                                  <label
                                    onClick={() => { handleApplication(0, "deny", null, "Supplier Rejected") }}
                                    className="font-size-14 font-wt-500 w-100 cursor text-left py-1 px-3 border-bottom-solid-dee2e6">Reject this Supplier</label>
                                </div> : null}
                                <label className={"font-size-14 font-wt-500 text-dark cursor"} >Reject Application</label>
                                <img
                                  src="assets/images/arrow.png"
                                />
                              </div>
                              <div
                                onClick={() => handleApplication(0, null, true)}
                                style={{ borderRight: '2px solid #5CB8D3' }} className="w-25 text-center py-2">
                                <img className="mr-2"
                                  src="assets/images/chat_new_icon.svg"
                                />
                                <label className={"font-size-14 font-wt-500 text-dark cursor"} >Chat / Remarks</label>
                              </div>
                              <div
                                onClick={() => {
                                  setFinancierActionTab(finActionTab == 3 ? 0 : 3)
                                }}
                                className="w-25 text-center py-2 position-relative">
                                {finActionTab == 3 ? <div
                                  style={{
                                    width: '14rem',
                                    top: `${(SubUsers.length + 1) * -4}rem`,
                                    borderRadius: "10px"
                                  }}
                                  className="position-absolute " >
                                  {SubUsers.map((i, j) => {
                                    return (
                                      <div
                                        onClick={async () => {
                                          setshowLoader(true)
                                          await call('POST', 'sendSubUserApplication', {
                                            userName, subUserEmail: i.email_id,
                                            linkObj: {
                                              link: "finQuotesDetails", metadata: JSON.parse(localStorage.getItem("quotesDetail")),
                                              metadatakey: "quotesDetail"
                                            }
                                          })
                                          setshowLoader(false)
                                          setPopupMessage({ show: true, msg: `Application forwarded to ${i.contact_person}` })
                                        }}
                                        style={{ height: '5rem' }} className="d-flex row bg-white border-bottom-solid-dee2e6" >
                                        <label className="font-size-13 font-wt-600 w-100 cursor text-left" >{`${i.contact_person} - ${i.designation}`}</label>
                                        <label className="font-size-13 font-wt-600 w-100 cursor truncate text-left">{`${i.company_city} - ${i.company_state}`}</label>
                                      </div>
                                    )
                                  })}
                                  <div
                                    onClick={() => { window.open("/viewprofile", "_blank") }}
                                    style={{ height: '2rem' }} className="d-flex row bg-white border-bottom-solid-dee2e6 align-items-center" >
                                    <label
                                      style={{ color: 'rgb(92, 184, 211)' }} className="font-size-13 font-wt-600 w-100 cursor text-left m-0 " >{`Add New`}</label>
                                  </div>
                                </div> : null}
                                <label
                                  className={"font-size-14 font-wt-500 text-dark cursor"} >Assign to other user</label>
                                <img className="ml-2"
                                  src="assets/images/redirect_new_icon.png"
                                />
                              </div>
                            </div>
                          )}
                        </> : null
                    }


                    {tab === 0 && (
                      <div className="row pb-5 mx-4">
                        <button type="button"
                          onClick={() => window.location = `/supplierDetails?id=${quotesDetail.userId}`}
                          className={`mx-4 new-btn2 w-15 py-2 px-3 text-blue`}>
                          {"View Profile"}
                        </button>
                      </div>
                    )}

                    {quoteForm.modal && <>
                      <div className={`modal fade ${quoteForm.modal && "show"}`} style={{ display: "block" }}>
                        <div className="modal-dialog modal-lg">
                          <div className="modal-content submitmodal pb-4">
                            <div className="modal-header border-0">
                              <button type="button" className="btn-close" aria-label="Close" onClick={() => setQuoteForm({ modal: false, page: 0 })}></button>
                            </div>
                            {(quoteForm.modal && quoteForm.page === 1) ? <div className="modal-body text-center">
                              <p>Quote</p>
                              <div className="row">
                                <div className='w-100'>
                                  <div className='row'>
                                    <div className='col-md-5 move-left'>
                                      <InputWithSelect
                                        isAstrix={false} type={"number"} label={"Factoring Fees"}
                                        selectData={currencies}
                                        selectName={"factoringFeesUnit"} selectValue={data.factoringFeesUnit}
                                        optionValue={"code"} optionLabel={"code"}
                                        name={"factoringFees"} value={data.factoringFees} error={errors.factoringFees}
                                        onChange={handleChange} />
                                      <label
                                        onClick={() => {
                                          if (data.factoringFeesUnit === "%") {
                                            setData({ ...data, factoringFeesUnit: "USD" })
                                          }
                                          else {
                                            setData({ ...data, factoringFeesUnit: "%" })
                                          }
                                        }}
                                        className="inputCurrencyIcon font-size-14" >{data.factoringFeesUnit === "%" ? "%" : "$"}</label>
                                    </div>
                                    <div className='col-md-5 move-left'>
                                      <InputWithSelect
                                        isAstrix={false} type={"number"} label={"Interest Rate"}
                                        selectData={[{ name: "%" }]}
                                        selectName={"interestRateUnit"} selectValue={data.interestRateUnit || "%"}
                                        optionValue={"name"} optionLabel={"name"}
                                        name={"interestRate"} value={data.interestRate} error={errors.interestRate}
                                        onChange={handleChange} />
                                      <div className='mt-3 d-flex justify-content-between pr-0 h-1 align-items-center'>
                                        <img
                                          onClick={() => {
                                            setData({ ...data, termSofr: "Over Night" })
                                          }}
                                          className='cursor mr-2' src={`assets/images/${data.termSofr ? 'bullet_box' : 'empty_bullet_box'}.svg`} />
                                        <label className=" w-100 text-start ">Inclusive of TERM SOFR</label>
                                        {data.termSofr ? (
                                          <div className="w-90">
                                            <NewSelect
                                              selectData={[{ "label": "Over Night" }, { "label": "1 Month" }, { "label": "3 Months" }, { "label": "6 Months" }, { "label": "9 Months" }, { "label": "12 Months" }]}
                                              optionLabel={'label'} optionValue={'label'}
                                              name={"termSofr"} value={data.termSofr} onChange={handleChange} error={errors.termSofr}
                                            />
                                          </div>
                                        ) : null}
                                      </div>
                                      <div className='mb-3 d-flex justify-content-between pr-0 h-1 align-items-center'>
                                        <img
                                          onClick={() => {
                                            setData({ ...data, termSofr: null })
                                          }}
                                          className='cursor mr-2 ' src={`assets/images/${!data.termSofr ? 'bullet_box' : 'empty_bullet_box'}.svg`} />
                                        <label className="pt-2 w-100 text-start">Exclusive of TERM SOFR</label>
                                      </div>
                                    </div>
                                    <div className='col-md-5 move-left'>
                                      <InputWithSelect
                                        isAstrix={false} type={"number"} label={"Setup Fees"}
                                        selectData={currencies}
                                        selectName={"setupFeesUnit"} selectValue={data.setupFeesUnit}
                                        optionValue={"code"} optionLabel={"code"}
                                        name={"setupFees"} value={data.setupFees} error={errors.setupFees}
                                        onChange={handleChange} />
                                      <label
                                        onClick={() => {
                                          if (data.setupFeesUnit === "%") {
                                            setData({ ...data, setupFeesUnit: "USD" })
                                          }
                                          else {
                                            setData({ ...data, setupFeesUnit: "%" })
                                          }
                                        }}
                                        className="inputCurrencyIcon font-size-14" >{data.setupFeesUnit === "%" ? "%" : "$"}</label>
                                    </div>
                                    <div className='col-md-5 move-left'>
                                      <NewInput minDate={moment().format("YYYY-MM-DD")} isAstrix={true} type={"date"} label={"Offer Validity"}
                                        name={"offerValidity"} value={data.offerValidity} error={errors.offerValidity}
                                        onChange={handleChange}
                                      />
                                    </div>
                                    <div className='col-md-5 move-left'>
                                      <InputWithSelect
                                        isAstrix={false} type={"number"} label={"Finance Limit"}
                                        selectData={most_used_currencies}
                                        selectName={"financeLimitUnit"} selectValue={data.financeLimitUnit}
                                        optionValue={"code"} optionLabel={"code"}
                                        name={"financeLimit"} value={data.financeLimit} error={errors.financeLimit}
                                        onChange={handleChange} />
                                    </div>
                                    <div className='col-md-5 move-left'>
                                      <InputWithSelect
                                        isAstrix={false} type={"number"} label={"Discounting Percentage(%)"}
                                        selectData={[{ name: "%" }]}
                                        selectName={"discountingPercentageUnit"} selectValue={data.discountingPercentageUnit || "%"}
                                        optionValue={"name"} optionLabel={"name"}
                                        name={"discountingPercentage"} value={data.discountingPercentage} error={errors.discountingPercentage}
                                        onChange={handleChange} />
                                    </div>
                                    {data.otherCharges && data.otherCharges.map((item) => {
                                      return (
                                        <div className='col-md-5 move-left'>
                                          <InputWithSelect
                                            isAstrix={false} type={"number"} label={item.name}
                                            selectData={[{ name: item.unit }]}
                                            selectName={"otherChargeCurrency"} selectValue={item.unit}
                                            optionValue={"name"} optionLabel={"name"}
                                            name={"otherChargesAmount"} value={item.amount} error={errors.otherChargesAmount}
                                            onChange={handleChange} />
                                        </div>
                                      )
                                    })}
                                    <div className="col-md-8 move-left">
                                      <p className="text-left ml-2 my-1">Termsheet (Optional)</p>
                                      <div className="position-relative" >
                                        {data.attachLinkForTermSheet ? (
                                          <NewInput label={"Add link for signature (If any)"} name={"termSheetLink"} value={data.termSheetLink} onChange={handleChange} />
                                        ) : (
                                          <FileInput name={"termSheet"} value={data.termSheet} error={errors.termSheet}
                                            onChange={handleFile} isEditable={true} userTokenDetails={userTokenDetails}
                                            onUploadCancel={() => {
                                              setData({
                                                ...data,
                                                termSheet: null
                                              })
                                            }} />
                                        )}
                                      </div>
                                      <img
                                        onClick={() => {
                                          setData({ ...data, attachLinkForTermSheet: !data.attachLinkForTermSheet })
                                        }}
                                        className="cursor"
                                        src={data.attachLinkForTermSheet ? "assets/images/cross.png" : "assets/images/attachLinkIcon.svg"}
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                          position: "absolute",
                                          top: "2.5rem",
                                          right: "-5rem"
                                        }}
                                      />
                                    </div>
                                    <div className="col-md-12 move-left">
                                      <p className="text-left ml-2" onClick={() => setQuoteForm({ modal: true, page: 2 })}>+ Add other charges</p>
                                    </div>
                                    <div className="col-md-12 move-left">
                                      <p className="text-left ml-2" onClick={() => setQuoteForm({ modal: true, page: 3 })}>+ Add remark</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <button type="button" className={`new-btn w-20 py-2 px-2 text-white`} onClick={() => preHandleSubmit('quote')} >Send Quote</button>
                            </div> :
                              (quoteForm.modal && quoteForm.page === 2) ? <>
                                <a className="cursor" onClick={() => setQuoteForm({ modal: true, page: 1 })} ><img className="mb-3 back-btn" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
                                <div className="modal-body text-center">
                                  <p>Add Other Charges</p>
                                  <div className="row">
                                    <div className='w-100'>
                                      <div className='row'>
                                        {data.otherCharges && data.otherCharges.map((item, key) => {
                                          return (
                                            <>
                                              <div className='col-md-6 move-m'>
                                                <InputWithSelect
                                                  isAstrix={false} type={"number"} label={item.name}
                                                  selectData={[{ name: item.unit }]}
                                                  selectName={"otherChargesAmountUnit"} selectValue={item.unit}
                                                  optionValue={"name"} optionLabel={"name"}
                                                  name={"otherChargesAmount"} value={item.amount} error={errors.otherChargesAmount}
                                                  onChange={handleChange} />
                                              </div>
                                              <div className='col-md-3 move-l'>
                                                <img src={"/assets/images/edit.png"} alt="" className="mb-3 mt-2" onClick={() => onEdit(item, key)} />
                                                <img src={"/assets/images/cancel-icon.png"} alt="" className="mb-3 px-3 mt-2" onClick={() => onDelete(key)} />
                                              </div>
                                            </>
                                          )
                                        })}
                                      </div>
                                      <div className='row'>
                                        <div className='col-md-3 move-m'>
                                          <NewSelect isDisabled={data.otherChargePercentage ? true : false} isAstrix={false} label={"Select Currency"}
                                            selectData={most_used_currencies} name={"otherChargeCurrency"}
                                            value={data.otherChargeCurrency} optionLabel={"code"} optionValue={'code'}
                                            error={errors.otherChargeCurrency} onChange={handleChange} />
                                        </div>
                                        <div className='col-md-3 move-m'>
                                          <NewInput isDisabled={data.otherChargeCurrency ? true : false} isAstrix={false} type={"number"} label={"% Percentage"}
                                            name={"otherChargePercentage"} value={data.otherChargePercentage} error={errors.otherChargePercentage}
                                            onChange={handleChange}
                                          />
                                        </div>
                                      </div>
                                      <div className='row'>
                                        <div className='col-md-6 move-m'>
                                          <NewInput isAstrix={false} type={"text"} label={"Title"}
                                            name={"otherChargeTitle"} value={data.otherChargeTitle} error={errors.otherChargeTitle}
                                            onChange={handleChange}
                                          />
                                        </div>
                                      </div>
                                      <div className='row'>
                                        <div className='col-md-6 move-m'>
                                          <NewInput isDisabled={data.otherChargePercentage ? true : false} isAstrix={false} type={"number"} label={"Enter Charges"}
                                            name={"otherChargesAmt"} value={data.otherChargesAmt} error={errors.otherChargesAmt}
                                            onChange={handleChange}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4 move-l">
                                        <a onClick={() => { preHandleSubmit('moreCharges') }}><p>+ Add other charges</p></a>
                                      </div>
                                    </div>
                                  </div>
                                  <button type="button" className={`new-btn w-20 py-2 px-2 text-white`} onClick={() => preHandleSubmit('charges')}>Add</button>
                                </div></> :
                                (quoteForm.modal && quoteForm.page === 3) && <>
                                  <a className="cursor" onClick={() => setQuoteForm({ modal: true, page: 1 })} ><img className="mb-3 back-btn" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
                                  <div className="modal-body text-center">
                                    <p>Add Remark</p>
                                    <div className="col-md-10 move-left">
                                      <NewTextArea rows={6} isAstrix={false} type={"text"} label={`Write a remark for supplier.`}
                                        name={"assignRemark"} value={data.assignRemark} error={errors.assignRemark}
                                        onChange={handleChange} />
                                    </div>
                                    <button type="button" className={`new-btn w-20 py-2 px-2 text-white`} onClick={() => { setQuoteForm({ modal: true, page: 1 }); }}>Add</button>
                                  </div></>}
                          </div>
                        </div>
                      </div></>}

                    {denyForm.modal && denyForm.page === 0 ? <>
                      <div className={`modal fade ${denyForm.modal && "show"}`} style={{ display: "block" }}>
                        <div className="modal-dialog modal-md">
                          <div className="modal-content submitmodal pb-4">
                            <div className="modal-header border-0">
                              <button type="button" className="btn-close" aria-label="Close" onClick={() => setDenyForm({ modal: false, page: 0 })}></button>
                            </div>
                            <div className="modal-body text-center">
                              <img src={"/assets/images/Questionmark.png"} alt="" className="mb-3" />
                              <p>Deny Finance Limit</p>
                              <button type="button" className={`mx-4 new-btn2 w-15 py-2 px-3 text-blue`} onClick={() => setDenyForm({ modal: true, page: 1, denialType: denyForm.denialType })}>Yes</button>
                              <button type="button" className={`mx-4 new-btn2 w-15 py-2 px-3 text-blue`} onClick={() => setDenyForm({ modal: false, page: 0 })}>No</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </> :
                      denyForm.page === 1 ? <div className={`modal fade ${denyForm.modal && "show"}`} style={{ display: "block" }}>
                        <div className="modal-dialog modal-md">
                          <div className="modal-content submitmodal pb-4">
                            <div className="modal-header border-0">
                              <button type="button" className="btn-close" aria-label="Close" onClick={() => setDenyForm({ modal: false, page: 0 })}></button>
                            </div>
                            <div className="modal-body text-center">
                              <p>{denyForm.onlyRemark ? 'Add Remark' : 'Denial Remark'}</p>
                              <div className="col-md-10 move-p">
                                <textarea rows="4" cols="50" className={"form-control"} placeholder="Leave a message for the supplier."
                                  name={denyForm.onlyRemark ? 'onlyRemark' : "assignRemark"} value={denyForm.onlyRemark ? data.onlyRemark : data.assignRemark}
                                  onChange={handleChange} />
                              </div>
                              <button type="button"
                                onClick={() => {
                                  if (denyForm.onlyRemark) {
                                    if (data.onlyRemark) {
                                      addRemarkOnQuote()
                                    }
                                    else {
                                      toastDisplay("Kindly add remark to send", "info")
                                    }
                                  }
                                  else {
                                    addRemark(); submitForm('deny', denyForm.denialType); setDenyForm({ modal: true, page: 2 })
                                  }
                                }}
                                className={`mx-2 new-btn w-25 py-2 px-2 mt-4 text-white`}>
                                {"Send"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div> :
                        denyForm.page === 2 && <div className={`modal fade ${denyForm.modal && "show"}`} style={{ display: "block" }}>
                          <div className="modal-dialog modal-md">
                            <div className="modal-content submitmodal pb-4">
                              <div className="modal-header border-0">
                                <button type="button" className="btn-close" aria-label="Close" onClick={() => setDenyForm({ modal: false, page: 0 })}></button>
                              </div>
                              <div className="modal-body text-center">
                                <img src={"/assets/images/upload_done.png"} alt="" className="mb-3" />
                                <p>{denyForm.onlyRemark ? 'Remark has been sent to user successfully' :
                                  'Finance limit for Invoice discounting denied successfully !'}</p>
                              </div>
                            </div>
                          </div>
                        </div>}
                  </div>
                </div>
              </>
            )}
          </main>
        </div >
      </div >
    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(ViewDetails)