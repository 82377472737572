import React, { useEffect, useState } from 'react'
import CustomAreaChart from '../../Dashboard/components/CustomAreaChart'
import { NewSelect } from '../../../utils/newInput'
import CustomBarChart from '../../Dashboard/components/CustomBarChart'
import moment from 'moment'
import { ExportExcel } from '../../../utils/myFunctions'
import CustomLineChart from '../../CRM/CustomLineChart'
import call from '../../../service'
import { platformBackendUrl } from '../../../urlConstants'
import { NewTable } from '../../../utils/newTable'
import Filter from '../../InvoiceDiscounting/components/Filter'
import { Pagination } from 'react-bootstrap'
const LogsArr = [
  { name: "Contacts", val: 'Create New Task', color: 'text-color1', count: 0 },
  { name: "Lead Created", val: 'Didnt connect', color: 'color3DB16F', count: 0 },
  { name: "Lost", val: 'Call back', color: 'colorFE4141', count: 0 },
  { name: "Supplier Onboarded", val: 'Not Interested', color: 'text-color1', count: 0 },
]
const bardataConfig = [
  { dataKey: "int_count", fill: "#2ECC71", display: 'Interested' },
  { dataKey: "others_count", fill: "#AED8FF", display: 'Others' },
  { dataKey: "not_interested", fill: "#FF7B6D", display: 'Not Interested' },
]

const taskAssignConfig = [
  { dataKey: "total_exporters", fill: "#76EEA9", display: 'Exporters' },
  { dataKey: "total_importers", fill: "#FFB801", display: 'Importers' },
  { dataKey: "total_audiotrs", fill: "#F26191", display: 'Auditors' },
]

const inboundConfig = [
  { dataKey: "value", fill: "#76EEA9", display: "Users" }
]
const label = {
  "int_count": "Interested",
  "others_count": "Others",
  "not_interested": "Not Interested"
}

const taskAssignLabel = {
  "total_exporters": "Exporters",
  "total_importers": "Importers",
  "total_audiotrs": "Auditors"
}

const taskColorMapping = {
  'Hot': 'text-color1',
  'Cold': 'text-color1',
  'Cold': 'text-color1',
  'Onboarded': 'color-2ECC71',
  'Not Reachable': 'text-F1C40F',
  'Not Interested': 'text-color-E74C3C',
  'Lost': 'text-color-value',
  'Call': 'color-2ECC71'

}
const CRMReportsTab = ({ userTokenDetails, dateRange, userIds }) => {
  let todayDateObj = moment()
  let lastMonthDateObj = moment().subtract("1", "months")
  const [data, setdata] = useState({})
  const [errors, setErrors] = useState({})
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10 })
  const [filterData, setFilterData] = useState({})
  const [filteredSearch, setFilteredSearch] = useState({})
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [dbData, setdbData] = useState([])
  const [stats, setStats] = useState(LogsArr)
  const [overalldata, setoveralldata] = useState([])
  const [salesPerson, setSalesPerson] = useState([])
  const [notePopup, toggleNotePopup] = useState({ show: false, data: "", selectedIndex: null, noteFor: "" })
  const [graphConfiguration, setGraphConfiguration] = useState({
    taskAssignGraphDuration: "6 Months",
    taskGraphUpdateDuration: "6 Months"
  })
  const [showLoader, setshowLoader] = useState(false)
  const [tableExpand, setTableExpand] = useState("");
  const [expandedData, setExapndedData] = useState([]);
  const userPermissionsForSubAdmin = JSON.parse(userTokenDetails.UserAccessPermission || "{}")
  const userId = userTokenDetails?.user_id
  const [activeIndex, setActiveIndex] = useState(null);


  const [taskgraph, setTaskgraph] = useState([])
  const [leadsGraph, setLeadsGraph] = useState([])
  const [tableConfiguration, settableConfiguration] = useState({
    fromDate: lastMonthDateObj.clone().format("YYYY-MM-DD"),
    toDate: todayDateObj.clone().format("YYYY-MM-DD"),
  })
  const [taskdue, settasksdue] = useState([])
  const [taskcreated, setTaskCreated] = useState([])
  const [TasksSelect, setTasksSelect] = useState('all')

  const [taskAssignedGraph, setTaskAssignedGraph] = useState([])
  const [taskUpdateGraph, settaskUpdateGraph] = useState([])
  const [inBoundTasksAssigned, setInboundTaskAssigned] = useState([])
  let onlyShowForUserId = userPermissionsForSubAdmin?.mainAdmin ? undefined : userId

  const handleGraphConfigurationChange = async (event) => {
    if (event.persist) {
      event.persist()
    }
    setGraphConfiguration({ ...graphConfiguration, [event.target.name]: event.target.value })
  }

  useEffect(() => {
    // let isCacheExist = localStorage.getItem('taskManagerFilterData') != "{}"
    // if (!isCacheExist) {
    let objectAPI = {}
    if (!userPermissionsForSubAdmin?.mainAdmin) {
      objectAPI["onlyShowForUserId"] = userId
    }
    call('POST', 'getOverallCRMTasksFilters', objectAPI).then(res => {
      console.log("getOverallCRMTasksFilters then", res);
      setFilterData(res)
    }).catch(err => { })

    // }
  }, [])
  const getTasks = () => {
    setshowLoader(true)
    let objectAPI = {
      currentPage: page,
      ...filter,
      resultPerPage: filter.resultPerPage,
      included_status: [0, 1, 2, 3, 4],
      taskType: 'Call List'
    }
    if (!userPermissionsForSubAdmin?.mainAdmin) {
      objectAPI["onlyShowForUserId"] = userId
    }
    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push((element.accordianId === "newUserType" ||
                element.accordianId === "leadStatus" || element.accordianId === "applicationStatus") ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
        else if (element.type === "minMaxDate") {
          objectAPI[element.accordianId] = element["value"]
        } else if (element.type === 'singleDate') {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }
    call('POST', 'getOverallCRMTasks', objectAPI)
      .then((result) => {
        setdbData(formatDataForTable(result.message))
        setCount(result.total_records)
        setoveralldata(result.message)
        setshowLoader(false)
      }).catch(e => {
        setshowLoader(false)
      })
  }

  const getXAxisDateFormat = (data, value) => {
    let countForMonths = moment(dateRange.to).diff(dateRange.from, 'month')
    let dateFormat = ''
    if (countForMonths > 3) {
      dateFormat = moment(value).format('MMM YYYY')
    } else if (countForMonths <= 1) {
      dateFormat = moment(value).format('DD MMM YYYY')
    } else {
      dateFormat = value
    }
    return dateFormat
  }
  function formatDataForTable(data) {
    let tableData = []
    let row = []
    data.forEach((item, index) => {
      try {
        let status = item.task_logs ? item.task_logs?.[0]?.EVENT_STATUS?.split("(")[0] || 'NA' : 'NA'
        row[0] = item.TASK_DATE ? moment(item.TASK_DATE).format('DD/MM/YYYY') : 'NA'
        row[1] = <div class="dropdown w-100" >
          <label class="font-wt-600 font-size-13 cursor" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            {item.TASK_ASSIGNED_TO[0].contact_person || '-'}
          </label>
        </div>
        row[2] = item.EXPORTER_NAME
        row[3] = item.EXPORTER_CITY
        row[4] = <span className={`${taskColorMapping[status.trim()]} font-wt-600`}>{status}</span>
        row[5] = item.TASK_DATE ? moment().diff(item.TASK_DATE, 'days') + " days" : '-'
        tableData.push(row)
        row = []
      } catch (e) {
        console.log('error in formatdatafortable', e);
      }

    })
    return tableData
  }
  useEffect(() => {
    getTasks()
  }, [page, refresh, filterData])
  const getTaskAssignedGraph = () => {
    setshowLoader(true)
    let reqObj = {
      from: dateRange.from,
      to: dateRange.to,
      userIds,
      onlyShowForUserId
    }
    call('POST', 'getTaskAssignedGraph', reqObj).then(result => {
      setTaskAssignedGraph(result)
      setshowLoader(false)
    }).catch(e => {
      setshowLoader(false)
    })
  }
  const getTaskUpdateGraph = () => {
    let reqObj = {
      from: dateRange.from,
      to: dateRange.to,
      userIds,
      onlyShowForUserId
    }
    setshowLoader(true)
    call('POST', 'getTaskUpdateGraph', reqObj).then(result => {
      settaskUpdateGraph(result)
      setshowLoader(false)
    }).catch(e => {
      setshowLoader(false)
    })
  }
  useEffect(() => {
    getTaskUpdateGraph()
    getInboundTasks()
    getTaskAssignedGraph()
  }, [dateRange, userIds])

  const getInboundTasks = () => {
    let reqObj = {
      from: dateRange.from,
      to: dateRange.to,
      userIds,
      onlyShowForUserId
    }
    setshowLoader(true)
    call('POST', 'getInboundTasks', reqObj).then(result => {
      setInboundTaskAssigned(result)
      setshowLoader(false)
    }).catch(e => {
      setshowLoader(false)
    })
  }

  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

      <div className='row pl-4 mt-4 gap-4'>
        <div className='card h-50 w-48 dashboard-card shadow-sm '>
          <div>
            <div class="dropdown">
              <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => setTasksSelect('all')}>Outbound Task Assigned</label>

                <div className='w-20 align-items-center d-flex justify-content-end'>
                  <div className='pr-3'>
                    <img
                      onClick={() => { setGraphConfiguration({ ...graphConfiguration, onbordedDurationGraphMode: !graphConfiguration.onbordedDurationGraphMode }) }}
                      className='cursor'
                      src={`/assets/images/${graphConfiguration.onbordedDurationGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                  </div>
                  <div className=''>
                    <img
                      onClick={() => { }}
                      className='cursor' src='/assets/images/download_icon_with_bg.png' />
                  </div>
                </div>

              </div>

            </div>
          </div>
          <div>
            <CustomLineChart XFormatter={(value) => getXAxisDateFormat(graphConfiguration.taskAssignGraphDuration, value)} YFormatter={(value) => value} bardataConfig={taskAssignConfig} formatterFunction={(value, name) => [value, taskAssignLabel[name]]} data={taskAssignedGraph} xDataKey={"xLabel"} tab={'Count'} type={"Sum"} />
          </div>

        </div>
        <div className='card h-50 w-48 dashboard-card shadow-sm '>
          <div>
            <div class="dropdown">
              <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => setTasksSelect('all')}>Inbound Task Assigned</label>


                <div className='w-20 align-items-center d-flex justify-content-end'>
                  <div className='pr-3'>
                    <img
                      onClick={() => { setGraphConfiguration({ ...graphConfiguration, onbordedDurationGraphMode: !graphConfiguration.onbordedDurationGraphMode }) }}
                      className='cursor'
                      src={`/assets/images/${graphConfiguration.onbordedDurationGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                  </div>
                  <div className=''>
                    <img
                      onClick={() => { }}
                      className='cursor' src='/assets/images/download_icon_with_bg.png' />
                  </div>
                </div>

              </div>

            </div>
          </div>
          <div>
            <CustomLineChart XFormatter={(value) => value} YFormatter={(value) => value} bardataConfig={inboundConfig} formatterFunction={(value, name) => [value, name]} data={inBoundTasksAssigned} xDataKey={"label"} tab={'Count'} type={"Sum"} />
          </div>

        </div>
      </div>
      <div className='row pl-4 mt-4 gap-4'>

        <div className='card h-50 w-100 dashboard-card shadow-sm '>
          <div>
            <div class="dropdown">
              <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => setTasksSelect('all')}>Task Update</label>

                <div className='w-20 align-items-center d-flex justify-content-end'>
                  <div className='pr-3'>
                    <img
                      onClick={() => { setGraphConfiguration({ ...graphConfiguration, onbordedDurationGraphMode: !graphConfiguration.onbordedDurationGraphMode }) }}
                      className='cursor'
                      src={`/assets/images/${graphConfiguration.onbordedDurationGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                  </div>
                  <div className=''>
                    <img
                      onClick={() => { }}
                      className='cursor' src='/assets/images/download_icon_with_bg.png' />
                  </div>
                </div>

              </div>

            </div>
          </div>
          <div>
            <CustomAreaChart XFormatter={(value) => value} YFormatter={(value) => value} bardataConfig={bardataConfig} formatterFunction={(value, name) => [value, label[name]]} data={taskUpdateGraph} xDataKey={"label"} dataKey={"value"} />
          </div>

        </div>
        {/* <div className='card h-50 w-48 dashboard-card shadow-sm '>
          <div>
            <div class="dropdown">
              <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => setTasksSelect('all')}>New User Status</label>


                <div className='w-20 align-items-center d-flex justify-content-end'>
                  <div className='pr-3'>
                    <img
                      onClick={() => { setGraphConfiguration({ ...graphConfiguration, onbordedDurationGraphMode: !graphConfiguration.onbordedDurationGraphMode }) }}
                      className='cursor'
                      src={`/assets/images/${graphConfiguration.onbordedDurationGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                  </div>
                  <div className=''>
                    <img
                      onClick={() => { }}
                      className='cursor' src='/assets/images/download_icon_with_bg.png' />
                  </div>
                </div>

              </div>

            </div>
          </div>
          <div>
            <CustomAreaChart XFormatter={(value) => value} YFormatter={(value) => value} bardataConfig={bardataConfig} formatterFunction={(value, name) => [value, label[name]]} data={[
              {
                "label": "Send Agreement",
                "value": 4
              },
              {
                "label": "Sign Agreement",
                "value": 1
              },
              {
                "label": "Add buyer",
                "value": 1
              },
              {
                "label": "apply for limit",
                "value": 3
              },
              {
                "label": "apply for finance",
                "value": 1
              }
            ]} xDataKey={"label"} dataKey={"value"} />
          </div>

        </div> */}
      </div>
      {/* <div className='row pl-4 mt-4 gap-4'>
        <div className='card h-50 w-48 dashboard-card shadow-sm '>
          <div>
            <div class="dropdown">
              <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => setTasksSelect('all')}>Outbound Task Assigned</label>
                <div className='w-20 align-items-center d-flex justify-content-end'>
                  <div className='pr-3'>
                    <img
                      onClick={() => { setGraphConfiguration({ ...graphConfiguration, onbordedDurationGraphMode: !graphConfiguration.onbordedDurationGraphMode }) }}
                      className='cursor'
                      src={`/assets/images/${graphConfiguration.onbordedDurationGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                  </div>
                  <div className=''>
                    <img
                      onClick={() => { }}
                      className='cursor' src='/assets/images/download_icon_with_bg.png' />
                  </div>
                </div>

              </div>

            </div>
          </div>
          <div>
            <CustomLineChart XFormatter={(value) => getXAxisDateFormat(graphConfiguration.taskAssignGraphDuration, value)} YFormatter={(value) => value} bardataConfig={taskAssignConfig} formatterFunction={(value, name) => [value, taskAssignLabel[name]]} data={[]} xDataKey={"xLabel"} />
          </div>

        </div>
        <div className='card h-50 w-48 dashboard-card shadow-sm '>
          <div>
            <div class="dropdown">
              <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => setTasksSelect('all')}>Inbound Task Assigned</label>
                <div className='w-20 align-items-center d-flex justify-content-end'>
                  <div className='pr-3'>
                    <img
                      onClick={() => { setGraphConfiguration({ ...graphConfiguration, onbordedDurationGraphMode: !graphConfiguration.onbordedDurationGraphMode }) }}
                      className='cursor'
                      src={`/assets/images/${graphConfiguration.onbordedDurationGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                  </div>
                  <div className=''>
                    <img
                      onClick={() => { }}
                      className='cursor' src='/assets/images/download_icon_with_bg.png' />
                  </div>
                </div>

              </div>

            </div>
          </div>
          <div>
            <CustomAreaChart XFormatter={(value) => getXAxisDateFormat(graphConfiguration.taskGraphUpdateDuration, value)} YFormatter={(value) => value} bardataConfig={bardataConfig} formatterFunction={(value, name) => [value, label[name]]} data={leadsGraph} xDataKey={"xLabel"} dataKey={"lead_count"} />
          </div>

        </div>
      </div> */}
      <div className='pl-4 mt-4'>
        <div className='card dashboard-card shadow-sm '>
          <div className='col-md-12'>
            <div className='filter-div ml-4'>
              <Filter
                filteredSearch={filteredSearch}
                setFilteredSearch={setFilteredSearch}
                filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} />

            </div>
            <div>
              <NewTable
                filteredSearch={filteredSearch}
                setFilteredSearch={setFilteredSearch}
                filterData={filterData}
                setFilterData={setFilterData}
                columns={[
                  {
                    name: "Date", filter: true, filterDataKey: "Date", sort: [
                      { name: "Sort Oldest", selected: filter.sortBydate === "ASC", onActionClick: () => { setFilter({ ...filter, sortBydate: 'ASC', sortBuyerName: false, sortFinName: false, sortAmount: false }); setRefresh(refresh + 1) } },
                      { name: "Sort Latest", selected: filter.sortBydate === "DESC", onActionClick: () => { setFilter({ ...filter, sortBydate: "DESC", sortBuyerName: false, sortFinName: false, sortAmount: false }); setRefresh(refresh + 1) } }]
                  },
                  {
                    name: "Admin", filter: true, filterDataKey: "Lead Assigned To", sort: [
                      { name: "Sort A-Z", selected: filter.sortBuyerName === "ASC", onActionClick: () => { setFilter({ ...filter, sortBuyerName: 'ASC', sortBydate: false, sortFinName: false, sortAmount: false }); setRefresh(refresh + 1) } },
                      { name: "Sort Z-A", selected: filter.sortBuyerName === "DESC", onActionClick: () => { setFilter({ ...filter, sortBuyerName: "DESC", sortBydate: false, sortFinName: false, sortAmount: false }); setRefresh(refresh + 1) } }]
                  },
                  { name: "Exporter Name" },
                  {
                    name: "Exporter City", filter: true, filterDataKey: "Application Type"
                  },
                  { name: "Status" },
                  { name: "Days" }
                ]}
                data={dbData}
                options={[]}
                disableAction={true}
              />
              <Pagination page={page} totalCount={count} onPageChange={(p) => setPage(p)} refresh={refresh} setRefresh={setRefresh} perPage={filter.resultPerPage || 10} />

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CRMReportsTab