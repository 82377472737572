import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { NewTable } from "../../../utils/newTable";
import HeaderV2 from "../../partial/headerV2";
import SideBarV2 from "../../partial/sideBarV2";
import Filter from "../../InvoiceDiscounting/components/Filter";
import Pagination from "../../InvoiceDiscounting/contract/components/pagination";
import config from '../../../config.json';
import call from "../../../service";
import toastDisplay from "../../../utils/toastNotification";
import { convertImageToPdf, getDocDetails, SetCache, ClearCache, most_used_currencies, GetCache, LCTypesObject, LCPurposeObject } from "../../../utils/myFunctions";
import moment from "moment";
import { FileInput } from "../../../utils/FileInput";
import SignDocument from "../../InvoiceDiscounting/components/SignDocument";
import { NewSelect } from "../../../utils/newInput";
import TooltipModal from "../../InvoiceDiscounting/components/Tooltipmodal";
import FinanceInvoiceModal from "../../InvoiceDiscounting/contract/components/financeinvoiceModal";


const ApplyforFinance = ({ userTokenDetails, navToggleState }) => {
  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [tableData, setTableData] = useState([])

  const [showAddBuyerForm, toggleAddBuyerForm] = useState(false)
  const [tab, setTab] = useState(0)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)

  const [dunsData, setDunsData] = useState([])
  const [hsnCodes, setHsnCodes] = useState([])
  const [countrys, setCountrys] = useState([])
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [financierData, setFinancierData] = useState([]);
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10, search: serarchParam ? serarchParam : '' })

  const [limitinvoice, setLimitinvoice] = useState(false);
  const [documents, setDocuments] = useState(false);
  const [signdoc, setSigndoc] = useState(false);
  const [tooltip, setTooltip] = useState(false);
  const [applicationCounts, setApplicationCounts] = useState({})

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  useEffect(() => {
    loadApplicationCounts()
  }, [])

  async function loadApplicationCounts() {
    setshowLoader(true)
    let apiResp = await call("POST", 'getLCApplyForFinanceApplicationCounts', { userId })
    setApplicationCounts({ ...apiResp })
    setshowLoader(false)
  }

  useEffect(() => {
    //Get Buyer list start
    let objectAPI = {
      userId,
      onlyReadyForApplyFinance: true,
      currentPage: 1,
      ...filter
    }
    setshowLoader(true)
    call('POST', 'getLCQuoteApplications', objectAPI).then((result) => {
      console.log('running getLCQuoteApplications api-->', result);
      setshowLoader(false)
      setDbData(result.data);
      setTableData(formatDataForTable(result.data))
      setCount(result.totalCount);
    }).catch((e) => {
      // console.log('error in getLCQuoteApplications', e);
      setshowLoader(false)
    });
  }, [refresh])


  function formatDataForTable(data) {
    let tableData = []
    let row = []
    // const tableData2 = [
    //   {
    //     buyerName: "Rosti Suzhou Integrated ",
    //     LCno: "256985632547854",
    //     LCtype: "LC confirmation",
    //     Offerexpiry: "12/08/2022",
    //     Financerselected: "ICICI bank",
    //     Limitavailable: "$ 1,00,000",
    //     status: false
    //   },
    //   {
    //     buyerName: "Rosti Suzhou Integrated ",
    //     LCno: "256985632547854",
    //     LCtype: "LC confirmation",
    //     Offerexpiry: "12/08/2022",
    //     Financerselected: "ICICI bank",
    //     Limitavailable: "$ 1,00,000",
    //     status: true
    //   },
    //   {
    //     buyerName: "Rosti Suzhou Integrated ",
    //     LCno: "256985632547854",
    //     LCtype: "LC confirmation",
    //     Offerexpiry: "12/08/2022",
    //     Financerselected: "ICICI bank",
    //     Limitavailable: "$ 1,00,000",
    //     status: false
    //   },
    //   {
    //     buyerName: "Rosti Suzhou Integrated ",
    //     LCno: "256985632547854",
    //     LCtype: "LC confirmation",
    //     Offerexpiry: "12/08/2022",
    //     Financerselected: "ICICI bank",
    //     Limitavailable: "$ 1,00,000",
    //     status: false
    //   },
    // ];
    data.forEach((item, index) => {
      row[0] = moment(item.updatedAt).format('DD/MM/YYYY')
      row[1] = item.buyerName
      row[2] = item.lcNo
      row[3] = item.invRefNo || "-"
      row[4] = LCPurposeObject[item.lcPurpose]
      row[5] = JSON.parse(item.selectedQuote)["lender_name"]
      row[6] = item.pendingLimit || 0
      row[7] = <div className='row ' >
        <a>
          <button type="button"
            disabled={item.appliedForFinance}
            onClick={() => { window.location = `/LcFinancebtn?id=${item.id}` }}
            class={` border-0 mb-2 enablesigncontract ${item.appliedForFinance && "disablesigncontract text-dark"}`}>
            {item.appliedForFinance ? "Applied for Finance" : "Apply for Finance"}
          </button>
        </a>
      </div >
      // row[9] = ""
      if (item.appliedForFinance) {
        row[8] =
          <div className="d-flex flex-row ">
            <img
              onClick={() => { window.location = `/LcFinancebtn?id=${item.id}&view=true` }}
              src={"assets/images/eye.png"} className="cursor ml-4" alt='' />
            <div className="dropdown ml-3">
              <img src={"/assets/images/more-dark.png"} alt='' id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" className="ml-2" />
              <ul className="dropdown-menu borderRadius chatmore font-size-12 text-center " aria-labelledby="dropdownMenuButton1">
                <li className="text-left p-0 m-0" onClick={() => window.location = '/ChatRoomV2'}>
                  <div className="py-2 px-2 action-label d-flex align-items-center">
                    <img className="mr-2 " src={`assets/images/sidebarV2/chatroom2.png`} height={15} width={15} />
                    <label className="m-0">{"Chat with Buyer"}</label>
                  </div>
                </li>
                <li className="text-left p-0 m-0" onClick={() => window.location = '/ChatRoomV2'}>
                  <div className="py-2 px-2 action-label d-flex align-items-center">
                    <img className="mr-2 " src={`assets/images/sidebarV2/chatroom2.png`} height={15} width={15} />
                    <label className="m-0">{"Chat with Financier"}</label>
                  </div>
                </li>
                {/* <li><a onClick={() => setSchedule(true)} className="dropdown-item"> Schedule meeting</a></li> */}
                <li className="text-left p-0 m-0" onClick={() => window.location = '/ChatRoomV2?chatType=Group'}>
                  <div className="py-2 px-2 action-label d-flex align-items-center">
                    <img className="mr-2 " src={`assets/images/sidebarV2/chatroom2.png`} height={15} width={15} />
                    <label className="m-0">{"Group Chat"}</label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
      } else {
        row[8] =
          <div className="d-flex flex-row ml-3">
            <div style={{ height: 24, width: 25 }} className="ml-4" >
            </div>
            <div className="dropdown" >
              <img src={"/assets/images/more-dark.png"} alt='' id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" className="ml-2" />
              <ul className="dropdown-menu borderRadius chatmore font-size-12 text-center " aria-labelledby="dropdownMenuButton1">
                <li><a className="dropdown-item">Chat With Buyer</a></li>
                <li><a className="dropdown-item">Chat With Financier</a></li>
                {/* <li><a onClick={() => setSchedule(true)} className="dropdown-item"> Schedule meeting</a></li> */}
                <li><a className="dropdown-item">Group Chat</a></li>
              </ul>
            </div>
          </div>
      }
      // row[8] = <div className="dropdown " style={{ background: 'yellow' }} >
      //   <img src={"/assets/images/more-dark.png"} alt='' id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" />
      //   <ul className="dropdown-menu borderRadius chatmore font-size-12 text-center " aria-labelledby="dropdownMenuButton1">
      //     <li><a className="dropdown-item">Chat With Buyer</a></li>
      //     <li><a className="dropdown-item">Chat With Financier</a></li>
      //     {/* <li><a onClick={() => setSchedule(true)} className="dropdown-item"> Schedule meeting</a></li> */}
      //     <li><a className="dropdown-item">Group Chat</a></li>
      //   </ul>
      // </div>
      // row[10] = ""
      tableData.push(row)
      row = []
    })
    return tableData
  }



  const handleChange = async (event) => {
    event.persist()
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }


  async function handleValidation(tabIndex) {

    if (tabIndex != undefined) {
      if (tabIndex < tab) {
        return setTab(tabIndex)
      }
      else if (tabIndex == tab) {
        return null
      }
      else {
        return toastDisplay("Click on continue button to go to next form", "info")
      }
    }

    let validateFields = []
    let err = {}
    if (tab === 0) {
      validateFields = ["buyerName", "buyerCountry"]
    }

    if (tab === 2) {
      validateFields = ["buyerName", "buyerCountry", "buyerAddress", "buyerPostalCode",
        "buyerPrevSale", "buyerExpectedSale", "buyerIncoTerms", "buyerTermsPayment", "buyerProductDetails"]
    }

    if (tab === 3) {
      validateFields = ["invoiceDocument", "poDocument"]
    }

    // if (tab === 4) {
    //   if (!data.selectedFinanciers.length) {
    //     return toastDisplay("Select atleast 1 financier", "info")
    //   }
    // }

    validateFields.forEach((item) => {
      if (!data[item]) {
        err[item] = "Mandatory Field"
      }
    })

    if (!Object.keys(err).length) {
      if (tab != 4) {
        if (tab === 0) {
        }
        setTab(tab + 1)
      }
      else {
      }
    }
    setErrors(err)
  }

  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (!file_type.includes("pdf")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }
  const closeSuccess = () => {
    setLimitinvoice(false);
  }



  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="LcApplyforFinance" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"LC- Apply for Finance"}
              userTokenDetails={userTokenDetails} />

            <div className='d-flex row justify-content-between px-2' >
              <div
                className='card border1Blue pl-4 d-flex justify-content-center cursor py-3' style={{ width: '21.44%' }} >
                <div>
                  <p className='font-size-22 font-wt-600 text2ECC71 p-0 m-0' >{applicationCounts.availableForApplication || 0}</p>
                  <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0 mt-3'>Available for Application</p>
                </div>
              </div>
              <div
                className='card border1Blue pl-4 d-flex justify-content-center cursor py-3' style={{ width: '19.44%' }} >
                <div>
                  <p className='font-size-22 font-wt-600 text-color1 p-0 m-0' >{applicationCounts.appliedForApplication || 0}</p>
                  <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0 mt-3'>Applied for Finance</p>
                </div>
              </div>
              <div
                className='card border1Blue pl-4 d-flex justify-content-center cursor py-3' style={{ width: '19.44%' }} >
                <div>
                  <p className='font-size-22 font-wt-600 text-color-E74C3C p-0 m-0' >{applicationCounts.rejected || 0}</p>
                  <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0 mt-3'>Rejected</p>
                </div>
              </div>
              <div style={{ width: '28.44%' }} >
              </div>
            </div>

            {!documents &&
              <><div>
                {/* <a className="cursor"><img src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a> */}
                <Filter filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} />
              </div><NewTable
                  disableAction={true}
                  columns={[{ name: "Date", filter: true }, { name: "Buyer name", filter: true },
                  { name: "LC No.", filter: true },
                  { name: "Invoice No.", filter: true },
                  {
                    name: "Finance Type", filter: true
                  },
                  {
                    name: "Financer Selected", filter: true
                  },
                  {
                    name: "Limit Available", filter: true
                  },
                  { name: "", filter: false },
                  { name: "", filter: false }
                  ]}

                  data={tableData} />
                <Pagination page={page} totalCount={count} onPageChange={(p) => setPage(p)} perPage={filter.resultPerPage || 0} />
                <div>
                  {/* <p className="font-size-14"><img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className="font-size-13 text-secondary">Additional document requested by financer </span> <a onClick={() => setDocuments(true)} className="text-color1 text-decoration-underline cursor">Send documents</a> </p> */}
                </div></>
            }
            {documents &&
              <>
                <a className="cursor" onClick={() => setDocuments(false)}><img src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
                <div className="card p-5 mt-4">
                  <p className="text-center ">Additional documents requested</p>
                  <label className='font-wt-500 font-size-14'><u>Drip</u></label>

                  <div className="col-md-8 mb-3 px-0 mt-2">
                    <label className="font-size-14">Invoice <img className="cursor"
                      onClick={() => setTooltip(true)}
                      src={"assets/images/warning.png"} alt="" /></label>
                    <div className="row align-items-center ">
                      <div className="form-group col-md-8 mb-0 ">
                        <FileInput name={"poDocument"} value={data.poDocument} error={errors.poDocument}
                          onChange={handleFile} isEditable={false}
                        />
                      </div>
                      <div className="col-md-4 text-end">
                        <a onClick={() => setSigndoc(true)} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8 mb-3 px-0 mt-1">
                    <div className="form-group col-md-8 mb-0 ps-0 pe-1">
                      <NewSelect isAstrix={false} label={"Select bank"}
                        selectData={dbData} name={"Selectbank"}
                        value={data.selectedBuyer} optionLabel={"buyerName"} optionValue={'id'}
                        onChange={handleChange} />
                    </div>
                  </div>
                  <label className='font-wt-500 font-size-14'><u>Stenn</u></label>
                  <div className="col-md-8 mb-3 px-0 mt-2">
                    <label className="font-size-14">Invoice <img className="cursor"
                      onClick={() => setTooltip(true)}
                      src={"assets/images/warning.png"} alt="" /></label>
                    <div className="row align-items-center ">
                      <div className="form-group col-md-8 mb-0 ">
                        <FileInput name={"poDocument"} value={data.poDocument} error={errors.poDocument}
                          onChange={handleFile} isEditable={false}
                        />
                      </div>
                      <div className="col-md-4 text-end">
                        <a onClick={() => setSigndoc(true)} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                      </div>
                    </div>
                  </div>
                  <button onClick={() => setLimitinvoice(true)} type="button" className="new-btn w-25 py-2 text-white cursor mt-4" >
                    Send documents
                  </button>
                </div>
              </>}
          </main>
        </div>
      </div >
      {
        signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
          setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />
      }
      {
        tooltip && <TooltipModal tooltip={tooltip} setTooltip={setTooltip}
          data={{ showRemark: data.showRemark, showLenderName: data.showLenderName }} />
      }
      {limitinvoice && <FinanceInvoiceModal limitinvoice={limitinvoice} setLimitinvoice={setLimitinvoice} closeSuccess={closeSuccess} >
        <img src={"/assets/images/upload_done.png"} alt="" className="mb-3" />
        <p>Additional documents sent successfully !</p>
      </FinanceInvoiceModal>}
    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(ApplyforFinance)