import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { FileInput } from "../../../../utils/FileInput";
import toastDisplay from "../../../../utils/toastNotification";
import call from "../../../../service";
import { toast, ToastContainer } from "react-toastify";
import { getDocDetails, most_used_currencies, convertImageToPdf, LcFieldsMaster, LCPurposeObject, LCTypesObject } from "../../../../utils/myFunctions";
import SideBarV2 from "../../../partial/sideBarV2";
import HeaderV2 from "../../../partial/headerV2";
import FinanceInvoiceModal from "../../../InvoiceDiscounting/contract/components/financeinvoiceModal";
import TooltipModal from "../../../InvoiceDiscounting/components/Tooltipmodal";
import SignDocument from "../../../InvoiceDiscounting/components/SignDocument";
import { InputWithSelect, NewInput, NewSelect, NewTextArea } from "../../../../utils/newInput";
import { Lcdetailsdata } from "../../applyforLimit/components/lcdiscountingcard";
import { getUserDataFromCookie } from "../../../../utils/cookieHelper";
import { useHistory } from "react-router";
import { NewTable } from "../../../../utils/newTable";
import NewTablev2 from "../../../../utils/newTablev2";
import { DocumentViewer } from "../../../../utils/documentViewer";
// import { eximBankUserId } from "../../../../urlConstants";

const reviewForm = [
  { "name": "Buyer Name", val: "buyerName" },
  { "name": "Previous year annual sales", val: "previousAnnualSale", unit: "buyerCurrency" },
  { "name": "Contact Number:", val: "buyerPhone", unit: "buyerPhoneCode" },
  { "name": "Expected current year’s annual sale", val: "currentAnnualSale", unit: "buyerCurrency" },

  { "name": "Email Id", val: "buyerEmail" },
  { "name": "Inco Terms", val: "incoTerms" },
  { "name": "Website", val: "buyerWebsite" },
  { "name": "Terms of Payment", val: "termsOfPayment" },

  { "name": "Address", val: "buyerAddress" },
  { "name": "Product Details", val: "productDetails" },

  { "name": "DUNS No", val: "buyerDUNSNo" },
  { "name": "HSN Code", val: "buyerHsnCode" },

]

const reviewForm3 = [
  { "name": "LC type", val: "lcType" },
  { "name": "LC number", val: "lcNo" },
  { "name": "LC tenor", val: "lcTenor" },
  { "name": "Shipment from country", val: "shipmentFromCountry" },
  { "name": "Shipment to country", val: "shipmentToCountry" },
  { "name": "Port of loading", val: "portOfLoading" },
  { "name": "Port of discharge", val: "portOfDischarge" },
  { "name": "Commodity", val: "commodity" },
  { "name": "Expected date of shipment", val: "expectedDateOfShipment" },
  { "name": "Beneficiary bank name", val: "beneficiaryBankName", notAmmendable: true },
  { "name": "Beneficiary bank account no.", val: "beneficiaryAccountNo", notAmmendable: true },
  { "name": "Beneficiary bank IFSC code", val: "beneficiaryIfscCode", notAmmendable: true },
  { "name": "Beneficiary bank SWIFT code", val: "beneficiarySwiftCode", notAmmendable: true },
  { "name": "LC issuing bank name", val: "lcIssuingBankName", notAmmendable: true },
  { "name": "LC issuing bank address", val: "lcIssuingBankAddress" },
  { "name": "LC issuing bank SWIFT code", val: "lcIssuingBankSwiftCode" },
  { "name": "Country of origin", val: "countryOfOrigin" },
  // { "name": "LC Purpose", val: "lcPurpose" },
  { "name": "Confirming bank name", val: "confirmingBankName" },
  { "name": "Confirming bank address", val: "confirmingBankAddress" },
  { "name": "Confirming bank SWIFT code", val: "confirmingBankSwiftCode" }
]

let most_used_currencies_with_percentage = most_used_currencies.concat([{ code: "%" }])


const SblcQuotationDetails = ({ navToggleState }) => {

  const queryParams = new URLSearchParams(window.location.search)
  const amendmentId = queryParams.get("id")
  const quoteId = queryParams.get("quoteId")

  let selectedItem = JSON.parse(localStorage.getItem("selectedItem"))

  let secondPhase = selectedItem.secondPhase ? true : false

  let temp = selectedItem?.selectedLenderName?.split(",") || []
  let temp2 = selectedItem?.selectedLenderId?.split(",") || []

  let lcFinanciers = []

  for (let index = 0; index < temp.length; index++) {
    const element = temp[index];
    lcFinanciers.push({ id: temp2[index], name: element })
  }

  let financierQuotes = {}
  let selectedQuote = true

  let history = useHistory()

  const [accord, setaccord] = useState({
    accord_0: true,
    accord_1: false,
    accord_2: false
  });

  const [tab, setTab] = useState(1)
  const [modal, setModal] = useState(false);
  const [limitinvoice, setLimitinvoice] = useState(false);
  const [lcCodes, setLCCodes] = useState({})
  const [LcFields, setLCFields] = useState(LcFieldsMaster)

  const [tooltip, setTooltip] = useState(false);
  const [signdoc, setSigndoc] = useState(false);
  const [data, setData] = useState({ ocrFields: {}, till_maturity: true })
  const [ammendData, setAmmendData] = useState({})
  const [ammendFields, setAmmendFields] = useState({})
  const [ocrFields, setOcrFields] = useState({})
  const [moreLCDetails, setMoreLCDetails] = useState(false)
  const [lcMoreDetails, setLCMoreDetails] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedFinancier, toggleSelectedFinancer] = useState(selectedItem.forwardTermsheetTo ? selectedItem.forwardTermsheetTo.split(",") : [])
  const [signedBy, toggleSignedBy] = useState(selectedItem.termSheetSignedBy ? selectedItem.termSheetSignedBy.split(",") : [])
  const [rejectedBy, toggleRejectedBy] = useState(selectedItem.termSheetRejectedBy ? selectedItem.termSheetRejectedBy.split(",") : [])
  const [signWithBank, toggleSignWithBank] = useState(selectedItem.termSheetSignedWith ? selectedItem.termSheetSignedWith.split(",") : [])
  const [otherDocsToSign, setOtherDocsToSign] = useState([])
  const [allBankTermSheetData, setAllBankTermSheetData] = useState([])
  const [viewDoc, toggleViewDoc] = useState({ show: false, doc: {} })

  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [addMoreDoc, setAddMoreDoc] = useState([])
  const [optionsPopup, toggleOptionsPopup] = useState(false)
  const [quoteForm, setQuoteForm] = useState({ modal: false, page: 0 });
  const [onlyView, setOnlyView] = useState(false)

  const [amend, setAmend] = useState(false);
  const [fieldValues, setFieldValues] = useState({})


  let userTokenDetails = getUserDataFromCookie()

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  // const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  // const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  // const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;


  const closeSuccess = () => {
    setAmend(false);
    // setExperience(true);
  }

  async function getMasterData() {
    let temp = LcFields
    setshowLoader(true)
    temp["shipmentFromCountry"]["selectData"] = await call('get', 'getallCountry')
    temp["shipmentToCountry"]["selectData"] = temp["shipmentFromCountry"]["selectData"]
    temp["countryOfOrigin"]["selectData"] = temp["shipmentFromCountry"]["selectData"]
    temp["portOfDischarge"]["selectData"] = await call('POST', 'getOldPortMaster')
    temp["portOfLoading"]["selectData"] = temp["portOfDischarge"]["selectData"]
    setshowLoader(false)
    setLCFields(temp)
  }

  async function fetchAllDocs(applicationDetails) {
    setshowLoader(true)
    let invoiceDocument = await getDocDetails(applicationDetails.buyersDocId ? applicationDetails.buyersDocId.split(":")[0] : null)
    let poDocument = await getDocDetails(applicationDetails.buyersDocId ? applicationDetails.buyersDocId.split(":")[1] : null)
    let draftLCDocument = await getDocDetails(applicationDetails.draftLC, null, true)
    let termSheetDocument = await getDocDetails(selectedItem.sblcTermSheet, null, true)
    let buyerOtherDocIdArr = applicationDetails.buyerOtherDocs ? applicationDetails.buyerOtherDocs.split(":") : []
    let otherDocObj = {}
    let moreDocArr = addMoreDoc
    for (let index = 0; index < buyerOtherDocIdArr.length; index++) {
      otherDocObj["otherDoc" + index] = await getDocDetails(buyerOtherDocIdArr[index])
      moreDocArr.push(null)
    }
    let { financierQuotes } = applicationDetails
    let providedQuote = {}
    if (financierQuotes) {
      financierQuotes = JSON.parse(financierQuotes).forEach(i => {
        if (i.lender_id / 1 == userId / 1) {
          providedQuote = i
        }
      })
    }

    // Get amendment data start
    let amendmentData = {}
    let amendmentDataRes = await call('POST', 'getAmendmentsByApplicationId', { quoteId, amendmentId })
    if (amendmentDataRes && amendmentDataRes.length) {
      if (amendmentDataRes[0]['amendmentStatus'] == 1) {
        amendmentData["fieldLabels"] = Object.keys(amendmentDataRes[0].details)
        amendmentData["fieldComments"] = Object.values(amendmentDataRes[0].details)
        amendmentData["fields"] = amendmentDataRes[0].lcAdditionalDetails || []
        setFieldValues(amendmentDataRes[0].beforeDetails)
      }
      else {
        toastDisplay("Invalid link", "info", () => { window.location = "/login" })
      }
    }
    // Get amendment data end

    // Get other bank term sheet copies start
    if (secondPhase) {
      let otherDocumentsToSign = []
      let termSheetCopies = await call('POST', 'getSBLCTermSheetCopies', { id: selectedItem.id })
      console.log("ttttttttttttttttttttttt", termSheetCopies);
      for (let index = 0; index < termSheetCopies.length; index++) {
        const element = termSheetCopies[index];
        otherDocumentsToSign.push({ ... await getDocDetails(element.termSheet) })
      }
      setOtherDocsToSign(otherDocumentsToSign)
      setAllBankTermSheetData(termSheetCopies)
    }
    // Get other bank term sheet copies end

    // Get submitted details start
    let submittedDetails = {}
    if (selectedItem.quoteDetails) {
      setOnlyView(true)
      let temp = JSON.parse(selectedItem.quoteDetails)
      temp["till_maturity"] = temp.confirmationFeesValidity === "till_maturity" ? true : false
      submittedDetails = temp
    }
    // Get submitted details end
    setData({
      ...data, ...applicationDetails, ...amendmentData, ...providedQuote, invoiceDocument, poDocument, draftLCDocument, termSheetDocument,
      ...otherDocObj,
      lcType: LCTypesObject[applicationDetails["lcType"]], ...submittedDetails
    })
    setAddMoreDoc(moreDocArr)
    setshowLoader(false)
  }

  function updateSelectedFinacier(updatedArr) {
    toggleSelectedFinancer([...updatedArr])
  }

  function updateSignWithFinancier(updatedArr) {
    toggleSignWithBank([...updatedArr])
  }

  function viewCopiedTermSheet(index) {
    toggleViewDoc({ show: true, doc: otherDocsToSign[index] })
  }

  async function asyncUseEffectFunction() {
    let temp = []
    if (lcFinanciers.length && !secondPhase) {
      lcFinanciers.forEach((i, index) => {
        let isBankSelected = selectedFinancier.includes(i.id.toString())
        temp.push(
          [<div>
            <img
              onClick={() => {
                let temp = selectedFinancier
                if (!isBankSelected) {
                  temp.push(i.id.toString())
                  updateSelectedFinacier(temp)
                }
                else {
                  temp = temp.filter((x) => {
                    if (x != i.id) {
                      return x
                    }
                  })
                  updateSelectedFinacier(temp)
                }
              }}
              className='cursor mr-3' src={`assets/images/${isBankSelected ? 'checked-green' : 'empty-check'}.png`} />
            <label className="checkbox" for={index}>{i.name}</label>
          </div>]
        )
      })
      setTableData(temp)
    }
    if (secondPhase && selectedFinancier.length && lcFinanciers) {

      lcFinanciers.forEach((i, index) => {

        if (selectedFinancier.includes(i.id.toString())) {

          let isBankSigned = signedBy.includes(i.id.toString())
          let isBankRejected = rejectedBy.includes(i.id.toString())
          let isSignedWith = signWithBank.includes(i.id.toString())

          temp.push(
            [<div>
              {isBankRejected || !isBankSigned ? null : (
                <img
                  onClick={() => {
                    if (!data?.termSheetDocument?.signatureId) {
                      return toastDisplay("First sign term sheet document to continue", "info")
                    }
                    let temp = signWithBank
                    if (!isSignedWith) {
                      temp.push(i.id.toString())
                      updateSignWithFinancier(temp)
                    }
                    else {
                      temp = temp.filter((x) => {
                        if (x != i.id) {
                          return x
                        }
                      })
                      updateSignWithFinancier(temp)
                    }
                  }}
                  className='cursor mr-3' src={`assets/images/${isSignedWith ? 'checked-green' : 'empty-check'}.png`} />
              )}
              <label className="checkbox" for={index}>{i.name}</label>
            </div>,

            <div className="signStatusDiv">
              <div className="w-50">
                {isBankSigned ? (<label
                  onClick={() => viewCopiedTermSheet(index)}
                  className="font-wt-600 font-size-13 text-success cursor">Signed</label>) :
                  isBankRejected ? (<label className="font-wt-600 font-size-13 text-danger">Rejected</label>) :
                    (<label className="font-wt-600 font-size-13 text-dark">Not Signed</label>)
                }
              </div>

              <div className="w-50">
                {isSignedWith ? (<label
                  onClick={() => viewCopiedTermSheet(index)}
                  className="font-wt-600 font-size-13 text-success cursor">Signed</label>) : null}
              </div>

            </div>
            ]
          )

        }
      })
      setTableData(temp)
    }
  }

  useEffect(() => {
    asyncUseEffectFunction()
  }, [selectedFinancier, signWithBank, data.termSheetDocument])

  useEffect(() => {
    if (!selectedItem.forwardTermsheetTo) {
      let temp = selectedFinancier
      if (lcFinanciers) {
        lcFinanciers.filter((i) => {
          temp.push(i.id.toString())
        })
      }
      updateSelectedFinacier(temp)
    }

    //Get master data start
    call('POST', 'getLCFieldMaster', {
    }).then((result) => {
      // console.log('running getLCFieldMaster api -->', result);
      let lcFieldObj = {}
      result.mandatoryFields.forEach((item) => {
        lcFieldObj[item.tag] = item.fieldName
      })
      result.optionalFields.forEach((item) => {
        lcFieldObj[item.tag] = item.fieldName
      })
      setLCCodes(lcFieldObj)
    }).catch((e) => {
      // console.log('error in getLCFieldMaster', e);
    })
    getMasterData()
    // Get master data end


    call('POST', 'getLCQuoteApplicationById', {
      quoteId: selectedItem.id
    }).then((result) => {
      // console.log('running getLCQuoteApplicationById api -->', result);
      if (result && result.length) {
        fetchAllDocs(result[0])
      }
      else {
        toastDisplay("Failed to retrieve lc data", "error")
      }
    }).catch((e) => {
      // console.log('error in getLCQuoteApplicationById', e);
      toastDisplay("Something went wrong", "error")
    })

  }, [])


  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (!file_type.includes("pdf")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  console.log("ffffffffffffffffffffffffffffffff", otherDocsToSign);

  async function handleValidation(tabIndex) {
    if (tabIndex != undefined) {
      return setTab(tabIndex)
    }
    let err = {}
    if (!Object.keys(err).length) {
    }
    else {
      toastDisplay("Kindly fill required details to continue", "info")
    }
    setErrors(err)
  }

  async function appendOtherCharge() {
    let otherCharges = data.otherCharges || []
    let temp = {
      "name": data.otherChargeTitle,
      "unit": data.otherChargeCurrency || "%",
      "amount": data.otherChargeCurrency ? data.otherChargesAmt : data.otherChargePercentage
    }
    otherCharges.push(temp)
    setData({
      ...data, otherChargeCurrency: "", otherChargePercentage: "", otherChargeTitle: "",
      otherChargesAmt: "", otherCharges
    })
  }


  const handleChange = async (event) => {
    event.persist()
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }

  console.log("fieldValues=========>", data)


  const handleAccordion = (accordion) => {
    setaccord(accorddata => ({ ...accorddata, [accordion]: !accorddata[accordion] }));
  }

  const onEdit = async (item, datakey) => {
    if (item.unit == '%') {
      setData({
        ...data,
        otherChargeTitle: item.name,
        otherChargePercentage: item.amount
      })
    }
    else {
      setData({
        ...data,
        otherChargeTitle: item.name,
        otherChargesAmt: item.amount,
        otherChargeCurrency: item.unit
      })
    }
    const objWithIdIndex = data.otherCharges.findIndex((obj, key) => key === datakey);
    data.otherCharges.splice(objWithIdIndex, 1);
    if (data.otherCharges.length == 0) {
      delete data.otherCharges;
    }
  }

  const onDelete = async (datakey) => {
    const objWithIdIndex = data.otherCharges.findIndex((obj, key) => key === datakey);
    data.otherCharges.splice(objWithIdIndex, 1);
    if (data.otherCharges.length == 0) {
      delete data.otherCharges;
    }
    setData({ ...data })
  }

  async function submitQuote(isQuoteApproved) {
    let formData = new FormData()
    if (isQuoteApproved) {
      if (!selectedFinancier.length) {
        return toastDisplay("Select atleast 1 financer", "info")
      }
      setshowLoader(true)
      if (selectedFinancier.length) {
        formData.append("selectedFinancer", selectedFinancier.join(","))
      }
      formData.append("termSheetDocument", data.termSheetDocument)
      if (data.termSheetDocument && data.termSheetDocument.signatureId) {
        formData.append("termSheetDocSignId", data.termSheetDocument.signatureId)
      }
      // formData.append("draftLCDocument", data.draftLCDocument)
      // formData.append("draftLCDocSignId", data.draftLCDocument.signatureId)
      // formData.append("draftLCId", selectedItem.draftLC)
      if (selectedItem.sblcTermSheet) {
        formData.append("termSheetId", selectedItem.sblcTermSheet)
      }
    }
    formData.append("userId", userId)
    let quoteDetails = {}
    if (isQuoteApproved) {
      quoteDetails = {
        confirmationFees: data.confirmationFees,
        confirmationFeesValidity: data.till_maturity ? "till_maturity" : "till_expiry", assignRemark: data.assignRemark, otherCharges: data.otherCharges || []
      }
    }
    formData.append("quoteDetails", JSON.stringify(quoteDetails))
    formData.append("isQuoteApproved", isQuoteApproved)
    formData.append("id", selectedItem.id)
    formData.append("lcNo", selectedItem.lcNo)
    call('POST', 'submitEximBankQuote', formData).then((result) => {
      // console.log('running submitEximBankQuote api -->', result);
      setshowLoader(false)
      toastDisplay(result, "success", () => {
        history.go(-1)
      })
    }).catch((e) => {
      // console.log('error in submitEximBankQuote', e);
      setshowLoader(false)
      toastDisplay("Something went wrong", "error")
    })
  }

  async function signTermSheetWithBank() {
    setshowLoader(true)
    let formData = new FormData()
    formData.append("signWithBank", signWithBank.join(","))
    formData.append("applicationId", selectedItem.id)
    formData.append("lcNo", selectedItem.lcNo)
    formData.append("termSheetId", selectedItem.sblcTermSheet)
    formData.append("termSheetDocument", data.termSheetDocument)
    formData.append("termSheetDocSignId", data.termSheetDocument.signatureId)

    for (let index = 0; index < allBankTermSheetData.length; index++) {
      const element = allBankTermSheetData[index];
      if (signWithBank.includes(element.bankUserId.toString())) {
        formData.append(element.bankUserId + "::" + element.termSheet, otherDocsToSign[index])
        formData.append(element.bankUserId + "::" + element.termSheet + "SignId", otherDocsToSign[index]["signatureId"])
      }
    }

    call("POST", "updateEximBankTermSheetSign", formData).then(res => {
      setshowLoader(false)
      toastDisplay(res, "success", () => {
        history.go(-1)
      })
    }).catch(e => {
      setshowLoader(false)
      toastDisplay('Somethign went wrong', "error")
    })
  }

  async function validateQuote() {
    let err = {}
    if (!data.confirmationFees) {
      err["confirmationFees"] = "Mandatory field"
    }
    if (!(data.termSheetDocument && data.termSheetDocument.name)) {
      err["termSheetDocument"] = "Mandatory field"
    }
    if (data.termSheetDocument && selectedItem.onlySignTermSheet && !data.termSheetDocument.signatureId) {
      err["termSheetDocument"] = "Signing term sheet is mandatory"
    }
    // if (data.draftLCDocument && !data.draftLCDocument.signatureId) {
    //   err["draftLCDocument"] = "Signing draft lc is mandatory"
    // }
    if (!Object.keys(err).length) {
      submitQuote(1)
    }
    setErrors(err)
  }

  return (
    <>{showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <DocumentViewer show={viewDoc.show} value={viewDoc.doc} dismiss={() => toggleViewDoc({ show: false, doc: {} })} />

      <div className="container-fluid">

        <div className="row">
          <SideBarV2 state="sblcQuotationDetails" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"SBLC > Quote Details"}
              userTokenDetails={userTokenDetails} />
            <a className="cursor"
              onClick={() => history.go(-1)}
            ><img className="mb-4" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>

            <div className="">
              <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                <li>
                  <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === 0 ? " formActiveTab show" : "")}
                    onClick={() => setTab(0)}
                  >
                    {"Application Form"}</a>
                </li>
                <li>
                  <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === 1 ? " formActiveTab show" : "")}
                    onClick={() => setTab(1)}
                  >
                    {"Documents"}</a>
                </li>
              </ul>

              <div className="card mt-1 pb-5">

                <p
                  style={{
                    "right": "5%",
                    "fontSize": "3rem",
                    "top": "0rem"
                  }}
                  onClick={() => toggleOptionsPopup(!optionsPopup)}
                  className="position-absolute totalChargesLabel text-right text-color-label">{"..."}</p>
                {optionsPopup ? (
                  <div className="denyConfirmationPopup">
                    <div className="border-bottom d-flex px-3 justify-content-between align-items-center pt-2">
                      <label
                        onClick={() => submitQuote(0)}
                        className="text-color-label">{"Deny Confirmation"}</label>
                    </div>
                  </div>
                ) : null}

                {tab == 0 ? (

                  <div className='px-5 py-4 pt-5 '>
                    {!lcMoreDetails ? (
                      <div className="row">
                        {/* <div className='w-100'>
                          <label className='font-wt-500 font-size-16'><u>Buyer Details</u></label>
                          <div className='row'>
                            {reviewForm.map((item) => {
                              return (
                                // <div className='col-5 p-0'>
                                //   <label className='col-6 lh-18 text-color-label font-size-14'>{item.name}</label>
                                //   <label className='lh-18 font-size-14'>:</label>
                                //   <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                //     {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}
                                //   </label>
                                // </div>
                                <div className="col-md-6 pe-5">
                                  <p className=" d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                                </div>
                              )
                            })}
                          </div>
                        </div> */}

                        <div className='w-100'>

                          <label className='font-wt-500 font-size-16'><u>LC Details</u></label>
                          <div className='row'>
                            {reviewForm3.map((item) => {
                              return (
                                <div className="col-md-6 pe-5">
                                  <p className="d-flex d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                                </div>
                              )
                            })}
                          </div>
                          <p className="mt-3 text-blue cursor" onClick={() => setLCMoreDetails(true)}>View more LC details
                            <img src={"assets/images/right-arrow.png"} alt="Back" height={15} width={15} />
                          </p>
                        </div>

                        <div className='w-100 mt-5'>
                          <label className='font-wt-500 font-size-16'><u>Documents</u></label>
                          <div className='row mt-2'>

                            <div className="col-md-4">
                              <label className="font-size-14">Draft LC</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"draftLCDocument"} value={data.draftLCDocument} error={errors.draftLCDocument}
                                    isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>

                            {/* <div className="col-md-4">
                            <label className="font-size-14">Invoice</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"invoiceDocument"} value={data.invoiceDocument} error={errors.invoiceDocument}
                                  isEditable={false}
                                />
                              </div>
                            </div>
                          </div> */}

                            {/* <div className="col-md-4">
                            <label className="font-size-14">Purchase Order</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"poDocument"} value={data.poDocument} error={errors.poDocument}
                                  isEditable={false}
                                />
                              </div>
                            </div>
                          </div> */}

                            {/* {addMoreDoc.length ? addMoreDoc.map((item, index) => {
                            return (
                              <div className="col-md-4">
                                <label className="font-size-14">Add More</label>
                                <div className="row form-group">
                                  <div className="col-md-12">
                                    <FileInput name={"otherDoc" + index} value={data["otherDoc" + index]}
                                      isEditable={false}
                                    />
                                  </div>
                                </div>
                              </div>
                            )
                          }) : null} */}

                          </div>
                        </div>

                        {/* <div className='w-100 mt-5'>
                        <label className='font-wt-500 font-size-16'><u>Financier selected</u></label>

                        <div className="d-flex">
                          <div className="my-3 col-2">
                            <label className="font11">{LCPurposeObject[data.lcPurpose]}</label>
                            <p
                              className="browse-div font-size-13 cursor text-center m-0 col-10 border-finance text-dark" >
                              {data.selectedFinancierName || 'dfsdfdsf'}
                            </p>
                          </div>

                          {data.lcPurpose === "lc_confirmation" && data.sameBankForLCDiscounting ? (
                            <div className="my-3 col-2">
                              <label className="font11">LC Discounting</label>
                              <p
                                className="browse-div font-size-13 cursor text-center m-0 col-10 border-finance text-dark" >
                                {data.selectedFinancierName || "dfdsfdf"}
                              </p>
                            </div>
                          ) : null}

                        </div>
                      </div> */}


                      </div>
                    ) : null}


                    <div className="">
                      {lcMoreDetails ? <>
                        <img className="mb-2 back-btn cursor" src={"assets/images/back.png"} alt="Back" height={15} width={15} onClick={() => setLCMoreDetails(false)} />
                        <div className='py-4 pt-4 '>
                          <div className="row">
                            <div className='w-100'>
                              <label className='font-wt-500 font-size-16'><u>LC Details</u></label>
                              <div className='row'>
                                {reviewForm3.map((item) => {
                                  return (
                                    <div className='col-md-4'>
                                      <NewInput type={"text"} isDisabled={true} label={item.name}
                                        value={data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}
                                      />
                                    </div>
                                  )
                                })}
                              </div>
                            </div>

                            <div className='w-100 mt-5'>
                              <label className='font-wt-500 font-size-16 mb-4'><u>More LC Details</u></label>
                              <div className='row'>
                                {lcCodes && Object.keys(lcCodes).map((item) => {
                                  return (
                                    <div className='col-md-4'>
                                      <NewTextArea isDisabled={true} label={item + "-" + lcCodes[item]}
                                        value={data["ocrFields"][item] || " "}
                                      />
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </> : null}
                    </div>
                  </div >
                ) : null}

                {
                  tab == 1 ? (
                    <div className='px-5 py-4 pt-5 '>
                      <div className="row">

                        {/* <div className='w-100'> */}

                        <div className="col-md-7 mb-3">
                          <label className="font-size-14">Term Sheet</label>
                          <div className="row align-items-center ">
                            <div className="form-group col-md-8 mb-0">
                              <FileInput isEditable={true} name={"termSheetDocument"} value={data.termSheetDocument} error={errors.termSheetDocument}
                                onChange={handleFile}
                                onUploadCancel={() => setData({ ...data, "termSheetDocument": null })}
                              />
                              {errors.termSheetDocument ? <div class="text-danger mt-2">
                                <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                <b>{errors.termSheetDocument}</b></div> : ''}
                            </div>
                            {secondPhase ? (
                              <>
                                {data.termSheetDocument && data.termSheetDocument.name && !data.termSheetDocument.signatureId ? (
                                  <div className="col-md-4 text-end">
                                    <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.termSheetDocument, docSignName: "termSheetDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                                  </div>
                                ) : null}
                                {data.termSheetDocument && data.termSheetDocument.signatureId ? (
                                  <div className="col-md-4 text-end">
                                    <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                                  </div>
                                ) : null}
                              </>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-md-7 mb-3">
                          <label className="font-size-14">Draft LC</label>
                          <div className="row align-items-center ">
                            <div className="form-group col-md-8 mb-0">
                              <FileInput name={"draftLCDocument"} value={data.draftLCDocument} error={errors.draftLCDocument}
                                onChange={handleFile} isEditable={false}
                                onUploadCancel={() => setData({ ...data, "draftLCDocument": null })}
                              />
                              {errors.draftLCDocument ? <div class="text-danger mt-2">
                                <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                <b>{errors.draftLCDocument}</b></div> : ''}
                            </div>
                            {/* {data.draftLCDocument && data.draftLCDocument.name && !data.draftLCDocument.signatureId ? (
                            <div className="col-md-4 text-end">
                              <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.draftLCDocument, docSignName: "draftLCDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                            </div>
                          ) : null}
                          {data.draftLCDocument && data.draftLCDocument.signatureId ? (
                            <div className="col-md-4 text-end">
                              <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                            </div>
                          ) : null} */}
                          </div>
                        </div>

                        {!secondPhase ? (
                          <div className="col-md-7 mb-3 mt-1">
                            <label className="font-size-14">Select Financier</label>
                            <div className="w-30">
                              <NewTable
                                disableAction={true}
                                columns={[{
                                  name: "Financer Name", filter: false
                                }]}
                                data={tableData}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="col-md-12 mb-3 mt-1">
                            <label className="font-size-14">Termsheet Signature Status</label>
                            <div className="col-md-5">
                              <NewTable
                                tableSubColumnWith={"11rem"}
                                disableAction={true}
                                columns={[{
                                  name: "Financer Name", filter: false
                                },
                                { name: "Signed By", subColumns: ["Financer", "Exim Bank"] }
                                ]}
                                data={tableData}
                              />
                            </div>
                          </div>
                        )}

                      </div>

                      {secondPhase ? (
                        <button type="button"
                          onClick={() => {
                            if (!signWithBank.length) {
                              toastDisplay("Select sign with atleast one bank to continue", "info")
                            }
                            else {
                              signTermSheetWithBank()
                            }
                          }}
                          className={`ml-1 mt-5 new-btn w-20 py-2 px-2 text-white`}>
                          {"Send"}
                        </button>
                      ) : (
                        <button type="button"
                          onClick={
                            () => {
                              if (selectedItem.onlySignTermSheet) {
                                validateQuote()
                              }
                              else {
                                setQuoteForm({ modal: true, page: 1 })
                              }
                            }}
                          className={`ml-1 mt-5 new-btn w-20 py-2 px-2 text-white`}>
                          {"Send Quote"}
                        </button>
                      )}



                    </div>

                  ) : null
                }

                {
                  tab == 0 ? (
                    <button type="button"
                      onClick={() => handleValidation(tab + 1)}
                      className={`ml-5 mt-3 new-btn w-20 py-2 px-2 text-white`}>
                      {"Continue"}
                    </button>
                  ) : null
                }

              </div >
            </div >

          </main>
        </div>
      </div >

      {
        quoteForm.modal && <>
          <div className={`modal fade ${quoteForm.modal && "show"}`} style={{ display: "block" }}>
            <div className="modal-dialog modal-m">
              <div className="modal-content submitmodal pb-4">
                <div className="modal-header border-0">
                  <button type="button" className="btn-close" aria-label="Close" onClick={() => setQuoteForm({ modal: false, page: 0 })}></button>
                </div>
                {(quoteForm.modal && quoteForm.page === 1) ? <div className="modal-body text-center">
                  <p>Quote</p>
                  <div className="row">
                    <div className='w-100'>
                      <div className='row justify-content-center'>
                        <div className='col-md-10'>
                          <InputWithSelect
                            isAstrix={false} type={"number"} label={"Confirmation Fees"}
                            selectData={[{ code: "%" }]}
                            optionValue={"code"} optionLabel={"code"}
                            selectName={data.confirmationFeesCurrency}
                            selectValue={data.confirmationFeesCurrency}
                            name={"confirmationFees"} value={data.confirmationFees} error={errors.confirmationFees}
                            onChange={handleChange} />
                        </div>
                        <div className='col-md-8 mb-3 d-flex justify-content-between'>
                          <input type="radio" id="till_maturity" name="till_maturity"
                            checked={data.till_maturity ? true : false}
                            onChange={() => {
                              setData({ ...data, ["till_maturity"]: !data.till_maturity })
                            }} />
                          <label for="till_maturity">Till maturity</label>
                          <input type="radio" id="till_expiry" name="till_maturity"
                            checked={!data.till_maturity ? true : false}
                            onChange={() => {
                              setData({ ...data, ["till_maturity"]: !data.till_maturity })
                            }}
                          />
                          <label for="till_expiry">Till expiry</label>
                        </div>

                        {data.otherCharges && data.otherCharges.length ? data.otherCharges.map((item, index) => {
                          return (
                            <div className='col-md-10'>
                              <InputWithSelect isDisabled={true}
                                isAstrix={false} type={"number"} label={item["name"]}
                                selectData={item.unit === "%" ? [{ code: "%" }] : most_used_currencies}
                                optionValue={"code"} optionLabel={"code"} selectValue={item.unit}
                                value={item["amount"]} />
                            </div>
                          )
                        }) : null}

                        <div className="col-md-10 text-start pl-2">
                          <a onClick={() => setQuoteForm({ modal: true, page: 2 })}><p>+ Add other charges</p></a>
                        </div>
                        <div className="col-md-10 text-start pl-2">
                          <a onClick={() => setQuoteForm({ modal: true, page: 3 })}><p>+ Add remark</p></a>
                        </div>

                      </div>
                    </div>
                  </div>
                  <button
                    onClick={validateQuote}
                    type="button" className={`new-btn w-20 py-2 px-2 text-white`} >Send Quote</button>
                </div> :
                  (quoteForm.modal && quoteForm.page === 2) ?
                    <>
                      <a className="cursor" onClick={() => {
                        setData({
                          ...data, otherChargeCurrency: "", otherChargePercentage: "", otherChargeTitle: "",
                          otherChargesAmt: ""
                        })
                        setQuoteForm({ modal: true, page: 1 })
                      }} ><img className="mb-3 back-btn" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
                      <div className="modal-body text-center">
                        <p>Add Other Charges</p>

                        {data.otherCharges && data.otherCharges.length ? data.otherCharges.map((item, key) => {
                          return (
                            <div className="row w-100 ml-5">
                              <div className='col-md-8'>
                                <InputWithSelect
                                  isAstrix={false} type={"number"} label={item.name}
                                  selectData={[{ name: item.unit }]}
                                  selectName={"otherChargesAmountUnit"} selectValue={data.otherChargesAmountUnit}
                                  optionValue={"name"} optionLabel={"name"}
                                  name={"otherChargesAmount"} value={item.amount} error={errors.otherChargesAmount}
                                  onChange={handleChange} />
                              </div>
                              <div className='col-md-3'>
                                <img src={"/assets/images/edit.png"} alt="" className="mb-3 mt-2" onClick={() => onEdit(item, key)} />
                                <img src={"/assets/images/cancel-icon.png"} alt="" className="mb-3 px-3 mt-2" onClick={() => onDelete(key)} />
                              </div>
                            </div>
                          )
                        }) : null}

                        <div className="row mt-2">
                          <div className='w-100'>
                            <div className='row'>
                              <div className='col-md-5 ml-5'>
                                <NewSelect isDisabled={data.otherChargePercentage} label={"Select Currency"}
                                  selectData={most_used_currencies} name={"otherChargeCurrency"}
                                  value={data.otherChargeCurrency} optionLabel={"code"} optionValue={'code'}
                                  error={errors.otherChargeCurrency} onChange={handleChange} />
                              </div>
                              <div className='col-md-5'>
                                <NewInput isDisabled={data.otherChargeCurrency} type={"number"} label={"% Percentage"}
                                  onChange={handleChange}
                                  name={"otherChargePercentage"} value={data.otherChargePercentage} error={errors.otherChargePercentage}
                                />
                              </div>
                              <div className='col-md-10 ml-5'>
                                <NewInput isAstrix={false} type={"text"} label={"Title"} onChange={handleChange}
                                  name={"otherChargeTitle"} value={data.otherChargeTitle} error={errors.otherChargeTitle}
                                />
                              </div>
                              <div className='col-md-10 ml-5'>
                                <NewInput isDisabled={data.otherChargePercentage} type={"number"} label={"Enter Charges"} onChange={handleChange}
                                  name={"otherChargesAmt"} value={data.otherChargesAmt} error={errors.otherChargesAmt}
                                />
                              </div>
                              <div className="col-md-10 ml-5">
                                <a onClick={() => {
                                  appendOtherCharge()
                                }}><p>+ Add other charges</p></a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button
                          type="button" className={`new-btn w-20 py-2 px-2 text-white`}
                          onClick={() => {
                            setQuoteForm({ modal: true, page: 1 })
                          }}
                        >Add</button>
                      </div></> :
                    (quoteForm.modal && quoteForm.page === 3) && <>
                      <a className="cursor" onClick={() => setQuoteForm({ modal: true, page: 1 })} ><img className="mb-3 back-btn" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
                      <div className="modal-body text-center">
                        <p>Add Remark</p>
                        <div className="col-md-10 ml-5">
                          <NewTextArea rows={6} isAstrix={false} type={"text"} label={`Write a remark for supplier.`}
                            name={"assignRemark"} value={data.assignRemark} error={errors.assignRemark}
                            onChange={handleChange}
                          />
                        </div>
                        <button type="button" className={`new-btn w-20 py-2 px-2 text-white`}
                          onClick={() => setQuoteForm({ modal: true, page: 1 })}>Add</button>
                      </div></>}
              </div>
            </div>
          </div></>
      }

      {
        signdoc && <SignDocument otherDocsToSign={otherDocsToSign} signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
          setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })}
          setUpdatedOtherDocs={updatedArrDocSigns => {
            setOtherDocsToSign(updatedArrDocSigns)
            toggleSignWithBank(signedBy)
          }} />
      }

    </>
  );
}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(SblcQuotationDetails)