import moment from 'moment'
import React, { useEffect } from 'react'
import { useRef } from 'react'
import { useState } from 'react'
import { FileInput } from '../../../utils/FileInput'
import avatarUrl from '../../../utils/makeAvatarUrl'
import { convertImageToPdf, dataURItoBlob } from '../../../utils/myFunctions'
import toastDisplay from '../../../utils/toastNotification'
import call from '../../../service'



const ChatBoxPopUp2 = ({ userTokenDetails, user_avatar, receiverName, chatList, userId, onChatSend, message, setTextMsg, onPopupClose, parties, reloadChatList }) => {
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  parties = parties?.split("::") || []

  let idWiseUserTypeMapping = parties[3] ? {
    [parties[0]]: "Trader",
    [parties[1]]: "Shipper",
    [parties[2]]: "Admin"
  } : {
    [parties[0]]: "Trader",
    [parties[1]]: "Shipper",
    [parties[2]]: "Admin"
  }

  let chatPartiesForShipper = parties[3] ? [{ name: "Trader", id: parties[0] }, { name: "Buyer", id: parties[3] }, { name: "Admin", id: parties[2] }] :
    [{ name: "Trader", id: parties[0] }, { name: "Admin", id: parties[2] }]

  let chatPartiesForTrader = parties[3] ? [{ name: 'Shipper', id: parties[1] }, { name: "Buyer", id: parties[3] }, { name: 'Admin', id: parties[2] }] :
    [{ name: 'Shipper', id: parties[1] }, { name: 'Admin', id: parties[2] }]

  // let chatPartiesForBuyer = parties[3] ? [{ name: 'Shipper', id: parties[1] }, { name: "Trader", id: parties[0] }, { name: 'Admin', id: parties[2] }] : []

  let chatPartiesForAdmin = parties[3] ? [{ name: 'Shipper', id: parties[1] }, { name: "Trader", id: parties[0] }, { name: "Buyer", id: parties[3] }] :
    [{ name: 'Shipper', id: parties[1] }, { name: "Trader", id: parties[0] }]

  const inputFile = useRef(null)
  const [data, setData] = useState()
  const [errors, setErrors] = useState()
  let [partyNames, setPartyNames] = useState((userTypeId / 1 == 3 || userTypeId / 1 == 4 || userTypeId / 1 == 14) ? chatPartiesForTrader :
    userTypeId / 1 == 5 ? chatPartiesForShipper : chatPartiesForAdmin)
  let [selectedParties, setSelectedParties] = useState([])
  let [userDetails, setUserDetails] = useState({})
  const [showFavOnly, toggleShowFavOnly] = useState(false)

  useEffect(() => {
    if (parties?.[0] && parties?.[1] && parties?.[2]) {
      getUserDetails()
    }
  }, [])

  async function getUserDetails() {
    let tempUserDetails = {}
    let apiResp = await call('POST', 'getUserDetailsById', { contactUserId: parties[0] })
    apiResp = apiResp?.[0]
    tempUserDetails["Trader"] = {
      companyName: apiResp?.company_name || "",
      avatar: apiResp?.user_avatar || ""
    }
    apiResp = await call('POST', 'getUserDetailsById', { contactUserId: parties[1] })
    apiResp = apiResp?.[0]
    tempUserDetails["Shipper"] = {
      companyName: apiResp?.company_name || "",
      avatar: apiResp?.user_avatar || ""
    }
    apiResp = await call('POST', 'getUserDetailsById', { contactUserId: parties[2] })
    apiResp = apiResp?.[0]
    tempUserDetails["Admin"] = {
      companyName: apiResp?.company_name || "",
      avatar: apiResp?.user_avatar || ""
    }
    if (parties[3]) {
      tempUserDetails["Buyer"] = {
        companyName: "Buyer",
        avatar: ""
      }
    }
    setUserDetails(tempUserDetails)
  }

  function mapIdWithShipperName() {
    let temp = []
    for (let index = 0; index < partyNames.length; index++) {
      const element = partyNames[index];
      if (selectedParties.includes(element.name)) {
        temp.push(element.id)
      }
    }
    return temp
  }


  const handleKeyDown = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      if (!selectedParties.length) {
        return toastDisplay("Select parties first", "info")
      }
      console.log();
      onChatSend(null, mapIdWithShipperName())
      setTextMsg('')
    }
  };
  const handleFile = (event, isImage) => {
    event.persist()
    if (!selectedParties.length) {
      return toastDisplay("Select parties first", "info")
    }
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")) || (file_type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")) || (file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png, jpeg, docx & xlsx extension are allowed" })
        return
      }
      console.log('Fileddatataaaa', event.target.files)
      if (event.target.name.includes("otherDoc")) {
        //setAddMoreDoc(addMoreDoc.concat(null))
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result

        if (file_type.includes("png") || file_type.includes("jpeg")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') || file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
          try {
            let formData = new FormData()
            formData.append("file", fileObj)
            if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
              formData.append("docType", "docx")
            }
            if (file_type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
              formData.append("docType", "xlsx")
            }
            let apiResp = await call('POST', "docToPdf", formData)
            let blob = dataURItoBlob(apiResp);
            fileObj = new File([blob], fileObj.name + "converted.pdf", { type: "application/pdf" });
            fileDataUrl = apiResp
            toastDisplay("File converted into pdf format", "success")
          } catch (error) {
            return toastDisplay("failed to convert file", "error")
          }
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        console.log('File Uploaded', event.target.files[0])
        onChatSend(event.target.files[0], mapIdWithShipperName())
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }
  // console.log("chatListttttttttttttttttttttttt", chatList);

  return (
    <div style={{ height: '31rem', width: '550px' }} className='chatBox'>
      <div className='chatHeader h-auto d-flex justify-content-between align-items-center'>
        <div className='d-flex gap-3 align-items-center'>
          <div className="chatImgCont">
            <img alt={userDetails?.Shipper?.avatar || '/assets/images/user-profile.png'}
              src={userDetails?.Shipper?.avatar ? avatarUrl(userDetails?.Shipper?.avatar) : "/assets/images/user-profile.png"}
              className="rounded-circle chat_user_img ml-2 mt-2 mb-2" />
            <span className="online_icon"></span>
          </div>
          <div>
            <p className='SenderLabel mb-0 ml-0'>{receiverName}</p>
            {/* <p className='mb-0 font-size-13 text-secondary'>Active now</p> */}
          </div>
        </div>
        <div>
          <img
            style={{ width: "20px", height: "20px" }}
            src={showFavOnly ? "assets/images/fav_filled_blue.png" : "assets/images/fav_empty_blue.png"} alt="" className='cursor mb-0 mr-3' onClick={() => {
              toggleShowFavOnly(!showFavOnly)
            }} />
          <img src={"/assets/images/cross-dark.svg"} alt="" className='cursor mb-0 mr-3' onClick={onPopupClose} />
        </div>
      </div>
      <div style={{ padding: '15px' }} className='chatContainerPopup'>
        {chatList.map((key, index) => {
          // console.log("chatLissssssssssssssssssssssss", key, userDetails);
          let tempReceiverNames = []
          let tempReceiverPartyIds = key.receiverParties?.split(",") || []
          for (let index = 0; index < key.receiverParties?.split(",")?.length; index++) {
            const element = key.receiverParties?.split(",")[index];
            tempReceiverNames.push(idWiseUserTypeMapping[element])
          }
          let userTypeName = idWiseUserTypeMapping[key.senderId]
          let isMarkedAsFav = key.favourite?.split(",")?.includes(userId.toString()) || null
          let isMarkedAsDone = key.markDone?.split(",")?.includes(userId.toString()) || null
          if (userTypeId / 1 == 1 || userId === key.senderId || tempReceiverPartyIds?.includes(userId.toString())) {
            let shouldShowMsg = false
            if (showFavOnly) {
              if (isMarkedAsFav) {
                shouldShowMsg = true
              }
            }
            else {
              shouldShowMsg = true
            }
            if (shouldShowMsg) {
              return (
                <div className={userId === key.senderId ? 'chatsend d-flex w-65 ms-auto justify-content-end gap-2 align-items-center mt-4 ' : 'messages d-flex w-65 justify-content-start gap-2 align-items-center mt-3'}>
                  <div
                    style={{ width: '20rem' }}
                    className={`${userId === key.senderId ? 'meetingLink' : 'messagesend'}  d-flex p-2 gap-2 position-relative`}>
                    <div style={{ right: '10px' }} className="position-absolute">
                      <img
                        onClick={async () => {
                          await call('POST', 'markChat', { type: 'favourite', action: !isMarkedAsFav ? "Add" : "Remove", msgId: key.id, userId })
                          reloadChatList()
                        }}
                        style={{ width: '20px', height: '20px' }}
                        className='cursor ml-2' src={isMarkedAsFav ? "assets/images/fav_filled_blue.png" : "assets/images/fav_empty_blue.png"} />
                      <img
                        onClick={async () => {
                          await call('POST', 'markChat', { type: 'markDone', action: !isMarkedAsDone ? "Add" : "Remove", msgId: key.id, userId })
                          reloadChatList()
                        }}
                        style={{ width: '20px', height: '20px' }}
                        className='cursor ml-2' src={isMarkedAsDone ? "assets/images/checked-green.png" : "assets/images/unchecked-box.png"} />
                    </div>

                    <div className='w-100 message'>

                      {userId / 1 != key.senderId / 1 ? <div
                        className='d-flex w-100 align-items-center'
                      ><img alt={userDetails?.["userTypeName"]?.avatar || '/assets/images/user-profile.png'}
                        src={userDetails?.[userTypeName]?.avatar ? avatarUrl(userDetails?.[userTypeName]?.avatar) : "/assets/images/user-profile.png"}
                        className="rounded-circle chat_user_img ml-2 mt-2 mb-2" />
                        <label className='ml-2'>{`${userDetails?.[userTypeName]?.companyName}`}</label>
                      </div> : null}
                      <div className='d-flex row w-100 mx-1 mb-2'>
                        {tempReceiverNames?.map((i, j) => {
                          return (
                            <div
                              style={{ background: '#fff' }}
                              className={`w-auto mr-2 selectedParty cursor`}>
                              <label className='cursor'>{`@${i}`}</label>
                              {key.markDone?.split(",")?.includes(tempReceiverPartyIds[j].toString()) ?
                                <img className='ml-2' style={{ width: '15px', height: '15px' }} src="assets/images/checked-green.png" /> : null}
                            </div>
                          )
                        })}
                      </div>
                      {key.chatFile ?
                        <FileInput value={key.chatFile} />
                        : <p className='mb-0' style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: key.text_message }}></p>
                      }
                    </div>
                    <div className='w-auto mt-auto '>
                      <p className='text-secondary font-size-12 mb-0 '>{moment(key.createdAt).format("HH:mm")}</p>
                    </div>
                  </div>
                </div>
              );
            }
          }
        })}
      </div>
      <div className='chatBottomDiv '>
        <div className='d-flex row w-100 p-2 ml-2'>
          {partyNames?.map((i, j) => {
            return (
              <div
                onClick={() => {
                  if (selectedParties.includes(i.name)) {
                    let temp = selectedParties
                    temp = temp.filter((m) => {
                      if (m != i.name) {
                        return true
                      }
                    })
                    setSelectedParties([...temp])
                  }
                  else {
                    let temp = selectedParties
                    temp.push(i.name)
                    setSelectedParties([...temp])
                  }
                }}
                className={`w-auto ${!selectedParties.includes(i.name) ? ' unSelectedParty ' : ' selectedParty '} mx-2 cursor`}>
                <label className='cursor'>{`@${i.name}`}</label>
                {selectedParties.includes(i.name) ?
                  <img className='cursor' src={"assets/images/close-schedule.png"} /> :
                  <img className='cursor' src="assets/images/add-participant.png" />}
              </div>
            )
          })}
          {/* <div className='w-10'>
            <img src="assets/images/add-participant.png" />
          </div> */}
        </div>
        <div className='d-flex px-2'>
          <div className='w-auto me-1 z-index99'>
            <div className="btn-group mt-1">
              <img onClick={(e) => inputFile.current.click()} src={'/assets/images/icon_attachment.png'} alt='' className="cursor" />
              <input type='file' id='inputFile' ref={inputFile} name="chatDoc" style={{ display: 'none' }} onChange={(e) => {
                handleFile(e)
              }} />
            </div>
          </div>
          <input className='form-control w-100 border send-btn' type="text" placeholder="Type" value={message} onChange={(e) => {
            e.preventDefault()
            setTextMsg(e.target.value)
          }} onKeyDown={handleKeyDown} />
        </div>
      </div>

    </div>
  )
}

export default ChatBoxPopUp2
