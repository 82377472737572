import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import call from '../../service';
import { FileInput } from '../../utils/FileInput';
import avatarUrl from '../../utils/makeAvatarUrl';
import MultipleSelect from '../../utils/MultipleSelect';
import { astrix, ClearCache, convertImageToPdf, dataURItoBlob, GetCache, getDocDetails, most_used_currencies, multiSelectEventHandler, SetCache, toolTip, userPlanQuotaFetch } from '../../utils/myFunctions';
import { InputWithSelect, NewInput, NewSelect } from '../../utils/newInput';
import { NewTable } from '../../utils/newTable';
import { StarRating } from '../../utils/starRating';
import toastDisplay from '../../utils/toastNotification';
import Header from '../partial/header';
import HeaderV2 from '../partial/headerV2';
import Sidebar, { inspectionTypeIds, traderTypeIds } from '../partial/sidebar';
import SideBarV2 from '../partial/sideBarV2';
import { PopupMessage } from '../popupMessage';
import config from '../../config.json';
import moment from 'moment';
import TutorialPopup, { TutorialVideoPopup } from '../tutorialPopup';
import ChatBoxPopUp2 from '../chatRoom/components/ChatBoxPopUp2';


import { getSocket } from '../../socket'
import NewTablev2 from '../../utils/newTablev2';
import { dateFormatter_DatePicker, formatDate_Application } from '../../utils/dateFormaters';
import { getStatusDisplayName } from '../dataTablesColumsMap/contractListColoums';
import { Action } from '../myCounterPartComp/action';
import Filter from '../InvoiceDiscounting/components/Filter';
import Pagination from '../InvoiceDiscounting/contract/components/pagination';
import { DocumentViewer } from '../../utils/documentViewer';
import { DocumentDownloader } from '../../utils/DocumentDownloader';
import { getTraderType, setTraderType } from '../../utils/cookieHelper';
import { setContractState, setaddBankObj, setClientType } from '../../store/actions/action';
import ReactCountryFlag from 'react-country-flag';
import MultipleSelectForTable from '../../utils/MultipleSelectForTable';
import validateContractPilot from '../../utils/validation_files/editContractValidationRules';
import validate from '../../utils/validation_files/createContractValidationRules';
import price_in_words from '../../utils/wordtoNumber';
import SpecificationsPreview from '../specifications/specificationPreview';
import SpecificationsForm from '../specifications/specificationForm'
import AddBankComponent from '../utilComponents/addBankComponent';
import TncForm from '../tncComponents/tncForms';
import DocumentACL from '../tradeContract/documentACL';
import commImageUrl from '../../utils/makeCommImageUrl';
import { useHistory } from "react-router";
import FinanceInvoiceModal from '../InvoiceDiscounting/contract/components/financeinvoiceModal';


const CreateWorkOrderContract = ({ navToggleState, userTokenDetails, setContractState, setaddBankObj, addBankObj, clientType, dispatch,
  setClientType }) => {

  const queryParams = new URLSearchParams(window.location.search)

  let contractState = {
    modal: true,
    info: {
      modalMode: queryParams.get("type") || "create",
      contractNo: queryParams.get("contractNo")
    }
  }
  console.log("contractStateeeeeeeeeeeeee", contractState);
  //---------------------------------------------------------------------------------------------------------------------

  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const [traderActiveType, setTraderActiveType] = useState(getTraderType())

  const astrix = <span className="required-field text-danger">*</span>


  // States and variables
  const [data, setData] = useState({
    'contractType': 'FOB',
    "paymentMode": 1,
    'quantity': 0
  });
  const [supplierInfo, setSupplierInfo] = useState({})
  const [dbSupplier, setDbSupplier] = useState([])
  const [activeClass, setActiveClass] = useState(1);
  const [tempactiveClass, settempactiveClass] = useState(1);
  const [maxactiveClass, setmaxactiveClass] = useState(1);
  const [contract, setContract] = useState({
    state: 0,
    col: 'FOB'
  });
  const [viewAccordion, setViewAccordion] = useState(0);
  const [commodity, setCommodity] = useState(false);
  const [supplier, setSupplier] = useState(false);
  const [changelogs, setchangelogs] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState({ state: false, name: '', location: '' });
  const [spec, setSpecification] = useState({});
  const [errorData, seterrorData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [agency, setAgency] = useState([]);
  const [insurers, setInsurers] = useState([]);
  const [portLoading, setPortLoading] = useState([]);
  const [portUnloading, setPortUnloading] = useState([]);
  const [buyerBank, setBuyerBank] = useState([]);
  const [sellerBank, setSellerBank] = useState([]);
  const [refresh, setrefresh] = useState(0)
  const [dataUpdate, setDataUpdate] = useState({});
  const [dataTnc, setdataTnc] = useState({});
  const [tabing, settabing] = useState(false);
  const [showLoader, setshowLoader] = useState(false);
  const [currencyData, setcurrencyData] = useState([]);
  const [paymentModes, setpaymentModes] = useState([]);
  const [commCategory, setCommCategory] = useState([]);
  const [supplierAvatar, setsupplierAvatar] = useState('');
  const [supplierCommodityList, setsupplierCommodityList] = useState([]);
  const [countryData, setcountryData] = useState([]);
  const [contractEditMode, setcontractEditMode] = useState(false);
  const [refreshSupplier, setrefreshSupplier] = useState(0)
  const [supplierSearch, setsupplierSearch] = useState('')
  const [editCount, setEditCount] = useState(0);
  // const [extraTnc, setextraTnc] = useState([]);
  const [docCategoryACL, setdocCategoryACL] = useState({});
  const [quotaAvail, setQuotaAvail] = useState(false)
  const [specChange, setspecChange] = useState(0)
  const [tncChange, settncChange] = useState(0)
  const [specOrder, setspecOrder] = useState([])
  const [tncOrder, settncOrder] = useState([])

  const [searchUserForListing, setSearchUserForListing] = useState("")
  const [trader, setTrader] = useState(traderActiveType);
  const [contractCopy, setContractCopy] = useState("Yes");

  const [tabsArr, setTabsArr] = useState(userTypeId / 1 == 14 ? [
    { name: "Contract Info" },
    { name: "Specification" },
    { name: "Quantity & Price" },
    { name: "Payment Details" },
    { name: "T&C" },
    { name: "Document ACL", hide: true },
    { name: "Review" }
  ] : [
    { name: "Contract Info" },
    { name: "Specification" },
    { name: "Quantity & Price" },
    { name: "Payment Details" },
    { name: "T&C" },
    { name: "Review" }
  ])
  const [traderOnboardForm, toggleTraderOnboardForm] = useState({ show: false, data: {} })
  const [userData, setUserData] = useState({});

  let history = useHistory()

  useEffect(() => {
    call('POST', 'getuserprofiledata', { 'email': userEmail, 'min': true }).then((result) => {
      console.log("result in getuserprofiledata-->", result)
      setUserData(result)
    }).catch((e) => {
      console.log('error in getuserprofiledata', e);
    })
  }, []);


  useEffect(() => {
    if (!queryParams.get("contractNo")) {
      if (!(aclMap.contract_access && aclMap.contract_access.cont_create && aclMap.contract_access.cont_create.isExist)) {
        toastDisplay("You don't have permission to create contract", "info", () => {
          history.go(-1)
        })
      }
      let planData = userPlanQuotaFetch()
      if (!planData?.trades?.limitLeft) {
        toastDisplay("You have already used 100% of commodity contracts quota", "info", () => {
          history.go(-1)
        })
      }
    }
  }, [])



  //Submit Handlers
  const handleSubmit = () => {
    console.log("dataaaaaaaBeforeSubmittttttttttttttttttt", data);
    let formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key != "commodity" && key != 'commodity_pretty_name' && key != "loadingCountry" && key != "unloadingCountry" && key != "currency") {
        formData.append(key, data[key]);
      }
    })

    let selectedLoadingCountry = countryData.filter(i => {
      if (i.id / 1 == data.loadingCountry / 1) {
        return true
      }
    })?.[0] || {}
    let selectedUnloadingCountry = countryData.filter(i => {
      if (i.id / 1 == data.unloadingCountry / 1) {
        return true
      }
    })?.[0] || {}
    let selectedCurrency = currencyData.filter(i => {
      if (i.code === data.currency) {
        return i
      }
    })?.[0] || {}

    if (contractCopy) {
      formData.append("contractCopy", contractCopy)
    }

    formData.append("commodity", data.commodity_pretty_name + ":" + data.commodity)
    formData.append("loadingCountry", selectedLoadingCountry.sortname + ":" + data.loadingCountry)
    formData.append("unloadingCountry", selectedUnloadingCountry.sortname + ":" + data.unloadingCountry)
    formData.append("currency", selectedCurrency.name + ":" + selectedCurrency.symbol + ":" + selectedCurrency.code)

    formData.append("specification", JSON.stringify(spec));
    formData.append("contractTnc", JSON.stringify(dataTnc));
    formData.append("docCategoryACL", JSON.stringify(docCategoryACL));
    formData.append("orderJson", JSON.stringify({ specOrder: specOrder && specOrder.length ? specOrder.join(":") : "", tncOrder: tncOrder.join(":") }));

    call('POST', 'initContract', formData).then((result) => {
      if (result) {
        setshowLoader(false)
        toastDisplay("Contract created", "success", () => {
          window.location = '/workOrderContracts'
        })
        // setContractState(false, { refresh: true })
        // setrefresh(refresh + 1)
      }
    }).catch(e => {
      setshowLoader(false)
      toastDisplay(e, "error")
      // setContractState()
    })
  }

  const agreement = () => {

    let formData = new FormData();

    formData.append("user_type", userTypeId);
    formData.append("loggedUserID", userId);
    formData.append("userTypeClient", traderActiveType ? traderActiveType : '');
    formData.append("contract_id", data.contract_id);
    formData.append("contract_number", data.contract_number);

    call('POST', 'agreeContract', formData).then((result) => {
      if (result) {
        setshowLoader(false)
        // setContractState(false, { refresh: true })
        toastDisplay("Contract agreed successfully", "success", () => {
          window.location = '/workOrderContracts'
        })
      }
    }).catch(e => {
      console.log("Error while arreeing contract:", e);
      setshowLoader(false)
      toastDisplay(e, "error")
    })
  }

  const handleSubmitUpdate = (operator) => {

    var formData = new FormData();
    Object.keys(dataUpdate).forEach((key) => {
      if (key != "commodity" && key != 'commodity_pretty_name' && key != "loadingCountry" && key != "unloadingCountry" && key != "currency") {
        formData.append(key, dataUpdate[key]);
      }
    })

    let selectedLoadingCountry = null
    let selectedUnloadingCountry = null
    let selectedCurrency = null

    if (dataUpdate.loadingCountry) {
      selectedLoadingCountry = countryData.filter(i => {
        if (i.id / 1 == data.loadingCountry / 1) {
          return true
        }
      })?.[0] || {}
      formData.append("loadingCountry", selectedLoadingCountry.sortname + ":" + data.loadingCountry)
    }
    if (dataUpdate.unloadingCountry) {
      selectedUnloadingCountry = countryData.filter(i => {
        if (i.id / 1 == data.unloadingCountry / 1) {
          return true
        }
      })?.[0] || {}
      formData.append("unloadingCountry", selectedUnloadingCountry.sortname + ":" + data.unloadingCountry)
    }
    if (dataUpdate.currency) {
      selectedCurrency = currencyData.filter(i => {
        if (i.code === data.currency) {
          return i
        }
      })?.[0] || {}
      formData.append("currency", selectedCurrency.name + ":" + selectedCurrency.symbol + ":" + selectedCurrency.code)
    }

    let showToStringArrray = data.exporter_id + "," +
      data.importer_id + "," +
      data.investigationAgencyImporter / 1 + "," +
      data.investigationAgencyExporter / 1 + "," +
      data.investigationAgencythird / 1 + "," +
      data.commodityInsurer / 1 + "," +
      data.tradeInsurer / 1 + "," +
      data.supplierBank / 1 + "," +
      data.buyerBank / 1

    formData.append("showToStringArrray", showToStringArrray);
    formData.append("user_type", userTypeId);
    formData.append("loggedUserID", userId);
    formData.append("importer_id", data.importer_id);
    formData.append("exporter_id", data.exporter_id);
    formData.append("userTypeClient", traderActiveType ? traderActiveType : '');
    formData.append("contract_id", data.contract_id);
    formData.append("contract_number", data.contract_number);
    if (specChange / 1 > 0) {
      formData.append("specification", JSON.stringify(spec));
    }
    if (tncChange / 1 > 0) {
      formData.append("contractTnc", JSON.stringify(dataTnc));
    }

    if (specChange / 1 > 0 || tncChange / 1 > 0) {
      formData.append("orderJson", JSON.stringify({ specOrder: specOrder ? specOrder.join(":") : "", tncOrder: tncOrder.join(":") }));
    }

    formData.append("docCategoryACL", JSON.stringify(docCategoryACL));
    formData.append("specChange", specChange);
    formData.append("tncChange", tncChange);


    formData.delete("supplierAvatar")
    formData.delete("buyerAvatar")
    // console.log('Here --->>');
    call('POST', 'updateContract', formData).then((result) => {
      if (result) {
        if (operator === "U&A") {
          agreement()
        } else {
          setshowLoader(false)
          // setContractState(false, { refresh: true })
          toastDisplay("Contract updated successfully", "success", () => {
            window.location = '/workOrderContracts'
          })
        }
      }
    }).catch(e => {
      console.log("Error while Updating contract:", e);
      setshowLoader(false)
      toastDisplay(e, "error")
    })
  }

  useEffect(() => {

    if (contractState.info.modalMode === "create") {
      if (userEmail) {
        // call('POST', 'getUserBanks', { email: userEmail }).then((result) => {
        //   // console.log("result in bank api->", result)
        //   if (traderActiveType === 'buyer') {
        //     setBuyerBank(result)
        //   } else {
        //     setSellerBank(result)
        //   }
        // }).catch((e) => {
        //   console.log("Error while querying getUserBanks:", e);
        // })

        call('GET', 'getBanksListMaster', {}).then((result) => {
          // console.log("result in bank api->", result)
          if (traderActiveType === 'buyer') {
            setBuyerBank(result)
          } else {
            setSellerBank(result)
          }
        }).catch((e) => {
          console.log("Error while querying getUserBanks:", e);
        })
      }

      if (supplierInfo.user_name) {
        // call('POST', 'getUserBanks', { email: supplierInfo.user_name }).then((result) => {
        //   // console.log("result in bank api->", result)
        //   if (traderActiveType === 'buyer') {
        //     setSellerBank(result)
        //   } else {
        //     setBuyerBank(result)
        //   }
        // }).catch((e) => {
        //   console.log("Error while querying getUserBanks:", e);
        // })

        call('GET', 'getBanksListMaster', {}).then((result) => {
          // console.log("result in bank api->", result)
          if (traderActiveType === 'buyer') {
            setSellerBank(result)
          } else {
            setBuyerBank(result)
          }
        }).catch((e) => {
          console.log("Error while querying getUserBanks:", e);
        })
      }
    } else {
      if (data.supplierEmail) {
        // call('POST', 'getUserBanks', { "email": data.supplierEmail }).then((result) => {
        //   // console.log('api result for getUserBanks supplierEmail', result);
        //   setSellerBank(result)
        // }).catch((e) => {
        //   console.log("Error while querying getUserBanks for seller:", e);
        // })

        call('GET', 'getBanksListMaster', {}).then((result) => {
          // console.log('api result for getUserBanks supplierEmail', result);
          setSellerBank(result)
        }).catch((e) => {
          console.log("Error while querying getUserBanks for seller:", e);
        })
      }

      if (data.buyerEmail) {
        // call('POST', 'getUserBanks', { "email": data.buyerEmail }).then((result) => {
        //   //  console.log('api result for getUserBanks buyerEmail', result);
        //   setBuyerBank(result)
        // }).catch((e) => {
        //   console.log("Error while querying getUserBanks for buyer:", e);
        // })

        call('POST', 'getBanksListMaster', {}).then((result) => {
          //  console.log('api result for getUserBanks buyerEmail', result);
          setBuyerBank(result)
        }).catch((e) => {
          console.log("Error while querying getUserBanks for buyer:", e);
        })
      }
    }
  }, [addBankObj.info.refresh, supplierInfo]);

  useEffect(() => {
    //------------------------------------------------------------------
    //API Calls

    if (contractState.info.modalMode === "create") {
      if (traderActiveType === 'buyer') {
        setData({
          ...data,
          'user_type': userTypeId,
          'buyerEmail': userEmail,
          'importer_id': userId,
          'buyerName': userName
        })
      } else {
        setData({
          ...data,
          'user_type': userTypeId,
          'supplierEmail': userEmail,
          'exporter_id': userId,
          'supplierName': userName,
        })
      }

      call('POST', 'getjsonfromdb', { "jsonFor": "fobTNCcoal" }).then((result) => {
        console.log("getjsonfromdb:=>", result);
        setdataTnc(result.json_data)
        settncOrder(result.order_array.split(":"))
      }).catch((e) => {
        console.log("Error while querying getjsonfromdb:", e);
      })

      call('POST', 'getAgencys', { traderId: (userTypeId === 14 ? userId : null) }).then((result) => {
        setAgency(result)
      }).catch((e) => {
        console.log("Error while querying getAgencys:", e);
      })

      call('POST', 'getinsuranceagencylist', { traderId: (userTypeId === 14 ? userId : null) }).then(async (result) => {
        setInsurers(result)
      }).catch((error) => {
        console.log("error occur in getinsuranceagencylist ->", error)
      })

      call('get', 'v1/plan/quota').then((result) => {
        if (result && result.trades && result.trades.enable && ((result.trades.limitLeft / 1) > 0 || (result.trades.limitLeft / 1) === -1))
          setQuotaAvail(true)
      }).catch((e) => {
        console.log("Error while  getUserCurrentPlanQuota header:-->", e);
      })

    } else {
      setshowLoader(true)
      setQuotaAvail(true)

      call('POST', 'getContracts', { "contract_number": contractState.info.contractNo, "contractOnly": true, "type_id": userTypeId }).then((result) => {
        console.log("api result get contract data--->", result)
        let tempTraderActiveType = result.importer_id / 1 == userId / 1 ? "buyer" : 'seller'
        setTraderActiveType(tempTraderActiveType)
        setData({
          "user_type": userTypeId,
          "loggedUserID": userId,
          "userTypeClient": tempTraderActiveType ? tempTraderActiveType : '',
          contract_id: result.id ? result.id : '',
          contract_type: result.contract_type ? result.contract_type : '',
          contractType: result.contract_type ? result.contract_type : '',
          contract_number: result.contract_number ? result.contract_number : '',
          contractAlias: result.contract_name ? result.contract_name : '',
          commodity_id: result.commodity_id ? result.commodity_id : '',
          commFilename: result.comm_filename ? result.comm_filename : '',
          quantity: result.quantity ? result.quantity : '',
          currency: result.currency ? result.currency.split(":")[2] : '',
          laycanStart: result.laycan_start ? dateFormatter_DatePicker(result.laycan_start) : '',
          laycanEnd: result.laycan_end ? dateFormatter_DatePicker(result.laycan_end) : '',
          supplierBank: result.supplier_bank,
          buyerBank: result.buyer_bank,
          price: result.price ? result.price : '',
          priceType: result.price_type ? result.price_type : '',
          loadingCountry: result.load_country ? result.load_country.split(":")[1] / 1 : '',
          unloadingCountry: result.unload_country ? result.unload_country.split(":")[1] / 1 : '',
          loadingPort: result.loading_port ? result.loading_port : '',
          unloadingPort: result.unloading_port ? result.unloading_port : '',
          supplierName: result.suppliername ? result.suppliername : '',
          buyerName: result.buyername ? result.buyername : '',
          supplierEmail: result.supplier_email ? result.supplier_email : '',
          buyerEmail: result.buyer_email ? result.buyer_email : '',
          commodity_name: result.commodity_name ? result.commodity_name : '',
          exporter_id: result.exporter_id ? result.exporter_id : '',
          importer_id: result.importer_id ? result.importer_id : '',
          investigationAgencyImporter: result.inv_agency_importer,
          investigationAgencyExporter: result.inv_agency_exporter,
          investigationAgencythird: result.inv_agency_third,
          commodityInsurer: result.commodity_insurer,
          tradeInsurer: result.trade_insurer,
          contract_type_id: result.contract_type_id ? result.contract_type_id : '',
          reference_contract: result.reference_contract ? result.reference_contract : 0,
          investigation_status: result.investigation_status ? result.investigation_status : '',
          payment_status: result.payment_status,
          created_by: result.created_by ? result.created_by : '',
          quotation_status: result.quotation_status ? result.quotation_status : '',
          shipment_status: result.shipment_status ? result.shipment_status : '',
          paymentMode: result.payment_mode ? result.payment_mode : '',
          rejection_level: result.rejection_level ? result.rejection_level : '',
          status_buyer: result.status_buyer,
          status_seller: result.status_seller,
          commenter: userId,
          supplierAvatar: result.supplieravatar ? result.supplieravatar : '',
          buyerAvatar: result.buyeravatar ? result.buyeravatar : '',
          edit_request: result.edit_request
        })

        // fillTNCValue(result.tnc ? JSON.parse(result.tnc) : {}, result.contract_type)
        setCommodity(result.commodity_id)
        setdataTnc(result.tnc)

        if (((userTypeId === 14 || userTypeId === 3) && result.status_buyer === 0) || ((userTypeId === 4) && result.status_seller === 0)) {
          setcontractEditMode(true)
        }

        call('POST', 'getAgencys', { traderId: (userTypeId === 14 ? userId : result.importer_id) }).then((result) => {
          setAgency(result)
        }).catch((e) => {
          console.log("Error while querying getAgencys:", e);
        })

        call('POST', 'getinsuranceagencylist', { traderId: (userTypeId === 14 ? userId : result.importer_id) }).then((result) => {
          setInsurers(result)
        }).catch((error) => {
          console.log("error occur in getinsuranceagencylist ->", error)
        })

        call('POST', 'getCountryPort', { 'countryId': result.load_country.split(':')[1] }).then((result) => {
          // console.log('setPortLoading in getCountryPort-->', result);
          setPortLoading(result)
        }).catch((e) => {
          console.log('error in getCountryPort', e);
        })
        call('POST', 'getCountryPort', { 'countryId': result.unload_country.split(':')[1] }).then((result) => {
          //console.log('setPortUnloading in getCountryPort-->', result);
          setPortUnloading(result)
        }).catch((e) => {
          console.log('error in getCountryPort', e);
        })

        setSpecification(result.specification)
        setspecOrder(result.json_order.specOrder ? result.json_order.specOrder.split(":") : "")
        settncOrder(result.json_order.tncOrder.split(":"))
        loadbanks(result.buyer_email, result.supplier_email)
        setshowLoader(false)

      }).catch((e) => {
        setshowLoader(false)
        console.log("error in view contract useeffect-->", e)
      })

    }

    call('get', 'getcurrencylist').then((result) => {
      setcurrencyData(result)
    }).catch((e) => {
      console.log('error in getcurrencylist', e);
    })

    call('get', 'getpaymentmodes').then((result) => {
      console.log("getpaymentmodes api result->", result)
      setpaymentModes(result)
    }).catch((e) => {
      console.log('error in getcurrencylist', e);
    })

    call('get', 'getallCountry').then((result) => {
      setcountryData(result)
    }).catch((e) => {
      console.log('error in getallCountry', e);
    })

    //------------------------------------------------------------------

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  var loadbanks = (buyerEmail, sellerEmail) => {
    // call('POST', 'getUserBanks', { email: sellerEmail }).then((result) => {
    //   // console.log('api result for getUserBanks supplierEmail load', result);
    //   setSellerBank(result)
    // }).catch((e) => {
    //   console.log("Error while querying getUserBanks for seller:", e);
    // })

    // call('POST', 'getUserBanks', { email: buyerEmail }).then((result) => {
    //   //console.log('api result for getUserBanks supplierEmail load', result);
    //   setBuyerBank(result)
    // }).catch((e) => {
    //   console.log("Error while querying getUserBanks for buyer:", e);
    // })

    call('GET', 'getBanksListMaster', {}).then((result) => {
      //console.log('api result for getUserBanks supplierEmail load', result);
      setBuyerBank(result)
      setSellerBank(result)
    }).catch((e) => {
      console.log("Error while querying getUserBanks for buyer:", e);
    })
  }

  useEffect(() => {
    if (contractState.info.modalMode === "create") {
      call('POST', 'getUsersListing', { "searchUserType": (traderActiveType === 'buyer') ? 4 : 3, "searchKey": searchUserForListing, "network": "innetwork", "userAvatars": true }).then((result) => {
        // console.log("result of api in getUsersListing-->", result)
        let tempResult = result || []
        if (tempResult.length) {
          for (let index = 0; index < tempResult.length; index++) {
            tempResult[index][`html`] = <div>
              <label className="font-size-12 font-wt-500" >{`${tempResult[index]["company_name"]}`}
                <ReactCountryFlag
                  countryCode={tempResult[index].country_code}
                  style={{ width: '20px', height: '20px', marginLeft: '1rem' }} svg />
              </label>
            </div>
          }
          tempResult.push({ 'html': <label className='font-size-13 font-wt-400' >Add New</label>, company_name: 'Add New', id: 'Add New' })
          setDbSupplier(tempResult)
        }
        else {
          tempResult.push({ 'html': <label className='font-size-13 font-wt-400' >Add New</label>, company_name: 'Add New', id: 'Add New' })
          setDbSupplier(tempResult)
        }
      }).catch((e) => {
        console.log('error in getUsersListing', e);
      })
    }
  }, [refreshSupplier, clientType.type, searchUserForListing]);

  useEffect(() => {
    if (Object.keys(errorData).length === 0 && isSubmitting) {
      doNext();
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [errorData]);

  // console.log("activeClasssssssssssssssssssssss", activeClass);

  const doNext = () => {
    if (tabing === true) {
      setActiveClass(tempactiveClass);
      setIsSubmitting(false)
      settabing(false)
    } else {
      if (activeClass >= maxactiveClass) {
        if (activeClass / 1 == 5) {
          setmaxactiveClass(7)
        }
        else {
          setmaxactiveClass(activeClass + 1)
        }
      }
      if (activeClass / 1 == 5) {
        setActiveClass(7)
      }
      else {
        setActiveClass(activeClass + 1);
      }
      setIsSubmitting(false)
    }
  }


  const handleChange = (event) => {
    event.persist();

    if (contractState.info.modalMode === "edit") {
      setDataUpdate(dataUpdate => ({ ...dataUpdate, [event.target.name]: event.target.value }));
    }
    setEditCount(editCount + 1)
    if (event.target.name === "commodity" && event.target.value) {
      let selectedCommodity = supplierCommodityList.filter(item => { if (item.id === event.target.value) { return item } })[0]
      setSpecification(selectedCommodity.specifications ? selectedCommodity.specifications : {});
      setspecOrder(selectedCommodity.spec_order_string ? selectedCommodity.spec_order_string.split(":") : "")
      setData(data => ({ ...data, [event.target.name]: event.target.value, commodity_pretty_name: selectedCommodity.commodity_pretty_name }));
      seterrorData(errorData => ({ ...errorData, [event.target.name]: "" }))
    }
    else {
      setData(data => ({ ...data, [event.target.name]: event.target.value }));
      seterrorData(errorData => ({ ...errorData, [event.target.name]: "" }))
    }
  };

  const handleSelectedSupplier = (value) => {
    console.log("value in supplier-->", value)
    setSelectedSupplier({
      state: true,
      supplierId: value.id ? value.id : 0,
      name: value.company_name ? value.company_name : '',
      location: value.country_code ? value.country_code : '',
      address: value.user_address ? value.user_address : '',
    });
    setSupplierInfo(value)
    setsupplierAvatar(value.user_avatar ? value.user_avatar : '')
    seterrorData(errorData => ({ ...errorData, supplierName: "" }))

    if (traderActiveType === 'buyer') {
      setData(data => ({
        ...data,
        'supplierName': value.company_name ? value.company_name : '',
        'supplierAddress': value.country_code ? value.country_code : '',
        'supplierEmail': value.user_name ? value.user_name : '',
        "exporter_id": value.id ? value.id : 0
      }));

      call('post', 'getusercommoditycategory', { userId: value.id }).then((result) => {
        console.log("API result getusercommoditycategory in comm modal:-->", result);
        setCommCategory(result);
      }).catch((e) => {
        console.log("Error while querying getusercommoditycategory:", e);
      })

    } else {
      setData(data => ({
        ...data,
        'buyerName': value.company_name ? value.company_name : '',
        'buyerAddress': value.country_code ? value.country_code : '',
        'buyerEmail': value.user_name ? value.user_name : '',
        "importer_id": value.id ? value.id : 0
      }));

      call('post', 'getusercommoditycategory', { userId: userId }).then((result) => {
        console.log("API result getusercommoditycategory in comm modal:-->", result);
        setCommCategory(result);
      }).catch((e) => {
        console.log("Error while querying getusercommoditycategory:", e);
      })

    }
  }

  function getSupplierCommodities(event, supplierId) {
    event.persist();
    setData(data => ({ ...data, "commodity": "" }));
    if (!event.target.value) {
      setsupplierCommodityList([])
      return null
    }
    call('post', 'getusercommoditylist', { "userId": supplierId, "commCatId": event.target.value === "All" ? "" : event.target.value }).then((result) => {
      // console.log("api result in getusercommoditylist->", result)
      setsupplierCommodityList(result)
    }).catch((e) => {
      console.log('error in getusercommoditylist', e);
    })
  }

  const loadPort = (event) => {
    event.persist();
    call('POST', 'getCountryPort', { 'countryId': event.target.value }).then((result) => {
      result.push({ id: 0, port_ckey: 'any_port', port_name: 'Any Port' })
      setPortLoading(result)
    }).catch((e) => {
      console.log('error in getCountryPort loadPort', e);
    })
  }

  const unloadPort = (event) => {
    event.persist();
    call('POST', 'getCountryPort', { 'countryId': event.target.value }).then((result) => {
      result.push({ id: 0, port_ckey: 'any_port', port_name: 'Any Port' })
      setPortUnloading(result)
    }).catch((e) => {
      console.log('error in getCountryPort unloadPort', e);
    })
  }

  function insertVariable(tncData) {
    const replaceData = {
      '{price}': data.currency ? data.currency.split(':')[1] : '' + data.price,
      '{currency}': data.currency ? data.currency.split(':')[1] : '',
      '{priceinwords}': price_in_words(data.price),
      '{priceWords}': price_in_words(data.price),
      '{grossCalorificVal}': ((data.commodity_pretty_name && data.commodity_pretty_name === "Coal") ? spec.grosscalorificvalue.value : ''),
      '{supplierAddress}': data.supplierAddress,
      '{commodity}': (data.commodity_pretty_name ? data.commodity_pretty_name : "Commodity"),
      '{quantity}': data.quantity,
      '{loadingPort}': data.loadingPort + "," + countryData?.filter((i, j) => {
        if (i.id / 1 === data.loadingCountry / 1) {
          return i
        }
      })?.[0]?.sortname,
      '{laycan}': formatDate_Application(data.laycanStart) + " - " + formatDate_Application(data.laycanEnd)
    };

    let keyArray = Object.keys(replaceData)
    for (let i = 0; i < keyArray.length; i++) {
      tncData = replaceGlobally(tncData, keyArray[i], replaceData[keyArray[i]])
    }

    return tncData;

    function replaceGlobally(original, searchTxt, replaceTxt) {
      const regex = new RegExp(searchTxt, 'g');
      return original.replace(regex, replaceTxt);
    }
  }

  function fillTNCValue() {

    let finalTnc = { ...dataTnc }

    let keyArray = Object.keys(finalTnc).length ? Object.keys(finalTnc) : []
    for (let i = 0; i < keyArray.length; i++) {
      finalTnc[keyArray[i]].value = insertVariable(finalTnc[keyArray[i]].value)
    }
    //console.log("fillTNCValue finalTnc in contract -->", finalTnc)
    setdataTnc(finalTnc)
  }

  const handleNext = () => {
    setIsSubmitting(true)
    if (contractState.info.modalMode === "create") {
      seterrorData(validate(data, spec, dataTnc, activeClass))
    } else {
      seterrorData(validateContractPilot(data, spec, dataTnc, activeClass, contractEditMode))
    }
    if (activeClass === 6 && (contractState.info.modalMode === "create")) {
      fillTNCValue()
    }
  }

  function getPaymentOptionslist() {
    let PaymentOptionslist = paymentModes ? paymentModes.map((value, index) => {
      return (
        <>
          <div className="w-auto row d-flex my-0 p-0 px-3 font-size-13 font-wt-600" >
            <div className='w-100' onClick={() => {
              setData({ ...data, contractType: "FOB" })
            }}>
              <input className="form-check-input" type="radio" name="paymentMode" id={"paymentMode" + value.id} value={value.id}
                checked={(data.paymentMode / 1 === value.id) ? true : false}
                onChange={handleChange} />
              <label for={"paymentMode" + value.id} className="form-check-label p-0 m-0" >
                {value.payment_type_pretty}
              </label>
            </div>
          </div>

          {/* <div className="switch-field ml-1 mr-1">
            <input type="radio" name="paymentMode" id={"paymentMode" + value.id} value={value.id} checked={(data.paymentMode / 1 === value.id) ? true : false} onChange={handleChange} />
            <label for={"paymentMode" + value.id}>{value.payment_type_pretty}</label>
          </div> */}
        </>
      )
    }) : ''
    return PaymentOptionslist
  }

  //---------------------------------------------------------------------------------------------------------------------
  //supplierBankList
  function getsupplierBankList() {
    let supplierBankList = sellerBank && sellerBank.length ? sellerBank.map((value, index) => {
      return (
        // <option value={value.bank_branch_id}>{value.company_name}</option>
        <option value={value.id}>{value.company_name}</option>
      )
    }) : ''
    return supplierBankList
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  //buyerBankList
  function getbuyerBankList() {
    let buyerBankList = buyerBank && buyerBank.length ? buyerBank.map((value, index) => {
      return (
        // <option value={value.bank_branch_id}>{value.company_name}</option>
        <option value={value.id}>{value.company_name}</option>
      )
    }) : ''
    return buyerBankList
  }
  //---------------------------------------------------------------------------------------------------------------------

  function getSelectName(id, value) {
    let sel = document.getElementById(id);
    if (sel) {
      for (let i = 0; i < sel.options.length; i++) {
        if (sel.options[i].value / 1 === value / 1) {
          return sel.options[i].text
        }
      }
    } else {
      return '--'
    }

  }

  function getUserArray() {
    let usersArray = [
      {
        "userId": data.exporter_id,
        "userName": data.supplierName ? data.supplierName : data.supplier
      },
      {
        "userId": data.importer_id,
        "userName": data.buyerName ? data.buyerName : data.buyerEmail
      },
      // {
      //   "userId": data.investigationAgencyExporter,
      //   "userName": getSelectName('investigationAgencyExporter', data.investigationAgencyExporter)
      // },
      // {
      //   "userId": data.investigationAgencyImporter,
      //   "userName": getSelectName('investigationAgencyImporter', data.investigationAgencyImporter)
      // },
      // {
      //   "userId": data.investigationAgencythird,
      //   "userName": getSelectName('investigationAgencythird', data.investigationAgencythird)
      // },
      // {
      //   "userId": data.commodityInsurer,
      //   "userName": getSelectName('commodityInsurer', data.commodityInsurer)
      // },
      // {
      //   "userId": data.tradeInsurer,
      //   "userName": getSelectName('tradeInsurer', data.tradeInsurer)
      // },
      // {
      //   "userId": data.supplierBank,
      //   "userName": getSelectName('supplierBank_select', data.supplierBank)
      // },
      // {
      //   "userId": data.buyerBank,
      //   "userName": getSelectName('buyerBank_select', data.buyerBank)
      // }
    ]

    let mapArray = [], finalArray = []
    for (let i = 0; i < usersArray.length; i++) {
      // console.log("here is id usersArray[i]===>", usersArray[i])
      if (usersArray[i].userId / 1 !== 0) {
        if (!mapArray.includes(usersArray[i].userId)) {
          finalArray.push(usersArray[i])
          mapArray.push(usersArray[i].userId)
        }
      }
    }
    // console.log("here is id finalArray===>", finalArray)
    return finalArray

  }

  const handleViewAccordion = () => {
    setViewAccordion(viewAccordion ? 0 : 1);
  }

  function tncPreviewGenerator() {
    let keyArray = Object.keys(dataTnc).length ? Object.keys(dataTnc) : []
    return (
      <ol className="terms-view-ul p-0 m-0">
        {keyArray.map((key, index) => {
          return (
            <li>
              <label className="date-format font-size-12 font-wt-500">{dataTnc[key].name ? dataTnc[key].name : "--"} </label>
              <p className='font-size-13 font-wt-500'>{dataTnc[key].value ? dataTnc[key].value.replace(/<br>/g, "\n") : "--"}</p>
            </li>
          )
        })}
      </ol>
    )
  }

  function miniPreview() {
    return (
      <div className="modal-padding">
        <ul className="price-ul">
          <li>
            <div className="price-left font-size-14 font-wt-500">{data.supplierName ? data.supplierName : '-'}</div>
            <div className="price-right font-size-13 font-wt-400">Exporter </div>
          </li>
          <li>
            <div className="price-left font-size-14 font-wt-500"> {data.commodity_pretty_name || data.commodity_name || '-'} </div>
            <div className="price-right font-size-13 font-wt-400"> Commodity</div>
          </li>
          <li>
            <div className="price-left font-size-14 font-wt-500">{data.contractType ? data.contractType : "-"}</div>
            <div className="price-right font-size-13 font-wt-400"> Inco Terms</div>
          </li>
          <li>
            <div className="price-left font-size-14 font-wt-500"> {data.laycanStart ? data.laycanStart : '-'}</div>
            <div className="price-right font-size-13 font-wt-400">Laycan Start</div>
          </li>
          <li>
            <div className="price-left font-size-14 font-wt-500"> {data.buyerName ? data.buyerName : '-'}</div>
            <div className="price-right font-size-13 font-wt-400">Importer</div>
          </li>
          <li>
            <div className="price-left font-size-14 font-wt-500">{data.price ? data.price : '-'}</div>
            <div className="price-right font-size-13 font-wt-400">{data.priceType / 1 === 1 ? "Commodity Price/mt" : data.priceType / 1 === 2 ? "Current Index Price" : "Commodity Price"}</div>
          </li>
          <li>
            <div className="price-left font-size-14 font-wt-500">{data.quantity ? data.quantity : '-'}</div>
            <div className="price-right font-size-13 font-wt-400">Quantity</div>
          </li>
          <li>
            <div className="price-left font-size-14 font-wt-500"> {data.laycanEnd ? data.laycanEnd : '-'}</div>
            <div className="price-right font-size-13 font-wt-400">Laycan End</div>
          </li>
        </ul>
      </div>
    )
  }

  const handleSpecification = (event) => {
    event.persist();
    let tempArray = event.target.name.split(",")
    let specdataElement = { ...spec }
    specdataElement[tempArray[0]][tempArray[1]] = event.target.value
    setSpecification(specdataElement);
    seterrorData(errorData => ({ ...errorData, "Specification": "" }))
    setEditCount(editCount + 1)
    setspecChange(specChange + 1)
  }

  const handleTNC = (event) => {
    event.persist();
    let tempArray = event.target.name.split(",")
    let tncdataElement = { ...dataTnc }
    tncdataElement[tempArray[0]][tempArray[1]] = event.target.value
    setdataTnc(tncdataElement);
    seterrorData(errorData => ({ ...errorData, "tnc": "" }))
    setEditCount(editCount + 1)
    settncChange(tncChange + 1)
  }

  console.log("dbSupplierrrrrrrrrrrrrrrrrrrrrrrrr", dbSupplier, data);

  function loadIndexPrice(event) {
    event.persist();
    if ((event.target.value / 1) === 2) {
      call('POST', 'getcommodityindexprice', { commodity: data.commodity || 0 }).then((result) => {
        // console.log("result in getcommodityindexprice api->", result)
        setData(data => ({
          ...data,
          price: result.lastPrice,
          currency: result.priceUnit
        }));
      }).catch((e) => {
        console.log("Error while querying getcommodityindexprice:", e);
        toastDisplay(e, "info")
      })
    }
  }

  function handleInc() {
    document.getElementById('quantity').stepUp(1)
    setData({
      ...data, quantity: parseInt(data.quantity, 10) + 1
    })
    if (contractState.info.modalMode === "edit") {
      setDataUpdate(dataUpdate => ({ ...dataUpdate, quantity: parseInt(data.quantity, 10) + 1 }));
    }
    seterrorData(errorData => ({ ...errorData, quantity: "" }))
    setEditCount(editCount + 1)
  }

  function handleDec() {
    document.getElementById('quantity').stepDown(1)
    setData({
      ...data, quantity: parseInt(data.quantity, 10) - 1
    })
    if (contractState.info.modalMode === "edit") {
      setDataUpdate(dataUpdate => ({ ...dataUpdate, quantity: parseInt(data.quantity, 10) - 1 }));
    }
    seterrorData(errorData => ({ ...errorData, quantity: "" }))
    setEditCount(editCount + 1)
  }


  function raiseContractEditRequest() {
    let formData = new FormData();
    formData.append("user_type", userTypeId);
    formData.append("loggedUserID", userId);
    formData.append("contract_id", data.contract_id);
    formData.append("importer_id", data.importer_id);
    formData.append("exporter_id", data.exporter_id);

    call('POST', 'raisecontracteditrequest', formData).then((result) => {
      if (result) {
        setshowLoader(false)
        toastDisplay("Edit request raised", "success", () => {
          window.location = '/workOrderContracts'
        })
      }
    }).catch(e => {
      console.log("Error while Updating contract:", e);
      setshowLoader(false)
      toastDisplay(e, "error")
    })
  }

  function openContractEdit(edit) {
    let formData = new FormData();
    formData.append("loggedUserID", userId);
    formData.append("importer_id", data.importer_id);
    formData.append("exporter_id", data.exporter_id);
    formData.append("contract_id", data.contract_id);
    formData.append("editSingle", edit);

    call('POST', 'opencontractforedit', formData).then((result) => {
      if (result) {
        setshowLoader(false)
        toastDisplay("Edit request accepted", "success", () => {
          window.location = '/workOrderContracts'
        })
      }
    }).catch(e => {
      console.log("Error while Updating contract:", e);
      setshowLoader(false)
      toastDisplay(e, "error")
    })
  }

  function switchTrader(traderType) {
    setTraderType(traderType);
    setTrader(traderType);
    setClientType(traderType);
    window.location.reload()
  }

  return (
    <div>

      {traderOnboardForm.show ? (
        <FinanceInvoiceModal limitinvoice={traderOnboardForm.show} closeSuccess={() => {
          toggleTraderOnboardForm({ show: false })
        }}>
          <div className="d-flex row justify-content-center" >
            <label className="font-size-16 font-wt-600 text-center w-100 col-12 pb-3" >{`Enter ${traderActiveType === "buyer" ? 'Seller' : 'Buyer'} Details`}</label>
            <div className="col-8 justify-self-center" >
              <NewInput label={"Company Name"} onChange={handleChange} name={"traderOnboardCompanyName"} value={data.traderOnboardCompanyName}
              />
            </div>
            <div className="col-8" >
              <NewInput label={"Email Id"} onChange={handleChange} name={"traderOnboardEmailId"} value={data.traderOnboardEmailId}
              />
            </div>
            {/* <div className="w-100 text-center" >
              <label class="form-check-label text-center font-size-13 font-wt-600" for="sendInvite">
                <input class="form-check-input new-check-box" type="checkbox" name="sendInvite" id="sendInvite"
                  value={!data.sendInvite}
                  onChange={handleChange}
                  checked={data.sendInvite}
                />
                Also add user in my Network</label>
            </div> */}
            <div className="col-6 mt-3" >
              <button type="button"
                onClick={() => {
                  if (data.traderOnboardCompanyName && data.traderOnboardEmailId) {
                    // onboard user start
                    let reqObject = {
                      "typeId": 14,
                      "cinDocumentName": null,
                      "gstDocumentName": null,
                      "iecDocumentName": null,
                      "panDocumentName": null,
                      "aadharDocumentName": null,
                      "iecDocumentName": null,
                      "panDocumentName": null,
                      "aadharDocumentName": null,
                      "organizationType": "foreign",
                      "companyName": data.traderOnboardCompanyName,
                      "contactPerson": data.traderOnboardCompanyName,
                      "companyAddress": "",
                      "email": data.traderOnboardEmailId,
                      "contactNo": "",
                      "gst_company_detail": null,
                      "iec_company_detail": null,
                      "cin_company_detail": null,
                      "type": "finTech",
                      "referralCode": userData.refercode,
                      "password": "Pa$$w0rd!",
                      "passwordConfirm": "Pa$$w0rd!",
                      "termsAndCondition": true,
                      "country": "",
                      "industryType": "",
                      "techType": 1,
                      "companyCity": "",
                      "companyPostalCode": "",
                      "phoneCode": "",
                      "nameTitle": "",
                      "companyState": "",
                      "designation": "",
                    }
                    setshowLoader(true)
                    call('POST', 'registration', reqObject, "user").then((result) => {
                      setshowLoader(false)
                      toastDisplay("Details saved", "info")
                      toggleTraderOnboardForm({ show: false })
                      setrefreshSupplier(refreshSupplier + 1)
                      setData({ ...data, traderOnboardCompanyName: "", traderOnboardEmailId: '', sendInvite: false })
                    }).catch(err => {
                      setshowLoader(false)
                      toastDisplay(err || "Something went wrong", "error");
                    })
                  }
                  else {
                    toastDisplay('Fill details to continue', 'info')
                  }
                }}
                className={` new-btn w-100 py-2 px-2  text-white`}>
                {"Submit"}
              </button>
            </div>
          </div>
        </FinanceInvoiceModal>
      ) : null}

      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />

      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="createWorkOrderContract" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Workorder Contracts > Create Contract"}
              userTokenDetails={userTokenDetails} />

            <div>
              <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                {tabsArr.map((item, index) => {
                  if (!item.hide) {
                    return (
                      <li
                        style={{ width: `14.28%` }}
                        onClick={() => {
                          if (index < activeClass) {
                            setActiveClass(index + 1)
                          }
                        }}
                      >
                        <a
                          style={{ width: `100%` }}
                          className={"nav-link formTab px-4 cursor-pointer " + (activeClass === index + 1 ? " formActiveTab show" : "")}
                        >
                          {item.name}</a>
                      </li>
                    )
                  }
                })}
              </ul>
            </div>

            <div className="card mt-1"
            >
              {activeClass === 1 ? (
                <div className="d-flex row p-4" >

                  <div className="d-flex row align-items-center mb-4" >
                    <div className="col-auto pt-1" >
                      <label className="font-size-14 font-wt-500 ">Do you want the contract to include a copy of the system-generated contract in your contract document?</label>
                    </div>
                    <div className="col-auto row d-flex my-0 p-0 px-1 font-size-13 font-wt-600" >
                      <div className='w-auto' onClick={() => {
                        setContractCopy("Yes")
                      }}>
                        <input className="form-check-input" type="radio" value={"Yes"} checked={contractCopy === 'Yes'} />
                        <label className="form-check-label p-0 m-0" >
                          Yes
                        </label>
                      </div>
                      <div className='w-auto' onClick={() => {
                        setContractCopy("No")
                      }}>
                        <input className="form-check-input" type="radio" value={"No"} checked={contractCopy === 'No'} />
                        <label className="form-check-label p-0 m-0" >
                          No
                        </label>
                      </div>
                    </div>
                  </div>

                  {contractState.info.modalMode === "create" ? <div className="col-md-6" >
                    <div className="d-flex row align-items-center" >
                      <div className="col-3 pt-1" >
                        <label className="font-size-14 font-wt-500 ">Your Role</label>
                      </div>
                      <div className="col-9 row d-flex my-0 p-0 px-1 font-size-13 font-wt-600" >
                        <div className='w-auto' onClick={() => {
                          switchTrader("buyer")
                        }}>
                          <input className="form-check-input" type="radio" value={"buyer"} checked={trader === 'buyer'} />
                          <label className="form-check-label p-0 m-0" >
                            As Importer
                          </label>
                        </div>
                        <div className='w-auto' onClick={() => {
                          switchTrader('seller')
                        }}>
                          <input className="form-check-input" type="radio" value={"CIF"} checked={trader === 'seller'} />
                          <label className="form-check-label p-0 m-0" >
                            As Exporter
                          </label>
                        </div>
                      </div>
                    </div>
                  </div> : null}
                  {contractState.info.modalMode != "create" ?
                    <>
                      <div className="col-md-4" >
                        <NewInput
                          label={`Contract Name`} value={data.contractAlias} error={errorData.contractAlias}
                          onChange={handleChange} name={"contractAlias"}
                        />
                      </div>
                      <div className="col-md-2">
                        <div className="row form-group">
                          <label className="col-md-12">{data.contractType} </label>
                          <div className="col-md-12"><span className="date-format">Inco Terms</span></div>
                        </div>
                      </div>
                      <div className="row col-md-6">
                        <div className="col-md-6 ">
                          <div className="mr-auto pr-3 form-group">
                            <label className="col-md-12">Buyer/Importer</label>
                            <div className="chat-message pl-2">
                              <img src={avatarUrl(data.buyerAvatar)} alt="Avatar" width="32" height="32" />
                              <div className="chat-message-content clearfix pt-2">
                                <span className="date-format">  {data.buyerName ? data.buyerName : '--'}</span><br />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 ">
                          <div className="mr-auto pr-3 form-group">
                            <label className="col-md-12">Seller/Exporter</label>
                            <div className="chat-message pl-2">
                              <img src={avatarUrl(data.supplierAvatar)} alt="Avatar" width="32" height="32" />
                              <div className="chat-message-content clearfix pt-2">
                                <span className="date-format">  {data.supplierName ? data.supplierName : '--'}</span><br />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                    : null}
                  {contractState.info.modalMode === "create" ? <div className="col-md-5" >
                    <MultipleSelect
                      Label={(traderActiveType === 'buyer') ? "Select Seller/Exporter" : "Select Buyer/Importer"}
                      filterOption={() => true}
                      onInputChange={(e) => {
                        setSearchUserForListing(e)
                      }}
                      Id={`NA`}
                      optiondata={dbSupplier}
                      onChange={(e) => {
                        if (e?.[0]?.id === "Add New") {
                          // window.open(window.location.origin + '/MyNetwork', "_blank")
                          toggleTraderOnboardForm({ show: true })
                        }
                        else if (e?.[0]?.id) {
                          handleSelectedSupplier(e?.[0])
                        }
                        else {
                          setData({
                            ...data, importer_id: null, exporter_id: null
                          })
                        }
                      }}
                      value={traderActiveType === "buyer" && data["exporter_id"] ? [data[`exporter_id`]] :
                        data["importer_id"] ? [data["importer_id"]] : []}
                      name={`NA`}
                      labelKey={"html"}
                      valKey={"id"}
                      error={errorData[`NA`]}
                    />
                  </div> : null
                  }

                  {contractState.info.modalMode === "create" ? <div className="col-md-6" >
                    <div className="d-flex row align-items-center" >
                      <div className="col-3 pt-1" >
                        <label className="font-size-14 font-wt-500 ">Inco Terms</label>
                      </div>

                      <div className="col-9 row d-flex my-0 p-0 px-1 font-size-13 font-wt-600" >

                        <NewSelect label={''} name={"contractType"} selectData={[{ name: 'EXW' }, { name: 'FCA' }, { name: 'FAS' }
                          , { name: 'FOB' }, { name: 'CFR' }, { name: 'CIF' }, { name: 'CPT' }, { name: 'CIP' }
                          , { name: 'DAP' }, { name: 'DPU' }, { name: 'DDP' }]} optionLabel={"name"}
                          optionValue={"name"} onChange={(event) => {
                            setData({ ...data, contractType: event.target.value })
                          }}
                          error={errorData.contractType} value={data.contractType} />

                        {/* <div className='w-auto' onClick={() => {
                          setData({ ...data, contractType: "FOB" })
                         }}>
                          <input className="form-check-input" type="radio" value={"FOB"} checked={data.contractType === 'FOB'} />
                          <label className="form-check-label p-0 m-0" >
                            FOB
                          </label>
                        </div>
                        <div className='w-auto' onClick={() => {
                          setData({ ...data, contractType: "CIF" })
                         }}>
                          <input className="form-check-input" type="radio" value={"CIF"} checked={data.contractType === 'CIF'} />
                          <label className="form-check-label p-0 m-0" >
                            CIF
                          </label>
                        </div> */}
                      </div>
                    </div>
                  </div> : null}

                  {contractState.info.modalMode === "create" ?
                    <div className="col-md-5" >
                      <NewInput
                        label={`Contract Name`} value={data.contractAlias} error={errorData.contractAlias}
                        onChange={handleChange} name={"contractAlias"}
                      />
                    </div> : null}

                  {contractState.info.modalMode != "create" ?
                    <div className="modal-padding">
                      <hr />
                      <div id="Check-box" className="mt-3">
                        <h3 className="mt-3 ml-2 font-size-14 font-wt-500"> Commodity </h3>
                        <div className="col-md-12 row mt-3">
                          <div className="col-auto">
                            {data.commFilename && <img width="100" src={commImageUrl(data.commFilename)} className="img-responsive" alt="description" />}
                            <h5 className='font-size-12 font-wt-500 w-100 text-center' >{data.commodity_name}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    : null}

                  {selectedSupplier.state && traderActiveType ? (
                    <>
                      <div className='customHrLine mt-3 mb-4' />
                      <div className="col-md-4" >
                        <MultipleSelect
                          Label={'Select Commodity Type/Category'}
                          Id={`commCatId`}
                          optiondata={commCategory}
                          onChange={(e) => {
                            handleChange(multiSelectEventHandler(e, `commCatId`, "id"))
                            getSupplierCommodities(multiSelectEventHandler(e, `commCatId`, "id"), (traderActiveType === 'buyer') ? selectedSupplier.supplierId : userId);
                          }}
                          value={data[`commCatId`] ? [data[`commCatId`]] : []}
                          name={`commCatId`}
                          labelKey={"category"}
                          valKey={"id"}
                          error={errorData[`commCatId`]}
                        />
                      </div>
                      <div className="col-md-4" >
                        <MultipleSelect
                          Label={'Select Commodity Name'}
                          Id={`commodity`}
                          optiondata={supplierCommodityList}
                          onChange={(e) => {
                            handleChange(multiSelectEventHandler(e, `commodity`, "id"))
                          }}
                          value={data[`commodity`] ? [data[`commodity`]] : []}
                          name={`commodity`}
                          labelKey={"commodity_pretty_name"}
                          valKey={"id"}
                          error={errorData[`commodity`]}
                        />
                      </div>
                    </>
                  ) : null}

                  {data.commodity || (contractState.info.modalMode != "create") ? <>
                    <div className='customHrLine mt-3 mb-4' />
                    <div className="col-md-4" >
                      <NewInput
                        type={"date"}
                        label={'Laycan Start Date'}
                        onChange={handleChange}
                        value={data['laycanStart']}
                        name={`laycanStart`}
                        error={errorData[`laycanStart`]}
                      />
                    </div>
                    <div className="col-md-4" >
                      <NewInput
                        type={"date"}
                        label={'Laycan End Date'}
                        onChange={handleChange}
                        value={data['laycanEnd']}
                        name={`laycanEnd`}
                        error={errorData[`laycanEnd`]}
                      />
                    </div>
                  </> : null}

                  {data.commodity ? (
                    <>
                      <div className='customHrLine mt-3 mb-4' />
                      <div className="col-md-4" >
                        <MultipleSelect
                          Label={'Loading Country'}
                          Id={`loadingCountry`}
                          optiondata={countryData}
                          onChange={(e) => {
                            handleChange(multiSelectEventHandler(e, `loadingCountry`, "id"))
                            loadPort(multiSelectEventHandler(e, `loadingCountry`, "id"));
                          }}
                          value={data[`loadingCountry`] ? [data[`loadingCountry`]] : []}
                          name={`loadingCountry`}
                          labelKey={"name"}
                          valKey={"id"}
                          error={errorData[`loadingCountry`]}
                        />
                      </div>
                      <div className="col-md-4" >
                        <MultipleSelect
                          Label={'Loading Port'}
                          Id={`loadingPort`}
                          optiondata={portLoading}
                          onChange={(e) => {
                            handleChange(multiSelectEventHandler(e, `loadingPort`, "port_ckey"))
                          }}
                          value={data[`loadingPort`] ? [data[`loadingPort`]] : []}
                          name={`loadingPort`}
                          labelKey={"port_name"}
                          valKey={"port_ckey"}
                          error={errorData[`loadingPort`]}
                        />
                      </div>
                      <div className='col-4' />
                      <div className="col-md-4" >
                        <MultipleSelect
                          Label={'Unloading Country'}
                          Id={`unloadingCountry`}
                          optiondata={countryData}
                          onChange={(e) => {
                            handleChange(multiSelectEventHandler(e, `unloadingCountry`, "id"))
                            unloadPort(multiSelectEventHandler(e, `unloadingCountry`, "id"))
                          }}
                          value={data[`unloadingCountry`] ? [data[`unloadingCountry`]] : []}
                          name={`unloadingCountry`}
                          labelKey={"name"}
                          valKey={"id"}
                          error={errorData[`unloadingCountry`]}
                        />
                      </div>
                      <div className="col-md-4" >
                        <MultipleSelect
                          Label={'Unloading Port'}
                          Id={`unloadingPort`}
                          optiondata={portUnloading}
                          onChange={(e) => {
                            handleChange(multiSelectEventHandler(e, `unloadingPort`, "port_ckey"))
                          }}
                          value={data[`unloadingPort`] ? [data[`unloadingPort`]] : []}
                          name={`unloadingPort`}
                          labelKey={"port_name"}
                          valKey={"port_ckey"}
                          error={errorData[`unloadingPort`]}
                        />
                      </div>
                    </>
                  ) : null}
                </div>) : null}

              {activeClass === 2 ? (
                <div className="d-flex row p-4" >
                  <div className={"tab-content p-0 m-0" + (activeClass === 2 ? " is-active" : "")}>
                    <div className="calc-inner-modal">
                      {miniPreview()}
                      <div className='customHrLine mt-3 mb-4' />
                      {/* Commodity Form Component Start */}
                      {errorData.Specification && <p className="text-danger text-center font-size-13 font-wt-500">{errorData.Specification}</p>}
                      {((data.commodity || data.commodity_id) && activeClass === 2) && <SpecificationsForm
                        commodity={data.commodity ? data.commodity : data.commodity_id}
                        spec={spec}
                        handleSpecification={handleSpecification}
                        setSpecification={setSpecification}
                        specOrder={specOrder}
                        setspecOrder={setspecOrder}
                        contMode={contractState.info.modalMode}
                        editCount={editCount}
                        setEditCount={setEditCount}
                        editLevel={true}
                        masterid={false} />}
                      {/* Commodity Form Component End */}
                    </div>
                  </div>
                </div>) : null}

              {activeClass === 3 ?
                <div className="d-flex row p-4" >
                  <div className={"tab-content p-0 m-0" + (activeClass === 3 ? " is-active" : "")}>
                    <div className="calc-inner-modal">
                      {miniPreview()}
                      <div className='customHrLine mt-3 mb-4' />
                      <div className="modal-padding">
                        <div className="row">
                          <label className="col-md-3 font-size-12 font-wt-400">Commodity Price/mt  {astrix} {toolTip('priceType')}</label>
                          <div className="col-md-3">
                            {/* <div className="form-group">
                              <select className={" form-control" + (!errorData.priceType ? '' : ' border-danger')} name="priceType" value={data.priceType}
                                onChange={(event) => { handleChange(event); loadIndexPrice(event) }}>
                                <option value="" selected>--Select Price Type--</option>
                                <option value="1"> Fixed Price</option>
                                <option value="2">Index Price</option>
                              </select>
                              {errorData.priceType && <p className="text-danger error-contract">{errorData.priceType}</p>}
                            </div> */}
                            <NewSelect label={'Select Price Type'} name={"priceType"} selectData={[{ name: 'Fixed Price', val: "1" }, { name: 'Index Price', val: "2" }]} optionLabel={"name"}
                              optionValue={"val"} onChange={(event) => { handleChange(event); loadIndexPrice(event) }}
                              error={errorData.priceType} value={data.priceType} />
                          </div>

                          <div className="col-md-3">
                            <div className="row">
                              {(data.priceType / 1) === 2 ? <>
                                <div className="col-md-5">
                                  {/* <div className="form-group">
                                    <input type="number" step="0.01" name="price" value={data.price} placeholder="Price" className={" form-control" + (!errorData.price ? '' : ' border-danger')} onChange={handleChange} disabled={(data.priceType / 1) === 2 ? true : false} />
                                    {errorData.price && <p className="text-danger error-contract">{errorData.price}</p>}
                                  </div> */}
                                  <NewInput label={"Price"} name="price" type={"number"} value={data.price} placeholder="Price" onChange={handleChange}
                                    error={errorData.price} isDisabled={(data.priceType / 1) === 2 ? true : false}
                                  />
                                </div>
                                <span className="col-md-2 font-size-20 font-wt-600 mt-2">+-</span>
                                <div className="col-md-5">
                                  {/* <div className="form-group">
                                    <input type="number" name="priceTolerance" value={data.priceTolerance} placeholder="" className={" form-control" + (!errorData.priceTolerance ? '' : ' border-danger')} onChange={handleChange} />
                                    {errorData.priceTolerance && <p className="text-danger error-contract">{errorData.priceTolerance}</p>}
                                  </div> */}
                                  <NewInput label={"Price"} name="priceTolerance" type={"number"} value={data.priceTolerance} placeholder="" onChange={handleChange}
                                    error={errorData.priceTolerance} isDisabled={(data.priceType / 1) === 2 ? true : false}
                                  />
                                </div>
                              </> : <>
                                <div className="col-md-12">
                                  {/* <div className="form-group">
                                    <input type="number" step="0.01" name="price" value={data.price} placeholder="Enter Price" className={" form-control" + (!errorData.price ? '' : ' border-danger')} onChange={handleChange} />
                                    {errorData.price && <p className="text-danger error-contract">{errorData.price}</p>}
                                  </div> */}
                                  <NewInput label={"Price"} name="price" type={"number"} value={data.price} placeholder="" onChange={handleChange}
                                    error={errorData.price}
                                  />
                                </div>
                              </>}
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="form-group">
                              {/* <select className={" form-control" + (!errorData.currency ? '' : ' border-danger')} name="currency" value={data.currency} onChange={handleChange} disabled={(data.priceType / 1) === 2 ? true : false}>
                                <option value="" selected>--Select Currency--</option>
                                {currencyData.map((currency) => {
                                  return (
                                    <option value={currency.name + ":" + currency.symbol + ":" + currency.code} >{currency.name + " ( " + currency.code + " )" + " ( " + currency.symbol + " )"}</option>
                                  )
                                })}
                              </select>
                              {errorData.currency && <p className="text-danger error-contract">{errorData.currency}</p>} */}
                              <MultipleSelect
                                Label={"Select Currency"}
                                optiondata={currencyData}
                                Id={"currency"}
                                name={"currency"}
                                labelKey={"code"}
                                valKey={"code"}
                                value={data.currency ? [data.currency] : []}
                                onChange={(e) => {
                                  handleChange(multiSelectEventHandler(e, `currency`, "code"))
                                }}
                                error={errorData[`currency`]}
                              />
                            </div>
                          </div>

                        </div>
                        <div className="row mt-3">
                          <div className="col-md-12">
                            <div className="form-group d-flex align-items-center mb-0 pb-2">
                              <label for="message-text" className="col-form-label font-size-13 font-wt-400">Add Quantity {astrix} {toolTip('quantity')}</label>
                              <span className="ml-2">(mt)</span>
                              <div className="ml-auto">
                                <button type="button" onClick={() => { handleDec() }} value="-" className="edit-icon"><i class="fas fa-minus text-primary"></i></button>
                                <input type="number" name="quantity" placeholder="0" min="0" size="1" id="quantity" value={data.quantity} className={" text-center increment" + (!errorData.quantity ? '' : ' border-danger')} onChange={handleChange} />
                                <button type="button" onClick={() => { handleInc() }} value="+" className="edit-icon"><i class="fas fa-plus text-primary"></i></button>
                              </div>
                            </div>
                            <p className="text-primary font-size-15 font-wt-400">(Tolerance = +/- 10%)</p>
                            {errorData.quantity && <p className="text-danger error-contract">{errorData.quantity}</p>}
                          </div>
                        </div>
                        <hr />
                        <div className="row d-flex mt-5">
                          <label className="col-md-6 font-size-13 font-wt-400">Total Amount = Commodity Price/mt x Quantity</label>
                          <div className="col-md-6 ">
                            <h4 className="text-primary text-right" ><span >{data.price && data.quantity && data.currency ? (data.currency.split(':')[1]) : ''} </span>{data.price && data.quantity ? Intl.NumberFormat('en-IN').format(data.price * data.quantity) : 0}</h4>
                            <p className="text-primary text-right font-size-16 font-wt-400" >{data.price && data.quantity ? price_in_words(data.price * data.quantity) : ''}<span >{data.price && data.quantity && data.currency ? (data.currency.split(':')[0]) : ''} </span></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> : null}

              {activeClass === 4 ? <div className="d-flex row p-4" >
                <div className={"tab-content p-0 m-0" + (activeClass === 4 ? " is-active" : "")}>
                  <div className="calc-inner-modal">
                    {miniPreview()}
                    <hr />
                    <div className="modal-padding">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row form-group">
                            <label className="col-md-12">Payment Mode {astrix} {toolTip('paymentMode')}</label>
                            <div className="modal-padding row">
                              {
                                getPaymentOptionslist()
                              }
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* //---------------------------------------------- */}
                      {/* add bank Component */}
                      {addBankObj.modal && <AddBankComponent />}
                      {/* //---------------------------------------------- */}


                      {/* <div className="row" id="Bank1">
                        <div className="col-md-12 my-3">
                          <div className="row">
                            <div className="col-md-4">
                              <MultipleSelect
                                Label={`Importer's Bank`}
                                Id={`buyerBank_select`}
                                optiondata={buyerBank}
                                onChange={(e) => {
                                  handleChange(multiSelectEventHandler(e, `buyerBank`, "id"))
                                }}
                                value={data[`buyerBank`] ? [data[`buyerBank`]] : []}
                                name={`buyerBank`}
                                labelKey={"company_name"}
                                valKey={"id"}
                                error={errorData[`buyerBank`]}
                              />
                            </div>
                            <div className="col-md-4">
                              <div className="modal-padding"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row" id="Bank1">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-4">
                              <MultipleSelect
                                Label={`Exporter's Bank`}
                                Id={`supplierBank_select`}
                                optiondata={sellerBank}
                                onChange={(e) => {
                                  handleChange(multiSelectEventHandler(e, `supplierBank`, "id"))
                                }}
                                value={data[`supplierBank`] ? [data[`supplierBank`]] : []}
                                name={`supplierBank`}
                                labelKey={"company_name"}
                                valKey={"id"}
                                error={errorData[`supplierBank`]}
                              />
                            </div>
                            <div className="col-md-4">
                              <div className="modal-padding"></div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div> : null}

              {activeClass === 5 ? <div className="d-flex row p-4" >
                <div className={"tab-content p-0 m-0" + (activeClass === 5 ? " is-active" : "")}>
                  <div className="calc-inner-modal">
                    {miniPreview()}
                    <hr />
                    {/* <h2>Contract TNC</h2> */}
                    {errorData.tnc && <p className="text-danger text-center font-size-13 font-wt-500">{errorData.tnc}</p>}
                    {(activeClass === 5) ? <TncForm
                      dataTnc={dataTnc}
                      setdataTnc={setdataTnc}
                      tncOrder={tncOrder}
                      settncOrder={settncOrder}
                      contMode={contractState.info.modalMode}
                      editCount={editCount}
                      setEditCount={setEditCount}
                      handleTNC={handleTNC} /> : ''}
                  </div>
                </div>
              </div> : null}

              {activeClass === 6 && userTypeId / 1 == 14 ? <div className="d-flex row p-4" >
                <div className={"tab-content p-0 m-0" + (activeClass === 6 ? " is-active" : "")}>
                  <div className="calc-inner-modal">
                    {miniPreview()}
                    <hr />
                    {activeClass === 6 ? <DocumentACL
                      userArray={getUserArray()}
                      docCategoryACL={docCategoryACL}
                      setdocCategoryACL={setdocCategoryACL}
                      contractId={data.contract_id ? data.contract_id : null}
                      contractNO={null}
                      dataObj={{ "exporter_id": data.exporter_id / 1, "importer_id": data.importer_id / 1, "importer_bank": data.buyerBank / 1, "exporter_bank": data.supplierBank / 1 }}
                      modalType={contractState.info.modalMode} /> : ""}
                  </div>
                </div>
              </div> : null}

              {activeClass === ((userTypeId === 14) ? 7 : 6) ?
                <div className="d-flex row p-4" >
                  {/* just to set doc acl array  */}
                  <DocumentACL
                    userArray={getUserArray()}
                    docCategoryACL={docCategoryACL}
                    setdocCategoryACL={setdocCategoryACL}
                    contractId={data.contract_id ? data.contract_id : null}
                    contractNO={null}
                    dataObj={{ "exporter_id": data.exporter_id / 1, "importer_id": data.importer_id / 1, "importer_bank": data.buyerBank / 1, "exporter_bank": data.supplierBank / 1 }}
                    modalType={contractState.info.modalMode} />

                  <div className="calc-inner-modal">
                    <div className={"accordionWrapper p-0" + (viewAccordion === 0 ? " open" : " closed")}>
                      <span className='font-size-13 font-wt-400' >Show/Hide</span><br />
                      <i style={{ fontSize: '1.5em' }} className={"mb-2 p-2 fa" + (viewAccordion === 0 ? " fa-minus-square-o fa-2x" : " fa-plus-square-o fa-2x")} aria-hidden="true" onClick={handleViewAccordion}></i>
                      {/*className={ "accordionItem" + (viewAccordion === 0? " open": " closed") }*/}
                      <div className="accordionItem">
                        <h2 className="accordionItemHeading font-size-15 font-wt-500" >Contract info</h2>
                        <div className="accordionItemContent">
                          {(contractState.info.modalMode === "create") ? <div className="mr-auto pr-3 form-group">
                            <label className="col-md-12">
                              {(traderActiveType === 'buyer') ? "Seller/Exporter" : "Buyer/Importer"}
                            </label>
                            <div className="chat-message">
                              <img src={avatarUrl(supplierAvatar)} alt="Avatar" width="32" height="32" />
                              <div className="chat-message-content clearfix pt-2">
                                {(traderActiveType === 'buyer') ? <>
                                  <span className="date-format">  {data.supplierName ? data.supplierName : '--'}</span>
                                  <br />
                                  <span className="date-format">  {data.supplierAddress ? data.supplierAddress : '--'}</span>
                                </> :
                                  <>
                                    <span className="date-format">  {data.buyerName ? data.buyerName : '--'}</span>
                                    <br />
                                    <span className="date-format">  {data.buyerAddress ? data.buyerAddress : '--'}</span>
                                  </>}
                              </div>
                            </div>
                          </div> :
                            <div className="row">
                              <div className="col-md-6 ">
                                <div className="mr-auto pr-3 form-group">
                                  <label className="col-md-12">Buyer/Importer</label>
                                  <div className="chat-message pl-2">
                                    <img src={avatarUrl(data.buyerAvatar)} alt="Avatar" width="32" height="32" />
                                    <div className="chat-message-content clearfix pt-2">
                                      <span className="date-format">  {data.buyerName ? data.buyerName : '--'}</span><br />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 ">
                                <div className="mr-auto pr-3 form-group">
                                  <label className="col-md-12">Seller/Exporter</label>
                                  <div className="chat-message pl-2">
                                    <img src={avatarUrl(data.supplierAvatar)} alt="Avatar" width="32" height="32" />
                                    <div className="chat-message-content clearfix pt-2">
                                      <span className="date-format">  {data.supplierName ? data.supplierName : '--'}</span><br />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>}
                          <hr />
                          <div className="row">
                            <div className="col-md-4">
                              <div className="row form-group">
                                <label className="col-md-12">{data.contractType} </label>
                                <div className="col-md-12"><span className="date-format">Inco Terms</span></div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="row form-group">
                                <label className="col-md-12">{data.commodity_pretty_name || '--'} </label>
                                <div className="col-md-12"><span className="date-format">Commodity</span></div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="row form-group">
                                <label className="col-md-12">{data.laycanStart ? formatDate_Application(data.laycanStart) : '--'} </label>
                                <div className="col-md-12"><span className="date-format">Laycan Start Date</span></div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="row form-group">
                                <label className="col-md-12">{data.laycanEnd ? formatDate_Application(data.laycanEnd) : '--'} </label>
                                <div className="col-md-12"><span className="date-format">Laycan End Date</span></div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="row form-group">
                                <label className="col-md-12">
                                  <span className="shadow">
                                    <ReactCountryFlag
                                      countryCode={data.loadingCountry || ''}
                                      style={{ width: '25px', height: '25px' }} svg />
                                  </span>{data.loadingPort ? data.loadingPort : '--'} </label>
                                <div className="col-md-12"><span className="date-format">Loading Country</span></div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="row form-group">
                                <label className="col-md-12">
                                  <span className="shadow">
                                    <ReactCountryFlag
                                      countryCode={data.unloadingCountry || ''}
                                      style={{ width: '25px', height: '25px' }} svg />
                                  </span>{data.unloadingPort ? data.unloadingPort : '--'} </label>
                                <div className="col-md-12"><span className="date-format">UnLoading Country</span></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordionItem">
                        <h2 className="accordionItemHeading font-size-15 font-wt-500" > Specification</h2>
                        <div className="accordionItemContent">
                          {/* Commodity Preview Component Start */}
                          {((data.commodity || data.commodity_id) && activeClass === 7) && <SpecificationsPreview specification={spec} specOrder={specOrder} />}
                          {/* Commodity Preview Component Start */}
                        </div>
                      </div>
                      <div className="accordionItem">
                        <h2 className="accordionItemHeading font-size-15 font-wt-500" > Price &amp; Rate</h2>
                        <div className="accordionItemContent">
                          <div className="d-flex divider">
                            <div className="">
                              <div className="row">
                                <div className="col-md-4 p-0 m-0">
                                  <div className="row form-group">
                                    <label className="col-md-12">{data.priceType / 1 === 1 ? "Fixed price" : data.priceType / 1 === 2 ? "Index Price" : "--"} </label>
                                    <div className="col-md-12">
                                      <span className="date-format">Price Type</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 p-0 m-0">
                                  <div className="row form-group">
                                    <label className="col-md-12">{data.price ? data.price : 0} </label>
                                    <div className="col-md-12">
                                      <span className="date-format"> {data.priceType / 1 === 1 ? "Commodity Price/mt ( metric tonne* )" : data.priceType / 1 === 2 ? "Current Index Price" : "--"}</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 p-0 m-0">
                                  <div className="row form-group">
                                    <label className="col-md-12">{data.quantity ? data.quantity : '--'}</label>
                                    <div className="col-md-12"> <span className="date-format">Quantity</span></div>
                                  </div>
                                </div>
                                <div className="col-md-4 p-0 m-0">
                                  <div className="row form-group">
                                    <label className="col-md-12">{data.quantity * data.price}</label>
                                    <div className="col-md-12">
                                      <span className="date-format">Total Amount = Commodity Price/mt x Quantity</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="accordionItem">
                        <h2 className="accordionItemHeading font-size-15 font-wt-500" >Investigation Agency</h2>
                        <div className="accordionItemContent">
                          <div className="d-flex divider">
                            <div className="modal-padding">
                              <div className="row">
                                <div className="col-md-4 p-0 m-0">
                                  <div className="row form-group">
                                    <label className="col-md-12"> {getSelectName('investigationAgencyExporter', data.investigationAgencyExporter)}</label>
                                    <div className="col-md-12">
                                      <span className="date-format">Importer's Investigation Agency</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 p-0 m-0">
                                  <div className="row form-group">
                                    <label className="col-md-12">{getSelectName('investigationAgencyImporter', data.investigationAgencyImporter)}</label>
                                    <div className="col-md-12">
                                      <span className="date-format">Exporter's Investigation Agency</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 p-0 m-0">
                                  <div className="row form-group">
                                    <label className="col-md-12">{getSelectName('investigationAgencythird', data.investigationAgencythird)}</label>
                                    <div className="col-md-12">
                                      <span className="date-format">Third Party Investigation Agency</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}

                      {/* <div className="accordionItem">
                        <h2 className="accordionItemHeading font-size-15 font-wt-500" >Insurance Agency</h2>
                        <div className="accordionItemContent">
                          <div className="d-flex divider">
                            <div className="modal-padding">
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="row form-group">
                                    <label className="col-md-12">{getSelectName('commodityInsurer', data.commodityInsurer)} </label>
                                    <div className="col-md-12">
                                      <span className="date-format">Commodity Insurer</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="row form-group">
                                    <label className="col-md-12">{getSelectName('tradeInsurer', data.tradeInsurer)}</label>
                                    <div className="col-md-12">
                                      <span className="date-format">Trade Insurer</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}

                      <div className="accordionItem">
                        <h2 className="accordionItemHeading font-size-15 font-wt-500" >Bank </h2>
                        <div className="accordionItemContent">
                          <div className="d-flex divider">
                            <div className="modal-padding">
                              <div className="row" id="Bank1">
                                <div className="col-md-4 p-0 m-0">
                                  <div className="row form-group">
                                    <label className="col-md-12 p-0 m-0">
                                      {(paymentModes && paymentModes.length) ? paymentModes.map((value) => {
                                        if (value.id === (data.paymentMode / 1)) {
                                          return value.payment_type_pretty
                                        }
                                      }) : ''}
                                    </label>
                                    <div className="col-md-12 p-0 m-0">
                                      <span className="date-format">Payment Mode</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 p-0 m-0">
                                  <div className="row form-group">
                                    <label className="col-md-12 p-0 m-0">{buyerBank.filter((i) => {
                                      if (i.id / 1 === data.buyerBank / 1) {
                                        return true
                                      }
                                    })?.[0]?.company_name || "---"}</label>
                                    <div className="col-md-12 p-0 m-0">
                                      <span className="date-format">Importer's Bank</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 p-0 m-0">
                                  <div className="row form-group">
                                    <label className="col-md-12">{
                                      sellerBank.filter((i) => {
                                        if (i.id / 1 === data.supplierBank / 1) {
                                          return true
                                        }
                                      })?.[0]?.company_name || "---"
                                    }</label>
                                    <div className="col-md-12">
                                      <span className="date-format">Exporter's Bank</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordionItem">
                        <h2 className="accordionItemHeading font-size-15 font-wt-500" > Terms and Conditions</h2>
                        <div className="accordionItemContent">
                          {(activeClass === ((userTypeId === 14) ? 7 : 8)) ? tncPreviewGenerator() : ''}
                        </div>
                      </div>

                    </div>
                  </div>

                </div> : null}

              {(activeClass < 7 && userTypeId / 1 == 14) || (userTypeId / 1 != 14 && activeClass < 6) ?
                <div className='px-4 pb-4'>
                  <button type="button"
                    onClick={() => handleNext()}
                    className={` new-btn w-17 py-2 px-2  text-white`}>
                    {"Next"}
                  </button>
                </div> :
                <div className="px-4 pb-4">
                  {(contractState.info.modalMode === "create") ? <>
                    {/* <button type="button" className="btn btn-dark btn-sm" onClick={activeClass > 1 ? () => handlePrev(activeClass) : ''} id="previd">Edit</button> */}
                    <button type="button" className="new-btn text-white py-2 w-15" onClick={() => { handleSubmit(); setshowLoader(true) }} id="nextid">Submit</button>
                  </> :
                    <>
                      {/* <button type="button" className="btn btn-dark btn-sm" onClick={activeClass > 1 ? () => handlePrev(activeClass) : ''} id="previd">Back</button> */}

                      {(data.status_buyer === 1 || data.status_seller === 1) ? <>
                        {(data.edit_request / 1 === 0) ?
                          <button type="button" className="btn btn-danger btn-sm mr-3" onClick={() => raiseContractEditRequest()} >Request Edit</button>
                          : (data.edit_request / 1 === userId) ?
                            <button type="button" className="btn btn-danger btn-sm mr-3" onClick="" disabled>Edit Request Raised</button> :
                            <button type="button" className="btn btn-danger btn-sm mr-3" onClick={() => { openContractEdit(false) }}>Accept Edit Request</button>
                        }
                      </>
                        : ""}

                      {(data.status_buyer === 0 && data.status_seller === 0) &&
                        <button type="button" className="btn btn-danger btn-sm mr-3" onClick={() => { handleSubmitUpdate("U&A"); setshowLoader(true) }} >Update & Agree</button>}

                      {((((userTypeId === 14 && traderActiveType === 'buyer')) && data.status_buyer === 0) ||
                        (((userTypeId === 14 && traderActiveType === 'seller')) && data.status_seller === 0)) && editCount === 0 &&
                        <button type="button" className="btn btn-primary btn-sm mr-3" onClick={() => { agreement(); setshowLoader(true) }} >Agree</button>}


                      {(data.status_buyer === 0 && data.status_seller === 0) &&
                        <button type="button" className="btn bg-5CB8D3 btn-sm mr-3 text-white" onClick={() => { handleSubmitUpdate(""); setshowLoader(true) }} >Update</button>}

                    </>}
                </div>}
            </div>
          </main>
        </div>
      </div>
    </div>
  )


}

const mapStateToProps = state => {
  return {
    contractState: state.contract,
    clientType: state.clientType,
    addBankObj: state.addBankObj,
    navToggleState: state.navToggleState
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setContractState: (flag, data) => { dispatch(setContractState({ modal: flag, info: data })) },
    setaddBankObj: (flag, data) => { dispatch(setaddBankObj({ modal: flag, info: data })) },
    setClientType: (type) => { dispatch(setClientType({ type: type })) }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateWorkOrderContract)