import React, { useEffect } from "react";
import { useState } from "react";
import { astrix } from "./myFunctions";
import { InputLabel, MenuItem, Select } from "@mui/material";

export const NewTextArea = ({ hideInputHeader, rows, isAstrix, label, type, name, value, onChange, error, onView, isDisabled, key, onClick, customclass }) => {

  const [showLabel, toggleLabel] = useState(false)

  return (
    <div className="row form-group">
      {(showLabel || value) && !hideInputHeader ? (
        <label className="input-label">{label} {isAstrix ? astrix : ""}</label>) : null}
      <div className="col-md-12">
        <textarea
          onClick={() => {
            if (onClick) {
              onClick()
            }
          }}
          key={key || label}
          style={{ minHeight: "2.5rem" }}
          disabled={isDisabled} type={type}
          placeholder={label + (isAstrix ? " *" : '')}
          onFocus={() => toggleLabel(true)}
          onBlur={() => toggleLabel(false)}
          rows={rows || 1}
          className={"form-control bg-white" + (error ? " border-danger " : "") + " " + (customclass ? customclass : '')}
          name={name} value={value} onChange={onChange} />
        {error ? <div class="text-danger mt-2 font-size-12">
          <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
          <b>{error}</b></div> : ''}
      </div>

    </div>
  )
}

export const NewInput = ({ onKeyDown, inputClass, key, highlightBorder, onClick, noIcon, isAstrix, label, type, name, defaultValue, value, onChange, error, onView, isDisabled, minDate, maxlength, removeMb, customclass }) => {

  const [showLabel, toggleLabel] = useState(type === "date")

  return (
    <div className={`row form-group ${removeMb ? 'mb-0' : ''}`}>
      {showLabel || value || type === "date" ? (
        <label className="input-label px-1">{label} {isAstrix ? astrix : ""}</label>) : null}
      <div onClick={onClick} className={`col-md-12 ${highlightBorder ? 'selectedInput' : ''} `}>
        <input key={key || label} disabled={isDisabled} type={type}
          min={minDate}
          onKeyDown={onKeyDown}
          placeholder={label + (isAstrix ? " *" : '')}
          onFocus={() => toggleLabel(true)}
          onBlur={() => toggleLabel(false)}
          className={"form-control bg-white" + (highlightBorder && " border-0 ") + (error ? " border-danger " : " ") + inputClass + "" + (customclass ? ` ${customclass}` : '')}
          name={name} defaultValue={defaultValue || ""} value={value} onChange={onChange} maxlength={maxlength ? maxlength : "524288"} />
        {/* {type === "date" && !noIcon ? (
          <img src={"assets/images/calender.png"} className="input-date-icon" />
        ) : null} */}
        {error ? <div class="text-danger mt-2 font-size-12">
          <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
          <b>{error}</b></div> : ''}
      </div>
    </div>
  )
}

export const InputForTable = ({ onKeyDown, inputClass, key, highlightBorder, onClick, noIcon, isAstrix, label, type, name, defaultValue, value, onChange, error, onView, isDisabled, minDate, maxlength, removeMb, customclass }) => {

  const [showLabel, toggleLabel] = useState(type === "date")
  const [focusState, toggleFocusState] = useState(false)

  return (
    <div className={`row form-group m-0 p-0 ${removeMb ? 'mb-0' : ''}`}>
      {showLabel || value ? (
        <label className="input-label px-1">{label} {isAstrix ? astrix : ""}</label>) : null}
      {focusState ? <div onClick={onClick} className={`col-md-12 ${highlightBorder ? 'selectedInput' : ''} `}>
        <input
          autoFocus={true}
          key={key || label} disabled={isDisabled} type={type}
          min={minDate}
          onKeyDown={onKeyDown}
          placeholder={label + (isAstrix ? " *" : '')}
          onFocus={() => toggleLabel(true)}
          onBlur={() => {
            toggleLabel(false);
            toggleFocusState(false)
          }}
          className={"form-control bg-white table-form-input " + (highlightBorder && " border-0 ") + (error ? " border-danger " : " ") + inputClass + "" + (customclass ? ` ${customclass}` : '')}
          name={name} defaultValue={defaultValue || ""} value={value} onChange={onChange} maxlength={maxlength ? maxlength : "524288"} />
        {/* {type === "date" && !noIcon ? (
          <img src={"assets/images/calender.png"} className="input-date-icon" />
        ) : null} */}
      </div> : <label style={{ height: '100%', width: '100%' }} className={"font-size-12 font-wt-500 cursor m-0 p-0"}
        onClick={() => toggleFocusState(true)}
      >{value || (isDisabled ? "-" : "Enter")}</label>}
      {error ? <div class="text-danger mt-2 font-size-12">
        <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
        <b>{error}</b></div> : ''}
    </div>
  )
}

export const SelectForTable = ({ noIcon, isAstrix, label,
  optionValue, optionLabel, selectData,
  name, value, onChange, error, isDisabled, highlightBorder, onClick, removeMb }) => {

  const [showLabel, toggleLabel] = useState(false)

  return (
    <div className={`row form-group m-0 p-0 ${removeMb ? 'mb-0' : ''}`}>
      {true ? (
        <label className="input-label">{label} {isAstrix ? astrix : ""}</label>) : null}
      <div
        onClick={onClick}
        className={`col-md-12 p-0 m-0 ${highlightBorder ? 'selectedInput' : ''} `}>
        <select
          onFocus={() => toggleLabel(true)}
          onBlur={() => toggleLabel(false)}
          disabled={isDisabled} className={" form-control form-select table-form-select " + (highlightBorder && "border-0") + (error ? " border-danger " : " ")} name={name}
          value={value} onChange={onChange} >
          {/* <option disabled selected value="">{(showLabel || value) ? "" : `${label} ${isAstrix ? " *" : ""}`} </option> */}
          <option value="">{"Select"} </option>
          {selectData.map((item, index) => (
            <option key={index} value={item[optionValue]} onClick={(e) => console.log('select', e.currentTarget.value)}>{item[optionLabel]}</option>
          ))}
        </select>
        {error ? <div class="text-danger mt-2 font-size-12">
          <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
          <b>{error}</b></div> : ''}
      </div>
    </div>
  )
}

export const NewSelect = ({ noIcon, isAstrix, label,
  optionValue, optionLabel, selectData,
  name, value, onChange, error, isDisabled, highlightBorder, onClick, removeMb, id }) => {

  const [showLabel, toggleLabel] = useState(false)

  return (
    <div className={`row form-group ${removeMb ? 'mb-0' : ''}`}>
      {true ? (
        <label className="input-label">{label} {isAstrix ? astrix : ""}</label>) : null}
      <div
        onClick={onClick}
        className={`col-md-12 ${highlightBorder ? 'selectedInput' : ''} `}>
        <select id={id}
          onFocus={() => toggleLabel(true)}
          onBlur={() => toggleLabel(false)}
          disabled={isDisabled} className={"form-control form-select " + (highlightBorder && "border-0") + (error ? " border-danger " : " ")} name={name}
          value={value} onChange={onChange} >
          {/* <option disabled selected value="">{(showLabel || value) ? "" : `${label} ${isAstrix ? " *" : ""}`} </option> */}
          <option value="">{"Select"} </option>
          {selectData.map((item, index) => (
            <option key={index} value={item[optionValue]} onClick={(e) => console.log('select', e.currentTarget.value)}>{item[optionLabel]}</option>
          ))}
        </select>
        {error ? <div class="text-danger mt-2 font-size-12">
          <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
          <b>{error}</b></div> : ''}
      </div>
    </div>
  )
}


export const InputWithSelect = ({ defaultSelectValue, defaultValue, key, isAstrix, type, label,
  selectName, selectValue, optionValue, optionLabel, selectData,
  name, value, onChange, error, isDisabled, InputFocus, onClick }) => {

  const [showLabel, toggleLabel] = useState(false)

  return (
    <div
      onClick={() => {
        if (onClick) {
          onClick()
        }
      }}
      className="row form-group justify-content-start mx-0">
      {showLabel || value ? (
        <label className="input-label">{label} {isAstrix ? astrix : ""}</label>) : null}
      <div className={`col-12 px-0 d-flex borderRadius  ${InputFocus ? "LimitRequiredFocus " : ""}`}>
        <select disabled={isDisabled} className={"form-control w-auto select-attached rounded-end-0" + (error ? " border-danger" : "") + (InputFocus ? " border-0 " : " ")} name={selectName}
          defaultValue={defaultSelectValue || ""} value={selectValue} onChange={onChange} onClick={(e) => console.log('on select click', e.currentTarget.value)}>
          <option value="" onClick={(e) => console.log('Empty Value selected', e.currentTarget.value)}>{"Select"}</option>
          {selectData.map((item, index) => (
            <option key={index} value={item[optionValue]} onClick={(e) => console.log('on select click', e.currentTarget.value)}>{item[optionLabel]}</option>
          ))}
        </select>
        <input disabled={isDisabled} type={type}
          placeholder={label + (isAstrix ? " *" : '')}
          onFocus={() => toggleLabel(true)}
          onBlur={() => toggleLabel(false)}
          className={"form-control w-100 bg-white input-attached selectinputform rounded-start-0 border" + (error ? " border-danger" : "") + (InputFocus ? "" : "border-0")}
          name={name} defaultValue={defaultValue || ""} value={value} onChange={onChange} />
        {/* {error ? <div class="text-danger mt-2 font-size-12">
          <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
          <b>{error}</b></div> : ''} */}
      </div>
      {error ? <div class="text-danger mt-2 font-size-12">
        <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
        <b>{error}</b></div> : ''}
    </div>
  )

}

export const InputWithSelectForTable = ({ defaultSelectValue, defaultValue, key, isAstrix, type, label,
  selectName, selectValue, optionValue, optionLabel, selectData,
  name, value, onChange, error, isDisabled, InputFocus, onClick }) => {

  const [showLabel, toggleLabel] = useState(false)
  const [focusState, toggleFocusState] = useState(false)

  return (
    <div
      onClick={() => {
        if (onClick) {
          onClick()
        }
      }}
      className="row form-group justify-content-start m-0 p-0">
      <div className={`col-12 px-0 d-flex borderRadius p-0 m-0 ${InputFocus ? "LimitRequiredFocus " : ""}`}>
        <select disabled={isDisabled} className={"form-control w-auto select-attached rounded-end-0 table-form-input" + (error ? " border-danger" : "") + (InputFocus ? " border-0 " : " ")} name={selectName}
          defaultValue={defaultSelectValue || ""} value={selectValue} onChange={onChange} onClick={(e) => console.log('on select click', e.currentTarget.value)}>
          <option value="" onClick={(e) => console.log('Empty Value selected', e.currentTarget.value)}>{"Select"}</option>
          {selectData.map((item, index) => (
            <option key={index} value={item[optionValue]} onClick={(e) => console.log('on select click', e.currentTarget.value)}>{item[optionLabel]}</option>
          ))}
        </select>
        {focusState ?
          <input autoFocus={true} disabled={isDisabled} type={type}
            onFocus={() => toggleLabel(true)}
            onBlur={() => {
              toggleLabel(false);
              toggleFocusState(false)
            }}
            className={"form-control w-100 bg-white selectinputform rounded-start-0 table-form-input " + (error ? " border-danger" : "") + (InputFocus ? "" : "border-0")}
            name={name} defaultValue={defaultValue || ""} value={value} onChange={onChange} /> :
          <label style={{ height: '100%', width: '99%' }} className={"font-size-13 font-wt-500 cursor pt-2 "}
            onClick={() => toggleFocusState(true)}
          >{value || (isDisabled ? "-" : "Enter")}</label>
        }
      </div>
      {error ? <div class="text-danger mt-2 font-size-12">
        <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
        <b>{error}</b></div> : ''}
    </div>
  )

}

export const CustomSelect = ({ noIcon, isAstrix, label, onItemCllick,
  optionValue, optionLabel, selectData,
  name, value, onChange, error, isDisabled, highlightBorder, onClick, removeMb }) => {
  const [showLabel, toggleLabel] = useState(false)
  return <>
    <div>
      {label && <InputLabel id="demo-simple-select-label">{label}</InputLabel>}
      <Select
        labelId="demo-simple-select"
        id="demo-simple-select"
        onFocus={() => toggleLabel(true)}
        onBlur={() => toggleLabel(false)}
        disabled={isDisabled}
        className={"form-control  custom-select " + (highlightBorder && "border-0") + (error ? " border-danger " : " ")}
        name={name}
        value={value} onChange={onChange}
        label={label}
        //style={{ borderc }}
        IconComponent={() => null}

      >
        {selectData.map((item, index) => (
          <MenuItem
            key={index} value={item[optionValue]} onClick={(e) => onItemCllick(item[optionValue])} >{item[optionLabel]}</MenuItem>
        ))}
      </Select>
    </div>

  </>
}
