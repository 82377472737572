import React, { useEffect } from 'react'
import { useState } from 'react'
import { NewInput, NewSelect } from '../../../utils/newInput'
import { Area, AreaChart, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import moment from 'moment';
import { ExportExcel, getInitials, removeDuplicates } from '../../../utils/myFunctions';
import PieChartComponent from './PieChartComponent';
import { NewTable } from '../../../utils/newTable';
import toastDisplay from '../../../utils/toastNotification';
import Filter from '../../InvoiceDiscounting/components/Filter';
import Pagination from '../../InvoiceDiscounting/contract/components/pagination';
import CustomLineChart from '../../CRM/CustomLineChart';
import call from '../../../service';
import CustomBarChart from '../../Dashboard/components/CustomBarChart';
import { companyTypes } from '../../registration/newRegistration';
import UserDetails from '../../UserOnboard/UserDetails';
import FinancerDetails from '../../UserOnboard/FinancerDetails';
import ChannelPartnerDetails from '../../UserOnboard/ChannelPartnerDetails';
const lanesColor = ['#2ECC71', '#5CB8D3']
const bardataConfig = [
  { dataKey: 'active', fill: '#2ECC71', display: 'Active' },
  { dataKey: 'inActive', fill: '#FF7B6D', display: 'Inactive' },
]

const shipmentsConfig = [
  { dataKey: 'SHIPMENTS', fill: '#C7F2FF', display: 'Active' },
]

const shipmentsConfigValues = [
  { dataKey: 'FOB', fill: '#C7F2FF', display: 'Active' },
]

const exportWiseConfig = [
  { dataKey: "SHIPMENTS", fill: "#5CB8D3", display: 'label' },
]

const exportWiseConfigValues = [
  { dataKey: "FOB", fill: "#5CB8D3", display: 'label' },
]
const geodataconfig = [
  { dataKey: "exporter_count", fill: "#2ECC71", display: 'Others' },
  { dataKey: "financer_count", fill: "#5CB8D3", display: 'Interested' },
  { dataKey: "cp_count", fill: "#FFB801", display: 'Not Interested' }
]
const expwiseConfig = [
  { dataKey: "count", fill: "#2ECC71", display: 'Others' }
]

const dealsConfig = [
  { dataKey: "total_lc_value", fill: "#2ECC71", display: 'LC' },
  { dataKey: "total_inv_value", fill: "#5CB8D3", display: 'Invoice' },
  // { dataKey: "others", fill: "#FFB801", display: 'Others' }
]

const dealsConfigCount = [
  { dataKey: "total_lc_count", fill: "#2ECC71", display: 'LC' },
  { dataKey: "total_inv_count", fill: "#5CB8D3", display: 'Invoice' },
  // { dataKey: "others", fill: "#FFB801", display: 'Others' }
]


const labels = {
  'value': 'Exporters',
  'FinValue': 'Buyers',
  'CPValue': 'Channel Partner'
}
const geolabel = {
  'exporter_count': "Exporter",
  'financer_count': "Financer",
  'cp_count': "Partner"
}

let exportWiseMapping = {
  "HS Code": "HS_CODE",
  "Port Of Loading": "INDIAN_PORT",
  "Port Of Discharge": "DESTINATION_PORT",
  "Lanes Used": "Lanes",
  "Exporter City": "EXPORTER_CITY",
  "Export Country": "DESTINATION_COUNTRY"
}

let dealsmapping = {
  total_lc_value: "LC",
  total_inv_value: "Invoice"
}

let dealsmappingCount = {
  total_lc_count: "LC",
  total_inv_count: "Invoice"
}

const UserReportsTab = ({ userTokenDetails, dateRange }) => {
  let todayDateObj = moment()
  let lastMonthDateObj = moment().subtract("1", "months")
  const [graphData, setGraphData] = useState({})
  const [stats, setStats] = useState({})
  const [userOnboardSelect, setUserOnboardSelect] = useState('all')
  const [onboardGraphdata, setOnboardGraphdata] = useState([])
  const [overallonboarddata, setoverallonboardata] = useState([])

  const [dealsGraphdata, setdealsGraphdata] = useState([])
  const [overalldealsdata, setoveralldealsdata] = useState([])
  const [dealsTab, setdealsTab] = useState('Values')


  const [EarningSelect, setEarningSelect] = useState('all')
  const [earningGraphData, setearningGraphData] = useState([])
  const [graphConfiguration, setGraphConfiguration] = useState({
    earningDuration: "6 Months",
    onbordedDuration: "6 Months",
    lcLimitDuration: "6 Months",
    invoiceLimitDuration: "6 Months",
    applicationStageGraphMode: true,
    applicationStageFrom: lastMonthDateObj.clone().format("YYYY-MM-DD"),
    applicationStageTo: todayDateObj.clone().format("YYYY-MM-DD"),
  })
  const [transactionPopup, toggleTransactionPopup] = useState({ show: false, data: [] })
  const [showLoader, setshowLoader] = useState(false)
  const [userStatusGraph, setUserStatusGraph] = useState([])
  const [filterData, setfilterdata] = useState([])
  const [filter, setFilter] = useState({ resultPerPage: 10 })
  const [refresh, setrefresh] = useState(0)
  const [Count, setCount] = useState(0)
  const [page, setpage] = useState(1)
  const [dbData, setdbData] = useState([])
  const [filteredSearch, setFilteredSearch] = useState([])
  const [overalldata, setoveralldata] = useState([])
  const [geographdata, setgeographdata] = useState([])
  const [selectedTypeDealsGraph, setselectedTypeDealsGraph] = useState('Exporter')
  const [selectedAppStageGraph, setselectedAppStageGraph] = useState('Exporter')

  const [selectedTypeExportWiseGraph, setselectedTypeExportWiseGraph] = useState('HS Code')
  const [exportWiseGraphdata, setExportWiseGraphdata] = useState([])
  const [BuyersExpWiseGraphdata, setBuyersExpWiseGraphdata] = useState([])
  const [shipmentsgraphdata, setshipmentsgraphdata] = useState([])
  const [exportdataTab, setexportdataTab] = useState('Values')
  const [shipmentsTab, setshipmentsTab] = useState('Values')
  const [dbdata, setdbdata] = useState([])
  const [showDetails, setShowDetails] = useState({
    isVisible: false,
    data: {},
    type: ''
  })
  const handleGraphConfigurationChange = async (event) => {
    if (event.persist) {
      event.persist()
    }
    setGraphConfiguration({ ...graphConfiguration, [event.target.name]: event.target.value })
  }
  const getGeographicaldata = () => {
    call('POST', 'getGeographicaldata', {}).then(result => {
      setgeographdata(result)
    }).catch(e => {
      console.log('error in getGeographicaldata', e);
    })
  }
  useEffect(() => {
    getGeographicaldata()
    getBuyersByExporters()
    getShipmentsdata()
  }, [dateRange])
  useEffect(() => {
    getExportWisedata()
  }, [dateRange, selectedTypeExportWiseGraph])
  const getExportWisedata = () => {
    setshowLoader(true)
    let objectAPI = {
      from: dateRange.from,
      to: dateRange.to
    }
    objectAPI["groupParam"] = exportWiseMapping[selectedTypeExportWiseGraph]
    //setShowLoader(true)
    call('POST', 'getExportWisedata', objectAPI).then(result => {
      setshowLoader(false)
      setExportWiseGraphdata(result)
      //setCountrydata(result)
    }).catch(e => {
      setshowLoader(false)

    })
  }
  const getBuyersByExporters = () => {
    setshowLoader(true)
    let objectAPI = {
      from: dateRange.from,
      to: dateRange.to
    }
    //setShowLoader(true)
    call('POST', 'getBuyersByExporters', objectAPI).then(result => {
      setshowLoader(false)
      setBuyersExpWiseGraphdata(result)
      //setCountrydata(result)
    }).catch(e => {
      setshowLoader(false)

    })
  }
  const getShipmentsdata = () => {
    setshowLoader(true)
    let objectAPI = {
      from: dateRange.from,
      to: dateRange.to
    }
    //setShowLoader(true)
    call('POST', 'getShipmentsdata', objectAPI).then(result => {
      setshowLoader(false)
      setshipmentsgraphdata(result)
      //setCountrydata(result)
    }).catch(e => {
      setshowLoader(false)

    })
  }
  async function downloadActiveUserApplicationStage() {
    if (!graphData.activeApplicationStageTableData?.length) {
      return toastDisplay('No data found to download', "info")
    }
    try {
      let temp = graphData.activeApplicationStageTableData
      let csvString = "Active Users (Application Stage),Finance Limit,Quote,Termsheet/Contract,Finance,Agreement,Approved\r\n"
      let rowString = `${temp[0][0]},${temp[0][1]},${temp[0][2]},${temp[0][3]},${temp[0][4]},${temp[0][5]},${temp[0][6]}\r\n`
      rowString = rowString.replace(/(\n)/gm, "")
      csvString += rowString

      rowString = `${temp[1][0]},${temp[1][1]},${temp[1][2]},${temp[1][3]},${temp[1][4]},${temp[1][5]},${temp[1][6]}\r\n`
      rowString = rowString.replace(/(\n)/gm, "")
      csvString += rowString

      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', `ActiveUserApplicationStage.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log("error in downloadActiveUserApplicationStage", error);
    }
  }

  async function downloadInactiveUserDays() {
    if (!graphData.inactiveUserDaysTableData?.length) {
      return toastDisplay('No data found to download', "info")
    }
    try {
      let temp = graphData.inactiveUserDaysTableData
      let csvString = "Inactive Users(Days),15 Days,30 Days,45 Days,60 Days,75 Days\r\n"
      let rowString = `${temp[0][0]},${temp[0][1]},${temp[0][2]},${temp[0][3]},${temp[0][4]},${temp[0][5]}\r\n`
      rowString = rowString.replace(/(\n)/gm, "")
      csvString += rowString

      rowString = `${temp[1][0]},${temp[1][1]},${temp[1][2]},${temp[1][3]},${temp[1][4]},${temp[1][5]}\r\n`
      rowString = rowString.replace(/(\n)/gm, "")
      csvString += rowString

      rowString = `${temp[2][0]},${temp[2][1]},${temp[2][2]},${temp[2][3]},${temp[2][4]},${temp[2][5]}\r\n`
      rowString = rowString.replace(/(\n)/gm, "")
      csvString += rowString

      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', `InactiveUserSummary.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log("error in downloadInactiveUserDays", error);
    }
  }
  useEffect(() => {
    setshowLoader(true)
    call('POST', 'getTaskManagerGraphData', {
      applicationStageFrom: dateRange.from,
      applicationStageTo: dateRange.to
    }).then(res => {
      console.log("getTaskManagerGraphData response===============>", res);
      setshowLoader(false)
      let activeApplicationStageFunnelGraphData = []
      activeApplicationStageFunnelGraphData[0] = { "name": "Finance Limit", "value": res.activeUserApplicationSummary["Finance Limit"]["invoice"] + res.activeUserApplicationSummary["Finance Limit"]["lc"] }
      activeApplicationStageFunnelGraphData[1] = { "name": "Quote", "value": res.activeUserApplicationSummary["Quote"]["invoice"] + res.activeUserApplicationSummary["Quote"]["lc"] }
      activeApplicationStageFunnelGraphData[2] = { "name": "Termsheet/Contract", "value": res.activeUserApplicationSummary["Termsheet/Contract"]["invoice"] + res.activeUserApplicationSummary["Termsheet/Contract"]["lc"] }
      activeApplicationStageFunnelGraphData[3] = { "name": "Finance", "value": res.activeUserApplicationSummary["Finance"]["invoice"] + res.activeUserApplicationSummary["Finance"]["lc"] }
      activeApplicationStageFunnelGraphData[4] = { "name": "Agreement", "value": res.activeUserApplicationSummary["Agreement"]["invoice"] }
      activeApplicationStageFunnelGraphData[5] = { "name": "Approved", "value": res.activeUserApplicationSummary["Approved"]["invoice"] + res.activeUserApplicationSummary["Approved"]["lc"] }

      let inactiveUserDaysFunnelGraphData = []
      inactiveUserDaysFunnelGraphData[0] = { "name": "15 Days", "value": res.inactiveUserDayWiseSummary["15 Days"]["exporter"] + res.inactiveUserDayWiseSummary["15 Days"]["channelPartner"] + res.inactiveUserDayWiseSummary["15 Days"]["financers"] }
      inactiveUserDaysFunnelGraphData[1] = { "name": "30 Days", "value": res.inactiveUserDayWiseSummary["30 Days"]["exporter"] + res.inactiveUserDayWiseSummary["30 Days"]["channelPartner"] + res.inactiveUserDayWiseSummary["30 Days"]["financers"] }
      inactiveUserDaysFunnelGraphData[2] = { "name": "45 Days", "value": res.inactiveUserDayWiseSummary["45 Days"]["exporter"] + res.inactiveUserDayWiseSummary["45 Days"]["channelPartner"] + res.inactiveUserDayWiseSummary["45 Days"]["financers"] }
      inactiveUserDaysFunnelGraphData[3] = { "name": "60 Days", "value": res.inactiveUserDayWiseSummary["60 Days"]["exporter"] + res.inactiveUserDayWiseSummary["60 Days"]["channelPartner"] + res.inactiveUserDayWiseSummary["60 Days"]["financers"] }
      inactiveUserDaysFunnelGraphData[4] = { "name": "75 Days", "value": res.inactiveUserDayWiseSummary["75 Days"]["exporter"] + res.inactiveUserDayWiseSummary["75 Days"]["channelPartner"] + res.inactiveUserDayWiseSummary["75 Days"]["financers"] }

      let activeApplicationStageTableData = []
      activeApplicationStageTableData[0] = ["Invoice Discounting", res.activeUserApplicationSummary["Finance Limit"]["invoice"], res.activeUserApplicationSummary["Quote"]["invoice"], res.activeUserApplicationSummary["Termsheet/Contract"]["invoice"],
        res.activeUserApplicationSummary["Finance"]["invoice"], res.activeUserApplicationSummary["Agreement"]["invoice"], res.activeUserApplicationSummary["Approved"]["invoice"]]
      activeApplicationStageTableData[1] = ["LC Discounting", res.activeUserApplicationSummary["Finance Limit"]["lc"], res.activeUserApplicationSummary["Quote"]["lc"], res.activeUserApplicationSummary["Termsheet/Contract"]["lc"],
        res.activeUserApplicationSummary["Finance"]["lc"], 0, res.activeUserApplicationSummary["Approved"]["lc"]]

      let inactiveUserDaysTableData = []
      inactiveUserDaysTableData[0] = ["Exporter", res.inactiveUserDayWiseSummary["15 Days"]["exporter"], res.inactiveUserDayWiseSummary["30 Days"]["exporter"], res.inactiveUserDayWiseSummary["45 Days"]["exporter"],
        res.inactiveUserDayWiseSummary["60 Days"]["exporter"], res.inactiveUserDayWiseSummary["75 Days"]["exporter"]]
      inactiveUserDaysTableData[1] = ["financers", res.inactiveUserDayWiseSummary["15 Days"]["financers"], res.inactiveUserDayWiseSummary["30 Days"]["financers"], res.inactiveUserDayWiseSummary["45 Days"]["financers"],
        res.inactiveUserDayWiseSummary["60 Days"]["financers"], res.inactiveUserDayWiseSummary["75 Days"]["financers"]]
      inactiveUserDaysTableData[2] = ["Channel Partner", res.inactiveUserDayWiseSummary["15 Days"]["channelPartner"], res.inactiveUserDayWiseSummary["30 Days"]["channelPartner"], res.inactiveUserDayWiseSummary["45 Days"]["channelPartner"],
        res.inactiveUserDayWiseSummary["60 Days"]["channelPartner"], res.inactiveUserDayWiseSummary["75 Days"]["channelPartner"]]

      setGraphData({
        ...res, activeApplicationStageFunnelGraphData, inactiveUserDaysFunnelGraphData, activeApplicationStageTableData, inactiveUserDaysTableData
      })
    })
  }, [graphConfiguration])
  const getReportsUserStats = () => {
    setshowLoader(true)
    call('POST', 'getReportsUserStats', {}).then(result => {
      setStats(result)
      setshowLoader(false)
    }).catch(e => {
      console.log('error in getReportsUserStats', e);
      setshowLoader(false)
    })
  }
  useEffect(() => {
    getReportsUserStats()
    getUserStatusGraph()
    getUserOnboardgraph()
  }, [dateRange])
  const getUserStatusGraph = () => {
    setshowLoader(true)
    call('POST', 'getUserStatusGraph', { from: dateRange.from, to: dateRange.to }).then(result => {
      setUserStatusGraph(result.barDataForUserOnboarded)
      setshowLoader(false)
    }).catch(e => {
      setshowLoader(false)
      console.log('error in getReportsUserStats', e);
    })
  }
  const getUserOnboardgraph = () => {
    setshowLoader(true)
    call('POST', 'getUserOnboardgraph', { from: dateRange.from, to: dateRange.to }).then(result => {
      setOnboardGraphdata(result)
      setoverallonboardata(result)
      setshowLoader(false)
    }).catch(e => {
      setshowLoader(false)

      console.log('error in getReportsUserStats', e);
    })
  }
  const getXAxisDateFormat = (data, value) => {
    let countForMonths = moment(dateRange.to).diff(dateRange.from, 'month')
    let dateFormat = ''
    if (countForMonths > 3) {
      dateFormat = moment(value).format('MMM YYYY')
    } else if (countForMonths <= 1) {
      dateFormat = moment(value).format('DD MMM YYYY')
    } else {
      dateFormat = value
    }
    return dateFormat
  }

  useEffect(() => {
    let overalldata = []
    if (userOnboardSelect === 'all') {
      overalldata = overallonboarddata || []
    } else {
      let keysincluded = ["label"]
      keysincluded.push(userOnboardSelect)
      overalldata = overallonboarddata.map(obj => {
        const newObj = {};
        keysincluded.forEach(key => {
          newObj[key] = obj[key];
        });
        return newObj;
      }) || []
    }
    setOnboardGraphdata(overalldata)
  }, [dateRange, userOnboardSelect])


  const getDeals = () => {
    setshowLoader(true)
    call('POST', 'getDeals', { from: dateRange.from, to: dateRange.to, selectedTypeDealsGraph }).then(result => {
      setdealsGraphdata(result)
      setoveralldealsdata(result)
      setshowLoader(false)
    }).catch(e => {
      setshowLoader(false)

      console.log('error in getReportsUserStats', e);
    })
  }
  useEffect(() => {
    getDeals()
  }, [dateRange, selectedTypeDealsGraph])

  const getLeadsListByAdmin = () => {
    setshowLoader(true)
    let reqObj = {
      from: dateRange.from,
      to: dateRange.to,
      resultPerPage: filter.resultPerPage,
      currentPage: page,
      sortByDate: 'DESC',
      ...filter
    }
    call('POST', 'getLeadsListByAdmin', reqObj).then(result => {
      setshowLoader(false)
      setoveralldata(result.message)
      setCount(result.total_count)
      setdbData(formatDataForTable(result.message))
    }).catch(e => {
      setshowLoader(false)
    })
  }
  function formatDataForTable(data) {
    let tableData = []
    let row = []
    let mapping = {
      8: 'Financier',
      19: 'Exporter',
      20: 'Channel Partner'
    }
    data.forEach((item, index) => {
      const orgType = companyTypes.find(element => element.alt === item.organization_type)
      row[0] = item.created_at ? moment(item.created_at).format('DD/MM/YYYY') : 'NA'
      row[1] = mapping[item.type_id] ? mapping[item.type_id] : 'NA'
      row[2] = item.company_name ? item.company_name : 'NA'
      row[3] = orgType ? orgType.name : 'NA'
      row[4] = `${item.name_title ? item.name_title : ''} ${item.contact_person ? item.contact_person : ''}`
      row[5] = `${item.phone_code ? "+" + item.phone_code : ''} ${item.contact_number ? item.contact_number : ''}`
      row[6] = item.TaskAssignedToName ? item.TaskAssignedToName : 'NA'
      row[7] = item.notification_description ? item.notification_description : 'NA'
      row[8] = <img src='/assets/images/redirect.svg' className='cursor' onClick={async () => {
        setshowLoader(true)
        // console.log("iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii", item);
        await call("POST", 'checkKYCDetailsOfUser', { userId: item.id })
        setshowLoader(false)
        let tempObj = {
          isVisible: true,
          data: item,
          type: mapping[item.type_id]
        }
        if (item.type_id / 1 == 21) {
          tempObj["data"] = {
            ...item,
            type_id: 19,
            id: item.parent_id,
            ttvExporterCode: item.parent_ttv_exporter_code,
            subUserProfileDetails: {
              type_id: item.type_id, id: item.id, parent_email_id: item.parent_email_id,
              contactPerson: item.contact_person, contact_number: item.contact_number,
              phone_code: item.phone_code, email_id: item.email_id
            }
          }
        }
        console.log("viewexpdetails=============>", tempObj);
        setShowDetails(tempObj)
      }} />

      tableData.push(row)
      row = []
    })
    return tableData
  }
  useEffect(() => {
    getLeadsListByAdmin()
  }, [dateRange, filter, page])
  return (
    <>
      <div className={`modal fade ${transactionPopup.show && "show"}`} style={transactionPopup.show ? { display: "block", "zIndex": '100001' } : {}}>
        <div className="modal-dialog modal-md mr-0 my-0">
          <div className="modal-content submitmodal pb-4"
          >

            <div className="modal-header border-0">
              <div className="w-100 d-flex align-items-center justify-content-between">
                <label
                  className="font-size-16 font-wt-600 text-color-value mx-3"
                >Transaction History</label>
                <div className="modal-header border-0">
                  <button type="button" className="btn-close" aria-label="Close" onClick={() => toggleTransactionPopup({ show: false, data: [] })}></button>
                </div>
              </div>
            </div>

            <div className="modal-body px-4">
              {transactionPopup.data.length ? transactionPopup.data.map((item, index) => {
                return (
                  <div className='d-flex flex-row ml-3'>
                    <div className="progressBarContainer">
                      <div className="progressBarInnerCircle">
                      </div>
                    </div>
                    <div className='pl-4 pt-3'>
                      <p className='font-size-14 text-color1 font-wt-500 mb-0'>{item.action}</p>
                      <p className='font-size-14 text-color-label font-wt-500 mb-0'>{item.date}</p>
                      <p className='font-size-14 text-color-label font-wt-500 mb-0'>{item.time}</p>
                    </div>
                  </div>
                )
              }) :
                null}
            </div>

          </div>
        </div>
      </div>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {showDetails.isVisible && showDetails.type === 'Exporter' ?
        <div className='mt-4'>
          <UserDetails data={showDetails.data} goBack={() => {
            setShowDetails({
              isVisible: false,
              data: {}
            })
          }} userTokenDetails={userTokenDetails} />
        </div>
        :
        showDetails.isVisible && showDetails.type === 'Financier' ?
          <div className='mt-4'>
            <FinancerDetails data={showDetails.data} goBack={() => {
              setShowDetails({
                isVisible: false,
                data: {}
              })
            }} userTokenDetails={userTokenDetails} />
          </div>
          :
          showDetails.isVisible && showDetails.type === 'Channel Partner' ?
            <div className='mt-4'>
              <ChannelPartnerDetails data={showDetails.data} goBack={() => {
                setShowDetails({
                  isVisible: false,
                  data: {}
                })
              }} userTokenDetails={userTokenDetails} />
            </div>
            :
            <div>
              <div className='row mt-5 d-flex justify-content-between'>

                <div className='w-23'>
                  <div className='card h-75 dashboard-card shadow-sm  justify-content-center'>
                    <label className='font-size-14 text-color-value font-wt-600 pt-2'>{`Total Users - `}
                      <label className='font-size-14 text-color-value font-wt-600 text-custom2'>{` ${stats["total_users"] || "0"}`}</label></label>
                    <div className='row px-4'>
                      <div className='w-50'>
                        <label className={`value font-wt-600 text-48DA87  w-100`}>
                          {`${stats["total_active_users"] || "0"}`}
                        </label>
                        <label className={'font-size-14 font-wt-600 text-color-value'}>{"Active"}</label>
                      </div>

                      <div className='w-50'>
                        <label className={`value font-wt-600 colorFF7B6D w-100`}>
                          {`${stats["total_inactive_users"] || "0"}`}
                        </label>
                        <label className={'font-size-14 font-wt-600 text-color-value'}>{"Inactive"}</label>
                      </div>
                    </div>

                  </div>
                </div>

                <div className='w-23'>
                  <div className='card h-75 dashboard-card shadow-sm  justify-content-center'>
                    <label className='font-size-14 text-color-value font-wt-600 pt-2'>{`Exporters - `}
                      <label className='font-size-14 text-color-value font-wt-600 text-custom2'>{` ${stats["total_Exp"] || "0"}`}</label></label>
                    <div className='row px-4'>
                      <div className='w-50'>
                        <label className={`value font-wt-600 text-48DA87  w-100`}>
                          {`${stats["active_Exp"] || "0"}`}
                        </label>
                        <label className={'font-size-14 font-wt-600 text-color-value'}>{"Active"}</label>
                      </div>

                      <div className='w-50'>
                        <label className={`value font-wt-600 colorFF7B6D w-100`}>
                          {`${stats["inactive_Exp"] || "0"}`}
                        </label>
                        <label className={'font-size-14 font-wt-600 text-color-value'}>{"Inactive"}</label>
                      </div>
                    </div>

                  </div>
                </div>

                <div className='w-23'>
                  <div className='card h-75 dashboard-card shadow-sm   justify-content-center'>
                    <label className='font-size-14 text-color-value font-wt-600 pt-2'>{`Financer- `}
                      <label className='font-size-14 text-color-value font-wt-600 text-custom2'>{` ${stats["total_Fin"] || "0"}`}</label></label>
                    <div className='row px-4'>
                      <div className='w-50'>
                        <label className={`value font-wt-600 text-48DA87  w-100`}>
                          {`${stats["active_Fin"] || "0"}`}
                        </label>
                        <label className={'font-size-14 font-wt-600 text-color-value'}>{"Active"}</label>
                      </div>

                      <div className='w-50'>
                        <label className={`value font-wt-600 colorFF7B6D w-100`}>
                          {`${stats["inactive_Fin"] || "0"}`}
                        </label>
                        <label className={'font-size-14 font-wt-600 text-color-value'}>{"Inactive"}</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='w-23'>
                  <div className='card h-75 dashboard-card shadow-sm  justify-content-center'>
                    <label className='font-size-14 text-color-value font-wt-600 pt-2'>{`Channel Partner - `}
                      <label className='font-size-14 text-color-value font-wt-600 text-custom2'>{` ${stats["total_CP"] || "0"}`}</label></label>
                    <div className='row px-4'>
                      <div className='w-50'>
                        <label className={`value font-wt-600 text-48DA87  w-100`}>
                          {`${stats["active_CP"] || "0"}`}
                        </label>
                        <label className={'font-size-14 font-wt-600 text-color-value'}>{"Active"}</label>
                      </div>

                      <div className='w-50'>
                        <label className={`value font-wt-600 colorFF7B6D w-100`}>
                          {`${stats["inactive_CP"] || "0"}`}
                        </label>
                        <label className={'font-size-14 font-wt-600 text-color-value'}>{"Inactive"}</label>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className='row mt-3'>
                <div className='col-md-6'>
                  <div className='card p-3 dashboard-card border-0 borderRadius h-100'>
                    <div>
                      <div class="dropdown">
                        <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                          <div className='d-flex align-items-center '>
                            <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => setUserOnboardSelect('all')}>Users Onboarded</label>

                          </div>
                          <div className="d-flex gap-2">
                            <label className="paymentsdots mb-0 cursor" onClick={() => setUserOnboardSelect('value')}><div className='greendot' /> Exporter </label>
                            <label className="paymentsdots mb-0 cursor" onClick={() => setUserOnboardSelect('FinValue')} ><div className='yellowdot' /> Buyers </label>
                            <label className="paymentsdots mb-0 cursor" onClick={() => setUserOnboardSelect('CPValue')} ><div className='reddot' /> Partner </label>
                          </div>

                          <div className='w-20 align-items-center d-flex justify-content-end'>
                            <div className='pr-3'>
                              <img
                                onClick={() => { setGraphConfiguration({ ...graphConfiguration, onbordedDurationGraphMode: !graphConfiguration.onbordedDurationGraphMode }) }}
                                className='cursor'
                                src={`/assets/images/${graphConfiguration.onbordedDurationGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                            </div>
                            <div className=''>
                              <img
                                onClick={() => { }}
                                className='cursor' src='/assets/images/download_icon_with_bg.png' />
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <ResponsiveContainer width="100%" height={300}>
                      <LineChart
                        width={500}
                        height={400}
                        data={onboardGraphdata}
                        //data={stats.customerOnboardedData || []}
                        margin={{
                          top: 10,
                          right: 30,
                          left: 0,
                          bottom: 0,
                        }}
                      >
                        <XAxis dataKey="label" type="category" />
                        <YAxis type="number" tickFormatter={(value) => Intl.NumberFormat('en-US', { notation: 'compact' }).format(value)} />
                        <Tooltip formatter={(value, name) => [value, labels[name]]}
                        />
                        {/* <defs>
                  <linearGradient id="fillcolor2" x1="0" y1="0" x2="0" y2="1" >
                    <stop offset={"30%"} stopColor="#95E7FF" stopOpacity={1} />
                    <stop offset={"100%"} stopColor="#95E7FF" stopOpacity={0.3} />
                  </linearGradient>
                </defs> */}
                        <Line dataKey="value" stroke="#76EEA9" strokeWidth={"2px"} dot={{ stroke: '#2ECC71', strokeWidth: 5, fill: "#2ECC71" }} />
                        <Line dataKey="FinValue" stroke="#FFB801" strokeWidth={"2px"} dot={{ stroke: '#FFB801', strokeWidth: 5, fill: "#FFB801" }} />
                        <Line dataKey="CPValue" stroke="#F26191" strokeWidth={"2px"} dot={{ stroke: '#F26191', strokeWidth: 5, fill: "#F26191" }} />

                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='card h-100 w-100 dashboard-card shadow-sm '>
                    <div>
                      <div class="dropdown">
                        <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                          <div className='d-flex align-items-center '>
                            <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => setUserOnboardSelect('all')}>Onboarded User's Status</label>

                          </div>
                          <div className="d-flex gap-2">
                            <label className="paymentsdots mb-0 cursor" onClick={() => setUserOnboardSelect('value')}><div className='greendot' /> Active </label>
                            <label className="paymentsdots mb-0 cursor" onClick={() => setUserOnboardSelect('CPValue')} ><div className='darkreddot' /> Inactive </label>
                          </div>

                          <div className='w-20 align-items-center d-flex justify-content-end'>
                            <div className='pr-3'>
                              <img
                                onClick={() => { setGraphConfiguration({ ...graphConfiguration, onbordedDurationGraphMode: !graphConfiguration.onbordedDurationGraphMode }) }}
                                className='cursor'
                                src={`/assets/images/${graphConfiguration.onbordedDurationGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                            </div>
                            <div className=''>
                              <img
                                onClick={() => { }}
                                className='cursor' src='/assets/images/download_icon_with_bg.png' />
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div>
                      <CustomBarChart XFormatter={(value) => value} YFormatter={(value) => value} bardataConfig={bardataConfig} formatterFunction={(value, name) => [value, name]} data={userStatusGraph} xDataKey={"type"} />
                    </div>

                  </div>
                </div>


              </div>
              <div className='mt-5'>
                <div className='card p-3 w-100 py-0 dashboard-card border-0 borderRadius h-100  mx-0' >
                  <div className='w-100'>
                    <div class="dropdown">
                      <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                        <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => { }}>Geographical Data</label>


                        <div className='w-20 align-items-center d-flex justify-content-end'>
                          <div className='pr-3'>
                            <img
                              onClick={() => { setGraphConfiguration({ ...graphConfiguration, onbordedDurationGraphMode: !graphConfiguration.onbordedDurationGraphMode }) }}
                              className='cursor'
                              src={`/assets/images/${graphConfiguration.onbordedDurationGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                          </div>
                          <div className=''>
                            <img
                              onClick={() => { }}
                              className='cursor' src='/assets/images/download_icon_with_bg.png' />
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>
                  <div>
                    <CustomBarChart XFormatter={(value) => value} YFormatter={(value) => value} bardataConfig={geodataconfig} formatterFunction={(value, name) => [value, geolabel[name]]} data={geographdata} xDataKey={"company_city"} isLegend={true} legendsMapper={geolabel} barSize={20} />
                  </div>
                </div>
              </div>

              <div className='row mt-5'>
                <div className='card p-3 dashboard-card border-0 borderRadius h-100'>
                  <div>
                    <div class="dropdown">
                      <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                        <div className='d-flex align-items-center '>
                          <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => { }}>Active Applications (Application stage)</label>

                        </div>
                        <div className='w-20 align-items-center d-flex justify-content-end'>
                          <div className='pr-3'>
                            <img
                              onClick={() => { }}
                              className='cursor'
                              src={`/assets/images/${graphConfiguration.applicationStageGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                          </div>
                          <div className=''>
                            <img
                              onClick={() => { }}
                              className='cursor' src='/assets/images/download_icon_with_bg.png' />
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>
                  {!graphConfiguration.applicationStageGraphMode ? (
                    <>
                      <div>
                        <NewTable
                          disableAction={true}
                          data={graphData.activeApplicationStageTableData || []}
                          columns={[{ name: "Active Users(Application Stage)" }, { name: "Finance Limit" }, { name: "Quote" },
                          { name: "Termsheet/Contract" }, { name: "Finance" }, { name: "Agreement" }, { name: "Approved" }]}
                        />
                      </div>
                      <div className='mt-4'>
                        <NewTable
                          disableAction={true}
                          data={graphData.inactiveUserDaysTableData || []}
                          columns={[{ name: "Inactive Users(Days)" }, { name: "15 Days" }, { name: "30 Days" },
                          { name: "45 Days" }, { name: "60 Days" }, { name: "75 Days" }]}
                        />
                      </div>
                    </>
                  ) : (
                    <div className='h-90 d-flex flex-row pt-5 pb-4 mx-0' >
                      <div className='col-6'>
                        {graphData?.activeApplicationStageFunnelGraphData?.length ? (
                          <>
                            <div className='text-center h-90'>
                              <img src='/assets/images/Active users application stage.png' width={"70%"} height={"85%"} />
                            </div>
                            <label className='position-absolute font-size-16 font-wt-700 appstage-fl'>{graphData?.activeApplicationStageFunnelGraphData[0]?.value}</label>
                            <label className='position-absolute font-size-16 font-wt-700 appstage-qts'>{graphData?.activeApplicationStageFunnelGraphData[1]?.value}</label>
                            <label className='position-absolute font-size-16 font-wt-700 appstage-ts'>{graphData?.activeApplicationStageFunnelGraphData[2]?.value}</label>
                            <label className='position-absolute font-size-16 font-wt-700 appstage-fin'>{graphData?.activeApplicationStageFunnelGraphData[3]?.value}</label>
                            <label className='position-absolute font-size-16 font-wt-700 appstage-agree'>{graphData?.activeApplicationStageFunnelGraphData[4]?.value}</label>
                            <label className='position-absolute font-size-16 font-wt-700 appstage-approved'>{graphData?.activeApplicationStageFunnelGraphData[5]?.value}</label>

                          </>
                        ) : null}
                        <label className='text-color-value font-size-14 font-wt-600 w-100 text-center mt-3'>Active Users (Application Stage)</label>
                      </div>

                      <div className='col-6'>
                        {graphData?.inactiveUserDaysFunnelGraphData?.length ? (
                          <>
                            <div className='text-center h-90'>
                              <img src='/assets/images/inactive days.png' width={"70%"} height={"75%"} />
                            </div>
                            <label className='position-absolute font-size-16 font-wt-700 inact-15-days'>{graphData?.inactiveUserDaysFunnelGraphData[0]?.value}</label>
                            <label className='position-absolute font-size-16 font-wt-700 inact-30-days'>{graphData?.inactiveUserDaysFunnelGraphData[1]?.value}</label>
                            <label className='position-absolute font-size-16 font-wt-700 inact-45-days'>{graphData?.inactiveUserDaysFunnelGraphData[2]?.value}</label>
                            <label className='position-absolute font-size-16 font-wt-700 inact-60-days'>{graphData?.inactiveUserDaysFunnelGraphData[3]?.value}</label>
                            <label className='position-absolute font-size-16 font-wt-700 inact-75-days'>{graphData?.inactiveUserDaysFunnelGraphData[4]?.value}</label>

                          </>
                        ) : null}
                        <label className='text-color-value font-size-14 font-wt-600 w-100 text-center mt-3'>Inactive Users (Days)</label>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='mt-5'>
                <div className='card p-3 w-100 py-0 dashboard-card border-0 borderRadius h-100  mx-0' >
                  <div className='w-100'>
                    <div class="dropdown">
                      <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                        <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => { }}>{`Deals From ${selectedTypeDealsGraph}`}</label>

                        <div >
                          <ul className="nav pricingtabs nav-pills bg-white mx-auto rounded-pill p-0 shadow-sm" id="pills-tab" role="tablist">
                            <li className="nav-item p-0 " role="presentation">
                              <button onClick={() => {
                                setdealsTab('Values')
                              }} className="nav-link active w-100 roundedpillleft font-size-14" id="pills-All-tab" data-bs-toggle="pill" data-bs-target="#pills-All" type="button" role="tab" aria-controls="pills-All" aria-selected="true">Values ($)</button>
                            </li>
                            <li className="nav-item p-0 " role="presentation">
                              <button onClick={() => {
                                setdealsTab('Count')
                              }} className="nav-link w-100 roundedpillright font-size-14 " id="pills-Yearly-tab" data-bs-toggle="pill" data-bs-target="#pills-Yearly" type="button" role="tab" aria-controls="pills-Yearly" aria-selected="false">Count</button>
                            </li>
                          </ul>
                        </div>

                        <div className='w-20 align-items-center d-flex justify-content-end'>
                          <div className='pr-3'>
                            <img
                              onClick={() => { setGraphConfiguration({ ...graphConfiguration, onbordedDurationGraphMode: !graphConfiguration.onbordedDurationGraphMode }) }}
                              className='cursor'
                              src={`/assets/images/${graphConfiguration.onbordedDurationGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                          </div>
                          <div className=''>
                            <img
                              onClick={() => { }}
                              className='cursor' src='/assets/images/download_icon_with_bg.png' />
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>
                  <div>
                    <CustomBarChart XFormatter={(value) => value} YFormatter={(value) => dealsTab === 'Values' ? "$ " + Intl.NumberFormat('en-US', { notation: 'compact' }).format(value) : value} bardataConfig={dealsTab === 'Values' ? dealsConfig : dealsConfigCount} formatterFunction={(value, name) => [dealsTab === 'Values' ? "$ " + Intl.NumberFormat('en-US', { notation: 'compact' }).format(value) : value, dealsTab === 'Values' ? dealsmapping[name] : dealsmappingCount[name]]} data={dealsGraphdata} xDataKey={"company_name"} isLegend={true} legendsMapper={dealsTab === 'Values' ? dealsmapping : dealsmappingCount} barSize={20} />
                  </div>
                  <div className='d-flex gap-3 align-items-center align-self-center py-4'>
                    <div className={`${selectedTypeDealsGraph === 'Exporter' ? 'selectedButton' : 'nonselectedButton'} cursor`} onClick={() => setselectedTypeDealsGraph("Exporter")}>
                      Exporter
                    </div>
                    <div className={`${selectedTypeDealsGraph === 'Partner' ? 'selectedButton' : 'nonselectedButton'} cursor`} onClick={() => setselectedTypeDealsGraph("Partner")}>
                      Partner
                    </div>
                    <div className={`${selectedTypeDealsGraph === 'Financer' ? 'selectedButton' : 'nonselectedButton'} cursor`} onClick={() => setselectedTypeDealsGraph("Financer")}>
                      Financer
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className='mt-5'>
                <div className='card p-3 w-100 py-0 dashboard-card border-0 borderRadius h-100  mx-0' >
                  <div className='w-100'>
                    <div class="dropdown">
                      <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                        <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => { }}>Application Stage</label>


                        <div className='w-20 align-items-center d-flex justify-content-end'>
                          <div className='pr-3'>
                            <img
                              onClick={() => { setGraphConfiguration({ ...graphConfiguration, onbordedDurationGraphMode: !graphConfiguration.onbordedDurationGraphMode }) }}
                              className='cursor'
                              src={`/assets/images/${graphConfiguration.onbordedDurationGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                          </div>
                          <div className=''>
                            <img
                              onClick={() => { }}
                              className='cursor' src='/assets/images/download_icon_with_bg.png' />
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>
                  <div>
                    <CustomBarChart XFormatter={(value) => value} YFormatter={(value) => value} bardataConfig={geodataconfig} formatterFunction={(value, name) => [value, geolabel[name]]} data={geographdata} xDataKey={"company_city"} />
                  </div>
                  <div className='d-flex gap-3 align-items-center align-self-center py-4'>
                    <div className={`${selectedAppStageGraph === 'Exporter' ? 'selectedButton' : 'nonselectedButton'} cursor`} onClick={() => setselectedAppStageGraph("Exporter")}>
                      Exporter
                    </div>
                    <div className={`${selectedAppStageGraph === 'Partner' ? 'selectedButton' : 'nonselectedButton'} cursor`} onClick={() => setselectedAppStageGraph("Partner")}>
                      Partner
                    </div>
                    <div className={`${selectedAppStageGraph === 'Financer' ? 'selectedButton' : 'nonselectedButton'} cursor`} onClick={() => setselectedAppStageGraph("Financer")}>
                      Financer
                    </div>
                  </div>
                </div>
              </div> */}
              <div className='mt-5'>
                <div className='card p-3 w-100 py-0 dashboard-card border-0 borderRadius h-100  mx-0' >
                  <div className='w-100'>
                    <div class="dropdown">
                      <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                        <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => { }}>{`${selectedTypeExportWiseGraph} Wise Export data`}</label>

                        <div >
                          <ul className="nav pricingtabs nav-pills bg-white mx-auto rounded-pill p-0 shadow-sm" id="pills-tab" role="tablist">
                            <li className="nav-item p-0 " role="presentation">
                              <button onClick={() => {
                                setexportdataTab('Values')
                              }} className="nav-link active w-100 roundedpillleft font-size-14" id="pills-All-tab" data-bs-toggle="pill" data-bs-target="#pills-All" type="button" role="tab" aria-controls="pills-All" aria-selected="true">Value Of Export ($)</button>
                            </li>
                            <li className="nav-item p-0 " role="presentation">
                              <button onClick={() => {
                                setexportdataTab('Count')
                              }} className="nav-link w-100 roundedpillright font-size-14 " id="pills-Yearly-tab" data-bs-toggle="pill" data-bs-target="#pills-Yearly" type="button" role="tab" aria-controls="pills-Yearly" aria-selected="false">No of Export</button>
                            </li>
                          </ul>
                        </div>

                        <div className='w-20 align-items-center d-flex justify-content-end'>
                          <div className='pr-3'>
                            <img
                              onClick={() => { setGraphConfiguration({ ...graphConfiguration, onbordedDurationGraphMode: !graphConfiguration.onbordedDurationGraphMode }) }}
                              className='cursor'
                              src={`/assets/images/${graphConfiguration.onbordedDurationGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                          </div>
                          <div className=''>
                            <img
                              onClick={() => { }}
                              className='cursor' src='/assets/images/download_icon_with_bg.png' />
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>
                  <div>
                    <CustomBarChart XFormatter={(value) => value} YFormatter={(value) => exportdataTab === 'Values' ? "$ " + Intl.NumberFormat('en-US', { notation: 'compact' }).format(value) : value} bardataConfig={exportdataTab === 'Values' ? exportWiseConfigValues : exportWiseConfig} formatterFunction={(value, name) => [exportdataTab === 'Values' ? "$ " + Intl.NumberFormat('en-US', { notation: 'compact' }).format(value) : value, name]} data={exportWiseGraphdata} xDataKey={"label"} barSize={30} />
                  </div>
                  <div className='d-flex gap-3 align-items-center align-self-center py-4'>
                    <div className={`${selectedTypeExportWiseGraph === 'HS Code' ? 'selectedButton' : 'nonselectedButton'} cursor`} onClick={() => setselectedTypeExportWiseGraph("HS Code")}>
                      HS Code
                    </div>
                    <div className={`${selectedTypeExportWiseGraph === 'Port Of Loading' ? 'selectedButton' : 'nonselectedButton'} cursor`} onClick={() => setselectedTypeExportWiseGraph("Port Of Loading")}>
                      Port Of Loading
                    </div>
                    <div className={`${selectedTypeExportWiseGraph === 'Port Of Discharge' ? 'selectedButton' : 'nonselectedButton'} cursor`} onClick={() => setselectedTypeExportWiseGraph("Port Of Discharge")}>
                      Port Of Discharge
                    </div>
                    <div className={`${selectedTypeExportWiseGraph === 'Lanes Used' ? 'selectedButton' : 'nonselectedButton'} cursor`} onClick={() => setselectedTypeExportWiseGraph("Lanes Used")}>
                      Lanes Used
                    </div>
                    <div className={`${selectedTypeExportWiseGraph === 'Exporter City' ? 'selectedButton' : 'nonselectedButton'} cursor`} onClick={() => setselectedTypeExportWiseGraph("Exporter City")}>
                      Exporter City
                    </div>
                    <div className={`${selectedTypeExportWiseGraph === 'Export Country' ? 'selectedButton' : 'nonselectedButton'} cursor`} onClick={() => setselectedTypeExportWiseGraph("Export Country")}>
                      Export Country
                    </div>
                  </div>
                </div>
              </div>
              <div className='mt-5'>
                <div className='card p-3 w-100 py-0 dashboard-card border-0 borderRadius h-100  mx-0' >
                  <div className='w-100'>
                    <div class="dropdown">
                      <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                        <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => { }}>Average Buyers Added By Exporters</label>


                        <div className='w-20 align-items-center d-flex justify-content-end'>
                          <div className='pr-3'>
                            <img
                              onClick={() => { setGraphConfiguration({ ...graphConfiguration, onbordedDurationGraphMode: !graphConfiguration.onbordedDurationGraphMode }) }}
                              className='cursor'
                              src={`/assets/images/${graphConfiguration.onbordedDurationGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                          </div>
                          <div className=''>
                            <img
                              onClick={() => { }}
                              className='cursor' src='/assets/images/download_icon_with_bg.png' />
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>
                  <div>
                    <CustomBarChart XFormatter={(value) => value} YFormatter={(value) => value} bardataConfig={expwiseConfig} formatterFunction={(value, name) => [value, geolabel[name]]} data={BuyersExpWiseGraphdata} xDataKey={"label"} barSize={30} />
                  </div>
                </div>
              </div>
              <div className='mt-5'>
                <div className='card p-3 w-100 py-0 dashboard-card border-0 borderRadius h-100  mx-0' >
                  <div className='w-100'>
                    <div class="dropdown">
                      <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                        <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => { }}>Average Shipment data</label>

                        <div >
                          <ul className="nav pricingtabs nav-pills bg-white mx-auto rounded-pill p-0 shadow-sm" id="pills-tab" role="tablist">
                            <li className="nav-item p-0 " role="presentation">
                              <button onClick={() => {
                                setshipmentsTab('Values')
                              }} className="nav-link active w-100 roundedpillleft font-size-14" id="pills-All-tab" data-bs-toggle="pill" data-bs-target="#pills-All" type="button" role="tab" aria-controls="pills-All" aria-selected="true">Value Of Export ($)</button>
                            </li>
                            <li className="nav-item p-0 " role="presentation">
                              <button onClick={() => {
                                setshipmentsTab('Count')
                              }} className="nav-link w-100 roundedpillright font-size-14 " id="pills-Yearly-tab" data-bs-toggle="pill" data-bs-target="#pills-Yearly" type="button" role="tab" aria-controls="pills-Yearly" aria-selected="false">No of Export</button>
                            </li>
                          </ul>
                        </div>

                        <div className='w-20 align-items-center d-flex justify-content-end'>
                          <div className='pr-3'>
                            <img
                              onClick={() => { setGraphConfiguration({ ...graphConfiguration, onbordedDurationGraphMode: !graphConfiguration.onbordedDurationGraphMode }) }}
                              className='cursor'
                              src={`/assets/images/${graphConfiguration.onbordedDurationGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                          </div>
                          <div className=''>
                            <img
                              onClick={() => { }}
                              className='cursor' src='/assets/images/download_icon_with_bg.png' />
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>
                  <div>
                    <CustomLineChart XFormatter={(value) => getXAxisDateFormat([], value)} YFormatter={(value) => shipmentsTab === 'Values' ? "$ " + Intl.NumberFormat('en-US', { notation: 'compact' }).format(value) : value} bardataConfig={shipmentsTab === 'Values' ? shipmentsConfigValues : shipmentsConfig} formatterFunction={(value, name) => [shipmentsTab === 'Values' ? "$ " + Intl.NumberFormat('en-US', { notation: 'compact' }).format(value) : value, name]} data={shipmentsgraphdata} xDataKey={"label"} tab={shipmentsTab} type={"Sum"} />
                  </div>
                </div>
              </div>
              <div className='row mt-5'>
                <div className='card p-3 dashboard-card border-0 borderRadius h-90 d-flex flex-row pt-5 pb-4 ml-3 w-98'>
                  <div className='col-md-12'>
                    <div className='filter-div ml-4'>
                      <Filter
                        filteredSearch={filteredSearch}
                        setFilteredSearch={setFilteredSearch}
                        filterData={filterData} setFilterData={setfilterdata} showFilterBtn={true}
                        showResultPerPage={true} count={Count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setrefresh} isAdditionalButton={true}>
                        <div className="d-flex gap-4">
                          <button className={`new-btn  py-2 px-2 text-white cursor`} onClick={() => { window.open(`/registration`, "_blank") }}>Add New User</button>
                        </div>
                      </Filter>
                    </div>
                    <div>
                      <NewTable
                        filteredSearch={filteredSearch}
                        setFilteredSearch={setFilteredSearch}
                        filterData={filterData}
                        setFilterData={setfilterdata}
                        columns={[
                          {
                            name: "Date",
                          },
                          {
                            name: "User Type"
                          },
                          { name: "Organisation Name" },
                          {
                            name: "Organisation Type"
                          },
                          {
                            name: "Contact Person"
                          },
                          {
                            name: "Contact Number"
                          },
                          { name: "Admin" },
                          { name: "Status" },
                          { name: "" }
                        ]}
                        data={dbData}
                        options={[]}
                        disableAction={true}
                      />
                      <Pagination page={page} totalCount={Count} onPageChange={(p) => setpage(p)} refresh={refresh} setRefresh={setrefresh} perPage={filter.resultPerPage || 10} />

                    </div>
                  </div>

                </div>
              </div>
            </div>
      }
    </>
  )
}

export default UserReportsTab