import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { ToastContainer } from "react-toastify";
import call from "../../../service";
import { displayRazorpay } from "../../../utils/PaymentGateway";
import toastDisplay from "../../../utils/toastNotification";
import FinanceInvoiceModal from "../../InvoiceDiscounting/contract/components/financeinvoiceModal";
import { useRef } from "react";
import QRCode from "react-qr-code";
import { FileInput } from "../../../utils/FileInput";
import { convertImageToPdf } from "../../../utils/myFunctions";


const chooseplan = [
  {
    heading: "Starter",
    amount: "199",
    month: "6",
    planincludes: [
      "2 LC", "3 Invoice", "2 Buyer Details", "3 Recent Transactions Vault"
    ],
    ribbon: false
  },
  {
    heading: "Growth",
    amount: "499",
    month: "6",
    planincludes: [
      "10 LC", "10 Invoice", "5 Buyer Details", "Daily Market Updates", "15 Recent Transactions Vault", "50 Points"
    ],
    ribbon: true
  },
  {
    heading: "Pro",
    amount: "1499",
    month: "12",
    planincludes: [
      "30 LC", "15 Invoice", "Dedicated RM", "10 Buyer Details", "MIS Reports", "Daily Market Updates", "40 Recent Transactions Vault", "200 Points", "E-Invoice Generation"
    ],
    ribbon: false
  },
  {
    heading: "Pro Plus",
    amount: "1999",
    month: "12",
    planincludes: [
      "50 LC", "25 Invoice", "Dedicated RM", "20 Buyer Details", "MIS Reports", "Daily Market Updates", "75 Recent Transactions Vault", "500 Points", "E-Invoice Generation"
    ],
    ribbon: false
  }
];

const servicesOffered = [
  { name: 'Real time shipment tracking', key: 'realTimeShipmentTracking' },
  { name: 'Carrier quote & booking', key: 'carrierQuoteBooking' },
  { name: 'Letter of credit', key: 'letterOfCredit' },
  { name: 'Packing credit', key: 'packingCredit' },
  { name: 'CGTMSE', key: 'cgtmse' },
  { name: 'Domestic bill discounting', key: 'domesticBillDiscounting' },
  { name: 'Supply Chain Finance', key: 'supplyChainFinance' },
  { name: 'Trade Credit Insurance', key: 'tradeCreditInsurance' },
  { name: 'E - Invoice generation', key: 'eInvoiceGeneration' },
  { name: 'MIS reports', key: 'misReport' },
  { name: 'Daily Market Updates', key: 'dailyMarketUpdates' }
]

const PricingCard = ({ plandata, userTypeId, currentPlan, userId, showTutorial, userTokenDetails, priceKey }) => {

  const [firstindex, setFirstindex] = useState(0);
  const [lastindex, setLastindex] = useState(4);
  const [count, setCount] = useState(1);
  const [showLoader, setshowLoader] = useState(false)
  const [upiScreen, setUPIScreen] = useState({
    show: false,
    price: 0,
    plan_name: ''
  })
  let [errors, setErrors] = useState({})
  let [data, setData] = useState({})
  const canvasRef = useRef(null)
  let history = useHistory()

  const Next = () => {
    setFirstindex(firstindex + 3);
    setLastindex(lastindex + 3);
    setCount(count + 1);
  };

  const Back = () => {
    setFirstindex(firstindex - 3);
    setLastindex(lastindex - 3);
    setCount(count - 1);
  };

  const onActivateFreePlan = (planId) => {
    setshowLoader(true)
    let req = {
      userId: userId,
      plan_id: planId

    }
    call('POST', 'activateFreePlan', req).then(result => {
      console.log('success in activateFreePlan', result)
      setshowLoader(false)
      toastDisplay(result, 'success')
      setTimeout(() => {
        window.location = '/wallet'
      }, 500);
    }).catch(e => {
      console.log('error in activateFreePlan', e)
      setshowLoader(false)
      toastDisplay("Something went wrong", 'error')
    })
  }

  const onSuccesfullPayment = (data, extradata) => {
    let req = {
      userId: userId,
      ...extradata,
      order_data: data
    }
    call('POST', 'buyNewPlan', req).then(result => {
      console.log('success in buyNewPlan', result)
      toastDisplay(result, 'success')
      setTimeout(() => {
        window.location = '/wallet'
      }, 500);
    }).catch(e => {
      console.log('error in buyNewPlan', e)
    })
  }
  const onPaymentFailure = (data, extradata, callback) => {
    let req = {
      userId: userId,
      ...extradata,
      order_data: data
    }
    call('POST', 'failedPlanPayment', req).then(result => {
      console.log('success in failedPlanPayment', result)
      toastDisplay(result, 'error')
      callback()
      setTimeout(() => {
        window.location.reload()
      }, 500);
    }).catch(e => {
      console.log('error in buyNewPlan', e)
    })
  }
  const onPurchasePlan = async (plan_id, plan_type, plan_amount_usd, currency) => {
    try {
      let reqObj = {
        plan_id,
        plan_type,
        plan_amount_usd,
        userId,
        currency
      }
      const result = await call('POST', 'createPaymentlink', reqObj)
      window.location = result
    } catch (e) {
      console.log('error in createPaymentlink', e)
    }
  }
  const renderQRCode = () => {
    QRCode.toCanvas(canvasRef.current, `upi://pay?pa=9820529066@okbizaxis&am=${plandata.plan_amount_inr}&pn=${userTokenDetails.userName}&cu=INR&mode=02&sign=MEYCIQC8bLDdRbDhpsPAt9wR1a0pcEssDaVQ7lugo8mfJhDk6wIhANZkbXOWWR2lhJOH2Qs/OQRaRFD2oBuPCGtrMaVFR23t`, function (error) {
      if (error) console.error(error)
      console.log('success!');
    })
  }
  const handleFile = (event, isImage) => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }
      console.log('Fileddatataaaa', event.target.files)

      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result

        if (!file_type.includes("pdf") && !isImage) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }
  return (
    <div className="row align-items-center">
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {/* <div className="col-md-1 text-end">
        <img src={"/assets/images/supplier-images/ep_arrow-down-bold.png"} alt="" onClick={() => firstindex !== 0 && Back()}
          className={`CustomColor  cursor fs-5 ${firstindex === 0 ? "not-allowed CustomColor" : "CustomColor"
            }`} />
      </div> */}
      {upiScreen.show &&
        <FinanceInvoiceModal limitinvoice={upiScreen.show} closeSuccess={() => setUPIScreen({ show: false, price: 0, plan_name: '' })} isCentered={true}>
          <div>
            <div className="text-center mb-4">
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "50%", width: "50%", alignSelf: 'center' }}
                viewBox={`0 0 256 256`}
                value={`upi://pay?pa=9820529066@okbizaxis&am=${upiScreen.price}&pn=Trade Reboot Fintech Private Limited&cu=INR&mode=02&sign=MEYCIQC8bLDdRbDhpsPAt9wR1a0pcEssDaVQ7lugo8mfJhDk6wIhANZkbXOWWR2lhJOH2Qs/OQRaRFD2oBuPCGtrMaVFR23t`}
              />
            </div>

            <label className="font-size-14 font-wt-500">After succesful Transaction please upload the screenshot of the payment for succesful activation. Your account will be activated within couple of hours</label>
            <FileInput name={"paymentreceipt"} value={data["paymentreceipt"]} error={errors["paymentreceipt"]}
              onChange={handleFile} isEditable={true} userTokenDetails={userTokenDetails}
              onUploadCancel={() => setData({ ...data, ["paymentreceipt"]: null })}
            />
            <div className="text-center">
              <button className={`my-4 new-btn w-30 py-2 px-2 text-white cursor`} onClick={() => {
                setshowLoader(true)
                const formdata = new FormData()
                formdata.append('supplierName', userTokenDetails.userName)
                formdata.append('docs', data["paymentreceipt"])
                formdata.append('plan_name', upiScreen.plan_name)

                call('POST', 'onUPIScannerPayment', formdata).then(result => {
                  toastDisplay(result, "success")
                  setshowLoader(false)
                  window.location = 'wallet'
                }).catch(e => {
                  toastDisplay(e, "error")
                  setshowLoader(false)
                })

              }}>Submit</button>
            </div>
          </div>
        </FinanceInvoiceModal>
      }

      <div className="col-md-12">
        <div className="d-flex">
          {plandata.slice(firstindex, lastindex).map((key, index) => {
            const featureList = JSON.parse(key.plan_features)
            console.log('error inm plan usd', key[priceKey], key, priceKey);
            try {
              return (
                <div className="col-md-3 p-3">
                  <div className={`${currentPlan.id === key.id ? 'selectedPricingCard' : 'pricingcard'} p-4 h-100  d-flex flex-column justify-content-between`}>
                    <div>
                      {key.ribbon === 1 && <div className="popularribbon" data-ribbon={key.ribbon_text}></div>}
                      <p className="font-size-14 font-wt-600 mb-0">{key.plan_name}</p>
                      {/* <p className="font-size-12 text-secondary">Perfect to get started</p> */}
                      <p>{showTutorial && key.plan_name === "Starter" ? (<span
                        style={{ color: 'green' }}
                        className="font-size-30 ">FREE</span>) : null}
                        <span className="fs-2 text-color1">
                          {showTutorial && key.plan_name === "Starter" ? (
                            <strike className={`ml-4`}>{`${priceKey === 'plan_amount_usd' ? '$' : '₹'} ${key[priceKey]}`} </strike>
                          ) : (
                            <> {`${priceKey === 'plan_amount_usd' ? '$' : '₹'} ${key[priceKey]}`}</>
                          )}
                        </span> <span className="font11 text-secondary">
                          / {key.plan_validity_value} {key.plan_validity_unit}</span>
                      </p>
                      <p className="font-size-13">Plan includes :</p>
                      {/* {featureList.map((item) => {
                        return (
                          <p className="font-size-13 mb-2 text-secondary"> <img src={"/assets/images/supplier-images/price-tick.png"} alt="" /> <span className="">{item} </span></p>
                        );
                      })} */}
                      <p className="font-size-13 mb-2 text-secondary"> <img src={"/assets/images/supplier-images/price-tick.png"} alt="" /> <span className="">{`Invoice nos. - ${key.total_inv_nos}`} </span></p>
                      <p className="font-size-13 mb-2 text-secondary"> <img src={"/assets/images/supplier-images/price-tick.png"} alt="" /> <span className="">{`No of sub-user creation. - ${key.total_inv_nos}`} </span></p>
                      {key.dedicatedRM ? <p className="font-size-13 mb-2 text-secondary"> <img src={"/assets/images/supplier-images/price-tick.png"} alt="" /> <span className="">{`Dedicated RM`} </span></p> : null}
                      <p className="font-size-13 mb-2 text-secondary"> <img src={"/assets/images/supplier-images/price-tick.png"} alt="" /> <span className="">{`Coins - ${key.coins}`} </span></p>

                      {servicesOffered.map((l, m) => {
                        if (key?.services?.[l.key]) {
                          return (
                            <p className="font-size-13 mb-2 text-secondary"> <img src={"/assets/images/supplier-images/price-tick.png"} alt="" /> <span className="">{l.name} </span></p>
                          )
                        }
                      })}

                    </div>
                    <div className="text-center">
                      <button
                        type="button"
                        className={`mx-2 new-btn w-75 py-2 ${currentPlan.id === key.id ? 'currentPlanBu' : 'chooseplan'}  text-white font-size-16 mt-3`}
                        onClick={() => {
                          if (showTutorial && key.plan_name === "Starter") {
                            onActivateFreePlan(key.id)
                          }
                          else {
                            setUPIScreen({
                              show: true,
                              price: key.plan_amount_inr,
                              plan_name: key.plan_name + ' - ' + key.plan_type
                            })

                            //onPurchasePlan(key.id, key.plan_name + ' - ' + key.plan_type, key[priceKey], priceKey === 'plan_amount_usd' ? "$" : "₹")
                            //window.location = `/payment-gateway?token=${userTokenDetails?.token}&id=${key.id}`
                            //displayRazorpay(key.plan_amount_usd, userTypeId, toastDisplay, onSuccesfullPayment, { plan_id: key.id, modeOfPayment: 'RazorPay' }, onPaymentFailure)
                          }
                        }
                        }>
                        {`${currentPlan.id === key.id ? 'Current Plan' : 'Choose Plan'}`}
                      </button>
                    </div>
                  </div>
                </div>
              );
            } catch (e) {
              console.log('error in UI', e);
            }

          })}

        </div>
      </div>
      {/* <div className="col-md-1">
        <img src={"/assets/images/supplier-images/ep_arrow-right-bold.png"} alt="" onClick={() =>
          Math.ceil(chooseplan.length / 3) !== count && Next()
        }
          className={`CustomColor cursor fs-5 ${count === Math.ceil(chooseplan.length / 3)
            ? "not-allowed CustomColor"
            : "CustomColor"
            }`} />
      </div> */}
    </div>
  );
}
export default PricingCard;