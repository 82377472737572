import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import HeaderV2 from "../../partial/headerV2";
import SideBarV2 from "../../partial/sideBarV2";
import Filter from '../../InvoiceDiscounting/components/Filter';
import moment from 'moment/moment';
import call from '../../../service';
import toastDisplay from '../../../utils/toastNotification';
import { LCPurposeObject, getDocDetails, most_used_currencies } from '../../../utils/myFunctions';
import { convertImageToPdf } from '../../../utils/myFunctions';
import { NewTable } from '../../../utils/newTable';
import Pagination from '../../InvoiceDiscounting/contract/components/pagination';
import TooltipModal from '../../InvoiceDiscounting/components/Tooltipmodal';
import NewTablev2 from '../../../utils/newTablev2';
import ChatBoxPopUp2 from '../../chatRoom/components/ChatBoxPopUp2';
import { Action } from '../../myCounterPartComp/action';

const Contract = ({ userTokenDetails, navToggleState }) => {
  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')
  const [dbData, setDbData] = useState([])
  const [tableData, setTableData] = useState([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [showLoader, setshowLoader] = useState(false)

  const [tab, setTab] = useState(0)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})

  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10, search: serarchParam ? serarchParam : '' })
  const [tooltip, setTooltip] = useState(false)
  const [filterData, setFilterData] = useState({})

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [action, setAction] = useState({ show: false, index: null })
  const [applicationCounts, setApplicationCounts] = useState({})

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  // const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  // const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  // const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  useEffect(() => {
    loadApplicationCounts()
  }, [])

  async function loadApplicationCounts() {
    setshowLoader(true)
    let apiResp = await call("POST", 'getLCContractApplicationCounts', { userId })
    setApplicationCounts({ ...apiResp })
    setshowLoader(false)
  }

  useEffect(() => {
    //Get Buyer list start
    let objectAPI = {
      userId,
      onlyReadyForContract: true,
      currentPage: 1,
      ...filter
    }
    setshowLoader(true)
    call('POST', 'getLCQuoteApplications', objectAPI).then((result) => {
      console.log('running getLCQuoteApplications api-->', result);
      setshowLoader(false)
      setDbData(result.data);
      setTableData(result.data)
      setCount(result.totalCount);
    }).catch((e) => {
      // console.log('error in getLCQuoteApplications', e);
      setshowLoader(false)
    });
  }, [refresh])



  console.log("tableDatatableData", tableData);

  function formatDataForTable(data) {
    let tableData = []
    let row = []
    data.forEach((item, index) => {
      let selectedQuote = JSON.parse(item.selectedQuote)
      row[0] = moment(item.updatedAt).format('DD/MM/YYYY')
      row[1] = item.buyerName
      row[2] = selectedQuote["confirmationFees"] ? (selectedQuote["confirmationFees"] + " %") : "NA"
      row[3] = selectedQuote["discountingRate"] ? (selectedQuote["discountingRate"] + " %") : "NA"
      row[4] = selectedQuote["discountingPercentage"] ? (selectedQuote["discountingPercentage"] + " %") : "NA"
      row[5] = selectedQuote["setupFees"] ? (selectedQuote["setupFees"] + " " + selectedQuote["setupFeesCurrency"]) : "NA"
      row[6] = selectedQuote["lender_name"]
      // row[7] =
      //   <>
      //     {
      //       item.Timeleft === "Success" && <p
      //         class={`text-success mb-0 font-size-13`}>
      //         {item.days} days <img src={'/assets/images/hourglass-not-done.png'} alt='hourglass' />
      //       </p>
      //     }
      //     {
      //       item.Timeleft === "inprogress" && <p type="button"
      //         class={`text-warning border-0 font-size-14`}>
      //         {item.days} days <img src={'/assets/images/hourglass-not-done.png'} alt='hourglass' />
      //       </p>
      //     }
      //     {
      //       item.Timeleft === "failed" && <p type="button"
      //         class={`text-danger border-0 font-size-14`}>
      //         {item.days} days <img src={'/assets/images/hourglass-not-done.png'} alt='hourglass' />
      //       </p>
      //     }
      //     {
      //       item.Timeleft === "" && <p type="button"
      //         class={`text-dark border-0 font-size-14`}>
      //         -
      //       </p>
      //     }
      //   </>
      if (item.isReadyForContract == 2) {
        row[99] =
          <p className="font-size-12 text-color-value ml-3 "><img src={"assets/images/warning.png"} alt="info" className="" />
            <span className="mr-2"><b>Your application was rejected by financer</b></span>
            <label
              onClick={() => {
                setTooltip(true); setData({
                  ...data, showRemark: selectedQuote["assignRemark"],
                  showLenderName: selectedQuote["lender_name"]
                })
              }}
              className="text-color1 text-decoration-underline cursor">View Remark</label> </p>
      }
      row[7] = <div >
        {item.isReadyForContract == 2 ? (
          <a>
            <button type="button"
              className={` border-0 mb-2 text-white rejectedQuotebtn `}>
              {"Application Rejected"}
            </button>
          </a>
        ) : (
          <a>
            <button type="button"
              onClick={() => { window.location = `/LcSignContract?id=${item.id}` }}
              disabled={item.contractDocsSignedByExporter}
              class={` border-0 mb-2 ${item.contractDocsSignedByExporter ? "disablesigncontract text-dark" : " enablesigncontract text-white  "}`}>
              {item.contractDocsSignedByExporter ? "Contract Signed" : "Sign contract"}
            </button>
          </a>
        )}
      </div >

      if (item.contractDocsSignedByExporter) {
        row[8] = <img
          onClick={() => { window.location = `/LcSignContract?id=${item.id}&view=true` }}
          src={"assets/images/eye.png"} className="cursor" alt='' />
      }

      tableData.push(row)
      row = []
    })
    return tableData
  }


  async function handleValidation(tabIndex) {

    if (tabIndex != undefined) {
      if (tabIndex < tab) {
        return setTab(tabIndex)
      }
      else if (tabIndex == tab) {
        return null
      }
      else {
        return toastDisplay("Click on continue button to go to next form", "info")
      }
    }

    let validateFields = []
    let err = {}
    if (tab === 0) {
      validateFields = ["buyerName", "buyerCountry"]
    }

    if (tab === 2) {
      validateFields = ["buyerName", "buyerCountry", "buyerAddress", "buyerPostalCode",
        "buyerPrevSale", "buyerExpectedSale", "buyerIncoTerms", "buyerTermsPayment", "buyerProductDetails"]
    }

    if (tab === 3) {
      validateFields = ["invoiceDocument", "poDocument"]
    }

    // if (tab === 4) {
    //   if (!data.selectedFinanciers.length) {
    //     return toastDisplay("Select atleast 1 financier", "info")
    //   }
    // }

    validateFields.forEach((item) => {
      if (!data[item]) {
        err[item] = "Mandatory Field"
      }
    })

    if (!Object.keys(err).length) {
      if (tab != 4) {
        if (tab === 0) {
          // return getDunsList()
        }
        setTab(tab + 1)
      }
      else {
        // submitBuyerDetails()
      }
    }
    setErrors(err)
  }


  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (!file_type.includes("pdf")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  const handleSelectedFinanciers = (id) => {
    let temp = data.selectedFinanciers
    if (temp.includes(id)) {
      temp = temp.filter(e => e !== id)
    }
    else {
      temp.push(id)
    }
    setData({ ...data, selectedFinanciers: temp })
  }

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('lcApplicationId', selectedChat.lcApplicationId)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))
    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {selectedChat.isChatOpen &&
        <div className="chatboxDivFixed">
          <ChatBoxPopUp2
            chatList={chatList}
            user_avatar={selectedChat.logo}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            parties={selectedChat.parties}
            userTokenDetails={userTokenDetails}
            onChatSend={(file, parties) => sendChatMsg(file, parties)}
            message={message}
            setTextMsg={setTextMsg}
            reloadChatList={() => getChatDetails({
              chat_room_id: selectedChat.chatRoomId,
              loggedInUser: userId
            })}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false,
                logo: ''
              })
              setRefresh(refresh + 1)
            }}
          />
        </div>
      }
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="LcContract" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"LC- Contract"}
              userTokenDetails={userTokenDetails} />

            <div className='d-flex row justify-content-between px-2' >
              <div className='card p-4 border1Blue' style={{ width: '33.22%' }} >
                <label className='font-size-15 font-wt-600 color0C0C0C' >Application in process - <label className='text-color1 font-size-15 font-wt-600' >{count || 0}</label></label>
                <div className='d-flex row' >
                  <div className='w-auto' >
                    <p className='font-size-22 font-wt-600 text-color1 p-0 m-0' >{applicationCounts.signContract || 0}</p>
                    <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0'>Sign Contract</p>
                  </div>
                  <div className='w-auto' >
                    <p className='font-size-22 font-wt-600 text-color1 p-0 m-0' >{applicationCounts.signedContract || 0}</p>
                    <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0'>Signed Contract</p>
                  </div>
                </div>
              </div>
              <div className='card border1Blue pl-4 d-flex justify-content-center cursor' style={{ width: '16.44%' }} >
                <div>
                  <p className='font-size-22 font-wt-600 text2ECC71 p-0 m-0' >{applicationCounts.approved || 0}</p>
                  <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0 mt-3'>Approved</p>
                </div>
              </div>
              <div
                className='card border1Blue pl-4 d-flex justify-content-center cursor' style={{ width: '16.44%' }} >
                <div>
                  <p className='font-size-22 font-wt-600 text-color-E74C3C p-0 m-0' >{applicationCounts.rejected || 0}</p>
                  <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0 mt-3'>Rejected</p>
                </div>
              </div>
              <div style={{ width: '23.44%' }}>
              </div>
            </div>

            {/* <div>
              <a className="cursor"><img src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
            </div> */}
            <div className='filter-div position-relative'>
              <Filter singleMultApplication={false}
                filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} />
            </div>
            <div className='mt-3'>
              {/* <Filter filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} /> */}
              {/* <NewTable disableAction={true}
                columns={[{
                  name: "Date", filter: true
                }, { name: "Buyer Name", filter: true },
                {
                  name: "Confirmation Fee", filter: true
                },
                {
                  name: "Discounting Fee", filter: true
                },
                {
                  name: "Discounting Rate", filter: true
                },
                {
                  name: "Setup Fee", filter: true
                },
                {
                  name: "Financer Selected", filter: true
                },
                // {
                //   name: "Time Left", filter: true
                // },
                { name: "", filter: false }, { name: "", filter: false }]}
                data={tableData}
              /> */}
              <NewTablev2
                columns={[{ subColumns: "Date", filter: true, subColumnStyle: { width: '8%' } },
                { subColumns: "Buyer Name", filter: true, subColumnStyle: { width: '11%' } },
                { subColumns: "LC No.", filter: true, subColumnStyle: { width: '10%' } },
                { subColumns: "Limit Type", filter: true, subColumnStyle: { width: '11%' } },
                { subColumns: "Issuing Bank", filter: true, subColumnStyle: { width: '11%' } },
                { subColumns: "Issuing Country", filter: true, subColumnStyle: { width: '11%' } },
                { subColumns: "Financer Selected", filter: true, subColumnStyle: { width: '15%' } },
                { subColumns: "Action", subColumnStyle: { width: '17%' } }, { subColumns: "", subColumnStyle: { width: '5%' } }
                ]}
              >
                {tableData.map((item, index) => {
                  try {
                    item[`selectedQuote`] = item.selectedQuote ? JSON.parse(item.selectedQuote) : {}
                  } catch (error) {
                    item[`selectedQuote`] = {}
                  }
                  let selectedLenderName = item.selectedQuote.lender_name
                  let selectedLenderId = item.selectedQuote.lender_id
                  let chatRoomIds = item.chatRoomIds?.split(",") || []
                  let chatRoomUsers = item.chatRoomUsers?.split(",") || []
                  let chatRoomUnreadMsgCount = item.chatRoomUnreadMsgCount?.split(",") || []
                  let openChatRoomIndx = null

                  chatRoomUsers.forEach((u, i) => {
                    if (u?.split("::")[1] / 1 == selectedLenderId / 1) {
                      openChatRoomIndx = i
                    }
                  })

                  return (
                    <tr>
                      <td className='font-size-13 font-wt-500'>{moment(item.updatedAt).format('DD/MM/YYYY')}</td>
                      <td className='font-size-13 font-wt-500'>{item.buyerName || "-"}</td>
                      <td className='font-size-13 font-wt-500'>{item.lcNo || "-"}</td>
                      <td className='font-size-13 font-wt-500'>{LCPurposeObject[item.lcPurpose]}</td>
                      <td className='font-size-13 font-wt-500'>{item.lcIssuingBankName || "-"}</td>
                      <td className='font-size-13 font-wt-500'>{item.countryOfOriginName || "-"}</td>
                      <td className='font-size-13 font-wt-500'>{<div
                        className='flex-row position-relative'
                      >
                        <img className='cursor'
                          onClick={async () => {
                            let reqObj = {
                              senderId: userId,
                              receiverId: selectedLenderId,
                              textMessage: 'Hii',
                              chat_room_name: "CHAT" + new Date().getTime(),
                              includeAdmins: true,
                              lcApplicationId: item.id,
                              receiverParties: selectedLenderId,
                              dontSendInitialMsg: true
                            }
                            setshowLoader(true)
                            let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
                            setshowLoader(false)
                            getChatDetails({
                              chat_room_id: apiResp.id,
                              loggedInUser: userId
                            })
                            setSelectedChat({
                              chatRoomId: apiResp.id,
                              receiverName: `${selectedLenderName} - ${chatRoomIds[openChatRoomIndx] || apiResp.id}`,
                              lcApplicationId: item.id,
                              parties: chatRoomUsers[openChatRoomIndx] || apiResp.parties,
                              userId: userId,
                              isChatOpen: true,
                              receiverId: selectedLenderId
                            })
                          }}
                          src={chatRoomIds[openChatRoomIndx] ? `assets/images/comment_filled.png` : `assets/images/chat.png`} />
                        <label className='p-1 mx-1 font-size-14 font-wt-600' >{selectedLenderName}
                          <span className='text-color1 mx-2' ><u>{chatRoomUnreadMsgCount[openChatRoomIndx] / 1 ? (chatRoomUnreadMsgCount[openChatRoomIndx] < 10 ? `0${chatRoomUnreadMsgCount[openChatRoomIndx]}` : chatRoomUnreadMsgCount[openChatRoomIndx]) : null}</u></span></label>
                      </div>}</td>
                      <td>
                        <div className='row ' >
                          {item.isReadyForContract == 2 ? (
                            <a>
                              <button type="button"
                                className={` border-0 mb-2 text-white rejectedQuotebtn`}>
                                {"Application Rejected"}
                              </button>
                            </a>
                          ) : (
                            <a>
                              <button type="button"
                                onClick={() => { window.location = `/LcSignContract?id=${item.id}` }}
                                disabled={item.contractDocsSignedByExporter}
                                class={` border-0 mb-2 ${item.contractDocsSignedByExporter ? "disablesigncontract text-dark" : " enablesigncontract text-white  "}`}>
                                {item.contractDocsSignedByExporter ? "Contract Signed" : "Sign contract"}
                              </button>
                            </a>
                          )}
                        </div >
                      </td>
                      <td
                        style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
                        className='position-relative'>
                        <i className="fa fa-ellipsis-v cursor mt-2 ml-2"
                          onClick={() => setAction({ show: true, index })}
                          aria-hidden="true"></i>
                        {action.show && action.index === index ? (
                          <Action
                            id={index}
                            onDismiss={() => setAction({ show: false, index })}
                            options={[
                              {
                                name: "Chat With Financier", onClick: async () => {
                                  let reqObj = {
                                    senderId: userId,
                                    receiverId: selectedLenderId,
                                    textMessage: 'Hii',
                                    chat_room_name: "CHAT" + new Date().getTime(),
                                    includeAdmins: true,
                                    lcApplicationId: item.id,
                                    receiverParties: selectedLenderId,
                                    dontSendInitialMsg: true
                                  }
                                  setshowLoader(true)
                                  let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
                                  setshowLoader(false)
                                  getChatDetails({
                                    chat_room_id: apiResp.id,
                                    loggedInUser: userId
                                  })
                                  setSelectedChat({
                                    chatRoomId: apiResp.id,
                                    receiverName: `${selectedLenderName} - ${chatRoomIds[openChatRoomIndx] || apiResp.id}`,
                                    lcApplicationId: item.id,
                                    parties: chatRoomUsers[openChatRoomIndx] || apiResp.parties,
                                    userId: userId,
                                    isChatOpen: true,
                                    receiverId: selectedLenderId
                                  })
                                }
                              }
                            ]} />
                        ) : null}
                      </td>

                    </tr>
                  )
                })}
              </NewTablev2>

            </div>
            <Pagination page={page} totalCount={count} onPageChange={(p) => setPage(p)} perPage={filter.resultPerPage || 0} />
          </main>
        </div>
      </div>

      {tooltip && <TooltipModal tooltip={tooltip} setTooltip={setTooltip}
        data={{ showRemark: data.showRemark, showLenderName: data.showLenderName }} />}
    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(Contract)