// import React from "react";
// import { useState } from "react";
// import { Action } from "../components/myCounterPartComp/action";

// const NewTablev2 = ({ columns, data, options, disableAction, tableContainsNotification }) => {
//   const [action, setAction] = useState({ show: false, index: null })
//   return (
//     <>

//       <div classname="table-responsive">
//         <table classname="table table-bordered tablenewV2 " style={{ width: "100%", fontSize: "0.8vw", fontWeight: 400, backgroundColor: "white", padding: "3px", borderRadius: "12px", boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px", tableLayout: "fixed", }}>
//           <thead classname="tableheadv2">
//             <tr className="bgF8F8F8 gap-4" >
//               {columns.map((item, index) => {
//                 return (
//                   <th scope="col" className="headingv2"
//                     style={!item.subColumns ? { width: `${100 / columns.length}%` } : {
//                       width: `${100 / columns.length}%`
//                     }}
//                   >
//                     <span className="d-flex align-items-center font-wt-300">{item.name}
//                       {item.filter ? (
//                         <span><img className='mx-2' src={"assets/images/header/down_arrow.png"} /> </span>
//                       ) : null}</span>
//                     {/*       */}
//                   </th>)
//               })}
//             </tr>
//           </thead>
//           <tbody>
//             {data && data.length ? data.map((item, index) => {
//               return (
//                 <>
//                   <tr className="border-top tabletdv2 position-relative" >
//                     {/* {item.length ? item.map((item) => (
//                       <td style={{ width: `${100 / data.length}%` }}>{item}</td>
//                     )) : null} */}

//                     {
//                       item.length ? item.map((item, j) => {
//                         if (j != 99) {
//                           return (
//                             <td
//                               style={{ width: `${100 / data.length}%` }}
//                               className=''>{item}</td>
//                           )
//                         }
//                       }) : null
//                     }

//                     {disableAction ? null : (
//                       <td style={{ width: `${100 / data.length}%` }} >
//                         <i className="fa fa-ellipsis-v cursor"
//                           onClick={() => setAction({ show: true, index })}
//                           aria-hidden="true"></i>
//                         {action.show && action.index === index ? (
//                           <Action
//                             id={index}
//                             onDismiss={() => setAction(show: false, index{  })}
//                             // top={`${6 * (index + 1)}rem`} 
//                             options={options} />
//                         ) : null}
//                       </td>
//                     )}
//                   </tr>
//                   {
//                     item.length == 100 ? (
//                       <tr className="w-100 position-relative" style={{ height: "2.5rem" }}>
//                         <td className="p-0" style={{ position: 'absolute', top: '0rem' }}>
//                           {item[99]}
//                         </td>
//                       </tr>
//                     ) : null
//                   }
//                 </>
//               )
//             })
//               : <tr className="w-100 py-4 text-center" >
//                 <td className="font-size-16 font-wt-600" colspan={columns.length}>No Data Found</td>
//               </tr>}
//           </tbody>
//         </table>
//       </div>

//     </>
//   )
// }

// export default NewTablev2;


import React from "react";

const NewTablev2 = ({ columns, children, tableFixed, thwidth, showSideBorders, stickTableHeaderToTop }) => {
  return (
    <>
      <table style={{ borderCollapse: 'separate', borderSpacing: 0, border: '1px solid #D5D5D5', borderRadius: '10px' }} class={`table Tablenew ${tableFixed && " tableLayoutfixed "} `}>
        <thead className={stickTableHeaderToTop ? "stickTableHeaderToTop" : ""} >
          <tr>
            {columns.map((item, index) => {
              return (
                <th style={item.subColumnStyle} scope="col" className={`${item.thwidth ? item.thwidth : ''} ${showSideBorders ? ' tableNewTh ' : ' '}`}> <div className="mb-0 d-flex ">
                  <div className={item.subColumnClass}> {item.subColumns}</div>
                  {item.colAdditional}
                  {/* <div style={{ paddingLeft: "5px" }}><i class="arrow down"></i></div> */}

                  {/* <i className="fa-solid fa-chevron-down text-secondary"></i> */}

                  {/* <i className="fa-solid fa-chevron-down text-secondary"></i> */}
                  {item.filter && <span><i className="ms-1 fa-solid fa-chevron-down text-secondary"></i></span>}
                </div></th>
              );
            })}
          </tr>
        </thead>
        <tbody className={`tablenewtbody ${showSideBorders ? ' tablenewtbod2 ' : ''} `}>
          {children}
        </tbody>
      </table>
    </>
  );
}
export default NewTablev2;