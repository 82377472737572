import React, { useEffect, useState } from 'react';
import { FileInput } from "../../../utils/FileInput";
import { NewInput, NewSelect } from "../../../utils/newInput";
import { NewTable } from "../../../utils/newTable";
import Filter from "../../InvoiceDiscounting/components/Filter";
import call from '../../../service';
import { convertImageToPdf, ExportExcel, getDocDetails, most_used_currencies, printDiv } from '../../../utils/myFunctions';
import FinanceDetails from './FinanceDetails';
import validateAddFinance from '../../../utils/validation_files/AddFinanceValidations';
import FilterProfile from '../../InvoiceDiscounting/components/FilterProfile';
import ExtraDetailsForLimitApplication from '../../InvoiceDiscounting/extraDetailsForLimitApplication';

const FAQsData = [
  {
    Heading: "Last 5 years",
    FaqsHeading: "HeadingOne",
    FaqsCollapse: "CollapseOne",
    points: [
      "Audit report",
      "Gst document",
      "ITR Document",
      "Debtors statement",
      "Creditors statement",
      "Balance sheet *",
      "Cash flow statement * ",
      "Profit & loss * ",
      " Ratios *",
    ],
    count: "500"
  },
  {
    Heading: "Last 3 years",
    FaqsHeading: "HeadingTwo",
    FaqsCollapse: "CollapseTwo",
    points: [
      "Audit report",
      "Gst document",
      "ITR Document",
      "Debtors statement",
      "Creditors statement",
      "Balance sheet *",
      "Cash flow statement * ",
      "Profit & loss * ",
      " Ratios *",
    ],
    count: "400"
  },
  {
    Heading: "Last 2 years",
    FaqsHeading: "HeadingThree",
    FaqsCollapse: "CollapseThree",
    points: [
      "Audit report",
      "Gst document",
      "ITR Document",
      "Debtors statement",
      "Creditors statement",
      "Balance sheet *",
      "Cash flow statement * ",
      "Profit & loss * ",
      " Ratios *",
    ],
    count: "200"
  },

];



const FinanceTab = ({ userTokenDetails, navToggleState, setshowLoader, toastDisplay, cin_no }) => {

  const [tableData, setTableData] = useState([])
  const [dbData, setDbData] = useState([])
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 100 })
  const [count, setCount] = useState(0)
  const [yearlist, setyearList] = useState([])
  const [detailsdata, setdetailsData] = useState({ isVisible: false, data: null })

  const [getfinance, setGetfinance] = useState(false);
  const [addfinance, setAddfinance] = useState(false);
  const [table, setTable] = useState(false);

  const [accordion, setAccordion] = useState("HeadingOne");
  const [data, setData] = useState({ pageCount: 10 })
  const [errors, setErrors] = useState({})
  const [isSubmitted, setSubmitted] = useState(false)
  const [addMoreDoc, setAddMoreDoc] = useState([null])
  const [totalRecords, setTotalRecords] = useState(0)
  const [selectedDuration, setSelectedDuration] = useState('Last 5 years')

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  const tableFun = () => {
    setGetfinance(false);
    setAddfinance(false);
    setTable(true);
  }

  const backFun = () => {
    setGetfinance(false);
    setAddfinance(false);
    setTable(false);
    setSubmitted(false)
    setData([])
  }
  const handleChange = (event) => {
    if (event.persist) {
      event.persist()
    }
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }
  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }
      if (event.target.name.includes("otherDoc")) {
        setAddMoreDoc(addMoreDoc.concat(null))
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (!file_type.includes("pdf")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  const deleteFinanceRecord = (finDtId) => {
    setshowLoader(true)
    call('POST', 'deleteuserfinancedetails', { finDtId }).then(result => {
      toastDisplay('Finance data deleted Succesfully', 'success')
      setdetailsData({
        isVisible: false,
        data: null
      })
      setTable(true)
      getfinanceData()
      setshowLoader(false)

    }).catch(e => {
      setshowLoader(false)

    })
  }

  useEffect(() => {
    let tempArray = []
    for (let i = 2000; i <= new Date().getFullYear(); i++) {
      tempArray.push({
        name: i,
        sortname: i
      })
    }
    setyearList(tempArray)
    getfinanceData()
  }, [refresh])
  const getfinanceData = () => {
    setshowLoader(true)
    let objectAPI = {
      userTypeId: userTypeId,
      currentPage: 1,
      resultPerPage: data.pageCount,
      search: data.search_text
    }
    call('POST', 'getuserfinancegrid', objectAPI).then((result) => {
      console.log('running getuserfinancegrid api-->', result, result.countdata);
      setDbData(result.finData);
      setTableData(formatDataForTable(result.finData))
      setCount(result.countdata.totalcount);
      setTotalRecords(result.countdata.totalcount)
      setshowLoader(false)
      if (result.countdata.totalcount < 10) {
        setData({
          ...data,
          pageCount: result.countdata.totalcount
        })
      } else {
        setData({
          ...data,
          pageCount: 10
        })
      }

    }).catch((e) => {
      console.log('error in getBuyersDetail', e);
      setshowLoader(false)
    });
  }

  function formatDataForTable(data) {
    let tableData = []
    let row = []
    data.forEach((item, index) => {
      let hsnCodes = item.Financersselected ? item.Financersselected.split(",") : []
      row[0] = item.year
      row[1] = item.turnover
      row[2] = item.netprofit
      row[3] = item.networth
      row[4] = <p className='mb-0 text-color1 font-wt-600 cursor' onClick={async () => {
        setdetailsData({
          isVisible: true,
          data: {
            ...item,
            auditDoc: await getDocDetails(item.finance_doc_current_year),
            GSTDoc: await getDocDetails(item.gst_doc_6_month),
            ITRDoc: await getDocDetails(item.itr_doc_1_year),
            DebDoc: await getDocDetails(item.debtor_doc),
            CredDoc: await getDocDetails(item.creditors_doc)


          }
        })
        setTable(false)
      }}>View documents</p>
      tableData.push(row)
      row = []
    })
    return tableData
  }
  useEffect(() => {
    if (Object.values(errors).every(data => data === '') && isSubmitted) {
      addFinanceDetails()
    } else {
      setSubmitted(false)
    }
  }, [errors])
  function addFinanceDetails() {
    setshowLoader(true)
    console.log('dataaaaa', data)
    const formdata = new FormData()
    formdata.append('year', data.selectedYear)
    formdata.append('currency', data.currencyType)
    formdata.append('turnover', data.turnover)
    formdata.append('netProfit', data.netprofit)
    formdata.append('netWorth', data.networth)
    formdata.append('finance_doc_current_year', data.AuditDoc)
    formdata.append('gst_doc_6_month', data.GSTDoc)
    formdata.append('debtor_doc', data.debStatement)
    formdata.append('creditors_doc', data.credDocs)
    formdata.append('itr_doc_1_year', data.ITRDoc)
    formdata.append('userId', userId)
    formdata.append('userEmail', userEmail)
    call("POST", "insertuserfinancedetails", formdata).then(result => {
      toastDisplay('Finance details added succesfully', "success")
      getfinanceData()
      backFun()
      setData([])
      setshowLoader(false)
      setSubmitted(false)
      setData([])
    }).catch(e => {
      console.log('Error', e)
      toastDisplay('Failed to add Finance details', "error")
      setshowLoader(false)
      setSubmitted(false)
    })
  }
  const exportToexcel = (data, filename) => {
    let downloaddata = []
    data.forEach(data => {
      let downloadobj = {
        "Financial year": data.year,
        "Turnover": data.turnover,
        "Net Profit": data.netprofit,
        "Net Worth": data.networth,
      }
      downloaddata.push(downloadobj)
    })
    ExportExcel(downloaddata, filename)
  }
  const downloadFinanceReports = async () => {
    setshowLoader(true)
    const currentYear = new Date().getFullYear().toString()
    let startYear = ''
    if (selectedDuration === 'Last 5 years') {
      startYear = currentYear - 5
    } else if (selectedDuration === 'Last 3 years') {
      startYear = currentYear - 3
    } else if (selectedDuration === 'Last 2 years') {
      startYear = currentYear - 2
    }
    let reqBody = {
      cin: cin_no,
      userEmail: "siddhant.mail@tradereboot.com",
      //userEmail: userEmail,
      from: startYear,
      to: currentYear,
      consent: "y",
      type: selectedDuration,
      userId
    }
    try {
      const result = await call('POST', 'downloadFinanceReports', reqBody)
      setshowLoader(false)
      toastDisplay("Finance Report will be sent to your email address shortly.", "success")

    } catch (e) {
      console.log('error in downloadFinanceReports ', e)
      setshowLoader(false)
      toastDisplay(e, "error")
    }
  }
  return (
    <>
      {/* get finance details click starts here*/}
      {(!getfinance && !addfinance && !table && !detailsdata.isVisible) && <div className='row justify-content-center mb-5 mt-5 pb-5'>
        <div className='col-md-4 mt-4'>
          <div className='card creditcard p-3 align-items-center justify-content-center financecard cursor'>
            <a onClick={() => setGetfinance(true)} >
              <div className='mt-4 text-center'><img src={"/assets/images/getfinancedetails-icon.png"} alt='' height="" /></div>
              <p className='mt-3 text-color1 font-size-14 font-wt-600'>Get finance details</p></a>
          </div>
        </div>
        <div className='col-md-4 mt-4'>
          <div className='card creditcard p-3 align-items-center justify-content-center financecard cursor'>
            <a onClick={() => setAddfinance(true)} >
              <div className='mt-4 text-center'><img src={"/assets/images/add more.png"} alt='' height="" /></div>
              <p className='mt-3 text-color1 font-size-14 font-wt-600'>Add finance details</p>
            </a>
          </div>
        </div>
      </div>}

      {
        getfinance &&
        <div className=''>
          <a onClick={() => backFun()} className="cursor"><img src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
          <div className="row col-md-11 mx-auto justify-content-between">
            <div className="col-md-7">
              <div className="accordion accordion-flush card border-0 borderRadius" id={"Faqs"}>
                {FAQsData.map((key) => {
                  return (
                    < div className="accordion-item financeacc mb-3 " key={key.Heading}>
                      <h2 className="accordion-header" id={key.FaqsHeading}>
                        <button onClick={() => setAccordion(key.FaqsHeading)} className={`accordion-button border-0 font-size-14 font-wt-600 ${accordion !== key.FaqsHeading && "collapsed"}`} type="button" data-bs-toggle="collapse" data-bs-target={`#${key.FaqsCollapse}`} aria-expanded="true" aria-controls={key.FaqsCollapse} >
                          {key.Heading} <img src={"/assets/images/getfinancedetails-icon.png"} alt="" height={20} className="ms-4 me-1" /> <span className="text-color1">{key.count}</span>
                        </button>
                      </h2>
                      <div id={key.FaqsCollapse} className={`accordion-collapse collapse ${accordion === key.FaqsHeading && "show"}`} aria-labelledby={key.FaqsHeading} data-bs-parent="#Faqs">
                        <div className="accordion-body pt-0">
                          <ul>
                            {key.points.map((item) => {
                              return (
                                <li className="font-size-14">{item}</li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-md-4">
              <div className="card p-4 pricedetailscard">
                <p className="font-size-14 text-secondary">Price details</p>
                <div class="dropdown">
                  <p id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" className="border-bottom pb-2 d-flex justify-content-between font-size-14"><span>{selectedDuration}</span><span><img src={"/assets/images/arrowdown.png"} alt="" /></span></p>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" style={{ width: '100%', backgroundColor: '#F0FAFD', borderWidth: 1, borderColor: '#B6E3F0' }}>
                    <li class="dropdown-item border-bottom pb-2" onClick={() => setSelectedDuration('Last 5 years')}>Last 5 years</li>
                    <li class="dropdown-item border-bottom pb-2" onClick={() => setSelectedDuration('Last 3 years')}>Last 3 years</li>
                    <li class="dropdown-item" onClick={() => setSelectedDuration('Last 2 years')}>Last 2 years</li>
                  </ul>
                </div>
                <div className="borderdashed">
                  <p className="font-size-14 text-secondary pb-0 d-flex justify-content-between mb-0"><span>Rate</span><span className="font-wt-600 text-dark"><img src={"/assets/images/getfinancedetails-icon.png"} alt="" height={20} className="ms-1" /> 500</span></p>
                  <p className="font-size-14 text-secondary pb-2 d-flex justify-content-between"><span>Coupon code</span><span className="font-wt-600 text-dark"> - <img src={"/assets/images/getfinancedetails-icon.png"} alt="" height={20} className="ms-1" /> 10</span></p>
                </div>
                <p className="font-size-14 mb-0 d-flex justify-content-between mt-3"><span>Total</span><span className="font-wt-600 text-color1"><img src={"/assets/images/getfinancedetails-icon.png"} alt="" height={20} className="ms-1" /> 490</span></p>
                <p className="font-size-12 color3DB16F">Coupon code applied !</p>
                <button onClick={() => {
                  //downloadFinanceReports()
                  tableFun()
                }} className={` new-btn w-80 py-2 px-2 text-white cursor mt-2`}>Get Finance details</button>
              </div>
            </div>
          </div>
        </div>
      }
      {/* get finance details click ends here*/}


      {/* add finance details starts here */}
      {(addfinance && !getfinance) &&
        <div>
          <a onClick={() => setAddfinance(false)} className="cursor"><img src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
          <ExtraDetailsForLimitApplication userTokenDetails={userTokenDetails} hideHeader={true} hideSideBar={true}
            hideSubmitDiv={true} hideBackArrow={true} expandAll={true} cardBorder0={true} cardPadding0={true}
            showContinueButton={true} continueBtnLabel={"Submit"} afterDetailsSavedOn={() => { setAddfinance(false) }} bankDoc1Hide
            bankDoc2Hide bankDoc3Hide finDoc4Hide orgDoc1Hide orgDoc2Hide orgDoc3Hide orgDocLabel={"Documents"} mainTagMarginPadding0
          />
        </div>

        // <div className=''>
        //   <a onClick={() => setAddfinance(false)} className="cursor"><img src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
        //   <p className='text-decoration-underline text-color1 font-size-14 mt-3'>Add new finance details</p>
        //   <div className="row">
        //     <div className="col-md-2">
        //       <NewSelect isAstrix={false} label={"Select year"}
        //         selectData={yearlist} name={"selectedYear"}
        //         value={data.selectedYear} optionLabel={"name"} optionValue={'sortname'}
        //         onChange={handleChange} error={errors.selectedYear} />
        //     </div>
        //     <div className="col-md-2">
        //       <NewSelect isAstrix={false} label={"Select currency"}
        //         selectData={most_used_currencies} name={"currencyType"}
        //         value={data.currencyType} optionLabel={"name"} optionValue={'code'}
        //         onChange={handleChange} error={errors.currencyType} />
        //     </div>
        //     <div className="col-md-2">
        //       <NewInput isAstrix={false} type={"number"} label={"Turnover"}
        //         name={"turnover"} value={data.turnover} error={errors.turnover}
        //         onChange={handleChange} />
        //     </div>
        //     <div className="col-md-2">
        //       <NewInput isAstrix={false} type={"number"} label={"Net profit"}
        //         name={"netprofit"} value={data.netprofit} error={errors.netprofit}
        //         onChange={handleChange} />
        //     </div>
        //     <div className="col-md-2">
        //       <NewInput isAstrix={false} type={"number"} label={"Net Worth"}
        //         name={"networth"} value={data.networth} error={errors.networth}
        //         onChange={handleChange} />
        //     </div>
        //   </div>
        //   <p className='text-decoration-underline font-size-14 mt-3'>Attach documents</p>
        //   <p className='font-size-14 mt-3'>Audit report</p>
        //   <div className="row">
        //     <div className="col-md-4">
        //       <div className="col-md-10 px-0 mt-1">
        //         <NewInput isAstrix={false} type={"number"} label={"Audit Year"}
        //           name={"auditYear"} value={data.selectedYear} error={errors.selectedYear} isDisabled={true}
        //         />
        //       </div>
        //     </div>
        //     <div className="col-md-6">
        //       <div className="row form-group">
        //         <div className="col-md-11">
        //           <FileInput name={"AuditDoc"} value={data.AuditDoc} error={errors.AuditDoc}
        //             onChange={handleFile} isEditable={true}
        //             onUploadCancel={() => setData({ ...data, "AuditDoc": null })} />
        //           {errors.AuditDoc ? <div class="text-danger mt-2 font-size-12">
        //             <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
        //             <b>{errors.AuditDoc}</b></div> : ''}
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        //   <p className='font-size-14 mt-3'>GST document</p>
        //   <div className="row">
        //     <div className="col-md-4">
        //       <div className="col-md-10 px-0 mt-1">
        //         <NewInput isAstrix={false} type={"number"} label={"GST Year"}
        //           name={"GSTyear"} value={data.selectedYear} error={errors.selectedYear} isDisabled={true}
        //         />
        //       </div>
        //     </div>
        //     <div className="col-md-6">
        //       <div className="row form-group">
        //         <div className="col-md-11">
        //           <FileInput name={"GSTDoc"} value={data.GSTDoc} error={errors.GSTDoc}
        //             onChange={handleFile} isEditable={true}
        //             onUploadCancel={() => setData({ ...data, "GSTDoc": null })} />
        //           {errors.GSTDoc ? <div class="text-danger mt-2 font-size-12">
        //             <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
        //             <b>{errors.GSTDoc}</b></div> : ''}
        //         </div>
        //       </div>
        //     </div>
        //   </div>

        //   <p className='font-size-14 mt-3'>ITR document</p>
        //   <div className="row">
        //     <div className="col-md-4">
        //       <div className="col-md-10 px-0 mt-1">
        //         <NewInput isAstrix={false} type={"number"} label={"ITR Year"}
        //           name={"ITR"} value={data.selectedYear} error={errors.selectedYear} isDisabled={true}
        //         />
        //       </div>
        //     </div>
        //     <div className="col-md-6">
        //       <div className="row form-group">
        //         <div className="col-md-11">
        //           <FileInput name={"ITRDoc"} value={data.ITRDoc} error={errors.ITRDoc}
        //             onChange={handleFile} isEditable={true}
        //             onUploadCancel={() => setData({ ...data, "ITRDoc": null })} />
        //           {errors.ITRDoc ? <div class="text-danger mt-2 font-size-12">
        //             <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
        //             <b>{errors.ITRDoc}</b></div> : ''}
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        //   <p className='font-size-14 mt-3'>Debtors statement</p>
        //   <div className="row">
        //     <div className="col-md-6">
        //       <div className="row form-group">
        //         <div className="col-md-11">
        //           <FileInput name={"debStatement"} value={data.debStatement} error={errors.debStatement}
        //             onChange={handleFile} isEditable={true}
        //             onUploadCancel={() => setData({ ...data, "debStatement": null })} />
        //           {errors.debStatement ? <div class="text-danger mt-2 font-size-12">
        //             <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
        //             <b>{errors.debStatement}</b></div> : ''}
        //         </div>
        //       </div>
        //     </div>
        //   </div>

        //   <p className='font-size-14 mt-3'>Creditors statement</p>
        //   <div className="row">
        //     <div className="col-md-6">
        //       <div className="row form-group">
        //         <div className="col-md-11">
        //           <FileInput name={"credDocs"} value={data.credDocs} error={errors.credDocs}
        //             onChange={handleFile} isEditable={true}
        //             onUploadCancel={() => setData({ ...data, "credDocs": null })} />
        //           {errors.credDocs ? <div class="text-danger mt-2 font-size-12">
        //             <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
        //             <b>{errors.credDocs}</b></div> : ''}
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        //   <button onClick={() => {
        //     setErrors(validateAddFinance(data))
        //     setSubmitted(true)
        //   }} className={`my-4 new-btn w-17 py-2 px-2 text-white cursor`}>Add new finance details</button>
        // </div>
      }

      {detailsdata.isVisible && !getfinance && !addfinance &&
        <FinanceDetails setdetailsData={setdetailsData} goBackAction={() => setTable(true)} deleteDetails={() => deleteFinanceRecord(detailsdata.data.id)} detailsdata={detailsdata} handleChange={handleChange} handleFile={handleFile} isEditable={false} yearlist={yearlist} />
      }

      {/* add finance details ends here */}


      {/* add new finance table starts here */}
      {(!getfinance && !addfinance && table) && <div>
        <FilterProfile pageCount={data.pageCount} handleChange={handleChange} isDisabled={false} maxCount={totalRecords} applyAction={getfinanceData} searchValue={data.search_text} ExcelExportFunc={() => { exportToexcel(dbData, "ExcelSheet_Finances") }} pdfExportFunc={() => { printDiv("financeTable", "PDF_Finances", []) }} />
        <div id='financeTable'>
          <NewTable
            disableAction={true}
            columns={[{
              name: "Financial year", filter: true
            }, { name: "Turnover", filter: true },
            { name: "Net profit", filter: true },
            { name: "Net worth", filter: true },
            { name: "", filter: false },]}
            data={tableData} />
        </div>
        <div className="d-flex gap-5">
          <button onClick={() => setGetfinance(true)} className={`my-4 new-btn w-17 py-2 px-2 text-white cursor`}><img src={"/assets/images/getfinancedetails-icon.png"} alt="" height={20} className="ms-1" /> Get Finance details</button>
          <button onClick={() => setAddfinance(true)} className={`my-4 new-btn w-17 py-2 px-2 bg-white text-color1 cursor viewdocbtn`}>Add finance details</button>
        </div>
      </div>}

      {/* add new finance table ends here */}

    </>
  );
}
export default FinanceTab;