import React, { useState } from 'react'
import { useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import call from '../../service'
import { FileInput } from '../../utils/FileInput'
import { convertImageToPdf, copyToClipboard, getDocDetails, isEmpty, most_used_currencies } from '../../utils/myFunctions'
import { NewTable } from '../../utils/newTable'
import toastDisplay from '../../utils/toastNotification'
import { BusinessDocs } from '../viewProfile/components/companyTab'
import SubUserModal from '../viewProfile/components/SubUserModal'
import { KYCUploads } from '../viewProfile/viewProfile'
import moment from 'moment'
import { InputWithSelect, NewInput, NewSelect } from '../../utils/newInput'
import { companyTypes, industryData } from '../registration/newRegistration'
import { useRef } from 'react'

const reviewForm = [
  { "name": "Contact Person Name", val: "contactPerson", unit: 'name_title' },
  { "name": "Contact Number", val: "contact_number", unit: "phone_code" },
  { "name": "Email ID", val: "email_id" },
  { "name": "Organization Type", val: "organization_type" },
  { "name": "Industry Type", val: "industry_type" },
  { "name": "Address", val: "user_address" },
  { "name": "City,State", val: ["companyCity", "companyState"], isMultipleKeys: true },
  { "name": "Postal code", val: "companyPostal" },
]

const reviewForm2 = [
  { "name": "Country of incorporation", val: "country_of_incorporation" },
  { "name": "Country of operation", val: "country_of_operation" },
  { "name": "Years of incorporation", val: "years_of_incorporation" },
  { "name": "Last years net profit", val: "prevNetProfit", unit: "currency" },
  { "name": "Existing turnover", val: "ExisExportTurnover", unit: "currency" },
  { "name": "Expected turnover", val: "ExpecExportTurnover", unit: "currency" },
  { "name": "Date of Incorportation", val: "dateOfIncorporation" },
  { "name": "Exporter Type", val: "categoryOfExporters" },
  { "name": "Export Turn Over (USD)", val: "exportTurnOver" }
]

const reviewForm3 = [
  { "name": "GST number", val: "gst_vat_no" },
  { "name": "IEC", val: "iec_no" },
  { "name": "CIN No.", val: "cin_no" },
  { "name": "PAN No.", val: "pan_no" },
  { "name": "Duns No.", val: "dunsNo" },
]

const docsFormArray = [
  { "key": "MOM_Document", "name": "MOM Document", "dbId": ":9:44" },
  { "key": "AOA_Document", "name": "AOA Document", "dbId": ":9:45" },
  { "key": "Shareholding_Document", "name": "Shareholding Document", "dbId": ":9:46" },
  { "key": "Partnership_Deed_Document", "name": "Partnership Deed Document", "dbId": ":9:47" },
  { "key": "Company_Profile_Document", "name": "Company Profile Document", "dbId": ":9:48" },
]

const UserDetailsTab = ({ userTokenDetails, setEditPopup, editPopup }) => {

  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [subUserList, setsubUserList] = useState([])
  const [showLoader, setshowLoader] = useState(false)
  const [referraldata, setreferraldata] = useState([])
  const [subUserModal, setSubUserModal] = useState(false)
  const [countrys, setCountrys] = useState([])
  const [KYCDocuments, setKYCDocuments] = useState([])
  const [viewdetails, setviewdetails] = useState({
    isVisible: false,
    data: {}
  })
  const [gstBranchList, setgstBranchList] = useState([])
  const modalRef = useRef(null)

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email_id ? userTokenDetails.email_id : null
  const userId = userTokenDetails.id ? userTokenDetails.id : null
  const userName = userTokenDetails.company_name ? userTokenDetails.company_name : null
  console.log('userTokenDetails', userTokenDetails);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        // Clicked outside the modal, so close it
        setEditPopup(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);
  const handleFile = (event, isImage) => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }
      console.log('Fileddatataaaa', event.target.files)

      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result

        if (!file_type.includes("pdf") && !isImage) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  const getUserProfileData = () => {
    let reqObj = {
      "email": userTokenDetails?.subUserProfileDetails?.parent_email_id || userEmail,
      "kyc": true
    }
    call('POST', 'getuserprofiledata', reqObj).then(async (result) => {
      console.log('running getuserprofiledata api-->', data);
      let userProfileData = result.userProfileData
      let kycDocs = {}
      for (let i = 0; i <= result.userKYCData.length - 1; i++) {
        let KYCdata = result.userKYCData[i]
        //let KYCdata = resultdata[i]
        const res = await getDocDetails(KYCdata.tbl_doc_id)

        if (res.filebase64) {
          kycDocs[KYCdata.doc_name.split(' ').join('_')] = {
            ...res,
            name: KYCdata.file_name
          }
        }
      }
      console.log('KYCDataaaaaaaaa2', kycDocs)

      // result.userKYCData.forEach(async KYCdata => {


      // })
      setKYCDocuments(result.userKYCData)
      getUserDetailsExtra({
        user_name: result.userProfileData.user_name,
        contact_number: result.userProfileData.contact_number,
        phone_code: result.userProfileData.phone_code,
        name_title: result.userProfileData.name_title,
        user_address: result.userProfileData.user_address,
        email_id: result.userProfileData.email_id,
        user_avatar: result.userProfileData.user_avatar ? result.userProfileData.user_avatar : null,
        company_name: result.userProfileData.company_name,
        organization_type: result.userProfileData.organization_type,
        industry_type: result.userProfileData.industry_type,
        pan_no: result.userProfileData.pan_no,
        gst_vat_no: result.userProfileData.gst_vat_no,
        iec_no: result.userProfileData.iec_no,
        cin_no: result.userProfileData.cin_no,
        aadhar_no: result.userProfileData.aadhar_no,
        company_pan_verification: result.userProfileData.company_pan_verification,
        company_cin_verification: result.userProfileData.company_cin_verification,
        company_gst_verification: result.userProfileData.company_gst_verification,
        company_iec_verification: result.userProfileData.company_iec_verification,
        kyc_done: (result.userProfileData.kyc_done / 1),
        country_code: result.userProfileData.country_code,
        contactPerson: result.userProfileData.contact_person,
        designation: result.userProfileData.designation,
        companyPostal: result.userProfileData.company_postal_code,
        companyCity: result.userProfileData.company_city,
        companyAdd1: result.userProfileData.company_address1,
        companyAdd2: result.userProfileData.company_address2,
        companyState: result.userProfileData.company_state,
        CompanyCountry: result.userProfileData.company_country,
        ...kycDocs,
        referralCode: result.userProfileData.refercode

      })
    }).catch((e) => {
      console.log('error in getuserprofiledata', e);
    });
  }
  const getUserDetailsExtra = async (extraDetails) => {
    let reqObj = {
      "userId": userId,
    }
    try {
      const result = await call('POST', 'getuserdetailsextra', reqObj)
      console.log('running getuserdetailsextra api-->', result);
      let businessDocuments = {}
      for (let i = 0; i <= result.docDetails.length - 1; i++) {
        let BusinessDoc = result.docDetails[i]
        const res = await getDocDetails(BusinessDoc.id)
        console.log('BusinessDocs', res)
        if (res.filebase64) {
          businessDocuments[BusinessDoc.doc_name] = {
            ...res,
            name: BusinessDoc.file_name
          }
        }
      }
      // Get user details from iec data start
      let apiResp = await call('POST', 'getUserDetailsFromIEC', { iec: extraDetails.iec_no })
      // Get user details from iec data end
      setData({
        ...extraDetails,
        company_email: result.userDetails.company_email,
        website: result.userDetails.website,
        dunsNo: result.userDetails.dunsNo,
        country_of_incorporation: result.userDetails.country_of_incorporation,
        country_of_operation: result.userDetails.country_of_operation,
        minExisting: result.userDetails.minExisting,
        minExpected: result.userDetails.minExpected,
        ...businessDocuments,
        ExisExportTurnover: result.userDetails.minExisting,
        ExpecExportTurnover: result.userDetails.minExpected,
        ExisDomesTurnover: result.userDetails.ExisDomesTurnover,
        ExpecDomesTurnover: result.userDetails.ExpecDomesTurnover,
        currency: result.userDetails.currency,
        years_of_incorporation: result.userDetails.years_of_incorporation,
        prevNetProfit: result.userDetails.prevNetProfit,
        categoryOfExporters: apiResp.categoryOfExporters,
        dateOfIncorporation: apiResp.dateOfIncorporation ? moment(apiResp.dateOfIncorporation).format("YYYY-MM-DD") : null,
        exportTurnOver: apiResp.fobUsd || "NA"
      })
    } catch (e) {
      console.log('error in getuserdetailsextra', e);
    }

  }
  const getRefferalsList = () => {
    setshowLoader(true)
    call('POST', 'getRefferalsList', { userId }).then(result => {
      setreferraldata(formatDataForRefferalTable(result.message))
      setshowLoader(false)
    }).catch(e => {
      setshowLoader(false)
    })
  }
  useEffect(() => {
    getUserProfileData()
    getSubUsers()
    getRefferalsList()
    getCountrydata()
    getBranchesData()
  }, [])
  const getSubUsers = () => {
    call('POST', 'getSubUsers', { parent_id: userId }).then(result => {
      setsubUserList(formatDataForTable(result))
    }).catch(e => {
      console.log('eror in getSubUsers', e)
    })
  }

  function formatDataForRefferalTable(data) {
    let obj = {
      "19": "Exporter",
      "8": "Bank",
      "20": "Channel Partner"
    }
    let tableData = []
    let row = []
    data.forEach((item, index) => {

      row[0] = `${item.name_title ? item.name_title : ""} ${item.contact_person ? item.contact_person : ""}`
      row[1] = `+ ${item.phone_code ? item.phone_code : ""} ${item.contact_number ? item.contact_number : ""}`
      row[2] = item.email_id
      row[3] = obj[item.type_id]
      row[4] = <span className="font-size-16 font-wt-500 text-center mb-0"><img src={'/assets/images/Lc/Dollar.png'} alt='' />{"100"}</span>
      tableData.push(row)
      row = []
    })
    console.log('Tabledataaaaa', tableData)
    return tableData
  }
  const getBranchesData = () => {
    setshowLoader(true)
    call('POST', 'getBranchesData', { userId }).then(result => {
      setgstBranchList(formatDataForGSTTable(result))
      setshowLoader(false)
    }).catch(e => {

    })
  }
  function formatDataForGSTTable(data) {
    let tableData = []
    let row = []
    data.forEach((item, index) => {
      row[0] = item.branchCode
      row[1] = item.gstin
      row[2] = item.address
      tableData.push(row)
      row = []
    })
    return tableData
  }

  function formatDataForTable(data) {
    let tableData = []
    let row = []
    data.forEach((item, index) => {
      const modules = item.UserPermissions ? JSON.parse(item.UserPermissions) : []
      const AccessArr = Object.keys(modules).map(data => data)
      row[0] = item.name_title + " " + item.contact_person
      row[1] = "+ " + item.phone_code + " " + item.contact_number
      row[2] = item.email_id
      row[3] = AccessArr.slice(0, 2).join(',') + (AccessArr.length > 2 ? "+" + (AccessArr.length - 2) + " more" : '')
      row[4] = <label className="text-color1 font-wt-600 m-0 cursor" onClick={() => {
        setSubUserModal(true)
        setviewdetails({
          isVisible: true,
          data: item
        })
      }}>View Details</label>
      tableData.push(row)
      row = []
    })
    return tableData
  }

  const getCountrydata = () => {
    call('GET', 'getallCountry').then((result) => {
      console.log('running getallCountry api-->', result);
      setCountrys(result)
    }).catch((e) => {
      // console.log('error in getBuyersDetail', e);
    });
  }

  const updateDocs = async () => {
    setshowLoader(true)
    try {
      const formData = new FormData()
      formData.append('userTypeId', userTypeId)
      formData.append('userId', userId)
      formData.append('email', data.email_id)
      formData.append('contactNo', data.contact_number)
      formData.append('address', data.user_address)
      formData.append('userName', data.user_name)
      formData.append('avatarFile', data.user_avatar)
      const profilekycdocs = KYCUploads
      profilekycdocs.forEach(kycuploads => {
        if (data[kycuploads.val] !== undefined && data[kycuploads.val] !== null) {
          const KYCData = KYCDocuments.find(data => kycuploads.val === data.doc_name.split(' ').join('_'))
          console.log('MatchFound', KYCData)
          let payloadUpload = {}
          if (KYCData) {
            payloadUpload = {
              uploadingUser: userId,
              kycUserId: userId,
              userTypeName: '',
              id: KYCData.id ? KYCData.id : '',
              tbl_doc_id: KYCData.tbl_doc_id ? KYCData.tbl_doc_id : '',
              contract_id: "",
              linked_to: KYCData.linked_to,
              isUpdateQuery: KYCData.doc_status === 1 ? true : false,
              prevFileHash: KYCData.file_hash,
              categoryId: KYCData.category_id,
              type: '',
              filePayload: {
                docNo: KYCData.doc_no,
                docName: KYCData.doc_name,
                genDocLabel: (KYCData.doc_status === 1 && KYCData.doc_type === 1) ? KYCData.gen_doc_label : '',
                docType: KYCData.doc_type,
                validUpto: KYCData.valid_upto
              }
            }
          } else {
            payloadUpload = {
              uploadingUser: userId,
              kycUserId: userId,
              userTypeName: '',
              contract_id: "",
              linked_to: 1,
              isUpdateQuery: false,
              prevFileHash: '',
              categoryId: 2,
              type: '',
              filePayload: {
                docNo: '',
                docName: kycuploads.val.split('_').join(' '),
                genDocLabel: '',
                docType: 1,
                validUpto: null
              }
            }

          }
          payloadUpload = JSON.stringify(payloadUpload);
          let formData = new FormData();
          formData.append('payloadUpload', payloadUpload);
          formData.append('doc', data[kycuploads.val])
          console.log('Doctypeeeeeeee', data[kycuploads.val], data[kycuploads.val] && data[kycuploads.val].fromDb)
          if (!(data[kycuploads.val] && data[kycuploads.val].fromDb)) {
            call('POST', 'updateDoc', formData).then((result) => {
              setshowLoader(false)
              if (result) {
                toastDisplay(kycuploads.name + " Uploaded", "success");
              }
            }).catch(err => {
              setshowLoader(false)
              console.log("conn:", err)
              toastDisplay("Failed to upload " + kycuploads.name, "error");
            })
          } else {
            setshowLoader(false)
          }
        } else {
          setshowLoader(false)
        }
      })
      const extradetailsreqObj = new FormData()
      for (let i = 0; i <= docsFormArray.length - 1; i++) {
        let compDocs = docsFormArray[i]
        if (data[compDocs.name] === null || data[compDocs.name] === undefined) {
          extradetailsreqObj.append(compDocs.key + compDocs.dbId, null)
        } else {
          if (data[compDocs.name].fromDb === true) {
            //Convert To file Object
            if (data[compDocs.name].filebase64) {
              const filedata = await fetch('data:application/pdf;base64,' + data[compDocs.name].filebase64)
              const blob = await filedata.blob();
              const fileObj = new File([blob], data[compDocs.name].name, { type: blob.type });
              extradetailsreqObj.append(compDocs.key + compDocs.dbId, fileObj)
            }
          } else {
            extradetailsreqObj.append(compDocs.key + compDocs.dbId, data[compDocs.name])
          }
        }
      }
      extradetailsreqObj.append("userId", userId)
      call('POST', 'updateCompanyDocs', extradetailsreqObj).then((result) => {
        setshowLoader(false)
        if (result) {
          setshowLoader(false)
          toastDisplay(result, "success")
        }
      }).catch(err => {
        setshowLoader(false)
        console.log("conn:", err)
        toastDisplay(err, "error");
      })
      getUserProfileData()
    } catch (error) {
      console.log('error in updatedocs', error)
      setshowLoader(false)
    }

  }
  const handleChange = async (event) => {

    if (event.persist) {
      event.persist()
    }

    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })


  }
  const handleValidation = () => {
    let error = {}
    if (!data.name_title) {
      error.contactPerson = 'Mandatory Field'
    }
    if (!data.contactPerson) {
      error.contactPerson = 'Mandatory Field'
    }
    if (!data.phone_code) {
      error.phone_code = 'Mandatory Field'
    }
    if (!data.contact_number) {
      error.contact_number = 'Mandatory Field'
    }
    if (!data.user_address) {
      error.user_address = 'Mandatory Field'
    }
    if (!data.companyCity) {
      error.companyCity = 'Mandatory Field'
    }
    if (!data.companyState) {
      error.companyState = 'Mandatory Field'
    }
    if (!data.country_code) {
      error.country_code = 'Mandatory Field'
    }
    if (isEmpty(error)) {
      let reqObj = {
        name_title: data.name_title,
        phone_code: data.phone_code,
        contactPerson: data.contactPerson,
        contact_number: data.contact_number,
        email_id: data.email_id,
        organization_type: data.organization_type,
        industry_type: data.industry_type,
        user_address: data.user_address,
        companyCity: data.companyCity,
        companyState: data.companyState,
        country_code: data.country_code,
        companyPostal: data.companyPostal,
        country_of_incorporation: data.country_of_incorporation,
        country_of_operation: data.country_of_operation,
        years_of_incorporation: data.years_of_incorporation,
        prevNetProfit: data.prevNetProfit,
        ExisExportTurnover: data.ExisExportTurnover,
        ExpecExportTurnover: data.ExpecExportTurnover,
        currency: data.currency,
        tbl_user_id: userId,
      }
      setshowLoader(true)
      call('POST', 'updateUserdata', reqObj).then(result => {
        setshowLoader(false)
        toastDisplay(result, "success")
        getUserDetailsExtra()
        getUserProfileData()
        setEditPopup(false)
      }).catch(e => {
        console.log('error in updateUserdata', e);
        setshowLoader(false)
        toastDisplay(e, "error")
      })
    } else {
      setErrors(error)
    }
  }
  console.log('data', referraldata, subUserList)
  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      {subUserModal &&
        <SubUserModal setSubUserModal={setSubUserModal} subUserModal={subUserModal} countrys={countrys} userTokenDetails={{
          user_id: userTokenDetails.id,
          type_id: userTokenDetails.type_id,
          userName: userTokenDetails.company_name
        }} isEditable={viewdetails.isVisible} formdata={viewdetails.data} />
      }
      {editPopup &&
        <div className={`modal fade ${editPopup && "show"}`} style={{ display: "block", "zIndex": '100001' }}>
          <div className="modal-dialog modal-md mr-0 my-0" ref={modalRef}>
            <div className="modal-content submitmodal pb-4">
              <div className="modal-header border-0">
                <div className="w-100 d-flex align-items-center justify-content-between">
                  <label
                    className="font-size-16 font-wt-600 text-color-value mx-3"
                  >Edit user details</label>
                  <div className="modal-header border-0">
                    <button type="button" className="btn-close" aria-label="Close" onClick={() => setEditPopup(false)}></button>
                  </div>
                </div>
              </div>

              <div className="modal-body px-4 filterbody">

                <div className="col py-2 ">
                  <div className="col-md-12 px-0">
                    <InputWithSelect isAstrix={true} type={"text"} label={"Contact Person"}
                      selectData={[{ name: "Mr" }, { name: 'Miss' }]}
                      selectName={"name_title"} selectValue={data.name_title}
                      optionLabel={"name"} optionValue={'name'}
                      name={'contactPerson'} value={data.contactPerson} error={errors.contactPerson}
                      onChange={handleChange} />
                  </div>
                </div>

                <div className="col py-2 ">
                  <div className="col-md-12 px-0">
                    {/* <label>Contact Number</label> */}
                    <InputWithSelect isAstrix={true} type={"text"} label={"Contact Number"}
                      selectData={countrys}
                      selectName={"phone_code"} selectValue={data.phone_code}
                      optionLabel={"phonecode"} optionValue={'phonecode'}
                      name={'contact_number'} value={data.contact_number} error={errors.contact_number}
                      onChange={handleChange} />
                  </div>
                </div>

                <div className="col py-2">
                  <div className="col-md-12 px-0">
                    <NewInput isAstrix={true} type={"text"} label={"Email ID"}
                      name={"email_id"} value={data.email_id} error={errors.email_id}
                      onChange={handleChange} />
                  </div>
                </div>

                <div className="col py-2">
                  <div className="col-md-12 px-0">
                    <NewSelect
                      selectData={companyTypes}
                      optionLabel={'name'}
                      optionValue={'alt'}
                      name={"organization_type"}
                      label={'Organization Type'}
                      value={data.organization_type || ""}
                      onChange={handleChange}
                      error={errors.organization_type} />
                  </div>
                </div>

                <div className="col py-2">
                  <div className="col-md-12 px-0">
                    <NewSelect
                      selectData={industryData}
                      optionLabel={'name'}
                      optionValue={'value'}
                      name={"industry_type"}
                      label={'Industry Type'}
                      value={data.industry_type || ""}
                      onChange={handleChange}
                      error={errors.industry_type} />
                  </div>
                </div>

                <div className="col py-2">
                  <div className="col-md-12 px-0">
                    <NewInput isAstrix={true} type={"text"} label={"Address"}
                      name={"user_address"} value={data.user_address} error={errors.user_address}
                      onChange={handleChange} />
                  </div>
                </div>

                <div className="col py-2">
                  <div className="col-md-12 px-0">
                    <NewInput isAstrix={true} type={"text"} label={"Company City"}
                      name={"companyCity"} value={data.companyCity} error={errors.companyCity}
                      onChange={handleChange} />
                  </div>
                </div>

                <div className="col py-2">
                  <div className="col-md-12 px-0">
                    <NewInput isAstrix={true} type={"text"} label={"Company State"}
                      name={"companyState"} value={data.companyState} error={errors.companyState}
                      onChange={handleChange} />
                  </div>
                </div>

                <div className="col py-2">
                  <div className="col-md-12 px-0">
                    <NewSelect
                      selectData={countrys}
                      optionLabel={'name'}
                      optionValue={'sortname'}
                      name={"country_code"}
                      label={'Country'}
                      value={data.country_code || ""}
                      onChange={handleChange}
                      error={errors.country_code} />
                  </div>
                </div>

                <div className="col py-2">
                  <div className="col-md-12 px-0">
                    <NewInput type={"text"} label={"Postal Code"}
                      name={"companyPostal"} value={data.companyPostal} error={errors.companyPostal}
                      onChange={handleChange} />
                  </div>
                </div>

                <div className="col py-2 ">
                  <div className="col-md-12 px-0">
                    <NewSelect label={"Country of incorporation"}
                      selectData={countrys} name={"country_of_incorporation"}
                      value={data.country_of_incorporation} optionLabel={"name"} optionValue={'sortname'}
                      onChange={handleChange} error={errors.country_of_incorporation} />
                  </div>
                </div>
                <div className="col py-2 ">
                  <div className="col-md-12 px-0">
                    <NewSelect label={"Country of operation"}
                      selectData={countrys} name={"country_of_operation"}
                      value={data.country_of_operation} optionLabel={"name"} optionValue={'sortname'}
                      onChange={handleChange} error={errors.country_of_operation} />
                  </div>
                </div>
                <div className="col py-2 ">
                  <div className="col-md-12 px-0">
                    <NewInput type={"text"} label={"Years of incorporation"}
                      name={"years_of_incorporation"} value={data.years_of_incorporation} error={errors.years_of_incorporation}
                      onChange={handleChange} />
                  </div>
                </div>
                <div className="col py-2 ">
                  <div className="col-md-12 px-0">
                    <InputWithSelect type={"text"} label={"Last year’s net profit"}
                      selectData={most_used_currencies}
                      selectName={"currency"} selectValue={data.currency}
                      optionLabel={"code"} optionValue={'code'}
                      name={'prevNetProfit'} value={data.prevNetProfit} error={errors.prevNetProfit}
                      onChange={handleChange} />
                  </div>
                </div>
                <div className="col py-2 ">
                  <div className="col-md-12 px-0">
                    <InputWithSelect type={"text"} label={"Average existing turnover"}
                      selectData={most_used_currencies}
                      selectName={"currency"} selectValue={data.currency}
                      optionLabel={"code"} optionValue={'code'}
                      name={'ExisExportTurnover'} value={data.ExisExportTurnover} error={errors.ExisExportTurnover}
                      onChange={handleChange} />
                  </div>
                </div>
                <div className="col py-2 ">
                  <div className="col-md-12 px-0">
                    <InputWithSelect type={"text"} label={"Expected turnover"}
                      selectData={most_used_currencies}
                      selectName={"currency"} selectValue={data.currency}
                      optionLabel={"code"} optionValue={'code'}
                      name={'ExpecExportTurnover'} value={data.ExpecExportTurnover} error={errors.ExpecExportTurnover}
                      onChange={handleChange} />
                  </div>
                </div>
                <div className="d-flex gap-4  px-3 ">
                  <button className={`mt-4 new-btn w-40 py-2 px-2 text-white cursor`} onClick={handleValidation}>Update</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      <div className='card border-0 chatlist p-4 mt-4'>
        <div className='row'>

          <div className='col-md-4'>
            <div className="">
              {reviewForm.map((item) => {
                let val = userTokenDetails?.subUserProfileDetails?.[item.val] || data?.[item.val] || ""
                let unit = item.unit ? (userTokenDetails?.subUserProfileDetails?.[item.unit] || data[item.unit]) : ""
                return (
                  <div className="col ">
                    <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {
                      item.isMultipleKeys ? item.val.map(item => data[item]).join(",")
                        : val ? (unit ? `${unit ? unit : ''} ` : "") + (val) : "NA"}</span> </p>
                  </div>
                );
              })}
              <div className="col ">
                <p className="d-flex align-items-top mb-2">
                  <span className="col-md-5 px-0 BuyerdetailsLabel">{"Onboarding Date"}</span>
                  <span className="mx-3">:</span>
                  <span className="col-md-7 BuyerdetailsDesc">{userTokenDetails?.created_at ? moment(userTokenDetails?.created_at).format("DD-MM-YYYY") : "-"}</span>
                </p>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className="">
              {reviewForm2.map((item) => {
                return (
                  <div className="col ">
                    <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {
                      item.isMultipleKeys ? item.val.map(item => data[item]).join(",")
                        : data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className='col-md-4'>
            <div className="">
              {reviewForm3.map((item) => {
                return (
                  <div className="col ">
                    <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {
                      item.isMultipleKeys ? item.val.map(item => data[item]).join(",")
                        : data[item.val] ? (item.unit ? `${data[item.unit] ? data[item.unit] : ""} ` : "") + (data[item.val]) : "NA"}</span> </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

      </div>
      <div className='card border-0 chatlist p-4 mt-4'>
        <p className='font-size-14 font-wt-600  text-decoration-underline'>Documents</p>
        <div className='row'>
          {BusinessDocs.map(docs => {
            return <div className="col-md-4">
              <label className="font-size-13">{docs.name}</label>
              <div className="row form-group">
                <div className="col-md-11">
                  <FileInput name={docs.name} value={data[docs.name]} error={errors[docs.name]}
                    onChange={handleFile}
                    isEditable={true}
                    userTokenDetails={userTokenDetails}
                    onUploadCancel={() => setData({ ...data, [docs.name]: null })}
                  />
                  {errors[docs.name] ? <div class="text-danger mt-2 font-size-12">
                    <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                    <b>{errors[docs.name]}</b></div> : ''}
                </div>
              </div>
            </div>
          })
          }
          {KYCUploads.map(kycData => {
            return <div className="col-md-4">
              <label className="font-size-13">{kycData.name}</label>
              <div className="row form-group">
                <div className="col-md-11">
                  <FileInput name={kycData.val} value={data[kycData.val]} error={errors[kycData.val]}
                    onChange={handleFile} isEditable={true} userTokenDetails={userTokenDetails}
                    onUploadCancel={() => setData({ ...data, [kycData.val]: null })}
                  />
                  {errors[kycData.val] ? <div class="text-danger mt-2 font-size-12">
                    <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                    <b>{errors[kycData.val]}</b></div> : ''}
                </div>
              </div>
            </div>
          })
          }
          <div className="d-flex gap-4">
            <button className={`my-4 new-btn w-17 py-2 px-2 text-white cursor`} onClick={updateDocs}>Save</button>
          </div>
        </div>
      </div>
      <div className='card border-0 chatlist p-4 mt-4'>
        <div className='d-flex flex-row justify-content-between align-items-center'>
          <div className='font-size-14 font-wt-600  text-decoration-underline'>Users</div>
          <div className="d-flex gap-4">
            <button className={`new-btn  py-2 px-2 text-white cursor`} onClick={() => setSubUserModal(true)}>Add New User</button>
          </div>
        </div>
        <div className='mt-4'>
          <NewTable
            disableAction
            columns={[
              { name: "Name", filter: true },
              { name: "Contact no.", filter: true },
              { name: "Email ID", filter: false },
              { name: "Access", filter: false },
              { name: "", filter: false }
            ]}
            data={subUserList}
          />
          {/* <Pagination perPage={filter.resultPerPage || 10} page={page} totalCount={count} onPageChange={(p) => setPage(p)} /> */}

        </div>

      </div>
      <div className='card border-0 chatlist p-4 mt-4'>
        <div className='d-flex flex-row justify-content-between align-items-center'>
          <div className='font-size-14 font-wt-600  text-decoration-underline'>Referrals</div>
          <div className='d-flex flex-row gap-2 align-items-center'>
            <label id='referCode' className='font-size-14 mb-0 font-wt-600 text-color1 text-decoration-underline'>{`Code: ${data.referralCode}`}</label>
            <img src={"/assets/images/copy_svg.svg"} alt="" className="ps-2 cursor" onClick={() => copyToClipboard("referCode")} />

          </div>

        </div>
        <div className='mt-4'>
          <NewTable
            disableAction
            columns={[
              { name: "Name", filter: true },
              { name: "Contact no.", filter: true },
              { name: "Email ID", filter: false },
              { name: "Category", filter: false },
              { name: "Rewards", filter: false }
            ]}
            data={referraldata}
          />
          {/* <Pagination perPage={filter.resultPerPage || 10} page={page} totalCount={count} onPageChange={(p) => setPage(p)} /> */}

        </div>

      </div>
      <div className='card border-0 chatlist p-4 mt-4'>
        <p className='font-size-14 font-wt-600  text-decoration-underline'>Other Branches</p>
        <div className='mt-4'>
          <NewTable
            disableAction
            columns={[
              { name: "Branch Code" },
              { name: "GST IN" },
              { name: "Address" }
            ]}
            data={gstBranchList}
          />

        </div>

      </div>
    </>
  )
}

export default UserDetailsTab