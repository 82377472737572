import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import call from '../../service';
import { FileInput } from '../../utils/FileInput';
import avatarUrl from '../../utils/makeAvatarUrl';
import MultipleSelect from '../../utils/MultipleSelect';
import { astrix, ClearCache, convertImageToPdf, dataURItoBlob, GetCache, getDocDetails, most_used_currencies, SetCache, toolTip, userPlanQuotaFetch } from '../../utils/myFunctions';
import { InputWithSelect, NewInput, NewSelect } from '../../utils/newInput';
import { NewTable } from '../../utils/newTable';
import { StarRating } from '../../utils/starRating';
import toastDisplay from '../../utils/toastNotification';
import Header from '../partial/header';
import HeaderV2 from '../partial/headerV2';
import Sidebar, { inspectionTypeIds, traderTypeIds } from '../partial/sidebar';
import SideBarV2 from '../partial/sideBarV2';
import { PopupMessage } from '../popupMessage';
import config from '../../config.json';
import moment from 'moment';
import TutorialPopup, { TutorialVideoPopup } from '../tutorialPopup';
import ChatBoxPopUp2 from '../chatRoom/components/ChatBoxPopUp2';
import { setContractDetails, setContractState, setContractDocList } from '../../store/actions/action';


import { getSocket } from '../../socket'
import NewTablev2 from '../../utils/newTablev2';
import { formatDate_Application } from '../../utils/dateFormaters';
import { getStatusDisplayName } from '../dataTablesColumsMap/contractListColoums';
import { Action } from '../myCounterPartComp/action';
import Filter from '../InvoiceDiscounting/components/Filter';
import Pagination from '../InvoiceDiscounting/contract/components/pagination';

const finHighlights = [
  { name: 'In-Pilot', color: 'rgba(255, 193, 7, 0.9)' },
  { name: 'In-Progress', color: 'rgba(248, 148, 6, 0.9)' },
  { name: 'Completed', color: 'rgba(63, 195, 128, 0.9)' }
]


const WorkOrderContract = ({ userTokenDetails, cDetailsState, setContractDetails, contractState, setContractState, clientType, setContractDocList, navToggleState, dispatch }) => {

  const queryParams = new URLSearchParams(window.location.search)


  var Socket = getSocket()
  const [contractCount, setcontractCount] = useState({})
  const [refresh, setrefresh] = useState(0)
  const [searchKey, setsearchKey] = useState("")
  const [filterData, setFilterData] = useState({})
  const [statusFilter, setstatusFilter] = useState(0)
  const [contractLog, setcontractLog] = useState({ modal: false, contract_no: "" })
  const [showQuotRaiseModal, setshowQuotRaiseModal] = useState({})
  const [showInvestigationRemark, setshowInvestigationRemark] = useState({})
  const [showInsuranceRemark, setshowInsuranceRemark] = useState({});
  const [showLoader, setshowLoader] = useState(false);
  const [quotaAvail, setQuotaAvail] = useState(false)
  const [userPlanQuota, setUserPlanQuota] = useState({});
  const [deleteConfirm, setdeleteConfirm] = useState(false);
  const [contDeleteData, setcontDeleteData] = useState({});
  const [inspectionContractModal, setInspectionContractModal] = useState({ show: false, data: {} })
  const [inspectionWorkorderDetails, setInspectionWorkorderDetails] = useState({ show: false, data: {}, offer_data: {} })

  const [tableData, settableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [action, setAction] = useState({ show: false, index: null })
  const [filter, setFilter] = useState({ resultPerPage: 10, search: queryParams.get("search") || "" })
  const [page, setPage] = useState(1)

  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const parentId = userTokenDetails.parentId !== undefined ? userTokenDetails.parentId : null

  console.log("aclMapppppppppppppppppppppppppppppppp", aclMap);

  useEffect(() => {
    // Create filter data & set it 
    let filterData = {}
    filterData["Date"] = {
      "accordianId": 'dateRangeFilter',
      type: "minMaxDate",
      value: []
    }
    filterData["Status"] = {
      "accordianId": 'status',
      type: "checkbox",
      data: [{ name: "In-Pilot" }, { name: "In-Progress" }, { name: 'Completed' }],
      labelName: "name"
    }
    setFilterData(filterData)
  }, [])

  useEffect(() => {
    fetchTableData({ userId: userId, type_id: userTypeId })
  }, [refresh, clientType.type, contractState.info.refresh, filter, filterData])

  function fetchTableData(inptObject) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": filter.resultPerPage,
      "userId": inptObject.userId ? inptObject.userId : userId,
      "type_id": inptObject.type_id ? inptObject.type_id : userTypeId,
      // "activeType": clientType.type,
      "userParentId": parentId,
      "gridOnly": true,
      search: filter.search
    }

    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push((element.accordianId === "status" || element.accordianId === "financiersFilter" || element.accordianId === "timeLeft") ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
        else if (element.type === "minMaxDate") {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }
    objectAPI["fromDate"] = objectAPI?.["dateRangeFilter"]?.[0]
    objectAPI["toDate"] = objectAPI?.["dateRangeFilter"]?.[1]
    if (objectAPI?.status?.includes("In-Pilot")) {
      objectAPI = {
        ...objectAPI,
        onlyPilot: true,
        onlyProgress: false,
        onlyComplete: false
      }
    }
    else if (objectAPI?.status?.includes("In-Progress")) {
      objectAPI = {
        ...objectAPI,
        onlyPilot: false,
        onlyProgress: true,
        onlyComplete: false
      }
    }
    else if (objectAPI?.status?.includes("Completed")) {
      objectAPI = {
        ...objectAPI,
        onlyPilot: false,
        onlyProgress: false,
        onlyComplete: true
      }
    }
    call('POST', 'getContracts', objectAPI).then((result) => {
      console.log('running getcontract api-->', result);
      settableData(result.contractdata || [])
      setTotalRows(result.countdata.totalcount)
      setcontractCount(result.countdata)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getContracts', e);
      setLoading(false);
    })
  }


  return (
    <>
      {loading && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="workOrderContracts" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Workorder Contracts > Contract List"}
              userTokenDetails={userTokenDetails} />

            <div className='filter-div position-relative'>
              <Filter
                filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                showResultPerPage={true} count={totalRows} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setrefresh} />

              {Object.values(filterData || {})?.filter(i => {
                if (i.isFilterActive) { return true }
              })?.length ? null : <div style={{ left: '21rem' }} className='d-flex position-absolute'>
                {finHighlights.map((i, j) => {
                  return (<div className='d-flex align-items-center mx-2' >
                    <div style={{ background: i.color, height: '10px', width: '10px', borderRadius: 1000 }} />
                    <label className='font-size-13 font-wt-500 mt-2 mx-2' >{i.name}</label>
                  </div>)
                })}
              </div>}
            </div>
            {!userPlanQuotaFetch()?.["trades"]?.limitLeft ?
              <label className='text-danger font-size-13 font-wt-600' >{'You have already used 100% of your commodity contracts quota. Please go to your plan and make a purchase in order to be able to create a new contract.'}</label>
              : null}
            <NewTablev2
              columns={[{ subColumns: "Contract Name", subColumnStyle: { width: '10%' } },
              { subColumns: "Buyer", subColumnStyle: { width: '10%' } },
              {
                subColumns: "Seller", filterDataKey: "Buyer Name",
                subColumnStyle: { width: '10%' }
              },
              { subColumns: "Commodity", subColumnStyle: { width: '8%' }, },
              { subColumns: "Quantity", subColumnStyle: { width: '8%' } },
              { subColumns: "Price", subColumnStyle: { width: '5%' } },
              { subColumns: "Created On", subColumnStyle: { width: '8%' } },
              { subColumns: "Laycan Start", subColumnStyle: { width: '8%' } },
              { subColumns: "Laycan End", subColumnStyle: { width: '8%' } },
              { subColumns: "Status", subColumnStyle: { width: '10%' } }, { subColumns: "", subColumnStyle: { width: '5%' } }
              ]}
            >
              {tableData.map((h, i) => {
                let isMktPlaceContract = tableData[i].booking_id ? true : false
                let offerType = inspectionTypeIds.includes(userTypeId) ? "offers_sent" : "offers_received"
                let tempObj = {}

                tempObj.isMktPlaceContract = isMktPlaceContract
                tempObj.contract_name = isMktPlaceContract ? tableData[i].booking_id : tableData[i].contract_name;
                tempObj.buyername = isMktPlaceContract ? tableData[i].company_name : tableData[i].buyername;
                tempObj.suppliername = isMktPlaceContract ? "NA" : tableData[i].suppliername;
                tempObj.commodity_name = tableData[i].commodity_name;

                tempObj.investigation_status = isMktPlaceContract ? 0 : tableData[i].investigation_status;
                tempObj.insurance_status = isMktPlaceContract ? 0 : tableData[i].insurance_status;

                if (isMktPlaceContract) {
                  tempObj.quantity = tableData[i][offerType][0]["offer_details_obj"]["quantity"] + ' mt';
                  tempObj.price = tableData[i][offerType][0]["offer_details_obj"]["currency"] + " " + tableData[i][offerType][0]["offer_details_obj"]["inspection_quote"];
                  tempObj.dbData = tableData[i]
                }
                else {
                  tempObj.quantity = ([3, 4, 8, 13, 14].includes(userTypeId / 1)) ? (tableData[i].quantity + ' mt') : "CONFI";
                  tempObj.price = ([3, 4, 8, 14].includes(userTypeId / 1)) ? (tableData[i].currency.split(':')[1] + tableData[i].price) : "CONFI";
                }
                tempObj.created_at = tableData[i].created_at ? formatDate_Application(tableData[i].created_at) : "NA";

                if (aclMap.contract_access && aclMap.contract_access.cont_bankerComp && aclMap.contract_access.cont_bankerComp.isExist) {
                  tempObj.category = ((userId === tableData[i].buyer_bank) ? "Buyer Bank" :
                    (userId === tableData[i].supplier_bank) ? "Seller Bank" :
                      (userId !== tableData[i].supplier_bank && userId !== tableData[i].buyer_bank && parentId === 0) ? "Bank Master" : "NA")
                  tempObj.lc = tableData[i].lc ? tableData[i].lc : 'N/A';
                  tempObj.status = tableData[i].lc_status;
                }
                else {
                  if (isMktPlaceContract) {
                    tempObj.laycan_start = formatDate_Application(tableData[i][offerType][0]["offer_details_obj"]["laycan_start_date"]);
                    tempObj.laycan_end = formatDate_Application(tableData[i][offerType][0]["offer_details_obj"]["laycan_end_date"]);
                  }
                  else {
                    tempObj.laycan_start = tableData[i].laycan_start ? formatDate_Application(tableData[i].laycan_start) : "NA";
                    tempObj.laycan_end = tableData[i].laycan_end ? formatDate_Application(tableData[i].laycan_end) : "NA";
                  }
                  tempObj.status = {
                    "status_buyer": tableData[i].status_buyer,
                    "status_seller": tableData[i].status_seller,
                    "edit_request": tableData[i].edit_request,
                    "payment_status": tableData[i].payment_status,
                    "userId": userId,
                    "display_status": isMktPlaceContract ? "Offer Accepted" : tableData[i].display_status
                  }
                }

                tempObj.actions = {
                  "status_buyer": tableData[i].status_buyer,
                  "status_seller": tableData[i].status_seller,
                  "contract_number": isMktPlaceContract ? tableData[i].booking_id : tableData[i].contract_number,
                  "contract_id": tableData[i].id,
                  "exporter_id": tableData[i].exporter_id,
                  "importer_id": tableData[i].importer_id,
                  "supplier_bank": tableData[i].supplier_bank,
                  "lc_status": tableData[i].lc_status,
                  "contract_type": tableData[i].contract_type,
                  "quotation_status": tableData[i].quotation_status,
                  "contAddress": tableData[i].address,
                }
                let temp = getStatusDisplayName(tempObj)
                let statusDisplayName = temp["label"]
                let statusStyle = temp["style"]

                let actionOptions = []

                if (
                  ((((userTypeId === 14 && clientType.type === "buyer") || userTypeId === 3) && tempObj.actions.status_buyer === 0) ||
                    (((userTypeId === 14 && clientType.type === "seller") || userTypeId === 4) && tempObj.actions.status_seller === 0)) &&
                  (aclMap.contract_access && aclMap.contract_access.cont_edit && aclMap.contract_access.cont_edit.isExist)
                  && tempObj.actions.contAddress === null
                ) {
                  actionOptions.push({
                    name: "Contract Form", onClick: () => {
                      // EDIT
                      window.location = `/createWorkOrderContract?contractNo=${encodeURIComponent(tempObj.actions.contract_number)}&type=edit`
                    }
                  })
                }
                if (
                  ((((userTypeId === 14 && clientType.type === "buyer") || userTypeId === 3) && tempObj.actions.status_buyer === 1) ||
                    (((userTypeId === 14 && clientType.type === "seller") || userTypeId === 4) && tempObj.actions.status_seller === 1)) &&
                  (aclMap.contract_access && aclMap.contract_access.cont_view && aclMap.contract_access.cont_view.isExist)
                  && tempObj.actions.contAddress === null
                ) {
                  actionOptions.push({
                    name: "Contract Form", onClick: () => {
                      // View
                      window.location = `/createWorkOrderContract?contractNo=${encodeURIComponent(tempObj.actions.contract_number)}&type=view`
                    }
                  })
                }
                // if (aclMap.contract_access && aclMap.contract_access.cont_details && aclMap.contract_access.cont_details.isExist && tempObj.actions.contAddress) {
                actionOptions.push({
                  name: "Contract Details", onClick: () => {
                    window.location = `/workOrderContractDetails?contractNo=${tempObj.actions.contract_number}`
                  }
                })
                // }

                return (
                  <tr>
                    <td><label className='font-size-12 font-wt-500 text-break' >{tempObj.contract_name || "-"}</label></td>
                    <td><label className='font-size-12 font-wt-500 text-break'>{tempObj.buyername || "-"}</label></td>
                    <td><label className='font-size-12 font-wt-500 text-break'>{tempObj.suppliername || "-"}</label></td>
                    <td><label className='font-size-12 font-wt-500 text-break'>{tempObj.commodity_name || "-"}</label></td>
                    <td><label className='font-size-12 font-wt-500 text-break'>{tempObj.quantity || "-"}</label></td>
                    <td><label className='font-size-12 font-wt-500 text-break'>{tempObj.price || "-"}</label></td>
                    <td><label className='font-size-12 font-wt-500 text-break'>{tempObj.created_at || "-"}</label></td>
                    <td><label className='font-size-12 font-wt-500 text-break'>{tempObj.laycan_start || "-"}</label></td>
                    <td><label className='font-size-12 font-wt-500 text-break'>{tempObj.laycan_end || "-"}</label></td>
                    <td><label
                      style={{ ...statusStyle, borderRadius: 5 }}
                      className='font-size-11 font-wt-500 text-break text-dark w-100 p-1 text-center' >{statusDisplayName}</label></td>
                    <td
                      style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
                      className='position-relative'>
                      <i className="fa fa-ellipsis-v cursor mt-2 ml-2"
                        onClick={() => setAction({ show: true, index: i })}
                        aria-hidden="true"></i>
                      {action.show && action.index === i ? (
                        <Action
                          id={i}
                          onDismiss={() => setAction({ show: false, index: i })}
                          options={actionOptions} />
                      ) : null}
                    </td>
                  </tr>
                )
              })}
            </NewTablev2>
            <Pagination page={page} totalCount={totalRows} onPageChange={(p) => setPage(p)} refresh={refresh} setRefresh={setrefresh} perPage={filter.resultPerPage || 0} />

          </main>
        </div>
      </div>
    </>
  )

}

const mapStateToProps = state => {
  return {
    cDetailsState: state.contractDetails,
    contractState: state.contract,
    clientType: state.clientType,
    navToggleState: state.navToggleState
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setContractDetails: (id) => { dispatch(setContractDetails({ cId: id, modal: true })) },
    setContractState: (flag, data) => { dispatch(setContractState({ modal: flag, info: data })) },
    setContractDocList: (id, data) => { dispatch(setContractDocList({ modal: true, contractId: id, info: data })) },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkOrderContract)