import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import HeaderV2 from "../../../partial/headerV2";
import SideBarV2 from "../../../partial/sideBarV2";
import toastDisplay from "../../../../utils/toastNotification";
import { astrix, ClearCache, convertImageToPdf, GetCache, most_used_currencies, SetCache, toolTip, getDocDetails } from "../../../../utils/myFunctions";
import call from '../../../../service';
import { FileInput } from "../../../../utils/FileInput";
import { InputWithSelect, NewInput, NewSelect, NewTextArea } from '../../../../utils/newInput';
import { ToastContainer } from "react-toastify";
import SignDocument from "../../../InvoiceDiscounting/components/SignDocument";

const addBuyerTabs = [
  { name: "Application form" },
  { name: "Documents" }
]

const reviewForm = [
  { "name": "Buyer Name", val: "buyerName" },
  { "name": "Previous year annual sales", val: "previousAnnualSale", unit: "buyerCurrency" },
  { "name": "Contact Number:", val: "buyerPhone", unit: "buyerPhoneCode" },
  { "name": "Expected current year’s annual sale", val: "currentAnnualSale", unit: "buyerCurrency" },

  { "name": "Email Id", val: "buyerEmail" },
  { "name": "Inco Terms", val: "incoTerms" },
  { "name": "Website", val: "buyerWebsite" },
  { "name": "Terms of Payment", val: "termsOfPayment" },

  { "name": "Address", val: "buyerAddress" },
  { "name": "Product Details", val: "productDetails" },

  { "name": "DUNS No", val: "buyerDUNSNo" },
  { "name": "HSN Code", val: "buyerHsnCode" },

]

const reviewForm3 = [
  { "name": "LC Purpose", val: "lcPurpose" },
  { "name": "LC type", val: "lcType" },
  { "name": "Beneficiary bank account no.", val: "beneficiaryAccountNo" },
  { "name": "LC number", val: "lcNo" },
  { "name": "Beneficiary bank IFSC code", val: "beneficiaryIfscCode" },

  { "name": "LC tenor", val: "lcTenor" },
  { "name": "LC issuing bank name", val: "lcIssuingBankName" },
  { "name": "Country of origin", val: "countryOfOrigin" },
  { "name": "LC issuing bank SWIFT code", val: "lcIssuingBankSwiftCode" },
  { "name": "Shipment from country", val: "shipmentFromCountry" },
  { "name": "Shipment to country", val: "shipmentToCountry" },
  { "name": "LC issuing bank address", val: "lcIssuingBankAddress" },
  { "name": "Port of loading", val: "portOfLoading" },
  { "name": "Confirming bank name", val: "confirmingBankName" },
  { "name": "Port of discharge", val: "portOfDischarge" },
  { "name": "Confirming bank SWIFT code", val: "confirmingBankSwiftCode" },

  { "name": "Commodity", val: "commodity" },
  { "name": "Expected date of shipment", val: "expectedDateOfShipment" },
  { "name": "Beneficiary bank name", val: "beneficiaryBankName" },
  { "name": "Confirming bank address", val: "confirmingBankAddress" },
  { "name": "Beneficiary bank SWIFT code", val: "beneficiarySwiftCode" },
]



const ViewSendLC = ({ userTokenDetails, navToggleState }) => {

  let applicationDetails = JSON.parse(localStorage.getItem("applicationDetails"))
  let { onlyView } = applicationDetails

  const [tab, setTab] = useState(1);
  const [signdoc, setSigndoc] = useState(false);
  const [documentsSend, setDocumentsSend] = useState(false);

  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})

  const [addMoreDoc, setAddMoreDoc] = useState([])
  const [finAddMoreDoc, setFinAddMoreDoc] = useState([null])
  const [denyForm, setDenyForm] = useState({ modal: false, page: 0 });
  const [showLoader, setshowLoader] = useState(false)
  const [lcCodes, setLCCodes] = useState({})
  const [lcMoreDetails, setLCMoreDetails] = useState(false);

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  useEffect(() => {

    // Get master data start
    call('POST', 'getLCFieldMaster', {
    }).then((result) => {
      // console.log('running getLCFieldMaster api -->', result);
      let lcFieldObj = {}
      result.mandatoryFields.forEach((item) => {
        lcFieldObj[item.tag] = item.fieldName
      })
      result.optionalFields.forEach((item) => {
        lcFieldObj[item.tag] = item.fieldName
      })
      setLCCodes(lcFieldObj)
    }).catch((e) => {
      // console.log('error in getLCFieldMaster', e);
    })
    // Get master data end


    fetchAllDocs({ ...applicationDetails })

  }, [])

  async function fetchAllDocs(appendData) {
    setshowLoader(true)
    let confirmationLetterDocument = {}
    let otherFinDocs = {}
    let otherFinDocArr = finAddMoreDoc

    if (onlyView) {
      let contractDocs = applicationDetails.contractDocsFromFinanciers.split(":")
      for (let index = 0; index < contractDocs.length; index++) {
        if (index == 0) {
          confirmationLetterDocument = await getDocDetails(contractDocs[0])
        }
        else {
          otherFinDocs["otherFinDoc" + (index - 1)] = await getDocDetails(contractDocs[index])
          otherFinDocArr.push(null)
        }
      }
      setFinAddMoreDoc(otherFinDocArr)
    }

    let invoiceDocument = await getDocDetails(applicationDetails.buyersDocId.split(":")[0])
    let poDocument = await getDocDetails(applicationDetails.buyersDocId.split(":")[1])
    let draftLCDocument = await getDocDetails(applicationDetails.draftLC)
    let reqLetterDocument = await getDocDetails(applicationDetails.reqLetterOfConfirmation)
    let buyerOtherDocIdArr = applicationDetails.buyerOtherDocs ? applicationDetails.buyerOtherDocs.split(":") : []
    let otherDocObj = {}
    let moreDocArr = addMoreDoc
    for (let index = 0; index < buyerOtherDocIdArr.length; index++) {
      otherDocObj["otherDoc" + index] = await getDocDetails(buyerOtherDocIdArr[index])
      moreDocArr.push(null)
    }
    setData({
      ...data, ...appendData, invoiceDocument, poDocument, draftLCDocument, reqLetterDocument, confirmationLetterDocument,
      ...otherDocObj, ...otherFinDocs
    })
    setAddMoreDoc(moreDocArr)
    setshowLoader(false)
  }

  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }
      if (event.target.name.includes("otherFinDoc")) {
        setFinAddMoreDoc(finAddMoreDoc.concat(null))
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (!file_type.includes("pdf")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  async function handleValidation(tabIndex) {
    if (tabIndex != undefined) {
      return setTab(tabIndex)
    }
    let err = {}
    if (tab === 1) {
      if (!(data.confirmationLetterDocument && data.confirmationLetterDocument.signatureId)) {
        err["confirmationLetterDocument"] = "Signature is mandatory"
      }
      if (!(data.confirmationLetterDocument && data.confirmationLetterDocument.name)) {
        err["confirmationLetterDocument"] = "Mandatory field"
      }
      finAddMoreDoc.forEach((item, index) => {
        if (data["otherFinDoc" + index] && !data["otherFinDoc" + index]["signatureId"]) {
          err["otherFinDoc" + index] = "Signature is mandatory"
        }
      })
    }
    if (!Object.keys(err).length) {
      if (tab === 1) {
        setshowLoader(true)
        let formData = new FormData()

        if (onlyView) {
          formData.append("onlyView", onlyView)
          formData.append("financierDocIds", applicationDetails.contractDocsFromFinanciers)
        }

        formData.append("userId", userId)
        formData.append("quoteId", applicationDetails.id)
        formData.append("confirmationLetterDocument", data.confirmationLetterDocument)
        formData.append("confirmationLetterDocSignId", data.confirmationLetterDocument.signatureId)

        finAddMoreDoc.forEach((item, index) => {
          if (data["otherFinDoc" + index] && data["otherFinDoc" + index]["name"]) {
            formData.append("otherFinDoc" + (index + 1), data["otherFinDoc" + index])
            formData.append(`otherFinDoc${index + 1}SignId`, data["otherFinDoc" + index].signatureId)
          }
        })
        call('POST', 'sendConfirmationLetter', formData).then((result) => {
          // console.log('running sendConfirmationLetter api -->', result);
          toastDisplay(result, "success")
          setshowLoader(false)
        }).catch((e) => {
          // console.log('error in sendConfirmationLetter', e);
          toastDisplay("Something went wrong", "error")
          setshowLoader(false)
        })
      }
      else {
        setTab(tab + 1)
      }
    }
    setErrors(err)
  }

  const handleChange = async (event) => {
    event.persist()
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }

  function denyFinance() {
    setshowLoader(true)
    call('POST', 'submitLCQuote', {
      "quote": {
        assignValue: "",
        assignRemark: data.denialRemark,
        lender_id: userId,
        lender_name: userName,
        status: "denied",
        assignDate: new Date()
      },
      userId,
      quoteId: applicationDetails.id,
      "denyWhileContract": true
    }).then((result) => {
      console.log('running submitLCQuote api-->', result);
      setshowLoader(false)
      setDenyForm({ modal: true, page: 2 })
    }).catch((e) => {
      // console.log('error in submitLCQuote', e);
      setshowLoader(false)
      toastDisplay("Something went wrong", "error")
    });
  }

  return (
    <>{showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="viewDetails" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"LC - Send LC"}
              userTokenDetails={userTokenDetails} />
            <a className="cursor" href="/lcRequestLetter"><img className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
            <div>
              <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                {addBuyerTabs.map((item, index) => {
                  return (
                    <li>
                      <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                        onClick={() => { handleValidation(index) }}
                      >
                        {item.name}</a>
                    </li>
                  )
                })}
              </ul>
              <div className="card mt-1">

                {tab === 0 ? (
                  <div className='px-5 py-4 pt-5 '>
                    <div className="row">
                      <div className='w-100'>
                        <label className='font-wt-500 font-size-16'><u>Buyer Details</u></label>
                        <div className='row'>
                          {reviewForm.map((item) => {
                            return (
                              // <div className='col-5 p-0'>
                              //   <label className='col-6 lh-18 text-color-label font-size-14'>{item.name}</label>
                              //   <label className='lh-18 font-size-14'>:</label>
                              //   <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                              //     {item.val}
                              //   </label>
                              // </div>
                              <div className="col-md-6 pe-5">
                                <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" >
                                  {item.val}</span> </p>
                              </div>
                            )
                          })}
                          {/* <div className='col-5 p-0'>
                            <label className='col-6 lh-18 text-color-label font-size-14'>{"Required Limit"}</label>
                            <label className='lh-18 font-size-14'>:</label>
                            <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                              {data["requiredLimitCurrency"] + " " + data["requiredLimit"]}
                            </label>
                          </div> */}
                          <div className="col-md-6 pe-5">
                            <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{"Required Limit"}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" >
                              {data["requiredLimitCurrency"] + " " + data["requiredLimit"]}
                            </span> </p>
                          </div>
                        </div>
                      </div>

                      <div className='w-100 mt-5'>
                        <label className='font-wt-500 font-size-16'><u>LC Details</u></label>
                        <div className='row'>
                          {reviewForm3.map((item) => {
                            return (
                              // <div className='col-5 p-0'>
                              //   <label className='col-6 lh-18 text-color-label font-size-14'>{item.name}</label>
                              //   <label className='lh-18 font-size-14'>:</label>
                              //   <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                              //     {item.val}
                              //   </label>
                              // </div>
                              <div className="col-md-6 pe-5">
                                <p className=" d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" >
                                  {item.val}</span> </p>
                              </div>
                            )
                          })}
                        </div>
                        {/* <p className="mt-3 text-blue cursor" onClick={() => setLCMoreDetails(true)}>View more LC details  <img src={"assets/images/right-arrow.png"} alt="Back" height={15} width={15} /></p> */}
                      </div>

                      <div className='w-100 mt-5'>
                        <label className='font-wt-500 font-size-16'><u>Documents</u></label>
                        <div className='row mt-2'>
                          <div className="col-md-4">
                            <label className="font-size-14">Request letter for confirmation</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"reqLetterDocument"} value={data.reqLetterDocument} isEditable={false}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <label className="font-size-14">Invoice</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"invoiceDocument"} value={data.invoiceDocument} isEditable={false}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <label className="font-size-14">Purchase Order</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"poDocument"} value={data.poDocument} isEditable={false}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <label className="font-size-14">Draft LC</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"draftLCDocument"} value={data.draftLCDocument} isEditable={false}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='w-100 mt-5'>
                        <label className='font-wt-500 font-size-16'><u>Applied for</u></label>
                        <div className="d-flex">
                          <div className="my-3 col-2">
                            <p
                              className="browse-div font-size-13 cursor text-center m-0 col-10 border-finance text-dark" >
                              {"LC Confirmation"}
                              {/* {data.lcPurpose} */}
                            </p>
                          </div>

                          {/* {data.lcPurpose === "LC confirmation" && data.sameBankForLCDiscounting ? ( */}
                          <div className="my-3 col-2">
                            <p
                              className="browse-div font-size-13 cursor text-center m-0 col-10 border-finance text-dark" >
                              {"LC discounting"}
                            </p>
                          </div>
                          {/* ) : null} */}

                        </div>
                      </div>

                    </div>
                  </div>
                ) : null}

                {tab === 1 ? (
                  <div className='px-5 py-4 pt-5'>

                    <div className="col-md-8 mb-3">
                      <label className="font-size-14">LC Confirmation Letter</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"confirmationLetterDocument"} value={data.confirmationLetterDocument} error={errors.confirmationLetterDocument}
                            onChange={handleFile} isEditable={true}
                            onUploadCancel={() => setData({ ...data, "confirmationLetterDocument": null })}
                          />
                          {errors.confirmationLetterDocument ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.confirmationLetterDocument}</b></div> : ''}
                        </div>
                        {data.confirmationLetterDocument && data.confirmationLetterDocument.name && !data.confirmationLetterDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.confirmationLetterDocument, docSignName: "confirmationLetterDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.confirmationLetterDocument && data.confirmationLetterDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {finAddMoreDoc && finAddMoreDoc.length ? finAddMoreDoc.map((item, index) => {
                      return (
                        <div className="col-md-8 mb-3">
                          <label className="font-size-14">Add More</label>
                          <div className="row align-items-center">
                            <div className="form-group col-md-8 mb-0">
                              <FileInput name={"otherFinDoc" + index} value={data["otherFinDoc" + index]}
                                error={errors["otherFinDoc" + index]}
                                onChange={handleFile} isEditable={true}
                                onUploadCancel={() => setData({ ...data, ["otherFinDoc" + index]: null })}
                              />
                              {errors["otherFinDoc" + index] ? <div class="text-danger mt-2">
                                <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                <b>{errors["otherFinDoc" + index]}</b></div> : ''}
                            </div>
                            {data["otherFinDoc" + index] && data["otherFinDoc" + index].name && !data["otherFinDoc" + index].signatureId ? (
                              <div className="col-md-4 text-end">
                                <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data["otherFinDoc" + index], docSignName: "otherFinDoc" + index }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                              </div>
                            ) : null}
                            {data["otherFinDoc" + index] && data["otherFinDoc" + index].signatureId ? (
                              <div className="col-md-4 text-end">
                                <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )
                    }) : null}

                    <div className="col-md-8 mb-3">
                      <label className="font-size-14">Letter of confirmation</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"confirmationLetterDocument"} value={data.confirmationLetterDocument} error={errors.confirmationLetterDocument}
                            onChange={handleFile} isEditable={false}
                            onUploadCancel={() => setData({ ...data, "confirmationLetterDocument": null })}
                          />
                          {errors.confirmationLetterDocument ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.confirmationLetterDocument}</b></div> : ''}
                        </div>
                        {data.confirmationLetterDocument && data.confirmationLetterDocument.name && !data.confirmationLetterDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.confirmationLetterDocument, docSignName: "confirmationLetterDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.confirmationLetterDocument && data.confirmationLetterDocument.signatureId ?
                          (
                            <div className="col-md-4 text-end">
                              <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                            </div>
                          )
                          : null}
                      </div>
                    </div>

                    <div className="col-md-8 mb-3">
                      <label className="font-size-14">Request letter of confirmation</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"reqLetterDocument"} value={data.reqLetterDocument} error={errors.reqLetterDocument}
                            onChange={handleFile} isEditable={false}
                            onUploadCancel={() => setData({ ...data, "reqLetterDocument": null })}
                          />
                          {errors.reqLetterDocument ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.reqLetterDocument}</b></div> : ''}
                        </div>
                        {data.reqLetterDocument && data.reqLetterDocument.name && !data.reqLetterDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.reqLetterDocument, docSignName: "reqLetterDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.reqLetterDocument && data.reqLetterDocument.signatureId ?
                          (
                            <div className="col-md-4 text-end">
                              <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                            </div>
                          )
                          : null}
                      </div>
                    </div>

                    <div className="col-md-8 mb-3">
                      <label className="font-size-14">LC draft</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"draftLCDocument"} value={data.draftLCDocument} error={errors.draftLCDocument}
                            onChange={handleFile} isEditable={false}
                            onUploadCancel={() => setData({ ...data, "draftLCDocument": null })}
                          />
                          {errors.draftLCDocument ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.draftLCDocument}</b></div> : ''}
                        </div>
                        {data.draftLCDocument && data.draftLCDocument.name && !data.draftLCDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.draftLCDocument, docSignName: "draftLCDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.draftLCDocument && data.draftLCDocument.signatureId ?
                          (
                            <div className="col-md-4 text-end">
                              <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                            </div>
                          )
                          : null}
                      </div>
                    </div>

                    <div className="col-md-8 mb-3">
                      <label className="font-size-14">Purchase Order</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"poDocument"} value={data.poDocument} error={errors.poDocument}
                            onChange={handleFile} isEditable={false}
                            onUploadCancel={() => setData({ ...data, "poDocument": null })}
                          />
                          {errors.poDocument ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.poDocument}</b></div> : ''}
                        </div>
                        {data.poDocument && data.poDocument.name && !data.poDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.poDocument, docSignName: "poDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.poDocument && data.poDocument.signatureId ?
                          (
                            <div className="col-md-4 text-end">
                              <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                            </div>
                          )
                          : null}
                      </div>
                    </div>

                    <div className="col-md-8 mb-3">
                      <label className="font-size-14">Invoice</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"invoiceDocument"} value={data.invoiceDocument} error={errors.invoiceDocument}
                            onChange={handleFile} isEditable={false}
                            onUploadCancel={() => setData({ ...data, "invoiceDocument": null })}
                          />
                          {errors.invoiceDocument ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.invoiceDocument}</b></div> : ''}
                        </div>
                        {data.invoiceDocument && data.invoiceDocument.name && !data.invoiceDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.invoiceDocument, docSignName: "invoiceDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.invoiceDocument && data.invoiceDocument.signatureId ?
                          (
                            <div className="col-md-4 text-end">
                              <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                            </div>
                          )
                          : null}
                      </div>
                    </div>
                  </div>
                ) : null}

                {tab === 0 && (
                  <div className="row pb-5 mx-4">
                    <button type="button"
                      onClick={() => setTab(1)}
                      className={`mx-4 new-btn w-15 py-2 px-3 text-white`}>
                      {"Continue"}
                    </button>
                    <button type="button"
                      onClick={() => setDenyForm({ modal: true, page: 0 })}
                      className={`mx-4 new-btn2 w-15 py-2 px-3 text-blue`}>
                      {"Deny"}
                    </button>
                  </div>
                )}

                {tab === 1 && (
                  <div className="row pb-5 mx-4">
                    <button type="button"
                      // onClick={() => handleValidation(undefined)}
                      onClick={() => setDocumentsSend(true)}
                      className={`mx-4 new-btn w-15 py-2 px-3 text-white`}>
                      {"Send Documents"}
                    </button>
                  </div>
                )}

                {documentsSend &&
                  <div className={`modal fade ${documentsSend && "show"}`} style={{ display: "block" }}>
                    <div className="modal-dialog modal-m">
                      <div className="modal-content submitmodal pb-4">
                        <div className="modal-header border-0">
                          <button type="button" className="btn-close" aria-label="Close" onClick={() => { setDocumentsSend(false); window.location = "/sendLC" }}></button>
                        </div>
                        <div className="modal-body text-center">
                          <img src={"/assets/images/upload_done.png"} alt="" className="mb-3" />
                          <p>LC sent to supplier successfully !</p>
                        </div>
                      </div>
                    </div>
                  </div>
                }

                {denyForm.modal && denyForm.page === 0 ? <>
                  <div className={`modal fade ${denyForm.modal && "show"}`} style={{ display: "block" }}>
                    <div className="modal-dialog modal-md">
                      <div className="modal-content submitmodal pb-4">
                        <div className="modal-header border-0">
                          <button type="button" className="btn-close" aria-label="Close" onClick={() => setDenyForm({ modal: false, page: 0 })}></button>
                        </div>
                        <div className="modal-body text-center">
                          <img src={"/assets/images/Questionmark.png"} alt="" className="mb-3" />
                          <p>Deny Finance Limit</p>
                          <button type="button" className={`mx-4 new-btn2 w-15 py-2 px-3 text-blue`} onClick={() => setDenyForm({ modal: true, page: 1 })}>Yes</button>
                          <button type="button" className={`mx-2 new-btn w-15 py-2 px-2 text-white`} onClick={() => setDenyForm({ modal: false, page: 0 })}>No</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </> :
                  denyForm.page === 1 ?
                    <div className={`modal fade ${denyForm.modal && "show"}`} style={{ display: "block" }}>
                      <div className="modal-dialog modal-md">
                        <div className="modal-content submitmodal pb-4">
                          <div className="modal-header border-0">
                            <button type="button" className="btn-close" aria-label="Close" onClick={() => setDenyForm({ modal: false, page: 0 })}></button>
                          </div>
                          <div className="modal-body text-center">
                            <p>Denial Remark</p>
                            <div className="col-md-10 move-p">
                              <NewTextArea rows={6} isAstrix={false} type={"text"} label={`Leave a message for the supplier.`}
                                name={"denialRemark"} value={data.denialRemark} error={errors.denialRemark}
                                onChange={handleChange}
                              />
                            </div>
                            <button type="button" className={`new-btn w-20 py-2 px-2 text-white`}
                              // onClick={denyFinance}
                              onClick={() => setDenyForm({ modal: true, page: 2 })}
                            >Send</button>
                          </div>
                        </div>
                      </div>
                    </div> :
                    denyForm.page === 2 && <div className={`modal fade ${denyForm.modal && "show"}`} style={{ display: "block" }}>
                      <div className="modal-dialog modal-m">
                        <div className="modal-content submitmodal pb-4">
                          <div className="modal-header border-0">
                            <button type="button" className="btn-close" aria-label="Close" onClick={() => { window.location = '/sendLC' }}></button>
                          </div>
                          <div className="modal-body text-center">
                            <img src={"/assets/images/upload_done.png"} alt="" className="mb-3" />
                            <p>Finance limit for LC Confirmation denied successfully !</p>
                          </div>
                        </div>
                      </div>
                    </div>}

              </div>
            </div>
          </main>
        </div>
      </div >

      {signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
        setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />}
    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(ViewSendLC)