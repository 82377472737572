import { ListItem } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { NewInput } from "../../../utils/newInput";
import SliderComponent from "../../viewProfile/components/SliderComponent";

const Filter = ({ filter, setFilter, refresh, setRefresh, count, showResultPerPage, showFilterBtn, filterData, setFilterData,
  showDownloadIcon, onDownloadClick, isAdditionalButton, children, filteredSearch, setFilteredSearch, selectedIndex, setSelectedIndex, resetPage, showSelectOption, setAssignType, setToggle, toggleLabel1, toggleLabel2, showToggleButton,
  singleMultApplication, showDraftCount, onShowDraftClick, draftModeActive }) => {

  const [showFilterPopup, toggleFilterPopup] = useState(false)
  const modalRef = useRef(null)
  const handleKeyDown = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      if (event.target.name != 'randomSelected') {
        setRefresh(refresh + 1)
        if (resetPage) {
          resetPage()
        }
      }
    }
  };
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        // Clicked outside the modal, so close it
        toggleFilterPopup(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleChange = async (event) => {
    event.persist()
    if (event.target.name === 'randomSelected') {
      if (event.target.value <= filter.resultPerPage) {
        setFilter({ ...filter, [event.target.name]: event.target.value })
        return
      } else {
        return
      }
    }
    setFilter({ ...filter, [event.target.name]: event.target.value })
    if (event.target.name === 'search' && resetPage) {
      resetPage()
    }

  }

  const setFilterDataOnChange = (filterName, filterDataIndex) => {
    console.log("setFilterDataOnChange", filterName, filterDataIndex, filterData);
    let temp = filterData
    temp[filterName]["data"][filterDataIndex]["isChecked"] = !temp[filterName]["data"][filterDataIndex]["isChecked"]
    temp[filterName]["isAllChecked"] = false
    let isFilterActive = false
    for (let index = 0; index < temp[filterName]["data"].length; index++) {
      const element = temp[filterName]["data"][index];
      if (element.isChecked) {
        isFilterActive = true
      }
    }
    temp[filterName]["isFilterActive"] = isFilterActive
    setFilterData({ ...temp })
  }

  const handleAllFilterStatus = (filterName) => {
    let temp = filterData
    let isFilterActive = false
    for (let index = 0; index < temp[filterName].data.length; index++) {
      const element = temp[filterName]["data"][index];
      element["isChecked"] = !temp[filterName]["isAllChecked"]
      if (element["isChecked"]) {
        isFilterActive = true
      }
    }
    temp[filterName]["isFilterActive"] = isFilterActive
    temp[filterName]["isAllChecked"] = !temp[filterName]["isAllChecked"]
    setFilterData({ ...temp })
  }

  const setSliderDataOnChange = (e, filterName) => {
    e.persist()
    let temp = filterData
    if (e.target.name === "min" && e.target.value / 1 >= filterData[filterName]["min"]) {
      temp[filterName]["value"][0] = parseInt(e.target.value)
      temp[filterName]["isFilterActive"] = true
      setFilterData({ ...temp })
    }
    else if (e.target.name === "max" && e.target.value / 1 <= filterData[filterName]["max"]) {
      temp[filterName]["value"][1] = parseInt(e.target.value)
      temp[filterName]["isFilterActive"] = true
      setFilterData({ ...temp })
    }
  }

  const setDateSelected = (e, filterName, index) => {
    e.persist()
    let temp = filterData
    console.log('eeeeeeeeeeeeeeee', e, filterName)
    temp[filterName]["value"][index] = e.target.value
    temp[filterName]["isFilterActive"] = true
    setFilterData({ ...temp })
  }
  const setSliderDateOnChange = (e, filterName) => {
    e.persist()
    let temp = filterData
    if (e.target.name === "min") {
      temp[filterName]["value"][0] = e.target.value
      temp[filterName]["isFilterActive"] = true
      setFilterData({ ...temp })
    }
    else if (e.target.name === "max") {
      temp[filterName]["value"][1] = e.target.value
      temp[filterName]["isFilterActive"] = true
      setFilterData({ ...temp })
    }
  }

  const removeFilter = (filterName) => {
    let temp = JSON.parse(JSON.stringify(filterData))
    if (filteredSearch) {
      let temp2 = JSON.parse(JSON.stringify(filteredSearch))
      temp2[filterName]["isFilterActive"] = false
      temp2[filterName]["isAllChecked"] = false
      for (let index = 0; index < temp2[filterName]?.["data"]?.length; index++) {
        const element = temp2[filterName]?.["data"][index];
        element["isChecked"] = false
      }
      setFilteredSearch({ ...temp2 })
    }

    temp[filterName]["isFilterActive"] = false
    temp[filterName]["isAllChecked"] = false
    for (let index = 0; index < temp[filterName]?.["data"]?.length; index++) {
      const element = temp[filterName]?.["data"][index];
      element["isChecked"] = false
    }
    setFilterData({ ...temp })

  }

  return (
    <>
      <div className={`modal fade ${showFilterPopup && "show"}`} style={showFilterPopup ? { display: "block", "zIndex": '100001' } : {}}>
        <div className="modal-dialog modal-md mr-0 my-0" ref={modalRef}>
          <div className="modal-content submitmodal pb-4"
          >
            <div className="modal-header border-0">
              <div className="w-100 d-flex align-items-center justify-content-between">
                <label
                  className="font-size-16 font-wt-600 text-color-value mx-3"
                >Filters</label>
                <div className="modal-header border-0">
                  <button type="button" className="btn-close" aria-label="Close" onClick={() => toggleFilterPopup(false)}></button>
                </div>
              </div>
            </div>

            <div className="modal-body px-4 filterbody">
              {Object.keys(filterData || {}).length ? Object.keys(filterData).map((item, index) => {
                if (filterData[item]["type"] === "checkbox") {
                  return (
                    <div className="accordion accordion-flush card border-0 borderRadius commonaccordion" id="accordionFlushExample">
                      <div className="accordion-item financeacc mb-3 ">
                        <h2
                          className="accordion-header font-size-14 font-wt-600" id={"Heading" + filterData[item]["accordianId"]}>
                          <button
                            className="accordion-button shadow-none border-0 font-size-14 font-wt-600 collapsed bg-F8F8F8" type="button" data-bs-toggle="collapse"
                            data-bs-target={`#Collapse${filterData[item]["accordianId"]}`} aria-expanded="true" aria-controls={"Collapse" + filterData[item]["accordianId"]}>
                            {item}
                          </button>
                          <div
                            className="position-absolute"
                            style={{
                              top: "0.8rem",
                              right: "3rem",
                              zIndex: 100
                            }}
                          >
                            <img
                              onClick={() => {
                                handleAllFilterStatus(item)
                              }}
                              className='cursor ml-3' src={`assets/images/${filterData[item]["isAllChecked"] ? 'checked-green' : 'empty-check'}.png`} />
                          </div>
                        </h2>
                        <div
                          style={{ maxHeight: '10rem', overflow: 'auto' }}
                          id={"Collapse" + filterData[item]["accordianId"]} className={`accordion-collapse collapse ${"show"}`} aria-labelledby={"Heading" + filterData[item]["accordianId"]} data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body"
                          >
                            {filterData?.[item]?.data?.length ? filterData[item]["data"].map((j, k) => {
                              return (
                                <div className="mb-1">
                                  <img
                                    onClick={() => setFilterDataOnChange(item, k)}
                                    className='cursor' src={`assets/images/${j.isChecked ? 'checked-green' : 'empty-check'}.png`} />
                                  <label className="ml-2" >{j[filterData[item]["labelName"]]}</label>
                                </div>
                              )
                            }) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
                else if (filterData[item]["type"] === "minMax") {
                  return (
                    <div className="accordion accordion-flush card border-0 borderRadius commonaccordion" id="accordionFlushExample">
                      <div className="accordion-item financeacc mb-3 ">
                        <h2
                          className="accordion-header font-size-14 font-wt-600" id={"Heading" + filterData[item]["accordianId"]}>
                          <button
                            className="accordion-button shadow-none border-0 font-size-14 font-wt-600 collapsed bg-F8F8F8" type="button" data-bs-toggle="collapse"
                            data-bs-target={`#Collapse${filterData[item]["accordianId"]}`} aria-expanded="true" aria-controls={"Collapse" + filterData[item]["accordianId"]}>
                            {item}
                          </button>
                        </h2>
                        <div
                          // style={{ maxHeight: '10rem', overflow: 'auto' }}
                          id={"Collapse" + filterData[item]["accordianId"]} className={`accordion-collapse collapse ${"show"}`} aria-labelledby={"Heading" + filterData[item]["accordianId"]} data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body"
                          >
                            <div className="px-2 py-3">
                              <SliderComponent
                                hideInfo={true}
                                label={item} value={filterData[item]["value"]} max={filterData[item]["max"]} min={filterData[item]["min"]}
                                setValue={(val) => {
                                  let temp = filterData
                                  temp[item]["value"] = val
                                  temp[item]["isFilterActive"] = true
                                  setFilterData({ ...temp })
                                }}
                              />
                            </div>
                            <div className="row d-flex">
                              <div className="position-relative col-6">
                                <NewInput
                                  label={"From"}
                                  name={"min"}
                                  value={filterData[item]["value"][0]}
                                  type={'number'}
                                  onChange={(e) => setSliderDataOnChange(e, item)}
                                />
                              </div>
                              <div className="position-relative col-6">
                                <NewInput
                                  label={"To"}
                                  name={"max"}
                                  value={filterData[item]["value"][1]}
                                  type={'number'}
                                  onChange={(e) => setSliderDataOnChange(e, item)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                } else if (filterData[item]["type"] === "date") {
                  return <div className="accordion accordion-flush card border-0 borderRadius commonaccordion" id="accordionFlushExample">
                    <div className="accordion-item financeacc mb-3 ">
                      <h2
                        className="accordion-header font-size-14 font-wt-600" id={"Heading" + filterData[item]["accordianId"]}>
                        <button
                          className="accordion-button shadow-none border-0 font-size-14 font-wt-600 collapsed bg-F8F8F8" type="button" data-bs-toggle="collapse"
                          data-bs-target={`#Collapse${filterData[item]["accordianId"]}`} aria-expanded="true" aria-controls={"Collapse" + filterData[item]["accordianId"]}>
                          {item}
                        </button>
                      </h2>
                      <div
                        // style={{ maxHeight: '10rem', overflow: 'auto' }}
                        id={"Collapse" + filterData[item]["accordianId"]} className={`accordion-collapse collapse ${"show"}`} aria-labelledby={"Heading" + filterData[item]["accordianId"]} data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body"
                        >
                          <div className="row d-flex">
                            <div className="position-relative col-6">
                              <NewInput
                                label={"From"}
                                name={"mindate"}
                                value={filterData[item]["value"][0]}
                                type={'date'}
                                onChange={(e) => setDateSelected(e, item, 0)}
                              />
                            </div>
                            <div className="position-relative col-6">
                              <NewInput
                                label={"To"}
                                name={"maxdate"}
                                value={filterData[item]["value"][1]}
                                type={'date'}
                                onChange={(e) => setDateSelected(e, item, 1)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                else if (filterData[item]["type"] === "minMaxDate") {
                  return (
                    <div className="accordion accordion-flush card border-0 borderRadius commonaccordion" id="accordionFlushExample">
                      <div className="accordion-item financeacc mb-3 ">
                        <h2
                          className="accordion-header font-size-14 font-wt-600" id={"Heading" + filterData[item]["accordianId"]}>
                          <button
                            className="accordion-button shadow-none border-0 font-size-14 font-wt-600 collapsed bg-F8F8F8" type="button" data-bs-toggle="collapse"
                            data-bs-target={`#Collapse${filterData[item]["accordianId"]}`} aria-expanded="true" aria-controls={"Collapse" + filterData[item]["accordianId"]}>
                            {item}
                          </button>
                        </h2>
                        <div
                          // style={{ maxHeight: '10rem', overflow: 'auto' }}
                          id={"Collapse" + filterData[item]["accordianId"]} className={`accordion-collapse collapse ${"show"}`} aria-labelledby={"Heading" + filterData[item]["accordianId"]} data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body"
                          >
                            <div className="row d-flex">
                              <div className="position-relative col-6">
                                <NewInput
                                  label={"From"}
                                  name={"min"}
                                  // min={filterData[item]["min"]}
                                  value={filterData[item]["value"][0]}
                                  type={'date'}
                                  onChange={(e) => setSliderDateOnChange(e, item)}
                                />
                              </div>
                              <div className="position-relative col-6">
                                <NewInput
                                  label={"To"}
                                  name={"max"}
                                  // max={filterData[item]["max"]}
                                  value={filterData[item]["value"][1]}
                                  type={'date'}
                                  onChange={(e) => setSliderDateOnChange(e, item)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                } else if (filterData[item]["type"] === "singleDate") {
                  return (
                    <div className="accordion accordion-flush card border-0 borderRadius commonaccordion" id="accordionFlushExample">
                      <div className="accordion-item financeacc mb-3 ">
                        <h2
                          className="accordion-header font-size-14 font-wt-600" id={"Heading" + filterData[item]["accordianId"]}>
                          <button
                            className="accordion-button shadow-none border-0 font-size-14 font-wt-600 collapsed bg-F8F8F8" type="button" data-bs-toggle="collapse"
                            data-bs-target={`#Collapse${filterData[item]["accordianId"]}`} aria-expanded="true" aria-controls={"Collapse" + filterData[item]["accordianId"]}>
                            {item}
                          </button>
                        </h2>
                        <div
                          // style={{ maxHeight: '10rem', overflow: 'auto' }}
                          id={"Collapse" + filterData[item]["accordianId"]} className={`accordion-collapse collapse ${"show"}`} aria-labelledby={"Heading" + filterData[item]["accordianId"]} data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body"
                          >
                            <div className="row d-flex">
                              <div className="position-relative col-12">
                                <NewInput
                                  label={"From"}
                                  name={"min"}
                                  // min={filterData[item]["min"]}
                                  value={filterData[item]["value"][0]}
                                  type={'date'}
                                  onChange={(e) => setSliderDateOnChange(e, item)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
              }) : null}

            </div>
          </div>
        </div>
      </div>

      <div className="d-flex align-items-center w-55">
        {showResultPerPage ? (
          <div className='row align-items-center'
          >
            <label className='col-auto pt-2'>Show</label>
            <input className='col-auto form-control pagination-input  my-4' type={"number"} name={"resultPerPage"} value={filter.resultPerPage}
              // onChange={(event) => setFilter({ ...filter, resultPerPage: event.target.value })}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
            />
            <label className='col-auto pt-2'>{`of ${count || 0}`}</label>
          </div>
        ) : null}
        {showFilterBtn ? (
          <div className={showResultPerPage ? "ml-4" : ""}>
            <button
              onClick={() => toggleFilterPopup(!showFilterPopup)}
              type="button" className="btn btn-light bg-white px-4 border border-radius-12 text-start"><img src={"assets/images/filter.png"} alt="" /><span className="px-2">Filter</span></button>
          </div>
        ) : null}
        {Object.keys(filterData || {}).length ? Object.keys(filterData).map((l, m) => {
          if (filterData[l]["isFilterActive"]) {
            return (
              <div className={"ml-4"}>
                <button
                  onClick={() => removeFilter(l)}
                  type="button" className="btn btn-light px-4 border-radius-12 text-start bg-color1 border-color1-2px">
                  <span className="px-2 text-color1 font-wt-600 font-size-12 text-center">{l}</span>
                  <button type="button" className="btn-close text-color1 font-size-12" aria-label="Close" onClick={() => removeFilter(l)}></button>
                </button>
              </div>
            )
          }
        }) : null}
        {selectedIndex?.length > 0 &&
          <div className={"ml-4"}>
            <button
              onClick={() => setSelectedIndex([])}
              type="button" className="btn btn-light px-4 border-radius-12 text-start bg-color1 border-color1-2px">
              <span className="px-2 text-color1 font-wt-600 font-size-12 text-center">{`${selectedIndex.length} Selected`}</span>
              <button type="button" className="btn-close text-color1 font-size-12" aria-label="Close" onClick={() => setSelectedIndex([])}></button>
            </button>
          </div>
        }
        {showSelectOption &&
          <div >
            <ul className="ml-3 w-90 nav pricingtabs nav-pills bg-white mx-auto rounded-pill p-0 shadow-sm" id="pills-tab" role="tablist">
              <li className="nav-item p-0 w-50" role="presentation">
                <button onClick={() => {
                  //setPriceKey("plan_amount_usd")
                  setAssignType("Random")
                }} className="nav-link active  roundedpillleft font-size-14" id="pills-All-tab" data-bs-toggle="pill" data-bs-target="#pills-All" type="button" role="tab" aria-controls="pills-All" aria-selected="true">
                  <label className="font-size-13 mb-0">Random</label>
                  <input type="number" name='randomSelected' value={filter?.randomSelected}
                    onKeyDown={handleKeyDown} onChange={handleChange} maxLength={filter.resultPerPage}
                    className="w-25 ml-2 assignTaskInput" placeholder="" />
                </button>
              </li>
              <li className="nav-item p-0 w-50" role="presentation">
                <button onClick={(e) => {
                  setAssignType("Start To End")
                }} className="nav-link h-100 w-100 roundedpillright font-size-14 " id="pills-Yearly-tab" data-bs-toggle="pill" data-bs-target="#pills-Yearly" type="button" role="tab" aria-controls="pills-Yearly" aria-selected="false">

                  <label className="font-size-13 mb-0">Start To End</label>

                </button>
              </li>
            </ul>
          </div>
        }

        {showToggleButton &&
          <div >
            <ul className="ml-3 w-90 nav pricingtabs nav-pills bg-white mx-auto rounded-pill p-0 shadow-sm" id="pills-tab" role="tablist">
              <li className="nav-item p-0 w-40" role="presentation">
                <button onClick={() => {
                  setToggle(toggleLabel1)
                }} className="nav-link active  roundedpillleft font-size-14" id="pills-All-tab" data-bs-toggle="pill" data-bs-target="#pills-All" type="button" role="tab" aria-controls="pills-All" aria-selected="true">
                  <label className="font-size-13 mb-0">{toggleLabel1}</label>
                </button>
              </li>
              <li className="nav-item p-0 w-60" role="presentation">
                <button onClick={(e) => {
                  setToggle(toggleLabel2)
                }} className="nav-link h-100 w-100 roundedpillright font-size-14 " id="pills-Yearly-tab" data-bs-toggle="pill" data-bs-target="#pills-Yearly" type="button" role="tab" aria-controls="pills-Yearly" aria-selected="false">

                  <label className="font-size-13 mb-0">{toggleLabel2}</label>

                </button>
              </li>
            </ul>
          </div>
        }
      </div>

      {singleMultApplication ?
        <div
          onClick={() => { setFilter({ ...filter, singleApplication: !filter.singleApplication }) }}
          className="cursor ml-auto" >
          <img className="cursor" src={filter.singleApplication ? "/assets/images/single_application.png" : "/assets/images/multiple_application.png"} />
        </div> : null}

      <div className={`input-group mb-3 col-md-3 currency mt-3 pe-1 ${showDraftCount ? ' ml-auto ' : ' '}`}>
        <span className="input-group-text bg-white border-end-0" id="basic-addon1"><img src={"assets/images/fe_search.png"} alt="search" /></span>
        <input type="text" name='search' value={filter?.search}
          onKeyDown={handleKeyDown} onChange={handleChange}
          className="form-control border-start-0" placeholder="Search" />
      </div>
      {showDraftCount ? (
        <div
          style={{ background: '#fff', border: draftModeActive ? "2px solid #AED8FF " : '1px solid #ced4da', borderRadius: '10px' }}
          onClick={onShowDraftClick}
          className="cursor ml-3 px-3 py-1" >
          <label className="cursor" ><span className="font-size-14 font-wt-500 mr-1" >Draft</span><span className="font-size-14 font-wt-600">{showDraftCount}</span></label>
        </div>
      ) : null}
      {showDownloadIcon ? (
        <div
          onClick={onDownloadClick}
          className="cursor" >
          <img className="cursor" src={"/assets/images/download_icon_with_bg.png"} />
        </div>
      ) : null}
      {isAdditionalButton ? (
        <div>
          {children}
        </div>
      ) : null}
    </>
  );
}
export default Filter;