import React, { useEffect, useState } from 'react';
import call from '../../service';
import Sidebar from '../partial/sidebar';
import { connect } from 'react-redux';
import { setshowModalAddComm } from '../../store/actions/action';
import { ToastContainer } from 'react-toastify';
import Header from '../partial/header';
import UserCommodityAddModal from './userCommodityAddModal'
import AdminCommodityAddModal from './adminCommodityAddModal'
import toastDisplay from '../../utils/toastNotification';
import commImageUrl from '../../utils/makeCommImageUrl'
import Footer from '../partial/footer';
import SideBarV2 from '../partial/sideBarV2';
import HeaderV2 from '../partial/headerV2';
import NewTablev2 from '../../utils/newTablev2';
import Pagination from '../InvoiceDiscounting/contract/components/pagination';
import Filter from '../InvoiceDiscounting/components/Filter';
import { useHistory } from "react-router";
import { userPlanQuotaFetch } from '../../utils/myFunctions';


const CommoditiesListV2 = ({ userTokenDetails, showModalAddComm, setshowModalAddComm, navToggleState, dispatch }) => {

  const queryParams = new URLSearchParams(window.location.search)

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [showLoader, setshowLoader] = useState(false);
  const [deleteConfirm, setdeleteConfirm] = useState(false);
  let [selectedComm, setselectedComm] = useState('');
  let [refresh, setrefresh] = useState(0);
  // pagination
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [items, setItems] = useState([]);
  const [entryCountField, setEntryCountField] = useState('');
  const [commodityCategories, setcommodityCategories] = useState([])
  const [categoryId, setcategoryId] = useState('');
  const [searchKey, setsearchKey] = useState('')
  const [filterData, setFilterData] = useState({})
  const [filter, setFilter] = useState({ resultPerPage: 10 })
  const [userPlanQuota, setUserPlanQuota] = useState({});

  let history = useHistory()


  const [tabsArr, setTabsArr] = useState([
    { name: "Commodity Details" },
    // { name: "Commodity Specification" }
  ])
  const [activeClass, setActiveClass] = useState(0);

  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  // console.log("aclMappppppppppppppppppppppppppppppppppppppppppppp", aclMap);
  //---------------------------------------------------------------------------------------------------------------------

  function addNewCommodity() {
    if (aclMap.commodity_access?.commodity_addUser?.isExist) {
      let planData = userPlanQuotaFetch()
      if (!planData?.["commodities"]?.limitLeft) {
        toastDisplay("You have already used 100% of commodity contracts quota", "info")
      }
      else {
        setshowModalAddComm(true, { "type": "addNewCommodityUser" })
      }
    }
    else {
      toastDisplay("Access not available to add commodity", "info")
    }
  }

  useEffect(() => {
    call('get', 'v1/plan/quota').then((result) => {
      console.log("API result getUserCurrentPlanQuota header:-->", result);
      setUserPlanQuota(result)
    }).catch((e) => {
      console.log("Error while  getUserCurrentPlanQuota header:-->", e);
    });
  }, [])

  useEffect(() => {
    if (queryParams.get("add") && Object.keys(userPlanQuota).length) {
      addNewCommodity()
    }
  }, [userPlanQuota])

  useEffect(() => {
    call('POST', 'getusercommoditycategory', { userId: userId }).then((result) => {
      if (result.length) {
        setcommodityCategories(result);
        let filterData = {}
        filterData["Categories"] = {
          "accordianId": 'Categories',
          type: "checkbox",
          data: result,
          labelName: "category"
        }
        setFilterData(filterData)
      }
    }).catch((e) => {
      console.log("Error while querying getcommoditycategory:", e);
    })
  }, [])

  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {

    //------------------------------------------------------------------
    //API Calls
    if (userTypeId == 1) {
      call('GET', 'getcommoditycategory').then((result) => {
        if (result.length) {
          setcommodityCategories(result);
        }
      }).catch((e) => {
        console.log("Error while querying getcommoditycategory:", e);
      })
    }
    else {
    }
    getCommodityList()
    // let last = count ? Math.ceil(count / PAGE_CAPACITY) : 0;
    // if ((data[page] && data[page].length) && (page !== last)) {
    // setPagination(count)
    // setshowLoader(false)
    // } else {
    // let reqBody = { "userId": userId, 'userTypeId': userTypeId, page, pageLimit: PAGE_CAPACITY, grid: true }
    // if (categoryId) {
    //   reqBody["commCatId"] = categoryId
    // }
    // call('POST', 'getusercommoditylist', reqBody).then((result) => {
    //   console.log("api result in getusercommoditylist->", result)
    //   data[page] = result.data;
    //   setData(data);
    //   setCount(result.count)
    //   setshowLoader(false)
    //   setPagination(result.count)

    // }).catch((e) => {
    //   setshowLoader(false)
    //   console.log("Error while querying users:", e);
    // })
    // }
    //------------------------------------------------------------------

  }, [page, refresh, showModalAddComm.info.refresh, categoryId, filterData]);
  //---------------------------------------------------------------------------------------------------------------------

  function getCommodityList() {
    setshowLoader(true)
    let reqBody = { "userId": userId, 'userTypeId': userTypeId, page, searchKey: filter.search, pageLimit: filter.resultPerPage, grid: true }
    if (categoryId) {
      reqBody["commCatId"] = categoryId
    }
    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          reqBody["commCatId"] = ""
          element["data"].forEach((i) => {
            if (i.isChecked && !reqBody["commCatId"]) {
              reqBody["commCatId"] = i.id
            }
          })
        }
      }
    }
    call('POST', 'getusercommoditylist', reqBody).then((result) => {
      console.log("api result in getusercommoditylist->", result)
      setshowLoader(false)
      if (result.data.length) {
        data[page] = result.data;
        setData(data);
        setCount(result.count)
        setPagination(result.count)
      }
      else {
        setData([]);
      }

    }).catch((e) => {
      setshowLoader(false)
      console.log("Error while querying users:", e);
    })
  }


  //---------------------------------------------------------------------------------------------------------------------
  // Pagination

  function setPagination(count) {
    const last = Math.ceil(count / filter.resultPerPage);
    let items = [];

    items.push(<Pagination.Prev onClick={() => { setshowLoader(true); setPage(1) }} />)
    for (let number = Math.max(1, page - 1); number <= Math.min(page + 1, last); number++) {

      items.push(
        <Pagination.Item key={number} active={number === page.currentPage} onClick={() => { if (number !== page) setshowLoader(true); setPage(number) }}>
          {number}
        </Pagination.Item>,
      );
    }
    items.push(<Pagination.Next onClick={() => { setshowLoader(true); setPage(last) }} />)

    let startingEntry = ((page - 1) * filter.resultPerPage) + 1;
    let endingEntry = page !== last ? (page * filter.resultPerPage) : count;
    setItems(items);
    setEntryCountField(<p className='col-md-6' style={{ flexGrow: true }}>Showing {startingEntry + '-' + endingEntry + ' of ' + count} Entities</p>);
  }

  // Pagination End
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Functions
  function deleteSelectedComm() {
    call('POST', 'deleteCommodity', { "selectedComm": selectedComm, 'userTypeId': userTypeId }).then((result) => {
      // console.log("api result in getusercommoditylist->", result)
      // setrefresh(refresh + 1)
      toastDisplay("Operation Success", "success", () => window.location.reload())
      setdeleteConfirm(false)
    }).catch((e) => {
      console.log("Error while querying users:", e);
      toastDisplay("Something Went Wrong", "error")
      setdeleteConfirm(false)
    })

  }

  const handleChange = (event) => {
    event.persist();
    setcategoryId(event.target.value);
    setCount(0)
    setPagination(0)
    setPage(1)
  }

  const handleKeyPress = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      getCommodityList()
    }
  };
  //---------------------------------------------------------------------------------------------------------------------



  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      <div className="container-fluid" >
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        <div className="row">
          <SideBarV2 context={'admin'} state={!queryParams.get("add") ? 'manageCommoditiesList' : 'manageCommoditiesAdd'} userTokenDetails={userTokenDetails} />   {/* Sidebar */}
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">

            <HeaderV2
              title={!showModalAddComm.modal ? 'Manage Commodities > Commodities List' : showModalAddComm.info.status ? "Manage Commodities > Edit Commodity" : 'Manage Commodities > Add Commodity'}
              // total={'Total ' + count + ' Commodity'}
              // showNavBack={showModalAddComm.modal}
              onBackAction={() => setshowModalAddComm(false, {})}
              userTokenDetails={userTokenDetails} />
            {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

            {showModalAddComm.modal ?
              <div>
                {!queryParams.get('add') ?
                  <a
                    className="cursor "
                    onClick={() => { setshowModalAddComm(false, {}) }}
                  ><img className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a> : null}
                <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                  {tabsArr.map((item, index) => {
                    return (
                      <li
                        style={{ width: `auto` }}
                        onClick={() => {
                          setActiveClass(index)
                        }}
                      >
                        <a
                          style={{ width: `100%` }}
                          className={"nav-link formTab px-4 cursor-pointer " + (activeClass === index ? " formActiveTab show" : "")}
                        >
                          {item.name}</a>
                      </li>
                    )
                  })}
                </ul>
              </div> :
              <>
                <div className='filter-div position-relative'>
                  <Filter
                    filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                    showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setrefresh} />
                </div>
                {!userPlanQuotaFetch()?.["commodities"]?.limitLeft ?
                  <label className='text-danger font-size-13 font-wt-600' >{'You have already used 100% of your commodity quota. Please go to your plan and make a purchase in order to be able to add new commodity.'}</label>
                  : null}
                <div>
                  <NewTablev2
                    columns={[{ subColumns: "Icon", subColumnStyle: { width: '14%' }, subColumnClass: 'ml-2' },
                    { subColumns: "Type/Category", subColumnStyle: { width: '15%' } },
                    { subColumns: "Commodity", subColumnStyle: { width: '15%' } },
                    { subColumns: "Total", subColumnStyle: { width: '12%' } },
                    { subColumns: "In-process", subColumnStyle: { width: '12%' } },
                    { subColumns: "Processed", subColumnStyle: { width: '12%' } },
                    { subColumns: "Action", subColumnStyle: { width: '16%' } }]}>
                    {(data.length && data[page] && data[page].length) ? data[page].map((commData) => {
                      return (
                        <tr>
                          <td>
                            <div className='ml-2'>
                              <img width="60" src={commImageUrl(commData.filename)} className="img-responsive" alt="" />
                            </div>
                          </td>
                          <td><label className='font-size-13 font-wt-500' >{commData.category ? commData.category : "--"}</label></td>
                          <td><label className='font-size-13 font-wt-500' >{commData.commodity_pretty_name ? commData.commodity_pretty_name : "--"}</label></td>
                          <td><label className='font-size-13 font-wt-500' >{commData.total_qty != null ? commData.total_qty : "--"} mt. </label></td>
                          <td><label className='font-size-13 font-wt-500' > 0 mt.</label></td>
                          <td><label className='font-size-13 font-wt-500' > 0 mt.</label></td>
                          <td className="align-middle text-center">
                            {(aclMap.commodity_access && aclMap.commodity_access.commodity_editUser && aclMap.commodity_access.commodity_editUser.isExist) &&
                              <button className="edit-icon mr-2 text-warning" onClick={() => { setshowModalAddComm(true, { ...commData, status: true, "type": "editCommodityUser" }) }}>
                                <i class="fa fa-pencil" aria-hidden="true"></i>
                              </button>}
                            {(aclMap.commodity_access && aclMap.commodity_access.commodity_delete && aclMap.commodity_access.commodity_delete.isExist) &&
                              <button className="edit-icon mr-2 text-danger" onClick={() => { setdeleteConfirm(true); setselectedComm(commData.id) }}>
                                <i class="fa fa-trash" aria-hidden="true"></i>
                              </button>}
                          </td>
                        </tr>
                      )
                    }) : null}
                  </NewTablev2>
                </div>
              </>
            }

            {/* admin commodity edit modal start */}
            {/* {(showModalAddComm.modal && (aclMap.commodity_access && aclMap.commodity_access.commodity_addAdmin && aclMap.commodity_access.commodity_addAdmin.isExist)) &&
              <AdminCommodityAddModal userTokenDetails={userTokenDetails} />} */}
            {/* admin commodity edit modal end */}

            {/* user commodity edit modal start */}
            {(showModalAddComm.modal &&
              ((aclMap.commodity_access && aclMap.commodity_access.commodity_addUser && aclMap.commodity_access.commodity_addUser.isExist) ||
                (aclMap.commodity_access && aclMap.commodity_access.commodity_editUser && aclMap.commodity_access.commodity_editUser.isExist))) &&
              <UserCommodityAddModal userTokenDetails={userTokenDetails} activeClass={activeClass} />}
            {/* user commodity edit modal end */}

            {(deleteConfirm && (aclMap.commodity_access && aclMap.commodity_access.commodity_delete && aclMap.commodity_access.commodity_delete.isExist)) &&
              <div className={"modal" + (deleteConfirm ? " show d-block" : "")} id="Confirmation">
                <div className="modal-dialog modal-s border-inner" id="parent_class">
                  <div className="modal-content">
                    <div className="modal-header primary">
                      <h4 className="modal-title text-white font-size-16 font-wt-600">Confirmation</h4>
                      <button type="button" className="close" onClick={() => { setdeleteConfirm(false) }}>×</button>
                    </div>
                    <div className="modal-body">
                      <label className='font-size-14 font-wt-400' >Please Confirm Delete Operation</label>
                    </div>
                    {/* <div className="modal-footer primary">
                      <button type="button" className="btn btn-danger btn-sm" onClick={() => deleteSelectedComm()}>Delete</button>
                      <button type="button" className="btn bg-1ea3ae text-white btn-sm" onClick={() => setdeleteConfirm(false)}>Cancel</button>
                    </div> */}
                  </div>
                </div>
              </div>}

            {!showModalAddComm.modal ? (
              <>
                {/* <hr /> */}
                {(items.length !== 0) && data.length ? <div className="float-right row">
                  {/* {entryCountField} */}
                  {/* <Pagination className='col-md-6'>{items}</Pagination> */}
                  <Pagination page={page} totalCount={count} onPageChange={(p) => setPage(p)} refresh={refresh} setRefresh={setrefresh} perPage={filter.resultPerPage || 0} />
                  {/* <br /> */}
                </div> : null}
              </>
            ) : null}
          </main>
        </div>
      </div >

    </>
  )
};

const mapStateToProps = state => {

  return {
    showModalAddComm: state.showModalAddComm,
    navToggleState: state.navToggleState
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setshowModalAddComm: (flag, data) => { dispatch(setshowModalAddComm({ modal: flag, info: data })) }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommoditiesListV2)
