import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import call from '../../../../service';
import { NewTable } from '../../../../utils/newTable';
import HeaderV2 from '../../../partial/headerV2';
import SideBarV2 from '../../../partial/sideBarV2';

import moment from 'moment';
import { setChannelPartnerAccountList } from '../../../../store/actions/action';
import { LCPurposeObject, LCTypesObject, getDocDetails } from '../../../../utils/myFunctions';
import Pagination from '../../../InvoiceDiscounting/contract/components/pagination';
import { ToastContainer } from 'react-toastify';
import toastDisplay from '../../../../utils/toastNotification';
import ChatBoxPopUp2 from '../../../chatRoom/components/ChatBoxPopUp2';
import Filter from '../../../InvoiceDiscounting/components/Filter';
import NewTablev2 from '../../../../utils/newTablev2';
import { Action } from '../../../myCounterPartComp/action';


export const FinLCQuotes = ({ userTokenDetails, navToggleState }) => {

  const [tableData, setTableData] = useState([])
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10, singleApplication: true })
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [showLoader, setshowLoader] = useState(false)
  const [filterData, setFilterData] = useState({})
  const [action, setAction] = useState({ show: false, index: null })

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [applicationCounts, setApplicationCounts] = useState({})

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  useEffect(() => {
    fetchApplicationCounts()
  }, [])

  async function fetchApplicationCounts() {
    call('POST', 'getAppliedForLCLimitApplicationCounts', { userId }).then(res => setApplicationCounts(res))
  }

  useEffect(() => {
    //Get Buyer list start
    let objectAPI = {
      "userId": userId,
      "userTypeId": userTypeId,
      currentPage: page,
      "onlyAppliedForQuote": true,
      ...filter
    }
    setshowLoader(true)
    call('POST', 'getAppliedForLCLimit', objectAPI).then((result) => {
      console.log('running getAppliedForLCLimit api-->', result);
      setTableData(result.data)
      setCount(result.totalCount)
      setshowLoader(false)
    }).catch((e) => {
      // console.log('error in getAppliedForLCLimit', e);
      setshowLoader(false)
    });
  }, [refresh, filter]);

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('lcApplicationId', selectedChat.lcApplicationId)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))

    formdata.append("userTypeId", userTypeId)
    formdata.append("sellerId", selectedChat.sellerId)

    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  function formatDataForTable(data) {
    let tableData = []
    let row = [];
    try {
      data.forEach((item, index) => {
        let financierQuotes = item.financierQuotes ? JSON.parse(item.financierQuotes) : [];
        let isQuotationSent = false
        let isApproved = false
        let isMyQuoteSelected = item.selectedQuote ? JSON.parse(item.selectedQuote)["lender_id"] / 1 == userId / 1 : false
        let chatRoomIds = item.chatRoomIds?.split(",") || []
        let chatRoomUsers = item.chatRoomUsers?.split(",") || []
        let openChatRoomIndx = null
        chatRoomUsers.forEach((u, i) => {
          if (u?.split("::")[1] / 1 == userId / 1) {
            openChatRoomIndx = i
          }
        })
        let threeDaysFromCreationDate = moment(item.createdAt).add(14, "days")
        let daysLeft = threeDaysFromCreationDate.clone().diff(moment(), "days")

        financierQuotes.forEach((i, j) => {
          if (i.lender_id / 1 == userId / 1) {
            isQuotationSent = true
            if (i.status === "approved") {
              isApproved = true
            }
          }
        })


        row[0] = item.createdAt ? moment(item.createdAt).format('DD/MM/YYYY') : 'NA'
        row[1] = item.company_name || 'NA'
        row[2] = item.buyerName || 'NA'
        row[3] = item.lcNo || 'NA'
        row[4] = item.lcType ? LCTypesObject[item.lcType] : 'NA'
        row[5] = item.lcTenor || 'NA'

        row[6] = (daysLeft / 1 > 0 && !isQuotationSent) ? <p
          class={`text-success mb-0 font-size-13`}>
          {daysLeft + " days"} <img src={'/assets/images/hourglass-not-done.png'} alt='hourglass' />
        </p> : "-"



        row[7] = <div
          onClick={async () => {
            let reqObj = {
              userTypeId,
              senderId: userId,
              sellerId: item.createdBy,
              receiverId: userId,
              textMessage: 'Hii',
              chat_room_name: "CHAT" + new Date().getTime(),
              includeAdmins: true,
              lcApplicationId: item.id,
              receiverParties: userId,
              dontSendInitialMsg: true
            }
            setshowLoader(true)
            let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
            setshowLoader(false)
            getChatDetails({
              chat_room_id: apiResp.id,
              loggedInUser: userId
            })
            setSelectedChat({
              chatRoomId: apiResp.id,
              receiverName: `${chatRoomIds[openChatRoomIndx] || apiResp.id}`,
              lcApplicationId: item.id,
              parties: chatRoomUsers[openChatRoomIndx] || apiResp.parties,
              userId: userId,
              isChatOpen: true,
              receiverId: userId,
              sellerId: item.createdBy
            })
          }}
        >
          {openChatRoomIndx != null ? (item.lastMsg || "-") : "-"}
        </div>

        if (item.sblcApplicationId && !isMyQuoteSelected) {
          if (item.eximBankSignOnSblc) {
            row[99] = <p className="font-size-12 text-color-value ml-3 ">
              <img src={"assets/images/warning.png"} alt="info" className="" /> <span className="mr-2"><b>Contract signed by Exim bank!</b> </span>
            </p>
          }
          else if (item.termSheetRevertStatus != null && item.termSheetRevertStatus / 1 == 0) {
            row[99] = <p className="font-size-12 text-color-value ml-3 ">
              <img src={"assets/images/warning.png"} alt="info" className="" /> <span className="mr-2"><b>SBLC application has been rejected!</b> </span>
            </p>
          }
          else if (item.termSheetRevertStatus != null && item.termSheetRevertStatus / 1 == 1) {
            row[99] = <p className="font-size-12 text-color-value ml-3 ">
              <img src={"assets/images/warning.png"} alt="info" className="" /> <span className="mr-2"><b>Term sheet signed for SBLC, waiting for exim bank approval!</b> </span>
            </p>
          }
        }
        else {
          if (isMyQuoteSelected && !item.quoteLocked) {
            row[99] = <p className="font-size-12 text-color-value ml-3 ">
              <img src={"assets/images/warning.png"} alt="info" className="" /> <span className="mr-2"><b>Supplier requested to lock the deal</b> </span>
            </p>
          }
          else if (isMyQuoteSelected && item.quoteLocked && item.lcPurpose != "lc_discounting") {
            row[99] = <p className="font-size-12 text-color-value ml-3 ">
              <img src={"assets/images/warning.png"} alt="info" className="" /> <span className="mr-2"><b>Waiting for request letter of confirmation</b> </span>
            </p>
          }
        }


        tableData.push(row)
        row = []
      })
      return tableData

    } catch (error) {
      console.log("error in formatdatafortable", error);
    }
  }


  return (
    <>{showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {selectedChat.isChatOpen &&
        <div className="chatboxDivFixed">
          <ChatBoxPopUp2
            chatList={chatList}
            user_avatar={selectedChat.logo}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            parties={selectedChat.parties}
            userTokenDetails={userTokenDetails}
            onChatSend={(file, parties) => sendChatMsg(file, parties)}
            message={message}
            setTextMsg={setTextMsg}
            reloadChatList={() => getChatDetails({
              chat_room_id: selectedChat.chatRoomId,
              loggedInUser: userId
            })}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false,
                logo: ''
              })
              setRefresh(refresh + 1)
            }}
          />
        </div>
      }
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="LcDiscountingV2" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Letter of Credit - Quotes"}
              userTokenDetails={userTokenDetails} />

            <div className='d-flex row justify-content-between px-2' >
              <div className='card p-4 border1Blue' style={{ width: '40.22%' }} >
                <label className='font-size-15 font-wt-600 color0C0C0C' >Application in process - <label className='text-color1 font-size-15 font-wt-600' >{count || 0}</label></label>
                <div className='d-flex row' >
                  <div className='w-auto' >
                    <p className='font-size-22 font-wt-600 text-color1 p-0 m-0' >{applicationCounts.new || 0}</p>
                    <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0'>New</p>
                  </div>
                  <div className='w-auto' >
                    <p className='font-size-22 font-wt-600 text-color1 p-0 m-0' >{applicationCounts.quoteSent || 0}</p>
                    <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0'>Quote</p>
                  </div>
                  <div className='w-auto' >
                    <p className='font-size-22 font-wt-600 text-color1 p-0 m-0' >{applicationCounts.reqLetter || 0}</p>
                    <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0'>Request Letter</p>
                  </div>
                  <div className='w-auto' >
                    <p className='font-size-22 font-wt-600 text-color1 p-0 m-0' >{applicationCounts.confirmationSent || 0}</p>
                    <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0'>Confirmation Sent</p>
                  </div>
                </div>
              </div>
              <div
                className='card border1Blue pl-4 d-flex justify-content-center cursor' style={{ width: '14.44%' }} >
                <div>
                  <p className='font-size-22 font-wt-600 text-color1 p-0 m-0' >{applicationCounts.sblc || 0}</p>
                  <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0 mt-3'>SBLC</p>
                </div>
              </div>
              <div className='card p-4 border1Blue' style={{ width: '24.22%' }} >
                <label className='font-size-15 font-wt-600 color0C0C0C' >Rejected Application - <label className='text-danger font-size-15 font-wt-600' >{applicationCounts.rejected || 0}</label></label>
                <div className='d-flex row' >
                  <div className='w-auto' >
                    <p className='font-size-22 font-wt-600 text-danger p-0 m-0' >{applicationCounts.rejectedByYou || 0}</p>
                    <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0'>By You</p>
                  </div>
                  <div className='w-auto' >
                    <p className='font-size-22 font-wt-600 text-danger p-0 m-0' >{applicationCounts.rejectedBySupplier || 0}</p>
                    <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0'>By Supplier</p>
                  </div>
                </div>
              </div>
              <div
                className='card border1Blue pl-4 d-flex justify-content-center cursor' style={{ width: '14.44%' }} >
                <div>
                  <p className='font-size-22 font-wt-600 text-secondary p-0 m-0' >{applicationCounts.expired || 0}</p>
                  <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0 mt-3'>Expired</p>
                </div>
              </div>
            </div>

            <div className='filter-div position-relative'>
              <Filter singleMultApplication={true}
                filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} />
            </div>
            <div className='mt-4'>
              {/* <NewTable tableContainsNotification={true
              } disableAction={true}
                columns={[{ name: "Date", filter: true }, { name: "Supplier Name", filter: true }, { name: "Buyer Name", filter: true }, { name: "LC No.", filter: true }, { name: "LC Type", filter: true }, { name: "LC Tenor", filter: true },
                { name: "Time Left", filter: true }, { name: "Remark", filter: true },
                { name: "", filter: false }, { name: "", filter: false }
                ]}
                data={tableData}
              /> */}
              <NewTablev2
                columns={[{ subColumns: "Date", filter: true, subColumnStyle: { width: '8%' } },
                { subColumns: "Supplier Name", filter: true, subColumnStyle: { width: '11%' } },
                { subColumns: "Issuing Bank", filter: true, subColumnStyle: { width: '11%' } },
                { subColumns: "Issuing Country", filter: true, subColumnStyle: { width: '12%' } },
                { subColumns: "Limit Type", filter: true, subColumnStyle: { width: '12%' } },
                { subColumns: "LC Tenor", subColumnStyle: { width: '10%' } }, { subColumns: "Remark", subColumnStyle: { width: '18%' } },
                { subColumns: "Action", subColumnStyle: { width: '15%' } }, { subColumns: "", subColumnStyle: { width: '3%' } }
                ]}
              >
                {tableData.map((item, index) => {
                  let financierQuotes = item.financierQuotes ? JSON.parse(item.financierQuotes) : [];
                  let isQuotationSent = false
                  let isApproved = false
                  let isMyQuoteSelected = item.selectedQuote ? JSON.parse(item.selectedQuote)["lender_id"] / 1 == userId / 1 : false
                  let chatRoomIds = item.chatRoomIds?.split(",") || []
                  let chatRoomUsers = item.chatRoomUsers?.split(",") || []
                  let openChatRoomIndx = null
                  chatRoomUsers.forEach((u, i) => {
                    if (u?.split("::")[1] / 1 == userId / 1) {
                      openChatRoomIndx = i
                    }
                  })
                  financierQuotes.forEach((i, j) => {
                    if (i.lender_id / 1 == userId / 1) {
                      isQuotationSent = true
                      if (i.status === "approved") {
                        isApproved = true
                      }
                    }
                  })
                  let threeDaysFromCreationDate = moment(item.createdAt).add(14, "days")
                  let daysLeft = threeDaysFromCreationDate.clone().diff(moment(), "days")
                  let isTermSheetAvailableForMe = item.forwardTermsheetTo ? item.forwardTermsheetTo.includes(userId) : false
                  return (
                    <tr>
                      <td className='font-size-13 font-wt-500'>{item.createdAt ? moment(item.createdAt).format('DD/MM/YYYY') : 'NA'}</td>
                      <td className='font-size-13 font-wt-500'>{item.company_name || 'NA'}</td>
                      <td style={{ whiteSpace: 'pre-line' }} className='font-size-13 font-wt-500'>{item.lcIssuingBankName ?
                        filter.singleApplication ? item.lcIssuingBankName : item.lcIssuingBankName.split(",").map((i, j) => `${j + 1}. ${i}`).join("\n") : 'NA'}</td>
                      {/* <td style={{ whiteSpace: 'pre-line' }} className='font-size-13 font-wt-500'>{item.buyerName ?
                        filter.singleApplication ? item.buyerName : item.buyerName.split(",").map((i, j) => `${j + 1}. ${i}`).join("\n") : 'NA'}</td> */}
                      <td style={{ whiteSpace: 'pre-line' }} className='font-size-13 font-wt-500'>{item.countryOfOriginName ?
                        filter.singleApplication ? item.countryOfOriginName : item.countryOfOriginName.split(",").map((i, j) => `${j + 1}. ${i}`).join("\n") : 'NA'}</td>
                      <td style={{ whiteSpace: 'pre-line' }} className='font-size-13 font-wt-500'>{item.lcPurpose ?
                        filter.singleApplication ? LCPurposeObject[item.lcPurpose] : item.lcPurpose.split(",").map((i, j) => `${j + 1}. ${LCPurposeObject[i]}`).join("\n") : 'NA'}</td>
                      <td style={{ whiteSpace: 'pre-line' }} className='font-size-13 font-wt-500'>{item.lcTenor ?
                        filter.singleApplication ? (item.lcTenor + " Days") : item.lcTenor.split(",").map((i, j) => `${j + 1}. ${i} Days`).join("\n") : 'NA'}</td>
                      <td><div
                        onClick={async () => {
                          let reqObj = {
                            userTypeId,
                            senderId: userId,
                            sellerId: item.createdBy,
                            receiverId: userId,
                            textMessage: 'Hii',
                            chat_room_name: "CHAT" + new Date().getTime(),
                            includeAdmins: true,
                            lcApplicationId: item.id,
                            receiverParties: userId,
                            dontSendInitialMsg: true
                          }
                          setshowLoader(true)
                          let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
                          setshowLoader(false)
                          getChatDetails({
                            chat_room_id: apiResp.id,
                            loggedInUser: userId
                          })
                          setSelectedChat({
                            chatRoomId: apiResp.id,
                            receiverName: `${chatRoomIds[openChatRoomIndx] || apiResp.id}`,
                            lcApplicationId: item.id,
                            parties: chatRoomUsers[openChatRoomIndx] || apiResp.parties,
                            userId: userId,
                            isChatOpen: true,
                            receiverId: userId,
                            sellerId: item.createdBy
                          })
                        }}
                      >
                        {openChatRoomIndx != null ? (
                          <div className='d-flex' >
                            <p className={`font-size-12 font-wt-500 p-0 m-0 ${item.chatRoomUnreadMsgCount > 0 ? ' w-80' : ' w-100 '}`} >
                              {item.lastMsgTime ?
                                <label className='font-wt-700 w-100 p-0 m-0' >{moment(item.lastMsgTime).format("DD/MM/YYYY | HH:mm")}</label> : null}
                              <p className='ellipsis-container' >
                                <p className='mb-0 ellipsis-text' style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: item.lastMsg }}></p>
                              </p>
                            </p>
                            {item.chatRoomUnreadMsgCount > 0 ?
                              <label
                                className='lastMsgCount'
                              >{item.chatRoomUnreadMsgCount}</label> : null}
                          </div>) : "-"}
                      </div></td>
                      <td>{item.sblcApplicationId && !isMyQuoteSelected && isTermSheetAvailableForMe ?
                        isQuotationSent ? <div className='row '>
                          <a>
                            <button type="button"
                              onClick={() => {
                                window.location = `/finLCQuotesDetails`;
                                localStorage.setItem("applicationDetails", JSON.stringify({
                                  ...item, buyerCurrency: item.buyerCurrency ? item.buyerCurrency.split(":")[1] : null,
                                  lcType: LCTypesObject[item.lcType],
                                  lcPurpose: LCPurposeObject[item.lcPurpose],
                                  onlyView: true, hideApplyForSblc: true
                                }))
                              }}
                              className={`border-0 mb-2 enableQuotebtn text-white`}>
                              {"Quote Sent"}
                            </button>
                          </a>
                        </div> : item.eximBankSignOnSblc ? <div className='row '>
                          <a>
                            <button type="button"
                              onClick={() => {
                                window.location = `/finLCQuotesDetails`;
                                localStorage.setItem("applicationDetails", JSON.stringify({
                                  ...item, buyerCurrency: item.buyerCurrency ? item.buyerCurrency.split(":")[1] : null,
                                  lcType: LCTypesObject[item.lcType],
                                  lcPurpose: LCPurposeObject[item.lcPurpose],
                                  hideApplyForSblc: true
                                }))
                              }}
                              className={`border-0 mb-2 enableQuotebtn text-white`}>
                              {"Send Quote"}
                            </button>
                          </a>
                        </div> :
                          <div className='row '>
                            <a>
                              <button type="button"
                                disabled={item.termSheetRevertStatus != null}
                                onClick={() => {
                                  window.location = `/finLCQuotesDetails`;
                                  localStorage.setItem("applicationDetails", JSON.stringify({
                                    ...item, buyerCurrency: item.buyerCurrency ? item.buyerCurrency.split(":")[1] : null,
                                    lcType: LCTypesObject[item.lcType],
                                    lcPurpose: LCPurposeObject[item.lcPurpose], sblcAvailable: true
                                  }))
                                }}
                                className={`border-0 mb-2 ${item.termSheetRevertStatus != null ? ' disabledQuotebtn text-dark ' : ' enableQuotebtn text-white '} `}>
                                {"View Application"}
                              </button>
                            </a>
                          </div>
                        :
                        <div className='row '>{!isQuotationSent ? (
                          <a>
                            <button type="button"
                              disabled={daysLeft <= 0 && !userEmail.includes("demobank")}
                              onClick={() => {
                                window.location = `/finLCQuotesDetails`;
                                localStorage.setItem("applicationDetails", JSON.stringify({
                                  ...item, buyerCurrency: item.buyerCurrency ? item.buyerCurrency.split(":")[1] : null,
                                  lcType: LCTypesObject[item.lcType],
                                  lcPurpose: LCPurposeObject[item.lcPurpose]
                                }))
                              }}
                              className={`border-0 mb-2  ${(daysLeft <= 0 && !userEmail.includes("demobank")) ? 'disabledQuotebtn text-dark' : 'enableQuotebtn text-white'} `}>
                              {"View Application"}
                            </button>
                          </a>
                        ) : isApproved && !isMyQuoteSelected ?
                          <a>
                            <button type="button"
                              onClick={() => {
                                window.location = `/finLCQuotesDetails`;
                                localStorage.setItem("applicationDetails", JSON.stringify({
                                  ...item, buyerCurrency: item.buyerCurrency ? item.buyerCurrency.split(":")[1] : null,
                                  lcType: LCTypesObject[item.lcType],
                                  lcPurpose: LCPurposeObject[item.lcPurpose],
                                  onlyView: true, hideApplyForSblc: true
                                }))
                              }}
                              className={`border-0 mb-2 enableQuotebtn text-white`}>
                              {"Quote Sent"}
                            </button>
                            <p className="font-size-12 text-color-value p-0 m-0 ml-3 mt-2">
                              <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-600">Quote sent waiting for supplier's response</span>
                            </p>
                          </a>
                          : (isMyQuoteSelected) ? (
                            <a>
                              <button type="button"
                                onClick={() => {
                                  window.location = `/viewRequestLetter`;
                                  localStorage.setItem("applicationDetails", JSON.stringify({
                                    ...item, buyerCurrency: item?.buyerCurrency?.split(":")[1],
                                    lcPurpose: LCPurposeObject[item.lcPurpose],
                                    lcType: LCTypesObject[item.lcType],
                                    onlyView: item.isReadyForContract / 1 != 0 ? true : false
                                  }))
                                }}
                                disabled={(!item.lcPurpose.includes("discounting") && !item.reqLetterOfConfirmation) || item.isReadyForContract}
                                className={` border-0 mb-2 text-white ${item.isReadyForContract == 2 ? "rejectedQuotebtn" :
                                  (!item.lcPurpose.includes("discounting") && !item.reqLetterOfConfirmation) || item.isReadyForContract ? " disabledQuotebtn text-dark " : "enableQuotebtn2"} `}>
                                {item.isReadyForContract == 2 ? "Application Rejected" : (
                                  item.lcPurpose.includes("discounting") ? "Send Contract" :
                                    "View Request Letter")}
                              </button>
                              {item.lcPurpose.includes("discounting") && !item.isReadyForContract ?
                                <p className="font-size-12 text-color-value p-0 m-0 ml-3 mt-2">
                                  <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-600">Quote selected by user, you can send contract now</span>
                                </p> : item.isReadyForContract ?
                                  <p className="font-size-12 text-color-value p-0 m-0 ml-3 mt-2">
                                    <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-600">Contract sent, waiting for supplier's response</span>
                                  </p> :
                                  !item.lcPurpose.includes("discounting") && !item.reqLetterOfConfirmation ?
                                    <p className="font-size-12 text-color-value p-0 m-0 ml-3 mt-2">
                                      <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-600">Waiting for supplier to send request letter</span>
                                    </p>
                                    :
                                    <p className="font-size-12 text-color-value p-0 m-0 ml-3 mt-2">
                                      <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-600">Request letter sent by supplier</span>
                                    </p>
                              }
                            </a>
                            // <a>
                            //   <button type="button"
                            //     onClick={() => {
                            //       setshowLoader(true)
                            //       call('POST', 'lockLCQuote', { quoteId: item.id, lcNo: item.lcNo, userId }).then((result) => {
                            //         console.log('running lockLCQuote api-->', result);
                            //         toastDisplay(result, "success", () => { window.location.reload() })
                            //         setshowLoader(false)
                            //       }).catch((e) => {
                            //         setshowLoader(false)
                            //       });
                            //     }}
                            //     className={`border-0 mb-2 ${!item.quoteLocked ? "enableQuotebtn" : "approvedQuotebtn"} text-white `}>
                            //     {!item.quoteLocked ? "Lock Deal" : "Deal Locked"}
                            //   </button>
                            // </a>
                          ) : (
                            <a>
                              <button type="button"
                                onClick={() => {
                                  window.location = `/finLCQuotesDetails`;
                                  localStorage.setItem("applicationDetails", JSON.stringify({
                                    ...item, buyerCurrency: item.buyerCurrency ? item.buyerCurrency.split(":")[1] : null,
                                    lcType: LCTypesObject[item.lcType],
                                    lcPurpose: LCPurposeObject[item.lcPurpose]
                                  }))
                                }}
                                className={`border-0 mb-2 text-white rejectedQuotebtn`}>
                                {"Limit Denied"}
                              </button>
                            </a>
                          )}</div>
                      }</td>
                      <td
                        style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
                        className='position-relative'>
                        <i className="fa fa-ellipsis-v cursor mt-2 ml-2"
                          onClick={() => setAction({ show: true, index })}
                          aria-hidden="true"></i>
                        {action.show && action.index === index ? (
                          <Action
                            id={index}
                            onDismiss={() => setAction({ show: false, index })}
                            options={[
                              {
                                name: "Chat With Supplier", icon: "",
                                onClick: async () => {
                                  let reqObj = {
                                    userTypeId,
                                    senderId: userId,
                                    sellerId: item.createdBy,
                                    receiverId: userId,
                                    textMessage: 'Hii',
                                    chat_room_name: "CHAT" + new Date().getTime(),
                                    includeAdmins: true,
                                    lcApplicationId: item.id,
                                    receiverParties: userId,
                                    dontSendInitialMsg: true
                                  }
                                  setshowLoader(true)
                                  let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
                                  setshowLoader(false)
                                  getChatDetails({
                                    chat_room_id: apiResp.id,
                                    loggedInUser: userId
                                  })
                                  setSelectedChat({
                                    chatRoomId: apiResp.id,
                                    receiverName: `${chatRoomIds[openChatRoomIndx] || apiResp.id}`,
                                    lcApplicationId: item.id,
                                    parties: chatRoomUsers[openChatRoomIndx] || apiResp.parties,
                                    userId: userId,
                                    isChatOpen: true,
                                    receiverId: userId,
                                    sellerId: item.createdBy
                                  })
                                }
                              }
                            ]} />
                        ) : null}
                      </td>
                    </tr>
                  )
                })}
              </NewTablev2>
              <Pagination page={page} totalCount={count} onPageChange={(p) => setPage(p)} perPage={filter.resultPerPage || 0} />
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(FinLCQuotes)