//----------------------------------------------------------------------
// Components Import
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";

import Login from './components/homeComponents/login.js';
import Logout from './components/homeComponents/logout.js';
import ChannelPartner from './components/homeComponents/channelPartner.js';
import shipment from './components/logistics/shipmentV2.js';
import ContractGrid from './components/contractGrid/contractList.js';
import Payment from './components/payments/payment.js';
import Tracking from './components/contractGrid/tracking.js';
import Admin from './components/admin/adminMain.js';
import ManageProfile from './components/admin/manageProfile.js';
import CommoditiesList from './components/commodityFormandMaster/commoditiesList.js';
import AllNotification from './components/notifications/allNotifications.js';
import AllCreditLines from './components/creditLineComp/CreditLines.js';
import AllCounterPart from './components/myCounterPartComp/myCounterPart.js';
import AllBuyerLines from './components/buyerLineComp/buyerLine.js';
import ContractDoc from './components/contractDetails/contractDetails.js';
import Land from './components/homeComponents/land.js';
import Ships from './components/ships/ships.js';
import quotationContract from './components/logistics/openQuotation.js';
import quotationslist from './components/logistics/quotationslist.js';
import Registration from './components/registration/registration.js';
import EditProfile from './components/userProfile/editProfile.js';
import Finance from './components/finance/financeGrid.js';
import Dashboard from './components/Dashboard/index.js';
import ShowPlans from './components/subscriptionPlans/showPlans.js';
import BuyPlans from './components/subscriptionPlans/buyPlans.js';
import SubscriptionGrid from './components/admin/subscriptionGrid.js';
import UserDirectory from './components/userDirectory/userDirectory.js';
import ContractDirectoryGrid from './components/contractDirectory/contractDirectoryGrid.js';
import ChangePassword from './components/userProfile/updatePassword.js';
import CreditLineUsers from './components/creditLineComp/creditLineUsers.js';
import Setting from './components/settingComp/eligibleCriteria.js';
import ManageProduct from './components/manageProductComp/manageProduct.js';
import RateCalculator from './components/rateCalculatorComp/rateCalculator.js';
import FinanceRequest from './components/finance/financeRequest.js';
import Reports from './components/reportsComp/report.js';
import UserDetailedList from './components/userProfile/userDetailedList.js';
import DocumentView from './components/utilComponents/documentView.js';
import InsuranceCasesList from './components/insurance/insuranceCases';
import ChannelPartnerAgreement from './components/channelPartner/channelPartnerAgreement.js';
//----------------------------------------------------------------------

//----------------------------------------------------------------------
// Functions Import
import { getUserDataFromCookie, removeCookieandAvatar } from './utils/cookieHelper';
import { initSocket } from './socket'
import inactivityWatcher from './utils/inactivityWatcher';
import POGrid from './components/purchaseOrder/poGrid.js';
import RateDashboard from './components/ships/rateList/rateDashboard.js';
import LCFastDashboard from './components/lcFast/lcFastDashboard.js';
import MarketPlace from './components/marketplace/Marketplace.js';
import MyBookingsDashboard from './components/ships/marketPlaceBookings/myBookingsDashboard.js';
import ChannelPartnerUserList from './components/channelPartner/channelPartnerUserList.js';
import ChannelPartnerList from './components/channelPartner/channelPartnerList.js';
import ShipmentQuotationsDashboard from './components/ships/marketPlaceBookings/shipmentQuotations/shipmentQuotationsDashboard.js';
import quotationslistV2 from './components/logistics/quotationslistV2.js';
import reportForFinancier from './components/reportsComp/reportForFinancier.js';
import AccountList from './components/channelPartner/accountList.js';
import DisbursedInvoice from './components/channelPartner/disbursedInvoices.js';
import RaisedInvoice from './components/channelPartner/raisedInvoices.js';
import markeplaceQuotationDashboard from './components/logistics/markeplaceQuotationDashboard.js';
import FinanceDetailView from './components/finance/financeDetailView.js';
import RefLink from './components/homeComponents/refLink.js';
import chatRooms from './components/chatRooms/chatRooms.js';
import rateManagement from './components/ships/freightForwarders/rateManagement.js';
import uploadDumpRates from './components/ships/rateList/uploadDumpRates.js';
import financeCalculator from './components/rateCalculatorComp/financeCalculator.js';
import InterestRates from './components/rateCalculatorComp/interestRates.js';
import NewRegistration from './components/registration/newRegistration.js';
import NewLogin from './components/registration/newLogin.js';
import lcFastDashboardV2 from './components/lcFast/lcFastDashboardV2.js';
import { HandleRedirect } from './components/handleRedirects.js';
import DashboardV2 from './components/Dashboard/DashboardV2.js';
import buyerManagement from './components/myCounterPartComp/buyerManagement.js';
import Invoice from './components/InvoiceDiscounting/Invoice.js';
import ApplyLimitComponent from './components/InvoiceDiscounting/applyLimitComponent.js';
import Quotes from "./components/InvoiceDiscounting/quotes.js"
import seeQuotesDetails from './components/InvoiceDiscounting/components/seeQuotesDetails';
import Contract from "./components/InvoiceDiscounting/contract/contract.js"
import signContract from './components/InvoiceDiscounting/contract/components/signContract';
import ApplyForFinance from './components/InvoiceDiscounting/applyForFinance/applyForFinance';
import ApplyFinancebtn from "./components/InvoiceDiscounting/applyForFinance/components/applyFinancebtn.js";
import ApprovedFinance from "./components/InvoiceDiscounting/approvedFinance/approvedFinance.js";
import ViewInvoiceDetails from "./components/InvoiceDiscounting/approvedFinance/viewDetails.js";
import ApplyforLimit from "./components/lcV2/applyforLimit/applyforLimit.js"
import quotes from './components/lcV2/qoutes/quotes';
import contract from './components/lcV2/contract/contract';
import approvedFinance from './components/lcV2/approvedFinance/approvedFinance';
import applyforFinance from './components/lcV2/applyforFinance/applyforFinance';
import amendment from './components/lcV2/amendment/amendment';
import Lcdiscountingcard from './components/lcV2/applyforLimit/components/lcdiscountingcard.js';
import finInvoiceQuotes from './components/InvoiceDiscounting/financier/quotesMenu/quotes.js';
import finQuotesDetails from './components/InvoiceDiscounting/financier/quotesMenu/viewDetails.js';
import termSheet from './components/InvoiceDiscounting/financier/sendTermSheet/termSheet.js';
import sendTermSheet from './components/InvoiceDiscounting/financier/sendTermSheet/sendTermSheet.js';
import signTermSheet from './components/InvoiceDiscounting/financier/signTermSheet/termSheet.js';
import viewSignTermSheet from './components/InvoiceDiscounting/financier/signTermSheet/signTermSheet.js';
import financeApplication from './components/InvoiceDiscounting/financier/financeApplication/financeApplication.js';
import viewFinanceApplication from './components/InvoiceDiscounting/financier/financeApplication/viewFinanceApp.js';
import disbursement from './components/InvoiceDiscounting/financier/disbursement/disbursement.js';
import disbursementDetails from './components/InvoiceDiscounting/financier/disbursement/disbursementDetails.js';
import lcSeequotes from './components/lcV2/qoutes/components/lcSeequotes';
import applyforSblc from './components/lcV2/qoutes/components/applyforSblc';
import InvoiceAgreement from './components/InvoiceDiscounting/applyForFinance/invoiceAgreement';
import signAgreement from './components/InvoiceDiscounting/applyForFinance/signAgreement';
import lcSignContract from './components/lcV2/contract/components/lcSignContract';
import LcApplyFinanceBtn from './components/lcV2/applyforFinance/components/lcApplyfinancebtn.js';
import amendApplication from './components/lcV2/amendment/components/amendApplication.js';
import quickFinance from './components/quickFinance/quickFinance';
import ammendLC from './components/lcV2/applyforFinance/components/ammendLC';
import wallet from './components/wallet/wallet';
import pricing from './components/wallet/components/pricing';
import viewDetails from './components/wallet/components/viewDetails';
import supplierList from './components/myCounterPartComp/supplierList';
import finLCQuotes from './components/lcV2/financier/quotesMenu/quotes.js'
import finLCQuotesDetails from './components/lcV2/financier/quotesMenu/viewDetails.js';
import lcRequestLetter from './components/lcV2/financier/reQuestLetter/requestLetter.js';
import viewRequestLetter from './components/lcV2/financier/reQuestLetter/viewRequestLetter.js';
import sendLC from './components/lcV2/financier/sendLC/sendLC.js';
import viewSendLC from './components/lcV2/financier/sendLC/viewSendLC';
import lcFinApplication from './components/lcV2/financier/lcFinApplication/lcFinApplication.js';
import viewLCFinApplication from './components/lcV2/financier/lcFinApplication/viewLCFinApplication.js';
import viewProfile from './components/viewProfile/viewProfile';
import ChatRoomV2 from './components/chatRoom/chatRoom'
import amendmentRequest from './components/lcV2/applyforFinance/components/amendmentRequest';
import amendmentDetails from './components/InvoiceDiscounting/applyForFinance/components/amendmentDetails';
import DocsRequested from './components/lcV2/qoutes/components/docsRequested';
import supplierDetails from './components/myCounterPartComp/supplierDetails';
import sblcQuotations from './components/lcV2/applyforFinance/components/sblcQuotations';
import sblcQuotationDetails from './components/lcV2/applyforFinance/components/sblcQuotationDetails';
import signEximBankTermSheet from './components/lcV2/financier/quotesMenu/signEximBankTermSheet';
import './firebase.js'
// Import the functions you need from the SDKs you need
import sblcTermSheet from './components/lcV2/applyforFinance/components/sblcTermSheet';
import walletV2 from './components/wallet/walletV2';
import reportsv2 from './components/Reports/reportsv2';
import helpSupport from './components/helpSupport/helpSupport';
import allNotifications from './components/allNotifications/allNotifications';
import PaymentScreen from './components/wallet/PaymentScreen';
import FailedPayment from './components/wallet/FailedPayment';
import SuccessPayment from './components/wallet/SuccessPayment';
import { DisbursementV2 } from './components/InvoiceDiscounting/financier/disbursement/disbursementV2';
import PageNotFound from './components/pageNotFound';
import toastDisplay from './utils/toastNotification';
import { ToastContainer } from 'react-toastify';
import InvoiceLimit from './components/adminNewUI/InvoiceLimit/InvoiceLimit';
import InvoiceFinance from './components/adminNewUI/InvoiceFinance/InvoiceFinance';
import InvoiceApprovedFinance from './components/adminNewUI/InvoiceApprovedFinance/InvoiceApprovedFinance';
import UserOnboard from './components/UserOnboard/UserOnboard';
import LCLimit from './components/adminNewUI/letterOfCredit/LCLimit';
import AdminPayments from './components/adminNewUI/AdminPayments';
import TaskManager from './components/TaskManager/TaskManager';
import SubAdminProfileDetails from './components/adminNewUI/SubAdminProfile/SubAdminProfileDetails';
import PayUPaymentScreen from './components/wallet/PayUPaymentScreen';
import applyMultFinanceBtn from './components/InvoiceDiscounting/applyForFinance/components/applyMultFinanceBtn';
import EnquiryList from './components/TaskManager/EnquiryList';
import CallList from './components/TaskManager/CallList';
import LeadsComponent from './components/CRM/LeadsComponent';
import CRMDataComponent from './components/CRM/CRMDataComponent';
import CRMMasterData from './components/CRM/CRMMasterData';
import InvoiceGenerateFinancier from './components/InvoiceGeneration/InvoiceGenerateFinancier';
import InvoiceGenerateCP from './components/InvoiceGeneration/InvoiceGenerateCP';
import AdminReports from './components/Reports/AdminReports';
import CRMUserProfile from './components/CRM/CRMUserProfile';
import CRMAssignScreen from './components/CRM/CRMAssignScreen';
import TTVBuyerDetail from './components/myCounterPartComp/TTVBuyerDetail';
import Corporate from './components/TaskManager/Corporate';
import Financer from './components/TaskManager/Financer';
import ApplicationForm from './components/TaskManager/ApplicationForm';
import AddFinancer from './components/TaskManager/AddFinancer';
import TradeDiscovery from './components/myCounterPartComp/TradeDiscovery';
import SubAdminTab from './components/adminNewUI/SubAdminProfile/SubAdminTab';
import ExtraDetailsForLimitApplication from './components/InvoiceDiscounting/extraDetailsForLimitApplication';
import BuyerCreditCheck from './components/underWriting/BuyerCreditCheck';
import SupplierCreditCheck from './components/underWriting/SupplierCreditCheck';
import applyLimitComponentV2 from './components/InvoiceDiscounting/applyLimitComponentV2';
import lcdiscountingcardV2 from './components/lcV2/applyforLimit/components/lcdiscountingcardV2';
import InvoiceApprovedFinance2 from './components/adminNewUI/InvoiceApprovedFinance/InvoiceApprovedFinance2';
import BGGetConfirmation from './components/bankGuarantee/BGGetConfirmation';
import BGQuote from './components/bankGuarantee/BGQuote';
import BGQuoteDetails from './components/bankGuarantee/BGQuoteDetails';
import BGSignTermSheet from './components/bankGuarantee/BGSignTermSheet';
import BGConfirmed from './components/bankGuarantee/BGConfirmed';
import WCApplyLimit from './components/workingCapital/WCApplyLimit';
import WCQuote from './components/workingCapital/WCQuote';
import BGFinancierQuote from './components/bankGuarantee/BGFinancierQuote';
import BGFinancierSendQuote from './components/bankGuarantee/BGFinancierSendQuote';
import BGFinancierSendTermSheet from './components/bankGuarantee/BGFinancierSendTermSheet';
import BGConfirmedFinancier from './components/bankGuarantee/BGConfirmedFinancier';
import WCQuoteDetails from './components/workingCapital/WCQuoteDetails';
import WCSignTermSheet from './components/workingCapital/WCSignTermSheet';
import WCFinancierQuote from './components/workingCapital/WCFinancierQuote';
import WCFinancierSendQuote from './components/workingCapital/WCFinancierSendQuote';
import WCFinancierSendTermSheet from './components/workingCapital/WCFinancierSendTermSheet';
import ApplyLimitSCF from './components/SupplyChainFinance/ApplyLimitSCF';
import SCFQuote from './components/SupplyChainFinance/SCFQuote';
import SCFApprovedLimit from './components/SupplyChainFinance/SCFApprovedLimit';
import ApplyFinanceDID from './components/DomesticInvoiceDiscounting/ApplyFinanceDID';
import ApplyLimitCGTMSE from './components/CGTMSE/ApplyLimitCGTMSE';
import CGTMSEQuote from './components/CGTMSE/CGTMSEQuote';
import CGTMSEQuoteDetails from './components/CGTMSE/CGTMSEQuoteDetails';
import CGTMSESignTermSheet from './components/CGTMSE/CGTMSESignTermSheet';
import CGTMSEFinancierSendQuote from './components/CGTMSE/CGTMSEFinancierSendQuote';
import CGTMSEFinancierSendTermSheet from './components/CGTMSE/CGTMSEFinancierSendTermSheet';
import { CGTMSEFinancierQuote } from './components/CGTMSE/CGTMSEFinancierQuote';
import SCFQuoteDetails from './components/SupplyChainFinance/SCFQuoteDetails';
import SCFFinancierQuote from './components/SupplyChainFinance/SCFFinancierQuote';
import SCFFinancierSendQuote from './components/SupplyChainFinance/SCFFinancierSendQuote';
import SCFFinancierSendTermsheet from './components/SupplyChainFinance/SCFFinancierSendTermsheet';
import SCFSignTermsheet from './components/SupplyChainFinance/SCFSignTermsheet';
import SCFForwardApplication from './components/SupplyChainFinance/SCFForwardApplication';
import call from './service';
import store from './store';
import { setNavbarConfiguration } from './store/actions/action';
import { useSelector } from 'react-redux';
import { FinanciersNavConfig, buyersNavConfig, exportersNavConfig, AdminConfig, importerExporterConfig, shipperConfig } from './utils/myFunctions';
import DocVaultTransaction from './components/documentVault/DocVaultTransaction';
import DocVaultTransactionDetails from './components/documentVault/DocVaultTransactionDetails';
import TradeCreditInsuarance from './components/InsuaranceNew/TradeCreditInsuarance';
import TCISingleBuyer from './components/InsuaranceNew/TCISingleBuyer';
import TCITransactional from './components/InsuaranceNew/TCITransactional';
import TCIWholeTO from './components/InsuaranceNew/TCIWholeTO';
import TCIKeyAccounts from './components/InsuaranceNew/TCIKeyAccounts';
import InvoiceAgreementV2 from './components/InvoiceDiscounting/applyForFinance/InvoiceAgreementV2';
import SignAgreementV2 from './components/InvoiceDiscounting/applyForFinance/SignAgreementV2';
import WorkOrderContract from './components/contractManagement/WorkOrderContract.js';
import CreateWorkOrderContract from './components/contractManagement/CreateWorkOrderContract.js';
import WorkOrderContractDetails from './components/contractManagement/WorkOrderContractDetails.js';
import CommoditiesListV2 from './components/commodityFormandMaster/CommoditiesListV2.js';
import MyNetwork from './components/networkManagement/MyNetwork.js';
import ManageNetwork from './components/networkManagement/ManageNetwork.js';
import plan from './components/subscription/plan.js';
import NetworkRequests from './components/networkManagement/NetworkRequests.js';
import BlockedUsers from './components/networkManagement/BlockedUsers.js';
import ManagePlan from './components/subscription/ManagePlan.js';
import ApplyForBooking from './components/ShipmentBooking/ApplyForBooking.js';
import ShipmentBookingQuotes from './components/ShipmentBooking/ShipmentBookingQuotes.js';
import ShipmentQuoteDetails from './components/ShipmentBooking/ShipmentQuoteDetails.js';
import ShipmentFinancierQuote from './components/ShipmentBooking/ShipmentFinancierQuote.js';
import ShipmentFinancierSendQuote from './components/ShipmentBooking/shipmentFinancierQuoteDetails.js';
import ShipmentBookingContract from './components/ShipmentBooking/ShipmentBookingContract.js';
import ShipmentBookingContractDetails from './components/ShipmentBooking/ShipmentBookingContractDetails.js';
import { ShipmentBookingFinancierContract } from './components/ShipmentBooking/ShipmentFinancierContract.js';
import ShipmentBookingFinancierContractDetails from './components/ShipmentBooking/ShipmentFinancierContractDetails.js'
import NotificationList from './components/settings/NotificationList.js';
import NotificationSettings from './components/settings/NotificationSettings.js';
import ShipmentBookingMarketPlace from './components/ShipmentBooking/ShipmentBookingMarketPlace.js';
import DashboardV4 from './components/Dashboard/DashboardV4.js';

//----------------------------------------------------------------------

//----------------------------------------------------------------------
// get token and user details. Init scoket
let socket
const userDetails = getUserDataFromCookie()
userDetails.aclMap = userDetails.aclMap ? JSON.parse(userDetails.aclMap) : {}
const token = userDetails.token ? userDetails.token : "";
const hasPlan = userDetails.has_plan === 1 ? true : userDetails.has_plan === 0 ? false : "NLI";
const userTypeId = userDetails.type_id ? userDetails.type_id : null
const userPermissions = userDetails.UserAccessPermission ? Object.values(JSON.parse(userDetails.UserAccessPermission)).join(",").split(",") : []
const sub_user_id = userDetails.sub_user_id ? userDetails.sub_user_id : 0
const userId = userDetails.user_id ? userDetails.user_id : null


console.log("token===================>", token);

// if (token && !socket) {
//   initSocket(token).then((socketObj) => {
//     socket = socketObj
//     // console.log("test socket in app.js-->", socketObj)
//   })
// }
//----------------------------------------------------------------------


//------------------------------------------------------------------------------------------------
// App function
function App() {
  const items = useSelector(state => state.NavConfiguration.navbarItems)

  let onlyAllowedUrl = localStorage.getItem("onlyAllowedUrl");
  console.log(onlyAllowedUrl);
  if (onlyAllowedUrl && !window.location.href.includes(onlyAllowedUrl)) {
    removeCookieandAvatar()
    localStorage.clear()
    window.location.reload()
  }

  console.log("userTokenDetails=======>", userDetails)

  // Defination of private route
  const PrivateRoute = ({ component: Component, ...props }) => {
    let isAccess = true
    if (props.path.split("/")[1] == 'dashboard') {
      isAccess = true
    } else if (sub_user_id === userId) {
      isAccess = true
    } else if (sub_user_id !== 0 && sub_user_id !== null) {
      if (userPermissions.includes(props.path.split("/")[1])) {
        isAccess = true
      } else {
        isAccess = false
      }
    }

    if (isAccess) {
      return (
        <Route
          {...props}
          render={
            innerProps =>
              (token ? <Component userTokenDetails={userDetails} {...innerProps} /> : <Redirect to="/login" />)
          }
        />
      );
    } else {
      return <Route
        {...props}
        render={
          innerProps =>
            (token ? <Redirect to="/dashboard" /> : <Redirect to="/login" />)
        }
      />
    }

  };

  if (token) {
    // Initiate inactivity watcher if user is logged in
    inactivityWatcher()
  }
  useEffect(() => {
    if (userId) {
      call('POST', 'getNavBarConfig', { userId }).then(result => {
        store.dispatch(setNavbarConfiguration(result))
      }).catch(e => {
        if (userTypeId / 1 == 3 || userTypeId / 1 == 4 || userTypeId / 1 == 14) {
          store.dispatch(setNavbarConfiguration(importerExporterConfig))
        }
        if (userTypeId / 1 == 5) {
          store.dispatch(setNavbarConfiguration(shipperConfig))
        }
        // if (userTypeId === 19 || userTypeId === 21) {
        //   store.dispatch(setNavbarConfiguration(exportersNavConfig))
        // }
        // if (userTypeId === 8 || userTypeId == 22) {
        //   store.dispatch(setNavbarConfiguration(FinanciersNavConfig))
        // }
        if (userTypeId === 1) {
          store.dispatch(setNavbarConfiguration(AdminConfig))
        }
        // if (userTypeId === 3) {
        //   store.dispatch(setNavbarConfiguration(buyersNavConfig))
        // }
      })
    }
  }, [])
  useEffect(() => {
    if (userId) {
      call('POST', 'setNavBarConfig', { userId, details: JSON.stringify(items) }).then(result => {
      }).catch(e => {
      })
    }
  }, [items])
  return (
    <Router >
      {/* <Route component={PageNotFound} /> */}
      <Route path="/" exact component={NewLogin} />
      <Route path="/login" exact component={NewLogin} />
      {/* <Route path="/login" exact component={Login} /> */}
      <Route path="/logout" exact component={Logout} />
      <Route path="/registration" exact component={NewRegistration} />
      {/* <Route path="/channelPartner" exact component={ChannelPartner} /> */}
      {/* <Route path="/showplans" exact component={ShowPlans} /> */}
      {/* <Route path="/channelPartnerAgreement" exact component={ChannelPartnerAgreement} /> */}
      <Route path="/financeDetailView" exact component={!userDetails.token ? Login : FinanceDetailView} />
      <Route path="/refLink" exact component={RefLink} />
      {/* <Route path="/uploadShippingRatesData" exact component={uploadDumpRates} /> */}
      <Route path="/redirect" exact component={HandleRedirect} />

      {/* <PrivateRoute path="/buyplans" exact component={BuyPlans} /> */}
      {/* <PrivateRoute path="/editprofile" exact component={EditProfile} /> */}
      {/* <PrivateRoute path="/contract" exact component={ContractGrid} /> */}
      {/* <PrivateRoute path="/shipment" exact component={shipment} /> */}
      <PrivateRoute path={userTypeId / 1 === 1 ? "/users" : "/network"} exact component={Admin} />
      {/* <PrivateRoute path="/manageprofile" exact component={ManageProfile} /> */}
      {/* <PrivateRoute path="/commoditieslist" exact component={CommoditiesList} /> */}
      {/* <PrivateRoute path="/notifications" exact component={AllNotification} /> */}
      {/* <PrivateRoute path="/creditLine" exact component={AllCreditLines} /> */}
      {/* <PrivateRoute path="/counterPart" exact component={AllCounterPart} /> */}
      {/* <PrivateRoute path="/buyerline" exact component={AllBuyerLines} /> */}
      {/* <PrivateRoute path="/contractdoc" exact component={ContractDoc} /> */}
      {/* <PrivateRoute path="/tracking" exact component={Tracking} /> */}
      {/* <PrivateRoute path="/payment" exact component={Payment} /> */}
      {/* <PrivateRoute path="/ships" exact component={Ships} /> */}
      {/* <PrivateRoute path="/quotation" exact component={quotationContract} /> */}
      {/* <PrivateRoute path="/quotationlist" exact component={quotationslist} /> */}
      {/* <PrivateRoute path="/finance" exact component={Finance} /> */}
      {/* <PrivateRoute path="/po" exact component={POGrid} /> */}
      {/* <PrivateRoute path="/rate-list" exact component={RateDashboard} /> */}
      {/* <PrivateRoute path="/rate-management" exact component={rateManagement} /> */}
      {/* <PrivateRoute path="/marketplace-shipment-quotations" exact component={ShipmentQuotationsDashboard} /> */}
      {/* <PrivateRoute path="/my-quotations" exact component={markeplaceQuotationDashboard} /> */}
      {/* <PrivateRoute path="/lc-fast" exact component={lcFastDashboardV2} /> */}
      {/* <PrivateRoute path="/marketplace" exact component={MarketPlace} /> */}
      {/* <PrivateRoute path="/dashboard" exact component={Dashboard} /> */}
      {/* <PrivateRoute path="/planpayments" exact component={SubscriptionGrid} /> */}
      {/* <PrivateRoute path="/userdirectory" exact component={UserDirectory} /> */}
      {/* <PrivateRoute path="/contractdirectory" exact component={ContractDirectoryGrid} /> */}
      {/* <PrivateRoute path="/changepassword" exact component={ChangePassword} /> */}
      {/* <PrivateRoute path="/creditlineview" exact component={CreditLineUsers} /> */}
      {/* <PrivateRoute path="/reports" exact component={userTypeId === 8 ? reportForFinancier : Reports} /> */}
      {/* <PrivateRoute path="/setting" exact component={Setting} /> */}
      {/* <PrivateRoute path="/product" exact component={ManageProduct} /> */}
      {/* <PrivateRoute path="/rateCalculator" exact component={RateCalculator} /> */}
      {/* <PrivateRoute path="/financeCalculator" exact component={financeCalculator} /> */}
      {/* <PrivateRoute path="/interestRates" exact component={InterestRates} /> */}
      {/* <PrivateRoute path="/financerequest" exact component={FinanceRequest} /> */}
      {/* <PrivateRoute path="/userDetailedList" exact component={UserDetailedList} /> */}
      {/* <PrivateRoute path="/documentView" exact component={DocumentView} /> */}
      {/* <PrivateRoute path="/cases" exact component={InsuranceCasesList} /> */}
      {/* <PrivateRoute path="/userdetails" exact component={ChannelPartnerUserList} /> */}
      {/* <PrivateRoute path="/channelUsers" exact component={ChannelPartnerList} /> */}
      {/* <PrivateRoute path="/accountlist" exact component={AccountList} /> */}
      {/* <PrivateRoute path="/disbursedinvoice" exact component={DisbursedInvoice} /> */}
      {/* <PrivateRoute path="/raisedinvoice" exact component={RaisedInvoice} /> */}
      {/* <PrivateRoute path="/chatRoom" exact component={chatRooms} /> */}


      <PrivateRoute path="/dashboard" exact component={DashboardV4} />
      <PrivateRoute path="/buyerManagement" exact component={buyerManagement} />
      <PrivateRoute path="/supplierList" exact component={supplierList} />

      <PrivateRoute path="/invoiceDiscouting" exact component={Invoice} />
      <PrivateRoute path="/applyLimit" exact component={ApplyLimitComponent} />
      <PrivateRoute path="/applyLimitV2" exact component={applyLimitComponentV2} />
      <PrivateRoute path="/Quotes" exact component={Quotes} />
      <PrivateRoute path="/seequotes" exact component={seeQuotesDetails} />
      <PrivateRoute path="/invoicecontract" exact component={Contract} />
      <PrivateRoute path="/SignContract" exact component={signContract} />
      <PrivateRoute path="/apply-finance" exact component={ApplyForFinance} />
      <PrivateRoute path="/apply-invoice" exact component={ApplyFinancebtn} />
      <PrivateRoute path="/apply-invoice-multiple" exact component={applyMultFinanceBtn} />
      <PrivateRoute path="/invoice-agreement" exact component={InvoiceAgreement} />
      <Route path="/sign-agreement" exact component={signAgreement} />
      <PrivateRoute path="/approvedFinance" exact component={InvoiceApprovedFinance2} />
      <PrivateRoute path="/viewDetails" exact component={ViewInvoiceDetails} />

      <PrivateRoute path="/applyForLimit" exact component={ApplyforLimit} />
      <PrivateRoute path="/LcQuote" exact component={quotes} />
      <PrivateRoute path="/LcContract" exact component={contract} />
      <PrivateRoute path="/LcApprovedFinance" exact component={approvedFinance} />
      <PrivateRoute path="/LcApplyforFinance" exact component={applyforFinance} />
      <PrivateRoute path="/LcAmendment" exact component={amendment} />
      <PrivateRoute path="/LcDiscounting" exact component={Lcdiscountingcard} />
      <PrivateRoute path="/LcDiscountingV2" exact component={lcdiscountingcardV2} />

      <PrivateRoute path="/finInvoiceQuotes" exact component={finInvoiceQuotes} />
      <PrivateRoute path="/finQuotesDetails" exact component={finQuotesDetails} />
      <PrivateRoute path="/termSheet" exact component={termSheet} />
      <PrivateRoute path="/sendTermSheet" exact component={sendTermSheet} />
      <PrivateRoute path="/signTermSheet" exact component={signTermSheet} />
      <PrivateRoute path="/viewSignTermSheet" exact component={viewSignTermSheet} />
      <PrivateRoute path="/financeApplication" exact component={financeApplication} />
      <PrivateRoute path="/viewFinanceApplication" exact component={viewFinanceApplication} />
      <PrivateRoute path="/disbursement" exact component={disbursement} />
      <PrivateRoute path="/disbursementV2" exact component={DisbursementV2} />
      <PrivateRoute path="/disbursementDetails" exact component={disbursementDetails} />
      <PrivateRoute path="/LcSeequotes" exact component={lcSeequotes} />
      <PrivateRoute path="/ApplyforSblc" exact component={applyforSblc} />
      <PrivateRoute path="/LcSignContract" exact component={lcSignContract} />
      <PrivateRoute path="/LcFinancebtn" exact component={LcApplyFinanceBtn} />
      <PrivateRoute path="/amendApplication" exact component={amendApplication} />
      <Route path="/ammendLC" exact component={ammendLC} />
      <PrivateRoute path="/finLCQuotes" exact component={finLCQuotes} />
      <PrivateRoute path="/finLCQuotesDetails" exact component={finLCQuotesDetails} />
      <PrivateRoute path="/signEximBankTermSheet" exact component={signEximBankTermSheet} />
      <PrivateRoute path="/lcRequestLetter" exact component={lcRequestLetter} />
      <PrivateRoute path="/viewRequestLetter" exact component={viewRequestLetter} />
      <PrivateRoute path="/sendLC" exact component={sendLC} />
      <PrivateRoute path="/viewSendLC" exact component={viewSendLC} />
      <PrivateRoute path="/lcFinApplication" exact component={lcFinApplication} />
      <PrivateRoute path="/viewLCFinApplication" exact component={viewLCFinApplication} />
      <PrivateRoute path="/amendmentDetails" exact component={amendmentDetails} />

      <Route path="/amendmentRequest" exact component={amendmentRequest} />


      {/* <Route path="/ammendmentLC" exact component={viewLCFinApplication} /> */}


      <PrivateRoute path="/QuickFinance" exact component={quickFinance} />
      <PrivateRoute path="/wallet" exact component={walletV2} />
      <PrivateRoute path="/plans" exact component={pricing} />
      <PrivateRoute path="/plansdetails" exact component={viewDetails} />

      <PrivateRoute path="/viewprofile" exact component={viewProfile} />
      {/* <PrivateRoute path="/chatRoom" exact component={ChatRoomV2} /> */}
      <PrivateRoute path="/ChatRoomV2" exact component={ChatRoomV2} />

      <PrivateRoute path="/supplierDetails" exact component={supplierDetails} />
      <Route path="/sblcQuotations" exact component={sblcQuotations} />
      <Route path="/sblcTermSheet" exact component={sblcTermSheet} />
      <Route path="/sblcQuotationDetails" exact component={sblcQuotationDetails} />

      <PrivateRoute path="/DocsRequested" exact component={DocsRequested} />

      <PrivateRoute path="/Reportv2" exact component={reportsv2} />

      <PrivateRoute path="/HelpSupport" exact component={helpSupport} />
      <PrivateRoute path="/AllNotifications" exact component={allNotifications} />
      <Route path="/payment-gateway" exact component={PayUPaymentScreen} />
      <Route path="/failedPayment" exact component={FailedPayment} />
      <Route path="/paymentSuccess" exact component={SuccessPayment} />
      <PrivateRoute path="/CreditInsurance" exact component={TradeCreditInsuarance} />
      <PrivateRoute path="/tciSingleBuyer" exact component={TCISingleBuyer} />

      <PrivateRoute path="/invoiceLimit" exact component={InvoiceLimit} />
      <PrivateRoute path="/invoiceFinance" exact component={InvoiceFinance} />
      <PrivateRoute path="/invoiceApprovedFinance" exact component={InvoiceApprovedFinance} />
      <PrivateRoute path="/usersonboard" exact component={UserOnboard} />

      <PrivateRoute path="/lcLimit" exact component={LCLimit} />
      <PrivateRoute path="/adminPayments" exact component={AdminPayments} />

      <PrivateRoute path="/taskManager" exact component={TaskManager} />

      <PrivateRoute path="/SubAdminProfile" exact component={SubAdminTab} />
      <PrivateRoute path="/SubAdminProfileDetails" exact component={SubAdminProfileDetails} />
      <PrivateRoute path="/enquiry" exact component={EnquiryList} />
      <PrivateRoute path="/callList" exact component={CallList} />
      <PrivateRoute path="/leads" exact component={LeadsComponent} />
      <PrivateRoute path="/crmdata" exact component={CRMDataComponent} />
      <PrivateRoute path="/masterdata" exact component={CRMMasterData} />
      <PrivateRoute path="/masterdataAssignment" exact component={CRMAssignScreen} />
      <PrivateRoute path="/masterdataProfile" exact component={CRMUserProfile} />


      <PrivateRoute path="/generateInvoiceFinancier" exact component={InvoiceGenerateFinancier} />
      <PrivateRoute path="/generateInvoiceCP" exact component={InvoiceGenerateCP} />
      <PrivateRoute path="/AdminReports" exact component={AdminReports} />

      <PrivateRoute path="/userDetail" exact component={TTVBuyerDetail} />
      <PrivateRoute path="/corporate" exact component={Corporate} />
      <PrivateRoute path="/financier" exact component={Financer} />
      <PrivateRoute path="/corporateApplication" exact component={ApplicationForm} />
      <PrivateRoute path="/addFinancer" exact component={AddFinancer} />
      <PrivateRoute path="/tradeDiscovery" exact component={TradeDiscovery} />

      <PrivateRoute path="/extraDetails" exact component={ExtraDetailsForLimitApplication} />
      <PrivateRoute path="/buyercreditcheck" exact component={BuyerCreditCheck} />
      <PrivateRoute path="/suppliercreditcheck" exact component={SupplierCreditCheck} />
      <PrivateRoute path="/bgGetConfirmation" exact component={BGGetConfirmation} />
      <PrivateRoute path="/bgQuote" exact component={BGQuote} />
      <PrivateRoute path="/bgQuoteDetails" exact component={BGQuoteDetails} />
      <PrivateRoute path="/bgSignTermsheet" exact component={BGSignTermSheet} />
      <PrivateRoute path="/bgConfirmed" exact component={BGConfirmed} />
      <PrivateRoute path="/bgFinancierQuote" exact component={BGFinancierQuote} />
      <PrivateRoute path="/bgFinancierSendQuote" exact component={BGFinancierSendQuote} />
      <PrivateRoute path="/bgFinancierSendTermSheet" exact component={BGFinancierSendTermSheet} />
      <PrivateRoute path="/bgConfirmedFinancier" exact component={BGConfirmedFinancier} />


      <PrivateRoute path="/wcApplyLimit" exact component={WCApplyLimit} />
      <PrivateRoute path="/wcQuote" exact component={WCQuote} />
      <PrivateRoute path="/wcQuoteDetails" exact component={WCQuoteDetails} />
      <PrivateRoute path="/wcSignTermsheet" exact component={WCSignTermSheet} />
      <PrivateRoute path="/wcFinancierQuote" exact component={WCFinancierQuote} />
      <PrivateRoute path="/wcFinancierSendQuote" exact component={WCFinancierSendQuote} />
      <PrivateRoute path="/wcFinancierSendTermSheet" exact component={WCFinancierSendTermSheet} />

      <PrivateRoute path="/applylimitSCF" exact component={ApplyLimitSCF} />
      <PrivateRoute path="/scfQuote" exact component={SCFQuote} />
      <PrivateRoute path="/scfQuoteDetails" exact component={SCFQuoteDetails} />
      <PrivateRoute path="/scfFinancierQuote" exact component={SCFFinancierQuote} />
      <PrivateRoute path="/scfFinancierSendQuote" exact component={SCFFinancierSendQuote} />
      <PrivateRoute path="/scfFinancierSendTermSheet" exact component={SCFFinancierSendTermsheet} />
      <PrivateRoute path="/scfSignTermsheet" exact component={SCFSignTermsheet} />
      <PrivateRoute path="/scfforwardapplication" exact component={SCFForwardApplication} />



      <PrivateRoute path="/approvedLimitSCF" exact component={SCFApprovedLimit} />

      <PrivateRoute path="/applyfinDID" exact component={ApplyFinanceDID} />

      <PrivateRoute path="/applylimitCGTMSE" exact component={ApplyLimitCGTMSE} />
      <PrivateRoute path="/cgtmseQuote" exact component={CGTMSEQuote} />
      <PrivateRoute path="/cgtmseQuoteDetails" exact component={CGTMSEQuoteDetails} />
      <PrivateRoute path="/cgtmseSignTermsheet" exact component={CGTMSESignTermSheet} />
      <PrivateRoute path="/cgtmseFinancierQuote" exact component={CGTMSEFinancierQuote} />
      <PrivateRoute path="/cgtmseFinancierSendQuote" exact component={CGTMSEFinancierSendQuote} />
      <PrivateRoute path="/cgtmseFinancierSendTermSheet" exact component={CGTMSEFinancierSendTermSheet} />

      <PrivateRoute path="/tciTransactional" exact component={TCITransactional} />
      <PrivateRoute path="/tciWholeTO" exact component={TCIWholeTO} />
      <PrivateRoute path="/tciKeyAccounts" exact component={TCIKeyAccounts} />

      <PrivateRoute path="/docVaultTransaction" exact component={DocVaultTransaction} />
      <PrivateRoute path="/docVaultTransactionDetails" exact component={DocVaultTransactionDetails} />


      <PrivateRoute path="/sign-invoice-agreement" exact component={InvoiceAgreementV2} />
      <PrivateRoute path="/sign-invoice-agreement-details" exact component={SignAgreementV2} />


      <PrivateRoute path="/workOrderContracts" exact component={WorkOrderContract} />
      <PrivateRoute path="/createWorkOrderContract" exact component={CreateWorkOrderContract} />
      <PrivateRoute path="/workOrderContractDetails" exact component={WorkOrderContractDetails} />
      <PrivateRoute path="/commoditiesList" exact component={CommoditiesListV2} />
      <PrivateRoute path="/manageCommodity" exact component={CommoditiesListV2} />

      <PrivateRoute path="/MyNetwork" exact component={MyNetwork} />
      <PrivateRoute path="/ManageNetwork" exact component={ManageNetwork} />
      <PrivateRoute path="/Purchaseplan" exact component={plan} />
      <PrivateRoute path="/NetworkRequests" exact component={NetworkRequests} />
      <PrivateRoute path="/NetworkBlockedUsers" exact component={BlockedUsers} />
      <PrivateRoute path="/Manageplan" exact component={ManagePlan} />


      <PrivateRoute path="/ShipmentBookingApply" exact component={ApplyForBooking} />
      <PrivateRoute path="/ShipmentBookingQuotes" exact component={ShipmentBookingQuotes} />
      <PrivateRoute path="/ShipmentQuoteDetails" exact component={ShipmentQuoteDetails} />
      <PrivateRoute path="/ShipmentBookingFinancierQuotes" exact component={ShipmentFinancierQuote} />
      <PrivateRoute path="/shipmentFinancierSendQuote" exact component={ShipmentFinancierSendQuote} />
      <PrivateRoute path="/ShipmentBookingContract" exact component={ShipmentBookingContract} />
      <PrivateRoute path="/ShipmentBookingContractDetails" exact component={ShipmentBookingContractDetails} />
      <PrivateRoute path="/ShipmentBookingFinancierContract" exact component={ShipmentBookingFinancierContract} />
      <PrivateRoute path="/ShipmentBookingFinancierContractDetails" exact component={ShipmentBookingFinancierContractDetails} />
      <PrivateRoute path="/ShipmentBookingMarketPlace" exact component={ShipmentBookingMarketPlace} />


      <PrivateRoute path="/NotificationList" exact component={NotificationList} />
      <PrivateRoute path="/SettingsNotification" exact component={NotificationSettings} />


    </Router>

  )
}

export default App;
