import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { ToastContainer } from 'react-toastify'
import call from '../../../service'
import { FileInput } from '../../../utils/FileInput'
import { convertImageToPdf, encryptData, isEmpty } from '../../../utils/myFunctions'
import { InputWithSelect, NewInput } from '../../../utils/newInput'
import toastDisplay from '../../../utils/toastNotification'

const UserAcess = {
  "Buyer Management": "buyerManagement",
  "LC Apply For Limit": "applyForLimit,LcDiscounting",
  "LC Quotes": "LcQuote,LcSeequotes",
  "LC Contract": "LcContract,LcSignContract",
  "LC Finance": "LcApplyforFinance,LcFinancebtn,LcApprovedFinance",
  "LC Ammendment": "LcAmendment,amendmentDetails",
  "Invoice Apply For Limit": "applyLimit",
  "Invoice Quotes": "Quotes,seequotes,DocsRequested",
  "Invoice Contract": "invoicecontract,signContract",
  "Invoice Finance": "apply-finance,apply-invoice",
  "Invoice Approved Finance": "approvedFinance,viewDetails",
  "Chat Room": "ChatRoomV2",
  "Reports": "Reportv2",
  "Notifications": "AllNotifications",
  "Profile": "viewprofile",
  "Wallet": "wallet,payment-gateway,plans"
}

const UserAcessFinancer = {
  "Supplier List": "supplierList,supplierDetails",
  "LC Quotes": "finLCQuotes,finLCQuotesDetails",
  "LC Request Letter": "lcRequestLetter,viewRequestLetter",
  "LC Finance": "lcFinApplication,viewLCFinApplication",
  "Invoice Quotes": "finInvoiceQuotes,finQuotesDetails,docsRequested",
  "Invoice Send Termsheet": "termSheet,sendTermSheet",
  "Invoice Sign Termsheet": "signTermSheet,viewSignTermSheet",
  "Invoice Finance": "financeApplication,viewFinanceApplication",
  "Invoice Disbursement": "disbursementV2",
  "Chat Room": "ChatRoomV2",
  "Reports": "Reportv2",
  "Notifications": "AllNotifications",
  "Profile": "viewprofile"
}
const SubUserModal = ({ subUserModal, setSubUserModal, countrys, userTokenDetails, isEditable, formdata }) => {
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [accessObj, setAccessObj] = useState({})
  const [showLoader, setShowLoader] = useState(false)
  const [securePw, toggleSecurePw] = useState(true)
  useEffect(() => {
    console.log('formdataaaaaaaa', formdata)
    if (isEditable) {
      setData({
        contact_name: formdata.contact_person,
        contact_personTitle: formdata.name_title,
        contact_number: formdata.contact_number,
        contact_phonecode: formdata.phone_code,
        email_id: formdata.email_id,
        addresss: formdata.user_address,
        panno: formdata.aadhar_no,
        aadhaarno: formdata.aadhar_no

      })
      setAccessObj(JSON.parse(formdata.UserPermissions))
    }
  }, [])
  const handleChangeUser = async (event) => {
    event.persist()
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }
  const handleFileUser = (event, isImage) => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }

      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result

        if (!file_type.includes("pdf") && !isImage) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }
  const createSubUser = async () => {
    setShowLoader(true)
    try {
      let reqObj = {
        email: data.email_id,
        encryptedPassword: encryptData(data.password),
        type_id: userTokenDetails?.type_id === 19 ? 21 : userTokenDetails?.type_id === 8 ? 22 : 0,
        parent_id: userTokenDetails?.user_id,
        techType: "",
        user_address: data.addresss,
        nameTitle: data.contact_personTitle,
        contactName: data.contact_name,
        contactNumber: data.contact_number,
        PhoneCode: data.contact_phonecode,
        pan_no: data.panno,
        aadhar_no: data.aadhaarno,
        UserPermissions: JSON.stringify(accessObj)
      }
      console.log('reqobj', reqObj)
      const result = await call('POST', 'createSubUser', reqObj)
      setShowLoader(false)
      setSubUserModal(false)
      toastDisplay('User Added Succesfully', 'success')
    } catch (e) {
      setShowLoader(false)
      toastDisplay('Failed to add the User', 'error')
    }
  }
  const handleValidations = () => {
    let errors = {}
    if (!data.contact_name) {
      errors.contact_name = 'Contact Name Cannot be empty'
    }
    if (!data.contact_personTitle) {
      errors.contact_personTitle = 'Contact Name Title Cannot be empty'
    }
    if (!data.contact_number) {
      errors.contact_number = 'Contact Number Cannot be empty'
    }
    if (!data.contact_phonecode) {
      errors.contact_phonecode = 'Contact Number Code cannot be empty'
    }
    if (!data.addresss) {
      errors.addresss = 'Address cannot be empty'
    }
    if (!data.password) {
      errors.password = 'Password cannot be empty'
    }
    if (!data.panno) {
      errors.panno = 'PAN Number cannot be empty'
    }
    if (!data.aadhaarno) {
      errors.aadhaarno = 'Aadhaar Number cannot be empty'
    }
    if (isEmpty(accessObj)) {
      errors.accesspermission = 'You need to select at least one module'
    }
    if (isEmpty(errors)) {
      createSubUser()
    } else {
      setErrors(errors)
    }
  }
  console.log('dataaa', data, accessObj)
  return (
    <>
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <div className={`modal fade ${subUserModal && "show"}`} style={subUserModal ? { display: "block", "zIndex": '100001' } : {}}>
        <div className="modal-dialog modal-md mr-0 my-0">
          <div className="modal-content submitmodal pb-4">
            <div className="modal-header border-0">
              <div className="w-100 d-flex align-items-center justify-content-between">
                <label
                  className="font-size-16 font-wt-600 text-color-value mx-3"
                >Add New User</label>
                <div className="modal-header border-0">
                  <button type="button" className="btn-close" aria-label="Close" onClick={() => setSubUserModal(false)}></button>
                </div>
              </div>
            </div>

            <div className="modal-body px-4">
              <div className="col py-2">
                <div className="col-md-12 px-0">
                  <InputWithSelect isAstrix={false} type={"text"} label={"Name"} isDisabled={isEditable}
                    selectData={[{ name: "Mr" }, { name: 'Miss' }]}
                    selectName={"contact_personTitle"} selectValue={data.contact_personTitle}
                    optionLabel={"name"} optionValue={'name'}
                    name={'contact_name'} value={data.contact_name} error={errors.contact_name}
                    onChange={handleChangeUser} />
                </div>
              </div>
              <div className="col py-2">
                <div className="col-md-12 px-0">
                  <InputWithSelect isAstrix={false} type={"text"} label={"Contact No."} isDisabled={isEditable}
                    selectData={countrys}
                    selectName={"contact_phonecode"} selectValue={data.contact_phonecode}
                    optionLabel={"phonecode"} optionValue={'phonecode'}
                    name={'contact_number'} value={data.contact_number} error={errors.contact_number}
                    onChange={handleChangeUser} />
                </div>
              </div>
              <div className="col py-2">
                <div className="col-md-12 px-0">
                  <NewInput isAstrix={true} type={"text"} label={"Email ID"} isDisabled={isEditable}
                    name={"email_id"} value={data.email_id} error={errors.email_id}
                    onChange={handleChangeUser} />
                </div>
              </div>
              {!isEditable &&
                <div className="col py-2">
                  <div className="col-md-12 px-0">
                    <div className="form-group w-100 position-relative ">
                      <i
                        onClick={() => toggleSecurePw(!securePw)}
                        className={`fas ${!securePw ? "fa-eye" : 'fa-eye-slash'} input-icon`} id="togglePassword" ></i>
                      <input type={securePw ? "password" : 'text'} className={" form-control" + (!errors.password ? '' : ' border-danger')} placeholder="Enter Password" name="password" value={data.password} disabled={isEditable} onChange={handleChangeUser} />

                      {errors.password &&
                        <div class="text-danger mt-2 font-size-12">
                          <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                          <b>{errors.password}</b>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              }

              <div className="col py-2">
                <div className="col-md-12 px-0">
                  <NewInput isAstrix={true} type={"text"} label={"Address"} isDisabled={isEditable}
                    name={"addresss"} value={data.address} error={errors.address}
                    onChange={handleChangeUser} />
                </div>
              </div>
              <div className="col py-2">
                <div className="col-md-12 px-0">
                  <NewInput isAstrix={true} type={"text"} label={"PAN No."} isDisabled={isEditable}
                    name={"panno"} value={data.panno} error={errors.panno}
                    onChange={handleChangeUser} />
                </div>
              </div>
              <div className="col py-2">
                <div className="col-md-12 px-0">
                  <NewInput isAstrix={true} type={"text"} label={"Aadhaar No."} isDisabled={isEditable}
                    name={"aadhaarno"} value={data.aadhaarno} error={errors.aadhaarno}
                    onChange={handleChangeUser} />
                </div>
              </div>
              <label className="font-size-15 font-wt-500 my-4">
                <u>
                  Attach Document
                </u>
              </label>
              <div>
                <label className="pl-2 font-size-14 font-wt-300">Aadhar card</label>
                <div className="col">
                  <FileInput name={"User_Aadhaar_Document"} value={data?.User_Aadhaar_Document} error={errors.User_Aadhaar_Document}
                    onChange={handleFileUser} isEditable={!isEditable} userTokenDetails={userTokenDetails} onUploadCancel={() => setData({ ...data, "User_Aadhaar_Document": null })} />
                </div>
                <label className="pl-2 font-size-14 font-wt-300">PAN card</label>
                <div className="col">
                  <FileInput name={"User_PAN_Document"} value={data?.User_PAN_Document} error={errors.User_PAN_Document}
                    onChange={handleFileUser} isEditable={!isEditable} userTokenDetails={userTokenDetails} onUploadCancel={() => setData({ ...data, "User_PAN_Document": null })} />
                </div>
              </div>
              <label className="font-size-15 font-wt-500 my-4">
                <u>
                  User Access
                </u>
              </label>
              <div className='row py-2'>
                {Object.keys(userTokenDetails?.type_id == 19 ? UserAcess : userTokenDetails?.type_id == 8 ? UserAcessFinancer : []).map((item, index) => {
                  return <div className="col-md-6 pt-2">
                    <div className='d-flex flex-row align-items-center'>
                      <img src={accessObj[item] ? '/assets/images/checked-green.png' : '/assets/images/unchecked-box.png'} height={20} width={20} className='mr-2 cursor' onClick={() => {
                        if (!isEditable) {
                          const temp = accessObj
                          if (accessObj[item]) {
                            delete temp[item]
                          } else {
                            let userAccess = userTokenDetails?.type_id == 19 ? UserAcess : userTokenDetails?.type_id == 8 ? UserAcessFinancer : []
                            temp[item] = userAccess[item]
                          }
                          setAccessObj({
                            ...temp
                          })
                        }
                      }} />
                      <p className="m-0">{item}</p>
                    </div>
                  </div>
                })}
              </div>
              {errors.accesspermission &&
                <div class="text-danger mt-2 font-size-12">
                  <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                  <b>{errors.accesspermission}</b>
                </div>
              }

              {!isEditable &&
                <div className="d-flex gap-4">
                  <button className={`mt-4 new-btn w-40 py-2 px-2 text-white cursor`} onClick={handleValidations}>Add New User</button>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SubUserModal