import React, { useEffect, useState } from 'react';
import call from '../../service';
import { connect } from 'react-redux';
import { setShowCreateProfileAdminModal } from '../../store/actions/action';
import { ToastContainer } from 'react-toastify';
import SideBarV2 from '../partial/sideBarV2';
import HeaderV2 from '../partial/headerV2';
import toastDisplay from '../../utils/toastNotification';
import moment from 'moment';
import { displayRazorpay } from '../../utils/PaymentGateway';
import { NewSelect, SelectForTable } from '../../utils/newInput';


const Plan = ({ userTokenDetails, channelPartnerUser, setShowCreateProfileAdminModal, showCreateProfileAdminModal, dispatch, navToggleState }) => {

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;
  const user_avatar = userTokenDetails.userAvatar ? userTokenDetails.userAvatar : null

  const queryParams = new URLSearchParams(window.location.search)
  const topup = queryParams.get("topup")



  const [showLoader, setShowLoader] = useState(false)
  const [data, setData] = useState({
    dataSecuredByBlockchainCount: 1, qmsCount: 1, commodityCount: 1, commodityContractsCount: 1,
    shipmentContractsCount: 1, partnersCount: 1, planValidity: 1
  })
  const [priceKey, setPriceKey] = useState('plan_amount_usd')
  const [planStartDate, setPlanStartDate] = useState(moment())

  let [plans, setPlans] = useState([])

  useEffect(() => {
    loadPlans()
  }, [])

  async function loadPlans() {
    let tempPlans = [
      { title: "Data secured by block chain", "alt": 'dataSecuredByBlockchain', subtitle: '', by: "month", perQuantity: 10, perQuantityInr: 850, unit: 'month', dontShowIncrementModal: true },
      { title: "QMS", "alt": 'qms', subtitle: '(query management with contract partners)', by: "month", perQuantity: 4, perQuantityInr: 340, unit: 'month', dontShowIncrementModal: true },
      { title: "10 commodities to manage", "alt": 'commodity', subtitle: '(select in multiple of 10)', by: "month", perQuantity: 10, perQuantityInr: 850, unit: 'month' },
      { title: "10 commodity contracts", "alt": 'commodityContracts', subtitle: '(select in multiple of 10)', by: "month", perQuantity: 20, perQuantityInr: 1700, unit: 'month' },
      { title: "10 shipment contracts", "alt": 'shipmentContracts', subtitle: '(select in multiple of 10)', by: "month", perQuantity: 20, perQuantityInr: 1700, unit: 'month' },
      { title: "10 partners can add in network", "alt": 'partners', subtitle: '(select in multiple of 10)', by: "month", perQuantity: 10, perQuantityInr: 850, unit: 'month' }
    ]
    setShowLoader(true)
    let apiResp = await call('POST', 'getRecentCurrencyConversionRate', {})
    if (apiResp?.length) {
      for (let index = 0; index < tempPlans.length; index++) {
        let element = tempPlans[index];
        element[`perQuantityInr`] = (element[`perQuantity`] * apiResp[0]['conversion']['INR']).toFixed(2) / 1
      }
    }
    setShowLoader(false)
    setPlans(tempPlans)
  }

  function getTotalPrice() {
    let total = 0
    for (let index = 0; index < plans.length; index++) {
      const element = plans[index];
      if (data[element.alt]) {
        total += ((priceKey === "plan_amount_usd" ? element.perQuantity : element.perQuantityInr) * data[element.alt + 'Count'])
      }
    }
    return (total * data.planValidity).toFixed(2) / 1
  }

  const onPurchasePlan = async (plan_id, plan_type, plan_amount_usd, currency) => {
    try {
      let reqObj = {
        plan_id,
        plan_type,
        plan_amount_usd,
        userId,
        currency
      }
      const result = await call('POST', 'createPaymentlink', reqObj)
      window.location = result
    } catch (e) {
      console.log('error in createPaymentlink', e)
    }
  }

  const onSuccesfullPayment = (orderData) => {
    let subscriptionDetails = []
    for (let index = 0; index < plans.length; index++) {
      const element = plans[index];
      if (data[element.alt]) {
        subscriptionDetails.push({
          name: element.title,
          alt: element.alt,
          quantity: data[element.alt + 'Count'],
          amount: (priceKey === "plan_amount_usd" ? element.perQuantity : element.perQuantityInr) * data[element.alt + 'Count'],
          unit: priceKey === 'plan_amount_usd' ? "USD" : "INR",
        })
      }
    }
    let req = {
      userId,
      subscriptionDetails,
      planValidity: data.planValidity,
      order_data: orderData,
      plan_amount_usd: getTotalPrice(),
      plan_currency: priceKey === "plan_amount_usd" ? '$' : '₹',
      topup,
      userEmail
    }
    // console.log("iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii", req);
    setShowLoader(true)
    call('POST', topup ? 'topupExistingPlan' : 'insertCustomPlan', req).then(result => {
      console.log('success in insertCustomPlan', result)
      toastDisplay(result, 'success', () => {
        window.location = '/Manageplan'
      })
    }).catch(e => {
      console.log('error in insertCustomPlan', e)
      toastDisplay("Something went wrong", 'error')
    })
  }

  const onPaymentFailure = (orderData, extradata, callback) => {
    let subscriptionDetails = []
    for (let index = 0; index < plans.length; index++) {
      const element = plans[index];
      if (data[element.alt]) {
        subscriptionDetails.push({
          name: element.title,
          alt: element.alt,
          quantity: data[element.alt + 'Count'],
          amount: (priceKey === "plan_amount_usd" ? element.perQuantity : element.perQuantityInr) * data[element.alt + 'Count'],
          unit: priceKey === 'plan_amount_usd' ? "USD" : "INR"
        })
      }
    }
    let req = {
      userId: userId,
      ...extradata,
      order_data: orderData,
      plan_amount_usd: getTotalPrice(),
      plan_currency: priceKey === "plan_amount_usd" ? '$' : '₹',
      subscriptionDetails,
      userEmail
    }
    call('POST', 'failedPlanPayment', req).then(result => {
      console.log('success in failedPlanPayment', result)
      toastDisplay(result, 'error')
      callback()
      setTimeout(() => {
        window.location.reload()
      }, 500);
    }).catch(e => {
      console.log('error in buyNewPlan', e)
    })
  }


  return (
    <div className="">
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="Purchaseplan" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Purchase Plan"}
              userTokenDetails={userTokenDetails} />
            {/* <div
              className='card p-4 mb-3'
            >
              <label className='font-size-16 font-wt-500 text-left' >Current Plan Information</label>
              <label className='font-size-14 font-wt-500 text-left' >{`Start Date: 2023-12-12`}</label>
              <label className='font-size-14 font-wt-500 text-left' >{`End Date: 2023-12-12`}</label>
              <label className='font-size-14 font-wt-500 text-left' >{`*Benefits`}</label>
              <label className='font-size-14 font-wt-500 text-left' >{`1.QMS`}</label>
              <label className='font-size-14 font-wt-500 text-left' >{`1.Blockchain`}</label>
            </div> */}
            <div
              className='card p-4'
            >
              <label className='font-size-20 font-wt-500 text-center' >{topup ? `Top up your existing active plan` : `Customize your Plan`}</label>
              <label className='font-size-15 font-wt-600 text-center mb-3' >Click on the check box to add services in your plan</label>
              {/* <label className='font-size-13 font-wt-500 text-center mb-3' >{`(Plan will be valid till - ${moment().add(1, "month").format("YYYY/MM/DD")})`}</label> */}
              <div className="text-center d-flex flex-row align-items-center  w-100 mb-4 justify-content-center">
                <div style={{}}>
                  <ul className="nav pricingtabs nav-pills bg-white mx-auto rounded-pill p-0 shadow-sm" id="pills-tab" role="tablist">
                    <li className="nav-item p-0 " role="presentation">
                      <button onClick={() => {
                        setPriceKey("plan_amount_usd")
                      }} className="nav-link active w-100 roundedpillleft font-size-14" id="pills-All-tab" data-bs-toggle="pill" data-bs-target="#pills-All" type="button" role="tab" aria-controls="pills-All" aria-selected="true">USD</button>
                    </li>
                    <li className="nav-item p-0 " role="presentation">
                      <button onClick={() => {
                        setPriceKey("plan_amount_inr")
                      }} className="nav-link w-100 roundedpillright font-size-14 " id="pills-Yearly-tab" data-bs-toggle="pill" data-bs-target="#pills-Yearly" type="button" role="tab" aria-controls="pills-Yearly" aria-selected="false">INR</button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='d-flex row mx-1' >
                <div className='col-7' >
                  {plans.map((i, j) => {
                    return (
                      <div className='d-flex row align-items-center' >
                        <div className='w-auto' >
                          <img width={20} height={20}
                            onClick={() => {
                              setData({ ...data, [i.alt]: !data[i.alt] })
                            }}
                            className='cursor' src={`assets/images/${data[i.alt] ? 'checked-green' : 'empty-check'}.png`} />
                        </div>
                        <div className='col-7' >
                          <label className='font-size-15 font-wt-400 p-0 m-0 w-100' >{i.title}</label>
                          {i.subtitle ?
                            <label className='font-size-13 font-wt-400 p-0 m-0 color434343 w-100' >{i.subtitle}</label> : null}
                        </div>
                        <div className=' ml-auto ' style={{ width: '12rem' }}>
                          <label className='font-size-15 font-wt-400 w-100 text-right ml-2' ><label className='text-color1 font-size-20 font-wt-500' >{`${priceKey === "plan_amount_usd" ? "$" : '₹'}${(priceKey === "plan_amount_usd" ? i.perQuantity : i.perQuantityInr) * data[i.alt + 'Count']}`}</label>/{i.unit}</label>
                          {i.dontShowIncrementModal ? null :
                            <div
                              style={{ border: '1px solid var(--primary-stroke, #D5D5D5)', borderRadius: '10px', alignItems: 'center' }}
                              className='row d-flex w-75 ml-auto' >
                              <div
                                onClick={() => {
                                  if (data[i.alt + "Count"] != 1) {
                                    setData({ ...data, [i.alt + 'Count']: data[i.alt + "Count"] - 1 })
                                  }
                                  else {
                                    toastDisplay("Quantity can't be zero", "info")
                                  }
                                }} className='col-4 d-flex align-items-center justify-content-center cursor'>
                                <img src='assets/images/minus.svg' />
                              </div>
                              <div className='col-4 align-items-center justify-content-center'><label className='font-size-14 font-wt-500 mt-1' >
                                {data[i.alt + "Count"] < 10 ? `0${data[i.alt + "Count"]}` : data[i.alt + 'Count']}</label></div>
                              <div
                                onClick={() => {
                                  setData({ ...data, [i.alt + 'Count']: data[i.alt + "Count"] + 1 })
                                }}
                                className='col-4 align-items-center justify-content-center cursor'>
                                <img src='assets/images/plus.svg' /></div>
                            </div>}
                        </div>
                        <hr className='mt-3' style={{ width: '97%' }} />
                      </div>
                    )
                  })}
                </div>

                <div className='col-5 d-flex justify-content-center' >
                  <div className='card w-90 p-4 h-auto' >
                    <label className='font-size-16 font-wt-500 text-color1' >Price breakdown</label>
                    <div className='my-3' style={{
                      borderBottom: '2px dashed #D5D5D5'
                    }} >
                      {plans.map((i, j) => {
                        if (data[i.alt]) {
                          return (
                            <div className='row d-flex mb-2' >
                              <label className='font-size-14 font-wt-400 w-auto' >{i.title}</label>
                              <label className='text-secondary font-size-14 font-wt-400 w-auto m-0 p-0' >{`x ${data[i.alt + 'Count']}`}</label>
                              <label className='color0C0C0C font-size-14 font-wt-600 ml-auto w-auto ' >{`${priceKey === "plan_amount_usd" ? "$" : '₹'}${(priceKey === "plan_amount_usd" ? i.perQuantity : i.perQuantityInr) * data[i.alt + 'Count']}`}</label>
                            </div>
                          )
                        }
                      })}
                    </div>
                    {topup ? null :
                      <div className='d-flex row my-3' >
                        <label className='font-size-13 font-wt-400 w-25' >Valid till</label>
                        <div className='w-35'>
                          <SelectForTable selectData={[{ name: '1 Month', alt: 1 }, { name: '3 Month', alt: 3 }, { name: '6 Month', alt: 6 },
                          { name: '9 Month', alt: 9 }, { name: '12 Month', alt: 12 }]} optionLabel={"name"} optionValue={"alt"}
                            name={'planValidity'} value={data.planValidity}
                            onChange={(e) => { setData({ ...data, planValidity: e.target.value }) }} />
                        </div>
                        <label className='font-size-13 font-wt-400 w-40 text-right' >{planStartDate.clone().add(data.planValidity, "months").format('YYYY-MM-DD')}</label>
                      </div>}
                    <div className='d-flex row my-3' >
                      <label className='font-size-16 text-color1 font-wt-600 w-auto' >Total </label>
                      <label className='font-size-16 text-color1 font-wt-600 ml-auto w-auto'>{`${priceKey === "plan_amount_usd" ? "$" : '₹'}${getTotalPrice()}`}</label>
                    </div>
                    {getTotalPrice() ?
                      <div className='w-100' >
                        <button type="button"
                          onClick={() => {
                            // onSuccesfullPayment()
                            // onPurchasePlan(1, "tradeTechMonthlyPlan", getTotalPrice(), "$")
                            // window.location = `/payment-gateway?token=${userTokenDetails?.token}&id=${1}`
                            displayRazorpay(getTotalPrice(), userTypeId, toastDisplay, onSuccesfullPayment, { plan_id: 1, modeOfPayment: 'RazorPay' }, onPaymentFailure, priceKey)
                          }}
                          className={` border-0 mb-2 text-white enableQuotebtn3 w-100`}>
                          {"Continue Purchase"}
                        </button>
                      </div> : null}
                  </div>
                </div>

              </div>

            </div>
          </main>
        </div>
      </div >
    </div >

  )
}

const mapStateToProps = state => {
  return {
    showCreateProfileAdminModal: state.showCreateProfileAdminModal,
    navToggleState: state.navToggleState
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setShowCreateProfileAdminModal: (flag, data) => { dispatch(setShowCreateProfileAdminModal({ modal: flag, info: data })) },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Plan)