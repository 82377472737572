import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import HeaderV2 from "../../../partial/headerV2";
import SideBarV2 from "../../../partial/sideBarV2";
import call from "../../../../service";
import toastDisplay from "../../../../utils/toastNotification";
import { getTotalCharges, getTotalOtherCharges, LCPurposeObject, LCTypesObject, most_used_currencies } from "../../../../utils/myFunctions";
import { getDocDetails } from "../../../../utils/myFunctions";
import { FileInput } from "../../../../utils/FileInput";
import { convertImageToPdf } from "../../../../utils/myFunctions";
import AdavancePaymentTab from "../../../InvoiceDiscounting/contract/components/adavancePaymentTab";
import FinanceInvoiceModal from "../../../InvoiceDiscounting/contract/components/financeinvoiceModal";
import SignDocument from "../../../InvoiceDiscounting/components/SignDocument";
import TooltipModal from "../../../InvoiceDiscounting/components/Tooltipmodal";
import { ToastContainer } from "react-toastify";


const addBuyerTabs = [
  { name: "Select financer" },
  { name: "Application form" },
  { name: "Documents" }
  // { name: "Advance payment" },
]

const reviewForm = [
  { "name": "Buyer Name", val: "buyerName" },
  { "name": "Previous year annual sales", val: "previousAnnualSale", unit: "buyerCurrency" },
  { "name": "Contact Number:", val: "buyerPhone", unit: "buyerPhoneCode" },
  { "name": "Expected current year’s annual sale", val: "currentAnnualSale", unit: "buyerCurrency" },

  { "name": "Email Id", val: "buyerEmail" },
  { "name": "Inco Terms", val: "incoTerms" },
  { "name": "Website", val: "buyerWebsite" },
  { "name": "Terms of Payment", val: "termsOfPayment" },

  { "name": "Address", val: "buyerAddress" },
  { "name": "Product Details", val: "productDetails" },

  { "name": "DUNS No", val: "buyerDUNSNo" },
  { "name": "HSN Code", val: "buyerHsnCode" },

]

const reviewForm3 = [
  { "name": "LC type", val: "lcType" },
  { "name": "Beneficiary bank account no.", val: "beneficiaryAccountNo" },
  { "name": "LC number", val: "lcNo" },
  { "name": "Beneficiary bank IFSC code", val: "beneficiaryIfscCode" },

  // { "name": "LC tenor", val:  },
  { "name": "LC issuing bank name", val: "lcIssuingBankName" },
  { "name": "Country of origin", val: "countryOfOrigin" },
  { "name": "LC issuing bank SWIFT code", val: "lcIssuingBankSwiftCode" },
  { "name": "Shipment from country", val: "shipmentFromCountry" },
  { "name": "Shipment to country", val: "shipmentToCountry" },
  { "name": "LC issuing bank address", val: "lcIssuingBankAddress" },
  { "name": "Port of loading", val: "portOfLoading" },
  { "name": "Confirming bank name", val: "confirmingBankName" },
  { "name": "Port of discharge", val: "portOfDischarge" },
  { "name": "Confirming bank SWIFT code", val: "confirmingBankSwiftCode" },

  { "name": "Commodity", val: "commodity" },
  { "name": "Expected date of shipment", val: "expectedDateOfShipment" },
  { "name": "Beneficiary bank name", val: "beneficiaryBankName" },
  { "name": "Confirming bank address", val: "confirmingBankAddress" },
  { "name": "Beneficiary bank SWIFT code", val: "beneficiarySwiftCode" },
]


const selectFinancedata = [
  {
    companyName: "Stenn",
    rating: "4.8",
    reviews: "178",
    logo: "assets/images/supplier-images/stenn-logo.png",
    ribbon: true,
    fees: "3.5", rate: "6.0", setupfee: "800", totalcharges: "2,300", validitydate: "30/09/2023",
    limited: true
  },
  {
    companyName: "Drip",
    rating: "4.8",
    reviews: "178",
    logo: "assets/images/supplier-images/Drip-logo.png",
    ribbon: false,
    fees: "3.5", rate: "6.0", setupfee: "800", totalcharges: "2,300", validitydate: "30/09/2023",
    limited: true
  },
  {
    companyName: "Modifi",
    rating: "4.8",
    reviews: "178",
    logo: "assets/images/supplier-images/modifi-logo.png",
    ribbon: false,
    fees: "3.5", rate: "6.0", setupfee: "800", totalcharges: "2,300", validitydate: "30/09/2023",
    limited: true
  },
  {
    companyName: "Federal bank",
    rating: "4.8",
    reviews: "178",
    logo: "assets/images/supplier-images/federal-logo.png",
    ribbon: false,
    fees: "3.5", rate: "6.0", setupfee: "800", totalcharges: "2,300", validitydate: "30/09/2023",
    limited: true
  },

  {
    companyName: "Modifi",
    rating: "4.8",
    reviews: "178",
    logo: "assets/images/supplier-images/modifi-logo.png",
    ribbon: false,
    fees: "3.5", rate: "6.0", setupfee: "800", totalcharges: "2,300", validitydate: "30/09/2023",
    limited: true
  },
  {
    companyName: "Federal bank",
    rating: "4.8",
    reviews: "178",
    logo: "assets/images/supplier-images/federal-logo.png",
    ribbon: false,
    fees: "3.5", rate: "6.0", setupfee: "800", totalcharges: "2,300", validitydate: "30/09/2023",
    limited: true
  },
  {
    companyName: "Federal bank",
    rating: "4.8",
    reviews: "178",
    logo: "assets/images/supplier-images/federal-logo.png",
    ribbon: false,
    fees: "3.5", rate: "6.0", setupfee: "800", totalcharges: "2,300", validitydate: "30/09/2023",
    limited: false
  },
];


const EmojiData = [
  {
    image: "/assets/images/angry.png",
    fillimage: "/assets/images/angry-fill.png",
    name: "Angry"
  },
  {
    image: "/assets/images/Not satisfied.png",
    fillimage: "/assets/images/notsatisfed-fill.png",
    name: "Not satisfied"
  },
  {
    image: "/assets/images/ok.png",
    fillimage: "/assets/images/ok-fill.png",
    name: "ok"
  }, {
    image: "/assets/images/Satisfied.png",
    fillimage: "/assets/images/satisfied-fill.png",
    name: "Satisfied"
  }, {
    image: "/assets/images/excellent.png",
    fillimage: "/assets/images/excellent-fill.png",
    name: "excellent"
  },
];

const adavanceData = [
  { name: "Confirmation fees", amount: "$ 50" },
  { name: "Discounting rate", amount: "$ 100" },
  { name: "One time set-up fees", amount: "$ 750" },
  { name: "Finance credit days", amount: "50" },
  { name: "Handling", amount: "50" },
  { name: "Courier", amount: "50" },
];



const LcSigncontract = ({ userTokenDetails, navToggleState }) => {

  const queryParams = new URLSearchParams(window.location.search)
  const quoteId = queryParams.get("id")
  const onlyView = queryParams.get("view")


  const [dbData, setDbData] = useState([])

  const [tab, setTab] = useState(2)
  const [modal, setModal] = useState(false);
  const [limitinvoice, setLimitinvoice] = useState(false);
  const [experience, setExperience] = useState(false);
  const [emoji, setEmoji] = useState("");

  const [tooltip, setTooltip] = useState(false);
  const [signdoc, setSigndoc] = useState(false);
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)
  const [addMoreDoc, setAddMoreDoc] = useState([null])
  const [contractDocs, setContractDocs] = useState([])

  const [dunsData, setDunsData] = useState([])
  const [hsnCodes, setHsnCodes] = useState([])
  const [countrys, setCountrys] = useState([])
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [financierData, setFinancierData] = useState([]);

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  const [firstindex, setFirstindex] = useState(0);
  const [lastindex, setLastindex] = useState(4);
  const [slice, setSlice] = useState(1);

  const Next = () => {
    setFirstindex(firstindex + 4);
    setLastindex(lastindex + 4);
    setSlice(slice + 1);
  };

  const Back = () => {
    setFirstindex(firstindex - 4);
    setLastindex(lastindex - 4);
    setSlice(slice - 1);
  };


  const closeSuccess = () => {
    setLimitinvoice(false);
    setExperience(true);
  }

  useEffect(() => {
    // master data start
    // call('POST', 'getFinanciersForLC').then((result) => {
    //   setLCFinanciers(result)
    // }).catch((e) => {
    //   console.log('error in getFinanciersForLC', e);
    // })
    //master data end
    //Get quote data start
    setshowLoader(true)
    call('POST', 'getLCQuoteApplicationById', { quoteId }).then(async (result) => {
      console.log('running getLCQuoteApplicationById api-->', result);
      if (result.length) {
        let reqLetterDocument = {}
        let additionalDetails = {}
        reqLetterDocument = await getDocDetails(result[0].reqLetterOfConfirmation)
        let draftLCDocument = await getDocDetails(result[0].draftLC)
        let invoiceDocument = await getDocDetails(result[0].buyersDocId ? result[0].buyersDocId.split(":")[0] : null)
        let poDocument = await getDocDetails(result[0].buyersDocId ? result[0].buyersDocId.split(":")[1] : null)
        let buyerOtherDocIdArr = result[0].buyerOtherDocs ? result[0].buyerOtherDocs.split(":") : []
        let contractDocIdArr = result[0]["contractDocsFromFinanciers"].split(":")

        let otherDocObj = {}
        let moreDocArr = addMoreDoc

        let contractDocObj = {}
        let contractDocArr = contractDocs

        for (let index = 0; index < buyerOtherDocIdArr.length; index++) {
          otherDocObj["otherDoc" + index] = await getDocDetails(buyerOtherDocIdArr[index])
          moreDocArr.push(null)
        }

        for (let index = 0; index < contractDocIdArr.length; index++) {
          contractDocObj["contractDoc" + index] = await getDocDetails(contractDocIdArr[index])
          contractDocArr.push(null)
        }

        let financierQuotes = result[0]["financierQuotes"] ? JSON.parse(result[0]["financierQuotes"]) : []

        for (let index = 0; index < financierQuotes.length; index++) {
          if (financierQuotes[index]["lender_name"].toLowerCase().includes("stenn")) {
            financierQuotes[index]["logo"] = "assets/images/supplier-images/stenn-logo.png"
          }
          else if (financierQuotes[index]["lender_name"].toLowerCase().includes("modifi")) {
            financierQuotes[index]["logo"] = "assets/images/supplier-images/modifi-logo.png"
          }
        }

        setData({
          ...result[0], buyerCurrency: result[0].buyerCurrency ? result[0].buyerCurrency.split(":")[1] : null, invoiceDocument, poDocument, draftLCDocument, reqLetterDocument, ...additionalDetails,
          limitRequired: result[0]["requiredLimit"], lcType: LCTypesObject[result[0]["lcType"]],
          limitRequiredCurrency: result[0]["requiredLimitCurrency"], ...otherDocObj, ...contractDocObj, financierQuotes, tcAccepted: onlyView ? true : false
        })
        setAddMoreDoc(moreDocArr)
        setContractDocs(contractDocArr)
      }
      setshowLoader(false)
    }).catch((e) => {
      // console.log('error in getLCQuoteApplicationById', e);
      setshowLoader(false)
    });
    //Get quote data end
  }, [])


  const closeExpFn = () => {
    setExperience(false);
  }


  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }
      if (event.target.name.includes("otherDoc")) {
        setAddMoreDoc(addMoreDoc.concat(null))
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (!file_type.includes("pdf")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  async function handleValidation(tabIndex) {

    if (tabIndex != undefined) {
      if (tabIndex < tab) {
        return setTab(tabIndex)
      }
      else if (tabIndex == tab) {
        return null
      }
      else {
        return toastDisplay("Click on continue button to go to next form", "info")
      }
    }

    let err = {}

    if (tab === 2) {
      contractDocs.forEach((item, index) => {
        if (!data["contractDoc" + index]["signatureId"]) {
          err["contractDoc" + index] = "Signing contract document is mandatory"
        }
      })
    }

    if (!Object.keys(err).length) {
      if (tab === 2) {
        setshowLoader(true)
        let formData = new FormData()
        formData.append("userId", userId)
        formData.append("applicationId", data.id)
        formData.append("selectedFinancier", data.selectedFinancier)
        formData.append("lcNo", data.lcNo)
        formData.append("buyerId", data.buyerId)
        formData.append("buyerOtherDocs", data.buyerOtherDocs)
        formData.append("contractDocsFromFinanciers", data.contractDocsFromFinanciers)
        addMoreDoc.forEach((k, index) => {
          if (data["otherDoc" + index]) {
            formData.append("otherDoc" + index, data["otherDoc" + index])
            if (data["otherDoc" + index]["signatureId"]) {
              formData.append("otherDoc" + index + "SignId", data["otherDoc" + index]["signatureId"])
            }
          }
        })
        contractDocs.forEach((k, index) => {
          if (data?.["contractDoc" + index]?.name) {
            formData.append("contractDoc" + index, data["contractDoc" + index])
          }
          formData.append("contractDoc" + index + "SignId", data["contractDoc" + index]["signatureId"])
        })
        if (userTypeId != 8) {
          formData.append("exporterSignedContract", true)
        }
        call('POST', 'updateLCContractSignStatus', formData).then((result) => {
          // console.log('running updateLCContractSignStatus api-->', result);
          toastDisplay(result, "success", () => { window.location = "/LcContract" })
          setshowLoader(false)
        }).catch((e) => {
          // console.log('error in updateLCContractSignStatus', e);
          toastDisplay("Something went wrong", "error")
          setshowLoader(false)
        });
        return null
      }
      else {
        setTab(tab + 1)
      }
    }

    setErrors(err)
  }


  return (
    <>{showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="LcSignContract" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"LC - Sign Contract"}
              userTokenDetails={userTokenDetails} />
            <a className="cursor" href="/LcContract"><img className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
            <div>
              <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                {addBuyerTabs.map((item, index) => {
                  return (
                    <li>
                      <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                        onClick={() => { handleValidation(index) }}
                      >
                        {item.name}</a>
                    </li>
                  )
                })}
              </ul>
              <div className="card mt-1">
                {tab === 1 ? (
                  <div className='px-5 py-4 pt-5'>
                    <div className="row">
                      {data.buyerId ? (
                        <div className='w-100'>
                          <label className='font-wt-500 font-size-16'><u>Buyer Details</u>
                            <img className="ms-3"
                              onClick={() => { window.location = `/buyerManagement?editBuyer=${data.buyerId}` }}
                              src={"assets/images/edit.png"} alt="edit" />
                          </label>
                          <div className='row'>
                            {reviewForm.map((item) => {
                              return (
                                // <div className='col-5 p-0'>
                                //   <label className='col-6 lh-18 text-color-label font-size-14'>{item.name}</label>
                                //   <label className='lh-18 font-size-14'>:</label>
                                //   <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                //     {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}
                                //   </label>
                                // </div>
                                <div className="col-md-6 pe-5">
                                  <p className=" d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      ) : null}
                      <div className={`w-100 ${data.buyerId ? 'mt-5' : ''}`}>
                        <label className='font-wt-500 font-size-16'><u>LC Details</u>
                          <img className="ms-3"
                            onClick={() => { window.location = `/LcDiscounting?buyer=${data.buyerId}&purpose=${data.lcPurpose}&id=${data.id}&view=true` }}
                            src={"assets/images/edit.png"} alt="edit" />
                        </label>
                        <div className='row'>
                          {reviewForm3.map((item) => {
                            return (
                              // <div className='col-5 p-0'>
                              //   <label className='col-6 lh-18 text-color-label font-size-14'>{item.name}</label>
                              //   <label className='lh-18 font-size-14'>:</label>
                              //   <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                              //     {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}
                              //   </label>
                              // </div>
                              <div className="col-md-6 pe-5">
                                <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      <div className='w-100 mt-5'>
                        <label className='font-wt-500 font-size-16'><u>Documents</u>
                          {/* <img className="ms-3"
                            onClick={() => { window.location = `/buyerManagement?editBuyer=${data.id}` }}
                            src={"assets/images/edit.png"} alt="edit" /> */}
                        </label>
                        <div className='row mt-2'>
                          {data.reqLetterDocument?.name ? (
                            <div className="col-md-4">
                              <label className="font-size-14">Request letter of confirmation</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"reqLetterDocument"} value={data.reqLetterDocument}
                                    isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : null}

                          <div className="col-md-4">
                            <label className="font-size-14">Draft LC</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"draftLCDocument"} value={data.draftLCDocument}
                                  isEditable={false}
                                />
                              </div>
                            </div>
                          </div>

                          {data.invoiceDocument?.name ? (
                            <div className="col-md-4">
                              <label className="font-size-14">Invoice</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"invoiceDocument"} value={data.invoiceDocument}
                                    isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : null}

                          {data.poDocument?.name ? (
                            <div className="col-md-4">
                              <label className="font-size-14">Purchase Order</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"poDocument"} value={data.poDocument}
                                    isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : null}

                          {addMoreDoc.length ? addMoreDoc.map((item, index) => {
                            if (data["otherDoc" + index]?.name) {
                              return (
                                <div className="col-md-4">
                                  <label className="font-size-14">Add More</label>
                                  <div className="row form-group">
                                    <div className="col-md-12">
                                      <FileInput name={"otherDoc" + index} value={data["otherDoc" + index]}
                                        isEditable={false}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          }) : null}

                        </div>
                      </div>

                      {/* <div className='w-100 mt-5'>
                        <label className='font-wt-500 font-size-16'><u>Financier selected</u></label>
                        <div className="d-flex">

                          <div className="my-3 col-2 px-0">
                            <label className="font11">LC Confirmation</label>
                            <p
                              className="browse-div font-size-13 cursor text-center m-0 col-10 border-finance text-dark" >
                              {data.selectedFinancierName}
                            </p>
                          </div>

                          <div className="my-3 col-2 px-0">
                            <label className="font11">LC Discounting</label>
                            <p
                              className="browse-div font-size-13 cursor text-center m-0 col-10 border-finance text-dark" >
                              N/A
                            </p>
                          </div>
                        </div>
                      </div> */}

                    </div>
                  </div>
                ) : null}
                {tab === 0 ? (
                  <div className='px-5 pt-5 pb-3'>
                    <div className="text-center">
                      <label className='font-wt-500 font-size-16 mb-0'>Select Financier</label><br />
                      <small>Quotes for {LCPurposeObject[data.lcPurpose]}</small>
                    </div>

                    <div className="row align-items-center mt-4">
                      {data.financierQuotes ? data.financierQuotes.map((key, index) => {
                        return (
                          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 my-3">
                            <div className={`text-center card py-4 px-3
                              ${key.status === "denied" ? "limitdeniedcontract" : "selectfinancercardrec"} `}>
                              {data.selectedFinancier / 1 == key.lender_id / 1 ? (
                                <div class="containercustom " data-ribbon="Selected"></div>
                              ) : null}
                              <>
                                <p className="cursor mt-4 mb-1"><img className="mr-2"
                                  src={key.logo} alt="" />
                                  <span className="font-size-14 font-wt-600 text-dark">{key.lender_name}</span>
                                  <img
                                    onClick={() => { setTooltip(true); setData({ ...data, showRemark: key.assignRemark, showLenderName: key.lender_name }) }}
                                    src={"assets/images/warning.png"} alt="" />
                                </p>
                              </>

                              {key.status != "denied" ? (
                                <>
                                  <p className="font-size-14 mb-1 font-wt-600 text-color1">
                                    {`Discounting Percentage : ${key.discountingPercentage} % of invoice`}</p>
                                  <p className="font-size-14 mb-1">
                                    {`Offer Validity : ${key.offerValidity}`}</p>
                                  <p style={{ width: "70%", margin: "auto", borderBottom: "2px solid #5CB8D3" }} />
                                </>
                              ) : null}

                              {key.status === "denied" ? (
                                <>
                                  <div className="limitDeniedCard">
                                    <div className="mb-2">
                                      <img src={"assets/images/block.png"} height={25} width={25} />
                                    </div>
                                    <div className="font-size-14 font-wt-600">
                                      Limit Denied
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="mt-2">
                                    <p className="mb-0 text-secondary  font-size-14">Confirmation Fees</p>
                                    <p className="font-size-16">{key.giveConfirmationFees ? key.confirmationFees ? (key.confirmationFees + " %") : "" : ""}</p>
                                  </div>
                                  <div className="mt-2">
                                    <p className="mb-0 text-secondary  font-size-14">Discounting Rate</p>
                                    <p className="font-size-16">{key.giveDiscountingRate ? key.discountingRate ? (key.discountingRate + " %") : "" : ""}</p>
                                  </div>
                                  <div className="mt-2">
                                    <p className="mb-0 text-secondary  font-size-14">One time set-up fees</p>
                                    {/* <span className="mb-0 text-secondary" style={{ fontSize: "12px" }}>(negotiable)</span> */}
                                    <p className="font-size-16">{key.setupFees + " " + key.setupFeesCurrency}</p>
                                  </div>

                                  <div className="position-relative">
                                    <p
                                      className="mb-0 text-secondary  font-size-14">Other Charges
                                    </p>
                                    <p className="font-size-16 totalChargesLabel">{getTotalOtherCharges(key.otherCharges) + " " + key.setupFeesCurrency}</p>
                                    <div className="otherChargePopup">
                                      {key.otherCharges.map((j) => (
                                        <>
                                          <div className="border-bottom d-flex px-3 justify-content-between align-items-center pt-2">
                                            <label className="text-color-label">{j.name}</label>
                                            <label className="font-wt-600 text-color-value">{j.amount + " " + j.unit}</label>
                                          </div>
                                        </>
                                      ))}
                                    </div>
                                  </div>
                                  <div className="mt-2">
                                    <p className="mb-0 text-secondary  font-size-14">Total Charges</p>
                                    <span className="mb-0 text-secondary" style={{ fontSize: "12px" }}>($ 50,0000/30 days)</span>
                                    <p className="font-size-16 font-wt-600 text-color1">{getTotalCharges(key) + " " + key.setupFeesCurrency}</p>
                                  </div>
                                </>
                              )}
                              <p className="mt-2"><img className="ms-2" src={"assets/images/supplier-images/chat.png"} alt="" /><span className="font-size-14 text-dark ms-2">Chat with financer</span></p>
                            </div>
                          </div>
                        );
                      }) : null}
                    </div>
                    {/* <div className="col-12 mx-auto text-end">
                      <img onClick={() => firstindex !== 0 && Back()}
                        className={` cursor fs-5 ${firstindex === 0 ? "not-allowed d-none" : "cursor"
                          }`} src={"/assets/images/arrow-left.png"} alt="" />
                      <img onClick={() =>
                        Math.ceil(data.buyers_credit && data.buyers_credit.length / 4) !== slice && Next()
                      }
                        className={`cursor fs-5 ${slice === Math.ceil(data.buyers_credit && data.buyers_credit.length / 4)
                          ? "not-allowed d-none"
                          : "cursor"
                          }`} src={"/assets/images/arrow-right.png"} alt="arrow" />
                    </div> */}
                  </div>
                ) : null}

                {tab === 2 ? (

                  <div className='px-5 py-4 pt-5'>

                    {contractDocs.length ? contractDocs.map((item, index) => {
                      return (
                        <div className="col-md-8 mb-3">
                          <label className="font-size-14">{data["contractDoc" + index]["doc_name"]}</label>
                          <div className="row align-items-center ">
                            <div className="form-group col-md-8 mb-0">
                              <FileInput name={"contractDoc" + index} value={data["contractDoc" + index]} error={errors["contractDoc" + index]}
                                onChange={handleFile} isEditable={false}
                                onUploadCancel={() => setData({ ...data, ["contractDoc" + index]: null })}
                              />
                              {errors["contractDoc" + index] ? <div class="text-danger mt-2">
                                <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                <b>{errors["contractDoc" + index]}</b></div> : ''}
                            </div>
                            {data["contractDoc" + index] && data["contractDoc" + index].name && !data["contractDoc" + index].signatureId ? (
                              <div className="col-md-4 text-end">
                                <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data["contractDoc" + index], docSignName: "contractDoc" + index }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                              </div>
                            ) : null}
                            {data["contractDoc" + index] && data["contractDoc" + index].signatureId ? (
                              <div className="col-md-4 text-end">
                                <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )
                    }) : null}

                    {data.reqLetterDocument?.name ? (
                      <div className="col-md-8 mb-3">
                        <label className="font-size-14">Request Letter of Confirmation</label>
                        <div className="row align-items-center ">
                          <div className="form-group col-md-8 mb-0">
                            <FileInput name={"reqLetterDocument"} value={data.reqLetterDocument} error={errors.reqLetterDocument}
                              onChange={handleFile} isEditable={false}
                              onUploadCancel={() => setData({ ...data, "reqLetterDocument": null })}
                            />
                            {errors.reqLetterDocument ? <div class="text-danger mt-2">
                              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                              <b>{errors.reqLetterDocument}</b></div> : ''}
                          </div>
                          {data.reqLetterDocument && data.reqLetterDocument.name && !data.reqLetterDocument.signatureId ? (
                            <div className="col-md-4 text-end">
                              <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.reqLetterDocument, docSignName: "reqLetterDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                            </div>
                          ) : null}
                          {data.reqLetterDocument && data.reqLetterDocument.signatureId ? (
                            <div className="col-md-4 text-end">
                              <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : null}



                    <div className="col-md-8 mb-3">
                      <label className="font-size-14">Draft LC</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"draftLCDocument"} value={data.draftLCDocument} error={errors.draftLCDocument}
                            onChange={handleFile} isEditable={false}
                            onUploadCancel={() => setData({ ...data, "draftLCDocument": null })}
                          />
                          {errors.draftLCDocument ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.draftLCDocument}</b></div> : ''}
                        </div>
                        {data.draftLCDocument && data.draftLCDocument.name && !data.draftLCDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.draftLCDocument, docSignName: "draftLCDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.draftLCDocument && data.draftLCDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {data.invoiceDocument?.name ? (
                      <div className="col-md-8 mb-3">
                        <label className="font-size-14">Invoice</label>
                        <div className="row align-items-center ">
                          <div className="form-group col-md-8 mb-0">
                            <FileInput name={"invoiceDocument"} value={data.invoiceDocument} error={errors.invoiceDocument}
                              onChange={handleFile} isEditable={false}
                              onUploadCancel={() => setData({ ...data, "invoiceDocument": null })}
                            />
                            {errors.invoiceDocument ? <div class="text-danger mt-2">
                              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                              <b>{errors.invoiceDocument}</b></div> : ''}
                          </div>
                          {data.invoiceDocument && data.invoiceDocument.name && !data.invoiceDocument.signatureId ? (
                            <div className="col-md-4 text-end">
                              <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.invoiceDocument, docSignName: "invoiceDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                            </div>
                          ) : null}
                          {data.invoiceDocument && data.invoiceDocument.signatureId ? (
                            <div className="col-md-4 text-end">
                              <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : null}

                    {data.poDocument?.name ? (
                      <div className="col-md-8 mb-3">
                        <label className="font-size-14">Purchase Order</label>
                        <div className="row align-items-center ">
                          <div className="form-group col-md-8 mb-0">
                            <FileInput name={"poDocument"} value={data.poDocument} error={errors.poDocument}
                              onChange={handleFile} isEditable={false}
                              onUploadCancel={() => setData({ ...data, "poDocument": null })}
                            />
                            {errors.poDocument ? <div class="text-danger mt-2">
                              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                              <b>{errors.poDocument}</b></div> : ''}
                          </div>
                          {data.poDocument && data.poDocument.name && !data.poDocument.signatureId ? (
                            <div className="col-md-4 text-end">
                              <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.poDocument, docSignName: "poDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                            </div>
                          ) : null}
                          {data.poDocument && data.poDocument.signatureId ? (
                            <div className="col-md-4 text-end">
                              <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : null}

                    {data.buyerId && addMoreDoc.length ? addMoreDoc.map((item, index) => {
                      return (
                        <div className="col-md-8 mb-3">
                          <label className="font-size-14">Add More</label>
                          <div className="row align-items-center ">
                            <div className="form-group col-md-8 mb-0">
                              <FileInput name={"otherDoc" + index} value={data["otherDoc" + index]} error={errors["otherDoc" + index]}
                                onChange={handleFile} isEditable={true}
                                onUploadCancel={() => setData({ ...data, ["otherDoc" + index]: null })}
                              />
                              {errors["otherDoc" + index] ? <div class="text-danger mt-2">
                                <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                <b>{errors["otherDoc" + index]}</b></div> : ''}
                            </div>
                            {data["otherDoc" + index] && data["otherDoc" + index].name && !data["otherDoc" + index].signatureId ? (
                              <div className="col-md-4 text-end">
                                <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data["otherDoc" + index], docSignName: "otherDoc" + index }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                              </div>
                            ) : null}
                            {data["otherDoc" + index] && data["otherDoc" + index].signatureId ? (
                              <div className="col-md-4 text-end">
                                <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )
                    }) : null}

                  </div>
                ) : null}


                {tab === 3 ? (
                  <div className='p-5'>
                    <AdavancePaymentTab adavanceData={adavanceData} />
                  </div>
                ) : null}

                {/* {tab === 3 && data.tcAccepted ? null : ( */}
                <div className="px-5 pb-5">
                  <button type="button"
                    onClick={() => handleValidation(undefined)}
                    className={` new-btn w-20 py-2 px-2 text-white`}>
                    {tab === 2 ? `${onlyView ? "Update" : "Submit"}` : "Continue"}
                  </button>
                </div>
                {/* )} */}

              </div>
            </div>
          </main>
        </div>
      </div>
      {/* {limitinvoice && <FinanceInvoiceModal limitinvoice={limitinvoice} setLimitinvoice={setLimitinvoice} closeSuccess={closeSuccess} >
        <img src={"/assets/images/upload_done.png"} alt="" className="mb-3" />
        <p>Finance limit for invoice discounting assigned..</p>
      </FinanceInvoiceModal>} */}
      {experience && <FinanceInvoiceModal limitinvoice={experience} setLimitinvoice={setExperience} closeSuccess={closeExpFn}>
        <p>How was your experience with <span className="text-custom">TradeRebootFin</span> ?</p>
        <div className="d-flex gap-3 justify-content-center">
          {EmojiData.map((key, index) => {

            return (
              <>
                <img src={emoji === key.name ? key.fillimage : key.image} alt="angry" key={index} className="cursor " onClick={() => setEmoji(key.name)} />
              </>
            );
          })}

        </div>
        {emoji !== "" && < div className="my-3 col-9 mx-auto text-end border px-0 send p-2">
          <textarea className="form-control border-0 bg-transparent" id="exampleFormControlTextarea1" rows="3" placeholder="Write a review..."></textarea>
          <img className="cursor px-1" src={"/assets/images/Send.png"} alt="" onClick={() => closeExpFn()} />
        </div>}
      </FinanceInvoiceModal >}


      {tooltip && <TooltipModal tooltip={tooltip} setTooltip={setTooltip}
        data={{ showRemark: data.showRemark, showLenderName: data.showLenderName }} />}

      {signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
        setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />}

    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(LcSigncontract)