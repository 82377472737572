import React from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
} from "react-simple-maps";

const geoUrl = "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json";

const WorldMap = ({ data }) => {
  return (
    <ComposableMap height={340}>
      {data && data.length &&
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const country = data.findIndex(countrydata => countrydata.DESTINATION_COUNTRY.toLowerCase() === geo.properties.name.toLowerCase())
              return <Geography key={geo.rsmKey} geography={geo} fill={country <= 4 && country >= 0 ? "#FFA502" : country >= 5 ? "#00D2D3" : "#F8F8F8"} stroke={"#434343"} strokeWidth={0.29} textRendering={true} >
                <text x="20" y="35" class="small">My</text>
              </Geography>
            })
          }
        </Geographies>
      }
    </ComposableMap>
  );
};

export default WorldMap;
