import React, { useEffect, useRef, useState } from 'react';
import { Calendar } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { connect } from 'react-redux';
import { Legend, Line, LineChart, ResponsiveContainer, XAxis, YAxis, Tooltip, AreaChart } from 'recharts';
import call from '../../service';
import NewTablev2 from '../../utils/newTablev2';
import Header from '../partial/header';
import HeaderV2 from '../partial/headerV2';
import Sidebar, { traderTypeIds } from '../partial/sidebar';
import SideBarV2 from '../partial/sideBarV2';
import CalendarTab from './components/CalendarTab';
import ExporterSummaryChart from './components/ExporterSummaryChart';
import Details from './components/Details';
import FinancerDv2Taskupdate from './components/financerDv2taskupdate';
import Shipmentschart from './components/Shipmentschart';
import Stats from './components/Stats';
import TaskUpdate from './components/taskUpdate';
import { SupplierData, FinanceData, SupplierDetailsData, FinancerDetailsData } from "./DataJson"
import FinancerChart from './components/FinancerChart';
import FinancerDisbursement from './components/FinancerDisbursementChart';
import { AdminDashbord } from './AdminDashboard';
import { ToastContainer } from 'react-toastify';
import CRMDashboard from './CRMDashboard';
import { CustomSelect, NewInput, NewSelect } from '../../utils/newInput';
import moment from 'moment';
import MultipleSelect from '../../utils/MultipleSelect';

const DashboardV2 = ({ userTokenDetails, navToggleState }) => {
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const ttvExporterCode = userTokenDetails.ttvExporterCode ? userTokenDetails.ttvExporterCode : ''
  const [graphdata, setgraphdata] = useState([])
  const [supplierCountdata, setsupplierCountdata] = useState([])

  // console.log("uuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu", userTypeId, userTokenDetails);

  const [FinCountdata, setFinCountdata] = useState([])
  const [dischartdata, setdischartdata] = useState([])
  const [AdminTab, setAdminTab] = useState('Admin')
  const [selectedDateFilter, setselectedDateFilter] = useState('Overall')
  const [showCalendar, setshowCalendar] = useState(false)
  const [dateRange, setDateRange] = useState()
  const box = useRef(null)
  const [salesPerson, setSalesPerson] = useState([])
  const userPermissionsForSubAdmin = JSON.parse(userTokenDetails.UserAccessPermission || "{}")
  const [showLoader, setshowLoader] = useState(false)
  const [tokenDetails, setTokenDetails] = useState(userTokenDetails)
  const [selectedUser, setSelectedUser] = useState(null)
  const [data, setdata] = useState({})
  const [dateFilters, setdateFillters] = useState([
    { name: 'Overall', val: "Overall" },
    { name: 'Previous Month', val: "Previous Month" },
    { name: 'Previous Week', val: "Previous Week" },
    { name: 'Yesterday', val: "Yesterday" },
    { name: 'Today', val: "Today" },
    { name: 'Current Week', val: "Current Week" },
    { name: 'Current Month', val: "Current Month" },
    { name: 'Custom', val: "Custom" }
  ])
  let onlyShowForUserId = userPermissionsForSubAdmin?.mainAdmin ? undefined : userId

  useEffect(() => {
    const tabName = localStorage.getItem('admin_report_name');
    setTab(tabName)
  }, [])
  const handleOutsideClick = (event) => {
    let eventTargetStr = event?.target?.outerHTML
    console.log("handleOutsideClick", box.current, event?.target?.outerHTML);
    if (box && box.current && !box.current.contains(event.target)) {
      setshowCalendar(false)
    }
  }
  useEffect(() => {
    let startDate
    let endDate
    const today = new Date();
    if (selectedDateFilter === 'Today') {
      startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, 0, 0, -1);
    } else if (selectedDateFilter === 'Yesterday') {
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      startDate = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate());
      endDate = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate(), 23, 59, 59, 999);
    }
    else if (selectedDateFilter === 'Previous Week') {
      startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
      endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    } else if (selectedDateFilter === 'Previous Month') {
      startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      endDate = new Date(today.getFullYear(), today.getMonth(), 0);
    } else if (selectedDateFilter === 'Current Week') {
      startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
      endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (6 - today.getDay()));
    } else if (selectedDateFilter === 'Current Month') {
      startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    } else if (selectedDateFilter === 'Custom') {
      setshowCalendar(true)
      return
    } else if (selectedDateFilter === 'Overall') {
      startDate = new Date(2021, 1, 1);
      endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    }
    setDateRange({
      from: moment(startDate).format('YYYY-MM-DD'),
      to: moment(endDate).format('YYYY-MM-DD')
    })

  }, [selectedDateFilter])
  const handleMultiSelectchange = (e, name, val, singleSelect) => {
    if (singleSelect) {
      setdata({
        ...data,
        [name]: e?.[0]?.[val] ? e.reverse()?.[0]?.[val] : null
      })
    }
    else {
      setdata({
        ...data,
        [name]: Array.isArray(e) ? e.map((x) => x[val]) : []
      });
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [])
  const getDashboardCounts = () => {
    call('POST', 'getdashboardCounts', { userId, ttvExporterCode }).then(result => {
      console.log('success in getdashboardCounts', result)
      setsupplierCountdata(result)
    }).catch(e => {
      console.log('error in getdashboardCounts', e)
      setsupplierCountdata(e)
    })
  }
  const getDashboardCountsFin = () => {
    call('POST', 'getFindashboardCounts', { userId }).then(result => {
      console.log('success in getdashboardCounts', result)
      setFinCountdata(result)
    }).catch(e => {
      console.log('error in getdashboardCounts', e)
      setFinCountdata(e)
    })
  }
  const getExporterSummary = () => {
    let reqObj = {
      "userId": userId,
      "startYear": 2017,
      "endYear": new Date().getFullYear(),
      ttvExporterCode
    }
    call('POST', 'getExporterSummary', reqObj).then(result => {
      console.log('success in getExporterSummary', result)
      setgraphdata(result)
    }).catch(e => {
      console.log('error in getdashboardCounts', e)
    })
  }
  const getdisbursementchartdata = () => {
    let reqObj = {
      "userId": userId,
      "startYear": 2017,
      "endYear": new Date().getFullYear()
    }
    call('POST', 'getdisbursementchart', reqObj).then(result => {
      setdischartdata(result)
    }).catch(e => {
      console.log('error in getdisbursementchart', e)
    })
  }
  useEffect(() => {
    if (userTypeId === 19) {
      setTab(1)
      getDashboardCounts()
      getExporterSummary()
    } else if (userTypeId === 8) {
      setTab(2)
      getDashboardCountsFin()
      getdisbursementchartdata()
    }
    else if (userTypeId === 3) {
      window.location = '/sign-invoice-agreement'
    }
    else {
      setTab(3)
    }

  }, [])

  useEffect(() => {

    if (onlyShowForUserId) {
      let reqObj = {
        parentId: onlyShowForUserId
      }
      console.log('onlyshowfor userid', reqObj);
      call("POST", 'getSubAdminUser', reqObj).then(res => {
        console.log('onlyshowfor userid', res);
        setshowLoader(false)
        setSalesPerson(res.data)
      }).catch(err => setshowLoader(false))
    } else {
      call("POST", 'getSubAdminUser', {}).then(res => {
        setshowLoader(false)
        setSalesPerson(res.data)
      }).catch(err => setshowLoader(false))
    }
  }, [])

  //1 - Exporter
  //2 - Financier
  //3 - Admin
  const [tab, setTab] = useState(null);
  useEffect(() => {
    console.log('discbarrrtttttttttt', salesPerson, onlyShowForUserId)
  }, [salesPerson])
  //console.log('discbarrrtttttttttt', salesPerson, onlyShowForUserId)
  return (
    <>
      <div className="container-fluid">
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />

        <div className="row">
          <SideBarV2 state="dashboard" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Dashboard"}
              userTokenDetails={userTokenDetails} />

          </main>

        </div>
      </div>

    </>
  )

}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(DashboardV2)