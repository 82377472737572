import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { inspectionTypeIds, traderTypeIds } from '../partial/sidebar';
import call from '../../service';
import toastDisplay from '../../utils/toastNotification';


const popupTypeLabel = {
  0: "Send Offer",
  1: "Update Offer"
}



export const InsepctionNegotiationPopup = ({ offer_data, closePopup, userId, userTypeId, popupType }) => {

  const [offerData, setOfferData] = useState(offer_data[0].offer_details_obj)
  const [offerExtraData, setOfferExtraData] = useState(offer_data[0])
  const [offerTab, toggleOfferTab] = useState(0)
  const [errorData, setErrorData] = useState({})
  const [currencies, setCurrencies] = useState([])
  const [commKeys, setCommKeys] = useState([])
  const [commSpecs, setCommSpecs] = useState({})
  const [updatedValues, setUpdatedValues] = useState({})
  const [data, setData] = useState({})

  const astrix = <span className="required-field text-danger">*</span>


  const getOfferStatusLabel = data => {
    // console.log("getOfferStatusLabel", data);
    if (!data.updated_at) {
      return "Offer Received"
    }
    if (data.agreed_by_trader && data.agreed_by_agency) {
      return "Inspection locked"
    }
    if (data.agreed_by_trader) {
      if (data.updated_by == userId) {
        return "Updated & Agreed by you"
      }
      else {
        return "Agreed by you"
      }
    }
    if (data.agreed_by_agency) {
      if (data.updated_by && data.updated_by != userId) {
        return "Updated & Agreed by inspection company"
      }
      else {
        return "Agreed by inspection company"
      }
    }
    if (data.updated_by == userId) {
      return "Updated by you"
    }
    else {
      return "Updated by inspection company"
    }
  }

  const getInspectionOfferStatusLabel = data => {
    // console.log("getInspectionOfferStatusLabel", data);
    if (!data.updated_at) {
      return "Offer Sent"
    }
    if (data.agreed_by_trader && data.agreed_by_agency) {
      return "Inspection locked"
    }
    if (data.agreed_by_agency) {
      if (data.updated_by == userId) {
        return "Updated & Agreed by you"
      }
      else {
        return "Agreed by you"
      }
    }
    if (data.agreed_by_trader) {
      if (data.updated_by && data.updated_by != userId) {
        // console.log("debugging", data);
        return "Updated & Agreed by trader company"
      }
      else {
        return "Agreed by trader company"
      }
    }
    if (data.updated_by == userId) {
      return "Updated by you"
    }
    else {
      return "Updated by trader company"
    }
  }


  useEffect(() => {
    call("get", "getcurrencylist").then((result) => {
      setCurrencies(result);
    }).catch((e) => {
      // console.log("error in getcurrencylist", e);
    });
  }, [])

  useEffect(() => {
    if (offerData.commodity_specs) {
      setCommKeys(Object.keys(offerData.commodity_specs))
      setCommSpecs(offerData.commodity_specs)
    }
  }, [offerData.commodity_specs])

  const handleChange = event => {
    event.persist()
    setOfferData({ ...offerData, [event.target.name]: event.target.value })
    setErrorData({ ...errorData, [event.target.name]: event.target.value ? "" : "Field is required" })
  }

  const handleAddedSpecs = (event, item) => {
    event.persist()
    let tempCommSpecs = commSpecs
    tempCommSpecs[item][event.target.name] = event.target.value
    setUpdatedValues({ ...updatedValues, [event.target.name + ":" + item]: event.target.value })
    setCommSpecs(tempCommSpecs)
  }

  const handleSpecData = event => {
    setData({ ...data, [event.target.name]: event.target.value })
  }

  function addSpec() {
    let specObj = {
      name: data.specName,
      rejectionSymbol: data.rejAt,
      rejectionValue: data.rejVal,
      value: data.typicalVal
    }
    let specShortName = (data.specName).split(/\s/).join('')
    let tempCommKeys = commKeys
    tempCommKeys.push(specShortName)
    setCommKeys(tempCommKeys)
    setCommSpecs({ ...commSpecs, [specShortName]: specObj })
    setData({ ...data, "specName": "", "rejAt": "", "rejVal": "", "typicalVal": "" })
  }

  function deleteSpec(delIndex) {
    let tempCommSpecs = commSpecs
    delete tempCommSpecs[commKeys[delIndex]]
    setCommSpecs(tempCommSpecs)
    setCommKeys(commKeys.filter((item, index) => {
      if (index != delIndex) {
        return item
      }
    }))
  }

  function validateFields(type) {
    let validateFields = ["laycan_start_date", "laycan_end_date", "quantity", "currency", "inspection_quote", "offer_valid_till"]
    let err = {}
    for (let i = 0; i < validateFields.length; i++) {
      if (!offerData[validateFields[i]]) {
        err[validateFields[i]] = "Field is required"
      }
    }
    if (!commKeys.length) {
      err["commodity_specs"] = "Atleast 1 commodity specs required"
    }
    setErrorData(err)

    if (!Object.keys(err).length) {
      if (type === "sendoffer") {
        call("post", "sendOfferForInspection", {
          booking_id: offerExtraData.booking_id, userId, offer_details_obj: {
            laycan_start_date: offerData.laycan_start_date, laycan_end_date: offerData.laycan_end_date, quantity: offerData.quantity,
            currency: offerData.currency, inspection_quote: offerData.inspection_quote, offer_valid_till: offerData.offer_valid_till,
            commodity_specs: commSpecs, commodity_name: offerExtraData.commodity_name
          }
        }).then((result) => {
          // console.log("sendOfferForInspection then", result);
          toastDisplay(result, "success")
          closePopup()
        }).catch((e) => {
          // console.log("error in sendOfferForInspection", e);
          toastDisplay("Something went wrong", "error")
        });
      }
      else {
        call('POST', 'updateInspectionOffer', {
          userTypeId, type, userId, offerId: offerExtraData.id,
          offer_details_obj: { ...offerData, commodity_specs: commSpecs },
          updatedAt: moment.utc(new Date()).format('YYYY-MM-DD hh:mm:ss')
        }).then((result) => {
          // console.log("updateInspectionOffer", result);
          toastDisplay(result, "success")
          closePopup()
        }).catch((e) => {
          // console.log('error in updateShipment', e);
          toastDisplay("Something went wrong", "error")
        })
      }
    }
  }

  return (
    <div className={"modal show"} id="negotiationPopup">
      <div className="modal-dialog d-flex modal-xl border-inner" id="parent_class">

        <div className="modal-content">
          <div className="modal-header primary">
            <h4 className="modal-title text-white">{popupTypeLabel[popupType]}</h4>
            <button type="button" className="close" onClick={closePopup}>×</button>
          </div>


          <div className="modal-body calc-modal modal-view">

            <div className="calc-inner-modal">
              {traderTypeIds.includes(userTypeId) ? (
                <ul className="nav nav-tabs-custom" id="myTab" role="tablist">
                  {offer_data.map((item, index) => (
                    <li>
                      <a className={"nav-link pl-4 pr-4 cursor-pointer " + (offerTab === index ? " active show" : "")} onClick={() => {
                        setOfferData(offer_data[index]["offer_details_obj"])
                        setOfferExtraData(offer_data[index])
                      }}>{"Offer " + (index + 1) + " | " + offerExtraData.company_name + " | " + offerData.inspection_quote + " " + offerData.currency} </a>
                    </li>
                  ))}
                </ul>
              ) : null
              }

              <div className='row mx-2'>
                <div className="col-md-6">
                  <div className="row form-group">
                    <label className="col-md-12">Layan Start Date {astrix} </label>
                    <div className="col-md-12">
                      <input type="date" className="form-control" name={"laycan_start_date"}
                        onChange={handleChange} value={offerData.laycan_start_date}
                      />
                      {errorData.laycan_start_date && <p className="text-danger error-contract">{errorData.laycan_start_date}</p>}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="row form-group">
                    <label className="col-md-12">Layan End Date {astrix} </label>
                    <div className="col-md-12">
                      <input type="date" className="form-control" name={"laycan_end_date"}
                        onChange={handleChange} value={offerData.laycan_end_date}
                      />
                      {errorData.laycan_end_date && <p className="text-danger error-contract">{errorData.laycan_end_date}</p>}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="row form-group">
                    <label className="col-md-12">Total Quantity (mt) {astrix} </label>
                    <div className="col-md-12">
                      <input type="number" className="form-control" name={"quantity"}
                        onChange={handleChange} value={offerData.quantity}
                      />
                      {errorData.quantity && <p className="text-danger error-contract">{errorData.quantity}</p>}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="row form-group">
                    <label className="col-md-12">Currency {astrix} </label>
                    <div className="col-md-12">
                      <select className="form-control" name="currency" value={offerData.currency} id="currency" onChange={handleChange}>
                        <option value="" selected>Select Currency</option>
                        {currencies.map(val =>
                          <option value={val.symbol} >{val.name + " (" + val.code + ") (" + val.symbol + ")"}</option>
                        )}
                      </select>
                      {errorData.currency && <p className="text-danger error-contract">{errorData.currency}</p>}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="row form-group">
                    <label className="col-md-12">Inspection Quote {astrix} </label>
                    <div className="col-md-12">
                      <input type="number" className="form-control" name={"inspection_quote"}
                        onChange={handleChange} value={offerData.inspection_quote}
                      />
                      {errorData.inspection_quote && <p className="text-danger error-contract">{errorData.inspection_quote}</p>}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="row form-group">
                    <label className="col-md-12">Offer Valid Till {astrix} </label>
                    <div className="col-md-12">
                      <input disabled={!inspectionTypeIds.includes(userTypeId)} type="date" className="form-control" name={"offer_valid_till"}
                        onChange={handleChange} value={offerData.offer_valid_till}
                      />
                      {errorData.offer_valid_till && <p className="text-danger error-contract">{errorData.offer_valid_till}</p>}
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mx-2'>
                <h5 className="mb-0 my-4 col-md-12">{"Specification (" + offerData.commodity_name + ")"}</h5>
                {commKeys.length ? commKeys.map((item, index) => {
                  return (
                    <>
                      <div className="col-md-3 mb-3">
                        <label className="mb-2">{commSpecs[item] && commSpecs[item]["name"] + " - (Typical Value)"}</label>
                        <input name={'value'} className="form-control" onChange={(event) => handleAddedSpecs(event, item)}
                          value={updatedValues["value:" + item] || (commSpecs[item] && commSpecs[item]["value"])}
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="mb-2">Rejection At </label>
                        <select name={"rejectionSymbol"} className="form-control" onChange={(event) => handleAddedSpecs(event, item)}
                          value={updatedValues["rejectionSymbol:" + item] || (commSpecs[item] && commSpecs[item]["rejectionSymbol"])}
                        >
                          <option value="" selected>Select</option>
                          <option value={"!"}>{"NA"}</option>
                          <option value={"<"}>{"Below"}</option>
                          <option value={">"}>{"Above"}</option>
                          <option value={"-"} >{"Range"}</option>
                        </select>
                      </div>
                      <div className="col-md-3">
                        <label className="mb-2">Rejection Value</label>
                        <input name={"rejectionValue"} className="form-control" onChange={(event) => handleAddedSpecs(event, item)}
                          value={updatedValues["rejectionValue:" + item] || (commSpecs[item] && commSpecs[item]["rejectionValue"])}
                        />
                      </div>
                      <div className="col-md-3 mb-3 align-self-end">
                        <button type="button "
                          className="btn btn-danger btn-sm" onClick={() => deleteSpec(index)}>Delete</button>
                      </div>
                    </>
                  )
                }) :
                  <label className="col-md-12">{"No specifications added"}</label>}

                {errorData.commodity_specs && <p className="text-danger error-contract col-md-12">{errorData.commodity_specs}</p>}
              </div>

              <div className='row mx-2 mb-4'>
                <h5 className="mb-0 my-4 col-md-12">{"Add New Specification "}</h5>

                <div className="col-md-2">
                  <label className="mb-2">Specification Name {astrix}</label>
                  <input className="form-control"
                    name="specName" value={data.specName} onChange={handleSpecData} />
                </div>

                <div className="col-md-2">
                  <label className="mb-2">Typical Value {astrix}</label>
                  <input className="form-control"
                    name="typicalVal" value={data.typicalVal} onChange={handleSpecData} />
                </div>

                <div className="col-md-2">
                  <label className="mb-2">Rejection At {astrix}</label>
                  <select className="form-control" name="rejAt" value={data.rejAt} id="rejAt" onChange={handleSpecData}>
                    <option value="" selected>-Select-</option>
                    <option value={"!"}>{"NA"}</option>
                    <option value={"<"}>{"Below"}</option>
                    <option value={">"}>{"Above"}</option>
                    <option value={"-"}>{"Range"}</option>
                  </select>
                </div>

                <div className="col-md-2">
                  <label className="mb-2">Rejection Value {astrix}</label>
                  <input className="form-control"
                    name="rejVal" value={data.rejVal} onChange={handleSpecData} />
                </div>

                <div className="col-md-2 mb-1 align-self-end">
                  <button type="button" disabled={!(data.specName && data.typicalVal && data.rejAt && data.rejVal)}
                    style={{ "background-color": "#138496", color: '#fff' }}
                    className="btn btn-sm" onClick={() => addSpec()}>Add +</button>
                </div>
              </div>

              {popupType != 0 ? (
                <div className="row mx-4 bg-primary text-white py-2 my-4">
                  <label className="form-check-label col-md-4">{(traderTypeIds.includes(userTypeId) ? "Received On : " : "Sent On : ")
                    + moment(offerExtraData.created_at).format("YYYY-MM-DD hh:mm:ss")}</label>
                  {offerExtraData.updated_at ? (
                    <label className="form-check-label col-md-4">{"Updated On : " + moment(offerExtraData.updated_at).format("YYYY-MM-DD hh:mm:ss")}</label>
                  ) : null}
                  {traderTypeIds.includes(userTypeId) ? (
                    <label className=" form-check-label col-md-4">{"Status : " + (getOfferStatusLabel(offerExtraData))}</label>
                  ) : (
                    <label className=" form-check-label col-md-4">{"Status : " + (getInspectionOfferStatusLabel(offerExtraData))}</label>
                  )}
                </div>
              ) : null}
            </div>
          </div>

          <div className="modal-footer primary">
            <button type="button" className="btn btn-dark btn-sm" onClick={closePopup} id="previd">Cancel</button>
            {popupType === 0 ? (
              <button type="button" className="btn btn-info btn-sm" onClick={() => validateFields("sendoffer")}
                id="nextid">{popupTypeLabel[popupType]}</button>
            ) : null}

            {traderTypeIds.includes(userTypeId) && !(offerData.offer_valid_till && offerData.offer_valid_till >= moment(new Date()).format("YYYY-MM-DD")) ? (
              <button type="button" disabled className="btn btn-danger btn-sm" id="nextid">{"Offer Expired"}</button>
            ) : (
              <>
                {popupType === 1 ? (
                  <>
                    <button type="button" className="btn btn-info btn-sm" onClick={() => validateFields("update")} id="nextid">{"Update"}</button>
                    <button type="button" className="btn btn-danger btn-sm" onClick={() => validateFields("agree")} id="nextid">{"Agree"}</button>
                    <button type="button" className="btn btn-primary btn-sm" onClick={() => validateFields('update&agree')} id="nextid">{"Update & Agree"}</button>
                  </>
                ) : null}

              </>
            )}
          </div>
        </div>
      </div>
    </div>

  )
}