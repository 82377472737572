import React, { useState } from "react";
import Select, { components } from "react-select";

const errorStyles = {

}
const InputOption = ({
  getStyles,
  isCheckableList,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);
  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex "
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      {isCheckableList ?
        <div className="d-flex flex-row align-items-center gap-3">
          <img src={`assets/images/${isSelected ? 'checked-green' : 'empty-check'}.png`} />
          <label className="font-size-14 mb-0 font-wt-500">{children}</label>
        </div>
        :
        children
      }


    </components.Option>
  );
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: 'none', // Remove the border
    boxShadow: 'none', // Example focus styling
    padding: 0
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0, // Remove padding
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    fontSize: '12px', // Set the desired font size
    fontWeight: 400,
    paddingRight: '1.2rem',
    whiteSpace: 'normal',
    background: '#fff',
    color: '#0C0C0C'
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    display: 'none', // Hide the remove (cross) icon
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: "12px",
    fontWeight: '500',
    color: "#000"
  }),
  menu: (provided, state) => ({
    ...provided,
    position: 'absolute',
    top: '100%',
    left: '0',
    width: "15rem"
  })
};

const MultipleSelectForTable = ({
  Label,
  Id,
  optiondata,
  error,
  isAstrix,
  onChange,
  name,
  value,
  labelKey,
  valKey,
  filterOption,
  onInputChange,
  isDisabled,
  onClick,
  hideLabel,
  isCheckableList
}) => {
  const [showLabel, toggleLabel] = useState(false)
  const astrix = <span className="required-field text-danger">*</span>;
  let defaultPlaceHolder = Label || "Select"
  return (
    <div
      onClick={onClick} className="">
      {showLabel || value.length ? (
        <label htmlFor={Id} className="input-label">{Label} {isAstrix ? astrix : ""}</label>) : null}
      <Select
        isDisabled={isDisabled}
        filterOption={filterOption}
        onInputChange={onInputChange}
        isMulti
        styles={customStyles}
        // styles={error ? errorStyles : {
        //   border: 'none',
        //   ...customStyles,
        //   multiValue: (base) => ({
        //     ...base,
        //     ...customStyles

        //   }),
        //   input: (base) => ({
        //     ...base,
        //     borderTopWidth: "0px",
        //     borderLeftWidth: "0px",
        //     borderRightWidth: "0px",
        //     borderRadius: "0px",
        //     border: 0
        //   })
        // }}
        name={name}
        options={optiondata}
        components={hideLabel ? {
          DropdownIndicator: (props) => {
            return (
              <img
                style={{
                  top: "0.6rem",
                  right: "1rem",
                  width: "1.1rem"
                }}
                className="position-absolute" src="assets/images/arrow.png" />
            )
          }, IndicatorSeparator: () => null,
          MultiValueLabel: () => null,
          MultiValueRemove: () => null,
          Option: (props) => {
            return <InputOption {...props} isCheckableList={isCheckableList} />
          }

        } : {
          DropdownIndicator: (props) => {
            return (
              <img
                style={{
                  top: "0.6rem",
                  right: "0.2rem",

                  width: "1.1rem"
                }}
                className="position-absolute" src="assets/images/arrow.png" />
            )
          }, IndicatorSeparator: () => null,
          Option: (props) => {
            return <InputOption {...props} isCheckableList={isCheckableList} />
          }
        }}
        // className="multi-select-input"
        // classNamePrefix="select"
        onFocus={() => toggleLabel(true)}
        onBlur={() => toggleLabel(false)}
        isClearable={false}
        onChange={onChange}
        value={optiondata.filter((obj) => value.includes(obj[valKey]))}
        getOptionLabel={(option) => option[labelKey]}
        getOptionValue={(option) => option[valKey]}
        placeholder={(showLabel || value.length) ? "" : `${defaultPlaceHolder} ${isAstrix ? " *" : ""}`}
        MultiValueLabel={""}
        closeMenuOnSelect={!isCheckableList}
        hideSelectedOptions={!isCheckableList}
      />
      {error ? <div class="text-danger mt-2 font-size-12">
        <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
        <b>{error}</b></div> : ''}
    </div>
  );
};
export default MultipleSelectForTable;
