import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { ToastContainer } from "react-toastify";
import swal from 'sweetalert';
import HeaderV2 from "../partial/headerV2";
import SideBarV2 from "../partial/sideBarV2";
import { FileInput } from "../../utils/FileInput";
import { InputWithSelect, NewInput, NewSelect } from "../../utils/newInput";
import { astrix, convertImageToPdf, getDocDetails, most_used_currencies } from "../../utils/myFunctions";
import toastDisplay from "../../utils/toastNotification";
import call from "../../service";
import { NewTable } from "../../utils/newTable";
import CheckBuyerHealth, { CustomProgressBar } from "./checkBuyerHealth";
import { useHistory } from "react-router";
import FinanceDetails from "../viewProfile/components/FinanceDetails";
import { KYCUploads } from "../viewProfile/viewProfile";
import ViewBuyerDetails from "./viewBuyerDetails";
const addBuyerTabs = [
  { name: "Buyer List" },
  { name: "Finance Details" },
  { name: "Company Details" },
  { name: 'KYC Details' },
  { name: "Credit Report" },
  { name: "Other Details" }
]

const reviewForm = [
  { "name": "Organization Name", val: "company_name" },
  { "name": "Average Existing Turnover", val: "minExisting", unit: "currency" },
  { "name": "Contact Number", val: "contact_number", unit: "phone_code" },
  { "name": "Expected Turnover", val: "minExpected", unit: "currency" },
  { "name": "Email Id", val: "email_id" },
  { "name": "Website", val: "website" },
  { "name": "Organization Type", val: "organization_type" },
  { "name": "Country of Incorporation", val: "country_of_incorporation" },
  { "name": "Industry Type", val: "industry_type" },
  { "name": "Country of Operation", val: "country_of_operation" },
  { "name": "GST No.", val: "gst_vat_no" },
  { "name": "Address", val: "user_address" },
  { "name": "PAN No.", val: "pan_no" },
  { "name": "Aadhar No.", val: "aadhar_no" },
  { "name": "IEC No.", val: "iec_no" },
  { "name": "CIN No.", val: "cin_no" }
]

const reviewForm2 = [
  { "name": "Account Holder Name", val: "beneficiaryAccountName" },
  { "name": "Account No.", val: "beneficiaryAccountNo" },
  { "name": "Bank Name", val: "beneficiaryBankName" },
  { "name": "IFSC Code", val: "beneficiaryIfscCode" },
  { "name": "Swift Code", val: "beneficiarySwiftCode" },
  { "name": "Correspondant Bank Name", val: "correspondentBankName" },
  { "name": "Correspondant Account No.", val: "correspondentAccountNumber" }
]

const ProgressBars = [
  { name: "Duns & bradsteet", min: 1, max: 800, key: "failure_score" },
  { name: "Equifax", min: 1, max: 800, key: "delinquency_score" },
  { name: "Experian", min: 1, max: 800, key: "paydex_scrore" },
  { name: "Trans union", min: 1, max: 800, key: "paydex_scrore" },
  { name: "S&P global", min: 1, max: 800, key: "paydex_scrore" }
]


const SupplierDetails = ({ userTokenDetails, navToggleState }) => {

  let history = useHistory()

  const queryParams = new URLSearchParams(window.location.search)
  const supplierId = queryParams.get("id")

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)

  const [tab, setTab] = useState(0)

  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [userBank, setUserBank] = useState([])
  const [buyerList, setBuyerList] = useState([])
  const [buyerTableData, setBuyerTableData] = useState([])
  const [shareHoldersTableData, setShareHoldersTableData] = useState([])
  const [finTableData, setFinTableData] = useState([])
  const [checkBuyerHealth, setCheckBuyerHealth] = useState({ show: false, data: {} })
  const [detailsdata, setdetailsData] = useState({ isVisible: false, data: null })
  const [kycdetails, setKYCdetails] = useState({})
  const [companyDocs, setCompanyDocs] = useState([])
  const [viewBuyerDetails, setViewBuyerDetails] = useState({ show: false, data: {} })

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;
  const userIdentifier = userTokenDetails.identifier ? userTokenDetails.identifier : null

  async function toggleViewBuyerDetails(buyerData) {
    setViewBuyerDetails({ show: true, data: buyerData })
  }

  useEffect(() => {
    //Get Buyer list start
    let objectAPI = {
      "userId": supplierId,
      // "userEmail": userEmail,
      "userTypeId": 19,
      "type": 'all',
      "onlySharedWith": userId
    }
    setshowLoader(true)
    call('POST', 'getBuyersDetail', objectAPI).then((result) => {
      setshowLoader(false)
      console.log('running getBuyersDetail api-->', result);
      setBuyerList(result.buyerData);
      setBuyerTableData(formatDataForTable(result.buyerData))
    }).catch((e) => {
      // console.log('error in getBuyersDetail', e);
      setshowLoader(false)
    });
    //Get buyer list end

    // Get user fin start
    call('POST', 'getuserfinancegrid', {
      "currentPage": 1,
      "resultPerPage": 20,
      "manualUserId": supplierId,
      "userTypeId": 19
    }).then((result) => {
      console.log('running getuserfinancegrid api-->', result);
      setFinTableData(formatfinDataFortable(result.finData))
    }).catch((e) => {
      console.log('error in getuserfinancegrid', e);
    })
    // Get user fin end

    // Get user details start
    call('POST', 'getUserDetailsById', {
      "contactUserId": supplierId
    }).then((result) => {
      console.log('running getUserDetailsById api-->', result);
      let userDataRes = result.length ? result[0] : {}
      call('POST', 'getUserDetailsExtra', {
        "userId": supplierId
      }).then(async (result) => {
        console.log('running getUserDetailsExtra api-->', result);
        userDataRes = {
          ...userDataRes, ...result["userDetails"], "currency": "USD"
        }
        setData(userDataRes)
        let businessDocuments = []
        for (let i = 0; i <= result.docDetails.length - 1; i++) {
          let BusinessDoc = result.docDetails[i]
          const res = await getDocDetails(BusinessDoc.id)
          if (res.filebase64) {
            businessDocuments.push({
              doc_name: BusinessDoc.doc_name,
              ...res
            })
          }
        }
        console.log('BusinesssDocsss', businessDocuments)
        setCompanyDocs(businessDocuments)
        let reqObj = {
          "email": userDataRes.email_id,
          "kyc": true
        }
        call('POST', 'getuserprofiledata', reqObj).then(async response => {
          console.log('Sucesss in getuserprofiledata', response)
          let kycDocs = {}
          for (let i = 0; i <= response.userKYCData.length - 1; i++) {
            let KYCdata = response.userKYCData[i]
            //let KYCdata = resultdata[i]
            const res = await getDocDetails(KYCdata.tbl_doc_id)
            if (res.filebase64) {
              kycDocs[KYCdata.doc_name.split(' ').join('_')] = {
                ...res,
                name: KYCdata.file_name
              }
            }
          }
          setKYCdetails(kycDocs)
          console.log('KYCdocs', kycDocs)
        }).catch(e => {
          console.log('Error in getuserprofiledata', e)
        })
      }).catch((e) => {
        // console.log('error in getUserDetailsExtra', e);
      })
    }).catch((e) => {
      // console.log('error in getUserDetailsById', e);
    })
    // Get user details end

    // Get share holders start
    call('POST', 'getShareHolderGrid', {
      "currentPage": 1,
      "resultPerPage": 20,
      "userId": supplierId,
      "userTypeId": 19
    }).then((result) => {
      console.log('running getShareHolderGrid api-->', result);
      setShareHoldersTableData(formatShareHolderDataFortable(result.message))
    }).catch((e) => {
      console.log('error in getShareHolderGrid', e);
    })
    // Get share holders end
  }, [])

  useEffect(() => {
    if (data.email_id) {
      // Get user banks start
      call('POST', 'getUserBanks', { email: data.email_id, inProfile: true }).then((result) => {
        console.log('running getUserBanks api -->', result);
        setUserBank(result.message);
      }).catch((e) => {
        // console.log('error in getUserBanks', e);
      })
      // Get user banks end
    }
  }, [data])


  function formatShareHolderDataFortable(data) {
    let tableData = []
    let row = []
    data.forEach((item, index) => {
      row[0] = item.name
      row[1] = item.email_id
      row[2] = item.address_line1 || "NA"
      row[3] = item.share_in_company + " %"
      row[4] = <div>
        <label
          className="font-wt-600 text-color1 font-size-16"
        >View documents</label>
      </div>
      tableData.push(row)
      row = []
    })
    return tableData
  }

  function formatfinDataFortable(data) {
    let tableData = []
    let row = []
    data.forEach((item, index) => {
      row[0] = item.year
      row[1] = item.turnover
      row[2] = item.currency + " " + item.netprofit
      row[3] = item.currency + " " + item.networth
      row[4] = <div>
        <label
          className="font-wt-600 text-color1 font-size-16 cursor" onClick={async () => {
            setshowLoader(true)
            setdetailsData({
              isVisible: true,
              data: {
                ...item,
                auditDoc: await getDocDetails(item.finance_doc_current_year),
                GSTDoc: await getDocDetails(item.gst_doc_6_month),
                ITRDoc: await getDocDetails(item.itr_doc_1_year),
                DebDoc: await getDocDetails(item.debtor_doc),
                CredDoc: await getDocDetails(item.creditors_doc)
              }
            })
            setshowLoader(false)
          }}
        >View documents</label>
      </div>
      tableData.push(row)
      row = []
    })
    return tableData
  }


  function formatDataForTable(data) {
    let tableData = []
    let row = []
    data.forEach((item, index) => {
      let hsnCodes = item.buyerHsnCode ? item.buyerHsnCode.split(",") : []
      row[0] = item.identifier
      row[1] = item.buyerName
      row[2] = item.totalShipments
      row[3] = item.productDetails
      // row[3] = <div
      //   className='flex-row'
      // >
      //   {hsnCodes.length ? hsnCodes.map((item, index) => {
      //     return (
      //       <label className='bg-color1 p-1 mx-1 border-radius-5' >{item}</label>
      //     )
      //   }) : "NA"}
      // </div>
      row[4] = item.buyerCountry
      row[5] =
        <div >
          <button type="button"
            onClick={() => {
              // onCheckBuyerHealthClick(item.buyerDUNSNo)
              toggleViewBuyerDetails(item)
            }}
            className={`new-btn2 py-2 px-3 text-blue font-wt-600`}>
            {"View Details"}
          </button>
        </div>
      tableData.push(row)
      row = []
    })
    return tableData
  }

  function onCheckBuyerHealthClick(duns) {
    setshowLoader(true)
    call('POST', 'getBuyerHealth', { duns }).then((result) => {
      setshowLoader(false)
      setCheckBuyerHealth({ show: true, data: result })
    }).catch((e) => {
      // console.log('error in getBuyersDetail', e);
      setshowLoader(false)
      toastDisplay(e, "error")
      setCheckBuyerHealth({ show: true, data: {} })
    });
  }


  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }
      // if (event.target.name.includes("otherDoc")) {
      //   setAddMoreDoc(addMoreDoc.concat(null))
      // }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (!file_type.includes("pdf")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  async function handleValidation(tabIndex) {
    // if (tabIndex != undefined) {
    // if (tabIndex < tab) {
    setTab(tabIndex)
    // }
    // else if (tabIndex == tab) {
    //   return null
    // }
    // }
  }

  return (
    <>{showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="supplierList" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={`Supplier List > ${data.company_name}`}
              userTokenDetails={userTokenDetails} />
            {/* {checkBuyerHealth.show ? (
              <CheckBuyerHealth data={checkBuyerHealth.data} />
            ) : null} */}
            {viewBuyerDetails.show ? (
              <ViewBuyerDetails data={viewBuyerDetails.data} userTokenDetails={userTokenDetails}
              // onEdit={onEdit} onDelete={onDelete} 
              />
            ) : null}
            {!viewBuyerDetails.show ? (
              <>
                <a className="cursor"
                  onClick={() => {
                    history.go(-1)
                  }}
                ><img className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
                <div>
                  <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                    {addBuyerTabs.map((item, index) => {
                      return (
                        <li>
                          <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                            onClick={() => { handleValidation(index) }}
                          >
                            {item.name}</a>
                        </li>
                      )
                    })}
                  </ul>
                  <div className="card mt-1">
                    {tab === 0 ? (
                      <div className='px-5 py-4 pt-5'>
                        <div className="row">
                          <NewTable
                            columns={[{ name: "Buyer Id", filter: true }, { name: "Buyer Name", filter: true },
                            { name: "Shipment with supplier", filter: true }, { name: "Product Details", filter: true },
                            { name: "Country", filter: true }, { name: "", filter: false }]}
                            data={buyerTableData}
                          />
                        </div>
                      </div>) : null}
                    {tab === 1 ? (
                      <div className='px-5 py-4 pt-5'>
                        <div className="row">
                          {!detailsdata.isVisible &&
                            < NewTable
                              disableAction={true}
                              columns={[{ name: "Financial Year", filter: true }, { name: "Turnover", filter: true },
                              { name: "Net Profit", filter: true }, { name: "Net Worth", filter: true },
                              { name: "", filter: false }]}
                              data={finTableData}
                            />
                          }

                          {detailsdata.isVisible &&
                            <FinanceDetails setdetailsData={setdetailsData} goBackAction={() => setdetailsData({ isVisible: false, data: null })} detailsdata={detailsdata} handleChange={() => { }} handleFile={handleFile} isEditable={false} yearlist={[]} />
                          }
                        </div>
                      </div>) : null}
                    {tab === 2 ? (
                      <div className='px-5 py-4 pt-5'>
                        <div className="row">
                          <div className='w-100'>
                            <label className='font-wt-500 font-size-16'><u>General Details</u></label>
                            <div className='row'>
                              {reviewForm.map((item) => {
                                return (
                                  <div className="col-md-6">
                                    <p className="d-flex d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className='w-100'>
                            <label className='font-wt-500 font-size-16 mt-4'><u>Bank Details</u></label>
                            {userBank.length ? userBank.map((item, index) => {
                              return (
                                <div className='row'>
                                  <label className='font-wt-500 font-size-16 mt-2'><u>Bank {index + 1}</u></label>
                                  {reviewForm2.map((item) => {
                                    return (
                                      <div className="col-md-6">
                                        <p className="d-flex d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" >
                                          {userBank[index][item.val] || "NA"}</span> </p>
                                      </div>
                                    )
                                  })}
                                </div>
                              )
                            }) : null}
                          </div>

                        </div>
                        <>
                          <p className='font-size-14 mt-4'><u>Documents</u></p>
                          <div className='row'>
                            {companyDocs.map(docs => {
                              return <div className="col-md-6">
                                <label className="font-size-13">{docs.doc_name}</label>
                                <div className="row form-group">
                                  <div className="col-md-11">
                                    <FileInput name={docs.name} value={docs}
                                      onChange={handleFile}
                                      isEditable={false}
                                      userTokenDetails={userTokenDetails}
                                    />
                                  </div>
                                </div>
                              </div>
                            })
                            }
                          </div>
                        </>
                      </div>) : null}
                    {tab === 3 ? (
                      <div className='px-5 py-4 pt-5'>
                        <>
                          <div className='row'>
                            <div className="col-md-5 pt-1 ">
                              <div className="col-md-11 px-0">
                                <NewInput isAstrix={true} type={"text"} label={"PAN No."}
                                  name={"pan_no"} value={data.pan_no}
                                />
                              </div>
                            </div>
                            <div className="col-md-5 pt-1 ">
                              <div className="col-md-11 px-0">
                                <NewInput isAstrix={true} type={"text"} label={"IEC No."}
                                  name={"iec_no"} value={data.iec_no}
                                />
                              </div>
                            </div>
                            <div className="col-md-5 pt-1 ">
                              <div className="col-md-11 px-0">
                                <NewInput isAstrix={true} type={"text"} label={"GST No."}
                                  name={"gst_vat_no"} value={data.gst_vat_no}
                                />
                              </div>
                            </div>
                            <div className="col-md-5 pt-1 ">
                              <div className="col-md-11 px-0">
                                <NewInput isAstrix={true} type={"text"} label={"CIN No."}
                                  name={"cin_no"} value={data.cin_no}
                                />
                              </div>
                            </div>
                            <div className="col-md-5 pt-1 ">
                              <div className="col-md-11 px-0">
                                <NewInput isAstrix={true} type={"text"} label={"Aadhaar card"}
                                  name={"aadhar_no"} value={data.aadhar_no}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                        <>
                          <p className='font-size-14 mt-4'>Attach documents</p>
                          <div className='row'>
                            {KYCUploads.map(kycData => {
                              return <div className="col-md-5">
                                <label className="font-size-13">{kycData.name}</label>
                                <div className="row form-group">
                                  <div className="col-md-11">
                                    <FileInput name={kycData.val} value={kycdetails[kycData.val]} error={errors[kycData.val]}
                                      isEditable={false} userTokenDetails={userTokenDetails}
                                    />
                                    {errors[kycData.val] ? <div class="text-danger mt-2 font-size-12">
                                      <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                      <b>{errors[kycData.val]}</b></div> : ''}
                                  </div>
                                </div>
                              </div>
                            })
                            }
                          </div>
                        </>
                      </div >
                    ) : null}

                    {tab === 4 ? (
                      <div className='px-5 py-5 mx-1'>
                        <label className='font-wt-500 font-size-16'><u>Credit Score</u></label>
                        <div className="row d-flex px-2 pt-2">
                          {ProgressBars.map((item, index) => {
                            return (
                              <div className="bg-white w-35 card-layout mr-5 mb-4 px-4 py-2">
                                <label className="font-wt-400 font-size-14" >{item.name}</label>
                                <CustomProgressBar reverse={item.reverse}
                                  value={data[item.key] ? data[item.key] / 1 : 0}
                                  min={item.min} max={item.max} />
                                <div className="row pt-2 d-flex">
                                  <div className="w-50">
                                    <label>{"High Risk : "} <label className="font-wt-600">{item.min}</label></label>
                                  </div>
                                  <div className="w-50 text-right">
                                    <label>{"Low Risk : "} <label className="font-wt-600">{item.max}</label></label>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    ) : null}

                    {tab === 5 ? (
                      <div className='px-5 py-4 pt-5'>
                        <label className='font-wt-500 font-size-16'><u>Shareholders</u></label>
                        <div className="row">
                          <NewTable
                            columns={[{ name: "Name", filter: true }, { name: "Email Id", filter: true },
                            { name: "Address", filter: true }, { name: "Holdings", filter: true }, { name: "", filter: false }]}
                            data={shareHoldersTableData}
                          />
                        </div>
                      </div>) : null}

                  </div>
                </div>
              </>
            ) : null}
          </main>
        </div>
      </div>
      {/* {signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
        setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />}
      {limitinvoice && <FinanceInvoiceModal limitinvoice={limitinvoice} setLimitinvoice={setLimitinvoice} closeSuccess={closeSuccess} >
        <img src={"/assets/images/upload_done.png"} alt="" className="mb-3" />
        <p>Your application for “Invoice discounting” for “Rosti Suzhou Integrated” has been submitted successfully.</p>
      </FinanceInvoiceModal>} */}
    </>
  );
}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(SupplierDetails)