import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import HeaderV2 from '../partial/headerV2'
import SideBarV2 from '../partial/sideBarV2'
import Filter from '../InvoiceDiscounting/components/Filter'
import { ExpandableTable } from '../wallet/components/ExpandableTable'
import Pagination from '../InvoiceDiscounting/contract/components/pagination'
import toastDisplay from '../../utils/toastNotification'
import moment from 'moment'
import call from '../../service'
import swal from 'sweetalert'
import NewTablev2 from '../../utils/newTablev2'
import { applyFinTblDID } from '../Dashboard/TableJson'
import { LCPurposeObject, roundOfNumberInDecimal } from '../../utils/myFunctions'
import { ToastContainer } from 'react-toastify'

const DocVaultTransaction = ({ userTokenDetails, navToggleState }) => {

  const [filteredSearch, setFilteredSearch] = useState({})
  const [filter, setFilter] = useState({
    resultPerPage: 10
  })
  const [refresh, setRefresh] = useState(0)
  const [filterData, setFilterData] = useState({})
  const [count, setCount] = useState(1)
  const [page, setPage] = useState(1)
  const [dbdata, setDbdata] = useState([])
  const [showLoader, setshowLoader] = useState(false)

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;
  const userPermissionsForSubAdmin = JSON.parse(userTokenDetails.UserAccessPermission || "{}")

  useEffect(() => {
    getDocVaultTransactionList()
  }, [page, filter])

  async function getDocVaultTransactionList() {
    setshowLoader(true)
    let res = await call("POST", 'getDocVaultTransactionList', { userId, ...filter, page })
    setDbdata(res.data)
    setCount(res.count)
    setshowLoader(false)
  }


  return (
    <div className='container-fluid'>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="row">
        <SideBarV2 state="docVaultTransaction" userTokenDetails={userTokenDetails} />
        <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState?.status ? " expanded-right" : "")} id="app-main-div">
          <HeaderV2
            title={"Document Vault > Transaction"}
            userTokenDetails={userTokenDetails} />

          <div className='filter-div'>
            <Filter
              filteredSearch={filteredSearch}
              setFilteredSearch={setFilteredSearch} onDownloadClick={() => { }}
              filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
              showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} />
          </div>

          <div className="mb-3">
            <NewTablev2
              columns={[{ subColumns: 'Transaction No', filter: false, thwidth: 'w-20' },
              { subColumns: 'Transaction Date', filter: false, thwidth: 'w-20' },
              { subColumns: 'Buyer Name', filter: false, thwidth: 'w-20' },
              { subColumns: 'Transaction Type', filter: false, thwidth: 'w-20' },
              { subColumns: '', filter: false, thwidth: 'w-20' }]}
            >
              {dbdata.map((i, j) => {
                return (
                  <tr>
                    <td><label className='font-size-12 font-wt-500' >{`24452657565${i.applicationId}`}</label></td>
                    <td><label className='font-size-12 font-wt-500' >{moment(i.applicationUpdatedAt).format("DD/MM/YYYY")}</label></td>
                    <td><label className='font-size-12 font-wt-500' >{i.buyerName || "-"}</label></td>
                    <td><label className='font-size-12 font-wt-500' >{LCPurposeObject[i.lcPurpose] || "Invoice Discounting"}</label></td>
                    <td>
                      <button className={`new-btn  py-2 px-2 text-white cursor w-100`}
                        onClick={() => {
                          localStorage.setItem("docVaultTransactionDetails", JSON.stringify(i))
                          window.location = "/docVaultTransactionDetails"
                        }}
                      >View Documents</button>
                    </td>
                  </tr>
                )
              })}
            </NewTablev2>
          </div>
          <Pagination page={page} totalCount={count} onPageChange={(p) => setPage(p)} perPage={filter.resultPerPage || 10} />
        </main>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    clientType: state.clientType,
    navToggleState: state.navToggleState,
    notifications: state.IncomingNotifications.notification_data
  }
}

export default connect(mapStateToProps)(DocVaultTransaction)