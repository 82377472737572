import React, { useState, useEffect } from 'react';
import call from '../../service';
import { BrowserRouter as Router, Link } from "react-router-dom";
// import { connect } from 'react-redux';
import useForm from '../../utils/useForm';
import validate from '../../utils/validation_files/LoginFormValidationRules';
import toastDisplay from '../../utils/toastNotification';
import { removeCookieandAvatar, setCookieInAPP } from '../../utils/cookieHelper';
import { ToastContainer } from 'react-toastify';
import Carousel from 'react-bootstrap/Carousel'
import ResetPassword from './resetPassword';
import { getUserDataFromCookie } from '../../utils/cookieHelper';
import Footer from '../partial/footer';
import LogInWithOtp from './logInWithOtp';
import { decryptData, encryptData } from '../../utils/myFunctions';
import { mainUrl } from '../../urlConstants';

const queryString = require('query-string');


const Login = () => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  var [showLoader, setshowLoader] = useState(false);
  var [hide, sethide] = useState(true);
  var [forgotPasswordView, setForgotPasswordView] = useState(0);
  //forgot password view:
  //0 - inactive
  //1 - Reset password(enter mail) view
  //2 - Reset password(new password view)
  const [index, setIndex] = useState(0);
  const [passwordForgot, setpasswordForgot] = useState(false);
  const [direction, setDirection] = useState(null);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
    setDirection(e);
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
  } = useForm(login, null, validate);
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {
    // Fetch IP
    fetch(
      "https://geolocation-db.com/json/")
      .then((res) => res.json())
      .then((json) => {
        // console.log("userippp", json);
        if (json.IPv4) {
          localStorage.setItem("attr1", encryptData(json.IPv4))
        }
      })
    // Fetch Location
    navigator.geolocation.getCurrentPosition(function (position) {
      localStorage.setItem("attr2", encryptData(position.coords.latitude + ", " + position.coords.longitude))
    });
    let mkt_ref_id = window.location.href.split("mkt_ref=")[1]
    let mkt_insp_ref_id = window.location.href.split("mkt_inspection_ref=")[1]
    if (mkt_ref_id) {
      localStorage.setItem("mkt_ref_id", mkt_ref_id)
    }
    if (mkt_insp_ref_id) {
      localStorage.setItem("mkt_insp_ref_id", mkt_insp_ref_id)
    }

    let tradefin_ref = window.location.href.split("tradefin_ref=")[1];
    if (tradefin_ref) {
      removeCookieandAvatar()
      localStorage.clear()
      let user_credentials = JSON.parse(decryptData(tradefin_ref));
      values.email = user_credentials.username
      values.password = decryptData(user_credentials.password)
      login(user_credentials)
    }
    let url_params = window.location.href.split("params=")[1]
    if (url_params) {
      url_params = decryptData(url_params).split("::")
      if (url_params && url_params[3] && url_params[4]) {
        removeCookieandAvatar()
        localStorage.clear()
        localStorage.setItem("onlyAllowedUrl", "financeDetailView")
        login({ username: url_params[3], otp: url_params[4] }, window.location.href)
      }
    }
    //reading querystring to check queries if any
    let query = queryString.parse(window.location.search);
    if (query.resetPass && query.id) {
      setForgotPasswordView(1)
    }


    //------------------------------------------------------------------
    //User details from cookie
    let userDetails = getUserDataFromCookie()
    let userTypeId = userDetails.type_id ? userDetails.type_id : null
    let userToken = userDetails.token ? userDetails.token : null
    let status = userDetails.status ? userDetails.status : null
    //------------------------------------------------------------------

    //------------------------------------------------------------------
    //Redirect if user already logged in
    if ((userToken !== undefined && userToken !== null) && (userTypeId !== undefined && userTypeId !== null) && (status !== undefined && status !== null)) {
      redirectFunc(userTypeId, status)
    } else {
      sethide(false)
    }

    //------------------------------------------------------------------

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Handlers
  async function login(payload, redirectUrl) {
    setshowLoader(true)
    let loginPayload = {}
    if (payload) {
      loginPayload = payload
    }
    else {
      loginPayload = {
        username: values.email,
        password: values.password
      };
    }
    if (loginPayload.password) {
      loginPayload.password = encryptData(loginPayload.password)
    }
    loginPayload.attr1 = localStorage.getItem("attr1")
    loginPayload.attr2 = localStorage.getItem("attr2")
    let fcmToken = localStorage.getItem('fcmToken')
    console.log('FCMTOKEN', fcmToken)
    if (fcmToken) {
      loginPayload.fcmToken = fcmToken
    }
    await call('post', 'login', loginPayload, "user").then((result) => {
      if (result) {
        console.log("result in login==>", result)
        localStorage.setItem("last_login_at", result.last_login_at)
        setCookieInAPP(result)
        if (redirectUrl) {
          window.location = redirectUrl
        }
        else {
          redirectFunc(result.type_id, result.status, redirectUrl)
        }
      }
      setshowLoader(false)
    }).catch(e => {
      setshowLoader(false)
      console.log("Error in login====>", e)
      setpasswordForgot(true)
      toastDisplay(e, "error");
    })
  }

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Functions
  var redirectFunc = (redirectTo, regStatus) => {
    console.log('redirectTo,regStatus===>', redirectTo, regStatus)

    if (regStatus / 1 === 1)
      window.location = "/dashboard"
    else
      toastDisplay("Admin verification is pending, Please try later or contact administrator !!", "warn");

  }
  //---------------------------------------------------------------------------------------------------------------------

  function normalLogin() {
    return (
      <>
        <div className="authfy-heading">
          <h1 className="auth-title">Login to your account</h1>
          <pre className="auth-description">Don’t have an account? <Link to="/registration">Sign Up </Link></pre>
        </div>
        <div className="form-group">
          <label htmlFor="inputEmail">Email address</label>
          <input type="email" id="inputEmail" value={values.email} className={" form-control" + (!errors.email ? '' : ' border-danger')} name="email" placeholder="Email ID" required="" autoFocus=""
            onChange={handleChange} noValidate />
          {errors.email && (
            <p className="text-danger error-contract">{errors.email}</p>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="inputPassword">Password</label>
          <input type="password" id="inputPassword" value={values.password} name="password" className={" form-control" + (!errors.password ? '' : ' border-danger')} placeholder="Password" required="required"
            onChange={handleChange} />
          {errors.password && (
            <p className="text-danger error-contract">{errors.password}</p>
          )}
        </div>
        <button className="btn btn-lg btn-primary btn-block btn-sm" type="submit" onClick={(event) => { handleSubmit(event); }} noValidate>Sign in</button>
        <div className="checkbox mb-3 d-flex">
          {/* <span className="field">
            <input type="checkbox" defaultValue id="checkbox3" />
            <label className="checkbox" htmlFor="checkbox3">Remember me</label>
          </span> */}
          <button className="btn btn-sm btn-link" onClick={() => { setForgotPasswordView(2) }}>Log in with OTP</button>
          <button className="ml-auto btn btn-sm btn-link" onClick={() => { setForgotPasswordView(1) }}>Forgot Password</button>
        </div>
        <hr />
        <a href='/channelPartner'><button className="btn btn-lg btn-primary btn-block btn-sm" type="button" noValidate>Login / Become a Channel Partner</button></a>

        {(passwordForgot) &&
          <button className="btn btn-lg btn-warning btn-block btn-sm" type="submit" onClick={() => { setForgotPasswordView(1) }} noValidate>Forgot Password?</button>}
      </>
    )
  }

  function resetPasswordForm() {
    return (
      <>
        <br />
        <br />
        <br />
        <br />
        <button type="button" className="btn btn-lg btn-primary btn-sm" onClick={() => { setForgotPasswordView(0) }} noValidate>
          Back to Login
        </button>
        <ResetPassword query={window.location.search} />
      </>
    )
  }

  function signinWithOtpForm() {
    return (
      <>
        <br />
        <br />
        <br />
        <br />
        <button type="button" className="btn btn-lg btn-primary btn-sm" onClick={() => { setForgotPasswordView(0) }} noValidate>
          Back to Login With Password
        </button>
        <LogInWithOtp login={(data) => login(data)} />
      </>
    )
  }


  //forgetPassword views:
  function formView() {
    switch (forgotPasswordView) {
      case 0:
        return normalLogin();
      case 1:
        return resetPasswordForm();
      case 2:
        return signinWithOtpForm();
      default:
        return normalLogin();
    }
  }

  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      {hide ? <div className="on-bording">
        <div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>
      </div>
        : <>
          <div className="on-bording">
            <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
            {showLoader === true && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
            <div className="row">
              <div className="login-panel-right hidden-xs hidden-sm">
                <div className="hero-heading">
                  <div className="headline">
                    {/* <a href='/channelPartner'><button type="button" style={{ "padding": "10px", "margin": "40px", "marginTop": "0px", "bottom": "120px", "position": "relative" }} className="btn btn-warning btn-sm float-right"> <b>Login / Become a Channel Partner</b></button></a> */}
                    <form className="login-container">
                      <div className="col-md-8">
                        <a href={mainUrl}><img height="80" src="../assets/images/logos/logo_1.png" /></a>
                      </div>
                      {formView()}
                    </form>
                    {/* <Carousel activeIndex={index} direction={direction} onSelect={handleSelect} interval={4000} indicators={true} fade={false} controls={false}>
                      <Carousel.Item>
                        <img height="500" src="../assets/images/onbording/slider1.png" />
                        <div className="numbertext mb-3"><h2 className="text-white">Established Trust with Blockchain Technology</h2></div>
                        <div className="mb-5"></div>
                      </Carousel.Item>
                      <Carousel.Item>
                        <img height="500" src="../assets/images/onbording/slider2.png" />
                        <div className="numbertext mb-3"><h2 className="text-white"> Immutable ledger with complete audit trail</h2></div>
                        <div className="mb-5"></div>
                      </Carousel.Item>
                      <Carousel.Item>
                        <img height="500" src="../assets/images/onbording/slider3.png" />
                        <div className="numbertext mb-3"><h2 className="text-white">Take complete control of your  value chain</h2> </div>
                        <div className="mb-5"></div>
                      </Carousel.Item>
                    </Carousel> */}
                  </div>
                </div>
              </div>
              {/* <form className="form-signin col-md-4 login-panel-left">
                <div className="col-md-12">
                  <a href={'/'}><img height="80" src="../assets/images/logos/logo_1.png" /></a>
                </div>
                {formView()}
              </form> */}
            </div>
          </div>
          {/* footer start */}
          <Footer />
          {/* footer end */}
        </>}
    </>
  )
}

const mapStateToProps = state => ({
  data: state,
});

export default Login;
