import axios from 'axios';
import call from '../../service';
// import { tradeFinBackendUrl } from '../../urlConstants';


const submitPayload = (data, employeeData) => {

  return new Promise((resolve, reject) => {

    console.log("data in create payload-->", data)
    let formData = new FormData();
    // let commodityArray = []
    let commodityList = []

    //making data flat object
    let values = {};
    if (data.payload.step2) {
      // commodityArray = data.payload.step2
      commodityList = data.payload.step2
      delete data.payload.step2;
    }

    //--handling step4(duplicate fields in bank form issue)----
    //----[fix later - duplication in bankfields settle]- [start]----
    var bankDataObj = { ...(data.payload.step4) };
    //----[fix later - duplication in bankfields settle - can be done by changing fieldnames in bankForm]- [end]----        


    //---handling otherDocData[start]----
    let otherDocDataObj = {};
    if (data.payload && data.payload.otherDocDataArray && data.payload.otherDocDataArray.length) {
      console.log("here");

      let otherDocDataArray = data.payload.otherDocDataArray;
      for (let i = 0; i < otherDocDataArray.length; i++) {
        if (otherDocDataArray[i] === null) {
          continue;
        }
        const otherDocData = otherDocDataArray[i];
        for (var field in otherDocData) {
          otherDocDataObj['otherdoc' + i + field.slice(8)] = otherDocData[field];
        }
      }
      // delete data.payload.otherDocDataArray;
    }
    //---handling otherDocData[end]----

    let payload = data.payload;
    for (let obj in payload) {
      values = { ...values, ...(payload[obj]) }
    }

    values = { ...values, ...otherDocDataObj };

    if (bankDataObj && bankDataObj.accountNo) {
      values = { ...values, ...bankDataObj };
    }

    if (data.payload && data.payload.techType) {
      values.techType = data.payload.techType
    }

    if (employeeData) {
      values.techType = employeeData.manualUserTechTypeId
    }

    if (data.payload && data.payload.hasOwnProperty('organizationType')) {
      values.organizationType = data.payload.organizationType
    }

    console.log("reg submit payload:===>", values);

    formData.append('typeId', data.type);

    Object.keys(values).forEach((key) => {
      formData.append(key, values[key]);
    });

    // formData.append('commodityArray', commodityArray);
    if (commodityList.length) {
      formData.append('commodityList', JSON.stringify(commodityList))
    }

    if (data.payload && data.payload.step9 && data.payload.step9.length) {
      formData.append("servingPorts", JSON.stringify(data.payload.step9))
    }

    formData.append("attr1", localStorage.getItem("attr1"))
    formData.append("attr2", localStorage.getItem("attr2"))

    call('POST', 'registration', formData, "user").then((result) => {
      console.log('Result:', result);
      // axios.post(tradeFinBackendUrl + "/users/markUserAsOnboarded", { email: values.email }).then(res => null)
      return resolve(result)
    }).catch(err => {
      console.log('Error:', err);
      return reject(err);
    })
  })
}




export default submitPayload
