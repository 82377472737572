import moment from 'moment'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { connect } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import call from '../../service'
import { NewTable } from '../../utils/newTable'
import Filter from '../InvoiceDiscounting/components/Filter'
import Pagination from '../InvoiceDiscounting/contract/components/pagination'
import HeaderV2 from '../partial/headerV2'
import SideBarV2 from '../partial/sideBarV2'
import ExportersTab from '../UserOnboard/ExportersTab'
import UserDetails from '../UserOnboard/UserDetails'
import ChannelPartnerDetails from '../UserOnboard/ChannelPartnerDetails'
import toastDisplay from '../../utils/toastNotification'
import { GetCache, convertImageToPdf, getDocDetails, getInitials, insertObjectInArray, printDiv } from '../../utils/myFunctions'
import { NewInput, NewTextArea } from '../../utils/newInput'
import { Area, AreaChart, Bar, BarChart, Funnel, Legend, Line, FunnelChart, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis, Cell } from 'recharts';
import PieChartComponent from '../Reports/components/PieChartComponent'
import FinanceInvoiceModal from '../InvoiceDiscounting/contract/components/financeinvoiceModal'
import MultipleSelect from '../../utils/MultipleSelect'
import { FileInput } from '../../utils/FileInput'
import { DocumentViewer } from '../../utils/documentViewer'
// import converter from 'number-to-words'
var converter = require('number-to-words');

let companyBillName = {
  "51": 'Stenn International Limited',
  "748": 'MODIFI B.V.'
}

let cardTypes = [
  { type: "Due", alt: "due", arr: [{ name: "No. of Invoice", class: "colorF1C40F font-size-26 font-wt-600", alt: "totalApplicationCount" }, { name: "Amount", class: "colorF1C40F font-size-26 font-wt-600", alt: "totalInvoiceAmount" }] },
  { type: "Over Due", alt: "overdue", arr: [{ name: "No. of Invoice", class: "text-danger font-size-26 font-wt-600", alt: "totalApplicationCount" }, { name: "Amount", class: "text-danger font-size-26 font-wt-600", alt: "totalInvoiceAmount" }] },
  { type: "Received", alt: "received", arr: [{ name: "No. of Invoice", class: "text2ECC71 font-size-26 font-wt-600", alt: "totalApplicationCount" }, { name: "Amount", class: "text2ECC71 font-size-26 font-wt-600", alt: "totalInvoiceAmount" }] },
  { type: "Receivables", alt: "receivables", arr: [{ name: "No. of Invoice", class: "text-custom2 font-size-26 font-wt-600", hide: true }, { name: "Amount", class: "text-custom2 font-size-26 font-wt-600", alt: "totalInvoiceAmount" }] }
]

const generateInvoiceLabels = [
  { name: 'No. of Financers', alt: 'noOfFinanciers', unit: "" },
  { name: 'Finance Amount', alt: "totalFinanceAmount", unit: "USD" },
  // { name: 'Total Commission (%)', alt: "totalCommissionPercentage", unit: "%" },
  { name: 'Total Commission', alt: "totalCommissionAmount", unit: "USD" }
]

const InvoiceGenerateFinancier = ({ userTokenDetails, navToggleState, renderAsChildren, hideGraphs, showForThisUser, changedFilterData, setChangedFilterData }) => {


  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null

  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')

  let todayDateObj = moment()
  let lastMonthDateObj = moment().subtract("1", "months")

  const [invBillfilterCount, setInvBillFilterCount] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [filterData, setFilterData] = useState({})
  const [filter, setFilter] = useState({ resultPerPage: 10, search: serarchParam ? serarchParam : '' })
  const [refresh, setRefresh] = useState(0)
  const [invBillcount, setInvBillCount] = useState(0)
  const [graphConfiguration, setGraphConfiguration] = useState({
    commissionFrom: lastMonthDateObj.clone().format("YYYY-MM-DD"),
    commissionTo: todayDateObj.clone().format("YYYY-MM-DD")
  })
  const [financierList, setFinancierList] = useState([])
  const [graphData, setGraphData] = useState({})
  const [showInvPdf, toggleInvPdf] = useState(false)
  const [invBillData, setInvBillData] = useState([])
  const [invBillTableData, setInvBillTableData] = useState([])
  const [invBillPage, setInvBillPage] = useState(1)

  const [transactionTableData, setTransactionTableData] = useState([])
  const [transactionInputData, setTransactionInputData] = useState({
    commissionFrom: lastMonthDateObj.clone().format("YYYY-MM-DD"),
    commissionTo: todayDateObj.clone().format("YYYY-MM-DD")
  })
  const [transactionTableDbData, setTransactionTableDbData] = useState([])
  const [attachPopup, toggleAttachPopup] = useState(false)
  const [viewDoc, toggleViewDoc] = useState({})

  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})

  function generateInvoiceSummary(resp) {
    if (graphConfiguration?.generateInvoiceForId?.length && resp?.commissionFromFinancierDbData?.length) {
      let summaryOfInvoiceCommission = { "totalFinanceAmount": 0, "totalCommissionPercentage": 0, "totalCommissionAmount": 0 }
      summaryOfInvoiceCommission["noOfFinanciers"] = graphConfiguration?.generateInvoiceForId?.length
      for (let index = 0; index < resp.commissionFromFinancierDbData.length; index++) {
        const element = resp.commissionFromFinancierDbData[index];
        let charges = element.invCharges || element.lcCharges
        summaryOfInvoiceCommission["totalFinanceAmount"] += element.contractAmount / 1
        summaryOfInvoiceCommission["totalCommissionPercentage"] += charges.commissionPercentage / 1
        summaryOfInvoiceCommission["totalCommissionAmount"] += (charges.totalCharges * (charges.commissionPercentage / 100))
      }
      summaryOfInvoiceCommission["totalFinanceAmount"] = summaryOfInvoiceCommission["totalFinanceAmount"]?.toFixed(2)
      summaryOfInvoiceCommission["totalCommissionAmount"] = summaryOfInvoiceCommission["totalCommissionAmount"]?.toFixed(2)
      summaryOfInvoiceCommission["totalCommissionPercentage"] = (summaryOfInvoiceCommission["totalCommissionPercentage"] / resp.commissionFromFinancierDbData.length)?.toFixed(2)

      // Creating Invoice data financier wise start
      for (let j = 0; j < resp.commissionFromFinancierDbData.length; j++) {
        const item = resp.commissionFromFinancierDbData[j];
        let charges = item.invCharges || item.lcCharges
        let status = item.invCharges ? item.invoiceCommissionStatus : item.lcCommissionStatus

        if (!summaryOfInvoiceCommission[item.lenderId]) {
          summaryOfInvoiceCommission[item.lenderId] = {
            data: [], item, totalCharges: 0, totalChargesCurrency: 'NA',
            totalCommissionPercentage: 0, commissionPayout: 0,
            billNo: "NA", billCreatedAt: moment(data.billCreationDate).format("YYYY-MM-DD"),
            commissionFrom: graphConfiguration.commissionFrom, commissionTo: graphConfiguration.commissionTo
          }
        }

        summaryOfInvoiceCommission[item.lenderId]["data"].push([moment(item.applicationCreatedAt).format("YYYY-MM-DD"), item.sellerName, `${(item.invCharges ? 'Invoice Discounting - ' : 'LC Discounting - ')}${item.applicationNo}`, `${item.invoiceDueDate}`, `${item.contractAmount} USD`,
        (charges.totalCharges)?.toFixed(2) + " USD",
        charges.commissionPercentage,
        ((charges.totalCharges * charges.commissionPercentage) / 100)?.toFixed(2) + " USD", `${status / 1 == 1 ? "Paid" : "Due"}`,
        `${item.commissionDate ? moment(item.commissionDate).format("YYYY-MM-DD") : "NA"}`])

        summaryOfInvoiceCommission[item.lenderId]["totalCharges"] += charges.totalCharges
        summaryOfInvoiceCommission[item.lenderId]["totalChargesCurrency"] = charges.totalChargesCurrency
        summaryOfInvoiceCommission[item.lenderId]["totalCommissionPercentage"] += charges.commissionPercentage
        summaryOfInvoiceCommission[item.lenderId]["commissionPayout"] += (charges.totalCharges * (charges.commissionPercentage / 100))
      }
      // Creating Invoice data financier wise end
      return summaryOfInvoiceCommission
    }
    else {
      return {}
    }
  }

  // console.log("grapDataaaaaaaaaaaaaa", graphData);

  function formatTransactionTableData(data) {
    let tableData = []
    let row = []
    for (let index = 0; index < data.length; index++) {
      const item = data[index];
      let isInvoice = item.invCharges ? true : false
      let charges = item.invCharges || item.lcCharges
      row[0] = item.lenderName
      row[1] = `${isInvoice ? 'Invoice ' : 'LC '} Discounting - ${item.applicationNo}`
      row[2] = `${Intl.NumberFormat("en", { notation: 'compact' }).format(item.contractAmount)} USD`
      row[3] = charges.commissionPercentage
      row[4] = `${Intl.NumberFormat("en", { notation: 'compact' }).format(charges.totalCharges / charges.commissionPercentage)} USD`
      row[5] = item.invoiceDueDate
      if (isInvoice) {
        if (item.invoiceCommissionStatus / 1 == 1) {
          row[6] = <button type="button"
            class={`approved text-white border-0 `}>
            {"Paid"}
          </button>
        }
        if (item.invoiceCommissionStatus / 1 == 0) {
          row[6] = <button type="button"
            class={`inprogress text-white border-0 `}>
            {"Due"}
          </button>
        }
      }
      else {
        if (item.lcCommissionStatus / 1 == 1) {
          row[6] = <button type="button"
            class={`approved text-white border-0 `}>
            {"Paid"}
          </button>
        }
        if (item.lcCommissionStatus / 1 == 0) {
          row[6] = <button type="button"
            class={`inprogress text-white border-0 `}>
            {"Due"}
          </button>
        }
      }
      tableData.push(row)
      row = []
    }

    return tableData
  }

  // useEffect(() => {
  //   // let isCacheExist = localStorage.getItem('invoiceLimitFilterData') != "{}"
  //   // if (!isCacheExist) {
  //   call('POST', 'getFilterForInvoiceBill', {}).then(res => {
  //     console.log("getFilterForInvoiceBill then", res);
  //     setFilterData(res)
  //   }).catch(err => { })
  //   // }
  // }, [])

  useEffect(() => {
    if (transactionInputData.commissionFrom && transactionInputData.commissionTo) {
      setshowLoader(true)
      call('POST', 'getAdminPaymentsResport', transactionInputData).then(res => {
        // console.log("getAdminPaymentsResportgetAdminPaymentsResport", res);
        setshowLoader(false)
        setTransactionTableData(formatTransactionTableData(res.commissionFromFinancierDbData))
        setTransactionTableDbData(res.commissionFromFinancierDbData)
      })
    }
  }, [transactionInputData, refresh])

  useEffect(() => {
    setshowLoader(true)
    call('POST', 'getGenerateInvoiceBills', { ...filter, currentPage: invBillPage }).then(res => {
      setshowLoader(false)
      setInvBillData(res.data)
      setInvBillTableData(formatInvBillData(res.data))
      setInvBillCount(res.count)
      setInvBillFilterCount(res.filter)
    }).catch(err => {
      setshowLoader(false)
      console.log("error in getGenerateInvoiceBills", err)
    })
  }, [refresh, invBillPage])

  function formatInvBillData(data) {
    try {
      let tempData = []
      let row = []
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        row[0] = element.details?.item?.lenderName
        row[1] = element.billDate
        row[2] = element.billNo
        row[3] = Intl.NumberFormat("en", { notation: 'compact' }).format(element.details.commissionPayout) + " USD"
        row[4] = element.status / 1 == 0 && moment(element.billDate, "YYYY-MM-DD").add(1, "month") < moment() ?
          <button type="button"
            class={`rejected text-white border-0 `}>
            {"Over Due"}
          </button> :
          element.status / 1 == 0 ?
            <button type="button"
              class={`inprogress text-white border-0 `}>
              {"Due"}
            </button> :
            element.status / 1 == 1 ?
              <button type="button"
                class={`approved text-white border-0 `}>
                {"Paid"}
              </button> : null
        row[5] = <label
          onClick={async () => {
            // if (element.attachment) {
            //   let docFileObj = await getDocDetails(element.attachment)
            //   // console.log("docFileeeeeeeeeeeeeeeee", docFileObj);
            //   toggleViewDoc({ show: true, doc: docFileObj })
            // }
            // else {
            setGraphConfiguration({ generateInvoiceForId: [element.details.item.lenderId] })
            setGraphData({ summaryOfInvoiceCommission: { [element.details.item.lenderId]: element.details } })
            toggleInvPdf(true)
            // }
          }}
          className='cursor text-custom2 font-size-16 font-wt-600 '
        >Invoice Details</label>
        if (element.attachment) {
          row[6] = <label
            onClick={async () => {
              if (element.attachment) {
                let docFileObj = await getDocDetails(element.attachment)
                // console.log("docFileeeeeeeeeeeeeeeee", docFileObj);
                toggleViewDoc({ show: true, doc: docFileObj })
              }
            }}
            className='cursor text-custom2 font-size-16 font-wt-600 '
          >Attachment</label>
        }
        else {
          row[6] = ""
        }
        tempData.push(row)
        row = []
      }
      return tempData
    } catch (error) {
      console.log("error in formatInvBillData", error);
    }
  }

  useEffect(() => {
    if (graphConfiguration.commissionFrom) {
      setshowLoader(true)
      call('POST', 'getAdminPaymentsResport', graphConfiguration).then(res => {
        // console.log("getAdminPaymentsResportgetAdminPaymentsResport", res);
        setshowLoader(false)
        setGraphData({
          ...res, summaryOfInvoiceCommission: generateInvoiceSummary(res)
        })
      })
    }
    call('POST', 'getAllFinanciersList', {}).then(res => { setFinancierList(res) })
  }, [graphConfiguration])

  const handleGraphConfigurationChange = async (event) => {
    if (event.persist) {
      event.persist()
    }
    setGraphConfiguration({ ...graphConfiguration, [event.target.name]: event.target.value })
  }

  const handleTransactionInputChange = async (event) => {
    if (event.persist) {
      event.persist()
    }
    setTransactionInputData({ ...transactionInputData, [event.target.name]: event.target.value })
  }

  async function changeInvoiceBillStatus(indx, markAs) {
    let tempDbData = invBillData[indx]
    setshowLoader(true)
    call('POST', 'changeInvoiceBillStatus', {
      markAs, billNo: tempDbData.billNo
    }).then(res => {
      setshowLoader(false)
      setGraphConfiguration({ ...graphConfiguration })
      toastDisplay(res, "success")
      setRefresh(refresh + 1)
    }).catch(err => {
      toastDisplay("Something went wrong", "error")
    })
  }

  async function changeCommissionStatus(indx, markAs) {
    let tempDbData = transactionTableDbData[indx]
    setshowLoader(true)
    call('POST', 'changeCommissionStatus', {
      markAs, invApplicationId: tempDbData.invCharges ? tempDbData.applicationId : undefined,
      lcApplicationId: tempDbData.lcCharges ? tempDbData.applicationId : undefined
    }).then(res => {
      setshowLoader(false)
      setRefresh(refresh + 1)
      toastDisplay("Transaction status updated successfully", "success")
    })
  }

  async function downloadCommissionFromFinancierData() {
    if (!transactionTableDbData?.length) {
      return toastDisplay('No data found to download', "info")
    }
    try {
      let csvString = "Financier,Application type & no,Finance Amount,Commission (%),Commission,Buyer Payment Date,Status\r\n"
      for (let index = 0; index < transactionTableDbData.length; index++) {
        const element = transactionTableDbData[index];
        let isInvoice = element.invCharges ? true : false
        let charges = isInvoice ? element.invCharges : element.lcCharges
        let status = isInvoice ? element.invoiceCommissionStatus : element.lcCommissionStatus
        let rowString = `${element.lenderName},
          ${isInvoice ? 'Invoice ' : 'LC '}Discounting - ${element.applicationNo},
          ${element.contractAmount} USD,
          ${charges.commissionPercentage},
          ${(charges.totalCharges / charges.commissionPercentage)?.toFixed(2)} USD,
          ${element.invoiceDueDate},
          ${status / 1 == 0 ? "Due" : "Paid"}\r\n`
        rowString = rowString.replace(/(\n)/gm, "")
        csvString += rowString
      }
      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', `CommissionFromFinancierData.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log("error in CommissionFromFinancierData", error);
    }
  }

  const handleFile = (event, isImage) => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (!file_type.includes("pdf") && !isImage) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  const handleUploadInvoiceFunction = () => {
    if (graphConfiguration?.generateInvoiceForId?.length && data?.invoiceAttachment?.name &&
      graphConfiguration.commissionFrom && graphConfiguration.commissionTo && data.billCreationDate && data.billAmountInUsd) {
      setshowLoader(true)
      let formData = new FormData()
      formData.append("commissionFrom", graphConfiguration.commissionFrom)
      formData.append("commissionTo", graphConfiguration.commissionTo)
      formData.append("billCreatedAt", data.billCreationDate)
      formData.append("userId", userId)
      formData.append("file", data.invoiceAttachment)
      formData.append("billAmountInUsd", data.billAmountInUsd)
      formData.append("lenderId", graphConfiguration?.generateInvoiceForId[0])
      formData.append("lenderName", graphConfiguration?.generateInvoiceForLabel[0])
      call('POST', 'uploadBilledInvoice', formData).then(res => {
        setshowLoader(false)
        setRefresh(refresh + 1)
        toastDisplay(res, "success")
      }).catch(err => {
        setshowLoader(false)
        toastDisplay("Something went wrong", "error")
      })
    }
    else {
      toastDisplay("Form validation error", "info")
    }
  }

  return (
    <>
      <DocumentViewer show={viewDoc.show} value={viewDoc.doc} dismiss={() => toggleViewDoc({ show: false, doc: {} })} />
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="generateInvoiceFinancier" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Invoice > Financier Invoice"}
              userTokenDetails={userTokenDetails} />
            <div className='mt-4' style={{}} >
              <div className='row mb-3 d-flex justify-content-around'>
                {cardTypes.map((i, j) => {
                  return (
                    <div className='row w-23 card dashboard-card shadow-sm ml-2 mb-0 pb-0 pt-3 ml-2'>
                      <label className={'font-size-14 font-wt-600 text-color-label pb-1'}>{i.type}</label>
                      <div className='row'>
                        {i.arr.map((k, l) => {
                          if (!k.hide) {
                            return (
                              <div className=' align-items-center w-50'>
                                <div>
                                  <label className={` ${k.class}`}>
                                    {(l / 1 == 1 ? '$ ' : "") + `${Intl.NumberFormat("en", { notation: 'compact' }).format(invBillfilterCount?.[i.alt]?.[k.alt])}` || "NA"}</label>
                                </div><div>
                                  <label className={'font-size-13 font-wt-600 text-color-value'}>{k.name}</label>
                                </div>
                              </div>
                            )
                          }
                        })}
                      </div>
                    </div>
                  )
                })}
              </div>

              {attachPopup && <FinanceInvoiceModal limitinvoice={attachPopup} setLimitinvoice={toggleAttachPopup} closeSuccess={() => toggleAttachPopup(false)} >
                <div className="col-md-10 mb-2 ml-5">
                  <label className='text-center font-wt-600 text-color1 font-size-14 mb-2'>{`Upload Invoice Attachment - ${data.selectedBillNo}`}</label>
                  <FileInput
                    onUploadCancel={() => { setData({ ...data, invoiceAttachment: {} }) }} name={"invoiceAttachment"} value={data["invoiceAttachment"]} onChange={handleFile}
                    error={errors.invoiceAttachment} isEditable={true} />
                  <div className="justify-content-center">
                    <button
                      onClick={() => {
                        let err = {}
                        if (!data.invoiceAttachment?.name) {
                          err["invoiceAttachment"] = "Select Document to Upload"
                        }
                        if (!Object.keys(err).length) {
                          setshowLoader(true)
                          let formData = new FormData()
                          formData.append("billNo", data.selectedBillNo)
                          formData.append("file", data.invoiceAttachment)
                          formData.append("userId", userId)
                          call('POST', 'uploadInvoiceAttachment', formData).then((res) => {
                            toastDisplay(res, "success")
                            setshowLoader(false)
                            toggleAttachPopup(false)
                            setRefresh(refresh + 1)
                          })
                        }
                        setErrors(err)
                      }}
                      type="button" className={`new-btn w-100 py-2 px-2 mt-3 text-white cursor`}>
                      Save
                    </button>
                  </div>
                </div>
              </FinanceInvoiceModal>}

              {graphConfiguration.generateInvoice ? (
                <FinanceInvoiceModal limitinvoice={graphConfiguration.generateInvoice} closeSuccess={() => {
                  // setGraphConfiguration({ ...graphConfiguration, generateInvoice: false })
                  window.location.reload()
                }} headerTitle={`Generating Invoice For ${graphConfiguration?.generateInvoiceForLabel}`}>
                  <div >
                    <div className='row my-3'>
                      <div className='col-md-4'>
                        <MultipleSelect
                          Id="Financier"
                          Label="Financier"
                          optiondata={financierList}
                          onChange={(e) => {
                            let generateInvoiceForLabel = []
                            let generateInvoiceForId = []
                            for (let index = 0; index < e.length; index++) {
                              const element = e[index];
                              generateInvoiceForLabel.push(element.name)
                              generateInvoiceForId.push(element.id)
                            }
                            setGraphConfiguration({ ...graphConfiguration, generateInvoiceForLabel: generateInvoiceForLabel, generateInvoiceForId: generateInvoiceForId })
                          }}
                          value={graphConfiguration.generateInvoiceForId || []}
                          name="generateInvoiceForId"
                          labelKey={"name"}
                          valKey={"id"}
                        />
                      </div>
                      <div className='col-md-4'>
                        <NewInput type={"date"} label={"Bill Date"} name={"billCreationDate"} value={data.billCreationDate}
                          onChange={(e) => { setData({ ...data, [e.target.name]: e.target.value }) }} />
                      </div>
                      <div className='col-md-4'>
                        <NewInput type={"date"} label={"Commission From"} name={"commissionFrom"} value={graphConfiguration.commissionFrom}
                          onChange={handleGraphConfigurationChange} />
                      </div>
                      <div className='col-md-4'>
                        <NewInput type={"date"} label={"Commission To"} name={"commissionTo"} value={graphConfiguration.commissionTo}
                          onChange={handleGraphConfigurationChange} />
                      </div>
                      <div className='mb-4'>
                        <div className='col-md-12'>
                          <FileInput
                            onUploadCancel={() => { setData({ ...data, invoiceAttachment: {} }) }} name={"invoiceAttachment"} value={data["invoiceAttachment"]} onChange={handleFile}
                            error={errors.invoiceAttachment} isEditable={true} />
                        </div>
                      </div>
                    </div>
                    <div className='row d-flex justify-content-evenly' >
                      <button type="button"
                        onClick={async () => {
                          if (graphConfiguration.generateInvoiceForId?.length) {
                            if (graphData.commissionFromFinancierDbData?.length) {
                              // show invoice
                              toggleInvPdf(true)
                              console.log("beeeeeeeeeeeeeeeeeeeeeeee", graphData.summaryOfInvoiceCommission);
                              // Save invoice details in backend start
                              setshowLoader(true)
                              try {
                                let formData = new FormData()
                                formData.append("userId", userId)
                                if (data.invoiceAttachment?.name) {
                                  formData.append("file", data.invoiceAttachment)
                                }
                                formData.append("invoices", JSON.stringify(Object.values(graphData.summaryOfInvoiceCommission)))
                                await call('POST', "generateInvoiceForBilling", formData)
                              } catch (error) {
                              }
                              setshowLoader(false)
                              // Save invoice details in backend end
                            }
                            else {
                              toastDisplay("data not found to generate invoice", "info")
                            }
                          }
                          else {
                            toastDisplay('Select financier', "info")
                          }
                        }}
                        className={` border-0 mb-2 text-white enableQuotebtn w-30`}>
                        {"Generate Invoice"}
                      </button>
                    </div>
                    <div className={"d-flex row justify-content-evenly px-4"} >
                      {generateInvoiceLabels.map((l, m) => {
                        return (
                          <label className="w-100 font-wt-500 font-size-14 text-color-value text-left mt-3" >{`${l.name} - `}<b>{graphData?.summaryOfInvoiceCommission?.[l.alt] || 'NA'} {l.unit}</b></label>
                        )
                      })}
                    </div>

                    <label
                      className='font-size-14 fc-0C0C0C font-wt-800 my-4'
                    >OR</label>
                    <div>

                      <label
                        className='font-size-16 fc-0C0C0C font-wt-800'
                      >Upload Generated Invoice Directly</label>

                      <div >
                        <div className='row my-3'>
                          <div className='col-md-4'>
                            <MultipleSelect
                              Id="Financier"
                              Label="Financier"
                              optiondata={financierList}
                              onChange={(e) => {
                                let generateInvoiceForLabel = []
                                let generateInvoiceForId = []
                                for (let index = 0; index < e.length; index++) {
                                  const element = e[index];
                                  generateInvoiceForLabel.push(element.name)
                                  generateInvoiceForId.push(element.id)
                                }
                                setGraphConfiguration({ ...graphConfiguration, generateInvoiceForLabel: generateInvoiceForLabel, generateInvoiceForId: generateInvoiceForId })
                              }}
                              value={graphConfiguration.generateInvoiceForId || []}
                              name="generateInvoiceForId"
                              labelKey={"name"}
                              valKey={"id"}
                            />
                          </div>
                          <div className='col-md-4'>
                            <NewInput type={"date"} label={"Commission From"} name={"commissionFrom"} value={graphConfiguration.commissionFrom}
                              onChange={handleGraphConfigurationChange} />
                          </div>

                          <div className='col-md-4'>
                            <NewInput type={"date"} label={"Commission To"} name={"commissionTo"} value={graphConfiguration.commissionTo}
                              onChange={handleGraphConfigurationChange} />
                          </div>

                          <div className='col-md-4'>
                            <NewInput type={"date"} label={"Bill Date"} name={"billCreationDate"} value={data.billCreationDate}
                              onChange={(e) => { setData({ ...data, [e.target.name]: e.target.value }) }} />
                          </div>

                          <div className='col-md-4'>
                            <NewInput type={"number"} label={"Bill Amount (USD)"} name={"billAmountInUsd"} value={data.billAmountInUsd}
                              onChange={(e) => { setData({ ...data, [e.target.name]: e.target.value }) }} />
                          </div>
                        </div>

                        <div className='mb-4'>
                          <div className='col-md-12'>
                            <FileInput
                              onUploadCancel={() => { setData({ ...data, invoiceAttachment: {} }) }} name={"invoiceAttachment"} value={data["invoiceAttachment"]} onChange={handleFile}
                              error={errors.invoiceAttachment} isEditable={true} />
                          </div>
                        </div>
                        <div className='row d-flex justify-content-evenly' >
                          <button type="button"
                            onClick={async () => {
                              handleUploadInvoiceFunction()
                            }}
                            className={` border-0 mb-2 text-white enableQuotebtn w-30`}>
                            {"Upload Invoice"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>


                </FinanceInvoiceModal>
              ) : null}

              {showInvPdf ? (
                <FinanceInvoiceModal modalSize={'xl'} limitinvoice={showInvPdf} closeSuccess={() => {
                  toggleInvPdf(false)
                }}>
                  <>
                    {graphConfiguration.generateInvoiceForId.map((i, j) => {
                      let lenderWiseData = graphData.summaryOfInvoiceCommission?.[i]
                      console.log("lenderWiseDatalenderWiseDatalenderWiseData", i, lenderWiseData);
                      if (lenderWiseData) {
                        let finalAmountArr = (lenderWiseData.commissionPayout / 1)?.toFixed(2).split(".")
                        return (
                          <>
                            <div className='d-flex flex-row justify-content-end mb-3'>
                              <button type="button"
                                onClick={() => printDiv("printCommissionInvoice" + j, `${lenderWiseData.item.lenderName}_${lenderWiseData.billNo}_${lenderWiseData.commissionFrom}~${lenderWiseData.commissionTo}_${lenderWiseData.billCreatedAt}`, [])}
                                className={` border-0 mb-2 text-white enableQuotebtn`}>
                                {"Print Invoice"}
                              </button>
                            </div>
                            <div id={"printCommissionInvoice" + j} className='px-4 pt-3'>
                              <p className='font-size-18 font-wt-800 text-center' >Tax Invoice</p>
                              {/* <div className='d-flex flex-row justify-content-between'>
                                <div>
                                  <img width={"100px"} height={"55px"} src={"assets/images/logo_1.png"} />
                                </div>
                                <div>
                                  <div className='d-flex flex-row'>
                                    <div><img width={"20px"} height={"20px"} src={"assets/images/call-vector.png"} /></div>
                                    <label className='text-color-value font-size-14 font-wt-600 text-center pl-2'>+ 91 84509 69138</label>
                                  </div>
                                  <div className='d-flex flex-row'>
                                    <div><img width={"20px"} height={"20px"} src={"assets/images/mail-vector.png"} /></div>
                                    <label className='text-color-value font-size-14 font-wt-600 text-center pl-2'>info@tradereboot.com</label>
                                  </div>
                                  <div className='d-flex flex-row'>
                                    <div><img width={"20px"} height={"20px"} src={"assets/images/web-vector.png"} /></div>
                                    <label className='text-color-value font-size-14 font-wt-600 text-center pl-2'>www.tradereboot.com</label>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <label className='text-color-value font-size-18 font-wt-600 text-center'>Invoice</label>
                              <div className='d-flex flex-row justify-content-between'>
                                <div>
                                  <label className='text-color-value font-size-12 font-wt-600 w-100 text-left'>To</label>
                                  <label className='text-color-value font-size-14 font-wt-600 w-100 text-left'>{lenderWiseData.item.lenderName}</label>
                                  <label className='text-color-value font-size-12 font-wt-600 w-100 text-left'>{lenderWiseData.item.lenderAddress}</label>
                                </div>
                                <div>
                                  <p className='text-color-value font-size-12 font-wt-600 text-left'>{`Invoice Number: ${lenderWiseData.billNo}`}</p>
                                  <p className='text-color-value font-size-12 font-wt-600 text-left'>{`Date: ${lenderWiseData.billCreatedAt}`}</p>
                                </div>
                              </div>
                              <label className='text-color-value font-size-14 font-wt-600 text-center my-4'>{`Commission payout from ${lenderWiseData.commissionFrom} - ${lenderWiseData.commissionTo}`}</label>
                              <div>
                                <NewTable
                                  disableAction={true}
                                  data={lenderWiseData.data}
                                  columns={[{ name: "Application Date" }, { name: "Exporter Name" }, { name: "Application Type & No." }, { name: "Due Date" }, { name: "Invoice Amount" }, { name: "Charges Amount" },
                                  { name: "Commission (%)" }, { name: "Commission Amount" }, { name: "Commission Status" }, { name: "Commission Paid On" }]} />
                                <div className='d-flex flex-row justify-content-end'>
                                  <table className='border'>
                                    <tbody className='border'>
                                      <tr className='border'>
                                        <th scope="row" className='font-size-14 font-wt-600'>Total Charges</th>
                                        <td className='font-size-14 font-wt-600'>{`${(lenderWiseData.totalCharges).toFixed(2)} USD`}</td>
                                      </tr>
                                      <tr className='border'>
                                        <th scope="row" className='font-size-14 font-wt-600'>Total Payout</th>
                                        <td className='font-size-14 font-wt-600'>{`${(lenderWiseData.commissionPayout).toFixed(2)} USD`}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div> */}
                              <div className='d-flex flex-row ' >
                                <div
                                  style={{
                                    "border": "1px solid black"
                                  }}
                                  className='col-6' >
                                  <p className='text-color-value font-size-16 font-wt-800 text-left m-1'>Tradereboot Fintech Pvt. Ltd.</p>
                                  <p className='text-color-value font-size-14 font-wt-400 text-left'>410,Atlanta Estate,Near Virvani Estate<br />
                                    W.E.Highway,Goregaon Mulund Link Road,<br />
                                    Goregaon(E),Mumbai - 400063<br />
                                    Contact : 8291474760<br />
                                    Maharashtra ( Code - 27)<br />
                                    GSTIN/UIN: 27AACI3158R1ZX<br />
                                    State Name :  Maharashtra, Code : 27<br />
                                    E-Mail : info@tradereboot.com<br />
                                    CIN :- U74999MH2019PTC327078
                                  </p>
                                </div>
                                <div
                                  style={{
                                    "border": "1px solid black"
                                  }}
                                  className='col-6' >
                                  <div style={{ borderBottom: '1px solid black' }} className='row' >
                                    <div style={{ borderRight: '1px solid black' }} className='col-6 py-2' >
                                      <p className='font-size-14 font-wt-400 m-0'>{`Invoice No.`}</p>
                                      <p className='font-size-14 font-wt-600 m-0'>{lenderWiseData.billNo}</p>
                                    </div>
                                    <div className='col-6 py-2' >
                                      <p className='font-size-14 font-wt-400 m-0'>{`Dated`}</p>
                                      <p className='font-size-14 font-wt-600 m-0'>{lenderWiseData.billCreatedAt}</p>
                                    </div>
                                  </div>
                                  <div style={{ borderBottom: '1px solid black' }} className='row' >
                                    <div style={{ borderRight: '1px solid black' }} className='col-6 py-2' >
                                      <p className='font-size-14 font-wt-400 m-0'>{`From`}</p>
                                      <p className='font-size-14 font-wt-600 m-0'>{lenderWiseData.commissionFrom}</p>
                                    </div>
                                    <div className='col-6 py-2' >
                                      <p className='font-size-14 font-wt-400 m-0'>{`To`}</p>
                                      <p className='font-size-14 font-wt-600 m-0'>{lenderWiseData.commissionTo}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className='px-3 py-1'
                                style={{ border: '1px solid black' }}
                              >
                                <label className='font-size-14 font-wt-400 m-0'>Buyer (Bill to)</label><br />
                                <label className='font-size-14 font-wt-600 m-0'>{companyBillName[lenderWiseData.item.lenderId] || lenderWiseData.item.lenderName}</label><br />
                                <label className='font-size-14 font-wt-500 m-0 w-20'>{lenderWiseData.item.lenderAddress}</label>
                              </div>
                              <div style={{ border: '1px solid black' }}>
                                <NewTable
                                  disableAction={true}
                                  data={lenderWiseData.data}
                                  columns={[{ name: "Application Date" }, { name: "Exporter Name" }, { name: "Application Type & No." },
                                  { name: "Due Date" }, { name: "Invoice Amount" },
                                  { name: "Charges Amount" },
                                  { name: "Commission (%)" }, { name: "Commission Amount" },
                                  { name: "Commission Status" }, { name: "Commission Paid On" }
                                  ]} />
                              </div>
                              <div style={{ border: '1px solid black' }} className='d-flex justify-content-between px-2 pt-2 mx-0' >
                                <label className='font-size-14 font-wt-500'>Total</label>
                                <label className='font-size-14 font-wt-600'>{`${(lenderWiseData.commissionPayout / 1)?.toFixed(2)} USD`}</label>
                              </div>
                              <div style={{ border: '1px solid black' }} className='d-flex row px-1 pt-2 mx-0' >
                                <label className='font-size-14 font-wt-500'>Amount Chargeable (in words)</label>
                                <label className='font-size-14 font-wt-600'>{"USD " + converter.toWords(finalAmountArr[0]) +
                                  `${finalAmountArr[1] > 0 ? (" and " + converter.toWords(finalAmountArr[1]) + " cents ") : ""}`}</label>
                              </div>
                              <div style={{ border: '1px solid black' }}>
                                <NewTable disableAction={true} tableSubColumnWith={'7rem'} data={[["", `${(lenderWiseData.commissionPayout / 1)?.toFixed(2)} USD`, <div className="signStatusDiv w7rem"><div className="w-50"></div><div className="w-50"></div></div>,
                                  <div className="signStatusDiv w7rem"><div className="w-50"></div><div className="w-50"></div></div>, ""]]} columns={[{ name: 'HSN/SAC' }, { name: "Taxable Value" }, { name: 'Central Tax', subColumns: ['Rate', "Amount"] },
                                  { name: 'State Tax', subColumns: ['Rate', "Amount"] },
                                  { name: 'Total Tax Amount' }]} />
                              </div>
                              <div style={{ border: '1px solid black' }} className='d-flex justify-content-between px-2 pt-2 mx-0' >
                                <label className='font-size-14 font-wt-500'>Total</label>
                              </div>
                              <div style={{ border: '1px solid black' }} className='d-flex row pt-2 mx-0' >
                                <label className='font-size-14 font-wt-500'>Tax Amount (in words)  :</label>
                              </div>
                              <div
                                style={{ border: '1px solid black' }}
                                className='d-flex row mx-0 py-2' >
                                <div className='col-6' >
                                  <label className='font-size-14 font-wt-500'>{`Company's PAN : AACI3158R`}</label>
                                </div>
                                <div style={{ borderLeft: '1px solid black' }} className='col-6 pl-2' >
                                  <p className='font-size-14 font-wt-600 m-0'>Company's Bank Details<br />
                                    Bank Name:HDFC Bank Ltd<br />
                                    Bank A/c. No. 50200041693423<br />
                                    Branch & IFS Code:Mumbai & HDFC0000212<br />
                                    Swift Code - HDFC0000322
                                  </p>
                                </div>
                              </div>
                              <div
                                style={{ border: '1px solid black' }} >
                                <p className='font-size-14 font-wt-600 text-right w-100 px-3' >For Tradereboot Fintech Pvt.Ltd.</p>
                                <div class="d-flex row px-4" >
                                  <div className='col-6 d-flex justify-content-center align-items-center'>
                                    <img width={120} height={120} src='assets/images/tradereboot_stamp.png' />
                                  </div>
                                  <div className='col-6 d-flex justify-content-end align-items-center'>
                                    <img width={100} height={50} src='assets/images/account_signature.png' />
                                  </div>
                                </div>
                                <p className='font-size-14 font-wt-600 text-right w-100 pt-0 px-3' >Authorised Signatory</p>
                              </div>
                              <p className='font-size-14 font-wt-600 text-center w-100'>This is a Computer Generated Invoice</p>
                            </div>
                            {/* <div className='my-5'>
                              <hr />
                              <hr />
                            </div> */}


                          </>
                        )
                      }
                    })}
                  </>
                </FinanceInvoiceModal>
              ) : null}

              <div className='filter-div'>
                <Filter showDownloadIcon={true} isAdditionalButton={true}
                  // onDownloadClick={downloadCSVFile}
                  filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                  showResultPerPage={true} count={invBillcount} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} >
                  <div className="d-flex ml-3">
                    <button type="button"
                      onClick={() => {
                        setGraphConfiguration({ ...graphConfiguration, generateInvoice: true })
                      }}
                      className={` border-0 mb-2 text-white enableQuotebtn`}>
                      {"Generate Invoice"}
                    </button>
                  </div>
                </Filter>
              </div>

              <div
                style={{}}>
                <NewTable disableAction={false}
                  columns={[{
                    name: "Financier", filter: false
                  }, {
                    name: "Invoice Date", filter: false
                  },
                  {
                    name: "Invoice No", filter: false
                  },
                  {
                    name: "Invoice Amount", filter: false
                  },
                  {
                    name: "Status", filter: false
                  },
                  {
                    name: "", filter: false
                  },
                  {
                    name: "", filter: false
                  },
                  {
                    name: "", filter: false
                  }
                  ]}
                  data={invBillTableData}
                  options={[
                    {
                      name: 'Upload Invoice Attachment', onClick: (indx) => {
                        let tempDbData = invBillData[indx]
                        setData({ ...data, selectedBillNo: tempDbData.billNo })
                        toggleAttachPopup(true)
                      }
                    },
                    {
                      name: 'View Invoice Attachment',
                      onClick: async (indx) => {
                        setshowLoader(true)
                        let tempDbData = invBillData[indx]
                        if (tempDbData?.attachment) {
                          let docFileObj = await getDocDetails(tempDbData.attachment)
                          toggleViewDoc({ show: true, doc: docFileObj })
                        }
                        else {
                          toastDisplay("No attachment found", "info")
                        }
                        setshowLoader(false)
                      }
                    },
                    {
                      name: "Delete Invoice", onClick: async (indx) => {
                        let tempDbData = invBillData[indx]
                        setshowLoader(true)
                        let res = await call('POST', "deleteInvoiceBill", { billNo: tempDbData.billNo })
                        toastDisplay(res, "success")
                        setshowLoader(false)
                        setRefresh(refresh + 1)
                      }
                    },
                    { name: "Mark as Paid", onClick: (indx) => changeInvoiceBillStatus(indx, 1) },
                    { name: "Mark as Due", onClick: (indx) => changeInvoiceBillStatus(indx, 0) }]}
                />
              </div>

              <Pagination perPage={filter.resultPerPage || 10} page={invBillPage} totalCount={invBillcount} onPageChange={(p) => setInvBillPage(p)} />

              <div>
                <label className='font-size-14 font-wt-700 mt-2 mb-3 text-decoration-underline'>Transactions</label>

                <div className='row  mb-3'>
                  <div className='w-80 align-items-center d-flex'>
                    <div className='w-auto pr-3'>
                      <label className='text-color-value font-size-14 font-wt-600'>Custom</label>
                    </div>
                    <div className='w-20 pr-3'>
                      <NewInput type={"date"} name={"commissionFrom"} value={transactionInputData.commissionFrom}
                        onChange={handleTransactionInputChange} />
                    </div>
                    <div className='w-20 pr-3'>
                      <NewInput type={"date"} name={"commissionTo"} value={transactionInputData.commissionTo}
                        onChange={handleTransactionInputChange} />
                    </div>
                  </div>
                  <div className='w-20 align-items-center d-flex justify-content-end'>
                    <div className='pr-3'>
                      <img
                        onClick={downloadCommissionFromFinancierData}
                        className='cursor' src='/assets/images/download_icon_with_bg.png' />
                    </div>
                  </div>
                </div>

                <div>
                  <NewTable
                    data={transactionTableData || []}
                    columns={[{ name: "Financier" }, { name: "Application type & no" }, { name: "Finance Amount" },
                    { name: "Commission (%)" }, { name: "Commission" }, { name: "Buyer Payment Date" }, { name: "Status" }]}
                    options={[
                      { name: "Mark as Paid", onClick: (indx) => changeCommissionStatus(indx, 1) },
                      { name: "Mark as Due", onClick: (indx) => changeCommissionStatus(indx, 0) }]}
                  />
                </div>

              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState
  }
}
export default connect(mapStateToProps, null)(InvoiceGenerateFinancier) 