import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import QRCode from 'qrcode';
import SignatureCanvas from 'react-signature-canvas'
import call from "../../../service";
import { getUserDataFromCookie } from "../../../utils/cookieHelper";
import { FileInput } from "../../../utils/FileInput";
import { convertImageToPdf, dataURItoBlob } from "../../../utils/myFunctions";
import toastDisplay from "../../../utils/toastNotification";

const signFonts = [
  { "font": "Nova Cut", "size": "18px" },
  { "font": "Yellowtail", "size": "18px" },
  { "font": "Whisper", "size": "18px" },
  { "font": "Oooh Baby", "size": "18px" }
]

const SignDocument = ({ signdoc, setSigndoc, docToSign, setUpdatedDoc, isBuyerSigning, buyerUserId, customUserId, otherDocsToSign, setUpdatedOtherDocs }) => {

  console.log("doc===>", signdoc, setSigndoc, docToSign, setUpdatedDoc, isBuyerSigning, buyerUserId, customUserId);

  if (!(docToSign.name && docToSign.name.split(".").reverse()[0].toLowerCase().includes("pdf"))) {
    toastDisplay("Only pdf document is allowed for digital signature", "info")
    setSigndoc(false)
  }

  const [tab, setTab] = useState("Type");
  const [data, setData] = useState({ typedSignStyle: 0 })
  const [errors, setErrors] = useState({})
  const [showLoader, setShowLoader] = useState(false)
  const [userIp, setUserIp] = useState("")
  const [userLocation, setUserLocation] = useState("")

  let userTokenDetails = getUserDataFromCookie()

  // console.log("userTokenDetails", userTokenDetails);

  useEffect(() => {
    // Fetch IP
    fetch(
      "https://geolocation-db.com/json/")
      .then((res) => res.json())
      .then((json) => {
        // console.log("userippp", json);
        if (json.IPv4) {
          setUserIp(json.IPv4)
        }
      }).catch(err => console.log("errorwhilefetchinglocation", err))
    // Fetch Location
    navigator.geolocation.getCurrentPosition(function (position) {
      setUserLocation(position.coords.latitude + ", " + position.coords.longitude)
    });
  }, [])

  const sigCanvas = useRef({});

  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!(file_type.includes("png"))) {
        setErrors({ ...errors, [event.target.name]: "Please upload sign in png format" })
        return null
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        setData({ ...data, [event.target.name]: fileObj, [event.target.name + "base64"]: reader.result })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  const handleChange = event => {
    event.persist()
    setData({ ...data, [event.target.name]: event.target.value })
  }


  async function handleValidation() {
    let validated = true
    if (tab === "Type" && !(data.typedSign && data.typedSign.trim())) {
      toastDisplay("Please type your sign to continue", "info")
      validated = false
    }
    if (tab === "Type" && (data.typedSign && data.typedSign.trim()) && data.typedSignStyle == undefined) {
      toastDisplay("Please select sign font to continue", "info")
      validated = false
    }
    if (tab === "Draw" && sigCanvas.current.isEmpty()) {
      toastDisplay("Please draw your sign to continue", "info")
      validated = false
    }
    if (tab === "Upload" && !data.pngSign) {
      toastDisplay("Please upload your sign to continue", "info")
      validated = false
    }
    if (validated) {
      console.log("docToSign========>", docToSign);
      setShowLoader(true)
      let signBase64 = null
      if (tab === "Draw") {
        signBase64 = sigCanvas.current.toDataURL()
      }
      if (tab === "Upload") {
        signBase64 = data.pngSignbase64
      }
      let qrBase64 = await QRCode.toDataURL(
        `Link : "tradereboot.com"
        IP: ${userIp}
        Location : ${userLocation}
        Datetime : ${moment().format('DD MMM, YYYY hh:mm a') + " IST"}`, { type: "png" })


      if (otherDocsToSign && otherDocsToSign.length) {
        let temp = []
        for (let index = 0; index < otherDocsToSign.length; index++) {
          const element = otherDocsToSign[index];
          let newDigitalSignResp = await call('POST', 'newDigitalSign', {
            docBase64: element.filebase64,
            userId: isBuyerSigning ? buyerUserId : (customUserId || userTokenDetails.user_id),
            tab, qrBase64, typedSign: data.typedSign, typedSignStyle: data.typedSignStyle,
            ip: userIp, location: userLocation,
            signBase64, isBuyerSigning
          })
          if (newDigitalSignResp.data) {
            let blob = dataURItoBlob(newDigitalSignResp.data);
            let file = new File([blob], element["name"], { type: "application/pdf" });
            file["filebase64"] = newDigitalSignResp.data
            file["signatureId"] = newDigitalSignResp.signatureId
            temp.push(file)
          }
        }
        setUpdatedOtherDocs(temp)
      }

      call('POST', 'newDigitalSign', {
        docBase64: docToSign.filebase64,
        userId: isBuyerSigning ? buyerUserId : (customUserId || userTokenDetails.user_id),
        tab, qrBase64, typedSign: data.typedSign, typedSignStyle: data.typedSignStyle,
        ip: userIp, location: userLocation,
        signBase64, isBuyerSigning
      }).then(async (result) => {
        setShowLoader(false)
        // console.log('running newDigitalSign api-->', result);
        if (result.data) {
          let blob = dataURItoBlob(result.data);
          let file = new File([blob], docToSign["name"], { type: "application/pdf" });
          file["filebase64"] = result.data
          file["signatureId"] = result.signatureId
          if (docToSign["path"]) {
            file["path"] = docToSign["path"]
          }
          setUpdatedDoc(file)
          toastDisplay("Document signed successfully", "success")
          setSigndoc(false)
        }
        else {
          toastDisplay("Something went wrong", "error")
        }
      }).catch((e) => {
        console.log('error in signDocument', e);
        setShowLoader(false)
        toastDisplay("Something went wrong", "error")
      });
    }
  }

  const clear = () => {
    sigCanvas.current.clear()
    // console.log("clear");
  }

  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <div className={`modal fade show p-3 ${signdoc && "show"}`} id="exampleModal" style={{ display: "block", zIndex: '100001' }}>
        <div className="modal-dialog modal-lg signmodaldialog">
          <div className="modal-content p-3 mx-auto">
            <div className="text-right">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setSigndoc(false)}></button>
            </div>
            <div className="modal-body">
              <nav>
                <div class="nav nav-tabs signdoctabs col-md-8 mx-auto justify-content-around" id="nav-tab" role="tablist">
                  <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-home" aria-selected="true" onClick={() => setTab("Type")}>Type</button>
                  <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-profile" aria-selected="false" onClick={() => setTab("Draw")}>Draw</button>
                  <button class="nav-link" id="nav-contact-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-contact" aria-selected="false" onClick={() => setTab("Upload")}>Upload</button>
                </div>
              </nav>
              <div class="tab-content d-block mx-auto" id="nav-tabContent">
                {tab === "Type" && <div class="tab-pane fade show active" id="nav-home" role="tabpanel" >
                  <div class="col-md-12">
                    <div class="mb-3 mt-5 col-md-8 mx-auto">
                      <input type="text" class="form-control borderRadius-8" id="exampleFormControlInput1" placeholder="Type your name"
                        name={"typedSign"} value={data.typedSign} onChange={handleChange}
                      />
                    </div>
                    <div className="row justify-evenly">
                      {signFonts.map((item, index) => {
                        return (
                          <div
                            onClick={() => {
                              setData({ ...data, typedSignStyle: index })
                            }}
                            style={data.typedSignStyle == index ? {
                              border: "2px solid #B9EFFF"
                            } : null}
                            className={`mb-3 col-md-6 form-control w-45 cursor borderRadius-8`}>
                            <label
                              style={{
                                "fontFamily": item.font,
                                "fontSize": item.size
                              }}
                            >{data.typedSign}</label>
                          </div>
                        )
                      })}
                    </div>
                    <div className="d-flex mt-3 mx-3">
                      <button
                        onClick={handleValidation}
                        className=" new-btn w-auto py-2 px-4 text-white">Add Signature</button>
                    </div>
                  </div>
                </div>}
                {tab === "Draw" && <div class="tab-pane fade show active" id="nav-profile" role="tabpanel">
                  <div className="col-md-8 mx-auto">
                    <p className="d-flex justify-content-between mt-4 ms-1 me-2"><span className="drawsign">Draw Signature</span> <span className="cursor" onClick={() => clear()}><img src={"assets/images/supplier-images/refresh.png"} alt="" /></span></p>
                    <SignatureCanvas penColor='black' ref={sigCanvas}
                      canvasProps={{ width: 450, height: 100, className: 'sigCanvas border border-width-2 borderRadius' }} />
                    <div className="d-flex justify-content-center mt-3">
                      <button
                        onClick={handleValidation} className="new-btn w-auto py-2 px-4 text-white">Add Signature</button>
                    </div>

                  </div>
                </div>}
                {tab === "Upload" && <div class="tab-pane fade show active" id="nav-contact" role="tabpanel">
                  <div className="col-md-8 mx-auto">
                    {/* <p className="d-flex justify-content-between"><span>Upload Signature</span>
                    </p> */}
                    <p className="d-flex mt-4 justify-content-between mx-3"><span className="drawsign">Upload Signature</span> <span className="cursor" onClick={() => clear()}><img src={"assets/images/supplier-images/refresh.png"} alt="" /></span></p>
                    <div className="form-group col-md-12">
                      <FileInput name={"pngSign"} value={data.pngSign} error={errors.pngSign}
                        onChange={handleFile} isEditable={true}
                        onUploadCancel={() => setData({ ...data, "pngSign": null })}
                      />
                      {errors.pngSign ? <div class="text-danger mt-2">
                        <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                        <b>{errors.pngSign}</b></div> : ''}
                    </div>
                    <div className="text-center">
                      <button
                        onClick={handleValidation}
                        className="mt-3 new-btn w-auto py-2 px-4 text-white">Add Signature</button>
                    </div>
                  </div>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SignDocument;