export default function validate(values, spec, tnc, activeclass) {
  //console.log("values in validate-->", values)
  //console.log("spec in validate-->", spec)
  //console.log("tnc in validate-->", tnc)
  //console.log("activeclass in validate-->", activeclass)
  let errors = {};
  // console.log("valuesssssssssssssssssssssssssss===", values, spec, tnc, activeclass);
  if (activeclass === 1) {
    if (!values.contractType) {
      errors.contractType = 'Contract Type is Mandatory';
    }
    if (!values.supplierName) {
      errors.supplierName = 'Select Second Party';
    }
    if (!values.commodity) {
      errors.commodity = 'Select a Commodity Name';
    }
    if (!values.commCatId) {
      errors.commCatId = 'Select a Commodity Type/Category';
    }
    // if (!values.laycanStart) {
    //   errors.laycanStart = 'Laycan Start is Required';
    // }
    // if (!values.laycanEnd) {
    //   errors.laycanEnd = 'Laycan End is Required';
    // }
    if (!values.loadingCountry) {
      errors.loadingCountry = 'Loading Country is Required';
    }
    if (!values.loadingPort) {
      errors.loadingPort = 'Loading Port is Required';
    }
    if (!values.unloadingCountry) {
      errors.unloadingCountry = 'Unloading Country is Required';
    }
    if (!values.unloadingPort) {
      errors.unloadingPort = 'Unloading Port is Required';
    }
  } else if (activeclass === 2) {

    let keysArray = Object.keys(spec)

    if (keysArray.length) {
      for (let i = 0; i < keysArray.length; i++) {
        if (!spec[keysArray[i]].value || !spec[keysArray[i]].rejectionValue || !spec[keysArray[i]].rejectionSymbol) {
          errors.Specification = 'Make Sure all the Values, Rejection values and Rejection At are filled, Check Spec key named - ' + spec[keysArray[i]].name;
          break;
        }
      }
    } else {
      errors.Specification = 'Specification is Required';
    }
  } else if (activeclass === 3) {
    if (!values.priceType / 1) {
      errors.priceType = 'Price Type is Required';
    }
    if (!(values.price / 1)) {
      errors.price = 'Price is Required';
    }
    if (!values.currency) {
      errors.currency = 'Currency is Required';
    }
    if (!(values.quantity / 1)) {
      errors.quantity = 'Quantity is Required';
    }
  }
  // else if (activeclass === 4) {
  //   // if (!values.investigationAgencyImporter || values.investigationAgencyImporter === "") {
  //   //   errors.investigationAgencyImporter = 'investigationAgencyImporter is Required';
  //   // }
  //   // if (!values.investigationAgencyExporter || values.investigationAgencyExporter === "") {
  //   //   errors.investigationAgencyExporter = 'investigationAgencyExporter is Required';
  //   // }
  //   // if (!values.investigationAgencythird || values.investigationAgencythird === "") {
  //   //   errors.investigationAgencythird = 'investigationAgencythird is Required';
  //   // }

  // } else if (activeclass === 5) {
  //   // if (!values.commodityInsurer || values.commodityInsurer === "") {
  //   //   errors.commodityInsurer = 'commodityInsurer is Required';
  //   // }
  //   // if (!values.tradeInsurer || values.tradeInsurer === "") {
  //   //   errors.tradeInsurer = 'tradeInsurer is Required';
  //   // }
  // } 
  else if (activeclass === 4) {
    if (!values.paymentMode) {
      errors.paymentMode = 'Payment Mode is a Mandatory Field';
    }
    // if (!values.buyerBank) {
    //   errors.buyerBank = 'Bank is a Mandatory Field';
    // }
    // if (!values.supplierBank) {
    //   errors.supplierBank = 'Bank is a Mandatory Field';
    // }
    //bank validate rules
  } else if (activeclass === 5) {

    let keysArray = Object.keys(tnc)

    if (keysArray.length) {
      for (let i = 0; i < keysArray.length; i++) {
        if (!tnc[keysArray[i]].value) {
          errors.tnc = 'Make Sure all the Values are filled, Check TNC key named - ' + tnc[keysArray[i]].name;
          break;
        }
      }
    } else {
      errors.tnc = 'TNC is Required';
    }

  }

  console.log(errors)
  return errors;
  // return {}
}
