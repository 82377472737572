import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { FileInput } from "../../../../utils/FileInput";
import toastDisplay from "../../../../utils/toastNotification";
import call from "../../../../service";
import { ToastContainer } from "react-toastify";
import { getDocDetails, most_used_currencies, convertImageToPdf, LCTypesObject, LCPurposeObject } from "../../../../utils/myFunctions";
import SideBarV2 from "../../../partial/sideBarV2";
import HeaderV2 from "../../../partial/headerV2";
import FinanceInvoiceModal from "../../../InvoiceDiscounting/contract/components/financeinvoiceModal";
import TooltipModal from "../../../InvoiceDiscounting/components/Tooltipmodal";
import SignDocument from "../../../InvoiceDiscounting/components/SignDocument";
import { useHistory } from "react-router";
import { InputWithSelect, NewInput } from "../../../../utils/newInput";
import moment from "moment";


const addBuyerTabs = [
  { name: "Applicant form" },
  { name: "Post-Shipment" },
  { name: "Documents" },
  { name: "Review" },
]

const reviewFormPostShipment = [
  { "name": "Invoice Amount", val: "contractAmount", unit: "contractAmountCurrency" },
  { "name": "Buyer", val: "buyerName" },
  { "name": "Invoice No.", val: "invoiceRefrenceNo" },

  { "name": "Purchase Order No.", val: "poRefrenceNo" },
  { "name": "Invoice Issue Date", val: "invoiceIssueDate" },
  { "name": "LC Tenor (in Days)", val: "lcTenor" },
  { "name": "Invoice Due Date", val: "invoiceDueDate" },
  { "name": "Credit Days", val: "creditDays" }

]

const reviewForm = [
  { "name": "Buyer Name", val: "buyerName" },
  { "name": "Previous year annual sales", val: "previousAnnualSale", unit: "buyerCurrency" },
  { "name": "Contact Number:", val: "buyerPhone", unit: "buyerPhoneCode" },
  { "name": "Expected current year’s annual sale", val: "currentAnnualSale", unit: "buyerCurrency" },

  { "name": "Email Id", val: "buyerEmail" },
  { "name": "Inco Terms", val: "incoTerms" },
  { "name": "Website", val: "buyerWebsite" },
  { "name": "Terms of Payment", val: "termsOfPayment" },

  { "name": "Address", val: "buyerAddress" },
  { "name": "Product Details", val: "productDetails" },

  { "name": "DUNS No", val: "buyerDUNSNo" },
  { "name": "HSN Code", val: "buyerHsnCode" },

]


const reviewForm3 = [
  { "name": "LC type", val: "lcType" },
  { "name": "Beneficiary bank account no.", val: "beneficiaryAccountNo" },
  { "name": "LC number", val: "lcNo" },
  { "name": "Beneficiary bank IFSC code", val: "beneficiaryIfscCode" },

  { "name": "LC tenor", val: "lcTenor" },
  { "name": "LC issuing bank name", val: "lcIssuingBankName" },
  { "name": "Country of origin", val: "countryOfOrigin" },
  { "name": "LC issuing bank SWIFT code", val: "lcIssuingBankSwiftCode" },
  { "name": "Shipment from country", val: "shipmentFromCountry" },
  { "name": "Shipment to country", val: "shipmentToCountry" },
  { "name": "LC issuing bank address", val: "lcIssuingBankAddress" },
  { "name": "Port of loading", val: "portOfLoading" },
  { "name": "Confirming bank name", val: "confirmingBankName" },
  { "name": "Port of discharge", val: "portOfDischarge" },
  { "name": "Confirming bank SWIFT code", val: "confirmingBankSwiftCode" },

  { "name": "Commodity", val: "commodity" },
  { "name": "Expected date of shipment", val: "expectedDateOfShipment" },
  { "name": "Beneficiary bank name", val: "beneficiaryBankName" },
  { "name": "Confirming bank address", val: "confirmingBankAddress" },
  { "name": "Beneficiary bank SWIFT code", val: "beneficiarySwiftCode" },
]


const LcApplyFinanceBtn = ({ userTokenDetails, navToggleState }) => {

  const queryParams = new URLSearchParams(window.location.search)
  const quoteId = queryParams.get("id")
  const onlyView = queryParams.get("view")
  const amendmentId = queryParams.get("amendmentId")
  const history = useHistory();

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;


  const [tab, setTab] = useState(0)
  const [modal, setModal] = useState(false);
  const [limitinvoice, setLimitinvoice] = useState(false);
  const [experience, setExperience] = useState(false);
  const [emoji, setEmoji] = useState("");

  const [tooltip, setTooltip] = useState(false);
  const [signdoc, setSigndoc] = useState(false);
  const [data, setData] = useState({
    contractAmountCurrency: "USD", nameTitle: "Mr", applicantName: userName,
  })
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)
  const [addMoreDoc, setAddMoreDoc] = useState([null])
  const [contractDocs, setContractDocs] = useState([])

  const [moreLCDetails, setMoreLCDetails] = useState(false)
  const [lcCodes, setLCCodes] = useState({})
  const [ocrFields, setOcrFields] = useState({})

  const [beforeAmendmentFiels, setBeforeAmendmentFields] = useState({})
  const [afterAmendmentFiels, setAfterAmendmentFields] = useState({})

  const [amend, setAmend] = useState(false);


  const closeSuccess = () => {
    setAmend(false);
    // setExperience(true);
  }

  useEffect(() => {

    call('POST', 'getAmmendmentDetails', {
      amendmentId
    }).then((result) => {
      // console.log('running getAmmendmentDetails api -->', result);
      if (result.length) {
        setBeforeAmendmentFields(result[0]["beforeDetails"] || {})
        setAfterAmendmentFields(result[0]["changedDetails"] || {})
      }
    }).catch((e) => {
      // console.log('error in getAmmendmentDetails', e);
    })

    // Get master data start
    call('POST', 'getLCFieldMaster', {
    }).then((result) => {
      // console.log('running getLCFieldMaster api -->', result);
      let lcFieldObj = {}
      result.mandatoryFields.forEach((item) => {
        lcFieldObj[item.tag] = item.fieldName
      })
      result.optionalFields.forEach((item) => {
        lcFieldObj[item.tag] = item.fieldName
      })
      setLCCodes(lcFieldObj)
    }).catch((e) => {
      // console.log('error in getLCFieldMaster', e);
    })
    // Get master data end

    //Get quote data start
    setshowLoader(true)
    call('POST', 'getLCQuoteApplicationById', { quoteId }).then(async (result) => {
      console.log('running getLCQuoteApplicationById api-->', result);
      if (result.length) {
        let reqLetterDocument = {}
        let additionalDetails = {}

        setOcrFields(result[0]["ocrFields"])

        additionalDetails = {
          selectedFinancier: result[0].selectedFinancier,
          selectedFinancierName: JSON.parse(result[0]["selectedQuote"])["lender_name"],
          sameFinancierForDiscounting: result[0].sameBankForLCDiscounting ? true : false
        }

        let financeDocs = {}
        if (onlyView) {
          financeDocs["originalLC"] = await getDocDetails(result[0].originalLC)
          financeDocs["invReport"] = await getDocDetails(result[0].invReport)
          financeDocs["certOfOrigin"] = await getDocDetails(result[0].certOfOrigin)
          financeDocs["billOfLading"] = await getDocDetails(result[0].billOfLading)
        }

        reqLetterDocument = await getDocDetails(result[0].reqLetterOfConfirmation)
        let draftLCDocument = await getDocDetails(result[0].draftLC)
        let invoiceDocument = await getDocDetails(result[0].buyersDocId ? result[0].buyersDocId.split(":")[0] : null)
        let poDocument = await getDocDetails(result[0].buyersDocId ? result[0].buyersDocId.split(":")[1] : null)
        let buyerOtherDocIdArr = result[0].buyerOtherDocs ? result[0].buyerOtherDocs.split(":") : []
        let contractDocIdArr = result[0]["contractDocsFromFinanciers"].split(":")

        let otherDocObj = {}
        let moreDocArr = addMoreDoc

        let contractDocObj = {}
        let contractDocArr = contractDocs

        for (let index = 0; index < buyerOtherDocIdArr.length; index++) {
          otherDocObj["otherDoc" + index] = await getDocDetails(buyerOtherDocIdArr[index])
          moreDocArr.push(null)
        }

        for (let index = 0; index < contractDocIdArr.length; index++) {
          contractDocObj["contractDoc" + index] = await getDocDetails(contractDocIdArr[index])
          contractDocArr.push(null)
        }

        let financierQuotes = result[0]["financierQuotes"] ? JSON.parse(result[0]["financierQuotes"]) : []

        for (let index = 0; index < financierQuotes.length; index++) {
          if (financierQuotes[index]["lender_name"].toLowerCase().includes("stenn")) {
            financierQuotes[index]["logo"] = "assets/images/supplier-images/stenn-logo.png"
          }
          else if (financierQuotes[index]["lender_name"].toLowerCase().includes("modifi")) {
            financierQuotes[index]["logo"] = "assets/images/supplier-images/modifi-logo.png"
          }
        }

        setData({
          ...data,
          ...result[0], contractAmountCurrency: result[0].contractAmountCurrency || "USD",
          poRefrenceNo: result[0].poRefNo, invoiceRefrenceNo: result[0].invRefNo, selectedQuote: JSON.parse(result[0].selectedQuote), buyerCurrency: result[0].buyerCurrency ? result[0].buyerCurrency.split(":")[1] : null, invoiceDocument, poDocument, draftLCDocument, reqLetterDocument, ...additionalDetails,
          limitRequired: result[0]["requiredLimit"], lcType: LCTypesObject[result[0]["lcType"]],
          limitRequiredCurrency: result[0]["requiredLimitCurrency"], ...otherDocObj, ...contractDocObj, financierQuotes, tcAccepted: onlyView ? true : false,
          ...financeDocs
        })
        setAddMoreDoc(moreDocArr)
        setContractDocs(contractDocArr)
      }
      setshowLoader(false)
    }).catch((e) => {
      // console.log('error in getLCQuoteApplicationById', e);
      setshowLoader(false)
    });
    //Get quote data end
  }, [])


  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }
      if (event.target.name.includes("otherDoc")) {
        setAddMoreDoc(addMoreDoc.concat(null))
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (!file_type.includes("pdf")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  useEffect(() => {
    if (data.invoiceDueDate && !onlyView) {
      let creditDays = moment(data.invoiceDueDate, "YYYY-MM-DD").diff(moment(), "days")
      setData({ ...data, creditDays: creditDays / 1 })
    }
  }, [data.invoiceDueDate])

  async function handleValidation(tabIndex) {

    if (tabIndex != undefined) {
      if (tabIndex < tab) {
        return setTab(tabIndex)
      }
      else if (tabIndex == tab) {
        return null
      }
      else {
        return toastDisplay("Click on continue button to go to next form", "info")
      }
    }

    let err = {}

    if (tab === 1) {
      let validateFields = ["applicantName", "poRefrenceNo", 'contractAmount', 'contractAmountCurrency', 'invoiceRefrenceNo', 'invoiceIssueDate', 'invoiceDueDate', 'creditDays']
      validateFields.forEach((item, index) => {
        if (!data[item]) {
          err[item] = "Mandatory Field"
        }
      })
    }

    if (tab === 2) {
      let validateDocs = ["originalLC", "invReport", "certOfOrigin", "billOfLading"]
      validateDocs.forEach((item, index) => {
        if (!(data[item] && data[item].signatureId)) {
          err[item] = "Signing document is mandatory"
        }
        if (!(data[item] && data[item].filebase64)) {
          err[item] = "Mandatory Field"
        }
      })
    }

    if (!Object.keys(err).length) {
      if (tab === 3) {
        setshowLoader(true)
        let formData = new FormData()
        formData.append("selectedFinancier", data.selectedFinancier)
        formData.append("lcNo", data.lcNo)
        formData.append("onlyView", onlyView)
        formData.append("buyerId", data.buyerId)
        formData.append("buyerOtherDocs", data.buyerOtherDocs)
        formData.append("userId", userId)
        formData.append("applicationId", data.id)

        formData.append("originalLC", data.originalLC)
        formData.append("invReport", data.invReport)
        formData.append("certOfOrigin", data.certOfOrigin)
        formData.append("billOfLading", data.billOfLading)

        formData.append("originalLCSignId", data.originalLC.signatureId)
        formData.append("invReportSignId", data.invReport.signatureId)
        formData.append("certOfOriginSignId", data.certOfOrigin.signatureId)
        formData.append("billOfLadingSignId", data.billOfLading.signatureId)

        formData.append("poRefNo", data.poRefrenceNo)
        formData.append("invRefNo", data.invoiceRefrenceNo)
        formData.append("invoiceIssueDate", data.invoiceIssueDate)
        formData.append("invoiceDueDate", data.invoiceDueDate)
        formData.append("contractAmount", data.contractAmount)
        formData.append("contractAmountCurrency", data.contractAmountCurrency)
        formData.append("creditDays", data.creditDays)

        addMoreDoc.forEach((k, index) => {
          if (data["otherDoc" + index]) {
            formData.append("otherDoc" + index, data["otherDoc" + index])
            if (data["otherDoc" + index]["signatureId"]) {
              formData.append("otherDoc" + index + "SignId", data["otherDoc" + index]["signatureId"])
            }
          }
        })

        call('POST', 'applyForLCFinance', formData).then((result) => {
          // console.log('running applyForLCFinance api-->', result);
          toastDisplay(result, "success", () => window.location = "/LcApplyforFinance")
          setshowLoader(false)
        }).catch((e) => {
          // console.log('error in applyForLCFinance', e);
          toastDisplay(e || "Something went wrong", "error")
          setshowLoader(false)
        });
        return null
      }
      else {
        setTab(tab + 1)
      }
    }

    setErrors(err)
  }

  const handleChange = async (event) => {
    event.persist()
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }


  return (
    <>{showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="LcFinancebtn" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={LCPurposeObject[data.lcPurpose]}
              userTokenDetails={userTokenDetails} />
            <a className="cursor"
              onClick={() => { history.go(-1) }}
            ><img className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
            <div>
              <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                {addBuyerTabs.map((item, index) => {
                  return (
                    <li>
                      <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                        onClick={() => { handleValidation(index) }}
                      >
                        {item.name}</a>
                    </li>
                  )
                })}
                {!onlyView ? (
                  <a
                    style={{ right: '3rem' }}
                    onClick={() => setAmend(true)} alt="" className="text-decoration-none text-color1 font-weight500 font-size-13 w-49 text-end cursor position-absolute">Amend Application <img src={"assets/images/Lc/ArrowBack.png"} alt="" height={10} width={10} /></a>
                ) : null}</ul>
              <div className="card mt-1">
                {tab === 0 ? (
                  <div className='px-5 py-4 pt-5'>
                    <div className="row">
                      {data.buyerId ? (
                        <div className='w-100'>
                          <label className='font-wt-500 font-size-16'><u>Buyer Details</u>
                            <img className="ms-3"
                              onClick={() => { window.location = `/buyerManagement?editBuyer=${data.buyerId}` }}
                              src={"assets/images/edit.png"} alt="edit" />
                          </label>
                          <div className='row'>
                            {reviewForm.map((item) => {
                              return (
                                // <div className='col-5 p-0'>
                                //   <label className='col-6 lh-18 text-color-label font-size-14'>{item.name}</label>
                                //   <label className='lh-18 font-size-14'>:</label>
                                //   <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                //     {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}
                                //   </label>
                                // </div>
                                <div className="col-md-6 pe-5">
                                  <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      ) : null}
                      <div className={`w-100 ${data.buyerId ? 'mt-5' : ''} `}>
                        <label className='font-wt-500 font-size-16'><u>LC Details</u>
                          {/* <img className="ms-3"
                            onClick={() => { window.location = `/LcDiscounting?buyer=${data.buyerId}&purpose=${data.lcPurpose}&id=${data.id}&view=true` }}
                            src={"assets/images/edit.png"} alt="edit" /> */}
                        </label>
                        <div className='row'>
                          {reviewForm3.map((item) => {
                            return (
                              // <div className='col-5 p-0'>
                              //   <label className='col-6 lh-18 text-color-label font-size-14'>{item.name}</label>
                              //   <label className='lh-18 font-size-14'>:</label>
                              //   <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                              //     {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}
                              //   </label>
                              // </div>
                              <div className="col-md-6 pe-5">
                                <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                              </div>
                            )
                          })}
                        </div>

                        {/* <label
                          onClick={() => setMoreLCDetails(!moreLCDetails)}
                          className='text-color1 mt-2 lh-18 font-size-14 cursor'><b>
                            {!moreLCDetails ? "More details ===>" : "Less details ===>"}</b></label> */}

                        {moreLCDetails ? (
                          <div className="mt-4">
                            <label className='font-wt-500 font-size-16'><u>More Details</u></label>
                            <div className='row'>
                              {lcCodes && Object.keys(lcCodes).map((item) => {
                                let isFieldChanged = afterAmendmentFiels[item] && afterAmendmentFiels[item] != beforeAmendmentFiels[item] ? true : false
                                return (
                                  <div className='col-5 p-0 mb-2'>
                                    <label className={`col-6 lh-18 ${isFieldChanged ? " text-color1 " : " text-color-label "} font-size-14`}>{item + "-" + lcCodes[item]}</label>
                                    <label className={`lh-18 font-size-14 ${isFieldChanged ? " text-color1 " : " text-color-label "}`}>:</label>
                                    <label
                                      style={{
                                        "maxHeight": "5rem",
                                        "overflow": "auto",
                                        "vertical-align": "bottom"
                                      }}
                                      className={`col-5 lh-18 pl-4 font-wt-600 font-size-14 ${isFieldChanged ? " text-color1 " : " text-color-value "}`}>
                                      {ocrFields[item] || "NA"}
                                    </label>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        ) : null}


                      </div>
                      <div className='w-100 mt-5'>
                        <label className='font-wt-500 font-size-16'><u>Documents</u>
                        </label>
                        <div className='row mt-2'>

                          {contractDocs.length ? contractDocs.map((item, index) => {
                            if (data["contractDoc" + index]) {
                              return (
                                <div className="col-md-4">
                                  <label className="font-size-14">{data["contractDoc" + index]["doc_name"]}</label>
                                  <div className="row form-group">
                                    <div className="col-md-12">
                                      <FileInput name={"contractDoc" + index} value={data["contractDoc" + index]}
                                        isEditable={false}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          }) : null}

                          {data?.reqLetterDocument?.name ? (
                            <div className="col-md-4">
                              <label className="font-size-14">Request letter of confirmation</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"reqLetterDocument"} value={data.reqLetterDocument}
                                    isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : null}

                          <div className="col-md-4">
                            <label className="font-size-14">Draft LC</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"draftLCDocument"} value={data.draftLCDocument}
                                  isEditable={false}
                                />
                              </div>
                            </div>
                          </div>
                          {data?.invoiceDocument?.name ? (
                            <div className="col-md-4">
                              <label className="font-size-14">Invoice</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"invoiceDocument"} value={data.invoiceDocument}
                                    isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {data?.poDocument?.name ? (
                            <div className="col-md-4">
                              <label className="font-size-14">Purchase Order</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"poDocument"} value={data.poDocument}
                                    isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : null}

                          {addMoreDoc.length ? addMoreDoc.map((item, index) => {
                            if (data["otherDoc" + index]) {
                              return (
                                <div className="col-md-4">
                                  <label className="font-size-14">Add More</label>
                                  <div className="row form-group">
                                    <div className="col-md-12">
                                      <FileInput name={"otherDoc" + index} value={data["otherDoc" + index]}
                                        isEditable={false}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          }) : null}

                        </div>
                      </div>

                      <div className='w-100 mt-5'>
                        <label className='font-wt-500 font-size-16'><u>Financier selected</u></label>
                        <div className="d-flex">
                          <div className="my-3 col-2 px-0">
                            <label className="font11">{LCPurposeObject[data.lcPurpose]}</label>
                            <p
                              className="browse-div font-size-13 cursor text-center m-0 col-10 border-finance text-dark" >
                              {data?.selectedQuote?.lender_name ? data.selectedQuote.lender_name : "NA"}
                            </p>
                          </div>

                          {data.lcPurpose === "lc_confirmation" && data.sameFinancierForDiscounting ? (
                            <div className="my-3 col-2">
                              <label className="font11">LC Discounting</label>
                              <p
                                className="browse-div font-size-13 cursor text-center m-0 col-10 border-finance text-dark" >
                                {data.selectedFinancierName}
                              </p>
                            </div>
                          ) : null}
                        </div>
                      </div>

                    </div>
                  </div>
                ) : null}

                {tab === 1 ? (
                  <div className='px-5 py-4 pt-5'>
                    <div className="row">

                      <div className="col-md-5 mb-2">
                        <div className="col-md-12">
                          <InputWithSelect isDisabled={true} isAstrix={true} type={"text"} label={"Applicant name"}
                            selectData={[{ name: "Mr" }, { name: "Miss" }]}
                            selectName={"name"} selectValue={data.nameTitle}
                            optionLabel={"name"} optionValue={'name'}
                            name={'applicantName'} value={data.applicantName} error={errors.applicantName}
                            onChange={handleChange} />
                        </div>
                      </div>

                      <div className="col-md-5 mb-2">
                        <div className="col-md-12">
                          <NewInput isDisabled={onlyView} isAstrix={true} type={"text"} label={"Purchase order number"}
                            name={"poRefrenceNo"} value={data.poRefrenceNo} error={errors.poRefrenceNo}
                            onChange={handleChange} />
                        </div>
                      </div>


                      <div className="col-md-5 mb-2">
                        <div className="col-md-12">
                          <NewInput isAstrix={true} isDisabled={true} type={"text"} label={"Buyer Name"}
                            name={"buyerName"} value={data.buyerName} error={errors.buyerName}
                            onChange={handleChange} />
                        </div>
                      </div>


                      <div className="col-md-5 mb-2">
                        <div className="col-md-12 d-flex">
                          <div className="col-md-7 ps-0">
                            <InputWithSelect isDisabled={onlyView} isAstrix={true} type={"number"} label={"Invoice Amount"}
                              selectData={most_used_currencies}
                              selectName={"contractAmountCurrency"} selectValue={data.contractAmountCurrency}
                              optionLabel={"code"} optionValue={'code'}
                              name={'contractAmount'} value={data.contractAmount} error={errors.contractAmount}
                              onChange={handleChange} />
                          </div>
                          <div className="col-md-5 pe-0">
                            <NewInput isDisabled={onlyView} isAstrix={true} type={"text"} label={"Invoice Number"}
                              name={"invoiceRefrenceNo"} value={data.invoiceRefrenceNo} error={errors.invoiceRefrenceNo}
                              onChange={handleChange} />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-5 mb-2">
                        <div className="col-md-12 d-flex ">
                          <div className="col-md-6 ps-0">
                            <NewInput isDisabled={onlyView} isAstrix={true} type={"date"} label={"Invoice issue date"}
                              name={"invoiceIssueDate"} value={data.invoiceIssueDate} error={errors.invoiceIssueDate}
                              onChange={handleChange} />
                          </div>
                          <div className="col-md-6 pe-0">
                            <NewInput isDisabled={onlyView} isAstrix={true} type={"date"} label={"Invoice due date"}
                              name={"invoiceDueDate"} value={data.invoiceDueDate} error={errors.invoiceDueDate}
                              onChange={handleChange} />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-5 mb-2">
                        <div className="col-md-12 d-flex ">
                          <div className="col-md-6 ps-0">
                            <NewInput isDisabled={onlyView} isAstrix={true} type={"number"} label={"Credit Days"}
                              name={"creditDays"} value={data.creditDays} error={errors.creditDays}
                              onChange={handleChange} />
                          </div>
                          <div className="col-md-6 pe-0">
                            <NewInput isDisabled={true} type={"text"} label={"Confirmation Rate"}
                              value={data?.selectedQuote?.confirmationFees ? (data?.selectedQuote?.confirmationFees + " %") : "NA"}
                              onChange={handleChange} />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-5 mb-2">
                        <div className="col-md-12 d-flex ">
                          <div className="col-md-6 ps-0">
                            <NewInput isDisabled={true} type={"text"} label={"Discounting Rate"}
                              value={data?.selectedQuote?.discountingRate ? (data?.selectedQuote?.discountingRate + " %") : "NA"}
                              onChange={handleChange} />
                          </div>
                          <div className="col-md-6 pe-0">
                            <NewInput isDisabled={true} type={"text"} label={"Setup fees"}
                              value={data?.selectedQuote?.setupFees + " " + data?.selectedQuote?.setupFeesCurrency}
                              onChange={handleChange} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                ) : null}

                {tab === 2 ? (
                  <div className='px-4 pt-5 pb-3'>

                    <div className="col-md-8 mb-3">
                      <label className="font-size-14">Original LC</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"originalLC"} value={data.originalLC} error={errors.originalLC}
                            onChange={handleFile} isEditable={onlyView ? false : true}
                            onUploadCancel={() => setData({ ...data, "originalLC": null })}
                          />
                          {errors.originalLC ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.originalLC}</b></div> : ''}
                        </div>
                        {data.originalLC && data.originalLC.name && !data.originalLC.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.originalLC, docSignName: "originalLC" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.originalLC && data.originalLC.signatureId ? (
                          <div className="col-md-4 text-end">
                            <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-8 mb-3">
                      <label className="font-size-14">Investigation Report</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"invReport"} value={data.invReport} error={errors.invReport}
                            onChange={handleFile} isEditable={onlyView ? false : true}
                            onUploadCancel={() => setData({ ...data, "invReport": null })}
                          />
                          {errors.invReport ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.invReport}</b></div> : ''}
                        </div>
                        {data.invReport && data.invReport.name && !data.invReport.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.invReport, docSignName: "invReport" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.invReport && data.invReport.signatureId ? (
                          <div className="col-md-4 text-end">
                            <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-8 mb-3">
                      <label className="font-size-14">Certificate of Origin</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"certOfOrigin"} value={data.certOfOrigin} error={errors.certOfOrigin}
                            onChange={handleFile} isEditable={onlyView ? false : true}
                            onUploadCancel={() => setData({ ...data, "certOfOrigin": null })}
                          />
                          {errors.certOfOrigin ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.certOfOrigin}</b></div> : ''}
                        </div>
                        {data.certOfOrigin && data.certOfOrigin.name && !data.certOfOrigin.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.certOfOrigin, docSignName: "certOfOrigin" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.certOfOrigin && data.certOfOrigin.signatureId ? (
                          <div className="col-md-4 text-end">
                            <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-8 mb-3">
                      <label className="font-size-14">Bill of Lading/Airway Bill</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"billOfLading"} value={data.billOfLading} error={errors.billOfLading}
                            onChange={handleFile} isEditable={onlyView ? false : true}
                            onUploadCancel={() => setData({ ...data, "billOfLading": null })}
                          />
                          {errors.billOfLading ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.billOfLading}</b></div> : ''}
                        </div>
                        {data.billOfLading && data.billOfLading.name && !data.billOfLading.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.billOfLading, docSignName: "billOfLading" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.billOfLading && data.billOfLading.signatureId ? (
                          <div className="col-md-4 text-end">
                            <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {contractDocs.length ? contractDocs.map((item, index) => {
                      return (
                        <div className="col-md-8 mb-3">
                          <label className="font-size-14">{data["contractDoc" + index]["doc_name"]}</label>
                          <div className="row align-items-center ">
                            <div className="form-group col-md-8 mb-0">
                              <FileInput name={"contractDoc" + index} value={data["contractDoc" + index]} error={errors["contractDoc" + index]}
                                onChange={handleFile} isEditable={false}
                                onUploadCancel={() => setData({ ...data, ["contractDoc" + index]: null })}
                              />
                              {errors["contractDoc" + index] ? <div class="text-danger mt-2">
                                <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                <b>{errors["contractDoc" + index]}</b></div> : ''}
                            </div>
                            {data["contractDoc" + index] && data["contractDoc" + index].name && !data["contractDoc" + index].signatureId ? (
                              <div className="col-md-4 text-end">
                                <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data["contractDoc" + index], docSignName: "contractDoc" + index }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                              </div>
                            ) : null}
                            {data["contractDoc" + index] && data["contractDoc" + index].signatureId ? (
                              <div className="col-md-4 text-end">
                                <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )
                    }) : null}

                    {data?.reqLetterDocument?.name ? (
                      <div className="col-md-8 mb-3">
                        <label className="font-size-14">Request Letter of Confirmation</label>
                        <div className="row align-items-center ">
                          <div className="form-group col-md-8 mb-0">
                            <FileInput name={"reqLetterDocument"} value={data.reqLetterDocument} error={errors.reqLetterDocument}
                              onChange={handleFile} isEditable={false}
                              onUploadCancel={() => setData({ ...data, "reqLetterDocument": null })}
                            />
                            {errors.reqLetterDocument ? <div class="text-danger mt-2">
                              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                              <b>{errors.reqLetterDocument}</b></div> : ''}
                          </div>
                          {data.reqLetterDocument && data.reqLetterDocument.name && !data.reqLetterDocument.signatureId ? (
                            <div className="col-md-4 text-end">
                              <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.reqLetterDocument, docSignName: "reqLetterDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                            </div>
                          ) : null}
                          {data.reqLetterDocument && data.reqLetterDocument.signatureId ? (
                            <div className="col-md-4 text-end">
                              <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : null}

                    <div className="col-md-8 mb-3">
                      <label className="font-size-14">Draft LC</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"draftLCDocument"} value={data.draftLCDocument} error={errors.draftLCDocument}
                            onChange={handleFile} isEditable={false}
                            onUploadCancel={() => setData({ ...data, "draftLCDocument": null })}
                          />
                          {errors.draftLCDocument ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.draftLCDocument}</b></div> : ''}
                        </div>
                        {data.draftLCDocument && data.draftLCDocument.name && !data.draftLCDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.draftLCDocument, docSignName: "draftLCDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.draftLCDocument && data.draftLCDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {data?.invoiceDocument?.name ? (
                      <div className="col-md-8 mb-3">
                        <label className="font-size-14">Invoice</label>
                        <div className="row align-items-center ">
                          <div className="form-group col-md-8 mb-0">
                            <FileInput name={"invoiceDocument"} value={data.invoiceDocument} error={errors.invoiceDocument}
                              onChange={handleFile} isEditable={false}
                              onUploadCancel={() => setData({ ...data, "invoiceDocument": null })}
                            />
                            {errors.invoiceDocument ? <div class="text-danger mt-2">
                              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                              <b>{errors.invoiceDocument}</b></div> : ''}
                          </div>
                          {data.invoiceDocument && data.invoiceDocument.name && !data.invoiceDocument.signatureId ? (
                            <div className="col-md-4 text-end">
                              <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.invoiceDocument, docSignName: "invoiceDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                            </div>
                          ) : null}
                          {data.invoiceDocument && data.invoiceDocument.signatureId ? (
                            <div className="col-md-4 text-end">
                              <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : null}

                    {data?.poDocument?.name ? (
                      <div className="col-md-8 mb-3">
                        <label className="font-size-14">Purchase Order</label>
                        <div className="row align-items-center ">
                          <div className="form-group col-md-8 mb-0">
                            <FileInput name={"poDocument"} value={data.poDocument} error={errors.poDocument}
                              onChange={handleFile} isEditable={false}
                              onUploadCancel={() => setData({ ...data, "poDocument": null })}
                            />
                            {errors.poDocument ? <div class="text-danger mt-2">
                              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                              <b>{errors.poDocument}</b></div> : ''}
                          </div>
                          {data.poDocument && data.poDocument.name && !data.poDocument.signatureId ? (
                            <div className="col-md-4 text-end">
                              <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.poDocument, docSignName: "poDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                            </div>
                          ) : null}
                          {data.poDocument && data.poDocument.signatureId ? (
                            <div className="col-md-4 text-end">
                              <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : null}


                    {data.buyerId && addMoreDoc.length ? addMoreDoc.map((item, index) => {
                      return (
                        <div className="col-md-8 mb-3">
                          <label className="font-size-14">Add More</label>
                          <div className="row align-items-center ">
                            <div className="form-group col-md-8 mb-0">
                              <FileInput name={"otherDoc" + index} value={data["otherDoc" + index]} error={errors["otherDoc" + index]}
                                onChange={handleFile} isEditable={true}
                                onUploadCancel={() => setData({ ...data, ["otherDoc" + index]: null })}
                              />
                              {errors["otherDoc" + index] ? <div class="text-danger mt-2">
                                <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                <b>{errors["otherDoc" + index]}</b></div> : ''}
                            </div>
                            {data["otherDoc" + index] && data["otherDoc" + index].name && !data["otherDoc" + index].signatureId ? (
                              <div className="col-md-4 text-end">
                                <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data["otherDoc" + index], docSignName: "otherDoc" + index }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                              </div>
                            ) : null}
                            {data["otherDoc" + index] && data["otherDoc" + index].signatureId ? (
                              <div className="col-md-4 text-end">
                                <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )
                    }) : null}

                  </div>
                ) : null}

                {tab === 3 ? (
                  <div className='px-5 py-4 pt-5'>
                    <div className="row">
                      {data.buyerId ? (
                        <div className='w-100'>
                          <label className='font-wt-500 font-size-16'><u>Buyer Details</u>
                          </label>
                          <div className='row'>
                            {reviewForm.map((item) => {
                              return (
                                // <div className='col-5 p-0'>
                                //   <label className='col-6 lh-18 text-color-label font-size-14'>{item.name}</label>
                                //   <label className='lh-18 font-size-14'>:</label>
                                //   <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                //     {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}
                                //   </label>
                                // </div>
                                <div className="col-md-6 pe-5">
                                  <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      ) : null}

                      <div className={`w-100 ${data.buyerId ? 'mt-5' : ''}`}>
                        <label className='font-wt-500 font-size-16'><u>LC Details</u></label>
                        <div className='row'>
                          {reviewForm3.map((item) => {
                            return (
                              <div className="col-md-6 pe-5">
                                <p className="d-flex d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                              </div>
                            )
                          })}
                        </div>
                      </div>

                      <div className={`w-100 ${data.buyerId ? 'mt-5' : ''}`}>
                        <label className='font-wt-500 font-size-16'><u>Post-Shipment Details</u></label>
                        <div className='row'>
                          <div className="col-md-6 pe-5">
                            <p className=" d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{"Applicant Name"}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {userName}</span> </p>
                          </div>
                          {reviewFormPostShipment.map((item) => {
                            return (
                              <div className="col-md-6 pe-5">
                                <p className=" d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                              </div>
                            )
                          })}
                        </div>
                      </div>

                      <div className='w-100 mt-5'>
                        <label className='font-wt-500 font-size-16'><u>Documents</u>
                        </label>
                        <div className='row mt-2'>

                          <div className="col-md-4">
                            <label className="font-size-14">Original LC</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"originalLC"} value={data.originalLC}
                                  isEditable={false}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <label className="font-size-14">Investigation Report</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"invReport"} value={data.invReport}
                                  isEditable={false}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <label className="font-size-14">Certificate of Origin</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"certOfOrigin"} value={data.certOfOrigin}
                                  isEditable={false}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <label className="font-size-14">Bill of Lading/Airway Bill</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"billOfLading"} value={data.billOfLading}
                                  isEditable={false}
                                />
                              </div>
                            </div>
                          </div>

                          {contractDocs.length ? contractDocs.map((item, index) => {
                            if (data["contractDoc" + index]) {
                              return (
                                <div className="col-md-4">
                                  <label className="font-size-14">{data["contractDoc" + index]["doc_name"]}</label>
                                  <div className="row form-group">
                                    <div className="col-md-12">
                                      <FileInput name={"contractDoc" + index} value={data["contractDoc" + index]}
                                        isEditable={false}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          }) : null}

                          {data?.reqLetterDocument?.name ? (
                            <div className="col-md-4">
                              <label className="font-size-14">Request letter of confirmation</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"reqLetterDocument"} value={data.reqLetterDocument}
                                    isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : null}

                          <div className="col-md-4">
                            <label className="font-size-14">Draft LC</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"draftLCDocument"} value={data.draftLCDocument}
                                  isEditable={false}
                                />
                              </div>
                            </div>
                          </div>
                          {data?.invoiceDocument?.name ? (
                            <div className="col-md-4">
                              <label className="font-size-14">Invoice</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"invoiceDocument"} value={data.invoiceDocument}
                                    isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : null}

                          {data?.poDocument?.name ? (
                            <div className="col-md-4">
                              <label className="font-size-14">Purchase Order</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"poDocument"} value={data.poDocument}
                                    isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : null}


                          {data.buyerId && addMoreDoc.length ? addMoreDoc.map((item, index) => {
                            if (data["otherDoc" + index]) {
                              return (
                                <div className="col-md-4">
                                  <label className="font-size-14">Add More</label>
                                  <div className="row form-group">
                                    <div className="col-md-12">
                                      <FileInput name={"otherDoc" + index} value={data["otherDoc" + index]}
                                        isEditable={false}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          }) : null}

                        </div>
                      </div>

                      <div className='w-100 mt-5'>
                        <label className='font-wt-500 font-size-16'><u>Financier selected</u></label>
                        <div className="d-flex">
                          <div className="my-3 col-2 px-0">
                            <label className="font11">{LCPurposeObject[data.lcPurpose]}</label>
                            <p
                              className="browse-div font-size-13 cursor text-center m-0 col-10 border-finance text-dark" >
                              {data?.selectedQuote?.lender_name ? data.selectedQuote["lender_name"] : "NA"}
                            </p>
                          </div>

                          {data.lcPurpose === "lc_confirmation" && data.sameFinancierForDiscounting ? (
                            <div className="my-3 col-2">
                              <label className="font11">LC Discounting</label>
                              <p
                                className="browse-div font-size-13 cursor text-center m-0 col-10 border-finance text-dark" >
                                {data.selectedFinancierName}
                              </p>
                            </div>
                          ) : null}
                        </div>

                      </div>

                      <div className='mt-3'>
                        <img
                          onClick={() => setData({ ...data, tcAccepted: !data.tcAccepted })}
                          className='cursor mr-3' src={`assets/images/${data.tcAccepted ? 'checked-green' : 'empty-check'}.png`} />
                        <label>I hereby declare that the above given information by me is true to my knowledge.</label>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="px-5 pb-5">
                  <button type="button"
                    disabled={(!data.tcAccepted && tab === 3)}
                    onClick={() => handleValidation(undefined)}
                    className={`new-btn w-20 py-2 px-2 text-white ${(!data.tcAccepted && tab === 3) ? " disablesigncontract" : ""}`}>
                    {tab === 3 ? `${onlyView ? "Update" : "Submit"}` : "Continue"}
                  </button>
                </div>

              </div>
            </div>
          </main>
        </div>
      </div >

      {limitinvoice && <FinanceInvoiceModal limitinvoice={limitinvoice} setLimitinvoice={setLimitinvoice} closeSuccess={closeSuccess} >
        <img src={"/assets/images/upload_done.png"} alt="" className="mb-3" />
        <p className="mx-4">Your application for “Non resource LC Discounting” for “Rosti Suzhou Integrated” has been submitted successfully.</p>
      </FinanceInvoiceModal>}

      {tooltip && <TooltipModal tooltip={tooltip} setTooltip={setTooltip}
        data={{ showRemark: data.showRemark, showLenderName: data.showLenderName }} />}

      {signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
        setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />}

      {
        amend && <FinanceInvoiceModal limitinvoice={amend} setLimitinvoice={setAmend} closeSuccess={closeSuccess} >
          <img src={"/assets/images/Questionmark.png"} alt="" className="mb-3" />
          <p className="font-size-13 font-wt-600">Do you want to amend your application?</p>
          <button
            onClick={() => { window.location = `/LcDiscounting?buyer=${data.buyerId}&purpose=${data.lcPurpose}&id=${data.id}&view=true` }}
            className="bg-white px-3 py-2 btnsmall font-size-13">Yes</button>
          <button onClick={closeSuccess} className="bg-white px-3 py-2 btnsmall font-size-13 ms-3">No</button>
        </FinanceInvoiceModal>
      }
    </>
  );
}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(LcApplyFinanceBtn)