import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import HeaderV2 from "../../partial/headerV2";
import SideBarV2 from "../../partial/sideBarV2";
import Filter from '../../InvoiceDiscounting/components/Filter';
import moment from 'moment/moment';
import call from '../../../service';
import toastDisplay from '../../../utils/toastNotification';
import { LCPurposeObject, LCTypesObject, getDocDetails, most_used_currencies } from '../../../utils/myFunctions';
import { convertImageToPdf } from '../../../utils/myFunctions';
import { NewTable } from '../../../utils/newTable';
import Pagination from '../../InvoiceDiscounting/contract/components/pagination';
import { ToastContainer } from 'react-toastify';
import NewTablev2 from '../../../utils/newTablev2';
import { columns, tablenewData, actionsdata } from "./TableJson"
import Action from './actions';
import TutorialPopup, { TutorialVideoPopup } from '../../tutorialPopup';
import ChatBoxPopUp2 from '../../chatRoom/components/ChatBoxPopUp2';

const finHighlights = [
  { name: 'Selected', color: '#5CB8D3' },
  { name: 'Quote received', color: '#2ECC71' },
  { name: 'Rejected', color: '#FE4141' }
]

const tutorialPopupCSS = {
  "0": {
    popup: {
      "top": "18rem",
      "right": "6rem"
    },
    arrow: {
      "top": "18rem",
      "zIndex": 10,
      transform: "rotate(179deg)",
      left: "14.5%"
    },
    children: <label className='font-size-14 font-wt-600 text-color-value'><span>To apply for LC limit or finance click on the respective tab at navbar under LC</span></label>
  },
  "1": {
    popup: {
      top: '-1rem',
      right: '-19rem'
    },
    arrow: {
      "top": "3rem",
      "zIndex": 10,
      transform: "rotate(255deg)",
      left: '45.5%'
    },
    children: <label className='font-size-14 font-wt-600 text-color-value'><span>Application of LC finance limit will be show in "Quotes" under LC tab</span></label>
  }
}

const Quotes = ({ userTokenDetails, navToggleState }) => {

  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')
  const [dbData, setDbData] = useState([])
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [tableData, setTableData] = useState([])
  const [filterData, setFilterData] = useState({})

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [draftCount, setDraftCount] = useState(0)
  const [onlyShowDrafts, toggleOnlyShowDrafts] = useState(false)

  const [tab, setTab] = useState(0)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)

  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10, search: serarchParam ? serarchParam : '' })
  const [lcTutorial, toggleLCTutorial] = useState(localStorage.getItem("lcTutorialStep") == 0)
  const [tutorialStage, setTutorialStage] = useState(0)
  const [showTutorialVideo, toggleTutorialVideo] = useState({ show: false, link: null })

  const [action, setAction] = useState({ show: false, id: null });
  const [applicationCounts, setApplicationCounts] = useState({})

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  // const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  // const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  // const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  useEffect(() => {
    loadApplicationCounts()
  }, [])

  async function loadApplicationCounts() {
    setshowLoader(true)
    let apiResp = await call("POST", 'getLCQuoteApplicationCounts', { userId })
    setApplicationCounts({ ...apiResp })
    setshowLoader(false)
  }

  useEffect(() => {
    //Get Buyer list start
    let objectAPI = {
      userId,
      onlyAppliedForQuote: true,
      currentPage: page,
      ...filter
    }
    setshowLoader(true)
    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push((element.accordianId === "status" || element.accordianId === "financiersFilter" || element.accordianId === "timeLeft") ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
        else if (element.type === "minMaxDate") {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }
    call('POST', 'getLCQuoteApplications', objectAPI).then(async (result) => {
      console.log('running getLCQuoteApplications api-->', result);
      setshowLoader(false)
      setDbData(result.data);
      let table1 = await formatDataForTable(result.data)
      if (onlyShowDrafts) {
        table1 = []
      }
      let table2 = await call('POST', 'getAllInvoiceLimitDrafts', { userId, forLC: true })
      table2 = formatDataForTable2(table2)
      if (table2.length) {
        setDraftCount(table2.length)
        setTableData(table1.concat(table2))
      }
      else {
        setTableData(table1)
      }
      setCount(result.totalCount);
    }).catch((e) => {
      // console.log('error in getLCQuoteApplications', e);
      setshowLoader(false)
    });
  }, [refresh, page, filterData, onlyShowDrafts])

  useEffect(() => {
    call('POST', 'getApplyForLCQuoteFilter', { userId }).then(res => {
      console.log("getApplyForLCQuoteFilter then", res);
      setFilterData(res)
    }).catch(err => { })
  }, [])


  console.log("tableDatatableData", tableData);

  function formatDataForTable2(data) {
    let tableData = []
    let row = []
    for (let index = 0; index < data.length; index++) {
      const item = data[index];
      row[0] = moment(item.createdAt).format('DD/MM/YYYY')
      row[1] = item.details?.data?.["buyerName0"] || "-"
      row[2] = item.details?.data?.["lcNo0"] || "-"
      row[3] = item.details?.data?.["lcPurpose0"] ? LCPurposeObject[item.details?.data?.["lcPurpose0"]] : "-"
      row[4] = item.details?.data?.lcIssuingBankName0 || "-"
      row[5] = item.details?.data?.countryOfOriginName0 || "-"
      row[5] = "-"
      row[6] = "-"
      row[7] = "-"
      row[8] = <div className='row ' >
        <a className='d-flex row w-auto mr-2'>
          <button type="button"
            onClick={() => {
              window.location = `/LcDiscountingV2?formContinue=${item.id}`;
            }}
            className={` border-0 mb-2 text-white enableQuotebtn `}>
            {"Continue"}
          </button>
          <p className="font-size-12 text-color-value">
            <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-500">This application is saved as draft</span>
          </p>
        </a>
      </div >

      tableData.push(row)
      row = []
    }
    return tableData
  }

  async function formatDataForTable(data) {
    let tableData = []
    let row = []
    for (let index = 0; index < data.length; index++) {
      const item = data[index];
      let createdAt = moment(item.createdAt)

      let allDeniedCount = 0
      let apporvedCount = 0

      if (item.financierQuotes) {
        for (let index = 0; index < JSON.parse(item.financierQuotes).length; index++) {
          const j = JSON.parse(item.financierQuotes)[index];
          if (j.status != "approved") {
            allDeniedCount += 1
          }
          else {
            apporvedCount += 1
          }
        }
      }

      let waitTillDate = createdAt.clone().add(4, "days")

      let timeLeftInDays = waitTillDate.diff(moment(), "days") / 1

      console.log("timeLeftInDaystime", timeLeftInDays, createdAt, waitTillDate);


      row[0] = createdAt.format('DD/MM/YYYY')
      row[1] = item.buyerName || "-"
      row[2] = item.lcNo
      row[3] = LCPurposeObject[item.lcPurpose] || "-"
      row[4] = item.lcIssuingBankName || "-"
      row[5] = item.countryOfOriginName || "-"
      // if (item.reviewPending) {
      //   row[99] = <p className="font-size-12 text-color-value ml-3">
      //     <img src={"assets/images/warning.png"} alt="info" className="" /> <span className="mr-2">
      //       <b>Your application will be sent to financiers once it is review by our team</b>  </span>
      //   </p>
      // }
      // if (item.ammendmentForwardedToBuyer && item.ammendmentForwardedToBuyer.length) {
      //   row[99] = <p className="font-size-12 text-color-value ml-3">
      //     <img src={"assets/images/warning.png"} alt="info" className="" /> <span className="mr-2">
      //       <b>Request sent for LC amendment!</b>  </span>
      //   </p>
      // }
      // else if (item.selectedFinancier && !item.quoteLocked) {
      //   row[99] = <p className="font-size-12 text-color-value ml-3">
      //     <img src={"assets/images/warning.png"} alt="info" className="" /> <span className="mr-2">
      //       <b>Quote selected, waiting for approval from financier</b></span>
      //   </p>
      // }
      // else if (item.amendmentRaisedFromFinanciers && item.amendmentRaisedFromFinanciers.length && item.quoteLocked) {
      //   row[99] = <p className="font-size-12 text-color-value ml-3">
      //     <img src={"assets/images/warning.png"} alt="info" className="" /> <span className=" mr-2"><b>Quote approved </b></span>
      //     <label
      //       onClick={() => {
      //         window.location = `/ammendLC?id=${item.id}`
      //         localStorage.setItem("buyerEmail", item.buyerEmail)
      //         localStorage.setItem("financierQuotes", item.financierQuotes)
      //         localStorage.setItem("selectedFinancier", item.selectedFinancier)
      //       }}
      //       className="text-color1 text-decoration-underline cursor mr-2">Send LC for amendment</label>
      //   </p>
      // }
      // else if (item.amendmentRaisedFromFinanciers && item.amendmentRaisedFromFinanciers.length && !item.selectedFinancier) {
      //   row[99] = <p className="font-size-12 text-color-value ml-3">
      //     <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" mr-2"><b>Additional details requested by financier</b> </span>
      //     <label
      //       onClick={() => {
      //         window.location = `/ammendLC?id=${item.id}`
      //         localStorage.setItem("buyerEmail", item.buyerEmail)
      //         localStorage.setItem("financierQuotes", item.financierQuotes)
      //         localStorage.setItem("selectedFinancier", item.selectedFinancier)
      //       }}
      //       className="text-color1 text-decoration-underline cursor">View request</label>
      //   </p>
      // }
      let selectedLenderName = item.selectedLenderName ? item.selectedLenderName.split(",") : []
      let selectedLenderId = item.selectedLenderId ? item.selectedLenderId.split(",") : []
      let buyersCredit = item.financierQuotes ? JSON.parse(item.financierQuotes) : []
      let chatRoomIds = item.chatRoomIds?.split(",") || []
      let chatRoomUsers = item.chatRoomUsers?.split(",") || []
      let chatRoomUnreadMsgCount = item.chatRoomUnreadMsgCount?.split(",") || []

      row[6] = <div
        className=''
      >
        {selectedLenderName.length ? selectedLenderName.map((key, j) => {
          let isApprovedByFinancier = buyersCredit?.filter(i => {
            if ((i.lender_id / 1 == selectedLenderId[j] / 1) && i.status === "approved") {
              return i
            }
          })?.[0]
          let isRejectedByFinancier = buyersCredit?.filter(i => {
            if ((i.lender_id / 1 == selectedLenderId[j] / 1) && i.status != "approved") {
              return i
            }
          })?.[0]
          let openChatRoomIndx = null
          chatRoomUsers.forEach((u, i) => {
            if (u?.split("::")[1] / 1 == selectedLenderId[j]) {
              openChatRoomIndx = i
            }
          })

          return (
            <div className='position-relative' >
              <img className='cursor'
                onClick={async () => {
                  let reqObj = {
                    senderId: userId,
                    receiverId: selectedLenderId[j],
                    textMessage: 'Hii',
                    chat_room_name: "CHAT" + new Date().getTime(),
                    includeAdmins: true,
                    lcApplicationId: item.id,
                    receiverParties: selectedLenderId[j],
                    dontSendInitialMsg: true
                  }
                  setshowLoader(true)
                  let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
                  setshowLoader(false)
                  getChatDetails({
                    chat_room_id: apiResp.id,
                    loggedInUser: userId
                  })
                  setSelectedChat({
                    chatRoomId: apiResp.id,
                    receiverName: `${key} - ${chatRoomIds[openChatRoomIndx] || apiResp.id}`,
                    lcApplicationId: item.id,
                    parties: chatRoomUsers[openChatRoomIndx] || apiResp.parties,
                    userId: userId,
                    isChatOpen: true,
                    receiverId: selectedLenderId[j]
                  })
                }}
                src={chatRoomIds[openChatRoomIndx] ? `assets/images/comment_filled.png` : `assets/images/chat.png`} />
              <label className={`ml-3 font-size-14 text-color-label font-wt-600 ${selectedLenderId[j] / 1 == item.selectedFinancier / 1 ? " text5CB8D3 " : isApprovedByFinancier ? " text2ECC71 " : " "}  ${isRejectedByFinancier ? ' text-danger ' : '   '} `} >{`${key} `}
                <span className='text-color1 mx-2' ><u>{chatRoomUnreadMsgCount[openChatRoomIndx] / 1 ? (chatRoomUnreadMsgCount[openChatRoomIndx] < 10 ? `0${chatRoomUnreadMsgCount[openChatRoomIndx]}` : chatRoomUnreadMsgCount[openChatRoomIndx]) : null}</u></span></label>
            </div>
          )
        }) : "NA"}
      </div>

      row[7] = <div className=''>
        {apporvedCount ?
          <button type="button"
            class={`approved text-white border-0 `}>
            {"Approved"}
          </button> :
          (allDeniedCount / 1 == selectedLenderName?.length / 1) ?
            <button type="button"
              class={`rejected text-white border-0 `}>
              {"Rejected"}
            </button>
            :
            <button type="button"
              class={`inprogress text-white border-0 `}>
              {"Inprogress"}
            </button>}
      </div>

      row[8] = <div className=''
        style={{ marginRight: '1rem' }}
      >
        {(!(allDeniedCount / 1 == selectedLenderName?.length / 1) && item.financierQuotes && !item.selectedFinancier) ?
          < a className='mt-2' >
            <button
              onClick={() => { window.location = `/LcSeequotes?id=${item.id}` }}
              type="button"
              class={` border-0 mb-2 enablesigncontract text-white w-100`} >
              {"See quotes"}
            </button>
            <p className="font-size-12 text-color-value ml-3">
              <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-500">Quote received</span>
            </p>
          </a> : null
        }
        {(!item.financierQuotes || item.selectedFinancier) && !(item.ammendmentForwardedToBuyer && item.ammendmentForwardedToBuyer.length) && !item.isAmendedLCReceived && !item.quoteLocked ?
          <a className='mt-2'>
            <button type="button"
              class={` border-0 mb-2 disablesigncontract text-dark w-100`} >
              {item.selectedFinancier ? "Quote Selected" : " See quotes"}
            </button>
            {!item.selectedFinancier ? <p className="font-size-12 text-color-value ml-3">
              <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-500">Waiting for financiers to send quote</span>
            </p> : null}
          </a>
          : null}
        {
          (allDeniedCount / 1 == selectedLenderName?.length / 1) ?
            <a className='d-flex row w-auto mr-2'>
              <button type="button"
                onClick={() => { window.location = `/ApplyforSblc?id=${item.id}` }}
                className={` border-0 mb-2 enablesigncontract text-white`} >
                {"Apply For SBLC"}
              </button>
              <p className="font-size-12 text-color-value ml-3">
                <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-500">Rejected by all financiers, to apply for SBLC click above</span>
              </p>
            </a> : null
        }
        {
          item.ammendmentForwardedToBuyer && item.ammendmentForwardedToBuyer.length && !item.isAmendedLCReceived ?
            <a className='d-flex row w-auto mr-2'>
              <button type="button"
                onClick={() => { window.location = `/LcDiscounting?buyer=${item.buyerId}&purpose=${item.lcPurpose}&id=${item.id}&view=true&amendmentId=${item.ammendmentForwardedToBuyer[0]["id"]}` }}
                class={` border-0 mb-2 enablesigncontract text-white`}>
                {"Upload amended LC"}
              </button>
            </a> :
            (item.isAmendedLCReceived || item.quoteLocked) && item.selectedFinancier ? (
              <a className='d-flex row w-auto mr-2'>
                <button type="button"
                  disabled={item.lcPurpose.includes("discounting") || item.reqLetterOfConfirmation}
                  onClick={() => {
                    window.location = `/LcSeequotes?id=${item.id}&reqLetterRequired=true`
                  }}
                  class={` border-0 mb-2 ${item.lcPurpose.includes("discounting") ? " disablesigncontract text-dark w-100 " : " enablesigncontract text-white w-100"}`}>
                  {item.reqLetterOfConfirmation ? "Request Letter Sent" : (
                    item.lcPurpose.includes("discounting") ? "Approved" :
                      "Send Request Letter")}
                </button>
                {item.reqLetterOfConfirmation ?
                  <p className="font-size-12 text-color-value ml-3">
                    <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-500">Request letter sent, waiting for contract</span>
                  </p>
                  : item.lcPurpose.includes("discounting") ?
                    (
                      <p className="font-size-12 text-color-value ml-3">
                        <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-500">Deal locked by financier, waiting for contract</span>
                      </p>
                    )
                    : (
                      <p className="font-size-12 text-color-value ml-3">
                        <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-500">Deal locked by financier</span>
                      </p>
                    )}
              </a>
            ) : null
        }
      </div>
      // if (item.selectedFinancier) {
      //   row[8] = <img
      //     onClick={() => { window.location = `/LcSeequotes?id=${item.id}&view=true` }}
      //     className='cursor ml-3'
      //     src={"assets/images/eye.png"} alt='' />
      // }
      tableData.push(row)
      row = []
    }
    return tableData
  }

  const onClickApplication = () => {

  }


  const handleDropDown = (index) => {
    if (!action.show)
      setAction({ show: true, id: index });
    else
      setAction({ show: false, id: index })
  }

  async function handleValidation(tabIndex) {

    if (tabIndex != undefined) {
      if (tabIndex < tab) {
        return setTab(tabIndex)
      }
      else if (tabIndex == tab) {
        return null
      }
      else {
        return toastDisplay("Click on continue button to go to next form", "info")
      }
    }

    let validateFields = []
    let err = {}
    if (tab === 0) {
      validateFields = ["buyerName", "buyerCountry"]
    }

    if (tab === 2) {
      validateFields = ["buyerName", "buyerCountry", "buyerAddress", "buyerPostalCode",
        "buyerPrevSale", "buyerExpectedSale", "buyerIncoTerms", "buyerTermsPayment", "buyerProductDetails"]
    }

    if (tab === 3) {
      validateFields = ["invoiceDocument", "poDocument"]
    }

    // if (tab === 4) {
    //   if (!data.selectedFinanciers.length) {
    //     return toastDisplay("Select atleast 1 financier", "info")
    //   }
    // }

    validateFields.forEach((item) => {
      if (!data[item]) {
        err[item] = "Mandatory Field"
      }
    })

    if (!Object.keys(err).length) {
      if (tab != 4) {
        if (tab === 0) {
          // return getDunsList()
        }
        setTab(tab + 1)
      }
      else {
        // submitBuyerDetails()
      }
    }
    setErrors(err)
  }


  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (!file_type.includes("pdf")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  const handleSelectedFinanciers = (id) => {
    let temp = data.selectedFinanciers
    if (temp.includes(id)) {
      temp = temp.filter(e => e !== id)
    }
    else {
      temp.push(id)
    }
    setData({ ...data, selectedFinanciers: temp })
  }


  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('lcApplicationId', selectedChat.lcApplicationId)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))
    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }


  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      {selectedChat.isChatOpen &&
        <div className="chatboxDivFixed">
          <ChatBoxPopUp2
            chatList={chatList}
            user_avatar={selectedChat.logo}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            parties={selectedChat.parties}
            userTokenDetails={userTokenDetails}
            onChatSend={(file, parties) => sendChatMsg(file, parties)}
            message={message}
            setTextMsg={setTextMsg}
            reloadChatList={() => getChatDetails({
              chat_room_id: selectedChat.chatRoomId,
              loggedInUser: userId
            })}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false,
                logo: ''
              })
              setRefresh(refresh + 1)
            }}
          />
        </div>
      }
      <TutorialPopup show={lcTutorial} featureName={"LC"} positioning={{
        ...tutorialPopupCSS[tutorialStage]["popup"]
      }} showSkip={true} userId={userId} showNext={true}
        onNext={() => {
          if (tutorialStage == 1) {
            localStorage.setItem('lcTutorialStep', 1)
            window.location.reload()
          }
          else {
            setTutorialStage(tutorialStage + 1)
          }
        }}
        onBack={() => {
          if (tutorialStage > 0) {
            setTutorialStage(tutorialStage - 1)
          }
        }}
        showBack={tutorialStage != 0} videoLinkLabel={"Watch detailed video about LC"}
        onVideoLinkClick={() => toggleTutorialVideo({ show: true, link: "https://www.youtube.com/embed/tgbNymZ7vqY" })}
        children={tutorialPopupCSS[tutorialStage]["children"]}
        arrowPositioning={{
          ...tutorialPopupCSS[tutorialStage]["arrow"]
        }} />

      <TutorialVideoPopup
        show={showTutorialVideo.show}
        videoLink={showTutorialVideo.link}
        onClose={() => toggleTutorialVideo({ show: false, link: null })}
      />

      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="LcQuote" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"LC- Quotes"}
              userTokenDetails={userTokenDetails} />

            <div className='d-flex row justify-content-between px-2' >
              <div className='card p-4 border1Blue' style={{ width: '43.22%' }} >
                <label className='font-size-15 font-wt-600 color0C0C0C' >Application in process - <label className='text-color1 font-size-15 font-wt-600' >{count || 0}</label></label>
                <div className='d-flex row' >
                  <div className='w-auto' >
                    <p className='font-size-22 font-wt-600 text-color1 p-0 m-0' >{applicationCounts.waitingForQuote || 0}</p>
                    <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0'>Waiting for Quote</p>
                  </div>
                  <div className='w-auto' >
                    <p className='font-size-22 font-wt-600 text-color1 p-0 m-0' >{applicationCounts.quotes || 0}</p>
                    <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0'>Quotes</p>
                  </div>
                  <div className='w-auto' >
                    <p className='font-size-22 font-wt-600 text-color1 p-0 m-0' >{applicationCounts.reqLetterSent || 0}</p>
                    <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0'>Request Letter Sent</p>
                  </div>
                </div>
              </div>
              <div className='card border1Blue pl-4 d-flex justify-content-center cursor' style={{ width: '12.44%' }} >
                <div>
                  <p className='font-size-22 font-wt-600 text2ECC71 p-0 m-0' >{applicationCounts.approved || 0}</p>
                  <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0 mt-3'>Approved</p>
                </div>
              </div>
              <div
                className='card border1Blue pl-4 d-flex justify-content-center cursor' style={{ width: '12.44%' }} >
                <div>
                  <p className='font-size-22 font-wt-600 text-color-E74C3C p-0 m-0' >{applicationCounts.rejected || 0}</p>
                  <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0 mt-3'>Rejected</p>
                </div>
              </div>
              <div
                className='card border1Blue pl-4 d-flex justify-content-center cursor' style={{ width: '12.44%' }} >
                <div>
                  <p className='font-size-22 font-wt-600 text-secondary p-0 m-0' >{applicationCounts.expired || 0}</p>
                  <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0 mt-3'>Expired</p>
                </div>
              </div>
              <div
                onClick={() => toggleOnlyShowDrafts(!onlyShowDrafts)}
                className='card border1Blue pl-4 d-flex justify-content-center cursor' style={{ width: '12.44%' }} >
                <div>
                  <p className='font-size-22 font-wt-600 text-color1 p-0 m-0' >{draftCount || 0}</p>
                  <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0 mt-3'>Draft</p>
                </div>
              </div>
            </div>

            {/* <div>
              <a className="cursor"><img src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
            </div> */}
            <div className='mt-1 position-relative' style={lcTutorial && tutorialStage == 0 ? { "zIndex": '-1', position: 'relative' } : {}} >

              <div className='filter-div position-relative'>
                <Filter
                  filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                  showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} />
                <div style={{ left: '21rem' }} className='d-flex position-absolute'>
                  {finHighlights.map((i, j) => {
                    return (<div className='d-flex align-items-center mx-2' >
                      <div style={{ background: i.color, height: '10px', width: '10px', borderRadius: 1000 }} />
                      <label className='font-size-14 font-wt-500 mt-2 mx-2' >{i.name}</label>
                    </div>)
                  })}
                </div>
              </div>

              {/* <Filter filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} />
              <div style={{ left: '21rem', top: '1rem' }} className='d-flex position-absolute'>
                {finHighlights.map((i, j) => {
                  return (<div className='d-flex align-items-center mx-2' >
                    <div style={{ background: i.color, height: '10px', width: '10px', borderRadius: 1000 }} />
                    <label className='font-size-14 font-wt-500 mt-2 mx-2' >{i.name}</label>
                  </div>)
                })}
              </div> */}

              <div
                style={tutorialStage == 1 ? {
                  "background": "white",
                  "position": "relative",
                  "zIndex": 10001,
                  "padding": "1rem 0rem 1rem 0rem",
                  "borderRadius": "1rem",
                } : {}}>
                <NewTable
                  tableContainsNotification={true}
                  disableAction={true}
                  columns={[{
                    name: "Date", filter: true, filterDataKey: "Date", sort: [
                      { name: "Latest First", selected: filter.sortDateBy === "DESC", onActionClick: () => { setFilter({ ...filter, sortDateBy: 'DESC', sortBuyerName: false }); setRefresh(refresh + 1) } },
                      { name: "Oldest First", selected: filter.sortDateBy === "ASC", onActionClick: () => { setFilter({ ...filter, sortDateBy: "ASC", sortBuyerName: false }); setRefresh(refresh + 1) } }]
                  }, {
                    name: "Buyer Name", filter: true, filterDataKey: "Buyer Name", sort: [
                      { name: "Sort A-Z", selected: filter.sortBuyerName === "ASC", onActionClick: () => { setFilter({ ...filter, sortBuyerName: 'ASC', sortDateBy: false }); setRefresh(refresh + 1) } },
                      { name: "Sort Z-A", selected: filter.sortBuyerName === "DESC", onActionClick: () => { setFilter({ ...filter, sortBuyerName: "DESC", sortDateBy: false }); setRefresh(refresh + 1) } }]
                  },
                  {
                    name: "LC no.",
                  },
                  {
                    name: "Limit Type"
                  },
                  {
                    name: "Issuing Bank"
                  },
                  {
                    name: "Origin Country"
                  },
                  {
                    name: "Selected Financiers"
                  },
                  {
                    name: "Status"
                  },
                  { name: "Action", filter: false },
                    // { name: "", filter: false },
                    // { name: "", filter: false },
                    // { name: "", filter: false },
                  ]}
                  data={tableData}

                />
              </div>

              {/* <NewTablev2 columns={columns}>
                {tablenewData.map((key, index) => {
                  return (
                    <tr>
                      <td>{key.date}</td>
                      <td title={key.buyerName} className="cursor"> {key.buyerName && key.buyerName.length > 100 ? key.buyerName.slice(0, 100) + '...' : key.buyerName} </td>
                      <td>{key.LCno}</td>
                      <td>{key.Limittype}</td>
                      <td>{key.LCtenor}</td>
                      <td>
                        <div>
                          {key.Status === "Approved" && <button type="button"
                            class={`approved text-white border-0 `} >
                            {"Approved"}
                          </button>}
                          {key.Status === "Inprogress" && <button type="button"
                            class={`inprogress text-white border-0 `} >
                            {"In progress"}
                          </button>}
                        </div>
                      </td>
                      <td>
                        {key.Action === "See quotes" && < a className="d-flex gap-4 align-items-center">
                          <button type="button"
                            class={`border-0   ${key.Seequotes ? "disablesigncontract text-secondary" : "enablesigncontract text-white"}`}>
                            See quotes
                          </button>
                          <a onClick={() => handleDropDown(index)} className="dropstart cursor"> <img src={"/assets/images/more-table.png"} alt="" /> {action.show && action.id === index ? <Action action={action.show} setAction={handleDropDown} id={index} actionsdata={[
                            {
                              name: "Application",
                              onClick: onClickApplication
                            }, {
                              name: "Amended application",
                              onClick: onClickApplication
                            },
                          ]} /> : null}</a>
                        </a>}
                        {key.Action === "Upload amended LC" && < a className="d-flex gap-4 align-items-center">
                          <button type="button"
                            class={`border-0 enablesigncontract text-white`}>
                            Upload amended LC
                          </button>
                          <a onClick={() => handleDropDown(index)} className="dropstart cursor"> <img src={"/assets/images/more-table.png"} alt="" /> {action.show && action.id === index ? <Action action={action.show} setAction={handleDropDown} id={index} actionsdata={[
                            {
                              name: "Application",
                              onClick: onClickApplication
                            }, {
                              name: "Amended application",
                              onClick: onClickApplication
                            },
                          ]} /> : null}</a>
                        </a>}
                        {key.Action === "Send request letter" && < a className="d-flex gap-4 align-items-center">
                          <button type="button"
                            class={`border-0 enablesigncontract text-white`}>
                            Send request letter
                          </button>
                          <a onClick={() => handleDropDown(index)} className="dropstart cursor"> <img src={"/assets/images/more-table.png"} alt="" /> {action.show && action.id === index ? <Action action={action.show} setAction={handleDropDown} id={index} actionsdata={[
                            {
                              name: "Application",
                              onClick: onClickApplication
                            }, {
                              name: "Amended application",
                              onClick: onClickApplication
                            },
                          ]} /> : null}</a>
                        </a>}
                        {key.Action === "Apply for SBLC" && < a className="d-flex gap-4 align-items-center">
                          <button type="button"
                            class={`mb-2 enablesigncontract bg-B9EFFF border2px-95E7FF  text-color1`}>
                            Apply for SBLC
                          </button>
                          <a onClick={() => handleDropDown(index)} className="dropstart cursor"> <img src={"/assets/images/more-table.png"} alt="" /> {action.show && action.id === index ? <Action action={action.show} setAction={handleDropDown} id={index} actionsdata={[
                            {
                              name: "Application",
                              onClick: onClickApplication
                            }, {
                              name: "Amended application",
                              onClick: onClickApplication
                            },
                          ]} /> : null}</a>
                        </a>}
                        {key.Action === "Applied for SBLC" && < a className="d-flex gap-4 align-items-center">
                          <button type="button"
                            class={`mb-2 enablesigncontract bg-transparent border2px-5CB8D3 text-color1`}>
                            Applied for SBLC
                          </button>
                          <a onClick={() => handleDropDown(index)} className="dropstart cursor"> <img src={"/assets/images/more-table.png"} alt="" /> {action.show && action.id === index ? <Action action={action.show} setAction={handleDropDown} id={index} actionsdata={[
                            {
                              name: "Application",
                              onClick: onClickApplication
                            }, {
                              name: "Amended application",
                              onClick: onClickApplication
                            },
                          ]} /> : null}</a>
                        </a>}
                        {key.Action === "Quote selected" && < a className="d-flex gap-4 align-items-center">
                          <button type="button"
                            class={`mb-2 enablesigncontract bg-transparent border2px-5CB8D3 text-color1`}>
                            Quote selected
                          </button>
                          <a onClick={() => handleDropDown(index)} className="dropstart cursor"> <img src={"/assets/images/more-table.png"} alt="" /> {action.show && action.id === index ? <Action action={action.show} setAction={handleDropDown} id={index} actionsdata={[
                            {
                              name: "Application",
                              onClick: onClickApplication
                            }, {
                              name: "Amended application",
                              onClick: onClickApplication
                            },
                          ]} /> : null}</a>
                        </a>}
                      </td>
                    </tr>
                  );
                })
                }
              </NewTablev2> */}

            </div>
            <Pagination page={page} totalCount={count} onPageChange={(p) => setPage(p)} perPage={filter.resultPerPage || 10} />

          </main>
        </div>
      </div >

    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(Quotes)