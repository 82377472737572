import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Calendar } from "react-calendar";
import call from "../../../service";
import PieChartComponent from "./PieChartComponent";
import Shipmentschart from "./ShipmentsChart";
import ShipmentCountryChart from "./ShipmentsCountryChart";

const ShipmentGraphs = ({ shipmentCountrydata, EXPORTER_NAME, userId, setshowLoader, userName }) => {
  console.log('shipmentCountrydata', shipmentCountrydata)
  const [filter, setFilter] = useState({
    resultPerPage: 10, groupBy: "$week", fromDate: moment().startOf('month').format("YYYY-MM-DD"),
    toDate: moment().endOf('month').format("YYYY-MM-DD"),
  })
  const [selectedFilter, setSelectedFilter] = useState('This Month')
  const [isDropDown, setisDropDown] = useState(false)
  const [refreshChart, setRefreshChart] = useState(0)
  const [shipmentdata, setshipmentsdata] = useState([])

  const handleKeyDown = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      setRefreshChart(refreshChart + 1)
      setisDropDown(false)
    }

  };
  const handleChange = async (event) => {
    event.persist()
    setFilter({ ...filter, [event.target.name]: event.target.value })
  }
  const getShipmentChartdata = () => {
    setshowLoader(true)
    let reqObj = {
      userId,
      EXPORTER_NAME,
      ...filter
    }
    call('POST', 'getExporterSummaryV2', reqObj).then(result => {
      setshipmentsdata(result)
      setshowLoader(false)
    }).catch(e => {
      setshowLoader(false)
    })
  }
  useEffect(() => {
    getShipmentChartdata()
  }, [refreshChart])
  return (
    <>
      <div className="row justify-content-between mt-5">
        <div className="col-md-6 my-3">
          <div className="card dashboard-card border-0 borderRadius px-4 ms-4 me-2 h-100">
            <div className="d-flex gap-4 align-items-center">
              <div className="">
                <p className="font-wt-600 letter-spacing05 text-color1 font-size-14 lineheight19 mb-0">Total Shipment</p>
              </div>
              <div>
                <div class="dropdown">
                  <button class="form-select border-0 monthsingraph " onClick={() => setisDropDown(!isDropDown)}>
                    {selectedFilter}
                  </button>
                  {isDropDown &
                    <ul class="graphdropdown borderRadius" >
                      <li><a class="dropdown-item cursor" onClick={() => {
                        setFilter({
                          ...filter,
                          fromDate: moment().startOf('month').format("YYYY-MM-DD"),
                          toDate: moment().endOf('month').format("YYYY-MM-DD"),
                          groupBy: "$week"
                        })
                        setRefreshChart(refreshChart + 1)
                        setSelectedFilter("This Month")
                        setisDropDown(false)
                      }}>This month</a></li>
                      <li><a class="dropdown-item cursor" onClick={() => {
                        setFilter({
                          ...filter,
                          fromDate: moment().startOf('year').format("YYYY-MM-DD"),
                          toDate: moment().endOf('year').format("YYYY-MM-DD"),
                          groupBy: "$month"
                        })
                        setRefreshChart(refreshChart + 1)
                        setSelectedFilter("This Year")
                        setisDropDown(false)
                      }}>This year</a></li>
                      <li>
                        <a class="dropdown-item cursor" onClick={() => setSelectedFilter("Custom date")}> Custom date</a>
                        {selectedFilter === 'Custom date' &&
                          <div className="tab-content d-block graphcalendardiv" id="nav-tabContent">
                            <div className="border-rounded mt-4">
                              <div className="px-2 py-1">
                                <Calendar onChange={(val) => {

                                  const dateDiff = moment(val[1]).diff(val[0], 'days')
                                  console.log('datedifffffff', dateDiff)
                                  let groupBy = '$week'
                                  if (dateDiff <= 14) {
                                    groupBy = '$day'
                                  } else if (dateDiff <= 31) {
                                    groupBy = '$week'
                                  } else if (dateDiff <= 365) {
                                    groupBy = '$month'
                                  } else {
                                    groupBy = '$year'
                                  }
                                  setFilter({
                                    ...filter,
                                    fromDate: moment(val[0]).format("YYYY-MM-DD"),
                                    toDate: moment(val[1]).format("YYYY-MM-DD"),
                                    groupBy
                                  })
                                  setRefreshChart(refreshChart + 1)
                                  setisDropDown(false)
                                }} value={[new Date(filter.fromDate), new Date(filter.toDate)]} className="borderRadius border-0 calenderBorder col-md-12" next2Label={null} prev2Label={null} selectRange={true} calendarType={"US"} />
                              </div>
                            </div>
                          </div>
                        }
                      </li>
                      <li>
                        <a class="dropdown-item cursor" onClick={() => setSelectedFilter("Buyer")}>Buyer</a>
                        {selectedFilter == 'Buyer' && <div className="input-group mb-3 currency mt-3 pe-1 position-absolute searchBarDropdown" style={{ top: "7.3rem" }}>
                          <span className="input-group-text bg-white border-end-0" id="basic-addon1"><img src={"assets/images/fe_search.png"} alt="search" /></span>
                          <input type="text" name='searchbuyer' value={filter.searchbuyer} onKeyDown={handleKeyDown}
                            onChange={handleChange}
                            className="form-control border-start-0" placeholder="Search" />
                        </div>

                        }
                      </li>
                      <li>
                        <a class="dropdown-item cursor" onClick={() => setSelectedFilter("HSN code")}>HSN code</a>
                        {selectedFilter == 'HSN code' && <div className="input-group mb-3 currency mt-3 pe-1 position-absolute searchBarDropdown" style={{ top: "9.3rem" }}>
                          <span className="input-group-text bg-white border-end-0" id="basic-addon1"><img src={"assets/images/fe_search.png"} alt="search" /></span>
                          <input type="text" name='hsnSearch' value={filter.hsnSearch} onKeyDown={handleKeyDown}
                            onChange={handleChange}
                            className="form-control border-start-0" placeholder="Search" />
                        </div>
                        }
                      </li>
                      <li>
                        <a class="dropdown-item border-0 cursor" onClick={() => setSelectedFilter("Product")}>Product</a>
                        {selectedFilter == 'Product' && <div className="input-group mb-3 currency mt-3 pe-1 position-absolute searchBarDropdown" style={{ top: "11.3rem", zIndex: 1000 }}>
                          <span className="input-group-text bg-white border-end-0" id="basic-addon1"><img src={"assets/images/fe_search.png"} alt="search" /></span>
                          <input type="text" name='productSearch' value={filter.productSearch} onKeyDown={handleKeyDown}
                            onChange={handleChange}
                            className="form-control border-start-0" placeholder="Search" />
                        </div>
                        }
                      </li>
                    </ul>
                  }
                </div>
              </div>
            </div>
            <div className="py-5 mt-5 text-center  ">
              <Shipmentschart data={shipmentdata} />
            </div>
          </div>
        </div>
        <div className="col-md-6 my-3">
          <div className="card boxshadowgraphs border-0 borderRadius p-4 ms-3">
            <div className="d-flex gap-3 h-100">
              <div className="col-md-6">
                <ShipmentCountryChart data={shipmentCountrydata} dataKey="country_count" />
              </div>
              <div className="d-flex flex-column justify-content-center col-md-6 mt-3">
                <p className="font-wt-600 letter-spacing05 text-color1 font-size-14 lineheight19 mb-3">Shipment breakdown</p>
                {shipmentCountrydata.map(item => {
                  return <p className=" letter-spacing05 font-wt-300 font-size-12 mb-4" ><span className="Financelimitapplied me-2" style={{ backgroundColor: item.color }}></span>{item.DESTINATION_COUNTRY}</p>
                })
                }
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
}
export default ShipmentGraphs;