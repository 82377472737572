// src/components/Navbar.js

import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { setClientType, setNavToggleState, setNavbarConfiguration } from '../../store/actions/action';
import { connect } from 'react-redux';
import LiveNotif from '../notifications/liveNotifications'
import { setTraderType, getTraderType } from '../../utils/cookieHelper';
import { setNavToggleStateLS, getNavToggleState } from '../../utils/cookieHelper';
import call from '../../service';
import { TabComponent } from '../CommonComponent/TabComponent';
import { Link } from 'react-router-dom';
import { checkPermission } from '../../utils/myFunctions';
import toastDisplay from '../../utils/toastNotification';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




let validLCTutorialSteps = ["0", "1", "3", "4", "5", "6"]


const SidebarV2 = ({ userTokenDetails, clientType, setClientType, state, navToggleState, setNavToggleState, dispatch, notifications, items, setNavbarConfigurations }) => {
  const [lcTutorial, toggleLCTutorial] = useState(validLCTutorialSteps.includes(localStorage.getItem("lcTutorialStep")))
  const [overlay, setOverlay] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [lcTutorialStep, setLCTutorialStep] = useState(localStorage.getItem("lcTutorialStep"))
  const [trader, setTrader] = useState(null);

  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  const userId = userTokenDetails && userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const isEximBank = userTokenDetails.isEximBank ? userTokenDetails.isEximBank : null;
  const isLCEnabled = userTokenDetails.isLCEnabled ? userTokenDetails.isLCEnabled : null;
  const isInvoiceEnabled = userTokenDetails.isInvoiceEnabled ? userTokenDetails.isInvoiceEnabled : null;
  const userPermissions = userTokenDetails.UserAccessPermission ? Object.values(JSON.parse(userTokenDetails.UserAccessPermission)).join(",").split(",") : []
  const userPermissionsForSubAdmin = JSON.parse(userTokenDetails.UserAccessPermission || "{}")
  const sub_user_id = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : 0

  // UseEffects
  useEffect(() => {
    setNavToggleState(getNavToggleState())
    let tdr = getTraderType();
    if (tdr) {
      setTrader(tdr);
      setClientType(tdr);
    } else {
      setTraderType("buyer");
      setTrader("buyer");
      setClientType("buyer");
    }
  }, []);

  // Function to handle scroll events
  const handleScroll = () => {
    const sidebar = document.getElementById('app-nav-div');
    // console.log("1111111111111111111111111111", sidebar?.scrollTop);
    if (sidebar) {
      const scrollTop = sidebar.scrollTop;
      setScrollPosition(scrollTop);
      // Store the scroll position in local storage
      localStorage.setItem('sidebarScrollPosition', scrollTop.toString());
    }
  };

  // Attach scroll event listener when the component mounts
  useEffect(() => {
    const sidebar = document.getElementById('app-nav-div');
    // console.log("22222222222222222222222222222222", sidebar);
    if (sidebar) {
      sidebar.addEventListener('scroll', handleScroll);
    }

    // Check for a stored scroll position in local storage
    const storedScrollPosition = localStorage.getItem('sidebarScrollPosition');
    if (storedScrollPosition) {
      // Scroll the sidebar to the stored position
      sidebar.scrollTop = parseInt(storedScrollPosition, 10);
      setScrollPosition(parseInt(storedScrollPosition, 10));
    }

    // Clean up the event listener when the component unmounts
    return () => {
      if (sidebar) {
        sidebar.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Functions

  //---------------------------------------------------------------------------------------------------------------------

  function handleNavBarToggle() {

    let currentNavState = getNavToggleState() / 1
    let newNavState = currentNavState ? 0 : 1

    setNavToggleStateLS(newNavState)
    setNavToggleState(newNavState)
  }
  console.log('IncomingNotifications', state)

  const isSideBarOpen = (type) => {
    let cacheData = JSON.parse(localStorage.getItem("sideBarStatus") || "{}")
    return cacheData[type]
  }

  const storeSideBarStatusInCache = (type) => {
    let cacheData = JSON.parse(localStorage.getItem("sideBarStatus") || "{}")
    cacheData[type] = !cacheData[type]
    localStorage.setItem("sideBarStatus", JSON.stringify(cacheData))
  }


  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(items);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);

    setNavbarConfigurations(reorderedItems);
  };

  function switchTrader(traderType) {
    setTraderType(traderType);
    setTrader(traderType);
    setClientType(traderType);
  }

  return (
    <>
      <nav className={(navToggleState.status ? "w-5 " : "col-md-2 ") + "d-md-block sidebar px-3 bg-white sidebarscroll" + (navToggleState.status ? " expanded-left" : "")} id="app-nav-div" style={{
        height: "750px", height: "105.04201680672269vh"
      }}>
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover toastClassName="toaster-container" />

        <div className="top-sticky2" style={lcTutorial ? { zIndex: -1 } : {}}>

          {/* logo start */}
          <div className="logo px-0 justify-content-center" style={{ height: "90px" }}>
            {/* <div className="expanded-menu cursor-pointer" id="app-nav-toggle" onClick={() => handleNavBarToggle()}><i className="fa fa-bars" aria-hidden="true"></i></div> */}

            <div onClick={() => handleNavBarToggle()}>
              <img height="60" className="default_logo" src="assets/images/logos/logo_1.png" />
              <img height="60" className="mini_logo" src="assets/images/logos/logo_mini.png" />
            </div>
          </div>
          {/* logo end */}
        </div>

        <div className={`${lcTutorial && 'overlay_in'}`}></div>

        <div className="menu-overflow">

          <div className="sidebar-sticky " id="scrollbarStyle-custom">
            <button className="close-menu">×</button>

            {/* nav panel start */}
            <div className="col-md-12 px-1">
              <div className="row">
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="navbar">
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        <ul className="nav flex-column gap-2">
                          {items.map((item, index) => {
                            return <Draggable key={item.id} draggableId={item.id} index={index}>
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className="navbar-item"
                                >
                                  {item.level === 0 ?
                                    <Link onClick={() => {
                                      call("POST", 'setNavBarActivities', { userId, navBarName: item.stateVal })
                                    }} className="nav-link mb-0" to={item.redirectURL} >
                                      <li className={"nav-item " + ((state === item.stateVal) ? 'active2' : 'text-dark')} >
                                        <span >
                                          <img src={item.icon} height="25px" className="pr-1" title="Dashboard" />
                                        </span>
                                        <span>{item.text}</span>
                                        {/* <span><img src="assets/images/sidebarV2/active_notifiation_dot.svg" className="pl-2" style={{ width: 18, height: 35 }} /> </span> */}
                                      </li>
                                    </Link>
                                    : item.level === 1 ?
                                      <a className={`nav-link accordion invoiceacc  cursor`} id="accordionExample" style={lcTutorialStep == 1 ? { zIndex: "9" } : {}} onClick={(e) => {
                                        if (navToggleState.status) {
                                          e.preventDefault()
                                          handleNavBarToggle()
                                        }
                                        call("POST", 'setNavBarActivities', { userId, navBarName: item.stateVal })
                                      }}>

                                        <li
                                          onClick={() => storeSideBarStatusInCache(item.stateVal)}
                                          className='accordion-item border-0 acordion-default-icon-remove'>
                                          <li className={`nav-item   ${navToggleState.status ? '' : 'accordion-button'}  ${(state.includes(item.stateVal) || isSideBarOpen(item.stateVal)) ? '' : 'collapsed'} rounded-0` + ((state.includes(item.stateVal) || isSideBarOpen(item.stateVal)) ? ' active2' : ' text-dark')} data-bs-toggle="collapse" data-bs-target={`#${item.collapseTarget}`} aria-expanded={(state.includes(item.stateVal) || isSideBarOpen(item.stateVal)) ? "true" : "false"} aria-controls={item.collapseTarget} >
                                            <span className='' ><img src={item.icon} height="25px" className="pr-1" title={item.text} /></span>
                                            <span>{item.text}</span>
                                            {notifications.findIndex(ele => ele?.toLowerCase().includes(item.notificationVal)) !== -1 ?
                                              <span className='pl-0'><img src="assets/images/sidebarV2/active_notifiation_dot.svg" className="pl-2" style={{ width: 18, height: 35 }} /> </span>
                                              : null}
                                          </li>
                                        </li>

                                        <div id={item.collapseTarget} className={`accordion-menus-container ${lcTutorialStep == 1 ? ' bg-white ' : ' NavDropdown '} accordion-collapse  ${lcTutorialStep == 1 ? '' : ' collapse '} ${((state.includes(item.stateVal) || isSideBarOpen(item.stateVal)) && !navToggleState.status) ? 'show' : ''}`} aria-labelledby="headingOne" data-bs-parent="#accordionExample" >
                                          {item.subItems.map((ele, j) => {
                                            return <Link className="nav-link borderInfo" to={ele.redirectURL} onClick={(e) => {
                                              if (item.localStorageKey) {
                                                checkPermission(userPermissions, sub_user_id, e, ele.permissionVal, toastDisplay, userId, () => {
                                                  localStorage.setItem(item.localStorageKey, ele.name)
                                                  window.location = ele.redirectURL
                                                })
                                              } else {
                                                checkPermission(userPermissions, sub_user_id, e, ele.permissionVal, toastDisplay, userId, null)
                                              }
                                            }}>
                                              <li className={"nav-item " + (item.localStorageKey ? (localStorage.getItem(item.localStorageKey) === ele.name ? 'active3 border-0' : 'text-dark') : (state === ele.stateVal) ? 'active3 border-0' : 'text-dark')} >
                                                <span className='text-secondary font-size-13'>{ele.name}</span>
                                                {notifications.findIndex(item => item?.toLowerCase().includes(ele.notificationVal)) !== -1 ?
                                                  <span className='pl-0'><img src="assets/images/sidebarV2/active_notifiation_dot.svg" className="pl-2" style={{ width: 18, height: 35 }} /> </span>
                                                  : null}
                                              </li>
                                            </Link>
                                          })}
                                        </div >
                                      </a >
                                      : item.level === 2 ?
                                        <a className="nav-link accordion invoiceacc  cursor" id="accordionExample" style={{}} onClick={(e) => {
                                          if (navToggleState.status) {
                                            e.preventDefault()
                                            handleNavBarToggle()
                                          }
                                          call("POST", 'setNavBarActivities', { userId, navBarName: item.stateVal })
                                        }}>
                                          <li
                                            onClick={() => storeSideBarStatusInCache(item.stateVal)}
                                            className='accordion-item border-0 acordion-default-icon-remove'>
                                            <li className={`nav-item   ${navToggleState.status ? '' : 'accordion-button'}  ${(state.includes(item.stateVal) || isSideBarOpen(item.stateVal)) ? '' : 'collapsed'} rounded-0` + ((state.includes(item.stateVal) || isSideBarOpen(item.stateVal)) ? ' active2' : ' text-dark')} data-bs-toggle="collapse" data-bs-target={`#${item.collapseTarget}`} aria-expanded={(state.includes(item.stateVal) || isSideBarOpen(item.stateVal)) ? "true" : "false"} aria-controls={item.collapseTarget} >
                                              <span className='' ><img src={item.icon} height="25px" className="pr-1" title={item.text} /></span>
                                              <span>{item.text}</span>
                                            </li>
                                          </li>
                                          <div id={item.collapseTarget} className={`accordion-menus-container ${' NavDropdown '} accordion-collapse  ${' collapse '} ${((state.includes(item.stateVal) || isSideBarOpen(item.stateVal)) && !navToggleState.status) ? 'show' : ''}`} aria-labelledby="headingOne" data-bs-parent="#accordionOtherFin" >

                                            {item?.subItems?.map((ele, j) => {
                                              return <>

                                                <>
                                                  <li className='accordion-item border-0 '>
                                                    <li className={`nav-item   ${navToggleState.status ? '' : 'accordion-button-submenu'}  ${state.includes(ele.stateVal) ? '' : 'collapsed'} rounded-0` + (state.includes(ele.stateVal) ? ' active2' : ' text-dark')} data-bs-toggle="collapse" data-bs-target={`#${ele.collapseTarget}`} aria-expanded={state.includes(ele.stateVal) ? "true" : "false"} aria-controls={ele.collapseTarget} >
                                                      {ele.name === 'Domestic Invoice Discounting' ?
                                                        <div style={{ paddingLeft: 29 }}>
                                                          <span className='font-size-13 px-0'>Domestic Invoice Discounting</span>
                                                        </div> :
                                                        <span className='font-size-13'>{ele.name}</span>}
                                                    </li>
                                                  </li>
                                                  <div id={ele.collapseTarget} className={`accordion-menus-container ${' NavDropdown '} accordion-collapse  ${' collapse '} ${(state.includes(ele.stateVal) && !navToggleState.status) ? 'show' : ''}`} aria-labelledby="headingOne" data-bs-parent="#accordionExample" >
                                                    {ele?.stages?.map((stage, k) => {
                                                      return <Link className="nav-link borderInfo" to={stage.redirectURL}
                                                      >
                                                        <li className={"nav-item " + ((state === stage.stateVal) ? ' active3 border-0' : 'text-dark')} >
                                                          <span className='text-secondary font-size-13'>{stage.name}</span>
                                                        </li>
                                                      </Link>
                                                    })}
                                                  </div>
                                                </>
                                              </>
                                            })}
                                          </div>

                                        </a >
                                        : null

                                  }
                                </div>
                              )}
                            </Draggable>
                          })}
                        </ul>
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
        </div>
      </nav>

    </>

  );
};

const mapStateToProps = state => {
  return {
    clientType: state.clientType,
    navToggleState: state.navToggleState,
    notifications: state.IncomingNotifications.notification_data,
    items: state.NavConfiguration.navbarItems
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setClientType: (type) => { dispatch(setClientType({ type: type })) },
    setNavToggleState: (status) => { dispatch(setNavToggleState({ status: status })) },
    setNavbarConfigurations: (data) => { dispatch(setNavbarConfiguration(data)) }
  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SidebarV2)
