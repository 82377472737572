import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import HeaderV2 from '../partial/headerV2';
import SideBarV2 from '../partial/sideBarV2';
import FAQs from './components/faqs';
import MonthCard from './components/monthCard';
import StandardCard from './components/standardCard';
import FinanceInvoiceModal from '../InvoiceDiscounting/contract/components/financeinvoiceModal';
import TableFilter from './components/tableFilter';
import { useHistory } from 'react-router';
import call from '../../service';
import { ExportExcel, generateInvoiceNumber, most_used_currencies, printDiv } from '../../utils/myFunctions';
import moment from 'moment';
import { convertImageToPdf } from '../../utils/myFunctions';
import toastDisplay from '../../utils/toastNotification';
import { getDocDetails } from '../../utils/myFunctions';
import { AccordionTable } from "./components/accordionTable";
import { ToastContainer } from 'react-toastify';
import Pagination from '../InvoiceDiscounting/contract/components/pagination';
import BalanceCard from './components/BalanceCard';
import CoinsCard from './components/CoinsCard';
import Topup from './components/Topup';
import { NewTable } from '../../utils/newTable';
import { CustomizedTable } from './components/CustomizedTable';
import { displayRazorpay } from '../../utils/PaymentGateway';
import TutorialPopup from '../tutorialPopup';

const otherIncludes = [
  "Daily Market Updates",
  "Dedicated RM",
  "MIS Reports",
  "E-Invoice Generator"
]

const tablerows = [
  { label: "LC nos.", currentKey: "lc_nos", actualKey: "total_lc_nos" },
  { label: "Buyer Details", currentKey: "buyer_nos", actualKey: "total_buyer_nos" },
  { label: "Document Vault Limit", currentKey: "transactions_nos", actualKey: "total_transactions_nos" },
  { label: "Points", currentKey: "points_nos", actualKey: "points_nos" },
  { label: "Invoice nos.", currentKey: "invoice_nos", actualKey: "total_inv_nos" },
]

const IconDisclaimers = [
  { label: 'In-Progress', imgPath: '/assets/images/supplier-images/inProgress_icon.png' },
  { label: 'Debit', imgPath: '/assets/images/supplier-images/debit_icon.png' },
  { label: 'Credit', imgPath: '/assets/images/supplier-images/credit_icon.png' },
  { label: 'Failed', imgPath: '/assets/images/supplier-images/failed_icon.png' },
]

const WalletV2 = ({ userTokenDetails, navToggleState }) => {

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)
  const [tableData, setTableData] = useState([])
  const [page, setPage] = useState(1)

  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10 })

  const [tableExpand, setTableExpand] = useState("");
  const [walletData, setWalletData] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [planBalance, setPlanBalance] = useState({})
  const [expandedData, setExapndedData] = useState([]);
  const [showmodalTopup, setShowModalTopup] = useState(false)
  const [showmodalCurrentDetails, setShowModalCurrentDetails] = useState(false)
  const [topupdata, settopupdata] = useState([])
  const [plandata, setplandata] = useState([])
  const [showTutorial, toggleShowTutorial] = useState(localStorage.getItem("walletTutorialStep") == 0)
  const [showInvPdf, toggleInvPdf] = useState(false)
  const [invoicedata, setinvoicedata] = useState([])
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  console.log('userTokenDetails', userTokenDetails)
  const getPlanBalance = () => {
    setshowLoader(true)
    call('POST', 'getPlanBalance', { userId }).then(result => {
      console.log('success in getPlanBalance', result)
      let expiryDate = moment(result.createdAt).add(result.plan_validity_unit?.toLowerCase(), result.plan_validity_value).format('MM/DD/YYYY')
      setPlanBalance({
        ...result,
        expiryDate: expiryDate
      })
      setshowLoader(false)
    }).catch(e => {
      console.log('error in getPlanBalance', e)
      setshowLoader(false)

    })
  }
  const getWalletBalance = () => {
    call('POST', 'getWalletBalance', { userId }).then((result) => {
      console.log("API result getWalletBalance", result);
      setshowLoader(false)
      if (result && result.length) {
        setWalletData(result[0])
      }
    }).catch((e) => {
      console.log("Error while getWalletBalance", e);
      setshowLoader(false)
    });
  }
  const getTopupPlans = () => {
    setshowLoader(true)
    call('POST', 'getTopupPlans', {}).then(result => {
      settopupdata(formatDataForTopup(result))
      setshowLoader(false)

    }).catch(e => {
      console.log('error in getTopupPlans', e)
      setshowLoader(false)

    })
  }
  const getTransactions = () => {
    setshowLoader(true)
    let walletTransactionReq = {
      userId,
      search: filter.search,
      resultPerPage: filter.resultPerPage,
      currentPage: page,
      fromDate: filter.fromDate,
      toDate: filter.toDate
    }
    console.log('getSubscriptionHistory req', walletTransactionReq, filter)
    call('POST', 'getSubscriptionHistory', walletTransactionReq).then((result) => {
      console.log("API result getSubscriptionHistory", result.message);
      setTableData(formatDataForTable(result.message))
      setDbData(result.message)
      setCount(result.countdata.total_transactions)
      setshowLoader(false)
    }).catch((e) => {
      console.log("Error while getSubscriptionHistory", e);
      setshowLoader(false)
    });
  }
  const getUserCurrentPlan = () => {
    setshowLoader(true)
    call('POST', 'getUserCurrentPlan', { userId }).then(result => {
      console.log('success in getUserCurrentPlan', result)
      setplandata(formatDataForCurrentPlan(tablerows, result))
      setshowLoader(false)
    }).catch(e => {
      console.log('error in getUserCurrentPlan', e)
      setshowLoader(false)
    })
  }

  useEffect(() => {
    getPlanBalance()
    getWalletBalance()
    getTopupPlans()
    getUserCurrentPlan()
  }, [])

  useEffect(() => {
    getTransactions()

  }, [refresh, page])

  const closeFn = () => {
    setShowModalTopup(false)
    setShowModalCurrentDetails(false)
  }
  const exportToexcel = (data, filename) => {
    let downloaddata = []
    data.forEach(data => {
      let downloadobj = {
        "Name": data.createdByName,
        "Service Name": data.serviceName,
        "Transaction ID": data.transactionId,
        "Type of Transaction": data.type,
        "Charges": data.charges,
        "Mode Of Payment": data.modeOfPayment,
        "Date And Time": moment(data.createdAt).format('DD-MM-YYYY hh:ss:mm A'),
      }
      downloaddata.push(downloadobj)
    })
    ExportExcel(downloaddata, filename)
  }

  function formatDataForTable(data) {
    let tableData = []
    let row = []
    data.forEach((item, index) => {
      console.log('ITemmmmmmmmmmmmm', item.transactionId === tableExpand)
      row[0] = <div>
        <img src={item.status === 1 ? "/assets/images/supplier-images/inProgress_icon.png" : item.status === 3 ? "/assets/images/supplier-images/failed_icon.png" : item.type === 'DEBIT' ? "/assets/images/supplier-images/debit_icon.png" : "/assets/images/supplier-images/credit_icon.png"} alt='' />
      </div>
      row[1] = item.createdByName
      row[2] = moment(item.updatedAt).format('DD/MM/YYYY hh:mm a')
      row[3] = item.serviceName
      row[4] = item.modeOfPayment
      row[5] = item.charges ? <>
        <span>{item.modeOfPayment === 'Coins' && <img src={'/assets/images/Lc/Dollar.png'} alt='' />}  {item.modeOfPayment === 'Coins' ? Math.abs(item.charges) : item.charges}</span>
      </> : <span>N/A</span>
      // row[6] = item.type
      row[6] = item.transactionId === tableExpand ? <div className='row' >
        <p onClick={() => expandedTableFN()} className="text-color1 font-wt-600 cursor">
          View Less
        </p>
      </div > : <div className='row' >
        <p onClick={() => expandedTable([item], item.transactionId)} className="text-color1 font-wt-600 cursor">
          View More
        </p>
      </div >
      row[7] = item.transactionId
      tableData.push(row)
      row = []
    })
    return tableData
  }

  const expandedTable = (more, buyer) => {
    console.log("eeeeeeeeeeeeeeeeeeeeeeeeee", more, buyer);
    setTableExpand(buyer);
    setExapndedData(more);
  }

  const expandedTableFN = () => {
    setTableExpand("");
    setExapndedData([]);

  }

  const onSuccesfullPayment = (data, extradata) => {
    let req = {
      userId: userId,
      ...extradata,
      order_data: data
    }
    call('POST', 'buyTopup', req).then(result => {
      console.log('success in buyTopup', result)
      toastDisplay(result, 'success')
      setTimeout(() => {
        window.location.reload()
      }, 500);
    }).catch(e => {
      console.log('error in buyTopup', e)
    })
  }
  const onPaymentFailure = (data, extradata, callback) => {
    let req = {
      userId: userId,
      ...extradata,
      order_data: data
    }
    call('POST', 'failedTopupPayment', req).then(result => {
      console.log('success in failedTopupPayment', result)
      toastDisplay(result, 'error')
      callback()
      setTimeout(() => {
        window.location.reload()
      }, 500);
    }).catch(e => {
      console.log('error in failedTopupPayment', e)
    })
  }

  function formatDataForTopup(data) {
    let tableData = []
    let row = []
    data.forEach((item, index) => {
      console.log(tableExpand, item)

      row[0] = item.topup_type
      row[1] = `$ ${item.topup_value_usd}`
      row[2] = item.topup_nos
      row[3] = <label className='font-size-14 text-color1 font-wt-600 cursor' onClick={() => {
        onPurchasePlan(item.id, 'Topup' + ' - ' + item.topup_type, item.topup_value_usd)
      }}>Buy now</label>
      tableData.push(row)
      row = []
    })
    return tableData
  }
  function formatDataForCurrentPlan(data, Obj) {
    let tableData = []
    let row = []
    data.forEach((item, index) => {
      console.log(tableExpand, item)

      row[0] = <>
        <b>{item.label}</b>
      </>
      row[1] = Obj[item.actualKey]
      row[2] = Obj[item.currentKey]
      tableData.push(row)
      row = []
    })
    return tableData
  }

  useEffect(() => {
    setTableData(formatDataForTable(dbData))
  }, [expandedData, tableExpand])

  const onPurchasePlan = async (plan_id, plan_type, plan_amount_usd,) => {
    try {
      let reqObj = {
        plan_id,
        plan_type,
        plan_amount_usd,
        userId,
        currency: "$"
      }
      const result = await call('POST', 'createPaymentlink', reqObj)
      window.location = result
    } catch (e) {
      console.log('error in createPaymentlink', e)
    }
  }
  return (
    <>
      <TutorialPopup show={showTutorial} featureName={"Wallet"} positioning={{
        "top": "18rem",
        "right": "0rem"
      }}
        children={<label className='font-size-14 font-wt-600'><span>Welcome onboard !</span><br />Click here to purchase a plan</label>}
        arrowPositioning={{
          "top": "12rem",
          "zIndex": 10,
          transform: "rotate(0deg)",
          left: "38.5%"
        }} />
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {showmodalTopup &&
        <FinanceInvoiceModal limitinvoice={showmodalTopup} setLimitinvoice={setShowModalTopup} closeSuccess={closeFn}>
          <p className='text-center font-size-16 font-wt-600'>Top - Up</p>
          <div className="d-flex justify-content-center">
            <div className="col-10 px-0">
              <CustomizedTable
                disableAction={true}
                columns={[
                  { name: "Top-up", filter: false },
                  { name: "Price", filter: false },
                  { name: "Nos", filter: false },
                  { name: "Action", filter: false }]}
                data={topupdata} />
            </div>
          </div>
        </FinanceInvoiceModal>
      }
      {showmodalCurrentDetails &&
        <FinanceInvoiceModal limitinvoice={showmodalCurrentDetails} setLimitinvoice={setShowModalCurrentDetails} closeSuccess={closeFn}>
          <p className='text-center font-size-16 font-wt-600'>Current plan details</p>
          <div className='ml-5'>
            <p className='leftText font-size-14 font-wt-600 text-color1'>{planBalance.plan_name}<label className='leftText font-size-14 font-wt-500 text-color-0C0C0C'> (valid till {planBalance.expiryDate}) </label></p>

          </div>
          <div className="d-flex justify-content-center">
            <div className="col-10 px-0">
              <CustomizedTable
                disableAction={true}
                columns={[
                  { name: "Plan Includes", filter: false, columnStyles: 'text-color1 font-wt-600 font-size-14' },
                  { name: "Plan", filter: false },
                  { name: "Left", filter: false },
                ]}
                data={plandata} />
            </div>
          </div>
          {(planBalance.plan_name === 'Pro' || planBalance.plan_name === 'Pro Plus') && <>
            <div className='pt-2 ml-5'>
              <p className='leftText font-size-14 font-wt-500'>Other Includes:</p>
            </div>
            <div className="d-flex justify-content-start pt-0">
              <div className="col-6 ml-3">
                <ul>
                  {otherIncludes.map(item => {
                    return <li className='leftText font-size-14 font-wt-500'>{item}</li>
                  })
                  }
                </ul>
              </div>
            </div>
          </>}

        </FinanceInvoiceModal>
      }
      {showInvPdf ? (
        <FinanceInvoiceModal modalSize={'xl'} limitinvoice={showInvPdf} closeSuccess={() => {
          toggleInvPdf(false)
        }}>
          <>
            <>
              <div id={"printsubscriptionInvoice"} className='px-4'>
                <div className='d-flex flex-row justify-content-end mb-3'>
                  <button type="button"
                    onClick={() => printDiv("printsubscriptionInvoice", `Subscription_Purchase_${invoicedata.createdByName}`, [])}
                    className={` border-0 mb-2 text-white enableQuotebtn`}>
                    {"Print Invoice"}
                  </button>
                </div>
                <div className='d-flex flex-row justify-content-between'>
                  <div>
                    <img width={"100px"} height={"55px"} src={"assets/images/logo_1.png"} />
                  </div>
                  <div>
                    <div className='d-flex flex-row'>
                      <div><img width={"20px"} height={"20px"} src={"assets/images/call-vector.png"} /></div>
                      <label className='text-color-value font-size-14 font-wt-600 text-center pl-2'>+ 91 84509 69138</label>
                    </div>
                    <div className='d-flex flex-row'>
                      <div><img width={"20px"} height={"20px"} src={"assets/images/mail-vector.png"} /></div>
                      <label className='text-color-value font-size-14 font-wt-600 text-center pl-2'>info@tradereboot.com</label>
                    </div>
                    <div className='d-flex flex-row'>
                      <div><img width={"20px"} height={"20px"} src={"assets/images/web-vector.png"} /></div>
                      <label className='text-color-value font-size-14 font-wt-600 text-center pl-2'>www.tradereboot.com</label>
                    </div>
                  </div>
                </div>
                <hr />
                <label className='text-color-value font-size-18 font-wt-600 text-center'>Invoice</label>
                <div className='d-flex flex-row justify-content-between'>
                  <div>
                    <div>
                      <label className='text-color-value font-size-14 font-wt-600 w-100 text-left'>Order From</label>
                      <label className='text-color-value font-size-13 font-wt-600 w-100 text-left'>{"TRADEREBOOT FINTECH PRIVATE LIMITED"}</label>
                      <div className='w-40'>
                        <label className='text-color-value font-size-13 font-wt-500 w-100 text-left'>{"410, Atlanta Estate, Western Express Hwy, opp. Westin Hotel, ITT Bhatti, Hanuman Tekdi, Goregaon, Mumbai, Maharashtra 400063"}</label>
                      </div>
                      <label className='text-color-value font-size-13 font-wt-500 w-100 text-left'>{`GST Number : 27AAFCI3158R1ZX`}</label>

                    </div>
                    <div className='mt-2'>
                      <label className='text-color-value font-size-14 font-wt-600 w-100 text-left'>To</label>
                      <label className='text-color-value font-size-13 font-wt-600 w-100 text-left'>{invoicedata.createdByName}</label>
                      <div className='w-40'>
                        <label className='text-color-value font-size-13 font-wt-500 w-100 text-left'>{invoicedata.createdByAddress}</label>
                      </div>
                      <label className='text-color-value font-size-13 font-wt-500 w-100 text-left'>{`GST Number : ${invoicedata.userGSTNo}`}</label>
                      {(invoicedata.userPan && invoicedata.userPan !== "null") && <label className='text-color-value font-size-13 font-wt-500 w-100 text-left'>{`PAN Number : ${invoicedata.userPan}`}</label>}
                    </div>
                  </div>
                  <div>
                    <p className='text-color-value font-size-12 font-wt-600 text-left'>{`Invoice Number: ${generateInvoiceNumber(invoicedata.createdAt, invoicedata.createdByName)}`}</p>
                    <p className='text-color-value font-size-12 font-wt-600 text-left'>{`Date: ${moment(invoicedata.createdAt).format('DD-MM-YYYY hh:mm A')}`}</p>
                  </div>
                </div>
                <label className='text-color-value font-size-14 font-wt-600 text-center my-4'>{`Purchase of ${invoicedata.serviceName}`}</label>
                <div>
                  <NewTable
                    disableAction={true}
                    data={[[moment(invoicedata.createdAt).format('DD-MM-YYYY hh:mm A'), "Subscription Plan", invoicedata.serviceName?.split('-')[0], invoicedata.serviceName?.split('-')[1], invoicedata.charges]]}
                    columns={[{ name: "Date" }, { name: "Product Category" }, { name: "Product Type" }, { name: "Product Sub Type" }, { name: "Charges" }]} />
                  <div className='d-flex flex-row justify-content-end'>
                    <table className='border'>
                      <tbody className='border'>
                        {invoicedata.modeOfPayment === 'FREE' ?
                          <tr className='border'>
                            <th scope="row" className='font-size-14 font-wt-600'>Total Discount</th>
                            <td className='font-size-14 font-wt-600'>{`${(invoicedata.charges)}`}</td>
                          </tr>
                          : null}
                        <tr className='border'>
                          <th scope="row" className='font-size-14 font-wt-600'>Total Charges</th>
                          <td className='font-size-14 font-wt-600'>{`${invoicedata.modeOfPayment === 'FREE' ? "$ 0" : invoicedata.charges}`}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className='d-flex flex-row '>
                    <table className='border w-100 mt-5'>
                      <tbody className='border'>
                        <tr className='border'>
                          <td className='font-size-14 font-wt-600 border'>
                            <th scope="row" className='font-size-14 font-wt-600 text-left' >Payment Transaction ID</th>
                            <tr className='font-size-14 font-wt-600 text-left'>{`${(invoicedata.transactionId)}`}</tr>
                          </td>

                          <td className='font-size-14 font-wt-600 border'>
                            <th scope="row" className='font-size-14 font-wt-600 text-left'>Date & Time</th>
                            <tr className='font-size-14 font-wt-500 text-left'>{`${moment(invoicedata.createdAt).format('DD-MM-YYYY hh:mm A')}`}</tr>
                          </td>

                          <td className='font-size-14 font-wt-600 border'>
                            <th scope="row" className='font-size-14 font-wt-600 text-left'>Invoice Value</th>
                            <tr className='font-size-14 font-wt-500 text-left'>{`${(invoicedata.charges)}`}</tr>
                          </td>

                          <td className='font-size-14 font-wt-600 border'>
                            <th scope="row" className='font-size-14 font-wt-600 text-left'>Mode of Payment</th>
                            <tr className='font-size-14 font-wt-500 text-left'>{`${(invoicedata.modeOfPayment)}`}</tr>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className='my-5'>
                <hr />
              </div>
            </>
          </>
        </FinanceInvoiceModal>
      ) : null}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="wallet" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Wallet"}
              userTokenDetails={userTokenDetails} />
            <div className='row gap-4'>
              <div className='col-auto'
              >
                <div
                  onClick={() => {
                    if (showTutorial) {
                      localStorage.setItem("walletTutorialStep", 1);
                      window.location.reload()
                    }
                    else {
                      window.location = '/plans'
                    }
                  }}
                  style={showTutorial ? {
                    "background": "white",
                    "position": "relative",
                    "zIndex": 10001,
                    "padding": "1rem",
                    "borderRadius": "1rem"
                  } : {}}>
                  <BalanceCard data={planBalance} />
                </div>
              </div>
              <div className='col-auto'>
                <CoinsCard data={walletData} />
              </div>
              <div className='col-auto'>
                <Topup onCurrentDetailsClick={setShowModalCurrentDetails} onToupClick={setShowModalTopup} />
              </div>
            </div>
            <div className='my-4'>
              <p className='font-size-14 font-wt-500 lineheight19 letter-spacing05'>Transaction History</p>
              {
                <div className='d-flex flex-row'>
                  {IconDisclaimers.map(item => {
                    return <div className='d-flex align-items-center p-2'>
                      <img alt='' src={item.imgPath} />
                      <span className='ml-2' >{item.label}</span>
                    </div>
                  })
                  }
                </div>
              }
              <TableFilter filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} onExcelExport={() => exportToexcel(dbData, "Excel_Transactions")} onPDFExport={() => printDiv("transactionTableDiv", "PDF_Transaactions", [])} />
              <div id="transactionTableDiv">
                <AccordionTable disableAction={false}
                  columns={[{ name: "", filter: false, width: '4%' },
                  { name: "Name", filter: true, width: '16%' },
                  { name: "Date", filter: true, width: '16%' },
                  { name: "Purpose", filter: true, width: '16%' },
                  { name: "Mode of Payment", filter: true, width: '16%' },
                  { name: "Amount", filter: true, width: '16%' },
                  // { name: "Type", filter: true },
                  { name: "", filter: false, width: '16%' }
                  ]}
                  data={tableData} overalldata={dbData}
                  expand={expandedData} tableExpand={tableExpand} toggleInvPdf={toggleInvPdf} setInvoicedata={setinvoicedata} />
              </div>
              <Pagination page={page} totalCount={count} onPageChange={(p) => setPage(p)} refresh={refresh} setRefresh={setRefresh} perPage={filter.resultPerPage || 10} />
            </div>
            <FAQs />
          </main>
        </div>
      </div >

    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(WalletV2)