

import React, { useEffect, useState } from 'react';
import call from '../../service';
import { formatDateTime_Application } from '../../utils/dateFormaters';


const ContractLogs = ({ userTokenDetails, contractNo }) => {
  //---------------------------------------------------------------------------------------------------------------------
  //States
  const [Data, setData] = useState({});
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // use effects
  useEffect(() => {
    //------------------------------------------------------------------
    //User details from cookie
    //------------------------------------------------------------------

    //------------------------------------------------------------------
    //API Calls
    call('POST', 'getcontractlogs', { "contract_number": contractNo }).then(async (result) => {
      console.log("result  in getcontractlogs ->", result)
      setData(result)
    }).catch((error) => {
      console.log("error occur in getcontractlogs ->", error)
    })

    //------------------------------------------------------------------

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //---------------------------------------------------------------------------------------------------------------------
  // Handlers
  //---------------------------------------------------------------------------------------------------------------------

  return (<>
    <div className="container">
      <div className="row">
        <div className="col-md-10">
          <div className="main-timeline2">

            {Data.length ? Data.map((data) => {
              return (
                <div className="timeline">
                  <span className="icon fa fa-globe" />
                  <a href="javascript:void(0);" className="timeline-content">
                    <h3 className="title">{data.message}</h3>
                    <p className="description">
                      On {formatDateTime_Application(data.created_at)}
                    </p>
                  </a>
                </div>
              )
            }) : ""}

          </div>
        </div>
      </div>
    </div>

  </>)
}

//---------------------------------------------------------------------------------------------------------------------
// Redux

//---------------------------------------------------------------------------------------------------------------------


export default ContractLogs
