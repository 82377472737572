import React, { useEffect, useState } from 'react';
import call from '../../service';
import { ToastContainer } from 'react-toastify';
import avatarUrl from '../../utils/makeAvatarUrl';
import SideBarV2 from '../partial/sideBarV2';
import HeaderV2 from '../partial/headerV2';
import { connect } from 'react-redux';
import NewTablev2 from '../../utils/newTablev2';


const NetworkRequests = ({ userTokenDetails, navToggleState }) => {

  const [showLoader, setshowLoader] = useState(false);
  const [requestType, setrequestType] = useState('received');
  const [requestsResult, setrequestsResult] = useState([]);

  // const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  // const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {

    //------------------------------------------------------------------
    //API Calls
    setshowLoader(true);
    call('POST', 'getnetworkrequests', { "reqType": requestType, "userId": userId }).then((result) => {
      //console.log('running getnetworkrequests api-->', result);
      setrequestsResult(result)
      setshowLoader(false);
    }).catch((e) => {
      console.log('error in getnetworkrequests', e);
      setshowLoader(false);
    })
    //------------------------------------------------------------------

  }, [requestType]);

  function resolveRequest(data, action, index, type) {
    //console.log("data==>", data)
    // console.log("action==>", action)

    let postObject = {
      id: data.id,
      rFrom: data.request_from,
      rTo: data.request_to,
      newStatus: action,
      loggedUserId: userId,
      reqType: type
    }

    setshowLoader(true);
    call('POST', 'resolvenetworkrequest', postObject).then((result) => {
      // console.log('running getnetworkrequests api-->', result);
      let temp = [...requestsResult]
      temp.splice(index, 1);
      setrequestsResult(temp)
      setshowLoader(false);
    }).catch((e) => {
      console.log('error in getnetworkrequests', e);
      setshowLoader(false);
    })

  }


  return (
    <div className="">
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />

      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="NetworkRequests" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Manage Network > Network Requests"}
              userTokenDetails={userTokenDetails} />
            <nav>
              <div className="nav nav-tabs signdoctabs gap-4 border-0" id="signdoctabs" role="tablist">
                <button className={`nav-link  bg-transparent ${requestType === "received" && 'active'} paymenttab`} id="nav-home-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-home" aria-selected="true" onClick={() => setrequestType("received")}>Recieved Requests</button>
                <span className="border-left"></span>
                <button className={`nav-link  bg-transparent ${requestType === "sent" && 'active'} paymenttab`} id="nav-contact-tab " data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-contact" aria-selected="false" onClick={() => setrequestType("sent")}>Sent Requests</button>
              </div>
            </nav>
            <div className='w-100 mt-5'>
              <NewTablev2
                columns={[{ subColumns: "Profile Image", subColumnStyle: { width: '10%' } },
                { subColumns: "Company Name", subColumnStyle: { width: '25%' } },
                { subColumns: "Category", subColumnStyle: { width: '25%' } },
                { subColumns: "Adress", subColumnStyle: { width: '25%' } },
                { subColumns: "Action", subColumnStyle: { width: '15%' } }
                ]}

              >
                {(requestsResult && requestsResult.length) ? requestsResult.map((request, index) => {
                  return (
                    <tr>
                      <td>
                        <img className="m-1" src={avatarUrl(request.user_avatar)} alt="Avatar" width="50" height="50" />
                      </td>
                      <td>
                        <label className="">{request.company_name ? request.company_name : "NA"} </label>
                      </td>
                      <td>
                        <label className="">{request.usertype_pretty_name ? request.usertype_pretty_name : "NA"} </label>
                      </td>
                      <td>
                        <p className="date-format p-0 m-0">{request.user_address ? request.user_address : "NA"} </p>
                      </td>
                      <td>
                        <div className='col-md-2 text-center d-flex flex-row'>
                          {(requestType === 'received') && <button type="button" className="btn btn-sm btn-success ml-1" onClick={() => resolveRequest(request, 2, index, requestType)}>
                            <i class="fa fa-check" aria-hidden="true"></i>
                          </button>}
                          <button type="button" className="btn btn-sm btn-warning ml-1" onClick={() => resolveRequest(request, 3, index, requestType)}>
                            <i class="fa fa-times" aria-hidden="true"></i>
                          </button>
                          <button type="button" className="btn btn-sm btn-danger ml-1" onClick={() => resolveRequest(request, 4, index, requestType)}>
                            <i class="fa fa-ban" aria-hidden="true"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  )
                }) : null}
              </NewTablev2>
            </div>

            {/* <Pagination page={page} totalCount={count} onPageChange={(p) => setPage(p)} refresh={refresh} setRefresh={setrefresh} perPage={filter.resultPerPage || 0} /> */}
            {/* <div className="">
              {genReqBody(requestType)}

            </div> */}
          </main>
        </div>
      </div>

    </div>
  )
};


const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState
  }
}


export default connect(
  mapStateToProps,
  null
)(NetworkRequests)

