import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { NewTable } from "../../../utils/newTable";
import HeaderV2 from "../../partial/headerV2";
import SideBarV2 from "../../partial/sideBarV2";
import Filter from "../../InvoiceDiscounting/components/Filter";
import Pagination from "../../InvoiceDiscounting/contract/components/pagination";
import config from '../../../config.json';
import call from "../../../service";
import toastDisplay from "../../../utils/toastNotification";
import { convertImageToPdf, getDocDetails, SetCache, ClearCache, most_used_currencies, GetCache, LCTypesObject } from "../../../utils/myFunctions";
import moment from "moment";
import { FileInput } from "../../../utils/FileInput";
import SignDocument from "../../InvoiceDiscounting/components/SignDocument";
import { NewSelect } from "../../../utils/newInput";
import TooltipModal from "../../InvoiceDiscounting/components/Tooltipmodal";
import FinanceInvoiceModal from "../../InvoiceDiscounting/contract/components/financeinvoiceModal";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const EmojiData = [
  {
    image: "/assets/images/angry.png",
    fillimage: "/assets/images/angry-fill.png",
    name: "Angry",
    rating: 1
  },
  {
    image: "/assets/images/Not satisfied.png",
    fillimage: "/assets/images/notsatisfed-fill.png",
    name: "Not satisfied",
    rating: 2
  },
  {
    image: "/assets/images/ok.png",
    fillimage: "/assets/images/ok-fill.png",
    name: "ok",
    rating: 3
  }, {
    image: "/assets/images/Satisfied.png",
    fillimage: "/assets/images/satisfied-fill.png",
    name: "Satisfied",
    rating: 4
  }, {
    image: "/assets/images/excellent.png",
    fillimage: "/assets/images/excellent-fill.png",
    name: "excellent",
    rating: 5
  },
];

const ApprovedFinance = ({ userTokenDetails, navToggleState }) => {

  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)
  const [tableData, setTableData] = useState([])
  const [page, setPage] = useState(1)

  const [showAddBuyerForm, toggleAddBuyerForm] = useState(false)
  const [tab, setTab] = useState(0)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10, search: serarchParam ? serarchParam : '' })

  const [limitinvoice, setLimitinvoice] = useState({ show: false, data: [] });
  const [documents, setDocuments] = useState(false);
  const [signdoc, setSigndoc] = useState(false);
  const [tooltip, setTooltip] = useState(false);

  const [experience, setExperience] = useState({ show: false, data: {} });
  const [emoji, setEmoji] = useState("");
  const [applicationCounts, setApplicationCounts] = useState({})

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  useEffect(() => {
    loadApplicationCounts()
  }, [])

  async function loadApplicationCounts() {
    setshowLoader(true)
    let apiResp = await call("POST", 'getLCApprovedForFinanceApplicationCounts', { userId })
    setApplicationCounts({ ...apiResp })
    setshowLoader(false)
  }

  useEffect(() => {
    //Get Buyer list start
    let objectAPI = {
      userId,
      onlyApprovedFinance: true,
      currentPage: 1,
      ...filter
    }
    setshowLoader(true)
    call('POST', 'getLCQuoteApplications', objectAPI).then((result) => {
      console.log('running getLCQuoteApplications api-->', result);
      setshowLoader(false)
      setDbData(result.data);
      setTableData(formatDataForTable(result.data))
      setCount(result.totalCount);
    }).catch((e) => {
      // console.log('error in getLCQuoteApplications', e);
      setshowLoader(false)
    });
  }, [refresh])

  async function showDisbursmentSchedule(id) {
    setshowLoader(true)
    call('POST', 'getInvoiceDisbursementSchedule', { invRefNo: id }).then(async (result) => {
      // console.log('running getInvoiceDisbursementSchedule api-->', result);
      setshowLoader(false)
      if (result.length) {
        setLimitinvoice({ show: true, data: result })
      }
      else {
        toastDisplay("No schedule provided by financier", "info")
      }
    }).catch((e) => {
      // console.log('error in getInvoiceDisbursementSchedule', e);
      setshowLoader(false)
      toastDisplay('Something went wrong', "error")
    });
  }

  async function submitRating(index) {
    setExperience({ show: true, data: dbData[index] })
  }

  async function postRating() {
    setshowLoader(true)
    await call("POST", "postRatingToFinancier", {
      ratingFor: 'LC', applicationId: experience.data.id, lenderId: JSON.parse(experience.data.selectedQuote)["lender_id"],
      ratings: emoji, remarks: data.remarks, userId
    })
    toastDisplay("Feedback submitted successfully", "success")
    closeExpFn()
    setshowLoader(false)
  }


  function formatDataForTable(data) {
    let tableData = []
    let row = []
    data.forEach((item, index) => {
      row[0] = moment(item.updatedAt).format('DD/MM/YYYY')
      row[1] = item.buyerName
      row[2] = item.lcNo
      row[3] = LCTypesObject[item.lcType]
      row[4] = JSON.parse(item.selectedQuote)["lender_name"]
      // row[5] = <>
      //   {
      //     item.Timeleft === "Success" && <p
      //       class={`text-success mb-0 font-size-13`}>
      //       {item.days} days <img src={'/assets/images/hourglass-not-done.png'} alt='hourglass' />
      //     </p>
      //   }
      //   {
      //     item.Timeleft === "inprogress" && <p type="button"
      //       class={`text-warning border-0 font-size-14`}>
      //       {item.days} days <img src={'/assets/images/hourglass-not-done.png'} alt='hourglass' />
      //     </p>
      //   }
      //   {
      //     item.Timeleft === "failed" && <p type="button"
      //       class={`text-danger border-0 font-size-14`}>
      //       {item.days} days <img src={'/assets/images/hourglass-not-done.png'} alt='hourglass' />
      //     </p>
      //   }
      //   {
      //     item.Timeleft === "" && <p type="button"
      //       class={`text-dark border-0 font-size-14`}>
      //       -
      //     </p>
      //   }
      // </>
      if (item.financeStatus / 1 == 3 || item.financeStatus / 1 == 4) {
        row[99] = <p className="font-size-12 text-color-value ml-3"><img src={"assets/images/warning.png"} alt="info" className="" />
          <span className=" mx-2"><b>Disbursement scheduled </b></span>
          <label
            onClick={() => showDisbursmentSchedule(item.id)}
            className="text-color1 text-decoration-underline cursor">View Schedule</label> </p>
      }
      row[8] =
        <div>
          <div className='row ' >
            {item.financeStatus === 1 && <button type="button"
              className={`approved text-white border-0 `}>
              {"Approved"}
            </button>}
            {item.financeStatus === 2 && <button type="button"
              className={`rejected text-white border-0 `}>
              {"Rejected"}
            </button>}
            {item.financeStatus === 3 && <button type="button"
              className={`inprogress text-white border-0 `}>
              {"In-progress"}
            </button>}
            {item.financeStatus === 4 && <button type="button"
              className={`approved text-white border-0 `}>
              {"Disbursed"}
            </button>}
          </div>
        </div >
      // row[9] = <img src={"assets/images/eye.png"} alt='' />
      tableData.push(row)
      row = []
    })
    return tableData
  }

  const closeSuccess = () => {
    setLimitinvoice({ show: false, data: {} });
  }

  const closeExpFn = () => {
    setExperience({ show: false, data: {} });
    setEmoji("")
    setData({})
  }

  const handleChange = async (event) => {
    event.persist()
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }


  async function onView(index) {
    window.location = `LcFinancebtn?id=${dbData[index]["id"]}&view=true`
  }


  return (
    <>{showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="LcApprovedFinance" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"LC- Approved Finance"}
              userTokenDetails={userTokenDetails} />

            <div className='d-flex row justify-content-between px-2' >
              <div className='card p-4 border1Blue' style={{ width: '39.22%' }} >
                <label className='font-size-15 font-wt-600 color0C0C0C' >Total 12 weeks payment summary</label>
                <div className='d-flex row justify-content-between' >
                  <div className='w-auto' >
                    <p className='font-size-22 font-wt-600 text2ECC71 p-0 m-0' >{"$ " + (Intl.NumberFormat("en", { notation: 'compact' }).format(applicationCounts.received12Week || 0))}</p>
                    <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0'>Received</p>
                  </div>
                  <div className='w-auto' >
                    <p className='font-size-22 font-wt-600 text-color1 p-0 m-0' >{"$ " + (Intl.NumberFormat("en", { notation: 'compact' }).format(applicationCounts.due12Week || 0))}</p>
                    <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0'>Due</p>
                  </div>
                  <div className='w-auto' >
                    <p className='font-size-22 font-wt-600 text-danger p-0 m-0' >{"$ " + (Intl.NumberFormat("en", { notation: 'compact' }).format(applicationCounts.overdue12Week || 0))}</p>
                    <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0'>Overdue</p>
                  </div>
                </div>
              </div>
              <div
                className='card border1Blue pl-4 d-flex justify-content-center cursor' style={{ width: '28.44%' }} >
                <div>
                  <p className='font-size-22 font-wt-600 text2ECC71 p-0 m-0' >{"$ " + (Intl.NumberFormat("en", { notation: 'compact' }).format(applicationCounts.receivable || 0))}</p>
                  <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0 mt-3'>
                    <img src='assets/images/disbursed_icon.png' className='pb-1 mr-2' style={{ width: '30px', height: '30px' }} />
                    Receivable</p>
                </div>
              </div>
              <div
                className='card border1Blue pl-4 d-flex justify-content-center cursor' style={{ width: '28.44%' }} >
                <div>
                  <p className='font-size-22 font-wt-600 text-danger p-0 m-0' >{"$ " + (Intl.NumberFormat("en", { notation: 'compact' }).format(applicationCounts.overdue || 0))}</p>
                  <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0 mt-3'>
                    <img src='assets/images/overdue_icon2.png' className='pb-1 mr-2' style={{ width: '30px', height: '30px' }} />Over Due</p>
                </div>
              </div>
            </div>

            {!documents &&
              <><div>
                {/* <a className="cursor"><img src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a> */}
                <Filter filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} />
              </div>
                <NewTable
                  tableContainsNotification={true}
                  columns={[{ name: "Date", filter: true }, { name: "Buyer name", filter: true },
                  { name: "LC no.", filter: true },
                  { name: "LC type", filter: true },
                  // {
                  //   name: "Finance amount", filter: true
                  // },
                  {
                    name: "Financer", filter: true
                  },
                  // {
                  //   name: "TimeLeft", filter: true
                  // }, 
                  {
                    name: "Status", filter: true
                  }, { name: "", filter: false }]}
                  data={tableData}
                  options={
                    [
                      { name: "Give ratings", icon: "rating.png", onClick: (index) => submitRating(index) },
                      // { name: "Delete", icon: "delete.png", onClick: onDelete },
                      { name: "View Details", icon: "eye.png", onClick: onView }]}
                />
                <Pagination page={page} totalCount={count} onPageChange={(p) => setPage(p)} perPage={filter.resultPerPage || 0} />

                {/* <div>
                  <p className="font-size-14"><img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className="font-size-13 text-secondary">Requested additional details </span> <a onClick={() => setDocuments(true)} className="text-color1 text-decoration-underline cursor">View</a> </p>

                  <div>
                    <p className="font-size-14"><img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className="font-size-13 text-secondary">Disbursement scheduled </span> <a onClick={() => setLimitinvoice(true)} className="text-color1 text-decoration-underline cursor">View Schedule</a> </p>
                  </div>
                  <div>
                    <p className="font-size-14"><img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className="font-size-13 text-secondary"> </span> <a onClick={() => setLimitinvoice(true)} className="text-color1 text-decoration-underline cursor">View next disbursement</a> </p>
                  </div>

                </div> */}
              </>
            }
            {/* {documents &&
              <>
                <a className="cursor" onClick={() => setDocuments(false)}><img src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
                <div className="card p-5 mt-4">
                  <p className="text-center ">Additional documents requested</p>
                  <label className='font-wt-500 font-size-14'><u>Drip</u></label>

                  <div className="col-md-8 mb-3 px-0 mt-2">
                    <label className="font-size-14">Invoice <img className="cursor"
                      onClick={() => setTooltip(true)}
                      src={"assets/images/warning.png"} alt="" /></label>
                    <div className="row align-items-center ">
                      <div className="form-group col-md-8 mb-0 ">
                        <FileInput name={"poDocument"} value={data.poDocument} error={errors.poDocument}
                          onChange={handleFile} isEditable={false}
                        />
                      </div>
                      <div className="col-md-4 text-end">
                        <a onClick={() => setSigndoc(true)} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8 mb-3 px-0 mt-1">
                    <div className="form-group col-md-8 mb-0 ps-0 pe-1">
                      <NewSelect isAstrix={false} label={"Select bank"}
                        selectData={dbData} name={"Selectbank"}
                        value={data.selectedBuyer} optionLabel={"buyerName"} optionValue={'id'}
                        onChange={handleChange} />
                    </div>
                  </div>
                  <label className='font-wt-500 font-size-14'><u>Stenn</u></label>
                  <div className="col-md-8 mb-3 px-0 mt-2">
                    <label className="font-size-14">Invoice <img className="cursor"
                      onClick={() => setTooltip(true)}
                      src={"assets/images/warning.png"} alt="" /></label>
                    <div className="row align-items-center ">
                      <div className="form-group col-md-8 mb-0 ">
                        <FileInput name={"poDocument"} value={data.poDocument} error={errors.poDocument}
                          onChange={handleFile} isEditable={false}
                        />
                      </div>
                      <div className="col-md-4 text-end">
                        <a onClick={() => setSigndoc(true)} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                      </div>
                    </div>
                  </div>
                  <button onClick={() => setLimitinvoice(true)} type="button" className="new-btn w-25 py-2 text-white cursor mt-4" >
                    Send documents
                  </button>
                </div>
              </>} */}
          </main>
        </div>
      </div>
      {limitinvoice.show && <FinanceInvoiceModal limitinvoice={limitinvoice} closeSuccess={closeSuccess} >
        <p>Disbursement schedule</p>
        <table
          className='details text-center mx-5'>
          <tr className='th p-1 d-flex justify-content-around'>
            <th className='heading text-secondary' scope="col">
              Date
            </th>
            <th className='heading text-secondary' scope="col">
              Amount
            </th>
            <th className='heading text-secondary' scope="col">
              Status
            </th>
          </tr>

          {limitinvoice.data.map((item, index) => {
            return (
              <tr className={`th bg-white rounded-0 p-0`}
              >
                <td className='heading width33'>
                  {moment(item.scheduledOn).format('DD/MM/YYYY')}
                </td>
                <td className='heading width33' >
                  {item.currency + " " + item.amount}
                </td>
                <td className='heading width33'>
                  {item.status ? (
                    <img src={"/assets/images/upload_done.png"} alt="Yes" height={16} width={17} />
                  ) : (
                    <img src={"/assets/images/processing.png"} alt="" height={16} width={17} />)}
                </td>
              </tr>

            )
          })}

        </table>
      </FinanceInvoiceModal>}
      {experience.show && <FinanceInvoiceModal limitinvoice={experience} closeSuccess={closeExpFn}>
        <p>How was your experience with <span className="text-custom">{JSON.parse(experience.data.selectedQuote)["lender_name"]}</span> ?</p>
        <div className="d-flex gap-3 justify-content-center">
          {EmojiData.map((key, index) => {

            return (
              <>
                <img src={emoji === key.rating ? key.fillimage : key.image} alt="angry" key={index} className="cursor " onClick={() => setEmoji(key.rating)} />
              </>
            );
          })}

        </div>

        {emoji !== "" && < div className="my-3 col-9 mx-auto text-end border px-0 send p-2">
          <textarea name={"remarks"} value={data.remarks} onChange={handleChange} className="form-control border-0 bg-transparent" id="exampleFormControlTextarea1" rows="3" placeholder="Write a review..."></textarea>
          <img className="cursor px-1" src={"/assets/images/Send.png"} alt="" onClick={() => postRating()} />
        </div>}
      </FinanceInvoiceModal >}
    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(ApprovedFinance)