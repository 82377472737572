import React from 'react';
import { connect } from 'react-redux';
import Header from '../partial/header';
import { setInvoiceDetail } from '../../store/actions/action';
import InvoiceView from './invoiceViewComp';
import InvoiceGen from './invoiceGenerateComp';
import InvoiceGenShipment from './invoiceGenerateCompShipment';
import HeaderV2 from '../partial/headerV2';
import SideBarV2 from '../partial/sideBarV2';


const Invoice = ({ userTokenDetails, planData, setInvoiceDetail, InvoiceDetail, dispatch, navToggleState }) => {

  const operationType = InvoiceDetail.info.operation
  const invoiceType = InvoiceDetail.info.invoiceType
  // const [showLoader, setshowLoader] = useState(false);

  console.log("invoiceType=======>", invoiceType)

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="workOrderContracts" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={'Contract Invoice ' + operationType}
              userTokenDetails={userTokenDetails} />

            <a style={{ top: '4.2rem', zIndex: 2 }} className="cursor position-absolute"
              onClick={() => { setInvoiceDetail(false, {}); }}
            ><img className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>

            {/* {showLoader && <div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>} */}
            {operationType === "View" ?
              <InvoiceView userTokenDetails={userTokenDetails} /> :
              (invoiceType === "contract" ? <InvoiceGen userTokenDetails={userTokenDetails} planData={planData} /> : <InvoiceGenShipment userTokenDetails={userTokenDetails} />)
            }
          </main>
        </div>
      </div>

    </>
  )
};

const mapStateToProps = state => {
  return {
    InvoiceDetail: state.InvoiceDetail
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setInvoiceDetail: (flag, data) => { dispatch(setInvoiceDetail({ modal: flag, info: data })) }
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Invoice)
