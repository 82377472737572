import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { ToastContainer } from 'react-toastify';
import call from '../../../service';
import { platformBackendUrl } from '../../../urlConstants';
import { FileInput } from '../../../utils/FileInput';
import { convertImageToPdf, decryptData, GetCache, getDocDetails, getProgressPercentage, isEmpty, most_used_currencies, SetCache } from '../../../utils/myFunctions';
import { InputWithSelect, NewInput, NewSelect } from '../../../utils/newInput';
import { NewTable } from '../../../utils/newTable';
import toastDisplay from '../../../utils/toastNotification';
import Filter from '../../InvoiceDiscounting/components/Filter';
import Pagination from '../../InvoiceDiscounting/contract/components/pagination';
import HeaderV2 from '../../partial/headerV2';
import SideBarV2 from '../../partial/sideBarV2';
import TaskManager from '../../TaskManager/TaskManager';
import InputEdit from '../../viewProfile/components/inputEdit';
import FinanceInvoiceModal from '../../InvoiceDiscounting/contract/components/financeinvoiceModal';

const subadminUserPermissions = [
  { name: 'Exporter' },
  { name: 'Financier' },
  { name: 'Importer' },
  { name: 'Channel Partner' },
  { name: 'Assign Task' }
]

const userDetailLabels = [
  { name: 'Contact No', alt1: 'phone_code', alt2: "contact_number" },
  { name: 'Address', alt1: "user_address" },
  { name: 'Email Id', alt1: "email_id" },
  { name: 'City, State', alt1: 'company_city', alt2: "company_state" },
  { name: 'Postal Code', alt1: "company_postal_code" },
  { name: 'Designation', alt1: "designation" }
]

const SubAdminProfileDetails = ({ userTokenDetails, navToggleState }) => {

  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')

  let history = useHistory()

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [tableData, setTableData] = useState([])
  const [showLoader, setshowLoader] = useState(false)
  const [filter, setFilter] = useState({ resultPerPage: 10 })
  const [filterData, setFilterData] = useState({})
  const [refresh, setRefresh] = useState(0)
  const [showAddNewUserPopup, toggleAddNewUserPopup] = useState(false)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [countryData, setcountryData] = useState([])
  const [viewDetails, setViewDetails] = useState({
    type: '',
    isVisible: false,
    data: {}
  })

  const [overalldata, setoveralldata] = useState([])
  const [leadStatusIndex, setLeadStatusIndex] = useState()
  const [applicationStatus, setApplicationStatus] = useState()
  const [addTasksCountPopup, setaddTasksCountPopup] = useState(false)
  const [defaultDailyTasks, setdefaultDailyTasks] = useState('')
  const [isAdminOnLeave, setisAdminOnLeave] = useState(false)
  const [showDeletePopup, setShowDeletePopup] = useState(false)
  const [showTransferScreen, setShowTransferScreen] = useState(false)
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;
  const subAdminDetails = JSON.parse(localStorage.getItem("subAdminDetails"))
  const userPermissionsForSubAdmin = JSON.parse(userTokenDetails.UserAccessPermission || "{}")
  let onlyShowForUserId = userPermissionsForSubAdmin?.mainAdmin ? undefined : userId
  const [salesPerson, setSalesPerson] = useState([])
  const [goalspopup, setGoalsPopup] = useState(false)
  const [goalsProgress, setGoalsProgress] = useState({
    onboarding: [0, 0, 0, 0],
    buyers: [0, 0, 0, 0],
    invoice_disbursement: [0, 0, 0, 0],
    lc_disbursement: [0, 0, 0, 0]
  })
  const [datas, setDatas] = useState({})
  const deleteSubAdmin = () => {
    let reqObj = {
      deleteUserId: subAdminDetails.tbl_user_id,
      AssignTo: data.selectedUser
    }
    setshowLoader(true)
    console.log('Delete', reqObj, subAdminDetails);
    call('POST', 'deleteSubAdmin', reqObj).then(result => {
      toastDisplay(result, "success")
      setshowLoader(false)
      history.go(-1)
    }).catch(e => {
      toastDisplay(e, "error")
      setshowLoader(false)
    })
  }
  useEffect(() => {
    setshowLoader(true)
    call('POST', 'getMonthlyGoals', { userId: subAdminDetails?.tbl_user_id }).then(result => {
      setDatas({
        ...datas,
        ...result
      })
      setshowLoader(false)

    }).catch(e => {
      setshowLoader(false)

    })
    call('POST', 'getMonthlyGoalsProgress', { userId: subAdminDetails?.tbl_user_id }).then(result => {
      setGoalsProgress({
        onboarding: getProgressPercentage(result.userOnboardPer),
        buyers: getProgressPercentage(result.buyerAddedPer),
        invoice_disbursement: getProgressPercentage(result.lcPer),
        lc_disbursement: getProgressPercentage(result.invPer)
      })
      setshowLoader(false)

    }).catch(e => {
      setshowLoader(false)

    })
  }, [])

  console.log('dataaaaaaaaaaaaaaaaaa', goalsProgress);
  const addMonthlyGoals = () => {
    let error = {}
    if (!datas.onboarding_goal) {
      error.onboarding_goal = 'Mandatory Field'
    }
    if (!datas.buyeradded_goal) {
      error.buyeradded_goal = 'Mandatory Field'
    }
    if (!datas.invDis_goal) {
      error.invDis_goal = 'Mandatory Field'
    }
    if (!datas.lcDis_goal) {
      error.lcDis_goal = 'Mandatory Field'
    }
    if (!datas.lc_currency) {
      error.lcDis_goal = 'Mandatory Field'
    }
    if (!datas.inv_currency) {
      error.invDis_goal = 'Mandatory Field'
    }
    if (isEmpty(error)) {
      //add api
      setshowLoader(true)
      let reqObj = {
        onboarding_goal: datas.onboarding_goal,
        buyeradded_goal: datas.buyeradded_goal,
        invDis_goal: datas.invDis_goal,
        lcDis_goal: datas.lcDis_goal,
        lc_currency: datas.lc_currency,
        inv_currency: datas.inv_currency,
        userId: subAdminDetails?.tbl_user_id
      }
      call('POST', 'addMonthlyGoals', reqObj).then(result => {
        toastDisplay(result, "success")
        setshowLoader(false)
        setGoalsPopup(false)
      }).catch(e => {
        setshowLoader(false)
        toastDisplay(e, "error")
      })
    } else {
      setErrors(error)
    }
  }
  useEffect(() => {
    // let isCacheExist = localStorage.getItem('taskManagerFilterData') != "{}"
    // if (!isCacheExist) {
    let objectAPI = {
      onlyShowForUserId: subAdminDetails?.tbl_user_id
    }
    call('POST', 'getTasksForAdminFilters', objectAPI).then(res => {
      console.log("getTasksForAdminFilters then", res);
      setFilterData(res)
    }).catch(err => { })
    // }
  }, [])

  async function asyncUseEffectFunction() {
    setshowLoader(true)
    let kycDocs = {}
    let tempDocsId = subAdminDetails?.kycDocs?.split(",") || []
    let docResp = await getDocDetails(tempDocsId[0])
    kycDocs[docResp?.gen_doc_label] = docResp
    docResp = await getDocDetails(tempDocsId[1])
    kycDocs[docResp?.gen_doc_label] = docResp
    setData(kycDocs)
    setshowLoader(false)
  }

  useEffect(() => {
    asyncUseEffectFunction()
  }, [])

  const handleChange = async (event) => {
    if (event.persist) {
      event.persist()
    }
    setDatas({ ...data, [event.target.name]: event.target.value })
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }

  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }

      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (!file_type.includes("pdf")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  const assignDailyDefaultTasks = () => {
    setshowLoader(true)
    let reqObj = {
      defaultTasksCount: defaultDailyTasks,
      userId: subAdminDetails?.tbl_user_id
    }
    call('POST', 'assignDailyDefaultTasks', reqObj).then(result => {
      setshowLoader(false)
      toastDisplay(result, "success")
    }).catch(e => {
      setshowLoader(false)

    })
  }
  const getDailyDefaultTasks = () => {
    setshowLoader(true)
    let reqObj = {
      userId: subAdminDetails?.tbl_user_id
    }
    call('POST', 'getDailyDefaultTasks', reqObj).then(result => {
      setshowLoader(false)
      setdefaultDailyTasks(result?.defaultTasksCount || 0)
    }).catch(e => {
      setshowLoader(false)
    })
  }
  useEffect(() => {
    getDailyDefaultTasks()
  }, [])

  const InsertadditionalTasksCount = () => {
    let errors = {}
    if (!data.taskCountStart) {
      errors.taskCountStart = "Mandatory Field"
    }
    if (!data.taskCountEnd) {
      errors.taskCountEnd = "Mandatory Field"
    }
    if (!isAdminOnLeave) {
      if (!data.tasksCount) {
        errors.tasksCount = "Mandatory Field"
      }
    }

    if (isEmpty(errors)) {
      setshowLoader(true)
      let taskCount = data.tasksCount
      if (isAdminOnLeave) {
        taskCount = 0
      }
      let additionalTasksCount = {}
      additionalTasksCount[`${moment(data.taskCountStart).format('DD-MM-YYYY')}-${moment(data.taskCountEnd).format('DD-MM-YYYY')}`] = taskCount
      let reqObj = {
        userId: subAdminDetails?.tbl_user_id,
        additionalTasksCount
      }
      call('POST', 'InsertadditionalTasksCount', reqObj).then(result => {
        toastDisplay(result, "success")
        setshowLoader(false)
        setaddTasksCountPopup(false)
      }).catch(e => {
        setshowLoader(false)
      })
    } else {
      setErrors(errors)
    }

  }
  useEffect(() => {
    if (userPermissionsForSubAdmin.mainAdmin || userPermissionsForSubAdmin?.["Assign Task"]) {
      setshowLoader(true)
      call("POST", 'getSubAdminUser', {}).then(res => {
        setshowLoader(false)
        setSalesPerson(res.data)
      }).catch(err => setshowLoader(false))
    } else {
      let onlyUserId = userTokenDetails?.sub_user_id
      setshowLoader(true)
      call("POST", 'getSubAdminUser', { onlyUserId }).then(res => {
        setshowLoader(false)
        setSalesPerson(res.data)
      }).catch(err => setshowLoader(false))
    }
  }, [])
  console.log('showTransferScreen', showTransferScreen);
  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      {addTasksCountPopup &&
        <FinanceInvoiceModal limitinvoice={addTasksCountPopup} closeSuccess={() => setaddTasksCountPopup(false)} isCentered={true} headerTitle={"Add Daily Customized Task"}>
          <div>
            <div className='d-flex align-items-center ml-4 my-2'>
              <div className='pr-3'>
                <label className='w-100 text-left font-size-14 font-wt-600'>From</label>
                <NewInput type={"date"} name={"taskCountStart"} onChange={handleChange} value={data.taskCountStart} error={errors.taskCountStart}
                />
              </div>
              <div className='pr-3'>
                <label className='w-100 text-left font-size-14 font-wt-600'>To</label>
                <NewInput type={"date"} name={"taskCountEnd"} error={errors.taskCountEnd} value={data.taskCountEnd} onChange={handleChange} />
              </div>
            </div>
            <div className='ml-4 w-30 my-2'>
              <div className="col-md-12 px-0">
                <div className="col-md-10 px-0">
                  <NewInput name={"tasksCount"} error={errors.tasksCount} isDisabled={isAdminOnLeave} type={"number"} label={"No. of tasks"} value={isAdminOnLeave ? 0 : data.tasksCount} onChange={handleChange} />
                </div>
              </div>
            </div>
            <div className='ml-4 w-40 my-2 d-flex gap-2 align-items-center' onClick={() => {
              setisAdminOnLeave(!isAdminOnLeave)
            }}>
              <img src={`assets/images/${isAdminOnLeave ? 'checked-green' : 'unchecked-box'}.png`} />
              <label className='w-100 text-left font-size-14 font-wt-500 mb-0'>Admin On Leave</label>
            </div>
            <div className="d-flex gap-4">
              <button className={`my-2 new-btn text-white w-17 py-2 px-2 ml-4 cursor`} onClick={InsertadditionalTasksCount}>Add Task</button>
            </div>
          </div>
        </FinanceInvoiceModal>
      }
      {showDeletePopup &&
        <FinanceInvoiceModal modalSize={"sm"} limitinvoice={showDeletePopup} closeSuccess={() => {
          setShowDeletePopup(false)
          setShowTransferScreen(false)
        }} isCentered={true} headerTitle={""}>
          <div>
            {!showTransferScreen ?
              <div>
                <div>
                  <label>{`Are You sure you want to delete` + '"'}<span className='font-wt-600 text-color1'>{subAdminDetails.contact_person + `'s`}</span>{`" Account?`}</label>
                </div>
                <div className="d-flex gap-4">
                  <button className={`my-2 new-btn2 text-color1 w-50 py-2 px-2 cursor`} onClick={() => { setShowTransferScreen(true) }}>Yes</button>
                  <button className={`my-2 new-btn text-white w-50 py-2 px-2 cursor`} onClick={() => { setShowDeletePopup(false) }}>No</button>
                </div>
              </div>
              :
              <>
                <div>
                  <label>{`Transfer` + '"'}<span className='font-wt-600 text-color1'>{subAdminDetails.contact_person + `'s`}</span>{`" data to`}</label>
                </div>
                <div className="col-md-12">
                  <NewSelect
                    selectData={salesPerson}
                    optionLabel={'contact_person'} optionValue={'id'}
                    onChange={handleChange}
                    removeMb={true}
                    name={"selectedUser"}
                    value={data.selectedUser}
                  />
                </div>
                <div className="d-flex gap-4 justify-content-center mt-2">
                  <button className={`my-2 new-btn text-white w-60 py-2 px-2 cursor`} onClick={deleteSubAdmin}>Transfer data</button>
                </div>
              </>
            }

          </div>
        </FinanceInvoiceModal>
      }
      {goalspopup &&
        <FinanceInvoiceModal modalSize={"sm"} limitinvoice={goalspopup} closeSuccess={() => {
          setGoalsPopup(false)
        }} isCentered={true} headerTitle={""}>
          <div>
            <>
              <div className='mb-4'>
                <label className='font-size-14 font-wt-500'>Set Monthly Goals</label>
              </div>
              <div className="col-md-12">
                <NewInput
                  onChange={handleChange}
                  name={"onboarding_goal"}
                  value={datas.onboarding_goal || 0}
                  label={"Onboarding"}
                  error={errors.onboarding_goal}
                />
              </div>
              <div className="col-md-12">
                <NewInput
                  onChange={handleChange}
                  name={"buyeradded_goal"}
                  value={datas.buyeradded_goal || 0}
                  label={"Buyer Added"}
                  error={errors.buyeradded_goal}

                />
              </div>
              <div className="col-md-12">
                <InputWithSelect
                  selectData={most_used_currencies} selectName={"inv_currency"} selectValue={datas.inv_currency} optionLabel={"code"}
                  optionValue={'code'}
                  type="number" name={"invDis_goal"} value={datas.invDis_goal || 0}
                  onChange={handleChange}
                  label={"Invoice  disbursement"} error={errors.invDis_goal} />
              </div>
              <div className="col-md-12">
                <InputWithSelect
                  selectData={most_used_currencies} selectName={"lc_currency"} selectValue={datas.lc_currency} optionLabel={"code"}
                  optionValue={'code'}
                  type="number" name={"lcDis_goal"} value={datas.lcDis_goal || 0}
                  onChange={handleChange}
                  label={"LC disbursement"} error={errors.lcDis_goal} />
              </div>
              <div className="d-flex gap-4 justify-content-center mt-2">
                <button className={`my-2 new-btn text-white w-60 py-2 px-2 cursor`} onClick={addMonthlyGoals}>Add Goals</button>
              </div>
            </>
          </div>
        </FinanceInvoiceModal>
      }
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="SubAdminProfileDetails" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={subAdminDetails.contact_person}
              userTokenDetails={userTokenDetails} />
            <div className='d-flex gap-3 align-items-center'>
              <a className="cursor"
                onClick={() => { history.go(-1) }}
              ><img className="mb-0" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
              <div className='d-flex flex-column'>
                <label className='font-size-14 font-wt-600'>{subAdminDetails.contact_person}</label>
                <label className='font-size-14 font-wt-600 text-color1'>{subAdminDetails.email_id}</label>
              </div>
              {!onlyShowForUserId &&
                <div className='d-flex gap-4'>
                  <img src='assets/images/edit.png' className='cursor' />
                  <img src='assets/images/deleteIcon.png' className='cursor' onClick={() => setShowDeletePopup(true)} />
                </div>
              }
              <div className='card p-2 dashboard-card shadow-sm w-26'>
                <div className='d-flex gap-2 align-items-center'>
                  <label className='font-wt-600 font-size-14 mb-0 rounded'>default daily number of tasks:</label>
                  <input value={defaultDailyTasks} type='number' class="form-control border-0 w-17 p-0 text-color1 font-wt-600" onChange={(e) => {
                    setdefaultDailyTasks(e.target.value)
                  }} onKeyDown={(event) => {
                    event.persist();
                    if (event.keyCode === 13) {
                      assignDailyDefaultTasks()
                    }
                  }} />
                  <img src={"/assets/images/bytesize_edit.png"} alt="" />
                </div>
              </div>
              <img className="mb-0" src={"assets/images/add_more.svg"} onClick={() => setaddTasksCountPopup(true)} />
              {/* <a className="cursor"
              ><img className="mb-0" src={"assets/images/add more-bg-white.png"} alt="Back" height={15} width={15} /></a> */}
            </div>


            <div className='mt-4' style={{}} >

              <div className='row'>

                <div className='w-20'>
                  <div className='card h-75 dashboard-card shadow-sm align-items-center justify-content-center'>
                    <label className={`text-custom2 font-wt-600 font-size-20  w-100 text-center`}>
                      {`$ ${Intl.NumberFormat("en", { notation: 'compact' }).format(subAdminDetails["totalDisbursedAmount"])}`}
                    </label>
                    <label className='font-size-16 text-color-value font-wt-600 pt-2'>{"Disbursement"}</label>
                  </div>
                </div>

                <div className='w-20'>
                  <div className='card h-75 dashboard-card shadow-sm align-items-center justify-content-center'>
                    <label className='font-size-16 text-color-value font-wt-600 pt-2'>{"Leads Assigned"}
                      <label className='font-size-16 text-color-value font-wt-600 text-custom2'>{` - ${subAdminDetails["totalLeadAssigned"]}`}</label></label>
                    <div className='row px-0 w-100'>
                      <div className='w-50 cursor'
                        onClick={() => {
                          setLeadStatusIndex(0)
                          // let temp = filterData
                          // temp["Lead Status"]["data"][0]["isChecked"] = true
                          // temp["Lead Status"]["data"][1]["isChecked"] = false
                          // temp["Lead Status"]["isFilterActive"] = true
                          // setFilterData({ ...temp })
                        }}>
                        <label className={`value font-wt-600 text-48DA87  w-100 cursor`}>
                          {`${subAdminDetails.totalActiveLeadAssigned}`}
                        </label>
                        <label className={'font-size-14 font-wt-600 text-color-value cursor'}>{"Active"}</label>
                      </div>

                      <div className='w-50 cursor'
                        onClick={() => {
                          setLeadStatusIndex(1)
                          // let temp = filterData
                          // temp["Lead Status"]["data"][0]["isChecked"] = false
                          // temp["Lead Status"]["data"][1]["isChecked"] = true
                          // temp["Lead Status"]["isFilterActive"] = true
                          // setFilterData({ ...temp })
                        }}>
                        <label className={`value font-wt-600 colorFF7B6D w-100 cursor`}>
                          {`${subAdminDetails.totalInactiveLeadAssigned}`}
                        </label>
                        <label className={'font-size-14 font-wt-600 text-color-value cursor'}>{"Inactive"}</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='w-30'>
                  <div className='card h-75 dashboard-card shadow-sm align-items-center justify-content-center'>
                    <label className='font-size-16 text-color-value font-wt-600 pt-2'>{"Finance Application"}
                      <label className='font-size-16 text-color-value font-wt-600 text-custom2'>{` - ${subAdminDetails["totalFinanceApplication"] || 0}`}</label></label>
                    <div className='row px-0 w-100'>
                      <div className='w-33 cursor'
                        onClick={() => {
                          setApplicationStatus(0)
                          // let temp = filterData
                          // temp["Application Status"]["data"][0]["isChecked"] = true
                          // temp["Application Status"]["data"][1]["isChecked"] = false
                          // temp["Application Status"]["data"][2]["isChecked"] = false
                          // temp["Application Status"]["isFilterActive"] = true
                          // setFilterData({ ...temp })
                        }}>
                        <label className={`value font-wt-600 text-custom2  w-100 cursor`}>
                          {`${subAdminDetails.totalOngoingFinanceApplication || 0}`}
                        </label>
                        <label className={'font-size-14 font-wt-600 text-color-value cursor'}>{"Ongoing"}</label>
                      </div>

                      <div className='w-33 cursor'
                        onClick={() => {
                          setApplicationStatus(1)
                          // let temp = filterData
                          // temp["Application Status"]["data"][0]["isChecked"] = false
                          // temp["Application Status"]["data"][1]["isChecked"] = true
                          // temp["Application Status"]["data"][2]["isChecked"] = false
                          // temp["Application Status"]["isFilterActive"] = true
                          // setFilterData({ ...temp })
                        }}>
                        <label className={`value font-wt-600 text-48DA87  w-100 cursor`}>
                          {`${subAdminDetails.totalApprovedFinanceApplication || 0}`}
                        </label>
                        <label className={'font-size-14 font-wt-600 text-color-value cursor'}>{"Approved"}</label>
                      </div>

                      <div className='w-33'
                        onClick={() => {
                          setApplicationStatus(2)
                          // let temp = filterData
                          // temp["Application Status"]["data"][0]["isChecked"] = false
                          // temp["Application Status"]["data"][1]["isChecked"] = false
                          // temp["Application Status"]["data"][2]["isChecked"] = true
                          // temp["Application Status"]["isFilterActive"] = true
                          // setFilterData({ ...temp })
                        }}>
                        <label className={`value font-wt-600 colorFF7B6D w-100 cursor`}>
                          {`${subAdminDetails.totalRejectedFinanceApplication || 0}`}
                        </label>
                        <label className={'font-size-14 font-wt-600 text-color-value cursor'}>{"Rejected"}</label>
                      </div>
                    </div>
                  </div>
                </div>


              </div>

              <div className='card dashboard-card'>
                <div className='d-flex gap-2 align-items-center'>
                  <label className='font-size-16 font-wt-500 text-decoration-underline mb-0'>Monthly goals</label>
                  <img src='assets/images/edit.png' className='cursor' height={20} width={20} onClick={() => setGoalsPopup(true)} />

                </div>
                <div className='mt-2 py-2'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <label className='font-size-14 font-wt-50 mb-0'>Onboarding</label>
                    <label className='font-size-14 font-wt-50 mb-0'>{datas.onboarding_goal || 0}</label>
                  </div>
                  <div className='outerDiv d-flex gap-2 align-items-center'>
                    {goalsProgress.onboarding.filter(item => item != 0).map((item) => {
                      return <div className='innerDiv' style={{ width: `${item}%` }} ></div>
                    })}


                  </div>
                </div>
                <div className='mt-2 py-2'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <label className='font-size-14 font-wt-50 mb-0'>Buyer Added</label>
                    <label className='font-size-14 font-wt-50 mb-0'>{datas.buyeradded_goal || 0}</label>
                  </div>
                  <div className='outerDiv d-flex gap-2 align-items-center'>
                    {goalsProgress.buyers.filter(item => item != 0).map((item) => {
                      return <div className='innerDiv' style={{ width: `${item}%` }} ></div>
                    })}

                  </div>
                </div>
                <div className='mt-2 py-2'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <label className='font-size-14 font-wt-50 mb-0'>Invoice discounting disbursement</label>
                    <label className='font-size-14 font-wt-50 mb-0'>{`${datas.inv_currency ? datas.inv_currency : ''} ${datas.invDis_goal ? Intl.NumberFormat('en-US', { notation: 'compact' }).format(datas.invDis_goal) : ''}`}</label>
                  </div>
                  <div className='outerDiv d-flex gap-2 align-items-center'>
                    {goalsProgress.invoice_disbursement.filter(item => item != 0).map((item) => {
                      return <div className='innerDiv' style={{ width: `${item}%` }} ></div>
                    })}

                  </div>
                </div>
                <div className='mt-2 py-2'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <label className='font-size-14 font-wt-50 mb-0'>LC discounting disbursement</label>
                    <label className='font-size-14 font-wt-50 mb-0'>{`${datas.lc_currency ? datas.lc_currency : ''} ${datas.lcDis_goal ? Intl.NumberFormat('en-US', { notation: 'compact' }).format(datas.lcDis_goal) : ''}`}</label>
                  </div>
                  <div className='outerDiv d-flex gap-2 align-items-center'>
                    {goalsProgress.lc_disbursement.filter(item => item != 0).map((item) => {
                      return <div className='innerDiv' style={{ width: `${item}%` }} ></div>
                    })}

                  </div>
                </div>
              </div>

              <div className='card dashboard-card mt-5'>
                <div className='d-flex row'>
                  {userDetailLabels.map(j => {
                    return (
                      <div className='w-50 row'>
                        <div className='w-30'>
                          <label className='font-size-14 font-wt-600 text-color-label '>{j.name}:</label>
                        </div>
                        <div className='w-70'>
                          <label className='font-size-14 font-wt-600 text-color-label '>{(subAdminDetails[j.alt1] || "NA")}{(subAdminDetails[j.alt2] || "")}</label>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>

              <div className='card dashboard-card mt-5'>
                <div className='d-flex row'>
                  <label className='font-size-14 font-wt-600 text-color-label text-decoration-underline '>Documents</label>
                  <div className='w-40'>
                    <p className='font-size-14 mt-3'>Aadhar Document</p>
                    <div className="col-md-12">
                      <div className="row form-group">
                        <div className="col-md-11">
                          <FileInput name={"aadharDocument"} value={data.aadharDocument} error={errors.aadharDocument}
                            onChange={handleFile} isEditable={false} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-40'>
                    <p className='font-size-14 mt-3'>PAN Document</p>
                    <div className="col-md-12">
                      <div className="row form-group">
                        <div className="col-md-11">
                          <FileInput name={"panDocument"} value={data.panDocument} error={errors.panDocument}
                            isEditable={false} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=''>
              <TaskManager
                leadStatusIndex={leadStatusIndex} applicationStatusIndex={applicationStatus}
                showForThisUser={subAdminDetails.tbl_user_id} hideGraphs={true} userTokenDetails={userTokenDetails} renderAsChildren={true} />
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(SubAdminProfileDetails)