import React from 'react'
import { useEffect } from 'react';

const FailedPayment = () => {
  useEffect(() => {
    setTimeout(() => {
      window.location = '/wallet'
    }, 1000);
  }, [])
  return (
    <div>
      <h1 className='text-center'>Payment Failed</h1>
    </div>
  )
}

export default FailedPayment