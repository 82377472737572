import React, { useState } from 'react'
import { useEffect } from 'react'
import call from '../../service'
import MultipleSelect from '../../utils/MultipleSelect'
import { ClearCache, GetCache, getDocDetails, getInitials, most_used_currencies, removeDuplicates, SetCache } from '../../utils/myFunctions'
import { InputWithSelect, NewInput, NewSelect } from '../../utils/newInput'
import { NewTable } from '../../utils/newTable'
import toastDisplay from '../../utils/toastNotification'
import Filter from '../InvoiceDiscounting/components/Filter'
import Pagination from '../InvoiceDiscounting/contract/components/pagination'
import config from '../../config.json';
import PieChartComponent from '../Reports/components/PieChartComponent'
import moment from 'moment'
import ViewBuyerDetails from '../myCounterPartComp/viewBuyerDetails'
import { ToastContainer } from 'react-toastify'

const reviewForm = [
  { "name": "Buyer Name", val: "buyerName" },
  { "name": "Previous year annual sales", val: "buyerPrevSale", unit: "buyerCurrency" },
  { "name": "Contact Number:", val: "buyerContact", unit: "buyerPhoneCode" },
  { "name": "Expected current year’s annual sale", val: "buyerExpectedSale", unit: "buyerCurrency" },

  { "name": "Email Id", val: "buyerEmail" },
  { "name": "Inco Terms", val: "buyerIncoTerms" },
  { "name": "Website", val: "buyerWebsite" },
  { "name": "Terms of Payment", val: "buyerTermsPayment" },

  { "name": "Address", val: "buyerAddress" },
  { "name": "Product Details", val: "buyerProductDetails" },

  { "name": "Country", val: "buyerCountry" },
  { "name": "Postal Code", val: "buyerPostalCode" },
  { "name": "DUNS No", val: "buyerDunsNo" },
  { "name": "HSN Code", val: "buyerHsnCode" },
]

// const financierData = [
//   { id: 100, name: "Stenn", icon: "stenn.png", rating: 4.6, reviews: 126 },
//   { id: 200, name: "Modifi", icon: "modifi.png", rating: 1, reviews: 130 },
// ]

export const IncoTerms = [
  { name: "EXW" },
  { name: "FCA" },
  { name: "FAS" },
  { name: "FOB" },
  { name: "CFR" },
  { name: "CIF" },
  { name: "CPT" },
  { name: "CIP" },
  { name: "DAP" },
  { name: "DPU" },
  { name: "DDP" }
]

const addBuyerTabs = [
  { name: "Select Buyer" },
  { name: "DUNS No." },
  { name: "Buyer details" },
  { name: "Review" }
]


const invoiceLcStatusColor = ['#48DA87', '#FF7B6D', '#F1C40F']

const invoiceLcStatus = [
  { label: "Approved", color: '#48DA87' },
  { label: "Rejected", color: "#FF7B6D" },
  { label: "Inprocess", value: "#F1C40F" }

]

const ApplicationTab = ({ userTokenDetails, hideGraphs }) => {

  let todayDateObj = moment()
  let lastMonthDateObj = moment().subtract("1", "months")

  const [filterData, setfilterdata] = useState([])
  const [filter, setFilter] = useState({ resultPerPage: 10 })
  const [refresh, setrefresh] = useState(0)
  const [Count, setCount] = useState(0)
  const [page, setpage] = useState(1)
  const [dbData, setdbData] = useState([])
  const [showLoader, setshowLoader] = useState(false)
  const [addBuyer, setAddBuyer] = useState(false)
  const [stats, setStats] = useState({
    applicationStats: {}
  })
  const [graphConfiguration, setGraphConfiguration] = useState({
    applicationStageGraphMode: true,
    applicationStageFrom: lastMonthDateObj.clone().format("YYYY-MM-DD"),
    applicationStageTo: todayDateObj.clone().format("YYYY-MM-DD"),
  })
  const [viewDetails, setViewDetails] = useState({
    type: '',
    isVisible: false,
    data: {}
  })
  const [overalldata, setoveralldata] = useState([])
  const [transactionPopup, toggleTransactionPopup] = useState({ show: false, data: [] })
  const [graphData, setGraphData] = useState({})
  const [stageTable, setStageTable] = useState([])
  const [filteredSearch, setFilteredSearch] = useState([])

  const userId = userTokenDetails.id ? userTokenDetails.id : null


  useEffect(() => {
    getApplications()
  }, [refresh, page, filterData])
  useEffect(() => {
    call('POST', 'getApplicationsFilters', { userId }).then(res => {
      console.log("getApplicationsFilters then", res);
      setfilterdata(res)
      setFilteredSearch(res)
    }).catch(err => { })

  }, [])
  useEffect(() => {
    setshowLoader(true)
    call('POST', 'getApplicationStats', { userId }).then(result => {
      setStats(result)
      setshowLoader(false)
    }).catch(e => {
      setshowLoader(false)

    })
  }, [])
  useEffect(() => {
    setshowLoader(true)
    call('POST', 'getTransactionCounts', { ...graphConfiguration, userId }).then(result => {
      setGraphData(result)
      setshowLoader(false)
      let overalldata = []
      const lcdata = []
      lcdata.push("LC")
      lcdata.push(result["LC_Limit_Count"])
      lcdata.push(result["LC_Quotes_Count"])
      lcdata.push(result["LC_Contracts_Count"])
      lcdata.push(result["LC_Apply_Fin_Count"])
      lcdata.push(result["LC_Agreement"])
      lcdata.push(result["LC_Approved_Fin_Count"])
      overalldata.push(lcdata)

      let invdata = []
      invdata.push("Invoice Discounting")
      invdata.push(result["INV_Limit_Count"])
      invdata.push(result["INV_Quotes_Count"])
      invdata.push(result["INV_Contracts_Count"])
      invdata.push(result["INV_Apply_Fin_Count"])
      invdata.push(result["INV_Agreement"])
      invdata.push(result["INV_Approved_Fin_Count"])
      overalldata.push(invdata)
      setStageTable(overalldata)

    }).catch(e => {
      console.log('errror in getTransactionCounts', e);
      setshowLoader(false)
    })
  }, [graphConfiguration])
  const getApplications = () => {
    let objectAPI = {
      "userId": userId,
      currentPage: page,
      // exactMatchId: editBuyer || undefined,
      ...filter,
    }
    setshowLoader(true)
    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push(element.accordianId === "hsCodes" ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
        else if (element.type === "minMax") {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }
    call('POST', 'getApplications', objectAPI).then((result) => {
      setshowLoader(false)
      console.log('running getBuyersDetail api-->', result, formatDataForTable(result.message));
      setdbData(formatDataForTable(result.message))
      setoveralldata(result.message)
      setCount(result.total_records);
    }).catch((e) => {
      // console.log('error in getBuyersDetail', e);
      setshowLoader(false)
    });
  }
  async function downloadActiveUserApplicationStage() {
    if (!stageTable?.length) {
      return toastDisplay('No data found to download', "info")
    }
    try {
      let temp = stageTable
      let csvString = "Application Stage,Finance Limit,Quote,Termsheet/Contract,Finance,Agreement,Approved\r\n"
      let rowString = `${temp[0][0]},${temp[0][1]},${temp[0][2]},${temp[0][3]},${temp[0][4]},${temp[0][5]},${temp[0][6]}\r\n`
      rowString = rowString.replace(/(\n)/gm, "")
      csvString += rowString

      rowString = `${temp[1][0]},${temp[1][1]},${temp[1][2]},${temp[1][3]},${temp[1][4]},${temp[1][5]},${temp[1][6]}\r\n`
      rowString = rowString.replace(/(\n)/gm, "")
      csvString += rowString

      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', `ApplicationStages.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log("error in downloadActiveUserApplicationStage", error);
    }
  }

  const handleGraphConfigurationChange = async (event) => {
    if (event.persist) {
      event.persist()
    }
    setGraphConfiguration({ ...graphConfiguration, [event.target.name]: event.target.value })
  }

  async function handleTransactionPopupLC(itemData) {
    setshowLoader(true)
    let apiResp = await call('POST', 'getTransactionHistoryForLC', {
      buyerId: itemData.buyerId, applicationId: itemData.applicationId
    })
    console.log("getTransactionHistoryForLC api resp====>", itemData, apiResp);
    setshowLoader(false)
    toggleTransactionPopup({ show: true, data: apiResp })
  }

  async function handleTransactionPopupINV(itemData) {
    setshowLoader(true)
    let apiResp = await call('POST', 'getTransactionHistoryForInvoiceLimit', {
      buyerId: itemData.buyerId,
      applicationId: itemData.applicationId,
      invRefNo: itemData.reference_no
    })
    // console.log("getTransactionHistoryForInvoiceLimit api resp====>", itemData, apiResp);
    setshowLoader(false)
    toggleTransactionPopup({ show: true, data: apiResp })
  }

  function formatDataForTable(data) {

    let tableData = []
    let row = []
    data.forEach((item, index) => {
      try {
        let buyers_credit = item.buyers_credit ? JSON.parse(item.buyers_credit) : []
        let status = ''
        if (item.buyers_credit === null) {
          status = "Waiting for financer quote"
        }
        else if (item.buyers_credit != null && item.selectedFinancier == null) {
          status = 'Quote Recieved'
        } else if (item.termSheetSignedByBank && item.termSheetSignedByExporter) {
          status = (item.invRefNo === 'lc_discounting' || item.invRefNo === 'lc_confirmation' || item.invRefNo === 'sblc') ? "Quote Locked" : "Limit Approved"
        } else if (item.termSheetSignedByExporter) {
          status = (item.invRefNo === 'lc_discounting' || item.invRefNo === 'lc_confirmation' || item.invRefNo === 'sblc') ? "Contract Signed by Exporter" : 'TermSheet signed by exporter'
        }
        else if (item.selectedFinancier) {
          status = 'Quote Selected by exporter'
        } else if (item.invRefNo === 'lc_discounting' || item.invRefNo === 'lc_confirmation') {
          if (buyers_credit.every(data => data.status === 'denied')) {
            status = 'Limit Denied'
          }
        } else if (item.invRefNo != 'lc_discounting' && item.invRefNo != 'lc_confirmation' && item.invRefNo != 'sblc') {

          if (buyers_credit.every(data => data.financierAction === 'deny')) {
            status = 'Limit Denied'
          }
        }
        let amount = ""
        if (item.finance_type === 'Invoice Discounting') {
          amount += `${item.requiredLimit ? `${item.requiredLimitCurrency ? item.requiredLimitCurrency : ''} ${item.requiredLimit}` : 'NA'}`
        } else {
          if (item.ocrFields) {
            const ocrfields = JSON.parse(item.ocrFields)
            amount += ocrfields["32B"]
          }

        }
        let allDeniedCount = 0
        let allApprovedCount = 0
        let buyersCredit = []

        if (item.buyers_credit) {
          buyersCredit = JSON.parse(item.buyers_credit)
          for (let index = 0; index < buyersCredit.length; index++) {
            const j = buyersCredit[index];
            if (j.financierAction === "deny" || j.status === 'denied') {
              allDeniedCount += 1
            }
            else {
              allApprovedCount += 1
            }
          }
        }
        console.log('financers', item?.selectedLenderName);
        let selectedLenderName = item.finance_type === 'Invoice Discounting' ? item.selectedLenderName ? item.selectedLenderName.split(",") : [] : item.selectedLCLenderName ? removeDuplicates(item.selectedLCLenderName.split(",")) : []
        let selectedLenderId = item.finance_type === 'Invoice Discounting' ? item.selectedLenderId ? item.selectedLenderId.split(",") : [] : item.selectedLCLenderId ? removeDuplicates(item.selectedLCLenderId.split(",")) : []
        if (allDeniedCount / 1 == selectedLenderName.length / 1) {
          status = 'Limit Rejected'
        }

        row[0] = item.createdAt ? moment(item.createdAt).format('DD/MM/YYYY') : 'NA'
        row[1] = item.buyerName ? item.buyerName : 'NA'
        row[2] = <span className='text-color1'>{item.finance_type === 'Invoice Discounting' ? item.invRefNo ? item.invRefNo : `${getInitials(item.buyerName)}/${item.buyerCountry ? item.buyerCountry : '-'}/${item.requiredLimit ? item.requiredLimit : '-'}` : item.id ? item.id : '-'}</span>
        row[3] = item.finance_type
        row[4] = item.FinancierName ? <span className='text2ECC71'>{item.FinancierName}</span> :
          < >
            <div
              className=''
            >
              {selectedLenderName.length ? selectedLenderName.map((key, j) => {
                let isApprovedByFinancier = buyersCredit?.filter(i => {
                  console.log('condtion', i.lender_id / 1, selectedLenderId[j] / 1);
                  if ((i.lender_id / 1 == selectedLenderId[j] / 1) && (i.financierAction === "Approved" || i.status === "approved")) {
                    return i
                  }
                })?.[0]
                console.log('isApproved', isApprovedByFinancier);
                let isRejectedByFinancier = buyersCredit?.filter(i => {
                  if ((i.lender_id / 1 == selectedLenderId[j] / 1) && (i.financierAction === "deny" || i.status === 'denied')) {
                    return i
                  }
                })?.[0]
                return (
                  <div
                    className={`position-relative cursor ${isApprovedByFinancier ? " text2ECC71 " : ''} ${isRejectedByFinancier ? ' text-danger ' : '   '}`} >
                    <label className='font-wt-600 font-size-22 position-absolute cursor' style={{ top: "-0.8rem" }} >{`.`}</label>
                    <label className='ml-3 font-size-14  font-wt-600 cursor' >{`${key}`}</label>
                  </div>
                )
              }) : "NA"}
            </div>
          </>
        row[5] = amount
        row[6] = <label className='text-color1 font-wt-600 cursor' onClick={() => {
          if (item.invRefNo === 'lc_discounting' || item.invRefNo === 'lc_confirmation' || item.invRefNo === 'sblc') {
            handleTransactionPopupLC({
              buyerId: item.buyerId,
              applicationId: item.tblId
            })
          } else {
            handleTransactionPopupINV({
              buyerId: item.buyerId,
              applicationId: item.tblId,
              invRefNo: item.invRefNo
            })
          }
        }}>{status}</label>

        tableData.push(row)
        row = []
      } catch (e) {
        console.log('erroor in table', e);
      }

    })
    return tableData
  }

  useEffect(() => {
    getApplications()
  }, [])
  async function onView(index) {

  }

  function handleOpeningApplication(indx, tabIndx) {
    let item = overalldata[indx]
    if (item.finance_type == 'Invoice Discounting') {
      if (item.buyers_credit == null || item.selectedFinancier == null) {
        window.location = `/seeQuotes?buyer=${item.buyerId}`;
        localStorage.setItem("applicationId", item.tblId)
        localStorage.setItem("invIfAppliedNo", item.invRefNo)
        localStorage.setItem("isAdmin", true)
        localStorage.setItem("defaultTabForAdmin", tabIndx)
        localStorage.setItem("selectedLenderName", item.FinancierName)

        // setting manual user id & email for user
        localStorage.setItem("manualUserId", item.userId)
        localStorage.setItem("manualUserEmail", item.email_id)
      } else {
        window.location = `/sign-agreement`
        localStorage.setItem("item", JSON.stringify(item))
        localStorage.setItem("isAdminUser", true)
        localStorage.setItem("defaultSetTab", tabIndx)

        // setting manual user id & email for user
        localStorage.setItem("manualUserId", item.userId)
        localStorage.setItem("manualUserEmail", item.email_id)
        localStorage.setItem("headerBreadCum", "Invoice Discounting > Finance > Application Details")
      }
    } else {
      window.location = `/LcSeequotes?id=${item.tblId}`
      localStorage.setItem("isAdmin", true)
      localStorage.setItem("defaultTabForAdmin", tabIndx)

      // setting manual user id & email for user
      localStorage.setItem("manualUserId", item.userId)
      localStorage.setItem("manualUserEmail", item.email_id)
    }
  }

  return (
    <>
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className='row mt-4'>

        <div className='w-25'>
          <div className='card h-75 dashboard-card shadow-sm align-items-center justify-content-center'>
            <label className='w-100 font-size-14 text-color-value font-wt-600 pt-2'>{`Ongoing Application - `}
              <label className='font-size-14 text-color-value font-wt-600 text-custom2'>{` ${stats["applicationStats"]["total_application_count"] || "NA"}`}</label></label>
            <div className='row px-0 w-100' >
              <div className='w-50 cursor' onClick={() => {
                let temp = filterData
                temp["Application Type"]["data"][0]["isChecked"] = true
                temp["Application Type"]["data"][1]["isChecked"] = true
                temp["Application Type"]["data"][2]["isChecked"] = true
                temp["Application Type"]["data"][3]["isChecked"] = false
                temp["Application Type"]["isFilterActive"] = true
                setfilterdata({ ...temp })
              }}>
                <label className={`value font-wt-600 .textFFB801  w-100`}>
                  {stats["applicationStats"]["lc_count"] || "NA"}
                </label>
                <label className={'font-size-13 font-wt-600 text-color-value'}>{"LC"}</label>
              </div>

              <div className='w-50 cursor' onClick={() => {
                let temp = filterData
                temp["Application Type"]["data"][0]["isChecked"] = false
                temp["Application Type"]["data"][1]["isChecked"] = false
                temp["Application Type"]["data"][2]["isChecked"] = false
                temp["Application Type"]["data"][3]["isChecked"] = true
                temp["Application Type"]["isFilterActive"] = true
                setfilterdata({ ...temp })
              }}>
                <label className={`value font-wt-600 text-48DA87 w-100`}>
                  {stats["applicationStats"]["invoice_count"] || "NA"}
                </label>
                <label className={'font-size-13 font-wt-600 text-color-value'}>{"Invoice Discounting"}</label>
              </div>
            </div>
          </div>
        </div>

        <div className='w-17'>
          <div className='card h-75 dashboard-card shadow-sm align-items-center justify-content-center'>
            <label className={`value font-wt-600 text-custom2`}>
              {`$ ${Intl.NumberFormat("en", { notation: 'compact' }).format(stats["applicationStats"]["limit_available"])}` || "NA"}
            </label>
            <label className={'font-size-13 font-wt-600 text-color-value'}>{"Limit Available"}</label>
          </div>
        </div>

        <div className='w-40'>
          <div className='card h-75 dashboard-card shadow-sm align-items-center justify-content-center'>
            <div className='row px-0 w-100'>
              <div className='w-33'>
                <label className={`value font-wt-600 textFFB801 w-100`}>
                  {`$ ${Intl.NumberFormat("en", { notation: 'compact' }).format(stats["applicationStats"]["finance_applied"])}` || "NA"}

                </label>
                <label className={'font-size-13 font-wt-600 text-color-value'}>{"Finance Applied"}</label>
              </div>
              <div className='w-33'>
                <label className={`value font-wt-600 text-48DA87  w-100`}>
                  {`$ ${Intl.NumberFormat("en", { notation: 'compact' }).format(stats["applicationStats"]["finance_approved"])}` || "NA"}

                </label>
                <label className={'font-size-13 font-wt-600 text-color-value'}>{"Finance Approved"}</label>
              </div>

              <div className='w-33'>
                <label className={`value font-wt-600 colorFF7B6D w-100`}>
                  {`$ ${Intl.NumberFormat("en", { notation: 'compact' }).format(stats["applicationStats"]["finance_rejected"])}` || "NA"}
                </label>
                <label className={'font-size-13 font-wt-600 text-color-value'}>{"Finance Rejected"}</label>
              </div>
            </div>
          </div>
        </div>

        <div className='w-17'>
          <div className='card h-75 dashboard-card shadow-sm align-items-center justify-content-center'>
            <div className='row px-0 w-100'>
              <div>
                <label className={`value font-wt-600 text-custom2 w-100`}>
                  {`$ ${Intl.NumberFormat("en", { notation: 'compact' }).format(stats["applicationStats"]["disbursement_done"])}` || "NA"}
                </label>
                <label className={'font-size-13 font-wt-600 text-color-value'}>{"Disbursement"}</label>
              </div>

            </div>
          </div>
        </div>

      </div>
      <div className={`modal fade ${transactionPopup.show && "show"}`} style={transactionPopup.show ? { display: "block", "zIndex": '100001' } : {}}>
        <div className="modal-dialog modal-md mr-0 my-0">
          <div className="modal-content submitmodal pb-4"
          >

            <div className="modal-header border-0">
              <div className="w-100 d-flex align-items-center justify-content-between">
                <label
                  className="font-size-16 font-wt-600 text-color-value mx-3"
                >Transaction History</label>
                <div className="modal-header border-0">
                  <button type="button" className="btn-close" aria-label="Close" onClick={() => toggleTransactionPopup({ show: false, data: [] })}></button>
                </div>
              </div>
            </div>

            <div className="modal-body px-4">
              {transactionPopup.data.length ? transactionPopup.data.map((item, index) => {
                return (
                  <div className='d-flex flex-row ml-3'>
                    <div className="progressBarContainer">
                      <div className="progressBarInnerCircle">
                      </div>
                    </div>
                    <div className='pl-4 pt-3'>
                      <p className='font-size-14 text-color1 font-wt-500 mb-0'>{item.action}</p>
                      <p className='font-size-14 text-color-label font-wt-500 mb-0'>{item.date}</p>
                      <p className='font-size-14 text-color-label font-wt-500 mb-0'>{item.time}</p>
                    </div>
                  </div>
                )
              }) :
                null}
            </div>

          </div>
        </div>
      </div>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <div className='my-2 card p-3 dashboard-card border-0 borderRadius'>
        <div className='filter-div ml-4'>
          <Filter
            filteredSearch={filteredSearch}
            setFilteredSearch={setFilteredSearch}
            filterData={filterData} setFilterData={setfilterdata} showFilterBtn={true}
            showResultPerPage={true} count={Count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setrefresh} isAdditionalButton={true} >
            <div className="d-flex gap-4">
              <button className={`new-btn  py-2 px-2 text-white cursor`} onClick={() => {
                localStorage.setItem("UserDetails", JSON.stringify({
                  ...userTokenDetails,
                  user_id: userTokenDetails.id,
                  email: userTokenDetails.email_id,
                  userName: userTokenDetails.company_name
                }))
                window.open(`/applyForLimit`, "_blank")
              }}>Create New Application</button>
            </div>
          </Filter>
        </div>
        <div>
          <NewTable
            filteredSearch={filteredSearch}
            setFilteredSearch={setFilteredSearch}
            filterData={filterData}
            setFilterData={setfilterdata}
            columns={[
              {
                name: "Date", filter: true, filterDataKey: "Date", sort: [
                  { name: "Sort Oldest", selected: filter.sortBydate === "ASC", onActionClick: () => { setFilter({ ...filter, sortBydate: 'ASC', sortBuyerName: false, sortFinName: false, sortAmount: false }); setrefresh(refresh + 1) } },
                  { name: "Sort Latest", selected: filter.sortBydate === "DESC", onActionClick: () => { setFilter({ ...filter, sortBydate: "DESC", sortBuyerName: false, sortFinName: false, sortAmount: false }); setrefresh(refresh + 1) } }]
              },
              {
                name: "Buyer Name", filter: true, filterDataKey: "Buyer Name", sort: [
                  { name: "Sort A-Z", selected: filter.sortBuyerName === "ASC", onActionClick: () => { setFilter({ ...filter, sortBuyerName: 'ASC', sortBydate: false, sortFinName: false, sortAmount: false }); setrefresh(refresh + 1) } },
                  { name: "Sort Z-A", selected: filter.sortBuyerName === "DESC", onActionClick: () => { setFilter({ ...filter, sortBuyerName: "DESC", sortBydate: false, sortFinName: false, sortAmount: false }); setrefresh(refresh + 1) } }]
              },
              { name: "Application No." },
              {
                name: "Application Type", filter: true, filterDataKey: "Application Type"
              },
              {
                name: "Financer", filter: true, filterDataKey: "Financier Name", sort: [
                  { name: "Sort A-Z", selected: filter.sortFinName === "ASC", onActionClick: () => { setFilter({ ...filter, sortFinName: 'ASC', sortBuyerName: false, sortAmount: false }); setrefresh(refresh + 1) } },
                  { name: "Sort Z-A", selected: filter.sortFinName === "DESC", onActionClick: () => { setFilter({ ...filter, sortFinName: "DESC", sortBuyerName: false, sortAmount: false }); setrefresh(refresh + 1) } }]
              },
              {
                name: "Amount", filter: true, filterDataKey: "Amount", sort: [
                  { name: "Sort Lowest", selected: filter.sortAmount === "ASC", onActionClick: () => { setFilter({ ...filter, sortAmount: 'ASC', sortBuyerName: false, sortFinName: false, sortBydate: false }); setrefresh(refresh + 1) } },
                  { name: "Sort Highest", selected: filter.sortAmount === "DESC", onActionClick: () => { setFilter({ ...filter, sortAmount: "DESC", sortBuyerName: false, sortFinName: false, sortBydate: false }); setrefresh(refresh + 1) } }]
              },
              { name: "Status" }
            ]}
            data={dbData}
            options={[
              {
                name: "View Application", icon: "eye.png", onClick: (index) => {
                  handleOpeningApplication(index, 0)
                }
              },
            ]}
          />
          <Pagination page={page} totalCount={Count} onPageChange={(p) => setpage(p)} refresh={refresh} setRefresh={setrefresh} perPage={filter.resultPerPage || 10} />

        </div>
      </div>
      {(hideGraphs || viewDetails.isVisible) ? null : (
        <div className='card p-3 dashboard-card border-0 borderRadius mt-5'>
          <div className='row  mb-3'>
            <div className='w-70 align-items-center d-flex'>
              <div className='w-auto pr-3'>
                <label className='text-color-value font-size-14 font-wt-600'>Custom</label>
              </div>
              <div className='w-20 pr-3'>
                <NewInput type={"date"} name={"applicationStageFrom"} value={graphConfiguration.applicationStageFrom}
                  onChange={handleGraphConfigurationChange} />
              </div>
              <div className='w-20 pr-3'>
                <NewInput type={"date"} name={"applicationStageTo"} value={graphConfiguration.applicationStageTo}
                  onChange={handleGraphConfigurationChange} />
              </div>
            </div>
            <div className='w-30 align-items-center d-flex justify-content-end'>
              <div className='px-3'>
                <img
                  onClick={() => { setGraphConfiguration({ ...graphConfiguration, applicationStageGraphMode: !graphConfiguration.applicationStageGraphMode }) }}
                  className='cursor'
                  src={`/assets/images/${graphConfiguration.applicationStageGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
              </div>
              <div className=''>
                <img
                  onClick={() => {
                    downloadActiveUserApplicationStage()
                  }}
                  className='cursor' src='/assets/images/download_icon_with_bg.png' />
              </div>
            </div>
          </div>
          {!graphConfiguration.applicationStageGraphMode ? (
            <>
              <div>
                <NewTable
                  disableAction={true}
                  data={stageTable || []}
                  columns={[{ name: "Stage Wise Application Summary" }, { name: "Finance Limit" }, { name: "Quote" },
                  { name: "Termsheet/Contract" }, { name: "Finance" }, { name: "Agreement" }, { name: "Approved" }]}
                />
              </div>
            </>
          ) : (
            <div className='h-100 d-flex flex-row pt-5 pb-4 mx-3' >
              <div className='col-6'>
                {true ? (
                  <>
                    <div className='text-center h-90'>
                      <img src='assets/images/LC application funnel.png' width={"70%"} />
                    </div>
                    <label className='position-absolute font-size-16 font-wt-700 inact-15-days'>{graphData?.LC_Limit_Count}</label>
                    <label className='position-absolute font-size-16 font-wt-700 inact-30-days'>{graphData?.LC_Quotes_Count}</label>
                    <label className='position-absolute font-size-16 font-wt-700 inact-45-days'>{graphData?.LC_Contracts_Count}</label>
                    <label className='position-absolute font-size-16 font-wt-700  inact-60-days'>{graphData?.LC_Apply_Fin_Count}</label>
                    <label className='position-absolute font-size-16 font-wt-700 inact-75-days'>{graphData?.LC_Approved_Fin_Count}</label>
                  </>
                ) : null}
                <label className='text-color-value font-size-14 font-wt-600 w-100 text-center mt-3'>LC Application</label>
              </div>

              <div className='col-6'>
                {true ? (
                  <>
                    <div className='text-center h-90'>
                      <img src='assets/images/Invoice application funnel.png' width={"70%"} height={"90%"} />
                    </div>
                    <label className='position-absolute font-size-16 font-wt-700 appstage-fl'>{graphData?.INV_Limit_Count}</label>
                    <label className='position-absolute font-size-16 font-wt-700 appstage-qts'>{graphData?.INV_Quotes_Count}</label>
                    <label className='position-absolute font-size-16 font-wt-700 appstage-ts'>{graphData?.INV_Contracts_Count}</label>
                    <label className='position-absolute font-size-16 font-wt-700 appstage-fin'>{graphData?.INV_Apply_Fin_Count}</label>
                    <label className='position-absolute font-size-16 font-wt-700 appstage-agree'>{graphData?.INV_Agreement}</label>
                    <label className='position-absolute font-size-16 font-wt-700 appstage-approved'>{graphData?.INV_Approved_Fin_Count}</label>

                  </>
                ) : null}
                <label className='text-color-value font-size-14 font-wt-600 w-100 text-center mt-3'>Invoice Application</label>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default ApplicationTab