import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import HeaderV2 from "../../../partial/headerV2";
import SideBarV2 from "../../../partial/sideBarV2";
import toastDisplay from "../../../../utils/toastNotification";
import { astrix, ClearCache, convertImageToPdf, GetCache, most_used_currencies, SetCache, toolTip, getDocDetails } from "../../../../utils/myFunctions";
import call from '../../../../service';
import { FileInput } from "../../../../utils/FileInput";
import moment from 'moment';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { NewTable } from '../../../../utils/newTable';
import { ToastContainer } from "react-toastify";
import CalenderView from "../../../chatRoom/components/calenderView";
import { useHistory } from "react-router";
import { InputWithSelect, NewInput } from "../../../../utils/newInput";

const serialLabels = ["1st", "2nd", "3rd"]

const addBuyerTabs = [
  { name: "Application form" }
]

const reviewFormPostShipment = [
  { "name": "Applicant Name", val: "company_name" },
  { "name": "Invoice Amount", val: "contractAmount", unit: "contractAmountCurrency" },
  { "name": "Buyer", val: "buyerName" },
  { "name": "Invoice No.", val: "invRefNo" },

  { "name": "Purchase Order No.", val: "poRefNo" },
  { "name": "Invoice Issue Date", val: "invoiceIssueDate" },
  { "name": "LC Tenor (in Days)", val: "lcTenor" },
  { "name": "Invoice Due Date", val: "invoiceDueDate" },
  { "name": "Credit Days", val: "creditDays" }

]

const reviewForm = [
  { "name": "Buyer Name", val: "buyerName" },
  { "name": "Previous year annual sales", val: "previousAnnualSale", unit: "buyerCurrency" },
  { "name": "Contact Number:", val: "buyerPhone", unit: "buyerPhoneCode" },
  { "name": "Expected current year’s annual sale", val: "currentAnnualSale", unit: "buyerCurrency" },

  { "name": "Email Id", val: "buyerEmail" },
  { "name": "Inco Terms", val: "incoTerms" },
  { "name": "Website", val: "buyerWebsite" },
  { "name": "Terms of Payment", val: "termsOfPayment" },

  { "name": "Address", val: "buyerAddress" },
  { "name": "Product Details", val: "productDetails" },

  { "name": "DUNS No", val: "buyerDUNSNo" },
  { "name": "HSN Code", val: "buyerHsnCode" },

]

const reviewForm3 = [
  { "name": "LC type", val: "lcType" },
  { "name": "LC number", val: "lcNo" },
  { "name": "LC tenor", val: "lcTenor" },
  { "name": "Shipment from country", val: "shipmentFromCountry" },
  { "name": "Shipment to country", val: "shipmentToCountry" },
  { "name": "Port of loading", val: "portOfLoading" },
  { "name": "Port of discharge", val: "portOfDischarge" },
  { "name": "Commodity", val: "commodity" },
  { "name": "Expected date of shipment", val: "expectedDateOfShipment" },
  { "name": "Beneficiary bank name", val: "beneficiaryBankName", notAmmendable: true },
  { "name": "Beneficiary bank account no.", val: "beneficiaryAccountNo", notAmmendable: true },
  { "name": "Beneficiary bank IFSC code", val: "beneficiaryIfscCode", notAmmendable: true },
  { "name": "Beneficiary bank SWIFT code", val: "beneficiarySwiftCode", notAmmendable: true },
  { "name": "LC issuing bank name", val: "lcIssuingBankName", notAmmendable: true },
  { "name": "LC issuing bank address", val: "lcIssuingBankAddress" },
  { "name": "LC issuing bank SWIFT code", val: "lcIssuingBankSwiftCode" },
  { "name": "Country of origin", val: "countryOfOrigin" },
  // { "name": "LC Purpose", val: "lcPurpose" },
  { "name": "Confirming bank name", val: "confirmingBankName" },
  { "name": "Confirming bank address", val: "confirmingBankAddress" },
  { "name": "Confirming bank SWIFT code", val: "confirmingBankSwiftCode" }
]


const ViewLCFinApplication = ({ userTokenDetails, navToggleState }) => {

  let applicationDetails = JSON.parse(localStorage.getItem("applicationDetails"))
  let { onlyView } = applicationDetails
  let quoteId = applicationDetails.id

  let history = useHistory()

  const [tab, setTab] = useState(0);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({})
  const [disbursement, setDisbursement] = useState({ modal: false, page: 0 });
  const [value, onChange] = useState(new Date());
  const [tableData, setTableData] = useState([])
  const [addMoreDoc, setAddMoreDoc] = useState([])
  const [finAddMoreDoc, setFinAddMoreDoc] = useState([])
  const [lcCodes, setLCCodes] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [disbursementArr, setDisbursementArr] = useState([{ unit: "USD" }, { unit: "USD" }])

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  useEffect(() => {
    // Get master data start
    call('POST', 'getLCFieldMaster', {
    }).then((result) => {
      // console.log('running getLCFieldMaster api -->', result);
      let lcFieldObj = {}
      result.mandatoryFields.forEach((item) => {
        lcFieldObj[item.tag] = item.fieldName
      })
      result.optionalFields.forEach((item) => {
        lcFieldObj[item.tag] = item.fieldName
      })
      setLCCodes(lcFieldObj)
    }).catch((e) => {
      // console.log('error in getLCFieldMaster', e);
    })
    // Get master data end
    fetchAllDocs({ ...applicationDetails })
    call('POST', 'getInvoiceDisbursementSchedule', {
      invRefNo: quoteId
    }).then((result) => {
      // console.log('running getInvoiceDisbursementSchedule api -->', result);
      if (result.length) {
        let temp = []
        for (let index = 0; index < result.length; index++) {
          const element = result[index];
          temp.push({
            "date": moment(element.scheduledOn).format("YYYY-MM-DD"), "id": element.id, "amount": element.amount, "unit": element.currency,
            "status": element.status
          })
        }
        setDisbursementArr(temp)
      }
    }).catch((e) => {
      // console.log('error in getInvoiceDisbursementSchedule', e);
    })
  }, [])

  async function fetchAllDocs(appendData) {
    setshowLoader(true)
    let confirmationLetterDocument = {}
    let otherFinDocs = {}
    let otherFinDocArr = finAddMoreDoc

    let originalLC = await getDocDetails(applicationDetails.originalLC)
    let invReport = await getDocDetails(applicationDetails.invReport)
    let certOfOrigin = await getDocDetails(applicationDetails.certOfOrigin)
    let billOfLading = await getDocDetails(applicationDetails.billOfLading)

    let reqLetterOfConfirmation = await getDocDetails(applicationDetails.reqLetterOfConfirmation)


    let contractDocs = applicationDetails.contractDocsFromFinanciers.split(":")
    for (let index = 0; index < contractDocs.length; index++) {
      if (index == 0) {
        confirmationLetterDocument = await getDocDetails(contractDocs[0])
      }
      else {
        otherFinDocs["otherFinDoc" + (index - 1)] = await getDocDetails(contractDocs[index])
        otherFinDocArr.push(null)
      }
    }
    setData({
      ...data, ...appendData, invReport, originalLC, certOfOrigin, billOfLading, confirmationLetterDocument, reqLetterOfConfirmation, ...otherFinDocs
    })
    setFinAddMoreDoc(otherFinDocArr)
    setshowLoader(false)
  }


  function formatDataForTable(data) {
    let tableData = []
    let row = []
    data = [{
      modified_at: '',
      amount: '$10,00,000'
    },
    {
      modified_at: '',
      amount: '$10,00,000'
    }];
    data.forEach((item, index) => {
      row[0] = moment(item.modified_at).format('DD/MM/YYYY')
      row[1] = item.amount
      tableData.push(row)
      row = []
    })
    return tableData
  }

  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    if (event.target.name.includes("otherDoc")) {
      setAddMoreDoc(addMoreDoc.concat(null))
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (!file_type.includes("pdf")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  async function handleValidation(tabIndex) {

    if (tabIndex != undefined) {
      if (tabIndex < tab) {
        return setTab(tabIndex)
      }
      else {
        console.log('else');
        return setTab(tabIndex)
      }
    }
  }

  async function updateLCFinanceStatus(finStatus) {
    setshowLoader(true)
    call('POST', 'updateLCFinanceStatus', {
      finStatus, quoteId, lcNo: data.lcNo, userId
    }).then((result) => {
      // console.log('running updateLCFinanceStatus api -->', result);
      setshowLoader(false)
      toastDisplay(result, "success", () => {
        history.go(-1)
      })
    }).catch((e) => {
      // console.log('error in updateLCFinanceStatus', e);
      setshowLoader(false)
      toastDisplay("Something went wrong", "error")
    })
  }

  console.log('valuueee: ', value);

  const handleDisbursementArr = (e, index) => {
    console.log("eeeeeeeeeeeeeeeeeeeeeeeeeee", e);
    if (e.persist) {
      e.persist()
    }
    let temp = disbursementArr
    temp[index][e.target.name] = e.target.value
    console.log("tempppppppppppppppppppppp", temp[index], e.target.name, e.target.value);
    setDisbursementArr([...temp])
  }

  async function validateDisbursementArr() {
    let err = {}
    disbursementArr.forEach((element, index) => {
      if (!element.unit) {
        err["amount" + index] = "Mandatory field"
      }
      if (!element.amount) {
        err["amount" + index] = "Mandatory field"
      }
      if (!element.date) {
        err["date" + index] = "Mandatory field"
      }
    });
    if (!Object.keys(err).length) {
      setshowLoader(true)
      call('POST', 'scheduleDisbursementForLC', {
        quoteId, disbursementArr, userId, lcNo: data.lcNo
      }).then((result) => {
        // console.log('running scheduleDisbursementForLC api -->', result);
        setshowLoader(false)
        toastDisplay(result, "success", () => {
          history.go(-1)
        })
      }).catch((e) => {
        // console.log('error in scheduleDisbursementForLC', e);
        setshowLoader(false)
        toastDisplay("Something went wrong", "error")
      })
    }
    setErrors(err)
  }

  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="viewDetails" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"LC - Finance Application"}
              userTokenDetails={userTokenDetails} />
            <a className="cursor" href="/lcFinApplication"><img className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
            <div>
              <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                {addBuyerTabs.map((item, index) => {
                  return (
                    <li>
                      <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                        onClick={() => { handleValidation(index) }}
                      >
                        {item.name}</a>
                    </li>
                  )
                })}
              </ul>
              <div className="card mt-1">

                {tab === 0 ? (
                  <div className='px-5 py-4 pt-5 '>
                    <div className="row">
                      {data.buyerName ? (
                        <div className='w-100'>
                          <label className='font-wt-500 font-size-16'><u>Buyer Details</u></label>
                          <div className='row'>
                            {reviewForm.map((item) => {
                              return (
                                <div className="col-md-6 pe-5">
                                  <p className=" d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                                </div>
                              )
                            })}
                            <div className="col-md-6 pe-5">
                              <p className=" d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{"Required Limit"}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" >
                                {data["requiredLimitCurrency"] + " " + data["requiredLimit"]}
                              </span> </p>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      <div className={`w-100 ${data.buyerName ? 'mt-5' : ''}`}>
                        <label className='font-wt-500 font-size-16'><u>LC Details</u></label>
                        <div className='row'>
                          {reviewForm3.map((item) => {
                            return (
                              <div className="col-md-6 pe-5">
                                <p className=" d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                              </div>
                            )
                          })}
                        </div>
                        {/* <p className="mt-3 text-blue cursor" onClick={() => setLCMoreDetails(true)}>View more LC details  <img src={"assets/images/right-arrow.png"} alt="Back" height={15} width={15} /></p> */}
                      </div>

                      <div className='w-100 mt-5'>
                        <label className='font-wt-500 font-size-16'><u>Post-Shipment Details</u></label>
                        <div className='row'>
                          {reviewFormPostShipment.map((item) => {
                            return (
                              <div className="col-md-6 pe-5">
                                <p className=" d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                              </div>
                            )
                          })}
                        </div>
                      </div>

                      <div className='w-100 mt-5'>
                        <label className='font-wt-500 font-size-16'><u>Documents</u></label>
                        <div className='row mt-2'>
                          <div className="col-md-4">
                            <label className="font-size-14">Original LC</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"originalLC"} value={data.originalLC}
                                  isEditable={false}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <label className="font-size-14">Investigation Report</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"invReport"} value={data.invReport}
                                  // onChange={handleFile} 
                                  isEditable={false}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <label className="font-size-14">Certificate of Origin</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"certOfOrigin"} value={data.certOfOrigin}
                                  isEditable={false}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <label className="font-size-14">Bill of Lading/Airway Bill</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"billOfLading"} value={data.billOfLading}
                                  // onChange={handleFile} 
                                  isEditable={false}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <label className="font-size-14">LC Confirmation Letter Document</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"confirmationLetterDocument"} value={data.confirmationLetterDocument} error={errors.confirmationLetterDocument}
                                  isEditable={false}
                                />
                              </div>
                            </div>
                          </div>

                          {data?.reqLetterOfConfirmation?.name ? (
                            <div className="col-md-4">
                              <label className="font-size-14">Request letter of confirmation</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"reqLetterOfConfirmation"} value={data.reqLetterOfConfirmation} error={errors.reqLetterOfConfirmation}
                                    isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : null}

                          {finAddMoreDoc.length ? finAddMoreDoc.map((item, index) => {
                            return (
                              <div className="col-md-4">
                                <label className="font-size-14">Add More</label>
                                <div className="row form-group">
                                  <div className="col-md-12">
                                    <FileInput name={"otherFinDoc" + index} value={data["otherFinDoc" + index]}
                                      isEditable={false}
                                    />
                                  </div>
                                </div>
                              </div>
                            )
                          }) : null}

                        </div>
                      </div>

                    </div>
                  </div>
                ) : null}

                {tab === 0 && (
                  <div className="d-flex pb-5 px-5 gap-3">
                    <button type="button"
                      onClick={() => updateLCFinanceStatus(1)}
                      className={` new-btn w-auto py-2 px-4 text-white`}>
                      {"Approve"}
                    </button>
                    <button type="button"
                      onClick={() => updateLCFinanceStatus(2)}
                      className={` new-btn w-auto py-2 px-4 text-white`}>
                      {"Reject"}
                    </button>
                    <button type="button"
                      onClick={() => setDisbursement({ modal: true, page: 1 })}
                      className={`new-btn w-auto py-2 px-3 text-white`}>
                      {"Schedule Disbursement"}
                    </button>
                  </div>
                )}

                {(disbursementArr && disbursement.modal && disbursement.page === 1) ?
                  <div className={`modal fade ${disbursement.modal && "show"}`} style={{ display: "block" }}>
                    <div className="modal-dialog modal-lg">
                      <div className="modal-content submitmodal pb-4">
                        <div className="modal-header border-0">
                          <button type="button" className="btn-close" aria-label="Close" onClick={() => setDisbursement({ modal: false, page: 0 })}></button>
                        </div>
                        <div className="modal-body text-center">
                          <p className="mb-4">Schedule Disbursement</p>
                          {disbursementArr.map((item, index) => {
                            return (
                              <div className="row pl-5">
                                <div className="col-1 text-center pt-2">
                                  <label>{serialLabels[index] || (index + 1) + "th"}</label>
                                </div>
                                <div className="col-4">
                                  <NewInput key={index} label={"Date"} type={"date"} name={"date"} defaultValue={item["date"]}
                                    onChange={(e) => handleDisbursementArr(e, index)} error={errors["date" + index]} />
                                </div>
                                <div className="col-5">
                                  <InputWithSelect
                                    key={index}
                                    selectData={most_used_currencies} selectName={"unit"}
                                    defaultSelectValue={item["unit"]} name={"amount"} defaultValue={item["amount"]}
                                    optionLabel={'code'} optionValue={'code'} error={errors["amount" + index]}
                                    label={"Amount"} type={"number"} onChange={(e) => handleDisbursementArr(e, index)} />
                                </div>
                                <div className="col-1 pt-1">
                                  {item.status ? (
                                    <img
                                      onClick={() => handleDisbursementArr({ target: { name: "status", value: false } }, index)}
                                      className="cursor" src={"/assets/images/upload_done.png"} alt="" height={20} width={20} />
                                  ) : (
                                    <img
                                      onClick={() => handleDisbursementArr({ target: { name: "status", value: true } }, index)}
                                      className="cursor" src={"/assets/images/processing.png"} alt="" height={20} width={20} />)}
                                </div>
                                {index > 0 ? (
                                  <div className="col-1 pt-1">
                                    <button
                                      type="button" className="btn-close"
                                      onClick={() => {
                                        let temp = disbursementArr
                                        temp = temp.filter((i, j) => {
                                          if (j != index) {
                                            return i
                                          }
                                        })
                                        setDisbursementArr(temp)
                                      }}
                                    >
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                            )
                          })}
                          <a
                            onClick={() => {
                              let temp = disbursementArr
                              temp = temp.concat([{ unit: "USD" }])
                              setDisbursementArr(temp)
                            }}
                          >
                            <p className="text-color1 font-wt-600 cursor"
                            >Add more</p>
                          </a>
                          <button type="button" className={`mx-2 new-btn w-25 py-2 px-2 mt-3 text-white`}
                            onClick={() => validateDisbursementArr()}>Save</button>
                        </div>
                      </div>
                    </div>
                  </div> :
                  (disbursement.modal && disbursement.page === 2) &&
                  <div className={`modal fade ${disbursement.modal && "show"}`} style={{ display: "block" }}>
                    <div className="modal-dialog modal-sm">
                      <div className="modal-content submitmodal pb-4">
                        <div className="modal-header border-0">
                          <button type="button" className="btn-close" aria-label="Close" onClick={() => setDisbursement({ modal: false, page: 0 })}></button>
                        </div>
                        <div className="modal-body text-center">
                          <p>Disbursement Schedule</p>
                          <NewTable disableAction={false}
                            columns={[{ name: "Date", filter: false }, { name: "Amount", filter: false }]}
                            data={tableData}
                          />
                          <button type="button" className={`mx-2 new-btn py-2 px-2 mt-3 text-white`}
                            onClick={() => { setDisbursement({ modal: false, page: 0 }); window.location = "/lcFinApplication" }}>Confirm</button>
                          <p className="mt-3 text-blue" onClick={() => setDisbursement({ modal: true, page: 1 })}>Change Schedule</p>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </main>
        </div>
      </div >
    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(ViewLCFinApplication)