import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import HeaderV2 from "../../partial/headerV2";
import SideBarV2 from "../../partial/sideBarV2";
import toastDisplay from "../../../utils/toastNotification";
import { astrix, ClearCache, convertImageToPdf, dataURItoBlob, decryptData, GetCache, getDocDetails, getInvoiceFinalChargesObj, getInvoiceTotalCharges, getInvoiceTotalOtherCharges, most_used_currencies, SetCache, toolTip } from "../../../utils/myFunctions";
import call from '../../../service';
import { FileInput } from "../../../utils/FileInput";
import config from "../../../config.json"
import SignDocument from "../components/SignDocument";
import FinanceInvoiceModal from "../contract/components/financeinvoiceModal";
import { InputWithSelect, NewInput, NewSelect } from "../../../utils/newInput";
import { ToastContainer } from "react-toastify";
import swal from 'sweetalert';
import { NewTable } from "../../../utils/newTable";
import { getUserDataFromCookie } from "../../../utils/cookieHelper";
import { AddMoreDocumentHandler } from "../../addMoreDocumentHandler";
import moment from "moment";
import { DocumentViewer } from "../../../utils/documentViewer";
import { useHistory } from "react-router";

const reviewForm = [
  { "name": "Applicant Name", val: "applicantName", unit: "nameTitle" },
  { "name": "Request amount", val: "reqAmount", unit: "currency" },
  { "name": "Invoice amount:", val: "contractAmount", unit: "currency" },
  { "name": "Finance credit days", val: "creditDays" },
  { "name": "Invoice No.", val: "invoiceRefrenceNo" },
  { "name": "Bank", val: "beneficiaryBankTitle" },
  { "name": "Invoice issue date", val: "invoiceIssueDate" },
  { "name": "Lender", val: "lenderNameTitle" },
  { "name": "Invoice due date", val: "invoiceDueDate" },
  { "name": "Buyer", val: "buyerName" },
  { "name": "Purchase order no.", val: "poRefrenceNo" }
]

const reviewForm2 = [
  { "name": "Shipment Origin Country", val: "shipmentOriginCountry" },
  { "name": "Shipment Origin Address", val: "shipmentOriginAddress" },
  { "name": "Shipment Origin City", val: "shipmentOriginCity" },
  { "name": "Shipment Origin Postal Code", val: "shipmentOriginPostalCode" },
  { "name": "Shipment Destination Country", val: "shipmentDestinationCountry" },
  { "name": "Shipment Destination Address", val: "shipmentDestinationAddress" },
  { "name": "Shipment Destination City", val: "shipmentDestinationCity" },
  { "name": "Shipment Destination Postal Code", val: "shipmentDestinationPostalCode" },
]


const SignAgreement = ({ navToggleState }) => {

  let history = useHistory()
  let isAdminUser = localStorage.getItem("isAdminUser")

  const addBuyerTabs = isAdminUser ? [
    { name: "Application form" },
    { name: "Documents" },
    { name: "Quotes" },
    { name: "Disbursement" },
  ] : [
    { name: "Application form" },
    { name: "Documents" },
    // { name: "Review" },
  ]

  const [tableData, setTableData] = useState([])
  const [tableDisbursementData, setTableDisbursementData] = useState([])
  const [viewDoc, toggleViewDoc] = useState({ show: false, doc: {} })
  const [tab, setTab] = useState(isAdminUser ? localStorage.getItem("defaultSetTab") / 1 : 1)
  const [signdoc, setSigndoc] = useState(false);
  const [limitinvoice, setLimitinvoice] = useState(false);
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [docSignPopup, setDocSignPopup] = useState({})
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [agreementConfiguration, setAgreementConfiguration] = useState({})
  const [moreDocsArray, setMoreDocsArray] = useState([])
  const [moreDocsArray2, setMoreDocsArray2] = useState([])
  const [finalCharges, setFinalCharges] = useState({})

  let userTokenDetails = getUserDataFromCookie()

  const queryParams = new URLSearchParams(window.location.search)
  const params = queryParams.get("params")
  let selectedItem = null

  if (params) {
    selectedItem = JSON.parse(decryptData(params))
    // console.log("insideifffffffffffffffffffffff", selectedItem);
  }
  else {
    selectedItem = JSON.parse(localStorage.getItem("item"))
  }

  // console.log("ssssssssssssssssss", selectedItem);

  let buyers_credit = selectedItem?.buyers_credit ? JSON.parse(selectedItem.buyers_credit) : []

  let selectedQuote = selectedItem.selectedQuote
  // let isLenderModifi = selectedQuote?.lender_name.toLowerCase().includes("modifi") ? true : false
  let isLenderModifi = true

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  // const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  // const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName || selectedItem.userName || selectedItem.supplierName
  // const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  // const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;
  // const userIdentifier = userTokenDetails.identifier ? userTokenDetails.identifier : null

  useEffect(() => {
    if (data.contractAmount && data.creditDays && selectedQuote?.assignDate) {
      setFinalCharges(getInvoiceFinalChargesObj(data.contractAmount, data.creditDays, selectedQuote))
    }
  }, [data.contractAmount, data.creditDays])

  useEffect(() => {
    // Load agreement configuration start
    call('POST', 'getAgreementDocConfigurationByInvoiceNo', { "invRefNo": selectedItem.invRefNo }).then((result) => {
      if (result.configuration) {
        setAgreementConfiguration(result.configuration)
      }
    }).catch((e) => {
    })
    // Load agreement configuration end
    // Fetch disbursement details start
    call('POST', 'getInvoiceDisbursementSchedule', { "invRefNo": selectedItem.invRefNo }).then((result) => {
      if (result?.length) {
        setTableDisbursementData(formatDataForTable2(result))
      }
    }).catch((e) => {
    })
    // Fetch disbursement details end
  }, [])

  useEffect(() => {
    // if (Object.keys(agreementConfiguration).length) {
    let viewOnlyData = {}
    call('POST', 'getBuyersDetailWithDoc', {
      buyersId: selectedItem.id + "",
      applicationId: selectedItem.applicationId
    }).then(async (result) => {
      // console.log('running getBuyersDetailWithDoc api-->', result);
      setshowLoader(true)
      let termSheetDocument = await getDocDetails(selectedItem.termSheet)

      let frameworkDocument = await getDocDetails(selectedItem.frameworkDoc)
      let exhibitDocument = await getDocDetails(selectedItem.exhibitDoc)
      let noaDocument = await getDocDetails(selectedItem.noaDoc)

      viewOnlyData = await getInvoice()

      setshowLoader(false)
      if (result.length) {
        let docSignStatus = await call("POST", 'invoiceAgreementSignStatus', { invRefNo: selectedItem.invRefNo })
        // console.log("docSignStatus=======================>", docSignStatus, result[0], selectedQuote, viewOnlyData);
        // Push more post shipment documents into data start
        let morePostShipmentDocsToData = {}
        for (let index = 0; index < moreDocsArray.length; index++) {
          const element = moreDocsArray[index];
          if (element?.name) {
            morePostShipmentDocsToData[element.doc_id] = element
          }
        }
        // Push more post shipment documents into data end
        setData({
          ...data, nameTitle: "Mr", currency: "USD", applicantName: userName, buyerName: result[0]["buyerName"],
          buyerPosition: result[0]["buyerPosition"], nameAuthorizedSignatory: result[0]["nameAuthorizedSignatory"],
          buyerEmail: result[0]["buyerEmail"], buyerPhoneCode: result[0]["buyerPhoneCode"], buyerContact: result[0]["buyerPhone"],
          buyerIdentifier: result[0]["identifier"], incoTerms: result[0]["incoTerms"],
          lenderId: selectedQuote?.lender_id, termSheetDocument, frameworkDocument, exhibitDocument, noaDocument, lenderNameTitle: selectedQuote?.lender_name, ...viewOnlyData,
          docSignStatus, ...morePostShipmentDocsToData
        })
      }
    }).catch((e) => {
      // console.log('error in getBuyersDetailWithDoc', e);
    });
    // }
    // }, [agreementConfiguration])
  }, [moreDocsArray])

  useEffect(() => {
    // if (Object.keys(agreementConfiguration).length && Object.keys(data.docSignStatus || {}).length && (data?.frameworkDocument || data?.exhibitDocument || data?.noaDocument)) {
    let onlyShowDoc = []
    if (selectedItem.isBuyer) {
      if (agreementConfiguration.frameworkToBuyer) {
        onlyShowDoc.push({ key: "frameworkDocument", title: "Framework Document", configurationKey: "framework" })
      }
      if (agreementConfiguration.exhibitToBuyer) {
        onlyShowDoc.push({ key: "exhibitDocument", title: "Exhibit Document", configurationKey: "exhibit" })
      }
      if (agreementConfiguration.noaToBuyer) {
        onlyShowDoc.push({ key: "noaDocument", title: "Noa Document", configurationKey: "noa" })
      }
    }
    else {
      if (agreementConfiguration.frameworkToExporter) {
        onlyShowDoc.push({ key: "frameworkDocument", title: "Framework Document", configurationKey: "framework" })
      }
      if (agreementConfiguration.exhibitToExporter) {
        onlyShowDoc.push({ key: "exhibitDocument", title: "Exhibit Document", configurationKey: "exhibit" })
      }
      if (agreementConfiguration.noaToExporter) {
        onlyShowDoc.push({ key: "noaDocument", title: "Noa Document", configurationKey: "noa" })
      }
    }
    //Adding all post shipment documents & term sheet start
    onlyShowDoc.push({
      key: "invoiceDocument", title: "Invoice Document", configurationKey: "invoiceDocument",
      expSignStatus: data?.invoiceDocument?.signatureId, finSignStatus: data?.invoiceDocument?.financierSignatureId
    })
    onlyShowDoc.push({
      key: "poDocument", title: "PO Document", configurationKey: "poDocument",
      expSignStatus: data?.poDocument?.signatureId, finSignStatus: data?.poDocument?.financierSignatureId
    })
    onlyShowDoc.push({
      key: "logisticDocument", title: "Bill of Lading/Airway Bill", configurationKey: "logisticDocument",
      expSignStatus: data?.logisticDocument?.signatureId, finSignStatus: data?.logisticDocument?.financierSignatureId
    })
    onlyShowDoc.push({
      key: "invReportDocument", title: "Investigation Report", configurationKey: "invReportDocument",
      expSignStatus: data?.invReportDocument?.signatureId, finSignStatus: data?.invReportDocument?.financierSignatureId
    })
    onlyShowDoc.push({
      key: "grnDocument", title: "Certificate of Origin", configurationKey: "grnDocument",
      expSignStatus: data?.grnDocument?.signatureId, finSignStatus: data?.grnDocument?.financierSignatureId
    })
    onlyShowDoc.push({
      key: "packingListDocument", title: "Packing List", configurationKey: "packingListDocument",
      expSignStatus: data?.packingListDocument?.signatureId, finSignStatus: data?.packingListDocument?.financierSignatureId
    })
    onlyShowDoc.push({
      key: "shippingBillDocument", title: "Shipping Bill", configurationKey: "shippingBillDocument",
      expSignStatus: data?.shippingBillDocument?.signatureId, finSignStatus: data?.shippingBillDocument?.financierSignatureId
    })
    // Adding more post ship docs into table view start
    for (let index = 0; index < moreDocsArray.length; index++) {
      const element = moreDocsArray[index];
      if (element?.name) {
        onlyShowDoc.push({
          key: element.doc_id, title: element.doc_name, configurationKey: element.doc_id,
          expSignStatus: data?.[element.doc_id]?.signatureId, finSignStatus: data?.[element.doc_id]?.financierSignatureId,
          disableEdit: true
        })
      }
    }
    // Adding more post ship docs into table view end
    onlyShowDoc.push({
      key: "termSheetDocument", title: "Term Sheet", configurationKey: "termSheetDocument",
      expSignStatus: data?.termSheetDocument?.signatureId, finSignStatus: data?.termSheetDocument?.financierSignatureId,
      disableEdit: true, titleCompulsary: true
    })
    setTableData(formatDataForTable(onlyShowDoc, data.docSignStatus))
    //Adding all post shipment documents & term sheet end
    // }
    // }, [agreementConfiguration, data.frameworkDocument, data.exhibitDocument, data.noaDocument, data.docSignStatus])
  }, [agreementConfiguration, data, moreDocsArray, moreDocsArray2])

  function handleSignPopup(documentKey, documentFile) {
    setSigndoc(true)
    setDocSignPopup({ docToSign: documentFile, docSignName: documentKey })
  }

  async function onUploadCancel(key) {
    setData({ ...data, [key]: null })
  }

  async function controlDocumentViewer(docFileObj) {
    toggleViewDoc({ show: true, doc: docFileObj })
  }

  function formatDataForTable2(data) {
    let tableData = []
    let row = []
    for (let index = 0; index < data.length; index++) {
      let item = data[index]
      row[0] = moment(item.scheduledOn).format('DD/MM/YYYY')
      row[1] = item.amount + " " + item.currency
      row[2] = item.status == 1 ? moment(item.updatedAt).format('DD/MM/YYYY') : "-"
      row[3] = item.status == 1 ? (item.currency + " " + item.disbursedAmount) : "-"
      row[4] = item.status ? (
        <img src={"/assets/images/upload_done.png"} alt="Yes" height={16} width={17} />
      ) : (
        <img src={"/assets/images/processing.png"} alt="" height={16} width={17} />
      )
      row[5] = <label
        onClick={async () => {
          if (item.attachment) {
            let docFileObj = await getDocDetails(item.attachment)
            controlDocumentViewer(docFileObj)
          }
          else {
            toastDisplay("Receipt not uploaded by financer", "info")
          }
        }}
        className="text-color1 font-wt-600 cursor"
      >{item.attachment ? "View Receipt" : "NA"}</label>

      tableData.push(row)
      row = []
    }
    return tableData
  }

  function formatDataForTable(param, docSignStatus) {
    let tableData = []
    let row = []

    for (let index = 0; index < param.length; index++) {
      const item = param[index];
      // console.log("ittttttttttttttttttt", item, data, selectedQuote);
      row[0] = item.titleCompulsary ? item.title : (data[item.key]?.doc_name || data[item.key]?.name || item.title)
      row[1] =
        <div className="w-100">
          <FileInput name={item.key} value={data[item.key]} onChange={handleFile}
            onUploadCancel={() => { onUploadCancel(item.key) }}
            isEditable={selectedItem?.agreementSignType === "Wet" && !isAdminUser && !item.disableEdit} changeEditIconToUpload={true}
          />
        </div>
      row[2] =
        <div className="signStatusDiv">

          <div className="w-15">
            {selectedItem.isBuyer ? (
              <>
                {agreementConfiguration[item.configurationKey + "ToExporter"] ? (
                  <>
                    {docSignStatus?.[item.key]?.["signatureId"] ? (
                      <img src={"assets/images/green_tick.png"} />
                    ) : null}
                  </>
                ) :
                  <label className="font-wt-600 font-size-12 ">N/A</label>}
              </>
            ) : (
              <>
                {(docSignStatus?.[item.key]?.["signatureId"] || item.expSignStatus) ? (
                  <img src={"assets/images/green_tick.png"} />
                ) :
                  ((selectedItem.isBuyer || isAdminUser) ? null : (
                    <img
                      onClick={() => handleSignPopup(item.key, data[item.key])}
                      src={"assets/images/supplier-images/fluent_signature-20-regular.png"} className="cursor" />
                  ))}
              </>
            )}
          </div>

          <div className="w-15">
            {agreementConfiguration?.[item.configurationKey + "ToBuyer"] ? (
              <>
                {docSignStatus?.[item.key]?.["buyerSignatureId"] ? (
                  <img src={"assets/images/green_tick.png"} />
                ) : (selectedItem.isBuyer ? (
                  <img
                    onClick={() => handleSignPopup(item.key, data[item.key])}
                    src={"assets/images/supplier-images/fluent_signature-20-regular.png"} className="cursor" />
                ) : null)}
              </>
            ) :
              <label className="font-wt-600 font-size-12 ">N/A</label>}
          </div>

          <div className="w-15">
            {(docSignStatus?.[item.key]?.["financierSignatureId"] || item.finSignStatus) ? (
              <img src={"assets/images/green_tick.png"} />
            ) : null}
          </div>

        </div>
      tableData.push(row)
      row = []

    }

    // console.log("ittttttttttttttttttt", tableData);

    return tableData

  }



  async function getInvoice() {
    return new Promise(resolve => {
      let viewOnlyData = {}
      call('POST', 'getInvDiscoutingDetails', { invNo: selectedItem.invRefNo }).then(async (result) => {
        // console.log('Invoice Get Discounting API  =====>', result);
        if (result && result.length) {
          let temp = result[0]
          let docsId = temp["tbl_doc_id"].split(",")

          viewOnlyData["tcAccepted"] = true
          viewOnlyData["docsId"] = docsId
          viewOnlyData["invoiceDocument"] = await getDocDetails(docsId[0])
          viewOnlyData["poDocument"] = await getDocDetails(docsId[1])
          viewOnlyData["logisticDocument"] = await getDocDetails(docsId[2])
          viewOnlyData["invReportDocument"] = await getDocDetails(docsId[3])
          viewOnlyData["grnDocument"] = await getDocDetails(docsId[4])
          viewOnlyData["packingListDocument"] = await getDocDetails(docsId[5])
          viewOnlyData["shippingBillDocument"] = await getDocDetails(docsId[6])

          viewOnlyData["poRefrenceNo"] = temp["po_no"]
          viewOnlyData["invoiceRefrenceNo"] = temp["reference_no"]
          viewOnlyData["beneficiaryBankName"] = temp["bankList"].split(":")[0] / 1
          viewOnlyData["beneficiaryBankTitle"] = temp["bankList"].split(":")[1]
          viewOnlyData["currency"] = currencyData.filter(i => {
            if (i.id / 1 == temp["currency"] / 1) {
              return i
            }
          })[0]["code"]
          viewOnlyData["contractAmount"] = temp["contract_amount"]
          viewOnlyData["invoiceIssueDate"] = temp["issue_date"]
          viewOnlyData["invoiceDueDate"] = temp["due_date"]
          viewOnlyData["reqAmount"] = temp["amount"]
          viewOnlyData["lenderId"] = temp["lender_id"] / 1
          viewOnlyData["creditDays"] = temp["credit_days"]

          viewOnlyData["shipmentOriginCountry"] = temp["shipment_origin_country"]
          viewOnlyData["shipmentOriginAddress"] = temp["shipment_origin_address"]
          viewOnlyData["shipmentOriginCity"] = temp["shipment_origin_city"]
          viewOnlyData["shipmentOriginPostalCode"] = temp["shipment_origin_postal_code"]

          viewOnlyData["shipmentDestinationCountry"] = temp["shipment_destination_country"]
          viewOnlyData["shipmentDestinationAddress"] = temp["shipment_destination_address"]
          viewOnlyData["shipmentDestinationCity"] = temp["shipment_destination_city"]
          viewOnlyData["shipmentDestinationPostalCode"] = temp["shipment_destination_postal_code"]

          resolve(viewOnlyData)
        }
      }).catch((e) => {
        resolve({})
        // console.log('error in Invoice Get Discounting API  =====>', e);
      })
    })
  }


  const closeSuccess = () => {
    setLimitinvoice(false);
  }



  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")) || (file_type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")) || (file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png, jpeg, docx & xlsx extension are allowed" })
        return
      }
      // if (event.target.name.includes("otherDoc")) {
      //   setAddMoreDoc(addMoreDoc.concat(null))
      // }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (file_type.includes("png") || file_type.includes("jpeg")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') || file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
          try {
            setshowLoader(true)
            let formData = new FormData()
            formData.append("file", fileObj)
            if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
              formData.append("docType", "docx")
            }
            if (file_type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
              formData.append("docType", "xlsx")
            }
            let apiResp = await call('POST', "docToPdf", formData)
            let blob = dataURItoBlob(apiResp);
            fileObj = new File([blob], fileObj.name + "converted.pdf", { type: "application/pdf" });
            fileDataUrl = apiResp
            toastDisplay("File converted into pdf format", "success")
          } catch (error) {
            return toastDisplay("failed to convert file", "error")
          }
          setshowLoader(false)
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  async function handleValidation(tabIndex) {

    if (tabIndex != undefined) {
      if (tabIndex < tab) {
        return setTab(tabIndex)
      }
      else if (tabIndex == tab) {
        return null
      }
      else {
        return toastDisplay("Click on continue button to go to next form", "info")
      }
    }

    let validateFields = []
    let err = {}

    validateFields.forEach((item) => {
      if (!data[item]) {
        err[item] = "Mandatory Field"
      }
    })

    if (!Object.keys(err).length) {
      setTab(tab + 1)
    }
    setErrors(err)
  }

  async function uploadSignedDocumentAndReload(updatedDoc) {
    setshowLoader(true)
    let formData = new FormData()
    formData.append("fileToSign", docSignPopup.docSignName)
    formData.append(docSignPopup.docSignName, updatedDoc)
    formData.append("signatureId", updatedDoc.signatureId)
    formData.append("docId", data.docSignStatus[docSignPopup.docSignName]["id"])
    if (selectedItem.isBuyer) {
      formData.append("isBuyer", true)
    }
    formData.append("invRefNo", selectedItem.invRefNo)
    await call("POST", 'signInvoiceDiscountingAgreement', formData)
    setshowLoader(false)
    window.location.reload()
  }

  return (
    <>
      <DocumentViewer show={viewDoc.show} value={viewDoc.doc} dismiss={() => toggleViewDoc({ show: false, doc: {} })} />
      {selectedItem?.applicationId ? (
        <>
          <AddMoreDocumentHandler hide={true}
            purpose={"ApplyForInvoiceFinance"} purposeId={selectedItem.applicationId}
            userTokenDetails={userTokenDetails} onChange={(docs) => setMoreDocsArray(docs)}
          />
          <AddMoreDocumentHandler hide={true}
            purpose={"SendTermSheet"} purposeId={selectedItem.applicationId}
            userTokenDetails={userTokenDetails} onChange={(docs) => setMoreDocsArray2(docs)}
          />
        </>
      ) : null}
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          {selectedItem.isBuyer ? null : (
            <SideBarV2 state="invoice-agreement" userTokenDetails={userTokenDetails} />
          )}
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            {selectedItem.isBuyer ? null : (
              <>
                <HeaderV2
                  title={isAdminUser ? localStorage.getItem("headerBreadCum") : "Sign Agreement"}
                  userTokenDetails={userTokenDetails} />
                <a className="cursor"
                  onClick={() => { history.go(-1) }}
                // href={isAdminUser ? "/invoiceFinance" : "/invoice-agreement"}
                ><img className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
              </>
            )}
            <div className={selectedItem.isBuyer ? "mt-5" : ''}>
              <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                {addBuyerTabs.map((item, index) => {
                  return (
                    <li>
                      <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                        onClick={() => {
                          if (isAdminUser) {
                            setTab(index)
                          }
                          else {
                            handleValidation(index)
                          }
                        }}
                      >
                        {item.name}</a>
                    </li>
                  )
                })}
              </ul>
              <div className="card mt-1">

                {tab === 0 ? (
                  <div className='px-5 py-4 pt-5'>
                    <div className="row">
                      <div className='w-100'>
                        <label className='font-wt-500 font-size-16'><u>Application Details</u></label>
                        <div className='row'>
                          {reviewForm.map((item) => {
                            return (
                              <div className='col-5 p-0'>
                                <label className='col-6 lh-18 text-color-label font-size-14'>{item.name}</label>
                                <label className='lh-18 font-size-14'>:</label>
                                <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                  {(item.val === "applicantName" && userTypeId / 1) ? (selectedItem.supplierName) : data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}
                                </label>
                              </div>
                            )
                          })}
                          {isLenderModifi ? reviewForm2.map((item) => {
                            return (
                              <div className='col-5 p-0'>
                                <label className='col-6 lh-18 text-color-label font-size-14'>{item.name}</label>
                                <label className='lh-18 font-size-14'>:</label>
                                <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                  {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}
                                </label>
                              </div>
                            )
                          }) : null}
                        </div>
                      </div>

                      <div className='w-100 mt-4'>
                        <label className='font-wt-500 font-size-16'><u>Total Charges</u></label>
                        <div className='col-5 p-0'>
                          <label className='col-6 lh-18 text-color-label font-size-14 p-0'>{"Factoring Rate"}</label>
                          <label className='lh-18 font-size-14'>:</label>
                          <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                            {finalCharges["factoring"] || "NA"}
                          </label>
                        </div>
                        <div className='col-5 p-0'>
                          <label className='col-6 lh-18 text-color-label font-size-14 p-0'>{"Interest Rate"}</label>
                          <label className='lh-18 font-size-14'>:</label>
                          <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                            {finalCharges["interest"] || "NA"}
                          </label>
                        </div>
                        <div className='col-5 p-0'>
                          <label className='col-6 lh-18 text-color-label font-size-14 p-0'>{"Setup Fees (One Time Only)"}</label>
                          <label className='lh-18 font-size-14'>:</label>
                          <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                            {finalCharges["oneTime"] || "NA"}
                          </label>
                        </div>
                        {finalCharges.otherCharges && finalCharges.otherCharges.length ? finalCharges.otherCharges.map((item, index) => {
                          return (
                            <div className='col-5 p-0'>
                              <label className='col-6 lh-18 text-color-label font-size-14 p-0'>{selectedQuote.otherCharges[index]["name"]}</label>
                              <label className='lh-18 font-size-14'>:</label>
                              <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                {item["OtherCharge" + (index + 1)] || "NA"}
                              </label>
                            </div>
                          )
                        }) : null}
                        <div className='col-5 p-0'>
                          <label className='col-6 lh-18 text-color-label font-size-14 p-0'>{"Total Payable"}</label>
                          <label className='lh-18 font-size-14'>:</label>
                          <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                            {finalCharges["total"] || "NA"}
                          </label>
                        </div>
                      </div>

                      {!isAdminUser ?
                        <div className='w-100 mt-5'>
                          <label className='font-wt-500 font-size-16'><u>Documents</u></label>

                          <div className='row mt-2'>

                            {data.invoiceDocument && data.invoiceDocument.filebase64 &&
                              <div className="col-md-4">
                                <label className="font-size-14">Invoice Document</label>
                                <div className="row form-group">
                                  <div className="col-md-12">
                                    <FileInput name={"invoiceDocument"} value={data.invoiceDocument}
                                      isEditable={false}
                                    />
                                  </div>
                                </div>
                              </div>
                            }

                            {data.poDocument && data.poDocument.filebase64 &&
                              <div className="col-md-4">
                                <label className="font-size-14">PO Document</label>
                                <div className="row form-group">
                                  <div className="col-md-12">
                                    <FileInput name={"poDocument"} value={data.poDocument}
                                      isEditable={false}
                                    />
                                  </div>
                                </div>
                              </div>
                            }

                            {data.logisticDocument && data.logisticDocument.filebase64 &&
                              <div className="col-md-4">
                                <label className="font-size-14">Bill of Lading/Airway Bill</label>
                                <div className="row form-group">
                                  <div className="col-md-12">
                                    <FileInput name={"logisticDocument"} value={data.logisticDocument}
                                      isEditable={false}
                                    />
                                  </div>
                                </div>
                              </div>
                            }

                            {data.invReportDocument && data.invReportDocument.name ? (
                              <div className="col-md-4">
                                <label className="font-size-14">Investigation Report</label>
                                <div className="row form-group">
                                  <div className="col-md-12">
                                    <FileInput name={"invReportDocument"} value={data.invReportDocument}
                                      isEditable={false}
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : null}

                            {data.grnDocument && data.grnDocument.name ? (
                              <div className="col-md-4">
                                <label className="font-size-14">Certificate of Origin</label>
                                <div className="row form-group">
                                  <div className="col-md-12">
                                    <FileInput name={"grnDocument"} value={data.grnDocument}
                                      isEditable={false}
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : null}

                            {moreDocsArray?.length ? moreDocsArray.map((item, index) => {
                              if (item?.name) {
                                return (
                                  <div className="col-md-4">
                                    <label className="font-size-14">{item.doc_name}</label>
                                    <div className="row form-group">
                                      <div className="col-md-12">
                                        <FileInput value={item}
                                          isEditable={false}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )
                              }
                            }) : null}

                            {data.termSheetDocument && data.termSheetDocument.name &&
                              <div className="col-md-4">
                                <label className="font-size-14">Term Sheet</label>
                                <div className="row form-group">
                                  <div className="col-md-12">
                                    <FileInput name={"termSheetDocument"} value={data.termSheetDocument}
                                      isEditable={false}
                                    />
                                  </div>
                                </div>
                              </div>
                            }

                            {moreDocsArray2?.length ? moreDocsArray2.map((item, index) => {
                              if (item?.name) {
                                return (
                                  <div className="col-md-4">
                                    <label className="font-size-14">{item.doc_name}</label>
                                    <div className="row form-group">
                                      <div className="col-md-12">
                                        <FileInput value={item}
                                          isEditable={false}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )
                              }
                            }) : null}

                            {/* <div className='mt-3'>
                            <img
                              onClick={() => setData({ ...data, tcAccepted: !data.tcAccepted })}
                              className='cursor mr-3' src={`assets/images/${data.tcAccepted ? 'checked-green' : 'empty-check'}.png`} />
                            <label>I hereby declare that the above given information by me is true to my knowledge.</label>
                          </div> */}
                          </div>
                        </div> : null}

                    </div>
                  </div>
                ) : null}

                {tab === 1 ? (
                  <>
                    {(selectedItem?.agreementSignType === "Wet" && !isAdminUser) ? (
                      <div class="text-danger mt-4 pt-4 mx-5 font-size-12">
                        <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                        <b>{"Upload Signed & Stamped Document"}</b>
                      </div>
                    ) : null}
                    <div className='px-5 py-4 pt-5 position-relative'>
                      <NewTable
                        disableAction={true}
                        columns={[{ name: "Document Name" }, { name: "Documents Attached" },
                        { name: "Signed By", subColumns: ["Supplier", "Buyer", "Financier"] }]}
                        data={tableData} />
                    </div>
                  </>
                ) : null}

                {!isAdminUser ? <>
                  {tab === 1 ? null : (
                    <div className=" pb-5 px-5">
                      <button type="button"
                        onClick={() => handleValidation(undefined)}
                        className={`new-btn w-20 py-2 px-2 text-white`}>
                        {"Continue"}
                      </button>
                    </div>
                  )}</> : null}

                {tab === 2 && isAdminUser ? (
                  <div className='px-5 pt-4 pb-4'>
                    <div className="row align-items-center mt-3">
                      {buyers_credit?.length ? buyers_credit.map((key, index) => {
                        return (
                          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 my-3">
                            <div className={`text-center card py-4 pb-0 px-3
                              ${key.financierAction != "Approved" ? "limitdeniedcontract" : "selectfinancercardrec"}`}
                            >
                              <>
                                <p className="cursor mt-2 mb-1 d-flex flex-row justify-content-center align-items-center"><img className="mr-2"
                                  src={key.logo} alt="" />
                                  <span className="font-size-14 font-wt-600 text-dark">{key.lender_name}</span>
                                  <div className="" style={{ position: "relative", zIndex: "100002", backgroundColor: 'white', marginLeft: 2, borderRadius: 1000, padding: 10 }} >
                                    <img
                                      onClick={async () => {
                                        localStorage.setItem("financeLimit", key.financeLimit)
                                        localStorage.setItem("lenderId", key.lender_id)
                                        localStorage.setItem("buyerId", data.id)
                                        localStorage.setItem("buyerData", JSON.stringify({
                                          buyerName: data.buyerName, buyerPosition: data.buyerPosition,
                                          nameAuthorizedSignatory: data.nameAuthorizedSignatory, buyerEmail: data.buyerEmail,
                                          buyerPhoneCode: data.buyerPhoneCode, buyerPhone: data.buyerPhone,
                                          buyerIdentifier: data.identifier, incoTerms: data.incoTerms,
                                          buyerWebsite: data.buyerWebsite, buyerAddress: data.buyerAddress,
                                          buyerCountry: data.buyerCountry, buyerDUNSNo: data.buyerDUNSNo,
                                          previousAnnualSale: data.previousAnnualSale, currentAnnualSale: data.currentAnnualSale,
                                          termsOfPayment: data.termsOfPayment, productDetails: data.productDetails,
                                          buyersDocId: data.buyersDocId
                                        }))
                                        localStorage.setItem("lenderName", key.lender_name)
                                        localStorage.setItem("lenderRemark", key.assignRemark)
                                        setshowLoader(true)
                                        let additionalReqResp = await call('POST', 'isAdditionalDetailsReqByFinancer', {
                                          lenderIdArr: [{ lender_id: key.lender_id }],
                                          applicationId: selectedItem.applicationId
                                        })
                                        // console.log("additionalReqResp========", additionalReqResp);
                                        setshowLoader(false)
                                        if (additionalReqResp?.[0]) {
                                          localStorage.setItem("additionalReqObj", JSON.stringify(additionalReqResp?.[0]))
                                        }
                                        else {
                                          localStorage.removeItem("additionalReqObj")
                                        }
                                        window.location = `/docsRequested`
                                      }}
                                      src={"assets/images/warning.png"} alt="" />
                                  </div>
                                </p>
                              </>
                              {key.financierAction != "Approved" ? (
                                <>
                                  <div className="limitDeniedCard">
                                    <div className="mb-2">
                                      <img src={"assets/images/block.png"} height={25} width={25} />
                                    </div>
                                    <div className="font-size-14 font-wt-600">
                                      Limit Denied
                                    </div>
                                  </div>
                                </>)
                                : null}

                              {key.financierAction != "Approved" ? <></> : (
                                <>
                                  <p className="font-size-12 mb-1 font-wt-600 text-color1">
                                    {`Limit ${key.financeLimit} ${key.financeLimitCurrency} upto ${key.discountingPercentage} % of invoice`}</p>
                                  <p className="font-size-14 mb-1">
                                    {`Offer Validity : ${key.offerValidity}`}</p>
                                  <p style={{ width: "70%", margin: "auto", borderBottom: "2px solid #5CB8D3" }} />
                                  <div className="mt-2 lineheight19">
                                    <p className="mb-0 text-secondary  font-size-12">Factoring Fees</p>
                                    <p className="font-size-16">{key.factoringFees ? (key.factoringFees + " " + key.factoringFeesCurrency) : "NA"}</p>
                                  </div>
                                  <div className="mt-0 lineheight19">
                                    <p className="mb-0 font-wt-600 font-size-12">Interest Rate
                                    </p>
                                    <span className="selectfinacne-subheading font-size-10">{"(Inclusive TERM for " + key.termSofr + ")"}</span>
                                    <p className="font-size-16">{key.interestRate ? (key.interestRate + " %") : "NA"}</p>
                                  </div>
                                  <div className="mt-0 lineheight19">
                                    <p className="mb-0 font-wt-600 font-size-12">One time set-up fees</p>
                                    <p className="font-size-16">{key.setupFees ? (key.setupFees + " " + key.setupFeesCurrency) : "NA"}</p>
                                  </div>

                                  <div className="position-relative lineheight19">
                                    <p
                                      className="mb-0 font-wt-600 font-size-12">Other Charges
                                    </p>
                                    <p className="font-size-16 totalChargesLabel">{getInvoiceTotalOtherCharges(key.otherCharges).toFixed(2) + " " + key.financeLimitCurrency}</p>
                                    <div className="otherChargePopup">
                                      {key.otherCharges && key.otherCharges.length ? key.otherCharges.map((j) => (
                                        <>
                                          <div className="border-bottom d-flex px-3 justify-content-between align-items-center pt-2">
                                            <label className="text-color-label">{j.name}</label>
                                            <label className="font-wt-600 text-color-value">{j.amount + " " + j.unit}</label>
                                          </div>
                                        </>
                                      )) : null}
                                    </div>
                                  </div>
                                  <div className="mt-0 lineheight19">
                                    <p className="mb-0 font-wt-600 font-size-12">Total Charges</p>
                                    <span className="selectfinacne-subheading font-size-10">($ 50,000/30 days)</span>
                                    <p className="font-size-16">{getInvoiceTotalCharges(key).toFixed(2) + " " + key.financeLimitCurrency}</p>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        );
                      }) : null}

                    </div>
                  </div>
                ) : null}

                {tab === 3 ? (
                  <>
                    <div className='px-5 py-4 pt-5 position-relative'>
                      <NewTable
                        disableAction={true}
                        columns={[{ name: "Scheduled Date" }, { name: "Scheduled Amount" },
                        { name: "Disbursed Date" }, { name: "Disbursed Amount" },
                        { name: "Status" }, { name: "" }]}
                        data={tableDisbursementData} />
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </main>
        </div>
      </div>
      {signdoc && <SignDocument isBuyerSigning={selectedItem.isBuyer} buyerUserId={selectedItem.id} signdoc={signdoc} setSigndoc={setSigndoc} docToSign={docSignPopup.docToSign}
        setUpdatedDoc={(updatedDoc) => uploadSignedDocumentAndReload(updatedDoc)} />}
      {limitinvoice && <FinanceInvoiceModal limitinvoice={limitinvoice} setLimitinvoice={setLimitinvoice} closeSuccess={closeSuccess} >
        <img src={"/assets/images/upload_done.png"} alt="" className="mb-3" />
        <p>Your application for “Invoice discounting” for “Rosti Suzhou Integrated” has been submitted successfully.</p>
      </FinanceInvoiceModal>}
    </>
  );
}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(SignAgreement)