

import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap'
import call from '../../service';
import ReactCountryFlag from "react-country-flag";
import { formatDate_Application, formatDateTime_Application } from '../../utils/dateFormaters';
import NewTablev2 from '../../utils/newTablev2';


const ContractTrail = ({ userTokenDetails, contractNo, modal }) => {
  //---------------------------------------------------------------------------------------------------------------------
  //States
  const [Data, setData] = useState({});
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // use effects
  useEffect(() => {
    //------------------------------------------------------------------
    //User details from cookie
    //------------------------------------------------------------------

    //------------------------------------------------------------------
    //API Calls
    call('POST', 'getcontractchangelogs', { "contract_number": contractNo }).then(async (result) => {
      console.log("result in getcontractchangelogs-->", result)
      setData(result)
    }).catch((error) => {
      console.log("error occur in getcontractchangelogs ->", error)
    })

    //------------------------------------------------------------------

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //---------------------------------------------------------------------------------------------------------------------
  // Handlers
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Specification Form Components
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Submit handler
  //---------------------------------------------------------------------------------------------------------------------

  return (<>
    <div className="mt-2">
      <label className="mt-1 mb-3 font-size-16 font-wt-500" >Change logs</label>
      <div style={{ overflowX: 'auto' }} >
        <Table responsive>
          <thead>
            <tr>
              <th><label className='font-size-12 font-wt-600' >#</label></th>
              <th><label className='font-size-12 font-wt-600' >Contract No</label></th>
              <th><label className='font-size-12 font-wt-600' >Contract Name</label></th>
              <th><label className='font-size-12 font-wt-600 mr-3' >Commodity</label></th>
              <th><label className='font-size-12 font-wt-600' >Laycan Start</label></th>
              <th><label className='font-size-12 font-wt-600' >Laycan End</label></th>
              <th><label className='font-size-12 font-wt-600 mr-3' >Payment Mode</label></th>
              <th><label className='font-size-12 font-wt-600 mr-3' >Price Type</label></th>
              <th><label className='font-size-12 font-wt-600' >Price</label></th>
              <th><label className='font-size-12 font-wt-600 mr-3' >Currency</label></th>
              <th><label className='font-size-12 font-wt-600 mr-3' >Quantity</label></th>
              {/* <th>Exporter's Inv. Agency</th>
              <th>Importer's Inv. Agency</th>
              <th>Third Party Inv. Agency</th>
              <th>Trade Insurer</th>
              <th>Commodity Insurer</th> */}
              <th><label className='font-size-12 font-wt-600 mr-3' >Ship Owner</label></th>
              <th><label className='font-size-12 font-wt-600 mr-3' >Load Country</label></th>
              <th><label className='font-size-12 font-wt-600 mr-3' >Loading Port</label></th>
              <th><label className='font-size-12 font-wt-600 mr-3' >Unload Country</label></th>
              <th><label className='font-size-12 font-wt-600 mr-3' >Unloading Port</label></th>
              <th><label className='font-size-12 font-wt-600 mr-3' >Commodity Specifications</label></th>
              <th><label className='font-size-12 font-wt-600 mr-3' >Contract TNC</label></th>
              <th><label className='font-size-12 font-wt-600' >Dated</label></th>
              <th><label className='font-size-12 font-wt-600' >Changes By</label></th>
            </tr>
          </thead>
          <tbody>
            {(Data && Data.length) ? Data.map((value, index) => {
              return (<tr>
                <td><label className='font-size-12 font-wt-400' >{index}</label></td>
                <td><label className='font-size-12 font-wt-400' >{value.contract_number ? value.contract_number : "-"}</label></td>
                <td><label className='font-size-12 font-wt-400' >{value.contract_name ? value.contract_name : "-"}</label></td>
                <td><label className='font-size-12 font-wt-400 mr-3' >{value.commodity_name ? value.commodity_name : "-"}</label></td>
                <td><label className='font-size-12 font-wt-400' >{value.laycan_start_clog ? formatDate_Application(value.laycan_start_clog) : "-"}</label></td>
                <td><label className='font-size-12 font-wt-400' >{value.laycan_end_clog ? formatDate_Application(value.laycan_end_clog) : "-"}</label></td>
                <td><label className='font-size-12 font-wt-400' >{value.payment_mode_clog ? value.payment_mode_clog : "-"}</label></td>
                <td><label className='font-size-12 font-wt-400' >{value.price_type_clog ? value.price_type_clog : "-"}</label></td>
                <td><label className='font-size-12 font-wt-400' >{value.price_clog ? value.price_clog : "-"}</label></td>
                <td><label className='font-size-12 font-wt-400' >{value.currency_clog ? value.currency_clog.split(':')[1] : "-"}</label></td>
                <td><label className='font-size-12 font-wt-400' >{value.quantity_clog ? value.quantity_clog : "-"}</label></td>
                {/* <td>{value.agencynameExporter ? value.agencynameExporter : "-"}</td>
                <td>{value.agencynameImporter ? value.agencynameImporter : "-"}</td>
                <td>{value.agencynameThird ? value.agencynameThird : "-"}</td>
                <td>{value.tradeinsurer ? value.tradeinsurer : "-"}</td>
                <td>{value.commodityInsurer ? value.commodityInsurer : "-"}</td> */}
                <td><label className='font-size-12 font-wt-400' >{value.shipOwner ? value.shipOwner : "-"}</label></td>
                <td>{value.load_country_clog ? <span className="shadow">
                  <ReactCountryFlag
                    countryCode={value.load_country_clog.split(':')[0]}
                    style={{ width: '25px', height: '25px' }} svg />
                </span> : "-"}</td>
                <td><label className='font-size-12 font-wt-400' >{value.loading_port_clog ? value.loading_port_clog : "-"}</label></td>
                <td>{value.unload_country_clog ? <span className="shadow">
                  <ReactCountryFlag
                    countryCode={value.unload_country_clog.split(':')[0]}
                    style={{ width: '25px', height: '25px' }} svg />
                </span> : "-"}</td>
                <td><label className='font-size-12 font-wt-400' >{value.unloading_port_clog ? value.unloading_port_clog : "-"}</label></td>
                <td><label className='font-size-12 font-wt-400' >{value.specification_clog > 0 ? "Updated" : "-"}</label></td>
                <td><label className='font-size-12 font-wt-400' >{value.tnc_clog > 0 ? "Updated" : "-"}</label></td>
                <td><label className='font-size-12 font-wt-400' >{value.created_at_clog ? formatDateTime_Application(value.created_at_clog) : "-"}</label></td>
                <td><label className='font-size-12 font-wt-400' >{value.changingParty ? value.changingParty : "-"}</label></td>
              </tr>
              )
            }) : ""}
          </tbody>
        </Table>
      </div>


    </div>
  </>)
}

//---------------------------------------------------------------------------------------------------------------------
// Redux

//---------------------------------------------------------------------------------------------------------------------


export default ContractTrail
