import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import SideBarV2 from "../partial/sideBarV2";
import HeaderV2 from "../partial/headerV2";
import { connect } from "react-redux";
import NewTablev2 from "../../utils/newTablev2";
import { bgGetConfirmationDocs } from "../Dashboard/TableJson";
import { FileInput } from "../../utils/FileInput";
import { astrix, financialYearMonths, handleFileCommonFunction, handleMultipleFile, mergeMultiplePdfs, most_used_currencies, multiSelectEventHandler } from "../../utils/myFunctions";
import { DocumentViewer } from "../../utils/documentViewer";
import { DocumentDownloader } from "../../utils/DocumentDownloader";
import SignDocument from "../InvoiceDiscounting/components/SignDocument";
import { InputForTable, InputWithSelect, InputWithSelectForTable, NewInput, NewSelect, SelectForTable } from "../../utils/newInput";
import { useEffect } from "react";
import avatarUrl from "../../utils/makeAvatarUrl";
import { environment, platformBackendUrl } from "../../urlConstants";
import call from "../../service";
import toastDisplay from "../../utils/toastNotification";
import NewRegistration, { industryData } from "../registration/newRegistration";
import moment from "moment";
import axios from "axios";
import ChatBoxPopUp from "../chatRoom/components/ChatBoxPopUp";
import FinanceInvoiceModal from "../InvoiceDiscounting/contract/components/financeinvoiceModal";
import ExtraDetailsForLimitApplication from "../InvoiceDiscounting/extraDetailsForLimitApplication";
import Papa from 'papaparse';
import MultipleSelect from "../../utils/MultipleSelect";
import { getTraderType, setTraderType } from "../../utils/cookieHelper";
import { IncoTerms } from "../UserOnboard/BuyersTab";
import ReactCountryFlag from "react-country-flag";
import MultipleSelectForTable from "../../utils/MultipleSelectForTable";

const ShipmentBookingMarketPlace = ({ userTokenDetails, navToggleState }) => {

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;
  const user_avatar = userTokenDetails.userAvatar ? userTokenDetails.userAvatar : null

  let stops = [{ distInPer: '20%' }, { distInPer: '10%' }, { distInPer: '30%', isStop: true }, { distInPer: '10%', isStop: true }, { distInPer: '10%' }, { distInPer: '20%' }, {
    distInPer: '0%'
  }]

  let containerTypes = [{ name: '20’ Standard' }, { name: '40’ Standard' }, { name: '40’ High Cube' }, { name: '45’ High Cube' }
    , { name: '20’ Refrigerated' }, { name: '40’ Refrigerated' }]

  const [showLoader, setshowLoader] = useState(false)
  const [tab, setTab] = useState(0)
  const [data, setData] = useState({
    quantityUnit: 'Tonne'
  })
  const [errors, setErrors] = useState({})
  const [commList, setCommList] = useState([])
  const [ports, setPorts] = useState([])
  const [countryData, setcountryData] = useState([]);
  const [commCategory, setCommCategory] = useState([]);
  const [supplierCommodityList, setsupplierCommodityList] = useState([]);
  const [currencyData, setcurrencyData] = useState([]);
  const [ShipperList, setShipperList] = useState([])
  const [reviewScrollIndex, toggleReviewScrollIndex] = useState(0)
  const [seeReview, setSeeReview] = useState({})

  const [shipmentData, setShipmentData] = useState([null, null, null])

  useEffect(() => {
    getPorts()
  }, [])

  async function getPorts() {
    setshowLoader(true)
    let apiResp = await call('POST', 'getOldPortMaster', {})
    // console.log("aaaaaaaaaaaaaaaaaaaaaaaa", apiResp);
    let tempPorts = apiResp
    for (let index = 0; index < tempPorts.length; index++) {
      tempPorts[index][`html`] = <div>
        <ReactCountryFlag
          countryCode={tempPorts[index].sortname}
          style={{ width: '20px', height: '20px', marginRight: '1rem' }} svg />
        <label className="font-size-12 font-wt-500" >{`${tempPorts[index]["port_name"]} (${tempPorts[index]["name"]})`}
        </label>
      </div>
    }
    setshowLoader(false)
    setPorts([...tempPorts])
  }

  const handleFilterOptions = (typedInput, name) => {
    // console.log("typedInput", typedInput);
    let filteredPorts = []
    if (name.includes("Port")) {
      if (typedInput) {
        filteredPorts = ports.filter((i) => {
          if (i.port_name.toLowerCase().includes(typedInput.toLowerCase()) || i.name.toLowerCase().includes(typedInput.toLowerCase())) {
            return true
          }
        })
      }
      else {
        filteredPorts = ports
      }
      setPorts([...filteredPorts])
    }
  }

  const handleChange = async (event) => {
    if (event.persist) {
      event.persist()
    }
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }

  return (
    <div>

      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />

      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="ShipmentBookingMarketPlace" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Shipment Booking > Market Place"}
              userTokenDetails={userTokenDetails} />

            <div>
              <div className='card shipmentBookingSearchCard d-flex py-2 flex-row'>
                <div
                  style={{ borderRight: '1px solid #ABABAB' }}
                  className="w-50 px-3" >
                  <label
                    className="font-size-14 font-wt-500 textColorABABAB"
                  >ORIGIN & DESTINATION PORT {astrix}</label>
                  <div
                    className="d-flex row align-items-center"
                  >
                    <div className="w-45" >
                      <MultipleSelectForTable
                        filterOption={() => true}
                        onInputChange={(e) => {
                          handleFilterOptions(e, "originPort")
                        }}
                        Label={""}
                        Id={`originPort`}
                        optiondata={ports}
                        onChange={(e) => {
                          handleChange(multiSelectEventHandler(e, `originPort`, "id"))
                        }}
                        value={data[`originPort`] ? [data[`originPort`]] : []}
                        name={`originPort`}
                        labelKey={"html"}
                        valKey={"id"}
                        error={errors[`originPort`]}
                      />
                    </div>
                    <div className="w-10 d-flex justify-content-center" >
                      <img src="assets/images/twoWayArrow.png" className="cursor" />
                    </div>
                    <div className="w-45">
                      <MultipleSelectForTable
                        filterOption={() => true}
                        onInputChange={(e) => {
                          handleFilterOptions(e, "originPort")
                        }}
                        Label={""}
                        Id={`originPort`}
                        optiondata={ports}
                        onChange={(e) => {
                          handleChange(multiSelectEventHandler(e, `originPort`, "id"))
                        }}
                        value={data[`originPort`] ? [data[`originPort`]] : []}
                        name={`originPort`}
                        labelKey={"html"}
                        valKey={"id"}
                        error={errors[`originPort`]}
                      />
                    </div>

                  </div>
                </div>

                <div className="px-3" style={{ width: '16%', borderRight: '1px solid #ABABAB' }}>
                  <label
                    className="font-size-14 font-wt-500 textColorABABAB"
                  >SHIPMENT DATE {astrix}</label>
                  <div className="mt-2" >
                    <InputForTable type={"date"} name={"shipmentDate"} value={data.shipmentDate} onChange={handleChange} error={errors.shipmentDate} />
                  </div>
                </div>

                <div className="w-15 px-3" style={{ borderRight: '1px solid #ABABAB' }}>
                  <label
                    className="font-size-14 font-wt-500 textColorABABAB"
                  >MODE {astrix}</label>
                  <div className="mt-0"  >
                    <MultipleSelectForTable
                      Label={""}
                      Id={`shipmentMode`}
                      optiondata={[{ name: 'FCL' }, { name: 'LCL' }]}
                      onChange={(e) => {
                        handleChange(multiSelectEventHandler(e, `shipmentMode`, "name"))
                      }}
                      value={data[`shipmentMode`] ? [data[`shipmentMode`]] : []}
                      name={`shipmentMode`}
                      labelKey={"name"}
                      valKey={"name"}
                      error={errors[`shipmentMode`]}
                    />
                  </div>
                </div>

                <div className="px-3" style={{ width: '19%' }}>
                  <label
                    className="font-size-14 font-wt-500 textColorABABAB"
                  >PRODUCT</label>
                  <div className="mt-2"  >
                    <InputForTable name={"shipmentProduct"} value={data.shipmentProduct} onChange={handleChange} error={errors.shipmentProduct} />
                  </div>
                </div>

                <div className="w-auto mt-2 px-3" >
                  <img src="assets/images/shipmentSearchIcon.png" className="cursor" />
                </div>
              </div>

              <div className="row d-flex mt-4" >
                <label className="font-size-16 font-wt-500" ><u>Search Results</u></label>
                <div style={{ width: '22%' }} >
                  <div className="card py-3" >
                    <div
                      style={{ borderBottom: '2px solid #D5D5D5' }}
                      className="d-flex mx-4 pb-2" >
                      <div>
                        <img src="assets/images/filter.png" /></div>
                      <label className="font-size-16 font-wt-500 ml-3" >Filter</label>
                    </div>

                    <div className="py-4 mx-4"
                      style={{ borderBottom: '2px solid #D5D5D5' }}>
                      <label className="font-size-16 font-wt-600" >Container Type</label>
                      {containerTypes.map((i, j) => {
                        return (
                          <div>
                            <img
                              onClick={() => {
                                setData({ ...data, containerType: i.name })
                              }}
                              className="mr-3 cursor" src={`assets/images/${data.containerType === i.name ? 'bullet_box' : 'empty_bullet_box'}.svg`} />
                            <label className="font-size-14 font-wt-400">{i.name}</label>
                          </div>
                        )
                      })}
                    </div>

                    {/* <div className="py-4 mx-4"
                      style={{ borderBottom: '2px solid #D5D5D5' }}>
                      <label className="font-size-16 font-wt-600" >Included Services</label>
                      {containerTypes.map((i, j) => {
                        return (
                          <div>
                            <img className="mr-3" src={`assets/images/${data.containerType === i.name ? 'bullet_box' : 'empty_bullet_box'}.svg`} />
                            <label className="font-size-14 font-wt-400">{i.name}</label>
                          </div>
                        )
                      })}
                    </div> */}

                  </div>

                </div>
                <div style={{ width: '78%' }} >
                  {shipmentData.map((i, j) => {
                    return (
                      <div className="card row d-flex flex-row py-3" >
                        <div className="px-4" style={{ borderRight: '1px solid #ABABAB', width: '48%' }} >
                          <div className="d-flex justify-content-center mb-3" >
                            <img src="assets/images/shippingLineLogo.png" />
                            <label className="font-size-16 font-wt-400" >Jeena & Company</label>
                          </div>
                          <div>
                            <label className="w-50" >{ports[0]?.['html']}</label>
                            <label className="w-50 text-right">{ports[0]?.['html']}</label>
                            <div className="d-flex align-items-center" >
                              {stops.map((k, l) => {
                                return (
                                  <>
                                    <img src={k.isStop ? 'assets/images/shipmentStopBlue.png' : "assets/images/shipmentStopGrey.png"} />
                                    <div style={{ height: '2px', width: k.distInPer, backgroundColor: (stops[l + 1]?.isStop || k.isStop) ? '#5CB8D3' : '#D5D5D5' }} />
                                  </>
                                )
                              })}
                            </div>
                          </div>
                          <label className="fpnt-size-14 font-wt-400 text-center w-100 mt
                          -2" >3 Stops</label>
                        </div>
                        <div
                          style={{ borderRight: '1px solid #ABABAB', width: '29%' }}
                          className="  d-flex align-items-center justify-content-center" >
                          <div>
                            <div className=" d-flex  w-100 flex-row" >
                              <div>
                                <img src='assets/images/timerLine.png' /></div>
                              <label className="font-size-14 font-wt-400  pl-2 pr-4" >Valid Till</label>
                              <label className="font-size-14 font-wt-600 " >06/10/2023</label>
                            </div>
                            <div className=" d-flex  w-100 flex-row my-2">
                              <div>
                                <img src='assets/images/leaf.png' /></div>
                              <label className="font-size-14 font-wt-400  pl-2 pr-5" >CO2</label>
                              <label className="font-size-14 font-wt-600" >1,565.54 Kg</label>
                            </div>
                            <div className=" d-flex  w-100 flex-row">
                              <div>
                                <img src='assets/images/freight_icon.png' /></div>
                              <label className="font-size-14 font-wt-400  pl-2 pr-4" >Ocean Freight</label>
                              <label className="font-size-14 font-wt-600" >27 days</label>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-center"
                          style={{ width: '23%' }}
                        >
                          <div>
                            <div className="d-flex align-items-center w-100 justify-content-center " >
                              <label className="text-color1 font-size-24 font-wt-600 pt-2 pr-2" >$ 1,111</label>
                              <img src='assets/images/greenLeafWithBorder.png' />
                            </div>
                            <div className="d-flex justify-content-center">
                              <button type="button"
                                onClick={() => {
                                  // window.location = `/applyLimitV2?formContinue=${item.id}`;
                                }}
                                className={` border-0 mb-2 text-white enableQuotebtn `}>
                                {"BOOK NOW"}
                              </button>
                            </div>
                            <label className="font-size-13 font-wt-400 cursor w-100 text-center" ><u>View More</u></label>
                          </div>
                        </div>
                      </div>
                    )
                  })}

                </div>

              </div>
            </div>

          </main>
        </div >
      </div >
    </div >
  )
}

const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(ShipmentBookingMarketPlace)