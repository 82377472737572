import moment from "moment";
import React, { useEffect, useState } from "react";
import { copyToClipboard } from "../../../utils/myFunctions";
import { Action } from "../../myCounterPartComp/action";
import { NewInput } from "../../../utils/newInput";
import toastDisplay from "../../../utils/toastNotification";
import { useRef } from "react";
// import { Action } from "../components/myCounterPartComp/action";

export const ExpandableTable = ({ columns, data, options, disableAction, expand, tableExpand, children, expandKey, tableSubColumnWith, filterData, setFilterData, filteredSearch, setFilteredSearch }) => {

  const [action, setAction] = useState({ show: false, index: null })
  const box2 = useRef();

  const [filterPopup, toggleFilterPopup] = useState({})

  const setFilterDataOnChange = (filterName, filterDataIndex, item) => {
    let temp = JSON.parse(JSON.stringify(filterData))
    let temp2 = JSON.parse(JSON.stringify(filteredSearch))

    let indexTemp1
    temp[filterName]["data"].forEach((val, index) => {
      if (val.name === item.name) {
        indexTemp1 = index
      }
    })
    temp[filterName]["data"][indexTemp1]["isChecked"] = !temp[filterName]["data"][indexTemp1]["isChecked"]
    temp2[filterName]["data"][filterDataIndex]["isChecked"] = !temp2[filterName]["data"][filterDataIndex]["isChecked"]
    temp2[filterName]["isAllChecked"] = false
    temp[filterName]["isAllChecked"] = false
    console.log('temppppp', temp, temp2, temp2[filterName]["data"][filterDataIndex]);
    let isFilterActive = false
    for (let index = 0; index < temp[filterName]["data"].length; index++) {
      const element = temp[filterName]["data"][index];
      if (element.isChecked) {
        isFilterActive = true
      }
    }
    let isFilterActive2 = false

    for (let index = 0; index < temp2[filterName]["data"].length; index++) {
      const element = temp2[filterName]["data"][index];
      if (element.isChecked) {
        isFilterActive2 = true
      }
    }
    temp[filterName]["isFilterActive"] = isFilterActive
    temp2[filterName]["isFilterActive"] = isFilterActive2
    setFilterData({ ...temp })
    setFilteredSearch({ ...temp2 })
  }

  const handleAllFilterStatus = (filterName) => {
    let temp = filterData
    let temp2 = filteredSearch
    let isFilterActive = false


    for (let index = 0; index < temp2[filterName].data.length; index++) {
      const element = temp2[filterName]["data"][index];
      element["isChecked"] = !temp2[filterName]["isAllChecked"]
      if (element["isChecked"]) {
        isFilterActive = true
      }
    }

    temp[filterName]["isFilterActive"] = isFilterActive
    temp[filterName]["isAllChecked"] = !temp2[filterName]["isAllChecked"]

    temp2[filterName]["isFilterActive"] = isFilterActive
    temp2[filterName]["isAllChecked"] = !temp2[filterName]["isAllChecked"]
    setFilterData({ ...temp })
    setFilteredSearch({ ...temp2 })
  }

  useEffect(() => {
    if (Object.keys(filterPopup || {}).length) {
      document.addEventListener('mousedown', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [filterPopup])

  const handleOutsideClick = (event) => {
    // console.log("bbbbbbbbbbbbbbbbbbb", box2.current);
    let eventTargetStr = event?.target?.outerHTML
    if (box2 && box2.current && !box2.current.contains(event.target)) {
      let temp = Object.keys(filterPopup || {})
      let tempFilterPopup = filterPopup
      for (let index = 0; index < temp.length; index++) {
        const element = temp[index];
        tempFilterPopup[element] = false
      }
      toggleFilterPopup({ ...tempFilterPopup })
    }
  }
  console.log("Table expand", tableExpand, expand);
  return (
    <table
      className='tablev2 text-start'>
      <tr className='th'>
        {columns.map((item, index) => {
          return (
            <th
              style={!item.subColumns ? { width: `${item.width}` } : {
                width: `${item.width}`, height: '3.2rem', alignItems: 'baseline'
              }}
              className='heading'>{item.name}
              {filterPopup[item.filterDataKey] ? (
                <div
                  ref={box2}
                  className={'position-absolute bg-white sortPopup px-3'}
                  style={{
                    width: '20rem',
                    zIndex: 10,
                    top: 0,
                  }}
                >
                  <div className="py-2 border-bottom-eeeeee d-flex flex-row gap-2 justify-content-between">
                    <div>
                      {item.name}
                      <img
                        style={{ "transform": "rotate(180deg)" }}
                        onClick={() => {
                          toggleFilterPopup({ [item.filterDataKey]: false })
                        }}
                        className='mx-2 cursor' src={"assets/images/header/down_arrow.png"} />
                    </div>

                    {filteredSearch?.[item?.filterDataKey]?.data?.length ? (
                      <div>
                        <img
                          onClick={() => handleAllFilterStatus(item.filterDataKey)}
                          className='cursor' src={`assets/images/${filteredSearch[item.filterDataKey]["isAllChecked"] ? 'checked-green' : 'empty-check'}.png`} />
                      </div>
                    ) : null}
                  </div>
                  <div>
                    {item?.sort?.length ? item.sort.map((m, n) =>
                      <div
                        onClick={() => { m.onActionClick(); toggleFilterPopup({ [item.filterDataKey]: false }) }}
                        className="pt-1 border-bottom-eeeeee cursor">
                        <label className={`${m.selected ? 'text-color1 font-wt-600 cursor' : 'text-color-label cursor'}`}>{m.name}</label>
                      </div>) : null}
                    {filterData?.[item?.filterDataKey]?.data?.length ?
                      <div>
                        <NewInput name={"search"} label={"Search"} onChange={(e) => {
                          setTimeout(() => {
                            if (e.target.value) {
                              console.log(filteredSearch);
                              let key = filteredSearch[item.filterDataKey]["labelName"]
                              const filtereddata = filterData?.[item?.filterDataKey]?.data.filter(item => item[key]?.toLowerCase().includes(e.target.value?.toLowerCase()))
                              setFilteredSearch({
                                ...filteredSearch,
                                [item?.filterDataKey]: {
                                  ...filteredSearch[item?.filterDataKey],
                                  data: filtereddata
                                }
                              })
                            } else {
                              setFilteredSearch(filterData)
                            }
                          }, 500);
                        }} />
                      </div>
                      : null
                    }
                    <div style={{
                      overflow: 'auto',
                      height: 'auto',
                      maxHeight: "15rem"
                    }}>

                      {filteredSearch?.[item?.filterDataKey]?.data?.length ? filteredSearch?.[item?.filterDataKey]?.data.map((i, j) => {
                        return (
                          <div className="mt-2">
                            <img
                              onClick={() => setFilterDataOnChange(item.filterDataKey, j, i)}
                              className='cursor' src={`assets/images/${i.isChecked ? 'checked-green' : 'empty-check'}.png`} />
                            <label className="ml-2" >{i[filteredSearch[item.filterDataKey]["labelName"]]}</label>
                          </div>
                        )
                      }) : null}
                    </div>
                  </div>
                </div>
              ) : null}
              {item.filter ? (
                <img
                  onClick={() => {
                    console.log('FilteredSearch', filteredSearch, item.filterDataKey);
                    if (filteredSearch?.[item.filterDataKey]?.data?.length || item.sort?.length) {
                      toggleFilterPopup({ [item.filterDataKey]: !filterPopup[item.filterDataKey] })
                    }
                    else {
                      toastDisplay("Data not available for filter", "info")
                    }
                  }}
                  className='mx-2 cursor' src={"assets/images/header/down_arrow.png"} />
              ) : null}
              {item.subColumns ? (
                <div className="tableSubColumns" >
                  {item.subColumns.map(i => (
                    <div>{i}</div>
                  ))}
                </div>
              ) : null}
            </th>)
        })}
      </tr>
      <tbody className="expandTableScroll">

        {data.length ? data.map((item, index) => {
          return (
            <>
              <tr className={`th bg-white ${expand.some(code => code?.[expandKey] === tableExpand) && (item.length && item.some((key) => key === tableExpand)) && expand.length && 'border-bottom-0'}`}
              >
                {item.length ? item.map((item, i) => (
                  <td
                    style={{ width: `${columns[i]?.width}` }}
                    className='tableRow text-break'>{item}</td>
                )) : null}

                {disableAction ? null : (
                  <td
                    // style={{ width: `${100 / columns.length}%` }}
                    style={{ width: '4rem', justifyContent: 'center', alignItems: 'center', display: 'flex' }}
                    className=''>
                    <i className="fa fa-ellipsis-v cursor mt-2 ml-2"
                      onClick={() => setAction({ show: true, index })}
                      aria-hidden="true"></i>
                    {action.show && action.index === index ? (
                      <Action
                        id={index}
                        onDismiss={() => setAction({ show: false, index })}
                        options={options}
                        isExpandedTable={true} />
                    ) : null}
                  </td>
                )}


              </tr>
              {expand.some(code => code?.[expandKey] === tableExpand) && (item.length && item.some((key) => (key === tableExpand || key?.props?.children === tableExpand))) && expand.length ?
                <div className="row align-items-center w-100 p-3">
                  {children}
                </div>
                : null}

            </>

          )
        })
          : <tr className="w-100 py-4 text-center">
            <label className="font-size-16 font-wt-600">No Data Found</label>
          </tr>}

      </tbody>

    </table>
  )
}