import moment from "moment";
import React from "react";

const MeetingDescriptionModal = ({ schedule, setSchedule, data, onMeetPress }) => {

  return (
    <>
      <div className={`modal fade ${schedule && "show"}`} style={{ display: "block" }}>
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content meetingmodal borderRadius">
            <div class="modal-body p-4">
              <div className="d-flex justify-content-between">
                <div>
                  <p className="text-color-0C0C0C font-wt-600 font-size-18">{data.meetingTitle} <img src={"/assets/images/chat_video.png"} alt="" height={19} className="ms-2 cursor" onClick={() => onMeetPress(data.room_id, data.createdBy, data.meetingType)} /></p>
                </div>
                <div className="d-flex gap-2 cursor">
                  <div> <img src={"/assets/images/bytesize_edit.png "} alt="" /></div>
                  <div class="dropdown">
                    <img src={"/assets/images/more-dark.png "} alt="" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" />
                    <ul class="dropdown-menu borderRadius chatmore font-size-12 text-center" aria-labelledby="dropdownMenuButton1">
                      <li><a class="dropdown-item" >Open group chat</a></li>
                      <li><a class="dropdown-item" >Message rosti</a></li>
                      <li><a class="dropdown-item">Message XYZ</a></li>
                    </ul>
                  </div>
                  <div><img src={"/assets/images/close-schedule.png "} alt="" onClick={() => setSchedule(false)} /></div>
                </div>
              </div>
              <p className="text-color-0C0C0C font-size-14 font-wt-400">{moment(data.meetingstartTime).format("DD MMMM , dddd") + " | " + moment(data.meetingstartTime).format("hh:mm A") + "-" + moment(data.meetingEndTime).format("hh:mm A")}</p>
              <p className="text-secondary font-size-13 lh-base">{data.meeting_description}</p>
              <p className="color434343 font-size-13 font-wt-400">Participant</p>
              <div className="row">
                {data.participantsdata?.map(item => {
                  return <span className="p-2 px-4 my-2 mx-1 borderRadius border col-auto font-size-12 text-secondary ">{item.company_name}</span>
                })}
                {/* <div> <img src={"/assets/images/add-participant.png "} alt="" /></div> */}
              </div>
              <p className="color0C0C0C font-size-13 mt-3">Scheduled by : <b>{data.hostname}</b> </p>
              <div className="mt-3 d-flex align-items-center">
                <p className="color0C0C0C font-size-13 mb-0">Reminder <span className="font-size-10">(before)</span> : <b>{data.reminder_duration + " " + data.reminder_unit}</b></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default MeetingDescriptionModal;