import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from 'react-redux';
import call from "../../service";
import HeaderV2 from "../partial/headerV2";
import SideBarV2 from "../partial/sideBarV2";
import NotificationCard from "./components/notificationCard";
import NotificationsInvoice from "./components/notificationInvoice";
import NotificationsOthers from "./components/notificationOthers";
import NotificationsLC from "./components/notificationsLC";
import { AllnotificationsToday } from "./JsonData"

const AllNotifications = ({ userTokenDetails, navToggleState }) => {
  //Today
  const [tab, setTab] = useState("All notification");
  const [tabLC, setTabLC] = useState("Quote");
  const [tabInv, setTabInv] = useState("Quote");
  const [tabOthers, setTabOthers] = useState("Wallet");
  const [notiLCdata, setNotiLcData] = useState([])
  const [notiINVdata, setNotiINVData] = useState([])
  const [notiAlldata, setNotiAllData] = useState([])
  const [notiOtherdata, setNotiOthersData] = useState([])

  //Yesterday
  const [tabY, setTabY] = useState("All notification");
  const [tabLCY, setTabLCY] = useState("Quote");
  const [tabInvY, setTabInvY] = useState("Quote");
  const [tabOthersY, setTabOthersY] = useState("Wallet");
  const [notiLCdataY, setNotiLcDataY] = useState([])
  const [notiINVdataY, setNotiINVDataY] = useState([])
  const [notiAlldataY, setNotiAllDataY] = useState([])
  const [notiOtherdataY, setNotiOthersDataY] = useState([])


  //Week
  const [tabW, setTabW] = useState("All notification");
  const [tabLCW, setTabLCW] = useState("Quote");
  const [tabInvW, setTabInvW] = useState("Quote");
  const [tabOthersW, setTabOthersW] = useState("Wallet");
  const [notiLCdataW, setNotiLcDataW] = useState([])
  const [notiINVdataW, setNotiINVDataW] = useState([])
  const [notiAlldataW, setNotiAllDataW] = useState([])
  const [notiOtherdataW, setNotiOthersDataW] = useState([])


  const [showLoader, setshowLoader] = useState(false)



  const userId = userTokenDetails && userTokenDetails.user_id ? userTokenDetails.user_id : null

  const getNotificationdata = ({ period, notification_type, notification_subtype, setterFunc, resultPerPage }) => {
    setshowLoader(true)
    let notificationReq = {
      userId,
      period: period,
      resultPerPage,
      currentPage: 1,
      notification_type,
      notification_subtype
    }
    call('POST', 'getNotification', notificationReq).then((result) => {
      console.log("API result getNotification", result);
      setterFunc(result)
      setshowLoader(false)
    }).catch((e) => {
      console.log("Error while  getNotification", e);
      setshowLoader(false)

    });
  }
  //today
  useEffect(() => {
    getNotificationdata({ period: 'today', setterFunc: setNotiAllData, resultPerPage: 3 })
  }, [tab])
  useEffect(() => {
    getNotificationdata({ period: 'today', notification_type: 'LC', notification_subtype: tabLC, setterFunc: setNotiLcData, resultPerPage: 3 })
  }, [tabLC])
  useEffect(() => {
    getNotificationdata({ period: 'today', notification_type: 'Invoice Discounting', notification_subtype: tabInv, setterFunc: setNotiINVData, resultPerPage: 3 })
  }, [tabInv])
  useEffect(() => {
    getNotificationdata({ period: 'today', notification_type: 'Other', notification_subtype: tabOthers, setterFunc: setNotiOthersData, resultPerPage: 3 })
  }, [tabOthers])

  //yesterday
  useEffect(() => {
    getNotificationdata({ period: 'yesterday', setterFunc: setNotiAllDataY, resultPerPage: 3 })
  }, [tabY])
  useEffect(() => {
    getNotificationdata({ period: 'yesterday', notification_type: 'LC', notification_subtype: tabLCY, setterFunc: setNotiLcDataY, resultPerPage: 3 })
  }, [tabLCY])
  useEffect(() => {
    getNotificationdata({ period: 'yesterday', notification_type: 'Invoice Discounting', notification_subtype: tabInvY, setterFunc: setNotiINVDataY, resultPerPage: 3 })
  }, [tabInvY])
  useEffect(() => {
    getNotificationdata({ period: 'yesterday', notification_type: 'Other', notification_subtype: tabOthersY, setterFunc: setNotiOthersDataY, resultPerPage: 3 })
  }, [tabOthersY])

  //Week 

  useEffect(() => {
    getNotificationdata({ period: 'week', setterFunc: setNotiAllDataW, resultPerPage: 3 })
  }, [tabW])
  useEffect(() => {
    getNotificationdata({ period: 'week', notification_type: 'LC', notification_subtype: tabLCY, setterFunc: setNotiLcDataW, resultPerPage: 3 })
  }, [tabLCW])
  useEffect(() => {
    getNotificationdata({ period: 'week', notification_type: 'Invoice Discounting', notification_subtype: tabInvY, setterFunc: setNotiINVDataW, resultPerPage: 3 })
  }, [tabInvW])
  useEffect(() => {
    getNotificationdata({ period: 'week', notification_type: 'Other', notification_subtype: tabOthersW, setterFunc: setNotiOthersDataW, resultPerPage: 3 })
  }, [tabOthersW])



  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="AllNotifications" userTokenDetails={userTokenDetails} />
          {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"All Notifications"}
              userTokenDetails={userTokenDetails} />
            <div className=''>
              <nav>
                <div className="nav nav-tabs signdoctabs gap-4 border-0" id="signdoctabs" role="tablist">
                  <button className={`nav-link  bg-transparent ${tab === "All notification" && 'active'} `} id="nav-home-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-home" aria-selected="true" onClick={() => setTab("All notification")}>All notification</button>
                  <span className="border-left"></span>
                  <button className={`nav-link d-flex gap-2 align-items-center bg-transparent ${tab === "LC" && 'active'} `} id="nav-profile-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-profile" aria-selected="false" onClick={() => setTab("LC")}>
                    <span>LC</span>
                    <img className="" src={"assets/images/red-dot.png"} alt="" /></button>
                  <span className="border-left"></span>
                  <button className={`nav-link  bg-transparent ${tab === "Invoice discounting" && 'active'} `} id="nav-contact-tab " data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-contact" aria-selected="false" onClick={() => setTab("Invoice discounting")}>Invoice discounting</button>
                  <span className="border-left"></span>
                  <button className={`nav-link  bg-transparent ${tab === "Others" && 'active'} `} id="nav-Others-tab " data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-Others" aria-selected="false" onClick={() => setTab("Others")}>Others</button>
                </div>
              </nav>

              <div className="tab-content d-block" id="nav-tabContent">
                {tab === "All notification" && <div className="tab-pane fade show active bg-transparent mt-4 " id="nav-home" role="tabpanel" >
                  <div className="mt-4">
                    <NotificationCard notificationcardData={AllnotificationsToday} Heading="Today" data={notiAlldata} getNotificationdata={(resultPerPage) => getNotificationdata({ period: 'today', setterFunc: setNotiAllData, resultPerPage: resultPerPage })} />
                  </div>
                  <div className="mt-4">
                    <NotificationCard notificationcardData={AllnotificationsToday} Heading="Yesterday" data={notiAlldataY} getNotificationdata={(resultPerPage) => getNotificationdata({ period: 'yesterday', setterFunc: setNotiAllDataY, resultPerPage: resultPerPage })} />
                  </div>
                  <div className="mt-4">
                    <NotificationCard notificationcardData={AllnotificationsToday} Heading="This week" data={notiAlldataW} getNotificationdata={(resultPerPage) => getNotificationdata({ period: 'week', setterFunc: setNotiAllDataW, resultPerPage: resultPerPage })} />
                  </div>
                </div>
                }
                {tab === "LC" && <div className="tab-pane fade show active bg-transparent mt-3" id="nav-home" role="tabpanel" >
                  <div className="mt-4">
                    <NotificationsLC Heading="Today" tab={tabLC} setTab={setTabLC} data={notiLCdata} getNotificationdata={(resultPerPage) => getNotificationdata({ period: 'today', notification_type: 'LC', notification_subtype: tabLC, setterFunc: setNotiLcData, resultPerPage: resultPerPage })} />
                  </div>
                  <div className="mt-4">
                    <NotificationsLC Heading="Yesterday" tab={tabLCY} setTab={setTabLCY} data={notiLCdataY} getNotificationdata={(resultPerPage) => getNotificationdata({ period: 'yesterday', notification_type: 'LC', notification_subtype: tabLCY, setterFunc: setNotiLcDataY, resultPerPage: resultPerPage })} />
                  </div>
                  <div className="mt-4">
                    <NotificationsLC Heading="This week" tab={tabLCW} setTab={setTabLCW} data={notiLCdataW} getNotificationdata={(resultPerPage) => getNotificationdata({ period: 'yesterday', notification_type: 'LC', notification_subtype: tabLCW, setterFunc: setNotiLcDataW, resultPerPage: resultPerPage })} />
                  </div>
                </div>
                }
                {tab === "Invoice discounting" && <div className="tab-pane fade show active bg-transparent mt-3" id="nav-home" role="tabpanel" >
                  <div className="mt-4">
                    <NotificationsInvoice Heading="Today" tab={tabInv} setTab={setTabInv} data={notiINVdata} getNotificationdata={(resultPerPage) => getNotificationdata({ period: 'today', notification_type: 'Invoice Discounting', notification_subtype: tabInv, setterFunc: setNotiINVData, resultPerPage: resultPerPage })} />
                  </div>
                  <div className="mt-4">
                    <NotificationsInvoice Heading="Yesterday" tab={tabInvY} setTab={setTabInvY} data={notiINVdataY} getNotificationdata={(resultPerPage) => getNotificationdata({ period: 'yesterday', notification_type: 'Invoice Discounting', notification_subtype: tabInvY, setterFunc: setNotiINVDataY, resultPerPage: resultPerPage })} />
                  </div>
                  <div className="mt-4">
                    <NotificationsInvoice Heading="This week" tab={tabInvW} setTab={setTabInvW} data={notiINVdataW} getNotificationdata={(resultPerPage) => getNotificationdata({ period: 'week', notification_type: 'Invoice Discounting', notification_subtype: tabInvY, setterFunc: setNotiINVDataW, resultPerPage: resultPerPage })} />
                  </div>
                </div>
                }
                {tab === "Others" && <div className="tab-pane fade show active bg-transparent mt-3" id="nav-Others" role="tabpanel" >
                  <div className="mt-4">
                    <NotificationsOthers Heading="Today" tab={tabOthers} setTab={setTabOthers} data={notiOtherdata} getNotificationdata={(resultPerPage) => getNotificationdata({ period: 'today', notification_type: 'Other', notification_subtype: tabOthers, setterFunc: setNotiOthersData, resultPerPage: resultPerPage })} />
                  </div>
                  <div className="mt-4">
                    <NotificationsOthers Heading="Yesterday" tab={tabOthersY} setTab={setTabOthersY} data={notiOtherdataY} getNotificationdata={(resultPerPage) => getNotificationdata({ period: 'yesterday', notification_type: 'Other', notification_subtype: tabOthersY, setterFunc: setNotiOthersDataY, resultPerPage: resultPerPage })} />
                  </div>
                  <div className="mt-4">
                    <NotificationsOthers Heading="This week" tab={tabOthersW} setTab={setTabOthersW} data={notiOtherdataW} getNotificationdata={(resultPerPage) => getNotificationdata({ period: 'week', notification_type: 'Other', notification_subtype: tabOthersW, setterFunc: setNotiOthersDataW, resultPerPage: resultPerPage })} />
                  </div>
                </div>
                }
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(AllNotifications)