import React from "react";
import { useState } from "react";
import avatarUrl from "../../../utils/makeAvatarUrl";
import FinanceInvoiceModal from "../../InvoiceDiscounting/contract/components/financeinvoiceModal";
import ScheduleMeetingModal from "./scheduleMeetingmodal";


const ChatHeader = ({ videocall, setVideocall, selectedUser }) => {

  const [schedule, setSchedule] = useState(false);
  const [meeturl, setMeeturl] = useState(false);

  const closeFn = () => {
    setMeeturl(false);
  }
  let extraId = selectedUser?.chat_room_name?.split('-')?.[1]

  return (
    <>
      <>
        <div className='chatcontacts h-auto d-flex gap-3 align-items-center'>
          <div className="img_cont">
            <img src={selectedUser?.ReceiverAvatar ? typeof (selectedUser.ReceiverAvatar) == 'string' ? avatarUrl(selectedUser.ReceiverAvatar) : selectedUser.ReceiverAvatar.filebase64 : "/assets/images/user-profile.png"} alt="" className="rounded-circle user_img" />
            <span className="online_icon"></span>
          </div>
          <div>
            <p className='mb-0 font-wt-600'>{`${selectedUser?.ReceiverName} ${extraId ? ' - ' + extraId : ''}`}</p>
            {/* <p className='mb-0 font-size-13 text-secondary'>Active now</p> */}
          </div>
        </div>
        <div className='h-auto d-flex gap-3 align-items-center cursor'>
          <img src={'/assets/images/chat-call.png'} alt='' />
          <img onClick={() => setVideocall(true)} src={'/assets/images/chat_video.png'} alt='' />
          <div className="dropdown ">
            <img src={"/assets/images/more.png"} alt='' id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" />
            <ul className="dropdown-menu borderRadius chatmore font-size-12 text-center " aria-labelledby="dropdownMenuButton1">
              <li><a className="dropdown-item">Delete chat</a></li>
              <li><a className="dropdown-item">View profile</a></li>
              {/* <li><a onClick={() => setSchedule(true)} className="dropdown-item"> Schedule meeting</a></li> */}
              <li><a className="dropdown-item"> Pin chat</a></li>
            </ul>
          </div>
        </div>
      </>
      {schedule && <ScheduleMeetingModal schedule={schedule} setSchedule={setSchedule} meeturl={meeturl} setMeeturl={setMeeturl} />}
      {meeturl && <FinanceInvoiceModal limitinvoice={meeturl} setLimitinvoice={setMeeturl} closeSuccess={closeFn}>
        <div className="mx-5">
          <img src={"/assets/images/upload_done.png"} alt="" className="mb-3" />
          <div className="urlcopy mb-4 d-flex align-items-center p-2 mx-3 justify-content-between"> <span className="w-80 text-color1 font-size-14 "> traderebootfin/d/gsf-np4-kvf/title</span> <span className="w-auto"><img src={"/assets/images/url-copy.png"} alt="" /> </span></div>
          <p className="font-size-14 text-secondary">Meeting scheduled for <span className="font-wt-600">10/11/2022</span> from <span className="font-wt-600">10:00 AM to 11:00 PM</span></p>
        </div>

      </FinanceInvoiceModal>}
    </>
  );
}
export default ChatHeader;