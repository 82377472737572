import React, { useState, useEffect } from 'react';
import call from '../../service';
import Spinner from 'react-bootstrap/Spinner'
import config from '../../config.json';
import { platformBackendUserServiceUrl } from '../../urlConstants';
const queryString = require('query-string');



const ResetPassword = (props) => {
    const [screen, setScreen] = useState(1); //1-enter email screen, 2-reset email screen
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});
    const [apiState, setApiState] = useState(1);//1-not called, 2-calling, 3-called
    const [token, setToken] = useState('');

    useEffect(() => {
        let query = props.query ? queryString.parse(props.query) : false;
        if (query.resetPass && query.id) {
            setToken(query.id);
            setScreen(2);
        }
    }, [])

    var handleChange = (event) => {
        event.persist();
        setValues({ ...values, [event.target.name]: event.target.value })
    }

    var handlePassChange = (event) => {
        handleChange(event);
        let password = event.target.value
        if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(password)) {
            setErrors({ ...errors, [event.target.name]: "Password doesn't meet the password policy." })
        } else {
            setErrors({ ...errors, [event.target.name]: "" })
        }
    }
    var handleCnfPassChange = (event) => {
        handleChange(event);
        let password = event.target.value;
        if (values.password != password) {
            setErrors({ ...errors, [event.target.name]: "Passwords doesn't match." })
        } else {
            setErrors({ ...errors, [event.target.name]: "" })
        }
    }




    function showMessage() {
        if (errors.email) {
            return (<p className="text-danger error-contract">{errors.email}</p>);
        }
        if (errors.api) {
            return (<p className="text-danger error-contract">{errors.api}</p>);
        }
        if (apiState / 1 === 3) {
            return (<p className="text-success">{values.api}</p>);
        }
    }

    const handleButtonScreen1 = () => {

        if (!values.email || !/\S+@\S+\.\S+/.test(values.email)) {
            setErrors({ ...errors, email: 'Please enter a valid email id.' })
            return;
        }
        errors.email = '';

        setApiState(2)
        call('post', 'createResetLink', { mail: values.email, baseUrl: platformBackendUserServiceUrl }, "user").then((res) => {
            setValues({ ...values, api: res })
            setApiState(3)
        }).catch((e) => {
            setErrors({ ...errors, api: e.message });
            setApiState(3)
        });
    }

    const handleButtonScreen2 = () => {
        if (!values.password) {
            setErrors({ ...errors, password: "Please enter password" });
            return;
        }
        if (!values.cnfm_password) {
            setErrors({ ...errors, cnfm_password: "Please enter password" });
            return;
        }
        if (errors.password || errors.cnfm_password) {
            return;
        }

        setApiState(2)
        var apiData = {
            pass: values.password,
            cpass: values.cnfm_password,
            token: token,
            attr1: localStorage.getItem("attr1"),
            attr2: localStorage.getItem("attr2")
        };
        call('post', 'resetPassword', apiData, "user").then((res) => {
            setValues({ ...values, api: res })
            setApiState(3)
        }).catch((e) => {
            setErrors({ ...errors, api: e.message });
            setApiState(3)
        });
        // setTimeout(() => { setApiState(3) }, 3000)
    }




    function showEnterEmailScreen() {
        return (
            <>
                <div className="form-group">
                    <label htmlFor="inputEmail">Enter your email address</label>
                    <br />
                    <input type="email" id="inputEmail" className={" form-control" + (!errors.email ? '' : ' border-danger')} name="email" placeholder="Email ID" required="" autoFocus={true}
                        onChange={handleChange} required />
                    {showMessage()}
                </div>
                <button type="button" className="btn btn-lg btn-primary btn-block btn-sm" onClick={handleButtonScreen1} disabled={apiState != 1} noValidate>
                    {apiState / 1 === 1 &&
                        <>Send Reset Link</>
                    }
                    {
                        apiState / 1 === 2 &&
                        <Spinner animation="border" variant="light" />
                    }
                    {
                        apiState / 1 === 3 &&
                        <i className="fa fa-check" />
                    }
                </button>
            </>
        )
    }

    function showResetScreen() {
        return (
            <>
                <div className="form-group">
                    <label htmlFor="inputEmail">Enter your new password</label><span className="help-icon" flow="right" tooltip="-The Password must contain at least 1 uppercase alphabetical character , -The Password must contain at least 1 numeric character , -The Password must contain at least one special character , -The Password must be eight characters or longer">i </span>
                    <br />
                    <input type="password" id="password" className={" form-control" + (!errors.password ? '' : ' border-danger')} name="password" placeholder="New Password" required="" autoFocus={true}
                        onChange={handlePassChange} required />
                    {errors.password && (
                        <p className="text-danger error-contract">{errors.password}</p>
                    )}
                    <br />

                    <label htmlFor="inputEmail">Confirm Password</label>
                    <br />
                    <input type="password" id="password" className={" form-control" + (!errors.cnfm_password ? '' : ' border-danger')} name="cnfm_password" placeholder="Confirm Password" required="" autoFocus={true}
                        onChange={handleCnfPassChange} required />
                    {errors.cnfm_password && (
                        <p className="text-danger error-contract">{errors.cnfm_password}</p>
                    )}
                    <br />
                    {values.api &&
                        <p className="text-success">{values.api}</p>
                    }
                    {errors.api &&
                        <p className="text-danger error-contract">{errors.api}</p>
                    }
                </div>
                <button type="button" className="btn btn-lg btn-primary btn-block btn-sm" onClick={handleButtonScreen2} disabled={apiState != 1} noValidate>
                    {apiState / 1 === 1 &&
                        <>Reset Password</>
                    }
                    {
                        apiState / 1 === 2 &&
                        <Spinner animation="border" variant="light" />
                    }
                    {
                        apiState / 1 === 3 &&
                        <i className="fa fa-check" />
                    }
                </button>
            </>
        )

    }



    return (
        <>
            <div className="authfy-heading">
                <h3 className="auth-title">Reset password</h3>
            </div>
            {screen / 1 === 1 && showEnterEmailScreen()}
            {screen / 1 === 2 && showResetScreen()}
        </>
    )

}


export default ResetPassword;