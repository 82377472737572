import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import call from '../../service';
import { FileInput } from '../../utils/FileInput';
import avatarUrl from '../../utils/makeAvatarUrl';
import MultipleSelect from '../../utils/MultipleSelect';
import { astrix, ClearCache, convertImageToPdf, dataURItoBlob, GetCache, getDocDetails, most_used_currencies, SetCache, toolTip } from '../../utils/myFunctions';
import { InputWithSelect, NewInput, NewSelect } from '../../utils/newInput';
import { NewTable } from '../../utils/newTable';
import { StarRating } from '../../utils/starRating';
import toastDisplay from '../../utils/toastNotification';
import Header from '../partial/header';
import HeaderV2 from '../partial/headerV2';
import Sidebar, { traderTypeIds } from '../partial/sidebar';
import SideBarV2 from '../partial/sideBarV2';
import { PopupMessage } from '../popupMessage';
import config from '../../config.json';
import moment from 'moment';
import TutorialPopup, { TutorialVideoPopup } from '../tutorialPopup';
import ChatBoxPopUp2 from '../chatRoom/components/ChatBoxPopUp2';
import Filter from '../InvoiceDiscounting/components/Filter';
import NewTablev2 from '../../utils/newTablev2';
import Pagination from '../InvoiceDiscounting/contract/components/pagination';
import { approvedSCFLimit, bgQuotesColumn, wcQuotesColumn } from '../Dashboard/TableJson';
import { Action } from '../myCounterPartComp/action';

const finHighlights = [
  { name: 'Selected', color: '#5CB8D3' },
  { name: 'Quote received', color: '#2ECC71' },
  { name: 'Rejected', color: '#FE4141' }
]

const SCFApprovedLimit = ({ userTokenDetails, navToggleState }) => {

  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [tableData, setTableData] = useState([])

  const [showAddBuyerForm, toggleAddBuyerForm] = useState(false)
  const [tab, setTab] = useState(0)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)

  const [dunsData, setDunsData] = useState([])
  const [hsnCodes, setHsnCodes] = useState([])
  const [countrys, setCountrys] = useState([])
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [financierData, setFinancierData] = useState([]);
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10, search: serarchParam ? serarchParam : '' })
  const [lcTutorial, toggleLCTutorial] = useState(localStorage.getItem("lcTutorialStep") == 1)
  const [tutorialStage, setTutorialStage] = useState(0)
  const [showTutorialVideo, toggleTutorialVideo] = useState({ show: false, link: null })
  const [filterData, setFilterData] = useState({})

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [action, setAction] = useState({ show: false, index: null })

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;


  useEffect(() => {
    setshowLoader(true)
    let objectAPI = {
      "userId": userId,
      "userEmail": userEmail,
      "userTypeId": userTypeId,
      "type": 'all',
      currentPage: page,
      ...filter
    }
    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push((element.accordianId === "status" || element.accordianId === "financiersFilter" || element.accordianId === "timeLeft") ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
        else if (element.type === "minMaxDate") {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }
    call('POST', 'getSCFApprovedList', objectAPI).then((result) => {
      console.log('running getWCQuoteList api-->', result);
      setDbData(result.data);
      setCount(result.countData);
      setshowLoader(false)
    }).catch((e) => {
      // console.log('error in getBGQuoteList', e);
      setshowLoader(false)
    });
  }, [refresh, page, filterData])

  useEffect(() => {
    call('POST', 'getSCFQuoteListFilter', { userId }).then(res => {
      console.log("getWCQuoteListFilterthen", res);
      setFilterData(res)
    }).catch(err => { })
  }, [])

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('scfApplicationId', selectedChat.scfApplicationId)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))
    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {selectedChat.isChatOpen &&
        <div className="chatboxDivFixed">
          <ChatBoxPopUp2
            chatList={chatList}
            user_avatar={selectedChat.logo}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            parties={selectedChat.parties}
            userTokenDetails={userTokenDetails}
            onChatSend={(file, parties) => sendChatMsg(file, parties)}
            message={message}
            setTextMsg={setTextMsg}
            reloadChatList={() => getChatDetails({
              chat_room_id: selectedChat.chatRoomId,
              loggedInUser: userId
            })}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false,
                logo: ''
              })
              setRefresh(refresh + 1)
            }}
          />
        </div>
      }
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="otherFinSCFQuote" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Other Financial > Supply Chain Finance > Quotes"}
              userTokenDetails={userTokenDetails} />

            <div className='mt-4' style={{}} >
              <div className='filter-div position-relative'>
                <Filter
                  filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                  showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} />

              </div>

              <div>
                <NewTablev2 columns={approvedSCFLimit} >
                  {dbData.map((item, j) => {
                    let selectedLenderName = item.selectedLenderName ? item.selectedLenderName.split(",") : []
                    let selectedLenderId = item.selectedLenderId ? item.selectedLenderId.split(",") : []
                    let buyersCredit = item.financierQuotes ? JSON.parse(item.financierQuotes) : []
                    let chatRoomIds = item.chatRoomIds?.split(",") || []
                    let chatRoomUsers = item.chatRoomUsers?.split(",") || []
                    let chatRoomUnreadMsgCount = item.chatRoomUnreadMsgCount?.split(",") || []

                    let allDeniedCount = 0
                    let apporvedCount = 0

                    if (item.financierQuotes) {
                      for (let index = 0; index < JSON.parse(item.financierQuotes).length; index++) {
                        const j = JSON.parse(item.financierQuotes)[index];
                        if (j.financierAction === "deny") {
                          allDeniedCount += 1
                        }
                        else {
                          apporvedCount += 1
                        }
                      }
                    }

                    let supplierNames = []
                    let supplierLimits = []
                    let supplierCount = 0
                    for (let index = 0; index < 11; index++) {
                      if (item.details[`supplierName${index}`]) {
                        supplierCount += 1
                        supplierNames.push(item.details[`supplierName${index}`])
                        supplierLimits.push(item.details[`supplierCreditLimitRequired${index}`])
                      }

                    }

                    return (
                      <tr>
                        <td className='font-size-13 font-wt-500' >
                          <ul className='py-0 pl-3 cursor'>
                            {supplierNames.map(item => {
                              return <li >
                                <div className='font-size-12'>
                                  {item}
                                </div>
                              </li>
                            })}
                          </ul>
                        </td>
                        <td className='font-size-13 font-wt-500' >
                          <ul className='py-0 pl-3 cursor'>
                            {supplierLimits.map(item => {
                              return <li >
                                <div className='font-size-12'>
                                  {"$ " + item}
                                </div>
                              </li>
                            })}
                          </ul>
                        </td>
                        <td>
                          <div
                            className=''
                          >
                            {selectedLenderName.length ? selectedLenderName.map((key, j) => {
                              let isApprovedByFinancier = buyersCredit?.filter(i => {
                                if ((i.lender_id / 1 == selectedLenderId[j] / 1) && i.financierAction === "Approved") {
                                  return i
                                }
                              })?.[0]

                              let openChatRoomIndx = null
                              chatRoomUsers.forEach((u, i) => {
                                if (u?.split("::")[1] / 1 == selectedLenderId[j]) {
                                  openChatRoomIndx = i
                                }
                              })
                              if (isApprovedByFinancier) {
                                return (
                                  <div className='position-relative' >
                                    <img className='cursor'
                                      onClick={async () => {
                                        let reqObj = {
                                          senderId: userId,
                                          receiverId: selectedLenderId[j],
                                          textMessage: 'Hii',
                                          chat_room_name: "CHAT" + new Date().getTime(),
                                          includeAdmins: true,
                                          scf: item.applicationId,
                                          receiverParties: selectedLenderId[j],
                                          dontSendInitialMsg: true
                                        }
                                        setshowLoader(true)
                                        let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
                                        setshowLoader(false)
                                        getChatDetails({
                                          chat_room_id: apiResp.id,
                                          loggedInUser: userId
                                        })
                                        setSelectedChat({
                                          chatRoomId: apiResp.id,
                                          receiverName: `${key} - ${chatRoomIds[openChatRoomIndx] || apiResp.id}`,
                                          scfApplicationId: item.applicationId,
                                          parties: chatRoomUsers[openChatRoomIndx] || apiResp.parties,
                                          userId: userId,
                                          isChatOpen: true,
                                          receiverId: selectedLenderId[j]
                                        })
                                      }}
                                      src={chatRoomIds[openChatRoomIndx] ? `assets/images/comment_filled.png` : `assets/images/chat.png`} />
                                    <label className={`ml-3 font-size-13 text-color-label font-wt-600  `} >{`${key} `}
                                      <span className='text-color1 mx-2' ><u>{chatRoomUnreadMsgCount[openChatRoomIndx] / 1 ? (chatRoomUnreadMsgCount[openChatRoomIndx] < 10 ? `0${chatRoomUnreadMsgCount[openChatRoomIndx]}` : chatRoomUnreadMsgCount[openChatRoomIndx]) : null}</u></span></label>
                                  </div>
                                )
                              } else {
                                return null
                              }

                            }) : "NA"}
                          </div>
                        </td>
                        <td>
                          <div className='' >
                            <a className='mt-2'>
                              {(item.isApplicationForwarded) ?
                                <>
                                  <button type="button"
                                    onClick={() => {
                                      window.location = `/scfSignTermsheet`;
                                      localStorage.setItem("scfQuoteDetails", JSON.stringify(item))
                                    }}
                                    className={`viewfinancersbtnnew text-color1 mb-2`}>

                                    {"Forwarded"}
                                  </button>
                                  <p className="font-size-12 text-color-value ml-3">
                                    <img src={"assets/images/warning.png"} alt="info" className="me-1" />
                                    <span className=" font-wt-500">
                                      Limit notification sent to supplier. You will get update when supplier apply for limit.
                                    </span>

                                  </p>
                                </>
                                :
                                <>
                                  <button type="button"
                                    onClick={() => {
                                      window.location = `/scfforwardapplication`;
                                      localStorage.setItem("scfQuoteDetails", JSON.stringify(item))
                                    }}
                                    className={`border-0 mb-2 text-white enableQuotebtn`}>
                                    {"Forward"}
                                  </button>
                                  <p className="font-size-12 text-color-value">
                                    <img src={"assets/images/warning.png"} alt="info" className="me-1" />
                                    <span className=" font-wt-500">
                                      Limit available,send notification to supplier to apply for finance
                                    </span>
                                  </p>
                                </>
                              }
                            </a>
                          </div >
                        </td>
                        <td
                          style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
                          className='position-relative'>
                          <i className="fa fa-ellipsis-v cursor mt-2 ml-2"
                            onClick={() => setAction({ show: true, index: j })}
                            aria-hidden="true"></i>
                          {action.show && action.index === j ? (
                            <Action
                              id={j}
                              onDismiss={() => setAction({ show: false, index: j })}
                              options={[
                                {
                                  name: "Application", icon: "edit.png", onClick: () => {
                                    window.location = `/scfQuoteDetails`;
                                    item["disableFinSelection"] = true
                                    localStorage.setItem("scfQuoteDetails", JSON.stringify(item))
                                  }
                                }
                              ]} />
                          ) : null}
                        </td>
                      </tr>
                    )
                  })}
                </NewTablev2>
              </div>
              <Pagination perPage={filter.resultPerPage || 10} page={page} totalCount={count} onPageChange={(p) => setPage(p)} />

            </div>
          </main>
        </div>
      </div>

    </>)

}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(SCFApprovedLimit)