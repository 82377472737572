import React, { useEffect, useState } from 'react'
import call from '../../service'
import { NewTable } from '../../utils/newTable'
import Filter from '../InvoiceDiscounting/components/Filter'
import Pagination from '../InvoiceDiscounting/contract/components/pagination'
import FinancerDetails from './FinancerDetails'
import UserDetails from './UserDetails'
import { GetCache, isUserInactive, SetCache } from '../../utils/myFunctions'
import toastDisplay from '../../utils/toastNotification'
import { ToastContainer } from 'react-toastify'

const FinancersTab = ({ userTokenDetails, setHideTopBar }) => {
  const [summarydata, setSummarydata] = useState({})
  const [filterData, setFilterData] = useState(GetCache("financerTabFilterData"))
  const [filteredSearch, setFilteredSearch] = useState(GetCache("financerTabSearchFilterData"))
  const [refresh, setRefresh] = useState([])
  const [filter, setFilter] = useState(Object.keys(GetCache("financerTabFilter")).length ? GetCache("financerTabFilter") : { resultPerPage: 10, search: '' })
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [showLoader, setShowLoader] = useState(false)
  const [dbData, setDbData] = useState([])
  const [showDetails, setShowDetails] = useState({
    isVisible: false,
    data: {}
  })
  const [salesPerson, setSalesPerson] = useState([])

  const type_id = userTokenDetails?.type_id
  const userPermissionsForSubAdmin = JSON.parse(userTokenDetails.UserAccessPermission || "{}")
  const userId = userTokenDetails?.user_id
  let onlyShowForUserId = userPermissionsForSubAdmin?.mainAdmin ? undefined : userId

  const updateLeadAssignedTo = (leadAssignedName, userId) => {
    call('POST', 'updateLeadAssignedTo', { leadAssignedName, userId }).then(result => {
      toastDisplay("Lead updated", "success")
      getExportersListForAdmin()
    }).catch(e => {
      toastDisplay("Failed to assign lead to " + leadAssignedName, "error")
    })
  }
  const getexportersummaryAdmin = () => {
    setShowLoader(true)
    call('POST', 'getexportersummaryAdmin', { type_id: 8, onlyShowForUserId }).then(result => {
      setSummarydata(result)
      setShowLoader(false)
    }).catch(e => {
      setShowLoader(false)
    })
  }
  useEffect(() => {
    SetCache("financerTabSearchFilterData", filteredSearch)
  }, [page, refresh, filteredSearch, salesPerson])
  useEffect(() => {
    SetCache("financerTabFilterData", filterData)
    SetCache("financerTabFilter", filter)
    getExportersListForAdmin()
  }, [page, refresh, filterData, salesPerson])
  const getExportersListForAdmin = () => {
    setShowLoader(true)
    let reqObj = {
      resultPerPage: filter.resultPerPage,
      currentPage: page,
      search: filter.search,
      type_id: 8,
      onlyShowForUserId
    }

    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          reqObj[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              reqObj[element.accordianId].push((element.accordianId === "status" || element.accordianId === "applicationStatus" || element.accordianId === "timeLeft") ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
        else if (element.type === "minMaxDate") {
          reqObj[element.accordianId] = element["value"]
        }
      }
    }

    call('POST', 'getExportersListForAdmin', reqObj).then(result => {
      setDbData(formatDataForTable(result.message))
      setCount(result.total_count)
      setShowLoader(false)
    }).catch(e => {
      setShowLoader(false)
    })
  }

  useEffect(() => {
    let isCacheExist = localStorage.getItem('financerTabFilterData') != "{}"
    let isSearchCacheExist = localStorage.getItem('financerTabSearchFilterData') != "{}"
    let reqObj = {
      resultPerPage: filter.resultPerPage,
      currentPage: page,
      search: filter.search,
      type_id: 8,
      sub_user_type_id: 22,
      onlyShowForUserId
    }

    call('POST', 'getUserManagementFiltersForAdmin', reqObj).then(res => {
      // console.log("getUserManagementFiltersForAdmin then", res);
      if (!isCacheExist) {
        setFilterData(res)
      }
      if (!isSearchCacheExist) {
        setFilteredSearch(res)
      }
      //setFilterData(res)
    }).catch(err => { })

  }, [page, refresh, salesPerson])

  useEffect(() => {
    getexportersummaryAdmin()
  }, [])
  useEffect(() => {
    if (userPermissionsForSubAdmin.mainAdmin || userPermissionsForSubAdmin?.["Assign Task"]) {
      setShowLoader(true)
      call("POST", 'getSubAdminUser', {}).then(res => {
        setShowLoader(false)
        setSalesPerson(res.data)
      }).catch(err => setShowLoader(false))
    } else {
      setShowLoader(true)
      call("POST", 'getSubAdminUser', { onlyUserId: onlyShowForUserId }).then(res => {
        setShowLoader(false)
        setSalesPerson(res.data)
      }).catch(err => setShowLoader(false))
    }
  }, [])
  useEffect(() => {
    SetCache("financerTabFilterData", filterData)
    SetCache("financerTabFilter", filter)
    getExportersListForAdmin()
  }, [page, refresh, filterData, salesPerson])
  function formatDataForTable(data) {
    let tableData = []
    let row = []
    data.forEach((item, index) => {
      let isUserInActive = isUserInactive(item.last_login_at)
      row.push(item.company_name)
      row.push(`${item.name_title ? item.name_title : ''} ${item.contact_person ? item.contact_person : ''}`)
      row.push(`${item.phone_code ? "+" + item.phone_code : ''} ${item.contact_number ? item.contact_number : ''}`)
      row.push(item.company_city ? item.company_city : '-')
      row.push(<div class="dropdown w-100" >
        <label class="font-wt-600 font-size-13 cursor" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
          {item.TaskAssignedToName || '-'}
        </label>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          {salesPerson.map(element => {
            return <li className="dropdown-item cursor" onClick={() => updateLeadAssignedTo(element.id, item.id)} >{element.contact_person}</li>
          })}
        </ul>
      </div>)
      row.push(`${(item.notification_type && item.notification_type !== 'Other') ? item.notification_type : ""} ${item.notification_sub_type ? item.notification_sub_type : ""}`)
      row.push(item.notification_description ? <span className='color3DB16F'>{item.notification_description}</span> :
        isUserInActive ? <span className='colorFE4141'>Inactive</span> : <span className='text2ECC71'>Active</span>)
      row.push(<img src='/assets/images/redirect.svg' className='cursor' onClick={() => {
        setShowDetails({
          isVisible: true,
          data: item
        })
        setHideTopBar(true)

      }} />)
      tableData.push(row)
      row = []
    })
    return tableData
  }
  return (
    <>
      <div className="row justify-content-between mt-4">
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />

        {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
        {showDetails.isVisible &&
          <div className='mt-4'>
            <FinancerDetails data={showDetails.data} goBack={() => {
              setShowDetails({
                isVisible: false,
                data: {}
              })
              setHideTopBar(false)
            }} userTokenDetails={userTokenDetails} />
          </div>
        }
        {!showDetails.isVisible &&
          <>
            <div className="col-md-3 ">
              <div className="card p-3 dashboard-card border-0 borderRadius h-100 justify-content-center me-3">
                <p className='dasboard-count text-color1 mb-1 font-size-22'>{summarydata.total_exporters ? summarydata.total_exporters : 0}</p>
                <label className='dashboard-name cursor font-size-16 font-wt-600'> <img className='me-2' src={"assets/images/Receivable.png"} alt="" /> Financers  </label>
              </div>
            </div>
            <div className="col-md-3 ">
              <div className="card p-3 dashboard-card border-0 borderRadius justify-content-center h-100  me-3">
                <label className='dasboard-count mb-1 font-size-14 font-wt-500'>Status</label>
                <div className='d-flex flex-row justify-content-around'>
                  <div className='d-flex flex-column cursor'
                    onClick={() => {
                      let temp = filterData
                      temp["Status"]["data"][0]["isChecked"] = true
                      temp["Status"]["data"][1]["isChecked"] = false
                      temp["Status"]["isFilterActive"] = true
                      setFilterData({ ...temp })
                    }}>
                    <label className='font-size-22 text-color1 font-wt-600 cursor'>{summarydata.active_exporters ? summarydata.active_exporters : 0}</label>
                    <label className='font-size-16 font-wt-600 cursor'>Active</label>
                  </div>
                  <div className='d-flex flex-column cursor'
                    onClick={() => {
                      let temp = filterData
                      temp["Status"]["data"][0]["isChecked"] = false
                      temp["Status"]["data"][1]["isChecked"] = true
                      temp["Status"]["isFilterActive"] = true
                      setFilterData({ ...temp })
                    }}>
                    <label className='font-size-22 font-wt-600 colorFF7B6D cursor'>{summarydata.inactive_exporters ? summarydata.inactive_exporters : 0}</label>
                    <label className='font-size-16 font-wt-600 cursor'>InActive</label>
                  </div>
                </div>

              </div>
            </div>
            <div className="col-md-6 ">
              <div className="card p-3 dashboard-card border-0 borderRadius justify-content-center h-100 mx-0">
                <label className="text-secondary font-size-12 font-wt-500 mb-0">Ongoing applications</label>
                <div className="d-flex justify-content-between mt-1">
                  <div className='cursor'
                    onClick={() => {
                      let temp = filterData
                      temp["Application Status"]["data"][0]["isChecked"] = true
                      temp["Application Status"]["data"][1]["isChecked"] = false
                      temp["Application Status"]["data"][2]["isChecked"] = false
                      temp["Application Status"]["isFilterActive"] = true
                      setFilterData({ ...temp })
                    }}>
                    <p className='dasboard-count text-color1 font-size-22 mb-1 font-wt-600 cursor'>{summarydata.total_limit_count ? summarydata.total_limit_count : 0}</p>
                    <label className='dashboard-name cursor font-wt-600'> Limit Application </label>
                  </div>
                  <div className='cursor'
                    onClick={() => {
                      let temp = filterData
                      temp["Application Status"]["data"][0]["isChecked"] = true
                      temp["Application Status"]["data"][1]["isChecked"] = false
                      temp["Application Status"]["data"][2]["isChecked"] = false
                      temp["Application Status"]["isFilterActive"] = true
                      setFilterData({ ...temp })
                    }}>
                    <p className='dasboard-count text-color1 font-size-22 mb-1 font-wt-600 cursor'>{summarydata.total_finance_count ? summarydata.total_finance_count : 0}</p>
                    <label className='dashboard-name cursor font-wt-600'> Finance Application </label>
                  </div>
                  <div className='cursor'
                    onClick={() => {
                      let temp = filterData
                      temp["Application Status"]["data"][0]["isChecked"] = true
                      temp["Application Status"]["data"][1]["isChecked"] = false
                      temp["Application Status"]["data"][2]["isChecked"] = false
                      temp["Application Status"]["isFilterActive"] = true
                      setFilterData({ ...temp })
                    }}>
                    <p className='dasboard-count colorFF7B6D font-size-22 mb-1 font-wt-600 cursor'>{summarydata.total_rejected_count ? summarydata.total_rejected_count : 0}</p>
                    <label className='dashboard-name cursor font-wt-600'> Rejected Application </label>
                  </div>
                </div>
              </div>
            </div>
            <div className='my-4'>
              <div className='filter-div ml-4 '>
                <Filter
                  filteredSearch={filteredSearch} setFilteredSearch={setFilteredSearch}
                  filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                  showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} showDownloadIcon onDownloadClick={() => { }} isAdditionalButton={true} >

                  <div className="d-flex gap-4">
                    <button className={`new-btn  py-2 px-2 text-white cursor`} onClick={() => window.open(`/registration?typeId=8`)}>Add New User</button>
                  </div>
                </Filter>
              </div>
              <div>
                <NewTable
                  filteredSearch={filteredSearch}
                  setFilteredSearch={setFilteredSearch}
                  filterData={filterData}
                  setFilterData={setFilterData}
                  disableAction={true}
                  columns={
                    [
                      {
                        name: "Company", filter: true, filterDataKey: "Exporter Name", sort: [
                          { name: "Sort A-Z", selected: filter.sortCompanyName === 'ASC', onActionClick: () => { setFilter({ ...filter, sortCompanyName: 'ASC', sortContactPerson: false, sortCompanyCity: false, sortLeadAssignedTo: false, sortByDate: false }); setRefresh(refresh + 1) } },
                          { name: "Sort Z-A", selected: filter.sortCompanyName === 'DESC', onActionClick: () => { setFilter({ ...filter, sortCompanyName: 'DESC', sortContactPerson: false, sortCompanyCity: false, sortLeadAssignedTo: false, sortByDate: false }); setRefresh(refresh + 1) } }]
                      },
                      {
                        name: "Contact person", filter: true, filterDataKey: "Contact Person", sort: [
                          { name: "Sort A-Z", selected: filter.sortContactPerson === 'ASC', onActionClick: () => { setFilter({ ...filter, sortCompanyName: false, sortContactPerson: 'ASC', sortCompanyCity: false, sortLeadAssignedTo: false, sortByDate: false }); setRefresh(refresh + 1) } },
                          { name: "Sort Z-A", selected: filter.sortContactPerson === 'DESC', onActionClick: () => { setFilter({ ...filter, sortCompanyName: false, sortContactPerson: 'DESC', sortCompanyCity: false, sortLeadAssignedTo: false, sortByDate: false }); setRefresh(refresh + 1) } }]
                      },
                      {
                        name: "Contact no.", filter: true, filterDataKey: "Contact Number"
                      },
                      {
                        name: "City", filter: true, filterDataKey: "Company City", sort: [
                          { name: "Sort A-Z", selected: filter.sortCompanyCity === 'ASC', onActionClick: () => { setFilter({ ...filter, sortCompanyName: false, sortContactPerson: false, sortCompanyCity: 'ASC', sortLeadAssignedTo: false, sortByDate: false }); setRefresh(refresh + 1) } },
                          { name: "Sort Z-A", selected: filter.sortCompanyCity === 'DESC', onActionClick: () => { setFilter({ ...filter, sortCompanyName: false, sortContactPerson: false, sortCompanyCity: 'DESC', sortLeadAssignedTo: false, sortByDate: false }); setRefresh(refresh + 1) } }]
                      },
                      {
                        name: "Admin", filter: true, filterDataKey: "Lead Assigned To", sort: [
                          { name: "Sort A-Z", selected: filter.sortLeadAssignedTo === 'ASC', onActionClick: () => { setFilter({ ...filter, sortCompanyName: false, sortContactPerson: false, sortCompanyCity: false, sortLeadAssignedTo: 'ASC', sortByDate: false }); setRefresh(refresh + 1) } },
                          { name: "Sort Z-A", selected: filter.sortLeadAssignedTo === 'DESC', onActionClick: () => { setFilter({ ...filter, sortCompanyName: false, sortContactPerson: false, sortCompanyCity: false, sortLeadAssignedTo: 'DESC', sortByDate: false }); setRefresh(refresh + 1) } }]
                      },
                      { name: "Type" },
                      {
                        name: "Status", filter: true, filterDataKey: "StatusFilter", sort: [
                          { name: "Sort Oldest", selected: filter.sortByDate === 'ASC', onActionClick: () => { setFilter({ ...filter, sortCompanyName: false, sortContactPerson: false, sortCompanyCity: false, sortByDate: 'ASC', sortLeadAssignedTo: false }); setRefresh(refresh + 1) } },
                          { name: "Sort Latest", selected: filter.sortByDate === 'DESC', onActionClick: () => { setFilter({ ...filter, sortCompanyName: false, sortContactPerson: false, sortCompanyCity: false, sortByDate: 'DESC', sortLeadAssignedTo: false }); setRefresh(refresh + 1) } }]
                      },
                      { name: "" }
                    ]

                  }
                  data={dbData} />
                <Pagination page={page} perPage={filter.resultPerPage} totalCount={count} onPageChange={(p) => setPage(p)} refresh={refresh} setRefresh={setRefresh} />

              </div>
            </div>
          </>
        }
      </div>
    </>
  )
}

export default FinancersTab