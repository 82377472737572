import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import HeaderV2 from '../partial/headerV2';
import SideBarV2 from '../partial/sideBarV2';
import config from "../../config.json";
import { FileInput } from '../../utils/FileInput';
import { ExportExcel, getDocDetails, getPDFFromFile, isEmpty, printDiv, sendDocumentsToFinancer } from '../../utils/myFunctions';
import call from '../../service';
import { convertImageToPdf } from '../../utils/myFunctions';
import toastDisplay from '../../utils/toastNotification';
import { InputForTable, NewInput, NewSelect, NewTextArea } from '../../utils/newInput';
import FinanceInvoiceModal from '../InvoiceDiscounting/contract/components/financeinvoiceModal';
import Filter from '../InvoiceDiscounting/components/Filter';
import { NewTable } from '../../utils/newTable';
import moment from 'moment';
import Shareholder from './components/shareholder';
import UserTab from './components/userTab';
import CompanyTab from './components/companyTab';
import FinanceTab from './components/financeTab';
import { ToastContainer } from 'react-toastify';
import FilterProfile from '../InvoiceDiscounting/components/FilterProfile';
import validateAddBank from '../../utils/validation_files/AddBankValidations';
import MultipleSelect from '../../utils/MultipleSelect';
import { CustomProgressBar } from '../myCounterPartComp/checkBuyerHealth';
import { DocumentViewer } from '../../utils/documentViewer';
import TutorialPopup, { TutorialVideoPopup } from '../tutorialPopup';
import CreditLineTab from './components/CreditLineTab';
import avatarUrl from '../../utils/makeAvatarUrl';
import ChatBoxPopUp2 from '../chatRoom/components/ChatBoxPopUp2';
import { useRef } from 'react';
import ExtraDetailsForLimitApplication from '../InvoiceDiscounting/extraDetailsForLimitApplication';

const tutorialPopupCSS = {
  "0": {
    popup: {
      "top": "3rem",
      "right": "-20rem"
    },
    arrow: {
      "top": "4rem",
      "zIndex": 10,
      transform: "rotate(465deg)",
      left: "88.5%"
    },
    children: <label className='font-size-14 font-wt-600 text-color-value'><span>View all your organization details in "User Profile" by clicking on Avatar icon</span></label>
  }
}

const tutorialPopupCSSForBank = {
  "0": {
    popup: {
      "top": "0rem",
      "right": "-20rem"
    },
    arrow: {
      "top": "6rem",
      "zIndex": 100000,
      transform: "rotate(258deg)",
      left: "51.5%"
    },
    children: <label className='font-size-14 font-wt-600 text-color-value'><span>Fill below form to add your bank account.</span></label>
  }
}


const BenfbankDetails = [
  { "name": "Bank Name", val: "beneficiaryBankNameame" },
  { "name": "Branch", val: "beneficiaryBranch" },
  { "name": "Account holder name", val: "beneficiaryAccountName" },
  { "name": "Account No.", val: "beneficiaryAccountNo" },
  { "name": "IFSC Code", val: "beneficiaryIfscCode" },
  { "name": "Swift Code", val: "beneficiarySwiftCode" },
  { "name": "Address", val: "beneficiaryBranchAddress" },
]
const CorrbankDetails = [
  { "name": "Bank Name", val: "correspondentBankName" },
  { "name": "Account No.", val: "correspondentAccountNumber" },
  { "name": "Swift Code", val: "correspondentSwift" },
  { "name": "Email ID", val: "email" },
]

export const KYCUploads = [
  { name: "PAN card", val: "PAN_Document" },
  { name: "GST certificate", val: "GST_Document" },
  { name: "IEC", val: "IEC_Document" },
  { name: "CIN", val: "CIN_Document" },
  // { name: "Aadhar card", val: "Aadhaar_Document" }

]

const docsFormArray = [
  { "key": "MOM_Document", "name": "MOM Document", "dbId": ":9:44" },
  { "key": "AOA_Document", "name": "AOA Document", "dbId": ":9:45" },
  { "key": "Shareholding_Document", "name": "Shareholding Document", "dbId": ":9:46" },
  { "key": "Partnership_Deed_Document", "name": "Partnership Deed Document", "dbId": ":9:47" },
  { "key": "Company_Profile_Document", "name": "Company Profile Document", "dbId": ":9:48" },
]

const creditreportdata = [
  {
    logo: "/assets/images/logo-dnb 1.png",
    companyname: "D&B",
    alt: "D&B",
    link: 'https://www.dnb.co.in/risk-management-solutions/finance-credit-risk/business-credit-report'
  },
  {
    logo: "/assets/images/equifax_logo.png",
    companyname: "Equifax",
    alt: "Equifax",
    link: "https://www.equifax.com/personal/credit-report-services/free-credit-reports/"
  },
  {
    logo: "/assets/images/Experian_logo.png",
    companyname: "Experian",
    alt: "Experian",
    link: 'https://www.experian.com/consumer-products/free-credit-report.html'
  },
  {
    logo: "/assets/images/transunion-logo.png",
    companyname: "Trans union",
    alt: "Trans Union",
    link: 'https://www.transunion.com/annual-credit-report'
  },
  {
    logo: "/assets/images/logo-spglobal.png",
    companyname: "S&P",
    alt: "S&P Global",
    link: 'https://www.spglobal.com/esg/scores/results'
  },
  {
    logo: "/assets/images/logo_crisil.png",
    companyname: "CRISIL",
    alt: "CRISIL",
    link: 'https://www.crisil.com/en/home/our-businesses/ratings/Credit-Rating-Report.html'
  },
  {
    logo: "/assets/images/logo_icra.png",
    companyname: "ICRA",
    alt: "ICRA",
    link: 'https://www.icra.in/Home/Index'
  },
  {
    logo: "/assets/images/logo_smera.png",
    companyname: "SMERA",
    alt: "SMERA",
    link: 'https://www.smeraonline.com/newweb/index.php/sme-rating/'
  },
  {
    logo: "/assets/images/logo_onicra.jfif",
    companyname: "ONICRA",
    alt: "ONICRA",
    link: 'https://in.linkedin.com/company/onicra-credit-rating-agency-of-india-limited'
  },
  {
    logo: "/assets/images/logo_brickwork.jpg",
    companyname: "BRICKWORK",
    alt: "BRICKWORK",
    link: 'https://www.brickworkratings.com/Home.aspx'
  }
];



const ViewProfile = ({ userTokenDetails, navToggleState }) => {


  const queryParams = new URLSearchParams(window.location.search)
  const buyer = queryParams.get("buyer")
  const profileAction = queryParams.get("action")

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  const [dbData, setDbData] = useState([])
  const [KYCDocuments, setKYCDocuments] = useState([])
  const [tab, setTab] = useState(profileAction === "addNewBank" ? 5 : 0)
  const [data, setData] = useState({ pageCount: 10 })
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [addMoreDoc, setAddMoreDoc] = useState([null])
  const [tableData, setTableData] = useState([])
  const [shareholderdata, setshareholderdata] = useState([])
  const [count, setCount] = useState(0)
  const [countrys, setCountrys] = useState([])
  const [userdata, setUserData] = useState([])
  const [swiftCodedata, setSwifCodeMaster] = useState([])
  const [allSwiftCodeData, setAllSwiftCodeData] = useState([])
  const [states, setstatedata] = useState([])
  // const [dunsData, setDunsData] = useState([])
  // const [hsnCodes, setHsnCodes] = useState([])
  // const [countrys, setCountrys] = useState([])
  // const [currencyData, setcurrencyData] = useState(most_used_currencies);
  // const [financierData, setFinancierData] = useState([]);
  const [totalRecordsbanks, setTotalRecordsBanks] = useState(0)
  const [limitinvoice, setLimitinvoice] = useState(false);
  const [experience, setExperience] = useState(false);
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 100 })
  const [bank, setBank] = useState(profileAction === "addNewBank" ? true : false)
  const [bankdetails, setbankdetails] = useState({ isVisible: false, data: null })
  const [isSubmitted, setSubmitted] = useState(false)
  const [ProgressBars, setProgressBars] = useState([])
  const [showPopup, togglePopup] = useState(false)
  const [creditReportData, setCreditReportData] = useState({})
  const [openLink, setOpenLink] = useState({ show: false, link: null })
  const [viewDoc, toggleViewDoc] = useState({ show: false, doc: {} })
  const [gstBranchList, setgstBranchList] = useState([])
  const [lcTutorial, toggleLCTutorial] = useState(localStorage.getItem("lcTutorialStep") == 6)
  const [tutorialStage, setTutorialStage] = useState(0)
  const [showTutorialVideo, toggleTutorialVideo] = useState({ show: false, link: null })
  const [lcTutorialAddBank, toggleLCTutorialAddBank] = useState(localStorage.getItem("lcTutorialStep") == 11)
  let [addBuyerTabs, setAddBuyerTabs] = useState(userTypeId / 1 == 8 ? [
    { name: "User" },
    { name: "Company" },
    { name: "Shareholder " },
    { name: "Finance" },
    { name: "KYC " },
    { name: "Bank " },
    { name: "Credit report" },
    { name: "Password" },
    { name: "About" }
  ] : [
    { name: "User" },
    { name: "Company" },
    { name: "Shareholder " },
    { name: "Finance" },
    { name: "KYC " },
    { name: "Bank " },
    { name: "Credit report" },
    { name: "Password" },
  ])
  let [reviews, setReviews] = useState([])
  const [bio, setBio] = useState('')
  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [scrollCardIndex, setScrollCardIndex] = useState(1)

  const quoteCardRef = useRef([])

  const scrollLeft = () => {
    // console.log("qqqqqqqqqqqqqqqqqqqqqqqqqqqq", quoteCardRef, quoteCardRef.current);
    let cardOffsetWidth = quoteCardRef.current[0].offsetWidth
    let containerRef = document.getElementById("scrollContainerId")
    // console.log("letCardOffsetWidthhhhhhhhhhhhh", cardOffsetWidth);
    containerRef.scrollTo({ left: -cardOffsetWidth * 1, behavior: 'smooth' })
    // let subCardIndx = (scrollCardIndex - 1) > 0 ? (scrollCardIndex - 1) : 1
    setScrollCardIndex(1)
  };

  const scrollRight = () => {
    let cardOffsetWidth = quoteCardRef.current?.[0]?.offsetWidth
    // console.log("rightCardOffsetWidthhhhhhhhhhhhh", cardOffsetWidth, scrollCardIndex);
    if (cardOffsetWidth) {
      let containerRef = document.getElementById("scrollContainerId")
      containerRef.scrollTo({ left: cardOffsetWidth * 1 * scrollCardIndex, behavior: 'smooth' })
      let subCardIndx = scrollCardIndex + 1
      setScrollCardIndex(subCardIndx)
    }
  };

  useEffect(() => {
    updateBio()
  }, [bio])

  async function updateBio() {
    await call('POST', 'updateUserBio', { bio: bio || "", userId })
  }

  useEffect(() => {
    if (userTypeId === 8) {
      addBuyerTabs.splice(7, 0, { name: "Criteria" })
      getUserRatings()
    }
  }, [])

  async function getUserRatings() {
    setshowLoader(true)
    let reviewsResp = await call('POST', 'getUserRatingAndBio', { userId })
    setshowLoader(false)
    setReviews(reviewsResp)
    setBio(reviewsResp?.[0]?.bio || "")
  }


  useEffect(() => {
    call('POST', 'getCountryCity', { countryId: 4 }).then((result) => {
      console.log('running getCountryCity api-->', result);

    }).catch((e) => {

    });

    call('POST', 'getUserCreditRatings', { userId }).then((result) => {
      console.log('running getUserCreditRatings api-->', result);
      setProgressBars(result)
    }).catch((e) => {

    });

    call('POST', 'getCreditReports', { userId }).then((result) => {
      console.log('running getCreditReports api-->', result);
      setCreditReportData(result)
    }).catch((e) => {

    });


  }, [refresh])
  useEffect(() => {
    call('GET', 'getallCountry').then((result) => {
      console.log('running getallCountry api-->', result);
      setCountrys(result)
    }).catch((e) => {
      // console.log('error in getBuyersDetail', e);
    });
  }, [])
  useEffect(() => {
    call('GET', 'getallstates').then((result) => {
      console.log('running getallstates api-->', result);
      //setCountrys(result)
    }).catch((e) => {
      // console.log('error in getBuyersDetail', e);
    });
  }, [])

  const getSwiftCodes = () => {
    call('POST', 'getSwiftMaster', {}).then(result => {
      console.log('success in getSwiftMaster', result)
      setSwifCodeMaster(result)
      setAllSwiftCodeData(result)
    }).catch(e => {
      console.log('error in getSwiftMaster', e)
    })
  }
  const getStateByCountry = (countryId) => {
    call("POST", "getStateByCountry", { countryId }).then(result => {
      console.log('Success getStateByCountry data', result)
      setstatedata(result)
    }).then(e => {
      console.log("Error in getStateByCountry", e)
    })
  }
  useEffect(() => {
    getSwiftCodes()
  }, [])
  const handleChange = async (event) => {
    if (event.persist) {
      event.persist()
    }
    if (event.target.name === 'BenfSwiftCode') {
      const swiftBankName = swiftCodedata.find(data => data.swiftCode === event.target.value)
      setData({ ...data, [event.target.name]: event.target.value, "BenfbankName": swiftBankName.bankName })
      setErrors({ ...errors, [event.target.name]: "" })
      return

    }
    if (event.target.name === 'CorrSwiftCode') {
      const swiftBankName = swiftCodedata.find(data => data.swiftCode === event.target.value)
      setData({ ...data, [event.target.name]: event.target.value, "CorrbankName": swiftBankName.bankName })
      setErrors({ ...errors, [event.target.name]: "" })
      return
    }
    if (event.target.name === 'bankCountry') {
      const country = countrys.find(data => data.sortname === event.target.value)
      getStateByCountry(country.id)
    }
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })

  }

  const closeExpFn = () => {
    setExperience(false);
  }

  const closeSuccess = () => {
    setLimitinvoice(false);
    setExperience(true);
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    if (selectedChat.invApplicationId) {
      formdata.append('invApplicationId', selectedChat.invApplicationId)
    }
    if (selectedChat.lcApplicationId) {
      formdata.append('lcApplicationId', selectedChat.lcApplicationId)
    }
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))

    formdata.append("userTypeId", userTypeId)
    formdata.append("sellerId", selectedChat.sellerId)

    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  const handleBankSubmit = () => {
    setshowLoader(true)
    var formData = new FormData();
    formData.append('beneficiaryBankName', data.BenfbankName)
    formData.append('beneficiaryBranch', data.BenfbankName)
    formData.append('beneficiaryBranchAddress', data.BenfAddress)
    formData.append('beneficiaryCity', data.BenfCity)
    formData.append('beneficiaryState', data.bankState)
    formData.append('beneficiaryCountry', data.bankCountry)
    formData.append('beneficiaryPostalCode', data.bankPostal)
    formData.append('beneficiaryAccountName', data.BenfaccountHolder)
    formData.append('beneficiaryAccountNo', data.BenfAccountNumber)
    formData.append('beneficiaryIfscCode', data.BenfIFSCCode)
    formData.append('beneficiarySwiftCode', data.BenfSwiftCode)
    formData.append('email', userdata.email_id)
    formData.append('correspondentBankName', data.CorrbankName)
    formData.append('correspondentSwift', data.CorrSwiftCode)
    formData.append('correspondentAccountNumber', data.CorrAccountNo)
    formData.append('bankStatement', data.Bank_Statement)
    formData.append('bankPassbook', data.Bank_Passbook)
    formData.append('bankblanqueCheck', data.Blank_Cheque)
    formData.append('userId', userId)

    call('POST', 'addBank', formData).then((result) => {
      if (result) {
        console.log('addbank sucess')
        setBank(false)
        setshowLoader(false)
        getUserBankList()
        toastDisplay("Bank details saved successfuly!", "success", () => {
          if (lcTutorialAddBank) {
            localStorage.setItem("lcTutorialStep", 9)
            window.location.reload()
          }
          else if (profileAction === "addNewBank") {
            window.close()
          }
        });
        setSubmitted(false)
      }
    }).catch(err => {
      setshowLoader(false)
      toastDisplay(err, "error");
      setSubmitted(false)
    })
  }
  const removeBank = (finDtId) => {
    setshowLoader(true)
    call('POST', 'removeBank', { finDtId }).then(result => {
      toastDisplay('Bank removed succesfully', 'success')
      getUserBankList()
      setbankdetails({
        isVisible: false,
        data: null
      })
      setshowLoader(false)
    }).catch(e => {
      toastDisplay('Failed to delete bank', 'error')
      setshareholderdata(false)
    })
  }
  const handleFile = (event, isImage) => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }
      console.log('Fileddatataaaa', event.target.files)
      if (event.target.name.includes("otherDoc")) {
        setAddMoreDoc(addMoreDoc.concat(null))
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result

        if (!file_type.includes("pdf") && !isImage) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }
  const updateUserData = () => {
    setshowLoader(true)
    const formData = new FormData()
    formData.append('userTypeId', userTypeId)
    formData.append('userId', userId)
    formData.append('email', data.email_id)
    formData.append('contactNo', data.contact_number)
    formData.append('address', data.user_address)
    formData.append('userName', data.user_name)
    formData.append('avatarFile', data.user_avatar)
    const profilekycdocs = [KYCUploads[0], KYCUploads[4]]
    profilekycdocs.forEach(kycuploads => {
      if (data[kycuploads?.val] !== undefined && data[kycuploads?.val] !== null) {
        const KYCData = KYCDocuments.find(data => kycuploads?.val === data.doc_name.split(' ').join('_'))
        console.log('MatchFound', KYCData)
        let payloadUpload = {}
        if (KYCData) {
          payloadUpload = {
            uploadingUser: userId,
            kycUserId: userId,
            userTypeName: '',
            id: KYCData.id ? KYCData.id : '',
            tbl_doc_id: KYCData.tbl_doc_id ? KYCData.tbl_doc_id : '',
            contract_id: "",
            linked_to: KYCData.linked_to,
            isUpdateQuery: KYCData.doc_status === 1 ? true : false,
            prevFileHash: KYCData.file_hash,
            categoryId: KYCData.category_id,
            type: '',
            filePayload: {
              docNo: KYCData.doc_no,
              docName: KYCData.doc_name,
              genDocLabel: (KYCData.doc_status === 1 && KYCData.doc_type === 1) ? KYCData.gen_doc_label : '',
              docType: KYCData.doc_type,
              validUpto: KYCData.valid_upto
            }
          }
        } else {
          payloadUpload = {
            uploadingUser: userId,
            kycUserId: userId,
            userTypeName: '',
            contract_id: "",
            linked_to: 1,
            isUpdateQuery: false,
            prevFileHash: '',
            categoryId: 2,
            type: '',
            filePayload: {
              docNo: '',
              docName: kycuploads.val.split('_').join(' '),
              genDocLabel: '',
              docType: 1,
              validUpto: null
            }
          }

        }
        payloadUpload = JSON.stringify(payloadUpload);
        let formData = new FormData();
        formData.append('payloadUpload', payloadUpload);
        formData.append('doc', data[kycuploads.val])
        console.log('Doctypeeeeeeee', data[kycuploads.val], data[kycuploads.val] && data[kycuploads.val].fromDb)
        if (!(data[kycuploads.val] && data[kycuploads.val].fromDb)) {
          call('POST', 'updateDoc', formData).then((result) => {
            if (result) {
              toastDisplay(kycuploads.name + " Uploaded", "success");
            }
          }).catch(err => {
            console.log("conn:", err)
            toastDisplay("Failed to upload " + kycuploads.name, "error");
          })
        }
      }
    })
    call('POST', 'updateUserDetails', formData).then((result) => {
      console.log('running updateUserDetails api-->', result);
      setshowLoader(false)
      toastDisplay("User Profile Updated Succesfully", "success");
      setTimeout(() => {
        //getUserProfileData()

        window.location.reload()
      }, 500);

    }).catch((e) => {
      setshowLoader(false)
      toastDisplay(e, "error");

    });
  }
  const getBranchesData = () => {
    setshowLoader(true)
    call('POST', 'getBranchesData', { userId }).then(result => {
      setgstBranchList(formatDataForGSTTable(result))
      setshowLoader(false)
    }).catch(e => {
      setshowLoader(false)
    })
  }
  useEffect(() => {
    getBranchesData()
  }, [])
  function formatDataForGSTTable(data) {
    let tableData = []
    let row = []
    data.forEach((item, index) => {
      row[0] = item.branchCode
      row[1] = item.gstin
      row[2] = item.address
      tableData.push(row)
      row = []
    })
    return tableData
  }
  const getUserBankList = () => {
    let objectAPI = {
      email: userEmail,
      inProfile: true,
      search: data.search_text,
      resultPerPage: data.pageCount,
      currentPage: 1
    }
    call('POST', 'getUserBanks', objectAPI).then((result) => {
      console.log('running getUserBanks api-->', result);
      setTableData(formatDataForTable(result.message))
      setDbData(result.message)
      setTotalRecordsBanks(result.countData)
      if (result.countData < 10) {
        setData({
          ...data,
          pageCount: result.countData
        })
      } else {
        setData({
          ...data,
          pageCount: 10
        })
      }
    }).catch((e) => {
      console.log('error in getBuyersDetail', e);
    });
  }
  useEffect(() => {
    getUserBankList()
  }, [refresh])
  const getUserProfileData = () => {
    let reqObj = {
      "email": userEmail,
      "kyc": true
    }
    call('POST', 'getuserprofiledata', reqObj).then(async (result) => {
      console.log('running getuserprofiledata api-->', data);
      let userProfileData = result.userProfileData
      setUserData(result.userProfileData)
      let kycDocs = {}
      for (let i = 0; i <= result.userKYCData.length - 1; i++) {
        let KYCdata = result.userKYCData[i]
        //let KYCdata = resultdata[i]
        const res = await getDocDetails(KYCdata.tbl_doc_id)
        if (KYCdata.tbl_doc_id) {
          sendDocumentsToFinancer(userId, KYCdata.tbl_doc_id, userEmail, KYCdata.doc_name, userTypeId)
        }

        if (res.filebase64) {
          kycDocs[KYCdata.doc_name.split(' ').join('_')] = {
            ...res,
            name: KYCdata.file_name
          }
        }
      }
      console.log('KYCDataaaaaaaaa2', kycDocs)

      // result.userKYCData.forEach(async KYCdata => {


      // })
      setKYCDocuments(result.userKYCData)
      getUserDetailsExtra({
        user_name: result.userProfileData.user_name,
        contact_number: result.userProfileData.contact_number,
        user_address: result.userProfileData.user_address,
        email_id: result.userProfileData.email_id,
        user_avatar: result.userProfileData.user_avatar ? result.userProfileData.user_avatar : null,
        company_name: result.userProfileData.company_name,
        organization_type: result.userProfileData.organization_type,
        industry_type: result.userProfileData.industry_type,
        pan_no: result.userProfileData.pan_no,
        gst_vat_no: result.userProfileData.gst_vat_no,
        iec_no: result.userProfileData.iec_no,
        cin_no: result.userProfileData.cin_no,
        aadhar_no: result.userProfileData.aadhar_no,
        company_pan_verification: result.userProfileData.company_pan_verification,
        company_cin_verification: result.userProfileData.company_cin_verification,
        company_gst_verification: result.userProfileData.company_gst_verification,
        company_iec_verification: result.userProfileData.company_iec_verification,
        kyc_done: (result.userProfileData.kyc_done / 1),
        country: result.userProfileData.country_code,
        contactPerson: result.userProfileData.contact_person,
        designation: result.userProfileData.designation,
        companyPostal: result.userProfileData.company_postal_code,
        companyCity: result.userProfileData.company_city,
        companyAdd1: result.userProfileData.company_address1,
        companyAdd2: result.userProfileData.company_address2,
        companyState: result.userProfileData.company_state,
        CompanyCountry: result.userProfileData.company_country,
        ...kycDocs,
        referralCode: result.userProfileData.refercode

      })
    }).catch((e) => {
      console.log('error in getuserprofiledata', e);
    });
  }
  const updateCompanyDetails = async () => {
    console.log('usedata', data)
    setshowLoader(true)
    const extradetailsreqObj = new FormData()
    extradetailsreqObj.append('companyName', data.company_name)
    extradetailsreqObj.append('website', data.website)
    extradetailsreqObj.append('dunsNo', data.dunsNo)
    extradetailsreqObj.append('minBusiness', data.minExisting)
    extradetailsreqObj.append('maxBusiness', data.minExpected)
    extradetailsreqObj.append('countryIncorporation', data.country_of_incorporation)
    extradetailsreqObj.append('countryOperation', data.country_of_operation)
    extradetailsreqObj.append('companyEmail', data.company_email)
    extradetailsreqObj.append('companyAddress', null)
    extradetailsreqObj.append('userId', userId)
    extradetailsreqObj.append('userEmail', userEmail)
    extradetailsreqObj.append('prevNetProfit', data.prevNetProfit)
    extradetailsreqObj.append('years_of_incorporation', data.years_of_incorporation)
    // extradetailsreqObj.append('ExisExportTurnover', data.ExisExportTurnover)
    // extradetailsreqObj.append('ExpecExportTurnover', data.ExpecExportTurnover)
    // extradetailsreqObj.append('ExisDomesTurnover', data.ExisDomesTurnover)
    // extradetailsreqObj.append('ExpecDomesTurnover', data.ExpecDomesTurnover)
    extradetailsreqObj.append('currency', data.currency)

    for (let i = 0; i <= docsFormArray.length - 1; i++) {
      let compDocs = docsFormArray[i]
      if (data[compDocs.name] === null || data[compDocs.name] === undefined) {
        extradetailsreqObj.append(compDocs.key + compDocs.dbId, null)
      } else {
        if (data[compDocs.name].fromDb === true) {
          //Convert To file Object
          if (data[compDocs.name].filebase64) {
            const filedata = await fetch('data:application/pdf;base64,' + data[compDocs.name].filebase64)
            const blob = await filedata.blob();
            const fileObj = new File([blob], data[compDocs.name].name, { type: blob.type });
            extradetailsreqObj.append(compDocs.key + compDocs.dbId, fileObj)
          }
        } else {
          extradetailsreqObj.append(compDocs.key + compDocs.dbId, data[compDocs.name])
        }
      }
    }
    console.log('inserttextraaaaaaaaa', extradetailsreqObj)
    const detailsreqObj = new FormData()
    detailsreqObj.append('userTypeId', userTypeId)
    detailsreqObj.append('userId', userId)
    detailsreqObj.append('email', data.email_id)
    detailsreqObj.append('industryType', data.industry_type)
    detailsreqObj.append('organizationType', data.organization_type)
    detailsreqObj.append('companyAdd1', data.companyAdd1)
    detailsreqObj.append('companyAdd2', data.companyAdd2)
    detailsreqObj.append('companyState', data.companyState)
    detailsreqObj.append('companyCity', data.companyCity)
    detailsreqObj.append('companyPostalCode', data.companyPostal)
    detailsreqObj.append('companyCountry', data.CompanyCountry)
    try {
      const result = await call('POST', "insertuserdetailsextra", extradetailsreqObj)
      const result2 = await call('POST', "updateUserDetails", detailsreqObj)
      console.log('running insertuserdetailsextra api-->', result);
      console.log('running updateUserDetails api-->', result2)
      getUserProfileData()
      setshowLoader(false)
      toastDisplay("Company data updated succesfully", "success")
    } catch (e) {
      console.log('error insertuserdetailsextra api-->', e);
      setshowLoader(false)
      toastDisplay("Failed to update company data", "error")
    }
  }
  const getReferCode = () => {
    call('GET', 'regenmyrefercode', {}).then(res => {
      toastDisplay('Referral Code generated', 'Success')
      getUserProfileData()
    }).catch(e => {
      toastDisplay('Failed to generate Referral code', 'error')
    })
  }
  const getUserDetailsExtra = async (extraDetails) => {
    let reqObj = {
      "loggedUserData": userId,
    }
    try {
      const result = await call('POST', 'getuserdetailsextra', reqObj)
      console.log('running getuserdetailsextra api-->', result);
      let businessDocuments = {}
      for (let i = 0; i <= result.docDetails.length - 1; i++) {
        let BusinessDoc = result.docDetails[i]
        const res = await getDocDetails(BusinessDoc.id)
        if (BusinessDoc.id) {
          sendDocumentsToFinancer(userId, BusinessDoc.id, userEmail, BusinessDoc.doc_name, userTypeId)
        }
        console.log('BusinessDocs', res)
        if (res.filebase64) {
          businessDocuments[BusinessDoc.doc_name] = {
            ...res,
            name: BusinessDoc.file_name
          }
        }
      }
      setData({
        ...extraDetails,
        company_email: result.userDetails.company_email,
        website: result.userDetails.website,
        dunsNo: result.userDetails.dunsNo,
        country_of_incorporation: result.userDetails.country_of_incorporation,
        country_of_operation: result.userDetails.country_of_operation,
        minExisting: result.userDetails.minExisting,
        minExpected: result.userDetails.minExpected,
        ...businessDocuments,
        currency: result.userDetails.currency,
        years_of_incorporation: result.userDetails.years_of_incorporation,
        prevNetProfit: result.userDetails.prevNetProfit
      })
    } catch (e) {
      console.log('error in getuserdetailsextra', e);
    }

  }
  useEffect(() => {
    getUserProfileData()
  }, [refresh])

  function formatDataForTable(data) {
    let tableData = []
    let row = []
    data.forEach((item, index) => {
      let hsnCodes = item.Financersselected ? item.Financersselected.split(",") : []
      const docArray = item.bankDocs ? item.bankDocs.split(',') : []
      row[0] = item.beneficiaryAccountName
      row[1] = item.beneficiaryAccountNo
      row[2] = item.beneficiaryBankName
      row[3] = item.beneficiaryIfscCode
      row[4] = item.beneficiarySwiftCode
      row[5] = item.correspondentBankName
      row[7] = item.correspondentAccountNumber
      row[8] = <img src={"assets/images/eye.png"} alt='' onClick={async () => {
        if (docArray.length > 1) {
          item.bankStatement = await getDocDetails(docArray[0])
          item.bankPassbook = await getDocDetails(docArray[1])
          item.blankCheque = await getDocDetails(docArray[2])
        }
        setbankdetails({
          isVisible: true,
          data: item
        })
        setBank(false)
      }} />
      tableData.push(row)
      row = []
    })
    return tableData
  }

  const exportToexcel = (data, filename) => {
    let downloaddata = []
    data.forEach(data => {
      let downloadobj = {
        "Beneficiary Bank Name": data.beneficiaryBankName,
        "Beneficiary Branch": data.beneficiaryBranch,
        "Beneficiary Account holder name": data.beneficiaryAccountName,
        "Beneficiary Account No.": data.beneficiaryAccountNo,
        "Beneficiary IFSC Code": data.beneficiaryIfscCode,
        "Beneficiary SWIFT Code": data.beneficiarySwiftCode,
        "Beneficiary Address": data.beneficiaryBranchAddress,
        "Correspondent Bank Name": data.correspondentBankName,
        "Correspondent Account No.": data.correspondentAccountNumber,
        "Correspondent SWIFT Code": data.correspondentSwift,
        "Correspondent Email ID": data.email
      }
      downloaddata.push(downloadobj)
    })
    ExportExcel(downloaddata, filename)
  }
  async function handleValidation(tabIndex) {
    return setTab(tabIndex)
  }
  useEffect(() => {
    console.log('Error data printtt', errors, data)
    if (Object.values(errors).every(data => data === '') && isSubmitted && tab === 5) {
      handleBankSubmit()
    } else {
      setSubmitted(false)
    }
  }, [errors])
  const updateKYCdata = () => {
    setshowLoader(true)
    KYCUploads.forEach(kycuploads => {
      if (data[kycuploads.val] !== undefined && data[kycuploads.val] !== null) {
        const KYCData = KYCDocuments.find(data => kycuploads.val === data.doc_name.split(' ').join('_'))
        console.log('MatchFound', KYCData)
        let payloadUpload = {}
        if (KYCData) {
          payloadUpload = {
            uploadingUser: userId,
            kycUserId: userId,
            userTypeName: '',
            id: KYCData.id ? KYCData.id : '',
            tbl_doc_id: KYCData.tbl_doc_id ? KYCData.tbl_doc_id : '',
            contract_id: "",
            linked_to: KYCData.linked_to,
            isUpdateQuery: KYCData.doc_status === 1 ? true : false,
            prevFileHash: KYCData.file_hash,
            categoryId: KYCData.category_id,
            type: '',
            filePayload: {
              docNo: KYCData.doc_no,
              docName: KYCData.doc_name,
              genDocLabel: (KYCData.doc_status === 1 && KYCData.doc_type === 1) ? KYCData.gen_doc_label : '',
              docType: KYCData.doc_type,
              validUpto: KYCData.valid_upto
            }
          }
        } else {
          payloadUpload = {
            uploadingUser: userId,
            kycUserId: userId,
            userTypeName: '',
            contract_id: "",
            linked_to: 1,
            isUpdateQuery: false,
            prevFileHash: '',
            categoryId: 2,
            type: '',
            filePayload: {
              docNo: '',
              docName: kycuploads.val.split('_').join(' '),
              genDocLabel: '',
              docType: 1,
              validUpto: null
            }
          }

        }
        payloadUpload = JSON.stringify(payloadUpload);
        let formData = new FormData();
        formData.append('payloadUpload', payloadUpload);
        formData.append('doc', data[kycuploads.val])
        console.log('Doctypeeeeeeee', data[kycuploads.val], data[kycuploads.val] && data[kycuploads.val].fromDb)
        if (!(data[kycuploads.val] && data[kycuploads.val].fromDb)) {
          call('POST', 'updateDoc', formData).then((result) => {
            if (result) {
              toastDisplay(kycuploads.name + "Uploaded", "success");
            }
          }).catch(err => {
            console.log("conn:", err)
            toastDisplay("Failed to upload" + kycuploads.name, "error");
          })
        }
      }

    })
    getUserProfileData()
    let KYCreqObj = {
      userTypeId: userTypeId,
      userId: userId,
      email: data.email_id,
      contactPerson: data.contactPerson,
      designation: data.designation,
      contactNo: data.contact_number,
      address: data.user_address,
      country: data.country,
      userAvatar: data.user_avatar,
      kyc_done: data.kyc_done,
      panNo: data.pan_no,
      gstNo: data.gst_vat_no,
      iecNo: data.iec_no,
      cinNo: data.cin_no,
      company_pan_verification: data.company_pan_verification,
      company_cin_verification: data.company_cin_verification,
      company_gst_verification: data.company_gst_verification,
      company_iec_verification: data.company_iec_verification,
      userEmail: data.email_id,
      aadhar_no: data.aadhar_no
    }
    call('POST', 'kycVerification', KYCreqObj).then((result) => {
      console.log('post request result:', result);
      toastDisplay("KYC Details Updated Succesfully", "success");
      getUserProfileData()
      setshowLoader(false);
    }).catch(err => {
      setshowLoader(false);
      console.log("conn:", err);
      toastDisplay("Failed to upload KYC Details", "error");
    })

  }
  const changePassword = () => {
    setshowLoader(true)
    let reqObj = {
      currentPassword: data.currentPasswd,
      newPassword: data.newPasswd,
      confirmPassword: data.confNewPasswd,
      userId: userId,
      userEmail: data.email_id,
      attr1: localStorage.getItem('attr1'),
      attr2: localStorage.getItem('attr2')
    }
    call('POST', "changepassword", reqObj).then(res => {
      console.log('sucess changepassword response', res)
      setshowLoader(false)
      toastDisplay("Password Updated", "success")
    }).catch(e => {
      console.log('Error', e)
      setshowLoader(false)
      toastDisplay("Failed to update password", "error")
    })
  }

  const handleMultiSelect = async (e, name, val) => {
    // console.log("handleMultiSelect", e, name, val);
    if (e?.[0]?.id === "temp") {
      let allSwiftData = allSwiftCodeData
      allSwiftData.push({ ...e[0], swiftCode: e[0]["typedInput"], id: e[0]["typedInput"] })
      setAllSwiftCodeData(allSwiftData)
      setSwifCodeMaster(allSwiftData)
      setData({
        ...data,
        [name]: e[0]["typedInput"]
      })
    }
    else {
      setData({
        ...data,
        [name]: e?.[0]?.swiftCode ? e.reverse()?.[0]?.swiftCode : null
      });
    }
  };

  const handleFilterOptions = (typedInput, name) => {
    // console.log("typedInput", typedInput);
    let tempPort = []
    let filtered = []
    tempPort = [{ id: "temp", "swiftCode": "Add New Option", typedInput }]
    filtered = allSwiftCodeData.filter((i) => {
      if (i.swiftCode && i.swiftCode.toLowerCase().includes(typedInput.toLowerCase())) {
        return i
      }
    })
    if (!filtered.length) {
      filtered = tempPort
    }
    setSwifCodeMaster(filtered)
  };

  console.log('FormData', data)
  return (
    <>

      <TutorialPopup show={lcTutorial} featureName={"User Profile"} positioning={{
        ...tutorialPopupCSS[tutorialStage]["popup"]
      }} showSkip={true} userId={userId} showNext={true}
        onNext={() => {
          localStorage.setItem('lcTutorialStep', 7)
          window.location.reload()
        }}
        onBack={() => {
          localStorage.setItem("lcTutorialStep", 5)
          window.location.reload()
        }}
        showBack={true}
        showDone={true}
        videoLinkLabel={"Watch detailed video about User Profile"}
        onVideoLinkClick={() => toggleTutorialVideo({ show: true, link: "https://www.youtube.com/embed/tgbNymZ7vqY" })}
        children={tutorialPopupCSS[tutorialStage]["children"]}
        arrowPositioning={{
          ...tutorialPopupCSS[tutorialStage]["arrow"]
        }} />

      <TutorialVideoPopup
        show={showTutorialVideo.show}
        videoLink={showTutorialVideo.link}
        onClose={() => toggleTutorialVideo({ show: false, link: null })}
      />

      <TutorialPopup show={lcTutorialAddBank}
        featureName={tutorialPopupCSSForBank[tutorialStage]["stageName"]} positioning={{
          ...tutorialPopupCSSForBank[tutorialStage]["popup"]
        }} showSkip={true} userId={userId}
        children={tutorialPopupCSSForBank[tutorialStage]["children"]}
        arrowPositioning={{
          ...tutorialPopupCSSForBank[tutorialStage]["arrow"]
        }} />

      <div className="container-fluid">
        <div className={`${lcTutorialAddBank && 'overlay_in'}`}></div>
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        <DocumentViewer show={viewDoc.show} value={viewDoc.doc} dismiss={() => toggleViewDoc({ show: false, doc: {} })} />
        <div className="row">
          <SideBarV2 state="viewprofile" userTokenDetails={userTokenDetails} />
          {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"User profile"}
              userTokenDetails={userTokenDetails} />

            {selectedChat.isChatOpen &&
              <div className="chatboxDivFixed">
                <ChatBoxPopUp2
                  chatList={chatList}
                  user_avatar={selectedChat.logo}
                  userId={selectedChat.userId}
                  receiverName={selectedChat.receiverName}
                  parties={selectedChat.parties}
                  userTokenDetails={userTokenDetails}
                  onChatSend={(file, parties) => sendChatMsg(file, parties)}
                  message={message}
                  setTextMsg={setTextMsg}
                  reloadChatList={() => getChatDetails({
                    chat_room_id: selectedChat.chatRoomId,
                    loggedInUser: userId
                  })}
                  onPopupClose={() => {
                    setSelectedChat({
                      receiverName: '',
                      receiverId: '',
                      userId: userId,
                      isChatOpen: false,
                      logo: ''
                    })
                  }}
                />
              </div>
            }

            <div style={lcTutorial ? { "position": "relative", zIndex: -1 } : {}}>
              <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                {addBuyerTabs.map((item, index) => {
                  return (
                    <li>
                      <a className={"nav-link userformTab formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                        onClick={() => { handleValidation(index) }}
                      >
                        {item.name}</a>
                    </li>
                  )
                })}
              </ul>
              <div className="card mt-1">
                {tab === 0 ? (
                  <div className='px-5 py-4 pt-5'>
                    <UserTab handleChange={handleChange} formData={data} handleUserSubmit={updateUserData} handleFile={handleFile} userTokenDetails={userTokenDetails} setData={setData} userId={userId} countrys={countrys} errors={errors} userTypeId={userTypeId} />
                  </div >
                ) : null}
                {
                  tab === 1 ? (
                    <div className='px-5 pt-5 pb-4'>
                      <CompanyTab handleChange={handleChange} userdata={data} countrys={countrys} handleCompanySubmit={updateCompanyDetails} handleFile={handleFile} userTokenDetails={userTokenDetails} setData={setData} />
                    </div>
                  ) : null}
                {tab === 2 ? (
                  <div className='px-5 py-4 pt-5'>
                    <Shareholder userTokenDetails={userTokenDetails} navToggleState={navToggleState} handleFile={handleFile} handleChange={handleChange} setshowLoader={setshowLoader} toastDisplay={(msg, status) => toastDisplay(msg, status)} />
                  </div>
                ) : null}

                {tab === 3 ? (
                  <div className='px-5 py-4 pt-5'>
                    <FinanceTab handleChange={handleChange} handleFile={handleFile} userTokenDetails={userTokenDetails} navToggleState={navToggleState} setshowLoader={setshowLoader} toastDisplay={(msg, status) => toastDisplay(msg, status)} cin_no={data?.cin_no} />
                  </div >
                ) : null}

                {tab === 4 ? (
                  <div className='px-5 py-4 pt-5'>
                    <>
                      <div className='row'>
                        <div className="col-md-4 pt-1 ">
                          <div className="col-md-11 px-0">
                            <NewInput isAstrix={true} type={"text"} label={"PAN No."}
                              name={"pan_no"} value={data.pan_no}
                              onChange={handleChange} />
                          </div>
                        </div>
                        <div className="col-md-4 pt-1 ">
                          <div className="col-md-11 px-0">
                            <NewInput isAstrix={true} type={"text"} label={"IEC No."}
                              name={"iec_no"} value={data.iec_no}
                              onChange={handleChange} />
                          </div>
                        </div>
                        {/* <div className="col-md-5 pt-1 ">
                          <div className="col-md-11 px-0">
                            <NewInput isAstrix={true} type={"text"} label={"GST No."}
                              name={"gst_vat_no"} value={data.gst_vat_no}
                              onChange={handleChange} />
                          </div>
                        </div> */}
                        <div className="col-md-4 pt-1 ">
                          <div className="col-md-11 px-0">
                            <NewInput isAstrix={true} type={"text"} label={"CIN No."}
                              name={"cin_no"} value={data.cin_no}
                              onChange={handleChange} />
                          </div>
                        </div>
                        {/* <div className="col-md-5 pt-1 ">
                          <div className="col-md-11 px-0">
                            <NewInput isAstrix={true} type={"text"} label={"Aadhaar card"}
                              name={"aadhar_no"} value={data.aadhar_no}
                              onChange={handleChange} />
                          </div>
                        </div> */}
                      </div>
                    </>

                    <div>
                      <ExtraDetailsForLimitApplication userTokenDetails={userTokenDetails} hideHeader={true} hideSideBar={true}
                        hideSubmitDiv={true} hideBackArrow={true} expandAll={true} cardBorder0={true} cardPadding0={true}
                        showContinueButton afterDetailsSavedOn={() => { }} gstCredentialsViewHide gstDoc1Hide bankDoc1Hide
                        bankDoc2Hide bankDoc3Hide finDoc1Hide fintDoc2Hide finDoc3Hide finDoc4Hide orgDoc1Hide orgDocLabel={"KYC Documents"} mainTagMarginPadding0
                        continueBtnLabel={"Submit"} />
                    </div>

                    {/* <>
                      <p className='font-size-14 mt-4'>Attach documents</p>
                      <div className='row'>
                        {KYCUploads.map(kycData => {
                          return <div className="col-md-5">
                            <label className="font-size-13">{kycData.name}</label>
                            <div className="row form-group">
                              <div className="col-md-11">
                                <FileInput name={kycData.val} value={data[kycData.val]} error={errors[kycData.val]}
                                  onChange={handleFile} isEditable={true} userTokenDetails={userTokenDetails}
                                  onUploadCancel={() => setData({ ...data, [kycData.val]: null })}
                                />
                                {errors[kycData.val] ? <div class="text-danger mt-2 font-size-12">
                                  <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                  <b>{errors[kycData.val]}</b></div> : ''}
                              </div>
                            </div>
                          </div>
                        })
                        }
                      </div>
                    </>
                    <>
                      <p className='font-size-14 font-wt-600  text-decoration-underline'>Other Branches</p>
                      <div className='mt-4'>
                        <NewTable
                          disableAction
                          columns={[
                            { name: "Branch Code" },
                            { name: "GST IN" },
                            { name: "Address" }
                          ]}
                          data={gstBranchList}
                        />
                        <Pagination perPage={filter.resultPerPage || 10} page={page} totalCount={count} onPageChange={(p) => setPage(p)} />

                      </div>
                    </> */}
                    {/* <button className={`my-4 new-btn w-17 py-2 px-2 text-white cursor`} onClick={updateKYCdata}>Save</button> */}
                  </div >
                ) : null}

                {tab === 5 ? (
                  <div
                    style={lcTutorialAddBank ? {
                      "background": "white",
                      "position": "relative",
                      "zIndex": 10001,
                      "padding": "1rem 0rem 1rem 0rem",
                      "borderRadius": "1rem",
                      "height": "auto",
                      "maxHeight": "40rem",
                      "overflow": "auto"
                    } : {}}
                    className='px-5 py-4 pt-5'>
                    {!bank && !bankdetails.isVisible && <>
                      <FilterProfile pageCount={data.pageCount} handleChange={handleChange} isDisabled={false} maxCount={totalRecordsbanks} applyAction={getUserBankList} searchValue={data.search_text} ExcelExportFunc={() => { exportToexcel(dbData, "ExcelSheet_BankList") }} pdfExportFunc={() => { printDiv("bankTableDiv", "PDF_BankList", []) }} isPaginationRequired={true} isDownloadRequired={true} />
                      <div id='bankTableDiv'>
                        <NewTable
                          disableAction={true}
                          columns={[{
                            name: "Accountholder name", filter: true
                          }, { name: "Account No.", filter: true },
                          { name: "Bank name", filter: true },
                          { name: "IFSC Code", filter: true },
                          {
                            name: "SWIFT Code", filter: true
                          },
                          {
                            name: "Correspondent bank name", filter: true
                          },
                          {
                            name: "Correspondent account no.", filter: false
                          }, { name: "", filter: false },]}
                          data={tableData} />
                      </div>
                      <button onClick={() => setBank(true)} className={`my-4 new-btn w-17 py-2 px-2 text-white cursor`}>Add new bank</button>
                    </>}
                    {bank &&
                      <div
                      >
                        {!lcTutorialAddBank ? (
                          <a className="cursor" onClick={() => {
                            setBank(false)
                            setSubmitted(false)
                          }} ><img src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
                        ) : null}
                        <p className='text-decoration-underline text-color1 font-size-14 mt-3'>Add new bank</p>
                        {/* <>
                        <p className='font-size-13 '>Correspondent bank details</p>
                        <div className='row'>
                          <div className="col-md-5 pt-1 ">
                            <div className="col-md-11 px-0">
                              <NewInput isAstrix={true} type={"email"} label={"Email id"}
                                name={"CorrbankEmail"} value={userdata.email_id} error={errors.email_id}
                                isDisabled={true} />
                            </div>
                          </div>
                          <div className="col-md-5 pt-1">
                            <div className="col-md-11 px-0">
                              <NewInput isAstrix={true} type={"text"} label={"Bank name"}
                                name={"CorrbankName"} value={data.CorrbankName} error={errors.CorrbankName}
                                onChange={handleChange} />
                            </div>
                          </div>
                          <div className="col-md-5 pt-1">
                            <div className="col-md-11 px-0">
                              <MultipleSelect
                                Id="SWIFT code"
                                Label="SWIFT code"
                                filterOption={() => true}
                                onInputChange={(e) => {
                                  handleFilterOptions(e, "swiftCodedata")
                                }}
                                optiondata={swiftCodedata}
                                onChange={(e) => handleMultiSelect(e, "CorrSwiftCode", "swiftCode")}
                                value={data.CorrSwiftCode ? [data.CorrSwiftCode] : []}
                                name="CorrSwiftCode"
                                labelKey={"swiftCode"}
                                valKey={"swiftCode"}
                                error={errors.CorrSwiftCode}
                              />
                            </div>
                          </div>
                          <div className="col-md-5 pt-1">
                            <div className="col-md-11 px-0">
                              <NewInput isAstrix={true} type={"text"} label={"Account no."}
                                name={"CorrAccountNo"} value={data.CorrAccountNo} error={errors.CorrAccountNo}
                                onChange={handleChange} />
                            </div>
                          </div>
                        </div>
                      </> */}
                        <>
                          <p className='font-size-13 mt-4'>Beneficiary bank details</p>
                          <div className='row'>
                            <div className="col-md-5 pt-1 ">
                              <div className="col-md-11 px-0">
                                <NewInput isAstrix={true} type={"text"} label={"Bank name"}
                                  name={"BenfbankName"} value={data.BenfbankName} error={errors.BenfbankName}
                                  onChange={handleChange} />
                              </div>
                            </div>
                            <div className="col-md-5 pt-1">
                              <div className="col-md-11 px-0">
                                <NewInput isAstrix={true} type={"text"} label={"Branch"}
                                  name={"BenfbranchName"} value={data.BenfbranchName} error={errors.BenfbranchName}
                                  onChange={handleChange} />
                              </div>
                            </div>
                            <div className="col-md-5 pt-1">
                              <div className="col-md-11 px-0">
                                <NewInput isAstrix={true} type={"text"} label={"Account holder name"}
                                  name={"BenfaccountHolder"} value={data.BenfaccountHolder} error={errors.BenfaccountHolder}
                                  onChange={handleChange} />
                              </div>
                            </div>
                            <div className="col-md-5 pt-1">
                              <div className="col-md-11 px-0">
                                <NewInput isAstrix={true} type={"text"} label={"Account no."}
                                  name={"BenfAccountNumber"} value={data.BenfAccountNumber} error={errors.BenfAccountNumber}
                                  onChange={handleChange} />
                              </div>
                            </div>
                            <div className="col-md-5 pt-1">
                              <div className="col-md-11 px-0">
                                <NewInput isAstrix={true} type={"text"} label={"IFSC code"}
                                  name={"BenfIFSCCode"} value={data.BenfIFSCCode} error={errors.BenfIFSCCode}
                                  onChange={handleChange} />
                              </div>
                            </div>
                            <div className="col-md-5 pt-1">
                              <div className="col-md-11 px-0">
                                {/* <NewSelect isAstrix={true} type={"text"} label={"SWIFT code"}
                                name={"BenfSwiftCode"} value={data.BenfSwiftCode} error={errors.BenfSwiftCode}
                                selectData={swiftCodedata} optionLabel={"swiftCode"} optionValue={'swiftCode'}
                                onChange={handleChange} /> */}
                                <MultipleSelect
                                  Id="SWIFT code"
                                  Label="SWIFT code"
                                  filterOption={() => true}
                                  onInputChange={(e) => {
                                    handleFilterOptions(e, "swiftCodedata")
                                  }}
                                  optiondata={swiftCodedata}
                                  onChange={(e) => handleMultiSelect(e, "BenfSwiftCode", "swiftCode")}
                                  value={data.BenfSwiftCode ? [data.BenfSwiftCode] : []}
                                  name="BenfSwiftCode"
                                  labelKey={"swiftCode"}
                                  valKey={"swiftCode"}
                                  error={errors.BenfSwiftCode}
                                />
                              </div>
                            </div>
                            <div className="col-md-5 pt-1">
                              <div className="col-md-11 px-0">
                                <NewSelect isAstrix={true} label={"Country"}
                                  selectData={countrys} name={"bankCountry"}
                                  value={data.bankCountry} optionLabel={"name"} optionValue={'sortname'}
                                  onChange={handleChange} error={errors.bankCountry} />
                              </div>
                            </div>
                            <div className="col-md-5 pt-1">
                              <div className="col-md-11 px-0">
                                <NewSelect isAstrix={true} label={"State"}
                                  selectData={states} name={"bankState"}
                                  value={data.bankState} optionLabel={"name"} optionValue={'sortname'}
                                  onChange={handleChange} error={errors.bankState} />
                              </div>
                            </div>
                            <div className="col-md-5 pt-1">
                              <div className="col-md-11 px-0">
                                <NewInput isAstrix={true} type={"text"} label={"City"}
                                  name={"BenfCity"} value={data.BenfCity} error={errors.BenfCity}
                                  onChange={handleChange} />
                              </div>
                            </div>
                            <div className="col-md-5 pt-1">
                              <div className="col-md-11 px-0">
                                <NewInput isAstrix={true} type={"text"} label={"Address"}
                                  name={"BenfAddress"} value={data.BenfAddress} error={errors.BenfAddress}
                                  onChange={handleChange} />
                              </div>
                            </div>
                            <div className="col-md-5 pt-1">
                              <div className="col-md-11 px-0">
                                <NewInput isAstrix={true} label={"Postal Code"} type="number"
                                  name={"bankPostal"} value={data.bankPostal}
                                  onChange={handleChange} error={errors.bankPostal} />
                              </div>
                            </div>
                          </div>
                        </>
                        <>
                          <p className='font-size-13 mt-4 text-decoration-underline'>Attach documents</p>
                          <div className='row'>
                            <div className="col-md-5">
                              <label className="font-size-13">Bank statement</label>
                              <div className="row form-group">
                                <div className="col-md-11">
                                  <FileInput name={"Bank_Statement"} value={data.Bank_Statement} error={errors.Bank_Statement}
                                    onChange={handleFile} isEditable={true}
                                    onUploadCancel={() => setData({ ...data, "Bank_Statement": null })} />
                                  {errors.Bank_Statement ? <div class="text-danger mt-2 font-size-12">
                                    <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                    <b>{errors.Bank_Statement}</b></div> : ''}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-5">
                              <label className="font-size-13">Blank cheque</label>
                              <div className="row form-group">
                                <div className="col-md-11">
                                  <FileInput name={"Blank_Cheque"} value={data.Blank_Cheque} error={errors.Blank_Cheque}
                                    onChange={handleFile} isEditable={true}
                                    onUploadCancel={() => setData({ ...data, "Blank_Cheque": null })} />
                                  {errors.Blank_Cheque ? <div class="text-danger mt-2 font-size-12">
                                    <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                    <b>{errors.Blank_Cheque}</b></div> : ''}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-5">
                              <label className="font-size-13">Passbook</label>
                              <div className="row form-group">
                                <div className="col-md-11">
                                  <FileInput name={"Bank_Passbook"} value={data.Bank_Passbook} error={errors.Bank_Passbook}
                                    onChange={handleFile} isEditable={true}
                                    onUploadCancel={() => setData({ ...data, "Bank_Passbook": null })} />
                                  {errors.Bank_Passbook ? <div class="text-danger mt-2 font-size-12">
                                    <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                    <b>{errors.Bank_Passbook}</b></div> : ''}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='mt-3'>
                            <img
                              onClick={() => setData({ ...data, tcAccepted: !data.tcAccepted })}
                              className='cursor mr-3' src={`assets/images/${data.tcAccepted ? 'checked-green' : 'empty-check'}.png`} />
                            <label>Select as default bank</label>
                          </div>
                        </>
                        <button onClick={() => {
                          setErrors(validateAddBank(data))
                          setSubmitted(true)
                        }} className={`my-4 new-btn w-17 py-2 px-2 text-white cursor`}>Add new bank details</button>
                      </div>}
                    {!bank && bankdetails.isVisible && <>
                      <div className="row">
                        <div className='d-flex justify-content-between'>
                          <div>
                            <img className='cursor' src={"/assets/images/ArrowBackLeft.png"} alt="" onClick={() => {
                              setbankdetails({
                                isVisible: false,
                                data: null
                              })
                            }} />
                          </div>
                          <div className='d-flex '>
                            <img src={"/assets/images/charm_download.png"} alt="" className='px-2 cursor' onClick={async () => {
                              let pdfArr = []
                              if (bankdetails.data.bankStatement && !isEmpty(bankdetails.data.bankStatement)) {
                                pdfArr.push(await getPDFFromFile(bankdetails.data.bankStatement))
                              }
                              if (bankdetails.data.blankCheque && !isEmpty(bankdetails.data.blankCheque)) {
                                pdfArr.push(await getPDFFromFile(bankdetails.data.blankCheque))
                              }
                              if (bankdetails.data.bankPassbook && !isEmpty(bankdetails.data.bankPassbook)) {
                                pdfArr.push(await getPDFFromFile(bankdetails.data.bankPassbook))
                              }
                              printDiv("bankdetails", `BankDetails-${bankdetails.data.beneficiaryAccountName}`, pdfArr)
                            }} />
                            <img src={"/assets/images/deleteIcon.png"} alt="" className='px-2 cursor' onClick={() => { removeBank(bankdetails.data.id) }} />
                          </div>
                        </div>
                        <div className='row' id='bankdetails'>
                          <div className='col'>
                            <label className='font-size-14 mt-2'><u>Beneficiary Bank Details</u></label>
                            <div className='py-2'>
                              {BenfbankDetails.map((item) => {
                                return (
                                  <div className="col-md-6">
                                    <p className="d-flex d-flex align-items-top mb-2"><span className="col-md-9 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-12 px-0 shareHolderValue" > {bankdetails.data[item.val] ? (bankdetails.data[item.val]) + (item.unit ? item.unit : '') : "NA"}</span> </p>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div className='col'>
                            <label className='font-size-14 mt-2'><u>Correspondent Bank Details</u></label>
                            <div className='py-2'>
                              {CorrbankDetails.map((item) => {
                                return (
                                  <div className="col-md-6">
                                    <p className="d-flex d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-12 px-0 shareHolderValue"> {bankdetails.data[item.val] ? (bankdetails.data[item.val]) + (item.unit ? item.unit : '') : "NA"}</span> </p>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <label className="font-size-15 font-wt-500">
                            <u>
                              Documents
                            </u>
                          </label>
                          <div className="row">
                            <div className='col-5'>
                              <label className='pl-2 font-size-14'>Bank Statement</label>
                              <FileInput name={"bankStatement"} isEditable={false} value={bankdetails.data.bankStatement} />
                            </div>
                            <div className='col-5'>
                              <label className='pl-2 font-size-14'>Blank Cheque</label>
                              <FileInput name={"blankCheque"} isEditable={false} value={bankdetails.data.blankCheque} />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-5">
                              <label className='pl-2 font-size-14'>Passbook</label>
                              <FileInput name={"bankPassbook"} isEditable={false} value={bankdetails.data.bankPassbook} />
                            </div>
                          </div>

                        </div>
                      </div>
                    </>

                    }
                  </div >
                ) : null}

                {tab === 6 && !openLink.show ? (
                  <div className='px-5 py-4 pt-5'>
                    <p className='font-size-14 font-wt-600'>Credit score</p>
                    <div className="row d-flex px-2 pt-2">
                      {ProgressBars.map((item, index) => {
                        return (
                          <div className="bg-white w-43 card-layout mr-5 mb-4 px-4 py-2">
                            <label className="font-wt-400 font-size-14" >{item.agencyName.toUpperCase().split("_").join(" ")}</label>
                            <CustomProgressBar
                              value={item["creditResp"]["rating_"].includes("A") ? 4 : item["creditResp"]["rating_"].includes("B") ? 3 :
                                item["creditResp"]["rating_"].includes("C") ? 2 :
                                  item["creditResp"]["rating_"].includes("D") ? 1 : 1}
                              textValue={item["creditResp"]["rating_"]} reverse={true}
                              min={1} max={4} />
                            <div className="row pt-2 d-flex">
                              <div className="w-50">
                                <label>{"High Risk"} <label className="font-wt-600"></label></label>
                              </div>
                              <div className="w-50 text-right">
                                <label>{"Low Risk"} <label className="font-wt-600"></label></label>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                    <p className='font-size-14 font-wt-600 mt-3'>Purchase credit report</p>
                    <div className='row pb-5 '>
                      <div className='col-md-4 mt-4'>
                        <div className='card creditcard p-3 uploadCreditReportView'>
                          <div className='d-flex flex-column justify-content-center align-items-center'>
                            <div className='uploadCreditReportIcon d-flex'
                              onClick={() => togglePopup(true)}
                            >
                              <img className='pt-1'
                                style={{
                                  "width": "2rem",
                                  "height": "2rem"
                                }} src={"assets/images/add_black_icon.png"} />
                            </div>
                            <div>
                              <label className='text-color1 font-wt-600 font-size-14 mt-3' >Upload Credit Report</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      {creditreportdata.map((key) => {
                        return (
                          <div className='col-md-4 mt-4'>
                            <div className='card creditcard p-3'>
                              <div className='d-flex align-items-center'>
                                <div className='col-6'>
                                  <img src={key.logo} alt='' height={20} />
                                  <h6>{key.companyname}</h6>
                                </div>
                                <div className='col-6'>
                                  {/* <h3 className='text-end text-color1 font-wt-600'>$ 10</h3> */}
                                </div>
                              </div>
                              {creditReportData?.[key.alt]?.["docId"] ? (
                                <div className='col-10 mb-2 mt-3 font-size-14'><p>Uploaded
                                  <img className='ml-2' style={{ width: '1.3rem', height: '1.3rem' }} src={"assets/images/green_tick.png"} /></p></div>
                              ) : (
                                <div className='col-10 mb-2 mt-3 font-size-14'><p>
                                </p></div>)}
                              {/* <div className='d-flex align-items-center'> */}
                              {creditReportData?.[key.alt]?.["docId"] ? (
                                <div className="d-flex justify-content-center">
                                  <button
                                    onClick={async () => {
                                      let docFileObj = await getDocDetails(creditReportData?.[key.alt]?.["docId"])
                                      toggleViewDoc({ show: true, doc: docFileObj })
                                    }}
                                    type="button" className={`new-btn w-80 py-2 px-2 mt-3 text-white cursor`}>
                                    View Report
                                  </button>
                                </div>
                              ) : (
                                <div className="d-flex justify-content-center">
                                  <button
                                    onClick={() => window.open(key.link)}
                                    type="button" className={`new-btn w-80 py-2 px-2 mt-3 text-white cursor`}>
                                    Get Report
                                  </button>
                                </div>)}
                            </div>
                          </div>
                        );
                      })}

                    </div>
                  </div >
                ) : null}

                {showPopup && <FinanceInvoiceModal limitinvoice={showPopup} setLimitinvoice={togglePopup} closeSuccess={() => togglePopup(false)} >
                  <div className="col-md-10 mb-2 ml-5">
                    <label className='text-center font-wt-600 text-color1 font-size-14 mb-2'>Upload Credit Report</label>
                    <div className='position-relative'>
                      <NewSelect label={"Select Report Provider Name"}
                        selectData={[{ "name": "Equifax" }, { "name": "Trans Union" }, { "name": "Experian" }, { "name": "S&P Global" }, { "name": "D&B" }]} name={"agencyName"}
                        value={data["agencyName"]} optionLabel={"name"} optionValue={'name'}
                        onChange={handleChange} error={errors.agencyName} />
                    </div>
                    <FileInput
                      onUploadCancel={() => { setData({ ...data, creditReportDoc: {} }) }} name={"creditReportDoc"} value={data["creditReportDoc"]} onChange={handleFile}
                      error={errors.creditReportDoc} isEditable={true} />
                    <div className="justify-content-center">
                      <button
                        onClick={() => {
                          let err = {}
                          if (!data.agencyName) {
                            err["agencyName"] = "Select Agency Name"
                          }
                          if (!data.creditReportDoc?.name) {
                            err["creditReportDoc"] = "Select Document to Upload"
                          }
                          if (!Object.keys(err).length) {
                            setshowLoader(true)
                            let formData = new FormData()
                            formData.append("userId", userId)
                            formData.append("agencyName", data.agencyName)
                            formData.append("file", data.creditReportDoc)
                            call('POST', 'uploadCreditReport', formData).then((res) => {
                              toastDisplay(res, "success")
                              setshowLoader(false)
                              togglePopup(false)
                              setRefresh(refresh + 1)
                              setData({ ...data, creditReportDoc: {}, agencyName: '' })
                            })
                          }
                          setErrors(err)
                        }}
                        type="button" className={`new-btn w-100 py-2 px-2 mt-3 text-white cursor`}>
                        Save
                      </button>
                    </div>
                  </div>
                </FinanceInvoiceModal>}

                {(userTypeId === 8 && tab === 8) || (userTypeId === 19 && tab === 7) ? (
                  <div className='px-5 py-4 pt-5'>

                    <div className="col-md-5 mb-4">
                      <NewInput isAstrix={true} type={"password"} label={"Enter current password"}
                        name={"currentPasswd"} value={data.currentPasswd} error=""
                        onChange={handleChange} />
                    </div>
                    <div className="col-md-5 mb-4">
                      <NewInput isAstrix={true} type={"password"} label={"Enter new password"}
                        name={"newPasswd"} value={data.newPasswd} error=""
                        onChange={handleChange} />
                    </div>
                    <div className="col-md-5 mb-4">
                      <NewInput isAstrix={true} type={"password"} label={"Confirm new password"}
                        name={"confNewPasswd"} value={data.confNewPasswd} error=""
                        onChange={handleChange} />
                    </div>
                    <div className='mt-5 mx-3 '>
                      <button type="button" className={`new-btn w-17 py-2 px-2 text-white cursor`} onClick={changePassword}>
                        Save
                      </button>
                      <br />
                      {/* <p onClick={() => setLimitinvoice(true)} className='forgetpassword cursor mt-3'>Forget Password?</p> */}
                    </div>

                  </div >
                ) : null}
                {(userTypeId === 8 && tab === 7 ?
                  <CreditLineTab userTokenDetails={userTokenDetails} toastDisplay={toastDisplay} />
                  : null
                )}

                {tab === 9 ? (
                  <div className='ulFormsContainer'>
                    <label className='font-size-14 font-wt-500'><u>About</u></label>
                    <div className='my-2'>
                      <NewTextArea label={"This organization detail will be shown to suppliers while applying for limit."}
                        name={'bio'} value={bio} onChange={(e) => { setBio(e.target.value) }} rows={10} hideInputHeader />
                    </div>
                    <label className='font-size-14 font-wt-500 mt-3'><u>Reviews & Ratings</u></label>
                    <div id="scrollContainerId" style={{ flexWrap: 'nowrap', overflowX: 'hidden' }} className='d-flex row p-2 gap-4 mb-4' >
                      {reviews.map((item, j) => {
                        return (
                          <div
                            ref={(el) => quoteCardRef.current[j] = el} className='reviewCard'>
                            <div className='row' >
                              <div className='col-3 align-items-center d-flex'>
                                <img style={{ maxWidth: '120px', maxHeight: '40px' }} src={avatarUrl(item.user_avatar)} />
                              </div>
                              <div className='col-5'>
                                <label className='font-size-12 font-wt-600 m-0 p-0' >{item.company_name}</label>
                                <label className='font-size-11 font-wt-500 m-0 p-0' >{item.industry_type}</label>
                              </div>
                              <div className='col-4' >
                                <span>
                                  <span className="font-size-12 font-wt-500 mr-1">{item.ratings.toFixed(2)}</span>
                                  <img className={`${item.ratings < 0.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                  <img className={`${item.ratings < 1.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                  <img className={`${item.ratings < 2.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                  <img className={`${item.ratings < 3.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                  <img className={`${item.ratings < 4.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                </span>
                              </div>
                            </div>
                            <div>
                              <p className='font-size-12 font-wt-600 py-3' >{item.remarks || "-"}</p>
                            </div>
                            <div className='row' >
                              <div className='col-6' >
                                <label className='font-size-12 font-wt-600' >{moment(item.createdAt).format("DD/MM/YYYY - HH:MM")}</label>
                              </div>
                              <div
                                onClick={async () => {
                                  let chatRoomIdsDecide = item.chatRoomIdsForLC || item.chatRoomIdsForInvoice
                                  let chatRoomUsersDecide = item.chatRoomUsersForLC || item.chatRoomUsersForInvoice
                                  let isInvApplication = item.invApplicationId
                                  let chatRoomIds = chatRoomIdsDecide?.split(",") || []
                                  let chatRoomUsers = chatRoomUsersDecide?.split(",") || []
                                  let openChatRoomIndx = null
                                  chatRoomUsers.forEach((u, i) => {
                                    if (u?.split("::")[1] / 1 == userId / 1) {
                                      openChatRoomIndx = i
                                    }
                                  })
                                  let reqObj = {
                                    userTypeId,
                                    senderId: userId,
                                    sellerId: item.createdBy,
                                    receiverId: userId,
                                    textMessage: 'Hii',
                                    chat_room_name: "CHAT" + new Date().getTime(),
                                    includeAdmins: true,
                                    [isInvApplication ? "invApplicationId" : "lcApplicationId"]: isInvApplication ? item.invApplicationId : item.lcApplicationId,
                                    receiverParties: userId,
                                    dontSendInitialMsg: true
                                  }
                                  setshowLoader(true)
                                  let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
                                  setshowLoader(false)
                                  getChatDetails({
                                    chat_room_id: apiResp.id,
                                    loggedInUser: userId
                                  })
                                  setSelectedChat({
                                    chatRoomId: apiResp.id,
                                    receiverName: `${chatRoomIds[openChatRoomIndx] || apiResp.id}`,
                                    invApplicationId: item.limit_id,
                                    parties: chatRoomUsers[openChatRoomIndx] || apiResp.parties,
                                    userId: userId,
                                    isChatOpen: true,
                                    receiverId: userId,
                                    sellerId: item.created_by
                                  })
                                }}
                                className='col-6 d-flex justify-content-end pt-2 cursor' >
                                <img style={{ width: '15px', height: '15px' }} className="mr-2 cursor" src={"assets/images/supplier-images/chat.png"} alt="" />
                                <label className='font-size-12 font-wt-600 cursor' >{"Chat with supplier"}</label>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                    {reviews?.length > 2 ? (
                      <div className="col-12 mx-auto text-end mb-4">
                        <img onClick={() => {
                          scrollLeft()
                        }} style={{ width: '2.5rem', height: '2.5rem' }}
                          className={` mx-3 cursor fs-5 cursor`} src={"/assets/images/left_side_circle_arrow.svg"} alt="" />
                        <img onClick={() =>
                          scrollRight()
                        } style={{ width: '2.5rem', height: '2.5rem' }}
                          className={`cursor fs-5`} src={"/assets/images/right_side_circle_arrow.svg"} alt="arrow" />
                      </div>
                    ) : null}
                  </div >
                ) : null}


                {/* {tab != 7 ? (
                  <div className="row pb-5 px-5">
                    <button type="button"
                      onClick={() => tab === 7 ? closeFn() : handleValidation(undefined)}
                      className={`new-btn w-20 py-2 px-2 text-white cursor`}>
                      {tab === 7 ? "Submit" : "Continue"}
                    </button>
                  </div>
                ) : null} */}

              </div>
            </div>

          </main>
        </div>
      </div >
      {
        limitinvoice && <FinanceInvoiceModal limitinvoice={limitinvoice} setLimitinvoice={setLimitinvoice} closeSuccess={closeSuccess} >
          <div className="col-md-9 mx-auto">
            <NewInput isAstrix={false} type={"text"} label={"Enter registered Mail id / Mobile No."}
              name={"lcIssuingBankSwiftCode"} value="" error=""
              onChange={handleChange} />
          </div>
          <button type="button" className={`new-btn w-25 py-2 px-2 mt-3 text-white cursor`}>
            Continue
          </button>
        </FinanceInvoiceModal>
      }
      {
        experience && <FinanceInvoiceModal limitinvoice={experience} setLimitinvoice={setExperience} closeSuccess={closeExpFn}>
          <img src={"/assets/images/reset-password.png"} alt="" className="mb-3" />
          <p className='font-size-14 mx-5'>A reset password mail has been sent to you on your registered mail id.</p>
          <p className='font-size-14 mx-5'> Please check your mail & rest your password.</p>
        </FinanceInvoiceModal >
      }
    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(ViewProfile)