// const env = "local"
const env = "dev"
// const env = "prod"

// function getTradeFinBackendUrl() {
//   if (env === "local") {
//     return "http://localhost:3004"
//   }
//   if (env === "dev") {
//     return "https://webfindev-backend.sofodev.co"
//   }
//   if (env === "prod") {
//     return "https://backend.traderebootfin.com"
//   }
// }

function getMainUrl() {
  if (env === "local") {
    return "http://localhost:3000"
  }
  if (env === "dev") {
    return "https://dev.tradereboot.com"
  }
  if (env === "prod") {
    return "https://tradereboot.com"
  }
}

function getPlatFormUrl() {
  if (env === "local") {
    return "http://localhost:3000"
  }
  if (env === "dev") {
    return "https://dev-alpha.app.tradereboot.com"
  }
  if (env === "prod") {
    return "https://app.tradereboot.com"
  }
}

function getPlatFormBackendUserServiceUrl() {
  if (env === "local") {
    return "http://localhost:3211"
  }
  if (env === "dev") {
    return "https://devsaasserver.tradereboot.com/user-service"
  }
  if (env === "prod") {
    return ""
  }
}

function getPlatFormBackendContractServiceUrl() {
  if (env === "local") {
    return "http://localhost:3213"
  }
  if (env === "dev") {
    return "https://devsaasserver.tradereboot.com/contract-service"
  }
  if (env === "prod") {
    return ""
  }
}

function getPlatFormBackendShipmentServiceUrl() {
  if (env === "local") {
    return "http://localhost:3214"
  }
  if (env === "dev") {
    return "https://devsaasserver.tradereboot.com/shipment-service"
  }
  if (env === "prod") {
    return ""
  }
}

function getWebsiteBackendURL() {
  if (env === "local") {
    return "http://localhost:3211"
  }
  if (env === "dev") {
    return "https://backendwebdev.sofodev.co"
  }
  if (env === "prod") {
    return "https://backend.tradereboot.com"
  }
}

function getAIServerBackendURL() {
  if (env === "local") {
    return "https://serverai.tradereboot.com"
  }
  if (env === "dev") {
    return "https://serverai.tradereboot.com"
  }
  if (env === "prod") {
    return "https://serverai.tradereboot.com"
  }
}

export const platformURL = getPlatFormUrl()
export const websiteBackendURL = getWebsiteBackendURL()
export const mainUrl = getMainUrl()
// export const tradeFinBackendUrl = getTradeFinBackendUrl()
export const googleApiKey = ""
export const platformBackendUrl = ""
export const platformBackendUserServiceUrl = getPlatFormBackendUserServiceUrl()
export const platformBackendContractServiceUrl = getPlatFormBackendContractServiceUrl()
export const platformBackendShipmentServiceUrl = getPlatFormBackendShipmentServiceUrl()
export const aiServerBackendUrl = getAIServerBackendURL()
// export const eximBankUserId = env === "prod" ? 134 : 134
export const enableStennReqDocFlow = true
// export const ZohoWidgetCode = '618527750d0cbef38d0b24b740f6e66308a7b88f4f4fb254725da05f168cf3e842033983a7442ec4217aff75a839e80d'
export const ZohoWidgetCode = '672d298b4eb1d850216ff64add560b7ed4b3960b762fb77a528d7e4c6a2ac8ac4d8394205193ea5f9e34ff2d7666ec6b'
export const environment = env