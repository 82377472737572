import axios from "axios";
import call from "../service";

export function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export async function displayRazorpay(reqamount, requserId, toastDisplay, successCallback, extradata, onPaymentFailure, amountCurrency) {
  const res = await loadScript(
    "https://checkout.razorpay.com/v1/checkout.js"
  );

  if (!res) {
    console.log('Failed To load the script')
    alert("Razorpay SDK failed to load. Are you online?");
    return;
  }
  try {
    // creating a new order
    const result = await call('POST', 'createOrder', { amount: reqamount, userId: requserId, amountCurrency: amountCurrency === "plan_amount_usd" ? 'USD' : 'INR' });
    console.log('result', result)

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }

    // Getting the order details back
    const { amount, id: order_id, currency } = result;
    console.log(amount, order_id, currency)
    const options = {
      key: "rzp_test_CmNCenloVZ2Ddf", // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: "Trade Reboot Fin",
      description: "Coin Addition",
      //image: { logo },
      order_id: order_id,
      handler: async function (response) {
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };
        console.log('UPI Success', response)
        if (successCallback) {
          successCallback(data, extradata)
        } else {
          toastDisplay('No action provided', 'error')
        }

      },
      // prefill: {
      //   name: "Trade Reboot",
      //   email: "info@tradereboot.com",
      //   contact: "9209544753",
      // },
      // notes: {
      //   address: "410, Atlanta Estate, Western Express Hwy, opp. Westin Hotel, ITT Bhatti, Hanuman Tekdi, Goregaon, Mumbai, Maharashtra 400063",
      // },
      theme: {
        color: "#1B94B7",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    paymentObject.on('payment.failed', function (error) {
      console.log('Payment failed', error.error)
      if (onPaymentFailure) {
        onPaymentFailure(error.error.metadata, extradata, () => paymentObject.close())
      }
    })

  } catch (e) {
    console.log('Error in payment gateway', e)
  }
}