import React, { PureComponent } from 'react'
import { ResponsiveContainer, XAxis, YAxis, Tooltip, Bar, BarChart, Legend } from 'recharts'

class CustomBarChart extends PureComponent {
  render() {
    console.log('render', this.props)
    return (
      <ResponsiveContainer width={"100%"} height={300}>
        <BarChart
          width={500}
          height={300}
          data={this.props.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}

        >
          <XAxis dataKey={this.props.xDataKey} tickFormatter={this.props.XFormatter} />
          <YAxis tickFormatter={this.props.YFormatter} />
          <Tooltip formatter={this.props.formatterFunction} />
          {(this.props.data?.length && this.props.isLegend) &&
            <Legend iconType={this.props.legendShape || 'circle'} formatter={(value) => this.props.legendsMapper[value]} onClick={this.props.onLegendClick} />
          }
          {this.props.bardataConfig && this.props.bardataConfig.map((item, index) => {
            return <Bar dataKey={item.dataKey} fill={item.fill} stackId={this.props.isStackedChart ? "A" : index} barSize={this.props.barSize ? this.props.barSize : null} radius={this.props.isStackedChart ? (index === this.props.bardataConfig?.length - 1) ? [4, 4, 0, 0] : [0, 0, 0, 0] : [4, 4, 0, 0]} />
          })}
        </BarChart>
      </ResponsiveContainer>
    )
  }
}

export default CustomBarChart