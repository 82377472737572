import axios from 'axios';
import { getTokenOnly, removeCookieandAvatar } from './utils/cookieHelper';
import { platformBackendContractServiceUrl, platformBackendShipmentServiceUrl, platformBackendUserServiceUrl } from './urlConstants';
let token = getTokenOnly()

if (token) {
  // axios.defaults.headers.common = { 'Authorization': `Bearer ${JSON.parse(localStorage.getItem('fob_token')).token}` }
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
}
/**
 * Method for calling APIs.
 * @param {String} method : Method using which the api is called(GET, POST, etc.)
 * @param {String} url : URL of the API
 * @param {Object} obj : Object/data to be sent as input.
 * @returns {Promise} Promise
 */

function getBaseUrlByService(service) {
  if (service === "user") {
    return platformBackendUserServiceUrl + "/"
  }
  else if (service === "shipment") {
    return platformBackendShipmentServiceUrl + "/"
  }
  else {
    return platformBackendContractServiceUrl + "/"
  }
}

const call = (method, url, obj = {}, service) => {
  return new Promise((resolve, reject) => {
    //let token;
    let args = {
      method: method,
      url: getBaseUrlByService(service) + url,
      data: obj
    }
    // console.log("argssssssssssssssss", args);
    try {
      axios(args).then(response => {
        // console.log("response console in called api:", response)
        if (response.data.success)
          resolve(response.data.message ? response.data.message : response.data.data ? response.data.data : response.data.disc);
        else
          response.data.message ? reject(response.data.message) : response.data.error ? reject(response.data.error) : reject(response.data.disc)
      }).catch(e => {
        // console.log("Error in called api 1:==>", url, e)
        if (e.response && e.response.status === 401) {
          removeCookieandAvatar()
          localStorage.clear()
          window.location = '/login'
        } else {
          // console.log("Error in called api 2:==>", url, e)
          reject(e);
        }
        reject(e);
      });

    } catch (error) {
      // console.log('Error in called api 3:==>', url, error);
      reject(error)
    }


  })
}


export default call;
