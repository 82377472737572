import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from 'react-dropzone'
import { Line, Circle } from 'rc-progress';
import DocViewer from "react-doc-viewer";

export function downloadTheFile(result) {
  if (result.filebase64.includes("base64,")) {
    result["filebase64"] = result.filebase64.split("base64,")[1]
  }
  if (result.name.split(".").pop() === "png" || result.name.split(".").pop() === "PNG") {
    console.log(result.name);
    let link = document.createElement("a");
    console.log(link);
    link.download = result.name.split(".")[0] + ".png";
    link.href = 'data:application/png;base64,' + encodeURI(result.filebase64);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else if (result.name.split(".").pop() === "jpg" || result.name.split(".").pop() === "JPG") {
    let link = document.createElement("a");
    link.download = result.name.split(".")[0] + ".jpeg";
    link.href = 'data:application/jpeg;base64,' + encodeURI(result.filebase64);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else if (result.name.split(".").pop() === "pdf" || result.name.split(".").pop() === "PDF") {
    let link = document.createElement("a");
    link.download = result.name.split(".")[0] + ".PDF";
    link.href = 'data:application/pdf;base64,' + encodeURI(result.filebase64);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}


export const DocumentDownloader = ({ hideVault, name, value, onChange, error, onUploadCancel, onView, isEditable, extra_class, show,
  dismiss }) => {
  console.log("selectedfile", value, name);

  useEffect(() => {
    if (show) {
      downloadTheFile(value)
      dismiss()
    }
  }, [show])

  return <></>
}