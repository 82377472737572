import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import call from '../../service';
import { FileInput } from '../../utils/FileInput';
import avatarUrl from '../../utils/makeAvatarUrl';
import MultipleSelect from '../../utils/MultipleSelect';
import { astrix, ClearCache, convertImageToPdf, dataURItoBlob, GetCache, getDocDetails, most_used_currencies, SetCache, toolTip } from '../../utils/myFunctions';
import { InputWithSelect, NewInput, NewSelect } from '../../utils/newInput';
import { NewTable } from '../../utils/newTable';
import { StarRating } from '../../utils/starRating';
import toastDisplay from '../../utils/toastNotification';
import Header from '../partial/header';
import HeaderV2 from '../partial/headerV2';
import Sidebar, { traderTypeIds } from '../partial/sidebar';
import SideBarV2 from '../partial/sideBarV2';
import { PopupMessage } from '../popupMessage';
import config from '../../config.json';
import moment from 'moment';
import TutorialPopup, { TutorialVideoPopup } from '../tutorialPopup';
import ChatBoxPopUp2 from '../chatRoom/components/ChatBoxPopUp2';
import Filter from '../InvoiceDiscounting/components/Filter';
import NewTablev2 from '../../utils/newTablev2';
import Pagination from '../InvoiceDiscounting/contract/components/pagination';
import { bgQuotesColumn, notificationListColumn, shipmentBookingQuotesColumn, wcQuotesColumn } from '../Dashboard/TableJson';
import { Action } from '../myCounterPartComp/action';

const finHighlights = [
  { name: 'Selected', color: '#5CB8D3' },
  { name: 'Quote received', color: '#2ECC71' },
  { name: 'Rejected', color: '#FE4141' }
]

const NotificationList = ({ userTokenDetails, navToggleState }) => {

  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [tableData, setTableData] = useState([])

  const [showAddBuyerForm, toggleAddBuyerForm] = useState(false)
  const [tab, setTab] = useState(0)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)

  const [dunsData, setDunsData] = useState([])
  const [hsnCodes, setHsnCodes] = useState([])
  const [countrys, setCountrys] = useState([])
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [financierData, setFinancierData] = useState([]);
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10, search: serarchParam ? serarchParam : '' })
  const [lcTutorial, toggleLCTutorial] = useState(localStorage.getItem("lcTutorialStep") == 1)
  const [tutorialStage, setTutorialStage] = useState(0)
  const [showTutorialVideo, toggleTutorialVideo] = useState({ show: false, link: null })
  const [filterData, setFilterData] = useState({})

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [action, setAction] = useState({ show: false, index: null })

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;


  useEffect(() => {
    setshowLoader(true)
    let objectAPI = { userTypeId, "userID": userId, "email": userEmail, "sidebar": false, "filter": false, currentPage: page, ...filter }

    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push((element.accordianId === "status" || element.accordianId === "financiersFilter" || element.accordianId === "timeLeft") ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
        else if (element.type === "minMaxDate") {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }
    call('POST', 'getNotifications', objectAPI).then((result) => {
      console.log('running getShipmentBookingQuoteList api-->', result);
      setDbData(result.data);
      setCount(result.count);
      setshowLoader(false)
    }).catch((e) => {
      // console.log('error in getShipmentBookingQuoteList', e);
      setshowLoader(false)
    });
  }, [refresh, page, filterData])

  // useEffect(() => {
  //   call('POST', 'getWCQuoteListFilter', { userId }).then(res => {
  //     console.log("getWCQuoteListFilterthen", res);
  //     setFilterData(res)
  //   }).catch(err => { })
  // }, [])

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('shipmentBookingId', selectedChat.shipmentBookingId)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))
    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {selectedChat.isChatOpen &&
        <div className="chatboxDivFixed">
          <ChatBoxPopUp2
            chatList={chatList}
            user_avatar={selectedChat.logo}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            parties={selectedChat.parties}
            userTokenDetails={userTokenDetails}
            onChatSend={(file, parties) => sendChatMsg(file, parties)}
            message={message}
            setTextMsg={setTextMsg}
            reloadChatList={() => getChatDetails({
              chat_room_id: selectedChat.chatRoomId,
              loggedInUser: userId
            })}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false,
                logo: ''
              })
              setRefresh(refresh + 1)
            }}
          />
        </div>
      }
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="SettingsNotificationList" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Settings > Notifications"}
              userTokenDetails={userTokenDetails} />
            <div className='mt-4' style={{}} >
              <div className='filter-div position-relative'>
                <Filter
                  filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                  showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} />
              </div>


              <div>
                <NewTablev2 columns={notificationListColumn} >
                  {dbData.map((item, j) => {
                    return (
                      <tr>
                        <td><label className='font-size-13 font-wt-500 text-break' >{moment(item.updatedAt).format("DD/MM/YYYY hh:mm a")}</label></td>
                        <td><label className='font-size-13 font-wt-500 text-break' >{item.contract_no || "NA"}</label></td>
                        {item.shipmentApplicationDetails ?
                          <td><label className='font-size-13 font-wt-500 text-break' >{`${item.shipmentApplicationDetails?.commodityPriceUnit ? `${item.shipmentApplicationDetails?.commodityPriceUnit} ${item.shipmentApplicationDetails.commodityPrice}` : "NA"}`}</label></td> :
                          <td><label className='font-size-13 font-wt-500 text-break' >{`${item.currency ? `${item.currency?.split(":")[2]} ${item.price}` : "NA"}`}</label></td>}
                        <td><label className='font-size-13 font-wt-500 text-break' >{item.contract_no?.includes('CT-') ? "Commodity Contract" :
                          item.contract_no?.includes("SB") ? "Shipment Contract" : 'NA'}</label></td>
                        <td><label className='font-size-13 font-wt-500 text-break' >{`${item.message || "NA"}`}</label></td>
                        <td>
                          <div className='' >
                            <a className='mt-2'>
                              <button type="button"
                                onClick={() => {
                                  if (item.contract_no?.includes('CT-')) {
                                    window.location = `/workOrderContracts?search=${item.contract_no}`;
                                  }
                                  else if (item.contract_no?.includes('SB')) {
                                    if (userTypeId / 1 == 5) {
                                      window.location = `/ShipmentBookingFinancierQuotes?search=${item.contract_no}`;
                                    }
                                    else if (userTypeId / 1 == 3 || userTypeId / 1 == 4 || userTypeId / 1 == 14) {
                                      if (item.message.includes("BL")) {
                                        window.location = `/ShipmentBookingContract?search=${item.contract_no}`;
                                      }
                                      else {
                                        window.location = `/ShipmentBookingQuotes?search=${item.contract_no}`;
                                      }
                                    }
                                  }
                                }}
                                className={` border-0 mb-2 text-white enableQuotebtn `}>
                                {"View Details"}
                              </button>
                              {/* {item.applicationStatus / 1 == 2 ?
                                <p className="font-size-12 text-color-value ml-3">
                                  <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-600 text-danger">Application rejected by Shipper</span>
                                </p>
                                : !item.blDetails ?
                                  <p className="font-size-12 text-color-value ml-3">
                                    <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-600">Waiting for BL to be uploaded by Shipper</span>
                                  </p>
                                  : item.blDetails ?
                                    <p className="font-size-12 text-color-value ml-3">
                                      <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-600 ">BL is issued by Shipper</span>
                                    </p>
                                    : null} */}
                            </a>
                          </div >
                        </td>
                      </tr>
                    )
                  })}

                </NewTablev2>
              </div>
              <Pagination perPage={filter.resultPerPage || 10} page={page} totalCount={count} onPageChange={(p) => setPage(p)} />

            </div>
          </main>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(NotificationList)
