import React, { useEffect, useState } from 'react';
import call from '../../service';
import { connect } from 'react-redux';
import { setshowModalAddComm } from '../../store/actions/action';
import toastDisplay from '../../utils/toastNotification';
import validate from '../../utils/validation_files/adminAddCommodityValidationRules'
import readXlsxFile from 'read-excel-file';
// import * as CSV from 'csv-string';

const UserCommodityAddModal = ({ userTokenDetails, showModalAddComm, setshowModalAddComm, dispatch }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [dbData, setdbData] = useState([]);
  const [data, setdata] = useState({});
  const [specdata, setspecdata] = useState({});
  const [newSpec, setnewSpec] = useState({ newSpecificationName: "", newSpecificationValue: "", newSpecificationrejectionValue: '' });
  const [commimage, setcommimage] = useState('');
  const [newcategoryName, setnewcategoryName] = useState('');
  const [refresh, setrefresh] = useState(0);
  const [showLoader, setshowLoader] = useState(false);
  const [error, setError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [specOrder, setspecOrder] = useState([])
  const [commodityTypes, setcommodityTypes] = useState([]);
  const [accord, setAccord] = useState({
    1: true,
    2: true
  });
  const [fileName, setfileName] = useState('')


  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null

  const astrix = <span className="required-field text-danger">*</span>
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {
    //------------------------------------------------------------------
    //------------------------------------------------------------------

    //------------------------------------------------------------------
    //API Calls
    setshowLoader(true)
    call('GET', 'getcommoditycategory').then((result) => {
      //console.log("API result getcommoditycategory in comm modal:-->", result);
      setdbData(result);
      setshowLoader(false)
    }).catch((e) => {
      console.log("Error while querying getcommoditycategory:", e);
      setshowLoader(false)
    })
    //------------------------------------------------------------------
  }, [refresh]);

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      handleSubmit()
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [error]);
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Handlers
  const handleChange = (event) => {
    event.persist();
    setdata(data => ({ ...data, [event.target.name]: event.target.value }));
    setError({ ...error, [event.target.name]: "" });
  }

  // const handleChangespec = (event) => {
  //   event.persist();
  //   setspecdata(specdata => ({ ...specdata, [event.target.name]: event.target.value }));
  // }

  const handleFile = (event) => {
    event.persist();

    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onloadend = () => {
      setcommimage(reader.result);
    };

    setdata(data => ({ ...data, [event.target.name]: event.target.files[0] }));
    setError({ ...error, [event.target.name]: "" });
  }

  let selectCsvFile = (event) => {
    event.persist();
    setError({ ...error, "bulk_upld_error": "" });
    setcommodityTypes([])
    if (!event.target.files[0]['name'].includes("csv")) {
      setError({ ...error, "bulk_upld_error": "Please select csv file" });
      return
    }
    // console.log("event =>", event);
    const reader = new FileReader();
    reader.onload = function (e) {
      // let rows = CSV.parse(e.target.result)
      let rows = []
      if (rows && rows.length > 1) {
        rows.shift()
        setcommodityTypes(rows)
      }
      else {
        setError({ ...error, "bulk_upld_error": "Selected file is empty" });
      }
      console.log("processed csv", rows);
    }
    reader.readAsText(event.target.files[0]);
    setfileName(event.target.files[0]['name'])
    // readXlsxFile(event.target.files[0]).then((rows) => {
    //   console.log("csv data==>", rows);
    //   if (rows && rows.length > 1) {
    //     rows.shift()
    //     setcommodityTypes(rows)
    //   }
    //   else {
    //     setError({ ...error, "bulk_upld_error": "Selected file is empty" });
    //   }
    // })
  }

  let saveCommodities = async () => {
    setshowLoader(true)
    for (let index = 0; index < commodityTypes.length; index++) {
      let row = commodityTypes[index]
      // Check if commodity categroy exist
      if (row[0]) {
        let formData = new FormData();
        formData.append('categoryName', row[0])
        let result = await call('POST', 'addnewcategory', formData)
        if (result && result.message && result.message.id) {
          // Check if commodity name exist
          if (row[1]) {
            formData.append('owner_id', userId)
            formData.append('commdCategory', result.message.id);
            formData.append('commodityPrettyName', row[1]);
            formData.append('commodityName', row[1].toLowerCase().replace(/\s/g, ''));
            if (row[2] && row[3]) {
              let specNames = row[2].split(",")
              let specValues = row[3].split(",")
              let specJson = {}
              for (let i = 0; i < specNames.length; i++) {
                specJson[specNames[i].replace(/\s/g, "").toLowerCase()] = {
                  "name": specNames[i],
                  "value": specValues[i],
                  "rejectionValue": "",
                  "rejectionSymbol": "!"
                }
              }
              formData.append('spec', JSON.stringify(specJson))
              formData.append('specOrder', Object.keys(specJson).join(":"))
            }
            else {
              formData.append('spec', JSON.stringify({}))
              formData.append('specOrder', "")
            }
            await call('POST', 'savecommoditymaster', formData)
          }
        }
      }
    }
    setshowLoader(false)
    toastDisplay("Commodity added successfully", "success")
    setshowModalAddComm(false, { refresh: true })
  }

  // console.log("data in admin comm form->", data)
  // console.log("specdata in admin comm form->", specdata)
  console.log("specOrder in admin comm form->", specOrder)

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Functions to add dynamic specdata elements
  function addElement() {
    let newSpecObj = { "name": newSpec.newSpecificationName, "value": newSpec.newSpecificationValue, "rejectionValue": newSpec.newSpecificationrejectionValue, "rejectionSymbol": newSpec.newSpecificationrejectionSymbol }
    setspecdata({ ...specdata, [newSpec.newSpecificationName.toLowerCase().replace(/\s/g, '')]: newSpecObj });
    setnewSpec(newSpec => ({
      ...newSpec,
      newSpecificationName: "",
      newSpecificationValue: "",
      newSpecificationrejectionSymbol: '',
      newSpecificationrejectionValue: ''
    }));

    let tempOrderArray = [...specOrder]
    tempOrderArray.push(newSpec.newSpecificationName.toLowerCase().replace(/\s/g, ''))
    setspecOrder(tempOrderArray)

  }

  function removeElement(key) {
    let specdataElement = { ...specdata }
    delete specdataElement[key]
    setspecdata(specdataElement)

    let tempOrderArray = [...specOrder]
    const index = tempOrderArray.indexOf(key);
    if (index > -1) {
      tempOrderArray.splice(index, 1);
    }
    setspecOrder(tempOrderArray)

  }


  function moreSpecElement() {
    let keyArray = specOrder.length ? specOrder : Object.keys(specdata).length ? Object.keys(specdata) : []
    return (<>
      {
        keyArray.map((key) => {
          return (
            <div className="col-md-12">
              <div className="row form-group">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="">{specdata[key].name ? specdata[key].name + (" - (Typical Value)") : '--'}</label>
                        <input type="text" className={"form-control "} name={key ? key + ',value' : ''} value={specdata[key].value} placeholder="" disabled />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="">Rejection At</label>
                        <select className={"form-control "} name={key ? key + ',rejectionSymbol' : ''} value={specdata[key].rejectionSymbol} disabled >
                          <option value="" selected>-select-</option>
                          <option value="!">N/A</option>
                          <option value="<">Below</option>
                          <option value=">">Above</option>
                          <option value="-">Range</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="">Rejection Value</label>
                        <input type="text" className={"form-control "} name={key ? key + ',rejectionValue' : ''} value={specdata[key].rejectionValue} placeholder="" disabled />
                      </div>
                    </div>
                    <div className="col-md-1">
                      <button type="button" className="delete-icon spec-delete" onClick={() => removeElement(key)} ><i className="fa fa-trash"></i></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })
      }
    </>)
  }

  const handlenewSpec = (event) => {
    event.persist();
    setnewSpec(newSpec => ({ ...newSpec, [event.target.name]: event.target.value }));
  };
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Submit handler
  function handleSubmit() {
    setshowLoader(true)

    let formData = new FormData();
    formData.append('owner_id', userId)
    formData.append('commdCategory', data.commdCategory);
    formData.append('commodityPrettyName', data.commName);
    formData.append('commodityName', data.commName.toLowerCase().replace(/\s/g, ''));
    formData.append('commodityImage', data.commodityImage);
    formData.append('spec', JSON.stringify(specdata))
    formData.append('specOrder', specOrder.join(":"))


    call('POST', 'savecommoditymaster', formData).then((result) => {
      if (result) {
        setshowLoader(false)
        setshowModalAddComm(false, { refresh: true })
        toastDisplay("New Commodity added", "success");
        console.log("result:", result)
      }
    }).catch(err => {
      setshowLoader(false)
      console.log("Error:", err)
      toastDisplay(err.message, "error");
      // setRedirectTo(2);
    })

  }

  const prehandleSubmit = (event) => {
    if (event) event.preventDefault();
    setError(validate(data, specdata));
    setIsSubmitting(true);
  };


  function submitnewcategory() {

    if (newcategoryName) {
      let formData = new FormData();
      formData.append('categoryName', newcategoryName)

      call('POST', 'addnewcategory', formData).then((result) => {
        if (result) {
          toastDisplay("ADD Success", "success");
          // console.log("result:", result)
          setrefresh(refresh + 1)
        }
      }).catch(err => {
        console.log("Error:", err)
        toastDisplay(err.message, "error");
        // setRedirectTo(2);
      })
    } else {
      setError({ ...error, "newcategoryName": "Please Provide a Category Name" });
    }

  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      {showModalAddComm.modal && <div className={(showModalAddComm.modal ? " show" : "")} id="ADDCommodity"
      >
        <div className="modal-dialog modal-xl border-inner mw-100" id="parent_class"
        >
          {/* <div className="modal-content h-100"> */}
          <div style={{ "pointer-events": "auto" }}>
            {/* <div className="modal-header primary mb-4">
              <h4 className="modal-title text-white">ADD Commodity </h4>
              <button type="button" className="close" onClick={() => { setshowModalAddComm(false, {}); }}>×</button>
            </div> */}
            {/* <div className="modal-body calc-inner-modal h-100"> */}

            <div className={"accordionItem mt-3" + (accord['1'] ? " open" : " closed")}>
              <h2 className="accordionItemHeading" onClick={() => setAccord({ ...accord, '1': !accord['1'] })}>Add Commodity</h2>
              <div className="accordionItemContent">
                {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
                <div className="row">
                  <div className="col-md-6">
                    <div className="row form-group">
                      <label className="col-md-12">Commodity Type/Category {astrix} </label>
                      <div className="col-md-12">
                        <select className={" form-control" + (!error.commdCategory ? '' : ' border-danger')} name="commdCategory" value={data.commdCategory} onChange={handleChange}>
                          <option value="" selected>--Select Category--</option>
                          <option value="NA">Not Available</option>
                          {
                            dbData.length ? dbData.map((item, index) => {
                              return (<option value={item.id}>{item.category}</option>)
                            }) : ''
                          }
                        </select>
                        {error.commdCategory ? <div class="text-danger error-contract">{error.commdCategory}</div> : ''}
                      </div>
                    </div>
                  </div>
                  {(data.commdCategory === "NA") ? <div className="col-md-6">
                    <label className="col-md-12">Add New Category {astrix} </label>
                    <div className="row form-group">
                      <div className="col-md-8">
                        <div className="col-md-12">
                          <input type="text" name="newcategoryName" value={newcategoryName} placeholder="Commodity Category" className={" form-control" + (!error.newcategoryName ? '' : ' border-danger')} onChange={(event) => {
                            setnewcategoryName(event.target.value);
                            setError({ ...error, [event.target.name]: "" });
                          }} />
                          {error.newcategoryName ? <div class="text-danger error-contract">{error.newcategoryName}</div> : ''}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <button className="btn btn-primary btn-sm" onClick={submitnewcategory}>Add new</button>
                      </div>
                    </div>
                  </div> : ''}
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-6">
                    <div className="row form-group">
                      <label className="col-md-12">Commodity name {astrix} </label>
                      <div className="col-md-12">
                        <input type="text" name="commName" value={data.commName} placeholder="Commodity name" className={" form-control" + (!error.commName ? '' : ' border-danger')} onChange={handleChange} />
                        {error.commName ? <div class="text-danger error-contract">{error.commName}</div> : ''}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row form-group">
                      <div className="col-md-6">
                        <label className="col-md-12">Commodity Image {astrix} </label>
                        <div className="col-md-12">
                          <div className="file-browse">
                            <button className="btn btn-primary btn-sm">Upload Image</button>
                            <input type="file" accept=".png,.jpg,.jpeg" name="commodityImage" onChange={handleFile} />
                            {error.commodityImage ? <div class="text-danger error-contract">{error.commodityImage}</div> : ''}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <img width="92" src={commimage ? commimage : "assets/images/sidebar/commodity.png"} className="img-responsive" alt="Commodity" />
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <h3 className="mt-4 mb-4">Specification {astrix} </h3>
                {error.specdata ? <div class="text-danger error-contract">{error.specdata}</div> : ''}
                <div className="row">

                  <>{moreSpecElement()}</>

                  <div className="col-md-12">
                    <h3 className="">ADD New Specification</h3>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="">Specification Name<span className="required-field text-danger">*</span></label>
                          <input type="text" value={newSpec.newSpecificationName} name="newSpecificationName" placeholder="Enter New Spec Name" className="form-control" onChange={handlenewSpec} />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label className="">Typical Value<span className="required-field text-danger">*</span></label>
                          <input type="text" value={newSpec.newSpecificationValue} name="newSpecificationValue" placeholder="Value" className="form-control" onChange={handlenewSpec} />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label className="">Rej. At</label>
                          <select className="form-control" name="newSpecificationrejectionSymbol" value={newSpec.newSpecificationrejectionSymbol} onChange={handlenewSpec} >
                            <option value="" selected>-select-</option>
                            <option value="!">N/A</option>
                            <option value="<">Below</option>
                            <option value=">">Above</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="">Rejection Value</label>
                          <input type="text" value={newSpec.newSpecificationrejectionValue} name="newSpecificationrejectionValue" placeholder="Rejection Value" className="form-control" onChange={handlenewSpec} />
                        </div>
                      </div>
                      <div className="col-md-2">
                        {(newSpec.newSpecificationName !== "" && newSpec.newSpecificationValue !== "") && <button className="btn-primary btn btn-sm spec-delete" onClick={() => addElement()} >Add +</button>}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <h3 className="mt-4 text-center">OR</h3> */}
                <div className="modal-footer primary">
                  {/* <div> */}
                  <button type="button" className="btn btn-danger btn-sm" onClick={() => { setshowModalAddComm(false, {}); }}>Cancel</button>
                  <button type="button" className="btn btn-info btn-sm" onClick={() => prehandleSubmit()}>ADD</button>
                </div>
              </div>
            </div>
            <div className={"accordionItem mt-3" + (accord['2'] ? " open" : " closed")}>
              <h2 className="accordionItemHeading" onClick={() => setAccord({ ...accord, '2': !accord['2'] })}>Bulk Upload Commodity</h2>
              <div className="accordionItemContent">
                {/* <h3 className="mt-3 mb-4 text-center">Bulk Upload Commodity</h3> */}
                <div className='row'>
                  <div className='col pl-4 align-self-center'>
                    <h6 className=''>
                      <a className="text-dark cursor-pointer" href="./assets/docs/CommodityList.csv"
                        target="_blank" download>
                        <img className='mr-2' src="./assets/images/logos/csv.jpg" alt="" width="45" height="45"></img>
                        {"Download Template"}</a>
                    </h6>
                  </div>
                  {/* <div className='col pl-2 pt-2'>
                    <input className='' type="file" accept=".csv" onChange={selectCsvFile} />
                    {commodityTypes.length ?
                      <label class="text-success error-contract">{commodityTypes.length + " Entries Found"}</label> : null}
                    <label class="text-danger error-contract">{error.bulk_upld_error}</label>
                  </div> */}
                  <div className='col pl-2 pt-2 d-flex'>
                    <div
                    // className="row"
                    >
                      <label className="">Select CSV file for bulk upload {astrix}</label>
                      <div className="file-browse">
                        <button className="btn btn-primary btn-sm">Upload  File</button>
                        <input type="file" accept=".csv" name="commodityImage" onChange={selectCsvFile} />
                      </div>
                      {(commodityTypes.length || error.bulk_upld_error) ?
                        <label className={"error-contract " + (!error.bulk_upld_error ? "text-success" : "text-danger")}>{error.bulk_upld_error ? error.bulk_upld_error : (commodityTypes.length + " Entries Found")}</label> : null}
                      {/* <label class="text-danger error-contract">{error.bulk_upld_error}</label> */}
                    </div>
                  </div>
                </div>
                {commodityTypes.length ? (
                  <>
                    <h6>{fileName}</h6>
                    <h6 className='text-danger'>Note* - Before submitting file kindly review below entries</h6>
                    <table class="table text-center">
                      <thead>
                        <tr>
                          <th scope="col">Sr No.</th>
                          <th scope="col">Commodity Type/Category</th>
                          <th scope="col">Commodity Name</th>
                          <th scope="col">Specification Names</th>
                          <th scope="col">Respective Typical Values</th>
                        </tr>
                      </thead>
                      <tbody>
                        {commodityTypes.map((item, index) =>
                          <tr>
                            <td scope="row">{index + 1}</td>
                            <td>{item[0] ? item[0] : "-"}</td>
                            <td>{item[1] ? item[1] : "-"}</td>
                            <td>{item[2] ? item[2] : "-"}</td>
                            <td>{item[3] ? item[3] : "-"}</td>
                          </tr>
                        )
                        }
                      </tbody>
                    </table>
                    <button type="button" style={{ "margin-left": '45%' }} className="btn btn-primary btn-sm mb-1" onClick={() => saveCommodities()}>SUBMIT</button>
                  </>
                ) : null}
              </div>
            </div>

          </div>
        </div>
      </div>
      }
    </>
  )
};

const mapStateToProps = state => {

  return {
    showModalAddComm: state.showModalAddComm
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setshowModalAddComm: (flag, data) => { dispatch(setshowModalAddComm({ modal: flag, info: data })) }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserCommodityAddModal)
