import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { ToastContainer } from 'react-toastify';
import call from '../../../service';
import { platformBackendUrl } from '../../../urlConstants';
import { FileInput } from '../../../utils/FileInput';
import { convertImageToPdf, decryptData, ExportExcel, GetCache, getDocDetails, most_used_currencies, SetCache } from '../../../utils/myFunctions';
import { InputWithSelect, NewInput, NewSelect } from '../../../utils/newInput';
import { NewTable } from '../../../utils/newTable';
import toastDisplay from '../../../utils/toastNotification';
import Filter from '../../InvoiceDiscounting/components/Filter';
import Pagination from '../../InvoiceDiscounting/contract/components/pagination';
import HeaderV2 from '../../partial/headerV2';
import SideBarV2 from '../../partial/sideBarV2';
import MultipleSelect from '../../../utils/MultipleSelect';
import CustomBarChart from '../../Dashboard/components/CustomBarChart';
import CustomAreaChart from '../../Dashboard/components/CustomAreaChart';

const subadminUserPermissions = [
  { name: 'Exporter' },
  { name: 'Financier' },
  { name: 'Importer' },
  { name: 'Channel Partner' },
  { name: 'Assign Task' }
]
const bardataConfig = [
  { dataKey: "buyer_added", fill: "#2ECC71", display: 'Buyer Added' },
  { dataKey: "lc_limit", fill: "#F26191", display: 'LC Limit' },
  { dataKey: "invoice_limit", fill: "#FFB801", display: 'Invoice Limit' },
  { dataKey: "lc_discounting", fill: "#5CB8D3", display: 'LC Discounting' },
  { dataKey: "invoice_discounting", fill: "#FF7B6D", display: 'Invoice Discounting' }
]
const UserAcessFinancer = {
  "Exporter": true,
  "Financier": true,
  "Importer": true,
  "Channel Partner": true,
  "Assign Task": true,
  "Task Manager Users": 'taskManager',
  "Task Manager Enquiry": 'enquiry',
  "Task Manager Call List": 'callList',
  "LC Limit": 'lcLimit',
  "Invoice Limit": 'invoiceLimit',
  "Invoice Finance": 'invoiceFinance',
  'Invoice Approved Finance': 'invoiceApprovedFinance',
  "CRM Leads": 'leads',
  "CRM Data": 'crmdata',
  "Payments": 'adminPayments',
  "Invoice Financer": 'generateInvoiceFinancier',
  "Invoice Channel Partner": 'generateInvoiceCP',
  "Chat Room": 'ChatRoomV2',
  "Master Data": "masterdata"
}

const SubAdminProfileAdmin = ({ userTokenDetails, navToggleState }) => {

  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')
  let todayDateObj = moment()
  let lastMonthDateObj = moment().subtract("1", "months")

  let history = useHistory()

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [tableData, setTableData] = useState([])
  const [showLoader, setshowLoader] = useState(false)
  const [subadminSummary, setSubAdminSummary] = useState([])
  const [filter, setFilter] = useState({ resultPerPage: 10 })
  const [filterData, setFilterData] = useState({})
  const [refresh, setRefresh] = useState(0)
  const [showAddNewUserPopup, toggleAddNewUserPopup] = useState(false)
  const [data, setData] = useState({ nameTitle: 'Mr', phoneCode: "91", country: "IN" })
  const [errors, setErrors] = useState({})
  const [countryData, setcountryData] = useState([])
  const [accessObj, setAccessObj] = useState({})
  const [salesPerson, setSalesPerson] = useState([])
  const [salesPersonOverall, setsalesPersonOverall] = useState([])
  const [graphConfiguration, setGraphConfiguration] = useState({
    isUserSummaryGraph: true,
    userSummaryFrom: lastMonthDateObj.clone().format("YYYY-MM-DD"),
    userSummaryTo: todayDateObj.clone().format("YYYY-MM-DD"),
    isDiscountingGraph: true,
    discountingFrom: lastMonthDateObj.clone().format("YYYY-MM-DD"),
    discountingTo: todayDateObj.clone().format("YYYY-MM-DD"),
  })
  const [userSummaryGraph, setUserSummaryGraph] = useState([])
  const [userSummarytbl, setUserSummarytbl] = useState([])
  const [discountingGraph, setdiscountingGraph] = useState([])
  const [discountingtbl, setdiscountingtbl] = useState([])

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;
  useEffect(() => {
    setshowLoader(true)
    call('POST', 'getSubAdminUserSummary', { type: 'Admin' }).then(res => {
      setshowLoader(false)
      setSubAdminSummary(res)
    })
  }, [])
  const getUserSummaryAdminWise = () => {
    let reqObj = {
      from: graphConfiguration.userSummaryFrom,
      to: graphConfiguration.userSummaryTo,
    }
    setshowLoader(true)
    call('POST', 'getUserSummaryAdminWise', reqObj).then(result => {
      setUserSummaryGraph(result)
      setshowLoader(false)
    }).catch(e => {
      setshowLoader(false)
    })
  }
  useEffect(() => {
    if (!graphConfiguration.isUserSummaryGraph) {
      let tableData = []
      let row = []

      userSummaryGraph.forEach((item) => {
        row.push(item.admin_name || '-')
        row.push(item.buyer_added || '-')
        row.push(item.lc_limit || '-')
        row.push(item.invoice_limit || '-')
        row.push(item.lc_discounting || '-')
        row.push(item.invoice_discounting || '-')
        tableData.push(row)
        row = []
      })
      setUserSummarytbl(tableData)
    }
  }, [graphConfiguration.isUserSummaryGraph])
  useEffect(() => {
    getUserSummaryAdminWise()
  }, [graphConfiguration.userSummaryFrom, graphConfiguration.userSummaryTo])

  const getDiscountingAdminWise = () => {
    let reqObj = {
      from: graphConfiguration.discountingFrom,
      to: graphConfiguration.discountingTo,
    }
    setshowLoader(true)
    call('POST', 'getDiscountingAdminWise', reqObj).then(result => {
      setdiscountingGraph(result)
      setshowLoader(false)
    }).catch(e => {
      setshowLoader(false)
    })
  }
  useEffect(() => {
    if (!graphConfiguration.isDiscountingGraph) {
      let tableData = []
      let row = []

      discountingGraph.forEach((item) => {
        row.push(item.admin_name || '-')
        row.push(item.disbursement ? "$ " + Intl.NumberFormat('en-US', { notation: 'compact' }).format(item.disbursement) : '-')
        tableData.push(row)
        row = []
      })
      setdiscountingtbl(tableData)
    }
  }, [graphConfiguration.isDiscountingGraph])
  useEffect(() => {
    getDiscountingAdminWise()
  }, [graphConfiguration.discountingFrom, graphConfiguration.discountingTo])

  useEffect(() => {
    setshowLoader(true)
    call("POST", 'getSubAdminUser', {}).then(res => {
      setshowLoader(false)
      setSalesPerson(res.data)
      setsalesPersonOverall(res.data)
    }).catch(err => setshowLoader(false))
  }, [])
  useEffect(() => {
    if (data.userIdToUpdate) {
      setSalesPerson(salesPersonOverall.filter(item => item.id != data.userIdToUpdate))
    }
  }, [data.userIdToUpdate])
  const handleMultiSelectchange = (e, name, val, singleSelect) => {
    if (singleSelect) {
      setData({
        ...data,
        [name]: e?.[0]?.[val] ? e.reverse()?.[0]?.[val] : null
      })
    }
    else {
      setData({
        ...data,
        [name]: Array.isArray(e) ? e.map((x) => x[val]) : []
      });
    }
  };

  const handleGraphConfigurationChange = async (event) => {
    if (event.persist) {
      event.persist()
    }
    setGraphConfiguration({ ...graphConfiguration, [event.target.name]: event.target.value })
  }
  useEffect(() => {
    axios.get(platformBackendUrl + "/getallCountry").then((result) => {
      if (result.data.message && result.data.message.length) {
        setcountryData(result.data.message);
      }
    });
    setshowLoader(true)
    call("POST", 'getSubAdminUser', { currentPage: page, resultPerPage: filter.resultPerPage, search: filter.search, type: 'Admin' }).then(res => {
      setshowLoader(false)
      setDbData(res.data)
      setTableData(formatDataForTable(res.data))
      setCount(res.totalCount)
    }).catch(err => setshowLoader(false))
  }, [filter, refresh, page]);

  function formatDataForTable(data) {
    let tableData = []
    let row = []
    for (let index = 0; index < data.length; index++) {
      const item = data[index];
      let UserPermissionsObj = JSON.parse(item.UserPermissions || "{}")
      let UserPermissionsArray = Object.keys(UserPermissionsObj).filter(i => {
        if (UserPermissionsObj[i]) {
          return i
        }
      })
      row[0] = moment(item.created_at).format("YYYY-MM-DD")
      row[1] = item.name_title + " " + item.contact_person
      row[2] = item.designation
      row[3] = "+" + item.phone_code + " " + item.contact_number
      row[4] = item.email_id
      row[5] = UserPermissionsArray.join(", ")
      row[6] = <label
        onClick={() => popupHandler(item)}
        className='color5CB8D3 font-wt-600 font-size-14 cursor'>View Details</label>
      tableData.push(row)
      row = []
    }
    return tableData
  }

  async function popupHandler(item) {
    setshowLoader(true)
    toggleAddNewUserPopup(true)
    let kycDocs = {}
    let tempDocsId = item?.kycDocs?.split(",") || []
    let docResp = await getDocDetails(tempDocsId[0])
    kycDocs[docResp?.gen_doc_label] = docResp
    docResp = await getDocDetails(tempDocsId[1])
    kycDocs[docResp?.gen_doc_label] = docResp
    const response = await call("POST", 'getSubAdminManagers', { userId: item.tbl_user_id })

    setData({
      nameTitle: item.name_title,
      contactPerson: item.contact_person,
      designation: item.designation,
      phoneCode: item.phone_code,
      contactNo: item.contact_number,
      email: item.email_id,
      password: decryptData(item.password),
      country: item.country_code,
      userAddress: item.user_address,
      aadharDocumentName: item.aadhar_no,
      panDocumentName: item.pan_no,
      ...kycDocs,
      userIdToUpdate: item.id,
      userAccess: JSON.parse(item.UserPermissions),
      functionalManager: response.functional_manager_id,
      reportingManager: response.reporting_manager_id,
    })
    setAccessObj(JSON.parse(item.UserPermissions))
    setshowLoader(false)
  }

  const handleChange = async (event) => {
    if (event.persist) {
      event.persist()
    }
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }

  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }

      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (!file_type.includes("pdf")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  async function handleValidation() {
    let validateFields = ["contactPerson", "contactNo", "email", "password", "designation", "country", "userAddress", "aadharDocumentName", "panDocumentName"]
    let err = {}

    for (let index = 0; index < validateFields.length; index++) {
      const element = validateFields[index];
      if (!data[element]) {
        err[element] = "Mandatory Field"
      }
    }

    if (!Object.keys(err).length) {
      setshowLoader(true)
      let formData = new FormData()
      Object.keys(data).forEach(k => {
        if (k != "userAccess") {
          formData.append(k, data[k])
        }
      })
      formData.append("userAccess", JSON.stringify(accessObj || {}))
      formData.append("userId", userId)
      // if (data.selectedCallers) {
      //   formData.append("selectedCallers", JSON.stringify(data.selectedCallers))
      // }
      call('POST', 'onboardSubAdminUser', formData).then(res => {
        setshowLoader(false)
        toastDisplay(res, "success")
        toggleAddNewUserPopup(false)
        setData({ nameTitle: 'Mr', phoneCode: "91", country: "IN" });
        setRefresh(refresh + 1)
      }).catch(err => {
        setshowLoader(false)
        toastDisplay('Something went wrong', "error")
      })
    }

    setErrors(err)
  }

  async function downloadCSVFile() {
    try {
      let csvString = "Onboarded On,Name,Designation,Contact No,Email Id,Access\r\n"
      for (let i = 0; i < dbData.length; i++) {
        let item = dbData[i]
        let UserPermissionsObj = JSON.parse(item.UserPermissions || "{}")
        let UserPermissionsArray = Object.keys(UserPermissionsObj).filter(i => {
          if (UserPermissionsObj[i]) {
            return i
          }
        })
        let rowString = `${moment(item.created_at).format("YYYY-MM-DD")},
          ${item.name_title + " " + item.contact_person},
          ${item.designation},
          ${"+" + item.phone_code + " " + item.contact_number},
          ${item.email_id},
          ${UserPermissionsArray.join(":")}\r\n`
        rowString = rowString.replace(/(\n)/gm, "")
        csvString += rowString
      }
      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', `SubAdminList.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log("error in downloadCSVFile", error);
    }
  }

  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />

      <div className={`modal fade ${showAddNewUserPopup && "show"}`} style={showAddNewUserPopup ? { display: "block", "zIndex": '100001' } : {}}>
        <div className="modal-dialog modal-md mr-0 my-0">
          <div className="modal-content submitmodal pb-4"
          >
            <div className="modal-header border-0">
              <div className="w-100 d-flex align-items-center justify-content-between">
                <label
                  className="font-size-16 font-wt-600 text-color-value mx-3"
                >{data.userIdToUpdate ? "Update SubAdmin" : 'Add New SubAdmin'}</label>
                <div className="modal-header border-0">
                  <button type="button" className="btn-close" aria-label="Close" onClick={() => {
                    setData({ nameTitle: 'Mr', phoneCode: "91", country: "IN" });
                    toggleAddNewUserPopup(false)
                  }}></button>
                </div>
              </div>
            </div>

            <div className="modal-body px-4">
              <div>
                <div className='mb-4 position-relative'>
                  <InputWithSelect selectData={[{ name: "Mr" }, { name: 'Miss' }]}
                    selectName="nameTitle" selectValue={data.nameTitle} optionLabel={'name'} optionValue={'name'}
                    onChange={handleChange} type="text" name={"contactPerson"} value={data["contactPerson"]}
                    label={"Full Name"} error={errors["contactPerson"]}
                  />
                </div>
                <div className='mb-4 position-relative'>
                  <InputWithSelect
                    selectData={countryData} selectName={"phoneCode"} selectValue={data.phoneCode} optionLabel={"phonecode"}
                    optionValue={'phonecode'}
                    type="number" name={"contactNo"} value={data["contactNo"]}
                    onChange={handleChange}
                    label={"Mobile Number"} error={errors["contactNo"]} />
                </div>
                <div className='mb-4 position-relative'>
                  <NewInput type={"email"} name={"email"}
                    value={data["email"]}
                    onChange={handleChange} label={"Email Id"} error={errors["email"]} />
                </div>
                <div className='mb-4 position-relative'>
                  <NewInput type={"text"} name={"password"}
                    value={data["password"]}
                    onChange={handleChange} label={"Password"} error={errors["password"]} />
                </div>
                <div className='mb-4 position-relative'>
                  <NewInput type={"text"} name={"designation"}
                    value={data["designation"]}
                    onChange={handleChange} label={"Designation"} error={errors["designation"]} />
                </div>
                <div className='mb-4 position-relative'>
                  <NewSelect
                    label={"Country"}
                    selectData={countryData} name="country" value={data.country} onChange={handleChange}
                    optionLabel={"name"} optionValue={"sortname"} error={errors["country"]} />
                </div>
                <div className='mb-4 position-relative'>
                  <NewInput type={"text"} name={"userAddress"}
                    value={data["userAddress"]}
                    onChange={handleChange} label={"Address"} error={errors["userAddress"]} />
                </div>
                <div className='mb-4 position-relative'>
                  <NewInput type={"text"} name={"aadharDocumentName"}
                    value={data["aadharDocumentName"]}
                    onChange={handleChange} label={"Aadhar No"} error={errors["aadharDocumentName"]} />
                </div>
                <div className='mb-4 position-relative'>
                  <NewInput type={"text"} name={"panDocumentName"}
                    value={data["panDocumentName"]}
                    onChange={handleChange} label={"PAN No"} error={errors["panDocumentName"]} />
                </div>
                <p className='text-decoration-underline font-size-14 mt-3'>Attach Documents</p>

                <p className='font-size-14 mt-3'>Aadhar Document</p>
                <div className="col-md-12">
                  <div className="row form-group">
                    <div className="col-md-11">
                      <FileInput name={"aadharDocument"} value={data.aadharDocument} error={errors.aadharDocument}
                        onChange={handleFile} isEditable={true}
                        onUploadCancel={() => setData({ ...data, "aadharDocument": null })} />
                      {errors.aadharDocument ? <div class="text-danger mt-2 font-size-12">
                        <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                        <b>{errors.aadharDocument}</b></div> : ''}
                    </div>
                  </div>
                </div>

                <p className='font-size-14 mt-3'>PAN Document</p>
                <div className="col-md-12">
                  <div className="row form-group">
                    <div className="col-md-11">
                      <FileInput name={"panDocument"} value={data.panDocument} error={errors.panDocument}
                        onChange={handleFile} isEditable={true}
                        onUploadCancel={() => setData({ ...data, "panDocument": null })} />
                      {errors.panDocument ? <div class="text-danger mt-2 font-size-12">
                        <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                        <b>{errors.panDocument}</b></div> : ''}
                    </div>
                  </div>
                </div>

                <p className='text-decoration-underline font-size-14 mt-4'>User Access</p>

                {/* <div className='row'> */}
                {/* {subadminUserPermissions.map((i, j) => {
                  return (
                    <div className="d-flex flex-row align-items-center mb-3">
                      <div className="align-self-center">
                        <img
                          onClick={() => {
                            let temp = data.userAccess || {}
                            temp[i.name] = !temp[i.name]
                            setData({ ...data, userAccess: temp })
                          }}
                          className='cursor mr-3' src={`assets/images/${data.userAccess?.[i.name] ? 'checked-green' : 'empty-check'}.png`} />
                      </div>
                      <label className="font-wt-500 font-size-14 mr-4 align-self-center pt-1" for="currentDocuments">{i.name}</label>
                    </div>
                  )
                })} */}
                {/* </div> */}
                <div className='row py-2'>
                  {Object.keys(UserAcessFinancer).map((item, index) => {
                    return <div className="col-md-6 pt-2">
                      <div className='d-flex flex-row align-items-center'>
                        <img src={accessObj[item] ? '/assets/images/checked-green.png' : '/assets/images/unchecked-box.png'} height={20} width={20} className='mr-2 cursor' onClick={() => {
                          const temp = accessObj
                          if (accessObj[item]) {
                            delete temp[item]
                          } else {
                            let userAccess = UserAcessFinancer
                            temp[item] = userAccess[item]
                          }
                          setAccessObj({
                            ...temp
                          })
                        }} />
                        <p className="m-0">{item}</p>
                      </div>
                    </div>
                  })}
                </div>
                {errors.accesspermission &&
                  <div class="text-danger mt-2 font-size-12">
                    <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                    <b>{errors.accesspermission}</b>
                  </div>
                }
                <p className='text-decoration-underline font-size-14 mt-4'>Reporting Manager</p>
                <div className='col-md-12 p-0'>
                  <MultipleSelect
                    isMulti
                    Id="Select Reporting Manager"
                    Label="Select Reporting Manager"
                    selectedvalue="Select Reporting Manager"
                    filterOption={() => true}
                    optiondata={data.userIdToUpdate ? salesPerson : salesPersonOverall}
                    onChange={(e) => handleMultiSelectchange(e, "reportingManager", "id", true)}
                    value={data.reportingManager ? [data.reportingManager] : []}
                    name="reportingManager"
                    labelKey={"contact_person"}
                    valKey={"id"}
                    customStyles={{
                      backgroundColor: '#DEF7FF',
                      borderRadius: '10px'
                    }}
                  />
                </div>
                <p className='text-decoration-underline font-size-14 mt-4'>Functional Manager</p>
                <div className='col-md-12 p-0'>
                  <MultipleSelect
                    isMulti
                    Id="Select Functional Manager"
                    Label="Select Functional Manager"
                    selectedvalue="Select Functional Manager"
                    optiondata={data.userIdToUpdate ? salesPerson : salesPersonOverall}
                    onChange={(e) => handleMultiSelectchange(e, "functionalManager", "id", true)}
                    value={data.functionalManager ? [data.functionalManager] : []}
                    name="functionalManager"
                    labelKey={"contact_person"}
                    valKey={"id"}
                    customStyles={{
                      backgroundColor: '#DEF7FF',
                      borderRadius: '10px'
                    }}
                  />
                </div>
                <div className='mt-4'>
                  <button type="button"
                    onClick={() => { handleValidation() }}
                    className={` border-0 mb-2 text-white enableQuotebtn`}>
                    {data.userIdToUpdate ? 'Update' : "Submit"}
                  </button>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="container-fluid p-0">
        <div className="row">
          <main role="main" className={"col-lg-12 p-0"} id="app-main-div">
            <div className='mt-4' style={{}} >

              <div className='row'>
                {subadminSummary.length ? subadminSummary.map((i) => {
                  return (
                    <div
                      onClick={() => {
                        localStorage.setItem("subAdminDetails", JSON.stringify(i))
                        window.location = `/SubAdminProfileDetails`
                      }}
                      className='w-20 cursor'>
                      <div className='card h-75 dashboard-card shadow-sm align-items-center justify-content-center'>
                        <label className='font-size-16 text-color-value font-wt-600 pt-2'>{i.contact_person}
                          <label className='font-size-16 text-color-value font-wt-600 text-custom2'>{` - ${i.totalLeadAssigned}`}</label></label>
                        <div className='row px-0 w-100'>
                          <div className='w-50'>
                            <label className={`value font-wt-600 text-48DA87  w-100`}>
                              {`${i.totalActiveLeadAssigned}`}
                            </label>
                            <label className={'font-size-14 font-wt-600 text-color-value'}>{"Active"}</label>
                          </div>

                          <div className='w-50'>
                            <label className={`value font-wt-600 colorFF7B6D w-100`}>
                              {`${i.totalInactiveLeadAssigned}`}
                            </label>
                            <label className={'font-size-14 font-wt-600 text-color-value'}>{"Inactive"}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  )

                }) : null}
              </div>

              <div>
                <div className="filter-div ml-4 mt-3">
                  <Filter showDownloadIcon={true} onDownloadClick={downloadCSVFile} isAdditionalButton={true}
                    children={<div
                      className='mx-2 pt-2'
                    >
                      <button type="button"
                        onClick={() => {
                          toggleAddNewUserPopup(true)
                        }}
                        className={` border-0 mb-2 text-white enableQuotebtn`}>
                        {"Add New"}
                      </button>
                    </div>}
                    filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                    showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} />
                </div>
                <div className="mb-4">
                  <NewTable
                    disableAction={true}
                    data={tableData}
                    columns={[
                      { name: "Onboarded On" },
                      { name: "Name" },
                      { name: "Designation" },
                      { name: "Contact No." },
                      { name: "Email Id" },
                      { name: "Access" },
                      { name: "" }
                    ]}
                  />
                </div>
                <Pagination perPage={filter.resultPerPage || 10} page={page} totalCount={count} onPageChange={(p) => setPage(p)} />
              </div>

              <div className='card h-75 w-100 dashboard-card shadow-sm '>
                <div>
                  <div class="dropdown">
                    <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                      <div className='d-flex align-items-center '>
                        <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => { }}>User Summary</label>

                      </div>
                      <div className='d-flex gap-3'>
                        <div className='w-50 pr-3'>
                          <NewInput type={"date"} name={"userSummaryFrom"} value={graphConfiguration.userSummaryFrom}
                            onChange={handleGraphConfigurationChange} removeMb={true} />
                        </div>
                        <div className='w-50 pr-3'>
                          <NewInput type={"date"} name={"userSummaryTo"} value={graphConfiguration.userSummaryTo}
                            onChange={handleGraphConfigurationChange} removeMb={true} />
                        </div>
                      </div>

                      <div className='d-flex flex-row align-items-center gap-1'>
                        <div className="d-flex gap-2">
                          {bardataConfig.map(item => {
                            return <label className="paymentsdots mb-0 cursor" onClick={() => { }}><div className='colorlessdot' style={{ backgroundColor: item.fill }} /> {item.display} </label>
                          })}
                        </div>
                        <div className='w-20 align-items-center d-flex justify-content-end'>
                          <div className='pr-3'>
                            <img
                              onClick={() => { setGraphConfiguration({ ...graphConfiguration, isUserSummaryGraph: !graphConfiguration.isUserSummaryGraph }) }}
                              className='cursor'
                              src={`/assets/images/${graphConfiguration.isUserSummaryGraph ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                          </div>
                          <div className=''>
                            <img
                              onClick={() => ExportExcel(userSummaryGraph, 'User Summary')}
                              className='cursor' src='/assets/images/download_icon_with_bg.png' />
                          </div>
                        </div>

                      </div>



                    </div>

                  </div>
                </div>
                <div>
                  {graphConfiguration.isUserSummaryGraph ?
                    <CustomBarChart XFormatter={(value) => value} YFormatter={(value) => value} bardataConfig={bardataConfig} formatterFunction={(value, name) => [value, name]} data={userSummaryGraph} xDataKey={"admin_name"} />
                    : <div>
                      <NewTable columns={[
                        { name: "Admin" },
                        { name: "Buyer Added" },
                        { name: "LC limit" },
                        { name: "Invoice Limit" },
                        { name: "LC Discounting" },
                        { name: "Invoice Discounting" },
                      ]}
                        data={userSummarytbl} disableAction />
                    </div>
                  }
                </div>

              </div>


              <div className='card h-75 w-100 dashboard-card shadow-sm mt-5'>
                <div>
                  <div class="dropdown">
                    <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                      <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => { }}>Discounting</label>

                      <div className='d-flex gap-3 w-50'>
                        <div className='w-50 pr-3'>
                          <NewInput type={"date"} name={"discountingFrom"} value={graphConfiguration.discountingFrom}
                            onChange={handleGraphConfigurationChange} removeMb={true} />
                        </div>
                        <div className='w-50 pr-3'>
                          <NewInput type={"date"} name={"discountingTo"} value={graphConfiguration.discountingTo}
                            onChange={handleGraphConfigurationChange} removeMb={true} />
                        </div>
                      </div>

                      <div className='align-items-center d-flex justify-content-end'>
                        <div className='pr-3'>
                          <img
                            onClick={() => { setGraphConfiguration({ ...graphConfiguration, isDiscountingGraph: !graphConfiguration.isDiscountingGraph }) }}
                            className='cursor'
                            src={`/assets/images/${graphConfiguration.isDiscountingGraph ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                        </div>
                        <div className=''>
                          <img
                            onClick={() => ExportExcel(discountingGraph, 'Discounting Graph')}
                            className='cursor' src='/assets/images/download_icon_with_bg.png' />
                        </div>
                      </div>

                    </div>

                  </div>
                </div>

                <div>
                  {graphConfiguration.isDiscountingGraph ?
                    <CustomAreaChart XFormatter={(value) => value} YFormatter={(value) => "$ " + Intl.NumberFormat('en-US', { notation: 'compact' }).format(value)} bardataConfig={bardataConfig} formatterFunction={(value, name) => ["$ " + Intl.NumberFormat('en-US', { notation: 'compact' }).format(value), name]} data={discountingGraph} xDataKey={"admin_name"} dataKey={"disbursement"} />
                    : <div>
                      <NewTable columns={[
                        { name: "Admin" },
                        { name: "Discounting" },
                      ]}
                        data={discountingtbl || []} disableAction />
                    </div>
                  }
                </div>

              </div>

            </div>
          </main>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(SubAdminProfileAdmin)