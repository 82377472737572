import moment from 'moment'
import React from 'react'
import { useRef } from 'react'
import { useState } from 'react'
import { FileInput } from '../../../utils/FileInput'
import avatarUrl from '../../../utils/makeAvatarUrl'
import { convertImageToPdf, dataURItoBlob } from '../../../utils/myFunctions'
import toastDisplay from '../../../utils/toastNotification'
import call from '../../../service'

const ChatBoxPopUp = ({ user_avatar, receiverName, chatList, userId, onChatSend, message, setTextMsg, onPopupClose }) => {
  const inputFile = useRef(null)
  const [data, setData] = useState()
  const [errors, setErrors] = useState()
  const handleKeyDown = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      onChatSend()
      setTextMsg('')
    }
  };
  const handleFile = (event, isImage) => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")) || (file_type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")) || (file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png, jpeg, docx & xlsx extension are allowed" })
        return
      }
      console.log('Fileddatataaaa', event.target.files)
      if (event.target.name.includes("otherDoc")) {
        //setAddMoreDoc(addMoreDoc.concat(null))
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result

        if (file_type.includes("png") || file_type.includes("jpeg")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') || file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
          try {
            let formData = new FormData()
            formData.append("file", fileObj)
            if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
              formData.append("docType", "docx")
            }
            if (file_type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
              formData.append("docType", "xlsx")
            }
            let apiResp = await call('POST', "docToPdf", formData)
            let blob = dataURItoBlob(apiResp);
            fileObj = new File([blob], fileObj.name + "converted.pdf", { type: "application/pdf" });
            fileDataUrl = apiResp
            toastDisplay("File converted into pdf format", "success")
          } catch (error) {
            return toastDisplay("failed to convert file", "error")
          }
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        console.log('File Uploaded', event.target.files[0])
        onChatSend(event.target.files[0])
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  return (
    <div className='chatBox'>
      <div className='chatHeader h-auto d-flex justify-content-between align-items-center'>
        <div className='d-flex gap-3 align-items-center'>
          <div className="chatImgCont">
            <img alt={user_avatar ? user_avatar : '/assets/images/user-profile.png'} src={user_avatar ? typeof (user_avatar) == 'string' ? avatarUrl(user_avatar) : user_avatar.filebase64 : "/assets/images/user-profile.png"} className="rounded-circle chat_user_img ml-2 mt-2 mb-2" />
            <span className="online_icon"></span>
          </div>
          <div>
            <p className='SenderLabel mb-0 ml-0'>{receiverName}</p>
            {/* <p className='mb-0 font-size-13 text-secondary'>Active now</p> */}
          </div>
        </div>
        <div>
          <img src={"/assets/images/cross-dark.svg"} alt="" className='mb-0 mr-2' onClick={onPopupClose} />
        </div>
      </div>
      <div className='chatContainerPopup'>
        {chatList.map((key, index) => {
          return (
            <div className={userId === key.senderId ? 'chatsend d-flex w-65 ms-auto justify-content-end gap-2 align-items-center mt-4 ' : 'messages d-flex w-65 justify-content-start gap-2 align-items-center mt-3'}>

              <div className={`w-auto ${userId === key.senderId ? 'meetingLink' : 'messagesend'}  d-flex p-2 gap-2`}>
                <div className='w-100 message '>
                  {key.chatFile ?
                    <FileInput value={key.chatFile} />
                    : <p className='mb-0' dangerouslySetInnerHTML={{ __html: key.text_message }}></p>
                  }
                </div>
                <div className='w-auto mt-auto '>
                  <p className='text-secondary font-size-12 mb-0 '>{moment(key.createdAt).format("HH:mm")}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className='d-flex flex-row chatBottomDiv p-2 '>
        <div className='w-auto me-1 z-index99'>
          <div className="btn-group mt-1">
            <img onClick={(e) => inputFile.current.click()} src={'/assets/images/icon_attachment.png'} alt='' className="cursor" />
            <input type='file' id='inputFile' ref={inputFile} name="chatDoc" style={{ display: 'none' }} onChange={(e) => {
              handleFile(e)
            }} />
          </div>
        </div>
        <input className='form-control w-100 border send-btn' type="text" placeholder="Type" value={message} onChange={(e) => {
          e.preventDefault()
          setTextMsg(e.target.value)
        }} onKeyDown={handleKeyDown} />
      </div>

    </div>
  )
}

export default ChatBoxPopUp
