import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import NewTablev2 from "../../../utils/newTablev2";
import call from "../../../service";
import moment from "moment";
import { encryptData } from "../../../utils/myFunctions";
import { most_used_currencies } from "../../../utils/myFunctions";
import { columns, tablenewData } from "../TableJson"
import Pagination from "../../InvoiceDiscounting/contract/components/pagination";

const transactions = [
  { name: "Finance Limit Quote", lc_label: "LC_Quotes_Count", inv_label: "INV_Quotes_Count" },
  { name: "Finance Limit Contract", lc_label: "LC_Contracts_Count", inv_label: "INV_Contracts_Count" },
  { name: "Finance Application", lc_label: "LC_Apply_Fin_Count", inv_label: "INV_Apply_Fin_Count" },
  { name: "Agreement", lc_label: "LC_Agreement", inv_label: "INV_Agreement" },
  { name: "Approved  Finance", lc_label: "LC_Approved_Fin_Count", inv_label: "INV_Approved_Fin_Count" },
  { name: "Amendment", lc_label: "LC_Ammendments_Count", inv_label: "INV_Ammendments_Count" },
]

const TaskUpdate = ({ userTokenDetails, navToggleState }) => {

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [tableData, setTableData] = useState([])
  const [transactionObj, setTransactionObj] = useState({})
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10 })

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  const getTransactionsCount = () => {
    call('POST', 'getTransactionCounts', { userId }).then(result => {
      setTransactionObj(result)
    }).catch(e => {
      setTransactionObj(e)
    })
  }
  const navigateFunction = (data) => {
    if (data.type === 'See Quotes' && data.service === 'LC') {
      window.location = `/LcSeequotes?id=${data.tblId}`
    } else if (data.type === 'See Quotes' && data.service === 'Invoice') {
      window.location = `seequotes?buyer=${data.buyerId}`
    } else if (data.type === 'Sign Contract' && data.service === 'LC') {
      window.location = `LcSignContract?id=${data.tblId}`
    } else if (data.type === 'Sign Contract' && data.service === 'Invoice') {
      window.location = `signContract?buyer=${data.buyerId}`
    }
  }
  useEffect(() => {
    getTransactionsCount()
  }, [])
  useEffect(() => {
    getTaskdata()

  }, [refresh, page])
  function formatDataForTable(data) {
    let tableData = []
    let row = []

    data.forEach((item, index) => {
      row[0] = item.transactionId
      row[1] = "Finance Application - Lc"
      row[2] = item.buyerName
      row[3] = <a className="text-custom2 font-size-13 font-wt-500 textunderline" href="">
        {"See Quotes"}
      </a>
      tableData.push(row)
      row = []
    })
    return tableData
  }

  const getTaskdata = () => {
    let reqObj = {
      userId,
      resultPerPage: 10,
      currentPage: page
    }
    call('POST', 'getTasks', reqObj).then(result => {
      console.log('success in getTasks', result)
      setDbData(result.data)
      setCount(result.countdata)

    }).catch(e => {
      console.log('error in getTasks', e)
    })
  }

  console.log('DbDataaaaa', dbData)
  return (
    <>
      <div className='card p-3 border-0 card-layout borderRadius mb-4'>
        <div className='d-flex justify-content-between'>
          <div className='col-md-4 p-2'>
            <div className='mx-1 text-center lineheight19'>
              <p className='border-bottom text-custom2 lineheight19 font-size-14 pb-1'>Transaction type</p>
              {transactions.map((tranaction, index) => <p className={index === transactions.length - 1 ? ' lineheight19 font-size-14 mb-0' : 'border-bottom lineheight19 font-size-14 pb-1'}>{tranaction.name}</p>)}
            </div>
          </div>
          <div className='col-md-4 p-2'>
            <div className='mx-1 text-center lineheight19'>
              <p className='border-bottom text-custom2 lineheight19 font-size-14 pb-1'>Letter of Credit</p>
              {transactions.map((tranaction, index) => <p className={index === transactions.length - 1 ? ' lineheight19 font-size-14 mb-0' : 'border-bottom lineheight19 font-size-14 pb-1'}>{transactionObj[tranaction.lc_label]}</p>)}
            </div>
          </div>
          <div className='col-md-4 p-2'>
            <div className='mx-1 text-center lineheight19'>
              <p className='border-bottom text-custom2 lineheight19 font-size-14 pb-1'>Invoice Discounting</p>
              {transactions.map((tranaction, index) => <p className={index === transactions.length - 1 ? ' lineheight19 font-size-14 mb-0' : 'border-bottom lineheight19 font-size-14 pb-1'}>{transactionObj[tranaction.inv_label]}</p>)}
            </div>
          </div>
        </div>
      </div>
      <p className='text-color-0C0C0C font-size-14 font-wt-600 lineheight19'>Task update</p>
      <div className="card border-0 card-layout borderRadius">
        <NewTablev2 columns={columns}>
          {/* data.map((item, index) */}
          {/* added tablenewData because it is not working as data  */}
          {dbData.map((key, index) => {
            let createdAt = moment(key.createdAt)
            let todaysDate = moment()
            let data
            let diff = createdAt.diff(todaysDate, 'days') + 5
            console.log('difff in dayasssss', key.status == 0)
            let Action = ''
            let TranscationType = ''
            if (key.selectedFinancier === null && key.buyers_credit !== null) {
              Action = 'See Quotes'
              data = {
                tblId: key.tblId,
                type: 'See Quotes',
                service: 'LC'
              }
            } else if (key.selectedFinancier !== null && key.termSheetSignedByExporter === 0) {
              Action = 'Sign Contract'
              data = {
                tblId: key.tblId,
                type: 'Sign Contract',
                service: 'LC'
              }
            } else if (key.status == 0) {
              Action = 'Apply For Finance'
            }

            if (key.invRefNo === 'sblc') {
              TranscationType = 'SBLC'
            } else if (key.invRefNo === 'lc_discounting') {
              TranscationType = 'LC Discounting'
            } else if (key.invRefNo === 'lc_confirmation') {
              TranscationType = 'LC Confirmation'
            } else {
              if (key.selectedFinancier === null && key.buyers_credit !== null) {
                Action = 'See Quotes'
                data = {
                  buyerId: key.buyerId,
                  type: 'See Quotes',
                  service: 'Invoice'
                }
              } else if (key.selectedFinancier !== null && key.termSheetSignedByExporter === 0) {
                Action = 'Sign Contract'
                data = {
                  buyerId: key.buyerId,
                  type: 'Sign Contract',
                  service: 'LC'
                }
              } else if (key.status == 0) {
                Action = 'Apply For Finance'
              }
              TranscationType = 'Invoice Discounting'
            }

            return (
              <tr>
                <td>{key.transactionId ? key.transactionId : '-'}</td>
                <td>{TranscationType}</td>
                <td title={key.buyerName} className="cursor"> {key.buyerName ? key.buyerName.length > 60 ? key.buyerName.slice(0, 60) + '...' : key.buyerName : '-'} </td>
                <td>
                  {diff < 0 ?
                    (<p
                      class={`text-color-0C0C0C mb-0 font-size-13`}> -
                    </p>)
                    :
                    (<p
                      class={`colorFE4141 mb-0 font-size-13`}> {diff + " days"}
                    </p>)
                  }
                </td>
                <td><p href="" className="text-decoration-none text-color1 font-size-14 font-wt-500" onClick={() => navigateFunction(data)}> {Action} </p></td>
              </tr>
            );
          })
          }
        </NewTablev2>
      </div>
      <Pagination page={page} totalCount={count} onPageChange={(p) => setPage(p)} perPage={filter.resultPerPage || 10} />

    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(TaskUpdate)