import React, { useEffect, useState } from 'react'
import HeaderV2 from '../partial/headerV2'
import SideBarV2 from '../partial/sideBarV2'
import { ToastContainer } from 'react-toastify'
import { connect } from 'react-redux'
import call from '../../service'
import TradeContract from './components/TradeContract'
import ShipmentContract from './components/ShipmentContract'

const DashboardV4 = ({ userTokenDetails, navToggleState }) => {
  const [tab, setTab] = useState("Trade Contract")
  const [showLoader, setshowLoader] = useState(false)
  const [dashData, setdashData] = useState({});
  useEffect(() => {
    call('get', 'getDashboardData')
      .then((result) => {
        console.log("fetched result in dashboard===>", result)
        setdashData(result);
        setshowLoader(false);
      })
      .catch((err) => {
        console.log("error:", err)
        setshowLoader(false);
      });
  }, [])

  return (
    <div className="">
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />

      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="dashboard" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Dashboard"}
              userTokenDetails={userTokenDetails} />
            <div>
              <nav>
                <div className="nav nav-tabs signdoctabs gap-4 border-0" id="signdoctabs" role="tablist">
                  <button className={`nav-link  bg-transparent ${tab === "Trade Contract" && 'active'} paymenttab`} id="nav-home-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-trade" aria-selected="true" onClick={() => setTab("Trade Contract")}>Trade Contract</button>
                  <span className="border-left"></span>
                  <button className={`nav-link  bg-transparent ${tab === "Shipment Contract" && 'active'} paymenttab`} id="nav-profile-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-shipment-contract" aria-selected="false" onClick={() => setTab("Shipment Contract")}>Shipment Contract</button>
                  {/* <span className="border-left"></span>
                  <button className={`nav-link  bg-transparent ${tab === "Shipment Quotations" && 'active'} paymenttab`} id="nav-profile-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-shipment-contract" aria-selected="false" onClick={() => setTab("Shipment Quotations")}>Shipment Quotations</button>
                  <span className="border-left"></span>
                  <button className={`nav-link  bg-transparent ${tab === "Plan Payment Details" && 'active'} paymenttab`} id="nav-contact-tab " data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-plan-payment" aria-selected="false" onClick={() => setTab("Plan Payment Details")}>Plan Payment Details</button> */}

                </div>
              </nav>
            </div>
            <div className="tab-content d-block" id="nav-tabContent" >
              {tab === 'Trade Contract' &&
                <TradeContract tradecontract_data={dashData.tradeContractDetails} />
              }
              {tab === 'Shipment Contract' &&
                <ShipmentContract shipmentcontract_data={dashData.shipmentDetails} />
              }
            </div>
          </main>
        </div>
      </div>

    </div>
  )
}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState
  }
}


export default connect(
  mapStateToProps,
  null
)(DashboardV4)
