import React, { useState } from "react";

const Pagination = ({ page, totalCount, onPageChange, perPage, refresh, setRefresh }) => {

  let startIndex = totalCount == 0 ? 0 : ((((page - 1) * (perPage || 10))) + 1)
  let lastIndex = startIndex + (perPage ? (perPage - 1) : 9)

  function handlePageChange(type) {
    if (type === "prev") {
      onPageChange(page - 1)
    }
    if (type === "next") {
      onPageChange(page + 1)
    }
    if (setRefresh) {
      setRefresh(refresh + 1)
    }
  }


  return (
    <>
      <div className="my-3">
        <div className="d-flex justify-content-end">
          {page > 1 ? (
            <img onClick={() => handlePageChange("prev")} src={"/assets/images/arrow-left.png"} alt="" className="cursor" />
          ) : null}
          <span className="PaginationFont">{page}/{parseInt(totalCount / perPage) + 1}</span>
          {totalCount > lastIndex ? (
            <img onClick={() => handlePageChange("next")} src={"/assets/images/arrow-right.png"} className="cursor" alt="" />
          ) : null}
        </div>
      </div>
    </>
  );
}
export default Pagination