import React, { useEffect, useState } from 'react';
import call from '../../service';
import { connect } from 'react-redux';
import { setShowCreateProfileAdminModal } from '../../store/actions/action';
import CreateProfileAdminModal from './createProfileModal'
import { ToastContainer } from 'react-toastify';
import EditProfile from '../userProfile/editProfile';
import commImageUrl from '../../utils/makeCommImageUrl'
import avatarUrl from '../../utils/makeAvatarUrl';
import Registration from '../registration/registration';
import toastDisplay from '../../utils/toastNotification';
import SideBarV2 from '../partial/sideBarV2';
import HeaderV2 from '../partial/headerV2';
import Filter from '../InvoiceDiscounting/components/Filter';
import NewTablev2 from '../../utils/newTablev2';
import Pagination from '../InvoiceDiscounting/contract/components/pagination';
import { userTypeList, userTypeList2 } from '../../utils/myFunctions';

const PAGE_CAPACITY = 7;

const MyNetwork = ({ userTokenDetails, channelPartnerUser, setShowCreateProfileAdminModal, showCreateProfileAdminModal, dispatch, navToggleState }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [showLoader, setshowLoader] = useState(true);
  const [userEmailEdit, setuserEmailEdit] = useState('');
  const [openEditProfile, setopenEditProfile] = useState(false);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [items, setItems] = useState([]);
  const [entryCountField, setEntryCountField] = useState('');
  const [userPlanQuota, setUserPlanQuota] = useState({});
  const [dbTypeData, setdbTypeData] = useState([]);
  const [filterData, setFilterData] = useState({})
  const [filter, setFilter] = useState({ resultPerPage: 10 })
  const [planList, setplanList] = useState([])
  const [searchKey, setsearchKey] = useState("")
  const [searchenable, setsearchenable] = useState(false)
  const [subUser, setSubUser] = useState({ modal: false, userData: {} });
  const [addMoreProfile, setAddMoreProfile] = useState({ modal: false, data: {} });
  const [errors, seterrors] = useState({});
  const [refresh, setrefresh] = useState(0)

  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const astrix = <span className="required-field text-danger">*</span>
  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects

  // useEffect(() => {

  //   //------------------------------------------------------------------
  //   //API Calls
  //   let last = count ? Math.ceil(count / filter.resultPerPage) : 0;

  //   if ((data[page] && data[page].length) && (page !== last)) {
  //     setPagination(count)
  //     setshowLoader(false)
  //   } else {
  //     let objectAPI = { "loggedUserType": userTypeId, "loggedUserId": userId, page, pageLimit: filter.resultPerPage }

  //     for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
  //       let filterName = Object.keys(filterData)[index]
  //       const element = filterData[filterName];
  //       if (element.isFilterActive) {
  //         if (element.type === "checkbox") {
  //           objectAPI[element.accordianId] = []
  //           element["data"].forEach((i) => {
  //             if (i.isChecked) {
  //               objectAPI[element.accordianId].push((element.accordianId === "searchUserType") ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
  //             }
  //           })
  //         }
  //         else if (element.type === "minMaxDate") {
  //           objectAPI[element.accordianId] = element["value"]
  //         }
  //       }
  //     }

  //     call('POST', 'getusersmanageprofile', objectAPI).then((result) => {
  //       console.log("result while querying users:", result);
  //       data[page] = result.data;
  //       setData(data);
  //       setCount(result.count)
  //       setshowLoader(false)
  //       if (result.count) {
  //         setPagination(result.count)
  //         setsearchenable(true)
  //       } else {
  //         setsearchenable(false)
  //       }
  //     }).catch((e) => {
  //       console.log("Error while querying users:", e);
  //     })
  //   }

  //   call('get', 'v1/plan/quota').then((result) => {
  //     setUserPlanQuota(result)
  //   }).catch((e) => {
  //     console.log("Error while  getUserCurrentPlanQuota MyNetwork:-->", e);
  //   })

  //   //------------------------------------------------------------------
  // }, [showCreateProfileAdminModal.info.refresh, page, filterData]);

  useEffect(() => {
    // Create filter data & set it 
    let filterData = {}
    filterData["User Type"] = {
      "accordianId": "searchUserType",
      type: "checkbox",
      data: userTypeList2,
      labelName: "name"
    }
    setFilterData(filterData)
  }, [])

  useEffect(() => {
    setshowLoader(true)
    call('get', 'v1/plan/quota').then((result) => {
      setUserPlanQuota(result)
    }).catch((e) => {
      console.log("Error while  getUserCurrentPlanQuota MyNetwork:-->", e);
    })
    let objectAPI = { "loggedUserType": userTypeId, "loggedUserId": userId, page, pageLimit: filter.resultPerPage, search: filter.search }

    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            console.log("elementtttttttttttttttttttttt", i);
            if (i.isChecked) {
              objectAPI[element.accordianId].push((element.accordianId === "searchUserType") ? i["id"] : `'${i[element["labelName"]]}'`)
            }
          })
        }
        else if (element.type === "minMaxDate") {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }
    //------------------------------------------------------------------
    //API Calls
    call('POST', 'getusersmanageprofile', objectAPI).then((result) => {
      console.log("result while querying users:", result);
      data[page] = result.data;
      setData(data);
      setCount(result.count)
      setshowLoader(false)
      if (result.count) {
        setPagination(result.count)
        setsearchenable(true)
      } else {
        setsearchenable(false)
      }

    }).catch((e) => {
      console.log("Error while querying users:", e);
      setshowLoader(false)

    })

    //------------------------------------------------------------------
  }, [filterData, page, refresh]);

  useEffect(() => {
    call('GET', 'getuserstypelist').then((result) => {
      setdbTypeData(result)
    }).catch((e) => {
      console.log('error in getuserstypelist', e);
    })

    call('get', 'getplanslist').then((result) => {
      console.log("API result gettraderplans:-->", result);
      setplanList(result)
      setshowLoader(false)
    }).catch((e) => {
      setshowLoader(false)
      console.log("Error while querying gettraderplans:", e);
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.value }));
  };

  //---------------------------------------------------------------------------------------------------------------------
  // Pagination

  function setPagination(count) {
    const last = Math.ceil(count / filter.resultPerPage);
    let items = [];

    items.push(<Pagination.Prev onClick={() => { setshowLoader(true); setPage(1) }} />)
    for (let number = Math.max(1, page - 1); number <= Math.min(page + 1, last); number++) {

      items.push(
        <Pagination.Item key={number} active={number === page.currentPage} onClick={() => { if (number !== page) setshowLoader(true); setPage(number) }}>
          {number}
        </Pagination.Item>,
      );
    }
    items.push(<Pagination.Next onClick={() => { setshowLoader(true); setPage(last) }} />)

    let startingEntry = ((page - 1) * filter.resultPerPage) + 1;
    let endingEntry = page !== last ? (page * filter.resultPerPage) : count;
    setItems(items);
    setEntryCountField(<p className='col-md-6' style={{ flexGrow: true }}>Showing {startingEntry + '-' + endingEntry + ' of ' + count} Entities</p>);
  }

  // Pagination End
  //---------------------------------------------------------------------------------------------------------------------

  function getprofileData() {

    const profileData = data.length && data[page] && data[page].length ? data[page].map((data) => {
      let typeOfData = [];
      if (data.domain_key) {
        typeOfData = data.domain_key.split(',');
      }
      return (<div className="col-md-3 col-md-3 col-sm-6 col-xs-12">
        <div className={"card mb-3 profile-view " + (data.status / 1 === 1 ? "shadow-sm-success" : data.status / 1 === 2 ? "shadow-sm-danger" : data.status / 1 === 0 ? "shadow-sm-warn" : "shadow-sm")}>

          {(aclMap.mng_subusers_profile && aclMap.mng_subusers_profile.edit_user_profile && aclMap.mng_subusers_profile.edit_user_profile.isExist) && (data.userDetails && data.userDetails.email_id) &&
            <>  <button className="edit-icon" onClick={() => { setuserEmailEdit(data.userDetails.email_id); setopenEditProfile(true) }}>
              <i class="fas fa-user-edit text-primary"></i>
            </button>
              <button className={"display-icon rounded-right text-light pl-2 pr-2 " + (data.status / 1 === 1 ? "bg-success" : data.status / 1 === 2 ? "bg-danger" : data.status / 1 === 0 ? "bg-warning" : "")}>
                {data.status / 1 === 1 ? " APPROVED" : data.status / 1 === 2 ? "REJECTED" : data.status / 1 === 0 ? "PENDING" : "NA"}
              </button>
            </>}

          <div className="card-header">
            <img src={avatarUrl(data.userDetails && data.userDetails.user_avatar ? data.userDetails.user_avatar : "default.png")} />
            <h3>{data.userDetails !== null && data.userDetails !== undefined ? data.userDetails.contact_person : ""}</h3>
            <p className="card-text">{data.userDetails !== null && data.userDetails !== undefined ? data.userDetails.email_id : ""}</p>
          </div>
          {data.type_id === 4 ?
            <div className="card-body">
              <h4>{data.userType.usertype_pretty_name}{typeOfData.length > 1 ? `  + ${typeOfData.length - 1}  ` : ''}</h4>
              {data.status == 1 ? <button type='button' className="btn btn-primary btn-sm" onClick={() => { setAddMoreProfile({ modal: true, data: data }) }} >Add On</button> : ''}
              {data.type_id != '9' && <button type="button" className="btn btn-primary float-right btn-sm" onClick={() => setSubUser({ modal: true, userData: data })}>Add Sub-User</button>}
              <ul>
                <li>{data.commodities !== undefined && data.commodities !== null ? data.commodities.map((commData) => {
                  return (
                    <img src={commImageUrl(commData.commodityInfo.filename)} />)
                }) : ""}
                </li>
              </ul>
            </div>
            : <div className="card-body">
              <h4>{data.userType.usertype_pretty_name}{typeOfData.length > 1 ? `  + ${typeOfData.length - 1}  ` : ''}</h4>
              {data.status == 1 ? <button type='button' className="btn btn-primary btn-sm" onClick={() => { setAddMoreProfile({ modal: true, data: data }) }} >Add On</button> : ''}
              {data.type_id != '9' && <button type="button" className="btn btn-primary float-right btn-sm" onClick={() => setSubUser({ modal: true, userData: data })}>Add Sub-User</button>}
              <ul>
                <li>
                  <img src={"../assets/images/onbording/usertype/" + `${data.type_id}.png`} />
                </li>
              </ul>
            </div>
          }
        </div>
      </div>)
    }) : ''
    return profileData
  }

  function addNewUser() {
    // if (userPlanQuota.partners && userPlanQuota.partners.enable && ((userPlanQuota.partners.limitLeft / 1) > 0 || (userPlanQuota.partners.limitLeft / 1) === -1)) {
    setShowCreateProfileAdminModal(true, {})
    // } else {
    //   if (userPlanQuota.trades)
    //     toastDisplay("You have already used " + userPlanQuota.partners.limitUsed + " out of " + userPlanQuota.partners.totalLimit + " " + userPlanQuota.partners.desc + " Quota", "warn")
    //   else
    //     toastDisplay("Plan Quota details are not available", "warn")
    // }
  }

  const setChannelPartner = (status) => {
    if (status === true) {
      call('POST', 'setUserAsChannelPartner', { "userEmail": userEmailEdit }).then((result) => {
        toastDisplay('Successfully Accepted', 'success');
      }).catch((e) => {
        console.log('error in getuserstypelist', e);
      })
    }
    else {
      toastDisplay('Successfully Rejected', 'success');
    }
  }

  const filterState = (event) => {
    setFilterData({
      ...filterData,
      [event.target.name]: event.target.value
    })
  };

  const handleKeyPress = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      setFilterData({
        ...filterData,
        "search": searchKey
      })
    }
  };

  const hanleDocChecks = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.checked }));
  }

  const reset = () => {
    setFilterData({ searchUserType: "", search: "" })
    setsearchKey("")
  }

  const setMultipleDomain = (userdata) => {
    setshowLoader(true);
    data.adminProfile = true;
    data.typeId = data.multipleDomain;
    data.email = userdata.userDetails.email_id;
    call('POST', 'setUserStatus', data).then((result) => {
      setshowLoader(false);
      toastDisplay(result.message.desc, "success");
      setrefresh(refresh + 1)
    }).catch(err => {
      setshowLoader(false);
      console.log("conn:", err)
      toastDisplay(err, "error");
    })
  }
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Html body
  return (
    <div className="">
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="MyNetwork" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Manage Network > My Network"}
              userTokenDetails={userTokenDetails} />

            {/* <div className="d-flex mt-3 mb-3">
              <div className="ml-auto">
                <ul className="range-slider">
                  <li>
                    <div className="d-md-flex">

                      <div className="mr-1 ">
                        <select className="form-control" name="searchUserType" value={filterData.searchUserType} onChange={filterState}>
                          <option value="" selected>All users</option>
                          {(dbTypeData.length) ? dbTypeData.map((item) => {
                            if (item.id > 2) {
                              return (<option value={item.id}>{item.usertype_pretty_name}</option>)
                            }
                          }) : ''
                          }
                        </select>
                      </div>

                      {(userTypeId / 1 === 1) && (channelPartnerUser && channelPartnerUser !== true) &&
                        <div className="">
                          <select className="form-control" name="subType" value={filterData.subType} onChange={filterState}>
                            <option value="" selected>All Sub. Type</option>
                            <option value="1">Free Plans</option>
                            {(planList.length) ? planList.map((item) => {
                              return (<option value={item.id}>{item.plan_name}</option>)
                            }) : ''
                            }
                          </select>
                        </div>}

                      <div className=" ml-1 position-relative input-margin">
                        <input placeholder="Search" type="text" name="search" className="form-control" value={searchKey} onChange={(event) => { setsearchKey(event.target.value) }} onKeyDown={handleKeyPress} />
                        <button type="button" onClick={() => {
                          setFilterData({
                            ...filterData,
                            "search": searchKey
                          })
                        }}><i class="fas fa-search fa-lg"></i></button>
                      </div>
                    </div>
                  </li>
                  <li>
                  </li>
                  <li>
                  </li>
                  <li><input type="button" value="Reset" className="btn btn-danger btn-sm" onClick={() => reset()} /></li>
                </ul>
              </div>
            </div> */}

            <div className='filter-div position-relative'>
              <Filter isAdditionalButton={aclMap.mng_subusers_profile}
                filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setrefresh} >
                <button style={{ width: '11rem', paddingTop: '0.3rem', paddingBottom: '0.3rem' }} type="button" className="new-btn text-white d-flex justify-content-center align-items-center"
                  onClick={() => {
                    if (userPlanQuota.partners?.limitLeft) {
                      addNewUser()
                    }
                    else {
                      toastDisplay("You do not have enough quota to add partners. Kindly purchase a plan to proceed.", "info")
                    }
                  }}
                  id="nextid">Add a New User
                  {userPlanQuota.partners &&
                    <span class="fa-stack" style={{ "vertical-align": "top", marginLeft: '5px' }}>
                      <i class="far fa-circle fa-stack-2x"></i>
                      <i class={(userPlanQuota.partners.limitLeft / 1) === -1 ? "fas fa-infinity fa-stack-1x" : ""}>
                        {(userPlanQuota.partners.limitLeft / 1) === -1 ? "" : userPlanQuota.partners.limitLeft}
                      </i>
                    </span>}
                </button>
              </Filter>
            </div>


            <div className="d-flex mt-3 custom-tab-content m-0 p-0">
              {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
              <div className="w-100">

                {(aclMap.mng_subusers_profile && aclMap.mng_subusers_profile.get_users_profiles && aclMap.mng_subusers_profile.get_users_profiles.isExist) &&
                  <>
                    <NewTablev2
                      columns={[{ subColumns: "Profile Image", subColumnStyle: { width: '8%' } },
                      { subColumns: "Company Name", subColumnStyle: { width: '20%' } },
                      { subColumns: "Contact Person", subColumnStyle: { width: '20%' } },
                      { subColumns: "Email Id", subColumnStyle: { width: '15%' } },
                      { subColumns: "User Type", subColumnStyle: { width: '10%' } },
                      { subColumns: "Action", subColumnStyle: { width: '15%' } }
                      ]}
                    >
                      {data.length && data[page] && data[page].length ? data[page].map((data) => {
                        let typeOfData = [];
                        if (data.domain_key) {
                          typeOfData = data.domain_key.split(',');
                        }
                        return (
                          <tr>
                            <td><img className='ml-3' height={"30px"} src={avatarUrl(data.userDetails && data.userDetails.user_avatar ? data.userDetails.user_avatar : "default.png")} /></td>
                            <td><label className='font-size-12 font-wt-500 text-break' >{data.userDetails !== null && data.userDetails !== undefined ? data.userDetails.company_name : ""}</label></td>
                            <td><label className='font-size-12 font-wt-500 text-break' >{data.userDetails !== null && data.userDetails !== undefined ? data.userDetails.contact_person : ""}</label></td>
                            <td><label className='font-size-12 font-wt-500 text-break'>{data.userDetails !== null && data.userDetails !== undefined ? data.userDetails.email_id : ""}</label></td>
                            <td><label className='font-size-12 font-wt-500 text-break'>{data.userType.usertype_pretty_name}{typeOfData.length > 1 ? `  + ${typeOfData.length - 1}  ` : ''}</label></td>
                            <td>
                              <div>
                                <button type="button" className="btn bg-5CB8D3 text-white float-right btn-sm w-100" onClick={() => setSubUser({ modal: true, userData: data })}>Add Sub-User</button>
                              </div>
                            </td>
                          </tr>
                        )
                      }) : null}
                    </NewTablev2>
                    {/* {getprofileData()} */}
                  </>}
              </div>
            </div>

            {(showCreateProfileAdminModal.modal && (aclMap.mng_subusers_profile)) &&
              <CreateProfileAdminModal userTokenDetails={userTokenDetails} channelPartnerUser={channelPartnerUser} />}

            {(openEditProfile && channelPartnerUser !== true && (aclMap.mng_subusers_profile && aclMap.mng_subusers_profile.edit_user_profile && aclMap.mng_subusers_profile.edit_user_profile.isExist)) &&
              <div className={"modal" + (openEditProfile ? " show" : "")} id="editprofile">
                <div className="modal-dialog modal-xl border-inner" id="parent_class">
                  <div className="modal-content">
                    <div className="modal-header primary">
                      <h4 className="modal-title text-white">Edit User Profile Details ({userEmailEdit}) </h4>
                      <button type="button" className="close" onClick={() => { setopenEditProfile(false) }}>×</button>
                    </div>
                    <div className="modal-body calc-inner-modal">
                      <EditProfile userTokenDetails={userTokenDetails} userEmail_id={userEmailEdit} isAdmin={true} />
                    </div>
                    <div className="modal-footer primary">
                      <button type="button" className="btn btn-secondary btn-sm" onClick={() => setopenEditProfile(false)}>Close</button>
                      {/* <Button variant="primary" onClick={handleClose}>Save Changes</Button> */}
                    </div>
                  </div>
                </div>
              </div>}

            {channelPartnerUser === true && openEditProfile &&
              <div className={"modal" + (openEditProfile ? " show" : "")} id="editprofile">
                <div className="modal-dialog modal-xl border-inner" id="parent_class">
                  <div className="modal-content">
                    <div className="modal-header primary">
                      <h4 className="modal-title text-white">Edit User Profile Details ({userEmailEdit}) </h4>
                      <button type="button" className="close" onClick={() => { setopenEditProfile(false) }}>×</button>
                    </div>
                    <div className="modal-body calc-inner-modal">
                      <EditProfile userTokenDetails={userTokenDetails} userEmail_id={userEmailEdit} isAdmin={true} />
                    </div>
                    <div className="modal-footer primary">
                      {channelPartnerUser === true &&
                        <>
                          <button type="button" className="btn btn-primary btn-sm" onClick={() => setChannelPartner(true)} >Accept</button>
                          <button type="button" className="btn btn-danger btn-sm" onClick={() => setChannelPartner(true)} >Reject</button>
                        </>}
                      <button type="button" className="btn btn-secondary btn-sm" onClick={() => setopenEditProfile(false)}>Close</button>
                    </div>
                  </div>
                </div>
              </div>
            }

            {/* <hr /> */}
            {(items.length !== 0 && searchenable) &&
              <div className="">
                <Pagination page={page} totalCount={count} onPageChange={(p) => setPage(p)} refresh={refresh} setRefresh={setrefresh} perPage={filter.resultPerPage || 0} />
                {/* {entryCountField}
                <Pagination className='col-md-6'>{items}</Pagination> */}
                <br />
              </div>
            }

            {subUser.modal &&
              <div className={"modal" + (subUser.modal ? " show d-block " : "")}>
                <div className="modal-dialog modal-lg border-inner" id="parent_class">
                  <div className="modal-content">
                    <div className="modal-header primary">
                      <h4 className="modal-title text-white font-size-16 font-wt-600">Create Profile</h4>
                      <button type="button" className="close" onClick={() => { setSubUser({ modal: false, userData: {} }) }}>×</button>
                    </div>
                    <div className="modal-body calc-modal overflow-auto m-0 p-0">
                      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
                      <Registration userTokenDetails={userTokenDetails} employeeData={{ type: 9, step: 2, manualUserId: subUser.userData.id, manualUserTypeId: subUser.userData.type_id, manualUserTechTypeId: subUser.userData.tech_type_id }} />
                    </div>
                    <div className="modal-footer primary">

                    </div>
                  </div>
                </div>
              </div>
            }

            {addMoreProfile.modal &&
              <div className={"modal show"} id="registration" >
                <div className="modal-dialog modal-lg border-inner" id="parent_class">
                  <div className="modal-content">
                    <div className="modal-header primary">
                      <h4 className="modal-title text-white">Add More Category</h4>
                      <button type="button" className="close" onClick={() => { setAddMoreProfile({ modal: false, data: {} }) }} >×</button>
                    </div>
                    <div className="modal-body">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="row">
                              <div className="form-group col-md-12">
                                <select className={" form-control" + (!errors.multipleDomain ? '' : ' border-danger')} name="multipleDomain" value={data.multipleDomain} onChange={handleChange} >
                                  <option value="" selected>Select Category</option>
                                  {(dbTypeData.length) && dbTypeData.map((item, key) => {
                                    if (key > 1) {
                                      return (<option value={item.id}>{item.usertype_pretty_name}</option>)
                                    }
                                  })
                                  }
                                </select>
                                {errors.multipleDomain && <p className="text-danger error-contract">{errors.multipleDomain}</p>}
                              </div>
                            </div>
                          </div>

                          {data.multipleDomain == 20 &&
                            <>
                              <div className=" col-md-12 row mt-2">
                                <div className=" col-md-6">
                                  <label>Select User Type of Channel Partner</label>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="tradeTechType" id="tradeTechType" onChange={hanleDocChecks} checked={data.tradeTechType} />
                                    <label className="form-check-label" for="tradeTechType">Channel Partner Trade-Tech</label>
                                  </div>
                                </div>
                              </div>

                              <div className=" col-md-12 row mt-2">
                                <div className=" col-md-6">
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="finTechType" id="finTechType" onChange={hanleDocChecks} checked={data.finTechType} />
                                    <label className="form-check-label" for="finTechType">Channel Partner Fin-Tech</label>
                                  </div>
                                </div>
                              </div>

                              <div className=" col-md-12 row mt-2">
                                <div className=" col-md-6">
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="logiTechType" id="logiTechType" onChange={hanleDocChecks} checked={data.logiTechType} />
                                    <label className="form-check-label" for="logiTechType">Channel Partner Logi-Tech</label>
                                  </div>
                                </div>
                              </div>

                              <div className=" col-md-12 row mt-2">
                                <div className=" col-md-6">
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="insureTechType" id="insureTechType" onChange={hanleDocChecks} checked={data.insureTechType} />
                                    <label className="form-check-label" for="insureTechType">Channel Partner Insure-Tech</label>
                                  </div>
                                </div>
                              </div>
                            </>}
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer primary">
                      <button type="button" className="btn btn-secondary btn-sm" onClick={() => setAddMoreProfile({ modal: false, data: {} })} >Cancel</button>
                      <button type="button" className="btn btn-primary btn-sm" onClick={() => setMultipleDomain(addMoreProfile.data)} disabled={(data.multipleDomain == 20 && (data.tradeTechType || data.finTechType || data.logiTechType || data.insureTechType)) ? false : (data.multipleDomain && data.multipleDomain != 20) ? false : true} >Submit</button>
                    </div>

                  </div>
                </div>
              </div>}


          </main>
        </div>

      </div>

    </div >
  )
};

const mapStateToProps = state => {
  return {
    showCreateProfileAdminModal: state.showCreateProfileAdminModal,
    navToggleState: state.navToggleState
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setShowCreateProfileAdminModal: (flag, data) => { dispatch(setShowCreateProfileAdminModal({ modal: flag, info: data })) },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyNetwork)