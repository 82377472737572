import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import call from "../../../service";
import MeetingDescriptionModal from "./meetingDescriptionModal";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

const events = [
  {
    start: moment().toDate(),
    end: moment()
      .add(1, "hour")
      .toDate(),
    title: "Some title"
  }
]
const scheduletimedata = [
  "12:00 AM", "01:00 AM", "02:00 AM", "03:00 AM", "04:00 AM", "05:00 AM", "06:00 AM", "07:00 AM", "08:00 AM", "09:00 AM", "10:00 AM", "11:00 AM", "12:00 PM", "01:00 PM", "02:00 PM", "03:00 PM", "04:00 PM", "05:00 PM", "06:00 PM",
  "07:00 PM", "08:00 PM", "09:00 PM", "10:00 PM", "11:00 PM",
];
const CustomEvent = ({ event }) => {
  return <div className="d-flex">
    <div className="col-md-8 p-0">
      <div className="d-flex flex-row gap-2 align-items-center">
        <label className="m-0">{event.title}</label>
        <img src={"/assets/images/chat_video.png"} height={10} width={10} className="m-0" />
      </div>
      <div className="d-flex flex-row gap-2 align-items-center">
        <label className="m-0">Participants:</label>
        <div className="d-flex">
          {event.participantsdata.slice(1, 2).map(item => {
            return <div className="text-color-68cae733 p-0 ml-2 py-1">
              <label className="m-0">{item.company_name}</label>
            </div>
          })}
          {event.participantsdata.length > 2 &&
            <div className="text-color-68cae733 p-0 ml-2 py-1">
              <label className="m-0">{"+" + event.participantsdata.length - 1 + " more"}</label>
            </div>
          }
        </div>
      </div>
    </div>
    <div className="col-md-9 p-0">
      <div className="d-flex flex-row gap-2 align-items-center mt-1">
        <label className="m-0">{"Time: "}</label>
        <label className="m-0">{moment(event.meetingstartTime).format("hh:mm A") + "-" + moment(event.meetingEndTime).format("hh:mm A")}</label>
      </div>
      <div className="d-flex flex-row gap-2 align-items-center">
        <label className="m-0">{"Duration: "}</label>
        <label className="m-0">{`${event.callduration}`}</label>
      </div>
    </div>

  </div>

};


const ScheduleList = ({ userId, meetingDate, setShowLoader, data, setData, onMeetPress }) => {

  const [schedule, setSchedule] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({})
  const [calendarList, setCalendarList] = useState([])
  const getCalendarList = () => {
    setShowLoader(true)
    let reqObj = {
      userId,
      date: moment(meetingDate).format('YYYY-MM-DD')
    }
    call('POST', 'getCalendarList', reqObj).then(result => {
      setShowLoader(false)
      setCalendarList(formatCalendarEvents(result))
    }).catch(e => {
      setShowLoader(false)

    })
  }
  const formatCalendarEvents = (data) => {
    let events = []
    data.forEach(item => {
      events.push({
        ...item,
        start: moment(item.meetingstartTime).toDate(),
        end: moment(item.meetingEndTime).toDate(),
        title: item.meetingTitle
      })
    })
    return events
  }
  useEffect(() => {
    getCalendarList()
  }, [meetingDate])
  console.log('calendarList', calendarList)
  return (
    <>
      <div className='card-header bg-95E7FF p-4 rounded-0 chatlist d-flex justify-content-between '>
        <div className="w-50 mx-auto d-flex justify-content-between align-items-center">
          <div><img src={"/assets/images/schedule-list-vector.png"} alt="" className="cursor" onClick={() => {
            const prevDate = new Date(meetingDate)
            prevDate.setDate(prevDate.getDate() - 1)
            setData({
              ...data,
              meetingDate: prevDate
            })
          }} /></div>
          <p className="mb-0 font-wt-600">{moment(meetingDate).format('DD MMMM , YYYY')}</p>
          <div><img src={"/assets/images/schedule-list-vector-right.png"} alt="" className="cursor" onClick={() => {
            const prevDate = new Date(meetingDate)
            prevDate.setDate(prevDate.getDate() + 1)
            setData({
              ...data,
              meetingDate: prevDate
            })
          }} /></div>
        </div>
      </div>
      <div className=" d-flex justify-content-between schedulescroll">
        {/* <div className="w-17 ">
          {scheduletimedata.map((key, index) => {
            return (
              <div className="border-end scheduletime">
                <p className="text-secondary font-size-14 text-center mb-0">{key}</p>
              </div>
            );
          })}
        </div>
        <div className="w-90 ">
          {scheduletimedata.map((key, index) => {
            return (
              <div className="schedulemeettime2 "></div>
            );
          })}
        </div> */}
        <Calendar
          localizer={localizer}
          defaultDate={meetingDate}
          views={['day']}
          eventPropGetter={(event) => {
            return {
              style: {
                backgroundColor: '#68cae71a',
                color: 'black',
                borderRadius: '10px',
                borderTopWidth: 0,
                borderLeftWidth: 0,
                borderRightWidth: 0,
                borderBottomWidth: 1,
                borderBottomColor: '#B9EFFF',
              }
            };
          }}
          defaultView="day"
          events={calendarList}
          components={{
            toolbar: () => null,
            header: () => null,
            resourceHeader: () => null,
            day: {
              header: () => null,
              event: CustomEvent
            }
          }}
          style={{
            width: '100%'
          }}
          date={meetingDate}
          titleAccessor={() => null}
          onSelectEvent={(event) => {
            console.log('on clicked', event)
            setSchedule(true)
            setSelectedEvent(event)
          }}
        //onNavigate={() => }
        />
      </div>
      {schedule && <MeetingDescriptionModal schedule={schedule} setSchedule={setSchedule} data={selectedEvent} onMeetPress={onMeetPress} />}
    </>
  );
}
export default ScheduleList;