import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import call from '../../service';
import { FileInput } from '../../utils/FileInput';
import avatarUrl from '../../utils/makeAvatarUrl';
import MultipleSelect from '../../utils/MultipleSelect';
import { astrix, ClearCache, convertImageToPdf, dataURItoBlob, GetCache, getDocDetails, most_used_currencies, SetCache, toolTip } from '../../utils/myFunctions';
import { InputWithSelect, NewInput, NewSelect } from '../../utils/newInput';
import { NewTable } from '../../utils/newTable';
import { StarRating } from '../../utils/starRating';
import toastDisplay from '../../utils/toastNotification';
import Header from '../partial/header';
import HeaderV2 from '../partial/headerV2';
import Sidebar, { traderTypeIds } from '../partial/sidebar';
import SideBarV2 from '../partial/sideBarV2';
import { PopupMessage } from '../popupMessage';
import config from '../../config.json';
import moment from 'moment';
import TutorialPopup, { TutorialVideoPopup } from '../tutorialPopup';
import ChatBoxPopUp2 from '../chatRoom/components/ChatBoxPopUp2';
import Filter from '../InvoiceDiscounting/components/Filter';
import NewTablev2 from '../../utils/newTablev2';
import Pagination from '../InvoiceDiscounting/contract/components/pagination';
import { bgQuotesColumn, notificationListColumn, shipmentBookingQuotesColumn, wcQuotesColumn } from '../Dashboard/TableJson';
import { Action } from '../myCounterPartComp/action';

const finHighlights = [
  { name: 'Selected', color: '#5CB8D3' },
  { name: 'Quote received', color: '#2ECC71' },
  { name: 'Rejected', color: '#FE4141' }
]

let notificationServices = [
  { name: 'Workorder Contracts', alt: 'workOrderContracts' },
  { name: 'Shipment Contracts', alt: 'shipmentContracts' },
  { name: 'Plan Purchase', alt: 'planPurchase' }
]

const NotificationSettings = ({ userTokenDetails, navToggleState }) => {

  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [tableData, setTableData] = useState([])

  const [showAddBuyerForm, toggleAddBuyerForm] = useState(false)
  const [tab, setTab] = useState(0)
  const [data, setData] = useState({ emailId: userEmail, emailNotificatioOn: true, workOrderContracts: true, shipmentContracts: true, planPurchase: true })
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)

  const [dunsData, setDunsData] = useState([])
  const [hsnCodes, setHsnCodes] = useState([])
  const [countrys, setCountrys] = useState([])
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [financierData, setFinancierData] = useState([]);
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10, search: serarchParam ? serarchParam : '' })
  const [lcTutorial, toggleLCTutorial] = useState(localStorage.getItem("lcTutorialStep") == 1)
  const [tutorialStage, setTutorialStage] = useState(0)
  const [showTutorialVideo, toggleTutorialVideo] = useState({ show: false, link: null })
  const [filterData, setFilterData] = useState({})

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [action, setAction] = useState({ show: false, index: null })



  useEffect(() => {
    setshowLoader(true)
    call('POST', 'emailNotificationConfiguration', { userId }).then(res => {
      console.log("insideThenemailNotificationConfiguration", res);
      setshowLoader(false)
      if (res?.length) {
        let tempData = { emailId: res[0]["email"] || userEmail, emailNotificatioOn: !res[0]["stopEmail"], emailNotificatioOff: res[0]["stopEmail"] }
        let servicesToTurnOff = res[0]["stopForServices"]?.split(",") || []
        for (let index = 0; index < notificationServices.length; index++) {
          let element = notificationServices[index];
          if (servicesToTurnOff.includes(element.alt)) {
            tempData[element.alt] = false
          }
          else {
            tempData[element.alt] = true
          }
        }
        setData({ ...data, ...tempData })
      }
    }).catch(err => { })
  }, [])

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('shipmentBookingId', selectedChat.shipmentBookingId)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))
    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  async function handleChange(event) {
    event.persist()
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }

  useEffect(() => {
    // updateToggleStatus()
  }, [data])

  // function updateToggleStatus() {
  //   let shouldMainToggleTurnOn = false
  //   for (let index = 0; index < notificationServices.length; index++) {
  //     let element = notificationServices[index];
  //     if (data[element.alt]) {
  //       shouldMainToggleTurnOn = true
  //     }
  //   }
  //   if (shouldMainToggleTurnOn) {
  //     setData({ ...data, emailNotificatioOn: true, emailNotificatioOff: false })
  //   }
  // }

  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {selectedChat.isChatOpen &&
        <div className="chatboxDivFixed">
          <ChatBoxPopUp2
            chatList={chatList}
            user_avatar={selectedChat.logo}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            parties={selectedChat.parties}
            userTokenDetails={userTokenDetails}
            onChatSend={(file, parties) => sendChatMsg(file, parties)}
            message={message}
            setTextMsg={setTextMsg}
            reloadChatList={() => getChatDetails({
              chat_room_id: selectedChat.chatRoomId,
              loggedInUser: userId
            })}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false,
                logo: ''
              })
              setRefresh(refresh + 1)
            }}
          />
        </div>
      }
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="SettingsNotification" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Settings > Notification Settings"}
              userTokenDetails={userTokenDetails} />
            <div className='mt-4' style={{}} >
              <label className='font-size-14 font-wt-600' >Enter Email address on which you want to receive all your emails</label>
              <div className='my-3 w-25' >
                <NewInput type={"email"} name={"emailId"} value={data.emailId} onChange={handleChange} />
              </div>
              <label className='font-size-14 font-wt-600 mt-3' >Email Notifications</label>
              <div className='my-3 w-25' >
                <div className='d-flex pl-2 align-items-center col-auto'>
                  <label className={!data[`emailNotificatioOn`] ? "font-wt-500 font-size-14" :
                    "font-wt-800 font-size-14 color-2ECC71"} for={`emailNotificatioOn`}>On</label>
                  <img
                    style={{ height: '1.5rem' }}
                    className="cursor mx-3 mb-1"
                    onClick={() => setData({ ...data, [`emailNotificatioOn`]: !data[`emailNotificatioOn`], [`emailNotificatioOff`]: !data[`emailNotificatioOff`] })}
                    src={data[`emailNotificatioOn`] ? "assets/images/turn_off_green_switch.svg" : "assets/images/turn_on_green_switch.svg"}
                  />
                  <label className={!data[`emailNotificatioOff`] ? "font-wt-500 font-size-14" :
                    "font-wt-800 font-size-14 color-2ECC71"} for={`emailNotificatioOff`}>Off</label>
                  <img src={"assets/images/warning.png"} className="totalChargesLabel ml-2 mb-2" />
                  <div className="otherChargePopup p-3">
                    <div>
                      <label className="font-size-13 font-wt-600" >If you turn off this option, all types of email notifications from TradeReboot will be disabled.</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-35' >
                <NewTablev2
                  columns={[
                    { subColumns: 'Service Name', filter: false, thwidth: 'w-60' },
                    { subColumns: 'On/Off', filter: false, thwidth: 'w-40' }]}
                >
                  {notificationServices.map((i, j) => {
                    return (
                      <tr>
                        <td><label className='font-size-13 font-wt-600 text-break' >{i.name}</label></td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <label className={!data[i.alt] ? "font-wt-500 font-size-14" :
                              "font-wt-800 font-size-14 color-2ECC71"} for={i.alt}>On</label>
                            <img
                              style={{ height: '1.5rem' }}
                              className="cursor mx-3 mb-1"
                              onClick={() => setData({ ...data, [i.alt]: !data[i.alt] })}
                              src={data[i.alt] ? "assets/images/turn_off_green_switch.svg" : "assets/images/turn_on_green_switch.svg"}
                            />
                            <label className={data[i.alt] ? "font-wt-500 font-size-14" :
                              "font-wt-800 font-size-14 color-2ECC71"} for={i.alt}>Off</label>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </NewTablev2>
              </div>
              <div className='my-4 w-15' >
                <button type="button"
                  onClick={async () => {
                    if (!data.emailId) {
                      return toastDisplay('Add email id to save', "info")
                    }
                    else {
                      let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                      if (!emailRegex.test(data.emailId)) {
                        return toastDisplay('Enter valid email id', "info")
                      }
                    }
                    setshowLoader(true)
                    let servicesToTurnOff = []
                    for (let index = 0; index < notificationServices.length; index++) {
                      let element = notificationServices[index];
                      if (!data[element.alt]) {
                        servicesToTurnOff.push(element.alt)
                      }
                    }
                    await call('POST', 'saveEmailNotificationConfiguration', {
                      userId, email: data.emailId, stopEmail: !data.emailNotificatioOn ? 1 : null,
                      servicesToTurnOff: servicesToTurnOff.join(",")
                    })
                    setshowLoader(false)
                    toastDisplay("Settings updated", "success")
                  }}
                  className={` new-btn w-100 py-2 px-2  text-white`}>
                  {"Save Settings"}
                </button>
              </div>

            </div>
          </main>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(NotificationSettings)
