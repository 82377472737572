import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import call from "../../../service";
import HeaderV2 from "../../partial/headerV2";
import SideBarV2 from "../../partial/sideBarV2";
import TutorialPopup from "../../tutorialPopup";
import PricingCard from "./pricingCard";
import toastDisplay from "../../../utils/toastNotification";

const Pricing = ({ userTokenDetails, navToggleState }) => {
  const userData = localStorage.getItem("UserDetails")
  let userToken = userTokenDetails
  if (userTokenDetails.type_id === 1) {
    userToken = userData ? JSON.parse(userData) : userTokenDetails
  }
  const userTypeId = userToken.type_id ? userToken.type_id : null
  const userEmail = userToken.email ? userToken.email : null
  const userId = userToken.user_id ? userToken.user_id : null
  const [tab, setTab] = useState("Invoice");
  const [plandata, setplandata] = useState([])
  const [currentPlan, setCurrentPlan] = useState({})
  const [showTutorial, toggleShowTutorial] = useState(localStorage.getItem("walletTutorialStep") == 1)
  const [tutorialSlide, setTutorialSlide] = useState(0)
  const [priceKey, setPriceKey] = useState('plan_amount_usd')

  const getPlans = () => {
    let reqBody = {
      userTypeId,
      plan_type: tab
    }
    call('POST', 'getUserPlans', reqBody).then(result => {
      console.log('success in getUserPlans', result)
      setplandata(result)
    }).catch(e => {
      console.log('error in getUserPlans', e)
    })
  }
  useEffect(() => {
    getPlans()
    getUserCurrentPlan()
  }, [tab])

  const getUserCurrentPlan = () => {
    call('POST', 'getUserCurrentPlan', { userId }).then(result => {
      console.log('success in getUserCurrentPlan', result)
      setCurrentPlan(result)
    }).catch(e => {
      console.log('error in getUserCurrentPlan', e)
    })
  }
  console.log('CurrentPlandataaaa', currentPlan)
  return (
    <>
      <TutorialPopup show={showTutorial} featureName={"Wallet"} positioning={tutorialSlide == 0 ? {
        "top": "14rem",
        "right": "0rem"
      } : {
        "top": "-1rem",
        left: '17%'
      }}
        children={<label className='font-size-14 font-wt-600'>
          {tutorialSlide == 0 ? 'Select a finace type you mostly deal in.' :
            'Select a plan as per your requirement & click on "Choose plan" button'}</label>}
        showBack={tutorialSlide == 1}
        onBack={() => setTutorialSlide(0)}
        arrowPositioning={tutorialSlide == 0 ? {
          "top": "8rem",
          "zIndex": 10,
          transform: "scaleX(-1)",
          left: "44%"
        } : {
          top: "2rem",
          zIndex: 10,
          transform: "rotate(-90deg)",
          left: "39%"
        }} />

      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="wallet" userTokenDetails={userToken} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Plans"}
              userTokenDetails={userToken} />

            <div className="text-center d-flex flex-row align-items-center  w-50 justify-content-between">
              <div style={showTutorial && tutorialSlide == 0 ? {
                "background": "white",
                "position": "relative",
                "zIndex": 10001,
                "padding": "1rem",
                "borderRadius": "1rem"
              } : {}}>
                <ul className="nav pricingtabs nav-pills bg-white mx-auto rounded-pill p-0 shadow-sm" id="pills-tab" role="tablist">
                  <li className="nav-item p-0 " role="presentation">
                    <button onClick={() => {
                      setPriceKey("plan_amount_usd")
                    }} className="nav-link active w-100 roundedpillleft font-size-14" id="pills-All-tab" data-bs-toggle="pill" data-bs-target="#pills-All" type="button" role="tab" aria-controls="pills-All" aria-selected="true">USD</button>
                  </li>
                  <li className="nav-item p-0 " role="presentation">
                    <button onClick={(e) => {
                      if (tab === 'LC') {
                        e.preventDefault()
                        toastDisplay("INR Currency Not available for LC", "info")
                      } else {
                        setPriceKey("plan_amount_inr")

                      }
                    }} className="nav-link w-100 roundedpillright font-size-14 " id="pills-Yearly-tab" data-bs-toggle="pill" data-bs-target="#pills-Yearly" type="button" role="tab" aria-controls="pills-Yearly" aria-selected="false">INR</button>
                  </li>
                </ul>
              </div>
              <div className="d-flex flex-row gap-3 align-items-center">
                <a href="/wallet" className="text-decoration-none"> <img src={"/assets/images/back.png"} alt="back" className="me-3" />
                </a>
                <div style={showTutorial && tutorialSlide == 0 ? {
                  "background": "white",
                  "position": "relative",
                  "zIndex": 10001,
                  "padding": "1rem",
                  "borderRadius": "1rem"
                } : {}}>
                  <ul className="nav pricingtabs nav-pills bg-white mx-auto rounded-pill p-0 shadow-sm" id="pills-tab" role="tablist">
                    <li className="nav-item p-0 " role="presentation">
                      <button onClick={() => {
                        setTutorialSlide(1)
                        setTab("Invoice")
                      }} className="nav-link active w-100 roundedpillleft font-size-14" id="pills-All-tab" data-bs-toggle="pill" data-bs-target="#pills-All" type="button" role="tab" aria-controls="pills-All" aria-selected="true">Invoice</button>
                    </li>
                    {/* <li className="nav-item p-0 " role="presentation">
                      <button onClick={() => {
                        setTutorialSlide(1)
                        setTab("Invoice")
                      }} className="nav-link w-100 roundedpillright font-size-14 " id="pills-Yearly-tab" data-bs-toggle="pill" data-bs-target="#pills-Yearly" type="button" role="tab" aria-controls="pills-Yearly" aria-selected="true">Invoice</button>
                    </li> */}
                  </ul>
                </div>
              </div>

            </div>

            <div style={showTutorial && tutorialSlide == 1 ? {
              "background": "white",
              "position": "relative",
              "zIndex": 10001,
              "padding": "1rem",
              "borderRadius": "1rem"
            } : {}}>
              <PricingCard showTutorial={showTutorial} userTypeId={userTypeId} plandata={plandata} currentPlan={currentPlan} userId={userId} userTokenDetails={userTokenDetails} priceKey={priceKey} />
            </div>

            <div className=" text-center my-3 d-flex flex-row align-items-center justify-content-center">
              <div className="contactus">
                <label className="text-center mt-2">For corporate & advisory services <label className="text-color1 font-size-14 font-wt-500"><u>Contact Us</u></label> </label>
              </div>
              <div>

              </div>
            </div>

          </main>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(Pricing)