import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import HeaderV2 from "../../partial/headerV2";
import SideBarV2 from "../../partial/sideBarV2";
import toastDisplay from "../../../utils/toastNotification";
import { astrix, ClearCache, convertImageToPdf, decryptData, GetCache, getDocDetails, most_used_currencies, SetCache, toolTip } from "../../../utils/myFunctions";
import call from '../../../service';
import { FileInput } from "../../../utils/FileInput";
import config from "../../../config.json"
import SignDocument from "../components/SignDocument";
import FinanceInvoiceModal from "../contract/components/financeinvoiceModal";
import { InputWithSelect, NewInput, NewSelect } from "../../../utils/newInput";
import { ToastContainer } from "react-toastify";
import swal from 'sweetalert';
import { NewTable } from "../../../utils/newTable";
import { getUserDataFromCookie } from "../../../utils/cookieHelper";

const addBuyerTabs = [
  { name: "Application form" },
  { name: "Documents" },
  // { name: "Review" },
]
const reviewForm = [
  { "name": "Applicant Name", val: "applicantName", unit: "nameTitle" },
  { "name": "Request amount", val: "reqAmount", unit: "currency" },
  { "name": "Invoice amount:", val: "contractAmount", unit: "currency" },
  { "name": "Finance credit days", val: "creditDays" },
  { "name": "Invoice No.", val: "invoiceRefrenceNo" },
  { "name": "Bank", val: "beneficiaryBankTitle" },
  { "name": "Invoice issue date", val: "invoiceIssueDate" },
  { "name": "Lender", val: "lenderNameTitle" },
  { "name": "Invoice due date", val: "invoiceDueDate" },
  { "name": "Buyer", val: "buyerName" },
  { "name": "Purchase order no.", val: "poRefrenceNo" }
]

const reviewForm2 = [
  { "name": "Shipment Origin Country", val: "shipmentOriginCountry" },
  { "name": "Shipment Origin Address", val: "shipmentOriginAddress" },
  { "name": "Shipment Origin City", val: "shipmentOriginCity" },
  { "name": "Shipment Origin Postal Code", val: "shipmentOriginPostalCode" },
  { "name": "Shipment Destination Country", val: "shipmentDestinationCountry" },
  { "name": "Shipment Destination Address", val: "shipmentDestinationAddress" },
  { "name": "Shipment Destination City", val: "shipmentDestinationCity" },
  { "name": "Shipment Destination Postal Code", val: "shipmentDestinationPostalCode" },
]


const ViewInvoiceDetails = ({ navToggleState }) => {

  const [tableData, setTableData] = useState([])
  const [tab, setTab] = useState(1)
  const [signdoc, setSigndoc] = useState(false);
  const [limitinvoice, setLimitinvoice] = useState(false);
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [docSignPopup, setDocSignPopup] = useState({})
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [agreementConfiguration, setAgreementConfiguration] = useState({})

  let userTokenDetails = getUserDataFromCookie()

  const queryParams = new URLSearchParams(window.location.search)
  const params = queryParams.get("params")
  let selectedItem = null

  if (params) {
    selectedItem = JSON.parse(decryptData(params))
    console.log("insideifffffffffffffffffffffff", selectedItem);
  }
  else {
    selectedItem = JSON.parse(localStorage.getItem("item"))
  }

  let selectedQuote = selectedItem.selectedQuote
  let isLenderModifi = selectedQuote.lender_name.toLowerCase().includes("modifi") ? true : false

  // const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  // const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  // const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName || selectedItem.userName
  // const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  // const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;
  // const userIdentifier = userTokenDetails.identifier ? userTokenDetails.identifier : null

  useEffect(() => {
    // Load agreement configuration start
    call('POST', 'getAgreementDocConfigurationByInvoiceNo', { "invRefNo": selectedItem.invRefNo }).then((result) => {
      if (result.configuration) {
        setAgreementConfiguration(result.configuration)
      }
    }).catch((e) => {
    })
    // Load agreement configuration end
  }, [])

  useEffect(() => {
    // if (Object.keys(agreementConfiguration).length) {
    let viewOnlyData = {}
    call('POST', 'getBuyersDetailWithDoc', {
      buyersId: selectedItem.id + "",
      applicationId: selectedItem.applicationId
    }).then(async (result) => {
      // console.log('running getBuyersDetailWithDoc api-->', result);
      setshowLoader(true)
      let termSheetDocument = await getDocDetails(selectedItem.termSheet)

      let frameworkDocument = await getDocDetails(selectedItem.frameworkDoc)
      let exhibitDocument = await getDocDetails(selectedItem.exhibitDoc)
      let noaDocument = await getDocDetails(selectedItem.noaDoc)

      viewOnlyData = await getInvoice()

      setshowLoader(false)
      if (result.length) {
        let docSignStatus = await call("POST", 'invoiceAgreementSignStatus', { invRefNo: selectedItem.invRefNo })
        setData({
          ...data, nameTitle: "Mr", currency: "USD", applicantName: userName, buyerName: result[0]["buyerName"],
          buyerPosition: result[0]["buyerPosition"], nameAuthorizedSignatory: result[0]["nameAuthorizedSignatory"],
          buyerEmail: result[0]["buyerEmail"], buyerPhoneCode: result[0]["buyerPhoneCode"], buyerContact: result[0]["buyerPhone"],
          buyerIdentifier: result[0]["identifier"], incoTerms: result[0]["incoTerms"],
          lenderId: selectedQuote.lender_id, termSheetDocument, frameworkDocument, exhibitDocument, noaDocument, lenderNameTitle: selectedQuote.lender_name, ...viewOnlyData,
          docSignStatus
        })
        setTableData(formatDataForTable([{ frameworkDocument, key: "frameworkDocument", title: "Framework Document", configurationKey: "framework" },
        { exhibitDocument, key: "exhibitDocument", title: "Exhibit Document", configurationKey: "exhibit" },
        { noaDocument, key: "noaDocument", title: "Noa Document", configurationKey: "noa" }], docSignStatus))
      }
    }).catch((e) => {
      // console.log('error in getBuyersDetailWithDoc', e);
    });
    // }
  }, [agreementConfiguration])

  function handleSignPopup(documentKey, documentFile) {
    setSigndoc(true)
    setDocSignPopup({ docToSign: documentFile, docSignName: documentKey })
  }

  function formatDataForTable(param, docSignStatus) {
    let tableData = []
    let row = []

    for (let index = 0; index < param.length; index++) {
      const item = param[index];
      if (item[item.key]?.name) {
        row[0] = item[item.key]?.doc_name
        row[1] = item[item.key] && item[item.key].name &&
          <div className="w-75">
            <FileInput name={item.key} value={item[item.key]}
              isEditable={false}
            />
          </div>
        row[2] =
          <div className="signStatusDiv">

            <div className="w-15">
              {docSignStatus?.[item.key]?.["signatureId"] ? (
                <img src={"assets/images/green_tick.png"} />
              ) :
                (selectedItem.isBuyer ? null : (
                  null
                  // <img
                  //   onClick={() => handleSignPopup(item.key, item[item.key])}
                  //   src={"assets/images/supplier-images/fluent_signature-20-regular.png"} className="cursor" />
                ))}
            </div>

            <div className="w-15">
              {docSignStatus?.[item.key]?.["buyerSignatureId"] ? (
                <img src={"assets/images/green_tick.png"} />
              ) : (selectedItem.isBuyer ? (
                null
                // <img
                //   onClick={() => handleSignPopup(item.key, item[item.key])}
                //   src={"assets/images/supplier-images/fluent_signature-20-regular.png"} className="cursor" />
              ) : null)}
            </div>

            <div className="w-15">
              {docSignStatus?.[item.key]?.["financierSignatureId"] ? (
                <img src={"assets/images/green_tick.png"} />
              ) : null}
            </div>

          </div>
        tableData.push(row)
        row = []
      }
    }

    console.log("ittttttttttttttttttt", tableData);

    return tableData

  }



  async function getInvoice() {
    return new Promise(resolve => {
      let viewOnlyData = {}
      call('POST', 'getInvDiscoutingDetails', { invNo: selectedItem.invRefNo }).then(async (result) => {
        // console.log('Invoice Get Discounting API  =====>', result);
        if (result && result.length) {
          let temp = result[0]
          let docsId = temp["tbl_doc_id"].split(",")

          viewOnlyData["tcAccepted"] = true
          viewOnlyData["docsId"] = docsId
          viewOnlyData["invoiceDocument"] = await getDocDetails(docsId[0])
          viewOnlyData["poDocument"] = await getDocDetails(docsId[1])
          viewOnlyData["logisticDocument"] = await getDocDetails(docsId[2])
          viewOnlyData["invReportDocument"] = await getDocDetails(docsId[3])
          viewOnlyData["grnDocument"] = await getDocDetails(docsId[4])

          viewOnlyData["poRefrenceNo"] = temp["po_no"]
          viewOnlyData["invoiceRefrenceNo"] = temp["reference_no"]
          viewOnlyData["beneficiaryBankName"] = temp["bankList"].split(":")[0] / 1
          viewOnlyData["beneficiaryBankTitle"] = temp["bankList"].split(":")[1]
          viewOnlyData["currency"] = currencyData.filter(i => {
            if (i.id / 1 == temp["currency"] / 1) {
              return i
            }
          })[0]["code"]
          viewOnlyData["contractAmount"] = temp["contract_amount"]
          viewOnlyData["invoiceIssueDate"] = temp["issue_date"]
          viewOnlyData["invoiceDueDate"] = temp["due_date"]
          viewOnlyData["reqAmount"] = temp["amount"]
          viewOnlyData["lenderId"] = temp["lender_id"] / 1
          viewOnlyData["creditDays"] = temp["credit_days"]

          viewOnlyData["shipmentOriginCountry"] = temp["shipment_origin_country"]
          viewOnlyData["shipmentOriginAddress"] = temp["shipment_origin_address"]
          viewOnlyData["shipmentOriginCity"] = temp["shipment_origin_city"]
          viewOnlyData["shipmentOriginPostalCode"] = temp["shipment_origin_postal_code"]

          viewOnlyData["shipmentDestinationCountry"] = temp["shipment_destination_country"]
          viewOnlyData["shipmentDestinationAddress"] = temp["shipment_destination_address"]
          viewOnlyData["shipmentDestinationCity"] = temp["shipment_destination_city"]
          viewOnlyData["shipmentDestinationPostalCode"] = temp["shipment_destination_postal_code"]

          resolve(viewOnlyData)
        }
      }).catch((e) => {
        resolve({})
        // console.log('error in Invoice Get Discounting API  =====>', e);
      })
    })
  }


  const closeSuccess = () => {
    setLimitinvoice(false);
  }



  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }
      // if (event.target.name.includes("otherDoc")) {
      //   setAddMoreDoc(addMoreDoc.concat(null))
      // }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (!file_type.includes("pdf")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  async function handleValidation(tabIndex) {

    if (tabIndex != undefined) {
      if (tabIndex < tab) {
        return setTab(tabIndex)
      }
      else if (tabIndex == tab) {
        return null
      }
      else {
        return toastDisplay("Click on continue button to go to next form", "info")
      }
    }

    let validateFields = []
    let err = {}

    validateFields.forEach((item) => {
      if (!data[item]) {
        err[item] = "Mandatory Field"
      }
    })

    if (!Object.keys(err).length) {
      setTab(tab + 1)
    }
    setErrors(err)
  }

  async function uploadSignedDocumentAndReload(updatedDoc) {
    setshowLoader(true)
    let formData = new FormData()
    formData.append("fileToSign", docSignPopup.docSignName)
    formData.append(docSignPopup.docSignName, updatedDoc)
    formData.append("signatureId", updatedDoc.signatureId)
    formData.append("docId", data.docSignStatus[docSignPopup.docSignName]["id"])
    if (selectedItem.isBuyer) {
      formData.append("isBuyer", true)
    }
    formData.append("invRefNo", selectedItem.invRefNo)
    await call("POST", 'signInvoiceDiscountingAgreement', formData)
    setshowLoader(false)
    window.location.reload()
  }

  return (
    <>{showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          {selectedItem.isBuyer ? null : (
            <SideBarV2 state="invoice_approvedFinance" userTokenDetails={userTokenDetails} />
          )}
          <main role="main" className={"ml-sm-auto col-lg-10 " + (" ")} id="app-main-div">
            {selectedItem.isBuyer ? null : (
              <>
                <HeaderV2
                  title={"Invoice Details"}
                  userTokenDetails={userTokenDetails} />
                <a className="cursor" href="/approvedFinance"><img className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
              </>
            )}
            <div className={selectedItem.isBuyer ? "mt-5" : ''}>
              <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                {addBuyerTabs.map((item, index) => {
                  return (
                    <li>
                      <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                        onClick={() => { handleValidation(index) }}
                      >
                        {item.name}</a>
                    </li>
                  )
                })}
              </ul>
              <div className="card mt-1">

                {tab === 0 ? (
                  <div className='px-5 py-4 pt-5'>
                    <div className="row">
                      <div className='w-100'>
                        <label className='font-wt-500 font-size-16'><u>Applicant Details</u></label>
                        <div className='row'>
                          {reviewForm.map((item) => {
                            return (
                              <div className='col-5 p-0'>
                                <label className='col-6 lh-18 text-color-label font-size-14'>{item.name}</label>
                                <label className='lh-18 font-size-14'>:</label>
                                <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                  {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}
                                </label>
                              </div>
                            )
                          })}
                          {isLenderModifi ? reviewForm2.map((item) => {
                            return (
                              <div className='col-5 p-0'>
                                <label className='col-6 lh-18 text-color-label font-size-14'>{item.name}</label>
                                <label className='lh-18 font-size-14'>:</label>
                                <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                  {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}
                                </label>
                              </div>
                            )
                          }) : null}
                        </div>
                      </div>
                      <div className='w-100 mt-5'>
                        <label className='font-wt-500 font-size-16'><u>Documents</u></label>

                        <div className='row mt-2'>

                          {data.invoiceDocument && data.invoiceDocument.name &&
                            <div className="col-md-4">
                              <label className="font-size-14">Invoice Document</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"invoiceDocument"} value={data.invoiceDocument}
                                    isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>
                          }

                          {data.poDocument && data.poDocument.name &&
                            <div className="col-md-4">
                              <label className="font-size-14">PO Document</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"poDocument"} value={data.poDocument}
                                    isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>
                          }

                          {data.logisticDocument && data.logisticDocument.name &&
                            <div className="col-md-4">
                              <label className="font-size-14">Bill of Lading/Airway Bill</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"logisticDocument"} value={data.logisticDocument}
                                    isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>
                          }

                          {data.invReportDocument && data.invReportDocument.name ? (
                            <div className="col-md-4">
                              <label className="font-size-14">Investigation Report</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"invReportDocument"} value={data.invReportDocument}
                                    isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : null}

                          {data.grnDocument && data.grnDocument.name ? (
                            <div className="col-md-4">
                              <label className="font-size-14">Certificate of Origin</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"grnDocument"} value={data.grnDocument}
                                    isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : null}

                          {
                            <div className="col-md-4">
                              <label className="font-size-14">Term Sheet</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"termSheetDocument"} value={data.termSheetDocument}
                                    isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>
                          }

                          {/* <div className='mt-3'>
                            <img
                              onClick={() => setData({ ...data, tcAccepted: !data.tcAccepted })}
                              className='cursor mr-3' src={`assets/images/${data.tcAccepted ? 'checked-green' : 'empty-check'}.png`} />
                            <label>I hereby declare that the above given information by me is true to my knowledge.</label>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {tab === 1 ? (
                  <div className='px-5 py-4 pt-5'>
                    <NewTable
                      disableAction={true}
                      columns={[{ name: "Document Name" }, { name: "Documents Attached" },
                      { name: "Signed By", subColumns: ["Supplier", "Buyer", "Financier"] }]}
                      data={tableData} />
                  </div>
                ) : null}

                {tab === 1 ? null : (
                  <div className="px-5 pb-5">
                    <button type="button"
                      onClick={() => handleValidation(undefined)}
                      className={`new-btn w-20 py-2 px-2 text-white`}>
                      {"Continue"}
                    </button>
                  </div>
                )}

              </div>
            </div>
          </main>
        </div>
      </div>
      {signdoc && <SignDocument isBuyerSigning={selectedItem.isBuyer} buyerUserId={selectedItem.id} signdoc={signdoc} setSigndoc={setSigndoc} docToSign={docSignPopup.docToSign}
        setUpdatedDoc={(updatedDoc) => uploadSignedDocumentAndReload(updatedDoc)} />}
      {limitinvoice && <FinanceInvoiceModal limitinvoice={limitinvoice} setLimitinvoice={setLimitinvoice} closeSuccess={closeSuccess} >
        <img src={"/assets/images/upload_done.png"} alt="" className="mb-3" />
        <p>Your application for “Invoice discounting” for “Rosti Suzhou Integrated” has been submitted successfully.</p>
      </FinanceInvoiceModal>}
    </>
  );
}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(ViewInvoiceDetails)