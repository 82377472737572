import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import call from '../../../../service';
import { NewTable } from '../../../../utils/newTable';
import HeaderV2 from '../../../partial/headerV2';
import SideBarV2 from '../../../partial/sideBarV2';
import Filter from "../../components/Filter";
import moment from 'moment';
import Pagination from '../../../InvoiceDiscounting/contract/components/pagination';
import NewTablev2 from '../../../../utils/newTablev2';
import { ToastContainer } from 'react-toastify';
import ChatBoxPopUp2 from '../../../chatRoom/components/ChatBoxPopUp2';
import { getDocDetails } from '../../../../utils/myFunctions';
import { Action } from '../../../myCounterPartComp/action';

export const FinanceApplication = ({ userTokenDetails, navToggleState }) => {

  const [tableData, setTableData] = useState([])
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10, singleApplication: true });
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [filterData, setFilterData] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [applicationCounts, setApplicationCounts] = useState({})

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [action, setAction] = useState({ show: false, index: null })

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  useEffect(() => {
    fetchApplicationCounts()
  }, [])

  async function fetchApplicationCounts() {
    call('POST', 'getInvoiceFinanceApplicationCounts', { userId }).then(res => setApplicationCounts(res))
  }

  useEffect(() => {
    //Get Buyer list start
    let objectAPI = {
      "userId": userId,
      "currentPage": page,
      "resultPerPage": 10,
      ...filter
    }
    setshowLoader(true)
    call('POST', 'financeApplication', objectAPI).then((result) => {
      setTableData(result.limitData)
      setCount(result.countData)
      setshowLoader(false)
    }).catch((e) => {
      setshowLoader(false)
      throw e;
    });
  }, [refresh])



  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('invApplicationId', selectedChat.invApplicationId)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))

    formdata.append("userTypeId", userTypeId)
    formdata.append("sellerId", selectedChat.sellerId)

    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {selectedChat.isChatOpen &&
        <div className="chatboxDivFixed">
          <ChatBoxPopUp2
            chatList={chatList}
            user_avatar={selectedChat.logo}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            parties={selectedChat.parties}
            userTokenDetails={userTokenDetails}
            onChatSend={(file, parties) => sendChatMsg(file, parties)}
            message={message}
            setTextMsg={setTextMsg}
            reloadChatList={() => getChatDetails({
              chat_room_id: selectedChat.chatRoomId,
              loggedInUser: userId
            })}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false,
                logo: ''
              })
              setRefresh(refresh + 1)
            }}
          />
        </div>
      }
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="invoice_financeApplication" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Export Factoring > Finance Application"}
              userTokenDetails={userTokenDetails} />

            <div className='d-flex row justify-content-between px-2' >
              <div className='card p-4 border1Blue' style={{ width: '39.22%' }} >
                <label className='font-size-15 font-wt-600 color0C0C0C' >Application in process - <label className='text-color1 font-size-15 font-wt-600' >{count || 0}</label></label>
                <div className='d-flex row' >
                  <div className='w-auto' >
                    <p className='font-size-22 font-wt-600 text-color1 p-0 m-0' >{applicationCounts.new || 0}</p>
                    <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0'>New</p>
                  </div>
                  <div className='w-auto' >
                    <p className='font-size-22 font-wt-600 text-color1 p-0 m-0' >{applicationCounts.sendAgreement || 0}</p>
                    <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0'>Send Agreement</p>
                  </div>
                  <div className='w-auto' >
                    <p className='font-size-22 font-wt-600 text-color1 p-0 m-0' >{applicationCounts.agreementSent || 0}</p>
                    <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0'>Agreement Sent</p>
                  </div>
                </div>
              </div>
              <div
                className='card border1Blue pl-4 d-flex justify-content-center cursor' style={{ width: '12.44%' }} >
                <div>
                  <p className='font-size-22 font-wt-600 text-danger p-0 m-0' >{applicationCounts.rejected || 0}</p>
                  <p className='font-size-16 font-wt-400 color0C0C0C p-0 m-0 mt-3'>Rejected</p>
                </div>
              </div>
              <div
                className='' style={{ width: '40.44%' }} >
              </div>
            </div>

            <div className='filter-div position-relative'>
              <Filter singleMultApplication={false}
                filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} />
            </div>
            <div className='mt-2'>
              <NewTablev2
                columns={[{ subColumns: "Date", filter: true, subColumnStyle: { width: '9%' } },
                { subColumns: "Supplier Name", filter: true, subColumnStyle: { width: '12%' } },
                { subColumns: "Buyer Name", filter: true, subColumnStyle: { width: '12%' } },
                { subColumns: "Bill of lading no. & date", subColumnStyle: { width: '10%' } },
                { subColumns: "Limit Available", subColumnStyle: { width: '14%' } },
                { subColumns: "Finance Credit Days", subColumnStyle: { width: '10%' } },
                { subColumns: "Remark", subColumnStyle: { width: '17%' } },
                { subColumns: "Action", subColumnStyle: { width: '16%' } }, { subColumns: "", subColumnStyle: { width: '5%' } }
                ]}
              >{
                  tableData.map((item, index) => {
                    let chatRoomIds = item.chatRoomIds?.split(",") || []
                    let chatRoomUsers = item.chatRoomUsers?.split(",") || []

                    let openChatRoomIndx = null
                    chatRoomUsers.forEach((u, i) => {
                      if (u?.split("::")[1] / 1 == userId / 1) {
                        openChatRoomIndx = i
                      }
                    })

                    return (
                      <tr>
                        <td >
                          <label className="font-size-13 font-wt-500" >{item.modified_at ? moment(item.modified_at).format('DD/MM/YYYY') : 'NA'}</label></td>
                        <td><label className="font-size-13 font-wt-500" >{item.supplierName ? item.supplierName : 'NA'}</label></td>
                        <td ><label className="font-size-13 font-wt-500" >{item.buyerName ? item.buyerName : 'NA'}</label></td>
                        <td style={{ whiteSpace: 'pre-line' }} ><label className="font-size-12 font-wt-500" >{(item.blNo || "-") + "\n" + item.issue_date}</label></td>
                        <td ><label className="font-size-12 font-wt-500" >{item.selectedQuote?.financeLimit + " " + item.selectedQuote?.financeLimitCurrency}</label></td>
                        <td ><label className="font-size-12 font-wt-500" >{item.credit_days + " Days"}</label></td>
                        <td><div
                          onClick={async () => {
                            let reqObj = {
                              userTypeId,
                              senderId: userId,
                              sellerId: item.seller_id,
                              receiverId: userId,
                              textMessage: 'Hii',
                              chat_room_name: "CHAT" + new Date().getTime(),
                              includeAdmins: true,
                              invApplicationId: item.limit_id,
                              receiverParties: userId,
                              dontSendInitialMsg: true
                            }
                            setshowLoader(true)
                            let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
                            setshowLoader(false)
                            getChatDetails({
                              chat_room_id: apiResp.id,
                              loggedInUser: userId
                            })
                            setSelectedChat({
                              chatRoomId: apiResp.id,
                              receiverName: `${chatRoomIds[openChatRoomIndx] || apiResp.id}`,
                              invApplicationId: item.limit_id,
                              parties: chatRoomUsers[openChatRoomIndx] || apiResp.parties,
                              userId: userId,
                              isChatOpen: true,
                              receiverId: userId,
                              sellerId: item.seller_id
                            })
                          }}
                        >
                          {openChatRoomIndx != null ? (
                            <div className='d-flex' >
                              <p className={`font-size-12 font-wt-500 p-0 m-0 ${item.chatRoomUnreadMsgCount > 0 ? ' w-80' : ' w-100 '}`} >
                                {item.lastMsgTime ?
                                  <label className='font-wt-700 w-100 p-0 m-0' >{moment(item.lastMsgTime).format("DD/MM/YYYY | HH:mm")}</label> : null}
                                <p className='ellipsis-container' >
                                  <p className='mb-0 ellipsis-text' style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: item.lastMsg }}></p>
                                </p>
                              </p>
                              {item.chatRoomUnreadMsgCount > 0 ?
                                <label
                                  className='lastMsgCount'
                                >{item.chatRoomUnreadMsgCount}</label> : null}
                            </div>) : "-"}
                        </div></td>
                        <td>
                          {!(item.frameworkDoc || item.exhibitDoc || item.noaDoc) ?
                            <div className='row ' >
                              <a>
                                <button type="button"
                                  onClick={() => {
                                    window.location = `/viewFinanceApplication`;
                                    localStorage.setItem("finApplicationDetail", JSON.stringify(item));
                                    localStorage.setItem("documentUploaded", 'no')
                                  }}
                                  className={`border-0 mb-2 text-white enableQuotebtn2`}>
                                  {"View Application"}
                                </button>
                              </a>
                            </div >
                            : item.status / 1 == 5 ?
                              <div className='row ' ><a>
                                <button type="button"
                                  className={`border-0 mb-2 text-white rejectedQuotebtn`}>
                                  {"Application Rejected"}
                                </button>
                                <p className="font-size-12 text-color-value p-0 m-0 ml-3 mt-2">
                                  <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-600">This application was rejected by you</span>
                                </p>
                              </a>
                              </div >
                              : <div className='row ' >
                                <a>
                                  <button type="button"
                                    onClick={() => {
                                      window.location = `/viewFinanceApplication`;
                                      localStorage.setItem("finApplicationDetail", JSON.stringify(item));
                                      localStorage.setItem("documentUploaded", 'yes')
                                    }}
                                    className={`viewfinancersbtnnew text-color1`}>
                                    {"Agreement Sent"}
                                  </button>
                                  <p className="font-size-12 text-color-value p-0 m-0 ml-3 mt-2">
                                    <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-600">Waiting for supplier & buyer to sign it</span>
                                  </p>
                                </a>
                              </div >}
                        </td>
                        <td
                          style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
                          className='position-relative'>
                          <i className="fa fa-ellipsis-v cursor mt-2 ml-2"
                            onClick={() => setAction({ show: true, index })}
                            aria-hidden="true"></i>
                          {action.show && action.index === index ? (
                            <Action
                              id={index}
                              onDismiss={() => setAction({ show: false, index })}
                              options={[
                                {
                                  name: "Chat With Supplier", icon: "",
                                  onClick: async () => {
                                    let reqObj = {
                                      userTypeId,
                                      senderId: userId,
                                      sellerId: item.seller_id,
                                      receiverId: userId,
                                      textMessage: 'Hii',
                                      chat_room_name: "CHAT" + new Date().getTime(),
                                      includeAdmins: true,
                                      invApplicationId: item.limit_id,
                                      receiverParties: userId,
                                      dontSendInitialMsg: true
                                    }
                                    setshowLoader(true)
                                    let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
                                    setshowLoader(false)
                                    getChatDetails({
                                      chat_room_id: apiResp.id,
                                      loggedInUser: userId
                                    })
                                    setSelectedChat({
                                      chatRoomId: apiResp.id,
                                      receiverName: `${chatRoomIds[openChatRoomIndx] || apiResp.id}`,
                                      invApplicationId: item.limit_id,
                                      parties: chatRoomUsers[openChatRoomIndx] || apiResp.parties,
                                      userId: userId,
                                      isChatOpen: true,
                                      receiverId: userId,
                                      sellerId: item.seller_id
                                    })
                                  }
                                }
                              ]} />
                          ) : null}
                        </td>
                      </tr>
                    )
                  })
                }
              </NewTablev2>
              <Pagination page={page} totalCount={count} onPageChange={(p) => setPage(p)} refresh={refresh} setRefresh={setRefresh} perPage={filter.resultPerPage || 0} />
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(FinanceApplication)