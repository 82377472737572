export const columns = [
  {
    subColumns: "Transaction ID",
    filter: true
  },
  {
    subColumns: "Transaction type",
    filter: true
  },
  {
    subColumns: "Buyer name",
    filter: true
  },
  {
    subColumns: "Time left",
    filter: true
  },
  {
    subColumns: "Action",
    filter: true
  },
];

export const tablenewData = [
  {
    TransactionID: "01/08/2022",
    Transactiontype: "LC Confirmation - Limit",
    buyerName: "Pharmanex Electronic Optical Technology Shanghai ",
    TimeLeft: false,
    Action: "See quotes",
  },
  {
    TransactionID: "01/08/2022",
    Transactiontype: "LC Discounting - Limit",
    buyerName: "Pharmanex Electronic Optical Technology Shanghai ",
    TimeLeft: false,
    Action: "Upload amended LC",
  },
  {
    TransactionID: "01/08/2022",
    Transactiontype: "Invoice Discounting - Limit",
    buyerName: "Pharmanex Electronic Optical Technology Shanghai ",
    TimeLeft: false,
    Action: "See quotes",
  },
  {
    TransactionID: "01/08/2022",
    Transactiontype: "LC Discounting - Finance",
    buyerName: "Pharmanex Electronic Optical Technology Shanghai ",
    TimeLeft: false,
    Action: "Sign Agreement",
  },
  {
    TransactionID: "01/08/2022",
    Transactiontype: "Invoice Discounting - Finance",
    buyerName: "Pharmanex Electronic Optical Technology Shanghai ",
    TimeLeft: false,
    Action: "Sign Agreement",
  },
];

export const quoteViewColumns2 = [
  { subColumns: 'Financiers', thwidth: 'w-20' },
  { subColumns: 'Confirmation Fees', thwidth: 'w-5' },
  {
    subColumns: 'Discounting Rate', thwidth: 'w-5'
  },
  {
    subColumns: 'Set-up fees (negotiable) ', thwidth: 'w-8'
  },
  {
    subColumns: 'Handling Charges', thwidth: 'w-8'
  },
  {
    subColumns: 'Courier Charges', thwidth: 'w-8'
  },
  {
    subColumns: 'Other Charges', thwidth: 'w-10'
  },
  {
    subColumns: 'Total Charges', thwidth: 'w-10'
  },
  { subColumns: 'Offer Validity', thwidth: 'w-8' },
  { subColumns: 'Chat', thwidth: 'w-5' },
  { subColumns: 'Select', thwidth: 'w-20' }
]

export const wcQuotesFinancierColumn = [
  { subColumns: 'Date', subColumnStyle: { width: '8%' } },
  { subColumns: 'Supplier Name', subColumnStyle: { width: '14%' } },
  { subColumns: 'No. of Buyer', subColumnStyle: { width: '10%' } },
  { subColumns: 'Services selected & limit requested', subColumnStyle: { width: '15%' } },
  { subColumns: 'Remark', subColumnStyle: { width: '20%' } },
  { subColumns: 'Action', subColumnStyle: { width: '13%' } },
  { subColumns: '', subColumnStyle: { width: '5%' } }
]

export const shipmentBookingQuotesFinancierColumn = [
  { subColumns: 'Date', subColumnStyle: { width: '10%' } },
  { subColumns: 'Shipment Contract No', subColumnStyle: { width: '15%' } },
  { subColumns: 'Supplier Name', subColumnStyle: { width: '12%' } },
  { subColumns: 'Commodity', subColumnStyle: { width: '8%' } },
  { subColumns: 'Container Type', subColumnStyle: { width: '8%' } },
  { subColumns: 'Container No', subColumnStyle: { width: '8%' } },
  { subColumns: 'Remark', subColumnStyle: { width: '17%' } },
  { subColumns: 'Status', subColumnStyle: { width: '15%' } }
]

export const wcQuotesColumn = [
  { subColumns: 'Date', subColumnStyle: { width: '8%' } },
  { subColumns: 'Buyer Name', subColumnStyle: { width: '14%' } },
  // { subColumns: 'Buyer Selected', subColumnStyle: { width: '8%' } },
  { subColumns: 'Services selected & limit requested', subColumnStyle: { width: '16%' } },
  { subColumns: 'Financers Selected', subColumnStyle: { width: '15%' } },
  { subColumns: 'Status', subColumnStyle: { width: '8%' } },
  { subColumns: 'Action', subColumnStyle: { width: '13%' } },
  { subColumns: '', subColumnStyle: { width: '5%' } }
]

export const notificationListColumn = [
  { subColumns: 'Date', subColumnStyle: { width: '8%' } },
  { subColumns: 'Contract No', subColumnStyle: { width: '9%' } },
  { subColumns: 'Contract Amount', subColumnStyle: { width: '7%' } },
  { subColumns: 'Notification Type', subColumnStyle: { width: '8%' } },
  { subColumns: 'Notification Title', subColumnStyle: { width: '12%' } },
  { subColumns: 'Action', subColumnStyle: { width: '8%' } }
]

export const shipmentBookingQuotesColumn = [
  { subColumns: 'Date', subColumnStyle: { width: '8%' } },
  { subColumns: 'Shipment Contract No', subColumnStyle: { width: '15%' } },
  { subColumns: 'Commodity', subColumnStyle: { width: '8%' } },
  { subColumns: 'Container Type', subColumnStyle: { width: '8%' } },
  // { subColumns: 'Container No', subColumnStyle: { width: '8%' } },
  { subColumns: 'Shippers Selected', subColumnStyle: { width: '24%' } },
  { subColumns: 'Status', subColumnStyle: { width: '8%' } },
  { subColumns: 'Action', subColumnStyle: { width: '12%' } }
]
export const shipmentBookingContractColumn = [
  { subColumns: 'Date', subColumnStyle: { width: '10%' } },
  { subColumns: 'Shipment Contract No', subColumnStyle: { width: '16%' } },
  { subColumns: 'Commodity', subColumnStyle: { width: '8%' } },
  { subColumns: 'Commodity Quantity', subColumnStyle: { width: '10%' } },
  { subColumns: 'Container Type', subColumnStyle: { width: '8%' } },
  { subColumns: 'Shipment Value', subColumnStyle: { width: '10%' } },
  { subColumns: 'Selected Shipper', subColumnStyle: { width: '14%' } },
  { subColumns: 'Status', subColumnStyle: { width: '9%' } },
  { subColumns: 'Action', subColumnStyle: { width: '15%' } }
]

export const bgFinQuotesColumn = [
  { subColumns: 'Date', subColumnStyle: { width: '8%' } },
  { subColumns: 'Supplier Name', subColumnStyle: { width: '12%' } },
  { subColumns: 'BG Type', subColumnStyle: { width: '5%' } },
  { subColumns: 'BG Tenor', subColumnStyle: { width: '5%' } },
  { subColumns: 'Amount', subColumnStyle: { width: '10%' } },
  { subColumns: 'Effective Date', subColumnStyle: { width: '7%' } },
  { subColumns: 'Expiry Date', subColumnStyle: { width: '7%' } },
  { subColumns: 'Remark', subColumnStyle: { width: '20%' } },
  { subColumns: 'Action', subColumnStyle: { width: '13%' } },
  { subColumns: '', subColumnStyle: { width: '5%' } },
]

export const bgFinConfirmedColumn = [
  { subColumns: 'Date', subColumnStyle: { width: '8%' } },
  { subColumns: 'Supplier Name', subColumnStyle: { width: '15%' } },
  { subColumns: 'BG Type', subColumnStyle: { width: '9%' } },
  { subColumns: 'BG Tenor', subColumnStyle: { width: '9%' } },
  { subColumns: 'Amount', subColumnStyle: { width: '10%' } },
  { subColumns: 'Effective Date', subColumnStyle: { width: '9%' } },
  { subColumns: 'Expiry Date', subColumnStyle: { width: '9%' } },
  { subColumns: 'Remark', subColumnStyle: { width: '20%' } },
  { subColumns: '', subColumnStyle: { width: '5%' } },
]

export const bgQuotesColumn = [
  { subColumns: 'Date', subColumnStyle: { width: '8%' } },
  { subColumns: 'Beneficiary Name', subColumnStyle: { width: '12%' } },
  { subColumns: 'BG Type', subColumnStyle: { width: '8%' } },
  { subColumns: 'Amount', subColumnStyle: { width: '10%' } },
  { subColumns: 'Effective Date', subColumnStyle: { width: '8%' } },
  { subColumns: 'Expiry Date', subColumnStyle: { width: '8%' } },
  { subColumns: 'Financers Selected', subColumnStyle: { width: '15%' } },
  { subColumns: 'Status', subColumnStyle: { width: '8%' } },
  { subColumns: 'Action', subColumnStyle: { width: '13%' } },
  { subColumns: '', subColumnStyle: { width: '5%' } },
]

export const bgConfirmedColumn = [
  { subColumns: 'Date', subColumnStyle: { width: '8%' } },
  { subColumns: 'Beneficiary Name', subColumnStyle: { width: '15%' } },
  { subColumns: 'BG Type', subColumnStyle: { width: '10%' } },
  { subColumns: 'Amount', subColumnStyle: { width: '12%' } },
  { subColumns: 'Effective Date', subColumnStyle: { width: '8%' } },
  { subColumns: 'Expiry Date', subColumnStyle: { width: '8%' } },
  { subColumns: 'Financer Selected', subColumnStyle: { width: '15%' } },
  { subColumns: '', subColumnStyle: { width: '5%' } },
]

export const quoteViewColumns = [
  { subColumns: 'Financiers', thwidth: 'w-20' },
  { subColumns: 'Limit', thwidth: 'w-15' },
  { subColumns: 'Factoring Rate (%)', thwidth: 'w-10' },
  {
    subColumns: 'Interest Rate', thwidth: 'w-10'
  },
  {
    subColumns: 'Set-up fees (negotiable) ', thwidth: 'w-10'
  },
  {
    subColumns: 'Other Charges', thwidth: 'w-10'
  },
  {
    subColumns: 'Total Charges', thwidth: 'w-10'
  },
  { subColumns: 'Offer Validity', thwidth: 'w-15' },
  { subColumns: 'Chat', thwidth: 'w-5' },
  { subColumns: 'Select', thwidth: 'w-20' }
]

export const multipleLCTable = [
  { subColumns: 'Upload LC', filter: false, subColumnStyle: { width: '28%' } },
  { subColumns: 'Service', filter: false, subColumnStyle: { width: '13.5%' } },
  { subColumns: 'Buyer Name (Optional)', filter: false, subColumnStyle: { width: '13.5%' } },
  { subColumns: 'LC Number', filter: false, subColumnStyle: { width: '9%' } },
  { subColumns: 'LC Tenor (in Days)', filter: false, subColumnStyle: { width: '8%' } },
  { subColumns: 'Origin Country', filter: false, subColumnStyle: { width: '9%' } },
  { subColumns: 'Issuing Bank', filter: false, subColumnStyle: { width: '11.5%' } },
  { subColumns: 'Action', filter: false, subColumnStyle: { width: '8%' } }
]

export const bgGetConfirmationDocs = [
  { subColumns: 'Document Name', filter: false, subColumnStyle: { width: '25.80%' } },
  { subColumns: 'Attach Document', filter: false, subColumnStyle: { width: '48%' } },
  { subColumns: 'Sign', filter: false, subColumnStyle: { width: '14.51%' } },
  { subColumns: 'Action', filter: false, subColumnStyle: { width: '11%' } }
]

export const multipleBuyerTable = [
  { subColumns: 'Buyer Name', filter: false, thwidth: 'w-25' },
  { subColumns: 'DUNS/Address', filter: false, thwidth: 'w-18' },
  { subColumns: 'Terms of Payment', filter: false, thwidth: 'w-10' },
  // { subColumns: 'Credit Days', filter: false },
  { subColumns: 'Buyer Turnover', filter: false, thwidth: 'w-10' },
  { subColumns: 'Expected Date of Shipment', filter: false, thwidth: 'w-15' },
  { subColumns: 'Credit Limit Required', filter: false, thwidth: 'w-15' },
  { subColumns: 'Action', filter: false, thwidth: 'w-7' }
]

export const documentTable4 = [
  {
    subColumns: "Document Name",
    filter: false, thwidth: 'w-10'
  },
  {
    subColumns: "Attach Document",
    filter: false, thwidth: 'w-35'
  },
  {
    subColumns: "Number",
    filter: false, thwidth: 'w-17'
  },
  {
    subColumns: "Amount",
    filter: false, thwidth: 'w-17'
  },
  {
    subColumns: "Sign",
    filter: false, thwidth: 'w-5'
  },
  {
    subColumns: "Action",
    filter: false, thwidth: 'w-10'
  },
]

export const lcDocumentTable = [
  {
    subColumns: "Document Name",
    filter: false, thwidth: 'w-20'
  },
  {
    subColumns: "Attach Document",
    filter: false, thwidth: 'w-35'
  },
  {
    subColumns: "Number",
    filter: false, thwidth: 'w-15'
  },
  {
    subColumns: "Sign",
    filter: false, thwidth: 'w-10'
  },
  {
    subColumns: "Action",
    filter: false, thwidth: 'w-10'
  },
]

export const documentTable = [
  {
    subColumns: "Document Name",
    filter: false, thwidth: 'w-10'
  },
  {
    subColumns: "Attach Document",
    filter: false, thwidth: 'w-35'
  },
  {
    subColumns: "Number",
    filter: false, thwidth: 'w-10'
  },
  {
    subColumns: "Amount",
    filter: false, thwidth: 'w-10'
  },
  {
    subColumns: "Sign",
    filter: false, thwidth: 'w-5'
  },
  {
    subColumns: "Action",
    filter: false, thwidth: 'w-10'
  },
]

export const documentTableForBL = [
  {
    subColumns: "Document Name",
    filter: false, thwidth: 'w-20'
  },
  {
    subColumns: "Attach Document",
    filter: false, thwidth: 'w-30'
  },
  {
    subColumns: "Document No",
    filter: false, thwidth: 'w-15'
  },
  {
    subColumns: "Issue Date",
    filter: false, thwidth: 'w-15'
  },
  {
    subColumns: "Sign",
    filter: false, thwidth: 'w-5'
  }
]

export const documentTableForFinancierBL = [
  {
    subColumns: "Attach Document",
    filter: false, thwidth: 'w-30'
  },
  {
    subColumns: "Document Name",
    filter: false, thwidth: 'w-15'
  },
  {
    subColumns: "Document No",
    filter: false, thwidth: 'w-15'
  },
  {
    subColumns: "Sign",
    filter: false, thwidth: 'w-8'
  },
  {
    subColumns: "Action",
    filter: false, thwidth: 'w-8'
  }
]

export const documentTable2 = [
  {
    subColumns: "Document Name", thwidth: 'w-17'
  },
  {
    subColumns: "Attach Document", thwidth: 'w-40'
  },
  {
    subColumns: "Sign", thwidth: 'w-5'
  },
  {
    subColumns: "Action", thwidth: 'w-5'
  }
]

export const documentTable3 = [
  {
    subColumns: "Document Name", thwidth: 'w-17'
  },
  {
    subColumns: "Amount", thwidth: 'w-20'
  },
  {
    subColumns: "Attach Document", thwidth: 'w-40'
  },
  {
    subColumns: "Action", thwidth: 'w-10'
  }
]

export const financertable = [
  {
    subColumns: "Transaction type",
    filter: true
  },
  {
    subColumns: "Stakeholder",
    filter: true
  },
  {
    subColumns: "LC no.",
    filter: true
  },
  {
    subColumns: "Time left",
    filter: true
  },
  {
    subColumns: "Action",
    filter: true
  },
];


export const tablefinancerData = [
  {
    Transactiontype: "LC Confirmation - Limit",
    Stakeholder: "Rosti Suzhou Integrated ",
    LCno: "256985632547854",
    TimeLeft: "failed",
    Action: "See quotes",
    NoofDays: "1",
  },
  {
    Transactiontype: "LC Confirmation - Limit",
    Stakeholder: "Rosti Suzhou Integrated ",
    LCno: "256985632547854",
    TimeLeft: "warning",
    Action: "Lock deal",
    NoofDays: "2",
  },
  {
    Transactiontype: "LC Confirmation - Limit",
    Stakeholder: "Rosti Suzhou Integrated ",
    LCno: "256985632547854",
    TimeLeft: "Approved",
    Action: "Send LC",
    NoofDays: "3",
  },
  {
    Transactiontype: "LC Confirmation - Limit",
    Stakeholder: "Rosti Suzhou Integrated ",
    LCno: "256985632547854",
    TimeLeft: "failed",
    Action: "Send quote",
    NoofDays: "1",
  },
  {
    Transactiontype: "LC Confirmation - Limit",
    Stakeholder: "Rosti Suzhou Integrated ",
    LCno: "256985632547854",
    TimeLeft: "failed",
    Action: "Send termsheet",
    NoofDays: "1",
  },

  {
    Transactiontype: "LC Confirmation - Limit",
    Stakeholder: "Rosti Suzhou Integrated ",
    LCno: "256985632547854",
    TimeLeft: "failed",
    Action: "Sign termsheet",
    NoofDays: "1",
  }, {
    Transactiontype: "LC Confirmation - Limit",
    Stakeholder: "Pharmanex Electronic Optical Technology Shanghai  ",
    LCno: "256985632547854",
    TimeLeft: "failed",
    Action: "View application",
    NoofDays: "1",
  },
  {
    Transactiontype: "LC Confirmation - Limit",
    Stakeholder: "Pharmanex Electronic Optical Technology Shanghai  ",
    LCno: "256985632547854",
    TimeLeft: "failed",
    Action: "Disburse",
    NoofDays: "1",
  },
  {
    Transactiontype: "LC Confirmation - Limit",
    Stakeholder: "Pharmanex Electronic Optical Technology Shanghai  ",
    LCno: "256985632547854",
    TimeLeft: "failed",
    Action: "Send termsheet",
    NoofDays: "1",
  },
  {
    Transactiontype: "LC Confirmation - Limit",
    Stakeholder: "Anhui Jinzhai Qiao Kang Pharmaceuti  ",
    LCno: "256985632547854",
    TimeLeft: "failed",
    Action: "Sign termsheet",
    NoofDays: "1",
  },
  {
    Transactiontype: "LC Confirmation - Limit",
    Stakeholder: "Anhui Jinzhai Qiao Kang Pharmaceuti  ",
    LCno: "256985632547854",
    TimeLeft: "failed",
    Action: "Disburse",
    NoofDays: "1",
  },


];


export const applyFinTblDID = [
  { subColumns: 'Buyer Name', filter: false, thwidth: 'w-23' },
  { subColumns: 'Invoice Number', filter: false, thwidth: 'w-15' },
  { subColumns: 'Invoice Amount ', filter: false, thwidth: 'w-10' },
  { subColumns: 'Limit Available', filter: false, thwidth: 'w-10' },
  { subColumns: 'Financers Selected', filter: false, thwidth: 'w-15' },
  { subColumns: 'Status', filter: false, thwidth: 'w-10' },
  { subColumns: 'Action', filter: false, thwidth: 'w-15' }
]

export const approvedSCFLimit = [
  { subColumns: 'Supplier Name', filter: false, thwidth: 'w-25' },
  { subColumns: 'Approved Limit', filter: false, thwidth: 'w-25' },
  { subColumns: 'Financiers Selected ', filter: false, thwidth: 'w-25' },
  { subColumns: 'Action', filter: false, thwidth: 'w-25' },
]