import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import call from '../../service';
import { FileInput } from '../../utils/FileInput';
import avatarUrl from '../../utils/makeAvatarUrl';
import MultipleSelect from '../../utils/MultipleSelect';
import { astrix, ClearCache, convertImageToPdf, dataURItoBlob, GetCache, getDocDetails, most_used_currencies, SetCache, toolTip } from '../../utils/myFunctions';
import { InputWithSelect, NewInput, NewSelect } from '../../utils/newInput';
import { NewTable } from '../../utils/newTable';
import { StarRating } from '../../utils/starRating';
import toastDisplay from '../../utils/toastNotification';
import Header from '../partial/header';
import HeaderV2 from '../partial/headerV2';
import Sidebar, { traderTypeIds } from '../partial/sidebar';
import SideBarV2 from '../partial/sideBarV2';
import { PopupMessage } from '../popupMessage';
import config from '../../config.json';
import moment from 'moment';
import TutorialPopup, { TutorialVideoPopup } from '../tutorialPopup';
import ChatBoxPopUp2 from '../chatRoom/components/ChatBoxPopUp2';
import Filter from '../InvoiceDiscounting/components/Filter';
import NewTablev2 from '../../utils/newTablev2';
import Pagination from '../InvoiceDiscounting/contract/components/pagination';
import { bgQuotesColumn, shipmentBookingContractColumn, shipmentBookingQuotesColumn, wcQuotesColumn } from '../Dashboard/TableJson';
import { Action } from '../myCounterPartComp/action';

const finHighlights = [
  { name: 'Selected', color: '#5CB8D3' },
  { name: 'Quote received', color: '#2ECC71' },
  { name: 'Rejected', color: '#FE4141' }
]

const ShipmentBookingContract = ({ userTokenDetails, navToggleState }) => {

  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [tableData, setTableData] = useState([])

  const [showAddBuyerForm, toggleAddBuyerForm] = useState(false)
  const [tab, setTab] = useState(0)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)

  const [dunsData, setDunsData] = useState([])
  const [hsnCodes, setHsnCodes] = useState([])
  const [countrys, setCountrys] = useState([])
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [financierData, setFinancierData] = useState([]);
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10, search: serarchParam ? serarchParam : '' })
  const [lcTutorial, toggleLCTutorial] = useState(localStorage.getItem("lcTutorialStep") == 1)
  const [tutorialStage, setTutorialStage] = useState(0)
  const [showTutorialVideo, toggleTutorialVideo] = useState({ show: false, link: null })
  const [filterData, setFilterData] = useState({})

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [action, setAction] = useState({ show: false, index: null })

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;


  useEffect(() => {
    setshowLoader(true)
    let objectAPI = {
      "userId": userId,
      "userEmail": userEmail,
      "userTypeId": userTypeId,
      "type": 'all',
      currentPage: page,
      ...filter
    }
    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push((element.accordianId === "status" || element.accordianId === "financiersFilter" || element.accordianId === "timeLeft") ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
        else if (element.type === "minMaxDate") {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }
    call('POST', 'getShipmentBookingContractList', objectAPI, "shipment").then((result) => {
      console.log('running getShipmentBookingContractList api-->', result);
      setDbData(result.data);
      setCount(result.countData);
      setshowLoader(false)
    }).catch((e) => {
      // console.log('error in getShipmentBookingContractList', e);
      setshowLoader(false)
    });
  }, [refresh, page, filterData])

  // useEffect(() => {
  //   call('POST', 'getWCQuoteListFilter', { userId }).then(res => {
  //     console.log("getWCQuoteListFilterthen", res);
  //     setFilterData(res)
  //   }).catch(err => { })
  // }, [])

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('shipmentBookingId', selectedChat.shipmentBookingId)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))
    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {selectedChat.isChatOpen &&
        <div className="chatboxDivFixed">
          <ChatBoxPopUp2
            chatList={chatList}
            user_avatar={selectedChat.logo}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            parties={selectedChat.parties}
            userTokenDetails={userTokenDetails}
            onChatSend={(file, parties) => sendChatMsg(file, parties)}
            message={message}
            setTextMsg={setTextMsg}
            reloadChatList={() => getChatDetails({
              chat_room_id: selectedChat.chatRoomId,
              loggedInUser: userId
            })}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false,
                logo: ''
              })
              setRefresh(refresh + 1)
            }}
          />
        </div>
      }
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="ShipmentBookingContract" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Shipment Booking > Contract"}
              userTokenDetails={userTokenDetails} />
            <div className='mt-4' style={{}} >
              <div className='filter-div position-relative'>
                <Filter
                  filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                  showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} />

                {/* {Object.values(filterData || {})?.filter(i => {
                  if (i.isFilterActive) { return true }
                })?.length ? null : <div style={{ left: '21rem' }} className='d-flex position-absolute'>
                  {finHighlights.map((i, j) => {
                    return (<div className='d-flex align-items-center mx-2' >
                      <div style={{ background: i.color, height: '10px', width: '10px', borderRadius: 1000 }} />
                      <label className='font-size-13 font-wt-500 mt-2 mx-2' >{i.name}</label>
                    </div>)
                  })}
                </div>} */}
              </div>


              <div>
                <NewTablev2 columns={shipmentBookingContractColumn} >
                  {dbData.map((item, j) => {
                    let selectedShipperName = item.selectedShipperName ? item.selectedShipperName.split(",") : []
                    let selectedShipperId = item.selectedShipperId ? item.selectedShipperId.split(",") : []
                    let buyersCredit = item.shipperQuotes ? JSON.parse(item.shipperQuotes) : []
                    let chatRoomIds = item.chatRoomIds?.split(",") || []
                    let chatRoomUsers = item.chatRoomUsers?.split(",") || []
                    let chatRoomUnreadMsgCount = item.chatRoomUnreadMsgCount?.split(",") || []

                    let allDeniedCount = 0
                    let apporvedCount = 0

                    if (item.shipperQuotes) {
                      for (let index = 0; index < JSON.parse(item.shipperQuotes).length; index++) {
                        const j = JSON.parse(item.shipperQuotes)[index];
                        if (j.shipperAction === "deny") {
                          allDeniedCount += 1
                        }
                        else {
                          apporvedCount += 1
                        }
                      }
                    }
                    let buyerNames = []
                    let buyerCount = 0
                    for (let index = 0; index < 11; index++) {
                      if (item.details[`buyerName${index}`]) {
                        buyerCount += 1
                        buyerNames.push(item.details[`buyerName${index}`])
                      }
                    }

                    return (
                      <tr>
                        <td><label className='font-size-13 font-wt-500 text-break' >{moment(item.applicationUpdatedAt).format("DD/MM/YYYY")}</label></td>
                        <td >
                          <label className='font-size-13 font-wt-500 text-break' >{item.applicationNo || "NA"}
                          </label></td>
                        <td >
                          <label className='font-size-13 font-wt-500 text-break' >{item.details?.commodity || "NA"}</label>
                        </td>
                        <td >
                          <label className='font-size-13 font-wt-500 text-break' >{item.details?.quantityUnit + " " + item.details?.quantity}</label>
                        </td>
                        <td  >
                          <label className='font-size-13 font-wt-500 text-break' >{item.details?.containerType || "NA"}
                          </label></td>
                        <td  >
                          <label className='font-size-13 font-wt-500 text-break' >{item.details?.commodityPriceUnit + " " + item.details?.commodityPrice}
                          </label></td>
                        <td>
                          <div
                            className=''
                          >
                            {selectedShipperName.length ? selectedShipperName.map((key, j) => {
                              let isApprovedByFinancier = buyersCredit?.filter(i => {
                                if ((i.shipper_id / 1 == selectedShipperId[j] / 1) && i.shipperAction === "Approved") {
                                  return i
                                }
                              })?.[0]
                              let isRejectedByFinancier = buyersCredit?.filter(i => {
                                if ((i.shipper_id / 1 == selectedShipperId[j] / 1) && i.shipperAction === "deny") {
                                  return i
                                }
                              })?.[0]
                              let openChatRoomIndx = null
                              chatRoomUsers.forEach((u, i) => {
                                if (u?.split("::")[1] / 1 == selectedShipperId[j]) {
                                  openChatRoomIndx = i
                                }
                              })
                              if (selectedShipperId[j] / 1 == item.selectedShipper / 1) {
                                return (
                                  <div className='position-relative' >
                                    <img className='cursor'
                                      onClick={async () => {
                                        let reqObj = {
                                          senderId: userId,
                                          receiverId: selectedShipperId[j],
                                          textMessage: 'Hii',
                                          chat_room_name: "CHAT" + new Date().getTime(),
                                          includeAdmins: true,
                                          shipmentBookingId: item.applicationId,
                                          receiverParties: selectedShipperId[j],
                                          dontSendInitialMsg: true
                                        }
                                        setshowLoader(true)
                                        let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
                                        setshowLoader(false)
                                        getChatDetails({
                                          chat_room_id: apiResp.id,
                                          loggedInUser: userId
                                        })
                                        setSelectedChat({
                                          chatRoomId: apiResp.id,
                                          receiverName: `${key} - ${chatRoomIds[openChatRoomIndx] || apiResp.id}`,
                                          shipmentBookingId: item.applicationId,
                                          parties: chatRoomUsers[openChatRoomIndx] || apiResp.parties,
                                          userId: userId,
                                          isChatOpen: true,
                                          receiverId: selectedShipperId[j]
                                        })
                                      }}
                                      src={chatRoomIds[openChatRoomIndx] ? `assets/images/comment_filled.png` : `assets/images/chat.png`} />
                                    <label className={`ml-3 font-size-13 font-wt-500 ${selectedShipperId[j] / 1 == item.selectedShipper / 1 ? " text5CB8D3 " : isApprovedByFinancier ? " text2ECC71 " : " "}  ${isRejectedByFinancier ? ' text-danger ' : '   '} `} >{`${key} `}
                                      <span className='text-color1 mx-2' ><u>{chatRoomUnreadMsgCount[openChatRoomIndx] / 1 ? (chatRoomUnreadMsgCount[openChatRoomIndx] < 10 ? `0${chatRoomUnreadMsgCount[openChatRoomIndx]}` : chatRoomUnreadMsgCount[openChatRoomIndx]) : null}</u></span></label>
                                  </div>
                                )
                              }
                            }) : "NA"}
                          </div>
                        </td>
                        <td >
                          <div className=''>
                            {item.applicationStatus / 1 == 1 ?
                              <button type="button"
                                class={`approved text-white border-0 `}>
                                {"Approved"}
                              </button> :
                              item.applicationStatus / 1 == 2 ?
                                <button type="button"
                                  class={`rejected text-white border-0 `}>
                                  {"Rejected"}
                                </button>
                                :
                                <button type="button"
                                  class={`inprogress text-white border-0 `}>
                                  {"Inprogress"}
                                </button>}
                          </div>
                        </td>
                        <td>
                          <div className='' >
                            <a className='mt-2'>
                              <button type="button"
                                onClick={() => {
                                  window.location = `/ShipmentBookingContractDetails`;
                                  localStorage.setItem("shipmentFinancierQuoteDetails", JSON.stringify(item))
                                }}
                                className={` border-0 mb-2 text-white enableQuotebtn `}>
                                {"View Details"}
                              </button>
                              {item.applicationStatus / 1 == 2 ?
                                <p className="font-size-12 text-color-value ml-3">
                                  <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-600 text-danger">Application rejected by Shipper</span>
                                </p>
                                : !item.blDetails ?
                                  <p className="font-size-12 text-color-value ml-3">
                                    <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-600">Waiting for BL to be uploaded by Shipper</span>
                                  </p>
                                  : item.blDetails ?
                                    <p className="font-size-12 text-color-value ml-3">
                                      <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-600 ">BL is issued by Shipper</span>
                                    </p>
                                    : null}
                            </a>
                          </div >
                        </td>
                      </tr>
                    )
                  })}
                </NewTablev2>
              </div>
              <Pagination perPage={filter.resultPerPage || 10} page={page} totalCount={count} onPageChange={(p) => setPage(p)} />

            </div>
          </main>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(ShipmentBookingContract)
