import React, { useEffect, useState } from 'react'
import HeaderV2 from '../partial/headerV2'
import SideBarV2 from '../partial/sideBarV2'
import { connect } from 'react-redux'
import toastDisplay from '../../utils/toastNotification'
import { InputForTable, InputWithSelect, NewInput, NewSelect, SelectForTable } from '../../utils/newInput'
import call from '../../service'
import { FileInput } from '../../utils/FileInput'
import { SmallFileInput } from '../../utils/SmallFileInput'
import moment from 'moment'
import NewTablev2 from '../../utils/newTablev2'
import { ExportExcel, convertImageToPdf, dataURItoBlob, getDocDetails, most_used_currencies } from '../../utils/myFunctions'
import axios from 'axios'
import { aiServerBackendUrl, environment, platformBackendUrl } from '../../urlConstants'
import SignDocument from '../InvoiceDiscounting/components/SignDocument'
import { DocumentViewer } from '../../utils/documentViewer'
import { DocumentDownloader } from '../../utils/DocumentDownloader'
import { ToastContainer } from 'react-toastify'
import { ExpandableTable } from '../wallet/components/ExpandableTable'
import avatarUrl from '../../utils/makeAvatarUrl'
import { industryData } from '../registration/newRegistration'
import ChatBoxPopUp2 from '../chatRoom/components/ChatBoxPopUp2'
import XLSX, { readFile } from 'sheetjs-style';
import Papa from 'papaparse';
import FinanceInvoiceModal from '../InvoiceDiscounting/contract/components/financeinvoiceModal'
import ExtraDetailsForLimitApplication from '../InvoiceDiscounting/extraDetailsForLimitApplication'

const scmformtabs = [
  { name: "Documents" },
  { name: "Exporter Details" },
  { name: "Vendor Details" },
  { name: "Select financer" },
  { name: "Review" }
]

export const companyDetailsReviewForm = [
  { "name": "Business Name", val: "companyName" },
  { "name": "Aadhar Number", val: "aadharNo" },
  { "name": "Contact Person", val: "contact_person" },
  { "name": "PAN Number", val: "panno" },
  { "name": "Contact number", val: "contact_number" },
  { "name": "Constitution", val: "deliveryIFSCCode" },
  { "name": "Email ID", val: "email_id" },
  { "name": "Address", val: "user_address" },
  { "name": "Start date of business", val: "startDateOfBusiness" },
  { "name": "Industry Type", val: "industry", },
  { "name": "Competition", val: "competition" },
  { "name": "Product Details", val: "productDetails" },
  { "name": "Sub-Sector", val: "subSector" }
]

const ownerReview = [
  { "name": "Owner Name", val: "ownerName" },
  { "name": "Category", val: "ownerCategory" },
  { "name": "Contact No.", val: "ownerContactNo", unit: "ownerPhoneCode" },
  { "name": "PAN No", val: "ownerPanNo" },
  { "name": "Email Id", val: "ownerEmailId" },
  { "name": "Aadhar No.", val: "ownerAadharNo" },
  { "name": "Address", val: "ownerAddressLine", after: "ownerAddressLine" },
  { "name": "Date of Birth", val: "ownerDOB" },
  { "name": "Net Worth", val: "ownerNetWorth" }
]

export const FinancialMonths = [
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
  "January",
  "February",
  "March"
]
const reviewLabels = [
  { name: 'Total Finance Application', color: "#1B94B7", alt: "totalFinanceApplied" },
  { name: 'Application Rejected', color: '#FE4141', alt: "applicationRejected" },
  { name: 'Application Approved', color: '#00B74D', alt: "applicationApproved" },
]
export let yesnoqsts = {
  "qst1": {
    label: "Women Entrepreneur"
  },
  "qst2": {
    label: "ISO certified"
  },
  "qst3": {
    label: "Business continuity indemnity policy"
  },
  "qst4": {
    label: "The agreement between the client and the debtor does not prohibit assignment of receivables by the client to a third party including a Factoring Company."
  },
  "qst5": {
    label: "Any irregular and slow payments in the past by the proposed debtor."
  },
  "qst6": {
    label: "Are there any overdue invoices outstanding on account of the debtor in the client’s books."
  },
  "qst7": {
    label: "Whether there have been any disputes with the debtor in recent past with respect to the goods supplied by the client to the debtor or otherwise."
  },
  "qst6": {
    label: "Inform whether direct contact allowed"
  }
}
let currentYear = moment().format("YYYY")

let financialYearMonths = [{ name: "April", lastYear: true }, { name: "May", lastYear: true }, { name: 'June', lastYear: true }, { name: 'July', lastYear: true }, { name: "August", lastYear: true }, { name: "Sept", lastYear: true }, { name: "Oct", lastYear: true },
{ name: "Nov", lastYear: true }, { name: 'Dec', lastYear: true }, { name: 'Jan' }, { name: "Feb" }, { name: "March" }]

let last5Years = [
  { label: `${currentYear - 1}-${currentYear}` },
  { label: `${currentYear - 2}-${currentYear - 1}` },
  { label: `${currentYear - 3}-${currentYear - 2}` },
  { label: `${currentYear - 4}-${currentYear - 3}` },
  { label: `${currentYear - 5}-${currentYear - 4}` }]

let bankDocsInitial = [
  { name: 'Bank statement of all working capital & Current accounts  (last 12 months)', alt: "bankStatement" },
  { name: 'Limit details & sanctioned letter of existing banking lines', alt: 'limdetailsExisting' },
  { name: 'Add More', alt: "moreDoc1" }
]

let financialDocsInitial = [
  { name: 'Audited financials including ITR', alt: "aduFin", docArray: [{ valKey: "AudFin_0", yearKey: "AudFinYear_0" }, { valKey: "AudFin_1", yearKey: "AudFinYear_1" }] },
  { name: 'Ledger with all the buyer in the term sheet (last 12 months)', alt: 'limdetailsExisting' },
  { name: 'Stock statement shared with banks ageing breakup of debtor /creditors (last 2 months)', alt: "stockStatement" },
  { name: 'Add More', alt: "FinmoreDoc1" }
]

let organisationalDocs = [
  { name: "Partnership deed (in case of partnership firm)", alt: 'partnerShipdeed' },
  { name: "", docArray: [{ name: "Aadhaar Card", partnerNameKey: "parnter1AadhaarName", valKey: 'aadharDocPartner1' }, { name: "PAN Card", partnerNameKey: "parnter1PANName", valKey: 'PANDocPartner1' }] },
  { name: "", docArray: [{ name: "Aadhaar Card", partnerNameKey: "parnter2AadhaarName", valKey: 'aadharDocPartner2' }, { name: "PAN Card", partnerNameKey: "parnter2PANName", valKey: 'PANDocPartner2' }] },
  { name: 'Add More', alt: "organisationmoreDoc1" }
]

let suppliersFormat = [{
  "Supplier Name": "",
  "Address": "",
  "Credit Limit Required ($)": "",
  "Terms Of Payment (days)": "",
  "Annual Sale ($)": "",
  "Years of Relation": "",
  "Relation with Client ($)": ""
}]
let buyersFormat = [{
  "Buyer Name": "",
  "Address": "",
  "Credit Limit Required ($)": "",
  "Terms Of Payment (days)": "",
  "Annual Sale ($)": "",
  "Years of Relation": "",
  "Relation with Client ($)": ""
}]

const ApplyLimitCGTMSE = ({ userTokenDetails, navToggleState }) => {
  const [tab, setTab] = useState(0)
  const [data, setData] = useState({ selectedFin: [] })
  const [errors, setErrors] = useState({})
  const [gstReturns, setGstReturns] = useState([null, null])
  const [bankDocs, setBankDocs] = useState(bankDocsInitial)
  const [finDocs, setFinDocs] = useState(financialDocsInitial)
  const [orgDocs, setOrgDocs] = useState(organisationalDocs)
  const [showLoader, setshowLoader] = useState(false)
  const [gstToggle, setGstToggle] = useState(0)
  const [signdoc, setSigndoc] = useState(false);
  const [viewDoc, toggleViewDoc] = useState({ show: false, doc: {} })
  const [dowdDoc, toggleDowdDoc] = useState({ show: false, doc: {} })
  const [reviewScrollIndex, toggleReviewScrollIndex] = useState(0)
  const [seeReview, setSeeReview] = useState({})
  const [financiers, setFinList] = useState([])
  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [top10Buyers, setTop10Buyers] = useState([null, null, null, null, null, null, null, null, null, null])
  const [top10Suppliers, setTop10Suppliers] = useState([null, null, null, null, null, null, null, null, null, null])
  const [applicationSubmitPopup, toggleApplicationSubmitPopup] = useState({ show: false, data: {} })
  const [owners, setOwners] = useState([null, null])
  const [countryData, setCountryData] = useState([])
  const [states, setstatedata] = useState([])
  const userData = localStorage.getItem("UserDetails")

  let userToken = userTokenDetails
  if (userTokenDetails.type_id === 1) {
    userToken = userData ? JSON.parse(userData) : userTokenDetails
  }
  const userTypeId = userToken.type_id ? userToken.type_id : null
  const userEmail = userToken.email ? userToken.email : null
  const userId = userToken.user_id ? userToken.user_id : null
  const userName = userToken.userName ? userToken.userName : null
  const subUserId = userToken.sub_user_id ? userToken.sub_user_id : null;
  const parentData = userToken.parent_data ? userToken.parent_data : null;
  const ttvExporterCode = userToken.ttvExporterCode ? userToken.ttvExporterCode : ''
  const subUserName = userToken?.sub_user_name
  const mainUserName = userToken?.main_user_name

  const handleCSV = (event, userTypee) => {
    event.persist();
    if (!event.target.files.length) {
      return null
    }
    setErrors({ ...errors, [event.target.name]: "" });
    // if (!event.target.files[0]['name'].includes("csv")) {
    //   setErrors({ ...errors, [event.target.name]: "Please select csv file" });
    //   return
    // }
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onloadend = function (e) {
      if (event.target.files[0]['name'].includes("csv")) {
        Papa.parse(event.target.files[0], {
          complete: (result) => {
            //console.log("csvParseComplete=======>", result);
            let tempObj = {}
            for (let index = 0; index < result?.data?.length; index++) {
              if (index <= 10) {
                const element = result.data[index];
                if (userTypee == "buyer") {
                  tempObj[`buyerName${index}`] = element["Buyer Name"] || ""
                  tempObj[`buyerAddress${index}`] = element["Address"] || ""
                  tempObj[`buyerCreditLimitRequired${index}`] = !isNaN(element["Credit Limit Required ($)"]) ? element["Credit Limit Required ($)"] : ""
                  tempObj[`buyerTermsOfPayment${index}`] = !isNaN(element["Terms Of Payment (days)"]) ? element["Terms Of Payment (days)"] : ""
                  tempObj[`buyerAnnualSale${index}`] = !isNaN(element["Annual Sale ($)"]) ? element["Annual Sale ($)"] : ""
                  tempObj[`buyerYearsOfRelation${index}`] = !isNaN(element["Years of Relation"]) ? element["Years of Relation"] : ""
                  tempObj[`buyerRelationWithClient${index}`] = !isNaN(element["Relation with Client ($)"]) ? element["Relation with Client ($)"] : ""
                }
                else if (userTypee === "supplier") {
                  tempObj[`supplierName${index}`] = element["Supplier Name"] || ""
                  tempObj[`supplierAddress${index}`] = element["Address"] || ""
                  tempObj[`supplierCreditLimitRequired${index}`] = !isNaN(element["Credit Limit Required ($)"]) ? element["Credit Limit Required ($)"] : ""
                  tempObj[`supplierTermsOfPayment${index}`] = !isNaN(element["Terms Of Payment (days)"]) ? element["Terms Of Payment (days)"] : ""
                  tempObj[`supplierAnnualSale${index}`] = !isNaN(element["Annual Sale ($)"]) ? element["Annual Sale ($)"] : ""
                  tempObj[`supplierYearsOfRelation${index}`] = !isNaN(element["Years of Relation"]) ? element["Years of Relation"] : ""
                  tempObj[`supplierRelationWithClient${index}`] = !isNaN(element["Relation with Client ($)"]) ? element["Relation with Client ($)"] : ""
                }
              }
              else {
                break
              }
            }
            // console.log("tempObjjjjjjjjjjjjjjjjjjjjj", tempObj);
            setData({ ...data, ...tempObj, [event.target.name]: event.target.files[0] })
            setErrors({ ...errors, [event.target.name]: "" });
          },
          header: true
        });
      } else {
        console.log('ressssssssssssssssss', e.target.result);
        const workbook = XLSX.read(e.target.result.split("base64,")[1], { type: 'base64' })
        const worksheetname = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[worksheetname]
        const finalRes = XLSX.utils.sheet_to_json(worksheet)

        let tempObj = {}
        for (let index = 0; index < finalRes.length; index++) {
          if (index <= 10) {
            const element = finalRes[index];
            if (userTypee == "buyer") {
              tempObj[`buyerName${index}`] = element["Buyer Name"] || ""
              tempObj[`buyerAddress${index}`] = element["Address"] || ""
              tempObj[`buyerCreditLimitRequired${index}`] = !isNaN(element["Credit Limit Required ($)"]) ? element["Credit Limit Required ($)"] : ""
              tempObj[`buyerTermsOfPayment${index}`] = !isNaN(element["Terms Of Payment (days)"]) ? element["Terms Of Payment (days)"] : ""
              tempObj[`buyerAnnualSale${index}`] = !isNaN(element["Annual Sale ($)"]) ? element["Annual Sale ($)"] : ""
              tempObj[`buyerYearsOfRelation${index}`] = !isNaN(element["Years of Relation"]) ? element["Years of Relation"] : ""
              tempObj[`buyerRelationWithClient${index}`] = !isNaN(element["Relation with Client ($)"]) ? element["Relation with Client ($)"] : ""
            }
            else if (userTypee === "supplier") {
              tempObj[`supplierName${index}`] = element["Supplier Name"] || ""
              tempObj[`supplierAddress${index}`] = element["Address"] || ""
              tempObj[`supplierCreditLimitRequired${index}`] = !isNaN(element["Credit Limit Required ($)"]) ? element["Credit Limit Required ($)"] : ""
              tempObj[`supplierTermsOfPayment${index}`] = !isNaN(element["Terms Of Payment (days)"]) ? element["Terms Of Payment (days)"] : ""
              tempObj[`supplierAnnualSale${index}`] = !isNaN(element["Annual Sale ($)"]) ? element["Annual Sale ($)"] : ""
              tempObj[`supplierYearsOfRelation${index}`] = !isNaN(element["Years of Relation"]) ? element["Years of Relation"] : ""
              tempObj[`supplierRelationWithClient${index}`] = !isNaN(element["Relation with Client ($)"]) ? element["Relation with Client ($)"] : ""
            }
          }
          else {
            break
          }
        }
        console.log("tempObjjjjjjjjjjjjjjjjjjjjj", tempObj);
        setData({ ...data, ...tempObj, [event.target.name]: event.target.files[0] })
        setErrors({ ...errors, [event.target.name]: "" });
      }

    }
    reader.readAsText(event.target.files[0]);
  }
  async function handleValidation() {
    let err = {}
    if (tab === 2) {
      if (!data["buyerName0"]) {
        err["buyerName"] = "Kindly enter atleast 1 buyer details"
        toastDisplay("Kindly enter atleast 1 buyer details", "info")
      }
      else {
        setTab(3)
      }
    }
    else if (tab === 3) {
      let isFinSelected = false
      for (let index = 0; index < financiers.length; index++) {
        const element = financiers[index];
        if (element.isChecked) {
          isFinSelected = true
        }
      }
      if (!isFinSelected) {
        toastDisplay("Kindly select atleast 1 financer", "info")
      }
      else {
        setTab(tab + 1)
      }
    }
    else if (tab === 4) {
      if (!data.tcAccepted) {
        toastDisplay('kindly accept declaration, to continue', "info")
        err["tcAccepted"] = 'kindly accept declaration, to continue'
      }
    }
    else {
      setTab(tab + 1)
    }
    setErrors(err)
    if (!Object.keys(err).length && tab === 4) {
      setshowLoader(true)
      let formData = new FormData()
      formData.append("userId", userId)
      formData.append("userName", userName)
      Object.keys(data).forEach(key => {
        if (!key.includes("Document")) {
          formData.append(key, data[key])
        }
      })
      if (data.securityDocument?.name) {
        formData.append("securityDocument", data.securityDocument)
        if (data[`securityDocument`].signatureId) {
          formData.append("securityDocumentSignId", data[`securityDocument`].signatureId)
        }
      }
      formData.append("finList", JSON.stringify(financiers))
      call('POST', 'applyForCGTMSELimit', formData).then(res => {
        setshowLoader(false);
        toggleApplicationSubmitPopup({ show: true, data: {} })
      }).catch(err => { setshowLoader(false); toastDisplay("Something went wrong") })
    }
  }
  useEffect(() => {
    getFinancers()
    axios.get(platformBackendUrl + "/getallCountry").then((result) => {
      if (result.data.message && result.data.message.length) {
        setCountryData(result.data.message);
      }
    });
    getStateByCountry(101)
  }, [])
  const getStateByCountry = (countryId) => {
    call("POST", "getStateByCountry", { countryId }).then(result => {
      console.log('Success getStateByCountry data', result)
      setstatedata(result)
    }).then(e => {
      console.log("Error in getStateByCountry", e)
    })
  }
  const handleChange = async (event) => {
    console.log('dataaaaaaa', event);
    if (event.persist) {
      event.persist()
    }
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }
  const getFinancers = async () => {
    setshowLoader(true)
    let finApiResp = await call('POST', "getFinanciersForCGTMSE", {})
    let finIds = []
    for (let index = 0; index < finApiResp.length; index++) {
      const element = finApiResp[index];
      element["isAvailable"] = true
      element["logo"] = element.user_avatar || null
      element["isChecked"] = true
      finIds.push(element.id)
    }
    setData({
      ...data,
      selectedFin: finIds
    })
    setshowLoader(false)
    setFinList(finApiResp)
  }
  function handleFinSelection(j) {
    let temp = financiers
    temp[j]["isChecked"] = !temp[j]["isChecked"]
    setFinList([...temp])
  }

  useEffect(() => {
    getFinancers()
    fetchUserDetails()
  }, [])
  async function fetchUserDetails() {
    let resp = await call("POST", "getUserDetailsById", { contactUserId: userId })
    let userResp = resp?.[0]
    let userIECResp = await call("POST", "getUserDetailsFromIEC", { userId })
    // console.log("userIECResppppppppppppp", userIECResp);
    setData({
      ...data,
      companyName: userResp.company_name,
      panno: userResp.pan_no,
      aadharNo: userResp.aadhar_no,
      contact_person: userResp.contact_person,
      contact_number: userResp.contact_number,
      phoneCode: userResp.phonecode,
      email_id: userResp.email_id,
      companyAdd1: userResp.user_address,
      city: userResp.company_city,
      state: userResp.company_state,
      country: userResp.country_code,
      PostalCode: userResp.company_postal_code,
      industry: industryData?.filter(i => {
        if (i.value === userResp.industry_type) {
          return true
        }
      })?.[0]?.["name"],
      startDateOfBusiness: userIECResp?.dateOfIncorporation ? moment(userIECResp.dateOfIncorporation).format("YYYY-MM-DD") : null,
      user_address: userResp.user_address,
      userResp: userResp
    })


  }

  const fetchOwnerDetails = (ownerIndex, action) => {
    const userResp = data.userResp
    let objectToSet = {
      [`ownerName${ownerIndex}`]: "",
      [`ownerNameTitle${ownerIndex}`]: "",
      [`ownerContactNo${ownerIndex}`]: "",
      [`ownerPhoneCode${ownerIndex}`]: "",
      [`ownerEmailId${ownerIndex}`]: "",
      [`ownerAddressLine1${ownerIndex}`]: "",
      [`ownerAddressLine2${ownerIndex}`]: "",
      [`ownerCity${ownerIndex}`]: "",
      [`ownerState${ownerIndex}`]: "",
      [`ownerCountry${ownerIndex}`]: "",
      [`ownerPostalCode${ownerIndex}`]: "",
      [`ownerPanNo${ownerIndex}`]: "",
      [`ownerAadharNo${ownerIndex}`]: ""
    }
    if (action) {
      setData({ ...data, ...objectToSet, [`sameAsUserDetailsOwner${ownerIndex}`]: false })
    }
    else {
      objectToSet = {
        [`ownerName${ownerIndex}`]: userResp.contact_person,
        [`ownerNameTitle${ownerIndex}`]: userResp.name_title,
        [`ownerContactNo${ownerIndex}`]: userResp.contact_number,
        [`ownerPhoneCode${ownerIndex}`]: userResp.phone_code,
        [`ownerEmailId${ownerIndex}`]: userResp.email_id,
        [`ownerAddressLine1${ownerIndex}`]: userResp.user_address,
        [`ownerAddressLine2${ownerIndex}`]: "",
        [`ownerCity${ownerIndex}`]: userResp.company_city,
        [`ownerState${ownerIndex}`]: userResp.company_state,
        [`ownerCountry${ownerIndex}`]: userResp.country_code,
        [`ownerPostalCode${ownerIndex}`]: userResp.company_postal_code,
        [`ownerPanNo${ownerIndex}`]: userResp.pan_no,
        [`ownerAadharNo${ownerIndex}`]: userResp.aadhar_no
      }
      setData({ ...data, ...objectToSet, [`sameAsUserDetailsOwner${ownerIndex}`]: true })
    }
  }
  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('bgApplicationId', selectedChat.bgApplicationId)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))
    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }
  return (
    <div className="container-fluid">
      {selectedChat.isChatOpen &&
        <div className="chatboxDivFixed">
          <ChatBoxPopUp2
            chatList={chatList}
            user_avatar={selectedChat.logo}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            parties={selectedChat.parties}
            userTokenDetails={userTokenDetails}
            onChatSend={(file, parties) => sendChatMsg(file, parties)}
            message={message}
            setTextMsg={setTextMsg}
            reloadChatList={() => getChatDetails({
              chat_room_id: selectedChat.chatRoomId,
              loggedInUser: userId
            })}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false,
                logo: ''
              })
            }}
          />
        </div>
      }
      {applicationSubmitPopup.show ? (
        <FinanceInvoiceModal limitinvoice={applicationSubmitPopup} closeSuccess={() => {
          toggleApplicationSubmitPopup(false)
          window.location = 'cgtmseQuote'
        }}>
          <div className='text-center'>
            <img src='/assets/images/successful_icon.svg' />
            <div className="my-4">
              <img style={{}} src="assets/images/greetings_congratulation.svg" />
            </div>
            <p className="m-0 p-0 px-5 font-size-16" >Your application for <span className="fc-3DB16F" >CGTMSE </span> has been submitted successfully</p>
          </div>
        </FinanceInvoiceModal>
      ) : null}
      {signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
        setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />}
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <DocumentViewer show={viewDoc.show} value={viewDoc.doc} dismiss={() => toggleViewDoc({ show: false, doc: {} })} />
      <DocumentDownloader show={dowdDoc.show} value={dowdDoc.doc} dismiss={() => toggleDowdDoc({ show: false, doc: {} })} />
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className={`modal fade ${seeReview.show && "show"}`} style={seeReview.show ? { display: "block", "zIndex": '100001' } : {}}>
        <div className="modal-dialog modal-md mr-0">
          <div className="modal-content submitmodal pb-4"
          >
            <div className="modal-header border-0">
              <div>
                <i onClick={() => { setSeeReview({ show: false }) }}
                  class="fas fa-2x fa-arrow-left mx-1 mb-4 icon-color cursor"></i>
              </div>
            </div>
            <div className="modal-body px-4">
              <div className="d-flex flex-row">
                {/* <img className="mr-2"
                  src={finLogoByUserId[seeReview.id]} alt="" /> */}
                {seeReview.logo ? (<img
                  style={{ maxWidth: '8rem', maxHeight: '2rem' }}
                  className="mx-2" src={avatarUrl(seeReview.logo)} alt="" />) : null}
                <label
                  className="font-size-16 text-color-label font-wt-500"
                >{seeReview.name}</label>
              </div>
              <div>
                <label
                  className="font-size-14 text-color1 font-wt-600"
                >{seeReview.website || "NA"}</label>
              </div>
              <div className="my-4">
                <label
                  className="font-size-14 text-color-label font-wt-500"
                >{seeReview.bio || "NA"}</label>
              </div>
              <div>
                {reviewLabels.map((item, index) => {
                  return (
                    <div
                      className={'d-flex justify-content-between py-1 px-3'}
                      style={{
                        "border": "1px solid #EEEEEE",
                        "borderRadius": "10px"
                      }}
                    >
                      <label className="font-size-14 text-color-label font-wt-500">{item.name}</label>
                      <label
                        style={{
                          color: item.color
                        }}
                        className="font-size-14 font-wt-600">{seeReview[item.alt] || "NA"}</label>
                    </div>
                  )
                })}
              </div>

              {seeReview?.reviews?.length ? (
                <>
                  <div
                    className={"darkPinkBg text-center my-4 py-4 justify-content-center align-items-center"}
                    style={{ borderRadius: "10px" }}
                  >
                    <div className="mb-3">
                      <label
                        className="font-size-14 text-color-label font-wt-500"
                      >{seeReview?.reviews?.[reviewScrollIndex]?.remarks || "NA"}</label>
                    </div>

                    <div className="mb-3">
                      <label
                        className="font-size-22 text-color-label font-wt-500"
                      >----------------------------------------------------------</label>
                    </div>

                    <div className="d-flex flex-row align-items-center justify-content-center" >
                      <img
                        onClick={() => {
                          let reviewsLength = seeReview?.reviews?.length
                          if (reviewScrollIndex == 0) {
                            toggleReviewScrollIndex(reviewsLength - 1)
                          }
                          else {
                            toggleReviewScrollIndex(reviewScrollIndex - 1)
                          }
                        }}
                        className="cursor" src={'assets/images/arrow-left.png'} />
                      <label className="font-size-14 text-color-label font-wt-500 mx-3">{seeReview.reviews?.[reviewScrollIndex]?.createdByName || "NA"}</label>
                      <img
                        onClick={() => {
                          let reviewsLength = seeReview?.reviews?.length - 1
                          if (reviewScrollIndex == reviewsLength) {
                            toggleReviewScrollIndex(0)
                          }
                          else {
                            toggleReviewScrollIndex(reviewScrollIndex + 1)
                          }
                        }}
                        className="cursor" src={'assets/images/arrow-right.png'} />
                    </div>

                    <div className="d-flex px-2 align-items-center justify-content-center">
                      <div className="col-6 px-0 cursor">
                        <span>
                          <p className="mb-0 font-size-14">{seeReview?.reviews?.[reviewScrollIndex]?.ratings?.toFixed(2) || "NA"}</p>
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 0.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 1.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 2.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 3.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 4.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <SideBarV2 state="otherFinCGTMSEApplyLimit" userTokenDetails={userTokenDetails} />
        <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState?.status ? " expanded-right" : "")} id="app-main-div">
          <HeaderV2
            title={"CGTMSE  >   Apply for Limit"}
            userTokenDetails={userTokenDetails} />
          <a className="cursor"><img className="cursor mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
          <div>
            <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
              {scmformtabs.map((item, index) => {
                return (
                  <li>
                    <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                      onClick={() => { setTab(index) }}
                    >
                      {item.name}</a>
                  </li>
                )
              })}
            </ul>
            <div className="card mt-1">
              {
                tab === 0 ? (
                  <div className='py-4 px-3'>
                    <ExtraDetailsForLimitApplication userTokenDetails={userTokenDetails} hideHeader={true} hideSideBar={true}
                      hideSubmitDiv={true} hideBackArrow={true} expandAll={true} cardBorder0={true} cardPadding0={true}
                      showContinueButton={false} afterDetailsSavedOn={() => setTab(1)} />
                  </div>
                ) : null
              }

              {tab === 1 ? (
                <div className='p-4'>
                  <div>
                    <label className='font-size-14 font-wt-500 text-decoration-underline'>Business details</label>
                    <div className='row'>
                      <div className='col-md-4'>
                        <NewInput isAstrix={true} type={"text"} label={"Business Name"}
                          name={"companyName"} value={data.companyName} error={errors.companyName}
                          onChange={handleChange} />
                      </div>
                      <div className='col-md-4'>
                        <NewInput isAstrix={true} type={"text"} label={"PAN No."}
                          name={"panno"} value={data.panno} error={errors.panno}
                          onChange={handleChange} />
                      </div>
                      <div className='col-md-4'>
                        <NewInput isAstrix={true} type={"text"} label={"Aadhaar No."}
                          name={"aadharNo"} value={data.aadharNo} error={errors.aadharNo}
                          onChange={handleChange} />
                      </div>
                      <div className='col-md-4'>
                        <InputWithSelect isAstrix={true} type={"text"} label={"Contact Person"}
                          selectData={[{ name: "Mr" }, { name: "Miss" }]}
                          selectName={"nameTitle"} selectValue={data.nameTitle}
                          optionLabel={"name"} optionValue={'name'}
                          name={'contact_person'} value={data.contact_person} error={errors.contact_person}
                          onChange={handleChange} />
                      </div>
                      <div className='col-md-4'>
                        <InputWithSelect type={"number"} label={"Contact Number"}
                          selectData={[]}
                          selectName={"phone_code"} selectValue={data.phone_code}
                          optionLabel={"phonecode"} optionValue={'phonecode'}
                          name={'contact_number'} value={data.contact_number} error={errors.contact_number}
                          onChange={handleChange} />
                      </div>
                      <div className='col-md-4'>
                        <NewInput isAstrix={true} type={"email"} label={"Email ID"}
                          name={"email_id"} value={data.email_id} error={errors.email_id}
                          onChange={handleChange} />
                      </div>
                      <div className='col-md-4'>
                        <NewInput isAstrix={true} type={"text"} label={"Address Line 1"}
                          name={"companyAdd1"} value={data.companyAdd1} error={errors.companyAdd1}
                          onChange={handleChange} />
                      </div>
                      <div className='col-md-4'>
                        <NewInput isAstrix={true} type={"text"} label={"Address Line 2"}
                          name={"companyAdd2"} value={data.companyAdd2} error={errors.companyAdd2}
                          onChange={handleChange} />
                      </div>
                      <div className='col-md-4'>
                        <NewInput isAstrix={true} type={"text"} label={"City"}
                          name={"city"} value={data.city} error={errors.city}
                          onChange={handleChange} />
                      </div>
                      <div className='col-md-4'>
                        <NewInput isAstrix={true} type={"text"} label={"State"}
                          name={"state"} value={data.state} error={errors.state}
                          onChange={handleChange} />
                      </div>
                      <div className='col-md-4'>
                        <NewInput isAstrix={true} type={"text"} label={"Country"}
                          name={"country"} value={data.country} error={errors.country}
                          onChange={handleChange} />
                      </div>
                      <div className='col-md-4'>
                        <NewInput isAstrix={true} type={"text"} label={"Postal Code"}
                          name={"PostalCode"} value={data.PostalCode} error={errors.PostalCode}
                          onChange={handleChange} />
                      </div>
                      <div className='col-md-4'>
                        <NewSelect isAstrix={true} label={"Constitution"}
                          selectData={[]} name={"constitution"}
                          value={data.constitution} optionLabel={"name"} optionValue={'sortname'}
                          onChange={handleChange} error={errors.constitution} />
                      </div>
                      <div className='col-md-4'>
                        <NewSelect isAstrix={true} label={"Industry"}
                          selectData={industryData} name={"industry"}
                          value={data.industry} optionLabel={"name"} optionValue={'sortname'}
                          onChange={handleChange} error={errors.industry} />
                      </div>
                      <div className='col-md-4'>
                        <NewInput isAstrix={true} type={"date"} label={"Start date of business"}
                          name={"startDateOfBusiness"} value={data.startDateOfBusiness} error={errors.startDateOfBusiness}
                          onChange={handleChange} />
                      </div>
                      <div className='col-md-4'>
                        <NewInput isAstrix={true} type={"text"} label={"Sector"}
                          name={"sector"} value={data.sector} error={errors.sector}
                          onChange={handleChange} />
                      </div>
                      <div className='col-md-4'>
                        <NewInput isAstrix={true} type={"text"} label={"Sub-Sector"}
                          name={"subsector"} value={data.subsector} error={errors.subsector}
                          onChange={handleChange} />
                      </div>
                      <div className='col-md-4'>
                        <NewInput isAstrix={true} type={"text"} label={"Competition"}
                          name={"competition"} value={data.competition} error={errors.competition}
                          onChange={handleChange} />
                      </div>
                      <div className='col-md-4'>
                        <NewInput isAstrix={true} type={"text"} label={"Factory Premises"}
                          name={"factoryPremises"} value={data.factoryPremises} error={errors.factoryPremises}
                          onChange={handleChange} />
                      </div>
                      <div className='col-md-4'>
                        <NewInput isAstrix={true} type={"text"} label={"Product Description"}
                          name={"productDescription"} value={data.productDescription} error={errors.productDescription}
                          onChange={handleChange} />
                      </div>

                    </div>
                  </div>

                  {owners.map((i, j) => {
                    return (
                      <div className="mb-3" >
                        <div className='mb-3 d-flex m-2'>
                          <label className="font-wt-500 font-size-14 pt-1 mr-4" ><u>{`Owner ${j + 1} details`}</u></label>
                          <div className="mr-4">
                            <img
                              onClick={() => {
                                fetchOwnerDetails(j, data[`sameAsUserDetailsOwner${j}`])
                              }}
                              className='cursor mr-3' src={`assets/images/${data[`sameAsUserDetailsOwner${j}`] ? 'checked-green' : 'empty-check'}.png`} />
                            <label className="font-wt-500 font-size-13">Same as user details</label>
                          </div>
                          <div>
                            <img
                              onClick={() => setData({ ...data, [`mainUserOwner${j}`]: !data[`mainUserOwner${j}`] })}
                              className='cursor mr-3' src={`assets/images/${data[`mainUserOwner${j}`] ? 'checked-green' : 'empty-check'}.png`} />
                            <label className="font-wt-500 font-size-13">is main user</label>
                          </div>
                        </div>

                        <div className="d-flex row" >
                          <div className="col-md-4" >
                            <InputWithSelect label={"Owner Name"}
                              selectData={[{ name: "Mr" }, { name: 'Miss' }]}
                              selectName={`ownerNameTitle${j}`} selectValue={data[`ownerNameTitle${j}`]}
                              optionLabel={"name"} optionValue={'name'}
                              name={`ownerName${j}`} value={data[`ownerName${j}`]}
                              onChange={handleChange} />
                          </div>
                          <div className="col-md-4" >
                            <NewInput type={"date"}
                              label={"Date of Birth"} value={data[`ownerDOB${j}`]} name={`ownerDOB${j}`}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-4" >
                            <NewInput
                              label={"Gender"} value={data[`ownerGender${j}`]} name={`ownerGender${j}`}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-4" >
                            <NewInput
                              label={"Category"} value={data[`ownerCategory${j}`]} name={`ownerCategory${j}`}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-4" >
                            <InputWithSelect type={"number"} label={"Contact No"}
                              selectData={countryData}
                              selectName={`ownerPhoneCode${j}`} selectValue={data[`ownerPhoneCode${j}`]}
                              optionLabel={"phonecode"} optionValue={'phonecode'}
                              name={`ownerContactNo${j}`} value={data[`ownerContactNo${j}`]}
                              onChange={handleChange} />
                          </div>
                          <div className="col-md-4" >
                            <NewInput
                              label={"Email ID"} value={data[`ownerEmailId${j}`]} name={`ownerEmailId${j}`}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-4" >
                            <NewInput
                              label={"Address Line 1"} value={data[`ownerAddressLine1${j}`]} name={`ownerAddressLine1${j}`}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-4" >
                            <NewInput
                              label={"Address Line 2"} value={data[`ownerAddressLine2${j}`]} name={`ownerAddressLine2${j}`}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-4" >
                            <NewInput
                              label={"City"} value={data[`ownerCity${j}`]} name={`ownerCity${j}`}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-4" >
                            <NewInput
                              label={"State"} value={data[`ownerState${j}`]} name={`ownerState${j}`}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-4" >
                            <NewSelect
                              selectData={countryData} optionLabel={"name"} optionValue={"sortname"} onChange={handleChange}
                              label={"Country"} name={`ownerCountry${j}`} value={data[`ownerCountry${j}`]}
                            />
                          </div>
                          <div className="col-md-4" >
                            <NewInput
                              label={"Postal Code"} value={data[`ownerPostalCode${j}`]} name={`ownerPostalCode${j}`}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-4" >
                            <NewInput
                              label={"PAN No"} value={data[`ownerPanNo${j}`]} name={`ownerPanNo${j}`}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-4" >
                            <NewInput
                              label={"Aadhar No"} value={data[`ownerAadharNo${j}`]} name={`ownerAadharNo${j}`}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-4" >
                            <NewInput
                              label={"New Worth"} value={data[`ownerNetWorth${j}`]} name={`ownerNetWorth${j}`}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  })}

                  <div className="mt-4">
                    <NewTablev2 showSideBorders={true} columns={[{ subColumns: "Yes / No Questions" }, { subColumns: 'YES' }, { subColumns: 'NO' }]}>
                      {Object.keys(yesnoqsts).map((i, j) => {
                        return (
                          <tr style={{ borderWidth: 1 }}>
                            <td>{yesnoqsts[i]["label"]}</td>
                            <td>
                              <img
                                onClick={() => {
                                  setData({ ...data, [i]: data[i] === true ? undefined : true })
                                }}
                                className='cursor mr-3' src={`assets/images/${data[i] === true ? 'checked_vector' : 'unchecked_vector'}.svg`} />
                            </td>
                            <td>
                              <img
                                onClick={() => {
                                  setData({ ...data, [i]: data[i] === false ? undefined : false })
                                }}
                                className='cursor mr-3' src={`assets/images/${data[i] === false ? 'checked_vector' : 'unchecked_vector'}.svg`} />
                            </td>
                          </tr>
                        )
                      })}
                    </NewTablev2>
                  </div>

                  <div className="mt-4">
                    <label className='underlinedLabel'>Month on month (Sales in Lacs)</label>

                    <NewTablev2 showSideBorders={true} columns={[{ subColumns: "Months" }, { subColumns: '2nd Last Year' }, { subColumns: 'Last Year' }, { subColumns: 'Current Year' }]}>
                      {FinancialMonths.map((i, j) => {
                        return (
                          <tr style={{ borderWidth: 1, borderColor: '#D5D5D5' }}>
                            <td style={{ borderWidth: 1, borderColor: '#D5D5D5' }}>{i}</td>
                            <td style={{ borderWidth: 1, borderColor: '#D5D5D5' }}>
                              <div className="col-md-12 px-0">
                                <InputWithSelect isAstrix={false} type={"text"} label={"Net Worth"}
                                  selectData={most_used_currencies}
                                  selectName={`Currency`} selectValue={data[`Currency`]}
                                  optionLabel={"code"} optionValue={'code'}
                                  name={`${i}_2ndLastValue`} value={data[`${i}_2ndLastValue`]} error={errors[`${i}_2ndLastValue`]}
                                  onChange={handleChange} />
                              </div>
                            </td>
                            <td style={{ borderWidth: 1, borderColor: '#D5D5D5' }}>
                              <div className="col-md-12 px-0">
                                <InputWithSelect isAstrix={false} type={"text"} label={"Net Worth"}
                                  selectData={most_used_currencies}
                                  selectName={`Currency`} selectValue={data[`Currency`]}
                                  optionLabel={"code"} optionValue={'code'}
                                  name={`${i}_LastValue`} value={data[`${i}_LastValue`]} error={errors[`${i}_LastValue`]}
                                  onChange={handleChange} />
                              </div>
                            </td>
                            <td style={{ borderWidth: 1, borderColor: '#D5D5D5' }}>
                              <div className="col-md-12 px-0">
                                <InputWithSelect isAstrix={false} type={"text"} label={"Net Worth"}
                                  selectData={[]}
                                  selectName={`${i}_CurrentCurrency`} selectValue={data[`${i}_CurrentCurrency`]}
                                  optionLabel={"code"} optionValue={'code'}
                                  name={`${i}_CurrenntValue`} value={data[`${i}_CurrenntValue`]} error={errors[`${i}_CurrenntValue`]}
                                  onChange={handleChange} />
                              </div>
                            </td>

                          </tr>
                        )
                      })}
                    </NewTablev2>
                  </div>

                </div>
              )
                : null}

              {tab === 2 ? (
                <div className='p-4'>

                  <div className='row mt-4'>
                    <div>
                      <label className='font-size-14  '><span className='font-size-14 font-wt-400  text-decoration-underline'>Top 10 buyer details</span>(Upload buyer details in excel format or fill the below table manually)</label>
                      <div className='col-md-6 my-2'>
                        <div className="col-md-12">
                          <FileInput name={"topBuyersDetail"} value={data.topBuyersDetail} error={errors.topBuyersDetail}
                            onChange={(e) => handleCSV(e, "buyer")} isEditable={true} userTokenDetails={userTokenDetails} onUploadCancel={() => setData({ ...data, "topBuyersDetail": null })} />
                        </div>
                      </div>
                    </div>
                    <div>
                      <label className='font-size-14 font-wt-600 text-color1' onClick={() => ExportExcel(buyersFormat, "SCF_buyers_format")}>Download File Format</label>
                    </div>
                    <div className='mt-4'>
                      <NewTablev2
                        showSideBorders={true}
                        columns={[
                          { subColumns: "Buyer Name", thwidth: "w-14" },
                          { subColumns: "Address", thwidth: "w-14" },
                          { subColumns: "Credit limit required", thwidth: "w-14" },
                          { subColumns: "Terms of payment", thwidth: "w-14" },
                          { subColumns: "Annual Sale", thwidth: "w-14" },
                          { subColumns: "Years of relation", thwidth: "w-14" },
                          { subColumns: "relation with client", thwidth: "w-14" }

                        ]}
                      >
                        {top10Buyers.map((i, j) => {
                          return (
                            <tr>
                              <td>
                                <InputForTable name={`buyerName${j}`} value={data[`buyerName${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable name={`buyerAddress${j}`} value={data[`buyerAddress${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`buyerCreditLimitRequired${j}`} value={data[`buyerCreditLimitRequired${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`buyerTermsOfPayment${j}`} value={data[`buyerTermsOfPayment${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`buyerAnnualSale${j}`} value={data[`buyerAnnualSale${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`buyerYearsOfRelation${j}`} value={data[`buyerYearsOfRelation${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`buyerRelationWithClient${j}`} value={data[`buyerRelationWithClient${j}`]} onChange={handleChange} />
                              </td>
                            </tr>
                          )
                        })}
                      </NewTablev2>
                    </div>

                  </div>

                  <div className='row mt-4'>
                    <div>
                      <label className='font-size-14 font-wt-400 '><span className='font-size-14 font-wt-400  text-decoration-underline'>Top 10 supplier details</span>(Upload buyer details in excel format or fill the below table manually)</label>
                      <div className='col-md-6 my-2'>
                        <div className="col-md-12">
                          <FileInput name={"topsupplierDetails"} value={data.topsupplierDetails} error={errors.topsupplierDetails}
                            onChange={(e) => handleCSV(e, "supplier")} isEditable={true} userTokenDetails={userTokenDetails} onUploadCancel={() => setData({ ...data, "topsupplierDetails": null })} />
                        </div>
                      </div>
                    </div>
                    <div>
                      <label className='font-size-14 font-wt-600 text-color1 cursor' onClick={() => ExportExcel(suppliersFormat, "SCF_suppliers_format")}>Download File Format</label>
                    </div>

                    <div className='mt-4'>
                      <NewTablev2
                        showSideBorders={true}
                        columns={[
                          { subColumns: "Supplier Name", thwidth: "w-14" },
                          { subColumns: "Address", thwidth: "w-14" },
                          { subColumns: "Credit limit required", thwidth: "w-14" },
                          { subColumns: "Terms of payment", thwidth: "w-14" },
                          { subColumns: "Annual Sale", thwidth: "w-14" },
                          { subColumns: "Years of relation", thwidth: "w-14" },
                          { subColumns: "relation with client", thwidth: "w-14" }

                        ]}
                      >
                        {top10Suppliers.map((i, j) => {
                          return (
                            <tr>
                              <td>
                                <InputForTable name={`supplierName${j}`} value={data[`supplierName${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable name={`supplierAddress${j}`} value={data[`supplierAddress${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`supplierCreditLimitRequired${j}`} value={data[`supplierCreditLimitRequired${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`supplierTermsOfPayment${j}`} value={data[`supplierTermsOfPayment${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`supplierAnnualSale${j}`} value={data[`supplierAnnualSale${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`supplierYearsOfRelation${j}`} value={data[`supplierYearsOfRelation${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`supplierRelationWithClient${j}`} value={data[`supplierRelationWithClient${j}`]} onChange={handleChange} />
                              </td>
                            </tr>
                          )
                        })}
                      </NewTablev2>
                    </div>

                  </div>


                </div>
              )
                : null}

              {tab === 3 ? (
                <div className='p-4'>
                  <label className='font-size-14 font-wt-600 text-decoration-underline'>Select any 5 Financier</label>
                  <div className='row gap-4 justify-content-between p-4'>
                    {financiers.map((item, index) => {
                      return <div className={`w-20 ${item.isRecommened ? ' financierCardNewRecommended ' : ' financierCardNew '}`}>
                        {item.isRecommened ? <img src="assets/images/recommended_ribbon.svg" className='recommendRibbon' /> : null}
                        <div className='d-flex w-100 gap-4 align-items-center justify-content-between'>
                          <div className='d-flex gap-4 align-items-center'>
                            <img src={avatarUrl(item.user_avatar)} height={25} width={25} />
                            <label className='font-size-14 font-wt-500 mb-0'>{item.name}</label>
                          </div>
                          <div>
                            <img
                              onClick={() => {
                                if (item.isAvailable || environment === "dev") {
                                  handleFinSelection(index)
                                } else {
                                  toastDisplay("Action not availble", "info")
                                }
                              }}
                              className='cursor mr-3' src={`assets/images/${financiers?.[index]["isChecked"] ? 'checked_vector' : 'unchecked_vector'}.svg`} height={21} width={21} />
                          </div>
                        </div>
                        <div className="d-flex px-2 pt-3 w-100">
                          <div className="col-6 px-0 cursor"><p className="mb-0 font-size-14">{item.avgRatings.toFixed(2)}</p>
                            <span>
                              <img className={`${item.avgRatings < 0.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                              <img className={`${item.avgRatings < 1.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                              <img className={`${item.avgRatings < 2.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                              <img className={`${item.avgRatings < 3.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                              <img className={`${item.avgRatings < 4.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                            </span>
                          </div>
                          <div
                            onClick={async () => {
                              // Get getUserTradeInfoWithFinancier
                              let temp = item
                              temp["show"] = true
                              toggleReviewScrollIndex(0)
                              setshowLoader(true)
                              temp = { ...temp, ...await call('POST', 'getUserTradeInfoWithFinancier', { lenderId: item.id, sellerId: userId }) }
                              setshowLoader(false)
                              setSeeReview(temp)
                            }}
                            className="col-6 px-0 cursor"><p className="mb-1 font-size-14">{item.reviewsCount}</p><p className="font10 font-size-14 ">See reviews</p></div>
                        </div>
                        <p
                          onClick={() => {
                            setSelectedChat({
                              receiverName: item.name,
                              receiverId: item.id,
                              userId: userId,
                              isChatOpen: true
                            })
                          }} className="cursor"
                        ><img className="ms-2" src={"assets/images/supplier-images/chat.png"} alt="" /><span className="font-size-14 text-dark ms-2">Chat with financer</span></p>
                      </div>
                    })}
                  </div>

                </div>
              )
                : null}

              {tab === 4 ? (
                <div className='d-flex row px-5 pt-2 pb-4'>
                  <div className='w-100 mt-4'>
                    <label className='font-wt-500 font-size-14'><u>Other Details</u></label>
                    <div className='row'>
                      {companyDetailsReviewForm.map((item) => {
                        return (
                          <div className="col-md-6 pe-5">
                            {item.name ?
                              <p className="d-flex align-items-top mb-2 font-size-14 font-wt-400"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[`${item.val}`] ? (item.unit ? `${data[`${item.unit}`]} ` : "") + (data[`${item.val}`]) : "-"} {item.after && data[item.after] && ` ${data[item.after]} `}</span> </p>
                              : null} </div>
                        );
                      })}
                    </div>
                  </div>
                  {owners.map((i, j) => {
                    return (
                      <div className='w-100 mb-3'>
                        <label className='font-wt-500 font-size-16'><u>{`Owner ${j + 1} Details`}</u></label>
                        <div className='row'>
                          {ownerReview.map((item) => {
                            return (
                              <div className="col-md-6 pe-5">
                                {item.name ?
                                  <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[`${item.val}${j}`] ? (item.unit ? `${data[`${item.unit}${j}`]} ` : "") + (data[`${item.val}${j}`]) : "-"} {item.after && data[`${item.after}${j}`] && ` ${data[`${item.after}${j}`]} `}</span> </p>
                                  : null} </div>
                            );
                          })}
                        </div>
                      </div>
                    )
                  })}
                  <div className="mt-4 px-0">
                    <NewTablev2 showSideBorders={true} columns={[{ subColumns: "Yes / No Questions" }, { subColumns: 'YES' }, { subColumns: 'NO' }]}>
                      {Object.keys(yesnoqsts).map((i, j) => {
                        return (
                          <tr style={{ borderWidth: 1 }}>
                            <td>{yesnoqsts[i]["label"]}</td>
                            <td>
                              <img
                                className='mr-3' src={`assets/images/${data[i] === true ? 'checked_vector' : 'unchecked_vector'}.svg`} />
                            </td>
                            <td>
                              <img
                                className='mr-3' src={`assets/images/${data[i] === false ? 'checked_vector' : 'unchecked_vector'}.svg`} />
                            </td>
                          </tr>
                        )
                      })}
                    </NewTablev2>
                  </div>

                  <div className="mt-4">
                    <label className='underlinedLabel'>Month on month (Sales in Lacs)</label>

                    <NewTablev2 columns={[{ subColumns: "Months" }, { subColumns: '2nd Last Year' }, { subColumns: 'Last Year' }, { subColumns: 'Current Year' }]} showSideBorders={true}>
                      {FinancialMonths.map((i, j) => {
                        return (
                          <tr style={{ borderWidth: 1, borderColor: '#D5D5D5' }}>
                            <td style={{ borderWidth: 1, borderColor: '#D5D5D5' }}>{i}</td>
                            <td style={{ borderWidth: 1, borderColor: '#D5D5D5' }}>
                              {data[`${i}_2ndLastValue`] || '-'}
                            </td>
                            <td style={{ borderWidth: 1, borderColor: '#D5D5D5' }}>
                              {data[`${i}_LastValue`] || '-'}
                            </td>
                            <td style={{ borderWidth: 1, borderColor: '#D5D5D5' }}>
                              {data[`${i}_CurrenntValue`] || '-'}
                            </td>

                          </tr>
                        )
                      })}
                    </NewTablev2>
                  </div>

                  <div className='row mt-4'>
                    <label className='font-size-14  '><span className='font-size-14 font-wt-400  text-decoration-underline'>Top 10 buyer details</span></label>
                    <div className='mt-4'>
                      <NewTablev2
                        showSideBorders={true}
                        columns={[
                          { subColumns: "Buyer Name", thwidth: "w-14" },
                          { subColumns: "Address", thwidth: "w-14" },
                          { subColumns: "Credit limit required", thwidth: "w-14" },
                          { subColumns: "Terms of payment", thwidth: "w-14" },
                          { subColumns: "Annual Sale", thwidth: "w-14" },
                          { subColumns: "Years of relation", thwidth: "w-14" },
                          { subColumns: "relation with client", thwidth: "w-14" }

                        ]}
                      >
                        {top10Buyers.map((i, j) => {
                          return (
                            data[`buyerName${j}`] &&
                            <tr>
                              <td>
                                {data[`buyerName${j}`] || '-'}
                              </td>
                              <td>
                                {data[`buyerAddress${j}`] || '-'}
                              </td>
                              <td>
                                {data[`buyerCreditLimitRequired${j}`] || '-'}
                              </td>
                              <td>
                                {data[`buyerTermsOfPayment${j}`] || "-"}
                              </td>
                              <td>
                                {data[`buyerAnnualSale${j}`] || '-'}
                              </td>
                              <td>
                                {data[`buyerYearsOfRelation${j}`] || '-'}
                              </td>
                              <td>
                                {data[`buyerRelationWithClient${j}`] || '-'}
                              </td>
                            </tr>
                          )
                        })}
                      </NewTablev2>
                    </div>

                  </div>

                  <div className='row mt-4'>
                    <label className='font-size-14 font-wt-400 '><span className='font-size-14 font-wt-400  text-decoration-underline'>Top 10 supplier details</span></label>
                    <div className='mt-4'>
                      <NewTablev2
                        showSideBorders={true}
                        columns={[
                          { subColumns: "Supplier Name", thwidth: "w-14" },
                          { subColumns: "Address", thwidth: "w-14" },
                          { subColumns: "Credit limit required", thwidth: "w-14" },
                          { subColumns: "Terms of payment", thwidth: "w-14" },
                          { subColumns: "Annual Sale", thwidth: "w-14" },
                          { subColumns: "Years of relation", thwidth: "w-14" },
                          { subColumns: "relation with client", thwidth: "w-14" }

                        ]}
                      >
                        {top10Suppliers.map((i, j) => {
                          return (
                            data[`supplierName${j}`] &&
                            <tr>
                              <td>
                                {data[`supplierName${j}`] || '-'}
                              </td>
                              <td>
                                {data[`supplierAddress${j}`] || '-'}
                              </td>
                              <td>
                                {data[`supplierCreditLimitRequired${j}`] || '-'}
                              </td>
                              <td>
                                {data[`supplierTermsOfPayment${j}`] || '-'}
                              </td>
                              <td>
                                {data[`supplierAnnualSale${j}`] || '-'}
                              </td>
                              <td>
                                {data[`supplierYearsOfRelation${j}`] || '-'}
                              </td>
                              <td>
                                {data[`supplierRelationWithClient${j}`] || '-'}
                              </td>
                            </tr>
                          )
                        })}
                      </NewTablev2>
                    </div>

                  </div>

                  <div className='w-100 mt-4'>
                    <label className='font-wt-500 font-size-16'><u>Financer selected</u></label>
                    <div className="d-flex gap-4 my-3">
                      {financiers.map((key) => {
                        if (key.isChecked) {
                          return (
                            <label key={key.name}
                              className="browse-div cursor text-center m-0 col-1 border-finance text-dark" >
                              {key.name}
                            </label>
                          );
                        }
                      })}
                    </div>
                  </div>
                  <div className='mt-3'>
                    <img
                      onClick={() => setData({ ...data, tcAccepted: !data.tcAccepted })}
                      className='cursor mr-3' src={`assets/images/${data.tcAccepted ? 'checked-green' : 'empty-check'}.png`} />
                    <label>I hereby declare that the above given information by me is true to my knowledge.</label>
                  </div>

                </div>
              )
                : null
              }

              <div className="d-flex px-4 pb-4 pt-0">
                <button
                  onClick={() => handleValidation()}
                  type="button" className={`new-btn w-20 py-2 px-2 text-white cursor`}>
                  {tab == 4 ? "Submit" : "Continue"}
                </button>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
const mapStateToProps = state => {
  return {
    clientType: state.clientType,
    navToggleState: state.navToggleState,
    notifications: state.IncomingNotifications.notification_data
  }
}

export default connect(mapStateToProps)(ApplyLimitCGTMSE)
