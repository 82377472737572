import React from "react";

const FinanceInvoiceModal = ({ headerTitle, limitinvoice, children, closeSuccess, modalSize, isCentered }) => {
  return (
    <>
      <div className={`modal fade ${limitinvoice && "show"}`} style={{ display: "block", "zIndex": '1000001' }}>
        <div style={{ marginTop: '8rem' }} className={`modal-dialog modal-${modalSize || "md"} ${isCentered ? 'modal-dialog-centered' : ''}`}>
          <div className="modal-content submitmodal pb-4">
            <div className="modal-header border-0">
              {headerTitle ?
                <div className="w-100 text-center pt-2 " >
                  <label className="font-size-16 mb-0 font-wt-600 text-color-value text-center" >{headerTitle}</label>
                </div> : null}
              <button type="button" className="btn-close" aria-label="Close" onClick={() => closeSuccess()}></button>
            </div>
            <div className="modal-body">

              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default FinanceInvoiceModal;


